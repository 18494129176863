import { SET_CONTRACT, DELETE_CONTRACT } from 'components/AdminPanel/Contracts/Actions/Contracts.actions';
import { logObjDifferences, isStateEqual } from "store/reducers/reducer.helper";

import {
  SET_SITE_CONTRACTS,
  FETCH_SITES,
  SET_SITE,
  DELETE_SITE,
  UPDATE_SITE_IMAGE,
  FAVOURITE_SITE
} from "components/AdminPanel/Sites/Actions/AdminPanelSites.actions";

import {
  SET_GROUP_SITE
} from "components/AdminPanel/Sites/Actions/AdminPanelSites.actions";

import { Site, contracts } from "../Interfaces/Site.inteface";
import {
  LookupEntity,
  Meta,
  Dictionary
} from "../../Common/Interfaces/Entity.interface";
import { getLookup } from "./Sites.reducer.helper";
import { fetchReducer } from "components/AdminPanel/Helpers/Entity.helper";
import { ContractInterface } from "../../AdminPanel/Contracts/Interfaces/Contract.interface";
import { AdminState } from "components/AdminPanel/Interfaces/Admin.state";
import {
  removePsuedoRecordFromState,
  createPsuedoRecord,
  mergeChildObjectsByKey,
  prepareMainListViewsAfterCreate
} from "components/AdminPanel/Reducers/adminReducer.helper";
import { getContractSiteFromSiteContracts } from "./Sites.reducer.helper";

const initialState = {
  selectedSite: null,
  hasNoSites: undefined,
  contracts: {},
  data: {},
  lookup: {},
  recordmeta: {},
  meta: {
    PUT: undefined,
    POST: undefined
  }
};

export interface SiteState extends AdminState {
  hasNoSites?: boolean; // makes more sense to define this as hasNoSites rather than hasSites, so it only becomes truthy once the call has returned.
  data: Dictionary<Site>;
  lookup: { [id: number]: LookupEntity };
  meta: Meta;
  contracts: contracts;//Dictionary<any[]>; // ? Normalised contract reference used by the AdminPanel SitesDetailView
}

const sitesReducer = (state: SiteState = initialState, action: any) => {
  switch (action.type) {
    case FETCH_SITES.success: {
      const newState = fetchReducer(state, action, 'sites', getLookup);
      newState['hasNoSites'] = !Object.keys(newState.data).length;
      return newState;
    }

    case SET_SITE_CONTRACTS.reducer: {
      return {
        ...state,
        contracts: {
          ...state.contracts,
          [action.siteId]: action.contracts
        }
      }
    }

    case SET_SITE.success: {
      const { preparedObj, preparedData } = prepareMainListViewsAfterCreate(state.data, action.site, action);
      return {
        ...state,
        data: {
          [action.site.id]: preparedObj,
          ...preparedData
        },
        lookup: {
          ...state.lookup,
          [action.site.id]: getLookup(action.site)
        },
        meta: {
          ...state.meta,
          PUT: action.meta || state.meta.PUT
        },
        recordmeta: {
          ...state.recordmeta,
          [action.site.id]: { ...action.meta },
        },
        options: action.options,
        permissions: {
          ...state.permissions,
          [action.site.id]: action.permissions
        },
      };
    }


    case SET_SITE.reducer: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.site.id]: {
            ...action.site,
          },
        },
        lookup: {
          ...state.lookup,
          [action.site.id]: getLookup(action.site)
        }
      };
    }


    case DELETE_SITE.reducer: {

      delete state.data[action.siteId];
      delete state.lookup[action.siteId];

      if (state.recordmeta) {
        delete state.recordmeta[action.siteId];
      }

      return {
        ...state,
        data: {
          ...state.data,
        }
      };
    }

    case DELETE_CONTRACT.reducer: {
      const { data, contracts } = state;
      const siteId = getContractSiteFromSiteContracts(data, action.contractId);
      const site = siteId ? data[siteId] : null
      if (siteId && site) { // HT Note: this 'should' always exist but it shuts typescript up... to discuss with Dan
        return {
          ...state,
          // HT Note: !!!!
          // NB it seems that the 'contracts' property of a site (not to be confused with 'data -> site_contracts'!) already gets updated when a contract is deleted - 
          // - it's only populated when viewing the sites contracts sublist anyway it seems, so generally its just an empty object at this point. 
          // So here its just the site_contracts that needs
          // to be taken care of 
          // .. TODO: find where the contracts part is cleaned up. 
          // Notes on this: currently I can't find it and DELETE_CONTRACT.start, which would invoke the epic - is dispatched by the action deleteContract.
          // However this never seems to be called(!!!) - instead the service called deleteContract directly calls DELETE_CONTRACT.reducer !!!)
          // As it doesn't seem to be called anywhere, quite likely the site 'contracts' property is just lost and repopulated on a full masterView refresh or something
          // Anyway, if it is called, decide whether to remove contract from both 'contracts' and 'data.site_contracts' in the same place
          // contracts: seems unecessary here,
          //update the site_contracts
          data: {
            ...data,
            [siteId]: {
              ...data[siteId],
              site_contracts: (site.site_contracts || []).filter(c => c.id !== action.contractId)
            }
          }
        }
      }
      return state
    }


    case SET_CONTRACT.success: {

      const { data, contracts } = state;
      const { contract: newContract, insert } = action;
      const site = data[newContract.site.id];

      if (!insert) {
        return state
      }

      return {
        ...state,
        // update the contracts reference
        contracts: {
          ...contracts,
          [newContract.site.id]: [
            ...(contracts[newContract.site.id] || []),
            newContract.id
          ]
        },
        // update the site_contracts
        data: {
          ...data,
          [newContract.site.id]: {
            ...data[newContract.site.id],
            site_contracts: [...(site.site_contracts || []), newContract]
          }
        }
      }
    }

    case UPDATE_SITE_IMAGE.success: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.site.id]: action.site
        }
      };
    }

    case DELETE_SITE.success: {
      const data: any = { ...state.data };
      delete data[action.siteId];

      return {
        ...state,
        data
      };
    }

    case FAVOURITE_SITE.success: {
      const data: Dictionary<Site> = state.data;

      const newData: any = {
        ...state.data,
        [action.siteId]: {
          ...data[action.siteId],
          favourite: action.favourited
        }
      };

      return {
        ...state,
        data: newData
      };
    }

    case SET_GROUP_SITE.reducer: {
      const newState = {
        ...state,
        data: mergeChildObjectsByKey(state.data, action.sitesGroup.data),
        recordmeta: {
          ...state.recordmeta,
          ...action.sitesGroup.meta,
        },
        contracts: {
          ...state.contracts,
          ...action.sitesGroup.contracts,
        }
      }
      const equal = isStateEqual(state, newState);
      if (!equal) {
        return newState;
      }
      return state;
    }

    default:
      return state;
  }
};

export default sitesReducer;
