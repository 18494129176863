import React, { useState, useEffect, memo, useRef } from 'react';
import DataGrid, { Column, Editing, TotalItem, Summary, Paging } from 'devextreme-react/data-grid';
import { useSelector } from 'react-redux';
import { Paper } from '@material-ui/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from "devextreme/data/custom_store";
import { TreeList, Column as TreeListColumn } from 'devextreme-react/tree-list';

// Own
import CommonAPIService from "components/ContractInFocus/Services/commonAPI.services";
import {
  DataGridMeta,
} from "components/ContractInFocus/Interfaces/DataGridColumn.interface";
import { SpendRawElement, SpendRawList, SpendResponse } from '../../Interfaces/spend.interfaces';
import { InlineWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { getSubTitle } from "components/ContractInFocus/Components/ContractPrintTitle/ContractPrintTitle";
import { extractSpendColumns } from '../../helpers/spend.helper';
import * as selectors from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import { currencyColumnFormat } from 'constants/grid.constants';
import { getLastXMonthsParams } from 'helpers/Pipelines/contractScopeOperator';
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { gridMetaInitialState } from "components/ContractInFocus/Models/Grid";
import { PrintChartAndTableLabels } from "components/Common/constants.js";
import Header from 'components/Common/Components/GridHeader/GridHeader';
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { portfolioInFocusPeriod } from 'components/PortfolioInFocus/Selectors/portfolioInFocus.selectors';
import { ContractPeriodDates } from "components/ContractInFocus/interfaces/contractInFocusActions.interfaces";
import { ContractPeriod } from 'components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface';
import { SHORT_MONTHS } from "components/Common/Utils/Dates.js";
import { sortByContactRef } from 'components/ContractInFocus/Maintenance/Helper/TablePolyfill.helpers';
import './SpendStyles.scss';

interface SpendAggregateProps {
  includeMarkup: boolean;
  id?: string;
  //contract: ContractInterface;
  contract: SiteContract;
  portfolio?: HydratedPortfolio;
  refreshSpendAt?: number;
  dataTestId?: string;
  aggregateType: 'consumable' | 'comprehensive' | 'managed' | 'combined-consumable' | 'combined-comprehensive' | 'combined-managed';
  title: string;
}

const extractAmount = (value: string) => Number(value.split(":")[1].replace(/[^0-9$.-]/g, ''));

const extractSpendTotal = (field: string, records: SpendRawList) => {
  let amount = 0;
  records.map((r) => {
    const value = r[field]
    if (value) {
      let figure = extractAmount(value); // note the final '-' is a literal dash - to preserve any negative amounts
      amount += figure;
    }
  })
  //const total_string = `£${amount.toFixed(2).toLocaleString()}`
  const total_format = new Intl.NumberFormat('en-UK', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  const total_string = total_format.format(amount); // ‘$123,456.79’
  return total_string
}

function getTotalSpendForCol(options: any) {
  // component	DataGrid	The widget's instance.
  // groupIndex	Number	A zero-based group level. Available only when calculating group summary items.
  // name	String	The summary item's name.
  // summaryProcess	String	Indicates the stage of the summary item calculation; equals "start", "calculate" or "finalize".
  // totalValue	any	The resulting summary item's value.
  // value	any	If the custom summary item is calculated by a column, this field contains the value from a cell of this column. Otherwise, it contains a whole object from the data source. 
  switch (options.summaryProcess) {
    case "start":
      // Initializing "totalValue" here
      options.totalValue = 0.00;
      break;
    case "calculate": {
      let amount = extractAmount(options.value);
      options.totalValue = options.totalValue + amount;
      break;
    }
    case "finalize":
      // Assigning the final value to "totalValue" here
      return options.totalValue
    //break;
  }
}

const SpendAggregate: React.FC<SpendAggregateProps> = ({
  id = 'spendAggregate',
  includeMarkup,
  contract,
  portfolio,
  refreshSpendAt,
  dataTestId,
  aggregateType,
  title
}) => {

  const selectFrozenFor = useSelector(selectors.contractInFocusFrozenForSelector);
  const selectFocusedContractPeriod = useSelector(selectors.contractInFocusFocusedContractPeriodSelector);
  const selectedPortfolioPeriod = useSelector(portfolioInFocusPeriod);
  let selectedPeriod: ContractPeriod | ContractPeriodDates | undefined;
  if (portfolio) {
    selectedPeriod = selectedPortfolioPeriod;
  } else {
    selectedPeriod = selectFocusedContractPeriod;
  }


  const [dataSource, setDataSource] = useState<DataSource>();
  const [rawRecords, setRawRecords] = useState<SpendRawList>([]);
  const [dataForTotals, setDataForTotals] = useState<SpendRawList>([]);
  const [grandTotals, setGrandTotals] = useState<any>();
  const [gridColumns, setGridColumns] = useState<string[]>([]);
  const [metadata, setMetadata] = useState<DataGridMeta>(gridMetaInitialState);

  const lowerCaseTitle = title.toLocaleLowerCase();

  useEffect(() => {
    if ((contract || portfolio) && selectedPeriod) {
      let getEndpoint;
      if (portfolio) {
        getEndpoint = (portfolioId: any) => `portfolios/${portfolioId}/${aggregateType}-monthly-aggregate-spend/?format=json${includeMarkup ? '&markup=1' : ''}`;

      } else {
        getEndpoint = (contractRef: any) => `contracts/${contractRef}/${aggregateType}-monthly-aggregate-spend/?format=json${includeMarkup ? '&markup=1' : ''}`;
      }
      CommonAPIService.fetchUnPaginated<SpendResponse>(
        getEndpoint,
        setMetadata,
        contract?.contract_ref || portfolio?.id,
        getLastXMonthsParams(48, selectedPeriod.start_date, selectedPeriod.end_date)
      ).then(({ data }: SpendResponse) => {
        let dataForTotals = data;
        let dataForGrid = data;
        if (portfolio) {
          dataForTotals = data.filter(x => SHORT_MONTHS.includes(x.header_ref || '')).map(x => {
            const { header_pointer, header_ref, contract_ref, ...rest } = x;
            return rest
          })
          setDataForTotals(dataForTotals);
          dataForGrid.sort(sortByContactRef);
          dataForGrid = data.map(x => {
            const { ...rest } = x;
            return rest
          })
        }
        const headers = extractSpendColumns(dataForTotals);

        setGridColumns(headers);
        if (portfolio) {
          const grandTotals: any = {};
          headers.map(header => {
            grandTotals[header] = extractSpendTotal(header, dataForTotals);
            grandTotals['header_ref'] = 'grand_total';
            grandTotals['header_pointer'] = null;
            grandTotals['contract_ref'] = "Grand Total";
          })
          dataForGrid = [...dataForGrid, ...[grandTotals]]
          setGrandTotals([grandTotals]);
        }
        setRawRecords(data);
        const custom = new CustomStore({
          //key: "id",
          load: () => dataForGrid
        });

        setDataSource(
          new DataSource({
            store: custom
          })
        );

        return { data: dataForGrid };
      })

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshSpendAt, includeMarkup]);

  return (
    <InlineWrapper
      id={id}
      pageBreakAfter={true}
      data-testid={dataTestId}
    >
      <Paper elevation={3}>

        <>
          <Header
            testId='aggregate-title'
            title={`${title} Table`}
            subTitle={getSubTitle(metadata)}
            className={`${PrintChartAndTableLabels ? '' : 'no-print'}`}
          //subTitle={`Your ${lowerCaseTitle} table for this contract ${selectFrozenFor}`}
          />
        </>

        {dataSource && portfolio && !contract &&
          <TreeList
            className="no-print spend-aggregate"
            dataSource={dataSource}
            rowAlternationEnabled={true}
            //className={`${lowerCaseTitle}-spend-aggregate`}
            showBorders={true}
            keyExpr="header_ref"
            parentIdExpr="header_pointer"
            rootValue={null}
          >
            <Paging enabled={false} />
            <TreeListColumn
              key="contract_ref"
              caption="Contract"
              dataField="contract_ref"
              alignment="left"
            >

            </TreeListColumn>
            {
              gridColumns.map((column, index) => {
                return <TreeListColumn
                  key={`${column}-${index}`}
                  caption={column}
                  format={currencyColumnFormat}
                  dataField={column}
                  alignment="right"
                  allowSorting={false}
                />
              }
              )
            }
          </TreeList>
        }
        {dataSource && !portfolio && contract &&
          <DataGrid
            className="no-print spend-aggregate"
            dataSource={dataSource}
            rowAlternationEnabled={true}
            //className={`${lowerCaseTitle}-spend-aggregate`}
            showBorders={true}
          >
            <Paging enabled={false} />
            <Editing
              mode='cell'
              allowUpdating={false}
              allowDeleting={false}
              allowAdding={false}
            />

            {
              gridColumns.map((column, index) => {
                //console.log('column: ', column, ', index: ', index);
                return <Column
                  key={`${column}-${index}`}
                  caption={column}
                  format={currencyColumnFormat}
                  dataField={column}
                  alignment="right"
                  allowSorting={false}
                />
              }
              )
            }

            <Summary
              calculateCustomSummary={getTotalSpendForCol}
            >
              {/* <TotalItem
                column='month'
                displayFormat={currencyColumnFormat}
              //displayFormat="Total"
              /> */}
              {
                gridColumns.map((column, index) =>
                  column !== "" ?
                    <TotalItem
                      column={column}
                      key={`${column}-${index}-total`}
                      summaryType="custom"
                      valueFormat={currencyColumnFormat}
                      displayFormat="{0}"
                    />
                    : null
                )}
            </Summary>

          </DataGrid>

        }
        <PrintableAggregateGrid gridColumns={gridColumns} records={portfolio ? dataForTotals : rawRecords}></PrintableAggregateGrid>
      </Paper>
    </InlineWrapper>
  );
};

interface PrintableSpendAggregateProps {
  gridColumns: string[],
  records?: SpendRawList,
  debug?: boolean
}



const PrintableAggregateGrid: React.FC<PrintableSpendAggregateProps> = (
  {
    gridColumns,
    records,
    debug = false
  }) => {
  const numColumns = gridColumns.length;
  const recordsRef = useRef(records);
  const width = `${100 / numColumns}%`
  useEffect(() => {
    recordsRef.current = records;
  }, [records]);
  return <>
    <table id="PrintDataGrid" style={{ width: "100%", borderCollapse: "collapse" }} className={`spend-aggregate-printable ${debug ? '' : 'no-screen'}`}>

      <thead>
        <tr>
          {gridColumns.map((field, index) => {
            return <td
              style={{ width: width, textAlign: "right", borderLeft: "1px solid white" }}
              key={`head${field}-${index}`}>
              {field}
            </td>
          })}
        </tr>
      </thead>
      <tbody className="printable-spend-aggregate-body">
        {recordsRef.current?.map((record: SpendRawElement, idx) => {
          const skipBottomBorder = recordsRef.current && idx == recordsRef.current.length - 1;
          const tdStyles: any = {
            width: width,
            textAlign: "right"
          }
          if (skipBottomBorder) {
            tdStyles.borderBottom = "None";
          }
          return <tr key={`${record.id}-${idx}`}>
            {gridColumns.map((field, colIndex) => {
              const amount = record[field]
              return <td
                style={tdStyles}
                key={`${record.id}-${field}-${idx}-${colIndex}`}>
                {amount}
              </td>
            }
            )}
          </tr>
        }
        )}
        <tr style={{ textAlign: "right" }}>
          {
            gridColumns.map((field, colIndex) => {
              return <td
                style={{ width: width, fontWeight: 600, borderTop: "1px solid black", borderBottom: "1px solid black" }}
                key={`Total-${field}-${colIndex}`}
              >
                {records && field && extractSpendTotal(field, records)}
              </td>
            }
            )}
        </tr>
      </tbody>
    </table>
  </>

}



export default memo(SpendAggregate);
