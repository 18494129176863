import { FrozenHistoryBranch } from "components/ContractInFocus/interfaces/contractInFocus.interfaces";
import { IDState, IDsState } from "../../Common/Interfaces/Entity.interface";

import { initialEntityState, initialIDsState, initialIDState, initialEntityListState } from "../../Common/Models/Entity.models";
import { MonthlyMaintenanceReports } from "interfaces/contract.interfaces";
import { ReactiveCategoryOptionsState } from 'components/ContractInFocus/Interfaces/ContractInFocusState.interface';
import * as portfolioActions from "components/PortfolioInFocus/Actions/portfolioInFocus.actions";
import { ContractPeriodDates } from "components/ContractInFocus/Interfaces/contractInFocusActions.interfaces";
import { ContractPeriod, PortfolioPeriod } from "components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface";
import { apiPrivilegesInitalState } from "services/models/apiprivileges";
import { getMonthsBetween } from "components/Common/Utils/Dates.js";


export type PortfolioInFocusState = {
    portfolio: IDState;
    distribution: IDsState;
    monthlyMaintenanceReports: MonthlyMaintenanceReports;
    reactiveCategoryOptions: ReactiveCategoryOptionsState;
    selectedPeriod?: ContractPeriod;
    frozenHistory: FrozenHistoryBranch;
};

const initialState = {
    reactiveCategoryOptions: initialEntityState,
    portfolio: initialEntityState,
    distribution: initialIDsState,
    monthlyMaintenanceReports: initialEntityListState,
    frozenHistory: {
        ...initialEntityState,
        data: {},
        focus: undefined,
        permissions: apiPrivilegesInitalState
    },
    selectedPeriod: undefined
};

const portfolioInFocusReducer = (state: PortfolioInFocusState = initialState, { type, payload }: { type: string, payload: any }) => {
    switch (type) {

        case portfolioActions.SET_SELECTED_PORTFOLIO.success: {
            return {
                ...state,
                ...payload,
            };
        }

        case portfolioActions.SET_SELECTED_PORTFOLIO_PERIOD.reducer: {
            return {
                ...state,
                selectedPeriod: payload
            };
        }

        case portfolioActions.CLEAR_PORTFOLIO_IN_FOCUS.reducer: {
            return initialState
        }

        case portfolioActions.SWITCH_PORTFOLIO_IN_FOCUS.reducer: {
            return {
                ...initialState,
                portfolio: {
                    id: payload.portfolioId
                },
            }
            //return initialState
        }

        case portfolioActions.FETCH_PORTFOLIO_SNAPSHOTS.success: {
            return {
                ...state,
                frozenHistory: {
                    ...payload.snapshotHistory,
                    focus: payload.focusedSnapshot ?? state.frozenHistory.focus
                },
            };
        }

        case portfolioActions.PORTFOLIO_IN_FOCUS_SET_SELECTED_SNAPSHOT.reducer: {
            return {
                ...state,
                frozenHistory: {
                    ...state.frozenHistory,
                    focus: payload.selectedSnapshot?.id
                },
            };
        }

        case portfolioActions.INSERT_PORTFOLIO_SNAPSHOT.reducer: {
            return {
                ...state,
                frozenHistory: {
                    ...state.frozenHistory,
                    data: {
                        ...state.frozenHistory.data,
                        [payload.snapshot.id]: payload.snapshot
                    }
                },
            };
        }

        case portfolioActions.RETRIEVE_PORTFOLIO_IN_FOCUS_FROM_CACHE.reducer: {
            return {
                ...initialState,
                ...payload.portfolioInFocus
            }
        }


        case portfolioActions.PORTFOLIO_GET_MONTHLY_MAINTENANCE_REPORTS.success:
            return {
                ...state,
                monthlyMaintenanceReports: { ...payload.monthlyMaintenanceReports }
            }

        default:
            return state;
    }
};

export default portfolioInFocusReducer;
