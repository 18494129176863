import { filter, switchMap, map, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of, forkJoin } from 'rxjs';

// Own
import { getData, getMeta, getListData, getListMeta, unWrapListDataAndMeta, unWrapDataAndMeta } from '../../../../services/API/API.helper';
import { APIR, getMultipartConfig } from '../../../../services/API/API';
import { mode } from '../../../Common/Consts/DataMode';
import { adminPanelType } from '../../Models/AdminPanel.model';
import { addDataSubItem } from '../../../../store/actions/branchSublist.actions';
import { contractKey } from 'components/AdminPanel/Contracts/Interfaces/Contract.interface';
import { fetchJobTitles } from "components/AdminPanel/People/Services/peopleServices";
import { NOTIFICATION_ERROR } from 'components/Notification/Constants/constants';

import * as fromNotificationActions from 'components/Notification/Actions/Notification.actions';
import * as fromAdminPanelActions from '../../Actions/adminPanel.actions';
import * as fromPeopleActions from '../Actions/People.actions';
import * as fromRootActions from "../../../../store/actions/root.actions";

const ROUTE = '/people/';

export const fetchPeopleEpic = (action$) =>
  action$.pipe(
    ofType(fromPeopleActions.FETCH_PEOPLE.start),
    switchMap(() =>
      APIR.get(`${ROUTE}?format=json`).pipe(
        unWrapListDataAndMeta(),
        map(({ jobTitle, ...people }) => fromPeopleActions.fetchPeopleSuccess(people)), // HT Note, I don't think jobTitle here corresponds to any value;
        catchError((error) => of(fromNotificationActions.addNotification({ message: error, type: NOTIFICATION_ERROR })))
      )
    )
  );

export const updatePersonEpic = (action$) =>
  action$.pipe(
    ofType(fromPeopleActions.UPDATE_PERSON.start),
    map((action) => {
      const { panelId, id, password, avatar, ...personData } = action.person;
      const updatePerson = password && (typeof password === 'string') ? { ...personData, password } : personData;
      return { panelId, id, updatePerson };
    }),
    switchMap(({ panelId, id, updatePerson }) =>
      APIR.patch(`${ROUTE}${id}/?format=json`, updatePerson).pipe(
        map((response) => ({ data: getData(response) })),
        map((person) => fromPeopleActions.updatePersonSuccess({ ...person })),
        catchError((error) => of(fromAdminPanelActions.setApiErrors(panelId, error))),
      ),
    ),
  );

export const updatePersonSuccessEpic = (action$) => action$.pipe(
  ofType(fromPeopleActions.UPDATE_PERSON.success),
  map(() => fromPeopleActions.fetchPeople()),
);

export const updatePersonAvatarEpic = (action$) =>
  action$.pipe(
    ofType(fromPeopleActions.UPDATE_AVATAR.start),
    map((action) => {
      const fd = new FormData();
      fd.append('avatar', action.avatar[0]);
      return { ...action, fd };
    }),
    switchMap(({ id, fd }) =>
      APIR.patch(`${ROUTE}${id}/`, fd, getMultipartConfig()).pipe(
        map((response) => getData(response)),
        map(person => fromRootActions.setBranchField(adminPanelType.people, id, 'avatar', person.avatar)),
        catchError((error) => of(fromAdminPanelActions.setApiErrors(error)))
      ),
    )
  );

export const deletePersonEpic = (action$) => action$.pipe(
  ofType(fromPeopleActions.DELETE_PERSON.start),
  switchMap(({ person, panelId }) =>
    APIR.delete(`${ROUTE}${person.id}`).pipe(
      switchMap(() => [
        fromPeopleActions.deletePersonSuccess(person.id),
        fromAdminPanelActions.setMode(panelId, mode.list)
      ]),
      catchError((error) => of(fromAdminPanelActions.setApiErrors(panelId, error)))
    ),
  ));

export const deletePersonSuccessEpic = (action$) => action$.pipe(
  ofType(fromPeopleActions.DELETE_PERSON.success),
  map(() => fromPeopleActions.fetchPeople()),
);

export const createPersonEpic = (action$) =>
  action$.pipe(
    ofType(fromPeopleActions.CREATE_PERSON.start),
    map(({ panelId, person, link, linkId }) => {
      const { id, avatar, ...personOtherFields } = person;
      return { recordId: id, panelId, person: { ...personOtherFields }, link, linkId };
    }),
    switchMap(({ recordId, panelId, person }) =>
      APIR.post(`${ROUTE}`, { ...person }).pipe(
        unWrapDataAndMeta(),
        map(({ data, meta }) => fromPeopleActions.createPersonSuccess(panelId, recordId, data, meta)),
        catchError((error) => of(fromAdminPanelActions.setApiErrors(panelId, error)))
      )
    ),
  );

export const createPersonSuccessEpic = (action$) =>
  action$.pipe(
    ofType(fromPeopleActions.CREATE_PERSON.success),
    switchMap(({ panelId, recordId, data, meta }) => [
      fromRootActions.replaceEntityById(adminPanelType.people, recordId, panelId, data, meta),
      fromAdminPanelActions.setMode(panelId, mode.view),
      fromPeopleActions.fetchPeople()
    ]),
  );

export const linkPersonToOrganisationEpic = (action$) =>
  action$.pipe(
    ofType(fromPeopleActions.LINK_PERSON_TO_ORGANISATION.start),
    switchMap(({ panelId, organisationId, person }) =>
      APIR.patch(`${ROUTE}${person.id}/?format=json`, { organisation: organisationId }).pipe(
        map((response) => getData(response)),
        map((data) =>
          addDataSubItem(adminPanelType.organisations, 'organisation_people', organisationId, data),
        ),
      )
    ),
    catchError((error) => {
      console.log(error);
    }),
  );

export const unlinkPersonToOrganisationEpic = (action$) =>
  action$.pipe(
    ofType(fromPeopleActions.UNLINK_PERSON_TO_ORGANISATION.start),
    switchMap(({ panelId, person }) => {
      const { id, avatar, organisation, ...otherPersonFields } = person;
      return APIR.patch(`${ROUTE}${person.id}/?format=json`, otherPersonFields).pipe(
        map(() =>
          fromAdminPanelActions.deleteSubItemRecord(panelId, 'organisation_people', person.id),
        ),
      )
    }
    ),
    catchError((error) => {
      return of(fromPeopleActions.unlinkPersonToOrganisationError(error));
    }),
  );


export const refreshPersonMetaEpic = (action$) =>
  action$.pipe(
    ofType(fromPeopleActions.REFRESH_PERSON_META.start),
    switchMap(() => {
      return APIR.get(`${ROUTE}?format=json`).pipe(
        unWrapDataAndMeta(),
        map(({ meta }) => fromPeopleActions.refreshPersonMetaSuccess(meta))
      )
    }),
    catchError((error) => {
      return of(fromPeopleActions.refreshPersonMetaError(error));
    }),
  );

export default [
  fetchPeopleEpic,
  // fetchAndOpenEpic,
  updatePersonEpic,
  updatePersonSuccessEpic,
  updatePersonAvatarEpic,
  deletePersonEpic,
  deletePersonSuccessEpic,
  createPersonEpic,
  createPersonSuccessEpic,
  linkPersonToOrganisationEpic,
  unlinkPersonToOrganisationEpic,
  refreshPersonMetaEpic
];
