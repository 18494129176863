import { ofType, StateObservable } from "redux-observable";
import { from, of } from "rxjs";
import { switchMap, catchError, map, filter, delay, tap } from "rxjs/operators";

// Own
import {
    getPortfolioMonthlyMaintenanceReportsSuccess,
    getPortfolioMonthlyMaintenanceReportsErrors
} from "components/PortfolioInFocus/Actions/portfolioInFocus.actions";
import { PORTFOLIO_GET_MONTHLY_MAINTENANCE_REPORTS } from "components/PortfolioInFocus/Actions/portfolioInFocus.actions";
import { getPortfolioFromState } from './portfolioInFocus.epic.helper';
import { monthlyMaintenanceReportsApi } from 'services/API/common/contractAPIs';

import { getListData, getListMeta, arrayToDict, unWrapDataAndMeta, unWrapDataAndMetaAndPUTMeta } from 'services/API/API.helper';
import { NOTIFICATION_ERROR } from 'components/Notification/Constants/constants';
import { State } from "store/interfaces/State";

import * as portfoliosActionsInterface from "components/PortfolioInFocus/Interfaces/portfolioInFocusActions.interfaces";
import * as fromNotificationActions from 'components/Notification/Actions/Notification.actions';
import * as contractAPIs from "services/API/common/contractAPIs";
import * as contractPeriodAPIs from "services/API/common/contractPeriodAPIs";
import * as fromPortfolioInFocus from "../Actions/portfolioInFocus.actions";

export const fetchPortfolioMaintenanceReportDocumentsEpic = (action$: any) => action$.pipe(
    ofType(PORTFOLIO_GET_MONTHLY_MAINTENANCE_REPORTS.start),
    switchMap(({ payload }) => {
        const { portfolioId } = payload;
        return monthlyMaintenanceReportsApi({ portfolioId: portfolioId }).pipe(
            map((response: any) => getPortfolioMonthlyMaintenanceReportsSuccess(response)),
            catchError((error) => of(getPortfolioMonthlyMaintenanceReportsErrors(error)))
        )
    })
);

const fetchPortfolioPeriodsEpic = (action$: any, state$: StateObservable<State>) => action$.pipe(
    ofType(fromPortfolioInFocus.PORTFOLIO_IN_FOCUS_FETCH_PORTFOLIO_PERIODS.start),
    filter(({ payload }) => payload.id === getPortfolioFromState(state$).id),
    switchMap(({ payload }) =>
        contractAPIs.portfolioPeriodsAPI(payload.id).pipe(
            map((response) => ({
                data: getListData(response),
                meta: getListMeta(response),
            })),
            map((portfolioPeriods) => fromPortfolioInFocus.fetchPortfolioPeriodsSuccess(portfolioPeriods)),
        )));

const setSelectedPortfolioPeriodEpic = (action$: any) => action$.pipe(
    ofType(fromPortfolioInFocus.SET_SELECTED_PORTFOLIO_PERIOD.reducer),
    map(({ payload }: any) => {
        return fromPortfolioInFocus.fetchPortfolioSnapshots({ portfolioPeriod: payload })
    }

    ),
    catchError((error) => of(fromNotificationActions.addNotification({ message: error, type: NOTIFICATION_ERROR })))
);

const fetchPortfolioSnapshotHistoryEpic = (action$: any, state$: StateObservable<State>) => action$.pipe(
    ofType(fromPortfolioInFocus.FETCH_PORTFOLIO_SNAPSHOTS.start),
    filter(({ payload }) => !!payload.portfolioPeriod),
    switchMap(({ payload }: { payload: portfoliosActionsInterface.FetchPortfolioSnapshotAction }) => {
        return contractPeriodAPIs.portfolioPeriodFrozenForListApi(payload.portfolioPeriod.id).pipe(
            map((response) => {
                return fromPortfolioInFocus.fetchPortfolioSnapshotSuccess({
                    snapshotHistory: { ...response, data: arrayToDict(response.data) },
                    focusedSnapshot: payload.focusedSnapshot?.id,
                    fetchSnapshotCallback: payload.fetchSnapshotCallback
                });
            })
        )
    }),
    catchError((error) => of(fromNotificationActions.addNotification({ message: error, type: NOTIFICATION_ERROR })))
);

// Once the fetch snapshot history fetch is complete successfully, call this result
const fetchSnapshotHistorySuccessEpic = (action$: any, state$: StateObservable<State>) => action$.pipe(
    ofType(fromPortfolioInFocus.FETCH_PORTFOLIO_SNAPSHOTS.success),
    // NB currently it seems that generally fetchSnapshotCallback is not defined...
    filter(({ payload }) => !!payload.fetchSnapshotCallback),
    // delay the action so that the reducer can get ahead and update the state
    delay(250),
    tap(({ payload }: { payload: portfoliosActionsInterface.FetchPortfolioSnapshotSuccessAction }) => {
        // execute the callback action e.g. to navigatge the snapshot
        payload.fetchSnapshotCallback && payload.fetchSnapshotCallback();
    })
);

export default [
    fetchPortfolioMaintenanceReportDocumentsEpic,
    fetchPortfolioPeriodsEpic,
    fetchPortfolioSnapshotHistoryEpic,
    setSelectedPortfolioPeriodEpic,
    fetchSnapshotHistorySuccessEpic
];