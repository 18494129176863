import {
    ADD_CONTRACT_PEOPLE_WITH_ACCESS,
    UPDATE_CONTRACT_PEOPLE_WITH_ACCESS,
    REMOVE_CONTRACT_PEOPLE_WITH_ACCESS
} from "components/AdminPanel/Contracts/Actions/Contracts.actions";
import { arrayToDict } from "services/API/API.helper";

import { APIPrivileges } from "services/Interface/Interface";
import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";

import { PersonWithAccessI, ContractPeopleWithAccessState } from "components/AdminPanel/Contracts/Interfaces/Contract.interface";

import { isEqual } from "lodash";

const initialState = {
    metaWrapper: {
        meta: {},
        putMeta: {},
    }
}

interface peopleWithAccessAction {
    contractId: number;
    peopleWithAccess?: PersonWithAccessI[];
    accessObjId?: string | number;
    meta?: FieldMetaGroup;
    putMeta?: FieldMetaGroup;
    permissions?: APIPrivileges;
    [otherOptions: string]: unknown;
}

// function getMappings(existingList: PersonWithAccessI[] | undefined, newList: PersonWithAccessI[] | undefined) {
//     const eL = existingList || [];
//     const nL = newList || [];
//     const existingData = <any>{};
//     const newData = <any>{};
//     eL.map((record: PersonWithAccessI) => {
//         existingData[record.id] = record
//     })
//     nL.map((record: PersonWithAccessI) => {
//         newData[record.id] = record
//     })
//     return [eL, nL];
// }

function getMappings(existingList: PersonWithAccessI[] | undefined, newList: PersonWithAccessI[] | undefined) {
    const eL = existingList || [];
    const nL = newList || [];
    const existingData = arrayToDict(eL);
    const newData = arrayToDict(nL);
    return [existingData, newData];
}

function addOrUpdateRecords(existingList: PersonWithAccessI[] | undefined, newList: PersonWithAccessI[] | undefined) {
    const [eD, nD] = getMappings(existingList, newList);
    const compoundList = Object.values({ ...eD, ...nD })
    return compoundList;
}

function fetchRecords(existingList: PersonWithAccessI[] | undefined, newList: PersonWithAccessI[] | undefined) {
    const [eL, nL] = getMappings(existingList, newList);
    return Object.values({ ...nL });
}

export const contractPeopleWithAccessReducer = (state: ContractPeopleWithAccessState = initialState, action: peopleWithAccessAction) => {
    switch (action.type) {
        case ADD_CONTRACT_PEOPLE_WITH_ACCESS.reducer: {
            const contractId = action.contractId;
            const newSet = fetchRecords(state[contractId]?.data, action.peopleWithAccess)
            // Right now the rights to manage contract links do not vary by contract relation to the current user, but only by admin status.  
            // Therefore we will store the meta, putMeta and permissions on the 'root' state of this reduceer slice, rather than within each contract.  
            // However, should we ever want to allow the assignment of permissions 
            // to others based on the logged in user's relationship to a specific contract, then obviously we will have to move the permissions inside the contract lookup
            // structure and have the code that checks the permissions look them up on the relevant contract instead..
            const stateMetaWrapper = state.metaWrapper;
            let metaWrapper = undefined;
            const newMetaWrapper = {
                meta: {
                    ...action.meta,
                },
                putMeta: {
                    ...action.putMeta,
                },
                permissions: {
                    ...action.permissions
                }
            }
            if (!isEqual(stateMetaWrapper, newMetaWrapper)) {
                metaWrapper = newMetaWrapper
            } else {
                metaWrapper = stateMetaWrapper
            }
            return {
                ...state,
                [action.contractId]: {
                    data: newSet,
                },
                metaWrapper: metaWrapper
            };
        }
        case UPDATE_CONTRACT_PEOPLE_WITH_ACCESS.reducer: {
            const contractId = action.contractId;
            const newSet = addOrUpdateRecords(state[contractId]?.data, action.peopleWithAccess)
            return {
                ...state,
                [action.contractId]: {
                    data: newSet,
                }
            };
        }
        case REMOVE_CONTRACT_PEOPLE_WITH_ACCESS.reducer: {
            const accessList = state[action.contractId]?.data || [];
            const newList = accessList.filter(accessObj => accessObj.id.toString() !== action.accessObjId?.toString())
            return {
                ...state,
                [action.contractId]: {
                    data: newList,
                }
            };
        }

        default:
            return state;
    }
};

export default contractPeopleWithAccessReducer;