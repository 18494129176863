import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import { SpinnerWrapper } from "components/Common/Components/Input/TextFocusInputStyles.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

import "./confirmationModalStyles.scss";

const ConfirmationModal = ({
  id = "confirmationModal",
  isOpen,
  title,
  body,
  error,
  size = "md",
  okCaption,
  color = "primary",
  onConfirmation,
  onCancel,
  cancelCaption = "Cancel",
  children,
  onClosed,
  disabled = false,
  style = undefined,
  bodyStyles = undefined,
  actionInProgress = false
}) => {
  return (
    <Modal
      id={id}
      isOpen={isOpen}
      size={size}
      centered={true}
      zIndex={5000}
      onClosed={onClosed}
      style={style}
    >
      <ModalHeader>{title}</ModalHeader>
      <ModalBody style={bodyStyles}>
        {error}
        {body && <div>{body}</div>}
        {children}
      </ModalBody>
      <ModalFooter>
        {!actionInProgress ? (
          <>
            <Button color={color} onClick={onConfirmation} disabled={disabled}>
              {okCaption}
            </Button>{" "}
            <Button color="secondary" onClick={onCancel}>
              {cancelCaption}
            </Button>
          </>
        ) : (
          <>
            {/* <SpinnerWrapper>
            <FontAwesomeIcon icon={faSync} spin className="confirmationModalSpinner" />
          </SpinnerWrapper> */}
            <FontAwesomeIcon
              icon={faSync}
              spin
              className="confirmationModalSpinner"
            />
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  id: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  okCaption: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  error: PropTypes.string,
  onConfirmation: PropTypes.func,
  onClosed: PropTypes.func,
  onCancel: PropTypes.func,
  cancelCaption: PropTypes.string,
  children: PropTypes.any
};

export default ConfirmationModal;
