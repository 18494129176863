import { tap } from 'rxjs/operators';
import { store } from "store/store";

// Own
import API, { APIN, APIR, getMultipartConfig } from "services/API/API";
import { ParseAPIResponse } from "services/Interface/Interface";
import { HAS_TASKS_ROUTE, ADMIN_HAS_TASKS_ROUTE } from "services/API/common/globalAPIs";
import { HASStatutoryTemplateDoc, HASTask, HASTaskWithID, HASTaskCheck, CreateHASTaskContractLink, CreateHASTaskCheck, HASContractTaskPersonalSettings } from "components/AdminPanel/HAS/Interfaces/HASTaskInterfaces";
import { getCount, removeReadOnlyFields, getDataAndMeta, getDataAndMetaAndPUTMeta, getPreFlightListMeta, preFlightCanCreateCheck, unWrapDataAndMeta, getData, getPrivileges, getListData, getListMeta } from "services/API/API.helper";
import { FieldMetaGroup, EntityState, PreFlightListInfo, Primitive, Dictionary } from 'components/Common/Interfaces/Entity.interface';
import { onResponseError } from "services/API/API.interceptor.js";
import * as fromRootActions from "store/actions/root.actions";
import { adminPanelType } from "components/AdminPanel/Models/AdminPanel.model";
import { ContractIdOrPortfolioId } from "services/API/common/contractAPIs";
import { ContractInterface } from "components/AdminPanel/Contracts/Interfaces/Contract.interface";
import { addNotification } from 'components/Notification/Actions/Notification.actions';
import { NOTIFICATION_SUCCESS } from 'components/Notification/Constants/constants';
import { CONTRACT_ROUTE, PORTFOLIO_ROUTE } from "services/API/common/globalAPIs";

const BASE_CONTRACT_ROUTE = CONTRACT_ROUTE;//'contracts/';
const BASE_CHECKS_ROUTE = 'hastask-checks/';
const BASE_PORTFOLIO_ROUTE = PORTFOLIO_ROUTE;
const BASE_H_A_S_CONTRACTTASK_ROUTE = 'h-a-s-contracttasks/';

export function getHASTaskRoute(taskId?: string) {
    let route = HAS_TASKS_ROUTE;
    if (taskId) {
        route = `${route}${taskId}/`;
    }
    return route;
}

export function getAdminHASTaskRoute(taskId?: string) {
    let route = ADMIN_HAS_TASKS_ROUTE;
    if (taskId) {
        route = `${route}${taskId}/`;
    }
    return route;
}

export function getContractTaskNestedHASTaskRoute(contractTaskId: string, taskId?: string) {
    let route = BASE_H_A_S_CONTRACTTASK_ROUTE + contractTaskId + '/' + HAS_TASKS_ROUTE;
    if (taskId) {
        route = `${route}${taskId}/`;
    }
    return route;
}

export function getContractTaskNestedStatutoryTemplateDocumentRoute(contractTaskId: string, statutoryTemplateDocumentId?: string) {
    let route = BASE_H_A_S_CONTRACTTASK_ROUTE + contractTaskId + '/statutory-template-documents/';
    if (statutoryTemplateDocumentId) {
        route = `${route}${statutoryTemplateDocumentId}/`;
    }
    return route;
}

export function getStatutoryTemplateDocumentRoute(taskId: string, contractTaskId?: string) {
    if (contractTaskId) {
        return getContractTaskNestedStatutoryTemplateDocumentRoute(contractTaskId);
    }
    return `${getHASTaskRoute(taskId)}statutory-template-documents/`;
}

export const getHASTaskChecksRoute = (contract_ref?: string | number) => {
    // where we need to operate in the context of a portfolio, we add the portfolio to the query params/payload body
    let route = "/get-hastask-checks/never/";
    if (contract_ref) {
        route = `${BASE_CONTRACT_ROUTE}${contract_ref}/${BASE_CHECKS_ROUTE}`;
    }
    return route;
}

interface SimpleUpdateHASTaskProps {
    id: string;
    payload: Partial<HASTask>;
    meta: FieldMetaGroup;
}

export const simpleUpdateHASTask = (props: SimpleUpdateHASTaskProps) => {
    const route = getHASTaskRoute(props.id);
    let payload = removeReadOnlyFields(props.payload, props.meta);
    return API.patch(route, payload).then((response) => {
        return getDataAndMeta(response);
    });
}

interface SimpleFetchHASTaskProps {
    contractRef: string;
    hasTaskId?: string;
}

export const simpleFetchHASTask = (props: SimpleFetchHASTaskProps) => {
    const route = getHASTaskRoute(props.hasTaskId);
    // a contract user should be verified against the contract
    return API.get(route).then((response) => {
        return getDataAndMetaAndPUTMeta(response);
    });
}

interface SimpleFetchHASContractTaskNestedHASTaskProps {
    contractTaskId: string;
    hasTaskId?: string;
}

export const simpleFetchHASContractTaskNestedHASTask = (props: SimpleFetchHASContractTaskNestedHASTaskProps) => {
    const route = getContractTaskNestedHASTaskRoute(props.contractTaskId, props.hasTaskId);// we grab the task under it's contracttask because the permissions for a h a s admin can be verified against themselves, but the permissions for a 
    // a contract user should be verified against the contract via the contracttask link here
    return API.get(route).then((response) => {
        return getDataAndMetaAndPUTMeta(response);
    });
}


interface UpdateHASContractTaskNestedHASTaskProps {
    contractTaskId: string;
    hasTaskId?: string;
    payload: Partial<HASTask>;
    meta: FieldMetaGroup;
}

export const simpleUpdateHASContractTaskNestedHASTask = (props: UpdateHASContractTaskNestedHASTaskProps) => {
    const route = getContractTaskNestedHASTaskRoute(props.contractTaskId, props.hasTaskId);// we may need to the permissions for a 
    let payload = removeReadOnlyFields(props.payload, props.meta);
    return API.patch(route, payload).then((response) => {
        return getDataAndMeta(response);
    });
}

export const adminSimpleFetchHASTask = (props: SimpleFetchHASTaskProps) => {
    const route = getHASTaskRoute(props.hasTaskId);
    return API.get(route).then((response) => {
        return getDataAndMetaAndPUTMeta(response);
    });
}

interface simpleFetchContractBoundHASTasksProps {
    contractRef: string;
    hasTaskId?: string;
}

const getContractBoundHASTaskEndpoint = (contractRef: string | number) => `contracts/${contractRef}/hastasks-for-contract/`;

export const simpleFetchContractBoundHASTask = (props: simpleFetchContractBoundHASTasksProps) => {
    // this is to get the route for has tasks belonging to a particular contract.
    let route = getContractBoundHASTaskEndpoint(props.contractRef);
    if (props.hasTaskId) {
        route = `${route}${props.hasTaskId}/`;
    }
    return API.get(route).then((response) => {
        return getDataAndMetaAndPUTMeta(response);
    });
}
export interface HASCheckQueryParams {
    flat?: boolean;
    month?: string;
    year?: string | number;
    start_date?: string;
    end_date?: string;
    task?: string;
    contract_task?: string | number;
    exceptions_only?: number;
    with_checks_only?: number;
    global_only?: number;
    [idx: string]: Primitive;
}

export const createContractBoundHASTask = (contractRef: string, payload: any) => {
    // this is to get the route for has tasks belonging to a particular contract.
    let route = getContractBoundHASTaskEndpoint(contractRef);
    return API.post(route, payload).then((response) => {
        return getDataAndMetaAndPUTMeta(response);
    });
}

interface SimpleupdateContractBoundHASTaskProps extends SimpleUpdateHASTaskProps {
    contractRef: string;
}

export const updateContractBoundHASTask = (props: SimpleupdateContractBoundHASTaskProps) => {
    // this is to get the route for has tasks belonging to a particular contract.
    let route = getContractBoundHASTaskEndpoint(props.contractRef);
    route = `${route}${props.id}/`
    const payload = removeReadOnlyFields(props.payload, props.meta);
    return API.patch(route, payload).then((response) => {
        return getDataAndMetaAndPUTMeta(response);
    });
}

interface DeleteContractBoundHASTasksProps {
    contractRef: string;
    id: string;
}

export const deleteContractBoundHASTask = (props: DeleteContractBoundHASTasksProps) => {
    // this is to get the route for has tasks belonging to a particular contract.
    let route = getContractBoundHASTaskEndpoint(props.contractRef);
    route = `${route}${props.id}/`
    return API.delete(route).then((response) => {
        return getDataAndMetaAndPUTMeta(response);
    });
}

const addStatutoryTemplateDocument = (data: Partial<HASStatutoryTemplateDoc>, panelId?: string): Promise<EntityState<HASStatutoryTemplateDoc>> | any => {
    const { id, ...payload } = data
    const route = getStatutoryTemplateDocumentRoute(payload.task as string);
    return API.post(route, payload)
        .then((response) => {
            const dataAndMeta = getDataAndMeta(response)
            return () => false;
        })
}

const removeStatutoryTemplateDocument = (task_id: string, id: string): Promise<EntityState<HASStatutoryTemplateDoc>> | any => {
    const route = `${HAS_TASKS_ROUTE}/${task_id}/statutory-template-documents/${id}`;
    return API.delete(route)
        .then((response) => {
            const dataAndMeta = getDataAndMeta(response)
            return () => false;
        })
}

const updateStatutoryTemplateDocumentDetails = (data: Partial<HASStatutoryTemplateDoc>, panelId?: string): Promise<EntityState<HASStatutoryTemplateDoc>> | any => {
    const { id, ...payload } = data
    const route = `${HAS_TASKS_ROUTE}/${payload.task}/statutory-template-documents/${id}`;
    return API.patch(route, payload)
        .then((response) => {
            const dataAndMeta = getDataAndMeta(response)
            return () => false;
        })
}

export const getStatutoryTemplateDocumentsPreFlightInfo = (task: string): Promise<PreFlightListInfo> => {
    const route = getStatutoryTemplateDocumentRoute(task);
    return APIN.options(route).then((response) => {
        const meta = getPreFlightListMeta(response); //preflight uses the 'options' verb
        const canCreate = preFlightCanCreateCheck(response);
        return { meta, canCreate, canRead: true }
    }).catch(error => {
        if (error.response?.status == 403) {
            return { meta: {}, canCreate: false, canRead: false }
        } else {
            return onResponseError(error)
        }
    })
}

export const updateHASStatutoryTemplateDocumentFile = (task: string, id: number, doc: any, attribute: string) => {
    const getFileObject = () => {
        const fd = new FormData();
        fd.append(attribute, doc);
        return fd;
    }

    const route = getStatutoryTemplateDocumentRoute(task);

    return APIR.patch<ParseAPIResponse<HASStatutoryTemplateDoc>>(`${route}${id}/`, doc ? getFileObject() : { [attribute]: null }, doc ? getMultipartConfig() : {}).pipe(
        unWrapDataAndMeta(),
        tap(({ data }: { data: any }) =>
            store.dispatch(fromRootActions.setBranchField(adminPanelType.h_a_s, id, attribute, data[attribute]))
        )
    )
}

interface FetchStatutoryDocumentPreFlightInfoProps {
    taskId: string;
    contractTaskId?: string;
}

export const fetchStatutoryTemplateDocsPreFlightInfo = (props: FetchStatutoryDocumentPreFlightInfoProps): Promise<PreFlightListInfo> => {
    let route = getStatutoryTemplateDocumentRoute(props.taskId, props.contractTaskId);
    return APIN.options(route).then((response) => {
        const meta = getPreFlightListMeta(response); //preflight uses the 'options' verb
        const canCreate = preFlightCanCreateCheck(response);
        return { meta, canCreate, canRead: true }
    }).catch(error => {
        if (error.response?.status == 403) {
            return { meta: {}, canCreate: false, canRead: false }
        } else {
            return onResponseError(error)
        }
    });
}

export function getHASTaskContractsBaseRoute(hasTaskId?: string | number) { return `${HAS_TASKS_ROUTE}${hasTaskId}/hastask-contracts/` }
function getContractHASTasksBaseRoute(contractRef: string | number) { return `${BASE_CONTRACT_ROUTE}${contractRef}/h-a-s-contracttasks/` }

export const fetchHASTaskContractLinksPreFlightInfo = (hasTaskId: string | number): Promise<PreFlightListInfo> => {
    let route = getHASTaskContractsBaseRoute(hasTaskId);
    return APIN.options(route).then((response) => {
        const meta = getPreFlightListMeta(response); //preflight uses the 'options' verb
        const canCreate = preFlightCanCreateCheck(response);
        const privileges = getPrivileges(response);
        return { meta, canCreate, canRead: true, privileges: privileges }
    }).catch(error => {
        if (error.response?.status == 403) {
            return { meta: {}, canCreate: false, canRead: false }
        } else {
            return onResponseError(error)
        }
    });
}

export const fetchContractHASTasksLinksPreFlightInfo = (contractRef: string | number): Promise<PreFlightListInfo> => {
    let route = getContractHASTasksBaseRoute(contractRef);
    return APIN.options(route).then((response) => {
        const meta = getPreFlightListMeta(response); //preflight uses the 'options' verb
        const canCreate = preFlightCanCreateCheck(response);
        const privileges = getPrivileges(response);
        return { meta, canCreate, canRead: true, privileges: privileges }
    }).catch(error => {
        if (error.response?.status == 403) {
            return { meta: {}, canCreate: false, canRead: false }
        } else {
            return onResponseError(error)
        }
    });
}

const fetchHASTaskContractLinks = (hasTaskId: string | number) => {
    return API.get(getHASTaskContractsBaseRoute(hasTaskId)).then(
        (response: any) => {
            return getDataAndMetaAndPUTMeta(response);
        }
    )
};

// aka fetchContractTask
export const fetchContractHASTaskLinks = (contractRef: string | number, taskId?: string) => {
    let route = getContractHASTasksBaseRoute(contractRef);
    if (taskId) {
        route = route + taskId + '/';
    }
    return API.get(route).then(
        (response: any) => {
            return getDataAndMetaAndPUTMeta(response);
        }
    )
};


export const linkContractsToHASTask = (hasTask: HASTaskWithID, accessObjs: CreateHASTaskContractLink[]) => {
    return API.post(getHASTaskContractsBaseRoute(hasTask.id), accessObjs);
}

export const linkHASTasksToContract = (contract: ContractInterface, accessObjs: CreateHASTaskContractLink[]) => {
    return API.post(getContractHASTasksBaseRoute(contract.contract_ref), accessObjs);
}

const removeContractFromHASTask = (hasTask: HASTaskWithID, contractId: number | string) => {
    const baseRoute = getHASTaskContractsBaseRoute(hasTask.id);
    return API.delete(`${baseRoute}${contractId}`);
}

const removeHASTaskFromContract = (contract: ContractInterface, taskId: number | string) => {
    const baseRoute = getContractHASTasksBaseRoute(contract.contract_ref);
    return API.delete(`${baseRoute}${taskId}/`);
}

export const archiveContractFromHASTask = (hasTaskId: number | string, contractId?: number | string): Promise<any> => {
    const baseRoute = getHASTaskContractsBaseRoute(hasTaskId);
    const body = {
        archived: true
    }
    return API.patch(`${baseRoute}${contractId || "never"}/`, body);
}

export const unArchiveContractFromHASTask = (hasTaskId: number | string, contractId?: number | string): Promise<any> => {
    const baseRoute = getHASTaskContractsBaseRoute(hasTaskId);
    const body = {
        archived: false
    }
    return API.patch(`${baseRoute}${contractId || "never"}/`, body);
}


interface HASContractTaskCheckProps {
    contract_ref?: string;
    portfolioId?: string | number;
    query_params: HASCheckQueryParams;
    data?: Dictionary<Primitive>;

}

interface GetHASContractTaskProps extends HASContractTaskCheckProps {
    contractTaskId: string;
}

const fetchHASContractTasksWithMonthChecksRoute = (props: HASContractTaskCheckProps) => {
    let route;
    if (props.portfolioId) {
        route = `portfolios/${props.portfolioId}/hascontracttasks-with-month-checks/`
    } else if (props.contract_ref) {
        route = `contracts/${props.contract_ref}/hascontracttasks-with-month-checks/`
    } else {
        route = 'hascontracttasks-with-month-checks/'
    }
    return route;
}

const fetchHASContractTaskRoute = (props: GetHASContractTaskProps) => {
    let route = fetchHASContractTasksWithMonthChecksRoute(props)
    route = `${route}${props.contractTaskId + '/'}`;
    return route;
}

export const fetchHASContractTasksWithMonthChecks = (props: HASContractTaskCheckProps) => {
    const route = fetchHASContractTasksWithMonthChecksRoute(props);
    return API.get(route, { params: props.query_params }).then((response) => {
        const hasChecksList = {
            data: getListData(response),
            metadata: getListMeta(response),
            permissions: getPrivileges(response),
            totalCount: getCount(response)
        }
        return hasChecksList;
    });
}

export const fetchHASContractTask = (props: GetHASContractTaskProps) => {
    const route = fetchHASContractTaskRoute(props);
    return API.get(route).then(
        (response: any) => {
            return getDataAndMetaAndPUTMeta(response);
        }
    )
}

export const updateMultipleContractTasksNotificationSettings = (props: HASContractTaskCheckProps) => {
    let route = fetchHASContractTasksWithMonthChecksRoute(props);
    route = `${route}update_multiple_notification_settings/`
    return API.post(route, props.data, { params: props.query_params }).then((response) => {
        store.dispatch(addNotification({ message: "Done", type: NOTIFICATION_SUCCESS }))
    });
}

export const fetchHASContractTasksWithMonthChecksPreFlightInfo = (props: HASContractTaskCheckProps): Promise<PreFlightListInfo> => {
    let route;
    if (props.portfolioId) {
        route = `portfolios/${props.portfolioId}/hascontracttasks-with-month-checks/`
    } else if (props.contract_ref) {
        route = `contracts/${props.contract_ref}/hascontracttasks-with-month-checks/`
    }
    else { route = 'hascontracttasks-with-month-checks/' }
    return APIN.options(route).then((response) => {
        const meta = getPreFlightListMeta(response); //preflight uses the 'options' verb
        const canCreate = preFlightCanCreateCheck(response);
        return { meta, canCreate, canRead: true }
    }).catch(error => {
        if (error.response?.status == 403) {
            return { meta: {}, canCreate: false, canRead: false }
        } else {
            return onResponseError(error)
        }
    })
}

interface CreateContractTaskCheckProps extends ContractIdOrPortfolioId {
    payload: CreateHASTaskCheck;
}

export const simpleCreateContractTaskCheck = (props: CreateContractTaskCheckProps) => {
    // h_a_s_check has no portfolio, but the portfolio is used to check permissions against portfolio object, if we're in the portfolio context
    let route = getHASTaskChecksRoute(props.contractRef);
    let params = {};
    if (props.portfolioId) {
        params = {
            surrogate_portfolio: props.portfolioId
        }
    }
    const payload = { ...props.payload, ...params }
    return API.post(route as string, payload).then((response) => {
        return getDataAndMeta(response);
    });
}

interface UpdateContractTaskCheckProps extends ContractIdOrPortfolioId {
    id: string;
    payload: CreateHASTaskCheck;
    meta: FieldMetaGroup;
}

export const simpleUpdateContractTaskCheck = (props: UpdateContractTaskCheckProps) => {
    let baseRoute = getHASTaskChecksRoute(props.contractRef);
    const route = `${baseRoute}${props.id}/`
    let newPayload = removeReadOnlyFields(props.payload, props.meta);
    // NB need to verify that this contract belongs to the specified portfolio on the back end...
    // ps portfolio is not an attribute of a visit - this is to allow the look up of relevant portfolio permissions on the backend.
    newPayload = { ...newPayload, surrogate_portfolio: props.portfolioId }
    return API.patch(route, newPayload).then((response) => {
        return getDataAndMeta(response);
    });
}

interface SimpleFetchHASCheckProps extends ContractIdOrPortfolioId {
    hasCheckId: string;
}
export const simpleFetchHASCheck = (props: SimpleFetchHASCheckProps) => {
    let baseRoute = getHASTaskChecksRoute(props.contractRef);
    let route = `${baseRoute}${props.hasCheckId}/`
    let params = {};
    // check has no portfolio, but the portfolio is used to check permissions against portfolio object, if we're in the portfolio context
    if (props.portfolioId) {
        params = {
            surrogate_portfolio: props.portfolioId
        }
    }
    return API.get(route, { params: params }).then((response) => {
        return getDataAndMeta(response);
    });
}

interface FetchHASContractTaskPreFlightInfoProps extends ContractIdOrPortfolioId {
    contractTaskId: string;
}

export const getHASContractTaskSupportingDocumentsRoute = (props: FetchHASContractTaskPreFlightInfoProps): string => {
    let route = `${BASE_H_A_S_CONTRACTTASK_ROUTE}${props.contractTaskId}/supporting-documents/`; // if this is for a contract, the contract is inferred from the hascontracttask
    // adding surrogate_portfolio as a query param where necessary is handled in the document grid/upload components
    return route;
}

export const fetchHASContractTaskSupportingDocumentsPreFlightInfo = (props: FetchHASContractTaskPreFlightInfoProps): Promise<PreFlightListInfo> => {
    let route = getHASContractTaskSupportingDocumentsRoute(props);
    return APIN.options(route).then((response) => {
        const meta = getPreFlightListMeta(response); //preflight uses the 'options' verb
        const canCreate = preFlightCanCreateCheck(response);
        return { meta, canCreate, canRead: true }
    }).catch(error => {
        if (error.response?.status == 403) {
            return { meta: {}, canCreate: false, canRead: false }
        } else {
            return onResponseError(error)
        }
    });
}

export const getHASContractTaskPersonalSettingsRoute = (HASContractTaskId: string): string => {
    let route = `${BASE_H_A_S_CONTRACTTASK_ROUTE}${HASContractTaskId}/personal-settings/`; // if this is for a contract, the contract is inferred from the hascontracttask
    return route;
}

export const simpleFetchHASContractTaskPersonalSettings = (HASContractTaskId: string) => {
    const route = getHASContractTaskPersonalSettingsRoute(HASContractTaskId);
    return API.get(route).then((response) => {
        return getDataAndMetaAndPUTMeta(response);
    });
}

interface SimpleCreateContractTaskPersonalSettingsProps {
    HASContractTaskId: string;
    payload: Partial<HASContractTaskPersonalSettings>;
}

export const simpleCreateHASContractTaskPersonalSettings = (props: SimpleCreateContractTaskPersonalSettingsProps) => {
    const route = getHASContractTaskPersonalSettingsRoute(props.HASContractTaskId);
    return API.post(route, props.payload).then((response) => {
        return getDataAndMeta(response);
    });
}

interface SimpleUpdateContractTaskPersonalSettingsProps {
    id: string;
    HASContractTaskId: string;
    payload: Partial<HASContractTaskPersonalSettings>;
}

export const simpleUpdateHASContractTaskPersonalSettings = (props: SimpleUpdateContractTaskPersonalSettingsProps) => {
    const route = getHASContractTaskPersonalSettingsRoute(props.HASContractTaskId) + props.id + '/';
    return API.patch(route, props.payload).then((response) => {
        return getDataAndMeta(response);
    });
}

export default {
    addStatutoryTemplateDocument,
    removeStatutoryTemplateDocument,
    updateStatutoryTemplateDocumentDetails,
    updateHASStatutoryTemplateDocumentFile,
    getStatutoryTemplateDocumentsPreFlightInfo,
    simpleFetchHASTask,
    simpleFetchContractBoundHASTask,
    simpleUpdateHASTask,
    fetchStatutoryTemplateDocsPreFlightInfo,
    linkContractsToHASTask,
    linkHASTasksToContract,
    removeContractFromHASTask,
    removeHASTaskFromContract,
    fetchHASTaskContractLinks,
    fetchContractHASTaskLinks,
    archiveContractFromHASTask,
    unArchiveContractFromHASTask,
    simpleFetchHASContractTaskPersonalSettings,
    simpleCreateHASContractTaskPersonalSettings,
    simpleUpdateHASContractTaskPersonalSettings,
    fetchContractHASTasksLinksPreFlightInfo
};
