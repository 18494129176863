import React, { useState, useEffect, useRef, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import { Button } from "@material-ui/core";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Cookies from "universal-cookie";
import Note from '@material-ui/icons/Notes'

// Own
import { ScheduleLegendProps } from "./Legend";
import ScheduleDataGrid from "./ScheduleDataGrid/ScheduleDataGrid";
import GridHeader from "../Common/Components/GridHeader/GridHeader";
import NewPageWrapper from "components/Common/Components/PrintStyles/Print";
import { OptionalContractPageContextProps } from "../ContractInFocus/HoC/WithPageContext/WithPageContext";
import { Wrapper } from "./SchedulerStyles";
import { PrintTitle } from "../ContractInFocus/Components/ContractPrintTitle/ContractPrintTitle";
// import * as organisationSelectors from "../AdminPanel/Organisation/Selectors/Organisations.selectors";
import Header from "components/Common/Components/GridHeader/GridHeader.js";
import { PrintChartAndTableLabels } from "components/Common/constants.js";
import { contractInFocusSiteSelector } from "components/ContractInFocus/Selectors/contractInFocus.selectors";
import { PrintPlannerTitle } from "components/ContractInFocus/Components/ContractPrintTitle/ContractPrintTitle";
import { updatePersonalVisibilitySetting } from "components/AdminPanel/Contracts/Services/contractService";
import { setSinglePersonalInFocusSettingReducer } from "components/ContractInFocus/Actions/contractVisibilitySettings.actions";
import { SchedulePlannerPDFViewer } from 'components/ContractInFocus/Components/SchedulePlannerViewer/SchedulePlannerViewer';
import { downloadPDFFromBase64, useQuery } from "store/Common/Helpers/commonHelper.js";
import { MatFabButton } from 'components/Common/Components/Material/MatFabButton/MatFabButton';
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { ModalContextContractSelector } from "components/Schedulers/VisitModal/Components/ContextContractSelector/ContextContractSelector";
import { APIPrivileges } from "services/Interface/Interface";
import { ColumnProps } from "components/ContractInFocus/interfaces/dataGridColumn.interface";
import { PreFlightListInfo, Primitive } from "components/Common/Interfaces/Entity.interface";
import { Period } from "components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface";
import { GeneralTooltip } from "components/Common/Components/InfoHint/InfoHint";

import styles from 'styles/app/common/variables.scss';
import "./ScheduleHeaderStyles.scss";
import { MainInFocusVisibilitySettings } from "components/ContractInFocus/interfaces/ContractInFocus.interfaces"
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

export interface ArchiveArgs {
    data: any,
    portfolio?: HydratedPortfolio,
    contract?: SiteContract
}

export interface FilterControlProperties {
    [query_param: string]: Primitive
}

interface ScheduleProps extends OptionalContractPageContextProps {
    contextIdString: string;
    DetailModal: React.FC<any>;
    title: string;
    canShowPlannerView: boolean;
    showPlannerView: boolean;
    isPDFRequest: boolean;
    extraFilterQueryParams?: FilterControlProperties;
    getHandleGeneratePDF?: (config: any) => () => void;
    plannerModeSetting?: keyof MainInFocusVisibilitySettings;
    reportSubTitle?: string;
    show: boolean;
    reportSection?: any;
    clickableCaptionColumns?: string[];
    getButtonCellTemplate: (e: any) => JSX.Element;
    getHeaderButtonCellTemplate?: (e: any, contractRef?: string, portfolioId?: number, query_params?: any) => JSX.Element;
    buttonCellWidth: number;
    processData?: (data: any) => any;
    visibleFixedColumns: string[];
    addToCellClassName?: (data: any) => string;
    fetchInfo: ({ args, query_params }: { args?: any, query_params?: any }) => Promise<{
        data: any;
        metadata: any;
        permissions: APIPrivileges;
        totalCount?: number;
    }>
    fetchPreflightInfo?: ({ args, query_params }: { args?: any, query_params?: any }) => Promise<PreFlightListInfo>;
    generateColumnHeaders: (months: string[]) => ColumnProps[];
    generateColumnHeadersForPlannerView?: (months: string[], portfolio?: HydratedPortfolio, reportContext?: boolean) => ColumnProps[];
    Legend: React.FunctionComponent<ScheduleLegendProps>;
    initialEditModalIndex?: number;
    CellRender: {
        (cellData: any): JSX.Element;
        propTypes: {
            cellData: PropTypes.Validator<object>;
        };
    }
    ControlSwitches?: () => JSX.Element;
    showArchived: boolean,
    setShowArchived: React.Dispatch<React.SetStateAction<boolean>>;
    remoteOperations?: boolean;
    contractRefDataField?: string;
    pageSize?: number;
    passedInPeriod?: Period;
    showContractsColumn?: boolean;
    reload: number;
    handleOnRowPrepared?: (e: any) => void;
    HASEditableContext?: boolean;
}

const Scheduler = ({
    visibilitySettings,
    contextIdString,
    contract,
    portfolio,
    sectionNumberMap,
    reportSection,
    contractReportContext,
    DetailModal,
    title,
    canShowPlannerView,
    showPlannerView,
    isPDFRequest,
    extraFilterQueryParams,
    getHandleGeneratePDF,
    plannerModeSetting,
    reportSubTitle,
    show,
    clickableCaptionColumns,
    getButtonCellTemplate,
    getHeaderButtonCellTemplate,
    buttonCellWidth,
    processData,
    visibleFixedColumns,
    addToCellClassName,
    fetchInfo,
    fetchPreflightInfo,
    generateColumnHeaders,
    generateColumnHeadersForPlannerView,
    Legend,
    initialEditModalIndex,
    CellRender,
    ControlSwitches,
    showArchived,
    setShowArchived,
    remoteOperations,
    contractRefDataField,
    pageSize,
    reload,
    passedInPeriod,
    showContractsColumn,
    handleOnRowPrepared,
    HASEditableContext
}: ScheduleProps) => {

    let query = useQuery();
    const queryStringViewFrom = query.get('viewFrom');
    const cookies = new Cookies();
    const overrideViewFrom = queryStringViewFrom ? Number(queryStringViewFrom) : undefined;
    const [viewFrom, setViewFrom] = useState(0);

    const selectedSite = useSelector(contractInFocusSiteSelector);

    const [generatingPDF, setGeneratingPDF] = useState(false);
    const [PDFbase64, setPDFBase64] = useState<string>();

    const handleCloseDialog = (): void => {
        setGeneratingPDF(false);
        setPDFBase64(undefined);
    };

    const handleOnClosedDialog = (): void => {
        setTimeout(() => {
            setPDFBase64(undefined);
        }, 500)
    };

    const handleGeneratePDFConfig = useRef({
        selectedSite: selectedSite,
        viewFrom: viewFrom,
        setGeneratingPDF: setGeneratingPDF,
        setPDFBase64: setPDFBase64,
        portfolio: portfolio,
        contract: contract,
        cookies: cookies
    })

    const [openNewRowModal, setNewRowModalOpen] = useState(false);
    const [openRowModal, setOpenRowModal] = useState(false);
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 1);
    const [contextContract, setContextContract] = useState<SiteContract>();

    const dispatch = useDispatch();
    const wrapperRef = useRef<any>(null);

    return (
        <Wrapper ref={wrapperRef} id={`schedulerView${contextIdString}`}>
            {PDFbase64 && (contract || portfolio) && <SchedulePlannerPDFViewer
                isOpen={!!PDFbase64}
                fileBase64={PDFbase64}
                okCaption="Download"
                onConfirm={() => {
                    downloadPDFFromBase64(PDFbase64, `${portfolio ? portfolio.name : (contract?.alias || contract?.contract_ref)} Subcontractor Planner.pdf`)
                    handleCloseDialog();
                }
                }
                onCancel={handleCloseDialog}
                onClosed={handleOnClosedDialog}
            />
            }
            {show &&
                < Paper elevation={3} id="schedulePaper">
                    {openNewRowModal && (contract || !!contextContract) ?
                        <DetailModal
                            contract={contract || contextContract}
                            portfolio={portfolio}
                            initialIndex={0}
                            setOpen={setNewRowModalOpen}
                            initialContextObject={undefined}
                            open={openNewRowModal}
                            refreshGrid={forceUpdate}
                        />
                        : openNewRowModal && !contract && !contextContract ?
                            <ModalContextContractSelector
                                isOpen={true} // whether this modal shows is controlled by other criteria, as just above
                                portfolio={portfolio}
                                setContextContract={setContextContract}
                                onCancel={() => setNewRowModalOpen(false)}
                                dataField={'contract'}
                                zIndex={9000}
                                HASEditableContext={HASEditableContext}
                            />
                            : null

                    }

                    <NewPageWrapper breakBefore={true}>
                        {!showPlannerView && reportSection && <PrintTitle sectionNumberMap={sectionNumberMap} reportSection={reportSection} />}
                        {showPlannerView && !isPDFRequest && contract && <PrintPlannerTitle portfolio_name={portfolio?.name} contract_ref={contract.contract_ref} site_name={selectedSite?.data?.name}></PrintPlannerTitle>}
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <GridHeader
                                className={`schedule-items-header ${PrintChartAndTableLabels ? '' : 'no-print'}`}
                                level={1}
                                title={title}
                            />
                            {canShowPlannerView && plannerModeSetting &&
                                <>
                                    {showPlannerView ?
                                        <>
                                            <Button
                                                size="small"
                                                onClick={() => {
                                                    // we update the BE setting separately from updating the local setting because in the case of switch of view like this
                                                    // we want to proceed whether the call to the BE succeeds or not, so we might as well do it at once
                                                    if (contract || portfolio) {
                                                        updatePersonalVisibilitySetting({
                                                            contractRef: contract?.contract_ref,
                                                            contractId: contract?.id,
                                                            portfolioId: portfolio?.id,
                                                            payload: { [plannerModeSetting]: false }
                                                        })
                                                    }
                                                    plannerModeSetting && dispatch(setSinglePersonalInFocusSettingReducer(plannerModeSetting, false))
                                                }}

                                                className="no-print plannerViewLink open"
                                            >
                                                Show Report View
                                            </Button>

                                            <MatFabButton
                                                visible={true}
                                                onClick={getHandleGeneratePDF ? getHandleGeneratePDF(handleGeneratePDFConfig.current) : undefined}
                                                loading={generatingPDF}
                                                styles={{ backgroundColor: styles.darkBlue }}
                                                className='schedulePlanner__generate-report no-print'
                                            >
                                                <PictureAsPdfIcon fontSize='large' />
                                            </MatFabButton>
                                        </>
                                        : <Button
                                            size="small"
                                            onClick={() => {
                                                if (contract || portfolio) {
                                                    updatePersonalVisibilitySetting({
                                                        contractRef: contract?.contract_ref,
                                                        contractId: contract?.id,
                                                        portfolioId: portfolio?.id,
                                                        payload: { [plannerModeSetting]: true }
                                                    })
                                                }
                                                plannerModeSetting && dispatch(setSinglePersonalInFocusSettingReducer(plannerModeSetting, true))
                                            }}
                                            className="no-print plannerViewLink"
                                        >
                                            Show Planner View
                                        </Button>
                                    }
                                </>}
                        </div>

                        {!showPlannerView && reportSubTitle && <Header
                            title={reportSubTitle}
                            className="no-screen reportSubTitle"
                        />}
                        {!showPlannerView && <Legend className={'no-print'} />}

                        {<ScheduleDataGrid
                            contextIdString={contextIdString}
                            contract={contract}
                            portfolio={portfolio}
                            onNewItem={() => {
                                //dispatch(fromVisitsAction.toggleModal(true));
                                setContextContract(undefined); // this is important otherwise the modal would always open with the portfolio contract last selected
                                setNewRowModalOpen(true);
                            }
                            }
                            refreshSignal={mustRefresh}
                            fetchInfo={fetchInfo}
                            fetchPreflightInfo={fetchPreflightInfo}
                            contractReportContext={contractReportContext}
                            openRowModal={openRowModal}
                            setOpenRowModal={setOpenRowModal}
                            //data={thisData}
                            wrapperRef={wrapperRef}
                            refreshGrid={forceUpdate}
                            showPlannerView={showPlannerView}
                            extraFilterQueryParams={extraFilterQueryParams}
                            overrideViewFrom={overrideViewFrom}
                            viewFrom={viewFrom}
                            setViewFrom={setViewFrom}
                            isPDFRequest={isPDFRequest}
                            DetailModal={DetailModal}
                            clickableCaptionColumns={clickableCaptionColumns}
                            getButtonCellTemplate={getButtonCellTemplate}
                            getHeaderButtonCellTemplate={getHeaderButtonCellTemplate}
                            buttonCellWidth={buttonCellWidth}
                            visibleFixedColumns={visibleFixedColumns}
                            addToCellClassName={addToCellClassName}
                            generateColumnHeaders={generateColumnHeaders}
                            generateColumnHeadersForPlannerView={generateColumnHeadersForPlannerView}
                            initialEditModalIndex={initialEditModalIndex}
                            CellRender={CellRender}
                            ControlSwitches={ControlSwitches}
                            showArchived={showArchived}
                            setShowArchived={setShowArchived}
                            // setExtraQueryParams={setExtraQueryParams}
                            remoteOperations={remoteOperations}
                            pageSize={pageSize}
                            contractRefDataField={contractRefDataField}
                            reload={reload}
                            processData={processData}
                            showContractsColumn={showContractsColumn}
                            passedInPeriod={passedInPeriod}
                            handleOnRowPrepared={handleOnRowPrepared}
                        />}
                    </NewPageWrapper>

                </Paper>
            }
        </Wrapper >
    );
};

export default Scheduler;

export const NotesPreviewer = ({ notes, onclick, hideTooltip }: { notes?: string, onclick?: (e: any) => void, hideTooltip?: boolean }) => {
    if (notes?.length && !hideTooltip) {
        return <span className="withNotes">
            <GeneralTooltip title={notes}>
                <Note
                    className='scheduleNotesIcon no-print'
                    onClick={onclick}
                />
            </GeneralTooltip>
        </span>
    } else {
        return <Note
            className='scheduleNotesIcon no-print'
            onClick={onclick}
        />
    }
}
