/* eslint-disable */
// no-undef
import * as React from "react";
import PropTypes from "prop-types";
import { Wrapper, Icon, Text } from "./IconButtonStyles";

const IconButton = ({
  active,
  whiteIcon,
  margin = "right",
  marginSize = "10px",
  className,
  id,
  onClick,
  icon,
  size,
  clickable = true,
  color,
  colorHover,
  text,
  isLoading,
  style
}) => {
  const handleClick = e => onClick && onClick(id, this, e);
  return (
    <Wrapper
      style={style}
      data-test-id={id}
      active={active}
      whiteIcon={whiteIcon}
      clickable={clickable}
      className={className}
      id={id}
      color={color}
      colorHover={colorHover}
      onClick={clickable ? handleClick : undefined}
    >
      {isLoading ? (
        <Icon
          marginSize={marginSize}
          margin={margin}
          className={
            size ? `fas fa-sync fa-${size} fa-spin` : "fas fa-sync fa-spin"
          }
        />
      ) : (
        <Icon
          marginSize={marginSize}
          margin={margin}
          className={size ? `${icon} fa-${size}` : icon}
          color={color}
        />
      )}
      {text ? (
        <Text
          active={active}
          whiteIcon={whiteIcon}
          color={color}
          colorHover={colorHover}
        >
          {text}
        </Text>
      ) : null}
    </Wrapper>
  );
};

IconButton.propTypes = {
  active: PropTypes.bool,
  whiteIcon: PropTypes.bool,
  margin: PropTypes.oneOf(["left", "right", "none"]),
  marginSize: PropTypes.string,
  clickable: PropTypes.bool,
  icon: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
  colorHover: PropTypes.string,
  text: PropTypes.string,
  isLoading: PropTypes.bool,
  style: PropTypes.object
};

export default IconButton;
