import { ContractMenu } from "./contractMenu.interfaces";
import { ViewMenuValues } from 'components/Profile/Helpers/profileHelpers';
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { Dictionary } from "components/Common/Interfaces/Entity.interface";
import { useGetContractReportingMenu, useGetPortfolioReportingMenu, useGetContractHASMenu, useGetPortfolioHASMenu } from "components/Layout/Contract/Containers/ContractSidebar/contractSidebarReportingMenu";

const reportingMenuKey = "Reporting"
const HASMenuKey = "Health And Safety"

export function useGetGroupedContractMenuItems(contract?: SiteContract, menuVisibility?: ViewMenuValues): Dictionary<ContractMenu[]> {
    let wholeContractMenu: Dictionary<ContractMenu[]> = {};
    let newContractMenu = useGetContractReportingMenu(contract).filter(x => !menuVisibility || !!menuVisibility[x.menu]);
    // newContractMenu = [
    //     {
    //         icon: "fas fa-list",
    //         menu: "Dashboard",
    //         name: "Dashboard",
    //         label: {
    //             value: "Dash"
    //         },
    //         path: `/contract/${contract?.contract_ref}/dash`,
    //         reportVisibilityFilter: undefined
    //     },
    //     ...newContractMenu
    // ]
    const HASMenu = useGetContractHASMenu(contract).filter(x => !menuVisibility || !!menuVisibility[x.menu]);
    if (!contract) {
        return {}
    }
    // const HASMenu = [{
    //     icon: "fas fa-medkit",
    //     menu: "Health and Safety",
    //     name: "Health and Safety",
    //     label: {
    //         value: "Health and Safety"
    //     },
    //     path: `/contract/${contract.contract_ref}/h-a-s`,
    //     reportVisibilityFilter: ppmRightVisibilityMenu // NB seems to be used by handleTabClick in ContractReportVisibility.tsx and ultimately by VisibilityControls
    // }]
    if (!!newContractMenu.length) {
        wholeContractMenu[reportingMenuKey] = newContractMenu
    }
    if (!!HASMenu.length) {
        wholeContractMenu["Health And Safety"] = HASMenu;
    }
    // if (viewMenuValues && viewMenuValues["Health and Safety"]) {
    //     // a bit hackish but at the minute that's really the condition that dictates if we'll want to display the 'Management' part of the menu at all
    //     wholeContractMenu["Health And Safety"] = HASMenu;
    // }
    return wholeContractMenu
}

export function useGetGroupedPortfolioMenu(portfolio?: HydratedPortfolio, menuVisibility?: ViewMenuValues): { [idx: string]: ContractMenu[] } {
    let newPortfolioMenu = useGetPortfolioReportingMenu(portfolio).filter(x => !menuVisibility || !!menuVisibility[x.menu]);
    const HASMenu = useGetPortfolioHASMenu(portfolio).filter(x => !menuVisibility || !!menuVisibility[x.menu]);
    let wholePortfolioMenu: Dictionary<ContractMenu[]> = {};
    if (!portfolio) {
        return {}
    }
    // const portfolioHASMenu = [{
    //     icon: "fas fa-medkit",
    //     menu: "Health and Safety",
    //     name: "Health and Safety",
    //     label: {
    //         value: "Health and Safety"
    //     },
    //     path: `/portfolio/${portfolio.id}/h-a-s`,
    //     reportVisibilityFilter: ppmRightVisibilityMenu // NB seems to be used by handleTabClick in ContractReportVisibility.tsx and ultimately by VisibilityControls
    // }]
    if (!!newPortfolioMenu.length) {
        wholePortfolioMenu.Reporting = newPortfolioMenu
    }
    if (!!HASMenu.length) {
        wholePortfolioMenu[HASMenuKey] = HASMenu;
    }
    return wholePortfolioMenu
}

export function getFirstPathFromGroupedMenuItems(menuItems: Dictionary<ContractMenu[]>) {
    let firstPath;
    const hasItems = menuItems[HASMenuKey];
    const reportingItems = menuItems[reportingMenuKey];
    if (reportingItems?.length) {
        firstPath = reportingItems[0].path
    } else if (hasItems?.length) {
        firstPath = hasItems[0].path
    }
    return firstPath;
}
