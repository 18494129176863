import React from 'react';

// Own
import { DocumentViewerModal, DocumentViewerModalProps } from 'components/Common/Components/DocumentViewer/DocumentViewer';

interface SchedulePlannerViewerProps extends DocumentViewerModalProps {
    onConfirm: () => void;
}

export const SchedulePlannerPDFViewer = ({
    onConfirm, ...props
}: SchedulePlannerViewerProps) => {
    //   const selectedMMRDownload = useSelector(downloadSelector);
    return (
        <DocumentViewerModal {...props} onConfirm={onConfirm} />
    );
}