import { dictToArray, arrayToDict } from "../../../../services/API/API.helper";
import { ColumnProps } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';
import { Snapshot } from "components/ContractInFocus/Interfaces/ContractInFocus.interfaces";

export const extractDataArraysFromBookendedTableRow = (row: any) => {
  const { name, key, header_pointer, head_ref, associated_with, ...rowData } = row;
  const primaryRowAsArray = dictToArray(rowData);
  const dataAsArray = primaryRowAsArray.slice(1, -1)
  return {
    'primaryRowAsArray': primaryRowAsArray,
    'dataAsArray': dataAsArray
  }
}
interface pannableViewProps {
  primaryRowAsArray: ColumnProps[],
  dataAsArray: any[],
  viewFrom: number,
  viewLength: number,
}

// TODO: update panable veiws to use column based
export const panableView = (
  {
    primaryRowAsArray,
    dataAsArray,
    viewFrom,
    viewLength,
  }: pannableViewProps
) => {

  const calculatedShowFrom =
    viewLength > dataAsArray.length
      ? 0
      : viewFrom + viewLength > dataAsArray.length
        ? dataAsArray.length - viewLength
        : viewFrom;

  const panableArray = dataAsArray.slice(
    calculatedShowFrom < 0 ? 0 : calculatedShowFrom,
    calculatedShowFrom + viewLength
  );

  return arrayToDict([
    primaryRowAsArray[0],
    ...panableArray,
    primaryRowAsArray[primaryRowAsArray.length - 1]
  ]);
};


interface PannableColumnViewProps {
  columnMeta: ColumnProps[],
  viewFrom: number,
  viewLength: number,
  upperLimitReachedRef?: any,
  lowerLimitReachedRef?: any
}
// This function is for the reactive categories follows similar logic to
// the above helper function, but with reduced complexity.  Reactive by category
// has an extrapolated header based on the data, where as the maintenance tables 
// grids are built out based on the data which is polyfilled.  It would be great
// to update the Maintenance Tables with the new pattern.
export const panableColumnView = ({
  columnMeta,
  viewFrom,
  viewLength,
  upperLimitReachedRef,
  lowerLimitReachedRef
}: PannableColumnViewProps): ColumnProps[] => {
  const coreDataColumns = columnMeta.slice(1, columnMeta.length - 1);

  const calculatedShowFrom = viewLength > coreDataColumns.length
    ? 0
    : viewFrom + viewLength > coreDataColumns.length
      ? coreDataColumns.length - viewLength
      : viewFrom;

  const panableColumns = coreDataColumns.slice(
    Math.max(calculatedShowFrom, 0), calculatedShowFrom + viewLength
  );

  return [
    columnMeta[0],
    ...panableColumns,
    columnMeta[columnMeta.length - 1]
  ];
};

// 1.7
export const showColumns = (width: number, selectedFocusedSnapshot: Snapshot) => selectedFocusedSnapshot ? 12 : Math.round((width >= 750 ? width * 1.6 : width / 1.3) / 200);