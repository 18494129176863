import API, { APIR } from 'services/API/API';
import { IFinancials, clientAWQuoteResponseHistoryI, awHistoryItemI, awCommentI } from '../interfaces/financials.interface';
import { tap } from 'rxjs/operators';
import { unWrapDataAndMeta, getDataAndMeta, getListData } from "services/API/API.helper";
import DataSource from 'devextreme/data/data_source';
import { map } from 'rxjs/operators';
import { sortByCreatedAt } from 'components/Common/Utils/Arrays.js';
import Axios from 'axios';
import moment from "moment";

interface getAWRecordProps {
  contractRef?: string;
  portfolioId?: number;
  awId: string;
}

const getAWRecord = (props: getAWRecordProps) => {
  let baseRoute = 'get-aw-record/never/';
  if (props.contractRef) {
    baseRoute = `contracts/${props.contractRef}/`
  }
  if (props.portfolioId) {
    baseRoute = `portfolios/${props.portfolioId}/`
  }
  return API.get(`${baseRoute}financials/${props.awId}/`).then((response) => {
    const transformedResponse = getDataAndMeta(response)
    return transformedResponse.data;
  })
}

const relegateClientFinancialRecord = (contractRef: string, data: IFinancials, contractId: any, datasource: DataSource, forceReload: () => void) => {
  // No need for this in portfolio context
  const payload = {
    "client_name": data.client_name,
    "contract": contractId,
    "relegated": true
  };

  return APIR.post(`contracts/${contractRef}/relegated-client-financial-records/`, payload).pipe(
    tap(() => forceReload())//datasource.reload())
  ).subscribe();
}

const restoreClientFinancialRecord = (contractRef: string, clientId: any, datasource: DataSource, forceReload: () => void) => {
  // No need for this in portfolio context
  return APIR.delete(`contracts/${contractRef}/relegated-client-financial-records/${clientId}`).pipe(
    tap(() => forceReload())//datasource.reload())
  ).subscribe();
}

const permissionsHideClientFinancial = (contractRef: string, setPermissions: (permissions: any) => void) => {
  // No need for this in portfolio context
  return APIR.get(`contracts/${contractRef}/relegated-client-financial-records/`).pipe(
    unWrapDataAndMeta(),
    map(response => setPermissions(response.permissions))
  ).subscribe();
}

// const getAWQuotesForContract = (contract: ContractInterface,) => {
//   return APIR.get(`contracts/${contract.contractRef}/awquotes/?format=json`).pipe(
//     unWrapDataAndMeta(),
//   ).subscribe((response) => {
//     store.dispatch(actions.setContractAWQuotes(contract.contractId, response.data));
//   })
// }


// const notifyPORefWithoutPODoc = async (client_response_id: string) => {
//   const payload = {
//     "send_email_without_po": true
//   }
//   const response = await API.patch(`financials-client-responses/${client_response_id}/`, payload);
//   const transformedResponse = getDataAndMeta(response);
//   return transformedResponse;
// }

// const commentOnFinancial = async (financial_id: string, comment: string) => {
//   const payload = {
//     quote_comments: comment
//   }
//   const response = await API.patch(`financials/${financial_id}/`, payload);
//   const transformedResponse = getDataAndMeta(response);
//   return transformedResponse;
// }


const commentOnFinancial = async (financial_id: string, comment: string, portfolioId?: string | number) => {
  let url = `financials/${financial_id}/comments/`;
  if (portfolioId) {
    url = `portfolios/${portfolioId}/${url}`;
  }
  const payload = {
    text: comment,
    aw: financial_id
  }
  const response = await API.post(url, payload);
  const transformedResponse = getDataAndMeta(response);
  return transformedResponse;
}

// const getAWQuoteItemsForAW = (financial: IFinancials, ) => {
//   return API.get(`financials/${financial.id}/quote/?format=json`).then((response) => {
//     console.log('here is the response: ', response);
//     const transformedResponse = getDataAndMeta(response)
//     return transformedResponse;
//   });
// }

const getAWQuoteItemsForAW = async (financial_id: string, portfolioId?: string | number) => {
  let url = `financials/${financial_id}/quote/?format=json`;
  if (portfolioId) {
    url = `portfolios/${portfolioId}/${url}`;
  }
  const response = await API.get(url);
  const transformedResponse = getDataAndMeta(response);
  return transformedResponse;
}

const getClientResponseForAW = async (financial_id: string, portfolioId?: string | number) => {
  let url = `financials/${financial_id}/client-response/?format=json`
  if (portfolioId) {
    url = `portfolios/${portfolioId}/${url}`;
  }
  const response = await API.get(url);
  const transformedResponse = getDataAndMeta(response);
  return transformedResponse;
}

const getClientHistoricalResponseForAW = async (financial_id: string, portfolioId?: string | number) => {
  let url = `financials/${financial_id}/historical-client-responses/?format=json`;
  if (portfolioId) {
    url = `portfolios/${portfolioId}/${url}`;
  }
  const response = await API.get(url);
  const transformedResponse = getDataAndMeta(response);
  return transformedResponse;
}

function processClientResponsesForHistoryPanel(clientResponses: clientAWQuoteResponseHistoryI[]) {
  const processedResponses = clientResponses.map((item: clientAWQuoteResponseHistoryI) => {
    const formatted_date = moment(item.history_date).format("YYYY-MM-DD HH:mm:ss");
    const obj = <awHistoryItemI>{
      created_at: formatted_date,
      text: item.status,
      log_type: "action",
      aw: item.aw,
      id: item.history_id.toString(),
      submitted_by: item.submitted_by,
      current_user_is_owner: item.current_user_is_owner,
      submitted_by_first_name: item.submitted_by_first_name,
      submitted_by_last_name: item.submitted_by_last_name
    };
    return obj;
  }
  ).filter((item: awHistoryItemI) => {
    // filters out actions you may not need to report on (say sending emails).  Just don't create text for them
    if (item.text) {
      return true
    }
  });
  return processedResponses;
}

function processCommentsForHistoryPanel(comments: awCommentI[]) {
  const processedComments = comments.map((item: awCommentI) => {
    const formatted_date = moment(item.created_at).format("YYYY-MM-DD HH:mm:ss");
    const obj = <awHistoryItemI>{
      created_at: formatted_date,
      text: item.text,
      log_type: "comment",
      aw: item.aw,
      id: item.id,
      submitted_by: item.submitted_by,
      current_user_is_owner: item.current_user_is_owner,
      submitted_by_first_name: item.submitted_by_first_name,
      submitted_by_last_name: item.submitted_by_last_name
    };
    return obj;
  });
  return processedComments;
}

const getAWHistory = async (financial_id: string, portfolioId?: string | number) => {
  let commentsUrl = `financials/${financial_id}/comments/?format=json`;
  let clientResponsesUrl = `financials/${financial_id}/historical-client-responses/?format=json`;
  if (portfolioId) {
    commentsUrl = `portfolios/${portfolioId}/${commentsUrl}`;
    clientResponsesUrl = `portfolios/${portfolioId}/${clientResponsesUrl}`;
  }
  const commentsRequest = API.get(commentsUrl);
  const clientResponsesRequest = API.get(clientResponsesUrl);
  const response = await Axios.all([commentsRequest, clientResponsesRequest]).then(Axios.spread((...responses) => {
    const comments = getListData(responses[0])
    const clientResponses = getListData(responses[1])
    const processedClientResponses = processClientResponsesForHistoryPanel(clientResponses);
    const processedComments = processCommentsForHistoryPanel(comments);
    const fullHistory = [...processedComments, ...processedClientResponses];
    const fullHistorySorted = sortByCreatedAt(fullHistory);
    //console.log('fullHistory: ', fullHistorySorted);
    return fullHistorySorted;
  }
  )
  ).catch(errors => {
    // react on errors.
    console.log('getAWHistory errors: ', errors);
    return null;
  })
  return response;
}

const submitQuoteResponse = async (financial_id: string, payload: any, portfolioId?: string | number) => {
  let url = `financials/${financial_id}/client-response/`;
  if (portfolioId) {
    url = `portfolios/${portfolioId}/${url}`;
  }
  const response = await API.post(url, payload);
  const transformedResponse = getDataAndMeta(response);
  return transformedResponse;
}

const deleteQuoteResponse = async (financial_id: string, client_response_id: string, portfolioId?: string | number) => {
  let url = `financials/${financial_id}/client-response/${client_response_id}/`;
  if (portfolioId) {
    url = `portfolios/${portfolioId}/${url}`;
  }
  const response = await API.delete(url);
  const transformedResponse = getDataAndMeta(response);
  return transformedResponse;
}

const permissionsChangeClientResponse = (financial_id: string, client_response_id: string, setPermissions: (permissions: any) => void, portfolioId?: string | number) => {
  let url = `financials/${financial_id}/client-response/${client_response_id}/`;
  if (portfolioId) {
    url = `portfolios/${portfolioId}/${url}`;
  }
  return APIR.get(url).pipe(
    unWrapDataAndMeta(),
    map(response => {
      setPermissions(response.permissions)
    })
  ).subscribe();
}

const permissionsCreateClientResponse = (financial_id: string, setPermissions: (permissions: any) => void, portfolioId?: string | number) => {
  let url = `financials/${financial_id}/client-response/`;
  if (portfolioId) {
    url = `portfolios/${portfolioId}/${url}`;
  }
  return APIR.get(url).pipe(
    unWrapDataAndMeta(),
    map(response => {
      //console.log('response permissions for create client response: ', response);
      setPermissions(response.permissions)
    })
  ).subscribe();
}

const permissionsAWComment = (financial_id: string, setPermissions: (permissions: any) => void, portfolioId?: string | number) => {
  let url = `financials/${financial_id}/comments/`;
  if (portfolioId) {
    url = `portfolios/${portfolioId}/${url}`;
  }
  return APIR.get(url).pipe(
    unWrapDataAndMeta(),
    map(response => {
      return setPermissions(response.permissions)
    })
  ).subscribe();
}



export default {
  getAWRecord,
  //getAWQuotesForContract,
  deleteQuoteResponse,
  submitQuoteResponse,
  getAWHistory,
  commentOnFinancial,
  getClientResponseForAW,
  getClientHistoricalResponseForAW,
  //notifyPORefWithoutPODoc,
  getAWQuoteItemsForAW,
  permissionsChangeClientResponse,
  permissionsCreateClientResponse,
  permissionsAWComment,
  relegateClientFinancialRecord,
  restoreClientFinancialRecord,
  permissionsHideClientFinancial
}
