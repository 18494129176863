import React, { useEffect, useState } from 'react';

// Own
import { APIPrivileges } from "services/Interface/Interface";
import { BasePermitToWork, PermitToWorkAgreement } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import AppriseWorkPermitDetail from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/AppriseWorkPermitDetail";
import ExternalPermitUploader from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/ExternalPermitUploader";
import { getPermitDocBaseRoute } from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/ExternalPermitUploader";
import commonAPIServices from 'components/ContractInFocus/Services/commonAPI.services';
import { PreFlightListInfo } from "components/Common/Interfaces/Entity.interface";
import { WorkPermitDocViewer } from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/WorkPermitDocVIewer";
import { Results } from 'components/Common/Interfaces/Entity.interface';
import { PtwType } from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/WorkPermit.models";

// Styles
import "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/workPermitForms.scss";
import "components/Common/Components/GeneralActionForm/GeneralActionFormStyles.scss";
import DataSource from 'devextreme/data/data_source';

export const generatePermitLabel = 'Generate Permit';
export const PTWSaveLabel = 'Save';

interface WorkPermitDetailProps {
    initialData: BasePermitToWork;
    visitId: string;
    initialPermissions?: APIPrivileges;
    dataSource: DataSource;
    contract?: SiteContract;
    currentDataRef: React.MutableRefObject<Results<PtwType> | undefined>;
}

function WorkPermitDetailView({
    initialData,
    visitId,
    initialPermissions,
    dataSource,
    contract,
    currentDataRef
}: WorkPermitDetailProps) {
    const [data, setData] = useState(initialData);
    const showExternalPermitUploader = data?.client_ptws && !contract?.disable_work_permits_system && !data.ptw_type?.length;
    const docBaseRoute = getPermitDocBaseRoute(data);

    const [preFlightDocInfo, setPreFlightDocInfo] = useState<PreFlightListInfo>();

    useEffect(() => {
        commonAPIServices.getPreFlightInfo(docBaseRoute).then(response => {
            setPreFlightDocInfo(response);
        }).catch(
            (error) => { console.log(error) }
        )
    }, [docBaseRoute]);

    const showNewDoc = (agreement: PermitToWorkAgreement) => (
        setData({ ...initialData, agreement: agreement })
    )

    if (showExternalPermitUploader) {
        if (data.agreement?.file) {
            return (
                <>
                    <WorkPermitDocViewer
                        data={data}
                        setData={setData}
                        docBaseRoute={docBaseRoute}
                        preFlightDocInfo={preFlightDocInfo}
                        isOpen={!!data.agreement}
                        showDeleteButton
                        showDownloadButton
                    />
                </>
            )
        } else {
            return <>
                <ExternalPermitUploader
                    ptw={data}
                    setData={showNewDoc}
                    preFlightInfo={preFlightDocInfo}
                />
            </>
        }
    } else {
        return <AppriseWorkPermitDetail
            data={data}
            visitId={visitId}
            initialPermissions={initialPermissions}
            dataSource={dataSource}
            setData={setData}
            docBaseRoute={docBaseRoute}
            preFlightDocInfo={preFlightDocInfo}
            currentListDataRef={currentDataRef}
        />
    }
}
// WorkPermitDetailView.whyDidYouRender = true;
export default WorkPermitDetailView;