import { MainInFocusVisibilitySettings } from "../../interfaces/contractInFocus.interfaces";
import { RightVisibilityMenuT } from "../../interfaces/personalContractSettings.interfaces";
import { reportSubtitles } from "components/ContractInFocus/Models/contractIndex.model";
import { GetRightVisibilityMenu } from "components/Common/Components/RightVisibilityMenu/Interfaces/RightContext.interfaces";

export const spendRightVisibilityMenu = ({ visibilitySettings }: GetRightVisibilityMenu): RightVisibilityMenuT => ({
  //note that the keys here are not arrays, the square brackets are needed to indicate interpolation of the value, it seems
  ["Spend Markup"]: {
    default: {
      value: visibilitySettings.show_spend_markup,
      updateKey: 'show_spend_markup'
    }
  },
  [reportSubtitles.SpendContractSettings]: {
    table: {
      value: visibilitySettings.show_contract_spend_settings,
      updateKey: 'show_contract_spend_settings'
    }
  },
  [reportSubtitles.SpendComprehensiveTotals]: {
    table: {
      value: visibilitySettings.show_comprehensive_monthly_combined_aggregate_table,
      updateKey: 'show_comprehensive_monthly_combined_aggregate_table'
    },
  },
  [reportSubtitles.SpendComprehensivePOs]: {
    table: {
      value: visibilitySettings.show_comprehensive_po_committed_spend_table,
      updateKey: 'show_comprehensive_po_committed_spend_table'
    },
  },
  [reportSubtitles.SpendComprehensivePaidTotals]: {
    table: {
      value: visibilitySettings.show_comprehensive_monthly_actual_aggregate_table,
      updateKey: 'show_comprehensive_monthly_actual_aggregate_table'
    },
  },
  [reportSubtitles.SpendComprehensivePaidPOs]: {
    table: {
      value: visibilitySettings.show_comprehensive_po_actual_spend_table,
      updateKey: 'show_comprehensive_po_actual_spend_table'
    },
  },

  [reportSubtitles.SpendComprehensiveNonPOTransactions]: {
    table: {
      value: visibilitySettings.show_comprehensive_individual_spend_table,
      updateKey: 'show_comprehensive_individual_spend_table'
    },
  },
  [reportSubtitles.SpendConsumableTotals]: {
    table: {
      value: visibilitySettings.show_consumable_monthly_combined_aggregate_table,
      updateKey: 'show_consumable_monthly_combined_aggregate_table'
    },
  },
  [reportSubtitles.SpendConsumablePOs]: {
    table: {
      value: visibilitySettings.show_consumable_po_committed_spend_table,
      updateKey: 'show_consumable_po_committed_spend_table'
    },
  },
  [reportSubtitles.SpendConsumablePaidTotals]: {
    table: {
      value: visibilitySettings.show_consumable_monthly_actual_aggregate_table,
      updateKey: 'show_consumable_monthly_actual_aggregate_table'
    },
  },
  [reportSubtitles.SpendConsumablePaidPOs]: {
    table: {
      value: visibilitySettings.show_consumable_po_actual_spend_table,
      updateKey: 'show_consumable_po_actual_spend_table'
    },
  },
  [reportSubtitles.SpendConsumableNonPOTransactions]: {
    table: {
      value: visibilitySettings.show_consumable_individual_spend_table,
      updateKey: 'show_consumable_individual_spend_table'
    },
  },

  [reportSubtitles.SpendManagedTotals]: {
    table: {
      value: visibilitySettings.show_managed_monthly_combined_aggregate_table,
      updateKey: 'show_managed_monthly_combined_aggregate_table'
    },
  },
  [reportSubtitles.SpendManagedPOs]: {
    table: {
      value: visibilitySettings.show_managed_po_committed_spend_table,
      updateKey: 'show_managed_po_committed_spend_table'
    },
  },
  [reportSubtitles.SpendManagedPaidTotals]: {
    table: {
      value: visibilitySettings.show_managed_monthly_actual_aggregate_table,
      updateKey: 'show_managed_monthly_actual_aggregate_table'
    },
  },
  [reportSubtitles.SpendManagedPaidPOs]: {
    table: {
      value: visibilitySettings.show_managed_po_actual_spend_table,
      updateKey: 'show_managed_po_actual_spend_table'
    },
  },
  [reportSubtitles.SpendManagedNonPOTransactions]: {
    table: {
      value: visibilitySettings.show_managed_individual_spend_table,
      updateKey: 'show_managed_individual_spend_table'
    },
  },

});

