import React, { memo, useEffect, useState } from "react";

// Own
import { AdminForm } from "../AdminForm";
import { FileInputControl } from "components/Common/Components/FileInputControl";
import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";
import { FormItem, AdminAction, GroupedFormItems } from "interfaces/form.interface";
import { FORM_ROW_ID } from "components/AdminPanel/Models/AdminPanel.model";
import { getSingleImageUploader } from "components/Common/Components/Input/FileUploader.js"
import {
  StyledAdminFormImagesContainer
} from 'components/Common/Components/FileInputControl/FileInputControlStyles';

export interface ImageConfig {
  imageKey: string;
  onUpload: (file: any, progress: any) => void;
  onClear: () => void;
  imageCaption: string;
  uploader?: any;
}
interface AdminImageFormProps<T> {
  data: T;
  meta: FieldMetaGroup;
  formMeta: (FormItem | GroupedFormItems)[];
  formActions?: AdminAction[];
  onUpdate: (oldData: any, data: any) => void;
  onCancel?: (data: any) => void;
  imagesConfig?: ImageConfig[];
  passedOnFieldDataChanged?: Function;
  uploader?: any
  reset?: (reset?: boolean) => void;
  refreshSignal?: any;
}

const AdminImageForm: React.FC<AdminImageFormProps<any>> = ({
  data,
  imagesConfig,
  uploader = getSingleImageUploader,
  reset,
  refreshSignal,
  ...props
}) => {
  //console.log('data: ', data);
  //console.log('imageKey: ', imageKey);

  const [show, setShow] = useState(false);
  useEffect(() => {
    setTimeout(() => setShow(true), 10) // sadly this is currently necessary to stave off the dreaded this._contentReadyAction is not a function error that otherwise occurs
    // whenever this panel refreshes after updating the data.  In time possibly best 
    // to get rid of dx forms altogether and use the form builder with AutoInput that I've created.
  }, [])

  return (
    <>
      {show && data?.id ? <AdminForm data={data} {...props}>
        {data?.id !== FORM_ROW_ID && (
          <StyledAdminFormImagesContainer>
            {
              imagesConfig && imagesConfig.map((config) => (
                <div key={config.imageKey}>
                  <div className="caption">{config.imageCaption}</div>
                  <FileInputControl
                    data={data[config.imageKey]}
                    onClear={config.onClear}
                    onUpload={config.onUpload}
                    uploader={config.uploader || uploader}
                    refreshSignal={refreshSignal}
                  />
                </div>
              )
              )
            }
          </StyledAdminFormImagesContainer>

        )}
      </AdminForm> : null}
    </>

  );
};

export default memo(AdminImageForm);
