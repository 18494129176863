import { tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

// Own
import API, { APIR, getMultipartConfig } from "services/API/API"
import { ParseAPIResponse } from "services/Interface/Interface"
import { ORGANISATION_ROUTE } from "services/API/common/globalAPIs";
import { Organisation } from "components/AdminPanel/Organisation/Interfaces/Organisation";
import { adminPanelType } from "components/AdminPanel/Models/AdminPanel.model";
import { getDataAndMeta, unWrapDataAndMeta, removeReadOnlyFields } from "services/API/API.helper";
import { store } from "store/store";
import { FieldMetaGroup, EntityState } from 'components/Common/Interfaces/Entity.interface';
import { groupFetchPipe } from 'components/AdminPanel/Helpers/Pipeline.helpers';

import * as fromRootActions from "store/actions/root.actions";
import * as actions from "components/AdminPanel/Organisation/Actions/Organisation.actions";

const createOrganisation = (data: Partial<Organisation>, panelId?: string): Promise<EntityState<Organisation>> | any => {
  const { id, ...payload } = data
  return API.post(ORGANISATION_ROUTE, payload)
    .then((response) => {
      const dataAndMeta = getDataAndMeta(response)
      // HT passing panelId so we can set the groupingMode value on the appropriate panel
      store.dispatch(actions.setOrganisationSuccess({ ...dataAndMeta.data }, dataAndMeta.meta, dataAndMeta.options, dataAndMeta.permissions, true, panelId, dataAndMeta.data.id));
      return () => false;
    })
}

const updateOrganisation = (id: string, data: Partial<Organisation>, meta: FieldMetaGroup) => {
  const { logo, ...payload } = removeReadOnlyFields(data, meta);
  return API.patch(`${ORGANISATION_ROUTE}${id}/`, payload).then((response) => {
    store.dispatch(actions.setOrganisationReducer(response.data.data))
  });
}

const groupFetchOrganisation = (organisations: Organisation[]) => {
  const requests = organisations.map((organisation) => APIR.get(`${ORGANISATION_ROUTE}${organisation.id}/`));
  return forkJoin(requests).pipe(
    groupFetchPipe()
  ).subscribe(response => {
    store.dispatch(actions.setGroupOrganisationReducer(response))
  });
}

const deleteOrganisation = (id: string) => {
  return API.delete(`${ORGANISATION_ROUTE}${id}/`).then((response) => {
    store.dispatch(actions.deleteOrganisationReducer(id))
  });
}

export const searchSupplierNames = (queryParams: { [index: string]: string }) => {
  return API.get(`/supplier-name-search/`, { params: queryParams }).then((response) => {
    return response.data
  })
}

export const searchSupplierRefs = (queryParams: { [index: string]: string }) => {
  return API.get(`/supplier-ref-search/`, { params: queryParams }).then((response) => {
    return response.data
  })
}

type supplierLookupParams = {
  name: string
} | {
  supplier_ref: string
}

export const getSupplierByLookup = (params: supplierLookupParams) => {
  // this requires an exact match to return a record
  return API.get(`/supplier-lookup/`, { params: params }).then((response) => {
    if (response.data.length === 0) {
      return null;
    } else {
      return response.data[0] // there will only ever be one result at most
    }
  })
}

const updateOrganisationLogo = (id: any, logo: any) => {
  const getFileObject = () => {
    const fd = new FormData();
    fd.append("logo", logo);
    return fd;
  }

  return APIR.patch<ParseAPIResponse<Organisation>>(`${ORGANISATION_ROUTE}${id}/`, logo ? getFileObject() : { logo: null }, logo ? getMultipartConfig() : {}).pipe(
    unWrapDataAndMeta(),
    tap(({ data }: { data: any }) =>
      store.dispatch(fromRootActions.setBranchField(adminPanelType.organisations, id, 'logo', data.logo))
    )
  )
}

const clearOrganisationLogo = (id: any) => updateOrganisationLogo(id, null);

export default {
  createOrganisation,
  updateOrganisation,
  deleteOrganisation,
  //fetchOrganisation,
  updateOrganisationLogo,
  clearOrganisationLogo,
  groupFetchOrganisation
}