import React from "react";
import { useSelector } from "react-redux";

// Own
import { apiSuccess, openPanelInSelectionMode } from "components/AdminPanel/Helpers/AdminPanel.helper";
import {
    DataGridUpdateEvent,
} from "interfaces/datagrid.interfaces";
import { ContractInterface } from "components/AdminPanel/Contracts/Interfaces/Contract.interface";
import { adminPanelType } from "components/AdminPanel/Models/AdminPanel.model";
import { AdminDataGrid } from "components/AdminPanel/Containers/AdminDataGrid";
import { getPeopleWithAccessActions } from "components/AdminPanel/People/Models/PeopleDetail.model";
import { monthlyMaintenanceDistributionStateForSpecificContract, DistributionRecipientI } from 'components/AdminPanel/Contracts/Interfaces/Contract.interface';
import contractServices from "components/AdminPanel/Contracts/Services/contractService";
import { EntityKeys } from "constants/entity.constants";
import { FieldMetaGroup } from 'components/Common/Interfaces/Entity.interface';
import {
    ColumnPropGroup,
    ColumnProps,
} from "components/ContractInFocus/Interfaces/DataGridColumn.interface";

interface maintenanceReportDistributionViewProps {
    panelId: string;
    masterRecordData: ContractInterface;
    distributionListState: monthlyMaintenanceDistributionStateForSpecificContract;
    getDatagridColumns: (meta: FieldMetaGroup) => ColumnPropGroup;
    dataTestId: string;
    onUnlink?: (item: any) => void;
    masterRecordType?: string;
    selectActionIdentifier?: string;
    onAddLinks?: Function;
    linkPanelTitle?: string;
    handleOnReorder?: (e: any, callback: () => void) => void;
}

const maintenanceReportDistributionView: React.FC<maintenanceReportDistributionViewProps> = ({
    panelId,
    masterRecordData,
    distributionListState,
    getDatagridColumns,
    dataTestId,
    onUnlink,
    masterRecordType,
    selectActionIdentifier,
    onAddLinks,
    linkPanelTitle,
    handleOnReorder
}) => {

    // const cloneMembersFromState = (): DistributionRecipientI[] => {
    //     const data = distributionListState.data || [];
    //     return data.slice();
    // };

    const accessList = distributionListState.data;//cloneMembersFromState();

    const currentMemberIds = accessList.map(m => m.person)

    const handlePersonLink = (): void => {
        //openPanelWithDragMessage(adminPanelType.people);
        masterRecordType && selectActionIdentifier && linkPanelTitle && openPanelInSelectionMode(
            {
                selectActionIdentifier,
                masterRecordType,
                masterRecordData,
                linkedRecordType: adminPanelType.people,
                onAddLinks,
                currentMemberIds,
                linkPanelTitle,
            }
        )
    };

    const linkPeople = selectActionIdentifier ? handlePersonLink : undefined;

    const handleAccessUpdate = (e: DataGridUpdateEvent): void => {
        // @ts-ignore    
        e.cancel = contractServices.updateAccess(
            masterRecordData,
            e.key,
            e.oldData,
            e.newData,
            distributionListState.metaWrapper.putMeta
            //selectPeopleState.recordmeta[e.key]
        ).then(apiSuccess);
    };

    return (
        <AdminDataGrid
            data={accessList}
            editMode="cell"
            meta={distributionListState.metaWrapper.putMeta}
            permissions={distributionListState.metaWrapper.permissions}
            dataTestId={dataTestId}
            getDatagridColumns={getDatagridColumns}
            masterView={false}
            entityKey={EntityKeys.People}
            columnActions={getPeopleWithAccessActions(onUnlink)}
            panelId={panelId}
            dragAndDrop={false}
            onUpdate={handleAccessUpdate}
            onLink={linkPeople}
            toolbarTitle="Explicit Links"
            handleOnReorder={handleOnReorder}
        />
    );
};

export default maintenanceReportDistributionView;
