export type IndexMenu = {
    iconType?: (props: any) => JSX.Element;
    icon: string;
    menu: string;
    name?: string;
    label: {
        value: string;
    },
    path: string;
}

const useIndexMenu = (healthAndSafetyAdmin?: boolean): IndexMenu[] => {
    const baseMenu = [
        {
            icon: "fas fa-building",
            menu: "Sites",
            label: {
                value: "Sites"
            },
            path: "/sites",
        },
        {
            icon: "fas fa-city",
            menu: "Portfolios",
            label: {
                value: "Portfolios"
            },
            path: "/portfolios",
        }]

    const HASMenu = [
        {
            icon: "fas fa-medkit",
            menu: "Health and Safety",
            name: "Health and Safety",
            label: {
                value: "Health and Safety"
            },
            path: `/h-a-s`,
        }
    ]
    if (healthAndSafetyAdmin) {
        return [...baseMenu, ...HASMenu]
    }
    return baseMenu
}

export default useIndexMenu;
