import styled from 'styled-components';
import styles from '../../../../styles/app/common/variables.scss';
export const GeneralDocsGridWrapper = styled.div`
  
    display: ${props => props.theme.layoutType};
    flex-direction: ${props => props.theme.direction};
    justify-content: ${props => props.theme.justifyContent};

    width: 100%
    height: 100%;

    input.dx-texteditor-input {  
      width: 60%!important;   
    }  

    .dx-texteditor {
      width: 100%!important;   
    }

    .dx-toolbar .dx-toolbar-after {  
      padding-left: 0px;  
      width: 100%!important;  
    }  

    .dx-datagrid-search-panel {  
      margin-left: 0px;  
    }      

    .dx-datagrid-header-panel {
      padding-right: 0;
      padding-left: 0;
    }
  }

  gridcell {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiIconButton-root {
    padding: 0px;
  }

  .clickable-icon {
    color: ${styles.grayMedDark};
    &:hover {
      color: ${styles.blueDark};
    }
  }  

  .not-seen {
    color: ${styles.brandGoldDarker};
  }

  .icon-column {
    text-overflow: unset !important;
  }

  .comments--available {
    color: ${styles.brandGoldDarker};
  }

  .comments--not-available {
    color: ${styles.grayMedDark};
  }  

  .dx-datagrid .dx-row > td {
    padding-top: 7px;
    padding-bottom: 7px;
    border-bottom: 0px;
  }

  .dx-datagrid, .dx-datagrid-rowsview {
    border: 0px;
  }

  .dx-datagrid.dx-gridbase-container.dx-datagrid-borders {
    border: 0px;
  }

  .dx-datagrid-search-text {
    color: ${styles.brandGoldDarker};
    background-color: unset;
  }

`;

GeneralDocsGridWrapper.defaultProps = {
  theme: {
    direction: "column",
    layoutType: "flex",
    justifyContent: "space-between"
  }
}