import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { APIR } from "services/API/API";
import Cookies from "universal-cookie";
import { unWrapDataAndMeta } from "../../../services/API/API.helper";
import queryString from "query-string";
import { history } from "helpers/History/History";
import { store } from "store/store";

import * as fromProfileActions from "components/Profile/Actions/Profile.actions";

const cookies = new Cookies();

const PROFILE_ROUTE = "/people/me/";

export const refreshProfile = () => {
  APIR.get(`${PROFILE_ROUTE}?format=json`)
    .pipe(unWrapDataAndMeta())
    .subscribe((response) => {
      store.dispatch(fromProfileActions.getProfileSuccess(response, false));
    });
};

const AutoLogin = () => {
  const dispatch = useDispatch();

  const [status, setStatus] = useState(
    cookies.get("token") ? "unauthenticated" : "no-token"
  );



  const authenticate = () => {
    APIR.get(`${PROFILE_ROUTE}?format=json`)
      .pipe(unWrapDataAndMeta())
      .subscribe((response) => {
        dispatch(fromProfileActions.getProfileSuccess(response, false));

        const queryParams = queryString.parse(window.location.search);

        if (queryParams.forward) {
          // @ts-ignore
          const forward = Buffer.from(queryParams.forward, "base64").toString();
          history.push(forward);
        }

        setStatus("authenticated");
      });
  };

  !!cookies.get("token") && authenticate();

  return <div>{status}</div>;
};

export default AutoLogin;
