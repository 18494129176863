import { updateClasses } from "./middlewares/settings.middleware.js";
import { updateTheme } from "./middlewares/themes.middleware.js";
import { mode } from "../components/Common/Consts/DataMode";
import { dictToArray, arrayToDict } from "../services/API/API.helper";
import { PDFGeneration } from "../components/ContractInFocus/HoC/WithPageContext/Types/Download.types";
import { settingsInitialState } from "./reducers/settings.reducer";

const STORAGE_KEY = "jaguar-tracks-store";

/**
 * Use this function if you need to call a middleware
 * when setting the initial state.
 */
const callMiddlewares = state => {
  // Since a middlwrare is requried to modify the DOM in this case
  // for settings and themes, is necessary to call middleware
  // directly to set the initial state loaded from storage
  updateClasses(state);
  updateTheme(state);
};

/* Use an IIFE to export the persisted state in a variable */
export const persistedState = (() => {
  try {
    const rawState = localStorage.getItem(STORAGE_KEY);
    if (rawState === null) return undefined;
    const state = JSON.parse(rawState);
    callMiddlewares(state);
    return state;
  } catch (err) {
    return undefined;
  }
})();

const persistingPanelsState = panels => {
  const panelArray = dictToArray(panels);
  const persistingPanels = panelArray.filter(panel => panel.mode !== mode.new);

  return {
    ...arrayToDict(persistingPanels)
  };
};

const setInitialisedStateItems = state => {
  return {
    ...state,
    download: {
      status: PDFGeneration.Standby
    },
    // siteDocuments: {
    //   open: false
    // },
    // visits: {
    //   ...state.visits,
    //   modal: undefined
    // },
    settings: settingsInitialState
  };
};

/* Export a method to save state on each store update */
export const saveState = state => {
  // const rawState = JSON.stringify({ profile: state.profile } );
  // localStorage.setItem(STORAGE_KEY, rawState);

  const shouldIgnore = key =>
    ["organisations", "sites", "contracts", "people", "adminPanel"].indexOf(
      key
    ) !== -1;

  try {
    // let t1 = new Date();
    const stateFilter = Object.keys(state).reduce(
      (acc, key) => (shouldIgnore(key) ? acc : { ...acc, [key]: state[key] }),
      {}
    );
    // const stateFilter = JSON.parse(JSON.stringify(state)); // deep clone
    // let t2 = new Date();
    // let dif = t1.getTime() - t2.getTime();
    // let Seconds_from_T1_to_T2 = dif / 1000;
    // let Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2);
    // console.log('Seconds_Between_Dates', Seconds_Between_Dates);

    ["offsidebarOpen", "asideToggled", "horizontal"] // settings - non persist
      .forEach(item => delete stateFilter.settings[item]);
    const persistedState = setInitialisedStateItems(stateFilter);
    const rawState = JSON.stringify(persistedState);
    localStorage.setItem(STORAGE_KEY, rawState);
  } catch (err) {
    // Ignore write errors.
  }
};
