import { Site, contracts } from "components/Sites/Interfaces/Site.inteface";
import { SiteState } from "./Sites.reducers";
import { Dictionary } from "../../Common/Interfaces/Entity.interface";
import { kMaxLength } from "buffer";

export const getLookup = (record: Site) => ({
  value: record.id,
  display_name: `${record.site_ref} - ${record.name}`,
  site_ref: record.site_ref
});

export function getContractSiteFromSiteContracts(data: Dictionary<Site>, contractId: number): string | null {
  const sites = Object.entries(data)
  let i;
  let siteId = null;
  for (i = 0; i < sites.length; i++) {
    let [site, siteInfo] = sites[i];
    let siteContractsIds = siteInfo.site_contracts.map(c => c.id);
    if (siteContractsIds.includes(contractId)) {
      siteId = site;
      break; // could return here but Typescript gets upset if you do, even with a 'fallback' return at the end of the function
    }
  }
  return siteId
}