import { tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

// Own
import API, { APIR, getMultipartConfig } from "services/API/API"
import { ParseAPIResponse } from "services/Interface/Interface"
import { SITE_ROUTE } from "services/API/common/globalAPIs";
//import { AdminSite } from "components/AdminPanel/Sites/Interfaces/Sites.interface";
import { Site } from "components/Sites/Interfaces/Site.inteface";
import { adminPanelType } from "components/AdminPanel/Models/AdminPanel.model";
import { getDataAndMeta, unWrapDataAndMeta, removeReadOnlyFields } from "services/API/API.helper";
import { store } from "store/store";
import { FieldMetaGroup, EntityState } from 'components/Common/Interfaces/Entity.interface';
import { groupFetchPipe } from 'components/AdminPanel/Helpers/Pipeline.helpers';
import { ContractInterface } from 'components/AdminPanel/Contracts/Interfaces/Contract.interface';
import { CONTRACT_ROUTE } from "services/API/common/globalAPIs";

import * as fromRootActions from "store/actions/root.actions";
import * as adminSitesActions from "components/AdminPanel/Sites/Actions/AdminPanelSites.actions";
import * as sitesActions from "components/AdminPanel/Sites/Actions/AdminPanelSites.actions";

const createSite = (data: Partial<Site>, panelId?: string): Promise<EntityState<Site>> | any => {
  const { id, ...payload } = data
  return API.post(SITE_ROUTE, payload)
    .then((response) => {
      const dataAndMeta = getDataAndMeta(response);
      store.dispatch(
        sitesActions.setSiteSuccess(
          { ...dataAndMeta.data }, dataAndMeta.meta, dataAndMeta.options, dataAndMeta.permissions, true, panelId, dataAndMeta.data.id
        )
      );
      return () => false;
    })
}

const updateSite = (site_ref: any, data: Partial<Site>, meta: FieldMetaGroup) => {
  const { site_image, ...payload } = removeReadOnlyFields(data, meta);
  return API.patch(`${SITE_ROUTE}${site_ref}/`, payload).then((response) => {
    store.dispatch(sitesActions.setSiteReducer({ ...response.data.data }))
    store.dispatch(sitesActions.fetchSites()) // sites for index...
  });
}

export const sitesObservable = () => APIR.get(SITE_ROUTE);

const fetchSite = (id: string) => {
  return APIR.get(`${SITE_ROUTE}${id}/`).pipe(
    unWrapDataAndMeta<Site>()
  ).toPromise().then((response) => {
    store.dispatch(sitesActions.setSiteSuccess(response.data, response.meta, response.options, response.permissions))
    store.dispatch(sitesActions.setSiteContractsReducer(response.data.site_contracts.map(contract => contract.id), response.data.id));
    return response;
  });
}

export const groupFetchSite = (sites: Site[]) => {
  const extractContracts = (acc: any, item: any) => ({
    contracts: {
      ...acc.contracts,
      [item.data.data.id]: item.data.data.site_contracts.map((contract: any) => contract.id)
    }
  });
  const requests = sites.map((site) => APIR.get(`${SITE_ROUTE}${site.site_ref}/`));
  return forkJoin(requests).pipe(
    groupFetchPipe(extractContracts)
  ).subscribe(response => {
    store.dispatch(adminSitesActions.setGroupSiteReducer(response))
  });
}

const deleteSite = (site_ref: string, siteId: any) => {
  return API.delete(`${SITE_ROUTE}${site_ref}/`).then((response) => {
    store.dispatch(sitesActions.deleteSiteReducer(siteId))
  });
}

const updateSiteImage = (id: any, siteRef: any, site_image: any) => {
  const getFileObject = () => {
    const fd = new FormData();
    fd.append("site_image", site_image);
    return fd;
  }

  return APIR.patch<ParseAPIResponse<Site>>(`${SITE_ROUTE}${siteRef}/`, site_image ? getFileObject() : { site_image: null }, site_image ? getMultipartConfig() : {}).pipe(
    unWrapDataAndMeta(),
    tap(({ data }: { data: any }) =>
      store.dispatch(fromRootActions.setBranchField(adminPanelType.sites, id, 'site_image', data.site_image))
    )
  )
}

const linkContractToSite = (contract: ContractInterface, siteId: any) => {
  APIR.patch(`${CONTRACT_ROUTE}${contract.contract_ref}/`, { site: siteId }).subscribe(
    () => store.dispatch(sitesActions.setSiteContractsReducer([contract.id, ...store.getState().sites.contracts[siteId]], siteId))
  )
}

const clearSiteImage = (id: any, siteRef: any) => updateSiteImage(id, siteRef, null);

export default {
  createSite,
  updateSite,
  deleteSite,
  updateSiteImage,
  clearSiteImage,
  groupFetchSite,
  linkContractToSite
}