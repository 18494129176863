import { appReducer } from "./reducers";
import {
  SET_BRANCH_FIELD,
  UNFOCUS_BRANCH,
  REPLACE_ENTITY_BY_ID,
  REMOVE_ENTITY,
  SET_ENTITY, 
  NEW_ENTITY, 
  RESET_ENTITY, 
  NEW_RECORD_CHANGE_TAB, 
  ROOT_RESET_STATE,
} from "../actions/root.actions";
import { IDHelper } from "../../components/Common/Utils/Key";
import { mode } from "../../components/Common/Consts/DataMode";
import { arrayToDict } from "../../services/API/API.helper";
import { branchHelper, resetMetaSubmit } from "./reducer.helper";
import { branchSublistReducer } from "./branchSublist.reducer";

export const rootReducer = (state: any, action: any) => {
  switch (action.type) {
    case SET_ENTITY.start: {
      const newState = {
        ...state,
        [action.branch]: {
          ...state[action.branch],
          data: {
            ...state[action.branch].data,
            [action.data.id]: action.data
          }
        }
      };

      return appReducer(newState, action);
    }

    case NEW_ENTITY.start: {

      const newId = IDHelper();

      const newState = {
        ...state,
        adminPanel: {
          ...state.adminPanel,
          panels: {
            ...state.adminPanel.panels,
            [action.panelId]: {
              ...state.adminPanel.panels[action.panelId],
              recordId: newId,
              mode: mode.new
            }
          }
        },
        [action.branch]: {
          ...state[action.branch],
          data: {
            ...state[action.branch].data,
            [newId]: { ...action.newEntity, id: newId }
          },
          meta: {
            ...resetMetaSubmit(state, action.branch)
          }
        }
      };

      return appReducer(newState, action);
    }

    case REMOVE_ENTITY.reducer: {
      const data = { ...state[action.branch].data };

      delete data[action.recordId];

      return branchHelper(state, action, { data });
    }

    case UNFOCUS_BRANCH.start: {
      const data = { ...state[action.branch].data };

      const unfocusedData = arrayToDict(Object.keys(data).map(key => ({...data[key], focused: false })));

      return branchHelper(state, action, { data: unfocusedData });
    }

    case REPLACE_ENTITY_BY_ID.start: {
      const data = { ...state[action.branch].data, [action.data.id]: action.data };
      delete data[action.recordId];

      const panel = { ...state.adminPanel.panels[action.panelId], recordId: action.data.id };

      const newState = {
        ...state,
        adminPanel: {
          ...state.adminPanel,
          panels: {
            ...state.adminPanel.panels,
            [action.panelId]: panel
          }
        },
        [action.branch]: {
          ...state[action.branch],
          data: { ...data },
          meta: {
            ...state[action.branch].meta,
            PUT: action.meta
          }
        }
      };

      return appReducer(newState, action);
    }

    case SET_BRANCH_FIELD.reducer: {
      const newState = {
        ...state,
        [action.branch]: {
          ...state[action.branch],
          data: {
            ...state[action.branch].data,
            [action.recordId]: { 
              ...state[action.branch].data[action.recordId],
              [action.field]: action.value 
            }
          },
        }
      };

      return appReducer(newState, action);   
    }

    case RESET_ENTITY.start: {
      const entity = { 
        data: {
          ...state[action.branch].data,
          [action.recordId]: {
            ...state[action.branch].data[action.recordId].initialState,
            focused: true,
            initialState: state[action.branch].data[action.recordId].initialState
          }
        }
      }
      return branchHelper(state, action, entity);
    }    

    case NEW_RECORD_CHANGE_TAB.start: {
      const newId = IDHelper();

      const panel = { 
        ...state.adminPanel.panels[action.panelId], 
        mode: mode.new, 
        panelType: action.branch,
        recordId: newId
      };

      const newState = {
        ...state,
        adminPanel: {
          ...state.adminPanel,
          ...(action.activePanelId ? {activePanelId: action.activePanelId} : {} ),
          panels: {
            ...state.adminPanel.panels,
            [action.panelId]: panel
          }
        },
        [action.branch]: {
          ...state[action.branch],
          data: {
            ...state[action.branch].data,
            [newId]: { ...action.data, id: newId }
          },
        }
      };

      return appReducer(newState, action);      
    }

    case ROOT_RESET_STATE.reducer: {
      return appReducer(undefined, action);      
    }

    default:
      return branchSublistReducer(state, action);
  }
}
