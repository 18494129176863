import { RightVisibilityMenuT } from "../../interfaces/personalContractSettings.interfaces";
import { MainInFocusVisibilitySettings } from "../../interfaces/contractInFocus.interfaces";
import { GetRightVisibilityMenu } from "components/Common/Components/RightVisibilityMenu/Interfaces/RightContext.interfaces";


export const ppmRightVisibilityMenu = ({ visibilitySettings }: GetRightVisibilityMenu): RightVisibilityMenuT => ({
  //usually this takes personalVisibilitySettings, but to calculate if the PPM/RM pages
  //  should be in the menu at all for people who cannot adjust the contractVisibilitySettings, it may take the stored contractVisibilitySettings
  "Total": {
    graph: {
      value: visibilitySettings.show_ppm_total_graph,
      updateKey: 'show_ppm_total_graph'
    },
    table: {
      value: visibilitySettings.show_ppm_total_table,
      updateKey: 'show_ppm_total_table'
    }
  },
  "SLA": {
    graph: {
      value: visibilitySettings.show_ppm_sla_graph,
      updateKey: 'show_ppm_sla_graph'
    },
    table: {
      value: visibilitySettings.show_ppm_sla_table,
      updateKey: 'show_ppm_sla_table'
    }
  },
  "Statutory": {
    graph: {
      value: visibilitySettings.show_ppm_statutory_graph,
      updateKey: 'show_ppm_statutory_graph'
    },
    table: {
      value: visibilitySettings.show_ppm_statutory_table,
      updateKey: 'show_ppm_statutory_table'
    },
  },
  "Fabric": {
    graph: {
      value: visibilitySettings.show_ppm_fabric_graph,
      updateKey: 'show_ppm_fabric_graph'
    },
    table: {
      value: visibilitySettings.show_ppm_fabric_table,
      updateKey: 'show_ppm_fabric_table'
    },
  },

  "Open": {
    table: {
      value: visibilitySettings.show_ppm_open_table,
      updateKey: 'show_ppm_open_table'
    }
  }
});

export const rmRightVisibilityMenu = ({ visibilitySettings }: GetRightVisibilityMenu): RightVisibilityMenuT => ({
  "Total": {
    graph: {
      value: visibilitySettings.show_rm_total_graph,
      updateKey: 'show_rm_total_graph'
    },
    table: {
      value: visibilitySettings.show_rm_total_table,
      updateKey: 'show_rm_total_table'
    },
  },
  "Reactive SLA": {
    graph: {
      value: visibilitySettings.show_rm_sla_graph,
      updateKey: 'show_rm_sla_graph'
    },
    table: {
      value: visibilitySettings.show_rm_sla_table,
      updateKey: 'show_rm_sla_table'
    }
  },
  "Total Issues": {
    graph: {
      value: visibilitySettings.show_rm_total_issues_graph,
      updateKey: 'show_rm_total_issues_graph'
    },
    table: {
      value: visibilitySettings.show_rm_total_issues_table,
      updateKey: 'show_rm_total_issues_table'
    },
  },
  "Total Issue Hours": {
    graph: {
      value: visibilitySettings.show_rm_total_issue_hours_graph,
      updateKey: 'show_rm_total_issue_hours_graph'
    },
    table: {
      value: visibilitySettings.show_rm_total_issue_hours_table,
      updateKey: 'show_rm_total_issue_hours_table'
    },
  },
  "Open": {
    table: {
      value: visibilitySettings.show_rm_open_table,
      updateKey: 'show_rm_open_table'
    }
  }
});