import { FORM_ROW_ID } from "components/AdminPanel/Models/AdminPanel.model"
import { variableGroupingColumnName, creatingRecordGroupValue, newRecordGroupValue, oldRecordGroupValue } from "constants/grid.constants";

export const removePsuedoRecord = (data: any) => {
  const newData = { ...data };
  delete newData[FORM_ROW_ID];
  return newData;
};

export const removePsuedoRecordFromState = (state: any) => {
  return {
    ...state,
    data: (removePsuedoRecord(state.data))
  }
};

export function mergeChildObjectsByKey(origData: any, newData: any) {
  const mergedData = {}
  for (const [key, value] of Object.entries(origData)) {
    // merge the objects individually rather than replacing them with {...origData, ...newData}
    //@ts-ignore
    mergedData[key] = { ...value, ...newData[key] }
  }
  return mergedData
}

export function addNewGroupStatuses(data: any, createdObj: any) {
  let newObj = { ...createdObj }
  for (const [key, value] of Object.entries(data)) {
    //@ts-ignore
    //delete value[variableGroupingColumnName]
    value[variableGroupingColumnName] = oldRecordGroupValue;
  }
  newObj = {
    ...newObj,
    [variableGroupingColumnName]: newRecordGroupValue
  }
  return { "preparedObj": newObj, "preparedData": data }
}

export function prepareMainListViewsAfterCreate(data: any, createdObj: any, action: any) {
  //@ts-ignore
  if (action.insert) {
    const newData = removePsuedoRecord(data);
    return addNewGroupStatuses(newData, createdObj)
  }
  return { "preparedObj": createdObj, "preparedData": data }
}

export const createPsuedoRecord = (state: any) => {
  return {
    ...state,
    data: {
      [FORM_ROW_ID]: {
        id: FORM_ROW_ID,
        logo: {},
        avatar: {},
        site_image: {},
        [variableGroupingColumnName]: creatingRecordGroupValue
      },
      ...state.data
    },
    recordmeta: {
      ...state.recordmeta,
      [FORM_ROW_ID]: { ...state.meta.POST },
    },
  };
};

