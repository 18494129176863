import { APIR } from "../../../../services/API/API";
import { ContractPeriod } from "../../ContractPeriods/Interfaces/ContractPeriod.interface";
import { ContractInterface } from "../Interfaces/Contract.interface";
import { recordDate } from "../../../Common/Utils/Dates";

export const ROUTE = "/contracts/";

export const transformContractPeriods = (contractPeriods: ContractPeriod[]) => (
  contractPeriods.map(contractPeriod => ({
    ...contractPeriod,
    start_date_string: recordDate(contractPeriod.start_date),
    end_date_string: recordDate(contractPeriod.end_date),
  }))
);

export const transformContractPeriod = (contractPeriod: ContractPeriod) => ({
  ...contractPeriod,
  start_date_string: recordDate(contractPeriod.start_date),
  end_date_string: recordDate(contractPeriod.end_date),
});

export const transformContract = (contract: ContractInterface) => {
  return ({
    ...contract,
    account_manager: contract.account_manager ? contract.account_manager.id : null,
    business_unit_head: contract.business_unit_head ? contract.business_unit_head.id : null,
    site_manager: contract.site_manager ? contract.site_manager.id : null,
    client_contact: contract.client_contact ? contract.client_contact.id : null,
  })
};