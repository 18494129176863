import { createRequestActionTypes } from "../../../components/Common/Utils/ReduxUtil";

export const SET_SCREEN_WIDTH = "COMMON_SET_SCREEN_WIDTH";

export const SET_ROUTE = createRequestActionTypes('COMMON_SET_ROUTE');
export const setRoute = (route) => ({ type: SET_ROUTE.reducer, payload: { route } });

export const SET_PROJECT_CONFIG = createRequestActionTypes('COMMON_SET_PROJECT_CONFIG');
export const setProjectConfig = (config) => {
    return { type: SET_PROJECT_CONFIG.reducer, payload: { config } }
};

export const FETCH_PROJECT_CONFIG = createRequestActionTypes('COMMON_FETCH_PROJECT_CONFIG');
export const fetchProjectConfig = () => ({ type: FETCH_PROJECT_CONFIG.start });
