import React, { useState } from 'react';
import moment from 'moment';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

// Own
import ConfirmationModal from 'components/Common/Components/Modal/Modal';
import MonthYearSelector from 'components/Common/Components/MonthYearSelector/MonthYearSelector';
import { ModalProps } from 'components/Common/Components/Modal/interfaces/modal.interfaces';
import { dateUDF, fromSaveDateFormat, DISPLACE_ZERO_STARTING_MONTH } from 'components/Common/Utils/Dates';
import { Snapshot } from 'components/ContractInFocus/interfaces/contractInFocus.interfaces';
import { ContractPeriod, PortfolioPeriod } from "components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface";
import { transformStringDatesToDateSelectMap } from 'components/Common/Components/MonthYearSelector/helpers/monthYearSelector.helper';
import { Dictionary } from 'components/Common/Interfaces/Entity.interface';

interface ContractSnapshotProps extends ModalProps {
  selectedPeriod: ContractPeriod | PortfolioPeriod;
  snapshotHistory: Dictionary<Snapshot>;
  onFreezeInfo: (event: React.MouseEvent, date: Date) => void;
  snapshotCreationInProgress: boolean;
}

const today = moment().toDate();

export default ({
  isOpen = true,
  onCancel,
  selectedPeriod,
  onFreezeInfo,
  snapshotHistory,
  snapshotCreationInProgress
}: ContractSnapshotProps) => {

  const contractFreezeTitle = 'Create a new snapshot';
  //const contractFreezeOkCaption = 'Create Snapshot';
  const contractFreezeButtonCaption = () => {
    if (getSnapshotByMonth(date.month, date.year)) {
      return "Overwrite Snapshot"
    }
    return "Create Snapshot"
  }

  const snapshotDates = transformStringDatesToDateSelectMap(
    Object.keys(snapshotHistory).map(key => `${snapshotHistory[key].frozen_for}`)
  );

  //const [date, setDate] = useState({ month: today.getMonth() + DISPLACE_ZERO_STARTING_MONTH, year: today.getFullYear() });
  const [date, setDate] = useState<{ month: number | undefined, year: number | undefined }>({ month: undefined, year: undefined });
  const [freezeButtonCaption, setFreezeButtonCaption] = useState("");
  const [buttonActive, activateButton] = useState(false);

  const getSnapshotByMonth = (month: number | undefined, year: number | undefined): Snapshot | undefined => {
    const key = Object.keys(snapshotHistory).find(snapshotId => {
      const snapshotDate = fromSaveDateFormat(snapshotHistory[snapshotId].frozen_for);
      // next line needs +1 as it appears getMonth is 0 indexed..
      return snapshotDate.getMonth() + DISPLACE_ZERO_STARTING_MONTH === month && snapshotDate.getFullYear() === year
    });
    return key ? snapshotHistory[key] : undefined;
  }

  const handleFreezeInformation = (event: React.MouseEvent): void => {
    const humanReadableMonth = date.month;
    const endDate = moment(date.year + '-' + (humanReadableMonth) + '-01', dateUDF).endOf('month').toDate();
    let freezeForDate = endDate;
    const CPEndDate = moment(selectedPeriod.end_date).toDate();
    if (endDate > CPEndDate && CPEndDate.getMonth() == endDate.getMonth()) {
      freezeForDate = CPEndDate // this is to allow us to have a separate freeze point for the previous contract period.
    }
    onFreezeInfo(event, freezeForDate);
  };

  const logX = (label: string, x: any) => {
    return true;
  }

  const thisCancel = (e: any) => {
    setDate({ month: undefined, year: undefined });
    onCancel && onCancel(e);
  }

  return (
    <>
      <ConfirmationModal
        id="contractFreeze"
        isOpen={isOpen}
        title={contractFreezeTitle}
        onCancel={thisCancel}
        onConfirmation={handleFreezeInformation}
        size="md"
        okCaption={contractFreezeButtonCaption()}
        disabled={!buttonActive || snapshotCreationInProgress}
        actionInProgress={snapshotCreationInProgress}
      >
        {selectedPeriod && logX('month', date.month) && logX('year', date.year) &&
          <MonthYearSelector
            startDate={new Date(selectedPeriod.start_date)}
            endDate={new Date(selectedPeriod.end_date)}
            month={undefined} //{date.month}
            year={undefined} //{date.year}
            highlightDates={snapshotDates}
            highlightIcon={PhotoCamera}
            highlightIconSize={16}
            onSelect={(month: number | undefined, year: number | undefined) => setDate({ month, year })}
            activateButton={activateButton}
          />
        }
      </ConfirmationModal>
    </>
  );
}