import { createRequestActionTypes } from "../../../Common/Utils/ReduxUtil";
import { Person, contractRelationType } from "../Interfaces/People.interface";
import { ContractInterface, AccessibleContractI } from "../../Contracts/Interfaces/Contract.interface";
import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";
import { APIPrivileges } from "services/Interface/Interface";

//HT Note: can't put this everywhere but just generally somewhat concerned about the level of apparent DRY violation across people, organisations etc, 
// including actions, reducers etc.

/* Create */
export const CREATE_PERSON = createRequestActionTypes('CREATE_PERSON');

// Where is this createPerson action called?  It dispatches the type CREATE_PERSON.start which is set to trigger an epic, but peopleServices calls setPersonSuccess
// (feels pretty confusing) and I haven't been able to track down where this createPerson action is dispatched...
export const createPerson = (panelId: string, person: Person) => ({ type: CREATE_PERSON.start, panelId, person });
export const createPersonSuccess = (panelId: string, recordId: any, data: Person, meta: object) => ({ type: CREATE_PERSON.success, recordId, panelId, data, meta });
export const createPersonError = (error: object) => ({ type: CREATE_PERSON.error, error });

/* Fetch All */
export const FETCH_PEOPLE = createRequestActionTypes('FETCH_PEOPLE');

export const fetchPeople = () => ({ type: FETCH_PEOPLE.start });
export const fetchPeopleSuccess = (people: Person[]) => ({ type: FETCH_PEOPLE.success, people });
export const fetchPeopleError = (error: object) => ({ type: FETCH_PEOPLE.error, error });

/* Add Polyfil People */
export const ADD_TO_PEOPLE = createRequestActionTypes('ADD_TO_PEOPLE');
export const addToPeople = (data: Person[], meta: FieldMetaGroup) => ({ type: ADD_TO_PEOPLE.reducer, people: { data, meta } });

/* Fetch One */
export const FETCH_PERSON = createRequestActionTypes('FETCH_PERSON');

export const fetchPerson = (panelId: string, id: number, changeTab?: string) => ({ type: FETCH_PERSON.start, panelId, id, changeTab });
export const fetchPersonSuccess = (person: Person) => ({ type: FETCH_PERSON.success, person });
export const fetchPersonError = (error: object) => ({ type: FETCH_PERSON.error, error });

/* Update */
export const UPDATE_PERSON = createRequestActionTypes('UPDATE_PERSON');

export const updatePerson = (id: number, person: Person) => ({ type: UPDATE_PERSON.start, id, person });
export const updatePersonSuccess = (data: Person) => ({ type: UPDATE_PERSON.success, data });
export const updatePersonError = (error: object) => ({ type: UPDATE_PERSON.error, error });

/* Update Avatar */
export const UPDATE_AVATAR = createRequestActionTypes('UPDATE_PERSON_AVATAR');

export const updatePersonAvatar = (panelId: string, id: number, avatar: object) => ({ type: UPDATE_AVATAR.start, panelId, id, avatar });
export const updatePersonAvatarSuccess = (id: number, avatar: object) => ({ type: UPDATE_AVATAR.success, id, avatar });
export const updatePersonAvatarError = (error: object) => ({ type: UPDATE_AVATAR.error, error });

/* Delete */
export const DELETE_PERSON = createRequestActionTypes('DELETE_PERSON');

export const deletePerson = (person: Person, panelId: string) => ({ type: DELETE_PERSON.start, person, panelId });
export const deletePersonSuccess = (personId: number, panelId: string) => ({ type: DELETE_PERSON.success, personId, panelId });
export const deletePersonReducer = (personId: any) => ({ type: DELETE_PERSON.reducer, personId });

/* Link */

export const LINK_PERSON_TO_ORGANISATION = createRequestActionTypes('PEOPLE_LINK_PERSON_TO_ORGANISATION');

export const linkPersonToOrganisation = (panelId: string, person: Person, organisationId: number) =>
  ({ type: LINK_PERSON_TO_ORGANISATION.start, panelId, person, organisationId });
export const linkPersonToOrganisationSuccess = () =>
  ({ type: LINK_PERSON_TO_ORGANISATION.success });

/* Unlink */
export const UNLINK_PERSON_TO_ORGANISATION = createRequestActionTypes('PEOPLE_UNLINK_PERSON_TO_ORGANISATION');

export const unlinkPersonToOrganisation = (panelId: string, person: Person, organisationId: number) => ({ type: UNLINK_PERSON_TO_ORGANISATION.start, panelId, person, organisationId });
export const unlinkPersonToOrganisationSuccess = () => ({ type: UNLINK_PERSON_TO_ORGANISATION.success });
export const unlinkPersonToOrganisationError = (error: any) => ({ type: UNLINK_PERSON_TO_ORGANISATION.error, error });

/* Set */
export const SET_PERSON = createRequestActionTypes('SET_PERSON');

export const setPerson = (id: number, person: Person) => ({ type: SET_PERSON.start, id, person });
export const setPersonSuccess = (person: Person, meta: any, options: string[] = [], permissions?: APIPrivileges, insert?: boolean, panelId?: string, recordId?: string | number) =>
  ({ type: SET_PERSON.success, person, meta, options, permissions, insert, panelId, recordId });
export const setPersonError = (error: object) => ({ type: SET_PERSON.error, error });
export const setPersonReducer = (person: Person) => ({ type: SET_PERSON.reducer, person });

export const CREATE_PSUEDO_PERSON = createRequestActionTypes('CREATE_PSUEDO_PERSON');
export const createPsuedoPerson = () => ({ type: CREATE_PSUEDO_PERSON.reducer });

export const CLEAR_PSUEDO_PERSON = createRequestActionTypes('CLEAR_PSUEDO_PERSON');
export const clearPsuedoPerson = () => ({ type: CLEAR_PSUEDO_PERSON.reducer });

/* Set */
export const SET_PERSON_JOBTITLE = createRequestActionTypes('SET_PERSON_JOBTITLES');
export const setPersonJobTitles = (jobTitles: string[]) => ({ type: SET_PERSON_JOBTITLE.reducer, jobTitles });

export const SET_GROUP_PERSON = createRequestActionTypes('SET_GROUP_PERSON');
export const setGroupPersonReducer = (personGroup: any) => ({ type: SET_GROUP_PERSON.reducer, personGroup });


export const FETCH_ACCESSIBLE_CONTRACTS_FOR_PERSON = createRequestActionTypes("FETCH_ACCESSIBLE_CONTRACTS_FOR_PERSON");
export const storeAccessibleContractsForPerson = (personId: number | string, accessibleContracts: AccessibleContractI[], meta: FieldMetaGroup, putMeta: FieldMetaGroup, permissions?: APIPrivileges) =>
  ({ type: FETCH_ACCESSIBLE_CONTRACTS_FOR_PERSON.reducer, personId, accessibleContracts, meta, putMeta, permissions });

export const UPDATE_ACCESSIBLE_CONTRACTS = createRequestActionTypes("UPDATE_ACCESSIBLE_CONTRACTS");
export const updateAccessibleContractsReducer = (personId: number | string, accessibleContracts: AccessibleContractI[]) =>
  ({ type: UPDATE_ACCESSIBLE_CONTRACTS.reducer, personId, accessibleContracts });

export const REMOVE_ACCESSIBLE_CONTRACTS = createRequestActionTypes("REMOVE_ACCESSIBLE_CONTRACTS");
export const removeAccessibleContractsReducer = (personId: number | string, accessObjId: string | number) =>
  ({ type: REMOVE_ACCESSIBLE_CONTRACTS.reducer, personId, accessObjId });

export const FETCH_ACCESSIBLE_PORTFOLIOS_FOR_PERSON = createRequestActionTypes("FETCH_ACCESSIBLE_PORTFOLIOS_FOR_PERSON");
export const storeAccessiblePortfoliosForPerson = (personId: number | string, accessiblePortfolios: AccessibleContractI[], meta: FieldMetaGroup, putMeta: FieldMetaGroup, permissions?: APIPrivileges) =>
  ({ type: FETCH_ACCESSIBLE_PORTFOLIOS_FOR_PERSON.reducer, personId, accessiblePortfolios, meta, putMeta, permissions });

export const UPDATE_ACCESSIBLE_PORTFOLIOS = createRequestActionTypes("UPDATE_ACCESSIBLE_PORTFOLIOS");
export const updateAccessiblePortfoliosReducer = (personId: number | string, accessiblePortfolios: AccessibleContractI[]) =>
  ({ type: UPDATE_ACCESSIBLE_PORTFOLIOS.reducer, personId, accessiblePortfolios });

export const REMOVE_ACCESSIBLE_PORTFOLIOS = createRequestActionTypes("REMOVE_ACCESSIBLE_PORTFOLIOS");
export const removeAccessiblePortfoliosReducer = (personId: number | string, accessObjId: string | number) =>
  ({ type: REMOVE_ACCESSIBLE_PORTFOLIOS.reducer, personId, accessObjId });


export const REFRESH_PERSON_META = createRequestActionTypes('REFRESH_PERSON_META');
export const refreshPersonMeta = () => ({ type: REFRESH_PERSON_META.start });
export const refreshPersonMetaSuccess = (meta: any) => ({ type: REFRESH_PERSON_META.success, meta });
export const refreshPersonMetaError = () => ({ type: REFRESH_PERSON_META.error });


// dispatch(fetchLists()); // this needs to be called, but it causes the ui bottom bar to flicker