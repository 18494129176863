import React from "react";
import { useSelector, useDispatch } from "react-redux";

// Own
import organisationService from "components/AdminPanel/Organisation/Services/organisationServices";
import { adminListOrganisationSelector } from "components/AdminPanel/Organisation/Selectors/Organisations.selectors";
import { organisationFilteredDatagridColumns, getOrganisationFilterExpForGrid, getOrganisationFormItems } from "components/AdminPanel/Organisation/Models/OrganisationDetail.model";
import { AdminFilteredDataGrid } from "components/AdminPanel/Containers/AdminFilteredDataGrid";
import {
  DataGridEvent,
  DataGridUpdateEvent,
} from "interfaces/datagrid.interfaces";
import { Organisation } from "components/AdminPanel/Organisation/Interfaces/Organisation";
import { OrganisationDetailView } from "components/AdminPanel/Organisation/Containers/OrganisationDetailView";
import { apiSuccess } from "components/AdminPanel/Helpers/AdminPanel.helper";
import { setPsuedoOrganisation } from "components/AdminPanel/Organisation/Actions/Organisation.actions";

interface OrganisationMasterViewProps {
  panelId: string;
}

// https://supportcenter.devexpress.com/ticket/details/t410004/dxdatagrid-how-to-display-multiple-values-in-a-lookup-column-using-dxtagbox

// How to handle the e.cancel & promises
// https://supportcenter.devexpress.com/ticket/details/t581713/dxdatagrid-how-to-use-promise-all-for-the-cancel-parameter-of-the-onrowupdating-event

const OrganisationMasterView: React.FC<OrganisationMasterViewProps> = ({
  panelId,
}) => {
  const organisationState = useSelector(adminListOrganisationSelector);

  const dispatch = useDispatch();

  const handleUpdate = (e: DataGridUpdateEvent): void => {
    // @ts-ignore
    e.cancel = organisationService
      //.updateOrganisation(e.key, e.newData, organisationState.recordmeta[e.key])
      .updateOrganisation(e.key, e.newData, organisationState.meta.PUT)
      .then(apiSuccess);
  };

  const handleCreate = (e: DataGridEvent): void => {
    // @ts-ignore
    organisationService.createOrganisation(e.data, panelId);
  };

  const handleDelete = (e: DataGridEvent): void => {
    // @ts-ignore
    e.cancel = organisationService.deleteOrganisation(e.key).then(apiSuccess);
  };

  // const handleFetchGroup = (organisations: Organisation[]) => {
  //   const getOrgs = organisationService.groupFetchOrganisation(organisations);
  // };

  // HT Note: but isn't this similar to all other setPsuedos?  What is unique abdout handleCreatePseudo or setPsuedoOrganisation?  
  function handleCreatePseudo() {
    dispatch(setPsuedoOrganisation());
  }

  return (
    <AdminFilteredDataGrid
      data={organisationState.data}
      meta={organisationState.meta}
      permissions={organisationState.permissions}
      recordmeta={organisationState.meta?.PUT}
      panelId={panelId}
      masterView
      dataTestId="organisation-master-view"
      detailView={OrganisationDetailView}
      createFormMeta={getOrganisationFormItems(organisationState.meta)}
      getDatagridColumns={organisationFilteredDatagridColumns}
      getPreFilterExp={getOrganisationFilterExpForGrid}
      onDelete={handleDelete}
      onUpdate={handleUpdate}
      onCreate={handleCreate}
      //onCreatePseudo={handleCreatePseudo}
      //fetchGroup={handleFetchGroup}
      editMode="form"
    />
  );
};

export default OrganisationMasterView;
