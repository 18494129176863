import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Draggable from "react-draggable";
import PropTypes from "prop-types";

import AdminPanelBody from "../../Components/AdminPanelBody/AdminPanelBody";
import AdminPanelHeader from "../../Components/AdminPanelHeader/AdminPanelHeader";
import AdminPanelErrorBoundary from "../AdminPanelErrorBoundary/AdminPanelErrorBoundary";

import * as actions from "../../../../store/actions/actions";

import "./AdminPanel.scss";
import "./../../../../styles/app/common/animate.scss";

const DraggableModal = ({ onDrag, children }) => {
  return (
    <Draggable
      onMouseDown={onDrag}
      handle="#adminPanelHeader"
      bounds={{ top: -10 }}
    >
      {children}
    </Draggable>
  );
};

DraggableModal.propTypes = {
  children: PropTypes.object,
  onDrag: PropTypes.func
};

export class AdminPanel extends Component {
  closeModal = () => this.props.closeModal();

  handleChangeTab = activeTab => {
    this.props.actions.panelChangeEvent(
      this.props.adminPanel.panels[this.props.panelId]
    );
    this.props.actions.setAdminPanelType(this.props.panelId, activeTab);
  };

  // HT currently we're simply setting this in the relevant entity success actions
  // setPanelGroupingMode = (groupingMode) => {
  //   this.props.actions.setAdminPanelGroupingMode(this.props.panelId, groupingMode);
  // }

  handleClonePanel = _ =>
    this.props.actions.cloneAdminPanel(this.props.panelId);

  static propTypes = {
    adminPanel: PropTypes.object,
    closeModal: PropTypes.func,
    actions: PropTypes.object,
    panelId: PropTypes.string,
    active: PropTypes.bool,
    activatePanel: PropTypes.func,
    activeTabId: PropTypes.string,
    index: PropTypes.number,
    selectionConfig: PropTypes.object,
    onlyShowTabs: PropTypes.array
  };

  getStyles = () => ({
    zIndex: this.props.active && "3001",
    left: `${this.props.index * 100}px`
  });

  render() {
    //console.log("admin Panel selection config: ", this.props.selectionConfig);
    return (
      <DraggableModal onDrag={this.props.activatePanel}>
        <div id="adminPanel" style={this.getStyles()}>
          <div className="admin-container">
            <AdminPanelHeader
              onClone={() => {
                this.handleClonePanel();
              }}
              onClose={this.closeModal.bind(this)}
              onChangeTab={this.handleChangeTab.bind(this)}
              activeTabId={this.props.activeTabId}
              panelId={this.props.panelId}
              selectionConfig={this.props.selectionConfig}
              onlyShowTabs={this.props.onlyShowTabs}
            />
            <AdminPanelErrorBoundary>
              <AdminPanelBody
                activeTabId={this.props.activeTabId}
                id={this.props.panelId}
              />
            </AdminPanelErrorBoundary>
          </div>
        </div>
      </DraggableModal>
    );
  }
}

const mapStateToProps = state => ({ adminPanel: state.adminPanel });
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel);
