import { currencyColumnFormat } from 'constants/grid.constants';

export const spendSimpleColumns = [
  { name: "year_label", width: 75 },
  { name: "period_month", width: 100 },
  //{ name: "po_number", width: 100 },
  { name: "replacement_details" },
  { name: "repair_date", width: 100 },
  { name: "transaction_date", width: 100 },
  { name: "transaction_total_net", width: 100, format: currencyColumnFormat, align: "right" }
]

export const portfolioSpendSimpleColumns = [
  { name: "contract_ref", width: 80, caption: "Contract" },
  { name: "year_label", width: 75 },
  { name: "period_month", width: 100 },
  //{ name: "po_number", width: 100 },
  { name: "replacement_details" },
  { name: "repair_date", width: 100 },
  { name: "transaction_date", width: 100 },
  { name: "transaction_total_net", width: 100, format: currencyColumnFormat, align: "right" }
]


export const contractSpendSettingsColumns = [
  { name: "contract_margin", width: 75 },
  { name: "consumable_budget", width: 100 },
  { name: "comprehensive_budget" },
  { name: "comprehensive_limit", width: 100 },
]

export const spendSimplePOColumns = [
  { name: "po_number", width: 150 },
  { name: "po_raised_date", width: 150 },
  { name: "po_description" },
  { name: "total_cost", width: 150, format: currencyColumnFormat, align: "right" }
]
