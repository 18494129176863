import { createEpicMiddleware, combineEpics } from "redux-observable";
import { catchError } from "rxjs/operators";

import commonEpic from "../Common/Epics/Common.epic";
import contractVisibilitySettingsEpic from "components/ContractInFocus/Epics/contractVisibilitySettings.epic";
import contractInFocusEpic from "components/ContractInFocus/Epics/ContractInFocus.epic";
import organisationEpic from "../../components/AdminPanel/Organisation/Epics/Organisation.epic";
import peopleEpic from "../../components/AdminPanel/People/Epic/People.epic";
import profileEpic from "../../components/Profile/Epics/Profile.epic";
import personalSettingsEpic from "../../components/Profile/Epics/PersonalSettings.epic";
import sitesEpic from "../../components/Sites/Epics/Sites.epic";
import portfoliosEpic from "components/Portfolios/Epics/Portfolios.epic";
import portfolioInFocusEpic from "components/PortfolioInFocus/Epics/PortfolioInFocus.epic";
import allContractsEpic from "../../components/AdminPanel/Contracts/Epics";
import rootEpics from "../epics/root.epic";
import appEpic from "../../App.epic";
import downloadEpic from "../../components/ContractInFocus/HoC/WithPageContext/Epics/Download.epic";

// HT Note: a bit nervous about fat objects like this performance wise as each is composed of many sub epics (especially as the project grows)
// and also just wondering if buried in here might be the cause of some of our multiple refreshes?
// I understand epics are run after the input actions hit 'their' reducers - could any of these further action epics cause another render
// after an update in state from reducers connected to the input action has run and has triggered the start of a render? https://redux-observable.js.org/docs/basics/Epics.html
// That said, I guess if generally the input action isn't mapped to any reducers itself, then that can't happen...? Perhaps that's the architecture strategy here?
// input action => epic => actions => reducers (never or rarely action => reducer at the same time?)

const epics = [
  ...contractVisibilitySettingsEpic,
  ...commonEpic,
  ...contractInFocusEpic,
  ...organisationEpic,
  ...sitesEpic,
  ...portfoliosEpic,
  ...portfolioInFocusEpic,
  ...personalSettingsEpic,
  ...allContractsEpic,
  ...peopleEpic,
  ...appEpic,
  ...profileEpic,
  ...rootEpics,
  ...downloadEpic
];

export const rootEpic = (action$, store$, dependencies) =>
  combineEpics(...epics)(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      console.error(error);
      return source;
    })
  );

export const epicMiddleware = createEpicMiddleware();
