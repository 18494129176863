import React, { useState, useRef, ReactNode, ReactNodeArray, useEffect, useMemo } from "react";
import Form, { Item, RequiredRule, GroupItem } from "devextreme-react/form";

import { Button, DialogActions } from "@material-ui/core";

// Own
import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";
import { AdminAction, FormItem, GroupedFormItems } from "interfaces/form.interface";

// Styles
import "./AdminForm.scss";

function customizeAdminFormItems(item: any) {
  // HT Note: generally this would be good, but DX is manifestly overiding the 'width' parameter with some calculated JS themselves, so it has no effect.
  // Min width does, but it's not what we want.  So this is a good one to know about but doesn't help us here (we need to set width to auto)
  // Instead will need to use some sort of call back JS to override their JS and set the width...
  if (item["editorType"] == "dxSelectBox") {
    //item.editorOptions["width"] = "500px";
    item.editorOptions["dropDownOptions"] = {
      width: "auto", // this is ignored/overridden by DX regardless of whether a straight primitive value or callback is used here
      minWidth: 325 // This works but is not good for our purposes here, will use it for now
    }
  }
}

interface AdminFormProps<T> {
  data: T;
  meta: FieldMetaGroup;
  formMeta: (FormItem | GroupedFormItems)[];
  formActions?: AdminAction[];
  onUpdate: (oldData: any, data: any) => void;
  onCancel?: (data: any) => void;
  children?: ReactNodeArray | ReactNode;
  passedOnFieldDataChanged?: Function;
}

const AdminForm: React.FC<AdminFormProps<any>> = ({
  data,
  meta,
  formMeta,
  formActions = [],
  onUpdate,
  onCancel,
  children,
  passedOnFieldDataChanged,
}) => {
  const labelLocation = "top";

  const formConfig = {
    readOnly: false,
    showColon: false,
    colCount: 2,
  };

  const [formData, setFormData] = useState(data);

  useEffect(() => {
    setFormData(data);
  }, [data])

  const formRef = useRef<any>(null);

  const cancelChanges = (): void => {
    setFormData(data);
    onCancel && onCancel(data);
  };

  const onFormFieldDataChanged = (e: any) => {
    //console.log('component: ', e?.component);
    //console.log('dataField: ', e?.dataField);
    //console.log('value: ', e?.value);
    passedOnFieldDataChanged && passedOnFieldDataChanged(e);
  }

  const getFormData = (): object => formData;

  // useEffect(() => {
  //   console.log('...: ', formRef.current.instance.element());
  //   formRef && formRef.current && formRef.current.instance.on(
  //     "fieldDataChanged", (e: any) => {
  //       console.log('component: ', e?.component);
  //       console.log('dataField: ', e?.dataField);
  //       console.log('value: ', e?.value);
  //       console.log('items: ', formRef.current.instance.itemOption(e.dataField));
  //     }
  //   )
  // })

  return (
    <div className="admin-form">
      <div className="widget-container">
        <Form
          ref={formRef}
          formData={formData}
          readOnly={formConfig.readOnly}
          showColonAfterLabel={formConfig.showColon}
          labelLocation={labelLocation}
          colCount={formConfig.colCount}
          customizeItem={customizeAdminFormItems}
          //onContentReady={(e) => console.log('formcontent is ready: ', e.component?.option("formData"), ' items: ', e.component)}
          //onFieldDataChanged={(e) => setTimeout(onFormFieldDataChanged(e), 1000)} //
          onInitialized={(e: any) => formRef.current = e.component}
        >
          {formMeta.map(
            item => {
              const fI = item as FormItem;
              const fIG = item as GroupedFormItems;
              if (fIG.items) {
                return <GroupItem
                  cssClass="apprise-form-group-item apprise-edit-form"
                  key={fIG.key}
                  caption={fIG.caption}
                >
                  {fIG.items.map(
                    i => <Item
                      key={i.dataField}
                      {...i}
                    >
                      {meta[i.dataField].required ? <RequiredRule /> : null}
                    </Item>
                  )}
                </GroupItem>
              } else {
                return <Item
                  key={fI.dataField}
                  {...fI}
                >
                  {meta[fI.dataField].required ? <RequiredRule /> : null}
                </Item>
              }

            }
          )}
        </Form>
        <DialogActions>
          {formActions.map((action) => (
            <Button key={action.key} className="button-reset" onClick={() => action.onClick && action.onClick(data[action.dataField])}>
              {action.caption}
            </Button>
          ))}
          <Button className="button-reset" onClick={cancelChanges}>
            Cancel
          </Button>
          <Button
            className="button-save"
            color="primary"
            onClick={() => onUpdate(data, getFormData())}
          >
            Save
          </Button>
        </DialogActions>
      </div>
      {children && <div className="options">{children}</div>}
    </div >
  );
};

//AdminForm.whyDidYouRender = true;

export default AdminForm;
