import { createSelector } from 'reselect';
import { ContractPeriodState } from 'components/AdminPanel/ContractPeriods/Reducers/ContractPeriods.reducers';
import { PortfolioPeriodState } from 'components/AdminPanel/ContractPeriods/Reducers/PortfolioPeriods.reducers';
import { createDeepEqualSelector, createShallowEqualSelector } from "helpers/SelectorUtilities/selectorUtilities";
import { ContractIdOrPortfolioId } from "services/API/common/contractAPIs";

import { State } from 'store/interfaces/State';
import { number } from 'prop-types';

export const contractPeriodsSelector = (state: State): ContractPeriodState => state.contractPeriods;
export const portfolioPeriodsSelector = (state: State): PortfolioPeriodState => state.portfolioPeriods;

export const contractContractPeriodsSelector = (contractId: any) => createDeepEqualSelector(
  contractPeriodsSelector, (contractPeriod) => {
    // console.log('Changed contractPeriodSelector');
    return {
      data: Object.keys(contractPeriod.data).filter(key => contractPeriod.data[key].contract === contractId).map(key => contractPeriod.data[key]),
      meta: contractPeriod.meta,
      permissions: contractPeriod.permissions,
    }
  }
);

export const portfolioPortfolioPeriodsSelector = (portfolioId: any) => createDeepEqualSelector(
  portfolioPeriodsSelector, (portfolioPeriodState) => {
    // console.log('Changed contractPeriodSelector');
    return {
      data: Object.keys(portfolioPeriodState.data).filter(key => portfolioPeriodState.data[key].portfolio === portfolioId).map(key => portfolioPeriodState.data[key]),
      meta: portfolioPeriodState.meta,
      permissions: portfolioPeriodState.permissions,
    }
  }
);


export const contractOrPortfolioPeriodListSelector = (id: ContractIdOrPortfolioId) =>
  createSelector(portfolioPortfolioPeriodsSelector, contractContractPeriodsSelector, () => {
    if (id.portfolioId) {
      return portfolioPortfolioPeriodsSelector
    } else if (id.contractId) {
      return contractContractPeriodsSelector
    }
    return [];
  })

