import React, { memo, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "store/Common/Helpers/commonHelper.js";
import { useDispatch, useSelector } from "react-redux";

// Own
import { store } from "store/store";
import GroupedCommonSideBarMenu from "components/Layout/Common/SideBar/GroupedCommonSideBar.js";
import { UseDrawer } from "components/Layout/Contract/Containers/ContractSidebar/DrawerHOC";

import {
  contractSideBarCollapsedSelector,
  contractSideBarHiddenSelector
} from "components/Profile/Selectors/Profile.selector";

import { updatePersonalSettingsReducer } from "components/Profile/Actions/PersonalSettings.actions";
import { updatePersonalSettingsService } from "components/Profile/Services/profile.services";
import { changeSetting } from "store/actions/actions";

//ContractSidebar.whyDidYouRender = true;

const WrappedInFocusSideBar = UseDrawer(GroupedCommonSideBarMenu, {
  drawerWidth: 220,
  drawerWidthClosed: 36,
  zIndex: 116,
  downShift: 64,
  variant: "permanent"
});

const WrappedSideBar = memo(withRouter(WrappedInFocusSideBar));

export const toggleHideContractSideBar = dispatch => {
  const payload = {
    hide_contract_sidebar: !store.getState().personalSettings?.data
      ?.hide_contract_sidebar
  };
  // here we want to update the local profile state immediately whether the BE succeeds or not
  // dispatch(updateProfileReducer(payload));
  dispatch(updatePersonalSettingsReducer(payload));
  updatePersonalSettingsService(payload);
  setTimeout(() => {
    dispatch(changeSetting("layoutUpdateBroadcast", Date.now()));
  }, 200);
};

const toggleCollapseContractSidebar = dispatch => {
  const payload = {
    collapse_contract_sidebar: !store.getState().personalSettings?.data
      ?.collapse_contract_sidebar
  };
  // here we want to update the local profile state immediately whether the BE succeeds or not
  // dispatch(updateProfileReducer(payload));
  updatePersonalSettingsService(payload);
  dispatch(updatePersonalSettingsReducer(payload));
  setTimeout(() => {
    dispatch(changeSetting("layoutUpdateBroadcast", Date.now()));
  }, 250);
};

const InFocusSideBarContainer = ({ menuVisibility, menuItems, menuImage }) => {
  const dispatch = useDispatch();
  const sideBarCollapsed = useSelector(contractSideBarCollapsedSelector);
  const sideBarHiddenSetting = useSelector(contractSideBarHiddenSelector);
  const query = useQuery();
  const isPDFRequest = !!query.get("pdfView");
  const sideBarHidden = isPDFRequest || sideBarHiddenSetting;
  const updateHideSideBar = useCallback(
    () => toggleHideContractSideBar(dispatch),
    []
  );
  const updateCollapseSideBar = useCallback(
    () => toggleCollapseContractSidebar(dispatch),
    []
  );
  return (
    <>
      <WrappedSideBar
        collapsed={sideBarCollapsed}
        toggleCollapseSidebar={updateCollapseSideBar}
        closed={sideBarHidden}
        toggleHideSideBar={updateHideSideBar}
        useMenuItems={menuItems}
        upperImage={menuImage}
        menuVisibility={menuVisibility}
      />
    </>
  );
};

export default InFocusSideBarContainer;
