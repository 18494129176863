import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";
import { filterColumnProps } from "components/ContractInFocus/Interfaces/DataGridColumn.interface";
import { columnVisibility, createdAtColumn } from "helpers/DataGrid/DataGridColumn.helper";
import moment from "moment";

const nameColumn = (width?: number | string, visible = true) => ({
    name: {
        width,
        alignment: "left",
        ...columnVisibility(visible),
    },
});

const coreColumn = (width?: number | string, visible = true) => ({
    core: {
        width,
        alignment: "left",
        ...columnVisibility(visible),
    },
});

const intervalColumn = (width?: number | string, visible = true) => ({
    interval: {
        width,
        alignment: "left",
        ...columnVisibility(visible),
    },
});

const intervalUnitColumn = (width?: number | string, visible = true) => ({
    interval_unit: {
        width,
        alignment: "left",
        ...columnVisibility(visible),
    },
});

const gracePeriodColumn = (width?: number | string, visible = true) => ({
    grace_period: {
        width,
        alignment: "left",
        ...columnVisibility(visible),
    },
});

const applicableFromColumn = (width?: number | string, visible = true) => ({
    applicable_from: {
        width,
        alignment: "left",
        ...columnVisibility(visible),
    },
});

const defaultForBaseColumn = (width?: number | string, visible = true) => ({
    default_for_base_contract: {
        width,
        alignment: "left",
        ...columnVisibility(visible),
    },
});

const defaultForTenantColumn = (width?: number | string, visible = true) => ({
    default_for_tenant_contract: {
        width,
        alignment: "left",
        caption: "Default for Tenant",
        ...columnVisibility(visible),
    },
});

const statutoryDocRefColumn = (width?: number | string, visible = true) => ({
    statutory_doc_ref: {
        width,
        alignment: "left",
        caption: "Statutory Document Ref",
        ...columnVisibility(visible),
    },
});

export function getHASTaskFilterExpForGrid(meta: any, headerFilterPage: string) {
    const thisHeaderFilterPage = headerFilterPage === "default" ? "all" : headerFilterPage;
    const filterExp = (function getFilters() {
        switch (thisHeaderFilterPage) {
            case "all":
                return null
            case "recent":
                //@ts-ignore
                return ["created_at", ">", moment().add(-5, 'day')]
            default:
                return null
        }
    })();
    return filterExp
}

export const hasTaskFilteredDatagridColumns = (
    props: filterColumnProps
) => {
    return {
        default: {
            caption: "All",
            onClick: () => props.setHeaderFilterPage("default"),
            columns: (meta: FieldMetaGroup): any => ({
                ...nameColumn("35%"),
                ...intervalColumn("5%"),
                ...intervalUnitColumn("12%"),
                ...gracePeriodColumn("10%"),
                ...applicableFromColumn("10%"),
                //...coreColumn("10%"),
                ...defaultForBaseColumn("10%"),
                ...defaultForTenantColumn("10%"),
                ...statutoryDocRefColumn("10%"),
                //...createdAtColumn("10%")
            }),
        },
    };
};
