import React from "react";
import { useSelector } from "react-redux";

// Own
import { apiSuccess, openPanelInSelectionMode } from "components/AdminPanel/Helpers/AdminPanel.helper";
import {
  DataGridUpdateEvent,
  DataGridEvent,
  DataGridDropEvent,
} from "interfaces/datagrid.interfaces";
import { adminPanelType } from "components/AdminPanel/Models/AdminPanel.model";
import { AdminDataGrid } from "components/AdminPanel/Containers/AdminDataGrid";
import { getPersonGridActions, getPersonFormItems } from "components/AdminPanel/People/Models/PeopleDetail.model";
import { PeopleDetailView } from "components/AdminPanel/People/Containers/PeopleDetailView";
import { Person } from "components/AdminPanel/People/Interfaces/People.interface";
import { allPeopleSelector } from "components/AdminPanel/People/Selectors/People.selector";
import peopleServices from "components/AdminPanel/People/Services/peopleServices";
import { EntityKeys } from "constants/entity.constants";
import { usePeopleMeta } from "components/AdminPanel/People/Helper/People.customHooks";
import { FieldMetaGroup } from 'components/Common/Interfaces/Entity.interface';
import {
  ColumnPropGroup,
  ColumnProps,
} from "components/ContractInFocus/Interfaces/DataGridColumn.interface";

interface PeopleSublistViewProps {
  panelId: string;
  masterRecordData?: object;
  peopleState: any;
  getDatagridColumns: (meta: FieldMetaGroup) => ColumnPropGroup;
  createPerson?: Function;
  dataTestId: string;
  onDrop?: (item: any) => void;
  onUnlink?: (item: any) => void;
  membersForm?: boolean;
  masterRecordType?: string;
  selectActionIdentifier?: string;
  onAddLinks?: Function;
  linkPanelTitle?: string;
  handleOnReorder?: (e: any, callback: () => void) => void;
}

const PeopleSublistView: React.FC<PeopleSublistViewProps> = ({
  panelId,
  masterRecordData,
  peopleState,
  getDatagridColumns,
  createPerson,
  dataTestId,
  onDrop,
  onUnlink,
  membersForm = false,
  masterRecordType,
  selectActionIdentifier,
  onAddLinks,
  linkPanelTitle,
  handleOnReorder
}) => {
  const selectPeopleState = useSelector(allPeopleSelector);

  const handleDrop = (e: DataGridDropEvent) => {
    onDrop && onDrop(e.itemData);
  };

  const cloneMembersFromState = (): Person[] => {
    return (peopleState || []).slice();
  };

  const members = cloneMembersFromState();
  const currentMemberIds = members.map(p => p.id)

  const handlePersonCreate = (e: DataGridEvent): void => {
    // HT Note: passing through the createPerson function now, but will keep this as a common wrapper 
    // @ts-ignore
    if (createPerson) { e.cancel = createPerson(e.data).then(apiSuccess) }
  };

  const handlePersonLink = (): void => {
    //openPanelWithDragMessage(adminPanelType.people);
    masterRecordType && selectActionIdentifier && linkPanelTitle && openPanelInSelectionMode(
      {
        selectActionIdentifier,
        masterRecordType,
        masterRecordData,
        linkedRecordType: adminPanelType.people,
        onAddLinks,
        currentMemberIds,
        linkPanelTitle,
      }
    )
  };

  const linkPeople = selectActionIdentifier ? handlePersonLink : undefined;

  const peopleMeta = usePeopleMeta(selectPeopleState.meta)

  const handlePersonUpdate = (e: DataGridUpdateEvent): void => {
    // @ts-ignore    
    e.cancel = peopleServices.updatePerson(
      e.key,
      e.oldData,
      e.newData,
      selectPeopleState.recordmeta[e.key]
    ).then(apiSuccess);
  };

  const handleFetchPeopleGroup = (people: Person[]) => {
    peopleServices.groupFetchPeople(people);
  };
  // HT Note: this is currently getting rendered way too many times! (before any changes I've made)  24 - 26 on last count, based on the console log below:
  // console.log('selectPeopleState.meta: ', selectPeopleState.meta);
  // HT Note: Should usePeopleMeta go into a useEffect? What effect would that have on re-rendering?  Can we have choices update without re-rending the row?
  // could we just update the cell perhaps - or even somehow just the choices values?
  // HT Note: Get Dan's comment and if ok copy usePeopleMeta to be used in PeopleMasterView as well - also later check where/how the meta is consumed by the dx component
  return (
    <AdminDataGrid
      data={members}
      meta={peopleMeta}
      permissions={selectPeopleState.permissions}
      recordmeta={selectPeopleState.recordmeta}
      dataTestId={dataTestId}
      getDatagridColumns={getDatagridColumns}
      masterView={false}
      entityKey={EntityKeys.People}
      columnActions={getPersonGridActions(onUnlink)}
      panelId={panelId}
      dragAndDrop={false} //was true
      fetchGroup={handleFetchPeopleGroup}
      onUpdate={handlePersonUpdate}
      onDrop={handleDrop}
      onLink={linkPeople}
      handleOnReorder={handleOnReorder}
    />
  );
};

export default PeopleSublistView;
