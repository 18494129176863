/* eslint-disable */
// 3rd
import React, { memo } from 'react'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { MenuItem } from '@material-ui/core';

// Own
import { useSubHeadingNavStyles } from '../../Containers/ContractSubHeading/ContractSubHeadingStyles';
import { Wrapper } from './ContractSubHeadingContractPeriodSelectorStyles';
import { ContractPeriod, PortfolioPeriod } from '../../../../AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface';
import { periodDate } from '../../../../Common/Utils/Dates';

interface InFocusSubHeadingPeriodSelectorProps {
  periodOptions: any[];
  selectedPeriod: ContractPeriod | PortfolioPeriod;
  onSelectPeriod: (period: ContractPeriod | PortfolioPeriod) => void;
  updateContractPeriodsState: () => void;
}

const InFocusSubHeadingPeriodSelector: React.SFC<InFocusSubHeadingPeriodSelectorProps> = ({
  periodOptions,
  selectedPeriod,
  onSelectPeriod,
  updateContractPeriodsState
}) => {
  const classes = useSubHeadingNavStyles();

  const getSelectPeriod = (id: number | string): any => periodOptions.find(period => period.id === id) || selectedPeriod;

  const handleSelectPeriod = (element: any) => {
    const period = getSelectPeriod(element.target.value);
    onSelectPeriod(period);
  }

  const handleOnOpen = () => {
    updateContractPeriodsState();
  }

  return (
    <Wrapper>
      <FormControl className={classes.formControl}>
        <InputLabel shrink htmlFor="mainContractPeriodSelector-native-label">
          Contract Period:
        </InputLabel>
        <Select
          value={selectedPeriod.id}
          onChange={(value: any) => handleSelectPeriod(value)}
          id="mainContractPeriodSelector"
          onOpen={handleOnOpen}
        >
          {periodOptions.map(period =>
            <MenuItem key={period.id} value={period.id} className={classes.selectItem}>
              {periodDate(period.start_date)} - {periodDate(period.end_date)}
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </Wrapper>
  )
}

export default memo(InFocusSubHeadingPeriodSelector);