const processTabValue = (x: string) => decodeURI(x).toLowerCase().replaceAll(' ', '').replaceAll('_', '');

export const getInitiallySelectedTabIndex = (tabs: string[], initiallySelectedTabValue: string | undefined) => {
    if (initiallySelectedTabValue == undefined) return undefined;
    const processedInitiallySelectedTabValue = processTabValue(initiallySelectedTabValue || '');
    const compareTab = (value: string) => {
        const processedTabValue = processTabValue(value);
        const equal = processedInitiallySelectedTabValue === processedTabValue;
        return equal
    };
    let index = tabs.findIndex(compareTab);
    if (index >= 0) {
        return index
    }
}