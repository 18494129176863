import styled from "styled-components";
import styles from 'styles/app/common/variables.scss';


export const Wrapper = styled.div`
  width: 100%;
  height: 92vh;

  .MuiPaper-rounded {
    padding 1.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;

// .dx-datagrid-focus-overlay::after {
//   height: 0px;
// }

type InlineWrapperProps = {
  pageBreakAfter?: boolean;
  pageBreakBefore?: boolean;
}

export const InlineWrapper = styled.div<InlineWrapperProps>`
  width: 100%;
  page-break-inside: avoid;

  page-break-after : ${({ pageBreakAfter }) => pageBreakAfter ? `always;` : `auto;`};

  page-break-before : ${({ pageBreakBefore }) => pageBreakBefore ? `always;` : `auto;`};

  .dx-editor-cell .dx-texteditor .dx-texteditor-input {
    line-height: 24px;
  }

  .MuiPaper-rounded {
    padding 1.5rem;
    margin-top: 3.5rem;
    margin-bottom: 2rem;
    margin-left: 3rem;
    margin-right: 2rem;
  }
`;

// position: relative;
export const RelativeWrapper = styled.div``;

// position: absolute;
export const AbsoluteWrapper = styled.div`
  width: 100%;
  padding-left: 1px;
  height: 100%;
  overflow-x: hidden;
  padding-top: 0.2rem;
`;

export const UsefulDXListWrapper = styled.div`
  margin-top: 1rem;

  .dx-freespace-row {
    // due to component bug
    // https://github.com/DevExpress/devextreme-react/issues/393
    display: none !important;
  }
`;

// Doc uploader and table

//width: 55%;
export const GeneralUploadedFilesWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.theme.direction};
  .notes-icon {
    color: ${styles.brandGoldDarker};
  }
  border: solid 1px #cae9fb;
  margin-top: 1rem;
  padding: 0.5rem;
`;

export const ContractDocumentFilesWrapper = styled(GeneralUploadedFilesWrapper as any)`
height: ${props => (props.height ? props.height : props.fullHeight ? 80 : 70)}vh;
`;

export const VisitDocumentFilesWrapper = styled(GeneralUploadedFilesWrapper as any)`
height: 80vh;
`;

export const HASDocumentFilesWrapper = styled(GeneralUploadedFilesWrapper as any)`
height: 80vh;
`;

export const AppendixDocumentFilesWrapper = styled(GeneralUploadedFilesWrapper as any)`
  height: 50vh;
`;



export const rowFlexTheme = {
  direction: "row",
  layoutType: "flex"
};

export const columnFlexTheme = {
  direction: "column",
  layoutType: "flex"
};

export const gridTheme = {
  layoutType: "grid"
};

// display: grid;
// grid-template-columns: repeat(12, minmax(0, 1fr));


GeneralUploadedFilesWrapper.defaultProps = {
  theme: {
    direction: "column"
  }
}

export const AWUploadedFilesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .notes-icon {
    color: ${styles.brandGoldDarker};
  }

`;

export const WarningWrapper = styled.div`
  color: red;
`;

interface GeneralUploadedFilesWrapperProps {
  internalAccessVisible?: boolean;
}

export const VisitUploadWrapper = styled.div<GeneralUploadedFilesWrapperProps>`

  padding: 1.5rem;
  padding-top: .75rem;
  background-color: ${styles.grayLighter};
  height: 100%;

  h5 {
    padding-bottom: 5px;
  }

  .dx-field {
    margin-bottom: .5rem;
  }

  .dx-field-label {
    color: ${styles.black};
  }

  .dx-field-label {
    font-size: 1rem;
  }

  .filepond--panel-root {
    background-color: ${styles.grayMedDark};
  }
  .filepond--root {
    margin-bottom: 0;

    ${({ internalAccessVisible }) => !internalAccessVisible ? `
      margin-top: 1rem;
      .filepond--drop-label {
        min-height: 9rem;
      }
      `: ``
  }
  }

  .filepond--drop-label {
    color: ${styles.black};
    background-color: ${styles.white};
    border: 1px dashed  ${styles.black};
    font-weight: 600;
  }
  `;


export const AWPOUploadWrapper = styled.div<GeneralUploadedFilesWrapperProps>`
  background-color: ${styles.grayLighter};
  height: 100%;

  h5 {
    padding-bottom: 5px;
  }

  .dx-field {
    margin-bottom: .5rem;
  }

  .dx-field-label {
    color: ${styles.black};
  }

  .dx-field-label {
    font-size: 1rem;
  }

  .filepond--panel-root {
    background-color: ${styles.grayMedDark};
  }
  .filepond--root {
    margin-bottom: 0;

    ${({ internalAccessVisible }) => !internalAccessVisible ? `
      margin-top: 1rem;
      .filepond--drop-label {
        min-height: 9rem;
      }
      `: ``
  }
  }

  .filepond--drop-label {
    color: ${styles.black};
    background-color: ${styles.white};
    border: 1px dashed  ${styles.black};
    font-weight: 600;
  }
  `;

export const GeneralDocsUploadWrapper = styled.div<GeneralUploadedFilesWrapperProps>`

  display: ${props => props.theme.layoutType};
  grid-template-columns: repeat(12, minmax(0, 1fr));
  flex-direction: ${props => props.theme.direction};
  background-color: ${styles.grayLighter};
  h5 {
    padding-bottom: 5px;
  }

  .dx-field {
    margin-bottom: .5rem;
  }

  .dx-field-label {
    color: ${styles.black};
  }

  .dx-field-label {
    font-size: 1rem;
  }

  .filepond--panel-root {
    background-color: ${styles.grayMedDark};
  }
  .filepond--root {
    margin-bottom: 0;

    ${({ internalAccessVisible }) => !internalAccessVisible ? `
      margin-top: 1rem;
      `: ``
  }
  }

  .filepond--drop-label {
    color: ${styles.black};
    background-color: ${styles.white};
    border: 1px dashed  ${styles.black};
    font-weight: 600;
  }
  `;

GeneralDocsUploadWrapper.defaultProps = {
  theme: {
    direction: "row"
  }
}


// export const VisitUploadWrapper = styled(generalUploadWrapperStyles) <GeneralUploadedFilesWrapperProps>`
//   padding: 1.5rem;
//   padding-top: .75rem;
// `;

//export const AWPOUploadWrapper = styled(generalUploadWrapperStyles) <GeneralUploadedFilesWrapperProps>``;

// export const GeneralFilesUploadWrapper = styled.div<GeneralUploadedFilesWrapperProps>`
//   background-color: ${styles.grayLighter};
//   height: 100%;


//   h5 {
//     padding-bottom: 5px;
//   }

//   .dx-field {
//     margin-bottom: .5rem;
//   }

//   .dx-field-label {
//     color: ${styles.black};
//   }

//   .dx-field-label {
//     font-size: 1rem;
//   }

//   .filepond--panel-root {
//     background-color: ${styles.grayMedDark};
//   }
//   .filepond--root {
//     margin-bottom: 0;

//     ${({ internalAccessVisible }) => !internalAccessVisible ? `
//       margin-top: 1rem;
//       .filepond--drop-label {
//         min-height: 9rem;
//       }
//       `: ``
//   }
//   }


//   .filepond--drop-label {
//     color: ${ styles.black};
//     background-color: ${ styles.white};
//     border: 1px dashed  ${ styles.black};
//     font-weight: 600;
//   }
// `;
