import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import styles from "../../../../styles/app/common/variables.scss";

export const useHeaderAvatarStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      width: '42px',
      height: '42px',
      backgroundColor: styles.white,
      color: styles.blueDark,
      cursor: 'pointer',
      marginLeft: 'auto'
    },
  })
);
