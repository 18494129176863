import React, { useState, useEffect } from 'react';
import {
  Chart,
  Series,
  CommonSeriesSettings,
  Legend,
  Title,
  ArgumentAxis,
  ValueAxis,
  Font,
  Label
} from 'devextreme-react/chart';

// Own
import { transformMeterReadingForChart } from '../../Helpers/MeterReadingChartHelper';
import { MeterReading, MeterReadingChartDataMeta } from '../../Interfaces/MeterReadings.interface';
import { chartsFontProps, colourSequence2, colourSequence3 } from 'constants/chart.constants';
import { IMeterReadingsStream } from 'components/ContractInFocus/MeterReadings/Interfaces/MeterReadings.interface';


// Styles
import './MeterReadingsChart.scss';


interface MeterReadingsChartProps {
  meterReadings: MeterReading[];
  meterStream: IMeterReadingsStream;
  animate?: boolean;
}

const MeterReadingsChart = ({ meterReadings, meterStream, animate = true }: MeterReadingsChartProps) => {

  const [chartData, setChartData] = useState<MeterReadingChartDataMeta>();

  useEffect(() => {
    setChartData(transformMeterReadingForChart(meterReadings));
  }, [meterReadings])

  return (
    <div id="meterReadingCharts">
      {chartData && (
        <Chart
          id="chart"
          dataSource={chartData.data}
          animation={animate}
          palette={colourSequence3}
        >
          <CommonSeriesSettings
            argumentField="month"
            type="bar"
            hoverMode="allArgumentPoints"
            selectionMode="allArgumentPoints"
          />

          {chartData.years.map((year) => (
            <Series
              key={year}
              argumentField="month"
              valueField={`year${year}`}
              name={year}
            />
          ))}

          <ValueAxis position="left">
            <Label> <Font  {...chartsFontProps} /> </Label>
            {meterStream.unit && <Title text={meterStream.unit} />}
          </ValueAxis>
          <ArgumentAxis>
            <Label> <Font  {...chartsFontProps} /> </Label>
          </ArgumentAxis>

          <Legend verticalAlignment="bottom" horizontalAlignment="center"></Legend>
        </Chart>
      )}
    </div>
  )
}

export default MeterReadingsChart;