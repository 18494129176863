import React, { useEffect, useState, useRef, useMemo, useReducer, useCallback } from 'react';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { RRule, RRuleSet, rrulestr } from 'rrule';
import { useSelector } from "react-redux";

//own
import { SupplierService, Visit } from "components/Schedulers/Interfaces/Schedule.interfaces";
import { FieldsFormConfig } from "components/Common/Components/DocumentsGrid/DocumentsGrid.interface";
import { FieldMetaGroup, Primitive } from "components/Common/Interfaces/Entity.interface";
import { FieldMeta } from "components/Common/Interfaces/Entity.interface";
import { BatchCreateVisitsFormWrapper } from "components/Schedulers/SchedulerStyles";
import { AutoInput } from 'components/Common/Components/AutoInput/AutoInput';
import { getBankHolidays } from 'components/Schedulers/Services/visitService';
import * as contractSelectors from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import { ContractPeriod } from 'components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface';
import { rangeMaker } from "store/reducers/reducer.helper";
import { formValueConsideredMissing } from "store/Common/Helpers/commonHelpers";
import { PeriodOption } from "components/ContractInFocus/interfaces/contractInFocusActions.interfaces";

// Styles
import './BatchCreateVisitFormStyles.scss';
import { dateUDF } from 'components/Common/Utils/Dates.js';


// const intervalChoices = [...Array(60)].reduce((previousValue: Array<any>, currentValue: any, currentIndex: number, array: number[]) => {
//     const value = (currentIndex + 1).toString()
//     previousValue.push({
//         value: value,
//         display_name: value
//     })
//     return previousValue;
// }, []);

export interface FormValues {
    [field: string]: Primitive; //represents field: actual value
}

let testA = 5;

const getFieldFormConfig = (period: PeriodOption, supplierService?: SupplierService, currentTemplateVisit?: Visit): FieldsFormConfig => {
    const minDate = moment(period?.start_date).toDate()
    const maxDate = moment(period?.end_date).toDate()
    const fromDate = currentTemplateVisit ? moment(currentTemplateVisit.scheduled_for).toDate() : moment(period.start_date).toDate()

    return {
        "from": {
            getMinDate: (meta: FieldMeta) => minDate,
            getMaxDate: (meta: FieldMeta) => maxDate,
            getInitialDate: (meta: FieldMeta) => fromDate,
        },
        "to": {
            getMinDate: (meta: FieldMeta) => minDate,
            getMaxDate: (meta: FieldMeta) => maxDate,
            getInitialDate: (meta: FieldMeta) => maxDate,
        },
        "frequency": {
            defaultValue: "MONTHLY"
        },
        "interval": {
            selectFirstAsDefault: true,
            min: 1,
            defaultValue: 1,
            max: 500
        },
        "exclude_start": {
            defaultChecked: !!currentTemplateVisit
        },
        "weekdays_only": {
            //defaultChecked: true
        },
        "avoid_bank_holidays": {
            //defaultChecked: true
        }
    }
}

const fieldMetaMap: FieldMetaGroup = {
    "from": {
        type: "date",
        required: true,
        field_name: "from",
        read_only: false,
        label: "From",
    },
    "to": {
        type: "date",
        required: true,
        field_name: "to",
        read_only: false,
        label: "To",
    },
    "frequency": {
        type: "choice",
        required: true,
        field_name: "frequency",
        read_only: false,
        label: "Unit",
        choices: [
            { value: "YEARLY", display_name: "YEAR(s)" },
            { value: "MONTHLY", display_name: "MONTH(s)" },
            { value: "WEEKLY", display_name: "WEEKS(s)" },
            { value: "DAILY", display_name: "DAY(s)" }
        ]
    },
    "interval": {
        type: "integer",
        required: true,
        field_name: "interval",
        read_only: false,
        label: "Every",
        //choices: intervalChoices
    },
    "exclude_start": {
        type: "boolean",
        required: false,
        field_name: "exclude_start",
        read_only: false,
        label: "Exclude start date"
    },
    "weekdays_only": {
        type: "boolean",
        required: false,
        field_name: "weekdays_only",
        read_only: false,
        label: "Weekdays only",
    },
    "avoid_bank_holidays": {
        type: "boolean",
        required: false,
        field_name: "avoid_bank_holidays",
        read_only: false,
        label: "Avoid Bank Holidays",
    }
}

function generateQuickGuid() {
    return Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
}

const frequencyMapping = {
    'WEEKLY': RRule.WEEKLY,
    'MONTHLY': RRule.MONTHLY,
    'YEARLY': RRule.YEARLY,
    'DAILY': RRule.DAILY
}
interface adjustDateIfRequiredI {
    dMoment: moment.Moment;
    avoidWeekends: boolean;
    avoidBankHolidays: boolean;
    bankHolidays: { [index: string]: any };
    loopCount: React.MutableRefObject<number>;
}
const adjustDateIfRequired = ({ dMoment, avoidWeekends, avoidBankHolidays, bankHolidays, loopCount }: adjustDateIfRequiredI): moment.Moment => {
    const daysInMonth = dMoment.daysInMonth()
    const initialMonthDay = dMoment.date() // weird but day() gives the day of the week, .date() called on a date gives the day of the month
    loopCount.current += 1;

    if (avoidWeekends) {
        let weekday = dMoment.isoWeekday();
        let adjustDay;
        // if (avoidBankHolidays) {
        //     adjustDay = weekday === 6 ? -1 : weekday === 7 ? 1 : undefined;
        // }
        //adjustDay = weekday === 6 ? -1 : weekday === 7 ? 1 : undefined;
        // if we're at the start of a month, so we can't move it backward, at the end, we can't move it forward
        if (weekday === 6) {
            adjustDay = initialMonthDay <= 1 ? 2 : -1
        } else if (weekday === 7) {
            adjustDay = initialMonthDay >= daysInMonth ? -2 : 1
        }
        if (adjustDay) {
            dMoment.add(adjustDay, 'days');
        }
    }
    let momentDateString = dMoment.format(dateUDF);
    let bankHoliday = bankHolidays ? bankHolidays.data[momentDateString] : undefined;
    let newMonthDay = dMoment.date()
    if (avoidBankHolidays && bankHoliday) {
        let weekday = dMoment.isoWeekday(); // call again just in case changed.
        let adjustDay;
        if (avoidWeekends) {
            // For bank holidays, as they're rarer we deal with the choppier logic, to avoid bouncing back and forth with weekends
            // In general the best thing is to keep it moving in the same direction, if its a Friday bank holiday it will skip to Monday, if a Saturday it moves to Monday, 
            // if that's a bank holiday it will move forward 1 on the next call till it finds a non bank holiday.
            // this means it will search forward until it hits an appropriate day.. but logic below deals with end of month where we can't move forward
            if (newMonthDay >= daysInMonth - 3) {
                console.log('newMonthDay: ', newMonthDay);
                console.log('daysInMonth: ', daysInMonth);
                console.log('momentDateString: ', momentDateString);

                // we're at the end of a month, so we can't move it forward, however moving it backward could hit a bank holiday
                // which would move it forward, resulting in an infinite loop
                // for safety throw it a few days back so there are at least three non weekend days to check between then and now
                // we allow daysInMonth - 3 to enter our 'end of Month' condition above because if we're avoiding weekends Friday throws it three days forward... we could of course
                // repeat the logic below for Friday and Saturday with different grace periods but that's probably a bit OTT
                switch (weekday) {
                    case 1: // Monday
                        adjustDay = -5 // Wednesday last week
                        break;
                    case 2: // Tuesday
                        adjustDay = -5 // Thursday last week
                        break;
                    case 3: // Wednesday
                        adjustDay = -5 // Friday last week
                        break;
                    case 4: // Thursday
                        adjustDay = -3 // last Monday
                        break;
                    case 5: // Friday
                        adjustDay = -3 // last Tuesday
                        break;
                    case 6: // Saturday
                        adjustDay = -3 // last Wednesday
                        break;
                    case 7: // Sunday
                        adjustDay = -4 // last Wednesday
                        break;
                }
            } else {
                // bank holiday generally always moves forwards and we have to skip weekends as we're in avoiding weekends here. 
                adjustDay = weekday === 5 ? 3 : weekday == 6 ? 2 : 1;
            }

        } else {
            // we're NOT avoiding weekends (to refresh your memory :) )
            if (newMonthDay == daysInMonth) {
                // we're at the end of a month, so we can't move it forward, however moving it backward could hit a bank holiday
                // which would move it forward, resulting in an infinite loop
                // for safety throw it a few days back so there are at least three days (week or weekend) to check between then and now
                switch (weekday) {
                    case 1: // Monday
                        adjustDay = -3 // Friday last week
                        break;
                    case 2: // Tuesday
                        adjustDay = -3 // Saturday last week
                        break;
                    case 3: // Wednesday
                        adjustDay = -3 // Sunday last week
                        break;
                    case 4: // Thursday
                        adjustDay = -3 // last Monday
                        break;
                    case 5: // Friday
                        adjustDay = -3 // last Tuesday
                        break;
                    case 6: // Saturday
                        adjustDay = -3 // last Wednesday
                        break;
                    case 7: // Sunday
                        adjustDay = -3 // last Thursday
                        break;
                }
            } else {
                // move off the bank holiday forward!
                adjustDay = 1;
            }

        }
        dMoment.add(adjustDay, 'days');
    }
    const newWeekday = dMoment.isoWeekday()

    const newMomentDateString = dMoment.format(dateUDF);
    const newBankHoliday = bankHolidays ? bankHolidays.data[newMomentDateString] : undefined;

    // we need to check the result, in case say, it was a weekend and it has now been moved to a bank holiday and we're avoiding both 
    // weekends and bank holidays.
    if (((avoidBankHolidays && newBankHoliday) || (avoidWeekends && newWeekday == 6 || avoidWeekends && newWeekday == 7)) && loopCount.current < 5) {
        return adjustDateIfRequired({ dMoment, avoidWeekends, avoidBankHolidays, bankHolidays, loopCount });
    }
    loopCount.current = 0;
    return dMoment;
}

interface BatchCreateProps {
    supplierService: SupplierService;
    supplierContractId: string;
    setPreviewVisits: React.Dispatch<React.SetStateAction<Visit[] | undefined>>;
    existingPreviewVisits?: Visit[];
    templateVisit?: Visit;
    resetBatchForm?: boolean;
    setOpenBatchCreateForm: React.Dispatch<React.SetStateAction<boolean>>;
    contractRef: string;
    contractId?: string | number;
    portfolioId?: string | number;
    period: PeriodOption;
}



const BatchCreateVisits = ({
    supplierService,
    setPreviewVisits,
    existingPreviewVisits,
    templateVisit,
    resetBatchForm,
    setOpenBatchCreateForm,
    contractRef,
    contractId,
    portfolioId,
    supplierContractId,
    period
}: BatchCreateProps) => {

    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 1);
    const [fieldConfigs, setFieldConfigs] = useState<FieldsFormConfig>({});
    const [bankHolidays, setBankHolidays] = useState<any>();
    const formValuesRef = useRef<FormValues>({});
    const currentFocus = useRef<string>();
    const loopCount = useRef<number>(0);
    const [readyToCreate, setReadyToCreate] = useState(false);
    //const selectedFocusedPeriod = useSelector(contractSelectors.contractOrPortfolioPeriodSelector({ portfolioId: portfolioId, contractId: contractId }));



    const handleGenerateVisitsPreview = (e: any) => {
        const rruleSet = new RRuleSet()
        const avoidWeekends = !!formValuesRef.current.weekdays_only;
        const avoidBankHolidays = !!formValuesRef.current.avoid_bank_holidays;
        const freq = formValuesRef.current.frequency;
        const startDate = formValuesRef.current.from ? moment.utc(formValuesRef.current.from as string).toDate() : undefined;
        let basicRule: { [index: string]: any } = {
            //@ts-ignore
            freq: RRule[freq],
            interval: formValuesRef.current.interval ? Number(formValuesRef.current.interval) : undefined,
            dtstart: startDate,
            until: formValuesRef.current.to ? moment.utc(formValuesRef.current.to as string).toDate() : undefined,
        }
        const startDay = startDate ? startDate.getDate() : undefined;
        if (freq === "MONTHLY" && startDay && startDay > 28) {
            const thisRange = rangeMaker(startDay - 27, 28);
            basicRule["bymonthday"] = thisRange;
            basicRule["bysetpos"] = -1;
        }
        rruleSet.rrule(
            new RRule(basicRule)
        )
        if (formValuesRef.current.exclude_start) {
            rruleSet.exdate(moment.utc(formValuesRef.current.from as string).toDate())
        }
        // console.log(rruleSet.toString());
        // console.log('bankHolidays: ', bankHolidays);
        // console.log(rruleSet.all());
        let previewVisits: Visit[] = existingPreviewVisits?.length ? [...existingPreviewVisits] : [];
        const newPreviewVisitMap: { [index: string]: Visit } = {};
        // using a dictionary approach means that if we 'move' a visit that has particular date to another date (e.g. to avoid a weekend or bank holiday)
        // then if a visit will already be generated on that day, we don't duplicate it (if we're just running create batch once that is)
        rruleSet.all().map((d, index) => {
            let thisMoment = moment.utc(d);
            thisMoment = adjustDateIfRequired({ dMoment: thisMoment, avoidBankHolidays: avoidBankHolidays, avoidWeekends, bankHolidays, loopCount })
            const tempId = "temp." + generateQuickGuid();
            const label = thisMoment.format(dateUDF);
            newPreviewVisitMap[label] = { //the point of storing this in a map is just to easily deduplicate visits that may have been shifted onto each other
                id: tempId,
                scheduled_for: moment(thisMoment).format(dateUDF),
                booked: false,
                status: "scheduled",
                service_type: "PPM",
                updated_at: moment().format(dateUDF),
                notes: "",
                supplier_service: supplierService.id,
                supplier_contract: supplierContractId
            }
        })
        previewVisits = [...previewVisits, ...Object.values(newPreviewVisitMap)]
        setPreviewVisits(previewVisits);
    }

    const runSideEffects = useCallback((currentFormValues: FormValues, fieldConfigs: FieldsFormConfig, onChangeFormValues: (newValues: FormValues) => void) => {
        let sideEffectsRan = [];
        let sideEffectsToRun = Object.keys(fieldConfigs).filter(x => fieldConfigs[x].sideEffect !== undefined);

        for (let index in sideEffectsToRun) {
            const field = sideEffectsToRun[index];
            console.log('field with se: ', field);
            //@ts-ignore
            const sideEffectRan = fieldConfigs[field].sideEffect(currentFormValues, fieldConfigs, onChangeFormValues);
            if (sideEffectRan) {
                sideEffectsRan.push(field)
            }
        }
        if (sideEffectsRan.length > 0) {
            //console.log('sideEffects ran!');
            forceUpdate();
        }
    }, []);

    const setupForm = useCallback((thisSupplierService?: SupplierService) => {
        let newValues: FormValues = {};
        if (!resetBatchForm) {
            newValues = { ...formValuesRef.current }
        }
        if (templateVisit) {
            newValues = {
                ...newValues,
                from: templateVisit.scheduled_for,
                exclude_start: true,
            }
        }
        if (thisSupplierService) {
            newValues['supplier_service'] = thisSupplierService.id
        }
        formValuesRef.current = newValues;
        const formConfig = getFieldFormConfig(period, thisSupplierService, templateVisit);
        setFieldConfigs(formConfig);
        //setFieldConfigs(formConfig);
        onChangeFormValues(newValues);
        // note, we don't have to invoke runSideEffects here, those should be triggered once the field config update has registered...

        forceUpdate();
    }, [templateVisit, resetBatchForm, period])

    const onChangeFormValues = useCallback((newValues: FormValues) => {
        const updatedFormValues = {
            ...formValuesRef.current,
            ...newValues,
            'supplier_service': supplierService.id,
        }
        formValuesRef.current = updatedFormValues;
        const missingValues = Object.keys(fieldMetaMap).filter(
            (k) => {
                const meta = fieldMetaMap[k];
                const config = fieldConfigs[k];
                const fV = formValuesRef.current[k];
                // let missing = (
                //     ((meta.required || config?.forceRequired) && (typeof (fV) == "undefined" || typeof (fV) == "string" && fV.trim().length == 0))
                // );
                let missing = formValueConsideredMissing({
                    meta,
                    config,
                    formValue: fV,
                })
                return missing;
            }
        );

        runSideEffects(updatedFormValues, fieldConfigs, onChangeFormValues);

        const checkIsReady = missingValues.length == 0
        // console.log('missing values: ', missingValues);
        setReadyToCreate(checkIsReady);
        //console.log('formValues current in OnChangeValues: ', formValuesRef.current);

    }, [supplierService.id, runSideEffects, fieldConfigs])

    useEffect(() => {
        setupForm(supplierService);
    }, [supplierService, setupForm, templateVisit])

    useEffect(() => {
        getBankHolidays(contractRef).then(
            (response) => {
                setBankHolidays(response)
            }
        )
    }, [contractRef])

    return <>
        <div id="closeBatchVisitsFormButtonWrapper">
            <IconButton
                aria-label="toggle password visibility"
                className="closeImportInfoButton"
                onClick={() => { setOpenBatchCreateForm(false) }}
                edge="end"
                style={{
                    float: "right"
                }}
            >
                <CloseIcon />
            </IconButton>
        </div>
        <div className="batchVisitsCreateFormOuterWrapper">

            <BatchCreateVisitsFormWrapper className="batchCreateVisitsForm">
                {mustRefresh && Object.keys(fieldConfigs).map((dataField) => {
                    const fieldConfig = fieldConfigs[dataField];
                    return <AutoInput
                        zIndex={1300}
                        key={dataField}
                        dataField={dataField}
                        fieldConfig={fieldConfig}
                        fieldMeta={fieldMetaMap[dataField]}
                        formValuesRef={formValuesRef}
                        refreshSignal={mustRefresh}
                        onChangeFormValues={onChangeFormValues}
                        currentFocus={currentFocus}
                        showReadOnly
                    />
                })}
                <div className="generateVisitsPreviewButtonWrapper">
                    <Button onClick={handleGenerateVisitsPreview}
                        className="generateVisitsPreviewButton"
                        color="secondary"
                        size="small"
                        type="submit"
                        variant="contained"
                        disabled={false}
                        style={
                            {
                                float: "right",
                            }
                        }
                    >
                        Generate Preview
                    </Button>
                </div>
            </BatchCreateVisitsFormWrapper>
        </div>
    </>
}

export default BatchCreateVisits;

