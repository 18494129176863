import API from "services/API/API";
import { onResponseError } from "services/API/API.interceptor.js"
import { getDownloadHeaderConfig } from 'components/ContractInFocus/HoC/WithPageContext/Types/Download.types';


function getRemotePDFGeneratorUrl() {
    //let REMOTEPDFURL = `http://localhost:9000/api/getSpecialistPlanner`;
    let REMOTEPDFURL = `${process.env.REACT_APP_PDF_URL}/api/getSpecialistPlanner`;
    // switch (process.env.REACT_CUSTOM_NODE_ENV) {
    //     case "staging":
    //         REMOTEPDFURL = `${process.env.REACT_APP_PDF_URL}/api/getSpecialistPlanner`;
    //         break;
    //     case "production":
    //         REMOTEPDFURL = `${process.env.REACT_APP_PDF_URL}/api/getSpecialistPlanner`;
    //         break;
    //     default:
    //         REMOTEPDFURL = `http://localhost:9000/api/getSpecialistPlanner`;
    // }
    // return REMOTEPDFURL
    //return `http://localhost:9000/api/getSpecialistPlanner`;
    console.log('REMOTEPDFURL: ', REMOTEPDFURL);
    return REMOTEPDFURL
}

interface remoteSpecialistPlannerRequestBody {
    token: string;
    remoteTargetUrl: string;
    elementToBePrinted: string;
    contractRef?: string;
    portfolioId?: number;
    portfolioName?: string;
    title: string;
    site: string;
}

interface generatePlannerPDFProps {
    payload: remoteSpecialistPlannerRequestBody,
    setPDFBase64: React.Dispatch<React.SetStateAction<string | undefined>>
    setGeneratingPDF: React.Dispatch<React.SetStateAction<boolean>>
}

export const generateSpecialistPlannerPDF = (
    {
        payload, setGeneratingPDF, setPDFBase64
    }: generatePlannerPDFProps) => {
    let route = getRemotePDFGeneratorUrl();
    setGeneratingPDF(true);
    return API.post(route, payload, getDownloadHeaderConfig()).then((response) => {
        setPDFBase64(response.data);
    }).finally(() => {
        setGeneratingPDF(false);
    })
}