import { arrayToDict } from '../../../../services/API/API.helper';
import { DataRow } from '../Interfaces/interactiveTable.interfaces';

export const transformDataForStandardTasksChart = (
  ppmRecords: DataRow[],
  months: string[]
) => {
  const dict = arrayToDict(ppmRecords);
  const taskCompletedKey: any = 'tasks_completed';
  const taskUncompletedKey: any = 'not_completed_in_a_month';

  return months.map(monthKey => {
    let completed = dict[taskCompletedKey][monthKey].value;
    completed = completed === undefined ? null : completed;
    let incomplete = dict[taskUncompletedKey][monthKey].value;
    incomplete = incomplete === undefined ? null : incomplete;
    return {
      month: monthKey,
      completed: completed,
      incomplete: incomplete,
    }
  }
  );
};

export const transformDataForSLATasksChart = (
  records: DataRow[],
  months: string[]
) => {
  const dict = arrayToDict(records);
  const taskCompletedInSLAKey: any = 'tasks_completed_in_sla';
  const tasksCompletedOutsideSLAKey: any = 'tasks_completed_outside_sla';
  const taskUncompletedKey: any = 'not_completed_in_a_month';

  return months.map(monthKey => {
    let completedInSLA = dict[taskCompletedInSLAKey][monthKey].value;
    completedInSLA = completedInSLA === undefined ? null : completedInSLA;
    let completedOutsideSLA = dict[tasksCompletedOutsideSLAKey][monthKey].value;
    completedOutsideSLA = completedOutsideSLA === undefined ? null : completedOutsideSLA;
    let incomplete = dict[taskUncompletedKey][monthKey].value;
    incomplete = incomplete === undefined ? null : incomplete;
    return {
      month: monthKey,
      completedInSLA: completedInSLA,
      completedOutsideSLA: completedOutsideSLA,
      incomplete: incomplete
    }
  })
};

export const transformDataForReactiveByCategoryChart = (
  reactiveByCategoryData: DataRow[],
  totalColumn: string
) => {
  return reactiveByCategoryData
    .map(row => ({
      name: row.name || row.portfolio_label,
      // @ts-ignore
      count: row[totalColumn].value
    }))
    .reverse();
};
