import { store } from 'store/store';
import { addNotification } from 'components/Notification/Actions/Notification.actions';
import { of } from 'rxjs';

export const addErrorNotice = ( message: string ) => dispatchNotification(message, 'error');

export const addInfoNotice = ( message: string ) => dispatchNotification(message, 'info');

export const addWarningNotice = ( message: string ) => dispatchNotification(message, 'warning');

export const addSuccessNotice = ( message: string ) => dispatchNotification(message, 'success');

const dispatchNotification = (message: string, type: 'error' | 'success' | 'info' | 'warning') => {
  store.dispatch(addNotification({ message, type }));
  return of(message);
}