import React, { useState, useEffect, useReducer } from "react";
import { useSelector } from 'react-redux';

// Own
import GenericMeterReadings from "./GenericMeterReadingsv2";
import { getContractPersonalHiddenObjects } from "components/ContractInFocus/Services/personalHideObject.services";
import CreateMeterModal from "components/ContractInFocus/MeterReadings/Components/CreateMeterStreamModal";
import meterStreamServices from "components/ContractInFocus/MeterReadings/Services/MeterStreamServices";
import MeterReadingEvents from "./MeterReadingEvents";
import withPageContext, { WithPageContextProps } from "../HoC/WithPageContext/WithPageContext";
import NewPageWrapper from "components/Common/Components/PrintStyles/Print";
import { reportSectionStructure } from "components/ContractInFocus/Models/contractIndex.model";
import { PrintTitle } from "../Components/ContractPrintTitle/ContractPrintTitle";
import * as inFocusSelector from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import IconButton from "components/Common/Components/IconButton/IconButton";
import { getMeterStreamsPersonalRightVisibilityMenu } from "components/ContractInFocus/MeterReadings/Models/meterStreamsRightVisibilityMenu";
import { personalHiddenMeterTables, personalHiddenMeterGraphs } from "components/ContractInFocus/Selectors/personalHiddenObject.selectors";
import { MainInFocusVisibilitySettings } from "components/ContractInFocus/Interfaces/ContractInFocus.interfaces";
import { RightVisibilityMenuT } from "components/ContractInFocus/interfaces/personalContractSettings.interfaces";
import { IMeterReadingsStream } from 'components/ContractInFocus/MeterReadings/Interfaces/MeterReadings.interface';
import { ContractInterface } from "components/AdminPanel/Contracts/Interfaces/Contract.interface";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { GetRightVisibilityMenu } from "components/Common/Components/RightVisibilityMenu/Interfaces/RightContext.interfaces";


interface MeterStreamProps extends WithPageContextProps { }

const MeterStreams: React.FunctionComponent<MeterStreamProps> = ({
    contract,
    animate,
    visibilitySettings,
    sectionNumberMap,
    enablePersonalVisibilitySettings,
    forceHidePersonalVisibilitySettingsMenu,
    contractReportContext
}) => {

    const selectedMeterStreams = useSelector(inFocusSelector.contractInFocusMeterStreamsSelector);
    const meterStreamsMeta = selectedMeterStreams.meta;
    const meterStreamsPermissions = selectedMeterStreams.permissions;
    const meterStreams = selectedMeterStreams.data;
    const hiddenTableObjs = useSelector(personalHiddenMeterTables);
    const hiddenGraphObjs = useSelector(personalHiddenMeterGraphs);
    const hiddenTableMeterStreamIds = hiddenTableObjs.map(x => x.object_id);
    const hiddenGraphMeterStreamIds = hiddenGraphObjs.map(x => x.object_id);
    const [openCreateMeterStream, setOpenCreateMeterStream] = useState(false);
    const [mustUpdate, forceUpdate] = useReducer((x) => x + 1, 0);

    const hasMeterReadingEvents = () => visibilitySettings.show_meter_reading_events_table;
    const PrintHeading = () => <PrintTitle sectionNumberMap={sectionNumberMap} reportSection={reportSectionStructure.Metering} />;

    const closeCreateMeterStream = () => {
        setOpenCreateMeterStream(false);
    }

    useEffect(
        () => {
            if (mustUpdate) {
                contract?.id && contract?.contract_ref && meterStreamServices.getContractMeterStreams(contract.id, contract.contract_ref);
            }
        }, [mustUpdate, contract?.id, contract?.contract_ref]
    );

    // useEffect(
    //     () => {
    //         console.log('meterStreams here: ', meterStreams);
    //     }, [meterStreams]

    // );

    return (
        <>
            {!contractReportContext && <IconButton
                margin="left"
                whiteIcon={false}
                icon="fas fa-plus"
                onClick={() => { setOpenCreateMeterStream(true) }}
                className="no-print"
                style={
                    { paddingTop: '1rem', marginBottom: "-1rem" }
                }
            />}

            {meterStreams && meterStreams.map && meterStreams.map(
                (meterStream: IMeterReadingsStream) => {
                    const showChart = forceHidePersonalVisibilitySettingsMenu || !enablePersonalVisibilitySettings ? meterStream.show_graph_in_monthly_maintenance_report : !hiddenGraphMeterStreamIds.includes(meterStream.id);
                    const showTable = forceHidePersonalVisibilitySettingsMenu || !enablePersonalVisibilitySettings ? meterStream.show_table_in_monthly_maintenance_report : !hiddenTableMeterStreamIds.includes(meterStream.id);

                    if (showChart || showTable) {
                        return <NewPageWrapper breakAfter key={meterStream.id}>
                            <PrintTitle sectionNumberMap={sectionNumberMap} reportSection={reportSectionStructure.Metering} />
                            <GenericMeterReadings
                                contract={contract}
                                meterStreamPermissions={meterStreamsPermissions}
                                meterStream={meterStream}
                                meterStreamPutMeta={meterStreamsMeta.PUT}
                                utilityType={meterStream.utility_type.display_name}
                                title={meterStream.title}
                                dataTestId={meterStream.title + 'testId'}
                                animate={animate}
                                showChart={showChart}
                                showTable={showTable}
                                reloadParent={forceUpdate}
                            />
                        </NewPageWrapper>
                    }

                }
            )}
            {hasMeterReadingEvents() ?
                <>
                    <PrintTitle sectionNumberMap={sectionNumberMap} reportSection={reportSectionStructure.Metering} />
                    <MeterReadingEvents />
                </>
                : null}
            {
                meterStreamsPermissions?.POST && meterStreamsMeta?.POST && openCreateMeterStream && !contractReportContext &&
                <CreateMeterModal
                    contract={contract}
                    meta={meterStreamsMeta.POST}
                    isOpen={openCreateMeterStream}
                    onCancel={closeCreateMeterStream}
                    close={closeCreateMeterStream}
                    reloadParent={forceUpdate}
                />
            }

        </>
    );
};

//const useGetMeterStreamsPersonalRightVisibilityMenu = (visibilitySettings: VisibilityContractSettings, contract: ContractInterface): RightVisibilityMenuT => {
const useGetMeterStreamsPersonalRightVisibilityMenu = ({ visibilitySettings, contract }: GetRightVisibilityMenu): RightVisibilityMenuT => {
    // returns a function which, when called, returns a RightVisibilityMenu
    getContractPersonalHiddenObjects(contract as SiteContract, 'meterstream');
    const selectedMeterStreams = useSelector(inFocusSelector.contractInFocusMeterStreamsSelector);
    const hiddenTableObjs = useSelector(personalHiddenMeterTables);
    const hiddenGraphObjs = useSelector(personalHiddenMeterGraphs);
    return getMeterStreamsPersonalRightVisibilityMenu(visibilitySettings, selectedMeterStreams, contract as SiteContract, hiddenGraphObjs, hiddenTableObjs);
}

export default withPageContext(MeterStreams, {
    visibilityMenu: useGetMeterStreamsPersonalRightVisibilityMenu,
});
