import React, { memo } from 'react'

// Styles
import { NoDataWrapper } from './NoItemsStyles';

interface NoItemProps {
  caption: string;
}

const NoItems = ({ caption }: NoItemProps) => {

  //Sorry you haven't got any ${label} - please contact an admin

  return (
    <NoDataWrapper>
      <div className="no-items__icon">
        <i className="far fa-frown"></i>
      </div>
      <div className="no-items__text">
        {caption}
      </div>
    </NoDataWrapper>
  )
}

export default memo(NoItems)
