import { createSelector } from 'reselect';
import { State } from '../../../store/interfaces/State';
import { EntityBranchState, Dictionary } from '../../Common/Interfaces/Entity.interface';
import { PanelEntityState } from '../Interfaces/Panel';
import { mode as dataMode } from "../../Common/Consts/DataMode";

export const stateSelector: any = (state: State) => state;

export const adminPanelStateSelector: any = (state: State) => state.adminPanel;

export const panelsStateSelector: any = (state: State) => state.adminPanel.panels;

export const panelEntitySelectorFactory = (key: string, recordId: number, mode: string) => {
  return createSelector(
    stateSelector, (state: { [key: string]: EntityBranchState }): PanelEntityState => {
      return ({ data: state[key].data[recordId], meta: mode === dataMode.new ?  state[key].meta.POST : state[key].meta.PUT })
    }
  )
}

export const panelSearchSelectorFactory = (key: string) => {
  return createSelector(
    stateSelector, (state: { [key: string]: EntityBranchState }): PanelEntityState => {
      return ({ data: state[key].data, meta: state[key].meta.POST || state[key].meta.PUT })
    }
  )
}

export const getPanelSelectorFactory = (panelId: string) => {
  return createSelector(
    panelsStateSelector, (panels: Dictionary<PanelEntityState>): PanelEntityState => {
      return panels[panelId];
    }
  )
}

export const getPanelWithActiveSelectorFactory = (panelId: string) => {
  return createSelector(
    adminPanelStateSelector, (adminPanel: any) => {
      return {
        panel: adminPanel.panels[panelId], 
        activePanel: adminPanel.panels[adminPanel.activePanelId]};
    }
  )
}

export const selectPanel = (state: any, panelId: string) => state.adminPanel.panels[panelId];

export const panelState = (state: any, panelId: string) => state.adminPanel.panels[panelId];

export const selectContracts = (state: any) => state.contracts;

export const selectSubListData = (state: any, panelId: string, subListKey: string, recordId: number) =>
  state.adminPanel.panels[panelId].data[subListKey].find(
    (record: any) => record.id === recordId
  );

export const selectSubListMode = (state: any, panelId: string, subListKey: string, recordId: number) =>
  state.adminPanel.panels[panelId].data[subListKey].find(
    (record: any) => record.id === recordId
  ).mode;

export const selectSubListMeta = (state: any, panelId: string, subListKey: string) =>
  state.adminPanel.panels[panelId].meta[subListKey];

