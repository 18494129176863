import { createSelector } from 'reselect';
import { dictToArray, existingRecordOnly } from '../../../../services/API/API.helper';
import { PeopleState, JobTitle } from '../Interfaces/People.interface';
import { State } from '../../../../store/interfaces/State';
import { getLookup, getNamesAndEmails } from 'components/AdminPanel/People/Reducers/People.reducer.helper';

export const selectAllPeople = (state: any) => state.people.allPeople;

export const peopleState: any = (state: State): PeopleState => state.people;
export const jobTitlesState = (state: State): JobTitle[] => state.people.jobTitles;

export const peopleSelector = createSelector(
  peopleState, (people: PeopleState) =>
  ({ data: people.data, meta: people.meta.POST })
);

export const allPeopleSelector = createSelector(
  peopleState, (people: PeopleState) => {
    return {
      data: dictToArray(people.data),
      meta: people.meta.POST,
      recordmeta: people.recordmeta,
      permissions: people.permissions
    }
  }
);

export const AllAccessibleContractsSelector = (state: State) => state.personAccessibleContracts;
export const AllAccessiblePortfoliosSelector = (state: State) => state.personAccessiblePortfolios;

export const personAccessibleContractsSelectorFactory = (id: any) => createSelector(
  AllAccessibleContractsSelector, (accessibleContracts) => ({
    data: accessibleContracts ? accessibleContracts[id]?.data || [] : [],
    metaWrapper: accessibleContracts.metaWrapper
  })
);

export const personAccessiblePortfoliosSelectorFactory = (id: any) => createSelector(
  AllAccessiblePortfoliosSelector, (accessiblePortfolios) => {
    return {
      data: accessiblePortfolios ? accessiblePortfolios[id]?.data || [] : [],
      metaWrapper: accessiblePortfolios.metaWrapper
    }
  }
);

export const personByIdSelector = (personId: any) => createSelector(
  peopleState, (people: PeopleState) => ({
    data: people.data[personId],
    meta: people.recordmeta[personId]
  })
);

export const peopleLookupByTypeSelectorFactory = (type: string) => createSelector(
  peopleState, (people: PeopleState) =>
  existingRecordOnly(dictToArray(people.data)
    .filter(person => person[type])
    .map(getLookup)
  )
);

export const peopleMetaSelector = createSelector(
  peopleState, (people: PeopleState) =>
  ({ meta: people.meta.POST })
);

export const peopleLookupSelector = createSelector(
  peopleState, (people: PeopleState) => dictToArray(people.lookup)
);

export const peopleCalcLookupSelector = createSelector(
  peopleState, (people: PeopleState) => Object.keys(people.data).map(key => getLookup(people.data[key]))
);

export const peopleEmailLookupSelector = createSelector(
  peopleState, (people: PeopleState) => Object.keys(people.data).map(key => getNamesAndEmails(people.data[key]))
);

export const selectPeoplePostMeta = (state: any) => state.allPeople.meta;