import {
  ADD_ADMIN_PANEL,
  REMOVE_ADMIN_PANEL,
  ADMIN_PANEL_CLONE,
  CLEAR_ADMIN_PANEL_EXPAND,
  SET_ADMIN_PANEL_TYPE,
  CLEAR_PANELS,
  SET_ACTIVE_PANEL
} from "../Actions/adminPanel.actions";
import { IDHelper } from "../../../components/Common/Utils/Key";
import { mode } from "../../Common/Consts/DataMode";
import { adminPanelType } from "components/AdminPanel/Models/AdminPanel.model";

const initialState = {
  panels: {}
};

const adminPanelOpsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ADMIN_PANEL_EXPAND: {
      return {
        ...state,
        panels: {
          ...state.panels,
          [action.panelId]: {
            ...state.panels[action.panelId],
            expandRecordId: undefined
          }
        }
      };
    }

    case ADD_ADMIN_PANEL: {
      const newPanelId = action.panelId || IDHelper();
      const panelType = action.panelType || adminPanelType.organisations;

      return {
        ...state,
        activePanelId: newPanelId,
        panels: {
          ...state.panels,
          [newPanelId]: {
            panelType: panelType,
            expandRecordId: action.expandRecordId,
            isDetail: action.isDetail,
            selectionConfig: action.selectionConfig,
            onlyShowTabs: action.onlyShowTabs
          }
        }
      };
    }

    case REMOVE_ADMIN_PANEL: {
      const { [action.panelId]: value, ...remainingPanels } = state.panels;
      const remainingPanelIds = Object.keys(remainingPanels);
      return {
        ...state,
        activePanelId: remainingPanelIds[remainingPanelIds.length - 1],
        panels: {
          ...remainingPanels
        }
      };
    }

    case CLEAR_PANELS:
      return {
        ...state,
        panels: {}
      };

    case ADMIN_PANEL_CLONE: {
      const newPanelId = IDHelper();

      const clonedPanel = {
        [newPanelId]: {
          ...state.panels[action.panelId],
          id: newPanelId,
          mode:
            state.panels[action.panelId].mode === mode.new
              ? mode.list
              : state.panels[action.panelId].mode
        }
      };

      return {
        ...state,
        activePanelId: newPanelId,
        panels: {
          ...state.panels,
          ...clonedPanel
          // [newPanelId]: {
          //   ...state.panels[action.panelId],
          //   mode: (state.panels[action.panelId].mode === mode.new ? mode.list :  state.panels[action.panelId].mode)
          // }
        }
      };
    }

    case SET_ACTIVE_PANEL:
      return {
        ...state,
        activePanelId: action.panelId
      };

    case SET_ADMIN_PANEL_TYPE:
      return {
        ...state,
        panels: {
          ...state.panels,
          [action.panelId]: {
            ...state.panels[action.panelId],
            panelType: action.panelType,
            expandRecordId: undefined
          }
        }
      };

    default:
      return state;
  }
};

export default adminPanelOpsReducer;
