import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Own
import siteService from "components/AdminPanel/Sites/Services/sitesServices";
import { adminListSitesSelector } from "components/AdminPanel/Sites/Selectors/Sites.selectors";
import { siteDatagridColumns, getSiteFormItems } from 'components/AdminPanel/Sites/Models/Sites.model';
import { AdminDataGrid } from "components/AdminPanel/Containers/AdminDataGrid";
import { DataGridEvent, DataGridUpdateEvent } from 'interfaces/datagrid.interfaces';
//import { AdminSite } from "components/AdminPanel/Sites/Interfaces/Sites.interface";
import { Site } from "components/Sites/Interfaces/Site.inteface";
import { apiSuccess } from 'components/AdminPanel/Helpers/AdminPanel.helper';
import { SitesDetailView } from 'components/AdminPanel/Sites/Containers/SitesDetailView';
interface SitesMasterViewProps {
  panelId: string;
}

// https://supportcenter.devexpress.com/ticket/details/t410004/dxdatagrid-how-to-display-multiple-values-in-a-lookup-column-using-dxtagbox

// How to handle the e.cancel & promises
// https://supportcenter.devexpress.com/ticket/details/t581713/dxdatagrid-how-to-use-promise-all-for-the-cancel-parameter-of-the-onrowupdating-event

const SitesMasterView: React.FC<SitesMasterViewProps> = ({ panelId }) => {
  const dataTestId = "site-master-view";

  const sitesState = useSelector(adminListSitesSelector);
  const dispatch = useDispatch();

  const handleUpdate = (e: DataGridUpdateEvent): void => {
    // @ts-ignore
    e.cancel = siteService.updateSite(e.oldData.site_ref, e.newData, sitesState.recordmeta[e.key]).then(apiSuccess);
  }

  const handleCreate = (e: DataGridEvent): void => {
    // @ts-ignore
    siteService.createSite(e.data, panelId);
  };

  const handleDelete = (e: DataGridEvent): void => {
    // @ts-ignore
    e.cancel = siteService.deleteSite(e.data.site_ref, e.key).then(apiSuccess);
  }

  const handleFetchGroup = (sites: Site[]) => {
    siteService.groupFetchSite(sites);
  }

  return (
    <AdminDataGrid
      data={sitesState.data}
      meta={sitesState.meta}
      permissions={sitesState.permissions}
      recordmeta={sitesState.recordmeta}
      masterView
      panelId={panelId}
      dataTestId={dataTestId}
      detailView={SitesDetailView}
      createFormMeta={getSiteFormItems(sitesState.meta)}
      getDatagridColumns={siteDatagridColumns}
      onDelete={handleDelete}
      onUpdate={handleUpdate}
      onCreate={handleCreate}
      fetchGroup={handleFetchGroup}
      editMode="form"
    />
  )
}

//SitesMasterView.whyDidYouRender = true;

export default memo(SitesMasterView);
