import React, { useEffect } from "react";
import { useSelector } from "react-redux";

// Own
import {
    DataGridEvent,
    DataGridUpdateEvent
} from "interfaces/datagrid.interfaces";
import { AdminDataGrid } from "components/AdminPanel/Containers/AdminDataGrid";
import { portfolioPortfolioPeriodsSelector } from "components/AdminPanel/ContractPeriods/Selectors/ContractPeriods.selectors";
import { contractPeriodDatagridColumns } from "components/AdminPanel/ContractPeriods/Models/ContractPeriods.model";
import { EntityKeys } from "constants/entity.constants";
import { apiSuccess } from 'components/AdminPanel/Helpers/AdminPanel.helper';
import { simpleFetchPortfolioPeriods } from "components/AdminPanel/ContractPeriods/Services/contractPeriodsService";

import contractPeriodService from "components/AdminPanel/ContractPeriods/Services/contractPeriodsService";

interface PortfolioPeriodSublistViewProps {
    panelId: string;
    id: any;
    dataTestId: string;
}

const PortfolioPeriodSublistView: React.SFC<PortfolioPeriodSublistViewProps> = ({
    panelId,
    id,
    dataTestId
}) => {
    const selectPortfolioPeriodState = useSelector(portfolioPortfolioPeriodsSelector(id));
    console.log('selectPortfolioPeriodState:', selectPortfolioPeriodState);

    const handleUpdate = (e: DataGridUpdateEvent) => {
        // @ts-ignore
        e.cancel = contractPeriodService.updatePortfolioPeriod(e.oldData.id, e.newData).then(apiSuccess);
    };

    const handleCreate = (e: DataGridEvent): void => {
        // @ts-ignore
        contractPeriodService.createPortfolioPeriod(e.data, id);
    };

    const handleDelete = (e: DataGridEvent): void => {
        // @ts-ignore
        e.cancel = contractPeriodService.deletePortfolioPeriod(e.data.id).then(apiSuccess);
    };

    useEffect(() => { console.log('selectPortfolioPeriodState:', selectPortfolioPeriodState) }, [selectPortfolioPeriodState]);

    useEffect(() => { simpleFetchPortfolioPeriods(id) }, [id]);


    return (
        <AdminDataGrid
            data={selectPortfolioPeriodState.data}
            meta={selectPortfolioPeriodState.meta}
            permissions={selectPortfolioPeriodState.permissions}
            dataTestId={dataTestId}
            getDatagridColumns={contractPeriodDatagridColumns}
            masterView={false}
            entityKey={EntityKeys.PortfolioPeriods}
            panelId={panelId}
            dragAndDrop={false}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
        />
    );
};

export default PortfolioPeriodSublistView;
