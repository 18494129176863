import React from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import VisibilityIcon from '@material-ui/icons/Visibility';


// Own
import { RightContextVisibilityButtonWrapper, openControlsStyles } from './rightContextButtonStyles';
import { MatIconButton } from 'components/Common/Components/Material/MatIconButton/MatIconButton';

interface RightContextButtonProps {
  showSettings: boolean;
  onShowSettings?: () => void;
}

export default ({
  showSettings,
  onShowSettings
}: RightContextButtonProps) => {

  return (
    <RightContextVisibilityButtonWrapper
      className={`animated ${showSettings ? 'fadeIn' : 'fadeOutUpShort'}`}
      hidden={!showSettings}
    >
      <MatIconButton onClick={onShowSettings}>
        <VisibilityIcon style={openControlsStyles} />
      </MatIconButton>
    </RightContextVisibilityButtonWrapper>
  );
}