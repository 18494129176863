import { FieldMetaGroup } from 'components/Common/Interfaces/Entity.interface';
import { ColumnPropGroup } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';
import { FormItem } from 'interfaces/form.interface';

export const siteImage = {
  key: 'site_image',
  label: 'Site Image'
}


export const siteDatagridColumns = (meta: FieldMetaGroup): ColumnPropGroup => ({
  site_ref: {
    width: 150,
    alignment: "left"
  },
  name: {
  },
  postcode: {
    width: 150,
  },
  active: {}
});


export const getSiteFormItems = (meta: FieldMetaGroup): FormItem[] => [
  {
    dataField: 'id',
    visible: false,
  },
  {
    dataField: meta.name.field_name,
    editorOptions: {
      readOnly: meta.name.read_only
    }
  },
  {
    dataField: meta.postcode.field_name,
    editorOptions: {
      readOnly: meta.postcode.read_only
    }
  },
  {
    dataField: meta.site_ref.field_name,
    editorOptions: {
      readOnly: meta.site_ref.read_only
    }
  },
  {
    dataField: meta.active.field_name,
    editorOptions: {
      readOnly: meta.active.read_only
    }
  },

];


export const newSite = {
  url: "",
  site_image: {
    thumbnail: null
  },
  name: "5",
  postcode: "1",
  site_ref: 21,
  site_contracts: []
};
