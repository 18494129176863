import { ApiErrorsKey } from "../Interfaces/Errors";
import { addAdminPanelInterface } from "../Interfaces/Panel";

//NB 'go to references' on my editor doesn't seem to pick up references in AdminPanels.js - so you might want to do a full 'text' based search for any actions in here
// if you want to know where they're used...

export const ADD_ADMIN_PANEL = "ADD_ADMIN_PANEL";
export const addAdminPanel = (addAdminPanelConfig: addAdminPanelInterface) => {
  const { panelId, panelType, expandRecordId, isDetail, selectionConfig, onlyShowTabs } = addAdminPanelConfig
  return { type: ADD_ADMIN_PANEL, panelId, panelType, expandRecordId, isDetail, selectionConfig, onlyShowTabs }
}

export const CLEAR_ADMIN_PANEL_EXPAND = "CLEAR_ADMIN_PANEL_EXPAND";
export const clearAdminPanelExpand = (panelId: string) => ({ type: CLEAR_ADMIN_PANEL_EXPAND, panelId });

export const REMOVE_ADMIN_PANEL = "REMOVE_ADMIN_PANEL";
export const removeAdminPanel = (panelId: string) => ({
  type: REMOVE_ADMIN_PANEL,
  panelId
});

export const SET_ADMIN_PANEL_TYPE = "SET_ADMIN_PANEL_TYPE";
export const setAdminPanelType = (panelId: string, panelType: string) => ({
  type: SET_ADMIN_PANEL_TYPE,
  panelId,
  panelType
});

export const SET_ACTIVE_PANEL = "ADMIN_PANEL_SET_ACTIVE_PANEL";
export const setActivePanel = (panelId: string) => ({ type: SET_ACTIVE_PANEL, panelId });

export const OPEN_RECORD = "ADMIN_PANEL_OPEN_RECORD";
export const openRecord = (
  panelId: string,
  data: object,
  meta: object,
  panelType?: string
) => ({ type: OPEN_RECORD, panelId, data, meta, panelType });

export const OPEN_RECORD_CHANGE_TAB = "ADMIN_PANEL_OPEN_RECORD_CHANGE_TAB";
export const openRecordChangeTab = (
  panelId: string,
  panelType: string,
  recordId: number,
) => ({ type: OPEN_RECORD_CHANGE_TAB, panelId, panelType, recordId });

export const OPEN_NEW_RECORD = "ADMIN_PANEL_OPEN_NEW_RECORD";
export const newRecord = (
  panelId: string,
  newRecord: object,
  meta: object
) => ({ type: OPEN_NEW_RECORD, panelId, newRecord, meta });

export const OPEN_NEW_RECORD_CHANGE_TAB =
  "ADMIN_PANEL_OPEN_NEW_RECORD_CHANGE_TAB";
export const panelNewRecordChangeTab = (
  panelId: string,
  panelType: string,
  data: object,
  meta: any
) => ({ type: OPEN_NEW_RECORD_CHANGE_TAB, panelId, panelType, data, meta });

export const OPEN_LIST_MODE = "ADMIN_PANEL_OPEN_LIST_MODE";
export const openListMode = (panelId: string) => ({
  type: OPEN_LIST_MODE,
  panelId
});

export const SET_MODE = "ADMIN_PANEL_SET_MODE";
export const setMode = (panelId: string, mode: string) => ({
  type: SET_MODE,
  panelId,
  mode
});

// export const SET_ADMIN_PANEL_GROUPING_MODE = "ADMIN_PANEL_SET_GROUPING_MODE";
// export const setAdminPanelGroupingMode = (panelId: string, groupingMode: boolean) => ({
//   type: SET_ADMIN_PANEL_GROUPING_MODE,
//   panelId,
//   groupingMode
// });
export const SET_NEW_RECORD_ID = "ADMIN_PANEL_SET_NEW_RECORD_ID";
//NB at present this is used mainly to 'unset' - we listen to the current success actions to set
export const setNewRecordId = (panelId: string, id: string | null) => ({
  type: SET_NEW_RECORD_ID,
  panelId,
  id
});

// HT Note: Seems unused.  Are many admin actions unused?
export const SET_RECORD_ID = "ADMIN_SET_RECORD_ID";
export const setRecordId = (panelId: string, originalId: string, recordId: number) => ({
  type: SET_RECORD_ID,
  panelId,
  originalId,
  recordId
});

export const SET_DATA_AND_META = "ADMIN_PANEL_SET_DATA_AND_META";
export const setDataAndMeta = (
  panelId: string,
  data: object,
  meta: object
) => ({ type: SET_DATA_AND_META, panelId, data, meta });

export const SET_DATA = "ADMIN_PANEL_SET_DATA";
export const setData = (panelId: string, data: object) => ({
  type: SET_DATA,
  panelId,
  data
});

export const SPREAD_ONTO_DATA = "ADMIN_PANEL_SPREAD_ONTO_DATA";
export const spreadOntoData = (panelId: string, data: object) => ({
  type: SPREAD_ONTO_DATA,
  panelId,
  data
});

export const SET_SUB_ITEM_DATA = "ADMIN_PANEL_SET_SUB_ITEM_DATA";
export const setSubItemData = (
  panelId: string,
  subItemKey: string,
  subItemId: number,
  subItem: object
) => ({ type: SET_SUB_ITEM_DATA, panelId, subItemKey, subItemId, subItem });

export const NEW_SUB_ITEM_RECORD = "ADMIN_PANEL_NEW_SUB_ITEM_RECORD";
export const newSubItemRecord = (
  panelId: string,
  subItemKey: string,
  subItem: object
) => ({ type: NEW_SUB_ITEM_RECORD, panelId, subItemKey, subItem });

export const SAVE_NEW_SUB_ITEM_RECORD = "ADMIN_PANEL_SAVE_NEW_SUB_ITEM_RECORD";
export const saveNewSubItemRecord = (
  panelId: string,
  subItemKey: string,
  subItem: object
) => ({
  type: SAVE_NEW_SUB_ITEM_RECORD,
  panelId,
  subItemKey,
  subItem
});

export const CANCEL_NEW_SUB_ITEM_RECORD =
  "ADMIN_PANEL_CANCEL_NEW_SUB_ITEM_RECORD";
export const cancelNewSubItemRecord = (
  panelId: string,
  subItemKey: string
) => ({
  type: CANCEL_NEW_SUB_ITEM_RECORD,
  panelId,
  subItemKey
});

export const DELETE_SUB_ITEM_RECORD = "ADMIN_PANEL_DELETE_SUB_ITEM_RECORD";
export const deleteSubItemRecord = (
  panelId: string,
  subItemKey: string,
  subItemId: number
) => ({
  type: DELETE_SUB_ITEM_RECORD,
  panelId,
  subItemKey,
  subItemId
});

export const SET_SUB_ITEM_MODE = "ADMIN_PANEL_SET_SUB_ITEM_MODE";
export const setSubItemMode = (
  panelId: string, subItemKey: string, subItemId: number, mode: string
) => ({ type: SET_SUB_ITEM_MODE, panelId, subItemKey, subItemId, mode });

export const SET_SUB_ITEM_DATA_AND_MODE = "ADMIN_PANEL_SET_SUB_ITEM_DATA_AND_MODE";
export const setSubItemDataAndMode = (
  panelId: string, subItemKey: string, subItemId: number, subItem: object, mode: string
) => ({ type: SET_SUB_ITEM_DATA_AND_MODE, panelId, subItemKey, subItemId, subItem, mode });

export const ADD_DATA_SUBITEM = "ADMIN_PANEL_ADD_DATA_SUBITEM";
export const addDataSubItem = (panelId: string, subItemKey: string, subItem: object) => ({ type: ADD_DATA_SUBITEM, panelId, subItemKey, subItem });

export const SET_META = "ADMIN_PANEL_SET_META";
export const setMeta = (panelId: string, meta: object) => ({
  type: SET_META, panelId, meta
});

export const RESET_DATA = "ADMIN_PANEL_RESET_DATA";
export const resetData = (panelId: string) => ({ type: RESET_DATA, panelId });

export const ADMIN_PANEL_CLONE = "ADMIN_PANEL_CLONE";
export const cloneAdminPanel = (panelId: string) => ({
  type: ADMIN_PANEL_CLONE, panelId
});

export const CLEAR_PANELS = "ADMIN_PANEL_CLEAR_PANELS";
export const clearPanels = () => ({ type: CLEAR_PANELS });

/* Fetch And Open */
// export const FETCH_AND_OPEN = createRequestActionTypes("FETCH_AND_OPEN");

// export const fetchAndOpen = (panelId: string, panelType: string, recordId: any) =>
//   ({ type: FETCH_AND_OPEN.start, panelId, panelType, recordId });

// export const fetchAndOpenSuccess = (panelId: string, response: object) => ({
//   type: FETCH_AND_OPEN.success, panelId, response
// });

// export const fetchAndOpenError = (panelId: string, error: object) => ({
//   type: FETCH_AND_OPEN.error, panelId, error
// });

export const SET_INVALID_FIELDS = "ADMIN_PANEL_SET_INVALID_FIELDS";
export const setInvalidFields = (panelId: string, invalidFields: string[]) => ({
  type: SET_INVALID_FIELDS, panelId, invalidFields
});

export const SET_API_ERRORS = "ADMIN_PANEL_SET_API_ERRORS";
export const setApiErrors = (panelId: string, apiErrors: ApiErrorsKey) => ({
  type: SET_API_ERRORS, panelId, apiErrors
});

export const CLEAR_API_ERRORS = "ADMIN_PANEL_CLEAR_API_ERRORS";
export const clearApiErrors = (panelId: string) => ({
  type: CLEAR_API_ERRORS, panelId
});

export const ADMIN_PANEL_SET_DETAIL_PAGE = "ADMIN_PANEL_SET_DETAIL_PAGE";
export const setDetailPage = (panelId: string, recordId: any, index: number) => ({
  type: ADMIN_PANEL_SET_DETAIL_PAGE, panelId, recordId, index
});