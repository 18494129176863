import { Dictionary } from "../../../Common/Interfaces/Entity.interface";
import { contractMonthlyRmCategoryCountRoute, contractMonthlyRMCategoryHoursCountRoute, contractMonthlyRmTaskCountRoute, contractMonthlyPPMTaskCountsRoute, contractMonthlyPPMSLATaskCountsRoute, contractMonthlyRMSLATaskCountsRoute } from "services/API/common/contractAPIs";
import { ReportConfig } from "components/ContractInFocus/Maintenance/Interfaces/interactiveTable.interfaces";

export type TasksCountType = Dictionary<{
  key: string;
  display: string;
  position: number;
  role?: string;
}>

export const BasicTaskCountTableMeta: TasksCountType = {
  tasks_carried_forward_manual: {
    key: "tasks_carried_forward_manual",
    display: "Tasks carried forward",
    position: 0
  },
  tasks_issued: {
    key: "tasks_issued",
    display: "Tasks issued for the month",
    role: "issued",
    position: 1
  },
  tasks_completed: {
    key: "tasks_completed",
    display: "Tasks completed",
    role: "completed",
    position: 2
  },
  percent_completed: {
    key: "percent_completed",
    display: "% Completed",
    position: 3
  },
  not_completed_in_a_month: {
    key: "not_completed_in_a_month",
    display: "Not completed in the month",
    position: 4
  },
  time_spent: {
    key: "time_spent",
    display: "Time spent on PPM(hours)",
    position: 5
  }
};

export const SLATaskCountTableMeta: TasksCountType = {
  tasks_issued: {
    key: "tasks_issued",
    display: "Tasks issued for the month",
    role: "issued",
    position: 0
  },
  tasks_completed: {
    key: "tasks_completed_in_sla",
    display: "Tasks completed in SLA",
    role: "completed",
    position: 1
  },
  tasks_completed_outside_sla: {
    key: "tasks_completed_outside_sla",
    display: "Tasks completed outside SLA",
    role: "completed",
    position: 2
  },
  percent_completed: {
    key: "percent_completed",
    display: "% Completed within SLA",
    position: 3
  },
  not_completed_in_a_month: {
    key: "not_completed_in_a_month",
    display: "Tasks outstanding",
    position: 4
  },
  time_spent: {
    key: "time_spent",
    display: "Time spent on PPM(hours)",
    position: 5
  }
};

export const ReportTableType = {
  fabric: {
    caption: "Fabric",
    dataKey: "fabric"
  },
  total: {
    caption: "Total",
    dataKey: "total"
  },
  statutory: {
    caption: "Statutory",
    dataKey: "statutory"
  }
};

export const ReportTableClass: Dictionary<ReportConfig> = {
  PPM: {
    caption: "PPMs",
    dataKey: "PPM",
    endpoint: contractMonthlyPPMTaskCountsRoute//"monthly-ppm-task-counts"
  },
  PPM_SLA: {
    caption: "PPM SLAs",
    dataKey: "PPM",
    endpoint: contractMonthlyPPMSLATaskCountsRoute,
    calculateSumForRowsConfig: ["tasks_issued", "tasks_completed_in_sla", "tasks_completed_outside_sla", "time_spent"],
    rowSumPercentagesConfig: {
      "percent_completed": {
        "nominator": "tasks_completed_in_sla",
        "denominator": "tasks_issued"
      }
    },
  },
  Reactive: {
    caption: "Reactive",
    dataKey: "RM",
    endpoint: contractMonthlyRmTaskCountRoute//"monthly-rm-task-counts"
  },
  Reactive_SLA: {
    caption: "Reactive SLAs",
    dataKey: "RM",
    endpoint: contractMonthlyRMSLATaskCountsRoute,
    calculateSumForRowsConfig: ["tasks_issued", "tasks_completed_in_sla", "tasks_completed_outside_sla", "time_spent"],
    rowSumPercentagesConfig: {
      "percent_completed": {
        "nominator": "tasks_completed_in_sla",
        "denominator": "tasks_issued"
      }
    },
  },
  ReactiveByCategory: {
    caption: "Total Reactive By Category",
    dataKey: "RM",
    endpoint: contractMonthlyRmCategoryCountRoute//"monthly-rm-category-counts"
  },
  ReactiveByHoursCategory: {
    caption: "Total Reactive Hours By Category",
    dataKey: "RM",
    endpoint: contractMonthlyRMCategoryHoursCountRoute
  }
};

