import { createSelector } from "reselect";
import { State } from "store/interfaces/State";
import { createDeepEqualSelector, createShallowEqualSelector } from "helpers/SelectorUtilities/selectorUtilities";
import { getLastXYearsFromFormatted } from "components/Common/Utils/Dates.js";

import { standardDateFormat } from "components/Common/Utils/Dates";

const getRecentYearPeriods = (years?: number) => {
    return getLastXYearsFromFormatted(years || 5);
}

const personalPortfolioNotificationsState = (state: State) => state.personalPortfolioNotificationSettings;

export const portfolioInFocusSnapshotHistory = (state: State) => state.portfolioInFocus.frozenHistory;

// export const portfolioPeriodOptions = (state: State) => getRecentYearPeriods(5);

export const portfolioInFocus = (state: State) => state.portfolioInFocus;

export const portfolioInFocusCache = (state: State) => state.portfolioInFocusCache;

export const portfolioInFocusPeriod = (state: State) => state.portfolioInFocus.selectedPeriod;

export const portfolioInFocusSelector = createSelector(portfolioInFocus, (portfolioInFocus) => portfolioInFocus);

export const portfolioInFocusSnapshotHistorySelector = createDeepEqualSelector(
    portfolioInFocusSnapshotHistory,
    (snapshotHistory) => {
        return {
            ...snapshotHistory,
            data: snapshotHistory.data,
            focus: snapshotHistory?.data?.[snapshotHistory.focus]
        }
    }
);

export const portfolioInFocusPortfolio = createDeepEqualSelector(
    portfolioInFocus,
    (portfolioInFocus) => {
        return portfolioInFocus?.portfolio;
    }
);

export const portfolioInFocusPortfolioId = createDeepEqualSelector(
    portfolioInFocus,
    (portfolioInFocus) => {
        return portfolioInFocus?.portfolio?.id
    }
);

// export const contractInFocusPersonalNotificationSettings = createSelector(
//     personalContractNotificationsState,
//     portfolioInFocus,
//     (personalContractNotificationSettingsList, contractInFocus) => {
//       const personalContractNotificationSettings = { ...personalContractNotificationSettingsList[contractInFocus.contract.id] }
//       return personalContractNotificationSettings;
//     }
//   )

export const portfolioInFocusPersonalNotificationSettings = createSelector(
    personalPortfolioNotificationsState,
    portfolioInFocus,
    (personalContractNotificationSettingsList, portfolioInFocus) => {
        const personalPortfolioNotificationSettings = { ...personalContractNotificationSettingsList[portfolioInFocus.portfolio.id] }
        return personalPortfolioNotificationSettings;
    }
)

export const portfolioInFocusCacheSelector = createSelector(portfolioInFocusCache, (portfolioInFocusCache) => portfolioInFocusCache);

export const portfolioInFocusPortfolioMonthlyMaintenanceSelector = createSelector(
    portfolioInFocus,
    (portfolioInFocus) => portfolioInFocus.monthlyMaintenanceReports
);

export const portfolioInFocusSnapshotFocusSelector = createSelector(
    portfolioInFocusSnapshotHistory,
    (frozenHistory) => frozenHistory?.data?.[frozenHistory.focus]
);

export const portfolioInFocusFrozenForSelector = createSelector(
    portfolioInFocusSnapshotFocusSelector,
    (focus) => {
        return focus ? `- frozen for ${standardDateFormat(focus.frozen_for)}` : ``
    });

export const portfolioDocumentsViewerSelector = (state: State) => state.portfolioDocumentsViewer;

// const portfolioMeterStreamState = (state: State) => state.portfolioMeterStreams;

// export const portfolioInFocusMeterStreamsSelector = createDeepEqualSelector(
//     portfolioMeterStreamState,
//     portfolioInFocus,
//     (meterStreams, portfolioInFocus) => {
//         const meterStreamsForPortfolio = { ...meterStreams[portfolioInFocus.portfolio.id] }
//         meterStreamsForPortfolio.data && meterStreamsForPortfolio.data.sort((a: any, b: any) => a.utility_type.display_name.localeCompare(b.utility_type.display_name) || a.title.localeCompare(b.title));
//         return meterStreamsForPortfolio;
//     }
// )

