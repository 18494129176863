import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";


export const getVisitListColumns = (documentsCellRender: any, renderPTWsCell: any, disable_work_permits_system: boolean) => {
  let columns = [
    {
      dataField: 'order',
      sortOrder: 1,
      width: 150
    },
    {
      dataField: 'service_type',
      editorType: "dxSelectBox",
      //width: 300
    },
    {
      dataField: 'scheduled_for',
      dataType: 'date',
      width: 150,
      format: "dd-MM-y"
    },
    {
      dataField: 'booked',
      dataType: 'boolean',
      width: 120,
    },
    {
      dataField: 'status',
      width: 180,
      //editorType: "dxSelectBox",
    },
    {
      dataField: 'visit_reports_count',
      caption: 'Documents',
      width: 100,
      cellRender: documentsCellRender
    },
  ];
  if (!disable_work_permits_system) {
    columns.push({
      dataField: '',
      caption: 'Permits',
      width: 100,
      cellRender: renderPTWsCell
    })
  }
  return columns;

}

export const extraVisitListMeta: FieldMetaGroup = {
  'order': {
    type: 'number',
    required: false,
    field_name: 'order',
    read_only: true,
    label: 'Visit Number',
  }
}
