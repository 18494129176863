import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { Button } from 'reactstrap';
import DataGrid, {
  Column,
  Editing,
  Paging,
  Lookup,
  RequiredRule,
  FilterRow
} from "devextreme-react/data-grid";

import DataSource from "devextreme/data/data_source";

// Own
import CommonAPIService from "components/ContractInFocus/Services/commonAPI.services";
import { exportOpenReactives } from "components/ContractInFocus/Maintenance/Services/MaintenanceService";
import CustomStore from "devextreme/data/custom_store";
import Header from "../../../../Common/Components/GridHeader/GridHeader";
import PrintDataGrid from "../../../../Common/Components/PrintDataGrid/PrintDataGrid";
import { getSubTitle } from "components/ContractInFocus/Components/ContractPrintTitle/ContractPrintTitle";
import { IOpenReactives } from "./OpenReactives.interface";
import { Paper } from "@material-ui/core";
import { InlineWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { TableChartWrapper } from "../MaintenanceTableChart/MaintenanceTableChartStyles";
import { filterRowConfig, gridMetaInitialState } from "components/ContractInFocus/Models/Grid";
import { DataGridMeta, ColumnProps } from '../../../interfaces/dataGridColumn.interface';
import { getColumnProps, editableTextAreaOnPreparing, getGridProps } from '../../../../../helpers/DataGrid/DataGridColumn.helper';
import { columnPropsPlaceHolder } from 'components/ContractInFocus/Models/ColumnProps';
import { openReactiveColumns, openPortfolioReactiveColumns } from "./Models/OpenPMs.models";
import { saveDateTimeFormat } from "../../../../Common/Utils/Dates";
import * as selectors from "components/ContractInFocus/Selectors/contractInFocus.selectors";
import { ContractInterface } from "components/AdminPanel/Contracts/Interfaces/Contract.interface";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { PrintChartAndTableLabels } from "components/Common/constants.js";
import { includeCSRFHeader } from "components/ContractInFocus/Interfaces/ContractInFocus.interfaces";
import { getCSRF, prependToAPI } from "services/API/API.helper";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

const getContractEndpoint = (contractRef: string | number) => `contracts/${contractRef}/open-reactive-tasks/?format=json`;
const getPortfolioEndpoint = (portfolioId: string | number) => `portfolios/${portfolioId}/open-reactive-tasks/?format=json`;

interface OpenReactivesProps {
  title: string;
  //contract: ContractInterface;
  contract: SiteContract;
  portfolio?: HydratedPortfolio
}

const OpenReactives = ({ title, contract, portfolio }: OpenReactivesProps) => {
  const selectFrozenFor = useSelector(selectors.contractInFocusFrozenForSelector);
  const [dataSource, setDataSource] = useState<DataSource>();
  const [metadata, setMetadata] = useState<DataGridMeta>(gridMetaInitialState);
  const [, setContentReady] = useState(false); // NOTE: force re-render
  const csrfToken = getCSRF();
  const isMountedRef = useRef(true);

  const getEndpoint = useCallback(() => {
    // note that the functions returned from this function don't actually use the argument that will be passed to it - it's a 'closure' so has the id 'baked in'
    let thisGetEndpoint: any = (id: string | number) => "/get-open-reactives/never";
    if (contract) {
      thisGetEndpoint = getContractEndpoint(contract.contract_ref);
    } else if (portfolio) {
      thisGetEndpoint = getPortfolioEndpoint(portfolio.id);
    }
    return thisGetEndpoint;
  }, [portfolio, contract])

  useEffect(() => {
    if (contract || portfolio) {
      const contractOrPortfolioId = contract?.contract_ref || portfolio?.id as string | number;
      const custom = new CustomStore({
        key: "id",
        load: loadOptions => CommonAPIService.getAll<IOpenReactives>(getEndpoint, setMetadata, contractOrPortfolioId),
        insert: values => CommonAPIService.create<IOpenReactives>({ getEndpoint, contract, portfolio, values }),
        // @ts-ignore
        remove: key => CommonAPIService.del<IOpenReactives>(getEndpoint, contractOrPortfolioId, key),
        update: (id, values) => CommonAPIService.update<IOpenReactives>(getEndpoint, contractOrPortfolioId, id, values)
      });

      isMountedRef.current && setDataSource(
        new DataSource({
          store: custom
        })
      );
      return () => {
        //dataStore && dataStore.dispose();
        isMountedRef.current = false;
      }
    }
  }, [contract, portfolio, getEndpoint]);

  const handleRowInserting = (values: any): void => {
    values.data.due_by = saveDateTimeFormat(values.data.due_by);
  };

  const getColumnPropsExt = (field: string): ColumnProps => {
    return metadata.loaded ? getColumnProps(field, metadata.activeMeta) : columnPropsPlaceHolder;
  }

  const handleGoToOpenReactivesImport = () => {
    //window.open("api/admin/core/openppmtask/import/?contract_ref=" + contract.contract_ref, "_self");//, "_blank"
    if (portfolio) {
      window.open(`${prependToAPI}api/admin/core/openreactivetask/import/?portfolio=${portfolio.id}`, "_blank");
    } else {
      window.open(`${prependToAPI}api/admin/core/openreactivetask/import/?contract_ref=${contract.contract_ref}`, "_blank");
    }
  }

  const handleOpenReactivesExport = () => {
    exportOpenReactives({ contract, portfolio, headers: includeCSRFHeader(csrfToken, { 'content-type': 'application/x-www-form-urlencoded' }) });
  }

  const handleEditingStart = () => {
    setMetadata({ ...metadata, activeMeta: metadata.PUTMeta });
  }

  const handleRowUpdated = () => {
    setMetadata({ ...metadata, activeMeta: metadata.POSTMeta });
  }

  return (
    <TableChartWrapper data-test-id="openReactives">
      <InlineWrapper>
        <Paper elevation={3}>
          <Header
            title={title}
            subTitle={getSubTitle(metadata)}
            className={PrintChartAndTableLabels ? '' : 'no-print'}
          //subTitle={`Your open reactives for this contract ${ selectFrozenFor } `}
          />
          <div className="dataActionButtonsWrapper">
            {metadata.privileges.POST && <Button
              onClick={handleGoToOpenReactivesImport}
              className="no-print"
              color="primary">Import Open Reactive Tasks</Button>
            }
            {metadata.privileges.POST && <Button
              onClick={handleOpenReactivesExport}
              className="no-print"
              color="primary">Export Open Reactive Tasks</Button>
            }
          </div>

          {dataSource && (
            <DataGrid
              className="no-print"
              dataSource={dataSource}
              {...getGridProps(metadata.activeMeta)}
              onEditorPreparing={editableTextAreaOnPreparing(metadata.activeMeta)}
              onRowInserting={handleRowInserting}
              onEditingStart={handleEditingStart}
              onRowUpdated={handleRowUpdated}
              onContentReady={() => setContentReady(true)}
            >
              <FilterRow visible={filterRowConfig.visible} applyFilter={filterRowConfig.applyFilter} />
              <Paging enabled={false} />
              <Editing
                mode="cell"
                allowUpdating={metadata.privileges.PUT}
                allowDeleting={metadata.privileges.DELETE}
                allowAdding={metadata.privileges.POST}
              />

              {portfolio && <Column
                {...getColumnPropsExt('contract_ref')}
                width="8%"
                minWidth={undefined}
                caption="Contract"
              />
              }

              <Column
                {...getColumnPropsExt('task_ref')}
                width="8%"
                minWidth={undefined}
              >
                <RequiredRule />
              </Column>
              <Column
                {...getColumnPropsExt('job_description')}
                width="28%"
              >
                <RequiredRule />
              </Column>
              <Column
                {...getColumnPropsExt('location')}
                width="15%"
                minWidth={undefined}
              >
                <RequiredRule />
              </Column>
              <Column
                {...getColumnPropsExt('discipline')}
                width="15%"
                minWidth={undefined}
              >
                <RequiredRule />
              </Column>
              <Column
                {...getColumnPropsExt('due_by')}
                width="8%"
                minWidth={undefined}
              />
              <Column
                {...getColumnPropsExt('reason')}
                width="26%"
              />
            </DataGrid>
          )}
          <PrintDataGrid
            meta={metadata.activeMeta}
            visibleColumns={portfolio ? openPortfolioReactiveColumns : openReactiveColumns}
            records={dataSource?.items()}
          />
        </Paper>
      </InlineWrapper>
    </TableChartWrapper>
  );
};

export default OpenReactives;
