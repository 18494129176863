import { APIR } from "services/API/API";
import { unWrapListDataAndMeta } from "services/API/API.helper";

export const ROUTE = "/contract-periods/";

export const contractPeriodFrozenForListApi = (contractPeriodId: any) => APIR.get(`${ROUTE}${contractPeriodId}/frozen-for-list/?format=json`).pipe(
  unWrapListDataAndMeta()
)

export const portfolioPeriodFrozenForListApi = (portfolioPeriodId: any) => APIR.get(`/portfolio-periods/${portfolioPeriodId}/frozen-for-list/?format=json`).pipe(
  unWrapListDataAndMeta()
)
