import styled from "styled-components";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import styles from "../../../styles/app/common/variables.scss";

export const HeaderLogoWrapper = styled.div`
  position: relative;
  cursor: pointer;
  img {
    position: absolute;
    left: -10px;
    height: 40px;
    width: auto;
  }
  .img-placeholder {
    width: 30px;
    padding-right: 3.2rem;
    height: 40px;
  }
`;

export const HeaderWrapper = styled.div`
  input {
    border: 0;
    flex-grow: 1;
    font-size: 1.2rem;
    background: transparent;

    &::placeholder {
      color: ${styles.grayMedDark};
    }
  }
`;


export const HeaderFiller = styled.div`
  flex-grow: 1;
  }
`;

const iconSize = {
  height: "1.75rem",
  width: "1.75rem"
};

export const useHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    MuiAppBar: {
      transition: 'background-color 0.5s ease',
      backgroundColor: styles.blueDark,
    },
    searchAppBar: {
      transition: 'background-color 0.5s ease',
      backgroundColor: styles.white,
      color: styles.blueDark,
    },
    darkMenuIcon: {
      ...iconSize
    },
    lightMenuIcon: {
      ...iconSize,
      color: styles.blueDark
    },
    searchCloseButton: {

    },
    menuButton: {
      marginRight: theme.spacing(1)
    },
    title: {
      flexGrow: 1
    }
  })
);
