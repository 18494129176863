import React, { useState, useEffect, useRef, useCallback } from 'react';
import MatConfirmationDialog from 'components/Common/Components/Material/MatConfirmationDialog/MatConfirmationDialog';

export interface WithContextProps {
    [idx: string]: any;
}

export type CloseOrSaveConfimationMode = "save?" | "close?";

export type ConfirmationDialogueInitialState = {
    open: boolean;
    agreeCallback: () => void;
    disagreeCallback: () => void;
    message?: string;
}

const SaveOnRowCollapseWrapper = (CP: React.FC<any>, confirmationMode?: CloseOrSaveConfimationMode, masterViewSharedSpace?: React.MutableRefObject<any>) => {

    const WrappedComponent: React.FunctionComponent<WithContextProps> = ({
        ...props
    }) => {
        const thisSharedSpace = useRef<any>({});
        const thisMasterViewSharedSpace = masterViewSharedSpace || thisSharedSpace;
        const closeDetailViewMessage = confirmationMode === 'save?' ? 'Save changes?' : 'Leave without saving changes?';
        const rowCollapseConfirmationInitialState = useRef<ConfirmationDialogueInitialState>({ open: false, agreeCallback: () => { }, disagreeCallback: () => { } });
        const [rowCollapseConfirmationDialogue, setRowCollapseConfirmationDialogue] = useState(rowCollapseConfirmationInitialState.current);

        const deleteConfirmationInitialState = useRef<ConfirmationDialogueInitialState>({ open: false, agreeCallback: () => { }, disagreeCallback: () => { }, message: "Are you sure you want to delete this item?" });
        const [deletionConfirmationDialogue, setDeletionConfirmationDialogue] = useState(deleteConfirmationInitialState.current);

        const agreeInterruptConfirmationInitialState = useRef<ConfirmationDialogueInitialState>({ open: false, agreeCallback: () => { }, disagreeCallback: () => { }, message: confirmationMode === 'save?' ? 'Save changes?' : 'Leave without saving changes?' });
        const [agreeInterruptConfirmationDialogue, setAgreeInterruptConfirmationDialogue] = useState(agreeInterruptConfirmationInitialState.current);

        const closeRowCollapseDialogue = useCallback((): void => {
            setRowCollapseConfirmationDialogue(rowCollapseConfirmationInitialState.current);
        }, [rowCollapseConfirmationInitialState]);

        const closeDeletionDialog = useCallback((): void => {
            setDeletionConfirmationDialogue(deleteConfirmationInitialState.current);
        }, [deleteConfirmationInitialState]);

        const closeAgreeInterruptDialogue = useCallback((): void => {
            setAgreeInterruptConfirmationDialogue(agreeInterruptConfirmationInitialState.current);
        }, [agreeInterruptConfirmationInitialState]);

        const resumeRowCollapse = useCallback((e: any) => {
            const rowLevelSharedSpace = thisMasterViewSharedSpace.current[e.key];
            rowLevelSharedSpace.collapse = true;
            rowLevelSharedSpace.data = false;
            rowLevelSharedSpace.formChanged = false;
            e.cancel = false;
            e.component.collapseRow(e.key);
            thisMasterViewSharedSpace.current.allowReload = true; // sometimes we have to throttle reloading with dx grid as it can get stuck in loops under certain filtering conditions
            // allowReload ensures that it will reload at least once when we call reload
            thisMasterViewSharedSpace.current.dataSource.reload();
        }, [thisMasterViewSharedSpace]);

        const onRowCollapsing = useCallback((e: any) => {
            // important to use useCallback here and onRowExpanding
            const rowLevelSharedSpace = thisMasterViewSharedSpace.current[e.key];
            const formChanged = rowLevelSharedSpace?.formChanged;
            if (formChanged) {
                e.cancel = true;
                setRowCollapseConfirmationDialogue({
                    open: true,
                    agreeCallback: () => {
                        closeRowCollapseDialogue();
                        if (confirmationMode === 'save?') {
                            rowLevelSharedSpace.handleSave && rowLevelSharedSpace.handleSave(e.key, () => resumeRowCollapse(e));
                        } else {
                            resumeRowCollapse(e);
                        }
                    },
                    disagreeCallback: () => {
                        rowLevelSharedSpace.collapse = false;
                        // end before
                        closeRowCollapseDialogue();
                        if (confirmationMode === 'save?') {
                            rowLevelSharedSpace.handleCancel && rowLevelSharedSpace.handleCancel(e.key, () => resumeRowCollapse(e));
                        }
                    }
                })
            } else {
                if (thisMasterViewSharedSpace.current.reloadOnCollapse) {
                    thisMasterViewSharedSpace.current.allowReload = true;
                    thisMasterViewSharedSpace.current.dataSource && thisMasterViewSharedSpace.current.dataSource.reload();
                }
            }
        }, [thisMasterViewSharedSpace, closeRowCollapseDialogue, resumeRowCollapse]);

        const onRowExpanding = useCallback((e: any) => {
            let rowLevelSharedSpace = thisMasterViewSharedSpace.current[e.key];
            if (!rowLevelSharedSpace) {
                thisMasterViewSharedSpace.current[e.key] = {};
                rowLevelSharedSpace = thisMasterViewSharedSpace.current[e.key];
            }
            rowLevelSharedSpace.collapse = false;
            const thisOnRowExpanding = thisMasterViewSharedSpace?.current.onRowExpanding;
            thisOnRowExpanding && thisOnRowExpanding(e);
        }, [thisMasterViewSharedSpace])

        const requestInterrupt = useCallback((onAgree: () => void, onDisagree: () => void, message?: string) => {
            setAgreeInterruptConfirmationDialogue({
                open: true,
                agreeCallback: () => {
                    closeAgreeInterruptDialogue();
                    onAgree();
                },
                disagreeCallback: () => {
                    closeAgreeInterruptDialogue();
                    onDisagree();
                },
                message: message
            })
        }, [closeAgreeInterruptDialogue]);

        const requestDelete = useCallback((onAgree: () => void, onDisagree: () => void, message?: string) => {
            setDeletionConfirmationDialogue({
                open: true,
                agreeCallback: () => {
                    closeDeletionDialog();
                    onAgree();
                },
                disagreeCallback: () => {
                    closeDeletionDialog();
                    onDisagree();
                },
                message: message
            })
        }, [closeDeletionDialog]);

        return (
            <>
                <MatConfirmationDialog
                    onAgree={rowCollapseConfirmationDialogue.agreeCallback}
                    onDisagree={rowCollapseConfirmationDialogue.disagreeCallback}
                    open={rowCollapseConfirmationDialogue.open}
                    className="collapseAdminRowCheck"
                    actions={{ agree: 'Yes', disagree: 'No' }}
                >
                    {closeDetailViewMessage}
                </MatConfirmationDialog>
                <MatConfirmationDialog
                    onAgree={deletionConfirmationDialogue.agreeCallback}
                    onDisagree={deletionConfirmationDialogue.disagreeCallback}
                    open={deletionConfirmationDialogue.open}
                    className="collapseAdminRowCheck"
                    actions={{ agree: 'Yes', disagree: 'No' }}
                >
                    {deletionConfirmationDialogue.message}
                </MatConfirmationDialog>
                <MatConfirmationDialog
                    onAgree={agreeInterruptConfirmationDialogue.agreeCallback}
                    onDisagree={agreeInterruptConfirmationDialogue.disagreeCallback}
                    open={agreeInterruptConfirmationDialogue.open}
                    className="collapseAdminRowCheck"
                    actions={{ agree: 'Yes', disagree: 'No' }}
                >
                    {agreeInterruptConfirmationDialogue.message}
                </MatConfirmationDialog>

                <CP
                    onRowCollapsing={onRowCollapsing}
                    onRowExpanding={onRowExpanding}
                    masterViewSharedSpace={thisMasterViewSharedSpace}
                    requestDelete={requestDelete}
                    requestInterrupt={requestInterrupt}
                    {...props}
                />
            </>
        )
    }
    return WrappedComponent;
}

export default SaveOnRowCollapseWrapper;