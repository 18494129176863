import {
  DownloadRequest,
  DOWNLOAD_REQUEST,
  DOWNLOAD_REQUEST_REMOTE,
  DOWNLOAD_CLEAR,
  DownloadActionTypes,
  DownloadSave,
  DOWNLOAD_STANDBY,
  DownloadRequestRemote
} from '../Types/Download.types';

export const downloadRequest = (payload: DownloadRequest): DownloadActionTypes =>
  ({ type: DOWNLOAD_REQUEST.start, payload });

export const downloadRequestSuccess = (payload: DownloadSave): DownloadActionTypes =>
  ({ type: DOWNLOAD_REQUEST.success, payload });

export const downloadStandby = (): DownloadActionTypes =>
  ({ type: DOWNLOAD_STANDBY.reducer });

export const downloadClear = (): DownloadActionTypes =>
  ({ type: DOWNLOAD_CLEAR.reducer });

export const downloadRequestRemote = (payload: DownloadRequestRemote): DownloadActionTypes =>
  ({ type: DOWNLOAD_REQUEST_REMOTE.start, payload });

export const downloadRequestRemoteSuccess = (payload: DownloadSave): DownloadActionTypes =>
  ({ type: DOWNLOAD_REQUEST_REMOTE.success, payload });
