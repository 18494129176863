import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";

export const getHASTaskChecksListColumns = (documentsCellRender: any, renderCompletedOnCell: any) => {
    return [
        {
            dataField: 'order',
            sortOrder: 1,
            width: 150
        },
        {
            dataField: 'check_type',
        },
        {
            dataField: 'scheduled_for',
            dataType: 'date',
            width: 150,
            format: "dd-MM-y"
        },
        {
            dataField: 'status',
            width: 180,
        },
        {
            dataField: 'completed_on',
            dataType: 'date',
            width: 150,
            format: "dd-MM-y",
            cellRender: renderCompletedOnCell
        },
        {
            dataField: 'supporting_documents_count',
            caption: 'Documents',
            width: 100,
            cellRender: documentsCellRender
        },
    ];
}

export const extraHASTaskChecksListMeta: FieldMetaGroup = {
    'order': {
        type: 'number',
        required: false,
        field_name: 'order',
        read_only: true,
        label: 'Check Number',
    }
}
