import React, { useState } from "react";

// Own
import { RightVisibilityMenuWrapper } from "./RightVisibilityMenuStyles";
import RightContextButton from "./Components/RightContextButton";
import { VisibilityControls } from "./Components/VisibilityControl/VisibilityControl";
import { VisibilitySettings } from "./Interfaces/RightContext.interfaces";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { Portfolio } from 'components/Portfolios/Interfaces/Portfolios.interface';

interface RightVisibilityMenuProps {
  visibilitySettings: VisibilitySettings;
  defaultOnClick: (updateKey: string) => void;
  appliedTo?: any;
}

const RightVisibilityMenu = ({ visibilitySettings, defaultOnClick, appliedTo }: RightVisibilityMenuProps
) => {

  const [showSettings, setShowSettings] = useState(false);

  return (
    <>
      <RightVisibilityMenuWrapper
        className={`animated ${showSettings ? 'fadeIn' : 'fadeOutUpShort'}`}
        hidden={!showSettings}
        onMouseLeave={() => setShowSettings(false)}
      >
        <VisibilityControls canWrite defaultOnClick={defaultOnClick} appliedTo={appliedTo} visibilitySettings={visibilitySettings} />
      </RightVisibilityMenuWrapper>

      <RightContextButton
        showSettings={!showSettings}
        onShowSettings={() => setShowSettings(!showSettings)}
      />
    </>
  );
};

export default RightVisibilityMenu;
