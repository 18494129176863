import React, { ReactNodeArray, ReactNode } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Own
import './MatConfirmationDialog.scss';

interface MatConfirmationDialogAction {
  agree: string;
  disagree: string;
}

interface MatConfirmationDialogProps {
  id?: string;
  title?: string;
  open: boolean;
  className?: string;
  onAgree: (e: any) => void;
  onDisagree: (e: any) => void;
  onClose?: (e: any) => void;
  actions: MatConfirmationDialogAction;
  children?: ReactNode | ReactNodeArray;
  zIndex?: number;
}

const MatConfirmationDialog = ({
  open,
  onClose,
  onAgree,
  onDisagree,
  actions,
  title,
  id,
  className,
  children,
  zIndex
}: MatConfirmationDialogProps) => {
  return (
    <Dialog
      id={id}
      className={`mat-confirmation-dialog ${className || ''}`}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title ? title : ''}</DialogTitle>
      {children ? (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {children}
          </DialogContentText>
        </DialogContent>
      ) : null}
      <DialogActions>
        <Button onClick={onDisagree} color="primary">
          {actions.disagree}
        </Button>
        <Button onClick={onAgree} color="primary" autoFocus>
          {actions.agree}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MatConfirmationDialog;