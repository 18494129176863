const apiConfig = {
  // timeout: 5000,
  // responseType: "json",
  // mode: "no-cors",
  // headers: {
  //   "Content-Type": "application/json"
  // }
};

export default apiConfig;
