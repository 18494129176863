import React, { useState, useEffect, useRef, useCallback } from 'react';

import Button from '@material-ui/core/Button';
import { store } from "store/store";

// Own
import { addNotification } from 'components/Notification/Actions/Notification.actions';
import { AutoInput } from 'components/Common/Components/AutoInput/AutoInput';
import { FieldsFormConfig } from "components/Common/Components/DocumentsGrid/DocumentsGrid.interface";
import { PreFlightListInfo, Primitive } from "components/Common/Interfaces/Entity.interface";
import { simpleFetchSnapshotPublications, publishSnapshot } from "components/ContractInFocus/Services/snapShot.service";
import { Snapshot } from "components/ContractInFocus/Interfaces/ContractInFocus.interfaces";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import * as notificationTypes from 'components/Notification/Constants/constants';
import { formValueConsideredMissing } from "store/Common/Helpers/commonHelpers";

// Styles
import "components/ContractInFocus/ContractReportVisibility/contractReportVisibility.scss";
import "components/Common/Components/DocumentsGrid/Styles/grids.scss";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
interface LimitController {
    [documentType: string]: (documentType: string, parentId: string) => boolean;
}

export interface FormValues {
    [field: string]: Primitive; //represents field: actual value
}

interface PublicationFormProps {
    preFlightInfo: PreFlightListInfo;
    formValues: React.MutableRefObject<{}>;
    fieldConfigs: FieldsFormConfig;
    contract?: SiteContract;
    portfolio?: HydratedPortfolio;
    snapshot: Snapshot;
    existingPublications?: any[];
    setSnapshotPublications: React.Dispatch<React.SetStateAction<any[] | undefined>>;
    wipe?: boolean;
}

const PublicationForm = (
    {
        preFlightInfo,
        formValues,
        fieldConfigs,
        contract,
        portfolio,
        snapshot,
        existingPublications,
        setSnapshotPublications,
        wipe,
    }: PublicationFormProps) => {

    const metaForCreate = preFlightInfo.meta;
    const { canCreate, itemsLimit } = preFlightInfo;
    // we use both state and a ref, because generally it's nice to change form values without repainting, but if a side effect runs, you'll want to repaint.
    const formValuesRef = useRef<FormValues>({});
    const [formValuesState, setFormValuesState] = useState(formValuesRef.current);

    const [readyToCreate, setReadyToCreate] = useState(false);
    const currentFocus = useRef<string>();

    const handlePublishSnapshot = (): void => {
        const payload = {
            snapshot: snapshot?.id,
            ...formValuesState
        }
        snapshot && publishSnapshot({ contractId: contract?.contract_ref, portfolioId: portfolio?.id, snapshotId: snapshot.id, payload: payload }).then((response) => {
            store.dispatch(addNotification({ message: "snapshot notifications sent", type: notificationTypes.NOTIFICATION_SUCCESS }))
            simpleFetchSnapshotPublications({ contractId: contract?.contract_ref, portfolioId: portfolio?.id, snapshotId: snapshot.id }).then((response: { data: any, meta: any, privileges: any }) => {
                setFormValuesState({});
                formValuesRef.current = {};
                setReadyToCreate(false);
                setSnapshotPublications(response.data);
            })
        }
        )
    };

    const generalLimit = itemsLimit?.all;
    const generalLimitExceeded = generalLimit ? generalLimit?.currentCount <= generalLimit.max : false;

    useEffect(() => {
        wipe && setFormValuesState({});
    }, [wipe])

    useEffect(() => {
        formValues.current = formValuesState;
    }, [formValuesState, formValues])

    const onChangeFormValues = useCallback((newValues: FormValues) => {
        const updatedFormValues = { ...formValuesRef.current, ...newValues }
        let sideEffectsRan = [];
        let sideEffectsToRun = Object.keys(fieldConfigs).filter(x => fieldConfigs[x].sideEffect !== undefined);
        formValuesRef.current = updatedFormValues;
        const newValueKey = Object.keys(newValues)[0];
        const missingValues = Object.keys(metaForCreate).filter(
            (k) => {
                const meta = metaForCreate[k];
                const config = fieldConfigs[k];
                const fV = formValuesRef.current[k];
                const missing = formValueConsideredMissing({
                    config,
                    meta,
                    formValue: fV,
                })
                //const missing = (((meta.required || config?.forceRequired) && (typeof (fV) === "undefined" || typeof (fV) === "string" && fV.trim().length === 0)));
                return missing;
            }
        );

        for (let config of Object.keys(fieldConfigs)) {
            if (typeof (fieldConfigs[config].sideEffect) !== "undefined") {
                //@ts-ignore
                const sideEffectRan = fieldConfigs[config].sideEffect(updatedFormValues, fieldConfigs, onChangeFormValues);
                if (sideEffectRan) {
                    sideEffectsRan.push(config)
                }
            }
        }
        if (sideEffectsToRun.length === 0 || sideEffectsRan.length > 0) {
            setFormValuesState(formValuesRef.current); //necessary to make form inputs re-render/compute - for sideeffects.
            sideEffectsRan = [];
        }
        const checkIsReady = missingValues.length === 0 && canCreate && !generalLimitExceeded;
        setReadyToCreate(checkIsReady);
    }, [canCreate, fieldConfigs, generalLimitExceeded, metaForCreate])

    useEffect(() => {
        onChangeFormValues(formValuesState);
    }, []);

    const hasMeta = () => metaForCreate && !!Object.keys(metaForCreate).length;

    return <div className="publicationFormWrapper">
        {hasMeta() && canCreate ? (

            Object.keys(fieldConfigs).map((dataField) => {
                const fieldConfig = fieldConfigs[dataField];
                return <AutoInput
                    zIndex={1200}
                    key={dataField}
                    dataField={dataField}
                    fieldConfig={fieldConfig}
                    fieldMeta={metaForCreate[dataField]}
                    formValuesRef={formValuesRef}
                    onChangeFormValues={onChangeFormValues}
                    currentFocus={currentFocus}
                />
            })

        ) : null}
        <Button onClick={handlePublishSnapshot}
            color="secondary"
            type="submit"
            variant="contained"
            disabled={!readyToCreate}
            style={
                {
                    float: "right",
                    marginTop: "1rem"
                }
            }>
            {!existingPublications?.length ? 'Publish Snapshot' : 'Re-Publish Snapshot'}
        </Button>
        {/* <Button onClick={handleClose}>
                Close
            </Button> */}
    </div>
}

export default PublicationForm;