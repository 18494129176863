import { useDispatch } from "react-redux";
import $ from "jquery";

// Own
import { store } from "store/store";
import { hideSublistsInSelectionMode } from "store/SelectedEntities/selectEntitySettings";
import { SelectionChangedEvent } from "interfaces/datagrid.interfaces";
import { addSelectedEntitiesToPanel } from "store/SelectedEntities/Actions/SelectedEntities.actions";
import { closePanel } from "components/AdminPanel/Helpers/AdminPanel.helper";

export interface SelectionParams {
    hideDetailViews: boolean,
    activateSelectionMode: boolean,
    selectionHandleCellPrepared: (e: any) => void,
    handleSelectionChanged: (e: SelectionChangedEvent) => void,
    allowSelectionLaunch: boolean,
    rowsForPreSelection: (number | string)[]
    submitLinksButtonConfig: object,
}

export const useSelectionParams = (data: any, panelId: string): SelectionParams => {
    const dispatch = useDispatch();
    const values = {
        hideDetailViews: false,
        activateSelectionMode: false,
        selectionHandleCellPrepared: (e: any): void => { },
        handleSelectionChanged: (e: SelectionChangedEvent): void => { },
        allowSelectionLaunch: true,
        rowsForPreSelection: [],
        submitLinksButtonConfig: {}
    }
    const selectionConfig = store.getState().selectedEntityConfigurations[panelId];
    if (!selectionConfig) {
        // this may seem pointless but it ensures a consistent shape and we have to call this function always
        // in our component if we want to use it as a hook (hooks cannot be called conditionally)
        return values
    }
    // if we're launching an admin form in selection mode, we do not need the config to change while it is open.. hence getState rather than useSelector
    // prevents other panels using this same function making each other rerender when a new config or selection is added.

    const panel = store.getState().adminPanel.panels[panelId];
    const potentialSelectionTargets = panel.panelType && panel.panelType === selectionConfig?.linkedRecordType;
    const idsAlreadyConnected = selectionConfig?.currentMemberIds || [];

    const getPreviouslySelectedRowIds = () => {
        //console.log('currently selected for panelId: ', panelId, ' :', store.getState().selectedEntities[panelId]);
        const sel = panelId ? store.getState().selectedEntities[panelId] : null
        return sel ? sel : []
    }

    const getRowsForPreSelection = () => {
        return [...getPreviouslySelectedRowIds(), ...idsAlreadyConnected]
    }

    function triggerAddLinks() {
        //note that the onAddLinks function should be a closure with a reference to the source to be linked to, so no need to supply that here
        return () => {
            selectionConfig.onAddLinks(getPreviouslySelectedRowIds());
            closePanel(panelId);
            //trigger close panel here too
        }
    }

    function handleCellPrepared(e: any) {
        if (potentialSelectionTargets && e.rowType === "data" && e.column.command === 'select' && selectionConfig.currentMemberIds.includes(e.data.id)) {
            const select = e.cellElement.find('.dx-select-checkbox').dxCheckBox("instance")
            select.option("disabled", true);
            select.option("checked", true);
            e.cellElement.off();
        }
    }

    const submitLinksButtonConfig = {
        location: "after",
        widget: "dxButton",
        options: {
            disabled: true,
            text: "SUBMIT SELECTION",
            onClick: triggerAddLinks(),
            elementAttr: { id: 'linkSubmissionsButton' }
        }
    }

    function adjustSubmissionButton(selectedRows: (string | number)[]) {
        const submissionButton = $("#linkSubmissionsButton")
        const dxSubmissionButton = submissionButton.dxButton("instance")
        if (selectedRows.length) {
            dxSubmissionButton.option("disabled", false)
            submissionButton.addClass("submission-ready");
        } else {
            dxSubmissionButton.option("disabled", true)
            submissionButton.removeClass("submission-ready");
        }
    }

    function handleSelectionChanged(e: SelectionChangedEvent) {
        const incomingSelectedIds = e.selectedRowsData?.map(ob => ob.id);
        const toAdd = incomingSelectedIds ? incomingSelectedIds.filter((id: any) => !idsAlreadyConnected.includes(id)) : [];
        adjustSubmissionButton(toAdd);
        panelId &&
            selectionConfig.selectActionIdentifier &&
            toAdd && dispatch(addSelectedEntitiesToPanel(panelId, selectionConfig.selectActionIdentifier, toAdd))
    }

    return {
        hideDetailViews: selectionConfig.hideSublistsInSelectionMode || hideSublistsInSelectionMode,
        activateSelectionMode: potentialSelectionTargets, //this isn't the same as checking whether the panel is for selection - it should verify this grid (e.g. tab) is
        selectionHandleCellPrepared: handleCellPrepared,
        handleSelectionChanged,
        allowSelectionLaunch: false,
        rowsForPreSelection: getRowsForPreSelection(),
        submitLinksButtonConfig,
    }
};