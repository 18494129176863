import React, { useEffect, useState, useRef, useReducer, useCallback } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from "react-router-dom";
//import { useSelector } from "react-redux";
import moment from 'moment';

import { store } from "store/store";
import { addNotification } from 'components/Notification/Actions/Notification.actions';
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from 'components/Notification/Constants/constants';
import SaveOnRowCollapseWrapper from "components/Common/Components/GridPatterns/SaveOnRowCollapseWrapper";
import HASCheckDetail from "components/Schedulers/HASDetailModal/HASCheckDetail/HASCheckDetail";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

// TODO:
// ask about not allowing visits outside of supplier contract start/end date.

// Own
import HASTaskChecksList from "components/Schedulers/HASDetailModal/HASCheckList";
import { ParseAPIResponseWithPUTMeta } from 'services/Interface/Interface';
import HASTaskDetailView from "./HASTaskDetailView"
import { TabbedPanel } from "components/Common/Components/TabPanel";
import { simpleFetchContractBoundHASTask, simpleFetchHASContractTaskNestedHASTask, deleteContractBoundHASTask } from "components/AdminPanel/HAS/HASTaskServices";
import { HASContractTask, HASTaskCheck, HASTask } from "components/AdminPanel/HAS/Interfaces/HASTaskInterfaces";
import HASDocumentsList from "components/Schedulers/HASDetailModal/HASDocumentList/HASDocumentList";
import { Period } from "components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface";
import PeriodChooser from "components/Common/Components/PeriodChooser/PeriodChooser";

// Styles
import 'components/Schedulers/Components/DetailModals.scss';

interface HASDetailModalProps {
    contract?: SiteContract;
    portfolio?: HydratedPortfolio;
    initialIndex?: number;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
    initialContextObject: HASContractTask;
    refreshGrid?: React.DispatchWithoutAction;
    initiallySelectedCheckId?: React.MutableRefObject<string | undefined>;
    masterViewSharedSpace: React.MutableRefObject<any>;
    requestInterrupt: (onAgree: () => void, onDisagree: () => void, message?: string) => void;
    selectedPeriod?: Period
}

export interface HASDetailModalState {
    mode: string;
    hasCheck: HASTaskCheck | undefined;
}

const HASTaskModal = ({
    contract,
    portfolio,
    initialIndex,
    setOpen,
    open,
    initialContextObject,
    refreshGrid,
    initiallySelectedCheckId,
    masterViewSharedSpace,
    requestInterrupt,
    selectedPeriod
}: HASDetailModalProps) => {
    // NOTE!! THIS IS CURRENTLY OPENED BY CLICKING ON A CONTRACTTASK ROW - THE ADMIN DETAIL VIEW IS DEFINED ELSEWHERE.
    // won't have a contract as the general context passed in - specifically in the HAS 'centre'.  So we'll need to derive it from the contracttask object.
    const history = useHistory();

    const [hasTaskInfo, setHasTaskInfo] = useState<ParseAPIResponseWithPUTMeta<HASTask | undefined>>();
    const [detailItem, setItem] = useState<HASTaskCheck>();
    const [detailIndex, setDetailIndex] = useState(0);
    const [modalDisplayMode, setModalDisplayMode] = useState<string>();
    const [index, setIndex] = useState(initialIndex || 0);
    const defaultNewItemDate = useRef<Date>(moment(selectedPeriod?.start_date).toDate());
    const [signalClose, setSignalClose] = useState(false);
    const [indexChangeRequest, setIndexChangeRequest] = useState<number>();
    const [signalDelete, setSignalDelete] = useState<boolean>();
    const [contractTask, setContractTask] = useState(initialContextObject);
    const [handleDeleteHASTask, setHandleDeleteHASTask] = useState<() => void>();
    const [thisContractRef, setThisContractRef] = useState(contract?.contract_ref || initialContextObject.contract__contract_ref);
    const [period, setPeriod] = useState<Period | undefined>(selectedPeriod);

    const initialStartDate = useRef(moment(period?.start_date));
    const initialEndDate = useRef(moment(period?.end_date));

    useEffect(() => {
        setThisContractRef(contract?.contract_ref || contractTask.contract__contract_ref);
    }, [
        contract, contractTask
    ])

    const onSaveCallBack = useCallback((response: ParseAPIResponseWithPUTMeta<HASTask>) => {
        setHasTaskInfo(response);
    }, []);

    let tabs = contractTask ? [
        "Task Details", "Checks List", "Documents"
    ] : ["Task Details"];

    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 0); // important it starts at 0 as otherwise we'll get double initial check list load

    const taskIsGlobal = !!(hasTaskInfo?.data?.id && !hasTaskInfo?.data?.for_contract);

    const handleClickClose = (): void => {
        if (detailItem) {
            setItem(undefined);
        } else {
            // The config below allows you to remove the hash part of the url without reloading the entire page and also to go back to the has with back button
            if (window.location.hash.length) {
                history.push(`${window.location.pathname}${window.location.search}${window.location.hash}`);
                window.history.replaceState(null, "Comment", `${window.location.pathname}${window.location.search}`);
            }
            setSignalClose(true);
        }
    }

    useEffect(() => {
        if (hasTaskInfo?.data?.for_contract && hasTaskInfo.data.id && contractTask?.contract__contract_ref) {
            const thisHandleDeleteHASTask = () => {
                hasTaskInfo.data?.id && contractTask?.contract__contract_ref && deleteContractBoundHASTask({ id: hasTaskInfo.data.id, contractRef: contractTask.contract__contract_ref }).then(() => {
                    refreshGrid && refreshGrid();
                    setOpen(false);
                    store.dispatch(
                        addNotification({ message: "Deleted", type: NOTIFICATION_SUCCESS })
                    )
                }
                ).catch((e) => {
                    setSignalDelete(undefined)
                    addNotification({ message: e.message, type: NOTIFICATION_ERROR })
                });
            }
            setHandleDeleteHASTask(() => thisHandleDeleteHASTask); //!!!!!!!!!!!! IT IS VITAL !!!!!!!!!!!! THAT WE DO NOT PASS thisHandleDeleteTask to setHandleDeleteTask - notice 
            // that we wrap it in an anonymous function.  This is because if you pass a function to the state setter, react treats it as a reducer and calls it immediately.
            // and sets the actual 'setstate' function to the 'result' of the callback.
        }
    }, [hasTaskInfo?.data?.for_contract, hasTaskInfo?.data?.id, contractTask?.contract__contract_ref, setSignalDelete, setOpen, refreshGrid])

    useEffect(() => {
        let sharedSpace = masterViewSharedSpace.current;

        if (indexChangeRequest !== index && indexChangeRequest !== undefined) {
            if (index !== 0 || !sharedSpace.formChanged) {
                setIndex(indexChangeRequest);
            } else {
                const onAgree = () => {
                    sharedSpace.handleSave && hasTaskInfo?.data?.id && sharedSpace.handleSave(hasTaskInfo?.data?.id);
                    setIndex(indexChangeRequest);
                };
                const onDisagree = () => {
                    sharedSpace.handleCancel && hasTaskInfo?.data?.id && sharedSpace.handleCancel(hasTaskInfo?.data?.id);
                    setIndex(indexChangeRequest);
                }
                requestInterrupt(onAgree, onDisagree, 'Save Changes?');
            }
        }
        if (signalClose) {
            if (!sharedSpace.formChanged) {
                setOpen(false);
                refreshGrid && refreshGrid();
            } else {
                const onAgree = () => {
                    sharedSpace.handleSave && hasTaskInfo?.data?.id && sharedSpace.handleSave(hasTaskInfo.data.id)
                    setOpen(false)
                };
                const onDisagree = () => {
                    sharedSpace.handleCancel && hasTaskInfo?.data?.id && sharedSpace.handleCancel(hasTaskInfo?.data?.id);
                    setOpen(false);
                }
                requestInterrupt(onAgree, onDisagree, 'Save Changes?');
            }
        }
        if (signalDelete) {
            const onAgree = () => {
                handleDeleteHASTask && handleDeleteHASTask();
                setOpen(false)
            };
            const onDisagree = () => {
                console.log('do nothing...');
            }
            requestInterrupt(onAgree, onDisagree, 'Are you sure you want to delete this task?');
        }
    }, [index, indexChangeRequest, signalClose, requestInterrupt, masterViewSharedSpace, setOpen, hasTaskInfo?.data?.id, handleDeleteHASTask, signalDelete, refreshGrid]);

    useEffect(() => {
        // we call one of two different endpoints depending on the context object because the permissions may vary based on whether the task is for 
        // a specific contract or not.
        let taskId = initialContextObject?.task; // initialContextObject is a serialized link between an HASTask and a contract (with some additional properties)
        // They are the objects that the rows in the HAS scheduler are constructed from.
        // NOTE that therefore initialContextObject is not the same kind of object as the one in hasTaskInfo.data - it is an HASTask.
        //let thisContractRef = contract?.contract_ref || initialContextObject?.contract__contract_ref;
        if (!thisContractRef) {
            store.dispatch(addNotification({ message: "The contract reference does not seem to be defined in this context", type: NOTIFICATION_ERROR }))
        } else {
            if (initialContextObject) {
                if (initialContextObject.task__for_contract) { // important that this attribute is source mapped on backend serializer so it exists.
                    thisContractRef && simpleFetchContractBoundHASTask({
                        contractRef: thisContractRef,
                        hasTaskId: initialContextObject.task
                    }).then((response: ParseAPIResponseWithPUTMeta<any>) => {
                        setHasTaskInfo(response);
                    })
                } else {
                    simpleFetchHASContractTaskNestedHASTask({
                        contractTaskId: initialContextObject.id as string,
                        hasTaskId: taskId
                    }).then((response: ParseAPIResponseWithPUTMeta<any>) => {
                        setHasTaskInfo(response);
                    })
                }
            } else {
                // if there is no initialContextObject we must be creating a task. So this is about the meta...
                thisContractRef && simpleFetchContractBoundHASTask({
                    contractRef: thisContractRef,
                }).then((response) => {
                    const { data, ...restOfResponse } = response;
                    // This API call returns data as a list (of all HASTask belong specifically to the contract in the current context), 
                    // because we are not looking up a specific object.
                    // Therefore we will explicitly inject data as undefined, because for the purposes of this component
                    // 'data' is assumed to be the HASTask object, when it is defined.
                    setHasTaskInfo({ data: undefined, ...restOfResponse });
                })
            }

        }

    }, [thisContractRef, initialContextObject])

    const [ModalTitle, setModalTitle] = useState<() => JSX.Element>();

    return (
        <Dialog
            id="detailModal"
            open={open}
            scroll="paper"
            maxWidth="xl"
            fullWidth
            fullScreen
        //style={{ minHeight: '500px' }}
        >
            <DialogTitle
                className={detailItem ? 'modalDetail' : ''}
            >
                <div className={`${detailItem ? 'detailItem' : ''} header-wrapper`}>
                    {ModalTitle && <ModalTitle></ModalTitle>}
                    <div className="detail-modal--close-button">
                        <IconButton onClick={handleClickClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            </DialogTitle>
            {detailItem && modalDisplayMode === "viewDocs" && initialContextObject.id && <HASCheckDetail
                index={detailIndex}
                setIndex={setDetailIndex}
                contractRef={initialContextObject.contract__contract_ref}
                portfolioId={portfolio?.id}
                initialData={detailItem}
                //initialNotes={detailItem.id ? notesStore.current[detailItem.id] as string || '' : ''}
                defaultNewItemDate={defaultNewItemDate.current}
                contractTaskId={initialContextObject.id}
                setModalTitle={setModalTitle}
                contractTask={initialContextObject}
                dispatchRefreshContext={forceUpdate}
            />}
            <div className={`${detailItem ? 'hide' : 'show'}`}>
                <TabbedPanel
                    pageIndex={index}
                    showTabs={true}
                    //onChangeTab={(index: number) => { setIndex(index) }}
                    onChangeTab={(index: number) => { setIndexChangeRequest(index) }}
                    tabs={tabs}
                >
                </TabbedPanel>
                {index !== 0 && <PeriodChooser
                    period={period}
                    setPeriod={setPeriod}
                    initialStartDate={initialStartDate.current}
                    initialEndDate={initialEndDate.current}
                />}
                {index == 0 && hasTaskInfo && <HASTaskDetailView
                    data={hasTaskInfo?.data?.id ? hasTaskInfo?.data : undefined}
                    setModalTitle={setModalTitle}
                    permissions={hasTaskInfo?.permissions}
                    meta={hasTaskInfo?.meta}
                    putMeta={hasTaskInfo?.putMeta}
                    formSharedSpace={masterViewSharedSpace.current}
                    contractRef={thisContractRef}
                    contractId={contract?.id || contractTask?.contract}
                    taskIsGlobal={taskIsGlobal}
                    refreshGrid={refreshGrid}
                    contractTask={contractTask}
                    setContractTask={setContractTask}
                    setSignalDelete={setSignalDelete}
                    onSaveCallBack={onSaveCallBack}
                />
                }
                {index == 1 && contractTask?.id && hasTaskInfo?.data &&
                    <DialogContent>
                        <HASTaskChecksList
                            thisHASTask={hasTaskInfo?.data}
                            contractRef={thisContractRef}
                            portfolioId={portfolio?.id}
                            setModalTitle={setModalTitle}
                            selectedPeriod={period}
                            contractTaskId={contractTask.id}
                            taskIsGlobal={taskIsGlobal}
                            refreshGrid={refreshGrid}
                            initiallySelectedCheckId={initiallySelectedCheckId}
                            setModalDisplayMode={setModalDisplayMode}
                            setItem={setItem}
                            refreshSignal={mustRefresh}
                        />
                    </DialogContent>
                }

                {index == 2 && contractTask?.id && hasTaskInfo?.data && <DialogContent>
                    <HASDocumentsList
                        portfolioId={portfolio?.id}
                        contractTaskId={contractTask?.id}
                        setModalTitle={setModalTitle}
                        thisHASTask={hasTaskInfo?.data}
                        taskIsGlobal={taskIsGlobal}
                        period={period}
                    />
                </DialogContent>}
            </div>

        </Dialog>
    );
}

export default SaveOnRowCollapseWrapper(HASTaskModal, "save?");