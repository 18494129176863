import React, { useState, useEffect, useCallback, useRef } from "react";
import DataGrid, { Column, Editing, RequiredRule } from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";

// Own
import CommonAPIService, { simpleFetchList } from "components/ContractInFocus/Services/commonAPI.services";
import { InlineWrapper } from 'components/ContractInFocus/Styles/CommonStyles';
import GridHeader from "components/Common/Components/GridHeader/GridHeader.js";
import { getSubTitle } from "components/ContractInFocus/Components/ContractPrintTitle/ContractPrintTitle";
import { ColumnProps, DataGridMeta } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';
import { getColumnProps, editableTextAreaOnPreparing, getGridProps } from 'helpers/DataGrid/DataGridColumn.helper';
import { columnPropsPlaceHolder } from 'components/ContractInFocus/Models/ColumnProps';
import { gridMetaInitialState } from 'components/ContractInFocus/Models/Grid';
import { saveDateFormat } from "components/Common/Utils/Dates";
import { PrintChartAndTableLabels } from "components/Common/constants.js";
import { IHASAccident, IncidentAction, IHASNearMiss, IncidentCategoryType, IncidentFieldType } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";
import PrintDataGrid from "components/Common/Components/PrintDataGrid/PrintDataGrid";


interface HASIncidentActionProps {
    incident: (IHASAccident | IHASNearMiss);
    incidentType: IncidentFieldType;
    category?: IncidentCategoryType;
    title: string;
    printable: boolean;
}

export const printableIncidentColumns = [
    { name: "description", caption: 'Description' },
    { name: "target_completion_date", width: 180, caption: 'Target Completion Date' },
    { name: "completion_date", width: 180, caption: 'Completion Date' }
]

const HASIncidentActions: React.FC<HASIncidentActionProps> = ({
    incident,
    incidentType,
    category,
    title,
    printable
}) => {
    const getBaseEndpoint = useCallback(() => `h-a-s-${incidentType == "accident" ? "accidents" : "near-misses"}/${incident.id}/actions/?format=json`, [incident, incidentType]);
    const getEndpoint = getBaseEndpoint;
    const [dataSource, setDataSource] = useState<DataSource>();
    const [metadata, setMetadata] = useState<DataGridMeta>(gridMetaInitialState);
    const currentMetaDataRef = useRef(metadata);
    const [contentReady, setContentReady] = useState(false);
    const [actionsForPrint, setActionsForPrint] = useState<any>();
    const thisKey = incident.id;

    const setActionsForPrintCallBack = useCallback((response) => {
        setActionsForPrint(response.data);
    }, [])

    useEffect(() => {
        const custom = new CustomStore({
            key: "id",
            load: loadOptions => {
                let params: any;
                if (category) {
                    params = { "category": category }
                }
                // const thisData = CommonAPIService.getAll<IncidentAction>(
                //     getEndpoint,
                //     setMetadata,
                //     thisKey,
                //     params
                // )
                const thisData = simpleFetchList({
                    route: getEndpoint(),
                    setMetaData: setMetadata,
                    currentMetaDataRef: currentMetaDataRef,
                    callBack: setActionsForPrintCallBack
                });
                return thisData;
            },
            insert: values => {
                const theseValues: IncidentAction = incidentType == "accident" ? { accident: thisKey, ...values } : { near_miss: thisKey, ...values }
                if (category) {
                    theseValues["category"] = category
                }
                return CommonAPIService.create<any>({ getEndpoint, values: theseValues })
            },
            // @ts-ignore
            remove: key => {
                return CommonAPIService.del<any>(getEndpoint, thisKey, key)
            },
            update: (id, values) => {
                return CommonAPIService.update<any>(getEndpoint, thisKey, id, values)
            }
        });

        setDataSource(
            new DataSource({
                store: custom,
            })
        );
    }, [getEndpoint, thisKey, category, incidentType, metadata, setActionsForPrintCallBack]);

    const handleDateColumns = (data: any): void => {
        ["target_completion_date", "completion_date"].map(
            (x) => {
                if (data[x]) {
                    data[x] = saveDateFormat(data[x])
                }
            }
        )
    };

    useEffect(() => {
        if (printable) {
            dataSource?.load(); //important as this makes sure the data is there
            // for the 
        }
    }, [printable, dataSource])

    const handleRowInserting = (values: any): void => {
        handleDateColumns(values.data);
    };

    const handleRowUpdating = (values: any): void => {
        handleDateColumns(values.newData);
    }

    const getColumnPropsExt = useCallback((field: string): ColumnProps | undefined => {
        return metadata.loaded ? getColumnProps(field, metadata.activeMeta) : columnPropsPlaceHolder;
    }, [metadata])

    const handleRowUpdated = () => {
        if (metadata) {
            setMetadata({ ...metadata, activeMeta: metadata.POSTMeta });
        }
    }

    const renderColIfMetaDataDefined = useCallback((fieldName: string, requiredOveride: boolean, colPropsOverride = {}) => {
        const metaColProps = getColumnPropsExt(fieldName);
        if (metaColProps) {
            const colProps = { ...metaColProps, ...colPropsOverride }
            return (<Column {...colProps}>
                {requiredOveride && <RequiredRule />}
            </Column>
            )
        }
    }, [getColumnPropsExt])

    return (
        <InlineWrapper className={`incident-actions ${printable ? 'field-group-wrapper' : ''}`}>
            <GridHeader
                title={title}
                className={`incident-actions ${PrintChartAndTableLabels ? undefined : 'no-print'}`}
                subTitle={getSubTitle(metadata)}
            />
            {printable && metadata.activeMeta && <PrintDataGrid
                records={actionsForPrint}
                visibleColumns={printableIncidentColumns}
                meta={metadata.activeMeta}
                showOnScreen
            />
            }
            {dataSource && !printable && (
                <DataGrid
                    className='no-print'
                    dataSource={dataSource}
                    onRowInserting={handleRowInserting}
                    onRowUpdating={handleRowUpdating}
                    onRowUpdated={handleRowUpdated}
                    {...getGridProps(metadata.activeMeta)}
                    onEditorPreparing={editableTextAreaOnPreparing(metadata.activeMeta)}
                    onContentReady={() => setContentReady(true)}
                    showColumnHeaders
                >
                    <Editing
                        mode="cell"
                        allowUpdating={!printable && metadata.privileges.PUT}
                        allowDeleting={!printable && metadata.privileges.DELETE}
                        allowAdding={!printable && metadata.privileges.POST}
                    />
                    {renderColIfMetaDataDefined('description', true)}
                    {renderColIfMetaDataDefined('target_completion_date', true)}
                    {renderColIfMetaDataDefined('completion_date', false)}
                </DataGrid>
            )}
        </InlineWrapper>
    );
};

export default HASIncidentActions;