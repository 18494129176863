import React, { useEffect, useState, useReducer, useRef } from "react";
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';


// Own
import { AdminDataGrid } from "components/AdminPanel/Containers/AdminDataGrid";
import { adminPanelType } from "components/AdminPanel/Models/AdminPanel.model";
import { ContractHASTaskSublistColumns, linkContractGridActions } from "components/AdminPanel/Contracts/Models/ContractDetail.model";
import { openPanelInSelectionMode, apiSuccess } from 'components/AdminPanel/Helpers/AdminPanel.helper';
import { ContractInterface } from "components/AdminPanel/Contracts/Interfaces/Contract.interface";
import { EntityKeys } from "constants/entity.constants";
import { PreFlightListInfo } from "components/Common/Interfaces/Entity.interface";
import { HASTaskContractLink, HASTaskWithID } from "components/AdminPanel/HAS/Interfaces/HASTaskInterfaces";
import HASTaskServices, { getHASTaskContractsBaseRoute } from "components/AdminPanel/HAS/HASTaskServices";
import CommonAPIService from "components/ContractInFocus/Services/commonAPI.services";

interface ContractHASTaskViewProps {
    panelId: string;
    masterRecordData: ContractInterface;
    id: any;
    dataTestId: string;
    masterRecordType?: string;
    selectActionIdentifier?: string;
    linkPanelTitle?: string;
}

const ContractHASTasksView: React.FunctionComponent<ContractHASTaskViewProps> = ({
    panelId,
    masterRecordData,
    id,
    //contractsState,
    dataTestId,
    masterRecordType,
    selectActionIdentifier,
    linkPanelTitle,
}) => {

    const currentMemberIds = useRef<(string | number)[]>();
    const gridData = useRef<any[]>([]); // we use this just so we can lookup the contract from the row id on update
    const [datasource, setDatasource] = useState<any>();
    //const [metaData, setMetadata] = useState<FieldMetaGroup>();
    const [response, setResponse] = useState<PreFlightListInfo>();

    useEffect(() => {
        HASTaskServices.fetchContractHASTasksLinksPreFlightInfo(masterRecordData.contract_ref).then(response => setResponse(response));
    }, [masterRecordData.contract_ref]);

    useEffect(() => {
        const custom = new CustomStore({
            key: "id",
            load: loadOptions => HASTaskServices.fetchContractHASTaskLinks(masterRecordData.contract_ref).then(
                (response) => {
                    currentMemberIds.current = response.data.map((x: HASTaskContractLink) => x.task);
                    gridData.current = response.data;
                    return response.data;
                }
            ),
            update: (id, values) => {
                const recordForUpdate = gridData.current.find(x => x.id === id);
                const endpoint = `${getHASTaskContractsBaseRoute(recordForUpdate.task)}${recordForUpdate.contract}/`;
                return CommonAPIService.sensibleUpdate<any>(endpoint, values).then(apiSuccess);
            }
        });

        setDatasource(
            // @ts-ignore
            new DataSource({
                store: custom
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function handleAddHASTaskToContract(taskIds: string[]) {
        const accessObjs = taskIds.map((taskId) => (
            {
                "contract": masterRecordData.id,
                "task": taskId,
            }
        ))
        HASTaskServices
            .linkHASTasksToContract(masterRecordData, accessObjs)
            .then(() => {
                // at the moment the admin panel is refreshed when closing the other admin picker panel, but we want to stop that so a reload will
                // be required
                datasource.reload();
            })
    }

    const handleRemoveHASTaskFromContract = (contract_task: HASTaskContractLink) => {
        HASTaskServices.removeHASTaskFromContract(
            masterRecordData,
            contract_task.task // we're using the task id for lookups on this route
        )
            .then(() => {
                datasource.reload();
            })
    };

    const handleTaskLink = (): void => {
        masterRecordType && selectActionIdentifier && linkPanelTitle && openPanelInSelectionMode(
            {
                selectActionIdentifier,
                masterRecordType,
                masterRecordData,
                linkedRecordType: adminPanelType.h_a_s,
                onAddLinks: handleAddHASTaskToContract,
                currentMemberIds: currentMemberIds.current || [],
                linkPanelTitle,
            }
        )
    };

    const linkHASTask = selectActionIdentifier ? handleTaskLink : undefined;

    return (
        <>
            {response?.meta && <AdminDataGrid
                repaintChangesOnly
                data={datasource}
                editMode="cell"
                meta={response.meta}
                dataTestId={dataTestId}
                getDatagridColumns={ContractHASTaskSublistColumns}
                masterView={false}
                entityKey={EntityKeys.HASTasks}
                columnActions={linkContractGridActions(handleRemoveHASTaskFromContract)}
                panelId={panelId}
                // no OnUpdate, records are not currently updated in this context, just linked and removed
                onLink={linkHASTask}
                permissions={response.privileges}
            />}
        </>

    );
};

export default ContractHASTasksView;
