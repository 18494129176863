import React, { Component } from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import AdminPanel from "../AdminPanel/AdminPanel";
import * as actions from "../../../../store/actions/actions";

export class AdminPanels extends Component {
  static propTypes = {
    adminPanel: PropTypes.object,
    actions: PropTypes.object,
    state: PropTypes.object
  };

  closeModal = id => {
    this.props.actions.panelChangeEvent(this.props.adminPanel.panels[id]);
    this.props.actions.removeAdminPanel(id);
  };

  activatePanel = id =>
    this.props.adminPanel.activePanelId !== id &&
    this.props.actions.setActivePanel(id);

  render() {
    return (
      <>
        {this.props.adminPanel &&
          Object.keys(this.props.adminPanel.panels).map((paneId, index) => {
            const panel = {
              ...this.props.adminPanel.panels[paneId],
              id: paneId
            };
            return !panel.isDetail ? (
              <AdminPanel
                key={paneId}
                index={index}
                active={paneId === this.props.adminPanel.activePanelId}
                panelId={paneId}
                activeTabId={panel.panelType}
                closeModal={() => this.closeModal(paneId)}
                activatePanel={() => this.activatePanel(paneId)}
                selectionConfig={panel.selectionConfig}
                onlyShowTabs={panel.onlyShowTabs}
              />
            ) : (
              <></>
            );
          })}
      </>
    );
  }
}

const mapStateToProps = state => ({ adminPanel: state.adminPanel });
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPanels);
