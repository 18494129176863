import { SET_DRAGGING } from "store/actions/interface.actions";

export const interfaceInitialState = {
  /* Layout fixed. Scroll content only */
  dragging: undefined,
};

const interfaceReducer = (state = interfaceInitialState, action: any) => {
  switch (action.type) {
    case SET_DRAGGING.reducer:
      return {
        ...state,
        dragging: action.entity
      };
    default:
      return state;
  }
};

export default interfaceReducer;
