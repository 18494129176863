import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from "react-redux";

import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { IFinancials, awHistoryItemI } from "components/ContractInFocus/Financials/interfaces/financials.interface";
import financialsService from "components/ContractInFocus/Financials/Services/financialServices";
import { APIPrivileges } from "services/Interface/Interface";
import { standardDateFormat } from "components/Common/Utils/Dates";
import { internalMemberSelector } from 'components/Profile/Selectors/Profile.selector';


import { useInView } from 'react-intersection-observer';

// Styling
import "components/ContractInFocus/Financials/Styles/QuoteDetail.scss";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

interface HistoryBlockViewProps {
    data: IFinancials;
    portfolio?: HydratedPortfolio
}

const HistoryBlock: React.FC<HistoryBlockViewProps> = ({
    data,
    portfolio
}) => {

    const suspendPolling = false;

    const [awCommentPermissions, setAWCommentPermissions] = useState<APIPrivileges>();
    const [commentHasText, setCommentHasText] = useState<boolean>(false);
    const [history, setHistory] = useState<awHistoryItemI[]>();
    const internalMember = useSelector(internalMemberSelector);
    let lastHistoryItemId = useRef<string>();
    let commentText = "";
    const commentFieldRef = useRef<any>();
    const fetchHistoryTimer = useRef<any>();

    const [visRef, inView, entry] = useInView({
        /* Optional options */
        threshold: 0,
        initialInView: true,
        trackVisibility: true,
        delay: 500,
    });

    const handleSubmitComment = () => {
        setCommentText(commentFieldRef.current.value || '');
        financialsService.commentOnFinancial(data.id, commentText, portfolio?.id).then(
            (response) => {
                commentFieldRef.current.value = '';
                updateHistory();
            }
        );
    }

    const handleDiscardCommentChanges = () => {
        setCommentText('');
        if (commentFieldRef.current) {
            //console.log('commentFieldRef current: ', commentFieldRef.current);
            //commentFieldRef.current.defaultText = '';
            commentFieldRef.current.value = '';
        }
    }

    const setCommentText = (text: string) => {
        commentText = text;
        const commentExists = !!commentText?.length;
        (commentExists !== commentHasText) && setCommentHasText(commentExists);
    }

    const scrollHistoryToEnd = useCallback(() => {
        const historyWrapperid = `History-${data.id}`
        const scroll = document.getElementById(historyWrapperid);
        if (scroll) {
            scroll.scrollTop = scroll.scrollHeight;
            scroll.animate({ scrollTop: scroll.scrollHeight });
        }
    }, [data?.id]);

    const updateHistory = useCallback(() => {
        financialsService.getAWHistory(data.id, portfolio?.id).then(
            (response) => {
                if (response !== null) {
                    // if it is null something has errored and it will be in console log of getAWHistory
                    const newLastHistoryItemId = response[response.length - 1]?.id.toString();
                    setHistory(response);
                    setTimeout(() => {
                        if (newLastHistoryItemId && (newLastHistoryItemId !== lastHistoryItemId.current || !lastHistoryItemId.current)) {
                            scrollHistoryToEnd()
                            lastHistoryItemId.current = newLastHistoryItemId;
                        }
                    }, 200);
                }
            }
        );
    }, [data.id, portfolio?.id, scrollHistoryToEnd]);

    useEffect(() => {
        // we clear up the fetchHistory in another useEffect below, when the row status is no longer 'expanding'. 
        // this is necessary because devextreme datagrid caches this component once the row has been expanded, so 
        // it isn't really unmounted when the row is collapsed.
        // however we also return a function to clear it from this useEffect, just as another failsafe, so if the component is
        // 'unmounted' it will be cleared.

        return () => {
            //console.log('clearing fetchHistoryTimer if exists on component destruction');
            if (fetchHistoryTimer.current) {
                window.clearInterval(fetchHistoryTimer.current);
            }
        };
    }, [])

    useEffect(() => {
        financialsService.permissionsAWComment(data.id, setAWCommentPermissions, portfolio?.id);
    }, [data.id, portfolio])

    useEffect(() => {
        // using intersection observer to manage polling state - still going to keep the 'unmount' clear interval above as a 'backup' to ensure that
        // the polling stops if the element is destroyed.
        if (inView && !fetchHistoryTimer.current) {
            //console.log('going to restart history polling...')
            updateHistory();
            if (!suspendPolling) {
                fetchHistoryTimer.current = setInterval(() => {
                    updateHistory();
                }, 7500);
            }
        } else if (!inView && fetchHistoryTimer.current) {
            //console.log('going to stop history polling...')
            window.clearInterval(fetchHistoryTimer.current);
            fetchHistoryTimer.current = null;
        }
    }, [inView, entry, suspendPolling, updateHistory]);

    return (

        <div className="awDetailBlock aw-history-block" ref={visRef}>

            <h4 className="title">History:</h4>
            <span className="subtitle">Response Log:</span>
            {history && !!history.length &&
                <div className='response-history-container'>
                    <div id={`History-${data.id}`} className='response-history-wrapper'>
                        {history && history.map((item) => {
                            return (
                                <div key={item.id}>
                                    <div id={item.id} className={`${item.log_type}Wrapper ${item.current_user_is_owner ? 'owner' : ''}`} >
                                        {item.log_type == "action" ?
                                            <>
                                                <div className="response-history-item">
                                                    <span className="actionLabel">ACTION:</span>
                                                    <span className="actionText">{item.text}</span>
                                                </div>
                                                <div className="actionLabel"> BY:&nbsp;{item.submitted_by_first_name}&nbsp;{item.submitted_by_last_name}</div>
                                                <span className="actionLabel">EMAIL:&nbsp;</span><span>{item.submitted_by}</span><br />
                                                <span className="actionLabel">AT:&nbsp;</span><span>{item.created_at}</span>
                                            </>
                                            :
                                            <>
                                                <div className={`response-history-item commentBubble ${item.current_user_is_owner ? 'owner' : ''}`}>
                                                    {!item.current_user_is_owner &&
                                                        <div className="submittedByNames">{item.submitted_by_first_name}&nbsp;{item.submitted_by_last_name}</div>
                                                    }
                                                    <div className="submittedBy">{item.current_user_is_owner ? "you" : item.submitted_by}</div>
                                                    <div className="actionText">{item.text}</div>
                                                    <div className="commentAt">{item.created_at}</div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
            {awCommentPermissions?.POST &&
                <>
                    <div className="CommentsInputWrapper">
                        <TextField inputRef={commentFieldRef} id="CommentInput" variant="outlined" placeholder="Add Comment To Log" multiline onChange={event => {
                            const { value } = event.target;
                            setCommentText(value);
                        }}>
                        </TextField>
                    </div>
                    <Button
                        disabled={commentHasText ? false : true} color="primary" type="submit" onClick={handleSubmitComment} >Submit</Button>
                    <Button
                        disabled={commentHasText ? false : true} color="primary" type="submit" onClick={handleDiscardCommentChanges} >Discard</Button>
                </>
            }
            <div className='key-dates-container'>
                <span className="subtitle">Key Dates:</span>
                {<div><span className="awDetailLabel">PO Received:</span>&nbsp;{standardDateFormat(data.po_date)}</div>}
                {<div><span className="awDetailLabel">Date Submitted:</span>&nbsp;{standardDateFormat(data.date_submitted)}</div>}
                {<div><span className="awDetailLabel">Date Requested:</span>&nbsp;{standardDateFormat(data.date_requested)}</div>}
            </div>
            <br /><br />
            {internalMember && <div>
                <h4 className="title">Notes:</h4>
                <p>
                    {data.fp_notes}
                </p>
            </div>
            }

        </div>
    );

}

export default HistoryBlock;
