import { ofType } from 'redux-observable';
import { switchMap, map, catchError } from 'rxjs/operators';
import * as fromCommonActions from 'store/Common/Actions/common.actions';
import API, { APIR } from "services/API/API";
import { setProjectConfig } from "store/Common/Actions/common.actions.js";
import { of } from "rxjs";



// Own
import { changeSetting } from 'store/actions/actions';
import { gridBreakpoints } from 'styles/app/common/variables';
import { addNotification } from "../../../components/Notification/Actions/Notification.actions";
import { NOTIFICATION_ERROR } from "../../../components/Notification/Constants/constants";

// const isCollapsed = "isCollapsed";
// const isHidden = "isHidden";

// const setScreenWidthEpic = (action$: any) => action$.pipe(
//   ofType(fromCommonActions.SET_SCREEN_WIDTH),
//   switchMap((action: any) => [
//     changeSetting(isCollapsed, action.payload < gridBreakpoints.lg),
//     changeSetting(isHidden, action.payload < gridBreakpoints.md),
//   ])
// );

const setProjectConfigEpic = (action$: any) => action$.pipe(
  ofType(fromCommonActions.FETCH_PROJECT_CONFIG.start),
  switchMap(() =>
    APIR.get(`/project-config/`).pipe(
      map((response) =>
        //@ts-ignore
        setProjectConfig(response.data.results.data)
      ),
      catchError((error) => of(addNotification({ message: error, type: NOTIFICATION_ERROR })))
    )
  )
);

export default [
  //setScreenWidthEpic,
  setProjectConfigEpic
];