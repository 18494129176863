import React, { ReactNode, ReactNodeArray } from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import styles from 'styles/app/common/variables.scss';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: styles.blueDark,
  },
  indicator: {
    backgroundColor: styles.white,
  }
}));

interface TabbedStatePanelProps {
  tabs: string[];
  children: ReactNode | ReactNodeArray;
}

const TabbedStatePanel: React.FC<TabbedStatePanelProps> = ({
  tabs,
  children
}) => {
  const [pageIndex, setPageIndex] = React.useState(0);

  return (
    <TabbedPanel
      tabs={tabs}
      showTabs={true}
      onChangeTab={setPageIndex}
      pageIndex={pageIndex}
    >
      {children}
    </TabbedPanel>
  )
};

interface TabbedPanelProps {
  pageIndex: number;
  tabs: string[];
  showTabs?: boolean;
  children: ReactNode | ReactNodeArray;
  onChangeTab: (index: number) => void;
  ExtraButtons?: () => JSX.Element;
}

export const TabbedPanel: React.FC<TabbedPanelProps> = ({
  pageIndex,
  tabs,
  showTabs = true,
  children,
  onChangeTab,
  ExtraButtons
}) => {
  const classes = useStyles();

  const filteredChildren = React.Children.toArray(children).filter(x => x); //filter out any children that have falsey values

  const getReactChildren = () => (
    filteredChildren.map((child, index) => {
      if (child) {
        return <TabPanelMemo
          index={index}
          key={index}
          value={pageIndex}
        >
          {child}
        </TabPanelMemo>
      }
    }
    )
  );
  const tabsChildren = getReactChildren();

  return (
    <div>
      <AppBar position="static" className={classes.root}>
        <Tabs
          classes={{ indicator: classes.indicator }}
          style={{ display: showTabs ? undefined : 'none' }}
          value={pageIndex}
          onChange={(e, i) => onChangeTab(i)}
        >
          {tabs.map(tab => <Tab key={tab} label={tab} />)}
        </Tabs>
        {ExtraButtons && <ExtraButtons />}
      </AppBar>
      {tabsChildren}
    </div>
  )
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

const TabPanelMemo = React.memo(TabPanel);




