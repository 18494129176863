import { common } from "../../actions/actions";

const { SET_SCREEN_WIDTH, SET_ROUTE, SET_PROJECT_CONFIG } = common;

const initialState = {
  screenWidth: window ? window.innerWidth : 0,
  projectConfig: {}
};

const commonReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SCREEN_WIDTH:
      return {
        ...state,
        screenWidth: payload
      };

    case SET_ROUTE.reducer:
      return {
        ...state,
        route: payload.route.pathname
      };

    case SET_PROJECT_CONFIG.reducer:
      return {
        ...state,
        projectConfig: payload.config[0] //singleton... will always be the only result
      };

    default:
      return state;
  }
};

export default commonReducer;
