/* eslint-disable */
import React, { useState, useCallback, useEffect } from 'react';
// Own
import HASCheckDetailDocuments from "components/Schedulers/HASDetailModal/HASCheckDetail/HASCheckDetailDocuments";
import { TabbedPanel } from "components/Common/Components/TabPanel";
import { getDimTitle } from "helpers/Forms/modal.helper";
import { getInWordDateFormat } from 'components/Common/Utils/Dates';


// import { simpleCreateContractTaskCheck, simpleUpdateContractTaskCheck, simpleFetchHASCheck } from "components/AdminPanel/HAS/HASTaskServices";
// import { addNotification } from "components/Notification/Actions/Notification.actions";
// import { NOTIFICATION_SUCCESS } from "components/Notification/Constants/constants";
// import formInitialState from 'components/Schedulers/VisitModal/Models/VisitForm';
// import { getPayloadFromRef } from 'services/API/API.helper';
// import { dateUDF } from "components/Common/Utils/Dates.js";

import "components/Common/Components/GeneralActionForm/GeneralActionFormStyles.scss"

// Styles
import {
    VisitDetailWrapper,
    VisitReportsWrapper
} from 'components/Schedulers/VisitModal/Components/VisitDetail/VisitDetailStyles';
import { HASTaskCheck, HASContractTask } from "components/AdminPanel/HAS/Interfaces/HASTaskInterfaces";

interface detailProps {
    initialData?: HASTaskCheck;
    contractTask: HASContractTask;
    initialNotes?: string;
    contractRef: string;
    portfolioId?: string | number;
    defaultNewItemDate?: Date;
    contractTaskId: string;
    setModalTitle: React.Dispatch<React.SetStateAction<(() => JSX.Element) | undefined>>;
    index: number;
    setIndex: React.Dispatch<React.SetStateAction<number>>;
    dispatchRefreshContext?: React.DispatchWithoutAction | undefined;
}

const HASCheckDetailView = ({
    index, setIndex, portfolioId, initialData, contractTaskId, contractTask, setModalTitle, contractRef, dispatchRefreshContext
}: detailProps) => {

    const [data, setData] = useState<HASTaskCheck | undefined>(initialData);

    const getModalTitle = useCallback(() => {
        return () => <>
            {data ? <div className="detail-modal--title">
                {`${contractTask.task__name}`}
                {getDimTitle('scheduled for: ')}
                {`${getInWordDateFormat(data.scheduled_for)}`}
            </div> : <></>
            }
        </>
    }, [contractTask, data])

    useEffect(() => {
        setModalTitle(getModalTitle)
    }, [contractTask, data, getModalTitle, setModalTitle])

    const tabs = ["Documents"]

    return <>
        <VisitDetailWrapper>
            <VisitReportsWrapper>
                {data && <><TabbedPanel
                    pageIndex={index}
                    showTabs={true}
                    onChangeTab={(index: number) => { setIndex(index) }}
                    tabs={tabs}
                >
                    <HASCheckDetailDocuments
                        hasCheck={data}
                        contractTaskId={contractTaskId}
                        portfolioId={portfolioId}
                        dispatchRefreshContext={dispatchRefreshContext}
                    />

                </TabbedPanel>

                </>}
            </VisitReportsWrapper>
        </VisitDetailWrapper>
    </>
}

export default HASCheckDetailView;