import { store } from "store/store";
import API, { APIR, } from "services/API/API";
import { ContractOrPortfolio } from "services/API/common/contractAPIs";
import { CONTRACT_ROUTE, PORTFOLIO_ROUTE } from "services/API/common/globalAPIs";
import { catchError, map } from "rxjs/operators";

import { getData, getDataAndMeta, getMeta, unWrapDataAndMeta, unWrapData, unWrapDataAndMetaAndPUTMeta } from "services/API/API.helper";
import { setPersonalInFocusSettingsSuccess } from 'components/ContractInFocus/Actions/contractVisibilitySettings.actions';
import { NotificationContractSettings } from "components/ContractInFocus/Interfaces/ContractInFocus.interfaces";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { addNotification } from "components/Notification/Actions/Notification.actions";
import * as notificationTypes from "components/Notification/Constants/constants";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

export const fetchPersonalContractInFocusSettings = (contract: SiteContract, portfolio = false) => {
    APIR.get(`${CONTRACT_ROUTE}${contract.contract_ref}/personal-contract-settings/?format=json`).pipe(
        //unWrapDataAndMetaAndPUTMeta(),
        unWrapData()
    ).subscribe((info) => {
        store.dispatch(setPersonalInFocusSettingsSuccess({ id: contract.id, contract: contract, personalVisibilitySettings: info.data }))
        return info
    })
}

export const fetchPersonalPortfolioInFocusSettings = (portfolio: HydratedPortfolio) => {
    APIR.get(`${PORTFOLIO_ROUTE}${portfolio.id}/personal-portfolio-settings/?format=json`).pipe(
        //unWrapDataAndMetaAndPUTMeta(),
        unWrapData()
    ).subscribe((info) => {
        store.dispatch(setPersonalInFocusSettingsSuccess({ id: portfolio.id, portfolio: portfolio, personalVisibilitySettings: info.data }))
        return info
    })
}

export const updatePersonalContractInFocusSettings = (contract: SiteContract, personalContractSettingsId: string, payload: Partial<NotificationContractSettings>, callback?: () => void) => {
    const route = `${CONTRACT_ROUTE}${contract.contract_ref}/personal-contract-settings/${personalContractSettingsId}/`
    return APIR.patch(route, payload).pipe(
        unWrapData()
    ).subscribe((info) => {
        // console.log('info: ', info);
        store.dispatch(setPersonalInFocusSettingsSuccess({ id: contract.id, contract: contract, personalVisibilitySettings: info.data }))
        store.dispatch(addNotification({ message: 'Settings Updated Successfully', type: notificationTypes.NOTIFICATION_SUCCESS }))
        callback && callback();
    },
        (error: any) => {
            store.dispatch(addNotification({ message: error, type: notificationTypes.NOTIFICATION_ERROR }))
        }
    )
}

export const updatePersonalPortfolioInFocusSettings = (portfolio: HydratedPortfolio, personalContractSettingsId: string, payload: Partial<NotificationContractSettings>, callback?: () => void) => {
    const route = `${PORTFOLIO_ROUTE}${portfolio.id}/personal-portfolio-settings/${personalContractSettingsId}/`
    return APIR.patch(route, payload).pipe(
        unWrapData()
    ).subscribe((info) => {
        store.dispatch(setPersonalInFocusSettingsSuccess({ id: portfolio.id, portfolio: portfolio, personalVisibilitySettings: info.data }))
        store.dispatch(addNotification({ message: 'Settings Updated Successfully', type: notificationTypes.NOTIFICATION_SUCCESS }))
        callback && callback();
    },
        (error: any) => {
            store.dispatch(addNotification({ message: error, type: notificationTypes.NOTIFICATION_ERROR }))
        }
    )
}

interface updatePersonalNotificationSettingsProps extends ContractOrPortfolio {
    personalSettingsId: string,
    payload: Partial<NotificationContractSettings>,
    callback?: () => void
}

export const updatePersonalNotificationSettings = (props: updatePersonalNotificationSettingsProps) => {
    const { id, ...propsForUpdate } = props.payload;
    if (props.contract && props.portfolio) {
        alert('cannot update personal notification settings as a contract AND a portfolio have been passed to this context');
    } else {
        if (props.contract) {
            updatePersonalContractInFocusSettings(props.contract, props.personalSettingsId, propsForUpdate, props.callback);
        }
        if (props.portfolio) {
            updatePersonalPortfolioInFocusSettings(props.portfolio, props.personalSettingsId, propsForUpdate, props.callback);
        }
    }
}


