import React, { useState } from 'react';
import { CardData, CardProps } from 'components/IndexPage/Interfaces/CardList.interface';

const Card = ({
    data,
    onSelect,
    onUnselect,
    onFavourite,
    CardBack,
    CardFront
}: CardProps) => {
    const [mouseMoveTimer, setMouseMoveTimer] = useState<number>();

    const handleMouseLeave = (e: any) => {
        //console.log("I'm outta here");
        onUnselect({ ...data, mode: "front" });
        clearTimeout(mouseMoveTimer);
    }
    //console.log('rendering...');
    const handleMouseMove = () => {
        clearTimeout(mouseMoveTimer);

        setMouseMoveTimer(
            setTimeout(() => {
                onSelect({ ...data, mode: "back" })
            }, 50));
    }

    return (
        <div
            onMouseMove={() => handleMouseMove()}
            onMouseLeave={(e: any) => handleMouseLeave(e)}
            className={data.active ? '' : 'inactive'}
        >
            {data.mode === "back" ? (
                <CardBack
                    onFavourite={() => onFavourite(data)}
                    data={data}
                />
            ) : (
                <CardFront
                    onFavourite={() => onFavourite(data)}
                    data={data}
                />
            )
            }
        </div>
    );
}

export default Card;