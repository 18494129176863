/*eslint-disable no-console*/
import React from "react";
import PropTypes from "prop-types";

import { StyledErrorMessage } from "./AdminPanelErrorBoundaryStyles";

class AdminPanelErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.error(error);
    console.error(errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <StyledErrorMessage>Something went wrong</StyledErrorMessage>
          <p>state.error</p>
        </>
      );
    }

    return this.props.children;
  }
}

AdminPanelErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default AdminPanelErrorBoundary;
