import styled from "styled-components";
import styles from "styles/app/common/variables.scss";

export const VisibilityControlWrapper = styled.div`
  border-bottom: solid 1.5px white;
  display: flex;
  justify-content: flex-end;
`;

//width: 10rem;
export const VisibilityControlLabel = styled.div`
  min-width: 10rem;
  text-align: right;
  padding-right: 1rem;
  padding-top: ${styles.rightVisibilityMenuPadding};
  padding-bottom: ${styles.rightVisibilityMenuPadding};
  padding-left: ${styles.rightVisibilityMenuPadding};
  border-right: solid 0.5px white;
`;

interface VisibilityControlIconsProps {
  color: string;
  panelLayout: boolean;
}

export const VisibilityControlIcons = styled.div<VisibilityControlIconsProps>`
  display: flex;
  padding: ${styles.rightVisibilityMenuPadding} ${styles.rightVisibilityMenuPadding} 0;

  ${(props: VisibilityControlIconsProps) => `
      padding-top: ${props.panelLayout ? '0.5rem' : styles.rightVisibilityMenuPadding};
      padding-left: ${props.panelLayout ? '0' : styles.rightVisibilityMenuPadding};
      padding-right: ${props.panelLayout ? '0' : styles.rightVisibilityMenuPadding};

      flex-direction: ${props.panelLayout ? 'row' : 'column'};
      color: ${props.color};
    `
  };

  .MuiIconButton-label {
    color: ${({ color }) => color};
  }

  .MuiSvgIcon-root {
    font-size: 2rem;
  }

  .MuiButtonBase-root.MuiIconButton-root {
    padding: unset;
    padding-bottom: ${styles.rightVisibilityMenuPadding};
  }

  .MuiButtonBase-root.MuiIconButton-root:not(:last-child) {
    margin-right: ${(props: VisibilityControlIconsProps) => props.panelLayout ? '0.75rem' : ''};
  }
  
`;
