// @ts-nocheck
import React, { memo } from "react";
import { Paper } from "@material-ui/core";
import { withResizeDetector } from "react-resize-detector";
import moment from "moment";

// Own
import IconButton from "../../../Common/Components/IconButton/IconButton";
import PortfolioContractRow from "components/Portfolios/Components/PortfolioContractRow/PortfolioContractRow";
import { Portfolio } from 'components/Portfolios/Interfaces/Portfolios.interface';
import {
  StyledPortfolioTable,
  StyledNoData,
  StyledImageCropper,
  PortfolioInfoWrapper,
  StyledPortfolioSub,
  StyledPortfolioTitle,
  StyledPortfolioHeader,
  StyledImageWrapper,
  usePortfolioCardBackStyles
} from "./PortfolioCardBackStyles";
import "../../../../styles/app.scss";

interface PortfolioCardBackProps {
  data: Portfolio;
  width: number;
  height?: number;
  onFavourite: () => void;
}

const PortfolioCardBack = ({
  data,
  width,
  height,
  onFavourite,
}: PortfolioCardBackProps) => {
  const classes = usePortfolioCardBackStyles();
  const now = moment();

  const handleOnFavourite = (event: MouseEvent) => {
    event.stopPropagation();
    onFavourite();
  }

  return (
    <PortfolioInfoWrapper
      favourite={data.favourite}
      className="fadeIn"
    >

      <Paper elevation={3} className={classes.paper}>
        <IconButton
          onClick={(id, button, event) => handleOnFavourite(event)}
          icon="fas fa-star"
          margin="none"
          active={true}
          style={{ zIndex: 3 }}
        />
        <StyledPortfolioHeader>
          <StyledImageWrapper>
            <StyledImageCropper>
              <img
                alt={`Building ${data.name}`}
                src={data.portfolio_image.thumbnail}
              />
            </StyledImageCropper>
          </StyledImageWrapper>
          <div>
            <StyledPortfolioTitle width={width}> {data.name} </StyledPortfolioTitle>
            <StyledPortfolioSub width={width}>
              {` ${data.contracts.length} Contracts`}
            </StyledPortfolioSub>
          </div>
        </StyledPortfolioHeader>
        {
          !!data.contracts.length && (
            <StyledPortfolioTable>
              {data.contracts.map(contract => {
                const expiry = moment(contract?.expiry);
                return <div key={`docs${contract?.id}`}>
                  {contract && <PortfolioContractRow
                    className={expiry < now ? "not-in-contract" : "in-contract"}
                    site={data}
                    contract={contract}
                    portfolio={data}
                    key={contract?.id}
                    inContract={expiry >= now}
                    showExpiry
                  />}
                </div>
              }
              )}
            </StyledPortfolioTable>
          )
        }
        {
          !data.contracts.length && (
            <StyledNoData>
              <i className="far fa-frown-open"></i>
              <span>No Contracts</span>
            </StyledNoData>
          )
        }
      </Paper>
    </PortfolioInfoWrapper>
  );
};

export default withResizeDetector(memo(PortfolioCardBack));
