import styled from 'styled-components';

export const DocumentWrapperStyle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  .react-pdf__Document {
    box-shadow: 0 10px 30px 0 rgba(16, 36, 94, 0.15);
    margin: 1rem 0;
  }
`;



