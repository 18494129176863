import React, { useState, useEffect } from "react";

// Own
import OpenReactivesDataGrid from "./Containers/OpenReactives/OpenReactives";
import withPortfolioPageContext from "components/PortfolioInFocus/HOC/WithPortfolioPageContext/WithPortfolioPageContext";
import MaintenanceTableChart from "./Containers/MaintenanceTableChart/MaintenanceTableChart";
import MaintenanceChart, { SLAMaintenanceChart } from "components/ContractInFocus/Maintenance/Components/MaintenanceChart/MaintenanceChart"
import PPMReactiveService from "components/ContractInFocus/Maintenance/Services/MaintenanceService";
import withPageContext, { WithPageContextProps } from "../HoC/WithPageContext/WithPageContext";
import NewPageWrapper from "components/Common/Components/PrintStyles/Print";
import ReactiveByCategoryTableChart from "./Containers/ReactiveByCategoryTableChart/ReactiveByCategoryTableChart";
import { transformDataForStandardTasksChart, transformDataForSLATasksChart } from "components/ContractInFocus/Maintenance/Helper/ChartHelper";
import { ReportTableClass, ReportTableType } from "./Models/PPMReactive.model";
import { rmRightVisibilityMenu } from "./Models/maintenanceRightVisibilityMenu";
import { reportSectionStructure, reportSubtitles } from "components/ContractInFocus/Models/contractIndex.model";
import { PrintTitle } from "../Components/ContractPrintTitle/ContractPrintTitle";
import { Dictionary } from "components/Common/Interfaces/Entity.interface";
import { getSubTitleNumber } from "components/ContractInFocus/ContractReport/Helpers/contractReportToc.helper";
import Header from "components/Common/Components/GridHeader/GridHeader.js";
import { tocsSelector } from "components/ContractInFocus/Selectors/visibility.selectors";
import { useSelector } from "react-redux";

interface ReactiveReportProps extends WithPageContextProps { };


const ReactiveReport: React.FC<ReactiveReportProps> = ({ contract, portfolio, animate, visibilitySettings, sectionNumberMap }) => {
  const hasReactiveTotal = () => visibilitySettings.show_rm_total_table || visibilitySettings.show_rm_total_graph;
  const hasRMSLA = () => visibilitySettings.show_rm_sla_graph || visibilitySettings.show_rm_sla_table;
  const hasReactiveIssues = () => visibilitySettings.show_rm_total_issues_table || visibilitySettings.show_rm_total_issues_graph;
  const hasReactiveHourIssues = () => visibilitySettings.show_rm_total_issue_hours_table || visibilitySettings.show_rm_total_issue_hours_graph;
  const hasReactiveOpen = () => visibilitySettings.show_rm_open_table
  const [reactiveTotals, setReactiveTotals] = useState(undefined);

  const reportSection = reportSectionStructure.Reactive;
  const [subTitleIndexes, setSubTitleIndexes] = useState<Dictionary<string | undefined>>({});
  const allReactiveTitle = reportSubtitles.AllReactive;
  const reactiveSLATitle = reportSubtitles.ReactiveSLA;
  const reactiveByCategoryTitle = reportSubtitles.ReactiveByCategory;
  const reactiveByCategoryHoursTitle = reportSubtitles.ReactiveByCategoryHours;
  const openReactiveTitle = reportSubtitles.OpenReactive;
  const tocs = useSelector(tocsSelector);

  useEffect(() => {
    const indexLookups = [allReactiveTitle, reactiveSLATitle, reactiveByCategoryTitle, reactiveByCategoryHoursTitle, openReactiveTitle]
    const newSubTitleIndexes: Dictionary<string | undefined> = {};//
    indexLookups.map((l: string) => {
      newSubTitleIndexes[l] = getSubTitleNumber(l, tocs.flatTocsLookup);
    })
    setSubTitleIndexes(newSubTitleIndexes);

  }, [tocs, allReactiveTitle, reactiveSLATitle, reactiveByCategoryTitle, reactiveByCategoryHoursTitle, openReactiveTitle])


  const PrintHeading = () => <PrintTitle sectionNumberMap={sectionNumberMap} reportSection={reportSectionStructure.Reactive} />;
  useEffect(() => {
    //console.log('here is reactive dataSet: ', reactiveTotals);
  }, [reactiveTotals])
  return (
    <div id="ReactiveMaintenanceSection">
      {hasReactiveTotal() &&
        <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
          <PrintHeading />
          <Header
            title={`${subTitleIndexes[allReactiveTitle]} ${allReactiveTitle}`}
            className="no-screen"
          />
          <MaintenanceTableChart
            contract={contract}
            portfolio={portfolio}
            className="reactive-total"
            //title={`1. ${ReportTableType.total.caption} ${ReportTableClass.Reactive.caption}`}
            category={ReportTableClass.Reactive}
            getData={PPMReactiveService.getBasicMaintenanceData}
            Chart={MaintenanceChart}
            transformForChart={transformDataForStandardTasksChart}
            animate={animate}
            subdivision={ReportTableType.total}
            showTable={visibilitySettings.show_rm_total_table}
            showChart={visibilitySettings.show_rm_total_graph}
            exposeDataSet={setReactiveTotals}
          />
        </div>
      }
      {hasRMSLA() &&
        <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
          <PrintHeading />
          <Header
            title={`${subTitleIndexes[reactiveSLATitle]} ${reactiveSLATitle}`}
            className="no-screen"
          />
          <MaintenanceTableChart
            contract={contract}
            portfolio={portfolio}
            className="rm-total-slas"
            category={ReportTableClass.Reactive_SLA}
            getData={PPMReactiveService.getSLAMaintenanceData}
            Chart={SLAMaintenanceChart}
            transformForChart={transformDataForSLATasksChart}
            subdivision={ReportTableType.total}
            animate={animate}
            showChart={visibilitySettings.show_rm_sla_graph}
            showTable={visibilitySettings.show_rm_sla_table}
          />
        </div>
      }
      {hasReactiveIssues() &&
        <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
          <PrintHeading />
          <Header
            title={`${subTitleIndexes[reactiveByCategoryTitle]} ${reactiveByCategoryTitle}`}
            className="no-screen"
          />
          <ReactiveByCategoryTableChart
            reportConfig={ReportTableClass.ReactiveByCategory}
            relegationPointer="relegated-reactive-categories"
            comparisonField="tasks_issued"
            contract={contract}
            portfolio={portfolio}
            subdivision={ReportTableType.total}
            title={reactiveByCategoryTitle}
            showTable={visibilitySettings.show_rm_total_issues_table}
            showChart={visibilitySettings.show_rm_total_issues_graph}
            reactiveTotals={reactiveTotals}
            tableSelector={"ReactiveByCategory"}
          />
        </div>
      }

      {hasReactiveHourIssues() &&
        <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
          <PrintHeading />
          <Header
            title={`${subTitleIndexes[reactiveByCategoryHoursTitle]} ${reactiveByCategoryHoursTitle}`}
            className="no-screen"
          />
          <ReactiveByCategoryTableChart
            reportConfig={ReportTableClass.ReactiveByHoursCategory}
            relegationPointer="relegated-reactive-hours-categories"
            comparisonField="time_spent"
            contract={contract}
            portfolio={portfolio}
            subdivision={ReportTableType.total}
            title={reactiveByCategoryHoursTitle}
            showTable={visibilitySettings.show_rm_total_issue_hours_table}
            showChart={visibilitySettings.show_rm_total_issue_hours_graph}
            reactiveTotals={reactiveTotals}
            tableSelector={"ReactiveByHoursCategory"}
          />
        </div>
      }

      {hasReactiveOpen() &&
        <NewPageWrapper breakAfter={true} >
          <PrintHeading />
          <Header
            title={`${subTitleIndexes[openReactiveTitle]} ${openReactiveTitle}`}
            className="no-screen"
          />
          <OpenReactivesDataGrid title={openReactiveTitle} contract={contract} portfolio={portfolio} />
        </NewPageWrapper>
      }
    </div>
  );
};

export default withPageContext(ReactiveReport, {
  visibilityMenu: rmRightVisibilityMenu
});

export const portfolioReactiveReport = withPortfolioPageContext(ReactiveReport, {
  visibilityMenu: rmRightVisibilityMenu
})