import React, { useRef } from "react";
import moment, { Moment } from 'moment';

// own
import ActionForm from "components/Common/Components/GeneralActionForm/GeneralActionForm";
import { dateUDF, getPeriodBetween } from "components/Common/Utils/Dates.js";
import { Period } from "components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface";
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';

export interface periodInitialConfig {
    initialStartDate?: Moment;
    initialEndDate?: Moment;
    period?: Period,
    setPeriod: React.Dispatch<React.SetStateAction<any>>;
    periodChooserClass?: string;
}

const periodFormConfig: FieldsFormConfig = {
    startDate: {
        //dateDisplayFormat: "monthAndYear",
        datePickerCalendarOptions: {
            maxZoomLevel: 'year',
            minZoomLevel: 'century',
        }
    },
    endDate: {
        //dateDisplayFormat: "monthAndYear",
        datePickerCalendarOptions: {
            maxZoomLevel: 'year',
            minZoomLevel: 'century',
        }
    }
}

const periodFormMeta = {
    startDate: {
        type: "date",
        required: true,
        field_name: "startDate",
        read_only: false,
        label: "Start Date",
    },
    endDate: {
        type: "date",
        required: true,
        field_name: "endDate",
        read_only: false,
        label: "End Date",
    }

}

const PeriodChooser = ({ initialStartDate, initialEndDate, period, setPeriod, periodChooserClass }: periodInitialConfig) => {
    let thisInitialStartDate;
    let thisInitialEndDate;

    if (initialStartDate) {
        thisInitialStartDate = initialStartDate.format(dateUDF);
    } else {
        thisInitialStartDate = moment().format(dateUDF);
    }

    if (initialEndDate) {
        thisInitialEndDate = initialEndDate.format(dateUDF);
    } else {
        thisInitialEndDate = moment().format(dateUDF)
    }

    const formValuesRef = useRef<any>({
        startDate: thisInitialStartDate,
        endDate: thisInitialEndDate
    });

    const handleNewValues = (newValues: any) => {
        const newPeriod = getPeriodBetween({ startDate: newValues.startDate, endDate: newValues.endDate, wholeMonths: true });
        if (period?.start_date !== newPeriod.start_date || period?.end_date !== newPeriod.end_date) {
            formValuesRef.current.startDate = newPeriod.start_date;
            formValuesRef.current.endDate = newPeriod.end_date;
            setPeriod(newPeriod);
        }
    }

    return <>
        <ActionForm
            formValues={formValuesRef}
            callWithOnChange={handleNewValues}
            fieldConfigs={periodFormConfig}
            gridClass={`periodChooser ${periodChooserClass ? periodChooserClass : ''}`}
            metaForForm={periodFormMeta}
        />
    </>
}

export default PeriodChooser