import styled from "styled-components";
import styles from "styles/app/common/variables.scss";

type ContractLayoutContainerProps = {
  snapshotMode: boolean,
  isCurrentPeriod?: boolean,
  active?: boolean
}
//  background-color: ${ ({ snapshotMode, isCurrentPeriod }) => snapshotMode ? styles.redLight : styles.blueLight};
export const ContractLayoutContainer = styled.div<ContractLayoutContainerProps>`
  background-color: ${({ snapshotMode, isCurrentPeriod }) => snapshotMode ? styles.redLight : isCurrentPeriod === false ? styles.lemonChiffron : styles.blueLight};
  min-height: 85vh;
  opacity: ${({ active }) => active ? 1 : 0.5};
`;

type ContractContentWrapperProps = {
  leftMenuIndent?: string;
}

export const ContractContentWrapper = styled.div<ContractContentWrapperProps>`
  margin-left: ${({ leftMenuIndent }) => leftMenuIndent};

  @media print {
    margin-left: 0px;
  }

`;

