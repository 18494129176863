import styled from "styled-components";
import styles from "../../../../../styles/app/common/variables.scss";

export const SubHeading = styled.div`
  font-size: 1.5rem;
  color: ${styles.blueDark};
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SubSmall = styled.div`
  font-size: 0.8rem;
`;
