import { combineReducers } from "redux";

import sites from "../../components/Sites/Reducers/Sites.reducers";
import portfolios from "components/Portfolios/Reducers/Portfolio.reducers";
import portfolioInFocus from "components/PortfolioInFocus/Reducers/portfolioInFocus.reducer";
import contracts from "../../components/AdminPanel/Contracts/Reducers/Contracts.reducer";
import contractPeopleWithAccess from "../../components/AdminPanel/Contracts/Reducers/PeopleWithAccess.reducer";
import monthlyMaintenanceReportDistribution from "../../components/AdminPanel/Contracts/Reducers/MaintenanceReportDistribution.reducer";
import personAccessibleContracts from "components/AdminPanel/Contracts/Reducers/AccessibleContracts.reducer";
import personAccessiblePortfolios from "components/AdminPanel/Contracts/Reducers/AccessiblePortfolios.reducer";
import contractInFocus from "../../components/ContractInFocus/Reducers/contractInFocus.reducer";
import contractInFocusCache from "../../components/ContractInFocus/Reducers/cachedContractInFocus.reducer";
import contractACLs from "components/ContractInFocus/Reducers/contractACL.reducer";
import portfolioACLs from "components/ContractInFocus/Reducers/portfolioACL.reducer";
import contractDocumentsViewer from "../../components/ContractInFocus/Reducers/contractDocsViewer.reducer";
import portfolioDocumentsViewer from "components/PortfolioInFocus/Reducers/portfolioDocsViewer.reducer";
import mainInFocusVisibilityObject from "components/ContractInFocus/Reducers/visibility.reducer";
import organisations from "../../components/AdminPanel/Organisation/Reducer/Organisation.reducer";
import people from "../../components/AdminPanel/People/Reducers/People.reducer";
import profile from "../../components/Profile/Reducers/Profile.reducer";
import personalSettings from "../../components/Profile/Reducers/PersonalSettings.reducer";
import common from "../Common/Reducers/Common.reducer.js";
import settings from "./settings.reducer.js";
import theme from "./themes.reducers.js";
import adminPanel from "../../components/AdminPanel/Reducers/adminPanel.reducer";
import notifications from "../../components/Notification/Reducers/Notification.reducer";
import layout from "../../components/Layout/reducers/Layout.reducer";
import download from "../../components/ContractInFocus/HoC/WithPageContext/Reducers/Download.reducer";
import contractPeriods from "components/AdminPanel/ContractPeriods/Reducers/ContractPeriods.reducers";
import portfolioPeriods from "components/AdminPanel/ContractPeriods/Reducers/PortfolioPeriods.reducers";
import selectedEntities from "store/SelectedEntities/Reducers/SelectedEntities.reducers.js";
import selectedEntityConfigurations from "store/SelectedEntities/Reducers/SelectEntityConfigurations.reducers.js";
import meterStreams from "components/ContractInFocus/MeterReadings/Reducers/MeterStreams.reducer";
import personalHiddenObjects from "components/ContractInFocus/Reducers/PersonalHiddenObjects.reducer";
import personalContractNotificationSettings from "components/ContractInFocus/Reducers/contractNotifications.reducer";
import personalPortfolioNotificationSettings from "components/Portfolios/Reducers/portfolioNotifications.reducer";
import interfaces from "store/reducers/interface.reducer";

export const appReducer = combineReducers({
  common,
  settings,
  contractInFocus,
  contractInFocusCache,
  contractACLs,
  portfolioACLs,
  contractDocumentsViewer,
  portfolioDocumentsViewer,
  mainInFocusVisibilityObject,
  contractPeriods,
  portfolioPeriods,
  theme,
  interfaces,
  adminPanel,
  selectedEntities,
  selectedEntityConfigurations,
  sites,
  portfolios,
  portfolioInFocus,
  //contractDocuments,
  organisations,
  contracts,
  contractPeopleWithAccess,
  monthlyMaintenanceReportDistribution,
  personAccessibleContracts,
  personAccessiblePortfolios,
  people,
  profile,
  personalSettings,
  notifications,
  layout,
  download,
  meterStreams,
  personalHiddenObjects,
  personalContractNotificationSettings,
  personalPortfolioNotificationSettings
});
