import React from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

// Own
import {
  StyledSiteRow,
  StyledSiteCell,
} from "./PortfolioContractRowStyles";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { withResizeDetector } from 'react-resize-detector';
import CommonAPIService from "components/ContractInFocus/Services/commonAPI.services";
import { Portfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

interface PortfolioContractRowProps {
  contract: SiteContract;
  portfolio: Portfolio;
  width: number;
  onDocClicked: (contractId: number) => void;
  inContract?: boolean;
}

const PortfolioContractRow = ({ portfolio, contract, width, onDocClicked, inContract }: PortfolioContractRowProps) => {

  const history = useHistory();

  const handleClicked = (event: any): void => {
    CommonAPIService.triggerServerSideContractDataUpdate(contract.contract_ref);
    event.stopPropagation();
    history.push(`/portfolio/${portfolio.id}/`);
  }

  const handleDocClicked = (event: any, contractId: number): void => {
    event.stopPropagation();
    onDocClicked(contractId);
  }

  return (
    <StyledSiteRow onClick={handleClicked} width={width} className={contract.active ? "active" : "inactive"}>
      <StyledSiteCell title={contract.contract_ref} className={inContract ? "in-contract contract-no" : "not-in-contract contract-no"}>
        <span>{contract.contract_ref}</span>
      </StyledSiteCell>
      <StyledSiteCell title={contract.alias || contract.contract_with.name} className={inContract ? "in-contract contract-with" : "not-in-contract contract-with"} style={{ flexGrow: 1 }}>
        <em className="fas fa-briefcase"></em>
        <span>{contract.alias ? contract.alias : contract.contract_category === "base" ? contract.site_name : contract.contract_with.name}</span>
      </StyledSiteCell>
      {/* <StyledSiteCell
        title={contract?.new_documents_count?.toString()}
        className="doc-count"
        onClick={(event) => handleDocClicked(event, contract.id)}
      >
        <em className="fas fa-file-alt"></em>
        <span>{contract.new_documents_count}</span>
      </StyledSiteCell> */}
    </StyledSiteRow>
  );
}

export default withResizeDetector(
  PortfolioContractRow
);