// Own
import API from "services/API/API"
import { store } from "store/store";
import * as actions from "components/AdminPanel/Portfolios/Actions/AdminPanelPortfolios.actions";

export const addPortfolioToMyFavourites = (portfolioId: number | string) => {
  return API.post(`my/favourite-portfolios/`, { portfolio: portfolioId }).then((response: any) => {
    // notice that the id of the connection between the portolio and user isn't stored.  Instead delete uses
    // a special route that looks that up based on the user and the portfolio.
    store.dispatch(actions.favouritePortfolioSuccess(portfolioId, 1))
  });
}

export const removePortfolioFromMyFavourites = (portfolioId: number | string) => {
  // note that we don't exactly conform to REST here as we send the id of the portfolio
  // rather than the 'connection' that is being deleted.  However I think the context justifies.
  return API.delete(`my/favourite-portfolios/${portfolioId}/`).then((response: any) => {
    store.dispatch(actions.favouritePortfolioSuccess(portfolioId, 0))
  });
}

export default {
  addPortfolioToMyFavourites,
  removePortfolioFromMyFavourites,
}