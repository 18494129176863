import { createRequestActionTypes } from "../../components/Common/Utils/ReduxUtil";
import { PanelState } from "../../components/AdminPanel/Interfaces/Panel";

export const UNFOCUS_BRANCH = createRequestActionTypes('ROOT_UNFOCUS_BRANCH');
export const unfocusBranch = (branch: string) => ({ type: UNFOCUS_BRANCH.start, branch });

export const SET_ENTITY = createRequestActionTypes('ROOT_SET_ENTITY');
export const setEntity = (branch: string, data: any, meta: any) => ({ type: SET_ENTITY.start, branch, data, meta });

export const SET_BRANCH_FIELD = createRequestActionTypes('SET_BRANCH_FIELD');
export const setBranchField = (branch: string, recordId: number, field: string, value: string) =>
  ({ type: SET_BRANCH_FIELD.reducer, branch, recordId, field, value });

export const NEW_RECORD_CHANGE_TAB = createRequestActionTypes('ROOT_NEW_RECORD_CHANGE_TAB');
export const newRecordChangeTab = (panelId: string, branch: string, data: object, activePanelId?: string) =>
  ({ type: NEW_RECORD_CHANGE_TAB.start, panelId, branch, data, activePanelId });

export const NEW_ENTITY = createRequestActionTypes('ROOT_NEW_ENTITY');
export const newEntity = (branch: string, panelId: string, newEntity: any) => ({ type: NEW_ENTITY.start, branch, panelId, newEntity });

export const REMOVE_ENTITY = createRequestActionTypes('ROOT_REMOVE_ENTITY');
export const removeEntity = (branch: string, recordId: string) => ({ type: REMOVE_ENTITY.reducer, branch, recordId });

export const RESET_ENTITY = createRequestActionTypes('ROOT_RESET_ENTITY');
export const resetEntity = (branch: string, recordId: number) =>
  ({ type: RESET_ENTITY.start, branch, recordId });

export const REPLACE_ENTITY_BY_ID = createRequestActionTypes('ROOT_REPLACE_ENTITY_BY_ID');
export const replaceEntityById = (branch: string, recordId: string, panelId: string, data: any, meta: any) =>
  ({ type: REPLACE_ENTITY_BY_ID.start, branch, recordId, panelId, data, meta });

export const PANEL_CHANGE_EVENT = createRequestActionTypes('ROOT_PANEL_CHANGE_EVENT');
export const panelChangeEvent = (panelState: PanelState) =>
  ({ type: PANEL_CHANGE_EVENT.start, panelState });
// export const panelChangeEventSuccess = () => ({type: PANEL_CHANGE_EVENT.success});

export const ROOT_RESET_STATE = createRequestActionTypes('ROOT_RESET_STATE');
export const resetState = () => ({ type: ROOT_RESET_STATE.reducer });
