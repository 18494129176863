import { createRequestActionTypes } from "../../../Common/Utils/ReduxUtil";
import { ContractInterface, ContractPersonTypes, PersonWithAccessI, DistributionRecipientI } from "../Interfaces/Contract.interface";
//import { AdminSite } from "../../Sites/Interfaces/Sites.interface";
import { Site } from "components/Sites/Interfaces/Site.inteface";
import { ContractSublistsMeta } from "../Interfaces/ContractSublists.interfaces";
import { FieldMetaGroup } from "../../../Common/Interfaces/Entity.interface";
import { APIPrivileges } from "services/Interface/Interface";

/* Add Person To Contract */

export const ADD_CONTRACT_PEOPLE_WITH_ACCESS = createRequestActionTypes("ADD_PEOPLE_WITH_ACCESS");
export const addPeopleWithAccessReducer = (contractId: number | string, peopleWithAccess: PersonWithAccessI[], meta: FieldMetaGroup, putMeta: FieldMetaGroup, permissions?: APIPrivileges) =>
    ({ type: ADD_CONTRACT_PEOPLE_WITH_ACCESS.reducer, contractId, peopleWithAccess, meta, putMeta, permissions });

export const UPDATE_CONTRACT_PEOPLE_WITH_ACCESS = createRequestActionTypes("UPDATE_CONTRACT_PERSON_WITH_ACCESS");
export const updatePersonWithAccessReducer = (contractId: number | string, peopleWithAccess: PersonWithAccessI[]) =>
    ({ type: UPDATE_CONTRACT_PEOPLE_WITH_ACCESS.reducer, contractId, peopleWithAccess });

export const REMOVE_CONTRACT_PEOPLE_WITH_ACCESS = createRequestActionTypes("REMOVE_CONTRACT_PERSON_WITH_ACCESS");
export const removePeopleWithAccessReducer = (contractId: number | string, accessObjId: string | number) =>
    ({ type: REMOVE_CONTRACT_PEOPLE_WITH_ACCESS.reducer, contractId, accessObjId });

export const ADD_RECIPIENT_TO_MAINTENANCE_REPORT_DISTRIBUTION_LIST = createRequestActionTypes("ADD_RECIPIENT_TO_MAINTENANCE_REPORT_DISTRIBUTION_LIST");
export const addRecipientsToMaintenanceReportDistributionReducer = (contractId: number | string, recipients: DistributionRecipientI[], meta: FieldMetaGroup, putMeta: FieldMetaGroup, permissions?: APIPrivileges) =>
    ({ type: ADD_RECIPIENT_TO_MAINTENANCE_REPORT_DISTRIBUTION_LIST.reducer, contractId, recipients, meta, putMeta, permissions });

export const MERGE_MAINTENANCE_REPORT_DISTRIBUTION_LIST = createRequestActionTypes("MERGE_MAINTENANCE_REPORT_DISTRIBUTION_LIST");
export const mergeMaintenanceReportDistributionReducer = (contractId: number | string, recipients: DistributionRecipientI[]) =>
    ({ type: MERGE_MAINTENANCE_REPORT_DISTRIBUTION_LIST.reducer, contractId, recipients });

export const REPLACE_MAINTENANCE_REPORT_DISTRIBUTION_LIST = createRequestActionTypes("REPLACE_MAINTENANCE_REPORT_DISTRIBUTION_LIST");
export const replaceMaintenanceReportDistributionReducer = (contractId: number | string, recipients: DistributionRecipientI[]) =>
    ({ type: REPLACE_MAINTENANCE_REPORT_DISTRIBUTION_LIST.reducer, contractId, recipients });

export const REMOVE_RECIPIENT_FROM_DISTRIBUTION_LIST = createRequestActionTypes("REMOVE_RECIPIENT_FROM_DISTRIBUTION_LIST");
export const removeMaintenanceReportRecipientReducer = (contractId: number | string, recipientObjId: string | number) =>
    ({ type: REMOVE_RECIPIENT_FROM_DISTRIBUTION_LIST.reducer, contractId, recipientObjId });

/* Get Contracts */
export const FETCH_CONTRACTS = createRequestActionTypes("FETCH_CONTRACTS");
export const fetchContracts = () => ({ type: FETCH_CONTRACTS.start });
export const fetchContractsSuccess = (contracts: any) => ({ type: FETCH_CONTRACTS.success, contracts });
export const fetchContractsError = (error: any) => ({ type: FETCH_CONTRACTS.error, error });

/* Get Contract */
export const FETCH_CONTRACT = createRequestActionTypes("FETCH_CONTRACT");
export const fetchContractSuccess = (contract: ContractInterface) => ({ type: FETCH_CONTRACT.success, contract });
export const fetchContractError = (error: any) => ({ type: FETCH_CONTRACT.error, error });

/* Create Contract */
export const CREATE_CONTRACT = createRequestActionTypes("CREATE_CONTRACT");
export const createContract = (panelId: string, contract: ContractInterface) => ({ type: CREATE_CONTRACT.start, panelId, contract });
export const createContractSuccess = () => ({ type: CREATE_CONTRACT.success });
export const createContractError = (error: any) => ({ type: CREATE_CONTRACT.error, error });

/* Create Contract */
export const UPDATE_CONTRACT = createRequestActionTypes("UPDATE_CONTRACT");
export const updateContract = (panelId: string, contract: ContractInterface, meta: FieldMetaGroup) => ({ type: UPDATE_CONTRACT.start, panelId, contract, meta });
export const updateContractSuccess = (contract: ContractInterface) => ({ type: UPDATE_CONTRACT.success, contract });
export const updateContractError = (error: any) => ({ type: UPDATE_CONTRACT.error, error });

/* Link Contract to  Site */
export const LINK_CONTRACT_TO_SITE = createRequestActionTypes('CONTRACT_LINK_CONTRACT_TO_SITE');
export const linkContractToSite = (panelId: string, site: Site, contract: ContractInterface) => ({ type: LINK_CONTRACT_TO_SITE.start, panelId, site, contract });
export const linkContractToSiteSuccess = () => ({ type: LINK_CONTRACT_TO_SITE.success });
export const linkContractToSiteError = (error: string) => ({ type: LINK_CONTRACT_TO_SITE.error, error });

/* Delete */
export const DELETE_CONTRACT = createRequestActionTypes('CONTRACT_DELETE_CONTRACT');
export const deleteContract = (contract: ContractInterface, panelId: string) => ({ type: DELETE_CONTRACT.start, contract, panelId });
// HT Unused?!! referenced in deleteContractEpic but seems epic isn't called - it seems only DELETE_CONTRACT.reducer is currently called when a contract is deleted
export const deleteContractSuccess = (contractId: number, panelId: string) => ({ type: DELETE_CONTRACT.success, contractId, panelId });
export const deleteContractReducer = (contractId: any) => ({ type: DELETE_CONTRACT.reducer, contractId });
export const deleteContractError = (error: any) => ({ type: DELETE_CONTRACT.error, error });

/* Set Contract */
export const SET_CONTRACT = createRequestActionTypes('SET_CONTRACT');
export const setContractSuccess = (
    contract: ContractInterface, meta: any, options?: any, permissions?: any, insert?: boolean, panelId?: string, recordId?: string | number) =>
    ({ type: SET_CONTRACT.success, contract, meta, options, permissions, insert, panelId, recordId });
export const setContractReducer = (contract: ContractInterface) => ({ type: SET_CONTRACT.reducer, contract });

/* Fetch Contract Sublist Meta */
export const FETCH_CONTRACT_SUBLIST_META = createRequestActionTypes('CONTRACT_FETCH_CONTRACT_SUBLIST_META');
export const fetchContractSublistMeta = (contractRef: string) => ({ type: FETCH_CONTRACT_SUBLIST_META.start, payload: { contractRef } });
export const fetchContractSublistMetaSuccess = (meta: ContractSublistsMeta) => ({ type: FETCH_CONTRACT_SUBLIST_META.success, payload: { meta } });
export const fetchContractSublistMetaError = (error: string) => ({ type: FETCH_CONTRACT_SUBLIST_META.error, payload: { error } });

export const SET_GROUP_CONTRACTS = createRequestActionTypes('SET_GROUP_CONTRACTS');
export const setGroupContractReducer = (contractGroup: any) => ({ type: SET_GROUP_CONTRACTS.reducer, contractGroup });