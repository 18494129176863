import React, { useEffect } from "react";
import { useSelector } from "react-redux";

// Own
import {
  DataGridEvent,
  DataGridUpdateEvent
} from "interfaces/datagrid.interfaces";
import { AdminDataGrid } from "components/AdminPanel/Containers/AdminDataGrid";
import { contractContractPeriodsSelector } from "components/AdminPanel/ContractPeriods/Selectors/ContractPeriods.selectors";
import { contractPeriodDatagridColumns } from "components/AdminPanel/ContractPeriods/Models/ContractPeriods.model";
import { EntityKeys } from "constants/entity.constants";
import { apiSuccess } from 'components/AdminPanel/Helpers/AdminPanel.helper';
import { simpleFetchContractPeriods } from "components/AdminPanel/ContractPeriods/Services/contractPeriodsService";

import contractPeriodService from "components/AdminPanel/ContractPeriods/Services/contractPeriodsService";

interface ContractPeriodsSublistViewProps {
  panelId: string;
  id: any;
  dataTestId: string;
  contractRef: string;
}

const ContractPeriodsSublistView: React.SFC<ContractPeriodsSublistViewProps> = ({
  panelId,
  id,
  dataTestId,
  contractRef
}) => {
  const selectContractPeriodState = useSelector(contractContractPeriodsSelector(id));

  const handleUpdate = (e: DataGridUpdateEvent) => {
    // @ts-ignore
    e.cancel = contractPeriodService.updateContractPeriod(e.oldData.id, e.newData).then(apiSuccess);
  };

  const handleCreate = (e: DataGridEvent): void => {
    // @ts-ignore
    contractPeriodService.createContractPeriod(e.data, id);
  };

  const handleDelete = (e: DataGridEvent): void => {
    // @ts-ignore
    e.cancel = contractPeriodService.deleteContractPeriod(e.data.id).then(apiSuccess);
  };

  useEffect(() => {
    simpleFetchContractPeriods(contractRef);
  }, [contractRef]);

  return (
    <AdminDataGrid
      data={selectContractPeriodState.data}
      meta={selectContractPeriodState.meta}
      permissions={selectContractPeriodState.permissions}
      dataTestId={dataTestId}
      getDatagridColumns={contractPeriodDatagridColumns}
      masterView={false}
      entityKey={EntityKeys.ContractPeriods}
      panelId={panelId}
      dragAndDrop={false}
      onCreate={handleCreate}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
    />
  );
};

export default ContractPeriodsSublistView;
