import React, { useEffect, useState, useRef, useReducer, useCallback } from 'react';
import DataGrid, { RowDragging, Paging, Column, Editing, MasterDetail, Button as DXButton } from 'devextreme-react/data-grid';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { Paper } from "@material-ui/core";
import { isEqual } from "lodash";
import {
    DataGridDropEvent
} from "interfaces/datagrid.interfaces";

// Own
import { store } from "store/store";
import { Visit, CreateVisit, SupplierService, ScheduleResponse } from '../../../Interfaces/Schedule.interfaces';
import { getVisitListColumns, extraVisitListMeta } from '../../Models/VisitColumns';
import { getColumnProps } from "helpers/DataGrid/DataGridColumn.helper";
import { UsefulDXListWrapper, } from "components/ContractInFocus/Styles/CommonStyles";
import MatConfirmationDialog from 'components/Common/Components/Material/MatConfirmationDialog/MatConfirmationDialog';
import { deleteSupplierVisit, multiCreateSupplierVisits, simpleCreateSupplierVisit, simpleUpdateVisit, fetchSingleSupplierServiceContract } from "components/Schedulers/Services/visitService";
import BatchVisitsForm from "components/Schedulers/VisitModal/Components/VisitList/BatchCreateVisits";
import { ScheduleListGridActionWrapper } from "components/Schedulers/SchedulerStyles";
import { FieldMetaGroup, FormValues } from "components/Common/Interfaces/Entity.interface";
import { addNotification } from 'components/Notification/Actions/Notification.actions';
import { NOTIFICATION_SUCCESS, NOTIFICATION_WARNING, NOTIFICATION_ERROR } from 'components/Notification/Constants/constants';
import { onResponseError } from "services/API/API.interceptor.js";
import NotesDetail from "components/Schedulers/Components/NotesDetail/NotesDetail";
import { sortByScheduledFor } from "components/Schedulers/ScheduleDataGrid/Helper/scheduleDataGridHelper";
import { removeReadOnlyFields } from "services/API/API.helper";
import { NotesPreviewer } from "components/Schedulers/Scheduler";
import { capitalize, elipse } from "helpers/String/String.helper";
import { Period } from "components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface";
import { fetchSupplierVisits, VisitQueryParams } from "components/Schedulers/Services/visitService";
import { SupplierContract } from 'components/Schedulers/Interfaces/Schedule.interfaces';
import { getDimTitle } from "helpers/Forms/modal.helper";
import { PeriodOption } from "components/ContractInFocus/interfaces/contractInFocusActions.interfaces";
import { GeneralTooltip } from "components/Common/Components/InfoHint/InfoHint";
import { wordDateFormat2 } from 'components/Common/Utils/Dates.js';


import 'components/Schedulers/ScheduleListGeneralStyles.scss';

interface BaseVisitListsProps {
    supplierService: SupplierService;
    contractRef: string;
    portfolioId?: string | number;
    refreshGrid: React.DispatchWithoutAction;
    setVisit: React.Dispatch<React.SetStateAction<Visit | undefined>>;
    initialNotesStore: React.MutableRefObject<FormValues>;
    setVisitIndex: React.Dispatch<React.SetStateAction<number>>;
    permitIndex: number;
    documentsIndex: number;
    disable_work_permits_system: boolean;
    selectedPeriod?: Period;
}
interface SupplierContractVisitListProps extends BaseVisitListsProps {
    dispatchRefreshContext: React.DispatchWithoutAction;
    supplierContract: SupplierContract;
    multipleContracts: boolean;
    refreshSignal: any;
}

interface SupplierServicesVisitListsProps extends BaseVisitListsProps {
    getSupplierServiceContractsResponse: ({ start_date, end_date, callback }: {
        start_date?: string;
        end_date?: string;
        callback?: any;
    }) => void;
    setModalTitle: React.Dispatch<React.SetStateAction<(() => JSX.Element) | undefined>>;
}

const VisitList = ({
    supplierService,
    contractRef,
    portfolioId,
    disable_work_permits_system,
    refreshGrid,
    setVisit,
    initialNotesStore,
    dispatchRefreshContext,
    setVisitIndex,
    permitIndex,
    documentsIndex,
    selectedPeriod,
    supplierContract,
    multipleContracts,
    refreshSignal
}: SupplierContractVisitListProps) => {

    //TODO: Turn the confirmation state into a custom hook and possibly integrate with confirmation component
    const deleteMessage = 'Are you sure you want to delete this visit?';
    const confirmationInitialState = { open: false, agreeCallback: () => { } };
    const [confirmationDialog, setConfirmationDialog] = useState(confirmationInitialState);
    const [previewVisits, setPreviewVisits] = useState<Visit[]>();
    const previewVisitIds = useRef<string[]>([]);
    const [visitsData, setVisitsData] = useState<Visit[]>();
    const [mustRefreshVisits, forceUpdate] = useReducer((x) => x + 1, 0);
    // const [updateCount, setUpdateCount] = useReducer((x) => x + 1, 0);
    // const [scheduledCount, setScheduledCount] = useState<number>();
    // const [completedCount, setCompletedCount] = useState<number>();
    const [visitListMeta, setVisitListMeta] = useState<FieldMetaGroup>();
    const [batchCreateFormOpen, setOpenBatchCreateForm] = useState(false);
    const [templateVisit, setTemplateVisit] = useState<Visit>();
    const [canEdit, setCanEdit] = useState(false);
    const [visitsResponse, setVisitsResponse] = useState<ScheduleResponse>();
    const [filterVisitsByPeriod, setFilterVisitsByPeriod] = useState<boolean>(true);
    const notesStore = initialNotesStore; // this is to be used for persistence until the component goes completely out of scope
    const gridRef = useRef<any>();
    const [visitStats, setVisitStats] = useState({
        visitsCount: supplierContract.actual_visits_count || 0,
        visitsContractedCount: supplierContract.number_of_visits || 0,
        visitsCompletedCount: supplierContract.completed_visits_count || 0
    })
    const [visitStatsWarning, setVisitStatsWarning] = useState<string>();

    useEffect(() => {
        const vcc = visitStats.visitsContractedCount;
        const vc = visitStats.visitsCount;
        if ((vcc || vcc === 0) && (vc || vc === 0)) {
            if (vcc > vc) {
                setVisitStatsWarning("More PPM visits have contracted than have been scheduled")
            } else if (vcc < vc) {
                setVisitStatsWarning("More PPM visits have been scheduled than are currently contracted")
            } else {
                setVisitStatsWarning(undefined);
            }
        }
    }, [visitStats])

    useEffect(() => {
        visitsData && visitsData.map(x => { initialNotesStore.current[x.id] = initialNotesStore.current[x.id] || x.notes })
    }, [visitsData, initialNotesStore])

    useEffect(() => {
        batchCreateFormOpen && setFilterVisitsByPeriod(false);
    }, [batchCreateFormOpen])

    useEffect(() => {
        refreshGrid && refreshGrid();
    }, [mustRefreshVisits, refreshGrid])

    const loadDetailsView = (cellData: any, visitIndex: number) => {
        if (cellData.data && !cellData.data.id.startsWith('temp.')) {
            setVisit(cellData.data);
            setVisitIndex(visitIndex);
        }
    }

    const updateVisitStats = useCallback(() => {
        fetchSingleSupplierServiceContract({
            contractRef: contractRef,
            portfolioId: portfolioId,
            supplier_service: supplierService?.id,
            id: supplierContract.id
        }).then((response) => {
            setVisitStats({
                visitsCount: response.data.actual_visits_count,
                visitsContractedCount: response.data.number_of_visits,
                visitsCompletedCount: response.data.completed_visits_count
            })
        })
    }, [supplierService, contractRef, portfolioId, supplierContract]);

    useEffect(() => {
        if (supplierContract.id && refreshSignal) {
            const query_params: VisitQueryParams = {};
            if (filterVisitsByPeriod) {
                query_params.start_date = selectedPeriod?.start_date;
                query_params.end_date = selectedPeriod?.end_date;
            }
            if (supplierContract.id) { query_params.supplier_contract = supplierContract.id }
            fetchSupplierVisits({
                contractRef: contractRef,
                portfolioId: portfolioId,
                query_params: query_params
            }).then((visitsResponse) => {
                setCanEdit(!!visitsResponse.permissions?.POST)
                setVisitsResponse(visitsResponse);
                updateVisitStats();
            })
        }
    }, [supplierContract, portfolioId, contractRef, selectedPeriod, filterVisitsByPeriod, refreshSignal, mustRefreshVisits, updateVisitStats])




    const RenderDocumentsCell = (cellData: any) => {
        let className = '';
        const status = cellData.data.status;
        const serviceReportsCount = cellData.data.service_reports_count;
        const certificatesCount = cellData.data.certificates_count;
        const visitReportsCount = cellData.data.visit_reports_count;
        const thisOnClick = (e: any) => loadDetailsView(cellData, documentsIndex);
        if (status === "completed" && serviceReportsCount === 0 && certificatesCount === 0) {
            className = "warn cell-content-link"
            return <GeneralTooltip title="Neither a service report nor a certificate has been made available">
                <div onClick={thisOnClick} className="warn cell-content-link"><span>{visitReportsCount}</span></div>
            </GeneralTooltip>
        }
        return <div className={"cell-content-link"} onClick={thisOnClick}><span>{visitReportsCount}</span></div>
    };

    const RenderPTWsCell = (cellData: any) => {
        const ptwsCount = cellData.data.ptws_count;
        const thisOnClick = (e: any) => loadDetailsView(cellData, permitIndex);
        let className = '';

        if (cellData.data.missing_ptw) {
            className = "warn"
            return <GeneralTooltip title="No Permit To Work has been issued for this Visit">
                <div className="warn cell-content-link" onClick={thisOnClick}><span>{ptwsCount}</span></div>
            </GeneralTooltip>
        } else if (cellData.data.unfinished_ptw) {
            className = "warn"
            return <GeneralTooltip title="A permit to work issued for this visit is still unclosed and is not cancelled">
                <div className="warn cell-content-link" onClick={thisOnClick}><span>{ptwsCount}</span></div>
            </GeneralTooltip>
        } else if (cellData.data.active_ptw) {
            className = "info"
            return <GeneralTooltip title="A permit to work issued for this visit is active">
                <div className="info cell-content-link" onClick={thisOnClick}><span>{ptwsCount}</span></div>
            </GeneralTooltip>
        }
        return <div className={"cell-content-link"} onClick={thisOnClick}><span>{ptwsCount}</span></div>
    };

    const visitListColumns = getVisitListColumns(RenderDocumentsCell, RenderPTWsCell, disable_work_permits_system);

    const handleRowPrepared = (rowInfo: any) => {
        //console.log('rowInfo: ', rowInfo);
        if (rowInfo.rowType === 'data') {
            if (rowInfo.data?.id?.startsWith('temp.')) {
                rowInfo.rowElement.addClass('preview');
            }
            // uncomment if you want notes icon to have withNotes styling
            // when row is expanded 
            // if (rowInfo.data.notes?.trim().length) { // this will be run when notes are saved...
            //   rowInfo.rowElement.addClass('withNotes');
            // } else {
            //   rowInfo.rowElement.removeClass('withNotes');
            // }
        }
    }

    const saveBatch = (visits: CreateVisit[]) => {
        const visitsToBeCreated = visits.map(x => {
            const { id, updated_at, ...visit } = x;
            visit.supplier_contract = supplierContract.id;
            visit.notes = notesStore.current[id as string] as string;
            return visit;
        })
        multiCreateSupplierVisits({
            contractRef: contractRef,
            portfolioId: portfolioId,
            payload: visitsToBeCreated
        }).then((response) => {
            if (response.errors?.length) {
                store.dispatch(addNotification({
                    message: "The following issues were noted with the items submitted: " + JSON.stringify(response.errors),
                    type: NOTIFICATION_WARNING
                }))
            } else {
                store.dispatch(addNotification({
                    message: "Saved",
                    type: NOTIFICATION_SUCCESS
                }))
            }
            setPreviewVisits(undefined);
            forceUpdate();
        })
    }

    useEffect(() => {
        if (previewVisits) {
            const newPreviewVisitIds = previewVisits.map(x => x.id as string);
            if (previewVisits && !isEqual(newPreviewVisitIds, previewVisitIds)) {
                previewVisitIds.current = previewVisits.map(x => x.id as string);
            }
        }

    }, [previewVisits, previewVisitIds])

    useEffect(() => {
        let newVisitsData: Visit[] = [];
        if (visitsResponse?.data) {
            let pV = previewVisits || [];
            let vRD = visitsResponse.data || [];
            newVisitsData = [
                ...pV,
                ...vRD
            ]
        }
        const visitsWithOrder: Visit[] = [];
        const sortedVisitIds = sortByScheduledFor(newVisitsData).map(x => x.id); //.filter(x => x.status !== 'cancelled')

        sortedVisitIds.map((id, index) => {
            const visit = newVisitsData.find((x) => x.id === id);
            visit && visitsWithOrder.push(
                {
                    ...visit,
                    order: index + 1
                })
        })

        setVisitsData(visitsWithOrder);
        // if (visitsWithOrder) {
        //     let completed = 0;
        //     let scheduled = 0;

        //     //console.log('visitsWithOrder: ', visitsWithOrder);
        //     scheduled = visitsWithOrder.filter(x => x.status == "scheduled").length;
        //     completed = visitsWithOrder.filter(x => x.status == "completed").length;
        //     setScheduledCount(scheduled);
        //     setCompletedCount(completed);
        // }
    }, [visitsResponse, previewVisits])

    const handleUpdateVisit = useCallback(({ id, payload, rowEvent, refreshContext }: { id: string, payload: any, rowEvent?: any; refreshContext?: boolean }) => {
        simpleUpdateVisit({
            contractRef: contractRef,
            portfolioId: portfolioId,
            id: id,
            payload: payload,
        }).then((response) => {
            store.dispatch(addNotification({ message: "Saved", type: NOTIFICATION_SUCCESS }))
            if (refreshContext) {
                forceUpdate();
                dispatchRefreshContext() // this will cause a refetch of visits and an updating of visit stats anyway
            }
            else {
                updateVisitStats();
            }
            forceUpdate();
        }).catch(error => {
            rowEvent && rowEvent.component.cancelEditData();
            forceUpdate();
            onResponseError(error)
        });
    }, [contractRef, forceUpdate, portfolioId, updateVisitStats, dispatchRefreshContext])

    const moveVisitToThisSupplierContract = useCallback((e: DataGridDropEvent) => {
        const visit = e.itemData;
        const thisPayload = {
            supplier_contract: supplierContract.id
        }
        handleUpdateVisit({ id: visit.id, payload: thisPayload, refreshContext: true });
    }, [handleUpdateVisit, supplierContract]);

    const handleRowUpdating = useCallback((e: any) => {

        const { oldData, newData } = e;
        if (newData.service_type === "RM") {
            newData.booked = true;
        }
        const combinedData = {
            ...oldData,
            ...newData
        }
        if (oldData.id.startsWith('temp.')) {
            //let's find the relevant preview record and update it
            if (previewVisits) {
                const newPreviewVisits = previewVisits.map((x) => x.id === oldData.id ? combinedData : x)
                setPreviewVisits(newPreviewVisits);
            }
        } else {
            const preparedPayload = removeReadOnlyFields(combinedData, visitsResponse?.putMeta, true);
            visitsResponse?.putMeta && handleUpdateVisit({ id: oldData.id, payload: preparedPayload, rowEvent: e })
        }
    }, [handleUpdateVisit, previewVisits, visitsResponse]);

    const handleCreate = useCallback((e: any) => {
        try {
            const { data } = e;
            if (data.service_type === "RM") {
                data.booked = true;
            }

            data.supplier_contract = supplierContract.id;
            const preparedPayload = removeReadOnlyFields(data, visitsResponse?.meta, true);
            visitsResponse?.putMeta && supplierService && simpleCreateSupplierVisit({
                contractRef: contractRef,
                portfolioId: portfolioId,
                payload: preparedPayload
            }).then((response) => {
                store.dispatch(addNotification({ message: "Saved", type: NOTIFICATION_SUCCESS }))
                forceUpdate();
            }).catch(error => {
                forceUpdate();
                onResponseError(error)
            });
        } catch (error) {
            forceUpdate();
            store.dispatch(addNotification({
                //@ts-ignore
                message: error.toString() as string,
                type: NOTIFICATION_ERROR
            }))
        }
    }, [contractRef, portfolioId, supplierContract, supplierService, visitsResponse, forceUpdate]);

    const handleOnInitNewRow = useCallback(
        (e) => {
            if (selectedPeriod) {
                e.data.scheduled_for = moment(selectedPeriod.start_date).toDate();
            } else if (supplierContract) {
                e.data.scheduled_for = moment(supplierContract.contract_start_date).toDate();
            } else {
                e.data.scheduled_for = moment().toDate();
            }
        }, [supplierContract, selectedPeriod]
    )

    useEffect(() => {
        visitsResponse?.meta && setVisitListMeta({
            ...visitsResponse.meta,
            ...extraVisitListMeta
        })

    }, [visitsResponse])

    const [period, setPeriod] = useState<PeriodOption>()

    useEffect(() => {
        supplierContract.contract_start_date && supplierContract.contract_end_date && setPeriod({
            start_date: supplierContract.contract_start_date,
            end_date: supplierContract.contract_end_date
        })
    }, [supplierContract])

    const handleDelete = (visit: Visit): void => {
        if (visit?.id?.startsWith('temp.')) {
            const remainingVisits = previewVisits?.filter(v => v.id !== visit.id)
            setPreviewVisits(remainingVisits);
        } else {
            setConfirmationDialog({
                open: true, agreeCallback: () => {
                    visit.id && deleteSupplierVisit({
                        contractRef: contractRef,
                        id: visit.id,
                        portfolioId: portfolioId
                    }).then((response) => {
                        forceUpdate();
                        setConfirmationDialog(confirmationInitialState);
                    });
                }
            });
        }
    }

    return <>
        {supplierService && <div className='supplierVisitListWrapper'>
            <>{`Contract from ${moment(supplierContract.contract_start_date).format(wordDateFormat2)} to ${moment(supplierContract.contract_end_date).format(wordDateFormat2)}`}</>
            {
                batchCreateFormOpen && period &&
                <Paper elevation={2} style={{ marginTop: "1rem", padding: "0.5rem" }}>
                    <BatchVisitsForm
                        supplierService={supplierService}
                        setPreviewVisits={setPreviewVisits}
                        templateVisit={templateVisit}
                        setOpenBatchCreateForm={setOpenBatchCreateForm}
                        existingPreviewVisits={previewVisits}
                        contractRef={contractRef}
                        portfolioId={portfolioId}
                        supplierContractId={supplierContract.id}
                        period={period}
                    />
                </Paper>
            }

            {
                <UsefulDXListWrapper>
                    <MatConfirmationDialog
                        onAgree={confirmationDialog.agreeCallback}
                        onDisagree={() => setConfirmationDialog(confirmationInitialState)}
                        open={confirmationDialog.open}
                        actions={{ agree: 'Yes', disagree: 'No' }}
                    >
                        {deleteMessage}
                    </MatConfirmationDialog>
                    {visitsResponse?.permissions?.POST &&
                        <ScheduleListGridActionWrapper>
                            <div className="scheduleListStatsWrapper">
                                <div className="scheduleListStatsGroup">
                                    <span className="scheduleListStatLabel">PPM Visits Contracted:</span>
                                    <span className="scheduleListStatValue">{visitStats.visitsContractedCount}</span>
                                </div>
                                <div className="scheduleListStatsGroup">

                                    {visitStatsWarning ?
                                        <>
                                            <GeneralTooltip title={`${visitStatsWarning}`}>
                                                <span className={
                                                    `${visitStats && visitStats.visitsCount < visitStats.visitsContractedCount ? 'visitStatWarningTooFew' :
                                                        visitStats.visitsCount > visitStats.visitsContractedCount ? 'visitStatInfoExcess' : 'equal'
                                                    }`
                                                }>
                                                    <span className="scheduleListStatLabel visitStatWarning">PPM Visits Scheduled:</span>
                                                    <span className="scheduleListStatValue  visitStatWarning">{visitStats.visitsCount}</span>
                                                </span>
                                            </GeneralTooltip>
                                        </>
                                        :
                                        <>
                                            <span className="scheduleListStatLabel">PPM Visits Scheduled:</span>
                                            <span className="scheduleListStatValue">{visitStats.visitsCount}</span>
                                        </>

                                    }
                                </div>
                                <div className="scheduleListStatsGroup">
                                    <span className="scheduleListStatLabel">PPM Visits Completed:</span>
                                    <span className="scheduleListStatValue">{visitStats.visitsCompletedCount}</span>
                                </div>
                            </div>
                            <div id="scheduleListActionButtonsWrapper">
                                {!batchCreateFormOpen &&
                                    <>
                                        <div className="newBatchButtonWrapper buttonWrapper">
                                            <Button onClick={() => {
                                                setFilterVisitsByPeriod(!filterVisitsByPeriod);
                                            }}
                                                className="newBatchButton"
                                                color="secondary"
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                disabled={!period}
                                            >
                                                {filterVisitsByPeriod ? 'View All' : 'Filter By Dates'}
                                            </Button>
                                        </div>
                                        <div className="newBatchButtonWrapper buttonWrapper">
                                            <Button onClick={
                                                (e: any) => {
                                                    setTemplateVisit(undefined);
                                                    setOpenBatchCreateForm(true);
                                                }}
                                                className="newBatchButton"
                                                color="secondary"
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                disabled={!period}
                                            >
                                                Open Batch Create
                                            </Button>
                                        </div>
                                    </>

                                }

                                {previewVisits?.length && <div className="clearPreviewButtonWrapper buttonWrapper">
                                    <Button onClick={(e: any) => {
                                        setPreviewVisits(undefined);
                                    }
                                    }
                                        className="clearPreviewButton"
                                        color="secondary"
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        disabled={false}
                                    >
                                        Clear Preview
                                    </Button>
                                </div>}

                                {previewVisits?.length && <div className="newScheduleItemButtonWrapper buttonWrapper">
                                    <Button onClick={(e: any) => {
                                        saveBatch(previewVisits);
                                    }
                                    }
                                        className="newVisitButton"
                                        color="secondary"
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        disabled={false}
                                    >
                                        Save Preview Visits
                                    </Button>
                                </div>}
                            </div>
                        </ScheduleListGridActionWrapper>
                    }
                    {visitsResponse && visitsData && <DataGrid
                        ref={gridRef}
                        id="scheduleListGridContainer"
                        className="dx-datagrid-jt"
                        dataSource={visitsData} //{visitsData} {dataSource}
                        keyExpr="id"
                        hoverStateEnabled={true}
                        showBorders={true}
                        rowAlternationEnabled={true}
                        width="100%"
                        onRowPrepared={handleRowPrepared}
                        onRowUpdating={handleRowUpdating}
                        onRowInserting={handleCreate}
                        onInitNewRow={handleOnInitNewRow}
                    // onEditorPreparing={(e) => {
                    //   // we no longer need to disable completed option if no service reports.  Leave around in case we re-enable.
                    //     if (e.dataField == "status") {
                    //       if (!e.row?.data.service_reports_count) {

                    //         //@ts-ignore
                    //         e.editorOptions.dataSource = e.lookup.dataSource.map((d: any) => {
                    //           if (d.value === "completed") {
                    //             d.disabled = true;
                    //           }
                    //           return d
                    //         });

                    //       }
                    //     }
                    // }}
                    >

                        <Paging enabled={false} />

                        {
                            multipleContracts && <RowDragging
                                group={"supplierContractVisitsGroup"}
                                onAdd={moveVisitToThisSupplierContract}
                            />
                        }

                        <Editing allowDeleting={visitsResponse?.permissions?.DELETE} allowAdding={supplierService && visitsResponse?.permissions?.POST} allowUpdating={visitsResponse?.permissions?.POST} mode="cell" />
                        {visitListMeta && visitListColumns.map((column) => {

                            return <Column
                                key={column.dataField}
                                //dataField={column.dataField}
                                {...getColumnProps(column.dataField, visitListMeta)}
                                caption={column.caption ? column.caption : visitListMeta[column.dataField].label}
                                dataType={column.dataType ? column.dataType : visitListMeta[column.dataField]?.type}
                                format={column.format}
                                width={column.width}
                                sortOrder={column.sortOrder}
                                cellRender={column.cellRender ? column.cellRender : undefined}
                            />

                        }

                        )}

                        {/* <Column
            caption="Documents"
            calculateCellValue={calculateReportCounts}
            width={100}
          /> */}

                        <Column type="buttons" cssClass={"customActionsColumn dx-command-edit dx-command-edit-with-icons"}>
                            <DXButton
                                icon={'fa fa-arrow-right'}
                                visible={canEdit}
                                hint={'Schedule'}
                                onClick={(component: any) => {
                                    if (component.row?.data) {
                                        setTemplateVisit(component.row.data);
                                        setOpenBatchCreateForm(true);
                                        //setOpenBatchCreateForm(false);
                                    }
                                }}
                            />
                            <DXButton render={
                                (data: any) => {
                                    const thisOnClick = (e: any) => {
                                        const thisGrid = gridRef.current.instance;
                                        e.preventDefault();
                                        if (data.row.isExpanded) {
                                            thisGrid.collapseRow(data.row.key);
                                        } else {
                                            thisGrid.expandRow(data.row.key);
                                        }
                                    }
                                    return <NotesPreviewer
                                        notes={elipse({ s: capitalize(data.data?.notes), mode: 'middle', threshold: 500, blockSize: 200 })}
                                        onclick={thisOnClick}
                                        hideTooltip={data.row.isExpanded}
                                    />
                                }}
                            />
                            <DXButton name="delete" onClick={(component: any) => handleDelete(component.row.data)} visible={visitsResponse?.permissions?.DELETE} />
                        </Column>

                        {visitsResponse.putMeta && <MasterDetail
                            enabled={true}
                            component={(e) => <NotesDetail
                                meta={visitsResponse.putMeta}
                                row={e}
                                contractRef={contractRef}
                                portfolioId={portfolioId}
                                previewRow={previewVisitIds.current.includes(e.data.data.id) || false}
                                notesStore={notesStore.current}
                                dispatchRefreshContext={forceUpdate}
                                updateNotes={simpleUpdateVisit}
                            />}
                        />}

                    </DataGrid>
                    }
                </UsefulDXListWrapper>
            }
        </div>}
    </>
}

const SupplierServiceVisits = (props: SupplierServicesVisitListsProps) => {

    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 1);
    const [supplierContracts, setSupplierContracts] = useState<SupplierContract[]>();

    const getModalTitle = useCallback(() => {
        return () => <>
            {props.supplierService ? <div className="detail-modal--title">
                Visits
                {getDimTitle('by')}
                {props.supplierService?.contractor?.name}
                {getDimTitle('to')}
                {props.supplierService?.equipment_name}
            </div> :
                <div className="detail-modal--title">
                    New Specialist Visit Contract
                </div>
            }
        </>
    }, [props.supplierService]);

    const { setModalTitle, contractRef, supplierService, selectedPeriod, getSupplierServiceContractsResponse } = props;

    useEffect(() => {
        setModalTitle(getModalTitle)
    }, [supplierService, contractRef, getModalTitle, setModalTitle]);

    const assignSupplierContractsResponse = useCallback((response: any) => {
        setSupplierContracts(response.data);
    }, []);

    useEffect(() => {
        getSupplierServiceContractsResponse({
            start_date: selectedPeriod?.start_date,
            end_date: selectedPeriod?.end_date,
            callback: assignSupplierContractsResponse
        });
    }, [assignSupplierContractsResponse, getSupplierServiceContractsResponse, selectedPeriod]);


    return <>
        {supplierContracts && supplierContracts.map(x => {
            const theseProps = {
                supplierContract: x,
                ...props
            }
            return <Paper elevation={3} key={x.id}>
                <VisitList
                    refreshSignal={mustRefresh}
                    {...theseProps}
                    dispatchRefreshContext={forceUpdate}
                    multipleContracts={supplierContracts.length > 1}
                />
            </Paper>
        })
        }
    </>
}

export default SupplierServiceVisits;