import React from 'react';
import MuiButton from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { GeneralTooltip } from "components/Common/Components/InfoHint/InfoHint";

const Button = withStyles({
    root: {
        "&.Mui-disabled": {
            pointerEvents: "auto",
        }
    }
})(MuiButton);

const ButtonWithTooltip = ({ tooltipText, disabled, onClick, ...other }: any) => {
    const adjustedButtonProps = {
        disabled: disabled,
        component: disabled ? "div" : undefined,
        onClick: disabled ? undefined : onClick
    };
    return (
        <GeneralTooltip title={tooltipText}>
            <Button {...other} {...adjustedButtonProps} />
        </GeneralTooltip>
    );
};

export default ButtonWithTooltip