// GLOBAL CONSTANTS
// -----------------------------------

export const APP_COLORS = {
  primary: "#5d9cec",
  success: "#27c24c",
  info: "#23b7e5",
  warning: "#ff902b",
  danger: "#f05050",
  inverse: "#131e26",
  green: "#37bc9b",
  pink: "#f532e5",
  purple: "#7266ba",
  dark: "#3a3f51",
  yellow: "#fad732",
  "gray-darker": "#232735",
  "gray-dark": "#3a3f51",
  gray: "#dde6e9",
  "gray-light": "#e4eaec",
  "gray-lighter": "#edf1f2"
};

export const APP_MEDIAQUERY = {
  desktopLG: 1200,
  desktop: 992,
  tablet: 768,
  mobile: 480
};

export const dateFormatMonth = "MMM YYYY";
export const dateFormatDay = "YYYY-MM-DD";

export const PrintChartAndTableLabels = false;
