export const addElementsAtTheBeginningOfArrayFromTheEndOfArray = (
  destinationArray,
  neededElementsNumber,
  sourceArray,
  startSourceIndex
) => {
  for (let i = 0; i < neededElementsNumber; i++) {
    const index = startSourceIndex - i;
    if (index < 0) {
      break;
    }
    if (sourceArray[index] !== undefined) {
      destinationArray.unshift(sourceArray[index]);
    }
  }
};

export function getChoicesSortedByDisplayName(choices) {
  const sortedChoices = [...choices].sort(function(a, b) {
    var nameA = a.display_name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.display_name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  return sortedChoices;
}

export function sortByCreatedAt(choices, desc = false) {
  const sortedChoices = [...choices].sort(function(a, b) {
    var nameA = a.created_at; // ignore upper and lowercase
    var nameB = b.created_at; // ignore upper and lowercase
    if (nameA < nameB) {
      return desc ? 1 : -1;
    }
    if (nameA > nameB) {
      return desc ? -1 : 1;
    }
    return 0;
  });
  return sortedChoices;
}
