import React, { memo, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';

import {
  StyledFile,
  StyledWrapper,
  // StyledImageWrapper,
  StyledFileActionLabel,
  StyledFileActionContainer
} from './FileInputControlStyles';

import FileUploader from 'components/Common/Components/Input/FileUploader';

export interface FileInputControlConfig {
  label?: string;
  onUpload: { (file: any, progress: any): void };
  onClear: { (): void };
  uploader?: any;
  refreshSignal?: any;
}

export interface FileInputControlProps extends FileInputControlConfig {
  data: any;
  includeFullImageLink?: boolean;
  inputRef?: any;
}

const FileInputControl = ({
  onUpload,
  onClear,
  label = 'image',
  data,
  uploader,
  refreshSignal,
  includeFullImageLink,
  inputRef
}: FileInputControlProps) => {

  const [showImageUpload, setShowImageUpload] = useState(false);

  const renderLabel = (): string =>
    `<div class="filepond-label">Drag and drop your ${label} here or click</div> 
     <div class="filepond--label-action"> upload</div>`;

  const handleChangeAvatarClick = (): void => {
    setShowImageUpload(true);
  }

  const renderAvatar = (image: string) => (
    <StyledFile>
      {image && <img ref={inputRef} alt={label} src={`${image}`} />}
      <StyledFileActionContainer>
        <StyledFileActionLabel onClick={handleChangeAvatarClick}>
          <EditIcon />
          {image ? 'Change' : `Add ${label}`}
        </StyledFileActionLabel>
        {image
          ? (<>
            <StyledFileActionLabel onClick={() => onClear()}>
              <DeleteIcon />
              Remove
            </StyledFileActionLabel>
            {includeFullImageLink && <a href={data.full_size} target="_blank" rel="noopener">
              <StyledFileActionLabel>
                <VisibilityIcon />
                View
              </StyledFileActionLabel>
            </a>}
          </>

          )
          : null
        }
      </StyledFileActionContainer>
    </StyledFile>
  )

  const noImage = (): boolean => !data?.thumbnail;

  const renderFileUploader = () => (
    <StyledFile>
      {uploader ? uploader(onUpload, renderLabel())
        : <FileUploader
          labelIdle={renderLabel()}
          onUpload={onUpload}
          ready={true}
          refreshSignal={refreshSignal}
        />}
      {!noImage()
        ? (
          <StyledFileActionLabel
            marginTop="-.5rem"
            onClick={() => setShowImageUpload(false)}
          >
            <BlockIcon /> Cancel
          </StyledFileActionLabel>
        )
        : null
      }
    </StyledFile>
  )

  return (
    <>
      <StyledWrapper className='fileInputControl'>
        {!data?.thumbnail || showImageUpload
          ? renderFileUploader()
          : renderAvatar(data?.thumbnail)
        }
      </StyledWrapper>
    </>
  );
}

export default memo(FileInputControl);