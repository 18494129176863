import React, { useEffect, useState } from "react";
import { Paper, Divider } from "@material-ui/core";
import { useSelector } from "react-redux";

// Own
import Header from "../../../../Common/Components/GridHeader/GridHeader";
import ReactiveByCategoryChart from "components/ContractInFocus/Maintenance/Components/ReactiveByCategoryChart/ReactiveByCategoryChart";
import ReactiveByCategoryTable, { PrintableReactiveByCategoryTable } from "components/ContractInFocus/Maintenance/Components/ReactiveByCategoryTable/ReactiveByCategoryTable";
import NewPageWrapper from "components/Common/Components/PrintStyles/Print";
import { TableChartWrapper, DividerWrapper } from "../../Containers/MaintenanceTableChart/MaintenanceTableChartStyles";
import { ReportConfig } from "components/ContractInFocus/Maintenance/Interfaces/interactiveTable.interfaces";
import { NewReactiveCategory, DeleteReactiveCategory, UpdateReactiveCategory, } from "../../Interfaces/reactiveByCategory.interfaces";
import { ReportSubdivisionConfig, InteractiveTableChart } from "../../Interfaces/interactiveTable.interfaces";
import { ReactiveByCategoryTableChartData } from '../../Interfaces/reactiveByCategory.interfaces';
import * as contractSelectors from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import { getContractScopeParams } from 'helpers/Pipelines/contractScopeOperator';
import { reactiveByCategoryUpdate, toggleRelegateReactiveByCategory, reactiveByCategoryNew, reactiveByCategoryDelete, getReactiveByCategory } from "../../Services/ReactiveByCategory.services";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { getSubTitle } from "components/ContractInFocus/Components/ContractPrintTitle/ContractPrintTitle";
import { PrintChartAndTableLabels } from "components/Common/constants.js";
import { ContractPeriodDates } from "components/ContractInFocus/interfaces/contractInFocusActions.interfaces";
import { ContractPeriod } from 'components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface';
import { portfolioInFocusPeriod } from 'components/PortfolioInFocus/Selectors/portfolioInFocus.selectors';
import { simpleFetchReactiveTaskByCategoryPreFlightInfo } from "components/ContractInFocus/Maintenance/Services/ReactiveByCategory.services";
import { PreFlightListInfo } from 'components/Common/Interfaces/Entity.interface';
import { LocalReactiveByCategoryProps } from "components/ContractInFocus/Maintenance/Services/ReactiveByCategory.services";

// Styles
import { InlineWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

function populateNonMatchingCells(collectNonMatchingMonths: any, columns: any[] | undefined, reactiveByCategoryIssues: any, reactiveTotalTasks: any, associatedWith: string | number) {
    // console.log('reactiveByCategoryIssues: ', reactiveByCategoryIssues);
    // console.log('reactiveTotal tasks: ', reactiveTotalTasks);
    if (columns && reactiveTotalTasks && reactiveByCategoryIssues) {
        columns.map(
            (monthField: string) => {
                // console.log('monthField: ', monthField);
                let categoryTotal = reactiveByCategoryIssues.reduce(
                    (acc: number, category: any) => {
                        //return 0;
                        return acc + (category[monthField] ? category[monthField] : 0);
                    }, 0
                )
                const thisMonthTotalTaskValue = reactiveTotalTasks[monthField]?.value;
                let matches = (reactiveTotalTasks && reactiveTotalTasks[monthField]) ? (thisMonthTotalTaskValue || 0) == categoryTotal : true; // note == not === for auto type conversion
                // if(categoryTotal){
                //     console.log('categoryTotal: ', categoryTotal);
                //     console.log('matches: ', matches);
                // }
                if (!matches) {
                    const theseValues = collectNonMatchingMonths[monthField] || [];
                    theseValues.push(associatedWith);
                    collectNonMatchingMonths[monthField] = theseValues;
                }
            }
        )
    }
    return collectNonMatchingMonths
}

interface ReactiveByCategoryBaseProps {
    contract: SiteContract;
    portfolio?: HydratedPortfolio
    title: string;
    showTable?: boolean;
    showChart?: boolean;
    subdivision: ReportSubdivisionConfig;
    reactiveTotals?: InteractiveTableChart;
    reportConfig: ReportConfig;
    relegationPointer: string;
    comparisonField: string;
    tableSelector: string;
}

interface ReactiveByCategoryProps extends ReactiveByCategoryBaseProps {
}

const ReactiveByCategoryTableChart = ({
    contract,
    portfolio,
    title,
    showTable = true,
    showChart = true,
    subdivision,
    reactiveTotals,
    reportConfig,
    relegationPointer,
    comparisonField,
    tableSelector
}: ReactiveByCategoryProps) => {
    const selectContractPeriod = useSelector(contractSelectors.contractInFocusFocusedContractPeriodSelector);
    let selectedPeriod: ContractPeriodDates | ContractPeriod | undefined;
    const selectedPortfolioPeriod = useSelector(portfolioInFocusPeriod);
    if (portfolio) {
        selectedPeriod = selectedPortfolioPeriod;
    } else {
        selectedPeriod = selectContractPeriod;
    }
    const [dataset, setDataset] = useState<ReactiveByCategoryTableChartData>();
    const [preFlightGenerateReactiveCategoryInfo, setPreFlightGenerateReactiveCategoryInfo] = useState<PreFlightListInfo>();


    useEffect(() => {
        (contract || portfolio) && simpleFetchReactiveTaskByCategoryPreFlightInfo({
            contract,
            portfolio,
            reportConfig
        }).then((preFlightReactiveByCategoryInfo) => {
            setPreFlightGenerateReactiveCategoryInfo(preFlightReactiveByCategoryInfo);
        });
    }, [contract, portfolio, reportConfig]);

    const [nonMatchingMonths, setNonMatchingMonths] = useState(undefined);
    const setNonMatchingData = () => {
        const columns = dataset?.columnMeta.map((item: any) => item.dataField).slice(1, -1);//removing the last column because it's a calculated summary column, not relevant
        // nb we could also grab these from reactiveTotalTasksCompleted but we'd need to do some sort of filtering first, e.g. checking that it resolved to a date 
        // as %b %Y
        const collectNonMatchingMonths: any = {};
        let reactiveTotalsByCategoryData = dataset?.data.table.filter((x: any) => !!x.header_pointer);
        let reactiveTotalTasks = reactiveTotals?.table;
        if (portfolio) {
            reactiveTotalTasks = reactiveTotalTasks?.filter((x: any) => !!x.header_pointer);
        }
        reactiveTotalTasks = reactiveTotalTasks?.filter(x => {
            return x.key == comparisonField
        }
        );
        const associatedWithList = reactiveTotalsByCategoryData ? Array.from(new Set(reactiveTotalsByCategoryData?.map((x: any) => x.associated_with))) : [];
        associatedWithList.map((contractId: any) => {
            let theseReactiveByCategoryIssues = reactiveTotalsByCategoryData.filter((x: any) => x.associated_with === contractId);
            let theseReactiveTotalTasks = reactiveTotalTasks?.filter((x: any) => x.associated_with === contractId)[0];
            populateNonMatchingCells(collectNonMatchingMonths, columns, theseReactiveByCategoryIssues, theseReactiveTotalTasks, contractId);
        })
        return collectNonMatchingMonths;
    }

    const reloadData = () => {
        const params = getContractScopeParams(selectedPeriod)
        if (selectedPeriod && (contract || portfolio)) {
            return getReactiveByCategory(
                {
                    reportConfig,
                    contract,
                    portfolio,
                    contractPeriod: selectedPeriod as ContractPeriod,
                    subdivision: subdivision.dataKey,
                    params
                }).subscribe(response => {
                    //this needs to retrieve the data over the whole contract period, not just snapshot month
                    if (typeof response === 'object') {
                        setDataset(response)
                    }
                });
        }
    };

    useEffect(() => {
        reloadData();
        /* eslint-disable react-hooks/exhaustive-deps*/
    }, [selectContractPeriod, selectedPeriod]);

    useEffect(() => {
        setNonMatchingMonths(setNonMatchingData());
        /* eslint-disable react-hooks/exhaustive-deps*/
    }, [dataset, reactiveTotals]);

    const isDataReady = (): boolean => !!dataset && !!dataset?.data?.table;

    const handleNewRecord = (
        payload: NewReactiveCategory,
    ) => {
        const thisContract = contract || payload.contract;
        if (thisContract) {
            reactiveByCategoryNew({
                ...payload,
                contract: thisContract,
                config: reportConfig,
                subdivision: subdivision.dataKey,
            }, portfolio).subscribe(() => reloadData());
        }
    };

    const handleUpdateRecord = (payload: UpdateReactiveCategory) => {
        if (contract || portfolio) {
            payload.config = reportConfig;
            if (!portfolio) {
                payload.contract = contract
            }
            reactiveByCategoryUpdate(payload).subscribe(() => reloadData());
        }
    };

    const handleDeleteRecord = (payload: DeleteReactiveCategory) => {
        if (contract || portfolio) {
            reactiveByCategoryDelete({
                ...payload,
                config: reportConfig,
                contract: contract,
                portfolio
            }).subscribe(() => reloadData());
        }
    };

    const handleCategoryArchive = ({
        portfolio,
        contract,
        reactive_category_option,
        id
    }: LocalReactiveByCategoryProps): void => {
        toggleRelegateReactiveByCategory({ endpoint: relegationPointer, contract, portfolio, reactive_category_option, id }).subscribe(() => reloadData());
    }

    return (
        <>
            {(showChart || showTable)
                ? <TableChartWrapper>
                    <NewPageWrapper breakAfter>
                        <InlineWrapper>
                            <Paper elevation={3}>

                                {!!title &&
                                    <Header
                                        id='Print'
                                        testId='table-chart-header'
                                        className={`print-section-indicator ${PrintChartAndTableLabels ? '' : 'no-print'}`}
                                        level={1}
                                        title={title}
                                    />
                                }

                                {isDataReady() ? (
                                    <>
                                        {showChart && dataset && Object.keys(dataset.data.chart).length
                                            ? <>
                                                <Header
                                                    testId='chart-header'
                                                    title={`${reportConfig.caption}`}
                                                    subTitle={getSubTitle(dataset.meta)}
                                                    className={`${PrintChartAndTableLabels ? '' : 'no-print'}`}
                                                />
                                                <ReactiveByCategoryChart data={dataset?.data.chart} />
                                                <DividerWrapper className='maintenance-divider'>
                                                    <Divider />
                                                </DividerWrapper>
                                            </>
                                            : null}

                                        {showTable && dataset
                                            ? <>
                                                <Header
                                                    testId='table-header'
                                                    title={`${reportConfig.caption}`}
                                                    subTitle={getSubTitle(dataset.meta)}
                                                    className={`${PrintChartAndTableLabels ? '' : 'no-print'}`}
                                                />
                                                <ReactiveByCategoryTable
                                                    testId='reactive-by-category-table'
                                                    reactiveCategoryOptions={dataset.reactiveCategoryOptions}
                                                    updateRecord={handleUpdateRecord}
                                                    newRecord={handleNewRecord}
                                                    deleteRecord={handleDeleteRecord}
                                                    preFlightGenerateReactiveCategoryInfo={preFlightGenerateReactiveCategoryInfo}
                                                    data={dataset.data.table}
                                                    portfolio={portfolio}
                                                    contract={contract}
                                                    matrix={dataset.data.matrix}
                                                    columnMeta={dataset.columnMeta}
                                                    meta={dataset.meta}
                                                    privileges={dataset?.permissions}
                                                    toggleCategoryArchive={handleCategoryArchive}
                                                    reactiveTotals={reactiveTotals}
                                                    nonMatchingMonths={nonMatchingMonths}
                                                    selectedPeriod={selectedPeriod}
                                                    tableSelector={tableSelector}
                                                />
                                            </>
                                            : null}
                                    </>
                                ) : null}
                            </Paper>
                        </InlineWrapper>
                    </NewPageWrapper>
                </TableChartWrapper>
                : null}
        </>
    );
};

export default ReactiveByCategoryTableChart;
