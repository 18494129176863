import React, { memo, useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from '@material-ui/core/InputLabel';
import { MenuItem } from "@material-ui/core";

// Own
import { useSubHeadingNavStyles } from "../../Containers/ContractSubHeading/ContractSubHeadingStyles";
import { Snapshot } from "components/ContractInFocus/interfaces/contractInFocus.interfaces";
import { recordDate, saveDateFormat } from "../../../../Common/Utils/Dates";
import { currentHistory } from "constants/snapshots.constants";
import { Dictionary } from "components/Common/Interfaces/Entity.interface";
import { dictToArray } from "services/API/API.helper";

interface ContractHistoryDropdownProps {
  history: Dictionary<Snapshot>;
  selectedHistory?: Snapshot;
  onSelect: (history: string) => void;
  isCurrentPeriod?: boolean;
  updateSnapshotState: () => void;
}

const ContractHistoryDropdown = ({
  history,
  selectedHistory,
  onSelect,
  isCurrentPeriod,
  updateSnapshotState
}: ContractHistoryDropdownProps) => {
  const classes = useSubHeadingNavStyles();

  const [orderedHistory, setOrderedHistory] = useState<Snapshot[]>([]);

  useEffect(() => {
    setOrderedHistory(dictToArray(history).sort((a: Snapshot, b: Snapshot) => {
      const snapshotANum = +a.frozen_for.replace(/[^0-9]/g, '');
      const snapshotBNum = +b.frozen_for.replace(/[^0-9]/g, '');

      if (snapshotANum < snapshotBNum) {
        return -1;
      }

      if (snapshotANum > snapshotBNum) {
        return 1;
      }

      return 0;
    }));
  }, [history]);

  const getSelectHistory = (frozen_for: string): string | undefined => {
    const snapshotKey = Object.keys(history).find(
      (key) => history[key].frozen_for === frozen_for
    );
    const foundFrozenFor = snapshotKey
      ? history[snapshotKey].frozen_for
      : undefined;
    return frozen_for === currentHistory ? currentHistory : foundFrozenFor;
  };

  const handleSelectContract = (element: any) => {
    const historyItem = getSelectHistory(element.target.value);
    historyItem && onSelect(historyItem);
  };

  const handleOnOpen = () => {
    updateSnapshotState();
  }

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel shrink htmlFor="mainVersionSelector-native-label">
          Version:
        </InputLabel>
        <Select
          value={selectedHistory?.frozen_for ?? currentHistory}
          onChange={(value: any) => handleSelectContract(value)}
          onOpen={handleOnOpen}
          id="mainVersionSelector"
        >
          <MenuItem
            key={0}
            value={currentHistory}
            className={classes.selectItem}
          >
            {isCurrentPeriod ? currentHistory : "Full Period"}
          </MenuItem>

          {orderedHistory.map((item, idx) => (
            <MenuItem
              key={item.id}
              value={saveDateFormat(item.frozen_for)}
              className={classes.selectItem}
            >
              {recordDate(item.frozen_for)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default memo(ContractHistoryDropdown);
