import { ContractPeriodDates } from 'components/ContractInFocus/interfaces/contractInFocusActions.interfaces';
import { ContractPeriod } from 'components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface';
import { getMonthsBetween } from "components/Common/Utils/Dates.js";

export const parseContractPeriodUrl = (contractPeriodQueryParam: string | null): ContractPeriodDates | undefined => {
  if (!contractPeriodQueryParam) {
    return undefined;
  }
  try {
    const [start_date, end_date] = contractPeriodQueryParam.split('_');
    return { start_date, end_date, months: getMonthsBetween(start_date, end_date) };
  } catch (e) {
    return undefined;
  }
}

export const transformContractPeriodToDates = (contractPeriod: ContractPeriod): ContractPeriodDates | undefined =>
  ({ end_date: contractPeriod.end_date, start_date: contractPeriod.start_date, months: getMonthsBetween(contractPeriod.start_date, contractPeriod.end_date) });

