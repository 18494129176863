import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2px;
  margin-left: 2px;
  height: 20px;
  position: relative;
  margin-bottom: 10px;
  &.warn:after {
    content: "";
    position: relative;
    width: 15px;
    left: 0;
    border-bottom: 3px solid red;
    display: block;
    margin-top: 3px;
  }
  &.info:after {
    content: "";
    position: relative;
    width: 15px;
    left: 0;
    border-bottom: 3px solid green;
    display: block;
  }
`;

export const FlexCenterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media print {
    display: inline;
  }
`;
