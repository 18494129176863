import React, { useEffect, useState, useRef, useReducer, useCallback } from "react";
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

// Own
import API from 'services/API/API.js';
import CommonAPIService, { commonUpdateGridRow } from "components/ContractInFocus/Services/commonAPI.services";
import { DataGridMeta } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';
import {
    DataGridEvent,
    DataGridUpdateEvent,
} from "interfaces/datagrid.interfaces";
import { gridMetaInitialState } from 'components/ContractInFocus/Models/Grid';
import { hasTaskFilteredDatagridColumns, getHASTaskFilterExpForGrid } from "components/AdminPanel/HAS/Models/HASTaskDetail.model";
import { AdminFilteredDataGrid } from "components/AdminPanel/Containers/AdminFilteredDataGrid";
import HASTaskAdminDetailView from "components/AdminPanel/HAS/Containers/HASTaskAdminDetailView/HASTaskAdminDetailView";

import { HASTask } from "components/AdminPanel/HAS/Interfaces/HASTaskInterfaces";
import { ADMIN_HAS_TASKS_ROUTE } from "services/API/common/globalAPIs";
import SaveOnRowCollapseWrapper from "components/Common/Components/GridPatterns/SaveOnRowCollapseWrapper";

import 'components/AdminPanel/HAS/Containers/HASTaskAdminDetailView/HASDetail.scss';

interface HASTaskMasterViewProps {
    panelId: string;
    masterViewSharedSpace?: React.MutableRefObject<any>;
    onRowCollapsing?: (e: any) => void;
    onRowExpanding?: (e: any) => void;
}

// https://supportcenter.devexpress.com/ticket/details/t410004/dxdatagrid-how-to-display-multiple-values-in-a-lookup-column-using-dxtagbox

// How to handle the e.cancel & promises
// https://supportcenter.devexpress.com/ticket/details/t581713/dxdatagrid-how-to-use-promise-all-for-the-cancel-parameter-of-the-onrowupdating-event

const HasTaskMasterView: React.FC<HASTaskMasterViewProps> = ({
    panelId,
    masterViewSharedSpace,
    onRowCollapsing,
    onRowExpanding
}) => {

    const [metadata, setMetadata] = useState<DataGridMeta>(gridMetaInitialState);
    const getEndpointRef = useRef(() => ADMIN_HAS_TASKS_ROUTE)
    const [dataSource, setDataSource] = useState<any>();

    const handleUpdate = useCallback((e: DataGridUpdateEvent) => {
        // @ts-ignore
        e.cancel = CommonAPIService.update<HASTask>(getEndpointRef.current, '', e.oldData.id, e.newData).then((response) => {
            commonUpdateGridRow({
                dataSource,
                key: e.newData.id,
                changes: e.newData
            })
        });
    }, [dataSource]);

    const handleDelete = useCallback((e: DataGridEvent): void => {
        // @ts-ignore
        e.cancel = CommonAPIService.del<HASTask>(getEndpointRef.current, undefined, e.data.id).then(() => {
            dataSource.reload();
            return false;
        })
    }, [dataSource]);

    useEffect(() => {
        const custom = new CustomStore({
            key: "id",
            load: () => CommonAPIService.getAll<HASTask>(
                getEndpointRef.current,
                setMetadata,
            ),
            insert: values => API.post(getEndpointRef.current(), values),
            // @ts-ignore
            remove: key => { }, // we need a function just so dxdatagrid doesn't get upset after running our delete function
            // which is passed into the AdminDataGrid and run onRemove //(id) => CommonAPIService.del<HASTask>(getEndpointRef.current, undefined, id), //key => { },
            update: (id, values) => CommonAPIService.update<HASTask>(getEndpointRef.current, '', id, values)
        });

        setDataSource(
            new DataSource({
                store: custom
            })
        );

    }, []);

    const thisSharedSpace = useRef<any>({});
    const sharedSpace = masterViewSharedSpace || thisSharedSpace;
    sharedSpace.current.dataSource = dataSource;

    return (
        <>
            {metadata && <AdminFilteredDataGrid
                data={dataSource}
                meta={metadata.POSTMeta}
                putMeta={metadata.PUTMeta}
                permissions={metadata.privileges}
                editMode="cell"
                recordmeta={metadata.POSTMeta}
                panelId={panelId}
                masterView
                masterViewSharedSpace={sharedSpace}
                dataTestId="h-a-s-tasks-master-view"
                detailView={HASTaskAdminDetailView}
                getDatagridColumns={hasTaskFilteredDatagridColumns}
                getPreFilterExp={getHASTaskFilterExpForGrid}
                onDelete={handleDelete}
                onUpdate={handleUpdate}
                dataSourceInserts
                onRowCollapsing={onRowCollapsing}
                onRowExpanding={onRowExpanding}
                gridDataSource={dataSource}
            />}
        </>

    );
};

// HasTaskMasterView.whyDidYouRender = true;

export default SaveOnRowCollapseWrapper(HasTaskMasterView, "save?");
// export default HasTaskMasterView;
