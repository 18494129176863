import { Dictionary, FieldMetaGroup } from "../../Common/Interfaces/Entity.interface";
import { arrayToDict } from "services/API/API.helper";
import { APIPrivileges } from "services/Interface/Interface";

export const retainFocusedRecord = (data: any, dictionary: Dictionary<any>, addToCurrentData: boolean = false) => {
  const foundFocusedId: any = Object.keys(data || {}).find(key => data[key].focused);

  const newDict = addToCurrentData ? { ...data, ...dictionary } : dictionary;

  const newData = foundFocusedId
    ? {
      ...newDict,
      [+foundFocusedId]: data[foundFocusedId]
    }
    : {
      ...newDict
    }

  return newData;
}

export const getLookupArray = (data: object[], lookupCallBack: (record: any) => object) => {
  const lookupArray = data.map((record: object) => lookupCallBack(record));
  const lookup = arrayToDict(lookupArray);
  return lookup
}


interface FetchEntityStateProp {
  state: any;
  entityDictionary: Dictionary<object>;
  meta: FieldMetaGroup;
  putMeta?: FieldMetaGroup;
  lookup?: Dictionary<object>;
  permissions: APIPrivileges;
}

export const fetchEntityState = (props: FetchEntityStateProp) => {
  const entityState = {
    ...props.state,
    data: props.entityDictionary,
    // meta: {
    //   ...props.state.meta,
    //   POST: props.meta,
    //   PUT: props.putMeta
    // },
    // permissions: props.permissions,
  }
  if (props.meta) {
    entityState.meta = {
      ...props.state.meta,
      POST: props.meta,
      PUT: props.putMeta
    }
  }
  if (props.permissions) {
    entityState.permissions = props.permissions;
  }
  if (props.lookup) {
    entityState.lookup = props.lookup;
  }
  return entityState;
};

export const fetchReducer = (state: any, payload: any, key: string, getLookupCB?: any) => {
  try {
    let lookup;
    const dictionary = arrayToDict(payload[key].data);
    if (getLookupCB) {
      lookup = getLookupArray(payload[key].data, getLookupCB);
    }

    return fetchEntityState({
      entityDictionary: dictionary,
      lookup,
      meta: payload[key].meta,
      putMeta: payload[key].putMeta,
      permissions: payload[key].permissions,
      state
    });
  } catch (e) {
    console.error('Key', key);
    console.error('Payload', payload);
    console.error('Callback ', getLookupCB);
    throw new Error(`Unable to fetch reduce using key ${key}`);
  }
}