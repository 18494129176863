import React, { useState, useEffect, useRef } from 'react';

import API from 'services/API/API';
import { getDataAndMeta } from "services/API/API.helper";
import { getAccidentFieldInfoRoute, simpleGetAccidentFieldInfo, simpleGetRiddorFieldInfo, getNearMissFieldInfoRoute, simpleGetNearMissFieldInfo, getRiddorFieldInfoRoute } from "components/AdminPanel/HAS/HASIncidentServices";
import FieldInfoControls from "components/ContractInFocus/HAS/HASFieldInfoSettingControls";
import { IncidentFieldType } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";
import { FieldInfoManagementProps } from "components/ContractInFocus/HAS/HASIncidentForm";
import { toTitleCase } from "helpers/String/String.helper";


import CommentBlock, {
    generateGetFieldComments,
    generateSubmitFieldComment,
    CreateFieldInfo,
    MentionablePerson,
    FieldInfo,
    SubmitFieldCommentType,
    GetFieldCommentsType,
} from "components/Common/Components/FormFieldComments/FormFieldComments";

export interface RenderFieldInfo extends CreateFieldInfo {
    meta?: FieldMetaGroup;
    fieldType: IncidentFieldType;
    formFieldsConfig: FieldsFormConfig;
    setFormFieldsConfig?: React.Dispatch<React.SetStateAction<FieldsFormConfig | undefined>>;
    getFormFieldsConfig?: ({ fieldInfoListData, fieldInfoListMeta, updateMode }: FieldInfoManagementProps) => FieldsFormConfig;
    fieldInfoListRef: React.MutableRefObject<any>;
    disableDxNavKeys?: React.MutableRefObject<boolean>;
    containing_tab?: string;
    initiallySelectedComment?: React.MutableRefObject<string | undefined>;
    incidentMentionablePersons?: React.MutableRefObject<MentionablePerson[] | undefined>;
}

// This component has to take FieldInfoProps as a props attribute
// Use generateSubmitFieldComment and generateGetFieldComments to generate the appropriate callbacks and then return an instance of CommentBlock
// This component is passed into the IncidentForm and we could use a separate component for near misses and accidents for instance, but because
// they are currently so similar, we'll just adapt this component based on a fieldType argument.

const HASFieldInfo: React.FC<RenderFieldInfo> = ({
    id,
    issue_field,
    parentId,
    canCreateFieldInfo,
    fieldLabel,
    meta,
    requires_resolution,
    field_redacted,
    fieldType,
    formFieldsConfig,
    setFormFieldsConfig,
    getFormFieldsConfig,
    fieldInfoListRef,
    disableDxNavKeys,
    containing_tab,
    initiallySelectedComment,
    incidentMentionablePersons
}) => {

    const getComments = useRef<GetFieldCommentsType>();
    const createComment = useRef<SubmitFieldCommentType>();
    const [fieldInfo, setFieldInfo] = useState<FieldInfo>();

    // console.log('fieldType: ', fieldType);

    useEffect(() => {
        const updatePaths = (fieldInfoId: string) => {
            let thisCommentsEndpoint = `${fieldType === "near_miss" ? "near-miss" : fieldType}-field-info/${fieldInfoId}/comments/`;
            getComments.current = generateGetFieldComments(thisCommentsEndpoint);
            createComment.current = generateSubmitFieldComment({ url: thisCommentsEndpoint, field_info_id: fieldInfoId, containing_tab });
        }
        if (!id) { // this means that the field info object does not already exist when this component was instantiated
            // here we have to create the field_info record
            const createFieldInfoEndpoint = fieldType === "accident" ? getAccidentFieldInfoRoute(parentId) :
                fieldType === "near_miss" ? getNearMissFieldInfoRoute(parentId) : getRiddorFieldInfoRoute(parentId);
            let payload: any;
            if (fieldType == "accident") {
                payload = {
                    issue_field: issue_field,
                    accident: parentId
                }
            } else if (fieldType == "near_miss") {
                payload = {
                    issue_field: issue_field,
                    near_miss: parentId
                }
            } else {
                payload = {
                    issue_field: issue_field,
                    riddor: parentId
                }
            }
            canCreateFieldInfo && API.post(createFieldInfoEndpoint, payload).then((rawResponse) => {
                const response = getDataAndMeta(rawResponse)
                updatePaths(response.data.id);
                setFieldInfo(response.data)
            })

        } else {
            updatePaths(id);
            let getFieldInfo: any;
            if (fieldType === "accident") {
                getFieldInfo = simpleGetAccidentFieldInfo
            } else if (fieldType === "near_miss") {
                getFieldInfo = simpleGetNearMissFieldInfo
            } else {
                getFieldInfo = simpleGetRiddorFieldInfo
            }
            getFieldInfo({ parentId: parentId, fieldInfoId: id }).then((response: any) => {
                setFieldInfo(response.data)
            })
        }
    }, [id, parentId, issue_field, canCreateFieldInfo, fieldLabel, requires_resolution, field_redacted, fieldType, containing_tab]);



    return <>
        <h4 className="title">{toTitleCase(fieldLabel)}</h4>
        {fieldInfo && meta && canCreateFieldInfo &&
            <FieldInfoControls
                parentId={parentId}
                data={fieldInfo}
                canWrite={canCreateFieldInfo}
                meta={meta}
                fieldType={fieldType}
                formFieldsConfig={formFieldsConfig}
                setFormFieldsConfig={setFormFieldsConfig}
                getFormFieldsConfig={getFormFieldsConfig}
                fieldInfoListRef={fieldInfoListRef}
            />
        }
        {fieldInfo && getComments && createComment && <CommentBlock
            data={fieldInfo}
            getComments={getComments}
            createComment={createComment}
            disableDxNavKeys={disableDxNavKeys}
            incidentMentionablePersons={incidentMentionablePersons}
            initiallySelectedComment={initiallySelectedComment}
        />}
    </>
}

export default HASFieldInfo;