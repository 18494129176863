import React, { MouseEvent, useState } from 'react';
import { Avatar, MenuItem, Menu } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { avatarDetailsSelector } from 'components/Profile/Selectors/Profile.selector';
import { authenticationService } from '../../../../services/Auth/AuthService';
import { useHeaderAvatarStyles } from './HeaderAvatarStyles';
import { getProfile } from 'components/Profile/Actions/Profile.actions';

interface HeaderAvatarProps {
  visible?: boolean;
}

const HeaderAvatar: React.SFC<HeaderAvatarProps> = ({
  visible = true
}) => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const classes = useHeaderAvatarStyles();

  const dispatch = useDispatch();

  const avatarDetails = useSelector(avatarDetailsSelector);

  const userAvatarSource =
    avatarDetails && avatarDetails.avatar && avatarDetails.avatar.thumbnail
      ? avatarDetails.avatar.thumbnail
      : undefined;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const logout = () => {
    authenticationService.logout();
    handleClose();
  };

  const getSupport = () => {
    window.open('https://forms.office.com/e/QxQjQjPuXC', '_blank');
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showProfile = () => {
    dispatch(getProfile());
    handleClose();
  };

  return <>
    {visible &&
      <>
        <Avatar
          onClick={(event: MouseEvent<any>) => handleClick(event)}
          src={userAvatarSource}
          alt={`${avatarDetails.first_name} ${avatarDetails.last_name}`}
          className={classes.avatar}
        />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={showProfile}>Profile</MenuItem>
          <MenuItem onClick={getSupport}>Contact Support</MenuItem>
          <MenuItem onClick={logout}>Logout</MenuItem>
        </Menu>
      </>
    }
  </>;
}

export default HeaderAvatar;