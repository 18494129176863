import React, { useReducer, useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Own
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { PreFlightListInfo } from "components/Common/Interfaces/Entity.interface";
import DocumentsUploader from "components/Common/Components/DocumentsUploader/DocumentUploader";
import { GeneralDocsGridWrapper } from 'components/SiteDocuments/Components/ContractDocumentsViewGrid/ContractDocumentGridStyles';
import { profileOrganisationSelector } from 'components/Profile/Selectors/Profile.selector';
import { IHASRiddor } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";

interface RiddorReportUploaderProps {
    riddor: IHASRiddor;
    setData: (data: any) => void;
    preFlightInfo: PreFlightListInfo | undefined;
}

export const getRiddorReportBaseRoute = (riddor: IHASRiddor) => {
    return `h-a-s-riddor/${riddor.id}/submitted-report/`;
}

const RiddorReportUploader = ({ riddor, setData, preFlightInfo }: RiddorReportUploaderProps) => {
    const [show, setShow] = useState(false);
    useEffect(() => {
        setTimeout(() => { setShow(true) }, 2000); // basically to stop it flickering into view before the rest of the riddor form loads
    }, [])

    const riddorReportBasePath = getRiddorReportBaseRoute(riddor);
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 0);
    const selectProfileOrganisationFromState = useSelector(profileOrganisationSelector);
    const refresh = useCallback(forceUpdate, [forceUpdate]);

    const getFieldFormConfig = useCallback((): FieldsFormConfig => {
        return {
            'reference': {
                forceRequired: true
            },
            'riddor': {
                hidden: true,
                defaultValue: riddor.id
            },
            'author_organisation': {
                hidden: true,
                defaultValue: selectProfileOrganisationFromState
            },
        }
    }, [riddor, selectProfileOrganisationFromState]);

    const fieldsConfig = getFieldFormConfig();

    return <GeneralDocsGridWrapper>

        {!riddor.submitted_report && preFlightInfo && riddorReportBasePath && show &&
            <>
                <h3 className='riddor-upload-title'>Once submitted, upload the RIDDOR report below...</h3>
                <DocumentsUploader
                    urlContext={riddorReportBasePath}
                    fieldConfigs={fieldsConfig}
                    preFlightInfo={preFlightInfo}
                    gridId="RiddorReportUploader"
                    reset={refresh}
                    zIndex={30000}
                    setData={setData}
                    showIfNotEditable
                />
            </>
        }
    </GeneralDocsGridWrapper>
}

export default RiddorReportUploader;