import * as actions from "components/AdminPanel/ContractPeriods/Actions/ContractPeriods.actions";

import { AdminNormState } from "../../Interfaces/Admin.state";
import { ContractPeriod } from "components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface";

const initialState = {
  data: {},
  meta: {},
  permissions: {
    PUT: false,
    POST: false,
    DELETE: false
  } // not record meta and only gets set once
}

export type ContractPeriodState = AdminNormState<ContractPeriod>;

const contractPeriodsReducer = (state: ContractPeriodState = initialState, action: any) => {
  switch (action.type) {
    case actions.FETCH_CONTRACT_PERIOD.reducer: {
      const newMeta = action.meta || state.meta;
      const newPermissions = action.permissions || state.permissions;
      return {
        ...state,
        data: {
          ...state.data,
          ...action.contractPeriods
        },
        meta: {
          ...newMeta
        },
        permissions: {
          ...newPermissions
        }
      };
    }

    case actions.DELETE_CONTRACT_PERIOD.reducer: {
      delete state.data[action.id];
      return state;
    }

    case actions.CREATE_CONTRACT_PERIOD.reducer:
    case actions.UPDATE_CONTRACT_PERIOD.reducer: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.contractPeriod.id]: action.contractPeriod
        }
      };
    }

    default:
      return state;
  }
};

export default contractPeriodsReducer;
