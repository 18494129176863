import { switchMap, map, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";

// Own
import { APIR } from "services/API/API";
import { unWrapListDataAndMeta, unWrapDataAndMetaAndPUTMeta } from "services/API/API.helper";
import { NOTIFICATION_ERROR } from 'components/Notification/Constants/constants';

import * as fromNotificationActions from 'components/Notification/Actions/Notification.actions';
import * as fromOrganisationActions from "../Actions/Organisation.actions";

const ROUTE = '/organisations/';

export const fetchOrganisationsEpic = (action$: any) => action$.pipe(
  ofType(fromOrganisationActions.FETCH_ORGANISATIONS.start),
  switchMap(() =>
    APIR.get(`${ROUTE}?format=json`).pipe(
      unWrapDataAndMetaAndPUTMeta(),
      map(({ industries, ...organisations }: any) => fromOrganisationActions.fetchOrganisationsSuccess(organisations)),
      catchError((error) => of(fromNotificationActions.addNotification({ message: error, type: NOTIFICATION_ERROR })))
    )
  )
);

export default [
  fetchOrganisationsEpic,
];
