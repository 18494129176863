import React, { useState, Suspense, useCallback, useEffect } from "react";
import { Switch } from "react-router-dom";
import { useSelector } from "react-redux";

import PageLoader from "components/Common/PageLoader.js";
import Sites from "components/Sites/Sites.js";
import Portfolios from "components/Portfolios/PortalCardList";
import { PrivateRoute } from "services/Auth/PrivateRoute";
import { waitFor } from "../../router.helper";
import IndexSideBar from "components/IndexPage/IndexSidebar";
import { useGetLeftMargin } from "components/Layout/Common/SideBar/SidebarHelpers";
import { indexSideBarCollapsedSelector, indexSideBarHiddenSelector } from "components/Profile/Selectors/Profile.selector";
import {
    portfoliosLengthSelector
} from "components/Portfolios/Selectors/Portfolio.selectors";
import {
    sitesLengthSelector
} from "components/Sites/Selectors/Sites.selectors";


const IndexPageWrapper = (props: any) => {
    let sideBarHidden = useSelector(indexSideBarHiddenSelector);
    let sideBarCollapsed = useSelector(indexSideBarCollapsedSelector);
    const sitesLength = useSelector(sitesLengthSelector);
    const portfoliosLength = useSelector(portfoliosLengthSelector);
    const [thisSideBarHidden, setThisSideBarHidden] = useState(sideBarHidden);

    // we use sitesLength and portfoliosLength here because we want it to apply before 
    // hasNoSites and hasNoPortfolios will have resolved.  Check whether this is better
    // for UX though

    useEffect(() => {
        if (!sitesLength || !portfoliosLength) {
            setThisSideBarHidden(true);
        } else {
            setThisSideBarHidden(sideBarHidden);
        }
    }, [sitesLength, portfoliosLength, sideBarHidden])


    const leftMargin = useGetLeftMargin(thisSideBarHidden, sideBarCollapsed);
    return (
        <>
            <div style={{ marginLeft: leftMargin, transition: "margin-left, 0.25s" }}>
                <Switch>
                    <PrivateRoute
                        path={`/sites`}
                        component={waitFor(Sites)}
                    />
                    <PrivateRoute
                        path={`/portfolios`}
                        component={waitFor(Portfolios)}
                    />
                </Switch>
            </div>
        </>


    )
}

const IndexPage = () => {
    return (
        <Suspense fallback={<PageLoader />}>
            <IndexSideBar />
            <IndexPageWrapper />
        </Suspense>
    )
};

export default IndexPage;