import { isEqual } from "lodash";
import DataSource from "devextreme/data/data_source";

export const getPeopleFormFieldsAdjuster = (
  organisationsData,
  lastJobTitleOptions,
  lastAdminLevelOptions,
  lastGlobalContractLevelOptions,
  parentType
) => {
  // this will return a callback that will be passed to the admindatagrid and be called when field values change - it will be passed the datagrid event object
  return e => {
    if (e.dataField === "job_title" && e.parentType === parentType) {
      //e.editorOptions.dataSource = dataSource;
      // What I need to do next is:
      // 1) Return the organisation descriptive 'type' on the organisation object itself (to avoid an additional look up on the 'relation' id for comparison purposes
      // 2) Create another branch of state which holds all the job title objects on the FE (right now we just have 'key', 'value' pairs as 'choice' meta on the people state but we need the 'group' adding to that)
      // 3) set that new state as the source for the job title field
      // 4) Filter field based on the organisation choice.
      // 5) possibly use the 'cascading lookup' approach shown on dev extreme, if not just do the necessary here
      // 6) Apply to the edit dataform (AdminImageForm - our detail view)
      // 7) Abstract this to a call back passed to the admin datagrid from the appropriate 'master' view
      // 8) Abstract the item values to call 'SetCellValue' on to some info passed to this view too (see note in form col config below)
      // Those should all be pretty straightforward now...
      let org = e.row?.data.organisation;
      let organisation = org
        ? organisationsData.data.filter(x => x.id === org)[0]
        : null;
      //let organisationRelationName = organisation?.relation_display_name;
      let organisationRelationName = organisation?.relation_choice_value;
      if (organisationRelationName) {
        //const dataSource = new DataSource(e.editorOptions.items.slice(1, 3)); // just a placeholder to demo filtering..
        // best to refilter in order to check that the options have not changed... also not worth doing equality comparisons
        // (e.g. if (!isEqual(lastJobTitleOptions.current, newOptions)) because even if they are the same, if other field changes
        // trigger setCellValue of any kind, the dataSource of this field will be reset, so this just ensures it's always reset to
        // what it should be... perhaps memo-ize but that might be about as much work as the filter here...
        const newOptions = e.editorOptions.dataSource.filter(
          x => x.group === organisationRelationName
        );
        e.editorOptions.dataSource = new DataSource(newOptions);
        lastJobTitleOptions.current = newOptions;
      }
    }
    if (
      (e.dataField === "general_admin_level" ||
        e.dataField === "h_a_s_admin_level") &&
      e.parentType === parentType
    ) {
      let org = e.row?.data.organisation;
      let organisation = org
        ? organisationsData.data.filter(x => x.id === org)[0]
        : null;
      //let organisationRelationName = organisation?.relation_display_name;
      let organisationRelationName = organisation?.relation_choice_value;
      if (
        organisationRelationName &&
        organisationRelationName !== "self" &&
        e.value !== "no_admin_access"
      ) {
        const newAdminLevelOptions = e.editorOptions.items.filter(
          x => x.value == "no_admin_access"
        );
        e.editorOptions.dataSource = new DataSource(newAdminLevelOptions);
        e.setValue && e.setValue("no_admin_access");
        lastAdminLevelOptions.current = newAdminLevelOptions;
        //e.editorOptions.
      }
    }
    if (
      e.dataField === "global_contract_level" &&
      e.parentType === parentType
    ) {
      let org = e.row?.data.organisation;
      let organisation = org
        ? organisationsData.data.filter(x => x.id === org)[0]
        : null;
      let organisationRelationName = organisation?.relation_choice_value;
      if (
        organisationRelationName &&
        organisationRelationName !== "self" &&
        e.value !== "none"
      ) {
        const newGlobalContactLevelOptions = e.editorOptions.items.filter(
          x => x.value == "none"
        );
        e.editorOptions.dataSource = new DataSource(
          newGlobalContactLevelOptions
        );
        e.setValue("none");
        lastGlobalContractLevelOptions.current = newGlobalContactLevelOptions;
      }
    }
  };
};
