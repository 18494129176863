import { SearchParams } from "components/Common/Components/JAAutoComplete/JAAutoComplete";
import { Option } from "components/Common/Interfaces/Entity.interface";
import { searchSupplierNames, searchSupplierRefs, getSupplierByLookup } from "components/AdminPanel/Organisation/Services/organisationServices";

export const fetchSupplierNameOptions = (params: SearchParams, callback: (results: Option[]) => any) => {
    !!params?.input && searchSupplierNames(params).then((response) => {
        callback(response);
    })
}

export const getSupplierNameOptionLabel = (option: Option | string) => typeof option === 'string' ? option : option?.name;
export const getSupplierNameOptionValue = (option: Option | string) => typeof option === 'string' ? option : option?.name;

export const fetchSupplierRefOptions = (params: SearchParams, callback: (results: Option[]) => any) => {
    searchSupplierRefs(params).then((response) => {
        callback(response);
    })
}

export const getSupplierRefOptionLabel = (option: Option | string) => typeof option === 'string' ? option : option?.supplier_ref;
export const getSupplierRefOptionValue = (option: Option | string) => typeof option === 'string' ? option : option?.supplier_ref;

export const fillSupplierDetailsByName = (supplier_name: string, onChangeFormValues: any, breakUpdateCycle: React.MutableRefObject<boolean>) => {
    getSupplierByLookup({ name: supplier_name as string }).then((response) => {
        if (response) {
            const newValues = {
                'supplier_name': supplier_name,
                'supplier_address': response.address + "\r" + response.postcode,
                'supplier_ref': response.supplier_ref ? response.supplier_ref : undefined
            }
            breakUpdateCycle.current = false;
            onChangeFormValues(newValues, true);
        }
    })
}

export const fillSupplierDetailsByRef = (supplier_ref: string, onChangeFormValues: any, breakUpdateCycle: React.MutableRefObject<boolean>) => {
    getSupplierByLookup({ supplier_ref: supplier_ref as string }).then((response: any) => {
        if (response) {
            const newValues = {
                'supplier_ref': supplier_ref,
                'supplier_address': response.address + "\r" + response.postcode,
                'supplier_name': response.name ? response.name : undefined
            }
            breakUpdateCycle.current = false;
            onChangeFormValues(newValues, true);
        }
    })
}