import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLocation } from 'react-router-dom';
import CustomStore from "devextreme/data/custom_store";
import { TreeList, Column as TreeListColumn } from 'devextreme-react/tree-list';
import DataSource from "devextreme/data/data_source";
//import DataGrid, { KeyboardNavigation, Column, Editing, Paging } from "devextreme-react/data-grid";
import DataGrid, { KeyboardNavigation, Editing, Paging, Column } from "devextreme-react/data-grid";
import { NumberBox } from "devextreme-react/number-box";
import { isNullOrUndefined } from "util";
import { IconButton } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { T, cond, equals, always } from "ramda";

// Own
import useQueryString from "components/Common/Hooks/useQueryString";
import { FieldMetaGroup, FieldMeta } from '../../../../Common/Interfaces/Entity.interface';
import { TablePosition } from "../../../../../helpers/DataGrid/Interfaces/DataGrid.interfaces";
import { navigateDataGridOnKeyDown, onFocusedCellChangingSetPosition } from "../../../../../helpers/DataGrid/DataGridColumn.helper";
import { initialTablePositionState } from "../../../../../helpers/DataGrid/Models/DataGrid.models";
import { APIPrivileges } from 'services/Interface/Interface';
import { tempReclickCell } from "components/ContractInFocus/Maintenance/Helper/MaintenanceTable.helper";

// Styling
import "./MaintenanceTable.scss";

type MaintenanceTableProps = {
  className?: string;
  data: any[];
  meta: FieldMetaGroup;
  privileges: APIPrivileges;
  newRecord: (payload: any) => any;
  updateRecord: (payload: any) => any;
  setGridWidth: React.Dispatch<React.SetStateAction<number | undefined>>;
  setDataGridRef: React.Dispatch<React.SetStateAction<React.MutableRefObject<any> | undefined>>;
  primaryRow: any;
  onPanLeft: () => any;
  onPanRight: () => any;
  viewFrom: number;
  cannotPanRight: boolean;
  cannotPanLeft: boolean;
  forPortfolio?: boolean;
};

const MaintenanceTable = ({
  className,
  data,
  meta,
  privileges,
  updateRecord,
  newRecord,
  setGridWidth,
  setDataGridRef,
  primaryRow,
  onPanLeft,
  onPanRight,
  viewFrom,
  cannotPanRight,
  cannotPanLeft,
  forPortfolio
}: MaintenanceTableProps) => {
  const [dataSource, setDataSource] = useState<DataSource>();
  const [gridData, setGridData] = useState<any[]>(data);

  const [tablePosition, setTablePosition] = useState<TablePosition>(initialTablePositionState);
  const dataGridRef = useRef<any>(null);
  const handleGridContentReady = useCallback(() => { setGridWidth(dataGridRef.current?.instance._$element[0].clientWidth) }, [setGridWidth]);

  const location = useLocation();
  const { report } = useQueryString(location);

  useEffect(() => {
    let thisData = data;
    if (forPortfolio && report) {
      thisData = data.filter(x => x.header_pointer === null);
    }
    setGridData(thisData);
  }, [data, forPortfolio, report]);

  useEffect(() => {
    setDataGridRef(dataGridRef)
  }, [setDataGridRef])

  useEffect(() => {
    if (data) {
      const custom = new CustomStore({
        key: forPortfolio ? "head_ref" : "key", // USE KEY OR COMMENT THIS OUT
        load: () => gridData,
        // HT commented out - need to check but think we don't need to setTablePosition in advance now...
        // load: () => {
        //   setTablePosition({ ...tablePosition, rowCount: gridData.length });
        //   return new Promise(resolve => resolve(gridData))
        // },
        update: (lookup: any, values: any) => {
          const lookupAttribute = forPortfolio ? "head_ref" : "key";
          const dataRow = gridData.find(item => item[lookupAttribute] === lookup);
          const key = dataRow['key'];
          const columnKey = Object.keys(values)[0];
          let dataGridPayload: any = { [key]: values[columnKey] };

          dataRow[columnKey].value = values[columnKey];// Does this do anything?
          if (!dataRow[columnKey].id) {
            //markColumnForUpdate(columnKey);
            if (forPortfolio) {
              dataGridPayload['contract'] = dataRow.associated_with;
            }
            const payload = {
              columnKey,
              dataGridPayload: dataGridPayload
            };
            return new Promise(resolve => resolve(newRecord(payload)));
          } else {
            const payload = {
              dataGridPayload: dataGridPayload,
              id: dataRow[columnKey].id
            };
            return new Promise(resolve => resolve(updateRecord(payload)));
          }
        }
      });

      setDataSource(new DataSource({ store: custom }));
    }
    /* eslint-disable react-hooks/exhaustive-deps*/
  }, [gridData]);

  // const markColumnForUpdate = (column: string): void => {
  //   gridData.forEach(row => (row[column].insert = false));
  // };

  const getCellStyle = ({ value }: { value: any }): string => {
    return !isNullOrUndefined(value.calcValue)
      ? "blue"
      : value.readonlyStyle
        ? "grey"
        : "";
  };

  const getValue = (props: any) => {
    if (props.value) {
      const value = [
        props.value.calcValue,
        props.value.value,
        props.value.displayValue,
      ].reduce(
        (acc, item) => {
          let thisValue = isNullOrUndefined(item) ? acc : item
          if (Number.isNaN(thisValue)) {
            thisValue = ""
          }
          return thisValue;
        },
        undefined
      );
      return <span className="numeric-cell" style={{ color: getCellStyle(props) }}> {value} </span>;
    } else {
      return null;
    }
  };

  const onValueChanged = (cellmeta: FieldMeta, cell: any, e: any): void => {
    const getNullValue = (): number | null => null;
    const newValue = isNullOrUndefined(e.value) ? getNullValue() : e.value;
    cell.setValue(newValue);
  };

  // TEMP
  const cellClicked = useRef({
    columnIndex: -1,
    rowIndex: -1
  });

  const numberEditorRender = (cell: any) => {
    const cellMeta = (meta[cell.data?.key || cell.key] || { required: true });

    const resetValue = cellMeta.required ? 0 : null;

    // TEMP - START
    const { columnIndex, rowIndex } = cell;
    const domElementSelector = `#maintenance-grid.${className} [aria-rowindex="${rowIndex + 1}"] [aria-colindex="${columnIndex + 1}"]`;
    const editorElementSelector = `#maintenance-grid.${className} [aria-rowindex="${rowIndex + 1}"] [aria-colindex="${columnIndex + 1}"] .dx-texteditor-input`;
    tempReclickCell(cell, domElementSelector, editorElementSelector, cellClicked);
    // TEMP - END

    return (cell.value && cell.value.readonly) || (cellMeta && cellMeta.read_only) ? (
      cell.value.value
    ) : (
      <NumberBox
        style={{ textAlign: 'right' }}
        step={0}
        defaultValue={cell.value ? (!isNaN(cell.value?.value) ? cell.value.value : resetValue) : resetValue}
        {...cell.column.lookup}
        onValueChanged={e => onValueChanged(cellMeta, cell, e)}
      //showClearButton={!cellMeta.required}
      />
    );
  };

  const getCss = (data: any, key: string): string => {
    if (!data[key]) {
      return "";
    }

    if (data[key].first) {
      return "my-first-cell";
    }

    if (data[key].last) {
      return "my-last-cell";
    }

    return "";
  };

  // @ts-ignore
  const firstColumn = equals(0);
  const lastColumn = (index: number, length: number) => index === length - 1;

  const getColumnWidth = cond([
    [firstColumn, always(275)],
    [lastColumn, always(150)],
    [T, always(null)]
  ]);

  const getPortfolioColumnWidths = cond([
    [firstColumn, always(280)],
    [lastColumn, always(150)],
    [T, always(null)]
  ]);

  // console.log("primaryRow: ", primaryRow);
  return (
    <div id="maintenance-grid" className={className}>
      <div className="buttons no-print">
        <IconButton onClick={onPanLeft} disabled={cannotPanLeft}>
          <NavigateBeforeIcon />
        </IconButton>
        <IconButton onClick={onPanRight} disabled={cannotPanRight}>
          <NavigateNextIcon />
        </IconButton>
      </div>
      {
        forPortfolio && !report ?
          <TreeList id="gridContainer"
            ref={dataGridRef}
            dataSource={dataSource}
            showBorders={true}
            // rowAlternationEnabled={true}
            onFocusedCellChanging={(e) => onFocusedCellChangingSetPosition(e, tablePosition, setTablePosition)}
            onKeyDown={(e) => navigateDataGridOnKeyDown(dataGridRef, tablePosition, e)()}
            onContentReady={handleGridContentReady}
            keyExpr="head_ref"
            parentIdExpr="header_pointer"
            rootValue={null}
            onEditingStart={(e: any) => {
              if (e.data?.associated_with === "total") {
                e.cancel = true;
              }
            }}
          >

            <Paging enabled={false} />
            <Editing
              mode="cell"
              allowUpdating={privileges.PUT}
            />

            <KeyboardNavigation
              editOnKeyPress={true}
              enterKeyAction="moveFocus"
              enterKeyDirection="column" />

            {Object.keys(primaryRow).map((key: string, index, array) => {
              return (
                <TreeListColumn
                  cssClass={getCss(data[0], key)}
                  key={key}
                  dataField={key}
                  alignment={index !== 0 ? 'right' : 'left'}
                  caption={key}
                  visible={key !== "key" && key !== "name"}
                  // @ts-ignore
                  // width={getPortfolioColumnWidths(index, array.length)}
                  // @ts-ignore
                  minWidth={getPortfolioColumnWidths(index, array.length)}
                  cellRender={getValue}
                  editCellRender={numberEditorRender}
                />
              );
            })}
          </TreeList> :
          <DataGrid id="gridContainer"
            ref={dataGridRef}
            dataSource={dataSource}
            showBorders={true}
            // rowAlternationEnabled={true}
            onFocusedCellChanging={(e) => onFocusedCellChangingSetPosition(e, tablePosition, setTablePosition)}
            onKeyDown={(e) => navigateDataGridOnKeyDown(dataGridRef, tablePosition, e)()}
            onContentReady={handleGridContentReady}
          >

            <Paging enabled={false} />
            <Editing
              mode="cell"
              allowUpdating={privileges.PUT}
            />

            <KeyboardNavigation
              editOnKeyPress={true}
              enterKeyAction="moveFocus"
              enterKeyDirection="column" />

            {Object.keys(primaryRow).map((key: string, index, array) => {
              return (
                <Column
                  cssClass={getCss(data[0], key)}
                  key={key}
                  dataField={key}
                  alignment={index !== 0 ? 'right' : 'left'}
                  caption={key}
                  visible={key !== "key" && key !== "name"}
                  // @ts-ignore
                  width={getColumnWidth(index, array.length)}
                  cellRender={getValue}
                  editCellRender={numberEditorRender}
                />
              );
            })}
          </DataGrid>

      }

    </div>
  );
};

//MaintenanceTable.whyDidYouRender = true;

export default MaintenanceTable;
