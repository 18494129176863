import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { ColumnProps } from "components/ContractInFocus/interfaces/dataGridColumn.interface";
import { wordPerLine } from "helpers/String/String.helper";

const primaryColumnAttributes = {
  alignment: 'center',
  allowEditing: true,
  width: 250,
  dataType: 'string',
}

export const columnConfig = {
  equipment: {
    name: 'Equipment',
    field: 'equipment_name',
    primary: true,
  },
  contractor: {
    name: 'Contractor',
    field: 'contractor__name', //use this rather than contractor.name as it works for filtering
    primary: true,
  },
  contract: {
    name: 'Contract',
    field: 'contract_ref',
    primary: true
  }
}


export const generateColumnHeaders = (months: string[]): ColumnProps[] => {
  // console.log('months: ', months);
  const columns: ColumnProps[] = months.map(month => {
    return {
      caption: month,
      alignment: 'center',
      allowEditing: true,
      dataField: month,
      primary: false,
    }
  });

  return [
    {
      ...primaryColumnAttributes,
      alignment: 'left',
      width: 200,
      caption: columnConfig.contractor.name,
      dataField: columnConfig.contractor.field,
      primary: true,
    },
    {
      ...primaryColumnAttributes,
      alignment: 'left',
      width: 175,
      caption: columnConfig.equipment.name,
      dataField: columnConfig.equipment.field,
      primary: true,
    },
    ...columns,
  ]
}

export const generateColumnHeadersForPlannerView = (months: string[], portfolio?: HydratedPortfolio, reportContext?: boolean): ColumnProps[] => {
  let theseColumns: ColumnProps[] = [];
  if (portfolio) {
    theseColumns.push({
      caption: 'Contract',
      width: '3%',
      alignment: 'left',
      allowEditing: false,
      dataField: 'contract__contract_ref',
      primary: true,
    })
  }
  const monthColumns: ColumnProps[] = months.map(month => {
    return {
      caption: wordPerLine(month),
      width: "3.75%",
      alignment: 'center',
      allowEditing: true,
      dataField: month,
      primary: false,
    }
  });
  if (reportContext) {
    theseColumns = [
      ...theseColumns,
      {
        ...primaryColumnAttributes,
        alignment: 'left',
        dataRowAlignment: 'left',
        width: "9%",//140,
        caption: columnConfig.contractor.name,
        dataField: columnConfig.contractor.field,
        primary: true,
      },
      {
        ...primaryColumnAttributes,
        alignment: 'left',
        dataRowAlignment: 'left',
        width: "10%",//150,
        caption: 'Equipment',
        dataField: 'equipment_name',
        primary: true,
      },
      ...monthColumns,
    ]
  } else {
    theseColumns = [
      ...theseColumns,
      {
        ...primaryColumnAttributes,
        alignment: 'left',
        dataRowAlignment: 'left',
        width: "9%",//140,
        caption: columnConfig.contractor.name,
        dataField: columnConfig.contractor.field,
        primary: true,
      },
      // {
      //   alignment: 'center',
      //   width: "5%",//75,
      //   caption: 'Order No.',
      //   dataField: 'order_number',
      //   allowEditing: false,
      //   primary: true,
      // },
      {
        ...primaryColumnAttributes,
        alignment: 'center',
        width: "6%",//90,
        caption: 'Contract Start\r\nContract End',
        dataField: 'contract_start_date',
        primary: true,
      },
      {
        ...primaryColumnAttributes,
        alignment: 'center',
        width: "6%",//90,
        caption: 'Telephone No.',
        dataField: 'supplier_telephone_number',
        primary: true,
      },
      {
        ...primaryColumnAttributes,
        alignment: 'center',
        width: "5.5%",//80,
        caption: 'Contact Name',
        dataField: 'contact',
        primary: true,
      },
      {
        ...primaryColumnAttributes,
        alignment: 'center',
        width: "5.5%",//80,
        caption: 'Emergency\r\nCallout No.',
        dataField: 'emergency_call_out_contact',
        primary: true,
      },
      {
        ...primaryColumnAttributes,
        alignment: 'center',
        width: "4.5%",//58,
        caption: 'Response\r\nTime',
        dataField: 'agreed_emergency_response_time',
        primary: true,
      },
      {
        ...primaryColumnAttributes,
        alignment: 'left',
        dataRowAlignment: 'left',
        width: "10%",//150,
        caption: 'Equipment Description',
        dataField: 'service_details',
        primary: true,
      },
      {
        ...primaryColumnAttributes,
        alignment: 'center',
        width: "3.5%",//50,
        caption: 'No. of\r\nvisits\r\n',
        dataField: 'number_of_visits',
        primary: true,
      },

      ...monthColumns,
    ]
  }
  return theseColumns;


}