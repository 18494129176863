import { Dictionary } from '../../../../Common/Interfaces/Entity.interface';
import { createRequestActionTypes } from '../../../../Common/Utils/ReduxUtil';
import { ContractInterface } from 'components/AdminPanel/Contracts/Interfaces/Contract.interface';
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { Snapshot } from 'components/ContractInFocus/Interfaces/ContractInFocus.interfaces';
import { TOCStructures } from "components/ContractInFocus/ContractReport/Helpers/contractReportToc.helper";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { ContractPeriod, PortfolioPeriod } from "components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface";


export enum PDFGeneration {
  Standby,
  Requested,
  Ready
}

export const getDownloadHeaderConfig = () => ({
  headers: {
    "x-api-key": "7tmqPOe6110tTTTvbgkasssYOB",
    "Content-Type": "application/json"
  }
});

export interface DownloadRequestRemote {
  elementToBePrinted: string;
  remoteTargetUrl: string;
  snapshot: Snapshot;
  month: number;
  year: number;
  contract?: ContractInterface;
  portfolio?: HydratedPortfolio;
  appendices?: any;
  jaguar_doc_logo_with_text?: Dictionary<any>;
  maintenance_report_cover_fallback?: Dictionary<any>;
  distribution?: any;
  focusedPeriod: ContractPeriod;
  fullVisibilityData: any;
}

export interface DownloadRequest {
  page: any;
  elementToBePrinted: string;
  meta: Dictionary<any>[];
  tocs: TOCStructures;
  snapshot: Snapshot;
}


export interface DownloadSave {
  htmlSource: string;
  base64: string;
  snapshot: Snapshot;
}

export interface DownloadData extends DownloadRequest {
  html: any;
  elementToBePrinted: any;
  targetElementToBePrinted?: string;
  localstorage?: any;
  token?: string;
  reportMeta: any;
  remoteTargetUrl?: string;
  inlineStyles: string;
  linkedStyles: string;
  calculatedStyles: string;
  constructedHtmlSource: string;
}

// State

export interface BasePDFState {
  status: PDFGeneration;
  htmlSource: string;
  pdfBase64: string;
  meta: any;
}
export interface DownloadState extends BasePDFState {
  snapshot: Snapshot;
}

// Actions
export const DOWNLOAD_REQUEST = createRequestActionTypes('DOWNLOAD_DOWNLOAD_REQUEST');
export const DOWNLOAD_STANDBY = createRequestActionTypes('DOWNLOAD_DOWNLOAD_STANDBY');
export const DOWNLOAD_CLEAR = createRequestActionTypes('DOWNLOAD_DOWNLOAD_CLEAR');
export const DOWNLOAD_REQUEST_REMOTE = createRequestActionTypes('DOWNLOAD_REQUEST_REMOTE');
export const DOWNLOAD_RESET_DOWNLOAD = createRequestActionTypes('DOWNLOAD_RESET_DOWNLOAD');

export const DOWNLOAD_REQUEST_REMOTE_PORTFOLIO = createRequestActionTypes('DOWNLOAD_REQUEST_REMOTE_PORTFOLIO');

export interface DownloadRequestRemoteAction {
  type: typeof DOWNLOAD_REQUEST_REMOTE.start;
  payload: DownloadRequestRemote;
}

export interface DownloadRequestAction {
  type: typeof DOWNLOAD_REQUEST.start;
  payload: DownloadRequest;
}

export interface DownloadSuccessAction {
  type: typeof DOWNLOAD_REQUEST.success;
  payload: DownloadSave;
}

export interface DownloadClearAction {
  type: typeof DOWNLOAD_CLEAR.reducer;
}

export interface DownloadStandByAction {
  type: typeof DOWNLOAD_STANDBY.reducer;
}

export type DownloadActionTypes = DownloadRequestAction | DownloadSuccessAction | DownloadClearAction | DownloadStandByAction | DownloadRequestRemoteAction