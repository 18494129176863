import { MainInFocusVisibilitySettings } from "../../interfaces/contractInFocus.interfaces";
import { RightVisibilityMenuT } from "../../interfaces/personalContractSettings.interfaces";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { ContractInterface } from "components/AdminPanel/Contracts/Interfaces/Contract.interface";
import { GetRightVisibilityMenu } from "components/Common/Components/RightVisibilityMenu/Interfaces/RightContext.interfaces";



export const auditRightVisibilityMenu = ({ visibilitySettings }: GetRightVisibilityMenu): RightVisibilityMenuT => ({
  "Audit": {
    table: {
      value: visibilitySettings.show_audit_table,
      updateKey: 'show_audit_table',
    }
  },
});