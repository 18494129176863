import { logError } from "services/Log/Log.service";
import API from "services/API/API.js";
import {
  getListData,
  getListMeta,
  getDataAndMeta,
  getDataAndMetaAndPUTMeta
} from "services/API/API.helper";

export const addAWDocumentApi = async (
  awId,
  formData,
  portfolioId,
  progress
) => {
  let url = `/financials/${awId}/documents/`;
  if (portfolioId) {
    url = `/portfolios/${portfolioId}${url}`;
  }
  try {
    const response = await API.post(url, formData, {
      onUploadProgress: progressEvent =>
        progress && progress(false, progressEvent.loaded, progressEvent.total)
    });
    return response.data.data;
  } catch (err) {
    logError(
      `POST_${url}_${JSON.stringify(formData)}_error:${JSON.stringify(err)}`
    );
    return null;
  }
};

export const getAWDocumentsApi = async (
  awId,
  setMetaInfo = undefined,
  typeFilter = undefined,
  portfolioId
) => {
  let url = typeFilter
    ? `/financials/${awId}/documents/?format=json&aw-document-type=${typeFilter}`
    : `/financials/${awId}/documents/?format=json`;
  if (portfolioId) {
    url = `/portfolios/${portfolioId}${url}`;
  }
  try {
    const response = await API.get(url);
    const { data, ...metainfo } = getDataAndMetaAndPUTMeta(response);
    if (typeof metainfo.misc == "undefined") {
      metainfo.misc = {};
    }
    metainfo.misc["numDocs"] = data.length;
    setMetaInfo && setMetaInfo(metainfo);
    return data;
  } catch (err) {
    logError(`GET_${url}_error:${JSON.stringify(err)}`);
    return [];
  }
};

const getAWDocumentUrl = (awId, fileId, portfolioId) =>
  `${
    portfolioId ? "/portfolios/" + portfolioId : ""
  }/financials/${awId}/documents/${fileId}/`;

export const deleteAWDocumentApi = async (awId, fileId, portfolioId) => {
  const url = getAWDocumentUrl(awId, fileId, portfolioId);
  try {
    // const response = await API.delete(url);
    // return response;
    return await API.delete(url);
  } catch (err) {
    logError(`DELETE_${url}_error:${JSON.stringify(err)}`);
    return null;
  }
};

export const updateAWDocumentApi = async (awId, fileId, data, portfolioId) => {
  const url = getAWDocumentUrl(awId, fileId, portfolioId);
  try {
    const response = await API.put(url, data);
    return response;
  } catch (err) {
    logError(`PUT_${url}_${JSON.stringify(data)}_error:${JSON.stringify(err)}`);
    return null;
  }
};
