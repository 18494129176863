import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

// Own
import styles from "../../../../styles/app/common/variables.scss";
import { hideOverflowText } from "../../../../styles/app/common/text";

export const useSiteBackCardStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    minHeight: "200px",
    maxWidth: "540px",
    display: "flex",
    flexDirection: "column"
  }
}));

export const SiteInfoWrapper = styled.div`
  ${({ favourite }: { favourite: number }) => {
    return `
    font-family: ${styles.fontFamily};
    height: 290px;
    cursor: default;

    .MuiPaper-rounded {
      position: relative;
      height: 294px;
      min-width: 200px;
    }
  
    .fa-star {
      position: absolute;
      right: .5rem;
      top: .5rem;
      font-size: 1.5rem;
      color: ${favourite ? styles.brandGold : styles.gray};
    }
  }`
  }}
`;

export const StyledSiteHeader = styled.div`
  display: flex;
`;

export const StyledSiteTable = styled.div`
  display: flex;
  flex-direction: column;
  height: 225px;
  overflow: auto;
  overflow-x: hidden;
`;

export const StyledImageCropper = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;

  img {
    display: inline;
    margin: 0 auto;
    height: 100%;
    width: auto;
  }
`;

export const StyledImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  margin-right: 1rem;
  height: 50px;
`;

export const StyledSiteTitle = styled.div`
  color: ${styles.blueDark};
  width: ${(props: { width: number }) => `${props.width - 100}px`};
  ${hideOverflowText}
  font-size: 1.2rem;
`;

export const StyledSiteSub = styled.div`
  font-size: 0.75rem;
  width: ${(props: { width: number }) => `${props.width - 100}px`};
  ${hideOverflowText}
`;

export const StyledNoData = styled.div`
  margin-top: 2.75rem;
  cursor: default;
  color: ${styles.grey};
  opacity: 0.2;
  text-align: center;
  i.fa-frown-open {
    font-size: 4rem;
    display: block;
  }
  span {
    font-size: 1.5rem;
    display: block;
    font-weight: 600;
  }
  
`;

