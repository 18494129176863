export const mode = {
  list: "list",
  view: "view",
  edit: "edit",
  new: "new",
  preload: "preload",
  viewOrEdit: "view or edit",
};

export const isNewMode = (recordMode: string) => recordMode === mode.new;

export const isEditMode = (recordMode: string) => recordMode === mode.edit;

export const isViewMode = (recordMode: string) => recordMode === mode.view;

export const isPreloadMode = (recordMode: string) =>
  recordMode === mode.preload;

export const isNewOrEditMode = (recordMode?: string) =>
  recordMode === mode.edit || recordMode === mode.new;

export const isViewOrEditMode = (recordMode: string) =>
  recordMode === mode.edit || recordMode === mode.view;

export const isViewOrPreloadMode = (recordMode: string) =>
  isViewMode(recordMode) || isPreloadMode(recordMode);

export const isViewEditOrPreloadMode = (recordMode: string) =>
  isViewOrEditMode(recordMode) || isPreloadMode(recordMode);

export const isDetailMode = (currentMode: string, hasData: Function) =>
  (currentMode === mode.viewOrEdit ||
    currentMode === mode.new ||
    currentMode === mode.view ||
    currentMode === mode.edit ||
    currentMode === mode.preload) &&
  hasData();

export const isListMode = (currentMode: string, hasData: Function) =>
  currentMode === mode.list || !currentMode || !hasData();

export const isListModeStrict = (currentMode: string | undefined, hasData: Function) => currentMode === mode.list;

export type MetaTypes = "POST" | "PUT";
