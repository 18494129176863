import { columnVisibility } from "helpers/DataGrid/DataGridColumn.helper";

export const initialTablePositionState = {
  rowIndex: 0,
  rowCount: 0,
  colIndex: 0
}



