import { MATTERS_FOR_DISCUSSION_ROUTE } from "services/API/common/globalAPIs";
import { PreFlightListInfo } from 'components/Common/Interfaces/Entity.interface';
import { APIN } from "services/API/API";
import { getPreFlightListMeta, preFlightCanCreateCheck } from "services/API/API.helper";
import { onResponseError } from "services/API/API.interceptor.js";

export function getMattersForDiscussionEventsRoute(eventId?: string) {
    let route = MATTERS_FOR_DISCUSSION_ROUTE;
    if (eventId) {
        route = `${route}${eventId}/`;
    }
    return route;
}

export function getMattersForDiscussionDocumentsRoute(eventId: string) {
    return `${getMattersForDiscussionEventsRoute(eventId)}documents/`;
}

interface FetchHASEventDocumentPreFlightInfoProps {
    eventId: string;
}

export const fetchMattersForDiscussionPreFlightInfo = (props: FetchHASEventDocumentPreFlightInfoProps): Promise<PreFlightListInfo> => {
    let route = getMattersForDiscussionDocumentsRoute(props.eventId);
    return APIN.options(route).then((response) => {
        const meta = getPreFlightListMeta(response); //preflight uses the 'options' verb
        const canCreate = preFlightCanCreateCheck(response);
        return { meta, canCreate, canRead: true }
    }).catch(error => {
        if (error.response?.status == 403) {
            return { meta: {}, canCreate: false, canRead: false }
        } else {
            return onResponseError(error)
        }
    });
}
