import { APIR, getMultipartConfig } from "services/API/API";
import { ContractOrPortfolio } from "services/API/common/contractAPIs";
import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";
import { removeReadOnlyFields } from "services/API/API.helper";

interface SendGeneratedMonthlyMaintenanceReportProps extends ContractOrPortfolio {
  file: string;
  data: any;
  id?: string;
  meta: FieldMetaGroup;
}

const sendGeneratedDoc = (props: SendGeneratedMonthlyMaintenanceReportProps) => {
  let basePostUrl = 'generate-docs-post/never/';
  let basePatchUrl = 'generate-docs-patch/never/';
  if (props.portfolio) {
    basePostUrl = `portfolios/${props.portfolio.id}/`;
    basePatchUrl = `portfolio-monthly-maintenance-reports/`;
  }
  if (props.contract) {
    basePostUrl = `contracts/${props.contract.contract_ref}/`;
    basePatchUrl = `monthly-maintenance-reports/`;
  }

  const postUrl = `${basePostUrl}monthly-maintenance-reports/`;
  const patchUrl = `${basePatchUrl}${(props.id || '').replace(/-/g, "")}/`;
  let fd = new FormData();

  fd.append("file", props.file);

  let attributes = props.data;
  attributes = removeReadOnlyFields(props.data, props.meta);
  if (props.contract) {
    attributes.contract = props.contract.id
  }
  if (props.portfolio) {
    attributes.portfolio = props.portfolio.id
  }

  Object.keys(attributes).forEach(key => fd.append(key, attributes[key]));

  return props.id
    ? APIR.patch(patchUrl, fd, getMultipartConfig())
    : APIR.post(postUrl, fd, getMultipartConfig());
}

export {
  sendGeneratedDoc,
};