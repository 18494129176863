import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import throttle from 'lodash/throttle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import { AutoInputProps } from "components/Common/Components/AutoInput/AutoInput";

import '../../../../components/Common/Components/AutoInput/AutoInputStyles.scss';
import { FieldFormConfig } from "components/Common/Components/DocumentsGrid/DocumentsGrid.interface";
import { Option } from "components/Common/Interfaces/Entity.interface";


export interface SearchParams {
    [index: string]: string
}

export type StandardSearchParams = { input: string }

interface JAAutoCompleteLookupProps extends AutoInputProps {
    inputWrapperClass?: string;
    disabled?: boolean;
    onBlur: any;
    inputRef?: any;
}

// AutoComplete Notes:
// when getOptionSelected is defined, it seems that the currently selected option
// is not included in the options list even if includeInputInList is set to true


export default function JAAutoComplete({
    dataField,
    onChangeFormValues,
    fieldConfig,
    inputWrapperClass,
    zIndex,
    fieldMeta,
    disabled,
    formValuesRef,
    currentFocus,
    onBlur,
    inputRef
}: JAAutoCompleteLookupProps) {
    const currentValue = formValuesRef.current[dataField] ? formValuesRef.current[dataField] : null;
    const [currentSearchValue, setCurrentSearchValue] = React.useState<any>();
    const [options, setOptions] = React.useState<(any[])>(fieldConfig.initialOptions || []);
    const loaded = React.useRef(false);
    const fetchActive = React.useRef(false);
    const thisLabel = fieldConfig.skipLabel ? undefined : fieldConfig.label ? fieldConfig.label : fieldMeta?.label;
    const required = fieldConfig.forceRequired ? fieldConfig.forceRequired : fieldMeta?.required;
    const finalStyles = {
        width: '100%',
        zIndex: zIndex || 1200,
        height: '100%',
        ...fieldConfig.style
    }

    if (typeof window !== 'undefined' && !loaded.current) {
        // use this for any initial conditions that must be set first
        loaded.current = true;
    }

    const fetch = React.useMemo(
        () =>
            throttle(
                (
                    params: SearchParams,
                    callback: (results?: Option[]) => void,
                    fieldConfig: FieldFormConfig
                ) => {
                    fieldConfig.fetchAutoCompleteOptions && fieldConfig.fetchAutoCompleteOptions(
                        params,
                        callback,
                        fieldConfig.getSuggestions,
                    );
                },
                200,
            ),
        [],
    );

    const thisOnBlur = React.useCallback(() => {
        fetchActive.current = false;
        return onBlur(formValuesRef.current, onChangeFormValues);
    }, [onBlur, formValuesRef, onChangeFormValues])

    const updateOptions = React.useCallback(() => {
        let minCh = fieldConfig.minCharactersForLookup;
        if (minCh) {
            let trimmedInput = currentSearchValue ? currentSearchValue.trim() : '';
            if (trimmedInput.length < minCh) {
                setOptions(currentValue ? [currentValue] : []);
            }
        }
        const inputParams = fieldConfig.additionalAutoCompleteLookupParams ? { ...fieldConfig.additionalAutoCompleteLookupParams, input: currentSearchValue as string } : { input: currentSearchValue as string }
        fetchActive && fetch(inputParams, (results?: Option[]) => {
            let newOptions: Option[] = [];
            if (results) {
                newOptions = [...newOptions, ...results];
            }
            setOptions(newOptions);
        }, fieldConfig);
    }, [fieldConfig, fetch, currentSearchValue, currentValue])

    React.useEffect(() => {
        updateOptions();
    }, [updateOptions]);

    return (
        <FormControl className={inputWrapperClass} required={required} hidden={fieldConfig.hidden} style={{
            // put any defaults in here
            ...finalStyles
        }}>
            {
                !fieldConfig.skipLabel &&
                <InputLabel shrink>
                    {thisLabel}
                </InputLabel>
            }
            <Autocomplete
                id={`autoField-${dataField}`}
                className="autocomplete"
                getOptionLabel={fieldConfig.getOptionLabel ? fieldConfig.getOptionLabel : undefined}
                getOptionSelected={fieldConfig.getOptionSelected}
                includeInputInList={true}
                filterOptions={(x: any) => x}
                options={options}
                disabled={disabled}
                autoComplete
                freeSolo={fieldConfig.freeSolo}
                onFocus={() => {
                    fetchActive.current = true;
                    currentFocus.current = dataField;
                    updateOptions();
                }}
                filterSelectedOptions
                value={currentValue as any}
                onChange={(event: any, newOption: Option | null, reason: string) => {
                    let thisOption: any = newOption;
                    if (fieldConfig.getOptionValue) {
                        thisOption = fieldConfig.getOptionValue(newOption);
                    }
                    if (fieldConfig.onChangeOption) {
                        fieldConfig.onChangeOption(thisOption, onChangeFormValues, reason);
                    } else {
                        onChangeFormValues({ [dataField]: thisOption })
                    }
                    //setCurrentValue(thisOption);
                }}
                onInputChange={(event: any, newInputValue: string) => {
                    setCurrentSearchValue(newInputValue);
                }}
                // onBlur={() => {
                //     fieldConfig.onBlur && fieldConfig.onBlur(formValuesRef.current, onChangeFormValues)
                // }}
                onBlur={thisOnBlur}
                renderInput={(params: any) => (
                    <TextField {...params}
                        ref={inputRef}
                        fullWidth
                    />
                )}
            // renderOption={(option: Option) => (
            //             <li>
            //             {option.label}
            //             </li>
            //     )
            // }
            />
        </FormControl>
    );
}
