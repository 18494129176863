import React, { useState, useRef, useEffect, useReducer, useCallback } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';

// TODO:
// ask about not allowing visits outside of supplier contract start/end date.

// Own
import { Visit, SupplierService, ListVisit, SupplierServiceContractsResponse } from '../Interfaces/Schedule.interfaces';
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { TabbedPanel } from "components/Common/Components/TabPanel";
import SupplierServiceDetails from "components/Schedulers/VisitModal/Components/SupplierServiceDetail/SupplierServiceDetail";
import SupplierContractVisitReports from "components/Schedulers/VisitModal/Components/VisitDocumentsList/VisitDocumentsList";
import { FieldMetaGroup } from 'components/Common/Interfaces/Entity.interface';
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { FormValues } from "components/Common/Interfaces/Entity.interface";
import VisitDetails from "components/Schedulers/VisitModal/Components/VisitDetail/VisitDetails";
import { fetchSupplierServiceContracts } from "components/Schedulers/Services/visitService";
import { Period } from "components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface";
import SupplierServiceVisits from "components/Schedulers/VisitModal/Components/VisitList/SupplierServiceVisits";
import PeriodChooser from "components/Common/Components/PeriodChooser/PeriodChooser";

// Styles
import 'components/Schedulers/Components/DetailModals.scss';
import { APIPrivileges } from "services/Interface/Interface";

export interface VisitListResponse {
  data: ListVisit[];
  meta: FieldMetaGroup;
  permissions: APIPrivileges;
}
interface VisitModalProps {
  contract?: SiteContract;
  portfolio?: HydratedPortfolio;
  initialIndex?: number;
  initialVisitIndex?: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  initialContextObject?: SupplierService;
  refreshGrid: React.DispatchWithoutAction;
  selectedPeriod: Period;

}

export interface VisitModalState {
  mode: string;
  visit: Visit | undefined;
}

const VisitModal = ({
  contract,
  portfolio,
  initialIndex,
  initialVisitIndex,
  setOpen,
  open,
  initialContextObject,
  refreshGrid,
  selectedPeriod
}: VisitModalProps) => {

  // NOTE: because format for storing visits in specialist data grid (grouped by month key directly in SC detail object) is inconvenient for visit list
  // and a refresh is a good idea anyway, here we'll be retreving the visits per SC detail but in 'flat' format (not grouped, nested per month).
  // we'll also grab them as a 'pure' visits list, not wrapped in specialist contract object.  That may be useful to keep things simple in future if 
  // we wish to use the same view for displaying all visits (in which circ we would show a list of supplier contract details under the contract details tab)
  // If we ever need to mix visits from different suppliers/equipment a more generic route can be queried which would also return a flat list of un-nested 
  // visits.

  const [supplierService, setSupplierService] = useState(initialContextObject);
  const [visit, setVisit] = useState<Visit>();
  // const [canWrite, setCanWrite] = useState(false);
  // const [visitsResponse, setVisitsResponse] = useState<ScheduleResponse>();
  const [supplierServiceContractsResponse, setSupplierServiceContractsResponse] = useState<SupplierServiceContractsResponse>();
  const defaultNewItemDate = useRef<Date>(moment(selectedPeriod?.start_date).toDate());
  const [period, setPeriod] = useState<Period>(selectedPeriod);
  const initialStartDate = useRef(moment(period?.start_date));
  const initialEndDate = useRef(moment(period?.end_date));

  let specialistTabs = [
    "Contract Details",
  ];

  const SERVICE_REPORT_TAB_LABEL = "Service Reports";
  const WORK_PERMITS_TAB_LABEL = "Work Permits";
  let visitTabs = [
    SERVICE_REPORT_TAB_LABEL]
  if (!supplierService?.contract__disable_work_permits_system) {
    visitTabs.push(WORK_PERMITS_TAB_LABEL)
  }

  if (supplierService) {
    specialistTabs = [...specialistTabs, "Service Schedule", "Documents"]
  }

  const [index, setIndex] = useState(initialIndex || 0);
  const [visitIndex, setVisitIndex] = useState(initialVisitIndex || 0);
  const [docsTabIndex, setDocsTabIndex] = useState(0);
  const [showPeriodChooser, setShowPeriodChooser] = useState(docsTabIndex !== 1);

  useEffect(() => {
    setShowPeriodChooser(docsTabIndex !== 1);
  }, [docsTabIndex])
  const handleClickClose = (): void => {
    if (visit) {
      setVisit(undefined);
    } else {
      setOpen(false);
    }
  }

  const notesStore = useRef<FormValues>({});

  const [ModalTitle, setModalTitle] = useState<() => JSX.Element>();

  const getSupplierServiceContractsResponse = useCallback(({ start_date, end_date, callback }: {
    start_date?: string,
    end_date?: string,
    callback?: any,
  }) => {
    // NB we define function to get the supplier contracts out here as it may well be useful in contexts other than the 
    // the supplier service/contract details view
    if (supplierService) {
      fetchSupplierServiceContracts({
        portfolioId: portfolio?.id,
        contractRef: supplierService.contract__contract_ref,
        supplier_service: supplierService.id,
        start_date,
        end_date
      }).then((supplierServiceContractsResponse: SupplierServiceContractsResponse) => {
        callback && callback(supplierServiceContractsResponse);
      })
    }
  }, [supplierService, portfolio?.id])

  useEffect(() => {
    if (supplierService) {
      getSupplierServiceContractsResponse({ start_date: period.start_date, end_date: period.end_date });
    }
  }, [supplierService, getSupplierServiceContractsResponse, period])


  return (
    <Dialog
      id="detailModal"
      open={open}
      scroll="paper"
      maxWidth="xl"
      fullWidth
      fullScreen
    //style={{ minHeight: '500px' }}
    >
      <DialogTitle
        className={visit ? 'modalDetail' : ''}
      >
        <div className={`${visit ? 'visit' : ''} header-wrapper`}>
          {ModalTitle && <ModalTitle></ModalTitle>}
          <div className="detail-modal--close-button">
            <IconButton onClick={handleClickClose} >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      {!visit ? <>
        <TabbedPanel
          pageIndex={index}
          showTabs={true}
          onChangeTab={(index: number) => { setIndex(index) }}
          tabs={specialistTabs}
        >
        </TabbedPanel>
        {
          showPeriodChooser && <PeriodChooser
            period={period}
            setPeriod={setPeriod}
            initialStartDate={initialStartDate.current}
            initialEndDate={initialEndDate.current}
          />
        }
        {index == 0 && <SupplierServiceDetails
          // pass functions to set the contractor and equipment in here...
          initialContract={contract}
          portfolio={portfolio}
          supplierService={supplierService}
          setSupplierService={setSupplierService}
          setModalTitle={setModalTitle}
          refreshGrid={refreshGrid}
          getSupplierServiceContractsResponse={getSupplierServiceContractsResponse}
          setOpen={setOpen}
        />
        }
        {supplierService && index == 1 &&
          <DialogContent>
            <SupplierServiceVisits
              supplierService={supplierService}
              getSupplierServiceContractsResponse={getSupplierServiceContractsResponse}
              contractRef={supplierService.contract__contract_ref}
              portfolioId={portfolio?.id}
              disable_work_permits_system={supplierService.contract__disable_work_permits_system}
              refreshGrid={refreshGrid}
              setVisit={setVisit}
              initialNotesStore={notesStore}
              setVisitIndex={setVisitIndex}
              permitIndex={visitTabs.findIndex(x => x == WORK_PERMITS_TAB_LABEL)}
              documentsIndex={visitTabs.findIndex(x => x == SERVICE_REPORT_TAB_LABEL)}
              selectedPeriod={period}
              setModalTitle={setModalTitle}
            />
          </DialogContent>
        }
        {index == 2 && supplierService && <DialogContent>
          <SupplierContractVisitReports
            contractId={supplierService.contract}
            portfolio={portfolio}
            supplierService={supplierService}
            index={docsTabIndex}
            setIndex={setDocsTabIndex}
            selectedPeriod={period}
          />

        </DialogContent>}
      </> : <VisitDetails
        portfolio={portfolio}
        visitIndex={visitIndex}
        setVisitIndex={setVisitIndex}
        visitTabs={visitTabs}
        supplierService={supplierService}
        defaultNewItemDate={defaultNewItemDate}
        setModalTitle={setModalTitle}
        initialVisit={visit}
      />
      }
    </Dialog>
  );
}

export default VisitModal;
