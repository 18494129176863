
import {
    DataGridOnEditPreparing
} from "interfaces/datagrid.interfaces";
import { editableTextAreaOnPreparing, swapEditorType } from "helpers/DataGrid/DataGridColumn.helper";

export function getEventEditorPreparing(metadata: any) {
    return (e: DataGridOnEditPreparing) => {
        if (e.editorName === "dxDateBox") {
            if (e.dataField === "resolved_on") {
                e.editorOptions.dateOutOfRangeMessage = "resolved on cannot be in the future or before the date raised"
                const data = e.row?.data;
                e.editorOptions.min = data?.date ? data.date : undefined;
            }
            if (e.dataField === "date") {
                e.editorOptions.dateOutOfRangeMessage = "date cannot be after the resolution date"
                const data = e.row?.data;
                e.editorOptions.max = data?.resolved_on ? data.resolved_on : undefined;
            }
        }
        const editorTypeMapping = {
            'details': 'dxTextArea'
        }
        swapEditorType(metadata, e, editorTypeMapping);
        editableTextAreaOnPreparing(metadata.activeMeta)(e);
    }
}