import styled from "styled-components";

// Own
import styles from "styles/app/common/variables.scss";

// const TomatoButton = styled(Button)`
//   color: tomato;
//   border-color: tomato;
// `;

export const RightContextButtonWrapperBase = styled.div`
  background-color: ${styles.blueDark};
  border-radius: 0.5rem 0 0 0.5rem;
  position: fixed;
  right: 0rem;
  color: ${styles.white};
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  z-index: 5000;

  div:last-child {
    border-bottom: 0;
  }
`;

export const RightContextRefreshButtonWrapper = styled(RightContextButtonWrapperBase as any)`
  top: calc(${styles.navbarHeight} + 5rem);
`;

export const RightContextVisibilityButtonWrapper = styled(RightContextButtonWrapperBase as any)`
  top: calc(${styles.navbarHeight} + 8.5rem);
`;

export const AppendixManagementButtonWrapper = styled(RightContextButtonWrapperBase as any)`
top: calc(${styles.navbarHeight} + 12rem);
`;

export const DistributionListManagementButtonWrapper = styled(RightContextButtonWrapperBase as any)`
top: calc(${styles.navbarHeight} + 15.5rem);
`;

export const PublishSnapshotButtonWrapper = styled(RightContextButtonWrapperBase as any)`
top: calc(${styles.navbarHeight} + 19rem);
`;

export const ProduceReportButtonWrapper = styled(RightContextButtonWrapperBase as any)`
top: calc(${styles.navbarHeight} + 22.5rem);
`;

export const getRefreshControlsStyles = (refreshing: boolean) => {
  if (refreshing) {
    return {
      color: styles.white,
      animation: "rotation infinite linear 2s"
    }
  } else {
    return {
      color: styles.white,
    }
  }
}
export const refreshControlsStyles = {
  color: styles.white,
};


export const openControlsStyles = {
  color: styles.white,
  //transform: "rotate(-90deg)"
};
