import React from "react";
import PropTypes from "prop-types";
import styles from "../../../../styles/app/common/variables.scss";

export const HighlightText = ({
  text,
  matchText,
  color = styles.brandGold
}) => {
  const getText = () => text || "";

  const indexOfMatchText = () =>
    getText()
      .toLowerCase()
      .indexOf(getMatchText());

  const getMatchText = () => (matchText || "").toLowerCase();

  const indexMatchTextFound = () => indexOfMatchText() !== -1;

  const renderTextPrefix = () => {
    const indexSearchTerm = indexOfMatchText();
    return indexMatchTextFound() ? getText().substr(0, indexSearchTerm) : "";
  };

  const renderTextSuffix = () => {
    const indexSearchTerm = indexOfMatchText();
    return indexMatchTextFound()
      ? getText().substr(
          indexSearchTerm + getMatchText().length,
          getText().length
        )
      : "";
  };

  const renderMatchText = () => {
    const indexSearchTerm = indexOfMatchText();
    return indexMatchTextFound()
      ? getText().substr(indexSearchTerm, getMatchText().length)
      : "";
  };

  const getSearchTermStyles = {
    fontStyle: "unset",
    color: color
  };

  const getHighlightStyle = {
    paddingRight: "4px"
  };

  const renderTextNoMatch = () => (!indexMatchTextFound() ? getText() : "");

  return (
    <span id="highlight" className="field" style={getHighlightStyle}>
      {renderTextNoMatch()}
      {renderTextPrefix()}
      <em className="highlight" style={getSearchTermStyles}>
        {renderMatchText()}
      </em>
      {renderTextSuffix()}
    </span>
  );
};

HighlightText.propTypes = {
  text: PropTypes.string,
  matchText: PropTypes.string,
  color: PropTypes.string
};
