import React, { useRef, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Animation,
  Chart,
  // ScrollBar,
  // ZoomAndPan,
  Series,
  CommonSeriesSettings,
  Legend,
  ValueAxis,
  Title,
  Label,
  Font,
  Tooltip,
  ArgumentAxis
} from "devextreme-react/chart";

// Own
import styles from "styles/app/common/variables.scss";
import { settingsSelector } from 'store/selectors/settings.selector';
import { chartsFontProps, colourSequence } from "constants/chart.constants";

// Styles
import './MaintenanceChartStyles.scss';
import useQueryString from "../../../../Common/Hooks/useQueryString";
import { StylesProvider } from "@material-ui/core";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

interface MaintenanceData {
  completed: number;
  incomplete: number;
  month: string;
}

export interface MaintenanceChartProps {
  className?: string;
  data: MaintenanceData[];
  animate?: boolean;
  viewFrom: number;
  viewLength: number;
  portfolio?: HydratedPortfolio
}

const MaintenanceChart: React.FC<MaintenanceChartProps> = ({
  className,
  data,
  animate = true,
  viewFrom,
  viewLength,

}) => {
  const chartElement = useRef(null);
  const settingsState = useSelector(settingsSelector);
  const location = useLocation();
  const { report } = useQueryString(location);

  useEffect(() => {
    if (chartElement.current) {
      // @ts-ignore
      chartElement.current?.render();
    }
  }, [settingsState]);

  const customizeTooltip = (arg: any) => ({
    text: `${arg.seriesName} jobs: ${arg.valueText}`
  });

  // const trimData = (data: MaintenanceData[], viewFrom: number, viewLength: number) => {
  //   return data.slice(viewFrom, viewLength);
  // }
  return (
    <div
      id="data-chart"
      className={className ? className : ''}
    >
      <Chart
        id="chart"
        dataSource={data.slice(viewFrom, viewFrom + viewLength)}
        ref={chartElement}
        animation={animate}
      >
        <Animation enabled={!report} />
        <CommonSeriesSettings argumentField="month" type="stackedBar" />
        <Series color={colourSequence[1]} valueField="completed" name="Complete" />
        <Series color={colourSequence[0]} valueField="incomplete" name="Incomplete" />
        {/* <ScrollBar visible={true} /> */}
        {/* <ZoomAndPan argumentAxis="pan" /> */}
        <ValueAxis position="left">
          <Label> <Font {...chartsFontProps} /> </Label>
          <Title text="Jobs" />
        </ValueAxis>
        {/* <ArgumentAxis  defaultVisualRange={{ startValue: getStartRange(data), endValue: getEndRange(data) }} > */}
        <ArgumentAxis>
          <Label> <Font {...chartsFontProps} /> </Label>
        </ArgumentAxis>
        <Legend
          verticalAlignment="bottom"
          horizontalAlignment="center"
          itemTextPosition="top"
        />
        <Tooltip
          enabled={true}
          location="edge"
          customizeTooltip={customizeTooltip}
        />
      </Chart>
      <div style={{ pageBreakBefore: "always" }}></div>
    </div>
  );
};

export const SLAMaintenanceChart: React.FC<MaintenanceChartProps> = ({
  className,
  data,
  animate = true,
  viewFrom,
  viewLength,
}) => {
  const chartElement = useRef(null);
  const settingsState = useSelector(settingsSelector);
  const location = useLocation();
  const { report } = useQueryString(location);

  useEffect(() => {
    if (chartElement.current) {
      // @ts-ignore
      chartElement.current?.render();
    }
  }, [settingsState]);

  const customizeTooltip = (arg: any) => ({
    text: `${arg.seriesName} jobs: ${arg.valueText}`
  });

  return (
    <div
      id="data-chart"
      className={className ? className : ''}
    >
      <Chart
        id="chart"
        dataSource={data.slice(viewFrom, viewFrom + viewLength)}
        ref={chartElement}
        animation={animate}
      >

        <Animation enabled={!report} />
        <CommonSeriesSettings argumentField="month" type="stackedBar" />
        <Series color={styles.blueDark} valueField="completedInSLA" name="Completed Within SLA" />
        <Series color={styles.yellow} valueField="completedOutsideSLA" name="Completed Outside SLA" />
        <Series color={styles.red} valueField="incomplete" name="Incomplete" />
        {/* <ScrollBar visible={true} /> */}
        {/* <ZoomAndPan argumentAxis="pan" /> */}
        <ValueAxis position="left">
          <Label> <Font {...chartsFontProps} /> </Label>
          <Title text="Jobs" />
        </ValueAxis>
        {/* <ArgumentAxis  defaultVisualRange={{ startValue: getStartRange(data), endValue: getEndRange(data) }} > */}
        <ArgumentAxis>
          <Label> <Font {...chartsFontProps} /> </Label>
        </ArgumentAxis>
        <Legend
          verticalAlignment="bottom"
          horizontalAlignment="center"
          itemTextPosition="top"
        />
        <Tooltip
          enabled={true}
          location="edge"
          customizeTooltip={customizeTooltip}
        />
      </Chart>
      <div style={{ pageBreakBefore: "always" }}></div>
    </div>
  );
};

export default MaintenanceChart;
