import React, { memo, useEffect, useState, useRef } from 'react';

// Own
import { ColumnProps } from 'components/ContractInFocus/Interfaces/dataGridColumn.interface';
import { VisitDataRow } from 'components/Schedulers/Interfaces/Schedule.interfaces';
import { PlannerHead } from './Components/PlannerHead';
import { PlannerBody } from './Components/PlannerBody';
import { sortPrintGridRecords } from './helpers/planner.helper';
import { Dictionary, Primitive } from "components/Common/Interfaces/Entity.interface";
import { APIPrivileges } from "services/Interface/Interface";

// Styles
import './Planner.scss';

interface PlannerGridProps {
    columnMeta: ColumnProps[];
    debug?: boolean;
    records?: VisitDataRow[];
    contractReportContext?: boolean;
    baseQueryParams?: Dictionary<Primitive>;
    fetchInfo: ({ args, query_params }: { args?: any, query_params?: any }) => Promise<{
        data: VisitDataRow[];
        metadata: any;
        permissions: APIPrivileges;
        totalCount?: number;
    }>
}

const PlannerGrid: React.FunctionComponent<PlannerGridProps> = ({
    debug,
    records,
    columnMeta,
    contractReportContext,
    fetchInfo,
    baseQueryParams,
}) => {

    const [sortedRecords, setSortedRecords] = useState<(VisitDataRow[] | undefined)>();
    const loaded = useRef(false);

    useEffect(() => {
        if (loaded.current || !records?.length) {
            fetchInfo({ query_params: baseQueryParams }).then(response => {
                sortPrintGridRecords(response.data);
                response.data && setSortedRecords(response.data);
                loaded.current = true;
            })
        } else {
            sortPrintGridRecords(records)
            records && setSortedRecords(records);
            loaded.current = true;
        }
        sortPrintGridRecords(records)
        records && setSortedRecords(records);
    }, [fetchInfo, baseQueryParams, records])

    return <>
        {sortedRecords?.length ? (
            <table
                id="PrintDataGrid"
                className="specialistPlannerViewGrid"
                style={{
                    fontSize: 12,
                    width: '100%'
                }}>
                <PlannerHead columnMeta={columnMeta} contractReportContext={contractReportContext} />
                <PlannerBody
                    columnMeta={columnMeta}
                    records={sortedRecords}
                    contractReportContext={contractReportContext}
                />

            </table>
        ) : null}
    </>
}

export default memo(PlannerGrid);