import styled from 'styled-components';
import styles from '../../../../styles/app/common/variables.scss'
// import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const DetailWrapper = styled.div`
  padding-top: 1rem;
  display: flex;
  height: 600px;
`;

//554px;
export const FormWrapper = styled.div`
  flex-basis: 30%;
  //min-width: 350px; 
  padding: 0 2rem 1rem 1rem;
  .MuiFormControl-root {
    width: 100%;
  }
  @media print {
    padding: 0;
  }
`;

export const ReportsWrapper = styled.div`
  padding-top: 1rem;
  width:70%;
  .MuiFormControl-root {
    width: 100%;
  }
`;

// flex-direction: row;
// justify-content: space-between;
// display: flex;  
export const MainControls = styled.div`
  padding: 1rem 0;
    .booked-checkbox.MuiFormControlLabel-root {
      align-items: start;
      margin-left: 0px
    }
    .booked-checkbox .MuiButtonBase-root.MuiIconButton-root.MuiCheckbox-root {
      margin: 2px;
      margin-left: -2px;
      padding: 0;
    } 
}
`;


export const NotesControls = styled.div`
  .visit-notes {
    width: 100%;
  }
`;

export const ActionsWrapper = styled.div`
  text-align: right;
  padding-top: .5rem;
`;

export const NoReportWrapper = styled.div`
  min-width: 628px;
  background-color: ${styles.grayLighter};
  color: ${styles.grayDarker};
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  .MuiSvgIcon-root {
    font-size: 5rem;
  }
`;

