import React, { useState, useEffect } from 'react';
import DataGrid, { Column, Paging, Editing, Form } from 'devextreme-react/data-grid';
import { SelectBox } from 'devextreme-react';
import { CheckBox } from 'devextreme-react/check-box';
import { useSelector, useDispatch } from 'react-redux';
import { Item } from 'devextreme-react/form';
import { WarningHint } from "components/Common/Components/InfoHint/InfoHint";


import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

// Own
import FileUploader from 'components/Common/Components/Input/FileUploader.js';
import * as AWDocumentServices from 'components/ContractInFocus/Financials/Services/AWDocumentServices.js';

import { IFinancials } from "components/ContractInFocus/Financials/interfaces/financials.interface";
import { profileOrganisationSelector } from 'components/Profile/Selectors/Profile.selector';
import { addNotification } from 'components/Notification/Actions/Notification.actions';

// Styles
//import { AWPOUploadWrapper, AWUploadedFilesWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { AWPOUploadWrapper, AWUploadedFilesWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { getColumnProps } from 'helpers/DataGrid/DataGridColumn.helper';
import { NOTIFICATION_SUCCESS } from 'components/Notification/Constants/constants';
import { FileTypeIcon, FileSourceIcon } from 'components/Common/Components/FileTypeIcon/FileTypeIcon';
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
interface AWDocumentsProps {
    aw: IFinancials;
    portfolio?: HydratedPortfolio
    columnsList: any;
    docTypeOverride?: string;
    setPOUploaded?: Function;
    limit?: number;
}

const AWDocuments = ({ aw, columnsList, docTypeOverride, setPOUploaded, limit, portfolio }: AWDocumentsProps) => {

    const initialNewReportState = { internal: false, aw_document_type: docTypeOverride && docTypeOverride.length ? docTypeOverride : '' };

    const [newReport, setNewReport] = useState(initialNewReportState);
    const [permitUpload, setPermitUpload] = useState(true);
    const [awDocsMetaInfo, setAWDocsMetaInfo] = useState<any>();
    //const [awDocsPrivileges, setAWDocsPrivileges] = useState<any>();
    const [datasource, setDatasource] = useState();

    const dispatch = useDispatch();

    const selectProfileOrganisationFromState = useSelector(profileOrganisationSelector);

    const isReadyToCreate = (): boolean => !!newReport.aw_document_type && permitUpload;

    const shouldShowInternalAccess = !!awDocsMetaInfo?.meta.internal_access_only && !awDocsMetaInfo?.meta.internal_access_only?.read_only;

    const onUpload = async (file: any, progress: any) => {
        const fd = new FormData();
        fd.append("file", file);
        fd.append("aw", `${aw.id}`);
        fd.append("aw_document_type", newReport.aw_document_type);
        fd.append("in_app_viewing_location", window.location.toString());
        if (shouldShowInternalAccess) {
            fd.append("internal_access_only", `${newReport.internal}`);
        }
        fd.append("author_organisation", `${selectProfileOrganisationFromState}`);
        const response = await AWDocumentServices.addAWDocumentApi(aw.id, fd, portfolio?.id);

        if (response) {
            // @ts-ignore
            datasource && datasource.reload();
            setPermitUpload(false);
            setTimeout(() => setPermitUpload(true), 50);
            dispatch(addNotification({ message: 'Report uploaded successfully', type: NOTIFICATION_SUCCESS }));
            setNewReport(initialNewReportState);
        }
    }

    function handleOnCellPrepared(e: any) {
        if (e.rowType === 'data' && e.column.command === 'edit')
            if (e.row.data.fp_file_id != null) {
                e.cellElement.find('a.dx-link-delete').remove();
            }
    }

    const handleInternalChange = (event: any) => {
        setNewReport({ ...newReport, internal: event.value });
    }

    const handleDocumentTypeChange = (event: any) => {
        setNewReport({ ...newReport, aw_document_type: event.value });

    }

    useEffect(() => {
        const custom = new CustomStore({
            key: "id",
            load: loadOptions => AWDocumentServices.getAWDocumentsApi(aw.id, setAWDocsMetaInfo, docTypeOverride, portfolio?.id),
            // @ts-ignore
            remove: id => AWDocumentServices.deleteAWDocumentApi(aw.id, id, portfolio?.id),
            update: (id, values) => AWDocumentServices.updateAWDocumentApi(aw.id, id, values, portfolio?.id)
        });


        setDatasource(
            // @ts-ignore
            new DataSource({
                store: custom
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cellRenderFileType = (row: any) => <FileTypeIcon fileType={row.data.mime_type} />

    const cellRenderFileSource = (row: any) => <FileSourceIcon fileSource={row.data.source} />

    function headerCellRenderInternalOnly(columnComponent: any) {
        return <>
            <WarningHint
                message={"The settings in this column will not currently have any effect as exposing Focal Point Financial documents is switched off for the associated contract."}
            />
            &nbsp;
            <span className='columnHeadingText'>{columnComponent?.column?.caption}</span>
        </>
    }

    const handleDownload = (component: any) => {
        window.open(component.row.data.download_link);
    }

    const handleOpen = (component: any) => {
        window.open(component.row.data.file, '_blank');
    }

    const hasMeta = () => awDocsMetaInfo?.meta && !!Object.keys(awDocsMetaInfo?.meta).length

    //@ts-ignore
    const underLimit = () => !limit || !awDocsMetaInfo || awDocsMetaInfo.misc.numDocs < limit;

    const docsPresent = () => awDocsMetaInfo && awDocsMetaInfo.misc.numDocs && awDocsMetaInfo.misc.numDocs > 0;

    setPOUploaded && setPOUploaded(docsPresent());

    //@ts-ignore
    // console.log('datasource: ', datasource?.items(), ' limit: ', limit);

    // console.log('docs Meta: ', awDocsMetaInfo);

    // console.log('docsPresent: ', docsPresent());

    const editAndDeleteActions = [];//canWrite ? ['edit', 'delete'] : [];
    const canPut = awDocsMetaInfo?.permissions.PUT;
    const canPost = awDocsMetaInfo?.permissions.POST;
    const canDelete = awDocsMetaInfo?.permissions.DELETE;
    canPut && editAndDeleteActions.push('edit');
    canDelete && editAndDeleteActions.push('delete');
    canPost && editAndDeleteActions.push('create');

    const handleEditingStart = (e: any) => {
        e.cancel = e.data.fp_file_id && e.column.dataField == 'description';
    }

    //console.log('docs Meta: ', awDocsMetaInfo);

    return <AWUploadedFilesWrapper>
        <DataGrid className={docsPresent() ? "awReportsGridContainer" : "awReportsGridContainer hide"} //dx-datagrid-jt"
            dataSource={datasource}
            columnAutoWidth={true}
            showBorders={true}
            height="100%"//{canWrite ? 320 : '100%'}
            rowAlternationEnabled={true}
            onEditingStart={handleEditingStart}
            onCellPrepared={handleOnCellPrepared}
        >
            <Paging enabled={false} />
            <Editing
                mode="cell"
                allowUpdating={canPut}
                allowDeleting={canDelete}
            >

                {
                    !docTypeOverride && <Form>
                        <Item dataField="aw_document_type" />
                        <Item dataField="notes" />
                        {shouldShowInternalAccess &&
                            <Item dataField="internal_access_only" />
                        }
                    </Form>
                }
            </Editing>

            {hasMeta()
                ?
                columnsList.map((column: any) => {
                    if (column.dataField === "internal_access_only" && shouldShowInternalAccess && !aw.expose_aw_focal_point_documents) {
                        return <Column
                            key={column.dataField}
                            {...getColumnProps(column.dataField, awDocsMetaInfo.putMeta)}
                            visible={column.visible}
                            width={column.width}
                            caption={column.caption}
                            headerCellRender={headerCellRenderInternalOnly}
                        />
                    } else {
                        return <Column
                            key={column.dataField}
                            {...getColumnProps(column.dataField, awDocsMetaInfo.putMeta)}
                            visible={column.visible === false ? false : ((column.dataField !== 'internal_access_only' || shouldShowInternalAccess))}
                            width={column.width}
                            caption={column.caption}
                        />
                    }
                }

                )
                : null}

            <Column
                visible={hasMeta()}
                caption="Type"
                dataField="mime_type"
                cellRender={cellRenderFileType}
                alignment="center"
                width={60}
            />
            <Column
                visible={hasMeta()}
                caption="Source"
                dataField="source"
                cellRender={cellRenderFileSource}
                alignment="center"
                width={80}
            />
            {/* <img alt="PDF file type" className="pdf-icon" src="./img/pdf-icon-small2.png" /> */}
            <Column
                alignment="left"
                type="buttons"
                width={140}
                visible={hasMeta()}
                buttons={[{
                    hint: 'Download',
                    icon: 'download',
                    onClick: (component: any) => handleDownload(component)
                }, {
                    hint: 'Open',
                    icon: 'fa fa-eye',
                    onClick: (component: any) => handleOpen(component)
                }, ...editAndDeleteActions]} />

        </DataGrid>
        {hasMeta() && canPost && underLimit() ? (
            <AWPOUploadWrapper
                internalAccessVisible={shouldShowInternalAccess}
                className="filesUploadWrapper"
            >
                {/* <h5>Upload {docTypeOverride ? docTypeOverride : "Document"}</h5> */}
                {
                    !docTypeOverride &&
                    <div className="dx-field">
                        <div className="dx-field-label">{awDocsMetaInfo.meta.aw_document_type.label}</div>
                        <div className="dx-field-value">
                            <SelectBox
                                valueExpr="value"
                                displayExpr="display_name"
                                value={newReport.aw_document_type}
                                dataSource={awDocsMetaInfo.meta.aw_document_type.choices}
                                onValueChanged={handleDocumentTypeChange} />
                        </div>
                    </div>
                }
                {shouldShowInternalAccess &&
                    <div className="dx-field">
                        <div className="dx-field-label">{awDocsMetaInfo.meta.internal_access_only?.label}</div>
                        <div className="dx-field-value">
                            <CheckBox
                                value={newReport.internal}
                                defaultValue={false}
                                onValueChanged={handleInternalChange}
                            />
                        </div>
                    </div>
                }
                {isReadyToCreate() ? (
                    <FileUploader
                        labelIdle="Upload Document Here"
                        onUpload={onUpload}
                        ready={true}
                    />
                ) : null
                }
            </AWPOUploadWrapper>
        ) : null}
    </AWUploadedFilesWrapper>
}

export default AWDocuments;