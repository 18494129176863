import { currencyColumnFormat } from 'constants/grid.constants';
import rightVisibilityMenuStories from 'stories/right-visibility-menu/right-visibility-menu.stories';
export const financialsColumns = [
  { name: "aw_number", width: "10%", required: true },
  { name: "title", width: "25%", required: true },
  { name: "quote_value", width: "7%", format: currencyColumnFormat },
  { name: "client_po", width: "9%" },
  { name: "status", width: "9%" },
  { name: "date_requested", width: "8%", required: true },
  { name: "quote_raised_date", width: "8%" },
  { name: "date_submitted", width: "8%" },
  { name: "completed_date", width: "8%" },
  { name: "invoice_date", width: "8%" },
  // { name: "po_date", width: 120 },
  // { name: "invoice_number", width: 120 },

  // { name: "client_name", width: 100 }
]

export const quoteItemColumns = [
  {
    header: 'Description',
    dataField: 'detail',
    headerStyle: {
      width: "60%",
      minWidth: 275
    }
  },
  // {
  //   header: 'Cost Centre',
  //   dataField: 'cost_centre',
  //   headerStyle: {
  //     width: 100,
  //     textAlign: "center",
  //   },
  // },
  {
    header: 'Hours',
    dataField: 'qty',
    headerStyle: {
      minWidth: 90,
      width: "8%",
      textAlign: "center",
    },
    cellStyle: {
      textAlign: "center"
    }
  },
  {
    header: 'Hourly Rate',
    dataField: 'rate',
    headerStyle: {
      minWidth: 90,
      width: "8%",
      textAlign: "center",
    },
    cellStyle: {
      textAlign: "center"
    },
    currency: true,
  },
  {
    header: 'Supplier Cost',
    dataField: 'value',
    className: "currency",
    headerStyle: {
      minWidth: 120,
      width: "8%"
    },
    currency: true,
  },
  {
    header: 'Mark Up',
    dataField: 'margin',
    currency: true,
    className: "currency",
    headerStyle: {
      minWidth: 90,
      width: "8%"
    },
    // cellStyle: {
    //   textAlign: "right"
    // }
  },
  {
    header: 'Total',
    dataField: 'total',
    format: currencyColumnFormat,
    currency: true,
    className: "currency",
    headerStyle: {
      textAlign: "right",
      minWidth: 100,
      width: "8%"
    },
    cellStyle: {
      textAlign: "right"
    }
  }
];

export const paperViewQuoteItemColumns = [
  {
    dataField: 'detail',
  },
  {
    dataField: 'total',
    format: currencyColumnFormat,
    currency: true
  }
];
