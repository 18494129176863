import {
  OPEN_LIST_MODE,
  OPEN_RECORD,
  OPEN_RECORD_CHANGE_TAB,
  OPEN_NEW_RECORD_CHANGE_TAB,
  OPEN_NEW_RECORD
} from "../Actions/adminPanel.actions";
import { mode as dataMode } from "../../Common/Consts/DataMode";
import { apiErrorsKey, invalidFieldsKey } from "./adminPanelData.reducer";

const initialState = {
  panels: {}
};

const adminPanelOpenReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_LIST_MODE:
      return {
        ...state,
        panels: {
          ...state.panels,
          [action.panelId]: {
            ...state.panels[action.panelId],
            mode: dataMode.list,
            meta: undefined,
            data: undefined,
            recordId: undefined
          }
        }
      };

    case OPEN_RECORD:
      return {
        ...state,
        panels: {
          ...state.panels,
          [action.panelId]: {
            ...state.panels[action.panelId],
            id: action.panelId,
            mode: dataMode.view,
            meta: action.meta,
            data: action.data,
            initialData: action.data,
            panelType:
              action.panelType || state.panels[action.panelId].panelType
          }
        }
      };

    case OPEN_RECORD_CHANGE_TAB:
      return {
        ...state,
        panels: {
          ...state.panels,
          [action.panelId]: {
            ...state.panels[action.panelId],
            id: action.panelId,
            panelType: action.panelType,
            mode: dataMode.preload,
            meta: action.meta,
            data: action.data,
            initialData: action.data,
            recordId: action.recordId,
            [apiErrorsKey]: undefined,
            [invalidFieldsKey]: undefined
          }
        }
      };

    case OPEN_NEW_RECORD_CHANGE_TAB:
      return {
        ...state,
        panels: {
          ...state.panels,
          [action.panelId]: {
            ...state.panels[action.panelId],
            id: action.panelId,
            panelType: action.panelType,
            mode: dataMode.new,
            meta: action.meta,
            data: action.data,
            link: action.link,
            linkId: action.linkId,
            initialData: {}
          }
        }
      };

    case OPEN_NEW_RECORD:
      return {
        ...state,
        panels: {
          ...state.panels,
          [action.panelId]: {
            ...state.panels[action.panelId],
            id: action.panelId,
            mode: dataMode.new,
            data: action.newRecord,
            meta: action.meta,
            recordId: undefined,
            initialData: {}
          }
        }
      };

    default:
      return state;
  }
};

export default adminPanelOpenReducer;
