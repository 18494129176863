import React, { useState, useReducer } from 'react';

// Own
import DocumentsGrid from "components/Common/Components/DocumentsGrid/DocumentsGrid";
import { ContractDocumentFilesWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { GeneralDocsGridWrapper } from 'components/SiteDocuments/Components/ContractDocumentsViewGrid/ContractDocumentGridStyles';
import { ColumnPropGroup } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';
import { getEventLogNestedAccidentDocumentsRoute, getEventLogNestedNearMissDocumentsRoute } from "components/AdminPanel/HAS/HASIncidentServices";

const accidentDocumentColumns: ColumnPropGroup = {
    'notes': {
        caption: 'Description',
        alignment: 'start',
        width: '50%'
    },
    "h_a_s_accident_document_type": { width: '225px', caption: 'Document Category' },
};

const nearMissDocumentColumns: ColumnPropGroup = {
    'notes': {
        caption: 'Description',
        alignment: 'start',
        width: '50%'
    },
    "h_a_s_near_miss_document_type": { width: '225px', caption: 'Document Category' },
};

interface HASEventDocsProps {
    data: any;
    parentType: 'accident' | 'near_miss',
}

const ShowWithEventLogDocs = ({ data, parentType }: HASEventDocsProps) => {

    const id: string = data.id;
    console.log('data: ', data);
    const basePath = parentType == "accident" ? getEventLogNestedAccidentDocumentsRoute(data.accident) : getEventLogNestedNearMissDocumentsRoute(data.near_miss);
    const [fetchFilters, setFetchFilters] = useState();
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 0);

    const columns = parentType == "accident" ? accidentDocumentColumns : nearMissDocumentColumns;

    return <GeneralDocsGridWrapper>
        {<ContractDocumentFilesWrapper height={30}>
            <DocumentsGrid
                listBasePath={basePath}
                hideSearch
                hideFilters
                hideSource
                columnsConfig={columns}
                fetchFilters={fetchFilters}
                editMode="row"
                refreshSignal={mustRefresh}
                actionColumnsWidth={100}
                updateRecordMetaBeforeEdit
            />
        </ContractDocumentFilesWrapper>}
    </GeneralDocsGridWrapper>
}

export default ShowWithEventLogDocs;