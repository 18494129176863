import React, { useState, useEffect, useRef, useCallback, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import path from "path";

// Own
import API from "services/API/API";
import { gridTheme, rowFlexTheme } from "components/ContractInFocus/Styles/CommonStyles";
import FileUploader from 'components/Common/Components/Input/FileUploader.js';
import { profileOrganisationSelector } from 'components/Profile/Selectors/Profile.selector';
import { addNotification } from 'components/Notification/Actions/Notification.actions';
import { AutoInput } from 'components/Common/Components/AutoInput/AutoInput';
import { FieldsFormConfig } from "components/Common/Components/DocumentsGrid/DocumentsGrid.interface";
import { PreFlightListInfo, Primitive } from "components/Common/Interfaces/Entity.interface";
import { formValueConsideredMissing } from "store/Common/Helpers/commonHelpers";
import MatConfirmationDialog from 'components/Common/Components/Material/MatConfirmationDialog/MatConfirmationDialog';

// Styles
import { GeneralDocsUploadWrapper, GeneralUploadedFilesWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import "components/Common/Components/DocumentsGrid/Styles/grids.scss";
import { NOTIFICATION_SUCCESS } from 'components/Notification/Constants/constants';
interface LimitController {
    [documentType: string]: (documentType: string, parentId: string) => boolean;
}

export interface FormValues {
    [field: string]: any; //represents field: actual value.  This must be generic as sometimes
    // the form value will actually be a selected object, and processing of selection will be done server side
}


interface GeneralDocsUploaderProps {
    urlContext: string;
    preFlightInfo: PreFlightListInfo;
    fieldConfigs: FieldsFormConfig;
    limitController?: LimitController;
    acceptedFileTypes?: string[];
    acceptedFileTypesModifier?: (currentFormValues: FormValues, currentlyAcceptedTypes?: string[]) => string[] | undefined;
    docTypeOverride?: string;
    reset?: (reset?: boolean) => void;
    allowNoParent?: boolean;
    gridId?: string;
    zIndex?: number;
    inAppViewingLocationOverride?: string;
    initialState?: FormValues;
    fileAttribute?: string;
    setData?: React.Dispatch<React.SetStateAction<any>>;
    surrogatePortfolioId?: string | number;
    showIfNotEditable?: boolean;
    className?: string;
    checkBeforeUploadMsg?: string;
}

const defaultInitialState = {
    file: undefined
}

const DocumentsUploader = (
    {
        urlContext,
        preFlightInfo,
        fieldConfigs,
        acceptedFileTypes,
        acceptedFileTypesModifier,
        limitController,
        docTypeOverride,
        allowNoParent,
        reset,
        gridId,
        zIndex,
        inAppViewingLocationOverride,
        initialState,
        fileAttribute,
        setData,
        surrogatePortfolioId,
        showIfNotEditable,
        className,
        checkBeforeUploadMsg
    }: GeneralDocsUploaderProps) => {

    const initialDocFormState = initialState || defaultInitialState;
    //const initialDocFormState = {};

    const metaForCreate = preFlightInfo.meta;
    const { canCreate, documentLimits } = preFlightInfo;

    const [basePath, setBasePath] = useState(urlContext);
    const [metaForUpdate, setMetaForUpdate] = useState<any>();
    const [parentChoice, setParentChoice] = useState<Primitive>();
    const formValuesRef = useRef<FormValues>({});
    const [formValuesState, setFormValuesState] = useState(formValuesRef.current);
    const currentFocus = useRef<string>();
    const [isReadyToCreate, setIsReadyToCreate] = useState(false);
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 1);

    const [currentAcceptedFileTypes, setCurrentlyAcceptedFileTypes] = useState(acceptedFileTypes);
    //const [incremented, reset] = useState<number>(1);

    const parentChoiceFields = Object.keys(fieldConfigs).filter(x => fieldConfigs[x].parentChoice == true);
    const generalLimit = documentLimits?.all;
    const generalLimitExceeded = generalLimit ? generalLimit?.currentCount <= generalLimit.max : false;

    const handleUploadProgress = (progressEvent: any) => {
        // NB this upload progress is not for the server - it's the upload to the filepond element prior to transfer to the server (it seems)
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        // if (percentCompleted < 95) {
        //     setDocumentUploaded(false);
        // }
        // if (percentCompleted == 100) {
        //     formValuesRef.current = initialDocFormState;
        //     setIsReadyToCreate(false);
        //     setTimeout(() => {
        //         setDocumentUploaded(true);
        //         setUploaded(false);
        //     }, 100);
        //     dispatch(addNotification({ message: 'Document uploaded successfully', type: NOTIFICATION_SUCCESS }));
        //     if (!mustRefresh) {
        //         forceUpdate()
        //         // setTimeout(() => {
        //         //     console.log('going to refresh now...');
        //         //     reset && reset();
        //         // }, 1000);

        //     }
        // }
    }

    const uploadConfig = {
        onUploadProgress: handleUploadProgress
    }

    const onChangeSelectParent = (value: Primitive) => {
        setParentChoice(value);
    }

    useEffect(() => {
        if (parentChoice) {
            const parentPath = path.join(urlContext, `${parentChoice}`)
            setBasePath(parentPath);
        }
    }, [parentChoice, urlContext]);

    const confirmationInitialState = useRef({ open: false, agreeCallback: () => { }, message: '' });

    const [confirmationDialog, setConfirmationDialog] = useState(confirmationInitialState.current);

    const apiAdd = (formData: FormData) => API.post(basePath, formData, uploadConfig);
    const dispatch = useDispatch();
    const [agreeToCheckBeforeUpload, setAgreeToCheckBeforeUpload] = useState(false);

    const setUpConfirmation = useCallback((recordId?: string): void => {
        checkBeforeUploadMsg && setConfirmationDialog({
            open: true,
            agreeCallback: () => {
                setAgreeToCheckBeforeUpload(true);
                setConfirmationDialog(confirmationInitialState.current);
                setIsReadyToCreate(true);
            },
            message: checkBeforeUploadMsg,
        });
    }, [checkBeforeUploadMsg]);

    const onChangeFormValues = useCallback((newValues: FormValues) => {
        // add reaction to change parent choice here...
        const updatedFormValues = { ...formValuesRef.current, ...newValues }
        let sideEffectsRan = [];
        formValuesRef.current = updatedFormValues;
        const newValueKey = Object.keys(newValues)[0];
        const missingValues = Object.keys(metaForCreate).filter(
            (k) => {
                const meta = metaForCreate[k];
                const config = fieldConfigs[k];
                const fV = formValuesRef.current[k];
                const missing = formValueConsideredMissing({
                    formValue: fV,
                    config,
                    meta,
                    extraOrCondition: config?.parentChoice && !allowNoParent,
                    extraAndCondition: k != "file"
                });
                //const missing = ((meta.required || config?.forceRequired || (config?.parentChoice && !allowNoParent)) && (typeof (fV) == "undefined" || typeof (fV) == "string" && fV.trim().length == 0) && k !== "file");
                return missing;
            }
        );
        if (parentChoiceFields.includes(newValueKey)) {
            //console.log('got parent choice field: ', newValueKey, newValues[newValueKey]);
            onChangeSelectParent(newValues[newValueKey]);
        }
        if (acceptedFileTypesModifier) {
            const newAcceptedFileTypes = acceptedFileTypesModifier(formValuesRef.current, currentAcceptedFileTypes);
            setCurrentlyAcceptedFileTypes(newAcceptedFileTypes);
        }
        for (let config of Object.keys(fieldConfigs)) {

            if (typeof (fieldConfigs[config].sideEffect) !== "undefined") {
                //@ts-ignore
                const sideEffectRan = fieldConfigs[config].sideEffect(updatedFormValues, fieldConfigs, onChangeFormValues);
                if (sideEffectsRan) {
                    sideEffectsRan.push(config)
                }
            }
        }
        if (sideEffectsRan.length > 0) {
            setFormValuesState(formValuesRef.current); //necessary to make form inputs re-render/compute - for sideeffects.
            sideEffectsRan = [];
        }
        let checkIsReady = missingValues.length == 0 && canCreate && !generalLimitExceeded;

        if (checkBeforeUploadMsg) {
            if (checkIsReady && !agreeToCheckBeforeUpload) {
                setUpConfirmation();
            }
            checkIsReady = checkIsReady && agreeToCheckBeforeUpload;
        }
        // console.log('missing values for doc upload: ', missingValues);
        // console.log('canCreate: ', canCreate);
        // console.log('documentUploaded: ', documentUploaded);
        // console.log('generalLimitExceeded: ', generalLimitExceeded);
        setIsReadyToCreate(checkIsReady);
    }, [
        acceptedFileTypesModifier,
        agreeToCheckBeforeUpload,
        allowNoParent,
        canCreate,
        checkBeforeUploadMsg,
        currentAcceptedFileTypes,
        fieldConfigs,
        generalLimitExceeded,
        metaForCreate,
        parentChoiceFields,
        setUpConfirmation]);


    const selectProfileOrganisationFromState = useSelector(profileOrganisationSelector);

    const shouldShowInternalAccess = metaForCreate?.internal_access_only && !metaForCreate?.internal_access_only?.read_only;

    const onUpload = async (file: any, progress: any) => {
        const fd = new FormData();
        fd.append(fileAttribute || "file", file);
        Object.keys(formValuesRef.current).filter(k => formValuesRef.current[k] !== undefined).map(k => {
            const v = formValuesRef.current[k]
            // we convert to a string explicitly here because that is all that (or a blob) is all that form data 
            // accepts/expects. However '' will be converted to null on send, so null should be converted to ''.
            fd.append(k, `${v === null ? '' : v}`);
        })
        fd.append("in_app_viewing_location", inAppViewingLocationOverride ? inAppViewingLocationOverride : window.location.toString());
        fd.append("author_organisation", `${selectProfileOrganisationFromState}`);
        if (surrogatePortfolioId) {
            fd.append("surrogate_portfolio", `${surrogatePortfolioId}`);
        }
        apiAdd(fd)
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    // @ts-ignore
                    setData && setData(response.data?.data);
                    formValuesRef.current = initialDocFormState;
                    setIsReadyToCreate(false);
                    dispatch(addNotification({ message: 'Document uploaded successfully', type: NOTIFICATION_SUCCESS }));
                    forceUpdate();
                    reset && reset();
                }
            }
            ).catch((e) => {
                //formValuesRef.current = initialDocFormState;
                forceUpdate();
                reset && reset();
                throw (e);
            })
    }

    const hasMeta = () => metaForCreate && !!Object.keys(metaForCreate).length;

    const docsPresent = () => metaForUpdate && metaForUpdate.misc.numDocs && metaForUpdate.misc.numDocs > 0;

    return < >
        <MatConfirmationDialog
            onAgree={confirmationDialog.agreeCallback}
            onDisagree={() => {
                setConfirmationDialog(confirmationInitialState.current);
                formValuesRef.current = {};
                forceUpdate();
            }}
            open={confirmationDialog.open}
            actions={{ agree: 'Yes', disagree: 'No' }}
        >
            {confirmationDialog.message}
        </MatConfirmationDialog>
        {hasMeta() && (canCreate || showIfNotEditable) ? (
            <GeneralDocsUploadWrapper
                theme={gridTheme}
                internalAccessVisible={shouldShowInternalAccess}
                className={`filesUploadWrapper autoFieldWrapper ${className ? className : ''}`}
                id={gridId}
            >
                {
                    Object.keys(fieldConfigs).map((dataField) => {
                        const fieldConfig = fieldConfigs[dataField];
                        return <AutoInput
                            zIndex={zIndex || 1200}
                            key={dataField}
                            dataField={dataField}
                            fieldConfig={fieldConfig}
                            documentLimits={documentLimits}
                            fieldMeta={metaForCreate[dataField]}
                            formValuesRef={formValuesRef}
                            onChangeFormValues={onChangeFormValues}
                            currentFocus={currentFocus}
                        />
                    })
                }
                <FileUploader
                    labelIdle={generalLimitExceeded ? "Document Upload Limit Reached" : "Upload Document Here"}
                    onUpload={onUpload}
                    ready={isReadyToCreate}
                    allowMultiple={false}
                    refreshSignal={mustRefresh}
                    acceptedFileTypes={currentAcceptedFileTypes}
                />
            </GeneralDocsUploadWrapper>
        ) : null}
    </>
}

export default React.memo(DocumentsUploader);