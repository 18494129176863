import React, { useCallback, useState, useRef, useReducer } from 'react';
import { IHASEventLog, IHASAccident } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";
import { FieldMetaGroup } from 'components/Common/Interfaces/Entity.interface';
import { APIPrivileges } from "services/Interface/Interface";
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { newGetPayloadFromRef } from 'services/API/API.helper';
import { simpleUpdateAccidentEventLog } from "components/AdminPanel/HAS/HASIncidentServices";
import GeneralEntityForm, { generateStandardGeneralActionButtonDefinitions } from "components/Common/Components/GeneralEntityForm/GeneralEntityForm";
import { FormErrorsType } from "store/Common/Interfaces/Common.interface";

interface AccidentEventLogProps {
    accident: IHASAccident;
    data: IHASEventLog;
    meta: FieldMetaGroup;
    permissions: APIPrivileges;
    formValuesRef: React.MutableRefObject<any>;
}

const eventLogFormConfig: FieldsFormConfig = {
    accident: { hidden: true },
    details: {},
    resolution: {},
    resolved_on: { labelPlacement: 'start' },
    investigation_completed: { labelPlacement: 'start' },
    publish: { labelPlacement: 'start' },
};

function AccidentEventLogView({
    accident,
    data,
    meta,
    permissions,
    formValuesRef,
}: AccidentEventLogProps) {
    const [thisData, setThisData] = useState(data as IHASEventLog);
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 1); // should start as 1 to ensure file is displayed
    const [formErrors, setFormErrors] = useState<FormErrorsType>({});
    const formLevelSharedSpace = useRef({});
    const handleSave = useCallback((recordId?: string, callback?: any) => {
        if (meta) {
            const payload = newGetPayloadFromRef({ ref: formValuesRef, metaData: meta })
            recordId
                && simpleUpdateAccidentEventLog({
                    accidentId: accident.id,
                    id: recordId,
                    payload: payload,

                }).then((response) => setThisData(response.data))
        } else {
            console.log('no put meta!')
        }
    }, [meta, formValuesRef, accident.id]);

    const getButtons = useCallback(() => {
        const theseButtons = generateStandardGeneralActionButtonDefinitions({
            handleSave: handleSave,
            formErrors: formErrors,
            showDelete: false,
        });
        return theseButtons;
    }, [handleSave, formErrors]);

    return <>
        <GeneralEntityForm
            buttons={getButtons()}
            formValuesRef={formValuesRef}
            useDefaultRevertChanges
            refreshSignal={mustRefresh}
            formLevelSharedSpace={formLevelSharedSpace.current}
            initialData={thisData}
            canWrite={!!permissions.PUT}
            formFieldsConfig={eventLogFormConfig}
            meta={meta}
            dispatchRefreshContext={forceUpdate}
            gridClass="accidentEventLogForm"
            formErrors={formErrors}
            setFormErrors={setFormErrors}
        />
    </>
}

export default React.memo(AccidentEventLogView);