import React from "react";

// Own
import DataGrid, {
    Column,
    Grouping,
    GroupItem,
    Summary
} from "devextreme-react/data-grid";
import { ContractsForRolesListI } from 'components/AdminPanel/Contracts/Interfaces/Contract.interface';


interface PrimaryContractRolesViewProps {
    panelId: string;
    id: any;
    personContractRoles: ContractsForRolesListI[];
}

const PrimaryContractRolesView: React.FunctionComponent<PrimaryContractRolesViewProps> = ({
    panelId,
    id,
    personContractRoles
}) => {
    return (
        <DataGrid
            dataSource={personContractRoles}
            key="id"
        >
            <Summary>
                <GroupItem column="role" summaryType="count"></GroupItem>
            </Summary>
            <Grouping autoExpandAll={false} />
            <Column dataField="id" visible={false} />
            <Column dataField="site_name" caption="Site Name" />
            <Column dataField="contract_ref" caption="Contract Reference" />
            <Column dataField="contract_with_name" caption="Contract With" />
            <Column dataField="role" caption="Role" groupIndex={0} />
        </DataGrid>
    );
};

export default PrimaryContractRolesView;
