/* eslint-disable */
import React, { useState, memo, useEffect } from "react";
import { useSelector } from "react-redux";

// Own
import SiteGrid from "./Containers/SiteGrid/SiteGrid";
import { NoItems } from "../Common/Components/NoItems";
import {
  selectSitesSortedByFavourites,
  hasNoSitesSelector
} from "./Selectors/Sites.selectors";
import { selectHeader } from "../Layout/selectors/Layout.selectors";
import RightVisibilityMenu from "components/Common/Components/RightVisibilityMenu/RightVisibilityMenu";
import { sitesIndexVisibilitySelector } from "components/Profile/Selectors/Profile.selector";
import { defaultTogglePersonalSettings } from "components/Profile/Helpers/profileHelpers";
// styles
import { SiteWrapper } from "./SitesStyles";
//import { fetchProjectConfig } from "store/Common/Actions/common.actions.js";

const Sites = () => {
  const selectedSites = useSelector(selectSitesSortedByFavourites); // This is from the Redux State
  const selectedHeader = useSelector(selectHeader); // This is from the Redux State
  const sitesIndexVisibilityProfile = useSelector(sitesIndexVisibilitySelector);

  const [sitesState, setSitesState] = useState({}); // This is from the Local Component Store

  const matches = (value, site) =>
    site.name.toLowerCase().includes(value.toLowerCase()) ||
    `${site.site_ref}`.includes(value);

  const filterSites = (selectedHeader, selectedSites) => {
    const filteredSites = Object.keys(selectedSites).reduce((acc, key) => {
      matches(selectedHeader.search.toLowerCase(), selectedSites[key]) &&
        (acc[key] = selectedSites[key]);
      return acc;
    }, {});
    setSitesState(filteredSites);
  };

  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(fetchProjectConfig()); // this is only run here to ensure that some query is run on sites index, to trigger logout if unauthenticated
  // }, [dispatch])

  useEffect(() => {
    if (selectedHeader.search && selectedHeader.search.length > 0) {
      filterSites(selectedHeader, selectedSites);
    } else {
      setSitesState(selectedSites);
    }
  }, [selectedSites, selectedHeader]); // when redux changes let's update the local component

  //const hasSites = sites => Object.keys(sites).length > 0;
  const hasNoSites = useSelector(hasNoSitesSelector);

  return (
    <SiteWrapper>
      <RightVisibilityMenu
        //uses configuration fed into withPageContext - used for individual interface pages
        visibilitySettings={sitesIndexVisibilityProfile}
        defaultOnClick={defaultTogglePersonalSettings}
      />
      {hasNoSites ? (
        <NoItems caption="Sorry you haven't got any sites - please contact an admin" />
      ) : (
        <SiteGrid sites={sitesState}></SiteGrid>
      )}
    </SiteWrapper>
  );
};

export default memo(Sites);
