import { RightVisibilityMenuT } from "../../interfaces/personalContractSettings.interfaces";
import { GetRightVisibilityMenu } from "components/Common/Components/RightVisibilityMenu/Interfaces/RightContext.interfaces";


//export const financialsRightVisibilityMenu = (visibilitySettings: MainInFocusVisibilitySettings, contract: SiteContract, inReportContext?: boolean, frozenFor?: string): RightVisibilityMenuT => {
export const financialsRightVisibilityMenu = ({ visibilitySettings, contract, inReportContext, frozenFor }: GetRightVisibilityMenu): RightVisibilityMenuT => {
  let menu: any = {
    "New Works": {
      table: {
        value: visibilitySettings.show_new_works_financials_table,
        updateKey: 'show_new_works_financials_table'
      }
    },
    "Part Comp": {
      table: {
        value: visibilitySettings.show_part_comp_repair_financials_table,
        updateKey: 'show_part_comp_repair_financials_table'
      }
    },
    "Reactive Works": {
      table: {
        value: visibilitySettings.show_reactive_works_financials_table,
        updateKey: 'show_reactive_works_financials_table'
      }
    },
  }
  if (!frozenFor) {
    menu = {
      ...menu,
      "Unfiltered Works": {
        table: {
          value: visibilitySettings.show_unfiltered_financials_table,
          updateKey: 'show_unfiltered_financials_table',
          neverPrint: true,
        }
      }

    }
  }
  return menu;
}