import React from 'react';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import { openControlsStyles } from './rightContextButtonStyles';
import { MatIconButton } from 'components/Common/Components/Material/MatIconButton/MatIconButton';
import { DistributionListManagementButtonWrapper } from "components/Common/Components/RightVisibilityMenu/Components/RightContextButton/rightContextButtonStyles";
import styles from 'styles/app/common/variables.scss';


interface ReportDistributionListButtonProps {
    showSettings: boolean;
    snapshotNeedsToBePublished?: boolean;
    snapshotCouldBePublished?: boolean;
    onShowSettings?: () => void;
}

export default ({
    showSettings,
    snapshotNeedsToBePublished,
    snapshotCouldBePublished,
    onShowSettings
}: ReportDistributionListButtonProps) => {

    return (
        <DistributionListManagementButtonWrapper
            className={`animated ${showSettings ? 'fadeIn' : 'fadeOutUpShort'}`}
            hidden={!showSettings}
        >
            <MatIconButton onClick={onShowSettings} styles={{ backgroundColor: snapshotNeedsToBePublished ? styles.dangerDark : snapshotCouldBePublished ? styles.green : styles.darkBlue }}
            >
                <ContactMailIcon style={openControlsStyles} />
            </MatIconButton>
        </DistributionListManagementButtonWrapper>
    );
}


