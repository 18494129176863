import React, { memo } from 'react';

// Own
import { Dictionary, FieldMetaGroup } from '../../Interfaces/Entity.interface';
import { PrintDataGridVisibleColumns } from './Interfaces/printDataGrid.interfaces';
import { recordDateTime, recordDate } from '../../../Common/Utils/Dates';
import CheckIcon from '@material-ui/icons/Check';

// Styles
import './PrintDataGrid.scss';
import { toPounds } from "store/Common/Helpers/commonHelper.js";

interface PrintDataGridProps {
  debug?: boolean;
  visibleColumns: PrintDataGridVisibleColumns[];
  meta: FieldMetaGroup;
  records?: Dictionary<any>[];
  className?: string;
  showOnScreen?: boolean;
}

const PrintDataGrid: React.SFC<PrintDataGridProps> = ({
  debug,
  visibleColumns,
  meta,
  records,
  className,
  showOnScreen
}: PrintDataGridProps) => {

  if (debug) {
    console.log('Records', records);
    console.log('Meta', meta);
    console.log('VisibleColumns', visibleColumns);
  }

  const getValue = (record: Dictionary<any>, field: string, meta: FieldMetaGroup): string => {
    switch (meta[field].type) {
      case 'datetime':
        return record[field] ? recordDateTime(record[field]) : ''
      case 'date':
        return record[field] ? recordDate(record[field]) : ''
      case 'choice':
        return meta[field]?.choices?.find((choice) => choice.value === record[field])?.display_name ?? '';
      default:
        return record[field]
    }
  }

  type textAlignment = "right" | "left" | "center";

  const getAlignment = (field: string, meta: FieldMetaGroup): textAlignment => {
    switch (meta[field]?.type) {
      case 'boolean':
        return 'center';
      case 'decimal':
      case 'number':
        return 'right';
      default:
        return 'left';
    }
  }

  // TODO: remove print separation - done for demo
  return (
    <>
      {
        meta && Object.keys(meta).length ? (
          <table id="PrintDataGrid" className={`${className} ${debug || showOnScreen ? '' : 'no-screen'}`}>
            <thead>
              <tr>
                {visibleColumns.map((field) => (
                  <td
                    style={{ width: field.width, textAlign: getAlignment(field.name, meta) }}
                    key={`head${field.name}`}>{field.caption || meta[field.name]?.label}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {records?.map((record: Dictionary<any>, idx) => (
                <tr key={`${record.id}-${idx}`}>
                  {visibleColumns.map((field) => {
                    let thisValue = getValue(record, field.name, meta);
                    if (thisValue && field?.format?.currency === "GBP") {
                      thisValue = toPounds(thisValue)
                    }
                    return <td
                      style={{ width: field.width, textAlign: getAlignment(field.name, meta), color: (thisValue === "Quote Expired" && field.name.toLowerCase() === "status") ? 'red' : undefined }}
                      key={`${record.id}${field.name}`}>
                      {meta[field.name].type == 'boolean' && record[field.name] ? <CheckIcon style={{ height: "1.3rem" }}></CheckIcon> :
                        thisValue
                      }
                    </td>
                  }
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        ) : null
      }
    </>
  );
}

export default memo(PrintDataGrid);