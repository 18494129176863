import React, { useCallback, useEffect, useState } from 'react';

import "helpers/DataGrid/CustomPaginatorStyles.scss";

interface CustomPaginatorProps {
    gridRef: React.MutableRefObject<any>;
    refresh: number;
    elipseAfter?: number;
}

interface GetPaginatorNumberProps {
    arr: number[];
    currentIndex: number;
    nrange?: number;
}

function getPaginatorNumbers({ arr, currentIndex, nrange }: GetPaginatorNumberProps) {
    let thisRange = nrange || 2;
    const greaterNumbersLength = arr.length - (currentIndex + 1);
    const previousNumbersLength = currentIndex;
    let diffGreater = 0;
    let diffLesser = 0;
    let lowerBound = currentIndex - thisRange;
    let upperBound = currentIndex + thisRange;
    if (thisRange > greaterNumbersLength) {
        diffGreater = thisRange - greaterNumbersLength;
    }
    if (thisRange > previousNumbersLength) {
        diffLesser = thisRange - previousNumbersLength;
    }
    if (diffGreater && !diffLesser) {
        lowerBound = lowerBound - diffGreater;
    } else if (!diffGreater && diffLesser) {
        upperBound = upperBound + diffLesser;
    }
    lowerBound = lowerBound < 0 ? 0 : lowerBound;
    upperBound = upperBound > arr.length - 1 ? arr.length - 1 : upperBound;

    // return arr.length > thisRange && currentIndex > thisRange
    //     ? arr.slice(currentIndex - (thisRange + 1), currentIndex + thisRange)
    //     : arr.slice(0, currentIndex + thisRange);

    return arr.slice(lowerBound, upperBound + 1);
}

const CustomPaginator = ({ gridRef, refresh, elipseAfter }: CustomPaginatorProps) => {
    const [indexesForLinks, setIndexesForLinks] = useState<number[]>();
    const [numPages, setNumpages] = useState<number>(gridRef.current?.instance.pageCount());
    const currentPage = gridRef.current?.instance.pageIndex();
    const elipse = (elipseAfter || 10) < numPages;
    useEffect(() => {
        const grid = gridRef.current?.instance;
        const pageArray = [...Array(numPages).keys()]
        if (grid && elipse) {
            const theseIndexes = getPaginatorNumbers({ arr: pageArray, currentIndex: currentPage, nrange: 1 });
            setIndexesForLinks(theseIndexes);
        } else {
            setIndexesForLinks(pageArray);
        }
    }, [gridRef, numPages, refresh, elipse, currentPage])
    useEffect(() => {
        const grid = gridRef.current?.instance;
        const theseNumPages = grid?.pageCount();
        if (grid && theseNumPages !== numPages) {
            setNumpages(grid.pageCount());
        }
    }, [gridRef, numPages, refresh])
    const showStart = indexesForLinks?.length && indexesForLinks[0] > 0 ? true : false;
    const showEnd = indexesForLinks?.length && indexesForLinks[indexesForLinks.length - 1] < numPages - 1 ? true : false;
    const goToPage = useCallback((pageNo: number) => {
        gridRef?.current?.instance.pageIndex(pageNo)
    }, [gridRef]);
    return numPages > 1 ? <div className='custom-paginator-wrapper'>
        {showStart && <span className='page-number' onClick={() => goToPage(0)}>1</span>}
        {showStart && indexesForLinks && indexesForLinks[0] > 1 && <span className='page-number-separator'>...</span>}
        {indexesForLinks?.map(x => <span key={x} className={`page-number ${currentPage === x ? 'selected' : ''}`} onClick={() => goToPage(x)}>{x + 1}</span>)}
        {showEnd && indexesForLinks && numPages - indexesForLinks[indexesForLinks.length - 1] > 2 && <span className='page-number-separator'>...</span>}
        {showEnd && <span className='page-number' onClick={() => goToPage(numPages - 1)}>{numPages}</span>}
    </div> : null;
}

export default CustomPaginator