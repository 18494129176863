import React from 'react';

// Own
import { DocumentViewerModal, DocumentViewer, DocumentViewerModalProps } from 'components/Common/Components/DocumentViewer/DocumentViewer';

interface WorkPermitPDFViewerProps extends DocumentViewerModalProps {
    onConfirm?: () => void;
    inModal: boolean;
    height?: number;
}

export const WorkPermitPDFViewer = ({
    onConfirm, inModal, height, ...props
}: WorkPermitPDFViewerProps) => {
    if (inModal) {
        return (
            <DocumentViewerModal {...props} onConfirm={onConfirm} />
        )
    } else {
        return <DocumentViewer
            pdfWrapperClass={props.pdfWrapperClass}
            fileBase64={props.fileBase64}
            url={props.url}
            height={height || 750}
            retryInterval={10}
        >
            {props.children}
        </DocumentViewer>
    }
}