import React, { memo } from 'react'

// Own
import { VisitDataRow } from 'components/Schedulers/Interfaces/Schedule.interfaces';
import { ColumnProps } from 'components/ContractInFocus/Interfaces/dataGridColumn.interface';
import { SpecialistVisitDataCellRender } from "components/Schedulers/ScheduleDataGrid/Components/Cell/CellDataGrid";

import './PlannerBody.scss';

interface PlannerBodyProps {
    columnMeta: ColumnProps[];
    records: VisitDataRow[];
    contractReportContext?: boolean;
}

const PlannerBody: React.FunctionComponent<PlannerBodyProps> = ({
    columnMeta,
    records,
    contractReportContext,
}) => {

    const getValue = (record: VisitDataRow, field: string): any => {
        const sorted_supplier_contracts = record.supplier_contracts.sort((a: any, b: any) => a.contract_start_date > b.contract_start_date ? -1 : 1);
        const most_recent_supplier_contract = sorted_supplier_contracts.length ? sorted_supplier_contracts[0] : undefined;
        const gscv = (field: string) => most_recent_supplier_contract && most_recent_supplier_contract[field] !== null ? most_recent_supplier_contract[field] : '';
        switch (field) {
            case 'contractor':
                if (contractReportContext) {
                    return `${record.contractor?.name}`;
                } else {
                    return `${record.contractor?.name}\r\n${record['supplier_address'] || ''}`;
                }
            case 'contract_start_date': { return `${gscv('contract_start_date')}\r\n${gscv('contract_end_date')}`; }
            case 'service_details': {
                const theseServiceDetails = gscv('service_details');
                return theseServiceDetails?.trim().length ? theseServiceDetails : record.equipment_name;
            }
            case 'archived':
                return '';
            default:
                return record[field] ? record[field] : gscv(field)
        }
    }

    const outOfContractRecords: VisitDataRow[] = [];
    const outOfContractRecordsWithVisits: VisitDataRow[] = [];

    let theseRecords = records.filter(x => {
        if (!x.archived) {
            return true;
        } else {
            outOfContractRecords.push(x);
            const keys_with_visits = Object.keys(x).filter(field => x[field]?.visits?.length)
            if (keys_with_visits.length) {
                outOfContractRecordsWithVisits.push(x);
            }
            return false;
        }

    });

    const showOutOfContract = false;
    const showOutOfContractWithVisits = !!outOfContractRecordsWithVisits.length; //contractReportContext && !!outOfContractRecordsWithVisits.length;

    if (showOutOfContract) {
        theseRecords = [...theseRecords, ...outOfContractRecords];
    } else if (showOutOfContractWithVisits) {
        theseRecords = [...theseRecords, ...outOfContractRecordsWithVisits];
    }

    const getIcon = (record: VisitDataRow, field: string) => {
        if (record[field] && record[field].visits) {
            const { visits } = record[field];
            return <SpecialistVisitDataCellRender cellData={{ value: { visits }, nonClickable: true }} />
        } else {
            return <></>;
        }
    }

    const isNewGroup = (record: VisitDataRow, index: number): boolean =>
        index === 0 || records[index - 1].archived !== record.archived;

    return (
        <tbody className="specialist-planner__body">
            {theseRecords.map((record, recordIndex) => {
                return (
                    <React.Fragment key={`${record.contractor?.id}${record.equipment_name}`}>
                        {(showOutOfContract || showOutOfContractWithVisits) && isNewGroup(record, recordIndex) &&
                            <tr>
                                <td className="specialist-planner__group-header" colSpan={columnMeta.length}>{record.archived ? 'Out of contract' : 'In contract'}</td>
                            </tr>
                        }
                        <tr>
                            {columnMeta.map((field, cellIndex) => (
                                <td
                                    style={{ width: field.width, maxHeight: 10, fontSize: "0.8rem", border: "solid 1px #ddd", textAlign: (field?.dataRowAlignment || field?.alignment || "left") }}
                                    key={`${recordIndex}-${cellIndex}-${field.caption}`}>
                                    {field.primary ? getValue(record, field.dataField) : getIcon(record, field.dataField)}
                                </td>
                            ))}
                        </tr>
                    </React.Fragment>
                )
            })}
        </tbody>
    )
}

export default memo(PlannerBody);