// @ts-nocheck
import React, { memo, useState } from "react";
import { Paper } from "@material-ui/core";
import { withResizeDetector } from "react-resize-detector";
import { useDispatch, useSelector } from "react-redux";

// Own
import IconButton from "../../../Common/Components/IconButton/IconButton";
import { SiteIndex, SiteContract } from "../../Interfaces/Sites.interface";
import { sitesIndexVisibilitySelector } from 'components/Profile/Selectors/Profile.selector';
import SiteContractRow from "../SiteContractRow/SiteContractRow";
import {
  StyledSiteTable,
  StyledNoData,
  StyledImageCropper,
  SiteInfoWrapper,
  StyledSiteSub,
  StyledSiteTitle,
  StyledSiteHeader,
  StyledImageWrapper,
  useSiteBackCardStyles
} from "./SiteBackCardStyles";
import { setContractDocsDialogueOpen } from "components/ContractInFocus/Actions/contractInFocus.actions";

import "../../../../styles/app.scss";

interface SiteBackCardProps {
  site: SiteIndex;
  width: number;
  height?: number;
  onFavourite: () => void;
}

const SiteBackCard = ({
  site,
  width,
  onFavourite,
}: SiteBackCardProps) => {
  const classes = useSiteBackCardStyles();
  const sitesIndexVisibilitySettings = useSelector(sitesIndexVisibilitySelector);
  const showDeactivatedContracts = sitesIndexVisibilitySettings["Deactivated Contracts"].default.value;
  const [selectedContractForctDoc, setSelectedContractForDoc] = useState();
  const dispatch = useDispatch();

  const handleOnFavourite = (event: MouseEvent) => {
    event.stopPropagation();
    onFavourite();
  }

  const handleDocClicked = (contract: SiteContract) => {
    setSelectedContractForDoc(contract);
    dispatch(setContractDocsDialogueOpen({ contract }));
  }

  //console.log('site', site)

  return (
    <SiteInfoWrapper
      favourite={site.favourite}
      className="fadeIn"
    >
      {/* <DocumentSelector
        contract={selectedContractForctDoc}
        siteNo={site.site_ref}
      /> */}

      <Paper elevation={3} className={classes.paper}>
        <IconButton
          onClick={(id, button, event) => handleOnFavourite(event)}
          icon="fas fa-star"
          margin="none"
          active={true}
          style={{ zIndex: 3 }}
        />
        <StyledSiteHeader>
          <StyledImageWrapper>
            <StyledImageCropper>
              <img
                alt={`Building ${site.name}`}
                src={site.site_image.thumbnail}
              />
            </StyledImageCropper>
          </StyledImageWrapper>
          <div>
            <StyledSiteTitle width={width}> {site.name} </StyledSiteTitle>
            <StyledSiteSub width={width}>
              {" "}
              {site.site_contracts.length} Contracts on this site{" "}
            </StyledSiteSub>
          </div>
        </StyledSiteHeader>
        {
          !!site.site_contracts.length && (
            <StyledSiteTable>
              {site.site_contracts.map(siteContract => {
                if (showDeactivatedContracts || siteContract.active) {
                  return <div key={`docs${siteContract.id}`}>
                    <SiteContractRow
                      className={siteContract.in_contract ? "not-in-contract" : "in-contract"}
                      onDocClicked={() => handleDocClicked(siteContract)}
                      site={site}
                      contract={siteContract}
                      key={siteContract.id}
                    />
                  </div>
                }

              }

              )}

            </StyledSiteTable>
          )
        }
        {
          !site.site_contracts.length && (
            <StyledNoData>
              <i className="far fa-frown-open"></i>
              <span>No Contracts</span>
            </StyledNoData>
          )
        }
      </Paper>
    </SiteInfoWrapper>
  );
};

export default withResizeDetector(memo(SiteBackCard));
