// HT Note: What lives here and what lives in AdminPanelPortfolios.actions.ts?  At least setPortfolioSuccess, defined in here is used in the admin...
import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";
import { createRequestActionTypes } from "components/Common/Utils/ReduxUtil";
import { Portfolio, SelectedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { APIPrivileges } from "services/Interface/Interface";
import { EntityState } from 'components/Common/Interfaces/Entity.interface';

// NB we don't have an 'add/remove contract(s)' reducer because when a contract is added or removed, other autocalculated values assigned to the portfolio
// are likely to change - as such when we add or remove a contract, we fetch and update the entire portfolio object.  On adding or removing a contract we'd expect
// that most if not all components plugged into the portfolio will need to re-render anyway.

/* Fetch Portfolios*/
export const FETCH_PORTFOLIOS = createRequestActionTypes("PORTFOLIO_FETCH_PORTFOLIOS");

export const fetchPortfolios = (redirect = false) => ({ type: FETCH_PORTFOLIOS.start, payload: { redirect } });
export const fetchPortfoliosSuccess = (portfolios: EntityState<Portfolio[]>) => ({ type: FETCH_PORTFOLIOS.success, portfolios });
export const fetchPortfoliosError = (error: object) => ({ type: FETCH_PORTFOLIOS.error, error });

export const SET_GROUP_PORTFOLIO = createRequestActionTypes('SET_GROUP_PORTFOLIO');
export const setGroupPortfolioReducer = (portfoliosGroup: any) => ({ type: SET_GROUP_PORTFOLIO.reducer, portfoliosGroup });

/* Update Portfolio */
export const UPDATE_PORTFOLIO = createRequestActionTypes('PORTFOLIO_UPDATE_PORTFOLIO');

export const updatePortfolio = (id: number, portfolio: Portfolio, meta: FieldMetaGroup) => ({ type: UPDATE_PORTFOLIO.start, id, portfolio, meta });
export const updatePortfolioSuccess = (portfolio: Portfolio) => ({ type: UPDATE_PORTFOLIO.success, portfolio });
export const updatePortfolioError = (error: object) => ({ type: UPDATE_PORTFOLIO.error, error });

/* Update Logo */
export const UPDATE_PORTFOLIO_IMAGE = createRequestActionTypes('PORTFOLIO_UPDATE_PORTFOLIO_IMAGE');

export const updatePortfolioImage = (panelId: string, portfolio: Portfolio, portfolioImage: string) => ({ type: UPDATE_PORTFOLIO_IMAGE.start, panelId, portfolio, portfolioImage });
export const updatePortfolioImageSuccess = (portfolio: Portfolio) => ({ type: UPDATE_PORTFOLIO_IMAGE.success, portfolio });
export const updatePortfolioImageError = (error: object) => ({ type: UPDATE_PORTFOLIO_IMAGE.error, error });


/* Delete Portfolio */
export const DELETE_PORTFOLIO = createRequestActionTypes('PORTFOLIO_DELETE_PORTFOLIO');

export const deletePortfolio = (portfolio: Portfolio, panelId: string) => ({ type: DELETE_PORTFOLIO.start, portfolio, panelId });
export const deletePortfolioSuccess = (portfolioId: number, panelId: string) => ({ type: DELETE_PORTFOLIO.success, portfolioId, panelId });
export const deletePortfolioError = (error: object) => ({ type: DELETE_PORTFOLIO.error, error });
export const deletePortfolioReducer = (portfolioId: number) => ({ type: DELETE_PORTFOLIO.reducer, portfolioId });

/* Favourite Portfolio */
export const FAVOURITE_PORTFOLIO = createRequestActionTypes('PORTFOLIO_FAVOURITE_PORTFOLIO');

export const favouritePortfolio = (portfolioId: string | number, favourited: number) => ({ type: FAVOURITE_PORTFOLIO.start, portfolioId, favourited });
export const favouritePortfolioSuccess = (portfolioId: string | number, favourited: number) => ({ type: FAVOURITE_PORTFOLIO.success, portfolioId, favourited });
export const favouritePortfolioError = (error: object) => ({ type: FAVOURITE_PORTFOLIO.error, error });

export const SET_PORTFOLIO = createRequestActionTypes('SET_PORTFOLIO');
export const setPortfolioSuccess = (portfolio: Portfolio, meta: FieldMetaGroup, options: any, permissions?: APIPrivileges, insert?: boolean, panelId?: string, recordId?: string | number) =>
    ({ type: SET_PORTFOLIO.success, portfolio, meta, options, permissions, insert, panelId, recordId });
export const setPortfolioReducer = (portfolio: Portfolio) => ({ type: SET_PORTFOLIO.reducer, portfolio });