export const filterRowConfig = {
  visible: true,
  applyFilter: {
    key: 'auto',
    name: 'Immediately'
  }
}

export const gridMetaInitialState = {
  activeMeta: {},
  PUTMeta: {},
  POSTMeta: {},
  GETMeta: {},
  privileges: {
    DELETE: false,
    POST: false
  }
}