import { isEqual } from "lodash";
import React from "react";
import { useLocation } from "react-router-dom";
import $ from "jquery";

export function sumObProps(objectArray, key, fixedPrecision) {
  const sum = objectArray.reduce((a, b) => a + (Number(b[key]) || 0), 0);
  return sum.toFixed(fixedPrecision || 2);
}

export function toPounds(amount) {
  const s = "£" + Number(amount).toFixed(2);
  const val = s.replace(/\d(?=(\d{3})+\.)/g, "$&,");
  return val;
}

export function range(start, stop, step) {
  var a = [start],
    b = start;
  while (b < stop) {
    a.push((b += step || 1));
  }
  return a;
}

export function downloadFromMemory(content, mimeType, filename) {
  const a = document.createElement("a");
  const blob = new Blob([content], { type: mimeType });
  const url = URL.createObjectURL(blob);
  a.setAttribute("href", url);
  a.setAttribute("download", filename);
  a.click();
}

export function getBlobFromBase64PDF(data) {
  const byteString = window.atob(data);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: "application/pdf" });
  return blob;
}

export function downloadPDFFromBase64(data, filename) {
  const a = document.createElement("a");
  const blob = getBlobFromBase64PDF(data);
  const url = URL.createObjectURL(blob);
  a.setAttribute("href", url);
  a.setAttribute("download", filename);
  a.click();
}

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function getObjVal(path, obj) {
  if (obj === undefined) {
    return obj;
  }
  const lookupPath = typeof path === "string" ? path.split(".") : path;
  if (lookupPath.length === 1) {
    const lookup = lookupPath[0];
    if (lookup in obj) {
      return obj[lookup];
    }
    // return obj[lookupPath[0]];
  } else {
    return getObjVal(lookupPath, obj[lookupPath.shift()]);
  }
}

export function setObjVal(path, obj, value, mutate = false) {
  let newObj;
  if (!mutate) {
    if (Array.isArray(obj)) {
      newObj = [...obj];
    } else {
      newObj = { ...obj };
    }
  } else {
    newObj = obj;
  }
  const lookupPath = typeof path === "string" ? path.split(".") : path;
  if (lookupPath.length === 1) {
    newObj[lookupPath[0]] = value;
  } else {
    setObjVal(lookupPath, newObj[lookupPath.shift()], value);
  }
  return newObj;
}

export function setObjValIfChanged(path, obj, value) {
  const existingValue = getObjVal(path, obj); //perhaps slightly inefficient to drill down once to read and again to write if changed
  // but should be pretty fast and it's the clearest way of replacing the object entirely, but only if the value has changed.
  if (!isEqual(existingValue, value)) {
    return setObjVal(path, obj, value); // as we don't set mutate to true, we'll get back a new object
  }
  return obj; // return the original object (i.e. same reference) if nothing has changed
}

export function getCSSTree({
  elementSelectorString,
  element,
  stylesContainer,
  ignoreClassList,
  matchClasses,
  unMatchClasses,
  matchProperties,
  unMatchProperties
}) {
  const thisEl = element || $(elementSelectorString);
  const thisId = thisEl.attr("id");
  let theseClasses = thisEl.attr("class");
  if (theseClasses) {
    theseClasses = theseClasses.split(" ");
    theseClasses = ignoreClassList
      ? theseClasses.filter(x => !ignoreClassList.includes(x))
      : theseClasses;
    theseClasses = matchClasses
      ? theseClasses.filter(x => {
          let matchCount = 0;
          matchClasses.map(mc => {
            if (x.includes(mc)) {
              matchCount += 1;
            }
          });
          return matchCount > 0;
        })
      : theseClasses;
    theseClasses = unMatchClasses
      ? theseClasses.filter(x => {
          let matchCount = 0;
          unMatchClasses.map(umc => {
            if (x.includes(umc)) {
              matchCount += 1;
            }
          });
          return matchCount === 0;
        })
      : theseClasses;
    theseClasses = "." + theseClasses.join(".");
    //element.className.replace(/^| /g, '.')
  }
  const identifier = thisId?.length ? thisId : theseClasses;
  if (identifier) {
    stylesContainer[identifier] = getCss(undefined, thisEl);
  }
  const children = thisEl.children();
  children.each((i, el) => {
    getCSSTree({
      element: $(el),
      stylesContainer,
      ignoreClassList,
      matchClasses,
      unMatchClasses,
      matchProperties,
      unMatchProperties
    });
  });
}

// usage example
// useEffect(() => {
//     setTimeout(() => {
//         const thisStylesContainer = {};
//         const ignoreClassList = ["postComponentNotSelected", "postComponentSelected"];
//         const matchClasses = ['input-wrapper', 'field-group-wrapper'];
//         //@ts-ignore
//         getCSSTree({
//             elementSelectorString: `#printable${workPermitFormId}`,
//             stylesContainer: thisStylesContainer,
//             ignoreClassList: ignoreClassList,
//             matchClasses: matchClasses
//         });
//         console.log('css: ', thisStylesContainer);
//     }, 1000);
// }, [])

export function getCss(elementSelectorString, element) {
  const thisEl = element || $(elementSelectorString);
  if (thisEl) {
    return getCssStyles(thisEl);
  }
}

export function getCssStyles(a) {
  var sheets = document.styleSheets,
    o = {};
  for (var i in sheets) {
    var rules = sheets[i].rules || sheets[i].cssRules;
    for (var r in rules) {
      if (a.is(rules[r].selectorText)) {
        o = $.extend(o, css2json(rules[r].style), css2json(a.attr("style")));
      }
    }
  }
  return o;
}

function css2json(css) {
  var s = {};
  if (!css) return s;
  if (css instanceof CSSStyleDeclaration) {
    for (var i in css) {
      if (css[i].toLowerCase) {
        s[css[i].toLowerCase()] = css[css[i]];
      }
    }
  } else if (typeof css == "string") {
    css = css.split("; ");
    for (var i in css) {
      var l = css[i].split(": ");
      s[l[0].toLowerCase()] = l[1];
    }
  }
  return s;
}
