// Own
import { ContractPeriod } from 'components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface';
import { ContractPeriodDates } from "components/ContractInFocus/interfaces/contractInFocusActions.interfaces";
import { Snapshot, MainInFocusVisibilitySettings } from 'components/ContractInFocus/interfaces/contractInFocus.interfaces';
import { fromSaveDateTimeFormat } from 'components/Common/Utils/Dates';
import { getFullReportSectionStructure } from "components/ContractInFocus/Models/contractIndex.model";

export const findFocusedContractPeriodByDate = (contractPeriods: ContractPeriod[], contractPeriodDates?: ContractPeriodDates): ContractPeriod => {
  let cpDefault = contractPeriods[-1];
  let cpFound = null;
  if (contractPeriodDates) {
    cpFound = contractPeriods?.find((contractPeriod: ContractPeriod) => contractPeriod.start_date === contractPeriodDates?.start_date && contractPeriod.end_date === contractPeriodDates?.end_date)
  }
  return cpFound || cpDefault;
}

export const getCurrentOrMostRecentContractPeriod = (contractPeriods: ContractPeriod[]): ContractPeriod => {
  let cpDefault = contractPeriods[contractPeriods.length - 1];
  let cpFound = null;
  let today = new Date()
  cpFound = contractPeriods?.find((contractPeriod: ContractPeriod) => {
    const startDate = new Date(contractPeriod.start_date);
    const endDate = new Date(contractPeriod.end_date);
    return today >= startDate && today <= endDate;
  })
  return cpFound || cpDefault;
}

export const isSnapshotReportOutOfDate = (snapshot: Snapshot): boolean => {
  return snapshot ?
    fromSaveDateTimeFormat(snapshot.last_frozen_at).isAfter(fromSaveDateTimeFormat(snapshot.report_last_produced_at))
    || (snapshot.report_last_produced_at === null && snapshot.last_frozen_at !== null) : false
}

export type CalculateSectionNumberProps = {
  visibility: MainInFocusVisibilitySettings;
}
/**
 * This function calculates the page headings on the report output.  In constructs an dictionary
 * with the page section name with a corresponding section number
 * @param visibility the calculation utilises the visibility settings on the UI
 */
export const calculateSectionNumber = ({ visibility }: CalculateSectionNumberProps) => {
  let count = 0;
  const fullReportSectionStructure = getFullReportSectionStructure();
  return Object.keys(fullReportSectionStructure).reduce((acc, key) => {

    const isVisibleOnRoot = fullReportSectionStructure[key].visible.length;

    const found = isVisibleOnRoot
      ? fullReportSectionStructure[key].visible.find(visibleItem => visibility[visibleItem as keyof MainInFocusVisibilitySettings])
      : fullReportSectionStructure[key].subTitles.find(subTitle => subTitle.visible.find(visibleItem => visibility[visibleItem as keyof MainInFocusVisibilitySettings]));

    if (found) {
      count++;
    }

    return { ...acc, [fullReportSectionStructure[key].name]: found ? count : undefined }
  }, {});
}
