import React, { useState, useRef, useCallback, useEffect, useReducer } from 'react';
import Button from '@material-ui/core/Button';

import { FieldMetaGroup, Primitive } from 'components/Common/Interfaces/Entity.interface';
import { simpleUpdateFieldInfo } from "components/AdminPanel/HAS/HASIncidentServices";
import ActionForm from "components/Common/Components/GeneralActionForm/GeneralActionForm";
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { ParseAPIResponseWithPUTMeta } from "services/Interface/Interface";
import { FieldInfoManagementProps } from "components/ContractInFocus/HAS/HASIncidentForm";
import { replaceArrayObjById } from "store/Common/Helpers/commonHelpers";

import "components/Common/Components/GeneralActionForm/GeneralActionFormStyles.scss"
import "components/ContractInFocus/HAS/Styles/forms.scss"

const FormFieldsConfig = {
    requires_resolution: {},
    field_redacted: {}
}

type FieldInfoControlProps = {
    data: any;
    canWrite: boolean;
    meta: FieldMetaGroup;
    parentId: string;
    fieldType: string;
    formFieldsConfig: FieldsFormConfig;
    setFormFieldsConfig?: React.Dispatch<React.SetStateAction<FieldsFormConfig | undefined>>;
    getFormFieldsConfig?: ({ fieldInfoListData, fieldInfoListMeta, updateMode }: FieldInfoManagementProps) => FieldsFormConfig;
    fieldInfoListRef: React.MutableRefObject<any>;
}

export const FieldInfoControl: React.FunctionComponent<FieldInfoControlProps> = ({
    data, canWrite, meta, parentId, fieldType, formFieldsConfig, setFormFieldsConfig, getFormFieldsConfig, fieldInfoListRef
}) => {
    const [formChanged, setFormChanged] = useState(false);
    const [recordData, setRecordData] = useState(data);
    const [updateResponse, setUpdateResponse] = useState<ParseAPIResponseWithPUTMeta<any>>();
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 1);
    const formValues = useRef(data);
    useEffect(() => {
        formValues.current = data
        setRecordData(data);
    }, [data])
    const action = useCallback(() => {
        simpleUpdateFieldInfo({
            parentId,
            fieldInfoId: data.id,
            payload: {
                requires_resolution: formValues.current.requires_resolution,
                field_redacted: formValues.current.field_redacted
            },
            fieldType: fieldType
        }).then((response) => {
            formValues.current = { ...response.data }
            setRecordData(response.data);
            setUpdateResponse(response);
        })
        //}, [parentId, data.id])

    }, [parentId, data.id, fieldType])

    useEffect(() => {
        if (updateResponse) {
            if (setFormFieldsConfig && getFormFieldsConfig) {
                setFormFieldsConfig(getFormFieldsConfig({ fieldInfoListData: [updateResponse.data], fieldInfoListMeta: updateResponse.meta, updateMode: true }));
                replaceArrayObjById({
                    existingArray: fieldInfoListRef.current.data,
                    newObj: updateResponse.data,
                    add: true
                })
            }
        }
    }, [updateResponse, getFormFieldsConfig, setFormFieldsConfig, formFieldsConfig, fieldInfoListRef]);

    return (
        <div className='HASFormControlsWrapper'>
            {
                recordData && <ActionForm
                    formValues={formValues}
                    fieldConfigs={FormFieldsConfig}
                    //caption="Field Settings..."
                    gridClass='fieldInfoControlPropsForm'
                    metaForForm={meta}
                    refreshSignal={mustRefresh}
                    setGAFormChanged={setFormChanged}
                />}
            <Button onClick={action}
                color="secondary"
                type="submit"
                variant="contained"
                disabled={!formChanged || !canWrite}
                style={
                    {
                        float: "right",
                        marginTop: "1rem"
                    }
                }>
                Update
            </Button>
        </div>
    );
}

export default FieldInfoControl