import React, { useEffect, useState, useRef } from "react";
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { isEqual } from "lodash";

// Own
import { AdminDataGrid } from "components/AdminPanel/Containers/AdminDataGrid";
import { APIPrivileges } from "services/Interface/Interface";
import { adminPanelType } from "components/AdminPanel/Models/AdminPanel.model";
import { peopleWithAccessColumns, getPeopleWithAccessActions } from "components/AdminPanel/People/Models/PeopleDetail.model";
import { removeDisabledFieldsBasedOnRecordMeta } from "helpers/Forms/form.helper";
import { openPanelInSelectionMode } from 'components/AdminPanel/Helpers/AdminPanel.helper';
import { HydratedPortfolio, AccessiblePortfolioI } from "components/Portfolios/Interfaces/Portfolios.interface";

import { EntityKeys } from "constants/entity.constants";
import { FieldMetaGroup, PreFlightListInfo } from "components/Common/Interfaces/Entity.interface";
import portfolioAdminServices from "components/AdminPanel/Portfolios/Services/portfolioAdminServices";

interface PortfolioAccessViewProps {
    panelId: string;
    masterRecordData: HydratedPortfolio;
    dataTestId: string;
    masterRecordType?: string;
    selectActionIdentifier?: string;
    linkPanelTitle?: string;
}

const PortfolioAccessView: React.FunctionComponent<PortfolioAccessViewProps> = ({
    panelId,
    masterRecordData,
    //contractsState,
    dataTestId,
    masterRecordType,
    selectActionIdentifier,
    linkPanelTitle,
}) => {

    const currentAccessIds = useRef<(string | number)[]>();
    const [datasource, setDatasource] = useState<any>();
    const [meta, setMetaData] = useState<FieldMetaGroup>();
    const [preFlightInfo, setPreFlightInfo] = useState<PreFlightListInfo>();
    const [privileges, setPrivileges] = useState<APIPrivileges>()

    useEffect(() => {
        portfolioAdminServices.portfolioAccessLinksPreFlightInfo(masterRecordData.id).then((newPreFlightInfo) => {
            if (!isEqual(preFlightInfo, newPreFlightInfo)) {
                setPreFlightInfo(newPreFlightInfo);
            }
            if (!isEqual(preFlightInfo?.meta, newPreFlightInfo.meta)) {
                setMetaData(newPreFlightInfo?.meta);
            }
        });

    }, []);

    useEffect(() => {
        const custom = new CustomStore({
            key: "id",
            load: loadOptions => portfolioAdminServices.fetchPortfolioAccessLinks(masterRecordData.id).then(
                (response) => {
                    setPrivileges(response.privileges);
                    return response.data;
                }
            ),
            update: (id, values) => portfolioAdminServices.updatePortfolioAccess(masterRecordData, id, values, meta)
        });

        setDatasource(
            // @ts-ignore
            new DataSource({
                store: custom
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const handleAccessUpdate = (e: DataGridUpdateEvent) => {
    //     // @ts-ignore    
    //     return portfolioAdminServices.updateAccess(
    //         masterRecordData as HydratedPortfolio,
    //         e.key,
    //         e.oldData,
    //         e.newData,
    //         meta
    //     );
    // };


    function handleAddPeopleToPortfolio(peopleIds: number[]) {
        const accessObjs = peopleIds.map((personId) => (
            {
                "person": personId,
                "portfolio": masterRecordData.id
            }
        ))
        portfolioAdminServices
            .grantAccessToPortfolio(masterRecordData, accessObjs)
            .then(() => {
                console.log('reloading');
                datasource.reload();
            })
    }

    const handleRemovePersonFromPortfolio = (accessObj: AccessiblePortfolioI) => {
        portfolioAdminServices.removeAccessToPortfolio(
            masterRecordData,
            accessObj.id
        )
            .then(() => {
                datasource.reload();
            })
    };

    const handlePersonLink = (): void => {
        masterRecordType && selectActionIdentifier && linkPanelTitle && openPanelInSelectionMode(
            {
                selectActionIdentifier,
                masterRecordType,
                masterRecordData,
                linkedRecordType: adminPanelType.people,
                onAddLinks: handleAddPeopleToPortfolio,
                currentMemberIds: currentAccessIds.current || [],
                linkPanelTitle,
            }
        )
    };



    const linkPerson = selectActionIdentifier ? handlePersonLink : undefined;

    return (
        datasource && meta ? <AdminDataGrid
            repaintChangesOnly
            data={datasource}
            editMode="cell"
            meta={meta}
            dataTestId={dataTestId}
            permissions={privileges}
            passedEditorPreparing={(e: any) => {
                removeDisabledFieldsBasedOnRecordMeta(e);
            }}
            getDatagridColumns={peopleWithAccessColumns}
            masterView={false}
            entityKey={EntityKeys.People}
            columnActions={getPeopleWithAccessActions(handleRemovePersonFromPortfolio)}
            panelId={panelId}
            //onUpdate={handleAccessUpdate} // records are currently updated in this context - just linked or removed
            onLink={linkPerson}
        /> : <></>
    );
};

export default PortfolioAccessView;
