import { createSelector } from "reselect";
import {
  dictToArray,
  arrayToDict,
  existingRecordOnly
} from "../../../services/API/API.helper";
import { dictionaryIdPrefix } from "../Helpers/SiteDictionary.helper";

//export const sitesState = state => state.sites.sites;
export const sitesMetaState = state => state.sites.meta;
export const siteSelector = state => state.sites;
export const sitesLengthSelector = state =>
  Object.keys(state.sites.data).length;

export const hasNoSitesSelector = state => state.sites.hasNoSites;

export const hasOneViableSiteSelector = state =>
  state.sites.data.length === 1 &&
  state.sites.data[0].site_contracts.length !== 0;

export const singleSiteFirstContractPathSelector = createSelector(
  siteSelector,
  sites => {
    let path = undefined;
    const siteKeys = Object.keys(sites.data);
    if (Object.keys(sites.data).length === 1) {
      const site = sites.data[siteKeys[0]];
      const contract = site.site_contracts[0];
      if (contract) {
        path = `/contract/${contract.contract_ref}/`;
      }
    }
    return path;
  }
);

export const onlyViableSiteContractRef = state =>
  Object.keys(state.sites?.data).length === 1 && state.sites.data;

// export const siteByContractRefSelector = contractRef =>
//   createSelector(siteSelector, sites => {
//     const matchingSites = Object.values(sites.data)
//       .filter(record => {
//         return Object.values(record.site_contracts)
//           .map(contract => contract.contract_ref)
//           .includes(contractRef);
//       })
//       .map(site =>
//         site.site_contracts.filter(
//           contract => contract.contract_ref == contractRef
//         )
//       );
//     if (matchingSites.length == 1) {
//       return matchingSites[0];
//     }
//   });

export const selectSitesSortedByFavourites = createSelector(
  siteSelector,
  site => {
    const siteArray = existingRecordOnly(dictToArray(site.data));
    siteArray.sort((siteA, siteB) => {
      if (siteA.favourite && !siteB.favourite) {
        return -1;
      }
      if (!siteA.favourite && siteB.favourite) {
        return 1;
      }
      return 0;
    });

    return arrayToDict(
      siteArray.map(site => ({ ...site, dictKey: dictionaryIdPrefix(site.id) }))
    );
  }
);
