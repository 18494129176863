import styled from "styled-components";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import styles from "../../../styles/app/common/variables.scss";

export const HeaderLogoWrapper = styled.div`
  position: relative;
  cursor: pointer;
  img {
    position: absolute;
    left: -10px;
    height: 40px;
    width: auto;
  }
  .img-placeholder {
    width: 30px;
    padding-right: 3.2rem;
    height: 40px;
  }
`;

export const HeaderWrapper = styled.div`
  input {
    border: 0;
    flex-grow: 1;
    font-size: 1.2rem;
    background: transparent;

    &::placeholder {
      color: ${styles.grayMedDark};
    }
  }
`;

export const useHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    darkAppBar: {
      transition: 'background-color 0.5s ease',
      backgroundColor: styles.blueDark,
    },
    searchAppBar: {
      transition: 'background-color 0.5s ease',
      backgroundColor: styles.white,
      color: styles.blueDark,
    },
  })
);
