import { store } from "store/store";
import API, { APIR, } from "services/API/API";
import { CONTRACT_ROUTE } from "services/API/common/globalAPIs";
import { getData, getDataAndMeta, getMeta, unWrapDataAndMeta, unWrapData, unWrapDataAndMetaAndPUTMeta } from "services/API/API.helper";
import * as actions from "components/ContractInFocus/Actions/personalHiddenObject.actions";
import { PersonalHiddenObjectI, SubmitPersonalHiddenObjectI } from 'components/ContractInFocus/Interfaces/ContractInFocus.interfaces';
import { ContractInterface } from "components/AdminPanel/Contracts/Interfaces/Contract.interface";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";

//export const getContractPersonalHiddenObjects = (contract: ContractInterface, contentType: string) => {
export const getContractPersonalHiddenObjects = (contract: SiteContract, contentType: string) => {
    const response = APIR.get(`${CONTRACT_ROUTE}${contract.contract_ref}/hide/?content-type-name=${contentType}&format=json`).pipe(
        unWrapDataAndMetaAndPUTMeta(),
    ).subscribe((info) => {
        store.dispatch(actions.fetchObjectsForContentType(contentType, info.data))
        return info
    })
}

const removePersonalHiddenObject = (contentType: string, objectId: string) => {
    const response = APIR.delete(`/hide/${objectId}/`).pipe(
        unWrapDataAndMetaAndPUTMeta(),
    ).subscribe((info) => {
        store.dispatch(actions.removePersonalHiddenObject(contentType, objectId))
        return info
    })
}

const addPersonalHiddenObject = (contentType: string, payload: SubmitPersonalHiddenObjectI) => {
    const response = APIR.post(`/hide/`, payload).pipe(
        unWrapData(),
    ).subscribe((info) => {
        console.log('info: ', info);
        store.dispatch(actions.addPersonalHiddenObject(contentType, info.data))
        return info
    })
}

export default {
    getContractPersonalHiddenObjects,
    addPersonalHiddenObject,
    removePersonalHiddenObject,
}

