import React, { useEffect, useState, useRef, useReducer, useCallback } from 'react';
import CustomStore from "devextreme/data/custom_store";

// const MyComponent = () => <></>
// export default MyComponent;

import { useSelector } from "react-redux";
import DataGrid, { Paging, Column, Editing, MasterDetail, Button as DXButton } from 'devextreme-react/data-grid';
import {
    DataGridOnEditPreparing
} from "interfaces/datagrid.interfaces";
import Button from '@material-ui/core/Button';
import { GeneralTooltip } from "components/Common/Components/InfoHint/InfoHint";
import { NotesPreviewer } from "components/Schedulers/Scheduler";
import { withResizeDetector } from "react-resize-detector";
import moment from 'moment';
import DataSource from 'devextreme/data/data_source';

// Own
import { store } from "store/store";
import { HASTask, HASTaskCheck } from "components/AdminPanel/HAS/Interfaces/HASTaskInterfaces";
import { getHASTaskChecksListColumns } from 'components/Schedulers/HASDetailModal/Models/HASTaskCheckColumns';
import { getColumnProps } from 'helpers/DataGrid/DataGridColumn.helper';
import { UsefulDXListWrapper, } from "components/ContractInFocus/Styles/CommonStyles";
import MatConfirmationDialog from 'components/Common/Components/Material/MatConfirmationDialog/MatConfirmationDialog';
import { HASCheckQueryParams, getHASTaskChecksRoute } from "components/AdminPanel/HAS/HASTaskServices";
import { getDimTitle } from "helpers/Forms/modal.helper";
import { ScheduleListGridActionWrapper } from "components/Schedulers/SchedulerStyles";
import { FormValues } from "components/Common/Interfaces/Entity.interface";
import { addNotification } from 'components/Notification/Actions/Notification.actions';
import { NOTIFICATION_SUCCESS } from 'components/Notification/Constants/constants';
import NotesDetail from "components/Schedulers/Components/NotesDetail/NotesDetail";
import { sortByScheduledFor } from "components/Schedulers/ScheduleDataGrid/Helper/scheduleDataGridHelper";
import { removeDisabledFieldsBasedOnRecordMeta } from "helpers/Forms/form.helper";
import CommonAPIService from "components/ContractInFocus/Services/commonAPI.services";
import { DataGridMeta } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';
import { gridMetaInitialState } from 'components/ContractInFocus/Models/Grid';
import { capitalize, elipse } from "helpers/String/String.helper";
import { Period } from "components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface";

import 'components/Schedulers/ScheduleListGeneralStyles.scss';
import { dateUDF, standardDashFormat } from "components/Common/Utils/Dates.js";
import commonAPIServices from 'components/ContractInFocus/Services/commonAPI.services';
import { getDataAndMeta } from "services/API/API.helper";

interface HASCheckListProps {
    height: number;
    thisHASTask: HASTask | undefined;
    contractRef: string;
    portfolioId?: string | number;
    setModalTitle: React.Dispatch<React.SetStateAction<(() => JSX.Element) | undefined>>;
    deleteMessage?: string;
    contractTaskId: string;
    taskIsGlobal: boolean;
    refreshGrid?: React.DispatchWithoutAction;
    initiallySelectedCheckId?: React.MutableRefObject<string | undefined>;
    setModalDisplayMode: React.Dispatch<React.SetStateAction<string | undefined>>;
    setItem: React.Dispatch<React.SetStateAction<HASTaskCheck | undefined>>;
    refreshSignal?: number;
    selectedPeriod?: Period;
}

const HASTaskChecksList = ({
    height = 525,
    thisHASTask,
    contractRef,
    portfolioId,
    setModalTitle,
    deleteMessage,
    contractTaskId,
    taskIsGlobal,
    refreshGrid,
    initiallySelectedCheckId,
    setModalDisplayMode,
    setItem,
    refreshSignal,
    selectedPeriod
}: HASCheckListProps) => {

    //TODO: Turn the confirmation state into a custom hook and possibly integrate with confirmation component
    const thisDeleteMessage = deleteMessage || 'Are you sure you want to delete this item?';
    const confirmationInitialState = { open: false, agreeCallback: () => { } };
    const [confirmationDialog, setConfirmationDialog] = useState(confirmationInitialState);
    const [listData, setListData] = useState<any>();
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 0); // important it starts as 0 as it will cause dataSource reload
    const [scheduledCount, setScheduledCount] = useState<number>();
    const [completedCount, setCompletedCount] = useState<number>();
    const gridRef = useRef<any>();
    const [dataSource, setDataSource] = useState<any>();
    const [metadata, setMetadata] = useState<DataGridMeta>(gridMetaInitialState);
    const metaDataRef = useRef(metadata);

    useEffect(() => {
        mustRefresh && refreshGrid && refreshGrid();
    }, [mustRefresh, refreshGrid])

    const processData = useCallback((data: HASTaskCheck[]) => {
        const itemsWithOrder: any[] = [];
        const sortedIds = sortByScheduledFor(data).map(x => x.id); //.filter(x => x.status !== 'cancelled')

        sortedIds.map((id, index) => {
            const item = data.find((x: any) => x.id === id);
            item && itemsWithOrder.push(
                {
                    ...item,
                    order: index + 1
                })
        })
        if (itemsWithOrder) {
            let completed = 0;
            let scheduled = 0;

            //console.log('itemsWithOrder: ', itemsWithOrder);
            scheduled = itemsWithOrder.filter(x => x.status == "scheduled").length;
            completed = itemsWithOrder.filter(x => x.status == "completed").length;
            setScheduledCount(scheduled);
            setCompletedCount(completed);
        }
        setListData(itemsWithOrder);
        return itemsWithOrder
    }, []);

    const onEditorPreparing = useCallback((e: DataGridOnEditPreparing) => {
        const maxDate = moment().toDate()
        if (e.editorName === "dxDateBox") {
            if (e.dataField === "completed_on") {
                e.editorOptions.dateOutOfRangeMessage = "completed cannot be set in the future"
                e.editorOptions.max = maxDate;
            }
        }
        removeDisabledFieldsBasedOnRecordMeta(e, metadata?.POSTMeta);
    }, [metadata]);

    const onInitNewRow = useCallback(
        (e) => {
            if (selectedPeriod) {
                e.data.scheduled_for = moment(selectedPeriod.start_date).toDate();
            } else {
                e.data.scheduled_for = moment().toDate();
            }
        }, [selectedPeriod]
    )

    const toolbarPrep = (e: any) => {
        e.toolbarOptions.items = [];
    };

    let initialNotesStore: FormValues = {};
    listData && listData.map((x: HASTaskCheck) => { initialNotesStore[x.id] = x.notes })

    useEffect(() => {
        if (listData) {
            const thisNotesStore: FormValues = {}
            listData.map((x: HASTaskCheck) => { thisNotesStore[x.id] = x.notes });
            notesStore.current = thisNotesStore;
        }

    }, [listData])

    useEffect(() => {
        if (listData) {
            if (initiallySelectedCheckId?.current) {
                const selected = listData.find(((x: HASTaskCheck) => x.id === initiallySelectedCheckId?.current));
                if (selected) {
                    //selectedItem.current = selected;
                    initiallySelectedCheckId.current = undefined;
                    setModalDisplayMode("viewDocs");
                    setItem(selected);
                }
            }
        }
    }, [listData, initiallySelectedCheckId, setItem, setModalDisplayMode]);

    const notesStore = useRef<FormValues>(initialNotesStore); // this is to be used for persistence until the component goes completely out of scope

    const getModalTitle = useCallback(() => {
        return () => <>
            {thisHASTask ? <div className="detail-modal--title">
                Health and Safety Checks
                {getDimTitle('for Task: ')}
                {`${thisHASTask.name}${taskIsGlobal ? " (Global Task)" : ""}`}
            </div> :
                <div className="detail-modal--title">
                    New Health and Safety Task
                </div>
            }
        </>
    }, [thisHASTask, taskIsGlobal])

    const loadDocumentsView = (cellData: any) => {
        if (cellData.data && !cellData.data.id.startsWith('temp.')) {
            setModalDisplayMode("viewDocs");
            setItem(cellData.data);
        }
    }

    const RenderDocumentsCell = (cellData: any) => {
        let className = '';
        const status = cellData.data.status;
        // console.log('cellData.data: ', cellData.data);
        const statutoryDocumentSubmitted = cellData.data.statutory_documents_count;
        const documentsCount = cellData.data.supporting_documents_count;
        const thisOnClick = (e: any) => loadDocumentsView(cellData);
        if (status === "completed" && !statutoryDocumentSubmitted) {
            className = "warn"
            return <GeneralTooltip title="The required statutory document has not been submitted">
                <div onClick={thisOnClick} className="warn cell-content-link"><span>{documentsCount}</span></div>
            </GeneralTooltip>
        }
        return <span onClick={thisOnClick} className="cell-content-link">{documentsCount}</span>
    };

    const RenderCompletedOnCell = (cellData: any) => {
        let className = '';
        let completed_on = cellData.data.completed_on;
        if (completed_on) {
            completed_on = moment(completed_on).format(standardDashFormat);
        }
        if (cellData.data.check_type === "routine") {
            const status = cellData.data.status;
            const due_by = moment(cellData.data.due_by).format(standardDashFormat);
            const due_after = moment(cellData.data.due_after).format(standardDashFormat);

            const completed_late = cellData.data.completed_late;
            const completed_early = cellData.data.completed_early;
            if (status === "completed" && (completed_late || completed_early)) {
                className = "warn"
                return <GeneralTooltip title={`This check should have been completed between ${due_after} and ${due_by}`}>
                    <span className="warn">{completed_on}</span>
                </GeneralTooltip>
            }
        }

        return <span>{completed_on}</span>
    };

    const listColumns = getHASTaskChecksListColumns(RenderDocumentsCell, RenderCompletedOnCell);

    const handleRowPrepared = (rowInfo: any) => {
        if (rowInfo.rowType === 'data') {
            if (rowInfo.data.id?.startsWith('temp.')) {
                rowInfo.rowElement.addClass('preview');
            }
            // uncomment if you want notes icon to have withNotes styling
            // when row is expanded 
            // if (rowInfo.data.notes?.trim().length) {
            //     rowInfo.rowElement.addClass('withNotes');
            // } else {
            //     rowInfo.rowElement.removeClass('withNotes');
            // }
        }
    }

    useEffect(() => {
        setModalTitle(getModalTitle)
    }, [thisHASTask, contractRef, getModalTitle, setModalTitle])

    const loadList = useCallback(() => {
        const query_params: HASCheckQueryParams = {};
        if (selectedPeriod) {
            query_params.start_date = selectedPeriod.start_date;
            query_params.end_date = selectedPeriod.end_date;
        }
        query_params.contract_task = contractTaskId;
        if (thisHASTask) { query_params.task = thisHASTask.id }
        if (portfolioId) {
            query_params.surrogate_portfolio = portfolioId
        }
        let route = getHASTaskChecksRoute(contractRef);
        return commonAPIServices.getAll<HASTaskCheck>(
            () => route,
            setMetadata,
            undefined,
            query_params,
            metaDataRef,
            processData
        )
    }, [contractRef, contractTaskId, portfolioId, processData, selectedPeriod, thisHASTask]);

    useEffect(() => {
        (mustRefresh || refreshSignal) && dataSource && dataSource.reload();
    }, [mustRefresh, dataSource, refreshSignal]);

    const preparePayload = useCallback((values: any) => {
        if (values.completed_on) {
            values.completed_on = moment(values.completed_on).format(dateUDF);
        }
        if (portfolioId) {
            values.surrogate_portfolio = portfolioId
        }
        return values
    }, [portfolioId]);

    const update = useCallback(
        (id: string, values: any) => {
            const theseValues = preparePayload(values);
            return CommonAPIService.update<any>(getHASTaskChecksRoute, contractRef, id, theseValues, metaDataRef?.current?.PUTMeta).then((response) => {
                store.dispatch(addNotification({ message: "Saved", type: NOTIFICATION_SUCCESS }))
                forceUpdate();
                return response
            })
        }, [contractRef, metaDataRef, preparePayload]
    )

    const updateNotes = ({ id, payload }: { id: string, payload: any, [x: string]: any; }) => update(id, payload).then((response) => getDataAndMeta(response));

    const insert = useCallback(
        (values: any) => {
            const theseValues = preparePayload(values);
            theseValues.contract_task = contractTaskId;
            const getEndpoint = () => getHASTaskChecksRoute(contractRef);
            return CommonAPIService.create<any>({ getEndpoint, values: theseValues, metadata: metaDataRef.current.POSTMeta }).then((response) => {
                store.dispatch(addNotification({ message: "Saved", type: NOTIFICATION_SUCCESS }))
                forceUpdate();
                return response

            })
        }, [contractRef, preparePayload, contractTaskId]
    )

    const handleDelete = useCallback((obj: HASTaskCheck): void => {
        const id = obj.id;
        const query_params: any = {};
        if (portfolioId) {
            query_params.surrogate_portfolio = portfolioId
        }
        setConfirmationDialog({
            open: true, agreeCallback: () => {
                id && CommonAPIService.del<any>(getHASTaskChecksRoute, contractRef, id, query_params).then((response) => {
                    forceUpdate();
                    setConfirmationDialog(confirmationInitialState);
                });
            }
        });
    }, [confirmationInitialState, contractRef, portfolioId]);

    useEffect(() => {
        const custom = new CustomStore({
            key: "id",
            load: loadList,
            insert: insert,
            // remove: handleDelete // this needs to be done via the onclick event in the action column, as we have a confirmation dialogue involved
            update: update
        });

        setDataSource(
            new DataSource({
                store: custom,
            })
        );
    }, [loadList, update, insert]);

    return <>
        {thisHASTask && <>
            <UsefulDXListWrapper>
                <MatConfirmationDialog
                    onAgree={confirmationDialog.agreeCallback}
                    onDisagree={() => setConfirmationDialog(confirmationInitialState)}
                    open={confirmationDialog.open}
                    actions={{ agree: 'Yes', disagree: 'No' }}
                >
                    {thisDeleteMessage}
                </MatConfirmationDialog>
                {metaDataRef.current?.privileges?.POST &&
                    <ScheduleListGridActionWrapper>
                        <div className="scheduleListStatsWrapper">
                            <div className="scheduleListStatsGroup">
                                <span className="scheduleListStatLabel">Passed Due:</span>
                                <span className="scheduleListStatValue">{thisHASTask?.passed_due_count}</span>
                            </div>
                            <div className="scheduleListStatsGroup">
                                <span className="scheduleListStatLabel">Missing Documentation:</span>
                                <span className="scheduleListStatValue">{scheduledCount}</span>
                            </div>
                        </div>
                        <div id="scheduleListActionButtonsWrapper">
                            <div className="newScheduleItemButtonWrapper buttonWrapper">
                                <Button onClick={(e: any) => {
                                    gridRef.current.instance.addRow();
                                    gridRef.current.instance.deselectAll();
                                }
                                }
                                    className="newScheduleItemButton"
                                    color="secondary"
                                    size="small"
                                    type="submit"
                                    variant="contained"
                                    disabled={false}
                                >
                                    Create &#39;Adhoc&#39; Check
                                </Button>
                            </div>
                        </div>
                    </ScheduleListGridActionWrapper>
                }
                {<DataGrid
                    id="scheduleListGridContainer"
                    ref={gridRef}
                    className="dx-datagrid-jt"
                    dataSource={dataSource}//{listData}
                    hoverStateEnabled={true}
                    showBorders={true}
                    rowAlternationEnabled={true}
                    width="100%"
                    onRowPrepared={handleRowPrepared}
                    onEditorPreparing={onEditorPreparing}
                    onInitNewRow={onInitNewRow}
                    onToolbarPreparing={toolbarPrep}


                // we no longer need to disable completed option if no service reports.  Leave around in case we re-enable.
                // onEditorPreparing={(e) => {
                //   if (e.dataField == "status") {
                //     if (!e.row?.data.service_reports_count) {

                //       //@ts-ignore
                //       e.editorOptions.dataSource = e.lookup.dataSource.map((d: any) => {
                //         if (d.value === "completed") {
                //           d.disabled = true;
                //         }
                //         return d
                //       });

                //     }
                //   }
                // }}
                >

                    <Paging enabled={false} />

                    <Editing allowDeleting={metadata?.privileges.DELETE} allowAdding={metadata?.privileges.POST} allowUpdating={metadata?.privileges.PUT} mode="cell" />
                    {metadata?.PUTMeta && listColumns.map((column: any) => <Column
                        key={column.dataField}
                        //dataField={column.dataField}
                        {...getColumnProps(column.dataField, metadata?.PUTMeta)}
                        caption={column.caption ? column.caption : metadata?.PUTMeta[column.dataField]?.label}
                        dataType={column.dataType ? column.dataType : metadata?.PUTMeta[column.dataField]?.type}
                        format={column.format}
                        width={column.width}
                        sortOrder={column.sortOrder}
                        cellRender={column.cellRender ? column.cellRender : undefined}
                        allowEditing={!metadata?.PUTMeta[column.dataField]?.read_only}
                    />
                    )}

                    {/* <Column
            caption="Documents"
            calculateCellValue={calculateReportCounts}
            width={100}
          /> */}
                    <Column type="buttons" cssClass={"customActionsColumn dx-command-edit dx-command-edit-with-icons"}>
                        <DXButton name="delete" onClick={(component: any) => handleDelete(component.row.data)} visible={metadata?.privileges.DELETE} />
                        <DXButton render={
                            (data: any) => {
                                const thisOnClick = (e: any) => {
                                    const thisGrid = gridRef.current.instance;
                                    e.preventDefault();
                                    if (data.row.isExpanded) {
                                        thisGrid.collapseRow(data.row.key);
                                    } else {
                                        thisGrid.expandRow(data.row.key);
                                    }
                                }
                                return <NotesPreviewer
                                    notes={elipse({ s: capitalize(data.data?.notes), mode: 'middle', threshold: 500, blockSize: 200 })}
                                    onclick={thisOnClick}
                                    hideTooltip={data.row.isExpanded}
                                />
                            }}
                        />
                    </Column>

                    {metadata?.activeMeta && <MasterDetail
                        enabled={true}
                        component={(e) =>
                            <NotesDetail
                                meta={metadata.activeMeta}
                                row={e}
                                contractRef={contractRef}
                                portfolioId={portfolioId}
                                previewRow={false}
                                notesStore={notesStore.current}
                                dispatchRefreshContext={forceUpdate}
                                updateNotes={updateNotes}
                            />
                        }
                    />}

                </DataGrid>
                }
            </UsefulDXListWrapper>
        </>}
    </>
}

export default withResizeDetector(HASTaskChecksList);
