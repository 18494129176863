import React, { useState, useEffect, useCallback, useRef } from "react";
import DataGrid, { Column, Editing, RequiredRule, MasterDetail, RemoteOperations, Paging, Button } from "devextreme-react/data-grid";
import { getRemoteOpParamsAndContractScopeParams } from 'helpers/Pipelines/contractScopeOperator';
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { Paper } from "@material-ui/core";
import { isEqual } from "lodash";
import moment from "moment";
import { useInView } from 'react-intersection-observer';

// Own
import CommonAPIService, { commonUpdateGridRow } from "components/ContractInFocus/Services/commonAPI.services";
import { InlineWrapper } from 'components/ContractInFocus/Styles/CommonStyles';
import { ColumnProps, DataGridMeta } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';
import { getColumnProps, editableTextAreaOnPreparing, getGridProps } from 'helpers/DataGrid/DataGridColumn.helper';
import { columnPropsPlaceHolder } from 'components/ContractInFocus/Models/ColumnProps';
import { gridMetaInitialState } from 'components/ContractInFocus/Models/Grid';
import { Visit } from 'components/Schedulers/Interfaces/Schedule.interfaces';
import { BasePermitToWork } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";
import { PtwType } from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/WorkPermit.models";
import WorkPermitDetail from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/WorkPermitDetail";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import GridHeader from "components/Common/Components/GridHeader/GridHeader.js";
import { PrintChartAndTableLabels } from "components/Common/constants.js";
import { Results } from 'components/Common/Interfaces/Entity.interface';
import { getDataAndMeta } from "services/API/API.helper";
import MatConfirmationDialog from 'components/Common/Components/Material/MatConfirmationDialog/MatConfirmationDialog';

interface PermitToWorkProps {
    visit?: Visit;
    contract?: SiteContract;
    portfolio?: HydratedPortfolio;
    title: string;
    showContractsColumn?: boolean;
    contractField?: string;
    remoteOperations?: boolean;
    pageSize?: number;
    all?: boolean;
}

const PTWsList: React.FC<PermitToWorkProps> = ({
    visit,
    contract,
    portfolio,
    remoteOperations,
    pageSize,
    showContractsColumn,
    title,
    all
}) => {
    const getBaseEndpoint = useCallback(() => {
        if (visit) {
            return `visits/${visit.id}/ptws/?format=json`
        } else if (portfolio) {
            return `portfolios/${portfolio.id}/ptws/?format=json`
        } else if (contract) {
            return `contracts/${contract.contract_ref}/ptws/?format=json`
        } else if (all) { // an added measure to make sure we don't accidentally try to load all ptws
            return '/ptws/?format=json'
        } else {
            return '/ptws/never/?format=json'
        }
    }, [visit, all, portfolio, contract]);
    const getEndpoint = getBaseEndpoint;
    const [dataSource, setDataSource] = useState<DataSource>();
    const [metadata, setMetadata] = useState<DataGridMeta>(gridMetaInitialState);
    const metaDataRef = useRef(metadata);
    const [contentReady, setContentReady] = useState(false);
    const currentDataRef = useRef<Results<PtwType>>();
    const lastGridValue = useRef<Promise<Results<PtwType>>>()
    const dataGridRef = useRef<any>(null);
    const thisShowContractsColumn = portfolio || showContractsColumn;

    const nonCancellableStatuses = ["complete", "closed", "cancelled"]

    const allowReload = useRef(false);
    const storedLoadOptions = useRef<any>();
    const refreshing = useRef(false);

    function isCancelButtonVisible(e: any) {
        return !!(
            e.row.data.submit_for_signing &&
            !metadata.PUTMeta?.cancellation_requested.read_only
            && !nonCancellableStatuses.includes(e.row.data.ptw_status)
        )
    }

    function isDeleteButtonVisible(e: any) {
        return !e.row.data.submit_for_signing && metadata.privileges.POST
    }

    interface initialConfirmationState {
        open: boolean;
        agreeCallback: () => void;
        message: string;
    }

    const cancelPermit = useCallback((e: any) => {
        const payload = {
            cancellation_requested: true,
            permittowork: e.row.data.id
        }
        CommonAPIService.update<any>(getEndpoint, undefined, e.row.data.id, payload).then((response) => {
            const thisResponse = getDataAndMeta(response);
            const thisData = thisResponse.data;
            allowReload.current = true;
            commonUpdateGridRow({
                dataSource,
                key: e.row.data.id,
                changes: thisData,
                currentListDataRef: currentDataRef
            });
        })
    }, [dataSource, getEndpoint]);

    const deletePermit = useCallback((e: any) => {
        return CommonAPIService.del<any>(getEndpoint, undefined, e.row.data.id).then(x => {
            allowReload.current = true;
            dataGridRef.current?.instance.refresh(true)
        });
    }, [getEndpoint])

    const confirmationInitialState: initialConfirmationState = { open: false, agreeCallback: () => { }, message: '' };
    const [confirmationDialog, setConfirmationDialog] = useState(confirmationInitialState);

    const handleCancelPermit = (e: any): void => {
        setConfirmationDialog({
            open: true,
            agreeCallback: () => {
                cancelPermit(e);
                setConfirmationDialog(confirmationInitialState);
            },
            message: `Are you sure you want to cancel this permit?`,
        });
    }

    const handleDeletePermit = (e: any): void => {
        setConfirmationDialog({
            open: true,
            agreeCallback: () => {
                deletePermit(e);
                setConfirmationDialog(confirmationInitialState);
            },
            message: `Are you sure you want to delete this permit?`,
        });
    }

    const RenderConfirmationDialogue = () => <MatConfirmationDialog
        onAgree={confirmationDialog.agreeCallback}
        onDisagree={() => setConfirmationDialog(confirmationInitialState)}
        open={confirmationDialog.open}
        actions={{ agree: 'Yes', disagree: 'No' }}
    >
        {confirmationDialog.message}
    </MatConfirmationDialog>

    const handleRefresh = useCallback(() => {
        // we use our own method here because the in built dx ones can't really tell when the detail has changed (owing to various signed urls changing)
        if (dataSource) { // we handle this separately from the main load for simplicity and so we're not dealing in promises
            const params = getRemoteOpParamsAndContractScopeParams({ loadOptions: storedLoadOptions?.current })
            CommonAPIService.getAll<PtwType>(
                getEndpoint,
                undefined, // we're not interested in resetting metadata here - if we decide we are, pass currentMetaData too so it doesn't refresh if it hasn't changed!
                undefined, // getEndpoint already has id baked in
                params
            ).then((x: any) => {
                const existingData = currentDataRef.current?.data;
                //console.log('existingData: ', existingData);
                if (x.data && existingData) {
                    x.data.map((ptw: PtwType) => {
                        const matching = existingData.find(r => r.id === ptw.id);
                        //console.log('matching: ', matching);
                        if (matching && matching?.updated_at !== ptw.updated_at || matching?.agreement?.id !== ptw.agreement?.id) {
                            //console.log('going to update...');
                            commonUpdateGridRow({
                                dataSource,
                                key: ptw.id,
                                changes: ptw,
                                updateMasterDetailInGridRef: dataGridRef
                            });
                        }
                    })
                }
                currentDataRef.current = x;
            });
        }
    }, [getEndpoint, dataSource]);

    useEffect(() => {
        const storeConfig: any = {
            key: "id",
            load: (loadOptions: any) => {
                refreshing.current = true;
                try {
                    if (!currentDataRef.current || allowReload.current || !isEqual(storedLoadOptions.current, loadOptions)) {
                        allowReload.current = false;
                        storedLoadOptions.current = loadOptions;
                        const params = getRemoteOpParamsAndContractScopeParams({ loadOptions })
                        const thisData = CommonAPIService.getAll<PtwType>(
                            getEndpoint,
                            setMetadata,
                            undefined,
                            params,
                            metaDataRef
                        ).then(x => {
                            currentDataRef.current = x;
                            setTimeout(() => {
                                refreshing.current = false; // just got new data so we may as well wait a bit before polling again
                            }, 3000)
                            return x
                        })
                        lastGridValue.current = thisData;
                        return thisData;
                    } else {
                        refreshing.current = false;
                        return lastGridValue.current;
                    }
                }
                catch {
                    refreshing.current = false;
                }
            },
            // @ts-ignore
            remove: key => {
                return CommonAPIService.del<any>(getEndpoint, undefined, key).then(x => {
                    allowReload.current = true;
                    dataGridRef.current?.instance.refresh(true)
                });
            },
            update: (id: string | number, values: any) => {
                return CommonAPIService.update<any>(getEndpoint, undefined, id, values).then(x => {
                    allowReload.current = true;
                    dataGridRef.current?.instance.refresh(true)
                });
            }
        }
        if (visit) {
            // we can only insert a PTW where we have the visit as context
            storeConfig['insert'] = (values: any) => {
                // NB this just inserts the basic ptw record (and creates the 'row').  The save method inside the detail form 
                // creates the child specific ptw e.g. 'hotworks or workingatheight etc... record
                const theseValues: BasePermitToWork = { visit: visit.id, ...values }
                return CommonAPIService.create<any>({ getEndpoint, values: theseValues }).then(x => {
                    allowReload.current = true;
                    dataGridRef.current?.instance.refresh(true)
                });
            }
        }
        const custom = new CustomStore(storeConfig);

        setDataSource(
            new DataSource({
                store: custom
            })
        );
    }, [getEndpoint, visit, metaDataRef]);



    const fetchDataTimer = useRef<any>();
    const [suspendPolling, setSuspendPolling] = useState<boolean>(false);

    const clearDataTimeout = useCallback(() => {
        window.clearTimeout(fetchDataTimer.current);
    }, [fetchDataTimer])

    useEffect(() => {
        // if the component is 'unmounted' it will be cleared.
        return () => {

            clearDataTimeout();
            fetchDataTimer.current = null;
        };
    }, [clearDataTimeout]);

    const poll = useCallback(() => {
        if (suspendPolling === false) {
            clearDataTimeout(); // we're just about to set a timeout, so we may as well make sure the last one is cleared
            fetchDataTimer.current = window.setTimeout(() => {
                !refreshing.current && handleRefresh();
                poll();
            }, 10000);
        }
    }, [suspendPolling, handleRefresh, clearDataTimeout])

    useEffect(() => {
        poll();
    }, [poll]);

    // useEffect(() => {
    //     // not really used now but previously polling was suspended 
    //     // when the list wasn't in view.  However now it's loaded in it's own
    //     // page and also useInView was causing the component to rerender multiple
    //     // times with increasing memory burden on each load when very particular
    //     // hard to track down CSS and spacing conditions were met
    //     setSuspendPolling(true);
    // }, []);

    // const handleDateColumns = (data: any): void => {
    //     ["date"].map(
    //         (x) => {
    //             if (data[x]) {
    //                 data[x] = saveDateFormat(data[x])
    //             }
    //         }
    //     )
    // };

    // const handleRowInserting = (values: any): void => {
    //     handleDateColumns(values.data);
    // };

    // const handleRowUpdating = (values: any): void => {
    //     handleDateColumns(values.newData);
    // }

    const getColumnPropsExt = useCallback((field: string): ColumnProps | undefined => {
        return metadata.loaded ? getColumnProps(field, metadata.activeMeta) : columnPropsPlaceHolder;
    }, [metadata])

    const handleRowUpdated = () => {
        if (metadata) {
            setMetadata({ ...metadata, activeMeta: metadata.POSTMeta });
        }
    }

    const onRowPrepared = (e: any) => {
        if (e.rowType === "data") {
            if (e.data.expired) {
                e.rowElement[0].style.color = 'red';
            } else if (e.data.active) {
                e.rowElement[0].style.color = 'green';
            }
        }
    }

    const renderColIfMetaDataDefined = useCallback((fieldName: string, requiredOveride: boolean, colPropsOverride = {}) => {
        const metaColProps = getColumnPropsExt(fieldName);
        if (metaColProps) {
            const colProps = { ...metaColProps, ...colPropsOverride }
            return (<Column {...colProps}>
                {requiredOveride && <RequiredRule />}
            </Column>
            )
        }
    }, [getColumnPropsExt])

    return (
        <InlineWrapper>
            {dataSource && (
                <DataGrid
                    ref={dataGridRef}
                    className='no-print'
                    dataSource={dataSource}
                    // onRowInserting={handleRowInserting}
                    // onRowUpdating={handleRowUpdating}
                    onRowUpdated={handleRowUpdated}
                    onRowPrepared={onRowPrepared}
                    {...getGridProps(metadata.activeMeta)}
                    onEditorPreparing={editableTextAreaOnPreparing(metadata.activeMeta)}
                    //onContentReady={() => setContentReady(true)}
                    onEditingStart={(e: any) => {
                        if (e.data?.submit_for_signing) {
                            e.cancel = true;
                        }
                    }}
                    showColumnHeaders
                    repaintChangesOnly
                    filterRow={{
                        visible: true,
                        applyFilter: 'auto',
                        //showOperationChooser: false,
                    }}
                    onInitNewRow={(e) => {
                        e.data.start_time = moment(visit?.scheduled_for + ' 8:00');
                        e.data.finish_time = moment(visit?.scheduled_for + ' 17:00');
                    }}
                    style={{ textTransform: "capitalize" }}
                >
                    {pageSize && <Paging defaultPageSize={pageSize} />}
                    {remoteOperations && <RemoteOperations
                        groupPaging={false}
                        grouping={true}
                        filtering={true}
                        paging={!!pageSize}
                    />}

                    <Editing
                        mode="cell"
                        //allowUpdating={metadata.privileges.PUT}
                        allowDeleting={metadata.privileges.POST}
                        allowAdding={visit && metadata.privileges.POST}
                    />
                    {thisShowContractsColumn && renderColIfMetaDataDefined('contract_ref', true, { caption: 'Contract', width: '100px' })}
                    {renderColIfMetaDataDefined('reference', false, { caption: 'Ref', width: '120px', minWidth: '120px' })}
                    {!visit && renderColIfMetaDataDefined('contractor_name', true, { caption: 'Contractor' })}
                    {!visit && renderColIfMetaDataDefined('equipment_name', true, { caption: 'Equipment' })}
                    {renderColIfMetaDataDefined('start_time', true, { format: 'dd/MM/yyyy HH:mm', width: visit ? undefined : '200px' })}
                    {renderColIfMetaDataDefined('finish_time', true, { format: 'dd/MM/yyyy HH:mm', width: visit ? undefined : '200px' })}
                    {renderColIfMetaDataDefined('ptw_status', false, { caption: 'Status', width: '125px' })}
                    {renderColIfMetaDataDefined('expired', false, { caption: 'Expired', dataType: 'boolean', width: '125px' })}
                    {renderColIfMetaDataDefined('ptw_type', false, { caption: 'Type', width: visit ? undefined : '150px' })}

                    {/* <Column type="buttons" dataField="actions" buttons={rowButtons} /> */}
                    {<Column type="buttons">

                        <Button name="delete" visible={isDeleteButtonVisible} onClick={handleDeletePermit} />
                        <Button hint="Cancel" icon="close" visible={isCancelButtonVisible} onClick={handleCancelPermit} />

                    </Column>}

                    <MasterDetail
                        enabled={true}
                        component={(e) => <WorkPermitDetail
                            initialData={e.data.data}
                            visitId={e.data.data.visit}
                            dataSource={dataSource}
                            contract={contract}
                            currentDataRef={currentDataRef}
                        />}
                    />
                </DataGrid>
            )}
            <RenderConfirmationDialogue />
        </InlineWrapper>
    );
};

export const PaperWrappedPTWsList = (props: PermitToWorkProps) => {
    return <InlineWrapper>
        <Paper elevation={3}>
            <GridHeader
                title="Work Permits"
                className={`${PrintChartAndTableLabels ? '' : 'no-print'} withCSVExport`}
            />
            <PTWsList {...props} />
        </Paper>

    </InlineWrapper>

}

export default PTWsList;