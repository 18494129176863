import React, { useRef } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { IconButton } from '@material-ui/core';
import { useHistory } from "react-router-dom";

// own
import QuoteDetail from "components/ContractInFocus/Financials/Containers/QuoteDetailView";
import { IFinancials } from "../../interfaces/financials.interface";
import { APIPrivileges } from "services/Interface/Interface";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import "../../Styles/AdditionalWorks.scss";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

interface AWDetailProps {
    contract: SiteContract;
    portfolio?: HydratedPortfolio;
    data: IFinancials;
    hideClientPermissions?: APIPrivileges;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
    forceReload: () => void;
}

const AWDetailModal = ({ contract, portfolio, data, hideClientPermissions, open, setOpen, forceReload }: AWDetailProps) => {
    const rowReference = useRef({});
    rowReference.current = { [data.id]: "expanding" }
    //const location = useLocation();
    const history = useHistory();
    const handleClose = (): void => {
        //forceReload();
        setOpen(false);
        // window.location.href = window.location.href.replace(window.location.hash, "");
        // The config below allows you to remove the hash part of the url without reloading the entire page and also to go back to the has with back button
        history.push(`${window.location.pathname}${window.location.search}${window.location.hash}`);
        window.history.replaceState(null, "Comment", `${window.location.pathname}${window.location.search}`);
        //history.push(history.location.hash, "");
        //history.push(`${history.location.pathname}`);
    }
    const isModalReady = (): boolean => (!!contract || !!portfolio);
    return (
        <Modal
            className="fullWidth"
            id="awDetailModal"
            centered={true}
            isOpen={isModalReady() && open}
            size={'xl'}
        >
            <ModalHeader>
                <div className="awHeaderDetail"></div>
                <div className="awHeaderDetail title">Details for Additional Works Item {data.aw_number}</div>
                <div className="awHeaderDetail closeButtonWrapper">
                    <IconButton onClick={handleClose} >
                        <CloseIcon />
                    </IconButton>
                </div>
            </ModalHeader>
            <ModalBody>
                {(contract || portfolio) && <QuoteDetail
                    data={data}
                    contract={contract}
                    portfolio={portfolio}
                    rowReference={rowReference}
                    dataSource={undefined}
                    forceReload={forceReload}
                    hideClientPermissions={hideClientPermissions}
                />
                }
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </Modal>

    )

}

export default AWDetailModal;