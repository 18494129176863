import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';

interface GetTheseFieldsConfigProps {
    HASAdminAccess?: boolean;
}

export const getInitialInfoFieldsConfig = ({ HASAdminAccess }: GetTheseFieldsConfigProps): FieldsFormConfig => ({
    full_investigation_mode: {
        hidden: true
    },
    confidential: {
        hidden: true
    },
    riddor_required: {
        hidden: true,
    },
    ap_name: {},
    ap_job_title: {},
    ap_email: {},
    // Record of incident
    occurred_at: {},
    location: {},
    incident_description: {
        multiline: true,
        rows: 10
    },
    reported_to: {},
    reported_at: {},
    // details of injury
    injury_details: {},
    injury_body_parts: {},
    // injury_diagram: added dynamically
    // immediate actions taken
    first_aid_given: {},
    ambulance_in_attendance: {},
    hospital_visit_required: {},
    area_closed_off_or_restricted: {},
    work_prohibited: {},
    equipment_out_of_service_and_secured: {},
    interim_safety_procedures_implemented: {},
    working_practices_changed_and_staff_trained: {},
    equipment_examined_by_competent_person: {},
    // further actions required
    site_accident_book_submitted: {},
    cctv_available: {},
    witness_statements_gathered: {},
    h_and_s_department_comments: {},
    // status: { // currently managed in formSettings panel
    //     excludeValues: ['reported', 'signed_off'],
    // }
});

interface InitialFormLayoutProps {
    HASAdminAccess: boolean;
}

export const useGetInitialInfoAccidentFormLayout = ({ HASAdminAccess }: InitialFormLayoutProps) => {
    // General details
    const thisLayout = [
        {
            fields: [
                "ap_name",
                "ap_job_title",
                "ap_email"
            ],
            group_title: "Details of the person affected",
            group_id: "Details of the person affected",
            className: "apDetails",
        },
        {
            fields: [
                "occurred_at",
                "location",
                "incident_description",
                "reported_to",
                "reported_at"
            ],
            group_title: "Record of incident",
            group_id: "Record of incident",
            className: "recordOfIncident"
        },
        {
            fields: [
                "injury_details",
                "injury_body_parts",
            ],
            group_title: "Injury Details",
            group_id: "Injury Details",
            className: "injuryDetails"
        },
        {
            fields: [
                "first_aid_given",
                "ambulance_in_attendance",
                "hospital_visit_required",
                "area_closed_off_or_restricted",
                "work_prohibited",
                "equipment_out_of_service_and_secured",
                "interim_safety_procedures_implemented",
                "working_practices_changed_and_staff_trained",
                "equipment_examined_by_competent_person",
            ],
            group_title: "Immediate Actions Taken",
            group_id: "Immediate Actions Taken",
            className: "immediateActionsTaken"
        },
        {
            fields: [
                "site_accident_book_submitted",
                "cctv_available",
                "witness_statements_gathered",
            ],
            group_title: "Further Actions Required",
            group_id: "Further Actions Required",
            className: "furtherActionsRequired"
        },
    ]
    if (HASAdminAccess) {
        thisLayout.push({
            fields: [
                "h_and_s_department_comments",
                // "status", // currently managed in form settings panel
            ],
            group_title: "H&S Department Use Only",
            group_id: "H&S Department Use Only",
            className: "HAndSUseOnly"
        })
    }
    return thisLayout;
};

export const getFullInvestigationFieldsConfig = ({ HASAdminAccess }: GetTheseFieldsConfigProps): FieldsFormConfig => ({
    full_investigation_mode: {
        hidden: true
    },
    confidential: {
        hidden: true
    },
    riddor_required: {
        hidden: true,
    },
    incident_type: {},
    // Details of the person affected
    ap_name: {},
    ap_address: {},
    ap_contact_number: {},
    ap_employment_status: {},
    ap_job_title: {},
    ap_email: {},
    ap_length_of_service: {},
    // Record of incident
    occurred_at: {},
    location: {},
    incident_description: {
        multiline: true,
        rows: 10
    },
    activity_description: {
        multiline: true,
        rows: 5
    },
    reported_to: {},
    reported_at: {},
    // details of injury
    injury_details: {},
    injury_body_parts: {},
    // injury_diagram: added dynamically
    // immediate actions taken
    first_aid_given: {},
    first_aider_name: {},
    first_aid_details: {
        multiline: true,
        rows: 3
    },
    has_ap_been_seen_by_a_medical_professional: {},
    authorities_involved: {
        multiline: true,
        rows: 3
    },
    // absence management
    afterwards: {
        sideEffect: (values, fieldConfigs, onChangeFormValues, previousValues) => {
            let changed = false;
            if (values['afterwards'] === "other" && (!previousValues || previousValues?.afterwards !== "other")) {
                fieldConfigs['afterwards_other'] = {
                    ...fieldConfigs['afterwards_other'],
                    hidden: false,
                };
                //fieldConfigs['afterwards_other'].label = "";
                changed = true;
            }
            if (values['afterwards'] !== "other" && (!previousValues || previousValues?.afterwards === "other")) {
                fieldConfigs['afterwards_other'] = {
                    ...fieldConfigs['afterwards_other'],
                    hidden: true,
                };
                changed = true;
            }
            return changed
        }
    },
    afterwards_other: { label: "" },
    sick_leave_start: {},
    return_to_work_date: {},
    // actions taken
    ambulance_in_attendance: {},
    hospital_visit_required: {},
    area_closed_off_or_restricted: {},
    work_prohibited: {},
    equipment_out_of_service_and_secured: {},
    interim_safety_procedures_implemented: {},
    working_practices_changed_and_staff_trained: {},
    equipment_examined_by_competent_person: {},
    // amsm
    // location
    access_and_egress_used: {},
    housekeeping_satisfactory: {},
    floor_conditions: {},
    lighing_adequate: {},
    noise_levels: {},
    weather_conditions: {},
    fall_from_unguarded: {},
    segregation_measures: {},
    appropriate_warning_signs: {},
    adequate_information: {},
    // plant and equipment
    correct_equipment: {},
    equipment_working_order: {},
    equipment_properly_used: {},
    equipment_guards_adequate: {},
    equipment_protective_devices_working: {},
    equipment_maintenance_records_up_to_date: {},
    equipment_control_layout_ok: {},
    correct_materials_and_substances: {},
    // safe working procedures
    safe_working_procedures: {},
    safe_working_procedures_up_to_date: {},
    safe_working_procedures_raa: {},
    safe_working_procedures_relevant: {},
    safe_working_procedures_on_site: {},
    safe_working_procedures_followed: {},
    ap_inducted: {},
    // the person involved
    ap_physically_suitable: {},
    ap_competent: {},
    ap_trained: {},
    work_safe_without_ppe: {},
    ap_wearing_correct_ppe: {},
    human_error: {},
    horseplay: {},
    violation: {},
    contributory_factors: {},
    // cause
    cause: {
        multiline: true,
        rows: 3
    },
    how_it_could_have_been_prevented: {
        multiline: true,
        rows: 3
    },
    // investigation submission
    investigation_completed_by: {},
    investigation_completed_by_job_title: {},
    investigation_submission_statement_made: {},
    investigation_submitted: {
        hidden: true
    },
    // further actions required
    site_accident_book_submitted: {},
    cctv_available: {},
    witness_statements_gathered: {},
    h_and_s_department_comments: {
        multiline: true,
        rows: 3
    },

    // status: { // currently managed in formSettings panel
    //     excludeValues: ['reported', 'signed_off'],
    // }
});

interface FullAccidentFormLayoutProps {
    HASAdminAccess: boolean;
    RenderAccidentActions: () => JSX.Element;
    RenderWitnessStatementsForm: () => JSX.Element;
}

export const useGetFullAccidentFormLayout = ({ HASAdminAccess, RenderAccidentActions, RenderWitnessStatementsForm }: FullAccidentFormLayoutProps) => {
    // General details
    const thisLayout = [
        {
            fields: [
                "incident_type",
            ],
            group_title: "Incident Type",
            group_id: "Incident Type",
            className: "incidentType",
        },
        {
            fields: [
                "ap_name",
                "ap_address",
                "ap_contact_number",
                "ap_employment_status",
                "ap_job_title",
                "ap_email",
                "ap_length_of_service",
            ],
            group_title: "Details of the person affected",
            group_id: "Details of the person affected",
            className: "apDetails",
        },
        {
            fields: [
                "occurred_at",
                "location",
                "incident_description",
                "activity_description",
                "reported_to",
                "reported_at"
            ],
            group_title: "Record of incident",
            group_id: "Record of incident",
            className: "recordOfIncident"
        },
        {
            fields: [
                "injury_details",
                "injury_body_parts",
                "injury_diagram"
            ],
            group_title: "Injury Details",
            group_id: "Injury Details",
            className: "injuryDetails"
        },
        {
            fields: [
                "first_aid_given",
                "first_aider_name",
                "first_aid_details",
                "has_ap_been_seen_by_a_medical_professional",
                "ambulance_in_attendance",
                "hospital_visit_required",
                "authorities_involved",
            ],
            group_title: "Immediate Actions Taken",
            group_id: "Immediate Actions Taken",
            className: "immediateActionsTaken"
        },
        {
            fields: [
                "afterwards",
                "afterwards_other",
                "sick_leave_start",
                "return_to_work_date",
            ],
            group_title: "Absence Management",
            group_id: "Absence Management",
            className: "absenceManagement",
        },
        {
            fields: [],
            group_title: "",
            group_id: "RenderWitnessStatementsForm",
            className: "",
            component: RenderWitnessStatementsForm
        },
        {
            fields: [
            ],
            group_title: "To be filled out by the account manager/site manager",
            group_id: "To be filled out by the account manager/site manager",
            className: "amsm",
            children: [
                {
                    fields: [
                        "access_and_egress_used",
                        "housekeeping_satisfactory",
                        "floor_conditions",
                        "lighing_adequate",
                        "noise_levels",
                        "weather_conditions",
                        "fall_from_unguarded",
                        "segregation_measures",
                        "appropriate_warning_signs",
                        "adequate_information",
                    ],
                    group_title: "Location",
                    group_id: "Location",
                    className: "location",
                },
                {
                    fields: [
                        "correct_equipment",
                        "equipment_working_order",
                        "equipment_properly_used",
                        "equipment_guards_adequate",
                        "equipment_protective_devices_working",
                        "equipment_maintenance_records_up_to_date",
                        "equipment_control_layout_ok",
                        "correct_materials_and_substances",
                    ],
                    group_title: "Plant and Equipment",
                    group_id: "Plant and Equipment",
                    className: "plantAndEquipment",
                },
                {
                    fields: [
                        "safe_working_procedures",
                        "safe_working_procedures_up_to_date",
                        "safe_working_procedures_raa",
                        "safe_working_procedures_relevant",
                        "safe_working_procedures_on_site",
                        "safe_working_procedures_followed",
                        "ap_inducted",
                    ],
                    group_title: "The process or procedure",
                    group_id: "The process or procedure",
                    className: "processOrProcedure",
                },
                {
                    fields: [
                        "ap_physically_suitable",
                        "ap_competent",
                        "ap_trained",
                        "work_safe_without_ppe",
                        "ap_wearing_correct_ppe",
                        "human_error",
                        "horseplay",
                        "contributory_factors",
                        "violation",
                    ],
                    group_title: "The person involved",
                    group_id: "The person involved",
                    className: "thePersonInvolved"
                }
            ]
        },
        {
            fields: [
                "area_closed_off_or_restricted",
                "work_prohibited",
                "equipment_out_of_service_and_secured",
                "interim_safety_procedures_implemented",
                "working_practices_changed_and_staff_trained",
                "equipment_examined_by_competent_person",

            ],
            group_title: "Actions Taken",
            group_id: "Actions Taken",
            className: "actionsTaken"
        },
        {
            fields: [
                "cause",
                "how_it_could_have_been_prevented",

            ],
            group_title: "Cause of the accident",
            group_id: "Cause of the accident",
            className: "accidentCause"
        },
        {
            fields: [],
            group_title: "",
            group_id: "RenderAccidentActions",
            className: "",
            component: RenderAccidentActions
        },
        {
            fields: [
                "investigation_submission_statement_made",
                "investigation_submitted",
                "investigation_completed_by",
                "investigation_completed_by_job_title",
            ],
            group_title: "Submission",
            group_id: "Submission",
            className: "submission"
        },
    ]
    if (HASAdminAccess) {
        thisLayout.push({
            fields: [
                "h_and_s_department_comments",
                // "status", // currently managed in form settings panel
            ],
            group_title: "H&S Department Use Only",
            group_id: "H&S Department Use Only",
            className: "HAndSUseOnly"
        })
    }
    return thisLayout;
};

export const getSignOffFieldsConfig = ({ HASAdminAccess }: GetTheseFieldsConfigProps): FieldsFormConfig => ({
    full_investigation_mode: {
        hidden: true
    },
    confidential: {
        hidden: true
    },
    // Details of the person affected
    ap_name: {},
    ap_job_title: {},
    ap_email: {},
    site_manager_at_time: {
        disabled: !HASAdminAccess,
    },
    // Record of incident
    occurred_at: {},
    location: {},
    incident_description: {
        multiline: true,
        rows: 10
    },
    work_process_involved: {},
    // details of injury
    injury_severity: {},
    injury_details: {},
    injury_body_parts: {},
    // injury_diagram: added dynamically
    // immediate actions taken
    first_aid_given: {},
    // absence management
    // actions taken
    ambulance_in_attendance: {},
    treatment: {},
    fit_for_work: {},
    time_off_required: {},
    riddor_required: {
        hidden: true,
    },
    h_and_s_department_comments: {
        multiline: true,
        rows: 3
    },
    signed_off_by: {},
    // status: { // currently managed in form settings panel
    //     excludeValues: ['investigation_open', 'investigation_closed'],
    // }
    // requires_further_investigation: {}
});

interface SignOffFormLayoutProps {
    HASAdminAccess: boolean;
    RenderAccidentActions: () => JSX.Element;
}

export const useGetSignOffFormLayout = ({ HASAdminAccess, RenderAccidentActions }: SignOffFormLayoutProps) => {
    // General details
    const thisLayout = [
        {
            fields: [
                "ap_name",
                "ap_job_title",
                "ap_email",
                "occurred_at",
                "location",
                "site_manager_at_time",
                "work_process_involved",
                "incident_description",
            ],
            group_title: "Accident Details",
            group_id: "Accident Details",
            className: "accidentDetails"
        },
        {
            fields: [
                "injury_severity",
                "injury_body_parts",
                "injury_details",
                "treatment",
                "fit_for_work",
                "time_off_required",
                //"riddor_required",
            ],
            group_title: "Injury Details",
            group_id: "Injury Details",
            className: "injuryDetails"
        },
        {
            fields: [],
            group_title: "",
            group_id: "RenderAccidentActions",
            className: "",
            component: RenderAccidentActions
        },
    ]
    if (HASAdminAccess) {
        thisLayout.push({
            fields: [
                "h_and_s_department_comments",
                "requires_further_investigation",
                "signed_off_by",
                // "status" // currently managed in form settings panel
            ],
            group_title: "H&S Department Use Only",
            group_id: "H&S Department Use Only",
            className: "HAndSUseOnly"
        }
        )
        //thisLayout[1].fields.splice(2, 0, "site_manager_at_time");
    }
    return thisLayout;
};

export const getRiddorFormConfig = ({ HASAdminAccess }: GetTheseFieldsConfigProps): FieldsFormConfig => ({
    accident: { hidden: true },
    accident__occurred_at: {
        typeOverride: "date",
        skipPostComponent: true
    }, // accident sourced
    company: {},
    point_of_contact: {},
    point_of_contact_and_job_title: {},
    poc_email_address: {},
    poc_telephone_numbers: {},
    accident__location: {
        skipPostComponent: true
    }, // same as?
    // Accident location details
    organisation_name: {},
    location_poc: {},
    local_authority: {},
    department_or_location_on_premise: {},
    type_of_work: {},
    type_of_accident: {},
    work_process_involved: {},
    main_factor_involved: {}, // same as?
    what_happened: {}, // same as?
    accident__ap_name: {
        skipPostComponent: true
    }, // accident sourced
    accident__ap_address: {
        skipPostComponent: true
    }, // accident sourced
    accident__ap_contact_number: {
        skipPostComponent: true
    }, // accident sourced
    //ap_employment_status: {}, same as work_status?
    accident__ap_job_title: {
        skipPostComponent: true
    }, // accident sourced
    ap_gender: {},
    accident__ap_email: {
        skipPostComponent: true
    }, // accident sourced
    //ap_length_of_service: {}, // accident sourced
    ap_work_status: {},
    ap_dob: {}, // accident sourced
    injury_severity: {},
    accident__injury_body_parts: {
        skipPostComponent: true
    },
    injury_details: {},
});

interface RiddorFormLayoutProps {
    HASAdminAccess: boolean;
    // RenderAPTrainingCoursesList: () => JSX.Element;
    // RenderAPQualificationsList: () => JSX.Element;
    // RenderCheckList: () => JSX.Element;
}

export const useGetRiddorFormLayout = ({
    HASAdminAccess,
    // RenderAPQualificationsList, 
    // RenderAPTrainingCoursesList, 
    // RenderCheckList 
}: RiddorFormLayoutProps) => {
    const thisLayout = [
        {
            fields: [
                "company",
                "point_of_contact_and_job_title",
                "poc_telephone_numbers",
                "poc_email_address"
            ],
            group_title: "Company Details",
            group_id: "Company Details",
            className: "companyDetails"
        },
        {
            fields: [
                "accident__location",
                "organisation_name",
                "location_poc",
                "local_authority",
                "department_or_location_on_premise",
                "type_of_work"
            ],
            group_title: "Accident Location Details",
            group_id: "Accident Location Details",
            className: "accidentLocationDetails"
        },
        {
            fields: [
                "accident__occurred_at",
                "type_of_accident",
                "work_process_involved",
                "main_factor_involved",
                "what_happened",
            ],
            group_title: "Accident Details",
            group_id: "Accident Details",
            className: "accidentDetails"
        },
        {
            fields: [
                "accident__ap_name",
                "accident__ap_address",
                "accident__ap_contact_number",
                "accident__ap_email",
                "accident__ap_job_title",
                "ap_gender",
                "ap_dob",
                "ap_work_status",
                "injury_severity",
                "accident__injury_body_parts",
                "injury_details",
            ],
            group_title: "Injured Person Details",
            group_id: "Injured Person Details",
            className: "injuredPersonDetails",
            //     children: [
            //         {
            //             fields: [],
            //             group_title: "",
            //             group_id: "RenderAPQualificationsList",
            //             className: "RenderAPQualificationsList",
            //             component: RenderAPQualificationsList
            //         },
            //         {
            //             fields: [],
            //             group_title: "",
            //             group_id: "RenderAPTrainingCoursesList",
            //             className: "RenderAPTrainingCoursesList",
            //             component: RenderAPTrainingCoursesList
            //         },
            //     ]
        },
        // {
        //     fields: [],
        //     group_title: "",
        //     group_id: "RenderCheckList",
        //     className: "RenderCheckList",
        //     component: RenderCheckList,
        //     skipInViewWrapper: true, // we do this because in the particular case that a render checklist item is expanded with dx datagrid, 
        //     // something appears to rerender on leaving view
        //     // and this causes a brief alternation between being the component not being displayed and being displayed as 'inView' is recalculated - which appears to cause some
        //     // shift in the scroll position.  The end result is that the checklist component is constantly reloaded slightly above the view port and thus you 
        //     // scroll up over it repeatedly.
        // },

    ]
    return thisLayout;
};