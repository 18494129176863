import React, { useState, ChangeEvent, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { timer } from 'rxjs';

// Own
import { headerSearch } from '../actions/Layout.actions';
import BaseHeaderHorizontal from 'components/Layout/BaseHeader/BaseHeaderHorizontal';

// Styles
import { HeaderFiller, useHeaderStyles } from './HeaderHorizontalStyles';

const HeaderHorizontal: React.FC = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [search, setSearch] = useState('');

  const classes = useHeaderStyles();

  const dispatch = useDispatch();

  const searchInput = useRef<HTMLInputElement>(null);

  const clearSearch = (): void => {
    setSearch('');
    searchDispatch('');
  }

  const searchDispatch = (search: string): void => {
    dispatch(headerSearch({ search }));
  }

  function handleEsc(event: any) {
    if (event.keyCode === 27) {
      clearSearch();
      setShowSearch(false);
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  const toggleSearch = (): void => {
    !showSearch && timer(200).subscribe(() => {
      !!searchInput.current && searchInput.current.focus();
    });

    setShowSearch(!showSearch);
    clearSearch();
    searchDispatch('');
  };

  const getIconClass = (showSearch: boolean): string =>
    showSearch ? classes.lightMenuIcon : classes.darkMenuIcon;

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    searchDispatch(event.target.value);
  }

  const renderSearchButton = () => (
    <>
      <IconButton
        edge='start'
        className={classes.menuButton}
        color='inherit'
        aria-label='Search'
        onClick={toggleSearch}
      >
        <SearchIcon className={getIconClass(showSearch)} />
      </IconButton>
      <HeaderFiller></HeaderFiller>
    </>
  );

  const renderSearchInput = () => (
    <>
      <input
        ref={searchInput}
        className="search-input"
        defaultValue={search}
        placeholder='Search...'
        onChange={handleSearchChange}
        value={search}
      />

      <IconButton
        edge='start'
        className={classes.searchCloseButton}
        color='inherit'
        aria-label='Search'
        onClick={toggleSearch}
      >
        <CloseIcon className={getIconClass(showSearch)} />
      </IconButton>
    </>
  )

  return (
    <BaseHeaderHorizontal
      isDark={!showSearch}
      appBarclassName={showSearch ? classes.searchAppBar : classes.MuiAppBar}
    >
      {!showSearch && renderSearchButton()}
      {showSearch && renderSearchInput()}
    </BaseHeaderHorizontal>
  );
};

export default withRouter(HeaderHorizontal);
