import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Dictionary } from "../../../../Interfaces/Entity.interface";
import { IconButton } from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import GridOnIcon from "@material-ui/icons/GridOn";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import PrintDisabledIcon from '@material-ui/icons/PrintDisabled';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
// Own
import {
  VisibilityControlIcons,
  VisibilityControlWrapper,
  VisibilityControlLabel
} from "./VisibilityControlStyles";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { Portfolio } from 'components/Portfolios/Interfaces/Portfolios.interface';

import { VisibilitySettings } from "../../Interfaces/RightContext.interfaces";

// Styles
import styles from "styles/app/common/variables.scss";

interface VisibilityControlProps {
  controlKey: string;
  className?: string;
  color?: string;
  panelLayout: boolean;
  canWrite?: boolean;
  visibilitySettings: VisibilitySettings;
  defaultOnClick: (icon: string) => void;
}

const VisibilityControl = ({
  controlKey,
  className = '',
  visibilitySettings,
  canWrite,
  panelLayout = false,
  color = styles.white,
  defaultOnClick
}: VisibilityControlProps) => {
  const controlOptions = visibilitySettings[controlKey as keyof VisibilitySettings];
  const getDisabledStyle = (enabled: boolean) => ({ opacity: enabled ? 1 : .4 })
  const controlName = controlOptions.getControlName ? controlOptions.getControlName(controlOptions) : controlKey;

  return (
    <VisibilityControlWrapper className={`${className}--item`}>

      <VisibilityControlLabel className={`${className}--label`}> {controlName} </VisibilityControlLabel>
      <VisibilityControlIcons className={`${className}--icons`} panelLayout={panelLayout} color={color}>
        {
          Object.keys(controlOptions).filter(option => option !== 'getControlName').map((option, idx) => {
            const controlOption = controlOptions[option];
            const value = controlOptions[option].value;
            const neverPrint = controlOptions[option].neverPrint;
            const customToggle = controlOption.toggleFunction;
            const updateKey = controlOption.updateKey // this is used by defaultOnClick for generic personalcontractsettings/maintenance report visibility settings update
            // and generally here to give the compenent a unique key for react.  So it's filled out even for custom toggles, just to give this component something to use.
            //TODO throw an error if there is no customToggle and no updateKey - flash it up briefly on snack bar
            const toggleFunction = customToggle || (updateKey ? () => { defaultOnClick(updateKey) } : () => { })
            const opacity = getDisabledStyle(value);
            if (typeof (value) === "undefined") {
              return (
                <IconButton
                  disabled={true}
                  key={option + idx}
                  onClick={() => { }}
                >
                  <PrintDisabledIcon></PrintDisabledIcon>
                  <VisibilityOffIcon></VisibilityOffIcon>
                </IconButton>
              )
            } else {
              return (
                <div key={option + idx}>
                  {neverPrint &&
                    <IconButton
                      disabled={true}
                      key={'neverprint-' + option + idx}
                      onClick={() => { }}
                    >
                      <PrintDisabledIcon></PrintDisabledIcon>
                    </IconButton>
                  }
                  <IconButton
                    disabled={!canWrite}
                    key={option + idx}
                    onClick={toggleFunction}
                  >
                    {option === "table" ? <GridOnIcon style={opacity} /> : null}
                    {option === "graph" ? <InsertChartIcon style={opacity} /> : null}
                    {option === "default" && value === true ? <VisibilityIcon style={opacity} /> : option === "default" && value === false ? <VisibilityOffIcon style={opacity} /> : null}
                    {option === "toggle" && <Switch
                      size="small"
                      defaultChecked={value}
                    />}
                  </IconButton>
                </div>
              )
            }
          }
          )}
      </VisibilityControlIcons>
    </VisibilityControlWrapper>
  );
};

export default VisibilityControl;

type VisibilityControlsProps = {
  visibilitySettings: VisibilitySettings;
  color?: string;
  className?: string;
  panelLayout?: boolean;
  canWrite?: boolean;
  defaultOnClick?: (updateKey: string, appliedTo: any) => void;
  appliedTo: any;
}

export const VisibilityControls = ({
  visibilitySettings,
  color,
  canWrite = false,
  className = '',
  panelLayout = false,
  defaultOnClick,
  appliedTo,
}: VisibilityControlsProps) => {
  return (
    <div className={className}>
      {Object.keys(visibilitySettings)
        .map((reportTypeKey) => {
          //console.log('report Type key: ')
          // here we need to do the work to set onClick based on the controlOptions
          //const onClick = controlOptions.toggleFunction?
          return (
            <VisibilityControl
              panelLayout={panelLayout}
              canWrite={canWrite}
              className={className}
              key={reportTypeKey}
              controlKey={reportTypeKey}
              color={color}
              visibilitySettings={visibilitySettings}
              // TODO: have to remodel the personalcontractsettings structure to support icon + key in the json structure on different levels
              defaultOnClick={(updateKey) => defaultOnClick && defaultOnClick(updateKey, appliedTo)}
            />
          )
        }
        )}
    </div>
  )
}