import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Wrapper, FlexCenterWrapper } from "./FlagsStyles";

const Cancelled = ({ count, color }) => {
  return (
    <Wrapper className="specialist-flag" style={{ color }}>
      <FontAwesomeIcon icon={faTimes} style={{ color }} />
      {count ? <FlexCenterWrapper>{count}</FlexCenterWrapper> : null}
    </Wrapper>
  );
};

Cancelled.propTypes = {
  count: PropTypes.number
};

export default Cancelled;
