import React, { useState, memo, useCallback, useReducer, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";


// Own
import HASSchedule from "components/Schedulers/HASSchedule";
import HASAccidents from "components/ContractInFocus/HAS/HASAccidents";
import { PaperWrappedPTWsList } from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/WorkPermits";
import HASNearMisses from "components/ContractInFocus/HAS/HASNearMisses";
import { HASCentreSpecialistMaintenance } from 'components/Schedulers/SpecialistMaintenance';
import * as visibilitySelector from 'components/ContractInFocus/Selectors/visibility.selectors';
import { Period } from "components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface";
import RightVisibilityMenu from "components/Common/Components/RightVisibilityMenu/RightVisibilityMenu";
import {
    HASCentreVisibilitySelector,
    showHASCentreAccidentsTableSelector,
    showHASCentreEventsTableSelector,
    showHASCentreNearMissesTableSelector,
    showHASCentreSchedulerSelector,
    showHASCentreWorkPermitsTableSelector,
    showHASCentreSpecialistTableSelector
} from "components/Profile/Selectors/Profile.selector";
import { defaultTogglePersonalSettings } from "components/Profile/Helpers/profileHelpers";
import HASCentreSubHeader from "components/HASCentre/HASCentreSubHeading";
import getMattersForDiscussionLogs from "components/ContractInFocus/Logs/Components/MattersForDiscussionLogs";
import { RefreshControlComponent } from "components/Common/Components/RightVisibilityMenu/Components/RightContextButton/refreshButton";
import InViewWrapper from 'components/Common/Components/InViewWrapper/InViewWrapper';
import HASLinkModal from "components/ContractInFocus/HAS/HASLinkModal";


import "./HASCentre.scss"

const HASCentre = () => {
    const visibilitySettings = useSelector(visibilitySelector.mainClientVisibilitySettingsObjectsSelector).data;
    const [period, setPeriod] = useState<Period>();
    const HASCentreVisibilityProfile = useSelector(HASCentreVisibilitySelector);
    const showScheduler = useSelector(showHASCentreSchedulerSelector);
    const showEventsTable = useSelector(showHASCentreEventsTableSelector);
    const showWorkPermitsTable = useSelector(showHASCentreWorkPermitsTableSelector);
    const showSpecialistTable = useSelector(showHASCentreSpecialistTableSelector);
    const showAccidentsTable = useSelector(showHASCentreAccidentsTableSelector);
    const showNearMissesTable = useSelector(showHASCentreNearMissesTableSelector);
    const [refreshAt, setRefreshAt] = useReducer((x) => x + 1, 1);
    const afterRefresh = useCallback(() => {
        //console.log('refreshed');
    }, []);
    const triggerRefresh = useCallback(() => {
        return new Promise(resolve => {

            setRefreshAt()
            setTimeout(() => {
                resolve(true);
            }, [250])

        })
    }, []); // each component consuming refreshAt will reload it's own data;

    const [fullScreenForm, setFullScreenForm] = useState<boolean>();

    const [inViewOptions, setInViewOptions] = useState({
        /* Optional options */
        threshold: 0,
        rootMargin: '10000px 0px 0px 0px' // Note we include such a large upper root margin so that if the page is reloaded while we are further down the page
        // all items above are immediately loaded - which means that we don't have to worry about glitches in scrolling when moving up the page
    });

    useEffect(() => {
        setTimeout(() => {
            setInViewOptions({
                /* Optional options */
                threshold: 0,
                rootMargin: '10000px 0px 150px 0px' // Note we include such a large upper root margin so that if the page is reloaded while we are further down the page
                // all items above are immediately loaded - which means that we don't have to worry about glitches in scrolling when moving up the page
            })
        }, 1000)
    }, []);

    let csvFileNameBase = 'Health And Safety Logs for All Contracts';

    const MattersForDiscussionLogs = memo(getMattersForDiscussionLogs({ remoteOperations: true, pageSize: 15, filterRow: true, csvFileNameBase: csvFileNameBase }));
    const ThisHASSchedule = useCallback(() => {
        return <HASSchedule
            visibilitySettings={visibilitySettings}
            contractReportContext={false}
            passedInPeriod={period}
            showContractsColumn
            defaultExceptionsOnly
            refreshAt={refreshAt}
            pageSize={15}
        />
    }, [refreshAt, period, visibilitySettings])

    const ThesePTWs = useCallback(() => <PaperWrappedPTWsList
        showContractsColumn
        contractField="contract_ref"
        remoteOperations
        pageSize={15}
        title="Work Permits"
        all
    // doesn't need refreshAt, as it polls
    />, [])

    const TheseAccidents = useCallback(() => {
        return <HASAccidents
            showContractsColumn
            contractField="contract"
            remoteOperations
            pageSize={15}
            setFullScreenFormForParentContext={setFullScreenForm}
            refreshAt={refreshAt}
        />
    }, [refreshAt]);

    const TheseNearMisses = useCallback(() => <HASNearMisses
        showContractsColumn
        contractField="contract"
        remoteOperations
        pageSize={15}
        setFullScreenFormForParentContext={setFullScreenForm}
        refreshAt={refreshAt}
    />, [refreshAt])

    const ThisSpecialistSchedule = useCallback(() => <HASCentreSpecialistMaintenance
        visibilitySettings={visibilitySettings}
        frozenFor={undefined}
        showContractsColumn
        contractField="contract_ref"
        remoteOperations
        pageSize={15}
        title="Specialist Maintenance"
        contractReportContext={false}
        passedInPeriod={period}
        defaultExceptionsOnly
        refreshAt={refreshAt}
    />, [refreshAt, period, visibilitySettings])

    return <>
        <RefreshControlComponent onclick={triggerRefresh} after={afterRefresh} />
        <>
            <HASCentreSubHeader
                period={period}
                setPeriod={setPeriod}
                initialStartDate={moment().subtract(5, "months").startOf('month')}
                initialEndDate={moment().add(6, "months").endOf('month')}
            />
            <div className={"HASCentreWrapper"}>
                <RightVisibilityMenu
                    //uses configuration fed into withPageContext - used for individual interface pages
                    visibilitySettings={HASCentreVisibilityProfile}
                    defaultOnClick={defaultTogglePersonalSettings}
                />
                <div>

                    {showScheduler && !fullScreenForm && <InViewWrapper
                        i={0}
                        inViewOptions={inViewOptions}
                        placeHolderMinHeight="100vh"
                        WrappedComponent={ThisHASSchedule}
                    />
                    }
                    {showEventsTable && !fullScreenForm && <InViewWrapper
                        i={1}
                        inViewOptions={inViewOptions}
                        placeHolderMinHeight="100vh"
                        WrappedComponent={MattersForDiscussionLogs}
                    />
                    }
                    {showAccidentsTable && <InViewWrapper
                        i={2}
                        inViewOptions={inViewOptions}
                        placeHolderMinHeight="100vh"
                        WrappedComponent={TheseAccidents}
                        pause={!!fullScreenForm}
                    />
                    }
                    {showNearMissesTable && <InViewWrapper
                        i={3}
                        inViewOptions={inViewOptions}
                        placeHolderMinHeight="100vh"
                        WrappedComponent={TheseNearMisses}
                        pause={!!fullScreenForm}
                    />
                    }
                    {showWorkPermitsTable && !fullScreenForm && <InViewWrapper
                        i={4}
                        inViewOptions={inViewOptions}
                        placeHolderMinHeight="100vh"
                        WrappedComponent={ThesePTWs}
                    />}
                    {showSpecialistTable && !fullScreenForm && <InViewWrapper
                        i={5}
                        inViewOptions={inViewOptions}
                        placeHolderMinHeight="100vh"
                        WrappedComponent={ThisSpecialistSchedule}
                    />}
                </div>
            </div>
        </>
        <HASLinkModal />
    </>


}

export default HASCentre