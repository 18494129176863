import { switchMap } from "rxjs/operators";
import { ofType } from "redux-observable";

import { PanelState } from "../../components/AdminPanel/Interfaces/Panel";
import { mode } from "../../components/Common/Consts/DataMode";

import * as fromRootActions from "../actions/root.actions";


export const panelChangeEventEpic = (action$: any) => action$.pipe(
  ofType(fromRootActions.PANEL_CHANGE_EVENT.start),
  switchMap(({panelState}: {panelState: PanelState}) => [
      ...(panelState.mode === mode.new ? [fromRootActions.removeEntity(panelState.panelType, panelState.recordId)] : [])//,
      // fromRootActions.panelChangeEventSuccess()
    ]
  )
);
  
export default [
  panelChangeEventEpic
];
