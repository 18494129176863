import { DataRow } from "../Interfaces/interactiveTable.interfaces";
import { transformDataForReactiveByCategoryChart } from "./ChartHelper";
import { ContractPeriod } from 'components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface';
import { ColumnProps } from "components/ContractInFocus/interfaces/dataGridColumn.interface";
import { CellData } from "components/ContractInFocus/Maintenance/Interfaces/interactiveTable.interfaces";
import { ContractPeriodDates } from "components/ContractInFocus/interfaces/contractInFocusActions.interfaces";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { sortByName } from "components/ContractInFocus/Maintenance/Helper/TablePolyfill.helpers";
import { roundNumber } from "components/ContractInFocus/Maintenance/Helper/Calculations.helper";


export const reactiveCategoryPrimaryColumn = {
  name: 'Total Reactive By Category'
};

export const getTotalColumnHeaderCaption = (selectedPeriod: ContractPeriod | ContractPeriodDates) => `${selectedPeriod.months[0]} - ${selectedPeriod.months[selectedPeriod.months.length - 1]}`;

export const createReactiveByCategoryRow = (columnMeta: ColumnProps[]) => {
  const newRow = columnMeta.reduce((acc, column, index, array) => {
    return {
      ...acc,
      [column.caption]: undefined,
    }
  }, {
    hidden: false,
    issue_category: "",
    name: "",
  });
  //console.log('newRow: ', newRow);
  return newRow;
}

export const generateColumnHeaders = (contractPeriod: ContractPeriod): ColumnProps[] => {

  const header = getTotalColumnHeaderCaption(contractPeriod);

  const columns: ColumnProps[] = contractPeriod.months.map(month => {
    return {
      caption: month,
      alignment: 'right',
      allowEditing: true,
      dataField: month,
      dataType: 'number'
    }
  });

  return [
    {
      caption: reactiveCategoryPrimaryColumn.name,
      alignment: 'left',
      allowEditing: true,
      dataField: 'issue_category',
      width: 250,
      dataType: 'string',
      key: 'total-reactive-by-category',
      className: 'my-first-cell'

    },
    ...columns,
    {
      caption: header,
      alignment: 'right',
      allowEditing: false,
      dataField: header,
      width: 150,
      dataType: 'string',
      key: header.toLowerCase().replace(/ /g, '-'),
      className: 'my-last-cell'
    },
  ]
}

export const transformForReactiveCategoryGridAndChart = (
  data: DataRow[],
  contractPeriod: ContractPeriod,
  primaryField: string,
  portfolio?: HydratedPortfolio
) => {
  const matrix = data.map((row) =>
    addPrefixColumnAndSuffixCalculatedColumnToDataRow(row, primaryField, contractPeriod)
  ).sort(sortByName);
  const table = simplifyForTable(matrix);
  // const key = Object.keys(matrix[0]).find(key => matrix[0][key]?.last);
  let chartInput = matrix;
  if (portfolio) {
    chartInput = matrix.filter(row => !row.header_pointer).map(x => ({ ...x, name: x.portfolio_label || x.name }));
  }


  const chartData = transformDataForReactiveByCategoryChart(chartInput, getTotalColumnHeaderCaption(contractPeriod));
  // console.log('chartData: ', chartData);
  return { chart: chartData, table, matrix, rawData: data };
};

const simplifyForTable = (data: DataRow[]): DataRow[] => {
  const isProtectedVariableKey = (key: string) => key === reactiveCategoryPrimaryColumn.name;//  || key.indexOf('-') !== -1;

  return data.map(record => {
    //const { name, key, issue_category, hidden, ...rowData } = record;
    const thisRecord = { ...record }
    Object.keys(thisRecord).map((key) => {
      if (thisRecord[key]?.hasOwnProperty('value') && !isProtectedVariableKey(key)) {
        const thisRecordAttribute = thisRecord[key] as CellData;
        const thisRecordValue = thisRecordAttribute.value;
        thisRecord[key] = thisRecordValue;
      }
    })
    return thisRecord;
    // return {
    //   name, key, issue_category, hidden, ...Object.keys(rowData).reduce((acc, key) =>
    //     isProtectedVariableKey(key)
    //       ? ({ ...acc, [key]: { ...(record[key] as CellData) } })
    //       : ({ ...acc, [key]: (record[key] as CellData).value }), {})
    // }
  })
}

const addPrefixColumnAndSuffixCalculatedColumnToDataRow = (record: any, primaryField: string, contractPeriod: ContractPeriod) => {
  const { name, key, ...rowData } = record;

  const primaryColumn = {
    [primaryField]: {
      dictKey: primaryField,
      value: name,
      readonly: true,
      first: true
    }
  };

  const calculatedTotalValue = contractPeriod.months.reduce((acc: any, columnKey: any) => {
    const recordValue = rowData[columnKey]?.value;
    const thisNum = isNaN(recordValue) ? acc : +recordValue + acc;
    return roundNumber(thisNum, 2)
  }, 0);

  const calculateTotalValueHeader = getTotalColumnHeaderCaption(contractPeriod);

  const calculateColumnAndValues = {
    [calculateTotalValueHeader]: {
      dictKey: calculateTotalValueHeader,
      url: null,
      value: calculatedTotalValue,
      readonly: true,
      last: true
    }
  };

  return { name, ...primaryColumn, ...rowData, ...calculateColumnAndValues };
};