interface ActionGroup {
  reducer: string;
  start: string;
  success: string;
  error: string;
}

export const createRequestActionTypes = (prefix: string): ActionGroup => ({
  reducer: `${prefix}_REDUCER`,
  start: `${prefix}_REQUEST`,
  success: `${prefix}_SUCCESS`,
  error: `${prefix}_ERROR`
});

export const reduceReducers = (...reducers: any) => (state: any, action: any) =>
  reducers.reduce((acc: any, r: any) => r(acc, action), state);

export type LookupType = {
  value: number;
  display_name: string;
};
