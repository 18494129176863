import { makeStyles } from "@material-ui/core";

import styles from '../../../../../styles/app/common/variables.scss';

export const useFabStyles = makeStyles((theme) => ({
  reportButton: {
    position: 'fixed',
    right: '0rem',
    top: `calc(${styles.navbarHeight} + 22.5rem)`,
    backgroundColor: styles.blueDark,
    '&:hover': {
      backgroundColor: '#02111f',
    }
  }
}));