import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// Own
import { TabbedPanel } from "components/Common/Components/TabPanel";
import { Person } from "components/AdminPanel/People/Interfaces/People.interface";
import * as actions from "components/AdminPanel/People/Actions/People.actions";
import * as selectors from "components/AdminPanel/People/Selectors/People.selector";
import peopleServices from "components/AdminPanel/People/Services/peopleServices";
import { AdminImageForm } from "components/AdminPanel/Containers/AdminImageForm";
import { useSortedJobTitles } from "components/AdminPanel/People/Helper/People.customHooks";
import {
  personAvatar,
  getPersonFormItems,
  getPersonFormActions,
} from "components/AdminPanel/People/Models/PeopleDetail.model";
import { setDetailPage } from "components/AdminPanel/Actions/adminPanel.actions";
import { getPanelSelectorFactory } from "components/AdminPanel/Selectors/Selectors";
import { personAccessiblePortfoliosSelectorFactory } from "components/AdminPanel/People/Selectors/People.selector";
import AccessibleContractsView from "components/AdminPanel/Contracts/Containers/ContractSublistView/AccessibleContracts";
import AccessiblePortfoliosView from "components/AdminPanel/Contracts/Containers/ContractSublistView/AccessiblePortfolios";
import PrimaryContractRolesView from "components/AdminPanel/Contracts/Containers/ContractSublistView/PersonPrimaryContractRoles";
import { FORM_ROW_ID } from "components/AdminPanel/Models/AdminPanel.model";
import { personContractRolesSelectorFactory } from "components/AdminPanel/Contracts/Selectors/Contracts.selectors";
import { AccessibleContractI } from 'components/AdminPanel/Contracts/Interfaces/Contract.interface';
import { AccessiblePortfolioI } from "components/Portfolios/Interfaces/Portfolios.interface";
import { ImageConfig } from "components/AdminPanel/Containers/AdminImageForm/AdminImageForm";

interface PeopleDetailViewProps {
  panelId: string;
  data: { data: Person };
  formOnly: boolean;
}

const PeopleDetailView: React.FC<PeopleDetailViewProps> = ({
  panelId,
  data: { data },
  formOnly,
}) => {
  const personId = data.id;
  const contractSublistTestId = "people-contract-detail-view";
  const portfolioSublistTestId = "people-portfolio-detail-view";
  const selectAdminPanelState = useSelector(getPanelSelectorFactory(panelId));
  const selectPeopleState = useSelector(selectors.allPeopleSelector);
  const selectPerson = useSelector(selectors.personByIdSelector(data.id));
  const accessibleContractsState = useSelector(selectors.personAccessibleContractsSelectorFactory(data.id));
  const accessiblePortfoliosState = useSelector(personAccessiblePortfoliosSelectorFactory(data.id));
  const personContractRolesState = useSelector(personContractRolesSelectorFactory(data.id));
  const [explicitContractLinksCount, setExplicitContractLinksCount] = useState(accessibleContractsState.data.length);
  const [explicitPortfolioLinksCount, setExplicitPortfolioLinksCount] = useState<number>();
  const [contractRolesCount, setContractRolesCount] = useState(personContractRolesState.length);
  const explicitContractLinksTabTitle = explicitContractLinksCount ? `Explicit Contract Links (${explicitContractLinksCount})` : "Explicit Contract Links"
  const explicitPortfolioLinksTabTitle = explicitPortfolioLinksCount ? `Explicit Portfolio Links (${explicitPortfolioLinksCount})` : "Explicit Portfolio Links"
  const contractRolesTitle = contractRolesCount ? `Contract Roles (${contractRolesCount})` : "Contract Roles"
  const peopleTabs = [`Detail`, explicitContractLinksTabTitle, explicitPortfolioLinksTabTitle, contractRolesTitle];

  useEffect(() => {
    setExplicitContractLinksCount(accessibleContractsState.data.length);
  }, [accessibleContractsState.data.length])

  useEffect(() => {
    setExplicitPortfolioLinksCount(accessiblePortfoliosState.data.length);
  }, [accessiblePortfoliosState.data.length]);

  useEffect(() => {
    setContractRolesCount(personContractRolesState.length);
  }, [personContractRolesState.length])
  const jobTitleChoices = useSortedJobTitles();
  const dispatch = useDispatch();
  // const organisationsData = useSelector(allOrganisationsSelector);
  // const currentJobTitleOptions = useRef(null);
  // const peopleFormFieldAdjuster = getPeopleFormFieldsAdjuster(organisationsData, currentJobTitleOptions, "filterRow");

  const handleChangeTab = (index: number) => {
    dispatch(setDetailPage(panelId, data.id, index));
  };

  const [imagesConfig, setImagesConfig] = useState<ImageConfig[]>([]);

  useEffect(() => {
    const onAvatarUpload = (file: any, progress: any) => {
      peopleServices.updatePersonAvatar(data.id, file).subscribe();
    };

    const onAvatarClear = () => {
      peopleServices.clearPersonAvatar(data.id).subscribe();
    };

    setImagesConfig(
      [
        {
          imageCaption: personAvatar.label,
          imageKey: personAvatar.key,
          onUpload: onAvatarUpload,
          onClear: onAvatarClear
        },
      ]
    )
  }, [data])

  const handleCancel = (person: Person): void => {
    const IS_GRID_FORM = person.id === FORM_ROW_ID;
    IS_GRID_FORM && dispatch(actions.clearPsuedoPerson());
  };

  function handleAccessibleContractListSubmit(contractIds: number[]) {
    const accessObjs = contractIds.map((contractId) => (
      {
        "person": selectPerson.data.id,
        "contract": contractId
      }
    ))
    peopleServices
      .linkAccessibleContractsToPerson(selectPerson.data, accessObjs)
      .then(() => {
        console.log('done');
      });
  }

  function handleAccessiblePortfolioListSubmit(portfolioIds: number[]) {
    const accessObjs = portfolioIds.map((portfolioId) => (
      {
        "person": selectPerson.data.id,
        "portfolio": portfolioId
      }
    ))
    peopleServices
      .linkAccessiblePortfoliosToPerson(selectPerson.data, accessObjs)
      .then(() => {
        console.log('done');
      });
  }

  const handleUnlinkAccessibleContract = (accessObj: AccessibleContractI) => {
    peopleServices.unlinkAccessibleContractsFromPerson(
      selectPerson.data,
      accessObj.id
    );
  };

  const handleUnlinkAccessiblePortfolio = (accessObj: AccessiblePortfolioI) => {
    peopleServices.unlinkAccessiblePortfoliosFromPerson(
      selectPerson.data,
      accessObj.id
    );
  };

  const handleUpdate = (oldPerson: Person, person: Person): void => {
    const IS_GRID_FORM = oldPerson.id === FORM_ROW_ID;

    IS_GRID_FORM
      ? peopleServices.createPerson({ ...person, avatar: undefined }, panelId)
      : peopleServices.updatePerson(
        person.id,
        oldPerson,
        person,
        selectPeopleState.recordmeta[person.id]
      );
  };

  const tabIndex = selectAdminPanelState[data.id]?.detailIndex;

  const getDetailTab = () => {
    // Get the meta of that person, and update the job choices
    // from the main list meta from global state
    // HT Note: we use items: dictToArray(store.getState().organisations.lookup), in the getPersonFormItems
    // to get the organisation choices, presumably because depending on the form we don't always require that field and hence those choices...
    const meta = {
      ...selectPerson.meta
      // job_title: {
      //   ...selectPerson.meta.job_title,
      //   choices: selectPeopleState.meta.job_title.choices,
      // },
    };

    // formOnly
    return (
      <AdminImageForm
        data={selectPerson.data}
        meta={selectPerson.meta}
        formMeta={getPersonFormItems(meta, formOnly, jobTitleChoices)}
        formActions={getPersonFormActions}
        onUpdate={handleUpdate}
        onCancel={handleCancel}
        imagesConfig={imagesConfig}
      //passedOnFieldDataChanged={peopleFormFieldAdjuster} //NB as this is for a 'manual' form I think a different approach is probably needed... this works for 
      // the masterView as it uses callbacks the datagrid calls when editing rows... however perhaps the approach we will use for this detailView with 'manual'
      // forms can be used also in the grid setting so we have one approach...
      />
    );
  };

  return (
    <>
      {selectPerson.data && (
        <TabbedPanel
          pageIndex={tabIndex && tabIndex > peopleTabs.length - 1 ? 0 : selectAdminPanelState[data.id]?.detailIndex || 0}
          showTabs={data.id !== FORM_ROW_ID}
          onChangeTab={handleChangeTab}
          tabs={formOnly ? [peopleTabs[0]] : peopleTabs}
        >
          <div>{selectPerson.meta && getDetailTab()}</div>
          <div>
            <AccessibleContractsView
              panelId={panelId}
              accessibleContractsState={accessibleContractsState}
              dataTestId={contractSublistTestId}
              id={personId}
              masterRecordData={selectPerson.data}
              masterRecordType="person"
              selectActionIdentifier="explicitAccess"
              onAddLinks={handleAccessibleContractListSubmit}
              onUnlink={handleUnlinkAccessibleContract}
              linkPanelTitle={"GRANT " + selectPerson.data.email + " ACCESS TO CONTRACTS"}
            />
          </div>
          <div>
            <AccessiblePortfoliosView
              panelId={panelId}
              accessiblePortfoliosState={accessiblePortfoliosState}
              dataTestId={portfolioSublistTestId}
              id={personId}
              masterRecordData={selectPerson.data}
              masterRecordType="person"
              selectActionIdentifier="portfolioExplicitAccess"
              onAddLinks={handleAccessiblePortfolioListSubmit}
              onUnlink={handleUnlinkAccessiblePortfolio}
              linkPanelTitle={"GRANT " + selectPerson.data.email + " ACCESS TO PORTFOLIOS"}
            />
          </div>
          <div>
            <PrimaryContractRolesView
              panelId={panelId}
              id={personId}
              personContractRoles={personContractRolesState}
            />
          </div>
        </TabbedPanel>
      )}
    </>
  );
};

// PeopleDetailView.whyDidYouRender = true;

export default React.memo(PeopleDetailView);
