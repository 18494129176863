import React, { useState, useEffect, useRef, useCallback, useReducer } from 'react';
import API from 'services/API/API.js';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import DataGrid, {
    Column,
    SearchPanel,
    HeaderFilter,
} from "devextreme-react/data-grid";
import { Paper } from "@material-ui/core";


// Own
import { store } from "store/store";
import { dateUDF, standardDashFormat } from 'components/Common/Utils/Dates.js';

import { AutoInput } from 'components/Common/Components/AutoInput/AutoInput';
import { FieldsFormConfig, getSuggestionsType } from "components/Common/Components/DocumentsGrid/DocumentsGrid.interface";
import { FieldMetaGroup, FormValues } from "components/Common/Interfaces/Entity.interface";
import { createSupplierContract, simpleFetchBaseFromTenant, updateSupplierContract, lookUpSupplierContractFocalPointInfo, lookUpSupplierContractSuggestions, deleteSupplierContract } from "components/AdminPanel/Contracts/Services/contractService";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { fetchSupplierContractCreatePreFlightInfo, fetchSupplierContractUpdatePreFlightInfo } from "components/Schedulers/Services/visitService"
import { SupplierContract, SupplierService } from 'components/Schedulers/Interfaces/Schedule.interfaces';
import { addNotification } from 'components/Notification/Actions/Notification.actions';
import { NOTIFICATION_WARNING } from 'components/Notification/Constants/constants';
import { getDimTitle } from "helpers/Forms/modal.helper";
import { internalMemberSelector, amSuperUserSelector } from 'components/Profile/Selectors/Profile.selector';
import { newGetPayloadFromRef, getDataAndMetaAndPUTMeta } from 'services/API/API.helper';
import { SupplierServiceContractsResponse } from 'components/Schedulers/Interfaces/Schedule.interfaces';
import SupplierServiceForm from "components/Schedulers/VisitModal/Components/SupplierServiceDetail/SupplierServiceForm";
import {
    fetchSupplierNameOptions,
    getSupplierNameOptionLabel,
    getSupplierNameOptionValue,
    getSupplierRefOptionLabel,
    getSupplierRefOptionValue,
    fetchSupplierRefOptions,
    fillSupplierDetailsByName,
    fillSupplierDetailsByRef,

} from "components/Schedulers/VisitModal/Components/SupplierServiceDetail/supplierFormHelpers";

// Styles
import {
    SupplierServiceDetailsFormWrapper,
    SupplierContractDetailsPOSuggestionsWrapper
} from './SupplierServiceDetailStyles';
import "components/Schedulers/VisitModal/Components/SupplierServiceDetail/SupplierServiceDetailForm.scss";
import "components/ContractInFocus/ContractReportVisibility/contractReportVisibility.scss";
import "components/Schedulers/VisitModal/Components/SupplierServiceDetail/SupplierServiceDetailStyles";
import { formValueConsideredMissing } from "store/Common/Helpers/commonHelpers";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

interface AcceptableValueSubstitutesI {
    [index: string]: string;
}
const today = new Date();
const outOfContractString = 'OutOfContract';

const orderNumberConfig = {};
const orderNumberHiddenConfig = {
    style: {
        visibility: "hidden"
    }
}
const acceptableValueSubstitutes: AcceptableValueSubstitutesI = {
    'contractor': 'supplier_name',
}

const fieldDependencies: { [index: string]: string[] } = {
    "order_number": ["contract_start_date", "contract_end_date"],
    "supplier_ref": ["supplier_name", "supplier_address"]
};

const createLookupEndAdornment = (action: () => void, disabled: boolean) => {
    return <IconButton
        aria-label="toggle password visibility"
        onClick={action}
        onMouseDown={() => { }}
        edge="end"
        disabled={disabled}
    >
        <SearchIcon />
    </IconButton>
}

const createUseValueEndAdornment = (action: () => void, disabled?: boolean) => {
    return <IconButton
        aria-label="transfer field contents"
        onClick={action}
        onMouseDown={() => { }}
        edge="end"
        disabled={disabled}
        className="useValueButton"
    >
        <ArrowLeftIcon />
    </IconButton>
};

interface SupplierServiceDetailsFormProps {
    portfolio?: HydratedPortfolio,
    initialContract?: SiteContract,
    supplierService?: SupplierService,
    supplierContractsResponse?: SupplierServiceContractsResponse,
    setSupplierService: React.Dispatch<any>;
    wipe?: boolean;
    setModalTitle: React.Dispatch<React.SetStateAction<(() => JSX.Element) | undefined>>;
    refreshGrid?: React.DispatchWithoutAction;
    getSupplierServiceContractsResponse: (callback?: any) => void;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const SupplierServiceDetailsForm = (
    {
        initialContract,
        portfolio,
        supplierService,
        setSupplierService,
        wipe,
        setModalTitle,
        refreshGrid,
        getSupplierServiceContractsResponse,
        setOpen,
    }: SupplierServiceDetailsFormProps) => {

    let breakUpdateCycle = useRef(true);

    const [contract, setContract] = useState(initialContract);
    const [thisContractRef, setThisContractRef] = useState(supplierService?.contract__contract_ref || contract?.contract_ref);
    const [showTransferForm, setShowTransferForm] = useState(false);
    const [supplierContractsResponse, setSupplierContractsResponse] = useState<SupplierServiceContractsResponse>();


    const assignSupplierContractsResponse = useCallback((response: any) => {
        setSupplierContractsResponse(response);
    }, []);

    const populateSupplierContractsResponse = useCallback(() => {
        getSupplierServiceContractsResponse({
            callback: assignSupplierContractsResponse
        });
    }, [assignSupplierContractsResponse, getSupplierServiceContractsResponse]);

    useEffect(() => {
        populateSupplierContractsResponse();
    }, [populateSupplierContractsResponse]);

    const [supplierContracts, setSupplierContracts] = useState<SupplierContract[]>();
    const [supplierContract, setSupplierContract] = useState<SupplierContract | undefined>();
    const [supplierContractsInfo, setSupplierContractsInfo] = useState('');
    const [canChange, setCanChange] = useState(false);
    const [metaForChange, setMetaForChange] = useState<FieldMetaGroup>({})

    const supplierContractsLength = supplierContracts?.length || 0;
    const [currentSupplierContractIndex, setCurrentSupplierContractIndex] = useState(supplierContractsLength - 1 || 0);

    useEffect(() => {
        if (supplierContractsResponse) {
            setCanChange(supplierContractsResponse?.permissions ? supplierContractsResponse?.permissions["POST"] : false)
            setMetaForChange(supplierContractsResponse?.putMeta || supplierContractsResponse?.meta || {})
            setSupplierContracts(supplierContractsResponse?.data);
            setCurrentSupplierContractIndex(supplierContractsResponse?.data.length ? supplierContractsResponse?.data.length - 1 : -1);
        } else {
            fetchSupplierContractCreatePreFlightInfo({
                contractRef: thisContractRef,
                portfolioId: portfolio?.id
            }).then((response) => {
                const { canCreate } = response;
                setCanChange(canCreate);
                setMetaForChange(response.meta);
            })
        }

    }, [supplierContractsResponse, portfolio, thisContractRef]);

    // useEffect(() => {
    //     if (thisContractRef) {
    //         if (supplierContract) {
    //             // adapt for portfolio
    //             fetchSupplierContractUpdatePreFlightInfo({
    //                 contractRef: thisContractRef,
    //                 portfolioId: portfolio?.id,
    //                 supplierContractId: supplierContract.id
    //             }).then((response) => {
    //                 const { canUpdate } = response;
    //                 setCanChange(canUpdate);
    //                 setMetaForChange(response.meta);
    //             })
    //         } else {
    //             // adapt for portfolio
    //             fetchSupplierContractCreatePreFlightInfo({
    //                 contractRef: thisContractRef,
    //                 portfolioId: portfolio?.id
    //             }).then((response) => {
    //                 const { canCreate } = response;
    //                 setCanChange(canCreate);
    //                 setMetaForChange(response.meta);
    //             })
    //         }
    //     }

    // }, [thisContractRef, supplierContract, portfolio])

    const [fieldConfigs, setFieldConfigs] = useState<FieldsFormConfig>({});
    const [importFieldConfigs, setImportFieldConfigs] = useState<FieldsFormConfig>({});
    const stashedImportValuesToApply = useRef<{ [index: string]: any }>();
    const amSuperUser = useSelector(amSuperUserSelector);

    const internalMember = useSelector(internalMemberSelector);

    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 1);
    const [mustRefreshImportForm, forceUpdateImportForm] = useReducer((x) => x + 1, 1);

    const importFormValuesRef = useRef<FormValues>({});
    const differingFieldsCountRef = useRef<number>(0);

    const [readyToCreate, setReadyToCreate] = useState(false);
    const currentFocus = useRef<string>();
    const [formWidth, setFormWidth] = useState("100%");
    const [showImportableData, setShowImportableData] = useState(false);
    const [showPOSuggestions, setShowPOSuggestions] = useState(false);
    const [poSuggestions, setPOSuggestions] = useState([]);
    const [importsDisabled, setImportsDisabled] = useState(false);
    const [importAlertMessage, setImportAlertMessage] = useState<string>();
    const [showImportBlankFieldsOverrideAlert, setShowImportBlankFieldsOverrideAlert] = useState(false);
    const [showImportSuppierRefMismatchAlert, setShowImportSuppierRefMismatchAlert] = useState(false);
    const [blankFieldsWillBePopulated, setBlankFieldsWillBePopulated] = useState(false);
    const [baseContract, setBaseContract] = useState<SiteContract>();
    const includeBaseContractInLookups = useRef(false);

    useEffect(() => {
        supplierContracts && setSupplierContract(supplierContracts[currentSupplierContractIndex])
    }, [supplierContracts, currentSupplierContractIndex])

    useEffect(() => {
        if (supplierContracts) {
            let info = '';
            supplierContracts.map((x, i) => {
                if (i) {
                    info += '; '
                }
                const hasDates = x.contract_start_date && x.contract_end_date
                info += `PO: ${x.order_number} (${hasDates ? moment(x.contract_start_date).format(standardDashFormat) + '-' : ''} ${hasDates ? moment(x.contract_end_date).format(standardDashFormat) : ''})`
            }

            )
            setSupplierContractsInfo(info);
        }
    }, [supplierContracts])

    const getCurrentSupplierContractIndex = useCallback(() => {
        return supplierContracts ? supplierContracts.findIndex((x) => x.id === supplierContract?.id) : undefined;
    }, [supplierContracts, supplierContract]);

    const putInContractCreateMode = useCallback(() => {
        setSupplierContract(undefined);
        formValuesRef.current = {};
        resetForms();
    }, []);

    const selectSupplierContract = useCallback((direction?: 'previous' | 'next') => {
        let thisSupplierContractIndex = getCurrentSupplierContractIndex();
        let targetSupplierContractIndex = supplierContractsLength > 0 ? thisSupplierContractIndex : supplierContractsLength - 1;
        targetSupplierContractIndex = targetSupplierContractIndex || 0;
        if (thisSupplierContractIndex !== undefined) {
            if (direction === "previous") {
                if (thisSupplierContractIndex > 0) {
                    targetSupplierContractIndex -= 1;
                }
            }
            if (direction === "next") {
                if (thisSupplierContractIndex < (supplierContractsLength - 1)) {
                    targetSupplierContractIndex += 1;
                }
            }
        }
        setCurrentSupplierContractIndex(targetSupplierContractIndex);
    }, [supplierContractsLength, getCurrentSupplierContractIndex]);

    const selectPreviousSupplierContract = useCallback(() => {
        selectSupplierContract("previous")
    }, [selectSupplierContract])

    const selectNextSupplierContract = useCallback(() => {
        selectSupplierContract("next")
    }, [selectSupplierContract]);

    const cancelContractCreateMode = useCallback(() => {
        supplierContracts && setSupplierContract(supplierContracts[currentSupplierContractIndex || supplierContracts.length - 1]);
    }, [currentSupplierContractIndex, supplierContracts]);

    const getFieldFormConfig = useCallback((
        supplierContract?: SupplierContract,
        currentValuesRef?: React.MutableRefObject<FormValues>,
        currentFocus?: React.MutableRefObject<string | undefined>,
        canChange?: boolean
    ): FieldsFormConfig => {
        // This method should be called every time the component fully re-renders.
        // However the sideEffect methods it defines will be run every time a value changes (by runSideEffects in onChangeFormValues).  
        // If any of the sideEffects return a truthy value, the onChangeForm values will
        // ensure that the component re-renders.  However the sideeffects are also used to adjust this form prior to setting the formConfigState
        // when the component renders - with the results of this call (called from setupFormFromSupplierContract).
        return {
            'contract': { ignoreMissing: true, hidden: true, autoCalculator: () => contract?.id, disabled: true },
            'equipment_name': {
                forceRequired: true,
                hideOnImportForm: true,
                skipForm: !!supplierService
            },
            'supplier_service': { ignoreMissing: !supplierService, hidden: true, disabled: true, skipForm: !!supplierService },
            'supplier_ref': {
                controlClassName: 'full-height',
                typeOverride: 'autoComplete',
                //skipForm: !!supplierService,
                forceRequired: false,
                disabled: !!supplierService?.contractor.supplier_ref,
                freeSolo: false,
                getOptionLabel: getSupplierRefOptionLabel,
                getOptionValue: canChange ? getSupplierRefOptionValue : undefined,
                fetchAutoCompleteOptions: canChange ? fetchSupplierRefOptions : undefined,
                // onBlur: (values, onChangeFormValues) => {
                //     if (currentFocus) {
                //         // make sure currentFocus is moved onblur, so we can run
                //         currentFocus.current = "contact"
                //     }
                //     const this_supplier_ref = values["supplier_ref"];
                //     breakUpdateCycle.current = false;
                //     this_supplier_ref ? fillSupplierDetailsByRef(this_supplier_ref as string, onChangeFormValues, breakUpdateCycle) : onChangeFormValues({ 'supplier_ref': this_supplier_ref });
                // },
                onChangeOption: (option, onChangeFormValues, reason) => {
                    const supplier_ref = getSupplierRefOptionValue(option);
                    if (reason === "clear") {
                        if (currentValuesRef?.current['supplier_address']) {
                            currentValuesRef.current['supplier_address'] = undefined;
                        }
                        if (currentValuesRef?.current['supplier_name']) {
                            currentValuesRef.current['supplier_name'] = undefined;
                        }
                        if (currentValuesRef?.current['supplier_ref']) {
                            currentValuesRef.current['supplier_ref'] = undefined;
                        }
                    }
                    if (currentFocus) {
                        // as we may lose focus on filling supplier name we want to jump to the next field
                        currentFocus.current = "order_number"
                    }
                    breakUpdateCycle.current = false;
                    supplier_ref ? fillSupplierDetailsByRef(supplier_ref as string, onChangeFormValues, breakUpdateCycle) : onChangeFormValues({ 'supplier_ref': supplier_ref });
                },
                sideEffect: (values, fieldConfigs, onChangeFormValues) => {
                    let changed = false;
                    if (!supplierService?.contractor.supplier_ref && currentFocus?.current !== "supplier_ref") {
                        // we're only going to consider changing the defaults expressed on supplier name and address if the subcontractor doesn't have 
                        // a supplier ref
                        const supplier_ref = values["supplier_ref"];
                        const supplier_ref_has_value = supplier_ref && typeof supplier_ref == "string" && supplier_ref.trim().length > 0;
                        console.log('supplier_ref: ', supplier_ref);
                        const disable_supplier_ref = supplier_ref_has_value; //&& !amSuperUser
                        if (disable_supplier_ref && !fieldConfigs['supplier_address'].disabled) {
                            fieldConfigs['supplier_address'].disabled = true;
                            changed = true;
                        }
                        if (!disable_supplier_ref && fieldConfigs['supplier_address'].disabled) {
                            fieldConfigs['supplier_address'].disabled = false;
                            changed = true;
                        }
                        if (disable_supplier_ref && !fieldConfigs['supplier_name'].disabled) {
                            fieldConfigs['supplier_name'].disabled = true;
                            changed = true;
                        }
                        if (!disable_supplier_ref && fieldConfigs['supplier_name'].disabled) {
                            fieldConfigs['supplier_name'].disabled = false;
                            changed = true;
                        }
                    }
                    return changed;
                }
            },
            'supplier_name': {
                skipForm: !!supplierService,
                controlClassName: 'full-height',
                typeOverride: 'autoComplete',
                forceRequired: true,
                disabled: !!supplierService?.contractor.supplier_ref,
                freeSolo: true,
                getOptionLabel: getSupplierNameOptionLabel,
                getOptionValue: getSupplierNameOptionValue,
                fetchAutoCompleteOptions: canChange ? fetchSupplierNameOptions : undefined,
                onBlur: (values, onChangeFormValues) => {
                    const supplier_name = values["supplier_name"];
                    breakUpdateCycle.current = false;
                    supplier_name ? fillSupplierDetailsByName(supplier_name as string, onChangeFormValues, breakUpdateCycle) : onChangeFormValues({ 'supplier_name': supplier_name });
                },
                onChangeOption: (option, onChangeFormValues, reason) => {
                    const supplier_name = getSupplierNameOptionValue(option);
                    if (currentFocus) {
                        // as we may lose focus on filling supplier name we want to jump to the next field
                        currentFocus.current = "equipment_name"
                    }
                    supplier_name ? fillSupplierDetailsByName(supplier_name as string, onChangeFormValues, breakUpdateCycle) : onChangeFormValues({ 'supplier_name': supplier_name });
                },
            },
            'order_number': {
                sideEffect: (values, fieldConfigs, onChangeFormValues) => {
                    let changed = false;
                    const order_number = values["order_number"];
                    const order_number_has_value = order_number && typeof order_number == "string" && order_number.trim().length > 0;

                    if (order_number_has_value) {
                        if (!fieldConfigs['contract_start_date'].disabled) {
                            fieldConfigs['contract_start_date'].disabled = true;
                            changed = true;
                        }
                        if (!fieldConfigs['contract_end_date'].disabled) {
                            fieldConfigs['contract_end_date'].disabled = true;
                            changed = true;
                        }
                    }
                    if (!order_number_has_value) {
                        if (fieldConfigs['contract_start_date'].disabled) {
                            fieldConfigs['contract_start_date'].disabled = false;
                            changed = true;
                        }
                        if (fieldConfigs['contract_end_date'].disabled) {
                            fieldConfigs['contract_end_date'].disabled = false;
                            changed = true;
                        }
                    }
                    return changed;
                }
            },
            'contact': {},
            'agreed_emergency_response_time': {},
            'supplier_telephone_number': {},
            'emergency_call_out_contact': {},
            'service_details': {
                multiline: true,
                rows: 5
            },
            'notes': {
                multiline: true,
                rows: 5
            },
            'supplier_address': {
                multiline: true,
                rows: 5,
                disabled: !!supplierService?.contractor.supplier_ref
            },
            'contract_start_date': {
                forceRequired: true,
            },
            'contract_end_date': {
                forceRequired: true,
                // NB it is very important that we evaluate the value of the contract end date in the current form before looking at the value attached to the 
                // the supplierContract.  
                // i.e. currentValuesRef?.current['contract_end_date'] as string || supplierContract?.contract_end_date
                // This is because otherwise the control class name would be reset to the value dictated by the supplierContract value on each render
                // and if the value in the form has been changed such that the classname should be different, the result would be an infinite loop.
                controlClassName: internalMember && (moment(currentValuesRef?.current['contract_end_date'] as string || supplierContract?.contract_end_date).toDate() < today) ? outOfContractString : '',
                sideEffect: (values, fieldConfigs, onChangeFormValues) => {
                    let changed = false;
                    const thisFieldConfig = fieldConfigs['contract_end_date']
                    const currentControlClassName = thisFieldConfig.controlClassName;
                    let currentControlClassNameIncludesOutOfContract = currentControlClassName && currentControlClassName.includes(outOfContractString);
                    const currentDateString = currentValuesRef?.current['contract_end_date'] as string;
                    if (currentDateString && internalMember) {
                        let currentDateValue = moment(currentDateString).toDate();
                        if (currentDateValue < today && (!currentControlClassName || !currentControlClassNameIncludesOutOfContract)) {
                            thisFieldConfig.controlClassName = outOfContractString + (currentControlClassName || '');
                            changed = true;
                        } else if (currentDateValue >= today && currentControlClassNameIncludesOutOfContract) {
                            thisFieldConfig.controlClassName = currentControlClassName?.replace(outOfContractString, '').trim();
                            changed = true;
                        }
                    }
                    // no need to call onChangeFormValues, as we 
                    return changed;
                }
            },
            'number_of_visits': {},
            // "site_name": getattr(row, "SITE_NAME"),
            // "po_description": getattr(row, "PO_DESCRIPTION"),
        }
    }, [internalMember, amSuperUser, contract?.id, supplierService])

    const resetForms = () => {
        importFormValuesRef.current = {};
        setShowImportableData(false);
        setShowPOSuggestions(false);
        setFormWidth("100%");
        forceUpdate();
    }

    const formValuesRef = useRef<FormValues>({});

    const handleSave = (): void => {
        let payload = newGetPayloadFromRef({ ref: formValuesRef, metaData: metaForChange })
        if (supplierContract && supplierService) {
            updateSupplierContract({
                contractRef: supplierService.contract__contract_ref,
                portfolioId: portfolio?.id,
                supplierContractId: supplierContract.id,
                payload
            }).then((response) => {
                setSupplierContract(response.data);
                populateSupplierContractsResponse();
                //forceUpdate();
                refreshGrid && refreshGrid();
            })
        } else {
            // a contract is selected before supplier contract creation when in a context without a 'natural' contract
            // such as a 'portfolio' context
            if (supplierService) {
                payload = {
                    ...payload,
                    supplier_service: supplierService.id
                }
            }
            contract && createSupplierContract({
                contractRef: contract.contract_ref,
                portfolioId: portfolio?.id,
                payload
            }).then((scResponse) => {
                // NB WE HAVE TO ALSO SET THE SUPPLIER SERVICE HERE AS IT IS CREATED AT THE SAME TIME
                if (!supplierService) {
                    const supplier_service_route = `contracts/${contract.contract_ref}/supplier-services/${scResponse.data.supplier_service}/`;
                    API.get(supplier_service_route).then((ssResponse: any) => {
                        const parsed_response = getDataAndMetaAndPUTMeta(ssResponse);
                        setSupplierContract(scResponse.data);
                        setSupplierService(parsed_response.data);
                    });
                } else {
                    populateSupplierContractsResponse();
                }
                refreshGrid && refreshGrid();
            })
        }
        //forceUpdate();
        resetForms();
    };

    const handleDelete = (): void => {
        //adapt for portfolio
        supplierService && supplierContract && deleteSupplierContract({
            contractRef: supplierService.contract__contract_ref,
            portfolioId: portfolio?.id,
            supplierContractId: supplierContract.id
        }).then((response) => {
            populateSupplierContractsResponse();
        })
        refreshGrid && refreshGrid();
        resetForms();
    };

    const handleOverWriteAllFields = (): void => {
        onChangeFormValues(importFormValuesRef.current);
        //resetForms();
    }

    const checkIfDifferent = useCallback((a: any, b: any, fieldType?: string) => {
        let incomingValue = a;
        let existingValue = b;
        if (fieldType === "date") {
            if (incomingValue) {
                incomingValue = moment(incomingValue as string).format(dateUDF)
            }
            if (existingValue) {
                existingValue = moment(existingValue as string).format(dateUDF)
            }
        }
        const different = incomingValue !== existingValue && incomingValue?.toString() !== existingValue?.toString();
        return different;
    }, []);

    interface willOverWriteArgs {
        field: string;
        dependency?: boolean;
        allBlanks?: boolean;
    }

    const willOverwriteField = useCallback(({ field, dependency, allBlanks }: willOverWriteArgs) => {
        const blankIfList = ["undefined", "null"];
        let calc = false;
        let existingValue = formValuesRef.current[field];
        const typeOfExistingValue = typeof existingValue;
        const fieldType = metaForChange ? metaForChange[field]?.type : undefined;
        //@ts-ignore
        const existingEmptyString = typeOfExistingValue === "string" && existingValue?.trim().length == 0;
        let incomingValue = importFormValuesRef.current[field];
        const different = checkIfDifferent(incomingValue, existingValue, fieldType);
        if (!allBlanks || dependency) {
            // we only want to know if the fields are different, not if they're blank
            calc = different;
        } else {
            calc = !!incomingValue && different && (
                existingValue === null ||
                blankIfList.includes(typeOfExistingValue) ||
                existingEmptyString
            );
        }
        return calc;
    }, [metaForChange, checkIfDifferent]);

    const calculateIfBlankFieldsWouldBePopulated = useCallback(() => {
        let fields: string[] = [];
        if (importFormValuesRef.current) {
            fields = Object.keys(importFormValuesRef?.current).filter((x) => willOverwriteField({ field: x, allBlanks: true }));
        }
        return !!fields.length;
    }, [willOverwriteField]);

    const handleSetUpImportForm = (data: FormValues) => {
        const newValues: FormValues = {};
        Object.keys(fieldConfigs).map((field) => {
            if (data.hasOwnProperty(field)) { //need to use hasOwnProperty here because we want to distinguish from the value being undefined
                newValues[field] = data[field as keyof SupplierContract];
            }
        })
        importFormValuesRef.current = newValues;
        const importFieldConfigOverrides: FieldsFormConfig = {
            "supplier_ref": {
                "typeOverride": undefined // all the fields are disabled on the import, but overriding this one ensures we don't get the drop down arrow for an autocomplete
            },
        }
        const newImportFieldConfigs = getFieldFormConfig(supplierContract, importFormValuesRef);
        Object.keys(newImportFieldConfigs).map(x => {
            let overRide = importFieldConfigOverrides[x] || {};
            newImportFieldConfigs[x] = {
                ...newImportFieldConfigs[x],
                disabled: true,
                ...overRide,
                style: {
                    ...newImportFieldConfigs[x].style,
                    visibility: newImportFieldConfigs[x].hideOnImportForm ? 'hidden' : 'visible'
                }
            }
        })
        setImportFieldConfigs(newImportFieldConfigs);
        setFormWidth("49%");
        setShowImportableData(true);
        setShowPOSuggestions(false);
        forceUpdateImportForm();
        preScreenImportData();
    }

    const preScreenImportData = () => {
        if (formValuesRef.current.supplier_ref && formValuesRef.current.supplier_ref !== importFormValuesRef.current.supplier_ref) {
            // store.dispatch(addNotification({
            //     message: "The supplier refs do not match!",
            //     type: NOTIFICATION_WARNING
            // }))
            setShowImportSuppierRefMismatchAlert(true);
            setImportsDisabled(true);
        } else {
            setImportsDisabled(false);
        }
    }

    const handleSupplierContractLookup = (): void => {

        const setUpAfterSupplierContractLookup = (response: any) => {
            const { order_number, contractor, ...currentEnteredValues } = formValuesRef.current;
            //console.log('Object.keys(currentEnteredValues): ', Object.keys(currentEnteredValues));
            if (!Object.keys(currentEnteredValues).length) {
                // Before we used to just import on lookup if the form was currently blank, but for now, for consistency, we'll populate the RH form
                /*  onChangeFormValues(response);
                    setShowPOSuggestions(false); // just in case we've done a lookup while having PO suggestions open
                    forceUpdate();
                */
                handleSetUpImportForm(response);
            } else {
                if (Object.keys(response).length) {
                    handleSetUpImportForm(response)
                } else {
                    store.dispatch(addNotification({
                        message: "No relevant results were found",
                        type: NOTIFICATION_WARNING
                    }))
                }
            }
        }
        // adapt for portfolio
        (contract || supplierContract) && POlookUpCurrentValue.current && lookUpSupplierContractFocalPointInfo({
            contractRef: thisContractRef,
            portfolioId: portfolio?.id,
            po_number: POlookUpCurrentValue.current
        }).then((response) => {
            if (baseContract && !response.id) { // this means it's a tenant contract and we didn't find the PO on this tenant contract 
                // - the spec for this feature was that if a specific PO is looked up on the 
                // tenant contract (and the user can read the base), it will automatically search the PO on the base too.
                POlookUpCurrentValue.current && lookUpSupplierContractFocalPointInfo({
                    contractRef: baseContract.contract_ref,
                    portfolioId: portfolio?.id,
                    po_number: POlookUpCurrentValue.current
                }).then((response) => {
                    setUpAfterSupplierContractLookup(response);
                })
            } else {
                setUpAfterSupplierContractLookup(response);
            }
        })


    }

    const handleSupplierSuggestionsLookup = useCallback((e: any, refresh?: boolean) => {
        const setSuggestions = (suggestions: any) => {
            setShowImportableData(false);
            setShowPOSuggestions(true);
            setPOSuggestions(suggestions);
        }
        thisContractRef && lookUpSupplierContractSuggestions({
            contractRef: thisContractRef,
            portfolioId: portfolio?.id,
            supplier_ref: supplierService?.contractor.supplier_ref || formValuesRef.current?.supplier_ref,
            refresh: refresh
        }
        ).then((response) => {
            if (includeBaseContractInLookups.current && baseContract) {
                let newSuggestions = response.data;
                lookUpSupplierContractSuggestions({
                    contractRef: baseContract.contract_ref,
                    portfolioId: portfolio?.id,
                    supplier_ref: formValuesRef.current?.supplier_ref,
                    refresh: refresh
                }).then((response) => {
                    newSuggestions = [...response.data, ...newSuggestions]
                    setSuggestions(newSuggestions);
                })
            } else {
                setSuggestions(response.data);
            }
        })
    }, [thisContractRef, portfolio?.id, includeBaseContractInLookups, baseContract, supplierService])

    const poSuggestionsToolbarPrep = useCallback((e: any) => {
        baseContract && e.toolbarOptions.items.unshift({
            location: "before",
            widget: "dxCheckBox",
            options: {
                onValueChanged: (e: any) => {
                    if (e.previousValue !== e.value) {
                        includeBaseContractInLookups.current = e.value;
                        // if we change the value we may as well adjust the results at once, otherwise it might be confusing
                        handleSupplierSuggestionsLookup(null);
                    }
                },
                text: "Include Base Contract POs"
            },
        });
        e.toolbarOptions.items.unshift({
            location: "before",
            widget: "dxButton",
            options: {
                icon: "refresh",
                onClick: (e: any) => { handleSupplierSuggestionsLookup(e, true) },
            },
        });

    }, [handleSupplierSuggestionsLookup, baseContract]);

    const prepareSuggestionsRow = useCallback((e: any) => {

        if (e.rowType === "data") {
            if (supplierContract) {
                // we want to highlight the existing matching contract
                const matchingSupplierContract = supplierContract.order_number === e.data.order_number;
                if (matchingSupplierContract) {
                    e.rowElement[0].style.fontWeight = '900';
                }
            } else {
                const matchingSupplierContracts = supplierContracts?.filter(x => x.order_number === e.data.order_number);
                if (matchingSupplierContracts?.length) {
                    e.rowElement[0].style.opacity = '0.5';
                }
            }

        }
    }, [supplierContracts, supplierContract]);

    const runSideEffects = useCallback((currentFormValues: FormValues, fieldConfigs: FieldsFormConfig, onChangeFormValues: (newValues: FormValues) => void) => {
        let sideEffectsRan = [];
        let sideEffectsToRun = Object.keys(fieldConfigs).filter(x => fieldConfigs[x].sideEffect !== undefined);
        for (let index in sideEffectsToRun) {
            const field = sideEffectsToRun[index];
            // sideEffect should only return a value if something significant changed that requires a re-render
            //@ts-ignore // we're iterating fields with side effects but TS doesn't realise this
            const sideEffectRan = fieldConfigs[field].sideEffect(currentFormValues, fieldConfigs, onChangeFormValues);
            if (sideEffectRan) {
                sideEffectsRan.push(field)
            }
        }
        if (sideEffectsRan.length > 0) {
            return true;
        }
        return false;
    }, []);

    const customAfterValuesSet = useCallback(() => {
        if (showImportableData) {
            setBlankFieldsWillBePopulated(calculateIfBlankFieldsWouldBePopulated());

            // now we're going to check if the number of matching fields is the same as it was. 
            // If it changes, we re-render so the fields can be highlighted as appropriate
            let numberOfDifferentFields = 0;
            Object.keys(formValuesRef.current).map((field) => {
                const fieldType = metaForChange ? metaForChange[field]?.type : undefined;
                const different = checkIfDifferent(formValuesRef.current[field], importFormValuesRef.current[field], fieldType);
                if (different) {
                    numberOfDifferentFields += 1;
                }
            })
            if (numberOfDifferentFields !== differingFieldsCountRef.current) {
                differingFieldsCountRef.current = numberOfDifferentFields;
                forceUpdate && forceUpdate();
            }
        }

    }, [checkIfDifferent, metaForChange, showImportableData, calculateIfBlankFieldsWouldBePopulated]);

    const onChangeFormValues = useCallback((newValues: FormValues, ensureForceUpdate?: boolean) => {
        if (newValues.contract_start_date) {
            // it's necessary to remove the time component returned from FP (always 00 though)
            //@ts-ignore
            newValues.contract_start_date = moment(newValues.contract_start_date).format(dateUDF)
        }
        if (newValues.contract_end_date) {
            // it's necessary to remove the time component returned from FP (always 00 though)
            //@ts-ignore
            newValues.contract_end_date = moment(newValues.contract_end_date).format(dateUDF)
        }
        const updatedFormValues = {
            ...formValuesRef.current,
            ...newValues,
            //note we don't need to manually inject the contractor id.  For update it is not writable, for create, it doesn't exist yet.
        }

        const injectValues = (updatedValues: FormValues): FormValues => {
            return {
                ...updatedValues,
                'contract_id': contract?.id
            }
        }

        const processedValues = injectValues(updatedFormValues);

        formValuesRef.current = processedValues;
        const missingValues = Object.keys(metaForChange).filter(
            (k) => {
                const meta = metaForChange[k];
                const config = fieldConfigs[k];
                const fV = formValuesRef.current[k];
                let missing = formValueConsideredMissing({
                    meta,
                    config,
                    formValue: fV
                })
                return missing;
            }
        );

        const processedMissing = missingValues.filter((x) => {
            const substitute: string | undefined = acceptableValueSubstitutes[x]
            return !formValuesRef.current[substitute];
        })

        customAfterValuesSet();

        // breakCycleUpdate shouldn't be required if ensureForceUpdate is used correctly, that is, if it is only set in scenarios where 
        // it will not be set on the next cycle (e.g. on blur of a given field). However, breakUpdateCycle is just another parameter to 
        // ensure that the user knows they are intentionally removing a safeguard temporarily.  One should only ever pass ensureForceUpdate
        // and set breakUpdateCycle to false, in a context that will not be repeated in a loop.
        const toForceUpdate = runSideEffects(processedValues, fieldConfigs, onChangeFormValues) || (ensureForceUpdate && breakUpdateCycle.current == false)
        toForceUpdate && forceUpdate();
        breakUpdateCycle.current = true;
        const checkIsReady = processedMissing.length == 0 && canChange // NB ADD FORM CHANGED CHECK HERE!!!!!!!!!!!!!!!!!
        // console.log('missing values: ', missingValues);
        // console.log('missing values: ', processedMissing);
        // console.log('current values: ', formValuesRef.current);
        setReadyToCreate(checkIsReady);

    }, [canChange, customAfterValuesSet, metaForChange, contract?.id, fieldConfigs, runSideEffects]);

    interface SetUpFormFromSupplierContractInterface {
        thisSupplierContract?: SupplierContract;
        existingValues?: FormValues;
        logValues?: boolean;
        canChange?: boolean;
    }
    const setupFormFromSupplierContract = useCallback(({ thisSupplierContract, existingValues, logValues, canChange }: SetUpFormFromSupplierContractInterface) => {
        const formConfig = getFieldFormConfig(thisSupplierContract, formValuesRef, currentFocus, canChange);
        let oldValues: any = existingValues || {};
        const newValues: any = { ...oldValues };
        if (thisSupplierContract) {
            Object.keys(formConfig).map((field) => {
                if (thisSupplierContract.hasOwnProperty(field)) { //need to use hasOwnProperty here because we want to distinguish from the value being undefined
                    newValues[field] = thisSupplierContract[field as keyof SupplierContract];
                }
            })
        }
        newValues['supplier_name'] = supplierService?.contractor.name
        newValues['supplier_ref'] = supplierService?.contractor.supplier_ref
        formValuesRef.current = newValues;
        // runSideEffects adjusts the form, it's not only for triggering re-renders.
        //runSideEffects(formValuesRef.current, formConfig, onChangeFormValues);
        setFieldConfigs(formConfig);
        forceUpdate();
    }, [getFieldFormConfig, supplierService]);

    interface populateFieldsArgs {
        fieldsList?: string[];
        allBlanks?: boolean;
    }

    const handlePopulateFields = useCallback(({ fieldsList, allBlanks }: populateFieldsArgs): void => {
        const newValues: FormValues = {};
        const fieldsWarningMap: { [index: string]: string[] } = {};
        const additionalMsg = allBlanks ? " even if they aren't blank" : "";

        let fieldsToPopulate = fieldsList || Object.keys(importFormValuesRef.current);

        importFormValuesRef && fieldsToPopulate.map((x) => {
            let overWrite = willOverwriteField({ field: x, allBlanks });
            if (overWrite) {
                const dependencies = fieldDependencies[x];
                if (dependencies?.length) {
                    dependencies.map((d) => {
                        const overWriteDependency = willOverwriteField({ field: d, dependency: true })
                        // the idea here is that there is no point warning if the dependency
                        // would have been ovewritten anyway AS A BLANK or the incoming is blank, 
                        // because that would be assumed if we're trying to populate blanks, 
                        // so in that case we'll re-evaluate the field as it were not a dependency.  
                        // otherwise we want to know if it would be overwritten at all, and the previous call to  willOverwriteField
                        // will only return true if the incoming and existing values are evaluated as different.                     
                        const warn = allBlanks ? !willOverwriteField({ field: d, dependency: false, allBlanks }) : true;
                        if (overWriteDependency && warn) {
                            const xLabel = fieldConfigs[x].label || metaForChange[x].label;
                            fieldsWarningMap[xLabel] = fieldsWarningMap[xLabel] || [];
                            warn && fieldsWarningMap[xLabel].push(fieldConfigs[d].label || metaForChange[d].label);
                        }
                    })
                }
                newValues[x] = importFormValuesRef.current[x];
                dependencies?.map((d) => {
                    newValues[d] = importFormValuesRef.current[d];
                })
            }
        })
        const warningKeys = Object.keys(fieldsWarningMap);
        if (warningKeys.length) {
            let message = `The following 'dependency' fields will also be overwritten${additionalMsg}: `;
            warningKeys.map((fieldLabel, index) => {
                message = message + `${fieldsWarningMap[fieldLabel].join(", ")} (as dependency of ${fieldLabel}. )`
            })
            stashedImportValuesToApply.current = newValues;
            setImportAlertMessage(message);
            setShowImportBlankFieldsOverrideAlert(true);
        } else {
            onChangeFormValues(newValues);
            if (!fieldsList?.length && !allBlanks) {
                //presumably it's an overwrite and we're done here
                //resetForms();
            } else {
                forceUpdate();
            }
        }
    }, [fieldConfigs, metaForChange, onChangeFormValues, willOverwriteField]);

    const handlePopulateBlankFields = (e: any) => {
        handlePopulateFields({ allBlanks: true })
    }

    const overRideImportWarning = (e: any) => {
        const valuesToApply = { ...stashedImportValuesToApply.current };
        stashedImportValuesToApply.current = undefined;
        valuesToApply && onChangeFormValues(valuesToApply);
        //resetForms();
        forceUpdate();
        setShowImportBlankFieldsOverrideAlert(false);
    }

    const handleUseImportValue = useCallback((field: string): void => {
        //@ts-ignore  we shouldn't make the button available if order number hasn't been entered
        // formValuesRef.current[field] = importFormValuesRef.current[field];
        // forceUpdate();
        const fieldArray = [field];
        handlePopulateFields({ fieldsList: fieldArray })
    }, [handlePopulateFields]);

    const getHandleUseImportValue = useCallback((field) => () => {
        handleUseImportValue(field)
        //handlePopulateBlankFields
    }, [handleUseImportValue]);

    const highLightIfValueDiffers = (fieldConfigs: FieldsFormConfig, dataField: string) => {
        const fieldConfig = fieldConfigs[dataField];
        let valueDiffers = (
            showImportableData &&
            !fieldConfigs[dataField].hideOnImportForm &&
            importFormValuesRef.current[dataField]?.toString() !== formValuesRef.current[dataField]?.toString()
        );
        const currentValue = formValuesRef.current[dataField];
        const importValue = importFormValuesRef.current[dataField];
        const currentMeta = metaForChange[dataField];
        if (currentMeta?.type === 'date' && valueDiffers) {
            valueDiffers = moment(currentValue?.toString()).format(dateUDF) !== moment(importValue?.toString()).format(dateUDF)
        }
        //moment(newValues.contract_start_date).format(dateUDF)
        let controlClassName = fieldConfig?.controlClassName || '';
        if (!importFormValuesRef.current.hasOwnProperty(dataField) || !valueDiffers) {
            fieldConfig.controlClassName = fieldConfig?.controlClassName?.replace('valueDiffers', '');
        } else {
            if (valueDiffers && (!controlClassName || !controlClassName.includes('valueDiffers'))) {
                fieldConfig.controlClassName = 'valueDiffers ' + (fieldConfig.controlClassName || '');
            }
        }
    }

    // useEffect(() => {
    //     // run this once only on load, 

    //     //just to update the supplier contract in case something has changed
    //     supplierContract && simpleFetchSupplierContract(contract.contract_ref, supplierContract.id).then((response) => {
    //         if (response.data && !isEqual(formValuesRef.current, response.data)) {
    //             console.log('not equal!');
    //             setSupplierContract(response.data);
    //         }
    //     })
    // }, [])

    const getModalTitle = useCallback(() => {
        return () => <>
            {supplierService ? <div className="detail-modal--title">
                Contract Details
                {getDimTitle('for')}
                {supplierService.contractor.name}
                {getDimTitle('on')}
                {supplierService.equipment_name}
            </div> :
                <div className="detail-modal--title">
                    New Contract
                </div>}
        </>
    }, [supplierService]);

    useEffect(() => {
        // we need to know three things 1) Is this a tenant contract 2) Is there a base contract 3) Can this user edit the base contract
        if (contract && contract.contract_category !== "base") {
            simpleFetchBaseFromTenant(contract.contract_ref).then((data) => {
                if (data) {
                    // if we only care if they can read the base contract we're done and all we have to do is set the base contract here
                    // however if we want to know if they could create supplier contracts on the base...
                    fetchSupplierContractCreatePreFlightInfo({
                        contractRef: data.contract_ref,
                        portfolioId: portfolio?.id
                    }).then((response) => {
                        const { canCreate } = response;
                        if (canCreate) {
                            setBaseContract(data);
                        }
                    })
                }
            })
        }
    }, [contract, portfolio?.id])

    useEffect(() => {
        setModalTitle(getModalTitle)
    }, [supplierContract, contract?.contract_ref, getModalTitle, setModalTitle])

    useEffect(() => {
        setupFormFromSupplierContract({ thisSupplierContract: supplierContract, existingValues: formValuesRef.current, canChange })
    }, [supplierContract, canChange, setupFormFromSupplierContract])

    useEffect(() => {
        setBlankFieldsWillBePopulated(calculateIfBlankFieldsWouldBePopulated());
        forceUpdate(); // This is so that the form will re-render and we can compare fields for styling changes, when a new import form appears
    }, [showImportableData])

    useEffect(() => {
        if (wipe) {
            formValuesRef.current = {};
        }
        forceUpdate();
    }, [wipe])

    const hasMeta = () => metaForChange && !!Object.keys(metaForChange).length;

    const POLookUpField = useRef();
    const POlookUpFocus = useRef<string>();
    const POlookUpCurrentValue = useRef<string>();
    const [POlookUpHasValue, setLookupHasValue] = useState(!!POlookUpCurrentValue.current);
    const POlookupAdornment = createLookupEndAdornment(handleSupplierContractLookup, !POlookUpHasValue);

    return <div className={`supplierServiceInfo ${showTransferForm ? 'transferring' : ''}`}>
        {supplierService && contract &&
            <>
                <SupplierServiceForm
                    supplierService={supplierService}
                    contractRef={contract?.contract_ref}
                    refreshGrid={refreshGrid}
                    setSupplierService={setSupplierService}
                    breakUpdateCycle={breakUpdateCycle}
                    populateSupplierContractsResponse={populateSupplierContractsResponse}
                    showTransferForm={showTransferForm}
                    setShowTransferForm={setShowTransferForm}
                    setOpen={setOpen}
                />
            </>
        }
        <Paper className='supplierContractFormsWrapper' elevation={3}>

            {hasMeta() && (<div className="supplierContractDetailsOuterWrapper">
                <SupplierServiceDetailsFormWrapper theme={{ width: "100%" }} className="supplierContractDetails controls">
                    {supplierContract && // we check for supplier contract as otherwise we will be in 'create' mode
                        <div className="navigateSupplierContractButtonWrapper previous">
                            <IconButton
                                aria-label="create new supplier contract"
                                className="previousSupplierContractButton"
                                onClick={selectPreviousSupplierContract}
                                disabled={currentSupplierContractIndex <= 0}
                                edge="end"
                                style={{
                                    visibility: 'visible',
                                    float: "right"
                                }}
                            >
                                <NavigateBeforeIcon />
                            </IconButton>
                        </div>
                    }
                    <div id="SupplierContractTitleWrapper" className={`${supplierContract ? 'current' : 'new'}`}>
                        {supplierContract ?
                            <>
                                <span id="SupplierContractTitle" className='upper'>
                                    {`Supplier Contract ${currentSupplierContractIndex + 1} of ${supplierContractsLength}`}
                                </span>
                                <span>
                                    {`${supplierContract.number_of_visits} visits contracted from ${moment(supplierContract.contract_start_date).format('DD/MMM/YYYY')} to ${moment(supplierContract.contract_end_date).format('DD/MMM/YYYY')}`}
                                </span>
                            </> : <>
                                <span id="SupplierContractTitle">
                                    {`New Supplier Contract`}
                                </span> <br />
                                {!!supplierContractsLength && <span id="currentContractsInfo">
                                    {`Existing contracts: ${supplierContractsInfo}`}
                                </span>}
                            </>
                        }
                    </div>

                    {supplierContract && // we check for supplier contract as otherwise we will be in 'create' mode
                        <div className="navigateSupplierContractButtonWrapper next">
                            <IconButton
                                aria-label="create new supplier contract"
                                className="nextSupplierContractButton"
                                onClick={selectNextSupplierContract}
                                disabled={currentSupplierContractIndex >= supplierContractsLength - 1}
                                edge="end"
                                style={{
                                    visibility: 'visible',
                                    float: "right"
                                }}
                            >
                                <NavigateNextIcon />
                            </IconButton>
                        </div>
                    }
                    <div id="addNewSupplierContractButtonWrapper">
                        {supplierContract && <IconButton
                            aria-label="create new supplier contract"
                            className="addNewSupplierContractButton"
                            onClick={putInContractCreateMode}
                            edge="end"
                            style={{
                                visibility: 'visible',
                                float: "right"
                            }}
                        >
                            <AddIcon />
                        </IconButton>}
                        {!supplierContract && !!supplierContracts?.length && <IconButton
                            aria-label="create new supplier contract"
                            className="addNewSupplierContractButton"
                            onClick={cancelContractCreateMode}
                            edge="end"
                            style={{
                                visibility: 'visible',
                                float: "right"
                            }}
                        >
                            <CloseIcon />
                        </IconButton>}
                    </div>
                </SupplierServiceDetailsFormWrapper>

            </div>)}
            {hasMeta() && canChange && (
                <div className="supplierContractDetailsOuterWrapper">
                    <SupplierServiceDetailsFormWrapper theme={{ width: "100%" }} className="supplierContractDetails">
                        <div id="poLookup">
                            <TextField
                                label="PO lookup"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={
                                    {
                                        endAdornment: POlookupAdornment
                                    }
                                }
                                inputRef={POLookUpField}
                                autoFocus={POlookUpFocus.current == "po_lookup"}
                                onFocus={() => { POlookUpFocus.current = "po_lookup" }}
                                variant="standard"
                                defaultValue={POlookUpCurrentValue.current}
                                placeholder="Lookup contractor details by PO"
                                onChange={(event: any) => {
                                    const { value } = event.target;
                                    if (!POlookUpHasValue && value) {
                                        setLookupHasValue(true);
                                    }
                                    if (POlookUpHasValue && !value) {
                                        setLookupHasValue(false);
                                    }
                                    POlookUpCurrentValue.current = value;
                                }}>
                            </TextField>
                        </div>
                        <div className="supplierContractDetailsSuggestionsButtonWrapper">
                            <Button onClick={handleSupplierSuggestionsLookup}
                                className="supplierContractDetailsSuggestionsLookup"
                                color="secondary"
                                size="small"
                                type="submit"
                                variant="contained"
                                disabled={false}
                                style={
                                    {
                                        float: "right",
                                        //marginTop: "1rem"
                                    }
                                }
                            >
                                Get PO Suggestions
                            </Button>
                        </div>
                        <div id="closeImportInfoButtonWrapper">
                            <IconButton
                                aria-label="toggle password visibility"
                                className="closeImportInfoButton"
                                onClick={resetForms}
                                edge="end"
                                style={{
                                    visibility: showImportableData || showPOSuggestions ? 'visible' : 'hidden',
                                    float: "right"
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </SupplierServiceDetailsFormWrapper>
                </div>
            )}

            <div className="supplierContractDetailsOuterWrapper">

                {!showPOSuggestions && mustRefresh && <SupplierServiceDetailsFormWrapper theme={{ width: formWidth }} className={`supplierContractDetails ${supplierService ? 'supplierServiceExists' : 'supplierServiceDoesNotExist'}`}>

                    {hasMeta() && (
                        mustRefresh && Object.keys(fieldConfigs).map((dataField) => {
                            const fieldConfig = fieldConfigs[dataField];

                            highLightIfValueDiffers(fieldConfigs, dataField);

                            return <AutoInput
                                zIndex={1200}
                                key={dataField}
                                dataField={dataField}
                                fieldConfig={fieldConfig}
                                fieldMeta={metaForChange[dataField]}
                                formValuesRef={formValuesRef}
                                refreshSignal={mustRefresh}
                                onChangeFormValues={onChangeFormValues}
                                currentFocus={currentFocus}
                                showReadOnly
                                defaultRefreshFormOnBlur
                                dispatchRefreshContext={forceUpdate}
                            />
                        })

                    )}

                    {hasMeta() && canChange &&
                        (<div id="supplierContractDetailsDeleteButtonWrapper" className={showImportableData ? "half-screen" : "full-screen"}>
                            <Button onClick={handleDelete}
                                id="supplierContractDetailsDeleteButton"

                                color="secondary"
                                type="submit"
                                variant="contained"
                                disabled={!supplierContract || !canChange}
                                style={
                                    {
                                        float: "right",
                                        marginTop: "1rem"
                                    }
                                }>
                                Delete Supplier Contract
                            </Button>
                        </div>)
                    }

                    {hasMeta() && canChange &&
                        (<div id="supplierContractDetailsUpdateButtonWrapper" className={showImportableData ? "half-screen" : "full-screen"}>
                            <Button onClick={handleSave}
                                id="supplierContractDetailsUpdateButton"
                                color="secondary"
                                type="submit"
                                variant="contained"
                                disabled={!readyToCreate}
                                style={
                                    {
                                        float: "right",
                                        marginTop: "1rem"
                                    }
                                }>
                                Save Supplier Contract Details
                            </Button>
                        </div>)}
                    {/* <Button onClick={handleClose}>
                        Close
                    </Button> */}
                </SupplierServiceDetailsFormWrapper>
                }
                {showImportableData && !showPOSuggestions && hasMeta() && canChange && <SupplierServiceDetailsFormWrapper theme={{ width: formWidth }} className={`supplierContractDetails ${supplierService ? 'supplierServiceExists' : 'supplierServiceDoesNotExist'}`}>

                    {/* <AutoInput
                    zIndex={1200}
                    key="order_number"
                    dataField="order_number"
                    //@ts-ignore
                    fieldConfig={orderNumberHiddenConfig}
                    fieldMeta={metaForChange["order_number"]}
                    formValuesRef={importFormValuesRef}
                    refreshSignal={mustRefreshImportForm}
                    onChangeFormValues={onChangeFormValues}
                    currentFocus={currentFocus}
                    defaultRefreshFormOnBlur
                    dispatchRefreshContext={forceUpdate}
                /> */}
                    {
                        mustRefresh && Object.keys(importFieldConfigs).map((dataField) => {
                            const fieldConfig = importFieldConfigs[dataField];
                            highLightIfValueDiffers(importFieldConfigs, dataField);
                            const useFieldValueAdornment = createUseValueEndAdornment(getHandleUseImportValue(dataField));

                            return <AutoInput
                                zIndex={1200}
                                key={dataField}
                                dataField={dataField}
                                fieldConfig={fieldConfig}
                                fieldMeta={metaForChange[dataField]}
                                formValuesRef={importFormValuesRef}
                                refreshSignal={mustRefreshImportForm}
                                onChangeFormValues={() => { }}
                                currentFocus={currentFocus}
                                endAdornment={useFieldValueAdornment}
                                defaultRefreshFormOnBlur
                                dispatchRefreshContext={forceUpdate}
                            />
                        })
                    }

                    <div className="supplierContractDetailsSuggestionsButtonWrapper">
                        <Button onClick={undefined}
                            className="supplierContractDetailsSuggestionsLookup"
                            size="small"
                            style={
                                {
                                    visibility: "hidden"
                                }
                            }
                        >
                            Get PO Suggestions
                        </Button>
                    </div>
                    <div id="overWriteAllButtonWrapper">
                        <Button onClick={handleOverWriteAllFields}
                            id="overWriteAllButton"
                            color="secondary"
                            type="submit"
                            variant="contained"
                            disabled={importsDisabled}
                            style={
                                {
                                    float: "right",
                                    marginTop: "1rem"
                                }
                            }>
                            &lt; &nbsp; Overwrite All Existing Fields
                        </Button>
                    </div>
                    {blankFieldsWillBePopulated && <div id="populateBlankFieldsButtonWrapper">
                        <Button onClick={handlePopulateBlankFields}
                            id="populateBlankFieldsButton"
                            color="secondary"
                            type="submit"
                            variant="contained"
                            disabled={importsDisabled}
                            style={
                                {
                                    float: "right",
                                    marginTop: "1rem"
                                }
                            }>
                            &lt; &nbsp;  Populate Any Blank Fields
                        </Button>
                    </div>}
                </SupplierServiceDetailsFormWrapper>
                }
                {!showImportableData && showPOSuggestions && hasMeta() && canChange &&
                    <SupplierContractDetailsPOSuggestionsWrapper theme={{ width: "100%" }} className="supplierContractDetails">
                        <DataGrid
                            dataSource={poSuggestions}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            columnAutoWidth={true}
                            width="100%"
                            //key="temp_id"
                            key="id"
                            onToolbarPreparing={poSuggestionsToolbarPrep}
                            onRowPrepared={prepareSuggestionsRow}
                        >
                            <SearchPanel
                                visible={true}
                                placeholder="Search..."
                                searchVisibleColumnsOnly={true}
                                width={500}
                            />
                            <HeaderFilter visible={true} />
                            <Column dataField="temp_id" visible={false} />
                            <Column dataField="order_number" caption="PO Number" />
                            <Column dataType="date" format="d MMM y" dataField="contract_start_date" caption="Contract Start Date" />
                            <Column dataType="date" format="d MMM y" dataField="contract_end_date" caption="Contract End Date" />
                            <Column dataField="supplier_ref" caption="Supplier Ref" />
                            <Column dataField="supplier_name" caption="Supplier Name" />
                            <Column dataField="po_description" caption="PO Description" />
                            <Column
                                alignment="left"
                                type="buttons"
                                width={140}
                                visible={hasMeta()}
                                buttons={[{
                                    hint: 'Open',
                                    icon: 'fa fa-eye',
                                    onClick: (event: any) => handleSetUpImportForm(event?.row?.data)
                                }]}
                            />
                        </DataGrid>
                    </SupplierContractDetailsPOSuggestionsWrapper>}
            </div>
            {
                showImportBlankFieldsOverrideAlert && <Alert
                    severity="warning"
                    style={{
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        zIndex: 10000
                    }}
                    action={
                        <>
                            <Button color="inherit" size="small" onClick={overRideImportWarning}>
                                PROCEED
                            </Button>
                            <Button color="inherit" size="small" onClick={() => {
                                setImportAlertMessage("");
                                stashedImportValuesToApply.current = undefined;
                                setShowImportBlankFieldsOverrideAlert(false);
                            }}>
                                CANCEL
                            </Button>
                        </>
                    }
                >
                    {importAlertMessage}
                </Alert>
            }
            {
                showImportSuppierRefMismatchAlert && <Alert
                    severity="error"
                    style={{
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        zIndex: 10000
                    }}
                    action={
                        <>
                            <Button color="inherit" size="small" onClick={() => {
                                resetForms()
                                setImportsDisabled(false);
                                setShowImportSuppierRefMismatchAlert(false);
                            }}>
                                OK
                            </Button>
                        </>
                    }
                >
                    This PO cannot be used for import as the supplier ref differs
                </Alert>
            }
        </Paper>

    </div>
}

export default SupplierServiceDetailsForm;