import { createSelector } from "reselect";
import { State } from "store/interfaces/State";
//import { IDsState } from "components/Common/Interfaces/Entity.interface";
import { sitesSelector, sitesSlice } from "components/AdminPanel/Sites/Selectors/Sites.selectors";
import { standardDateFormat } from "components/Common/Utils/Dates";
import { contractPeriodsSelector } from "components/AdminPanel/ContractPeriods/Selectors/ContractPeriods.selectors";
import { portfolioInFocusPeriod, portfolioInFocusSnapshotFocusSelector, portfolioInFocusFrozenForSelector, portfolioInFocusPortfolioMonthlyMaintenanceSelector } from 'components/PortfolioInFocus/Selectors/portfolioInFocus.selectors';
import { createDeepEqualSelector, createShallowEqualSelector } from "helpers/SelectorUtilities/selectorUtilities";
import { ContractIdOrPortfolioId } from "services/API/common/contractAPIs";
import { SiteBranchState } from "components/AdminPanel/Sites/Interfaces/Sites.interface";

export const contractInFocus = (state: State) => state.contractInFocus;

export const contractInFocusCache = (state: State) => state.contractInFocusCache;

export const contractInFocusSelector = createSelector(contractInFocus, (contractInFocus) => contractInFocus);

export const contractInFocusCacheSelector = createSelector(contractInFocusCache, (contractInFocusCache) => contractInFocusCache);

const meterStreamState = (state: State) => state.meterStreams;

const personalContractNotificationsState = (state: State) => state.personalContractNotificationSettings;


export const contractInFocusMeterStreamsSelector = createDeepEqualSelector(
  meterStreamState,
  contractInFocus,
  (meterStreams, contractInFocus) => {
    const meterStreamsForContract = { ...meterStreams[contractInFocus.contract.id] }
    meterStreamsForContract.data && meterStreamsForContract.data.sort((a, b) => a.utility_type.display_name.localeCompare(b.utility_type.display_name) || a.title.localeCompare(b.title));
    return meterStreamsForContract;
  }
)

export const reportReadySelector = createSelector(
  contractInFocusMeterStreamsSelector,
  (meterStreamsForContract) => {
    return !!meterStreamsForContract.meta;
  }
)

export const contractInFocusPersonalNotificationSettings = createSelector(
  personalContractNotificationsState,
  contractInFocus,
  (personalContractNotificationSettingsList, contractInFocus) => {
    const personalContractNotificationSettings = { ...personalContractNotificationSettingsList[contractInFocus.contract.id] }
    return personalContractNotificationSettings;
  }
)

//export const contractInFocusContractSelector = createSelector(
export const contractInFocusContractSelector = createDeepEqualSelector(
  sitesSlice,
  contractInFocus,
  (sites, contractInFocus) => {
    const sL = Object.values(sites.data);
    for (let i = 0; i < sL.length; i++) {
      const c = sL[i].site_contracts ? sL[i].site_contracts.find(c => c.id == contractInFocus.contract.id) : null;
      if (c) {
        return { ...contractInFocus.contract, data: c }
      }
    }
  }
);

const getSiteContractFromSite = (sites: SiteBranchState, contractRef: string) => {
  const sL = Object.values(sites.data);
  for (let i = 0; i < sL.length; i++) {
    const c = sL[i].site_contracts ? sL[i].site_contracts.find(c => c.contract_ref == contractRef) : null;
    if (c) {
      return c
    }
  }
}

export const siteContractByContractRefSelector = (contractRef: string) => createDeepEqualSelector(
  sitesSlice, (sites) => {
    return getSiteContractFromSite(sites, contractRef);
  }
);

export const siteContractByContractRefSelectorOrUndefined = (contractRef?: string) => createDeepEqualSelector(
  sitesSlice, (sites) => {
    // we have to go through this rigamarole because you cannot use a selector conditionally
    if (contractRef) {
      return getSiteContractFromSite(sites, contractRef);
    } else {
      return undefined
    }

  }
);

//export const contractInFocusContractId = createSelector(
export const contractInFocusContractId = createDeepEqualSelector(
  contractInFocus,
  (contractInFocus) => {
    return contractInFocus.contract?.id
  }
);

//export const contractInFocusContractDataSelector = createSelector(
export const contractInFocusContractDataSelector = createDeepEqualSelector(
  contractInFocusContractSelector,
  (contract) => contract?.data
);

export const contractInFocusReactiveCategoryOptionsSelector = createSelector(
  contractInFocus,
  (contractInFocus) => ({
    dictionary: contractInFocus.reactiveCategoryOptions.data,
    list: Object.keys(contractInFocus.reactiveCategoryOptions.data).map(key =>
      contractInFocus.reactiveCategoryOptions.data[key])
  })
);


export const contractInFocusContractMonthlyMaintenanceSelector = createSelector(
  contractInFocus,
  (contractInFocus) => contractInFocus.monthlyMaintenanceReports
);

export const contractInFocusSnapshotHistory = (state: State) => state.contractInFocus.frozenHistory;

//export const contractInFocusSnapshotHistorySelector = createSelector(
export const contractInFocusSnapshotHistorySelector = createDeepEqualSelector(
  contractInFocusSnapshotHistory,
  (snapshotHistory) => {
    return {
      ...snapshotHistory,
      data: snapshotHistory.data,
      focus: snapshotHistory?.data?.[snapshotHistory.focus]
    }
  }
);

export const contractInFocusMostRecentPublicationsSelector = createDeepEqualSelector(
  contractInFocusSnapshotHistorySelector,
  (snapshotHistory) => {
    const snapshotInFocus = snapshotHistory?.focus
    const publications = snapshotInFocus?.publications
    if (publications) {
      const sortedPublications = [...snapshotInFocus.publications]
      sortedPublications.sort(function (a: any, b: any) { return a.publication_number - b.publication_number })
      return sortedPublications[sortedPublications.length - 1]
    }
    return undefined;

  }
);

export const contractInFocusSnapshotFocusSelector = createSelector(
  contractInFocusSnapshotHistory,
  (frozenHistory) => frozenHistory?.data?.[frozenHistory.focus]
);

export const contractInFocusFrozenForSelector = createSelector(
  contractInFocusSnapshotFocusSelector,
  (focus) => {
    return focus ? `- frozen for ${standardDateFormat(focus.frozen_for)}` : ``
  });

export const contractInFocusSite = (state: State) => state.contractInFocus.site;

//export const contractInFocusSiteSelector = createSelector(
export const contractInFocusSiteSelector = createDeepEqualSelector(
  contractInFocusContractSelector,
  sitesSelector,
  (contract, sites) => {
    return {
      //data: contract.site_id ? sites.data[contract.site_id] : undefined,//
      data: contract?.data ? sites.data[contract.data?.site_id] : null,
      meta: sites.meta
    }
  }
);

export const contractActiveSelector = createSelector(
  contractInFocusContractSelector,
  contractInFocusSiteSelector,
  (contract, site) => {
    const active = contract?.data.active !== false && site.data?.active !== false;
    return active;
  }
)

//export const contractInFocusFocusedContractPeriodSelector = createSelector(
export const contractInFocusFocusedContractPeriodSelector = createDeepEqualSelector(
  contractPeriodsSelector, contractInFocusSelector, (contractPeriods, contract) => contractPeriods.data[contract.focusedContractPeriod?.id]
);

export const contractOrPortfolioPeriodSelector = (props: ContractIdOrPortfolioId) => {
  return createSelector(portfolioInFocusPeriod, contractInFocusFocusedContractPeriodSelector, (portfolioPeriodInFocus, contractPeriodInFocus) => {
    if (props.portfolioId) {
      return portfolioPeriodInFocus
    }
    return contractPeriodInFocus
  });
}

export const contractOrPortfolioSnapshotSelector = (props: ContractIdOrPortfolioId) => {
  return createSelector(portfolioInFocusSnapshotFocusSelector, contractInFocusSnapshotFocusSelector, (portfolioSnapshotInFocus, contractSnapshotInFocus) => {
    if (props.portfolioId) {
      return portfolioSnapshotInFocus
    }
    return contractSnapshotInFocus
  });
}

export const contractOrPortfolioFrozenForSelector = (props: ContractIdOrPortfolioId) => {
  return createSelector(portfolioInFocusFrozenForSelector, contractInFocusFrozenForSelector, (portfolioInFocusFrozenFor, contractInFocusFrozenFor) => {
    if (props.portfolioId) {
      return portfolioInFocusFrozenFor
    }
    return contractInFocusFrozenFor
  });
}

export const contractOrPortfolioMonthlyReportsSelector = (props: ContractIdOrPortfolioId) => {
  return createSelector(portfolioInFocusPortfolioMonthlyMaintenanceSelector, contractInFocusContractMonthlyMaintenanceSelector, (portfolioMonthlyReports, contractMonthlyReports) => {
    if (props.portfolioId) {
      return portfolioMonthlyReports
    }
    return contractMonthlyReports
  });
}


export const contractDocumentsViewerSelector = (state: State) => state.contractDocumentsViewer;