import { map } from 'rxjs/operators';

export const groupFetchPipe = (customExtractCB?: Function) => map((response: any[]) => {
  return response.reduce((acc: any, item: any) => {
    const { data: { data, metadata } } = item;
    acc = {
      data: {
        ...acc.data,
        [item.data.data.id]: data
      },
      meta: {
        ...acc.meta,
        [item.data.data.id]: metadata.actions.PUT
      },
      ...(customExtractCB ? customExtractCB(acc, item) : {})
    }
    return acc;
  }, { data: {}, meta: {} })
})