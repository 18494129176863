import React, { ReactNodeArray, ReactNode } from 'react';

import './print.scss';

interface PrintProps {
  breakAfter?: boolean;
  breakBefore?: boolean;
  keepTogether?: boolean;
  children?: ReactNodeArray | ReactNode;
}

export default ({ breakAfter, breakBefore, keepTogether, children }: PrintProps) => {
  return <div
    className={`print-page-wrapper ${breakAfter ? `print-page-wrapper__new-after` : ''} ${breakBefore ? `print-page-wrapper__new-before` : ''} ${keepTogether ? `print-page-wrapper__keep-together` : ''}`}
  >
    {children}
  </div>
}