import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { createRequestActionTypes } from "components/Common/Utils/ReduxUtil"
import { MainInFocusVisibilitySettings } from "components/ContractInFocus/interfaces/ContractInFocus.interfaces"
import {
  FetchMaintenanceReportConfigSuccessAction,
} from "../interfaces/contractInFocusActions.interfaces";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { ContractOrPortfolio } from "services/API/common/contractAPIs";

export const SET_PERSONAL_IN_FOCUS_SETTINGS = createRequestActionTypes('SET_PERSONAL_IN_FOCUS_SETTINGS')
export const SET_SINGLE_PERSONAL_IN_FOCUS_SETTING = createRequestActionTypes('SET_PERSONAL_IN_FOCUS_SETTING')

export const TOGGLE_PERSONAL_IN_FOCUS_SETTINGS = createRequestActionTypes('TOGGLE_PERSONAL_IN_FOCUS_SETTINGS')

export const togglePersonalInFocusSettings = (updateKey: string, obj: SiteContract | HydratedPortfolio) =>
  ({ type: TOGGLE_PERSONAL_IN_FOCUS_SETTINGS.start, payload: { updateKey, obj } })

export const setSinglePersonalInFocusSettingReducer = (attribute: keyof MainInFocusVisibilitySettings, value: any) =>
  ({ type: SET_SINGLE_PERSONAL_IN_FOCUS_SETTING.reducer, payload: { attribute, value } })

interface setPersonalInFocusSettingsSuccessProps extends ContractOrPortfolio {
  id: number,
  personalVisibilitySettings: MainInFocusVisibilitySettings
}
export const setPersonalInFocusSettingsSuccess = (props: setPersonalInFocusSettingsSuccessProps) =>
  ({ type: SET_PERSONAL_IN_FOCUS_SETTINGS.success, payload: props })
export const setPersonalInFocusSettingsErrors = (error: string) => ({ type: SET_PERSONAL_IN_FOCUS_SETTINGS.error, error })

export const SET_CLIENT_STATIC_OPSDATA_VISIBILITY_SETTINGS = createRequestActionTypes('SET_CLIENT_STATIC_OPSDATA_VISIBILITY_SETTINGS');
export const setClientStaticOpsDataVisibilitySettings = (updateKey: string, obj: SiteContract | HydratedPortfolio) =>
  ({ type: SET_CLIENT_STATIC_OPSDATA_VISIBILITY_SETTINGS.start, payload: { updateKey, obj } });
export const setClientStaticOpsDataVisibilitySettingsSuccess = (visibilitySettings: MainInFocusVisibilitySettings) =>
  ({ type: SET_CLIENT_STATIC_OPSDATA_VISIBILITY_SETTINGS.success, payload: { visibilitySettings } });
export const setClientStaticOpsDataVisibilitySettingsErrors = (error: string) => ({ type: SET_PERSONAL_IN_FOCUS_SETTINGS.error, error });

export const FETCH_FULL_CLIENT_STATIC_OPSDATA_VISIBILITY_SETTINGS_CONFIG = createRequestActionTypes('CONTRACT_IN_FOCUS_FETCH_FULL_CLIENT_STATIC_OPSDATA_VISIBILITY_SETTINGS_CONFIG');
export const fetchClientStaticOpsDataVisibilitySettings = (obj: ContractOrPortfolio) => {
  return { type: FETCH_FULL_CLIENT_STATIC_OPSDATA_VISIBILITY_SETTINGS_CONFIG.start, payload: obj };
}
export const fetchClientStaticOpsDataVisibilitySettingsSuccess = ({ data, meta, permissions }: FetchMaintenanceReportConfigSuccessAction) =>
  ({ type: FETCH_FULL_CLIENT_STATIC_OPSDATA_VISIBILITY_SETTINGS_CONFIG.success, payload: { data, meta, permissions } });
