import styles from "styles/app/common/variables.scss";

export const chartsFontProps = {
  color: "#272727",
  size: 13
}

export const colourSequence = [
  styles.yellow,
  styles.blueDark,
  styles.gray,
  styles.green,
  '#ff0000', // red
]

export const colourSequence2 = [
  styles.blueDark,
  styles.yellow,
  styles.green,
  styles.gray,
  '#ff0000', // red
]

export const colourSequence3 = [
  styles.blueDark,
  styles.yellow,
  '#62b6cb',
  '#8b8a8c',
  '#fa991c'
]