import {
  ADMIN_PANEL_SET_DETAIL_PAGE,
  SET_MODE,
  //SET_ADMIN_PANEL_GROUPING_MODE,
  SET_DATA_AND_META,
  SET_META,
  SET_DATA,
  RESET_DATA,
  ADD_DATA_SUBITEM,
  SET_SUB_ITEM_DATA,
  SET_SUB_ITEM_MODE,
  SET_SUB_ITEM_DATA_AND_MODE,
  NEW_SUB_ITEM_RECORD,
  SAVE_NEW_SUB_ITEM_RECORD,
  CANCEL_NEW_SUB_ITEM_RECORD,
  SPREAD_ONTO_DATA,
  SET_INVALID_FIELDS,
  SET_API_ERRORS,
  CLEAR_API_ERRORS,
  SET_NEW_RECORD_ID,
  ADD_SELECTED_ENTITIES_TO_PANEL
} from "../Actions/adminPanel.actions";
import { SET_ORGANISATION } from "../Organisation/Actions/Organisation.actions";
import { SET_PERSON } from "../People/Actions/People.actions";
import { SET_CONTRACT } from "../Contracts/Actions/Contracts.actions";
import { SET_SITE } from "components/AdminPanel/Sites/Actions/AdminPanelSites.actions";
import { mode as dataMode } from "../../Common/Consts/DataMode";

const initialState = {
  panels: {}
};

export const invalidFieldsKey = "invalidFields";
export const apiErrorsKey = "apiErrors";

const panelUpdateHelper = (state, panelId, panelUpdateObject) => {
  return {
    ...state,
    panels: {
      ...state.panels,
      [panelId]: {
        ...state.panels[panelId],
        ...panelUpdateObject
      }
    }
  };
};

const panelSubItemHelper = (state, panelId, subItemKey, subItemValue) => {
  const panelUpdate = {
    data: {
      ...state.panels[panelId].data,
      [subItemKey]: subItemValue
    }
  };

  return panelUpdateHelper(state, panelId, panelUpdate);
};

const adminPanelDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODE:
      return panelUpdateHelper(state, action.panelId, { mode: action.mode });

    //case SET_ADMIN_PANEL_GROUPING_MODE
    case SET_ORGANISATION.success:
    case SET_PERSON.success:
    case SET_CONTRACT.success:
    case SET_SITE.success: {
      if (action.panelId) {
        return panelUpdateHelper(state, action.panelId, {
          groupingMode: true,
          newRecordId: action.recordId //used to identify the most recently created entity
        });
      }
      return state;
    }

    case SET_NEW_RECORD_ID: {
      return panelUpdateHelper(state, action.panelId, {
        newRecordId: action.id //used to identify the most recently created entity
      });
    }

    case ADMIN_PANEL_SET_DETAIL_PAGE: {
      const panelUpdate = {
        [action.recordId]: {
          detailIndex: action.index
        }
      };

      return panelUpdateHelper(state, action.panelId, panelUpdate);
    }

    case SET_DATA_AND_META: {
      const panelUpdate = {
        meta: action.meta,
        data: action.data
      };

      return panelUpdateHelper(state, action.panelId, panelUpdate);
    }

    case SET_META:
      return panelUpdateHelper(state, action.panelId, { meta: action.meta });

    case SPREAD_ONTO_DATA: {
      const panelUpdate = {
        data: {
          ...state.panels[action.panelId].data,
          ...action.data
        },
        initialData: action.data
      };

      return panelUpdateHelper(state, action.panelId, panelUpdate);
    }

    case SET_DATA:
      return panelUpdateHelper(state, action.panelId, {
        data: action.data,
        initialData: action.data
      });

    case SET_SUB_ITEM_DATA: {
      const records = [...state.panels[action.panelId].data[action.subItemKey]];

      const recordIndex = records.findIndex(
        record => record.id === action.subItemId
      );
      records.splice(recordIndex, 1, action.subItem);

      return panelSubItemHelper(
        state,
        action.panelId,
        action.subItemKey,
        records
      );
    }

    case CLEAR_API_ERRORS:
      return panelUpdateHelper(state, action.panelId, { [apiErrorsKey]: null });

    case SET_API_ERRORS: {
      return panelUpdateHelper(state, action.panelId, {
        [apiErrorsKey]: action.apiErrors
      });
    }

    case SET_INVALID_FIELDS:
      return panelUpdateHelper(state, action.panelId, {
        [invalidFieldsKey]: action.invalidFields
      });

    case ADD_DATA_SUBITEM: {
      const found = (
        state.panels[action.panelId].data[action.subItemKey] || []
      ).find(item => item.id === action.subItem.id);

      const panelUpdate = [
        ...state.panels[action.panelId].data[action.subItemKey],
        action.subItem
      ];

      return found
        ? { ...state }
        : panelSubItemHelper(
            state,
            action.panelId,
            action.subItemKey,
            panelUpdate
          );
    }

    case SET_SUB_ITEM_DATA_AND_MODE: {
      const records = [...state.panels[action.panelId].data[action.subItemKey]];

      const recordIndex = records.findIndex(
        record => record.id === action.subItemId
      );
      records.splice(recordIndex, 1, { ...action.subItem, mode: action.mode });

      return panelSubItemHelper(
        state,
        action.panelId,
        action.subItemKey,
        records
      );
    }

    case SET_SUB_ITEM_MODE: {
      const records = [...state.panels[action.panelId].data[action.subItemKey]];

      const recordIndex = records.findIndex(
        record => record.id === action.subItemId
      );

      const record = { ...records[recordIndex], mode: action.mode };

      records.splice(recordIndex, 1, record);

      return panelSubItemHelper(
        state,
        action.panelId,
        action.subItemKey,
        records
      );
    }

    case NEW_SUB_ITEM_RECORD: {
      const records = [...state.panels[action.panelId].data[action.subItemKey]];

      records.push({ ...action.subItem, mode: "new" });

      return panelSubItemHelper(
        state,
        action.panelId,
        action.subItemKey,
        records
      );
    }

    case SAVE_NEW_SUB_ITEM_RECORD: {
      const records = [...state.panels[action.panelId].data[action.subItemKey]];

      const recordIndex = records.findIndex(record => record.move === "new");

      const record = { ...action.subItem, mode: "view" };

      records.splice(recordIndex, 1, record);

      return panelSubItemHelper(
        state,
        action.panelId,
        action.subItemKey,
        records
      );
    }

    case CANCEL_NEW_SUB_ITEM_RECORD: {
      const records = [...state.panels[action.panelId].data[action.subItemKey]];

      const recordIndex = records.findIndex(record => record.move === "new");

      records.splice(recordIndex, 1);

      return panelSubItemHelper(
        state,
        action.panelId,
        action.subItemKey,
        records
      );
    }

    case RESET_DATA: {
      const panelUpdate = {
        data: state.panels[action.panelId].initialData,
        mode: dataMode.view,
        [invalidFieldsKey]: [],
        [apiErrorsKey]: null
      };

      return panelUpdateHelper(state, action.panelId, panelUpdate);
    }

    default:
      return state;
  }
};

export default adminPanelDataReducer;
