import React from 'react';
import { withRouter } from 'react-router-dom';
import logoLightSvg from '../../../../styles/images/logoLight.svg';
import logoDarkSvg from '../../../../styles/images/logoDark.svg';
import { HeaderLogoWrapper } from './HeaderLogoStyles';

interface HeaderLogoProps {
  history: any;
  location: any;
  match: any;
  isDark: boolean;
}

const HeaderLogo = ({ history, isDark }: HeaderLogoProps) => {
  const handleLogoClick = (): void => {
    history.push('/');
  }

  const getLogoClass = (): string => isDark ? logoLightSvg : logoDarkSvg;

  return <HeaderLogoWrapper onClick={handleLogoClick}>
    <img src={getLogoClass()} alt='Jaguar Apprise Logo' />
    <div className='img-placeholder' />
  </HeaderLogoWrapper>
}

export default withRouter<HeaderLogoProps, any>(HeaderLogo);