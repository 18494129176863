/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import moment from "moment";

import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import QuoteItemList from "components/ContractInFocus/Financials/Components/QuoteItemsList";
import { InlineWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { projectConfigSelector } from 'components/ContractInFocus/HoC/WithPageContext/Selectors/Download.selectors';
import { Paper } from "@material-ui/core";
import financialsService from "components/ContractInFocus/Financials/Services/financialServices";
import { IFinancials, clientAWQuoteResponseI } from "components/ContractInFocus/Financials/interfaces/financials.interface";
import AWDocuments from "components/ContractInFocus/Financials/Containers/FinancialGrid/AWDocuments/Containers/AWDocuments";
import { APIPrivileges } from "services/Interface/Interface";
import { sumObProps, toPounds } from "store/Common/Helpers/commonHelper.js";
import { quoteItemColumns, paperViewQuoteItemColumns } from 'components/ContractInFocus/Financials/Models/financialsColumn';
import { ConvertClientAddressForDisplay } from "components/Common/Utils/Component.js";

// Styling
import "components/ContractInFocus/Financials/Styles/QuoteDetail.scss";
import "components/ContractInFocus/Financials/Styles/QuotePaperView.scss";
import * as inFocusSelectors from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

interface QuoteItemDetailViewProps {
    data: IFinancials;
    setPaperQuoteViewData: Function;
    portfolio?: HydratedPortfolio
}

const PaperQuoteView: React.FC<QuoteItemDetailViewProps> = ({
    data,
    setPaperQuoteViewData,
    portfolio
}) => {
    const [quoteItems, setQuoteItems] = useState<any>(null);
    const [clientResponseValue, setClientResponseValue] = useState<clientAWQuoteResponseI | null>(null);
    const [changeClientResponsePermissions, setChangeClientResponsePermissions] = useState<APIPrivileges>();
    const [poText, setPOText] = useState<string>("");
    const projectConfig = useSelector(projectConfigSelector);
    const selectedSiteState = useSelector(inFocusSelectors.contractInFocusSiteSelector);

    useEffect(() => {
        clientResponseValue && financialsService.permissionsChangeClientResponse(data.id, clientResponseValue.id, setChangeClientResponsePermissions, portfolio?.id);

        if (!quoteItems) {
            const quoteItems = financialsService.getAWQuoteItemsForAW(data.id, portfolio?.id).then(
                (quoteItems) => {
                    setQuoteItems(quoteItems);
                }
            );
        }

    }, [quoteItems, clientResponseValue]);

    useEffect(() => {
        //if (!clientResponse) {
        data.id && financialsService.getClientResponseForAW(data.id, portfolio?.id).then(
            (clientResponse: any) => {
                let r;
                if (clientResponse.data.length) {
                    r = clientResponse.data[0];
                } else {
                    r = null;
                }
                if (!isEqual(r, clientResponseValue)) {
                    setClientResponseValue(r);
                }
            }
        );
        //}
    }, [data]);

    // useEffect(() => {
    //     //if (!clientResponse) {
    //     setClientResponseValue(clientResponseValues.current[data.id]);
    //     console.log('clientResponseValues: ', clientResponseValues.current[data.id]);
    //     //}
    // }, [clientResponseValues.current]);

    const handleSubmitResponseToQuote = () => {
        const payload = {
            "quote_declined": 0,
            "reference": poText,
            "aw": data.id,
        }
        financialsService.submitQuoteResponse(data.id, payload, portfolio?.id).then(
            (response) => {
                //clientResponseValues[data.id] = response.data;
                setClientResponseValue(response.data);
                setPOText('');
            }
        );
    }

    const handleDecline = () => {
        const payload = {
            "quote_declined": true,
            "aw": data.id,
        }
        financialsService.submitQuoteResponse(data.id, payload, portfolio?.id).then(
            (response) => {
                //clientResponseValues[data.id] = response.data;
                setClientResponseValue(response.data);
                setPOText('');
            }
        );
    }

    const handleDeleteResponseToQuote = () => {
        changeClientResponsePermissions?.DELETE && clientResponseValue?.id.length && financialsService.deleteQuoteResponse(data.id, clientResponseValue.id, portfolio?.id).then(
            (response) => {
                //clientResponseValues[data.id] = response.data;
                setClientResponseValue(null);
                setPOText('');
            }
        );
    }

    const totalCharge = quoteItems ? sumObProps(quoteItems.data, 'total') : 0;
    const totalChargeCurr = toPounds(totalCharge);

    return (
        <InlineWrapper >
            <Paper elevation={3}>
                <div className="AWDetails paperQuote">
                    <Button id="backToAWList" onClick={() => setPaperQuoteViewData(null)}>{"< Back To List View"}</Button>
                    <div className="headerWrapper">
                        <div className="LHSWrapper">
                            <br />
                            <h1 className="QuoteTitle">Additional Works Quotation</h1>
                            <br />
                            <table className="quote-view-title-properties">
                                <tbody>
                                    <tr><td className="quote-view-title-label">AW No:</td><td className="emphasised">{data.aw_number}</td></tr>
                                    <tr><td className="quote-view-title-label">Title:</td><td>{data.title}</td></tr>
                                    <tr><td className="quote-view-title-label">Site:</td><td>{selectedSiteState.data?.name}</td></tr>
                                    <tr><td className="quote-view-title-label">Supervisor:</td><td>{data.quote_supervisor}</td></tr>
                                    <tr><td className="quote-view-title-label">Date:</td><td>{data.date_requested && data.date_requested.length ? moment(data.date_requested).format("DD/MM/YYYY") : ""}</td></tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="RHSWrapper">
                            <div className="quoteViewLogoWrapper">
                                <img src={projectConfig.jaguar_doc_logo_with_text.small} alt="Jaguar Tracks Logo" />
                            </div>
                            <div className="JBSTradingAddressWrapper">
                                Jaguar Building Services<br />
                                6 Gracechurch Street<br />
                                London, EC3V 0AT<br />
                                Tel: 0207 071 0700
                            </div>
                        </div>

                    </div>


                    <table className="quote-view-details">
                        <tbody>
                            <tr><td colSpan={2}>{data.description}</td></tr>
                            {quoteItems && quoteItems.data.map(
                                (row: any) => {
                                    return <tr key={row["id"]}>
                                        {
                                            paperViewQuoteItemColumns.map(
                                                (column) => <td className={column.currency ? "currency" : undefined}
                                                    key={column.dataField}> {column.currency ? (toPounds(row[column.dataField])) : (row[column.dataField] + ":")}
                                                </td>
                                            )
                                        }
                                    </tr>
                                }
                            )}
                            <tr className="quote-view-title-label no-bottom-border"><td >Quotation Total: </td><td className="currency total">{totalChargeCurr}</td></tr>
                            <tr className="no-upper-border"><td colSpan={2} >The above quotation is held for a period of 30 days and excludes VAT </td></tr>
                            <tr ><td colSpan={2} ><span className="emphasised">Please confirm the following Client invoicing Address is correct for these works:</span>
                                <br />
                                <br />
                                <ConvertClientAddressForDisplay {
                                    ...{
                                        origAddressString: data?.quote_default_address
                                    }
                                }>
                                </ConvertClientAddressForDisplay>
                            </td></tr>
                            <tr className="no-bottom-border"><td colSpan={2} className="quote-view-title-label no-bottom-border">Authorization:</td></tr>
                            <tr className="no-upper-border po-row">
                                <td>
                                    <span>{data.client_po ? "Purchase Order Number: " + data.client_po :
                                        clientResponseValue?.reference ? `Purchase Order Number'${clientResponseValue?.reference}' Pending Registration...` :
                                            clientResponseValue?.quote_declined ? `QUOTE DECLINED on ${moment(clientResponseValue.updated_at).format("DD/MM/YYYY")} at ${moment(clientResponseValue.updated_at).format("HH:mm:ss")} ` : ""}
                                    </span>

                                    {!data.client_po && !clientResponseValue &&
                                        <>
                                            <div className="POInputWrapper">
                                                <TextField id="POInput" label="PO Number/Reference" onChange={event => {
                                                    const { value } = event.target;
                                                    setPOText(value);
                                                }}>
                                                </TextField>
                                            </div>

                                        </>
                                    }
                                </td>
                                {!data.client_po ?
                                    <>
                                        <td className="poActionsWrapper">
                                            {clientResponseValue?.reference && changeClientResponsePermissions?.DELETE &&
                                                <Button color="primary" type="submit" onClick={handleDeleteResponseToQuote} >Undo pending PO Registration</Button>
                                            }
                                            {!clientResponseValue && changeClientResponsePermissions?.POST && !!poText.length && <Button color="primary" type="submit" onClick={handleSubmitResponseToQuote} >Submit PO</Button>}

                                            {!clientResponseValue?.reference && clientResponseValue?.quote_declined && changeClientResponsePermissions?.DELETE &&
                                                <Button color="primary" type="submit" onClick={handleDeleteResponseToQuote} >Undo declined quote</Button>
                                            }
                                            {!clientResponseValue && changeClientResponsePermissions?.POST && !poText.length && <Button color="primary" type="submit" onClick={handleDecline}>...Or Decline AW</Button>}
                                        </td>
                                    </>
                                    : <td></td>}
                            </tr>
                            <tr className="quote-view-title-label no-bottom-border comments-label-row"><td colSpan={2} >Comments</td></tr>
                            <tr className="no-upper-border comments-row"><td colSpan={2} ></td></tr>
                        </tbody>

                    </table>
                    {/* AW Details
                AW Breakdown of Costs
                Date Submitted
                Client Po number
                Po Received Date
                Invoice Number
                Invoice Date
                Client Name */}

                </div>
            </Paper>
        </InlineWrapper >
    )
}

export default PaperQuoteView;