import API, { APIR } from "../../../services/API/API";
import { getData } from "../../../services/API/API.helper";
import { catchError, map } from "rxjs/operators";
import { of } from "rxjs";

export const updateProfileService = (payload: any) => {
    return APIR.patch(`/people/me/`, payload).pipe(
        map((response) => getData(response)),
        catchError((error) => of(error))
    );
};

export const updatePersonalSettingsService = (payload: any) => {
    API.patch(`/my/personal-settings/`, payload).then(
        (response) => getData(response)
    ).catch((e: any) => {
        console.log('error in updating personal settings: ', e);
    });
};