import React from "react";
import PropTypes from "prop-types";

// Styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { Wrapper, FlexCenterWrapper } from "./FlagsStyles";
import { GeneralTooltip } from "components/Common/Components/InfoHint/InfoHint";

const Scheduled = ({ count, color, warn, style }) => {
  if (warn) {
    return (
      <GeneralTooltip title={warn}>
        <Wrapper
          className={`specialist-flag ${warn ? "warn" : ""}`}
          style={{ ...style, color }}
        >
          <FontAwesomeIcon icon={faClock} style={{ color }} />
          {/* {!warn && <FontAwesomeIcon icon={faCheck} style={{ color }} />} */}
          {count ? <FlexCenterWrapper>{count}</FlexCenterWrapper> : null}
        </Wrapper>
      </GeneralTooltip>
    );
  }
  return (
    <Wrapper className="specialist-flag" style={{ color }}>
      <FontAwesomeIcon icon={faClock} style={{ color }} />
      {count ? <FlexCenterWrapper>{count}</FlexCenterWrapper> : null}
    </Wrapper>
  );
};

Scheduled.propTypes = {
  count: PropTypes.number,
  color: PropTypes.string,
  warn: PropTypes.string,
  style: PropTypes.any
};

export default Scheduled;
