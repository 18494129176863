import moment from 'moment';

// Own
import { arrayToDict, dictToArray } from '../../../../services/API/API.helper';
import { Dictionary } from '../../../Common/Interfaces/Entity.interface';
import {
  calculatePercentCompleted,
  calculateNotCompleted,
  calculateTasksCarriedForwardNullValues,
  roundNumber
} from './Calculations.helper';
import { DataRow, InteractiveTableChart, PortfolioInteractiveTableChart } from '../Interfaces/interactiveTable.interfaces';
import { Snapshot } from 'components/ContractInFocus/Interfaces/ContractInFocus.interfaces';
import { TasksCountType } from 'components/ContractInFocus/Maintenance/Models/PPMReactive.model';
import { CellData } from "components/ContractInFocus/Maintenance/Interfaces/interactiveTable.interfaces";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

export const getSortByAttribute = (attribute: string, asc = true) => (rowA: any, rowB: any) => {
  let valueA = rowA[attribute] === undefined ? null : rowA[attribute]; // null values can be compared to other primitives, undefined can't be
  let valueB = rowB[attribute] === undefined ? null : rowB[attribute];

  //@ts-ignore who cares if the values may be null or undefined.. they can still be sorted
  if (valueA > valueB) {
    return asc ? 1 : -1;
  }
  //@ts-ignore who cares if the values may be null or undefined.. they can still be sorted
  if (valueA < valueB) {
    return asc ? -1 : 1;
  }
  return 0
}

export const sortByName = getSortByAttribute('name');

export const sortByContactRef = getSortByAttribute('contract_ref');

export interface transformTaskCountDataProps {
  data: DataRow[],
  months: string[],
  primaryField: string,
  transformForChart: (ppmRecords: DataRow[], months: string[]) => any,
  focusSnapshot?: Snapshot,
  calculateSumForRowsConfig?: string[],
  percentagesConfig?: {},
  tasksCountMeta: TasksCountType,
  carryForward?: boolean,
  portfolio?: HydratedPortfolio,
  result?: PortfolioInteractiveTableChart
}

export interface transformTaskCountDataForPortfolioOrContractProps extends Omit<transformTaskCountDataProps, 'data'> {
  data: DataRow[] | Dictionary<Dictionary<DataRow[]>>,
  portfolio?: HydratedPortfolio
}

export type transformForChartType = (ppmRecords: DataRow[], months: string[]) => any;

export const transformTaskCountDataForPortfolioOrContract = (
  {
    data,
    months,
    primaryField,
    transformForChart,
    focusSnapshot,
    calculateSumForRowsConfig,
    percentagesConfig,
    tasksCountMeta,
    carryForward = true,
    portfolio
  }: transformTaskCountDataForPortfolioOrContractProps

): InteractiveTableChart | PortfolioInteractiveTableChart | null => {
  const contractData = data as DataRow[];
  const portfolioContracts = portfolio?.contracts.filter(x => !!x);
  if (portfolio) {
    if (!portfolioContracts?.length) {
      return null;
    }
    let result = <PortfolioInteractiveTableChart>{};
    let totalRows: DataRow[][] = [contractData.filter(x => x.associated_with == "total")];
    const contractRows: DataRow[][] = [];
    let portfolioRowGroups: DataRow[][] = [];
    portfolio.contracts.map(x => {
      contractRows.push(contractData.filter(cD => cD?.associated_with === x?.id));
    })
    portfolioRowGroups = [...contractRows, ...totalRows]; // The order here is important, as we want to be processing the total rows once the contract rows have 
    // already been processed, in order to access their carried forward values.
    // console.log(portfolioRowGroups);
    portfolioRowGroups.map((x, i) => {
      let childContract = false;
      let thisTransformForChart = transformForChart;
      if (portfolio) {
        const firstRow = x[0]; //should apply to any row, so might as well use the first
        if (firstRow['associated_with'] !== "total") {
          childContract = true; // the idea here is to filter the dataset down to the 'total' rows for the chart when we're in a portfolio context
          thisTransformForChart = () => []; // no point doing any processing if we won't use the result
        }
      }
      const thisResult = transformTaskCountData({
        data: x,
        months,
        primaryField,
        transformForChart: thisTransformForChart,
        focusSnapshot,
        calculateSumForRowsConfig,
        percentagesConfig,
        tasksCountMeta,
        carryForward,
        portfolio,
        result
      })

      result.table = result.table ? [...thisResult.table, ...result.table] : thisResult.table;
      result.chart = childContract ? result.chart : result.chart ? [...thisResult.chart, ...result.chart] : thisResult.chart;
      // console.log('result.table: ', result.table);
    })
    const tableTotals = result.table.filter(x => !x.header_pointer);
    const tableContractRows = result.table.filter(x => !!x.header_pointer).sort(sortByName);
    result.table = [...tableTotals, ...tableContractRows];
    return result;
  }
  const result = transformTaskCountData({
    data: contractData,
    months,
    primaryField,
    transformForChart,
    focusSnapshot,
    calculateSumForRowsConfig,
    percentagesConfig,
    tasksCountMeta,
    carryForward,
    portfolio,
  })
  return result;
}


export const transformTaskCountData = (
  {
    data,
    months,
    primaryField,
    transformForChart,
    focusSnapshot,
    calculateSumForRowsConfig,
    percentagesConfig,
    tasksCountMeta,
    carryForward = true,
    portfolio,
    result
  }: transformTaskCountDataProps

): InteractiveTableChart => {
  const dataWithPolyfilledMonths = polyFillMonths(data, months, tasksCountMeta);
  let dataForRowCalculations = dataWithPolyfilledMonths;
  const tableDataAsDictionary = arrayToDict(dataForRowCalculations);
  const calculatedRows = getCalculatedRows(
    dataForRowCalculations,
    tableDataAsDictionary,
    tasksCountMeta,
    months,
    portfolio
  );
  const dataWithNewCalculatedRows = [
    ...dataWithPolyfilledMonths,
    ...calculatedRows
  ];

  const orderedData = orderRows(dataWithNewCalculatedRows, tasksCountMeta);
  const calculatedSumColCells: any[] = [];
  let toTransform = orderedData;

  if (carryForward) {
    toTransform = [...removeCarriedForward(orderedData, tasksCountMeta)]
    const carriedForwardPolyfill = calculateTasksCarriedForwardNullValues(
      {
        ...tableDataAsDictionary,
        ...arrayToDict(calculatedRows),
      },
      primaryField,
      tasksCountMeta,
      months,
      portfolio,
      result
    );
    toTransform.unshift(carriedForwardPolyfill);
  }

  const transformedResult = toTransform.map(tableRow => {
    // the calculation retrieves the RHS summary column part and pushes into an array so we can do calcs on the column afterwards.
    // the calculateSumForRowsConfig indicates which rows should be summed 
    const { row, calculation } = addPrefixColumnAndSuffixCalculatedColumnToDataRow(tableRow, primaryField, tasksCountMeta, calculateSumForRowsConfig);
    calculatedSumColCells.push(calculation);
    return row;
  });
  // here we use the config we passed into to know which sums to calculate %s for and which row the % should be displayed in...
  // console.log('calculatedSumColCells: ', calculatedSumColCells);
  percentagesConfig && Object.keys(percentagesConfig).map(
    (pc) => {
      // the 'pc' key should be the key of the cell we want to calculate a percentage for - e.g. 'percent_completed'
      //console.log('pc: ', pc);
      //@ts-ignore
      const pcComponents = percentagesConfig[pc];
      // the components in the percentagesConfig for the percent calc should hold the keys of the cells we want to use as the nominator and denominator
      if (pcComponents) {
        const pcNominator = pcComponents["nominator"];
        //console.log("nominator: ", pcNominator);
        const pcDeNominator = pcComponents["denominator"];
        //console.log("denominator: ", pcDeNominator);
        const pcSummaryCol = calculatedSumColCells ? calculatedSumColCells.filter(r => r.key.toLowerCase() === pc.toLowerCase())[0] : null;
        //console.log('pc summary row: ', pcSummaryCol);
        const pcNominatorCell = calculatedSumColCells ? calculatedSumColCells.filter(r => r.key.toLowerCase() === pcNominator.toLowerCase())[0] : null;
        //console.log('pcNominatorCell: ', pcNominatorCell);
        const pcDeNominatorCell = calculatedSumColCells ? calculatedSumColCells.filter(r => r.key.toLowerCase() === pcDeNominator.toLowerCase())[0] : null;
        //console.log('pcDeNominatorCell: ', pcDeNominatorCell);
        const pcNominatorValue = pcNominatorCell ? (pcNominator.displayValue || pcNominatorCell.value) : null;
        //console.log('pcNominatorValue: ', pcNominatorValue);
        const pcDeNominatorValue = pcDeNominatorCell ? (pcDeNominatorCell.displayValue || pcDeNominatorCell.value) : null;
        //console.log('pcDeNominatorValue: ', pcDeNominatorValue);
        let pcValue = pcNominatorValue && pcDeNominatorValue ? (pcNominatorValue * 100 / pcDeNominatorValue) : null;
        pcValue = pcValue ? Math.round(pcValue * 100) / 100 : null;
        //console.log('pcValue: ', pcValue);
        const summaryColName = pcSummaryCol ? pcSummaryCol.columnName : null;
        //console.log('pc summaryColName: ', summaryColName);
        let row = transformedResult.filter(r => r.key.toLowerCase() === pc.toLowerCase())[0];
        //console.log('pc row: ', row);
        if (row) {
          row[summaryColName].value = pcValue;
          //console.log('row[summaryColName]: ', row[summaryColName]);
        }
      }
    }
  )
  /* *********************************
  Calculated table last columns values - used for changing the way the table is calculated
  //console.log('calculatedSumColCells', calculatedSumColCells);
  *********************************** */

  // const chartData = transformDataForStandardTasksChart(transformedResult, months);
  const chartData = transformForChart(transformedResult, months);
  return { chart: chartData, table: transformedResult };
};

// const removeCarriedForward = (gridData: DataRow[]) => gridData.slice(1);
const removeCarriedForward = (gridData: DataRow[], tasksCountMeta: TasksCountType) => gridData.filter(x => x.key !== tasksCountMeta.tasks_carried_forward_manual.key);


// export const isKeyColumn = (key: any, primaryField = ''): boolean =>
//   key === 'name' || key === 'key' || key === primaryField || key === "header_pointer" || key === "head_ref"


export const getCalculatedRows = (
  data: any[],
  dictionary: Dictionary<any>,
  tasksCountMeta: TasksCountType,
  months: string[],
  portfolio?: HydratedPortfolio,
): any[] => {
  const readonly = true;
  const associated_with = dictionary[tasksCountMeta.tasks_completed.key]?.associated_with;
  const name = dictionary[tasksCountMeta.tasks_completed.key]?.name;
  const calculatedPercentCompletedRow: Dictionary<any> = {
    name: `${(associated_with === "total" || !portfolio) ? tasksCountMeta.percent_completed.display : name}`,
    key: tasksCountMeta.percent_completed.key,
    head_ref: !portfolio ? null : associated_with + "_" + tasksCountMeta.percent_completed.key,
    header_pointer: (associated_with === "total" || !portfolio) ? null : "total_" + tasksCountMeta.percent_completed.key,
    associated_with: associated_with
  }
  const calculatedNotCompletedRow: Dictionary<any> = {
    name: `${(associated_with === "total" || !portfolio) ? tasksCountMeta.not_completed_in_a_month.display : name}`,
    key: tasksCountMeta.not_completed_in_a_month.key,
    head_ref: !portfolio ? null : associated_with + "_" + tasksCountMeta.not_completed_in_a_month.key,
    header_pointer: (associated_with === "total" || !portfolio) ? null : "total_" + tasksCountMeta.not_completed_in_a_month.key,
    associated_with: associated_with
  }
  months.map((month, index) => {
    const prevMonth = index > 0 ? months[index - 1] : null;
    calculatedPercentCompletedRow[month] = {
      readonly,
      readonlyStyle: readonly,
      value: calculatePercentCompleted(
        calculatedNotCompletedRow,
        prevMonth,
        month,
        dictionary,
        tasksCountMeta
      )
    }
    calculatedNotCompletedRow[month] = {
      readonly,
      readonlyStyle: readonly,
      value: calculateNotCompleted(
        calculatedNotCompletedRow,
        prevMonth,
        month,
        dictionary,
        tasksCountMeta
      )
    }
  })
  return [calculatedPercentCompletedRow, calculatedNotCompletedRow];
};

export const orderRows = (data: any[], tasksCountMeta: TasksCountType) => {
  return [...data].sort(
    (recordA: { key: string }, recordB: { key: string }) => {
      const lookupA = Object.keys(tasksCountMeta).filter(x => tasksCountMeta[x].key == recordA.key)[0];
      const lookupB = Object.keys(tasksCountMeta).filter(x => tasksCountMeta[x].key == recordB.key)[0];
      if (
        tasksCountMeta[lookupA].position <
        tasksCountMeta[lookupB].position
      ) {
        return -1;
      }
      if (
        tasksCountMeta[lookupA].position >
        tasksCountMeta[lookupB].position
      ) {
        return 1;
      }
      return 0;
    }
  );
};

const isPercentComplete = (key: string, tasksCountMeta: TasksCountType): boolean =>
  key === tasksCountMeta.percent_completed.key;

const addPrefixColumnAndSuffixCalculatedColumnToDataRow = (
  record: any,
  primaryField: string,
  tasksCountMeta: TasksCountType,
  calculateSumForRowsConfig?: string[],
) => {
  const { name, key, header_pointer, head_ref, associated_with, ...rowData } = record;

  const primaryColumn = {
    [primaryField]: {
      dictKey: primaryField,
      value: name,
      readonly: true,
      first: true
    }
  };

  //@ts-ignore
  const skipSum = calculateSumForRowsConfig ? !calculateSumForRowsConfig.includes(key.toLowerCase()) : false;
  const rowDataKeys = Object.keys(rowData);
  const firstColumnHeader = rowDataKeys[0];
  const lastColumnHeader = rowDataKeys[rowDataKeys.length - 1];
  const count = rowDataKeys.length;
  let valueSum = skipSum ? null : rowDataKeys.reduce(
    (acc: any, columnKey: string) => {
      const displayValue = rowData[columnKey].displayValue;
      const recordValue = isNaN(parseFloat(displayValue)) ? (rowData[columnKey].value || rowData[columnKey].calcValue || 0) : displayValue;
      return isNaN(recordValue) ? acc : acc + parseFloat(recordValue)
    }, 0
  )
  if (!isNaN(valueSum)) {
    valueSum = valueSum?.toFixed(2);
  }

  const rowInfo = { firstColumnHeader: firstColumnHeader, lastColumnHeader: lastColumnHeader, value: valueSum, count: count };

  const dateRangeColumnName = `${rowInfo.firstColumnHeader} - ${rowInfo.lastColumnHeader}`;

  const calculatedColumnAndValues = {
    [dateRangeColumnName]: {
      dictKey: dateRangeColumnName,
      url: null,
      value: isPercentComplete(key, tasksCountMeta)
        ? roundNumber(rowInfo.value / rowInfo.count, 2)
        : rowInfo.value,
      readonly: true,
      last: true
    }
  };

  return {
    row: {
      name,
      key,
      header_pointer,
      head_ref,
      associated_with,
      ...primaryColumn,
      ...rowData,
      ...calculatedColumnAndValues
    },
    calculation: {
      key,
      columnName: dateRangeColumnName,
      value: calculatedColumnAndValues[dateRangeColumnName].value
    }
  };
};

export const shouldRowZeroInitialise = (key: string, tasksCountMeta: TasksCountType) =>
  key === tasksCountMeta.tasks_issued.key ||
  key === tasksCountMeta.time_spent.key;

export const zeroInitializeRow = (key: string, tasksCountMeta: TasksCountType) => {
  if (key === tasksCountMeta.tasks_issued.key) {
    return 0
  } else if (key === tasksCountMeta.time_spent.key) {
    const thisZero = 0
    return thisZero.toFixed(2)
  };
}

export const polyFillMonths = (data: any[], months: string[], tasksCountMeta: TasksCountType): DataRow[] => {
  const monthIndexMap = arrayToDict(
    months.map((month, index) => ({ month, index })),
    'month'
  );
  const polyfilledMonths = data.map((row: DataRow) => {
    const { name, key, contract_ref, header_pointer, head_ref, associated_with, ...unsortedRow } = months.reduce((acc, month) => {
      const value = zeroInitializeRow(row.key, tasksCountMeta);//shouldRowZeroInitialise(row.key, tasksCountMeta) ? 0 : null;
      const monthObj = acc[month] as CellData;
      return monthObj?.id ? acc : { ...acc, [month]: { value, insert: true } };
    }, row);

    const newRowArray = dictToArray(unsortedRow, false);
    const sortedRow = [...newRowArray].sort((colA: any, colB: any) => {
      return monthIndexMap[colA.dictKey].index <
        monthIndexMap[colB.dictKey].index
        ? -1
        : monthIndexMap[colA.dictKey].index > monthIndexMap[colB.dictKey].index
          ? 1
          : 0;
    });

    return { name, key, header_pointer, head_ref, associated_with, ...arrayToDict(sortedRow) };
  });
  return polyfilledMonths;
};
