import React, { memo } from 'react'
import { Site } from '../../../../Sites/Interfaces/Site.inteface'
import { ContractInterface } from 'components/AdminPanel/Contracts/Interfaces/Contract.interface';
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";

import { SubHeading, SubSmall } from './ContractSubHeadingSiteStyles';
import { ContractPeriod } from 'components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface';

interface ContractSubHeadingSiteProps {
  //selectedContract: ContractInterface;
  selectedContract: SiteContract;
  site: Site;
  contractPeriods: ContractPeriod[];
}



const ContractSubHeadingSite: React.FC<ContractSubHeadingSiteProps> = ({ site, selectedContract, contractPeriods }) => {
  return (
    <div>
      <SubHeading>
        {selectedContract.display_name}
      </SubHeading>
      <SubSmall>
        {site.site_contracts.length} contracts &amp; {contractPeriods.length} contract periods
      </SubSmall>
    </div>
  )
}

export default memo(ContractSubHeadingSite);