import { StateObservable } from "redux-observable";
import { State } from "store/interfaces/State";
import { portfolioPeriodFrozenForListApi } from "services/API/common/contractPeriodAPIs";
import { Snapshot, FrozenHistoryBranch } from "components/ContractInFocus/Interfaces/ContractInFocus.interfaces";
import { ContractPeriod, PortfolioPeriod } from 'components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface';

export const ROUTE = "/portfolios/";

export const getPortfolioFromState = (state$: StateObservable<State>) => {
    return state$.value.portfolios.data[state$.value.portfolioInFocus.portfolio.id]
}

export const getPortfolioSnapshotHistory = (state$: StateObservable<State>): FrozenHistoryBranch => state$.value.portfolioInFocus.frozenHistory;

export const portfoliosPeriodsApiRequests = (portfolioPeriodId: any) => ({
    frozenHistory: portfolioPeriodFrozenForListApi(portfolioPeriodId),
});