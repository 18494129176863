import { number } from "prop-types";
import React from "react";
import styled from "styled-components";

export const Logo = styled.img`
  height: 100%;
`;

const logo = (props: any) => (
  <Logo src="img/jaguar-logo.png" alt="Jaguar Tracks Logo" />
);

interface LogoDims {
  height?: number | string;
  width?: number | string;
}

export const LogoWithText = ({ height, width }: LogoDims) => (
  <Logo style={{ height: height, width: width }} src="./img/jbs-logo-with-text.svg" alt="Jaguar Tracks Logo" />
);

export default logo;
