import React from "react";
import PropTypes from "prop-types";
import { GridHeading, GridTitle, GridSubTitle } from "./GridHeaderStyles";
import { InfoHint } from "components/Common/Components/InfoHint/InfoHint";

const GridHeader = ({
  id = undefined,
  testId,
  title,
  subTitle = "",
  level = 1,
  className = "",
  externalCardHeaders,
  ExtraButtons
}) => {
  return (
    <GridHeading
      data-testid={testId}
      id={`gridHeader${id ? id : ""}`}
      className={`gridHeader ${className ? className : ""}`}
      externalCardHeaders={externalCardHeaders}
      ExtraButtons={ExtraButtons}
    >
      <div
        style={{
          display: "flex",
          justifyContent: ExtraButtons ? "space-between" : undefined,
          alignItems: ExtraButtons ? "center" : undefined
        }}
      >
        {level === 1 && (
          <GridTitle>
            <h1 data-testid={`${testId}-h1-title`}> {title} </h1>{" "}
          </GridTitle>
        )}
        {ExtraButtons && <ExtraButtons />}
        {level === 2 && (
          <GridTitle>
            <h2 data-testid={`${testId}-h2-title`}> {title} </h2>{" "}
          </GridTitle>
        )}
        {level === 3 && (
          <GridTitle>
            <h3 data-testid={`${testId}-h3-title`}> {title} </h3>{" "}
          </GridTitle>
        )}

        {/* <GridSubTitle className="no-print">{subTitle}</GridSubTitle> */}
        <InfoHint className="no-print" message={subTitle}></InfoHint>
      </div>
    </GridHeading>
  );
};

GridHeader.propTypes = {
  id: PropTypes.string,
  testId: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  level: PropTypes.number,
  className: PropTypes.string,
  externalCardHeaders: PropTypes.bool,
  ExtraButtons: PropTypes.func
};

export default GridHeader;

export const PrintHeader = ({ title }) => (
  <GridHeader
    id={`Print`}
    testId={`Test-${title}`}
    className="print-section-indicator"
    title={title}
  />
  //<GridHeader id="Print" className="print-section-indicator" title={title} />
);

PrintHeader.propTypes = {
  title: PropTypes.string,
  breakBefore: PropTypes.bool
};
