import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";
import { createRequestActionTypes } from "components/Common/Utils/ReduxUtil";
import { Site } from "components/Sites/Interfaces/Site.inteface";
import { APIPrivileges } from "services/Interface/Interface";
import { EntityState } from 'components/Common/Interfaces/Entity.interface';

/* Fetch Sites*/
export const FETCH_SITES = createRequestActionTypes("SITE_FETCH_SITES");

export const fetchSites = (redirect = false) => {
    return { type: FETCH_SITES.start, payload: { redirect } }
};
export const fetchSitesSuccess = (sites: EntityState<Site[]>) => ({ type: FETCH_SITES.success, sites });
export const fetchSitesError = (error: object) => ({ type: FETCH_SITES.error, error });

export const SET_GROUP_SITE = createRequestActionTypes('SET_GROUP_SITE');
export const setGroupSiteReducer = (sitesGroup: any) => ({ type: SET_GROUP_SITE.reducer, sitesGroup });

/* Update Site */
export const UPDATE_SITE = createRequestActionTypes('SITE_UPDATE_SITE');

export const updateSite = (id: number, site: Site, meta: FieldMetaGroup) => ({ type: UPDATE_SITE.start, id, site, meta });
export const updateSiteSuccess = (site: Site) => ({ type: UPDATE_SITE.success, site });
export const updateSiteError = (error: object) => ({ type: UPDATE_SITE.error, error });

// /* Update Logo */
export const UPDATE_SITE_IMAGE = createRequestActionTypes('SITE_UPDATE_SITE_IMAGE');

// export const updateSiteImage = (panelId: string, site: Site, siteImage: string) => ({ type: UPDATE_SITE_IMAGE.start, panelId, site, siteImage });
export const updateSiteImageSuccess = (site: Site) => ({ type: UPDATE_SITE_IMAGE.success, site });
export const updateSiteImageError = (error: object) => ({ type: UPDATE_SITE_IMAGE.error, error });

/* Delete Site */
export const DELETE_SITE = createRequestActionTypes('SITE_DELETE_SITE');

export const deleteSite = (site: Site, panelId: string) => ({ type: DELETE_SITE.start, site, panelId });
export const deleteSiteSuccess = (siteId: number, panelId: string) => ({ type: DELETE_SITE.success, siteId, panelId });
export const deleteSiteError = (error: object) => ({ type: DELETE_SITE.error, error });
export const deleteSiteReducer = (siteId: number) => ({ type: DELETE_SITE.reducer, siteId });

export const SET_SITE = createRequestActionTypes('SET_SITE');
export const setSiteSuccess = (site: Site, meta: FieldMetaGroup, options: any, permissions?: APIPrivileges, insert?: boolean, panelId?: string, recordId?: string | number) =>
    ({ type: SET_SITE.success, site, meta, options, permissions, insert, panelId, recordId });
export const setSiteReducer = (site: Site) => ({ type: SET_SITE.reducer, site });

export const SET_SITE_CONTRACTS = createRequestActionTypes('SET_SITE_CONTRACTS');
export const setSiteContractsReducer = (contracts: number[], siteId: number) => ({ type: SET_SITE_CONTRACTS.reducer, contracts, siteId });

/* Favourite Site */
export const FAVOURITE_SITE = createRequestActionTypes('SITE_FAVOURITE_SITE');

export const favouriteSite = (siteId: number, favourited: boolean) => ({ type: FAVOURITE_SITE.start, siteId, favourited });
export const favouriteSiteSuccess = (siteId: number, favourited: boolean) => ({ type: FAVOURITE_SITE.success, siteId, favourited });
export const favouriteSiteError = (error: object) => ({ type: FAVOURITE_SITE.error, error });