import React, { useState, useCallback } from 'react';

// Own
import RightVisibilityMenu from 'components/Common/Components/RightVisibilityMenu/RightVisibilityMenu';
import RightContextButton from 'components/Common/Components/RightVisibilityMenu/Components/RightContextButton';
import { PDFGeneration, DownloadRequestRemote } from 'components/ContractInFocus/HoC/WithPageContext/Types/Download.types';
import { useDispatch, useSelector } from 'react-redux';
import { downloadSelector, projectConfigSelector } from 'components/ContractInFocus/HoC/WithPageContext/Selectors/Download.selectors';
//import { MainVisibilityPortfolioSettings } from 'components/PortfolioInFocus/Actions/portfolioVisibilitySettings.actions';
import { MainInFocusVisibilitySettings } from "components/ContractInFocus/interfaces/ContractInFocus.interfaces"

import { togglePersonalInFocusSettings } from 'components/ContractInFocus/Actions/contractVisibilitySettings.actions';
import { RightVisibilityMenuT } from 'components/ContractInFocus/interfaces/personalContractSettings.interfaces';
import * as selectors from 'components/PortfolioInFocus/Selectors/portfolioInFocus.selectors';
import * as visibilitySelectors from 'components/ContractInFocus/Selectors/visibility.selectors';
import { useEnablePersonalVisibilitySettings } from 'components/Profile/Helpers/profileHelpers';
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { GetRightVisibilityMenu } from "components/Common/Components/RightVisibilityMenu/Interfaces/RightContext.interfaces";
import { reportVisibilityModalOpenSelector } from "store/selectors/settings.selector";
import { toggleSetting, changeSetting } from "store/actions/actions";

import { simpleFetchDistribution, simpleFetchMMRAppendices } from "components/AdminPanel/Contracts/Services/contractService";
import { forkJoin } from 'rxjs';
import { downloadRequestRemote } from 'components/ContractInFocus/HoC/WithPageContext/Actions/Download.actions';
export interface WithPageContextProps {
    forceHidePersonalVisibilitySettingsMenu?: boolean;
    visibilitySettings: MainInFocusVisibilitySettings;
    className?: string;
    animate?: boolean;
    portfolio: HydratedPortfolio; //ContractInterface;
    frozenFor: string;
    contractReportContext?: boolean;
    [idx: string]: any;
}

interface WithPortfolioPageContextConfig {
    visibilityMenu?: (settings: GetRightVisibilityMenu) => RightVisibilityMenuT;
    customVisibilityMenu?: boolean;
    showRefreshButton?: boolean;
}

// NB Note that on the ContractReport page, this will be called many times - once for each 'subpage' contained
const withPageContext = (ReportComponent: any, configurations?: WithPortfolioPageContextConfig) => {
    const WithPageContext: React.FunctionComponent<WithPageContextProps> = ({
        portfolio,
        className,
        forceHidePersonalVisibilitySettingsMenu = false,
        animate = true,
        visibilitySettings,
        contractReportContext,
        frozenFor,
        ...props
    }) => {
        const selectedDownloadSelector = useSelector(downloadSelector);
        const selectedPersonalPortfolioSettings = useSelector(visibilitySelectors.personalInFocusVisibilitySettingsSelector);
        const selectedVisibilitySettings = useSelector(visibilitySelectors.mainClientVisibilitySettingsObjectsSelector);
        const selectFrozenFor = useSelector(selectors.portfolioInFocusFrozenForSelector);
        const enablePersonalVisibilitySettings = useEnablePersonalVisibilitySettings();
        const projectConfig = useSelector(projectConfigSelector);
        const reportVisibilityModalOpen = useSelector(reportVisibilityModalOpenSelector);

        const dispatch = useDispatch();

        const defaultToggleVisibilityControls = (updateKey: string): void => {
            //default because if a custom function is provided in the visibility settings, it will run that instead (VisibilityControl takes care of that)
            dispatch(togglePersonalInFocusSettings(updateKey, portfolio));
        }

        const isDownloadRequested = (): boolean => selectedDownloadSelector.status === PDFGeneration.Requested;

        const isDownloadReady = (): boolean => selectedDownloadSelector.status === PDFGeneration.Ready;


        const onGeneratPDF = useCallback(
            (downloadPayload: DownloadRequestRemote): void => {
                downloadPayload.jaguar_doc_logo_with_text = projectConfig.jaguar_doc_logo_with_text;
                downloadPayload.maintenance_report_cover_fallback = projectConfig.maintenance_report_cover_fallback;
                if (portfolio) {
                    downloadPayload.portfolio = portfolio
                    forkJoin({
                        distribution: simpleFetchDistribution({ portfolioId: portfolio.id }),
                        appendices: simpleFetchMMRAppendices({ portfolioId: portfolio?.id, snapshotId: downloadPayload.snapshot.id })
                    }).subscribe(response => {
                        downloadPayload.distribution = response.distribution.data;
                        downloadPayload.appendices = response.appendices.data;
                        dispatch(downloadRequestRemote(downloadPayload));
                    })
                }
            }, [dispatch, projectConfig.jaguar_doc_logo_with_text, projectConfig.maintenance_report_cover_fallback, portfolio]
        );

        return (
            <>
                {enablePersonalVisibilitySettings && (configurations?.visibilityMenu) && !forceHidePersonalVisibilitySettingsMenu &&
                    //HIDE THIS FOR NON JAGUAR STAFF
                    <RightVisibilityMenu
                        //uses configuration fed into withPageContext - used for individual interface pages
                        visibilitySettings={configurations.visibilityMenu({ visibilitySettings: selectedPersonalPortfolioSettings, portfolio, inReportContext: contractReportContext, frozenFor: selectFrozenFor })}
                        defaultOnClick={defaultToggleVisibilityControls}
                        appliedTo={portfolio}
                    />
                }

                {(configurations?.customVisibilityMenu) && !reportVisibilityModalOpen &&
                    <RightContextButton
                        // Just sets a button that will alter a boolean which a particular 'ReportComponent' (i.e. any component passed to this HOC wrapper) 
                        // can use to know when to show it's own custom context menu in place of the RightVisibilityMenu.
                        // Right now the ContractReport component does this - delegating to ContractReportVisibility, which uses a custom Dialogue rather than 
                        // RightVisibilityMenu.  The custom dialogue effectively sets some state which 'switches' to the appropriate visibility menu (the visibility menus
                        // are first obtained in ContractReportVisibility through useGetContractReportingMenu()) and then 'feeds' it the contractVisibilitySettings, 
                        // just as the RightVisibilityMenu component above feeds the personal visibilitySettings to the same 'menu' passed in to HOC as config. 
                        // The ContractReportVisibility Dialogue renders the 
                        // VisibilityControls component - and the RightVisibilityMenu component above does the same - so at that point the ways have 'merged' ).
                        // The RightVisibilityMenu therefore is analagous to the ContractReportVisibility Dialogue component.
                        // visibilitySettings are a dictionary of values defining the parameters to update on the backend (for 'fixed' sections defined on the server
                        // in PersonalContractSettings or MonthlyMaintenanceReportConfiguration) and the current values and it is generated by passing the personal
                        // or contract visibility settings to the menu 'template' - defined somewhere like logsRightVisibilityMenu.ts
                        showSettings={true}
                        onShowSettings={() => dispatch(changeSetting("reportVisibilityModalOpen", true))}
                    />
                }

                <div className={className}>
                    <ReportComponent
                        {...props}
                        animate={animate}
                        className={className}
                        frozenFor={selectFrozenFor}
                        // passing forceHidePersonalVisibilitySettingsMenu and enablePersonalVisibilitySettings give report components and opportunity to formulate their own logic
                        // where it's not enough to simply swap the contract and personal visibility settings (which happens in the visibilitySettings argument immediately below) 
                        // - e.g. the meterreadings where it doesn't rely on the visibility settings dict alone but also incorporates some custom state holding lists of ids
                        // for personal view settings
                        forceHidePersonalVisibilitySettingsMenu={forceHidePersonalVisibilitySettingsMenu}
                        enablePersonalVisibilitySettings={enablePersonalVisibilitySettings}
                        contractReportContext={contractReportContext}
                        // FOR THIS NEXT BLOCK WE COULD PROBABLY ALLOW EACH COMPONENT TO IMPLEMENT - TURNING THE TERNARY INTO A SELECTOR IF APPROPRIATE - THE END RESULT 
                        // IS THAT THIS withPageContext component wouldn't rely on the selectedVisibilitySettings and each child could just listen to the appropriate changes
                        // with a custom selector - which might help prevent refresh of all pages when updating contract report visibility settings

                        // In the visibilitySettings below we use the contract visibility settings if they're not a user meant to adjust their own settings,
                        // then fallback to any explicity passed ones - the major use case being the ContractReport.tsx page, where even if we have access
                        // to our own personal settings, we want to use the 'contract' ones.  Finally we fall back to any personal settings.

                        visibilitySettings={!enablePersonalVisibilitySettings ? selectedVisibilitySettings.data : visibilitySettings ? visibilitySettings : selectedPersonalPortfolioSettings}
                        onGeneratPDF={onGeneratPDF}
                        pdfStatus={selectedDownloadSelector.status}
                        isDownloadReady={isDownloadReady()}
                        isDownloadRequested={isDownloadRequested()}
                        documentBase64={selectedDownloadSelector.pdfBase64}
                        portfolio={portfolio}
                    />
                </div>
            </>
        )
    }
    WithPageContext.displayName = `WithPageContext(HoC)(${getDisplayName(ReportComponent)})`;
    return WithPageContext;
}

const getDisplayName = (WrappedComponent: any) => {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withPageContext;
