/* eslint-disable */
import React from 'react';
import IncidentFormManagementWrapper, {
    PreManagementWrapperIncidentFormProps,
    GetIncidentFormLayoutProps,
    PrepareBasicHASIncidentFormConfigProps
} from "components/ContractInFocus/HAS/IncidentFormManagementWrapper";
import { simpleFetchNearMissFieldInfoList } from "components/AdminPanel/HAS/HASIncidentServices";
import {
    getInitialInfoFieldsConfig,
    useGetInitialInfoNearMissFormLayout,
    getNearMissInvestigationFieldsConfig,
    useGetNearMissInvestigationFormLayout
} from "components/ContractInFocus/HAS/HASNearMissDetailModels";
import HASIncidentActions from "components/ContractInFocus/HAS/HASIncidentActions";
import { getNearMissesRoute } from 'components/AdminPanel/HAS/HASIncidentServices';

import "components/Common/Components/GeneralActionForm/GeneralActionFormStyles.scss"
import "components/ContractInFocus/HAS/Styles/forms.scss"
import { IHASNearMiss } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";

export const prepareBasicFieldConfigs = ({
    HASAdminAccess,
    formType
}
    : PrepareBasicHASIncidentFormConfigProps) => {
    const baseFieldsConfig = formType == "initial_data" ? getInitialInfoFieldsConfig({ HASAdminAccess: HASAdminAccess }) : getNearMissInvestigationFieldsConfig({ HASAdminAccess: HASAdminAccess });
    return baseFieldsConfig;
}

const UseGetFormLayoutForNearMissInitialInfo = ({
    HASAdminAccess,
    simpleLayout,
    thisRecord,
    formType,
    printable
}
    : GetIncidentFormLayoutProps) => {
    const RenderImmediateNearMissActions = () => {
        return <HASIncidentActions
            incident={thisRecord as IHASNearMiss}
            incidentType="near_miss"
            category='immediate'
            title="Immediate Actions"
            printable={printable}
        />
    }
    const RenderFurtherNearMissActions = () => {
        return <HASIncidentActions
            incident={thisRecord as IHASNearMiss}
            incidentType="near_miss"
            category='further'
            title="Futher Actions"
            printable={printable}
        />
    }
    if (simpleLayout) { return undefined } else {
        const thisLayout = formType == "initial_data" ? useGetInitialInfoNearMissFormLayout : useGetNearMissInvestigationFormLayout
        const layout = thisLayout({
            HASAdminAccess: HASAdminAccess,
            RenderImmediateNearMissActions: RenderImmediateNearMissActions,
            RenderFurtherNearMissActions: RenderFurtherNearMissActions,
        })
        return layout;
    };
}

interface HASNearMissFormProps extends PreManagementWrapperIncidentFormProps {
    formType: string;
    setFullScreenForm?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

function HASNearMissForms({
    formType,
    ...props
}: HASNearMissFormProps) {
    return <IncidentFormManagementWrapper
        {...props}
        prepareBasicFieldConfigs={prepareBasicFieldConfigs}
        getFormLayout={UseGetFormLayoutForNearMissInitialInfo}
        gridClass={`${formType == "initial_data" ? "initialNearMissInfo" : "nearMissFull"} HASIncidentDetailForm fieldGroupsForm darker-disabled`}
        simpleFetchFieldInfoList={simpleFetchNearMissFieldInfoList}
        recordType="near_miss"
        getIncidentRoute={getNearMissesRoute}
        formType={formType}
    //formSettingsControls={HASNearMissFormSettingControls}
    />
}

export default React.memo(HASNearMissForms);