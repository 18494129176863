import React from 'react';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { openControlsStyles } from './rightContextButtonStyles';
import { MatIconButton } from 'components/Common/Components/Material/MatIconButton/MatIconButton';
import { AppendixManagementButtonWrapper } from "components/Common/Components/RightVisibilityMenu/Components/RightContextButton/rightContextButtonStyles";


interface ReportAppendixButtonProps {
    showSettings: boolean;
    onShowSettings?: () => void;
}

export default ({
    showSettings,
    onShowSettings
}: ReportAppendixButtonProps) => {

    return (
        <AppendixManagementButtonWrapper
            className={`animated ${showSettings ? 'fadeIn' : 'fadeOutUpShort'}`}
            hidden={!showSettings}
        >
            <MatIconButton onClick={onShowSettings}>
                <AttachmentIcon style={openControlsStyles} />
            </MatIconButton>
        </AppendixManagementButtonWrapper>
    );
}


