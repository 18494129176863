import styled from "styled-components";
import styles from "../../../../styles/app/common/variables.scss";
import { hideOverflowText } from "../../../../styles/app/common/text";

export const StyledSiteRow = styled.div`
  display: flex;
  cursor: pointer;

  em {
    font-size: 1rem;
    line-height: 1.2rem;
    margin-right: 0.5rem;
  }

  span {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }

  .not-in-contract  {
    color: ${styles.red};
  }

  &.inactive {
    opacity: 0.5;
  }

  .fa-briefcase {
    display: ${({ width }: { width: number }) =>
    width > 180 ? `inline` : `none`};
  }

  .contract-no {
    min-width: 73px;
    max-width: 73px;
    text-align: center;

    display: ${({ width }: { width: number }) =>
    width > 200 ? `block` : `none`};
    ${hideOverflowText}
  }

  .contract-with {
    width: ${({ width }: { width: number }) => `${width - 5}px`};
    ${hideOverflowText}
  }

  .doc-count {
    min-width: 58px;
    width: 88px;
    text-align: center;

    ${({ width }: { width: number }) =>
    width < 180
      ? `
      width: 60px;
      min-width: 50px;
    `
      : null};

    .fa-file-alt {
      display: ${({ width }: { width: number }) =>
    width > 180 ? `inline` : `none`};
    }

    ${hideOverflowText}
  }
`;

export const StyledSiteCell = styled.div`
  background-color: ${styles.blueLight};
  color: ${styles.blueDark};
  padding: 0.3rem 0.5rem;
  margin: 0 0 0.3rem 0;

  :not(:last-child) {
    margin-right: 0.5rem;
  }

  border-radius: 3px;
  font-size: 0.75rem;
`;
