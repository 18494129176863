import { ContractInterface } from 'components/AdminPanel/Contracts/Interfaces/Contract.interface';
import { LookupEntity, FieldMetaGroup } from 'components/Common/Interfaces/Entity.interface';

//HT Note: what would go in Contracts/Epics/ContractsHelpers.epic.ts and what would go here?

function sortSiteDisplayNamesBySiteRef(siteA: any, siteB: any) {
  let siteARef = Number(siteA.display_name.split(' ')[0]);
  let siteBRef = Number(siteB.display_name.split(' ')[0]);
  return siteARef - siteBRef;
}

export const transformContractDataForGrid = (data: ContractInterface) => {
  return {
    ...data,
    site_name: data.site?.name,
    contract_with_name: data.contract_with?.name,
    contract_with_id: data.contract_with?.id,
    account_manager_id: data.account_manager?.id,
    business_unit_head_id: data.business_unit_head?.id,
    client_contact_id: data.client_contact?.id,
    site_manager_id: data.site_manager?.id,
    site_id: data.site.id
  }
}

export const transformContractMetaForGrid = (meta: FieldMetaGroup, contractWithLookup: LookupEntity[], peopleLookup: LookupEntity[], siteLookup: LookupEntity[]) => {
  const filterPersonLookup = (items: any[], type: string) => items.filter((person: any) => person[type])
  // const filterPersonLookup = (items: any, type: any) => Object.keys(items).filter((key: any) => items[key][type])
  if (meta) { // HT Note: needed to wrap this in this check after deleting a contract - should check it out why this is being called (after the contract has gone)
    return {
      ...meta,
      contract_with_id: {
        ...meta.contract_with,
        type: 'choice',
        label: 'Contract With',
        field_name: 'contract_with_id',
        choices: contractWithLookup,
      },
      account_manager_id: {
        ...meta.account_manager,
        type: 'choice',
        label: 'Account Manager',
        field_name: 'account_manager_id',
        choices: filterPersonLookup(peopleLookup, 'account_manager_eligible')
      },
      business_unit_head_id: {
        ...meta.business_unit_head,
        type: 'choice',
        label: 'Business Unit Head',
        field_name: 'business_unit_head_id',
        choices: filterPersonLookup(peopleLookup, 'business_unit_head_eligible')
      },
      client_contact_id: {
        ...meta.client_contact,
        type: 'choice',
        label: 'Client Contact',
        field_name: 'client_contact_id',
        choices: filterPersonLookup(peopleLookup, 'client_contact_eligible')
      },
      site_manager_id: {
        ...meta.site_manager,
        type: 'choice',
        label: 'Site Manager',
        field_name: 'site_manager_id',
        choices: filterPersonLookup(peopleLookup, 'site_manager_eligible')
      },
      site_id: {
        ...meta.site,
        type: 'choice',
        label: 'Site',
        field_name: 'site_id',
        choices: siteLookup.sort(sortSiteDisplayNamesBySiteRef)
      }
    }
  }
  return meta
}

export const transformContractForUpdate = (data: Partial<ContractInterface>) => {
  return {
    ...data,
    contract_with: data.contract_with_id,
    contract_with_id: undefined,
    account_manager: data.account_manager_id,
    account_manager_id: undefined,
    business_unit_head: data.business_unit_head_id,
    business_unit_head_id: undefined,
    client_contact: data.client_contact_id,
    client_contact_id: undefined,
    site_manager: data.site_manager_id,
    site_manager_id: undefined,
    site: data.site_id,
    site_id: undefined
  }
}