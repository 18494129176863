/* eslint-disable */
import React, { useState, useRef, useReducer, useEffect, useCallback } from 'react';
import { useSelector } from "react-redux";
import DataSource from "devextreme/data/data_source";
import { IntersectionOptions } from 'react-intersection-observer';

import { InViewProgressTracker } from "components/Common/Components/InViewWrapper/InViewWrapper";
import HASIncidentForm from "components/ContractInFocus/HAS/HASIncidentForm";
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { FieldMetaGroup, FieldGroup, APIResult } from 'components/Common/Interfaces/Entity.interface';
import { newGetPayloadFromRef } from 'services/API/API.helper';
import { APIPrivileges } from "services/Interface/Interface";
import { IHASAccident, IHASNearMiss, IncidentFieldType, IHASRiddor } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";
import { commonHandleSave } from "components/ContractInFocus/Services/commonAPI.services";
import { CreateFieldInfo, MentionablePerson } from "components/Common/Components/FormFieldComments/FormFieldComments";
import { healthAndSafetyAdminAccessSelector } from 'components/Profile/Selectors/Profile.selector';
import HASFieldInfo from "components/ContractInFocus/HAS/HASFieldInfo";
import { FieldInfoManagementProps, filterIncidentFieldConfigs } from "components/ContractInFocus/HAS/HASIncidentForm";
import { processIncidentFieldsForForm } from "components/ContractInFocus/HAS/HASIncidentForm";
import { ParseAPIResponseWithPUTMeta } from "services/Interface/Interface";

import "components/Common/Components/GeneralActionForm/GeneralActionFormStyles.scss"
import "components/ContractInFocus/HAS/Styles/forms.scss"

export interface PreManagementWrapperIncidentFormProps {
    data: IHASAccident | IHASNearMiss | IHASRiddor;
    rowKey: string | number;
    meta?: FieldMetaGroup;
    permissions?: APIPrivileges;
    masterViewSharedSpace: React.MutableRefObject<any>;
    signalSave: any;
    signalCancel: any;
    formSettingsInFocus: boolean;
    handleFormSettingsClose: () => void;
    signalClearFieldInfoInFocus: any;
    formValuesRef: React.MutableRefObject<any>;
    formType?: string;
    setFullScreenForm?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    disableDxNavKeys?: React.MutableRefObject<boolean>;
    initiallySelectedDataField?: React.MutableRefObject<string | undefined>;
    containing_tab?: string;
    incidentMentionablePersons?: React.MutableRefObject<MentionablePerson[] | undefined>;
    initiallySelectedComment?: React.MutableRefObject<string | undefined>;
    updateRowValue?: (newValues: any) => void;
    showReadOnly?: boolean;
    incidentFormSharedSpace: any;
    FormSettingsComponent: () => JSX.Element;
    onSaveCallBack?: (id: string | number, response: APIResult<any>) => void;
    gridDataSource?: DataSource | undefined;
}

export interface PrepareBasicHASIncidentFormConfigProps {
    thisRecord: IHASAccident | IHASNearMiss | IHASRiddor;
    setThisRecord: React.Dispatch<any>;
    dispatchRefreshContext: React.DispatchWithoutAction;
    formValuesRef: React.MutableRefObject<any>;
    HASAdminAccess: boolean;
    formType?: string;
}

export interface GetIncidentFormLayoutProps {
    thisRecord: IHASAccident | IHASNearMiss | IHASRiddor;
    HASAdminAccess: boolean;
    simpleLayout: boolean;
    formType?: string;
    printable: boolean;
}

export interface IncidentFormManagementWrapperProps extends PreManagementWrapperIncidentFormProps {
    prepareBasicFieldConfigs: ({
        thisRecord,
        setThisRecord,
        dispatchRefreshContext,
        formValuesRef,
        HASAdminAccess,
    }: PrepareBasicHASIncidentFormConfigProps
    ) => FieldsFormConfig;
    getFormLayout: ({ thisRecord, HASAdminAccess, simpleLayout, formType }: GetIncidentFormLayoutProps) => any;
    gridClass: string;
    simpleFetchFieldInfoList: (parentId: string) => Promise<ParseAPIResponseWithPUTMeta<any>>;
    recordType: IncidentFieldType;
    getIncidentRoute: (id?: string | number) => string;
}

export type FieldConfigsListType = "unfiltered" | "unresolved" | "redacted";


function IncidentFormManagementWrapper({
    data,
    meta,
    permissions,
    masterViewSharedSpace,
    rowKey,
    signalSave,
    signalCancel,
    formSettingsInFocus,
    handleFormSettingsClose,
    signalClearFieldInfoInFocus,
    formValuesRef,
    prepareBasicFieldConfigs,
    getFormLayout,
    gridClass,
    formType,
    simpleFetchFieldInfoList,
    recordType,
    getIncidentRoute,
    setFullScreenForm,
    disableDxNavKeys,
    initiallySelectedDataField,
    containing_tab,
    incidentMentionablePersons,
    initiallySelectedComment,
    updateRowValue,
    showReadOnly,
    incidentFormSharedSpace,
    FormSettingsComponent,
    onSaveCallBack
}: IncidentFormManagementWrapperProps) {



    if (!masterViewSharedSpace.current[rowKey]) {
        masterViewSharedSpace.current[rowKey] = {}; //important to ONLY set this to an empty object when not yet defined
    }
    const [fieldInfoInFocus, setFieldInfoInFocus] = useState<CreateFieldInfo>();
    const rowLevelSharedSpace = masterViewSharedSpace.current[rowKey];
    const [currentFieldConfigsListType, setCurrentFieldConfigsListType] = useState<FieldConfigsListType>("unfiltered");

    const HASAdminAccess = useSelector(healthAndSafetyAdminAccessSelector);
    const [printable, setPrintable] = useState(!!incidentFormSharedSpace.printable);
    const [thisRecord, setThisRecord] = useState<any>(data);
    const [putMeta, setPutMeta] = useState(meta);
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 1);
    const [baseFormFieldsConfig, setBaseFormFieldsConfig] = useState<FieldsFormConfig>();
    const [unfilteredFormFieldsConfig, setUnfilteredFormFieldsConfig] = useState<FieldsFormConfig>();
    const [unresolvedFormFieldsConfig, setUnresolvedFormFieldsConfig] = useState<FieldsFormConfig>();
    const [redactedFormFieldsConfig, setRedactedFormFieldsConfig] = useState<FieldsFormConfig>();
    const [formFieldsConfig, setFormFieldsConfig] = useState<FieldsFormConfig>();
    const fieldInfoListRef = useRef<any>();
    const unresolvedFormFieldsCount = useRef<number>();

    // useEffect(() => {
    //     console.log('going to compare: ...', recordType);
    //     if (recordType === "riddor") {
    //         let thisData = data as IHASRiddor;
    //         console.log('record: ', thisRecord.accident__ap_name, ' data: ', thisData?.accident__ap_name);
    //     }
    //     if (!isEqual(thisRecord, data)) {
    //         if (recordType === "riddor") {
    //             console.log('riddor data not equal: ', data);
    //             console.log('going to update...');
    //         }
    //         setThisRecord(data)
    //     }
    // }, [data])

    // SWITCH BETWEEN FILTERED AND UNFILTERED FORMCONFIGS WHEN currentFieldConfigsListType UPDATED

    useEffect(() => {
        if (currentFieldConfigsListType === "unresolved" && formFieldsConfig !== unresolvedFormFieldsConfig) {
            setFormFieldsConfig(unresolvedFormFieldsConfig)
        } else if (currentFieldConfigsListType === "unfiltered" && formFieldsConfig !== unfilteredFormFieldsConfig) {
            setFormFieldsConfig(unfilteredFormFieldsConfig);
        } else if (currentFieldConfigsListType === "redacted" && formFieldsConfig !== redactedFormFieldsConfig) {
            setFormFieldsConfig(redactedFormFieldsConfig);
        }
    }, [unfilteredFormFieldsConfig, unresolvedFormFieldsConfig, redactedFormFieldsConfig, currentFieldConfigsListType]);

    useEffect(() => {
        const lastCount = unresolvedFormFieldsCount.current;
        const thisCount = unresolvedFormFieldsConfig ? Object.keys(unresolvedFormFieldsConfig).length : undefined;
        if (lastCount === undefined || thisCount !== lastCount) {
            unresolvedFormFieldsCount.current = thisCount; // essential to avoid loop;
            if (thisCount === 0 && data.fields_require_attention) {
                updateRowValue && updateRowValue({ fields_require_attention: false })
            } else if (thisCount && thisCount > 0 && data.fields_require_attention === false) {
                updateRowValue && updateRowValue({ fields_require_attention: true })
            }
        }
    }, [unresolvedFormFieldsConfig, unresolvedFormFieldsCount])

    // MAKE FILTERED FIELDCONFIGS TRACK UNFILTERED FIELD CONFIGS
    useEffect(() => {
        if (unfilteredFormFieldsConfig) {
            const theseUnresolvedFormFieldConfigs = filterIncidentFieldConfigs({
                fieldInfoListData: fieldInfoListRef?.current.data,
                formFieldsConfig: unfilteredFormFieldsConfig,
                field: 'requires_resolution'
            })
            setUnresolvedFormFieldsConfig(theseUnresolvedFormFieldConfigs);
            const theseRedactedFormFieldConfigs = filterIncidentFieldConfigs({
                fieldInfoListData: fieldInfoListRef?.current.data,
                formFieldsConfig: unfilteredFormFieldsConfig,
                field: 'field_redacted'
            })
            setRedactedFormFieldsConfig(theseRedactedFormFieldConfigs);
        }
    }, [unfilteredFormFieldsConfig, currentFieldConfigsListType, printable]);


    // GET BASIC FIELD CONFIGS -- PARTICULAR TO THIS SPECIFIC FORM
    useEffect(() => {
        const baseFieldsConfig = prepareBasicFieldConfigs({
            thisRecord,
            setThisRecord,
            dispatchRefreshContext: forceUpdate,
            formValuesRef,
            HASAdminAccess,
        })
        setBaseFormFieldsConfig(baseFieldsConfig)
    }, [])

    // SETUP FUNCTION WHICH CAN BE CALLED TO UPDATE UNFILTERED FIELD FORM CONFIGS
    const getUnfilteredFormFieldsConfig = useCallback(({ fieldInfoListData, fieldInfoListMeta, updateMode }: FieldInfoManagementProps) => {
        const thisFieldsFormConfig = unfilteredFormFieldsConfig || baseFormFieldsConfig;
        if (thisFieldsFormConfig) {
            const thisFormFieldsConfig = processIncidentFieldsForForm({
                fieldInfoListData: fieldInfoListData,
                fieldInfoListMeta: fieldInfoListMeta,
                updateMode: updateMode,
                formFieldsConfig: thisFieldsFormConfig,
                setFieldInfoInFocus: setFieldInfoInFocus,
                handleFormSettingsClose: handleFormSettingsClose,
                incidentId: data.id,
                printable: printable,
                initiallySelectedDataField: initiallySelectedDataField
            });
            // if (unfilteredFormFieldsConfig) {
            //     console.log('ap_name matches?: ', thisFormFieldsConfig['ap_name'] === unfilteredFormFieldsConfig['ap_name'])
            // }
            setUnfilteredFormFieldsConfig(thisFormFieldsConfig);

            return thisFormFieldsConfig;
        }

        return {};
    }, [baseFormFieldsConfig, unfilteredFormFieldsConfig, printable]);

    // WHEN THE BASE FIELD FORM CONFIGS CHANGE, GRAB THE 
    // INCIDENT FIELD INFO FROM THE BE AND GENERATE THE UNFILTERED (PROCESSED) FORM CONFIGS (E.G. WITH POST COMPONENT ATTACHED ETC)
    useEffect(() => {
        // we have to be careful about dependencies here... thisRecord is not a required dependency and may cause a double rerender for example
        // as changes to thisRecord will cause a component rerender anyway
        baseFormFieldsConfig && simpleFetchFieldInfoList(data.id).then((response) => {
            fieldInfoListRef.current = response; // this will also be updated by the HASFieldInfo when changing values.  We use a ref as 
            // changes to the formConfigs drive this component, but the field info should be up to date first, without causing rerenders
            const thisFormFieldsConfig = getUnfilteredFormFieldsConfig({ fieldInfoListData: response.data, fieldInfoListMeta: response.meta });
            setUnfilteredFormFieldsConfig(thisFormFieldsConfig);
        })
    }, [baseFormFieldsConfig, printable])

    const FieldInfoComponent = useCallback(() => <>
        {fieldInfoInFocus && formFieldsConfig && <HASFieldInfo
            {...fieldInfoInFocus}
            {...{
                fieldType: recordType,
                setFormFieldsConfig: setUnfilteredFormFieldsConfig, // changes to unfiltered form configs drive changes to the filtered ones in useEffects above
                getFormFieldsConfig: getUnfilteredFormFieldsConfig,
                formFieldsConfig: formFieldsConfig,
                fieldInfoListRef: fieldInfoListRef,
                disableDxNavKeys: disableDxNavKeys,
                containing_tab: containing_tab,
                incidentMentionablePersons: incidentMentionablePersons,
                initiallySelectedComment: initiallySelectedComment,
            }}
        />}
    </>, [fieldInfoInFocus, setFormFieldsConfig, getUnfilteredFormFieldsConfig, setUnfilteredFormFieldsConfig])

    // NOTE we should probably do a refactor to use methods like 'commonHandleSave' where we can elsewhere
    const handleSave = useCallback((recordId: string) => {
        const payload = newGetPayloadFromRef({ ref: formValuesRef, metaData: meta });
        const thisCallBack = (response: any) => {
            incidentFormSharedSpace.data = formValuesRef.current = response.data;
            setThisRecord(response.data);
            onSaveCallBack && onSaveCallBack(recordId, response);
        }
        recordId && putMeta && commonHandleSave(
            {
                payload,
                recordId,
                putMeta,
                getEndpoint: getIncidentRoute,
                callback: thisCallBack
            }
        )
    }, []);

    const handleCancel = useCallback(() => {
        const record = { ...thisRecord };
        incidentFormSharedSpace.data = false;
        formValuesRef.current = record;
        setThisRecord(record); // this effectively forces a rerender
    }, []);

    const [formLayout, setFormLayout] = useState<FieldGroup[]>();
    useEffect(() => {
        const formLayout = getFormLayout({
            thisRecord,
            HASAdminAccess,
            simpleLayout: currentFieldConfigsListType !== "unfiltered",
            formType,
            printable: printable
        })
        setFormLayout(formLayout);

    }, [thisRecord, currentFieldConfigsListType, thisRecord.full_investigation_mode, printable])

    useEffect(() => {
        signalSave && handleSave(data.id)
    }, [signalSave]);

    useEffect(() => {
        signalCancel && handleCancel()
    }, [signalCancel]);

    useEffect(() => {
        signalClearFieldInfoInFocus && setFieldInfoInFocus(undefined);
    }, [signalClearFieldInfoInFocus])

    let inViewOptions: React.MutableRefObject<IntersectionOptions> | undefined = useRef({
        /* Optional options */
        threshold: 0,
        rootMargin: '0px 0px 200px'
    });

    let inViewProgressTracker: React.MutableRefObject<InViewProgressTracker> | undefined = useRef({});

    inViewOptions = printable ? undefined : inViewOptions;

    return <>
        {formFieldsConfig && <HASIncidentForm
            meta={meta}
            fieldInfoInFocus={fieldInfoInFocus}
            setFieldInfoInFocus={setFieldInfoInFocus}
            currentFieldConfigsListType={currentFieldConfigsListType}
            setCurrentFieldConfigsListType={setCurrentFieldConfigsListType}
            permissions={permissions}
            handleCancel={handleCancel}
            handleSave={handleSave}
            thisRecord={thisRecord}
            formValuesRef={formValuesRef}
            rowLevelSharedSpace={rowLevelSharedSpace}
            refreshSignal={mustRefresh}
            formSharedSpace={incidentFormSharedSpace}
            formFieldsConfig={formFieldsConfig}
            formLayout={formLayout}
            putMeta={putMeta}
            dispatchRefreshContext={forceUpdate}
            formSettingsInFocus={formSettingsInFocus}
            handleFormSettingsClose={handleFormSettingsClose}
            gridClass={gridClass}
            FormSettingsComponent={FormSettingsComponent}
            FieldInfoComponent={FieldInfoComponent}
            setFullScreenForm={setFullScreenForm}
            //fullScreenFormComponent={CallableForm}
            setPrintable={setPrintable}
            printable={printable}
            disableDxNavKeys={disableDxNavKeys}
            initiallySelectedDataField={initiallySelectedDataField}
            showReadOnly={showReadOnly}
            inViewProgressTracker={inViewProgressTracker}
            inViewOptions={inViewOptions}
        />}
    </>
}

export default React.memo(IncidentFormManagementWrapper);