import React, { useState, useEffect, useRef } from 'react';
import { useInView, IntersectionOptions } from 'react-intersection-observer';
import { StyledComponent } from 'styled-components';

import { getInViewReffableClassName } from "components/Common/Components/AutoInput/AutoInput.helper";
import { FieldsFormConfig } from "components/Common/Components/DocumentsGrid/DocumentsGrid.interface";

export interface InViewProgressTracker {
    [idx: number]: boolean;
}

interface InViewWrapperProps {
    WrappedComponent: React.FC | StyledComponent<"div", any, {}, never>;
    inViewOptions: IntersectionOptions;
    i: number;
    override?: boolean; //make sure this is undefined rather than false if you don't want it to have an impact
    placeHolderMinHeight?: string;
    dataField?: string | React.FC<any>;
    fieldConfigs?: FieldsFormConfig;
    inViewProgressTracker?: React.MutableRefObject<InViewProgressTracker>; //useful to preserve the status of whether something has been in view across rerenders of a component
    // or even it's parent
    pause?: boolean;
    triggerMultiple?: boolean; // This inverts the default 'triggerOnce' API of the useInView function - here we presume trigger once
}

const InViewWrapper: React.FC<InViewWrapperProps> = ({
    WrappedComponent,
    inViewOptions,
    i,
    override,
    placeHolderMinHeight,
    dataField,
    fieldConfigs,
    inViewProgressTracker,
    pause,
    triggerMultiple
}) => {
    const fieldConfig = typeof dataField === "string" && fieldConfigs ? fieldConfigs[dataField] : undefined;
    const inputClassName = useRef(dataField && typeof dataField === "string" ? getInViewReffableClassName(dataField, fieldConfig) : undefined);
    const previouslyLoaded = inViewProgressTracker?.current ? !!inViewProgressTracker?.current[i] : false;
    const { ref, inView, entry } = useInView({ ...inViewOptions, triggerOnce: !triggerMultiple, skip: pause });
    const [show, setShow] = useState(override === undefined ? (i === 0 || previouslyLoaded || inView) : override); // the  i===0 speeds things up quite significantly on initial load, though inView should eventually kick in anyway
    useEffect(() => {
        if (!show && inView) {
            if (inViewProgressTracker) { inViewProgressTracker.current[i] = true }
            setShow(inView);
        }
    }, [inView, dataField, inViewProgressTracker, i, show, pause])
    // it's important to give a minHeight to the 'empty' wrappers so that all the divs don't come into view at the exact same time when scrolling
    return <div ref={ref} className={inputClassName.current}>
        {show ? <WrappedComponent></WrappedComponent> : <div style={{ minHeight: placeHolderMinHeight }}></div>}
    </div>
}
//InViewWrapper.whyDidYouRender = true;

export default InViewWrapper;