import React, { useState, useEffect, memo } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';

// Own
import { calculateMonths } from './helpers/monthYearSelector.helper';
import { DateSelectorMap } from './interfaces/monthYearSelector.interfaces';

// Styles
import styles from 'styles/app/common/variables.scss';
import { highlightIconStyle } from './monthYearSelectorStyles';
import './MonthYearSelector.scss';

type MonthYearSelectorProps = {
  month: number | undefined;
  year: number | undefined;
  highlightDates?: DateSelectorMap;
  onSelect: (month: number | undefined, year: number | undefined) => void;
  highlightIcon?: any,
  highlightIconSize?: number,
  startDate: Date;
  endDate: Date;
  activateButton?: (activate: boolean) => void;
}

const MonthYearSelector = ({
  month,
  year,
  highlightDates,
  highlightIcon,
  highlightIconSize,
  onSelect,
  startDate,
  endDate,
  activateButton,
}: MonthYearSelectorProps) => {

  const [monthOpen, setMonthOpen] = useState(false);

  const [yearOpen, setYearOpen] = useState(false);

  const [date, setDate] = useState({ month, year });

  const toggleMonth = () => setMonthOpen(prevState => !prevState);

  const toggleYear = () => setYearOpen(prevState => !prevState);

  const [dateSelectorMap, setDateSelectorMap] = useState<DateSelectorMap>({});

  const hasDateMapping = () => Object.keys(dateSelectorMap).length !== 0;

  const isHighlighted = (year: number | undefined, month: number | undefined): boolean => highlightDates && year && month && highlightDates[year] && Object.keys(highlightDates).length ? !!highlightDates[year][month] : false;

  const HighlightIcon = highlightIcon;

  const showHighlightIcon = (year: number | undefined, month: number | undefined): boolean => HighlightIcon && isHighlighted(year, month);

  useEffect(() => {
    const dateMap = calculateMonths(startDate, endDate);
    setDateSelectorMap(dateMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => activateButton && activateButton(false); // important so the button isn't active if you cancel and re-open
  }, [activateButton]);

  const handleSelectMonth = (month: number | undefined) => {
    month && setDate({ ...date, month })
    month && date.year && onSelect(month, date.year);
    activateButton && activateButton(true);
  };

  const handleSelectYear = (year: number | undefined) => {
    //year && setDate({ ...date, year, month: +Object.keys(dateSelectorMap[year])[0] });
    year && setDate({ ...date, year, month: undefined });
    year && onSelect(undefined, year);
    activateButton && activateButton(false);
  };

  return (
    <div className="month-year-selector" data-testid="month-year-selector">
      {hasDateMapping() && date.year &&
        <Dropdown isOpen={monthOpen} toggle={toggleMonth}>
          <DropdownToggle caret>
            {date.year && date.month ? dateSelectorMap[date.year][date.month] : "Month"}
          </DropdownToggle>
          <DropdownMenu>
            {Object.keys(dateSelectorMap[date.year]).map((month) =>
              <DropdownItem
                data-testid={`${isHighlighted(date.year, +month) ? 'month-year-selector__highlighted' : ''}`}
                style={{ color: isHighlighted(date.year, +month) ? styles.dynamicBlue : 'inhereit' }}
                key={month}
                onClick={() => handleSelectMonth(+month)}>
                <span className="month-year-selector__month-options">{date.year ? dateSelectorMap[date.year][+month] : null} </span>
                {showHighlightIcon(date.year, +month) ? <HighlightIcon style={highlightIconStyle(highlightIconSize)} className="month-year-selector__highlight-icon" /> : null}
              </DropdownItem>
            )}
          </DropdownMenu>
        </Dropdown>
      }

      <Dropdown isOpen={yearOpen} toggle={toggleYear}>
        <DropdownToggle caret>
          {date.year ? date.year : "Year"}
        </DropdownToggle>
        <DropdownMenu>
          {Object.keys(dateSelectorMap).map((year) =>
            <DropdownItem key={year} onClick={() => handleSelectYear(+year)} >{year}</DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default memo(MonthYearSelector);