import {ParsedQuery, parse} from 'query-string';
import {useState} from 'react';

// export interface QueryStringResult {
//   [0]: ParsedQuery;
// }

export default function useQueryString(
  location: any
): ParsedQuery {
  const [state] = useState(parse(location.search));

  // function setQuery(values: object): void {
  //   const newQuery = {
  //     ...state,
  //     ...values
  //   };

  //   setState(newQuery);
  // }

  return state;
}