import { store } from "store/store";
import API, { APIR, } from "services/API/API";
import { CONTRACT_ROUTE } from "services/API/common/globalAPIs";
import { getData, getDataAndMeta, getMeta, unWrapDataAndMeta, unWrapData, unWrapDataAndMetaAndPUTMeta } from "services/API/API.helper";
import { IMeterReadingsStream, ISubmitMeterReadingsStream } from 'components/ContractInFocus/MeterReadings/Interfaces/MeterReadings.interface';
import * as actions from "components/ContractInFocus/MeterReadings/Actions/MeterStreams.actions";
import { ContractInterface } from "components/AdminPanel/Contracts/Interfaces/Contract.interface";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";

// const getContractMeterStreams = async (contract: ContractInterface) => {
//     const response = await API.get(`${CONTRACT_ROUTE}${contract.contract_ref}/meter-streams/`);
//     const info = getDataAndMeta(response);
//     store.dispatch(actions.fetchMeterStreamsForContract(contract.id, info),)
//     return info;
// }

export const getContractMeterStreams = (contract_id: number, contract_ref: string) => {
    const response = APIR.get(`${CONTRACT_ROUTE}${contract_ref}/meter-streams/`).pipe(
        unWrapDataAndMetaAndPUTMeta(),
    ).subscribe((info) => {
        store.dispatch(actions.fetchMeterStreamsForContract(contract_id, info))
        return info
    })
}

const deleteMeterStream = async (contract_ref: string, meterStreamId: string) => {
    const response = await API.delete(`${CONTRACT_ROUTE}${contract_ref}/meter-streams/${meterStreamId}`);
    const info = getDataAndMeta(response);
    return info;
}

const createMeterStream = async (contract_ref: string, payload: ISubmitMeterReadingsStream) => {
    const response = await API.post(`${CONTRACT_ROUTE}${contract_ref}/meter-streams/`, payload);
    const info = getDataAndMeta(response);
    return info;
}

// const updateMeterStream = (contract: ContractInterface, meterStreamId: string | number, payload: Partial<ISubmitMeterReadingsStream>) => {
//     //const payload = removeReadOnlyFields(payload, )
//     const response = APIR.patch(`${CONTRACT_ROUTE}${contract.contract_ref}/meter-streams/${meterStreamId}/`, payload).pipe(
//         unWrapData(),
//     ).subscribe((info) => {
//         console.log('info: ', info);
//         store.dispatch(actions.updateMeterStreamsForContract(contract.id, info))
//         return info
//     })
// }

//const updateMeterStream = (contract: ContractInterface, meterStreamId: string | number, payload: Partial<ISubmitMeterReadingsStream>) => {
const updateMeterStream = (contract: SiteContract, meterStreamId: string | number, payload: Partial<ISubmitMeterReadingsStream>) => {
    //const payload = removeReadOnlyFields(payload, )
    const response = APIR.patch(`${CONTRACT_ROUTE}${contract.contract_ref}/meter-streams/${meterStreamId}/`, payload).pipe(
        unWrapData(),
    ).toPromise().then((info) => {
        console.log('info: ', info);
        store.dispatch(actions.updateMeterStreamsForContract(contract.id, info))
        return info
    })
    return response;
}

export default {
    getContractMeterStreams,
    createMeterStream,
    deleteMeterStream,
    updateMeterStream,
}

