import { FieldMetaGroup, EntityState } from "components/Common/Interfaces/Entity.interface";
import { APIPrivileges } from "services/Interface/Interface";
import { IMeterReadingsStream } from 'components/ContractInFocus/MeterReadings/Interfaces/MeterReadings.interface';
import * as personalHideObjects from "../Actions/personalHiddenObject.actions";
import content from "*.json";


export interface PersonalHiddenObjectsState {
    // these will often just be mappings of keys to itself - the point is for management an object is easier than arrays to easily avoid duplicates etc without
    // risking any oddities that might come with use of sets
    [contentType: string]: any[];
}
interface PersonalHideObjectAction {
    contentType: string;
    payload: any;
    type: string;
}

const initialState = {
}

const personalHiddenObjectsReducer = (state: PersonalHiddenObjectsState = initialState, { contentType, type, payload }: PersonalHideObjectAction) => {
    switch (type) {
        case personalHideObjects.FETCH_OBJECTS_FOR_CONTENT_TYPE.reducer: {
            return {
                ...state,
                [contentType]: payload, //in this case payload should be an array of objects
            }
        }
        case personalHideObjects.ADD_OBJECT_TO_CONTENT_TYPE.reducer: {
            console.log('payload in reducer: ', payload);
            console.log('contentType in reducer: ', contentType);
            return {
                ...state,
                [contentType]: [...state[contentType], payload]
                //in this case payload should be the object you want to add
            }
        }
        case personalHideObjects.REMOVE_OBJECT_FROM_CONTENT_TYPE.reducer: {
            const newContentTypeState = state[contentType].filter((x) => x.id !== payload)
            return {
                ...state,
                [contentType]: newContentTypeState
            }
        }
        default:
            return state;
    }
};

export default personalHiddenObjectsReducer;