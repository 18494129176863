import styled, { keyframes } from "styled-components";
import styles from "styles/app/common/variables.scss";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  70% {
  opacity: 0;
  }
  100% {
  opacity: 1;
  }
`;

export const NoDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 275px);

  animation: ${fadeIn} 2s linear;

  .no-items__icon{
    margin-bottom: 2rem;
    color: ${styles.blueDark};
    opacity: .6;
  }

  .fa-frown {
    font-size: 11rem;
    height: 11rem;
    width: 11rem;
  }

  .no-items__text {
    font-size: 2rem;
    font-weight: 600;
    color: ${styles.blueDark};
  }
`;
