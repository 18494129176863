import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { isEqual } from "lodash";

// Own
import { statutoryTemplateDocumentColumns } from 'components/AdminPanel/HAS/Models/StatutoryTemplateDocColumns';
import { HASTaskWithID, HASContractTask } from "components/AdminPanel/HAS/Interfaces/HASTaskInterfaces";
import DocumentsGrid from "components/Common/Components/DocumentsGrid/DocumentsGrid";
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { PreFlightListInfo } from "components/Common/Interfaces/Entity.interface";
import DocumentsUploader from "components/Common/Components/DocumentsUploader/DocumentUploader";
import { getStatutoryTemplateDocumentRoute, getContractTaskNestedStatutoryTemplateDocumentRoute } from "components/AdminPanel/HAS/HASTaskServices";
import { fetchStatutoryTemplateDocsPreFlightInfo } from "components/AdminPanel/HAS/HASTaskServices";
import { ContractDocumentFilesWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { GeneralDocsGridWrapper } from 'components/SiteDocuments/Components/ContractDocumentsViewGrid/ContractDocumentGridStyles';

interface StatutoryTemplateDocProps {
    canWrite: boolean;
    data: HASTaskWithID | HASContractTask;
    taskIsGlobal?: boolean;
    contractTask?: HASContractTask;
}

const HASStatutoryTemplateDocs = ({ canWrite, data, taskIsGlobal, contractTask }: StatutoryTemplateDocProps) => {

    let id: string;

    if ('task' in data) {
        id = data.task
    } else {
        id = data.id
    }

    let basePath;

    if (contractTask?.id) {
        basePath = getContractTaskNestedStatutoryTemplateDocumentRoute(contractTask.id);
    } else {
        basePath = getStatutoryTemplateDocumentRoute(id);
    }

    const [fetchFilters, setFetchFilters] = useState();
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 0);
    const [preFlightInfo, setPreFlightInfo] = useState<PreFlightListInfo>();

    const getFieldFormConfig = useCallback((): FieldsFormConfig => {
        return {
            'issue_number': {
                forceRequired: true,
                variant: 'outlined',
                min: 0,
            },
            'notes': {
                label: 'Issue notes'
            },
            'notify_account_managers': { labelPlacement: 'start' },
            'notify_site_managers': { labelPlacement: 'start' },
            'notify_business_unit_heads': { labelPlacement: 'start' },
            'notify_all_key_roles': {
                labelPlacement: 'start',
                sideEffect: (values, fieldConfigs, onChangeFormValues, previousValues) => {
                    let changed = false;
                    if (values['notify_all_key_roles'] && (!previousValues || !previousValues['notify_all_key_roles'])) {
                        values['notify_business_unit_heads'] = true;
                        values['notify_site_managers'] = true;
                        values['notify_account_managers'] = true;
                        fieldConfigs['notify_business_unit_heads'].disabled = true;
                        fieldConfigs['notify_site_managers'].disabled = true;
                        fieldConfigs['notify_account_managers'].disabled = true;
                        changed = true;
                    }
                    if (!values['notify_all_key_roles'] && (!previousValues || previousValues['notify_all_key_roles'])) {
                        fieldConfigs['notify_business_unit_heads'].disabled = false;
                        fieldConfigs['notify_site_managers'].disabled = false;
                        fieldConfigs['notify_account_managers'].disabled = false;
                        changed = true;
                    }
                    // NB running (if changed then onChangeFormValues(values)) here would mean the above code would re-evaluate before previousValues would
                    // have a chance to be set, resulting in an infinite loop (max recursion exceeded error.)  If you ever want to run re-evaluation in that 
                    // manner before previousValues is set then you'd have to add conditions above to check that none of the individual values had to be changed.
                    return changed;
                }
            },
            'task': {
                hidden: true,
                defaultValue: id
            },
        }
    }, [id]);

    const fieldsConfig = getFieldFormConfig();

    useEffect(() => {
        fetchStatutoryTemplateDocsPreFlightInfo({ taskId: id, contractTaskId: contractTask?.id }).then((newPreFlightInfo) => {
            if (!isEqual(preFlightInfo, newPreFlightInfo)) {
                setPreFlightInfo(newPreFlightInfo);
            }
        });
    }, [preFlightInfo, id, contractTask?.id]);

    return <GeneralDocsGridWrapper>
        {statutoryTemplateDocumentColumns && <ContractDocumentFilesWrapper height={!preFlightInfo?.canCreate ? 70 : 60}>
            <DocumentsGrid
                listBasePath={basePath}
                hideSearch
                hideFilters
                hideSource
                columnsConfig={statutoryTemplateDocumentColumns}
                fetchFilters={fetchFilters}
                editMode="row"
                refreshSignal={mustRefresh}
                actionColumnsWidth={100}
                updateRecordMetaBeforeEdit
                checkBeforeEditingMsg={taskIsGlobal ? "This is a global task. Are you sure you want to proceed?" : undefined}
                checkBeforeDeletingMsg={taskIsGlobal ? "This is a global task. Are you sure you want to proceed?" : undefined}
            />
        </ContractDocumentFilesWrapper>}

        {preFlightInfo?.canCreate && basePath &&
            <DocumentsUploader
                urlContext={basePath}
                fieldConfigs={fieldsConfig}
                preFlightInfo={preFlightInfo}
                gridId="HASStatutoryDocumentUploader"
                reset={forceUpdate}
                zIndex={1300}
                inAppViewingLocationOverride="admin"
                checkBeforeUploadMsg={taskIsGlobal ? "This is a global task. Are you sure you want to proceed?" : undefined}
            />
        }
    </GeneralDocsGridWrapper>
}

export default HASStatutoryTemplateDocs;