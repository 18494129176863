import { Person } from "../Interfaces/People.interface";

export const getLookup = (person: Person) => ({
  value: person.id,
  display_name: `${person.first_name} ${person.last_name}`,
  account_manager_eligible: person.account_manager_eligible && person.is_active,
  business_unit_head_eligible: person.business_unit_head_eligible && person.is_active,
  client_contact_eligible: person.client_contact_eligible && person.is_active,
  site_manager_eligible: person.site_manager_eligible && person.is_active,
});

export const getNamesAndEmails = (person: Person) => ({
  id: person.email,
  display: `${person.first_name} ${person.last_name}`,
});

