import React, { memo } from 'react';

import { FormGroup, Label, Input, FormText, Row, Col, Form, Dropdown, DropdownItem, DropdownMenu } from 'reactstrap';
import { Profile } from '../../Interfaces/Profile.interface';
import { FieldChangeType } from '../../../AdminPanel/Interfaces/Detail';
import { Dictionary, FieldMeta } from '../../../Common/Interfaces/Entity.interface';

import './ProfileForm.scss';

interface ProfileFormProps {
  data: Profile;
  meta: Dictionary<FieldMeta>;
  showPassword: boolean;
  onChange: { (fieldValue: FieldChangeType): void };
  onPasswordChange: { (fieldValue: FieldChangeType): void };
  onConfirmPasswordChange: { (fieldValue: FieldChangeType): void };
  passwordMatch: () => boolean;
}

const ProfileForm = ({
  data,
  meta,
  showPassword,
  onChange,
  onPasswordChange,
  onConfirmPasswordChange,
  passwordMatch

}: ProfileFormProps) => {

  const initialJobTitleOb = meta.job_title.choices && data.job_title ? meta.job_title.choices.filter(ob => ob.value == data.job_title)[0] : undefined;
  const initialJobTitle = initialJobTitleOb ? initialJobTitleOb.display_name : '';

  const handleChanged = (e: any): void => {
    onChange({ fieldName: e.target.name, value: e.target.value });
  }

  const handlePasswordChanged = (e: any): void => {
    let trimmed = e.target.value.trim();
    trimmed = trimmed.replace(/ +/g, '');
    if (trimmed !== e.target.value) {
      e.target.value = trimmed;
    }
    onPasswordChange({ fieldName: e.target.name, value: e.target.value });
  }

  const handleOnConfirmPasswordChange = (e: any): void => {
    onConfirmPasswordChange({ fieldName: e.target.name, value: e.target.value });
  }

  const handleFocus = (event: any): void => event.target.select();

  const renderPasswordGroup = () => (
    <>
      {/* <FormGroup>
        <Label for="current_password">Current Password</Label>
        <Input
          type="password"
          name="current_password"
          id="current_password"
          required={showPassword}
          placeholder="Enter your current password"
          onChange={(e) => handleChanged(e, 'current_password')}
        />
      </FormGroup> */}
      <Row>
        <Col md={12}>
          <FormText color="muted" className='passwordRules'>
            Your password must not:
            <ul>
              <li>Be less than 8 characters long</li>
              <li>Be entirely numeric</li>
              <li>Be too common</li>
              <li>Contain whitespace</li>
            </ul>
          </FormText>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="new_password">Password</Label>

            <Input
              type="password"
              name="new_password"
              id="new_password"
              required={showPassword}
              onFocus={handleFocus}
              onLoad={handleFocus}
              invalid={!passwordMatch()}
              placeholder="Enter your password"
              onChange={handlePasswordChanged}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="confirm_password">Confirm Password</Label>
            <Input
              type="password"
              name="confirm_password"
              id="confirm_password"
              required={showPassword}
              onFocus={handleFocus}
              invalid={!passwordMatch()}
              placeholder="Confirm your password"
              onChange={handleOnConfirmPasswordChange}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  )

  return (
    <Form style={{ flexGrow: 1 }} id="profileForm">
      <h2>Profile</h2>
      <FormGroup>
        <Label for="email">Email</Label>
        <Input
          type="email"
          name="email"
          id="email"
          readOnly
          onChange={handleChanged}
          value={data.email} />
      </FormGroup>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="first_name">First Name</Label>
            <Input
              type="text"
              name="first_name"
              id="first_name"
              required={meta['first_name'].required}
              placeholder="Enter your first name here"
              onChange={handleChanged}
              value={data.first_name}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="last_name">Last Name</Label>
            <Input
              type="text"
              name="last_name"
              id="last_name"
              required={meta['last_name'].required}
              placeholder="Enter your last name here"
              onChange={handleChanged}
              value={data.last_name}
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label for="job_title">Job Title</Label>

        <Input
          readOnly
          name="job_title"
          id="job_title"
          // onChange={handleChanged}
          // style={{ textTransform: 'capitalize' }}
          value={initialJobTitle}
        />
      </FormGroup>
      <FormGroup>
        <Label for="mobile">Mobile</Label>
        <Input
          type="text"
          name="mobile"
          id="mobile"
          required={meta['mobile'].required}
          placeholder="Enter your mobile number here"
          onChange={handleChanged}
          value={data.mobile}
        />
      </FormGroup>
      {!showPassword
        ? null
        : renderPasswordGroup()
      }
    </Form>
  );
}

export default memo(ProfileForm);