import { createRequestActionTypes } from "components/Common/Utils/ReduxUtil";
import { ContractPeriod, PortfolioPeriod } from "components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface";
import { ContractInterface } from "components/AdminPanel/Contracts/Interfaces/Contract.interface";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { Dictionary, FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";
import { APIPrivileges } from "services/Interface/Interface";


export const FETCH_CONTRACT_PERIOD = createRequestActionTypes("FETCH_CONTRACT_PERIOD");
export const fetchContractPeriodReducer = (contractPeriods: Dictionary<ContractPeriod>, meta?: FieldMetaGroup, permissions?: APIPrivileges) =>
  ({ type: FETCH_CONTRACT_PERIOD.reducer, contractPeriods, meta, permissions });

export const UPDATE_CONTRACT_PERIOD = createRequestActionTypes("UPDATE_CONTRACT_PERIOD");

export const updateContractPeriod = (contractPeriod: ContractPeriod) =>
  ({ type: UPDATE_CONTRACT_PERIOD.start, contractPeriod });
export const updateContractPeriodSuccess = (contractPeriod: ContractPeriod) =>
  ({ type: UPDATE_CONTRACT_PERIOD.success, contractPeriod });
export const updateContractPeriodError = (error: any) =>
  ({ type: UPDATE_CONTRACT_PERIOD.error, error });

export const updateContractPeriodReducer = (contractPeriod: ContractPeriod) =>
  ({ type: UPDATE_CONTRACT_PERIOD.reducer, contractPeriod });

export const CREATE_CONTRACT_PERIOD = createRequestActionTypes("CREATE_CONTRACT_PERIOD");

export const createContractPeriod = (contractPeriod: ContractPeriod) =>
  ({ type: CREATE_CONTRACT_PERIOD.start, contractPeriod });
export const createContractPeriodSuccess = (contractPeriod: ContractPeriod) =>
  ({ type: CREATE_CONTRACT_PERIOD.success, contractPeriod });
export const createContractPeriodError = (error: any) =>
  ({ type: CREATE_CONTRACT_PERIOD.error, error });

export const DELETE_CONTRACT_PERIOD = createRequestActionTypes("DELETE_CONTRACT_PERIOD");

export const deleteContractPeriod = (contract: ContractInterface, contractPeriodId: number) =>
  ({ type: DELETE_CONTRACT_PERIOD.start, contract, contractPeriodId });
export const deleteContractPeriodSuccess = (contractPeriod: ContractPeriod) =>
  ({ type: DELETE_CONTRACT_PERIOD.success, contractPeriod });
export const deleteContractPeriodError = (error: any) =>
  ({ type: DELETE_CONTRACT_PERIOD.error, error });

export const deleteContractPeriodReducer = (id: any) =>
  ({ type: DELETE_CONTRACT_PERIOD.reducer, id });

// portfolio mimics

export const FETCH_PORTFOLIO_PERIOD = createRequestActionTypes("FETCH_PORTFOLIO_PERIOD");
export const fetchPortfolioPeriodReducer = (portfolioPeriods: Dictionary<PortfolioPeriod>, meta?: FieldMetaGroup, permissions?: APIPrivileges) =>
  ({ type: FETCH_PORTFOLIO_PERIOD.reducer, portfolioPeriods, meta, permissions });

export const UPDATE_PORTFOLIO_PERIOD = createRequestActionTypes("UPDATE_PORTFOLIO_PERIOD");

export const updatePortfolioPeriod = (portfolioPeriod: PortfolioPeriod) =>
  ({ type: UPDATE_PORTFOLIO_PERIOD.start, portfolioPeriod });
export const updatePortfolioPeriodSuccess = (portfolioPeriod: PortfolioPeriod) =>
  ({ type: UPDATE_PORTFOLIO_PERIOD.success, portfolioPeriod });
export const updatePortfolioPeriodError = (error: any) =>
  ({ type: UPDATE_PORTFOLIO_PERIOD.error, error });

export const updatePortfolioPeriodReducer = (portfolioPeriod: PortfolioPeriod) =>
  ({ type: UPDATE_PORTFOLIO_PERIOD.reducer, portfolioPeriod });

export const CREATE_PORTFOLIO_PERIOD = createRequestActionTypes("CREATE_PORTFOLIO_PERIOD");

export const createPortfolioPeriod = (portfolioPeriod: PortfolioPeriod) =>
  ({ type: CREATE_PORTFOLIO_PERIOD.start, portfolioPeriod });
export const createPortfolioPeriodSuccess = (portfolioPeriod: PortfolioPeriod) =>
  ({ type: CREATE_PORTFOLIO_PERIOD.success, portfolioPeriod });
export const createPortfolioPeriodError = (error: any) =>
  ({ type: CREATE_PORTFOLIO_PERIOD.error, error });

export const DELETE_PORTFOLIO_PERIOD = createRequestActionTypes("DELETE_PORTFOLIO_PERIOD");

export const deletePortfolioPeriod = (portfolio: HydratedPortfolio, portfolioPeriodId: number) =>
  ({ type: DELETE_PORTFOLIO_PERIOD.start, portfolio, portfolioPeriodId });
export const deletePortfolioPeriodSuccess = (portfolioPeriod: PortfolioPeriod) =>
  ({ type: DELETE_PORTFOLIO_PERIOD.success, portfolioPeriod });
export const deletePortfolioPeriodError = (error: any) =>
  ({ type: DELETE_PORTFOLIO_PERIOD.error, error });

export const deletePortfolioPeriodReducer = (id: any) =>
  ({ type: DELETE_PORTFOLIO_PERIOD.reducer, id });
