import { createRequestActionTypes } from "../../../Common/Utils/ReduxUtil";
import { Organisation } from "../Interfaces/Organisation";
import { APIPrivileges } from "services/Interface/Interface";

/* Fetch All */
export const FETCH_ORGANISATIONS = createRequestActionTypes('FETCH_ORGANISATIONS');

export const fetchOrganisations = () => ({ type: FETCH_ORGANISATIONS.start });
export const fetchOrganisationsSuccess = (organisations: Organisation[]) => ({ type: FETCH_ORGANISATIONS.success, organisations });
export const fetchOrganisationsError = (error: any) => ({ type: FETCH_ORGANISATIONS.error, error });

/* Set Organisation */
export const SET_ORGANISATION_INDUSTRIES = createRequestActionTypes('SET_ORGANISATION_INDUSTRIES');
export const setIndustries = (industries: string[]) => ({ type: SET_ORGANISATION_INDUSTRIES.reducer, industries });

/* Update */
export const UPDATE_ORGANISATION = createRequestActionTypes('UPDATE_ORGANISATION');
export const updateOrganisationReducer = (data: Organisation) => ({ type: UPDATE_ORGANISATION.reducer, data });

/* Delete */
export const DELETE_ORGANISATION = createRequestActionTypes('DELETE_ORGANISATION');
export const deleteOrganisationReducer = (organisationId: any) => ({ type: DELETE_ORGANISATION.reducer, organisationId });

export const SET_ORGANISATION = createRequestActionTypes('SET_ORGANISATION');
// HT Note: whacking created record Id in here but I feel like this could do with a refactor across all major entity types to have a generic set
export const setOrganisationSuccess = (organisation: Partial<Organisation>, meta: any, options: string[] = [], permissions?: APIPrivileges, insert?: boolean, panelId?: string, recordId?: string | number) =>
  ({ type: SET_ORGANISATION.success, organisation, meta, options, permissions, insert, panelId, recordId });

export const setOrganisationReducer = (organisation: Organisation) => ({ type: SET_ORGANISATION.reducer, organisation });

// HT Note: should this be abstracted to SET_PSEUDO_RECORD? It ends up calling createPsuedoRecord, a cross panel helper function, with the state as an argument
export const SET_PSUEDO_ORGANISATION = createRequestActionTypes('SET_PSUEDO_ORGANISATION');
export const setPsuedoOrganisation = () => ({ type: SET_PSUEDO_ORGANISATION.reducer });

export const CLEAR_PSUEDO_ORGANISATION = createRequestActionTypes('CLEAR_PSUEDO_ORGANISATION');
export const clearPsuedoOrganisation = () => ({ type: CLEAR_PSUEDO_ORGANISATION.reducer });

export const SET_GROUP_ORGANISATION = createRequestActionTypes('SET_GROUP_ORGANISATION');
export const setGroupOrganisationReducer = (organisationGroup: any) => ({ type: SET_GROUP_ORGANISATION.reducer, organisationGroup });

export const ADD_ORGANISATION_MEMBER = createRequestActionTypes('ADD_ORGANISATION_MEMBER');
export const addMember = (organisationId: any, personId: any) => ({ type: ADD_ORGANISATION_MEMBER.reducer, organisationId, personId });

export const REMOVE_ORGANISATION_MEMBER = createRequestActionTypes('REMOVE_ORGANISATION_MEMBER');
export const removeMember = (organisationId: any, personId: any) => ({ type: REMOVE_ORGANISATION_MEMBER.reducer, organisationId, personId });
