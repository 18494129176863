import React from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from "universal-cookie";

import { authenticationService } from "./AuthService";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

import { saveRouteBeforeLogout } from "../../Routes.js"

const cookies = new Cookies();

export const PrivateRoute = ({
  component: Component,
  path,
  contract,
  portfolio,
  redirectTo,
  ...props
}: {
  path: string;
  component: any;
  contract?: SiteContract;
  portfolio?: HydratedPortfolio;
  redirectTo?: string;
  [idx: string]: any;
}) => (
  <Route
    render={() => {
      const currentToken = authenticationService.token || cookies.get('token');
      if (!currentToken) {
        saveRouteBeforeLogout();
        // not logged in so redirect to login page with the return url
        return <Redirect to={{ pathname: "/login" }} />;
      } else if (redirectTo) {
        return <Redirect to={{ pathname: redirectTo }} />;
      }
      // authorised so return component
      return portfolio ? <Component portfolio={portfolio} {...props} /> : <Component contract={contract} {...props} />;
    }}
  />
);
