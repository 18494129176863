//import * as contractActions from "components/ContractInFocus/Actions/contractInFocus.actions";
import * as portfolioActions from "components/PortfolioInFocus/Actions/portfolioInFocus.actions";
import { ContractDocsViewerState } from "components/ContractInFocus/Reducers/contractDocsViewer.reducer";

const initialState = {};

// This could have been managed as a single boolean right now, rather than by portfolio, as right now it's a global modal - but might be nice at some point
// to keep track of document viewer state per portfolio.

const portfolioDocsViewerReducer = (state: ContractDocsViewerState = initialState, { type, payload }: { type: string, payload: any }) => {
    switch (type) {

        case portfolioActions.SET_PORTFOLIO_DOCS_DIALOGUE_OPEN.reducer: {
            return {
                ...state,
                [payload.portfolioId]: {
                    open: true
                }
            }
        }
        case portfolioActions.SET_PORTFOLIO_DOCS_DIALOGUE_CLOSE.reducer: {
            return {
                ...state,
                [payload.portfolioId]: {
                    open: false
                }
            }
        }
        case portfolioActions.TOGGLE_PORTFOLIO_DOCS_DIALOGUE_OPEN.reducer: {
            return {
                ...state,
                [payload.portfolioId]: {
                    open: !state[payload.portfolioId].open
                }
            }
        }

        default:
            return state;
    }
};

export default portfolioDocsViewerReducer;
