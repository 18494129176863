import React, { useState, useEffect, useReducer, useRef } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DataGrid, { Column, Paging, SearchPanel } from 'devextreme-react/data-grid';
import Alert from '@material-ui/lab/Alert';
import { Paper } from "@material-ui/core";


// Own
import { updateOrderApi } from "components/ContractInFocus/Services/commonAPI.services";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { PreFlightListInfo } from 'components/Common/Interfaces/Entity.interface';
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import ReportDistributionListGrid from "components/ContractInFocus/ContractReportVisibility/ReportDistributionListGrid";
import { ColumnsConfig } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';
import { maintenanceReportDistributionBaseRoute } from "services/API/common/contractAPIs";
import { simpleFetchSnapshotPublications, publishSnapshot } from "components/ContractInFocus/Services/snapShot.service";
import { Snapshot, SnapshotPublication } from "components/ContractInFocus/Interfaces/ContractInFocus.interfaces";
import PublicationForm from "components/ContractInFocus/ContractReportVisibility/publicationForm";
import GridHeader from "components/Common/Components/GridHeader/GridHeader.js";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

// Styles
import './contractReportVisibility.scss';
import styles from "styles/app/common/variables.scss";
import { APIPrivileges } from "services/Interface/Interface";

type ContractReportDistributionListProps = {
    contract?: SiteContract;
    portfolio?: HydratedPortfolio;
    snapshot?: Snapshot;
    snapshotPublications: SnapshotPublication[];
    setSnapshotPublications: React.Dispatch<React.SetStateAction<SnapshotPublication[] | undefined>>;
    snapshotNeedsToBePublished?: boolean;
    snapshotCouldBePublished?: boolean;
    preFlightInfo: PreFlightListInfo;
    preFlightPublishSnapshotInfo: PreFlightListInfo;
    onModalClose?: () => void;
}

export const DistributionListDialogue: React.FunctionComponent<ContractReportDistributionListProps> = ({
    contract,
    portfolio,
    snapshot,
    preFlightInfo,
    snapshotPublications,
    snapshotNeedsToBePublished,
    snapshotCouldBePublished,
    setSnapshotPublications,
    preFlightPublishSnapshotInfo,
    onModalClose,
}) => {
    const dialogueTitle = `Monthly Maintenance Report Notification Management Console`;
    const [open, setOpen] = React.useState(true);
    //const [snapshotPublications, setSnapshotPublications] = useState<any[]>();
    const [snapshotPublicationPrivileges, setSnapshotPublicationPrivileges] = useState<APIPrivileges>();
    const [wipePublicationForm, setWipePublicationForm] = useState(false);
    const [isReadyToPublish, setIsReadyToPublish] = useState(false);
    const publicationFormValues = useRef({});

    const distributionColumnsConfig: ColumnsConfig = {
        'first_name': {
            'caption': 'First Name',
        },
        'last_name': {
            'caption': 'Last Name',
        },
        'email': {
            'caption': 'Email',
        },
        'organisation': {
            'caption': 'Organisation',
        },
        'subscribed': {
            'caption': 'Currently subscribed to publication notifications for this contract',
            'width': 300
        }
    }
    const publicationFieldsConfig: FieldsFormConfig = {
        'publication_notes': {
            multiline: true,
            forceRequired: snapshotPublications?.length ? true : false
        }
    }
    const listBasePath = maintenanceReportDistributionBaseRoute({ portfolioId: portfolio?.id, contractRef: contract?.contract_ref });
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 0);
    const orderBasePath = listBasePath;


    const handleOnReorder = (e: any, callback: () => void) => {
        updateOrderApi(orderBasePath, e.itemData.id, { order: e.toIndex }).then(() => {
            callback()
        });
    }

    //const orderBasePath = `contracts/${data.contract_ref}/monthly-maintenance-report-distribution/`;
    useEffect(() => {
        snapshot && simpleFetchSnapshotPublications({ contractId: contract?.contract_ref, portfolioId: portfolio?.id, snapshotId: snapshot.id }).then((response: { data: any, meta: any, privileges: any }) => {
            setSnapshotPublications(response.data);
            setSnapshotPublicationPrivileges(response.privileges);
        })
    }, [contract?.contract_ref, portfolio?.id, snapshot, setSnapshotPublications])

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Dialog
            id="reportDistributionListManager"
            open={open}
            onClose={handleClose}
            onExited={onModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
            fullWidth={true}
            disableEnforceFocus
            transitionDuration={{ appear: 0, enter: 1000, exit: 0 }}
        >
            <DialogTitle id="distributionListTitle">{dialogueTitle}</DialogTitle>
            <DialogContent
                style={{ backgroundColor: styles.redLight }}
            >
                {/* {snapshotNeedsToBePublished ? <span>This snapshot has not been published yet</span> : snapshotCouldBePublished ? <span>This snapshot has been revised since it was last published</span> : <></>} */}
                {snapshotNeedsToBePublished ?
                    <Alert>This snapshot has not been published yet</Alert> :
                    snapshotCouldBePublished ?
                        <Alert>This snapshot has been revised since it was last published</Alert>
                        : <></>
                }
                <br />
                <Paper elevation={3}>

                    <div className="reportDistributionList-dialog__content">
                        <GridHeader
                            title={`${portfolio ? "Portfolio" : "Contract"} Report Distribution List`}
                        />
                        {(contract || portfolio) && <ReportDistributionListGrid
                            listBasePath={listBasePath}
                            contract={contract}
                            portfolio={portfolio}
                            columnsConfig={distributionColumnsConfig}
                            signalRefresh={mustRefresh}
                            orderable={true}
                            handleOnReorder={handleOnReorder}
                        />}
                    </div>
                </Paper>
                <br />
                <Paper elevation={3}>
                    <div className="reportDistributionList-dialog__content">
                        <GridHeader
                            title="Snapshot Publications List"
                        />
                        {!!snapshotPublications?.length && <DataGrid
                            className="distributionListContainer" //dx-datagrid-jt"
                            dataSource={snapshotPublications}
                            columnAutoWidth={true}
                            showBorders={true}
                            height="100%"//{canWrite ? 320 : '100%'}
                            rowAlternationEnabled={true}
                        >
                            <Paging enabled={false} />
                            <SearchPanel
                                visible={true}
                                placeholder="Search..."
                                searchVisibleColumnsOnly={true}
                                width={500}
                            />
                            <Column
                                caption="Publication Number"
                                dataField="publication_number"
                                alignment="left"
                                width={150}
                            />
                            <Column
                                caption="Published By"
                                dataField="published_by_string"
                                alignment="left"
                                width={300}
                            />
                            <Column
                                caption="Publication Notes"
                                dataField="publication_notes"
                                alignment="left"
                            />
                            <Column
                                caption="Created At"
                                dataField="created_at"
                                alignment="left"
                                dataType="date"
                                format="d MMM y HH:mm:ss"
                                width={150}
                            />
                        </DataGrid>
                        }
                    </div>

                    {snapshot && snapshotPublicationPrivileges?.POST && <PublicationForm
                        fieldConfigs={publicationFieldsConfig}
                        formValues={publicationFormValues}
                        existingPublications={snapshotPublications}
                        setSnapshotPublications={setSnapshotPublications}
                        contract={contract}
                        portfolio={portfolio}
                        snapshot={snapshot}
                        //@ts-ignore not sure why typescript is being thick here...
                        preFlightInfo={preFlightPublishSnapshotInfo}
                        wipe={wipePublicationForm} />}
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>

    );
}