import styled from "styled-components";
// need to export these variables through scss modules loader
import { grayMedDark, grayDark } from "styles/app/common/variables";

export const StyledFile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 250px;
`;

export const StyledFileActionContainer = styled.div`
  display:flex;
  justify-content: space-around;
  width: 100%;
  padding: 0 10px;
  position: relative;
`;

export const StyledAdminFormImagesContainer = styled.div`
  max-height: 600px;
  overflow: auto;
`


export const StyledFileActionLabel = styled.div`
  margin-top: ${(props: { marginTop?: string }) => props.marginTop || '.5rem'};
  color: ${grayMedDark};
  cursor: pointer;
  font-size: 1rem;
  display: flex;
    align-items: center;
    
  .MuiSvgIcon-root {
    font-size: 1rem;
    margin-right: 2px;
  }
  &:hover {
    color: ${grayDark};
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  min-width: 100%;
  margin-bottom: 2rem;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-direction: column;
  .filepond--hopper {
    height: 200px;
    width: 320px;
  }
  .filepond--root,
  .filepond--root .filepond--drop-label {
    height: 200px;
  }
  .filepond-label, .filepond--label-action{
    font-size: 14px;
    width: 320px;
    white-space: break-spaces;
  }
  img {
    border-radius: 0.25rem;
    padding: .75rem 0;
    width: 100%;
  }
`;

export const StyledEdit = styled.div`
  color: ${grayMedDark};
  cursor: pointer;
  margin-top: 0.6rem;
  em:nth-child(1) {
    margin-right: 0.25rem;
  }
  &:hover {
    color: ${grayDark};
  }
`;

export const StyledPasswordButtonWrapper = styled.div`
  padding-bottom: 1rem;
  button {
    color: ${grayDark};
  }
`;

export const StyledImageWrapper = styled.div`
  flex-grow: 1;

`;
