import { addAdminPanel, removeAdminPanel } from "components/AdminPanel/Actions/adminPanel.actions";
import { panelChangeEvent } from "store/actions/root.actions";
import { store } from "store/store";
import { addInfoNotice } from "components/Notification/helper/notification.helper";
import { DataGrid } from "devextreme-react/data-grid";
import { addAdminPanelInterface, openSelectionPanelInterface } from "../Interfaces/Panel";
import { addSelectionConfiguration } from "store/SelectedEntities/Actions/SelectedEntities.actions";
import { IDHelper } from "components/Common/Utils/Key";

const dragMessage = (panelType: string): string => `Find records and drag them to the ${panelType} sublist`;

export const openPanel = (openAdminPanelConfig: addAdminPanelInterface): void => {
  const { panelId, panelType, expandRecordId, isDetail, selectionConfig } = openAdminPanelConfig;
  store.dispatch(addAdminPanel(
    { panelId, panelType, expandRecordId, isDetail, selectionConfig }
  ));
};

// export const openPanelWithDragMessage = (panelType: string): void => {
//   openPanel(panelType);
//   addInfoNotice(dragMessage(panelType))
// };

export const openPanelInSelectionMode = (openSelectionPanelConfig: openSelectionPanelInterface): void => {
  let { panelId, selectActionIdentifier, masterRecordType, masterRecordData, linkedRecordType, onAddLinks, currentMemberIds, linkPanelTitle } = openSelectionPanelConfig
  panelId = panelId || IDHelper(); // for initializing selection config it suits us to know the new panel id in advance

  const selectionConfig = {
    selectActionIdentifier,
    add: [],
    remove: [],
    linkedRecordType,
    masterRecordType,
    masterRecordId: masterRecordData.id,
    onAddLinks,
    currentMemberIds,
    linkPanelTitle
  }
  store.dispatch(addSelectionConfiguration(panelId, selectionConfig));
  openPanel({ panelId, panelType: linkedRecordType, selectionConfig });
};

export function getVisibleRows(grid: DataGrid) {
  const visibleRowData = grid
    ?.instance?.getVisibleRows()
    .map((record: any) => record.data) || []
  const filteredVisibleRowData = visibleRowData.filter((record: any) => record.id); //filtering necessary as sometimes we have 'grouping' rows (controls for expanding and collapsing groups)
  return filteredVisibleRowData
}

export function getDatagridRowElementById(grid: DataGrid, targetId: string) {
  let row = null;
  const index = getVisibleRows(grid).findIndex((item: any) => item.id.toString() === targetId.toString());
  if (index > -1) {
    row = grid.instance.getRowElement(index);
  }
  return row
}

export function expandDataGridRow(grid: DataGrid, targetId: string) {
  grid.instance.expandRow(targetId);
}

export const apiSuccess = () => false;

export const apiFailure = () => false;

export function closePanel(panelId: string) {
  const panel = store.getState().adminPanel.panels[panelId];
  store.dispatch(panelChangeEvent(panel));
  store.dispatch(removeAdminPanel(panelId));
}