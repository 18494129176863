import React, { useState } from 'react';

import SiteFrontCard from '../SiteFrontCard/SiteFrontCard';
import SiteBackCard from '../SiteBackCard/SiteBackCard';

import { SiteIndex } from '../../Interfaces/Sites.interface';
import { SiteCardModeBack, SiteCardModeFront } from '../../Models/SitesEnum';

interface SiteCardProps {
  site: SiteIndex;
  documentDialogOpen: boolean;
  onSelectSite: (site: SiteIndex) => void;
  onUnselectSite: (site: SiteIndex) => void;
  onFavourite: (site: SiteIndex) => void;
}

const SiteCard = ({
  site,
  documentDialogOpen,
  onSelectSite,
  onUnselectSite,
  onFavourite
}: SiteCardProps) => {
  const [mouseMoveTimer, setMouseMoveTimer] = useState<number>();

  const handleMouseLeave = (e: any) => {
    if (!documentDialogOpen) {
      onUnselectSite({ ...site, mode: SiteCardModeFront });
      clearTimeout(mouseMoveTimer);
    }
  }

  const handleMouseMove = () => {
    clearTimeout(mouseMoveTimer);

    setMouseMoveTimer(
      setTimeout(() => {
        onSelectSite({ ...site, mode: SiteCardModeBack })
      }, 50));
  }

  return (
    <div
      onMouseMove={() => handleMouseMove()}
      onMouseLeave={(e: any) => handleMouseLeave(e)}
      className={site.active ? '' : 'inactive'}
    >
      {site.mode === SiteCardModeBack ? (
        <SiteBackCard
          onFavourite={() => onFavourite(site)}
          site={site}
        />
      ) : (
        <SiteFrontCard
          onFavourite={() => onFavourite(site)}
          site={site}
        />
      )
      }
    </div>
  );
}

export default SiteCard;