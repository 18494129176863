/* eslint-disable */
import React from 'react';
import IncidentFormManagementWrapper, { PreManagementWrapperIncidentFormProps, GetIncidentFormLayoutProps, PrepareBasicHASIncidentFormConfigProps } from "components/ContractInFocus/HAS/IncidentFormManagementWrapper";
import { getInitialInfoFieldsConfig, useGetInitialInfoAccidentFormLayout } from "components/ContractInFocus/HAS/HASAccidentDetailModels";
import { simpleFetchAccidentFieldInfoList } from "components/AdminPanel/HAS/HASIncidentServices";
import { getAccidentRoute } from 'components/AdminPanel/HAS/HASIncidentServices';

import "components/Common/Components/GeneralActionForm/GeneralActionFormStyles.scss"
import "components/ContractInFocus/HAS/Styles/forms.scss"

export const prepareBasicFieldConfigsForAccidentInitialInfo = ({
    HASAdminAccess
}
    : PrepareBasicHASIncidentFormConfigProps) => {
    let baseFieldsConfig = getInitialInfoFieldsConfig({ HASAdminAccess: HASAdminAccess });
    return baseFieldsConfig;
}

const UseGetFormLayoutForAccidentInitialInfo = ({
    HASAdminAccess,
    simpleLayout
}
    : GetIncidentFormLayoutProps) => {
    if (simpleLayout) { return undefined } else {
        const layout = useGetInitialInfoAccidentFormLayout({
            HASAdminAccess: HASAdminAccess
        })
        return layout;
    };
}

function HASAccidentInitialInfo({
    ...props
}: PreManagementWrapperIncidentFormProps) {
    return <IncidentFormManagementWrapper
        {...props}
        prepareBasicFieldConfigs={prepareBasicFieldConfigsForAccidentInitialInfo}
        getFormLayout={UseGetFormLayoutForAccidentInitialInfo}
        gridClass='initialAccidentInfo HASIncidentDetailForm fieldGroupsForm darker-disabled'
        simpleFetchFieldInfoList={simpleFetchAccidentFieldInfoList}
        recordType="accident"
        getIncidentRoute={getAccidentRoute}
    />
}

export default React.memo(HASAccidentInitialInfo);