import { APIPrivileges } from "services/Interface/Interface";
import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";
import { PersonalHiddenObjectI } from 'components/ContractInFocus/Interfaces/ContractInFocus.interfaces';
import { createRequestActionTypes } from 'components/Common/Utils/ReduxUtil';

interface fetchPersonalHiddenObjectsI {
    data: PersonalHiddenObjectI[],
    meta: FieldMetaGroup,
    putMeta?: FieldMetaGroup,
    permissions?: APIPrivileges
}

interface updatePersonalHiddenObject {
    data: PersonalHiddenObjectI
}

export const FETCH_OBJECTS_FOR_CONTENT_TYPE = createRequestActionTypes("FETCH_OBJECTS_FOR_CONTENT_TYPE");
export const fetchObjectsForContentType = (contentType: string, payload: fetchPersonalHiddenObjectsI) =>
    ({ type: FETCH_OBJECTS_FOR_CONTENT_TYPE.reducer, contentType: contentType, payload: payload });

export const ADD_OBJECT_TO_CONTENT_TYPE = createRequestActionTypes("ADD_OBJECT_TO_CONTENT_TYPE");
export const addPersonalHiddenObject = (contentType: string, payload: updatePersonalHiddenObject) =>
    ({ type: ADD_OBJECT_TO_CONTENT_TYPE.reducer, contentType: contentType, payload: payload });

export const REMOVE_OBJECT_FROM_CONTENT_TYPE = createRequestActionTypes("REMOVE_OBJECT_FROM_CONTENT_TYPE");
export const removePersonalHiddenObject = (contentType: string, payload: string) =>
    ({ type: REMOVE_OBJECT_FROM_CONTENT_TYPE.reducer, contentType: contentType, payload: payload });