import moment, { Moment } from 'moment';

//Ours
import { periodDate } from "components/Common/Utils/Dates";

export const getIndexFromPannableData = (dataAsArray: any, targetDateString: string, findColBy?: string) => {
    const lookup = findColBy || 'dictKey';
    // console.log('lookup: ', lookup);
    // console.log('targetDateString: ', targetDateString);
    const findElement = (element: any) => element[lookup] === targetDateString
    let i = dataAsArray.findIndex(findElement);
    if (i === -1) {
        const targetDate = moment(targetDateString, 'MMM YYYY');
        //console.log('targetDate: ', targetDate);
        const latestDateString = dataAsArray[dataAsArray.length - 1][lookup]
        //console.log('latestDateString: ', latestDateString);
        const latestDateInDataArray = moment(latestDateString, 'MMM YYYY');
        //console.log('latestDateInDataArray', latestDateInDataArray);
        i = targetDate > latestDateInDataArray ? dataAsArray.length - 1 : 0;
    }
    return i
}

export function getThisMonth(selectedFocusedSnapshot?: any) {
    return periodDate(selectedFocusedSnapshot ? selectedFocusedSnapshot.frozen_for : null);
}

export const calcViewFrom = (viewLength: number, targetIndex: number, offsetTwelthes: number, dataLength: number) => {
    const leftOffset = Math.round(offsetTwelthes / 12 * viewLength) - 1
    // leftOffset is how far from the left (i.e. columns to the right) should the target month appear (as an index, so 0 would be 1 column, 11 would be 12)
    let viewFrom = targetIndex - leftOffset;
    if (viewFrom + viewLength >= dataLength) {
        viewFrom = dataLength - viewLength;
    }
    // console.log('viewLength: ', viewLength);
    // console.log('offsetTwelthes: ', offsetTwelthes);
    // console.log('numberofViewableColumns: ', viewLength);
    // console.log('targetIndex: ', targetIndex);
    // console.log('calcViewFrom: ', viewFrom);
    //console.log('leftOffset: ', leftOffset);
    //  don't have to worry about moving data too far to the left (i.e. so there are gaps to the right) 
    // as that is handled by panable view handler... (as is < 0, but FWIW handled here too)
    return viewFrom <= 0 ? 0 : viewFrom
}

export function getInitialViewFrom(viewLength: number, index: number, offsetTwelthes: number, dataLength: number) {
    return calcViewFrom(viewLength, index, offsetTwelthes, dataLength);
}