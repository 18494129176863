import React, { useState, useRef, useCallback, useEffect, useReducer } from 'react';
import DataSource from "devextreme/data/data_source";
import Button from '@material-ui/core/Button';

import { FieldMetaGroup, Primitive } from 'components/Common/Interfaces/Entity.interface';
import ActionForm from "components/Common/Components/GeneralActionForm/GeneralActionForm";
import { commonHandleSave } from "components/ContractInFocus/Services/commonAPI.services";
import { getAccidentRoute, getNearMissesRoute } from 'components/AdminPanel/HAS/HASIncidentServices';
import { IHASNearMiss, IncidentFieldType, IHASAccident } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";

import "components/Common/Components/GeneralActionForm/GeneralActionFormStyles.scss"
import "components/ContractInFocus/HAS/Styles/forms.scss"
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';

const useButtonLoader = false;

const getAccidentFormSettingsFieldsConfig = (values: IHASAccident) => ({
    full_investigation_mode: {
        label: "Full Investigation"
    },
    confidential: {
        label: "Confidential"
    },
    riddor_required: {},
    status: {
        excludeValues: values.full_investigation_mode ? ['reported', 'signed_off'] : ['investigation_open', 'investigation_closed'],
    }
});

const getNearMissFormSettingsFieldsConfig = (values: IHASNearMiss) => ({
    status: {},
    confidential: {
        label: "Confidential"
    },
});

export type HASFormInfoSettingControlsProps = {
    meta: FieldMetaGroup;
    gridDataSource?: DataSource;
    parentFormRef?: React.MutableRefObject<any>;
    tableLevelSharedSpace: React.MutableRefObject<any>;
    recordType: IncidentFieldType;
}

export interface HASAccidentFormSettingControlsProps extends HASFormInfoSettingControlsProps {
    data: IHASAccident;
}

export interface HASNearMissFormSettingControlsProps extends HASFormInfoSettingControlsProps {
    data: IHASNearMiss;
}

export const HASAccidentFormSettingControls: React.FunctionComponent<HASAccidentFormSettingControlsProps> = ({
    data, meta, tableLevelSharedSpace, gridDataSource, parentFormRef, recordType
}) => {
    const [formChanged, setFormChanged] = useState(false);
    const [recordData, setRecordData] = useState(data);
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 1);
    const [updating, setUpdating] = useState(false);
    const formValues = useRef(data);
    useEffect(() => {
        formValues.current = data
        setRecordData(data);
    }, [data])
    const action = useCallback(() => {
        const payload = {
            riddor_required: formValues.current.riddor_required,
            full_investigation_mode: formValues.current.full_investigation_mode,
            confidential: formValues.current.confidential,
            status: formValues.current.status
        }
        const thisCallBack = (response: any) => {
            //formValues.current.status = response.data.status;
            Object.keys(payload).map((x) => {
                if (parentFormRef) {
                    parentFormRef.current[x] = response.data[x];
                }
            })
            tableLevelSharedSpace.current.allowReload = true;
            // currently reloading because it's fairly fast, alternative would
            // be to use commonUpdateGridRow, but then call a reset of the record info
            // in the form as it should cause a form rerender and we don't want to repaint
            // the entire row and dependcies as for that we may as well reload - we'd still
            // get the entire form container flicker. 
            gridDataSource?.reload();
        }
        commonHandleSave(
            {
                payload: payload,
                recordId: data.id,
                putMeta: meta,
                getEndpoint: getAccidentRoute,
                callback: thisCallBack
            }
        )
    }, [data, meta, gridDataSource, tableLevelSharedSpace, parentFormRef])

    const [thisFormFieldConfig, setThisFormFieldConfig] = useState<FieldsFormConfig>();

    useEffect(() => {
        const ffc = getAccidentFormSettingsFieldsConfig(recordData);
        setThisFormFieldConfig(ffc);
    }, [recordData]);

    // useEffect(() => {
    //     console.log('updating: ', updating);
    // }, [updating]);

    return (
        <>
            <h4 className="title">Form Settings</h4>
            <div className='HASFormControlsWrapper'>
                {
                    recordData && thisFormFieldConfig && <ActionForm
                        formValues={formValues}
                        fieldConfigs={thisFormFieldConfig}
                        //caption="Field Settings..."
                        gridClass='formSettingPropsForm accident'
                        metaForForm={meta}
                        refreshSignal={mustRefresh}
                        setGAFormChanged={setFormChanged}
                    />}
                <Button onClick={action}
                    color="secondary"
                    type="submit"
                    variant="contained"
                    disabled={!formChanged || updating}
                    style={
                        {
                            float: "right",
                            marginTop: "1rem"
                        }
                    }>
                    {updating && useButtonLoader ? <div className='button-loader'>
                        &nbsp;&nbsp;<em className="fas fa-circle-notch fa-spin fa-lg text-muted"></em>
                    </div> : "Update"}
                </Button>
            </div>
        </>

    );
};

export const HASNearMissFormSettingControls: React.FunctionComponent<HASNearMissFormSettingControlsProps> = ({
    data, meta, tableLevelSharedSpace, gridDataSource, parentFormRef, recordType
}) => {
    const [formChanged, setFormChanged] = useState(false);
    const [recordData, setRecordData] = useState(data);
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 1);
    const [updating, setUpdating] = useState(false);
    const formValues = useRef(data);
    useEffect(() => {
        formValues.current = data
        setRecordData(data);
    }, [data])
    const action = useCallback(() => {
        const payload = {
            status: formValues.current.status,
            confidential: formValues.current.confidential,
        }
        const thisCallBack = (response: any) => {
            //setUpdating(false);
            //formValues.current.status = response.data.status;
            Object.keys(payload).map((x) => {
                if (parentFormRef) {
                    parentFormRef.current[x] = response.data[x];
                }
            })
            tableLevelSharedSpace.current.allowReload = true;
            gridDataSource?.reload();

        }
        //setUpdating(true);
        commonHandleSave(
            {
                payload: payload,
                recordId: data.id,
                putMeta: meta,
                getEndpoint: getNearMissesRoute,
                callback: thisCallBack
            }
        )
    }, [data, meta, gridDataSource, tableLevelSharedSpace, parentFormRef])

    const [thisFormFieldConfig, setThisFormFieldConfig] = useState<FieldsFormConfig>();

    useEffect(() => {
        const ffc = getNearMissFormSettingsFieldsConfig(recordData);
        setThisFormFieldConfig(ffc);
    }, [recordData]);

    return (
        <>
            <h4 className="title">Form Settings</h4>
            <div className='HASFormControlsWrapper'>
                {
                    recordData && thisFormFieldConfig && <ActionForm
                        formValues={formValues}
                        fieldConfigs={thisFormFieldConfig}
                        //caption="Field Settings..."
                        gridClass='formSettingPropsForm near-miss'
                        metaForForm={meta}
                        refreshSignal={mustRefresh}
                        setGAFormChanged={setFormChanged}
                    />}
                <Button onClick={action}
                    color="secondary"
                    type="submit"
                    variant="contained"
                    disabled={!formChanged || updating}
                    style={
                        {
                            float: "right",
                            marginTop: "1rem",
                            minWidth: "350px"
                        }
                    }>
                    {updating && useButtonLoader ? <div className='button-loader'>
                        &nbsp;&nbsp;<em className="fas fa-circle-notch fa-spin fa-lg text-muted"></em>
                    </div> : "Update"}
                </Button>
            </div>
        </>

    );
};