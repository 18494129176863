import styled from "styled-components";
import styles from "./../../../../styles/app/common/variables.scss";
import {
  white,
  activeOpacity,
  hoverOpacity,
  disabledOpacity
} from "../../Styles/variables";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: ${props => (props.clickable || props.active ? "pointer" : "default")};
  font-size: 1.3rem;
  line-height: 1.3rem;
  opacity: ${props => (props.active ? activeOpacity : disabledOpacity)};
  color: ${props => {
    if (props.color) {
      return props.color;
    } else if (props.whiteIcon) {
      return white;
    } else {
      return styles.blueDark;
    }
  }};

  &:hover {
    color: ${props =>
      props.colorHover && props.active ? props.colorHover : ""};
    opacity: ${props => (!props.active ? hoverOpacity : "")};
  }
`;

export const Icon = styled.em`
  margin-right: ${({ margin, marginSize }) =>
    margin === "right" ? marginSize + ";" : null}
  margin-left: ${({ margin, marginSize }) =>
    margin === "left" ? marginSize + ";" : null}
  width: fit-content;
`;

export const Text = styled.div``;
