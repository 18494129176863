import styled from "styled-components";
import styles from "../../../../../styles/app/common/variables.scss";

export const Wrapper = styled.div`
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-right: 1px solid #999;
  border-bottom: 1px solid #999;
`;

export const CellWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 2px;
  position: relative;
`;

export const FlagsWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  cursor: pointer;
  padding: 1.1rem 0rem 0.8rem 0;
  transition: border-bottom 0.2s ease;
  justify-content: center;
  ${({ clickable }) =>
    clickable &&
    `
    :hover {
      border-bottom: ${styles.dynamicBlue} solid 1px;
    }
  `}
`;
// :hover {
//   border-bottom: ${styles.dynamicBlue} solid 1px;
// }

export const MobileColumnNameWrapper = styled.div`
  display: inline-block;
  min-width: 100px;
  font-weight: 600;
`;
