import { RightVisibilityMenuT } from "../../ContractInFocus/interfaces/personalContractSettings.interfaces";
import { GetRightVisibilityMenu } from "components/Common/Components/RightVisibilityMenu/Interfaces/RightContext.interfaces";


// TODO: incomplete as we're not sure if this component should be toggleable
export const specialistRightVisibilityMenu = ({ visibilitySettings, inReportContext }: GetRightVisibilityMenu): RightVisibilityMenuT => {
  let menu = {
    "Specialist": {
      table: {
        value: visibilitySettings.show_specialist_table,
        updateKey: 'show_specialist_table'
      },
    }
  }
  if (inReportContext) {
    menu = {
      ...menu, ...{
        "Show visits with exceptions only": {
          toggle: {
            value: visibilitySettings.specialist_report_by_exception,
            updateKey: 'specialist_report_by_exception'
          }
        }
      }
    }
  }
  return menu;
};