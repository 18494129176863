import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.material.blue.light.css";
import React, { useEffect } from "react";
import { Router } from "react-router";
import { history } from "./helpers/History/History";

import "./Polyfill.js";

// App Routes
import Routes from "./Routes";

// Vendor dependencies
import "./Vendor";
// Application Styles
import "./styles/bootstrap.scss";
import "./styles/app.scss";
import "./styles/app-print.scss";
// import FullStory from "react-fullstory";

// const ORG_ID = "QH5AX"; // Fill this in here

const App = () => {
  // specify base href from env varible 'PUBLIC_URL'
  // use only if application isn't served from the root
  // for development it is forced to root only
  /* global PUBLIC_URL */
  const basename =
    process.env.NODE_ENV === "development" ? "/" : PUBLIC_URL || "/";

  useEffect(() => {
    return history.listen(location => {
      // console.log(`You changed the page to: ${location.pathname}`)
    });
  }, []);

  return (
    <Router
      onUpdate={() => window.scrollTo(0, 0)}
      basename={basename}
      history={history}
    >
      {/* <FullStory org={ORG_ID} /> */}
      <Routes />
    </Router>
  );
};

export default App;
