/* eslint-disable */
import React, { useState, useRef, useReducer, useCallback, useEffect } from 'react';
import API from "services/API/API";

// Own
import { store } from "store/store";
import { FieldMetaGroup, FormValues } from 'components/Common/Interfaces/Entity.interface';
import { getPreFlightInfo } from 'components/ContractInFocus/Services/commonAPI.services';
import { getSupplierServicesRoute } from 'components/Schedulers/Services/visitService';
import { addNotification } from "components/Notification/Actions/Notification.actions";
import { NOTIFICATION_SUCCESS } from "components/Notification/Constants/constants";
import { newGetPayloadFromRef } from 'services/API/API.helper';
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { FormErrorsType } from "store/Common/Interfaces/Common.interface";
import { SupplierService } from 'components/Schedulers/Interfaces/Schedule.interfaces';
import SupplierServiceTransferForm from "components/Schedulers/VisitModal/Components/SupplierServiceDetail/SupplierServiceDataTransferForm";


import {
    fetchSupplierNameOptions,
    getSupplierNameOptionLabel,
    getSupplierNameOptionValue,
    getSupplierRefOptionLabel,
    getSupplierRefOptionValue,
    fetchSupplierRefOptions,
    fillSupplierDetailsByName,
    fillSupplierDetailsByRef,
} from "components/Schedulers/VisitModal/Components/SupplierServiceDetail/supplierFormHelpers";

import GeneralEntityForm, { generateStandardGeneralActionButtonDefinitions } from "components/Common/Components/GeneralEntityForm/GeneralEntityForm";

import "components/Common/Components/GeneralActionForm/GeneralActionFormStyles.scss"

interface SupplierServiceFormProps {
    supplierService: any;
    contractRef: string;
    refreshGrid: React.DispatchWithoutAction | undefined;
    setSupplierService: React.Dispatch<any>;
    breakUpdateCycle: React.MutableRefObject<boolean>;
    populateSupplierContractsResponse: () => void;
    setShowTransferForm: React.Dispatch<React.SetStateAction<boolean>>
    showTransferForm: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function SupplierServiceFormView({
    supplierService,
    contractRef,
    refreshGrid,
    setSupplierService,
    breakUpdateCycle,
    populateSupplierContractsResponse,
    setShowTransferForm,
    showTransferForm,
    setOpen,
}: SupplierServiceFormProps) {
    const [thisRecord, setThisRecord] = useState<any>();
    const [formErrors, setFormErrors] = useState<FormErrorsType>({});
    const formValuesRef = useRef<any>(supplierService || {});
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 1);
    const [meta, setMeta] = useState<FieldMetaGroup>();
    const [canChange, setCanChange] = useState(false);
    const currentFocus = useRef<string>();
    const [signalDelete, setSignalDelete] = useState<boolean>();

    useEffect(() => {
        if (thisRecord) {
            const route = getSupplierServicesRoute(contractRef);
            getPreFlightInfo(route, thisRecord.id).then((response) => {
                const { canUpdate } = response;
                setCanChange(!!canUpdate);
                setMeta(response.meta);
            })
        }
    }, [thisRecord])

    useEffect(() => {
        const route = getSupplierServicesRoute(contractRef, supplierService.id);
        signalDelete && API.delete(route).then((response: any) => {
            setSupplierService(undefined);
            refreshGrid && refreshGrid();
        })
        setSignalDelete(false);
    }, [signalDelete, supplierService])

    const getFormFieldsConfig = useCallback((supplierService?: SupplierService,
        currentValuesRef?: React.MutableRefObject<FormValues>,
        currentFocus?: React.MutableRefObject<string | undefined>,
        canChange?: boolean): FieldsFormConfig => {
        const config: FieldsFormConfig = {
            equipment_name: {
            },
            'contractor': {
                skipForm: true
            },
            'supplier_ref': {
                controlClassName: 'full-height',
                typeOverride: 'autoComplete',
                forceRequired: false,
                disabled: !!supplierService?.contractor.supplier_ref,
                autoCalculator: supplierService?.contractor.supplier_ref ? () => supplierService?.contractor.supplier_ref : undefined,
                freeSolo: false,
                getOptionLabel: getSupplierRefOptionLabel,
                getOptionValue: canChange ? getSupplierRefOptionValue : undefined,
                fetchAutoCompleteOptions: canChange ? fetchSupplierRefOptions : undefined,
                onBlur: (values, onChangeFormValues) => {
                    const supplier_ref = values["supplier_ref"];
                    breakUpdateCycle.current = false;
                    supplier_ref ? fillSupplierDetailsByRef(supplier_ref as string, onChangeFormValues, breakUpdateCycle) : onChangeFormValues({ 'supplier_ref': supplier_ref });
                },
                onChangeOption: (option, onChangeFormValues, reason) => {
                    const supplier_ref = getSupplierRefOptionValue(option);
                    breakUpdateCycle.current = false;
                    supplier_ref ? fillSupplierDetailsByRef(supplier_ref as string, onChangeFormValues, breakUpdateCycle) : onChangeFormValues({ 'supplier_ref': supplier_ref })
                },
                sideEffect: (values, fieldConfigs, onChangeFormValues) => {
                    let changed = false;
                    if (!supplierService?.contractor.supplier_ref && currentFocus?.current !== "supplier_ref") {
                        // we're only going to consider changing the defaults expressed on supplier name and address if the subcontractor doesn't have 
                        // a supplier ref
                        const supplier_ref = values["supplier_ref"];
                        const supplier_ref_has_value = supplier_ref && typeof supplier_ref == "string" && supplier_ref.trim().length > 0;
                        const disable_supplier_ref = supplier_ref_has_value;
                        if (disable_supplier_ref && !fieldConfigs['supplier_name'].disabled) {
                            fieldConfigs['supplier_name'].disabled = true;
                            changed = true;
                        }
                        if (!disable_supplier_ref && fieldConfigs['supplier_name'].disabled) {
                            fieldConfigs['supplier_name'].disabled = false;
                            changed = true;
                        }
                    }
                    return changed;
                }
            },
            'supplier_name': {
                controlClassName: 'full-height',
                typeOverride: 'autoComplete',
                forceRequired: true,
                disabled: !!supplierService?.contractor.supplier_ref,
                //autoCalculator: supplierService?.contractor.supplier_ref ? () => supplierService?.contractor.name : undefined,
                freeSolo: true,
                getOptionLabel: getSupplierNameOptionLabel,
                getOptionValue: getSupplierNameOptionValue,
                fetchAutoCompleteOptions: canChange ? fetchSupplierNameOptions : undefined,
                onBlur: (values, onChangeFormValues) => {
                    const supplier_name = values["supplier_name"];
                    breakUpdateCycle.current = false;
                    supplier_name ? fillSupplierDetailsByName(supplier_name as string, onChangeFormValues, breakUpdateCycle) : onChangeFormValues({ 'supplier_name': supplier_name });
                },
                onChangeOption: (option, onChangeFormValues, reason) => {
                    const supplier_name = getSupplierNameOptionValue(option);
                    breakUpdateCycle.current = false;
                    supplier_name ? fillSupplierDetailsByName(supplier_name as string, onChangeFormValues, breakUpdateCycle) : onChangeFormValues({ 'supplier_name': supplier_name });
                },
            },
        }
        return config;
    }, []);

    const [formFieldsConfig, setFormFieldsConfig] = useState<FieldsFormConfig>();

    useEffect(() => {
        const formConfig = getFormFieldsConfig(supplierService, formValuesRef, currentFocus, !!canChange);
        setFormFieldsConfig(formConfig)
    }, [supplierService, formValuesRef, currentFocus, canChange]);

    const processSuccessfulResponse = (response: any, callback: any) => {
        store.dispatch(addNotification({ message: "Saved", type: NOTIFICATION_SUCCESS }))
        //formValuesRef.current = response.data;
        setSupplierService(response.data.data);
        //forceUpdate();
    }

    const handleSave = (recordId?: string, callback?: any) => {
        const baseRoute = getSupplierServicesRoute(contractRef);
        const route = `${baseRoute}${recordId}/`
        const payload = newGetPayloadFromRef({ ref: formValuesRef, metaData: meta, formConfig: formFieldsConfig });
        // we need to update a task that isn't tied to a specific contract
        recordId && API.patch(route, payload).then((response) => {
            processSuccessfulResponse(response, callback);
        }).catch((e) => {
            console.log('issue: ', e);
        });
        refreshGrid && refreshGrid();
    }

    interface SetUpFormFromSupplierContractInterface {
        thisSupplierService?: SupplierService;
        existingValues?: FormValues;
        logValues?: boolean;
        canChange?: boolean;
    }

    const setupFormFromSupplierService = useCallback(({ thisSupplierService, existingValues, logValues, canChange }: SetUpFormFromSupplierContractInterface) => {
        //const formConfig = getFormFieldsConfig(thisSupplierService, formValuesRef, currentFocus, canChange);
        let oldValues: any = existingValues || {};
        const newValues: any = { ...oldValues };
        if (thisSupplierService && formFieldsConfig) {
            Object.keys(formFieldsConfig).map((field) => {
                if (thisSupplierService.hasOwnProperty(field)) { //need to use hasOwnProperty here because we want to distinguish from the value being undefined
                    newValues[field] = thisSupplierService[field as keyof SupplierService];
                }
            })
            newValues['supplier_name'] = supplierService?.contractor.name
            newValues['supplier_ref'] = supplierService?.contractor.supplier_ref
        }
        formValuesRef.current = newValues;

        const adjustedSupplierService = {
            ...supplierService,
            supplier_name: supplierService?.contractor?.name,
            supplier_ref: supplierService?.contractor?.supplier_ref

        }
        setThisRecord(adjustedSupplierService);
    }, [formFieldsConfig]);

    useEffect(() => {
        setupFormFromSupplierService({ thisSupplierService: supplierService, existingValues: formValuesRef.current, canChange })
    }, [supplierService, canChange, setupFormFromSupplierService])

    const getButtons = useCallback(() => generateStandardGeneralActionButtonDefinitions({
        handleSave,
        setSignalDelete: setSignalDelete,
        showDelete: true,//!thisRecord.hasSupplierContracts,
        formErrors
    }), [handleSave, thisRecord]);

    const formLevelSharedSpace = useRef({});

    return <div className='supplierServiceFormsWrapper'>
        <SupplierServiceTransferForm
            supplierService={supplierService}
            refreshGrid={refreshGrid}
            populateSupplierContractsResponse={populateSupplierContractsResponse}
            setSignalDelete={setSignalDelete}
            showTransferForm={showTransferForm}
            setShowTransferForm={setShowTransferForm}
            setOpen={setOpen}
        />
        <div className={`supplierServiceDetailsWrapper ${showTransferForm ? 'transferring' : ''}`}>
            <span id="SupplierServiceTitle">
                {`Supplier Service Details:`}
            </span>

            {
                meta && mustRefresh && thisRecord &&
                <>
                    <GeneralEntityForm
                        buttons={getButtons()}
                        useDefaultRevertChanges
                        formValuesRef={formValuesRef}
                        refreshSignal={mustRefresh}
                        formLevelSharedSpace={formLevelSharedSpace.current}
                        initialData={thisRecord}
                        canWrite={!!canChange}
                        formFieldsConfig={formFieldsConfig}
                        meta={meta}
                        dispatchRefreshContext={forceUpdate}
                        gridClass="SupplierServiceForm"
                        formErrors={formErrors}
                        setFormErrors={setFormErrors}
                    />
                </>
            }

        </div>
    </div>
}

export default SupplierServiceFormView;