/* eslint-disable */
import React, { useEffect } from 'react';
/* Used to render a lazy component with react-router */
export const waitFor = (Tag: any) => (props: object) => <Tag {...props} />;

export function useHashFragment(offset = 0, trigger = true) {
    useEffect(() => {
        const scrollToHashElement = () => {
            const { hash } = window.location;
            const elementToScroll = document.getElementById(hash?.replace("#", ""));

            if (!elementToScroll) return;

            window.scrollTo({
                top: elementToScroll.offsetTop - offset,
                behavior: "smooth"
            });
        };
        if (!trigger) return;
        scrollToHashElement();
        window.addEventListener("hashchange", scrollToHashElement);
        return window.removeEventListener("hashchange", scrollToHashElement);
    }, [trigger]);
}