import * as contractActions from "components/ContractInFocus/Actions/contractInFocus.actions";
import { ContractInFocusState } from "components/ContractInFocus/Reducers/contractInFocus.reducer";

export type contractInFocusCacheState = {
    [contractId: number]: ContractInFocusState
};

const initialState = {};

const cachedContractInFocusCacheReducer = (state: contractInFocusCacheState = initialState, { type, payload }: { type: string, payload: any }) => {
    switch (type) {

        case contractActions.ADD_CONTRACT_IN_FOCUS_TO_CACHE.reducer: {
            return {
                ...state,
                [payload.contractInFocus.contract.id]: payload.contractInFocus
            };
        }

        case contractActions.CLEAR_CONTRACT_IN_FOCUS_CACHE.reducer: {
            return initialState
        }

        case contractActions.REMOVE_CONTRACT_IN_FOCUS_FROM_CACHE.reducer: {
            const { [payload.contractId]: removed, ...rest } = state;
            return rest;
        }

        default:
            return state;
    }
};

export default cachedContractInFocusCacheReducer;
