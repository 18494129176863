import { createSelector } from "reselect";
import { orderBy } from "lodash";

import { Primitive } from 'components/Common/Interfaces/Entity.interface';
import { getObjVal, setObjVal, setObjValIfChanged } from 'store/Common/Helpers/commonHelper.js';


export interface SelectorOrdering {
    params: string[];
    direction: ("asc" | "desc")[];
}
export interface OrderedSelectorInterface extends SelectorOrdering {
    selector: any;
}

export const selectObjectsOrderedByFactory = ({ selector, params, direction }: OrderedSelectorInterface) => createSelector(
    selector,
    (thisArray: any[]) => {
        return orderBy(thisArray, params, direction);
    }
);

export const selectObjectValuesFilterByFactory = (selector: any, param: string, value: Primitive) => createSelector(
    selector,
    (thisArray: any[]) => {
        return thisArray.filter((x: any) => x[param] == value)
    }
);

export interface HydrateToSelector {
    selector: any;
    path: string;
}

export interface HydrateFromSelector {
    selector: any;
    path?: string;
}

export interface HydrationConfig {
    addToSelectorConfig: HydrateToSelector,
    getFromSelectorConfig: HydrateFromSelector

}

export function mapForHydration(objToBeUpdated: object, objToBeUpdatedPath: string, lookUpObj: any, lookUpPath?: string) {
    // console.log('objToBeUpdated: ', objToBeUpdated);
    // console.log('objToBeUpdatedPath: ', objToBeUpdatedPath);
    // console.log('lookUpObj: ', lookUpObj);
    // console.log('lookUpPath: ', lookUpPath)
    // The lookUpObj should be either a direct mapping of the key value to be looked up (i.e. the values held at the objToBeUpdatedPath) 
    // (in which case lookUPPath should be undefined) - or an object which contains a path to a direct mapping of the same 
    // i.e. either { [value]: <object>, ..etc } or, if lookUpPath is defined as 'a.path.to': { a: { path: { to: {[value]:<object>, ..etc } ..etc } ..etc} .. etc}
    // Provision is NOT made to search arrays here on the lookup because in general it's much better both from a clarity and performance point of view
    // to first reshape to a lookup with another process first (i.e. extract keys to a lookUpObj, probably with a memo-ised selector and then pass that as the lookUpObj)
    // The objToBeUpdatedPath must be defined, to specify the attribute that must be hydrated on the objToBeUpdated.
    // The objToBeUpdatedPath may be a path to a key that returns a string or number, or an array of strings/values, which are assumed to be the values to be looked up an swapped.
    const objLookupValue = getObjVal(objToBeUpdatedPath, objToBeUpdated); // this will generally be the id of an object we're lookingUp, or an array of such ids.
    let lookupObj = lookUpObj;
    if (lookUpPath) {
        lookupObj = getObjVal(lookUpPath, lookUpObj); // this is to extract an object based on a string path with '.' representing containment
    }
    if (Array.isArray(objLookupValue)) {
        return objLookupValue.map((x: string | number) => lookupObj[x])
    }
    return lookupObj[objLookupValue];
}

export const hydrationSelectorFactory = (hydrationConfig: HydrationConfig, mutate = false) => {
    // This function returns a selector that 'hydrates' i.e swaps a 'reference' attribute of one object ( aka the 'addToSelector') 
    // for the actual object itself, by looking that object up in a mapping - aka the 'getFromSelector'.
    // In terms of the 'addToSelector' (the one being hydrated), this function is designed to cater for either:
    // * A single object with an attribute that must be hydrated
    // * An array of objects with an attribute that must be hydrated 
    // The attribute to be hydrated can itself either be a single value, or a simple array of values (the values should be strings or numbers)
    // For notes on the limitations on the 'getFromSelector', i.e. the lookup - see notes on the mapForHydration function above.
    const { addToSelectorConfig, getFromSelectorConfig } = hydrationConfig;
    // console.log('addToSelectorConfig: ', addToSelectorConfig);
    return createSelector(
        addToSelectorConfig.selector, // this should have a stable identity when it doesn't change
        getFromSelectorConfig.selector, // this should have a stable identity when it doesn't change
        (toUpdate: any, lookup: any) => {
            // console.log('toUpdate: ', toUpdate);
            // console.log('lookup: ', lookup);
            let toUpdateIsArray = Array.isArray(toUpdate);
            if (toUpdateIsArray && !toUpdate.length || toUpdate === undefined) return toUpdate; // this stops typescript complaining about a 'never[]' type which it does if you explicitly return [], (even conditionally! - it complains about |never[])
            if (toUpdateIsArray) {
                return toUpdate.map((obj: any) => {
                    const targetValue = mapForHydration(obj, addToSelectorConfig.path, lookup, getFromSelectorConfig.path);
                    return setObjVal(addToSelectorConfig.path, obj, targetValue, mutate); // this is to set an attribute using a string path with '.' representing containment
                })
            } else {
                const targetValue = mapForHydration(toUpdate, addToSelectorConfig.path, lookup, getFromSelectorConfig.path);
                // console.log('targetValue: ', targetValue);
                return setObjVal(addToSelectorConfig.path, toUpdate, targetValue, mutate); // this is to set an attribute based on a string path with '.' representing containment
            }
        }
    );
}

