import { createSelector } from "reselect";
import { State } from "../../../store/interfaces/State";

export const layoutSelector = (state: State) => state.layout;

export const selectLayout = createSelector(
  layoutSelector,
  layout => layout
);

export const selectHeader = createSelector(
  layoutSelector,
  layout => layout.header
);
