import styled from "styled-components";
import 'styles/app/common/variables.scss';


export const Wrapper = styled.div`
  .MuiPaper-rounded {
    padding: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .plannerViewLink {
    color: var(--dynamic-blue);
    margin-right: 1rem;
    width: 180px;
    height: 30px;
    // &.open {
    //   padding: 0px;
    //   height: 25px;
    // }
  }
  .scheduleNotesIcon {
    position: absolute;
    bottom: 0;
    right: -10px;
    font-size: 1.3rem;
  }
  .notesPreview ..MuiTooltip-tooltip {
    max-width: 50vw;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

export const BatchCreateVisitsFormWrapper = styled.div`
    display:grid;
    margin-top: 1rem;
    padding: 1rem;
    width: ${props => props.theme.width};
    grid-template-columns: repeat(24, minmax(0, 1fr));
`;

export const ScheduleListGridActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

BatchCreateVisitsFormWrapper.defaultProps = {
  theme: {
    width: "100%",
  }
}
