import React from "react";
import { ArchivedIcon, UnArchivedIcon, NotifyDueIcon, NotifyInAdvanceIcon, NotifyOverDueIcon, NotifyNewDocument } from 'components/Common/Components/FileTypeIcon/FileTypeIcon';
import { GeneralTooltip } from "components/Common/Components/InfoHint/InfoHint";
import { IconButton } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import PublicIcon from '@material-ui/icons/Public';
import PlaceIcon from '@material-ui/icons/Place';
import styles from "styles/app/common/variables.scss";


import "./SchedulerActionStyles.scss";

export interface GetBaseSchedulerButtonCellTemplateProps {
    showArchived: boolean;
    handleArchive: (data: any) => void;
    handleRestore: (data: any) => void;
    data: any;
}

interface ArchiveControlProps extends GetBaseSchedulerButtonCellTemplateProps {
    data: any;
}

export const ArchiveControls = ({ showArchived, data, handleArchive, handleRestore }: ArchiveControlProps) => {
    return <>
        {showArchived && data.archived ? (
            // <i className="fas fa-redo" onClick={() => handleRestore(data)} />
            <GeneralTooltip title="Restore contract">
                <IconButton onClick={() => handleRestore(data)}>
                    <ArchivedIcon width="20px" />
                </IconButton>
            </GeneralTooltip>

        ) : showArchived ? (
            // <i className="fas fa-archive" onClick={() => handleArchive(data)} />
            <GeneralTooltip title="Put contract 'on stop'">
                <IconButton onClick={() => handleArchive(data)}>
                    <UnArchivedIcon width="20px" />
                </IconButton>
            </GeneralTooltip>
        ) : <></>}
    </>
}

export const GlobalIndicator = ({ data }: { data: any }) => {
    return <>
        {data.task__for_contract ? (
            // <i className="fas fa-redo" onClick={() => handleRestore(data)} />
            <GeneralTooltip title="This task belongs to this contract only">
                <PlaceIcon width="24px" style={{ color: styles.dynamicBlue }} />
            </GeneralTooltip>

        ) : (
            // <i className="fas fa-archive" onClick={() => handleArchive(data)} />
            <GeneralTooltip title="This task is a 'core' task">
                <PublicIcon width="18px" style={{ color: styles.dynamicBlue }} />
            </GeneralTooltip>
        )}
    </>
}

export interface HASNotificationControlProps {
    showNotificationSettings: boolean;
    handleSubmitNotificationSetting: (data: any, id?: string) => void;
    data: any;
}

export interface HeaderHASNotificationControlProps {
    showNotificationSettings: boolean;
    handleSubmitNotificationSetting: (data: any) => void;
}

export interface GetHASSchedulerButtonCellTemplateProps extends GetBaseSchedulerButtonCellTemplateProps, HASNotificationControlProps { }

//"notification_email_on_required_document_submitted",


export const HASNotificationControls = ({ showNotificationSettings, data, handleSubmitNotificationSetting }: HASNotificationControlProps) => {
    return <>
        {showNotificationSettings && (
            <div id="NotificationIconsContainer">
                <GeneralTooltip title={`${data.notification_email_in_advance ? 'Disable advance notifications for due dates' : 'Enable advance notifications for due dates'} `}>
                    <IconButton onClick={() => handleSubmitNotificationSetting({ notification_email_in_advance: !data.notification_email_in_advance }, data.personal_notification_settings_id)}>
                        <NotifyInAdvanceIcon disabled={!data.notification_email_in_advance} width="28px" />
                    </IconButton>
                </GeneralTooltip>
                <GeneralTooltip title={`${data.notification_email_on_due ? 'Disable notifications on due dates' : 'Enable notifications on due dates'} `}>
                    <IconButton onClick={() => handleSubmitNotificationSetting({ notification_email_on_due: !data.notification_email_on_due }, data.personal_notification_settings_id)}>
                        <NotifyDueIcon disabled={!data.notification_email_on_due} width="28px" />
                    </IconButton>
                </GeneralTooltip>
                <GeneralTooltip title={`${data.notification_email_on_overdue ? 'Disable notifications when overdue' : 'Enable notifications when overdue'} `}>
                    <IconButton onClick={() => handleSubmitNotificationSetting({ notification_email_on_overdue: !data.notification_email_on_overdue }, data.personal_notification_settings_id)}>
                        <NotifyOverDueIcon disabled={!data.notification_email_on_overdue} width="28px" />
                    </IconButton>
                </GeneralTooltip>
                <GeneralTooltip title={`${data.notification_email_on_required_document_submitted ? 'Disable notifications on submitted required document' : 'Enable notifications on submitted required document'} `}>
                    <IconButton onClick={() => handleSubmitNotificationSetting({ notification_email_on_required_document_submitted: !data.notification_email_on_required_document_submitted }, data.personal_notification_settings_id)}>
                        <NotifyNewDocument disabled={!data.notification_email_on_required_document_submitted} width="28px" />
                    </IconButton>
                </GeneralTooltip>
            </div>
        )}
    </>
}

export const HeaderHASNotificationControls = ({ showNotificationSettings, handleSubmitNotificationSetting }: HeaderHASNotificationControlProps) => {
    return <>
        {showNotificationSettings && (
            <div id="NotificationIconsContainer">
                <div className="scheduleHeaderSettingsGroupWrapper">
                    <GeneralTooltip title='Enable advance notifications for due dates for all records currently in the grid'>
                        <IconButton onClick={() => handleSubmitNotificationSetting({ notification_email_in_advance: true })}>
                            <CheckIcon width="28px" />
                        </IconButton>
                    </GeneralTooltip>
                    <GeneralTooltip title='Disable advance notifications for due dates for all records currently in the grid'>
                        <IconButton onClick={() => handleSubmitNotificationSetting({ notification_email_in_advance: false })}>
                            <ClearIcon width="28px" />
                        </IconButton>
                    </GeneralTooltip>
                </div>
                <div className="scheduleHeaderSettingsGroupWrapper">
                    <GeneralTooltip title='Enable notifications on due dates for all records currently in the grid'>
                        <IconButton onClick={() => handleSubmitNotificationSetting({ notification_email_on_due: true })}>
                            <CheckIcon width="28px" />
                        </IconButton>
                    </GeneralTooltip>
                    <GeneralTooltip title='Disable notifications on due dates for all records currently in the grid'>
                        <IconButton onClick={() => handleSubmitNotificationSetting({ notification_email_on_due: false })}>
                            <ClearIcon width="28px" />
                        </IconButton>
                    </GeneralTooltip>
                </div>
                <div className="scheduleHeaderSettingsGroupWrapper">
                    <GeneralTooltip title='Enable advance notifications when overdue for all records currently in the grid'>
                        <IconButton onClick={() => handleSubmitNotificationSetting({ notification_email_on_overdue: true })}>
                            <CheckIcon width="28px" />
                        </IconButton>
                    </GeneralTooltip>
                    <GeneralTooltip title='Disable advance notifications when overdue for all records currently in the grid'>
                        <IconButton onClick={() => handleSubmitNotificationSetting({ notification_email_on_overdue: false })}>
                            <ClearIcon width="28px" />
                        </IconButton>
                    </GeneralTooltip>
                </div>
                <div className="scheduleHeaderSettingsGroupWrapper">
                    <GeneralTooltip title='Enable notifications on submitted required document for all records currently in the grid'>
                        <IconButton onClick={() => handleSubmitNotificationSetting({ notification_email_on_required_document_submitted: true })}>
                            <CheckIcon width="28px" />
                        </IconButton>
                    </GeneralTooltip>
                    <GeneralTooltip title='Disable notifications on submitted required document for all records currently in the grid'>
                        <IconButton onClick={() => handleSubmitNotificationSetting({ notification_email_on_required_document_submitted: false })}>
                            <ClearIcon width="28px" />
                        </IconButton>
                    </GeneralTooltip>
                </div>
            </div>
        )}
    </>
}
