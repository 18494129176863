import React from 'react';

// Own
import { DocumentViewerModal, DocumentViewerModalProps } from 'components/Common/Components/DocumentViewer/DocumentViewer';
import { ContractDocument, MonthlyMaintenanceReports } from 'interfaces/contract.interfaces';
import { monthAsString } from 'components/Common/Utils/Dates';
import { downloadSelector } from 'components/ContractInFocus/HoC/WithPageContext/Selectors/Download.selectors';
import { useSelector } from 'react-redux';

interface MonthlyReportViewerProps extends DocumentViewerModalProps {
  existingDocuments: MonthlyMaintenanceReports;
  onSaveReport: (snapshotId: string, id: any) => void;
};

export const MonthlyReportViewer = ({
  existingDocuments,
  onSaveReport, ...props
}: MonthlyReportViewerProps) => {
  const selectedMMRDownload = useSelector(downloadSelector);
  const getDocumentBySnapshot = (snapshotId: string): ContractDocument | undefined =>
    existingDocuments.data.find(document => document.snapshot === snapshotId);

  const handleSaveReport = (): void => {
    const doc = getDocumentBySnapshot(selectedMMRDownload.snapshot.id);
    onSaveReport(selectedMMRDownload.snapshot.id, doc ? doc.id : undefined);
  };

  return (
    <DocumentViewerModal {...props} onConfirm={handleSaveReport} />
  );
}