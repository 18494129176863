import React, { useState, useEffect, useCallback, useReducer, useRef } from "react";
import DataGrid, { Column, Editing, RequiredRule, MasterDetail } from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { useSelector } from 'react-redux';

// Own
import CommonAPIService from "components/ContractInFocus/Services/commonAPI.services";
import { getDataAndMeta } from "services/API/API.helper";
import API from "services/API/API";
import { InlineWrapper } from 'components/ContractInFocus/Styles/CommonStyles';
import GridHeader from "../../Common/Components/GridHeader/GridHeader";
import { getSubTitle } from "components/ContractInFocus/Components/ContractPrintTitle/ContractPrintTitle";
import { ColumnProps, DataGridMeta } from '../interfaces/dataGridColumn.interface';
import { getColumnProps, editableTextAreaOnPreparing, getGridProps } from '../../../helpers/DataGrid/DataGridColumn.helper';
import { columnPropsPlaceHolder } from 'components/ContractInFocus/Models/ColumnProps';
import { gridMetaInitialState } from 'components/ContractInFocus/Models/Grid';
import { saveDateFormat } from "components/Common/Utils/Dates";
import { PrintChartAndTableLabels } from "components/Common/constants.js";
import { IHASAccident, IHASRiddor, RiddorCourseAttended, RiddorQualification, AccidentCheckListItem } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";
import { getRiddorQualificationsListRoute, getRiddorTrainingCourseListRoute, getAccidentCheckListRoute } from "components/AdminPanel/HAS/HASIncidentServices"

import DocumentsGrid from "components/Common/Components/DocumentsGrid/DocumentsGrid";
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { FieldMetaGroup, PreFlightListInfo, DocumentsMetaInfo } from "components/Common/Interfaces/Entity.interface";
import DocumentsUploader from "components/Common/Components/DocumentsUploader/DocumentUploader";
import { getAccidentDocumentsRoute, fetchAccidentDocumentsPreFlightInfo } from "components/AdminPanel/HAS/HASIncidentServices"
import { ContractDocumentFilesWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { GeneralDocsGridWrapper } from 'components/SiteDocuments/Components/ContractDocumentsViewGrid/ContractDocumentGridStyles';
import { profileOrganisationSelector } from 'components/Profile/Selectors/Profile.selector';
import { healthAndSafetyAdminAccessSelector, internalMemberSelector } from 'components/Profile/Selectors/Profile.selector';
import { commonUpdateGridRow } from "components/ContractInFocus/Services/commonAPI.services";

interface RiddorSubListProps {
    riddor: IHASRiddor;
    title: string;
    sublistType: "qualification" | "course";
}

export const RiddorSubList: React.FC<RiddorSubListProps> = ({
    riddor,
    title,
    sublistType
}) => {
    const getEndpoint = sublistType === "qualification" ? getRiddorQualificationsListRoute : getRiddorTrainingCourseListRoute;
    const [dataSource, setDataSource] = useState<DataSource>();
    const [metadata, setMetadata] = useState<DataGridMeta>(gridMetaInitialState);
    const descriptiveColumn = sublistType === "qualification" ? "qualification" : "course_name";
    const dateColumn = sublistType === "qualification" ? "date" : "date_attended";
    const [contentReady, setContentReady] = useState(false);
    const thisKey = riddor.id;

    useEffect(() => {
        const custom = new CustomStore({
            key: "id",
            load: loadOptions => {
                let params: any;
                const thisData = CommonAPIService.getAll<RiddorQualification | RiddorCourseAttended>(
                    getEndpoint,
                    setMetadata,
                    thisKey,
                    params
                )
                return thisData;
            },
            insert: values => {
                const theseValues: RiddorQualification | RiddorCourseAttended = { riddor: thisKey, ...values }
                return CommonAPIService.create<any>({ getEndpoint: () => getEndpoint(thisKey), values: theseValues })
            },
            // @ts-ignore
            remove: key => {
                return CommonAPIService.del<any>(getEndpoint, thisKey, key)
            },
            update: (id, values) => {
                return CommonAPIService.update<any>(getEndpoint, thisKey, id, values)
            }
        });

        setDataSource(
            new DataSource({
                store: custom
            })
        );
    }, [getEndpoint, thisKey]);

    const handleDateColumns = (data: any): void => {
        ["date", "date_attended"].map(
            (x) => {
                if (data[x]) {
                    data[x] = saveDateFormat(data[x])
                }
            }
        )
    };

    const handleRowInserting = (values: any): void => {
        handleDateColumns(values.data);
    };

    const handleRowUpdating = (values: any): void => {
        handleDateColumns(values.newData);
    }

    const getColumnPropsExt = useCallback((field: string): ColumnProps | undefined => {
        return metadata.loaded ? getColumnProps(field, metadata.activeMeta) : columnPropsPlaceHolder;
    }, [metadata])

    const handleRowUpdated = () => {
        if (metadata) {
            setMetadata({ ...metadata, activeMeta: metadata.POSTMeta });
        }
    }

    const renderColIfMetaDataDefined = useCallback((fieldName: string, requiredOveride: boolean, colPropsOverride = {}) => {
        const metaColProps = getColumnPropsExt(fieldName);
        if (metaColProps) {
            const colProps = { ...metaColProps, ...colPropsOverride }
            return (<Column {...colProps}>
                {requiredOveride && <RequiredRule />}
            </Column>
            )
        }
    }, [getColumnPropsExt])

    return (
        <InlineWrapper>
            <GridHeader
                title={title}
                className={`${PrintChartAndTableLabels ? '' : 'no-print'}`}
                subTitle={getSubTitle(metadata)}
            />
            {dataSource && (
                <DataGrid
                    className='no-print'
                    dataSource={dataSource}
                    onRowInserting={handleRowInserting}
                    onRowUpdating={handleRowUpdating}
                    onRowUpdated={handleRowUpdated}
                    {...getGridProps(metadata.activeMeta)}
                    onEditorPreparing={editableTextAreaOnPreparing(metadata.activeMeta)}
                    onContentReady={() => setContentReady(true)}
                    showColumnHeaders
                >
                    <Editing
                        mode="cell"
                        allowUpdating={metadata.privileges.PUT}
                        allowDeleting={metadata.privileges.DELETE}
                        allowAdding={metadata.privileges.POST}
                    />
                    {renderColIfMetaDataDefined(descriptiveColumn, true)}
                    {renderColIfMetaDataDefined(dateColumn, true)}
                </DataGrid>
            )}
        </InlineWrapper>
    );
};


interface AccidentCheckListDocumentProps {
    data: AccidentCheckListItem;
    preFlightInfo: PreFlightListInfo
    updateCheckListItem: (checkListItemId: string) => void;
}

const AccidentCheckListDocuments = ({ data, preFlightInfo, updateCheckListItem }: AccidentCheckListDocumentProps) => {
    const accidentId: string = data.accident;
    const checkListItem = useRef(data);
    const basePath = getAccidentDocumentsRoute(accidentId);
    const [fetchFilters, setFetchFilters] = useState();
    const selectProfileOrganisationFromState = useSelector(profileOrganisationSelector);
    const [fieldsConfig, setFieldsConfig] = useState<FieldsFormConfig>();
    const HASAdminAccess = useSelector(healthAndSafetyAdminAccessSelector);
    const internalMember = useSelector(internalMemberSelector);
    const refresh = useCallback(() => {
        updateCheckListItem(data.id);
    }, [data.id, updateCheckListItem])
    const modelColumns = useRef({
        'notes': {
            width: '45%',
            caption: 'Description'

        },
        // 'internal_access_only': {
        //     visible: internalMember,
        //     width: '125px'
        // },
        'h_a_s_department_only': {
            visible: HASAdminAccess,
            caption: "H&S staff only",
            width: '125px'
        }
    });
    const getDocuments = useCallback(({ setMetaInfo, setResponse, recentAction, defaultLoad }) => {
        // we already have the documents nested under each checklist item, but we need to set the meta for them and return the appropriate ones
        const documentsMetaInfo: DocumentsMetaInfo = {
            meta: preFlightInfo.meta,
            permissions: {
                POST: preFlightInfo.canCreate,
                DELETE: preFlightInfo.canCreate,
                PUT: preFlightInfo.canCreate
            },
        }
        setMetaInfo(documentsMetaInfo);
        // we don't really need to 'setResponse' at present as it's not used outside of the docuemntsGrid
        if (recentAction === "recordDeleted") {
            // here we might call defaultLoad() to refresh the data, but as we also need to update the document count in the 
            // parent checklistitem row, we'll call updateCheckListItem
            updateCheckListItem(data.id);
        }
        return checkListItem.current.accident_documents;
    }, [preFlightInfo, data.id, updateCheckListItem])

    useEffect(() => {
        const config = {
            'notes': {
                label: 'Description',
                forceRequired: true,
            },
            'accident': {
                hidden: true,
                defaultValue: data.accident
            },
            'h_a_s_accident_document_type': {
                hidden: true,
                defaultValue: data.item
            },
            'author_organisation': {
                hidden: true,
                defaultValue: selectProfileOrganisationFromState
            },
            'internal_access_only': {
                hidden: true,
                defaultValue: true,
            },
            'h_a_s_department_only': {
                hidden: !HASAdminAccess,
                label: "H&S Staff Only"
            },
        }
        setFieldsConfig(config);
    }, [selectProfileOrganisationFromState, HASAdminAccess, data.accident, data.item]);

    return <GeneralDocsGridWrapper className='witnessStatementsWrapper field-group-wrapper'>
        {modelColumns.current && <ContractDocumentFilesWrapper height={!preFlightInfo?.canCreate ? 30 : 20}>
            <DocumentsGrid
                listBasePath={basePath}
                hideSearch
                hideFilters
                hideSource
                columnsConfig={modelColumns.current}
                fetchFilters={fetchFilters}
                editMode="row"
                //actionColumnsWidth={100}
                updateRecordMetaBeforeEdit
                overRideGetDocuments={getDocuments}
            />
        </ContractDocumentFilesWrapper>}

        {preFlightInfo?.canCreate && basePath && fieldsConfig &&
            <DocumentsUploader
                urlContext={basePath}
                fieldConfigs={fieldsConfig}
                preFlightInfo={preFlightInfo}
                gridId="CheckListDocumentsUploader"
                reset={refresh}
                zIndex={1300}
            //inAppViewingLocationOverride="admin"
            />
        }
    </GeneralDocsGridWrapper>
}


interface AccidentCheckListProps {
    accident: IHASAccident;
    title: string;
}

export const AccidentCheckList: React.FC<AccidentCheckListProps> = ({
    accident,
    title,
}) => {
    const getEndpoint = getAccidentCheckListRoute;
    const [dataSource, setDataSource] = useState<DataSource>();
    const [metadata, setMetadata] = useState<DataGridMeta>(gridMetaInitialState);
    const [documentsPreFlightInfo, setDocumentsPreFlightInfo] = useState<PreFlightListInfo>();
    const [contentReady, setContentReady] = useState(false);
    const thisKey = accident.id;

    useEffect(() => {
        const custom = new CustomStore({
            key: "id",
            load: loadOptions => {
                let params: any;
                const thisData = CommonAPIService.getAll<AccidentCheckListItem>(
                    getEndpoint,
                    setMetadata,
                    thisKey,
                    params
                )
                return thisData;
            },
            update: (id, values) => {
                return CommonAPIService.update<any>(getEndpoint, thisKey, id, values)
            }
        });

        setDataSource(
            new DataSource({
                store: custom
            })
        );
    }, [getEndpoint, thisKey]);

    const getColumnPropsExt = useCallback((field: string): ColumnProps | undefined => {
        return metadata.loaded ? getColumnProps(field, metadata.activeMeta) : columnPropsPlaceHolder;
    }, [metadata])

    const updateRowValues = useCallback((checkListItemId: string) => {
        // this isn't needed to update the row via the grid mechanism, but to update the row values when something changes in the child component
        // e.g. docs are added or removed (because it changes the docs count)
        const checkListRoute = getEndpoint(accident.id);
        const checkListItemRoute = `${checkListRoute}${checkListItemId}`
        API.get(checkListItemRoute).then((response) => {
            const thisResponse = getDataAndMeta(response);
            commonUpdateGridRow({
                dataSource,
                key: checkListItemId,
                changes: thisResponse.data
            });
        })
    }, [dataSource, getEndpoint, accident.id]);

    useEffect(() => {
        fetchAccidentDocumentsPreFlightInfo({ parentId: accident.id }).then((response) => {
            setDocumentsPreFlightInfo(response); // we set this here and pass it into each checklist item so 
            // we don't have to retrieve it for each checklist item
        })
    }, [accident.id]);

    const renderColIfMetaDataDefined = useCallback((fieldName: string, requiredOveride: boolean, colPropsOverride?: Partial<ColumnProps>) => {
        const theseColPropsOverride = colPropsOverride || {};
        const metaColProps = getColumnPropsExt(fieldName);
        if (metaColProps) {
            const colProps = { ...metaColProps, ...theseColPropsOverride }
            return (<Column {...colProps}>
                {requiredOveride && <RequiredRule />}
            </Column>
            )
        }
    }, [getColumnPropsExt])

    return (
        <InlineWrapper>
            <GridHeader
                title={title}
                className={`${PrintChartAndTableLabels ? '' : 'no-print'}`}
                subTitle={getSubTitle(metadata)}
            />
            {dataSource && (
                <DataGrid
                    className='no-print'
                    dataSource={dataSource}
                    //onRowUpdated={handleRowUpdated}
                    {...getGridProps(metadata.activeMeta)}
                    onEditorPreparing={editableTextAreaOnPreparing(metadata.activeMeta)}
                    onContentReady={() => setContentReady(true)}
                    showColumnHeaders
                >
                    <Editing
                        mode="cell"
                        allowUpdating={metadata.privileges.PUT}
                    />
                    {renderColIfMetaDataDefined("item", true)}
                    {renderColIfMetaDataDefined("checklist_item_documents_count", true, { width: "120px", caption: "Documents Count", alignment: "right" })}
                    {renderColIfMetaDataDefined("verified", false, { caption: "Reviewed" })}
                    {renderColIfMetaDataDefined("notes", false)}

                    {documentsPreFlightInfo && (
                        <MasterDetail
                            enabled={true}
                            component={
                                (e) => {
                                    console.log('data: ', e.data);
                                    return (<AccidentCheckListDocuments
                                        data={e.data.data}
                                        preFlightInfo={documentsPreFlightInfo}
                                        updateCheckListItem={updateRowValues}
                                    />)
                                }
                            }
                        />
                    )}
                </DataGrid>
            )}
        </InlineWrapper>
    );
};

