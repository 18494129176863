export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";

export const notificationType = {
  ERROR: "error",
  WARNING: "warning",
  INFO: "info"
};

export function showNotification(notification, notificationType) {
  return { type: SHOW_NOTIFICATION, notification, notificationType };
}
