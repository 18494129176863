import React, { useState, useEffect, useCallback } from "react";

// Own
import { AdminDataGrid } from "components/AdminPanel/Containers/AdminDataGrid";
import { isEqual } from "lodash";

// NOTE!! there appear to be issues with multiple refreshes whenever more than one row is 'expanded' at a time and then one of the rows is collapsed.
// To investigate/fix.


const AdminFilteredDataGrid = (props: any) => {
  const [data, setData] = useState(props.data);
  const [headerFilterPage, setHeaderFilterPage] = useState("default");
  const [gridActions, setGridActions] = useState();

  // useEffect(() => {
  //   console.log('props changed: ', props);
  // }, [props])

  // useEffect(() => {
  //   console.log('data changed: ', props.data);
  // }, [props.data]);

  // useEffect(() => {
  //   console.log('headerFilterPage changed: ', headerFilterPage);
  // }, [headerFilterPage]);

  useEffect(() => {
    //const thisGridConfig = props.getDatagridColumns(setHeaderFilterPage, setData, props.data, props.meta);
    const filters = props.getDatagridColumns({ setHeaderFilterPage, setData, data: props.data, meta: props.meta })
    const actions = { ...filters, ...props.datagridActions }

    if (!isEqual(data, props.data)) {
      setData(props.data);
    }

    if (!isEqual(actions, gridActions)) {
      // console.log('actions: ', actions, ' gridActions: ', gridActions);
      setGridActions(actions);
    }

    //setGridActions(actions);
  }, [props, headerFilterPage]);
  // NB - a function is passed into AdminFilteredDataGrid for the getDatagridColumns property, but a simple dict for a standard AdminGrid (as below)
  return (
    <>
      {gridActions ? (
        <AdminDataGrid
          {...props}
          data={data}
          headerFilterPage={headerFilterPage} //we should disambiguate this page variable (against general pagination)
          // @ts-ignore
          getDatagridColumns={gridActions[headerFilterPage]?.columns}
          datagridActions={gridActions}
        />
      ) : null}
    </>
  );
};

// AdminFilteredDataGrid.whyDidYouRender = true;

export default React.memo(AdminFilteredDataGrid);
