import styled from "styled-components";
import { createStyles, Theme, makeStyles } from '@material-ui/core';
// need to export these variables through scss modules loader
import { grayMedDark, grayDark } from "../../../../styles/app/common/variables";
import styles from "../../../../styles/app/common/variables.scss";


export const StyledAvatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledAvatarActionContainer = styled.div`
  display:flex;
  justify-content: space-around;
  width: 100%;
  padding: 0 10px;
`;


export const StyledAvatarActionLabel = styled.div`
  margin-top: ${(props: { marginTop?: string }) => props.marginTop || '.5rem'};
  color: ${grayMedDark};
  cursor: pointer;
  font-size: 1rem;
  display: flex;
    align-items: center;
    
  .MuiSvgIcon-root {
    font-size: 1rem;
    margin-right: 2px;
  }
  &:hover {
    color: ${grayDark};
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  min-width: 220px;
  margin-right: 10px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  .filepond--hopper {
    height: 150px;
    width: 180px;
  }
  .filepond--root,
  .filepond--root .filepond--drop-label {
    height: 150px;
  }
  .filepond-label, .filepond--label-action{
    font-size: 14px;
    width: 150px;
  }
`;

export const StyledEdit = styled.div`
  color: ${grayMedDark};
  cursor: pointer;
  margin-top: 0.6rem;
  em:nth-child(1) {
    margin-right: 0.25rem;
  }
  &:hover {
    color: ${grayDark};
  }
`;

export const StyledPasswordButtonWrapper = styled.div`
  padding-bottom: 1rem;
  button {
    color: ${grayDark};
  }
`;

export const StyledImageWrapper = styled.div`
  flex-grow: 1;
  max-height: 200px;
  img {
    border-radius: 0.25rem;
  }
`;


export const useProfileAvatarStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      width: '144px',
      height: '144px',
      backgroundColor: styles.blueDark,
      color: styles.white,
      cursor: 'pointer'
    },
  })
);
