import React, { useRef } from "react";

// Own
import { apiSuccess, openPanelInSelectionMode } from "components/AdminPanel/Helpers/AdminPanel.helper";
import {
  DataGridUpdateEvent,
} from "interfaces/datagrid.interfaces";
import { ContractInterface } from "components/AdminPanel/Contracts/Interfaces/Contract.interface";
import { adminPanelType } from "components/AdminPanel/Models/AdminPanel.model";
import { AdminDataGrid } from "components/AdminPanel/Containers/AdminDataGrid";
import { getPeopleWithAccessActions } from "components/AdminPanel/People/Models/PeopleDetail.model";
import { PeopleWithAccessStateForSpecificContract, PersonWithAccessI } from 'components/AdminPanel/Contracts/Interfaces/Contract.interface';
import { removeDisabledFieldsBasedOnRecordMeta } from "helpers/Forms/form.helper";
import { allPeopleSelector } from "components/AdminPanel/People/Selectors/People.selector";
import contractServices from "components/AdminPanel/Contracts/Services/contractService";
import { EntityKeys } from "constants/entity.constants";
import { FieldMetaGroup } from 'components/Common/Interfaces/Entity.interface';
import {
  ColumnPropGroup,
  ColumnProps,
} from "components/ContractInFocus/Interfaces/DataGridColumn.interface";

interface PeopleWithAccessViewProps {
  panelId: string;
  masterRecordData: ContractInterface;
  peopleWithAccessState: PeopleWithAccessStateForSpecificContract;
  getDatagridColumns: (meta: FieldMetaGroup) => ColumnPropGroup;
  dataTestId: string;
  onUnlink?: (item: any) => void;
  masterRecordType?: string;
  selectActionIdentifier?: string;
  onAddLinks?: Function;
  linkPanelTitle?: string;
}

const PeopleWithAccessView: React.FC<PeopleWithAccessViewProps> = ({
  panelId,
  masterRecordData,
  peopleWithAccessState,
  getDatagridColumns,
  dataTestId,
  onUnlink,
  masterRecordType,
  selectActionIdentifier,
  onAddLinks,
  linkPanelTitle
}) => {

  const cloneMembersFromState = (): PersonWithAccessI[] => {
    const data = peopleWithAccessState.data || [];
    return data.slice();
  };

  const accessList = cloneMembersFromState();

  const currentMemberIds = accessList.map(m => m.person)

  const handlePersonLink = (): void => {
    //openPanelWithDragMessage(adminPanelType.people);
    masterRecordType && selectActionIdentifier && linkPanelTitle && openPanelInSelectionMode(
      {
        selectActionIdentifier,
        masterRecordType,
        masterRecordData,
        linkedRecordType: adminPanelType.people,
        onAddLinks,
        currentMemberIds,
        linkPanelTitle,
      }
    )
  };

  const linkPeople = selectActionIdentifier ? handlePersonLink : undefined;

  const handleAccessUpdate = (e: DataGridUpdateEvent): void => {
    // @ts-ignore    
    e.cancel = contractServices.updateAccess(
      masterRecordData as ContractInterface,
      e.key,
      e.oldData,
      e.newData,
      peopleWithAccessState.metaWrapper.putMeta
      //selectPeopleState.recordmeta[e.key]
    ).then(apiSuccess);
  };

  return (
    <AdminDataGrid
      data={accessList}
      editMode="cell"
      meta={peopleWithAccessState.metaWrapper.putMeta}
      permissions={peopleWithAccessState.metaWrapper.permissions}
      dataTestId={dataTestId}
      getDatagridColumns={getDatagridColumns}
      masterView={false}
      passedEditorPreparing={(e: any) => {
        removeDisabledFieldsBasedOnRecordMeta(e);
      }}
      entityKey={EntityKeys.People}
      columnActions={getPeopleWithAccessActions(onUnlink)}
      panelId={panelId}
      dragAndDrop={false}
      onUpdate={handleAccessUpdate}
      onLink={linkPeople}
      toolbarTitle="Explicit Links"
    />
  );
};

export default PeopleWithAccessView;
