import React, { useState, useCallback, useRef, memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { IntersectionOptions } from 'react-intersection-observer';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { faExclamationTriangle, faStrikethrough } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GeneralTooltip } from 'components/Common/Components/InfoHint/InfoHint';
import Fullscreen from '@material-ui/icons/Fullscreen';
import FullscreenExit from '@material-ui/icons/FullscreenExit';
import Print from '@material-ui/icons/Print';
import Edit from '@material-ui/icons/Edit';

import { APIPrivileges } from "services/Interface/Interface";
import { InViewProgressTracker } from "components/Common/Components/InViewWrapper/InViewWrapper";
import GeneralEntityForm, { UseFormButtons, generateStandardGeneralActionButtonDefinitions } from "components/Common/Components/GeneralEntityForm/GeneralEntityForm";
import { IHASAccident } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { FieldMetaGroup, FieldGroup, Primitive } from 'components/Common/Interfaces/Entity.interface';
import { FieldInfoButton, CreateFieldInfo } from "components/Common/Components/FormFieldComments/FormFieldComments";
import { arrayToDict } from "services/API/API.helper";
import FadeInWrapper from "components/Common/Components/Wrappers/FadeInWrapper";
import { FieldConfigsListType } from "components/ContractInFocus/HAS/IncidentFormManagementWrapper";
import { projectConfigSelector } from 'components/ContractInFocus/HoC/WithPageContext/Selectors/Download.selectors';
import { FormErrorsType } from "store/Common/Interfaces/Common.interface";

export interface FieldInfoManagementProps { fieldInfoListData: any, fieldInfoListMeta: any, updateMode?: boolean }

interface HASIncidentFormProps {
    meta?: FieldMetaGroup;
    fieldInfoInFocus: CreateFieldInfo | undefined;
    setFieldInfoInFocus: React.Dispatch<React.SetStateAction<CreateFieldInfo | undefined>>;
    permissions?: APIPrivileges;
    currentFieldConfigsListType: FieldConfigsListType;
    setCurrentFieldConfigsListType: React.Dispatch<React.SetStateAction<FieldConfigsListType>>;
    handleCancel: () => void;
    handleSave: (args: any) => void;
    handleSubmit?: (args: any) => void;
    thisRecord: IHASAccident;
    formValuesRef: React.MutableRefObject<any>;
    rowLevelSharedSpace: any;
    refreshSignal: any;
    formSharedSpace: any;
    formFieldsConfig?: FieldsFormConfig;
    formLayout: FieldGroup[] | undefined;
    putMeta: FieldMetaGroup | undefined;
    dispatchRefreshContext: React.DispatchWithoutAction;
    formSettingsInFocus: boolean;
    handleFormSettingsClose: () => void;
    gridClass: string;
    FormSettingsComponent?: () => JSX.Element;
    FieldInfoComponent?: () => JSX.Element;
    setFullScreenForm?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    setPrintable: React.Dispatch<React.SetStateAction<boolean>>;
    printable?: boolean;
    disableDxNavKeys?: React.MutableRefObject<boolean>;
    initiallySelectedDataField?: React.MutableRefObject<string | undefined>;
    showReadOnly?: boolean;
    inViewOptions?: React.MutableRefObject<IntersectionOptions>;
    inViewProgressTracker?: React.MutableRefObject<InViewProgressTracker>;
}

const HASIncidentForm = ({
    meta,
    fieldInfoInFocus,
    setFieldInfoInFocus,
    permissions,
    handleCancel,
    handleSave,
    handleSubmit,
    thisRecord,
    formValuesRef,
    rowLevelSharedSpace,
    refreshSignal,
    formSharedSpace,
    formFieldsConfig,
    formLayout,
    putMeta,
    dispatchRefreshContext,
    formSettingsInFocus,
    handleFormSettingsClose,
    gridClass,
    FormSettingsComponent,
    FieldInfoComponent,
    setFullScreenForm,
    setPrintable,
    printable,
    setCurrentFieldConfigsListType,
    currentFieldConfigsListType,
    disableDxNavKeys,
    initiallySelectedDataField,
    showReadOnly,
    inViewOptions,
    inViewProgressTracker
}: HASIncidentFormProps) => {

    const [mainAccidentFormChanged, setMainAccidentFormChanged] = useState<boolean>();
    const projectConfig = useSelector(projectConfigSelector);
    const [formErrors, setFormErrors] = useState<FormErrorsType>({});

    const timeToTriggerFadeIn = 0;
    const transitionTimeForFadeIn = 0;

    const onMainAccidentFormChanged = useCallback((changed: boolean) => {
        if (rowLevelSharedSpace.formsChangedArray === undefined) {
            rowLevelSharedSpace.formsChangedArray = [];
        }
        if (changed) {
            rowLevelSharedSpace.formsChangedArray.push("incidentMainForm");
        } else {
            rowLevelSharedSpace.formsChangedArray = rowLevelSharedSpace.formsChangedArray.filter((x: string) => x !== "incidentMainForm");
        }
        rowLevelSharedSpace.formChanged = !!Object.keys(rowLevelSharedSpace.formsChangedArray).length;
    }, [rowLevelSharedSpace]);

    //handleCancel may have the same effect as the default revert implementation in GeneralEntityForm 
    // but it is overridden here to ensure it stays the same as whatever is called by handleCancelAll
    const getButtons = useCallback(() => {
        const theseButtons = generateStandardGeneralActionButtonDefinitions({
            handleCancel: handleCancel,
            handleSave: handleSave,
            handleSubmit: handleSubmit,
            showDelete: false,
            formSubmitted: thisRecord?.investigation_submitted,
            formErrors
        })
        // as we're not using the default revert, we'll also want to set the form level handleCancel here so it can be called on row collapse
        formSharedSpace.handleCancel = handleCancel;
        return theseButtons;
    }, [handleSave, handleCancel, formSharedSpace, handleSubmit, thisRecord, formErrors]);

    const handleFieldInfoInFocusClose = useCallback(() => {
        setFieldInfoInFocus(undefined);
    }, [])

    return <div className={`formWithComments ${fieldInfoInFocus ? 'commentsOpen' : 'commentsClosed'} ${printable ? 'printable' : ''}`}>
        {putMeta && <div className={`formSection ${printable ? 'column-flow' : ''}`}>
            <div className='no-print viewSettings'>
                {currentFieldConfigsListType && <div className='viewSettings-left'>
                    {!printable && <GeneralTooltip title="View Fields That Require Attention Only">
                        <div>
                            <FormControlLabel
                                label={""} // View Fields That Require Attention Only
                                control={<>
                                    <Switch
                                        size="small"
                                        checked={currentFieldConfigsListType === "unresolved"}
                                        onClick={() => {
                                            setCurrentFieldConfigsListType(currentFieldConfigsListType === "unresolved" ? "unfiltered" : "unresolved")
                                        }}
                                    />

                                    <FontAwesomeIcon
                                        icon={faExclamationTriangle}
                                        cursor="arrow"
                                        className="flagged"
                                    />

                                </>
                                }
                            />
                        </div>
                    </GeneralTooltip>}
                    {!printable && <GeneralTooltip title="View Redacted Fields Only">
                        <div>
                            <FormControlLabel
                                label={""}
                                control={<>
                                    <Switch
                                        size="small"
                                        checked={currentFieldConfigsListType === "redacted"}
                                        onClick={() => {
                                            setCurrentFieldConfigsListType(currentFieldConfigsListType === "redacted" ? "unfiltered" : "redacted")
                                        }}
                                    />

                                    <FontAwesomeIcon
                                        icon={faStrikethrough}
                                        cursor="arrow"
                                        className="flagged"
                                    />

                                </>
                                }
                            />
                        </div>
                    </GeneralTooltip>}
                    {setFullScreenForm && <GeneralTooltip title="Expand This Form">

                        <div id={thisRecord.id} className='expansionControl fullScreenFormExpander'>
                            <IconButton
                                onClick={() => {
                                    setFullScreenForm(true)
                                    setTimeout(() => {
                                        const scroll = document.getElementById(thisRecord.id);
                                        if (scroll) {
                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                        }
                                    }, 1500)
                                }
                                }
                            >
                                <Fullscreen />
                            </IconButton>


                        </div>
                    </GeneralTooltip>
                    }
                    {setFullScreenForm && <GeneralTooltip title="Collapse This Form">
                        <div className='expansionControl fullScreenFormCollapser'>
                            <IconButton
                                onClick={() => {
                                    formSharedSpace.printable = false;
                                    setFullScreenForm(undefined);
                                    setTimeout(() => {
                                        const scroll = document.getElementById(thisRecord.id);
                                        if (scroll) {
                                            const offset = document.getElementById('BaseHeaderHorizontal')?.offsetHeight || 0;
                                            console.log('offset: ', offset);
                                            const y = scroll.getBoundingClientRect().top + window.scrollY - offset - 150;
                                            //scroll.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                            window.scrollTo({ top: y, behavior: 'smooth' });
                                        }
                                    }, 1500)
                                }
                                }
                            >
                                <FullscreenExit />
                            </IconButton>
                        </div>
                    </GeneralTooltip>
                    }
                    {setPrintable && !printable && <GeneralTooltip title="Print View">
                        <div className='expansionControl'>
                            <IconButton
                                onClick={() => {
                                    formSharedSpace.printable = true;
                                    setPrintable(true)
                                    setFullScreenForm && setFullScreenForm(true);
                                }}
                            >
                                <Print />
                            </IconButton>
                        </div>
                    </GeneralTooltip>
                    }
                    {setPrintable && printable && <GeneralTooltip title="Edit View">
                        <div className='expansionControl'>
                            <IconButton
                                onClick={() => {
                                    formSharedSpace.printable = false;
                                    setPrintable(false)
                                }}
                            >
                                <Edit />
                            </IconButton>
                        </div>
                    </GeneralTooltip>
                    }
                </div>}

                {!printable &&
                    <UseFormButtons
                        buttons={getButtons()}
                        recordId={thisRecord?.id}
                        formChanged={!!mainAccidentFormChanged}
                        //evaluateButtons={ evaluateButtons }
                        position='top'
                        formErrors={formErrors}
                    />
                }
            </div>

            <FadeInWrapper
                timeToTrigger={timeToTriggerFadeIn}
                transitionTime={transitionTimeForFadeIn}
                minHeight="20vh"
            >
                <div>
                    {printable &&
                        <>
                            <header className='printableFormHeader'>
                                <div className="RHSWrapper">
                                    <div className="incidentViewLogoWrapper">
                                        <img src={projectConfig.jaguar_doc_logo_with_text.small} alt="Jaguar Tracks Logo" />
                                    </div>
                                </div>
                            </header>
                            <table className='paging'>
                                <thead>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <GeneralEntityForm
                                                useDefaultRevertChanges={false}
                                                formValuesRef={formValuesRef}
                                                rowLevelSharedSpace={rowLevelSharedSpace}
                                                refreshSignal={refreshSignal}
                                                formLevelSharedSpace={formSharedSpace} //
                                                initialData={thisRecord}
                                                canWrite={!!permissions?.PUT}
                                                formFieldsConfig={formFieldsConfig}
                                                formLayout={formLayout}
                                                meta={putMeta}
                                                dispatchRefreshContext={dispatchRefreshContext}
                                                gridClass={gridClass}
                                                generalFieldZindex={1}
                                                paperElevation={3}
                                                formChangedCallback={onMainAccidentFormChanged}
                                                //inViewOptions={inViewOptions}
                                                //inViewProgressTracker={inViewProgressTracker}
                                                signalFormChanged={setMainAccidentFormChanged}
                                                initiallySelectedDataField={initiallySelectedDataField}
                                                showReadOnly={showReadOnly}
                                                formErrors={formErrors}
                                                setFormErrors={setFormErrors}
                                                addColonToLabel
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                </tfoot>
                            </table>
                            {/* <table className='paging'><thead><tr><td>&nbsp;</td></tr></thead><tbody><tr><td></td>
                    </td></tr></tbody><tfoot><tr><td>&nbsp;</td></tr></tfoot></table> */}
                        </>


                    }
                    {!printable && <GeneralEntityForm
                        useDefaultRevertChanges={false}
                        formValuesRef={formValuesRef}
                        rowLevelSharedSpace={rowLevelSharedSpace}
                        refreshSignal={refreshSignal}
                        formLevelSharedSpace={formSharedSpace} //
                        initialData={thisRecord}
                        canWrite={!!permissions?.PUT}
                        formFieldsConfig={formFieldsConfig}
                        formLayout={formLayout}
                        meta={putMeta}
                        dispatchRefreshContext={dispatchRefreshContext}
                        gridClass={gridClass}
                        generalFieldZindex={1}
                        paperElevation={3}
                        formChangedCallback={onMainAccidentFormChanged}
                        inViewOptions={inViewOptions}
                        inViewProgressTracker={inViewProgressTracker}
                        signalFormChanged={setMainAccidentFormChanged}
                        initiallySelectedDataField={initiallySelectedDataField}
                        formErrors={formErrors}
                        setFormErrors={setFormErrors}
                    />}
                </div>
            </FadeInWrapper>


            <div className='no-print lower viewSettings'>
                <UseFormButtons
                    buttons={getButtons()}
                    recordId={thisRecord?.id}
                    formChanged={!!mainAccidentFormChanged}
                    position='bottom'
                    formErrors={formErrors}
                />
            </div>
        </div>}

        {!printable && fieldInfoInFocus && FieldInfoComponent &&
            <div className='commentsSection'>
                <div className='sideHeader'>
                    <div className="commentsSection-close-button">
                        <IconButton onClick={handleFieldInfoInFocusClose} >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>

                <FieldInfoComponent />
            </div>

        }

        {!printable && formSettingsInFocus && FormSettingsComponent &&
            <div className='commentsSection'>
                <div className='sideHeader'>
                    <div className="commentsSection-close-button">
                        <IconButton onClick={handleFormSettingsClose} >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <FormSettingsComponent />
            </div>
        }

    </div>
}

export default HASIncidentForm;


export interface FilterIncidentFieldsForFormProps {
    fieldInfoListData: any,
    formFieldsConfig: FieldsFormConfig;
    fullFormFieldsConfig?: FieldsFormConfig;
    field: string;
    value?: Primitive;
}


export const filterIncidentFieldConfigs = ({
    fieldInfoListData,
    formFieldsConfig,
    field,
    value
}: FilterIncidentFieldsForFormProps) => {
    let thisFormFieldsConfig = { ...formFieldsConfig };
    const dataByFieldLookup = fieldInfoListData ? arrayToDict(fieldInfoListData, "issue_field") : {};
    Object.keys(thisFormFieldsConfig).map((x: string) => {
        const include = value !== undefined ? dataByFieldLookup[x]?.[field] === value : dataByFieldLookup[x]?.[field];
        if (!include) {
            delete thisFormFieldsConfig[x] // no point using 'spread' syntax as this is already a new object
        }
    })
    return thisFormFieldsConfig;
};

export interface ProcessIncidentFieldsForFormProps extends FieldInfoManagementProps {
    formFieldsConfig: FieldsFormConfig;
    viewUnresolvedFieldsOnly?: boolean;
    setFieldInfoInFocus: React.Dispatch<React.SetStateAction<CreateFieldInfo | undefined>>;
    handleFormSettingsClose: () => void;
    incidentId: string;
    printable?: boolean;
    initiallySelectedDataField?: React.MutableRefObject<string | undefined>;
    initiallySelectedComment?: React.MutableRefObject<string | undefined>;
}

export const processIncidentFieldsForForm = ({
    fieldInfoListData,
    fieldInfoListMeta,
    updateMode,
    formFieldsConfig,
    setFieldInfoInFocus,
    handleFormSettingsClose,
    incidentId,
    printable,
    initiallySelectedDataField
}: ProcessIncidentFieldsForFormProps) => {
    let toUpdateFormFieldConfigs = { ...formFieldsConfig };
    const issueFieldMetaChoices = fieldInfoListMeta?.issue_field?.choices;
    const issueFieldMetaChoicesLookup = issueFieldMetaChoices ? arrayToDict(issueFieldMetaChoices, "value") : {};
    const dataByFieldLookup = fieldInfoListData ? arrayToDict(fieldInfoListData, "issue_field") : {};
    if (updateMode) { // we only need the field configs for the new field info data that we are passing in
        toUpdateFormFieldConfigs = {};
        fieldInfoListData.map((r: any) => { toUpdateFormFieldConfigs[r.issue_field] = formFieldsConfig[r.issue_field] })
    }

    if (printable) {
        Object.keys(toUpdateFormFieldConfigs).map((x: string) => {
            const redacted = dataByFieldLookup[x]?.field_redacted;
            const config = { ...toUpdateFormFieldConfigs[x] };
            config.printable = true;
            config.redacted = redacted;
            toUpdateFormFieldConfigs[x] = config;
        })
    } else {
        fieldInfoListData && Object.keys(toUpdateFormFieldConfigs).map((x: string) => {
            const config = { ...toUpdateFormFieldConfigs[x] };
            config.printable = false;
            const requires_resolution = dataByFieldLookup[x]?.requires_resolution;
            const canCreateFieldInfo = !issueFieldMetaChoicesLookup[x]?.disabled;
            if (!config.skipPostComponent && canCreateFieldInfo) { // although this does require manual config - it is simple and flexible.  Anything we might write logic wise
                // would run the risk of quickly needing ammending
                config.PostComponent = memo(() => (
                    <FieldInfoButton
                        setFieldInfo={setFieldInfoInFocus}
                        openingPrep={handleFormSettingsClose}
                        issue_field={x}
                        parentId={incidentId}
                        id={dataByFieldLookup[x]?.id}
                        canCreateFieldInfo={!issueFieldMetaChoicesLookup[x]?.disabled}
                        fieldLabel={config.label || issueFieldMetaChoicesLookup[x]?.display_name}
                        field_redacted={dataByFieldLookup[x]?.field_redacted}
                        requires_resolution={requires_resolution}
                        meta={fieldInfoListMeta}
                        className="no-print"
                        initiallySelectedDataField={initiallySelectedDataField}
                    />))
            }
            toUpdateFormFieldConfigs[x] = config;
        })


    }
    const thisFormFieldsConfig = { ...formFieldsConfig, ...toUpdateFormFieldConfigs }
    return thisFormFieldsConfig;
}