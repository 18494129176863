import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { organisationLookupSelector } from "components/AdminPanel/Organisation/Selectors/Organisations.selectors";
import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";
import { getChoicesSortedByDisplayName } from 'components/Common/Utils/Arrays.js';
import { jobTitlesState } from "components/AdminPanel/People/Selectors/People.selector";
import { JobTitle } from '../Interfaces/People.interface';

export const usePeopleMeta = (peopleStateMeta: FieldMetaGroup): FieldMetaGroup => {
    const organisationLookupState = useSelector(organisationLookupSelector);
    return {
        ...peopleStateMeta,
        organisation: {
            ...peopleStateMeta.organisation,
            type: 'choice',
            choices: organisationLookupState
        }
    }
}

const getJobTitleObjectsAsChoices = (choices: JobTitle[]) => {
    const sortedChoices = getChoicesSortedByDisplayName(choices);
    const convertedChoices = sortedChoices.map(x => ({ ...x, "value": x.id }));
    return convertedChoices
}

export const useSortedJobTitles = () => {
    const selectJobTitlesState = useSelector(jobTitlesState);
    const getJobTitleChoices = useCallback(getJobTitleObjectsAsChoices, [selectJobTitlesState]);
    return getJobTitleChoices(selectJobTitlesState);
}