import UPDATE_PROFILE, {
  GET_PROFILE, CANCEL_PROFILE, SET_PROFILE_ENTITY
} from "../Actions/Profile.actions";

const initialState = {
  showProfile: false,
  data: undefined,
  meta: undefined
};

interface State {
  showProfile: boolean;
  data: any;
  meta: any;
}

const reducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case GET_PROFILE.success:
      return {
        ...state,
        showProfile: action.showProfile,
        ...action.profile,
        data: {
          ...action.profile.data,
          password: ''
        }
      }

    case CANCEL_PROFILE.start:
      return {
        ...state,
        showProfile: false
      }

    case UPDATE_PROFILE.success:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.profile.data,
        },
        showProfile: false
      }

    case UPDATE_PROFILE.reducer:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.profile,
        },
        showProfile: false
      }

    case SET_PROFILE_ENTITY.start:
      return {
        ...state,
        data: { ...action.profile },
      }

    default:
      return state;
  }
}

export default reducer;