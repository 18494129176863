import React, { useState, useEffect } from 'react';
import DataGrid, { Column, Paging, Editing, Form, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import path from "path";

// Own
import API from "services/API/API";
import { ColumnsConfig } from "components/ContractInFocus/Interfaces/DataGridColumn.interface";
import { logError } from "services/Log/Log.service";
import { getDataAndMetaAndPUTMeta } from "services/API/API.helper";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { openPanelInSelectionMode } from "components/AdminPanel/Helpers/AdminPanel.helper";
import { adminPanelType } from 'components/AdminPanel/Models/AdminPanel.model.js';
import contractServices from "components/AdminPanel/Contracts/Services/contractService";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { ContractIdOrPortfolioId } from "services/API/common/contractAPIs";

// Styles
import { getColumnPropsIfFoundInMeta } from 'helpers/DataGrid/DataGridColumn.helper';

import './contractReportVisibility.scss';

interface limitControls {
    [documentType: string]: (documentType: string, parentId: string) => boolean;
}

export interface formValues {
    [field: string]: string | number | boolean | null | undefined; //represents field: actual value
}

export const getDistributionListApi = async (
    url: string,
    setMetaInfo: React.Dispatch<any>,
    filters?: {
        [name: string]: string;
    }
) => {

    try {
        const response = await API.get(url, { params: filters });
        const { data, ...metainfo } = getDataAndMetaAndPUTMeta(response);
        if (typeof (metainfo.misc) == "undefined") {
            metainfo.misc = {}
        }
        metainfo.misc.numDocs = data.length;
        setMetaInfo && setMetaInfo(metainfo);
        // console.log('getDistributionListApi data: ', data);
        return data;
    } catch (err) {
        logError(`GET_${url}_error:${JSON.stringify(err)}`);
        return [];
    }
};

export const deleteDocumentApi = async (baseUrl: string, id: string | number) => {
    const url = path.join(baseUrl, `${id}`);
    try {
        return await API.delete(url);
    } catch (err) {
        logError(`DELETE_${url}_error:${JSON.stringify(err)}`);
        return null;
    }
};

interface ConstructHandleDistributionListSubmitProps extends ContractIdOrPortfolioId {
    datasource?: any;
}

export function constructHandleDistributionListSubmit(props: ConstructHandleDistributionListSubmitProps) {
    const contractId = props.contractId;
    const portfolioId = props.portfolioId;
    if (contractId && portfolioId) {
        alert("Cannot add to the distribution list as both a contract AND a portfolio are defined in this context");
    }
    return (peopleIds: number[]) => {
        const accessObjs = peopleIds.map((personId) => (
            {
                "person": personId,
                "contract": contractId,
                "portfolio": portfolioId
            }
        ));

        return contractServices.addToMaintenanceReportDistribution({ contractId: props.contractId, contractRef: props.contractRef, portfolioId: portfolioId, accessObjs: accessObjs })
            .then((response: any) => {
                props.datasource && props.datasource.reload()
            });

    }
}

interface DistributionGridProps {
    listBasePath: string;
    columnsConfig: ColumnsConfig;
    orderable?: boolean;
    handleOnReorder?: (e: any, callback: () => void) => void;
    signalRefresh?: any;
    contract?: SiteContract;
    portfolio?: HydratedPortfolio;
    fetchFilters?: {
        [name: string]: string;
    };
    //launchAddRecipients: MutableRefObject<((e: any) => void) | undefined>;
}

const ReportDistributionListGrid = (
    {
        listBasePath,
        columnsConfig,
        orderable,
        handleOnReorder,
        contract,
        portfolio,
        fetchFilters,
        signalRefresh,
    }: DistributionGridProps) => {

    const [basePath, setBasePath] = useState(listBasePath);
    const [metaForUpdate, setMetaForUpdate] = useState<any>();
    const [datasource, setDatasource] = useState<any>();

    const handleDistributionListSubmit = constructHandleDistributionListSubmit({ contractId: contract?.id, contractRef: contract?.contract_ref, portfolioId: portfolio?.id, datasource });

    const handlePersonLink = (): void => {
        (portfolio || contract) && !(portfolio && contract) && openPanelInSelectionMode(
            {
                selectActionIdentifier: "distribution",
                masterRecordType: `${portfolio ? 'portfolio' : 'contract'}`,
                masterRecordData: portfolio || contract,
                linkedRecordType: adminPanelType.people,
                onAddLinks: handleDistributionListSubmit,
                currentMemberIds: datasource.items().map((x: any) => x.person),
                linkPanelTitle: `Add to distribution list for ${contract ? "contract " + contract?.contract_ref : "portfolio: " + portfolio?.name}`,
            }
        )
    };

    const handleRemoveRecipientFromDistribution = (accessObj: any) => {
        contractServices.removeRecipientFromMaintenanceReportDistribution(
            {
                contractRef: contract?.contract_ref,
                portfolioId: portfolio?.id,
                accessId: accessObj.row?.data?.id
            }
        ).then(
            () => {
                datasource && datasource.reload()
            }
        );
    };

    function handleOnCellPrepared(e: any) {
        if (e.rowType === 'data' && e.column.command === 'edit')
            if (e.row.data.fp_file_id != null) {
                e.cellElement.find('a.dx-link-delete').remove();
            }
    }

    useEffect(() => {
        if (signalRefresh > 0) {
            setTimeout(() => {
                // NB this timeout is just for aesthetic reasons (to allow the upload animation to complete first) 
                // - the new document will be there even if the datasource were to be loaded outside of this timeout
                //@ts-ignore
                datasource && datasource.reload();
            }, 2000)
        }
    }, [signalRefresh, datasource]);

    useEffect(() => {
        const custom = new CustomStore({
            key: "id",
            load: loadOptions => getDistributionListApi(basePath, setMetaForUpdate, fetchFilters).then(
                (response) => {
                    const data = response.map((x: any) => ({ ...x, subscribed: x.subscribed ? 'Yes' : 'No' }))
                    return (new Promise((resolve, reject) => {
                        resolve(data);
                    }));
                }
            )
        });

        setDatasource(
            // @ts-ignore
            new DataSource({
                store: custom
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const localHandleOnReorder = (e: any) => {
        handleOnReorder && handleOnReorder(e, () => {
            //@ts-ignore
            datasource && datasource.reload();
        });
    }

    const hasMeta = () => metaForUpdate?.meta && !!Object.keys(metaForUpdate?.meta).length

    const docsPresent = () => metaForUpdate && metaForUpdate.misc.numDocs && metaForUpdate.misc.numDocs > 0;

    const canPut = metaForUpdate?.permissions.PUT;
    const canDelete = metaForUpdate?.permissions.DELETE;

    const handleEditingStart = (e: any) => {
        const field = e.column.dataField as string;
        e.cancel = !columnsConfig[field]?.allowEditing;
    }

    const submitLinksButtonConfig = false;

    const toolbarPrep = (e: any) => {

        handlePersonLink &&
            e.toolbarOptions.items.push({
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "add",
                    onClick: handlePersonLink,
                },
            });

        submitLinksButtonConfig &&
            e.toolbarOptions.items.unshift(submitLinksButtonConfig);
    };
    //return 

    return <DataGrid className="distributionListContainer" //dx-datagrid-jt"
        dataSource={datasource}
        columnAutoWidth={true}
        showBorders={true}
        height="100%"//{canWrite ? 320 : '100%'}
        rowAlternationEnabled={true}
        onEditingStart={handleEditingStart}
        onCellPrepared={handleOnCellPrepared}
        onToolbarPreparing={toolbarPrep}
        rowDragging={
            {
                allowReordering: orderable,
                onReorder: localHandleOnReorder
            }
        }
    >
        <Paging enabled={false} />
        <SearchPanel
            visible={true}
            placeholder="Search..."
            searchVisibleColumnsOnly={true}
            width={500}
        />
        <HeaderFilter visible={true} />
        <Editing
            mode="cell"
            allowUpdating={canPut}
            allowDeleting={canDelete}
        />

        {hasMeta()
            ?
            Object.keys(columnsConfig).map((dataField) => {
                const columnConfig = columnsConfig[dataField];
                // note columnConfig overrides Meta here...
                const columnProps = { ...getColumnPropsIfFoundInMeta(dataField, metaForUpdate.putMeta), ...columnConfig }
                return (columnProps && <Column
                    key={dataField}
                    {...columnProps}
                    visible={columnConfig.visible === false ? false : dataField !== 'internal_access_only'}
                    width={columnConfig.width}
                    caption={columnConfig.caption ? columnConfig.caption : columnProps.caption}
                />)
            }
            )
            : null}
        <Column
            alignment="right"
            type="buttons"
            width={140}
            visible={hasMeta()}
            buttons={[
                {
                    key: "unlink-person",
                    icon: "fas fa-unlink",
                    dataField: "id",
                    onClick: handleRemoveRecipientFromDistribution,
                },
            ]} />
    </DataGrid>
}
//ReportDistributionListGrid.whyDidYouRender = true;

export default ReportDistributionListGrid;