/* eslint-disable */
import React, { useState, useCallback, useEffect } from 'react';

// Own
import { Visit, SupplierService } from '../../../Interfaces/Schedule.interfaces';
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { TabbedPanel } from "components/Common/Components/TabPanel";
import { ScheduleResponse } from 'components/Schedulers/Interfaces/Schedule.interfaces';
import VisitDocumentsContainer from "components/Schedulers/VisitModal/Components/VisitDetail/VisitDocumentsContainer";
import WorkPermits from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/WorkPermits";
import { getDimTitle } from "helpers/Forms/modal.helper";
import { getInWordDateFormat } from 'components/Common/Utils/Dates';

interface VisitDetailProps {
    initialVisit?: Visit;
    visitIndex: number;
    setVisitIndex: React.Dispatch<React.SetStateAction<number>>;
    visitTabs: string[];
    portfolio?: HydratedPortfolio;
    defaultVisitDate?: Date;
    supplierService?: SupplierService;
    setModalTitle: React.Dispatch<React.SetStateAction<(() => JSX.Element) | undefined>>;
    defaultNewItemDate: React.MutableRefObject<Date | undefined>
}

const VisitDetailView = ({
    portfolio, initialVisit, supplierService, setModalTitle, visitIndex, setVisitIndex, visitTabs, defaultNewItemDate
}: VisitDetailProps) => {
    const [visit, setVisit] = useState<Visit | undefined>(initialVisit);

    const getModalTitle = useCallback(() => {
        return () => <>
            {supplierService ? <div className="detail-modal--title">
                Visit
                {getDimTitle('by')}
                {supplierService.contractor.name}
                {getDimTitle('to')}
                {supplierService.equipment_name}
                {getDimTitle('scheduled for')}
                {getInWordDateFormat(visit?.scheduled_for)}
            </div> : null}
        </>
    }, [visit, supplierService])

    useEffect(() => {
        setModalTitle(getModalTitle)
    }, [supplierService, visit, getModalTitle, setModalTitle])

    return <>
        <TabbedPanel
            pageIndex={visitIndex}
            showTabs={true}
            onChangeTab={(index: number) => { setVisitIndex(index) }}
            tabs={visitTabs}
        >
        </TabbedPanel>
        {supplierService &&
            <>
                {
                    visitIndex == 0 && <VisitDocumentsContainer
                        portfolio={portfolio}
                        initialVisit={visit}
                        //@ts-ignore
                        defaultNewItemDate={defaultNewItemDate.current}
                        supplierService={supplierService}
                        setModalTitle={setModalTitle}
                    />
                }
                {
                    visitIndex == 1 && !supplierService.contract__disable_work_permits_system && <WorkPermits
                        visit={visit}
                        title='Work Permits'
                    />
                }
            </>
        }
    </>
}

export default VisitDetailView;


