import { ContractPeriod } from 'components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface';
import { FrozenHistoryBranch, Snapshot } from "components/ContractInFocus/interfaces/contractInFocus.interfaces";
import { Dictionary } from "components/Common/Interfaces/Entity.interface";
import { dateUDF, fromSaveDateFormatMoment } from 'components/Common/Utils/Dates';
import { ContractPeriodDates } from "components/ContractInFocus/interfaces/contractInFocusActions.interfaces";
import { map } from "rxjs/operators";
import { ContractScope } from './Interfaces/contractScopeInterfaces';
import moment, { Moment } from 'moment';

const pluckSnapshotFromDictionary = (snapshotHistory: FrozenHistoryBranch) => snapshotHistory.data[snapshotHistory.focus];


export const contractScopeOperators = (contractScope: ContractScope, useSnapshot: boolean = true) =>
  map((action: any) => {
    const { focusedContractPeriod, frozenHistory } = contractScope;

    if (!!focusedContractPeriod && frozenHistory.data && Object.keys(frozenHistory.data).length) {
      const response = {
        ...action,
        payload: {
          ...action.payload,
          params: getContractScopeParams(focusedContractPeriod, useSnapshot ? pluckSnapshotFromDictionary(frozenHistory) : undefined)
        }
      }

      return response;
    } else {
      return action;
    }
  });


export const getContractScopeParamsComposeSnapshot = (contractPeriod: ContractPeriod, snapshotHistory?: FrozenHistoryBranch): Dictionary<string> => {
  return getContractScopeParams(contractPeriod, snapshotHistory ? pluckSnapshotFromDictionary(snapshotHistory) : undefined);
}

/**
 * https://docs.google.com/spreadsheets/d/1wW9NkQCC3BDB7RjR8N-aQOK-55csRZpTuAokbU8k7mI/edit?usp=sharing
 * Get the contract query scope (3 different FE modes)
 * Mode 1: no need for scoping
 * Mode 2: current; scoping on contract period because snapshot is undefined
 * Mode 3: snapshot: scoping based on snapshot if speficied, otherwise on contract period.  
 * For mode 3, items like total ppm and all maintenance tables, spend aggregate, visitis are contract period scoped so 
 * they won't use snapshot scoping (windowing)
 * @param contractPeriod: use as the default scope unless snapshot is specified
 * @param snapshot: will be unset when in current mode, or might be unset if scoping is only concerned about contract period
 */
export const getContractScopeParams = (contractPeriod?: ContractPeriod | ContractPeriodDates, snapshot?: Snapshot): Dictionary<string> => {
  if (snapshot) {
    const frozenForDate = fromSaveDateFormatMoment(snapshot.frozen_for);
    return {
      'start-date': frozenForDate.startOf('month').format(dateUDF),
      'end-date': frozenForDate.endOf('month').format(dateUDF)
    }
  }
  if (!contractPeriod) {
    return {}
  }
  return {
    'start-date': fromSaveDateFormatMoment(contractPeriod.start_date).startOf('month').format(dateUDF),
    'end-date': fromSaveDateFormatMoment(contractPeriod?.end_date).endOf('month').format(dateUDF)
  }
}

export const getLastXMonthsParams = (months: number, startDate?: string, endDate?: string, snapshot?: Snapshot): Dictionary<string> => {

  let endMoment = snapshot ? fromSaveDateFormatMoment(snapshot.frozen_for) : endDate ? moment(endDate) : moment();
  let startMoment = startDate ? moment(startDate) : endMoment.clone()
  startMoment.subtract(months, 'months');
  const dates = {
    'start-date': startMoment.startOf('month').format(dateUDF),
    'end-date': endMoment.endOf('month').format(dateUDF)
  }
  return dates
}

type queryParamsType = {
  [key: string]: any
}

const isNotEmpty = (value: any) => value !== undefined && value !== null && value !== '';

export const getRemoteOperationParams = (loadOptions: any) => {

  let params: queryParamsType = {};
  [
    'filter',
    'group',
    'groupSummary',
    'parentIds',
    'requireGroupCount',
    'requireTotalCount',
    'searchExpr',
    'searchOperation',
    'searchValue',
    'select',
    'sort',
    'skip',
    'take',
    'totalSummary',
    'userData'
  ].forEach(function (i) {
    if (i in loadOptions && isNotEmpty(loadOptions[i])) {
      //params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      params[i] = JSON.stringify(loadOptions[i])
    }
  });
  return params
}

export interface remoteOpsAndContractScopeParamsI {
  loadOptions: any;
  selectedPeriod?: ContractPeriod | ContractPeriodDates;
  selectedSnapshot?: Snapshot;
  contractReportContext?: boolean;
  startDate?: string;
  endDate?: string;
}

export const getRemoteOpParamsAndContractScopeParams = ({
  loadOptions,
  selectedPeriod,
  selectedSnapshot,
  contractReportContext,
  startDate,
  endDate,
}: remoteOpsAndContractScopeParamsI) => {
  let contractScopeParams = getContractScopeParams(selectedPeriod, selectedSnapshot)

  if (startDate) {
    contractScopeParams['start-date'] = startDate; //explicit startDate and endDate effectively override other calculated params
  }
  if (endDate) {
    contractScopeParams['end-date'] = endDate;
  }

  // note that setting start and end dates doesn't control whether we're looking at 'frozen' data - that is handled by an axios interceptor

  const remoteOperationParams = getRemoteOperationParams(loadOptions);
  const cRC = { 'contract_report_context': contractReportContext }
  return { ...remoteOperationParams, ...contractScopeParams, ...cRC }
}