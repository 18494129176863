import React from 'react';
import { useSelector } from 'react-redux';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import SettingsIcon from '@material-ui/icons/Settings';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description';

// Own
import { MatIconButton } from 'components/Common/Components/Material/MatIconButton/MatIconButton';
import { Snapshot } from 'components/ContractInFocus/interfaces/contractInFocus.interfaces';
import { isSnapshotReportOutOfDate } from 'components/ContractInFocus/Helper/contractInFocus.helper';
import {
  contractInFocusACLSelector,
  needsPersonalContractSettingsModalSelector,
  portfolioInFocusACLSelector,
  needsPersonalPortfolioSettingsModalSelector
} from "components/ContractInFocus/Selectors/ACL.selectors";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

// Styles
import styles from 'styles/app/common/variables.scss';
import './ContractQuickLinks.scss';

type ContractQuickLinksProps = {
  contract?: SiteContract
  portfolio?: HydratedPortfolio
  snapshot?: Snapshot;
  onShowFreezeDialog: (show: boolean) => void;
  onOpenContractFiles: () => void;
  onOpenContractReport: () => void;
  onOpenGeneralPersonalContractSettings: (show: boolean) => void;
  showSnapshot: boolean;
  enableSnapshot: boolean;
  enableReportLink: boolean;
}

export const ContractQuickLinks: React.FC<ContractQuickLinksProps> = ({
  contract,
  portfolio,
  snapshot,
  onShowFreezeDialog,
  onOpenContractFiles,
  onOpenContractReport,
  onOpenGeneralPersonalContractSettings,
  showSnapshot,
  enableSnapshot,
  enableReportLink
}) => {
  const CIFACL = useSelector(contractInFocusACLSelector);
  const PIFACL = useSelector(portfolioInFocusACLSelector);
  const needsPersonalContractSettings = useSelector(needsPersonalContractSettingsModalSelector);
  const needsPersonalPortfolioSettings = useSelector(needsPersonalPortfolioSettingsModalSelector);
  const canReadReport = portfolio ? PIFACL?.report?.canRead : contract ? CIFACL?.report?.canRead : false;
  const needsPersonalSettingsModal = portfolio ? needsPersonalPortfolioSettings : needsPersonalContractSettings;
  // right now canReadReport pretty much co-incides with the need to view personal contract settings and contract files generally
  // (this may need review if things change). For example someone only viewing/editing PTWs doesn't need any of that

  return (
    <>
      {showSnapshot &&
        <MatIconButton
          disabled={!enableSnapshot}
          hint='Take snapshot'
          onClick={() => onShowFreezeDialog(true)}
        >

          <AddAPhotoIcon className='quick-links__icon' />
        </MatIconButton>
      }
      {canReadReport && <MatIconButton hint='Files' onClick={onOpenContractFiles}>
        <FolderIcon className='quick-links__icon' />
      </MatIconButton>}
      {snapshot &&
        <MatIconButton
          hint='Report Link'
          disabled={!enableReportLink}
          onClick={onOpenContractReport}
        >
          <DescriptionIcon
            className='quick-links__icon'
            style={{ color: isSnapshotReportOutOfDate(snapshot) ? styles.dangerDark : styles.darkBlue }}
          />
        </MatIconButton>

      }
      {needsPersonalSettingsModal && <MatIconButton
        hint='Open Your Contract Settings'
        onClick={() => onOpenGeneralPersonalContractSettings(true)}
      >
        <SettingsIcon className='quick-links__icon' />
      </MatIconButton>}

    </>
  );
}