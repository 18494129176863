import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Wrapper, FlexCenterWrapper } from "./FlagsStyles";
import { GeneralTooltip } from "components/Common/Components/InfoHint/InfoHint";

interface CompletedProps {
  count?: number;
  style: any;
  color: any;
  warn?: string;
}

const Completed = ({ count, style, color, warn }: CompletedProps) => {
  if (warn) {
    return (
      <GeneralTooltip title={`${warn}`} >
        <Wrapper className={`specialist-flag ${warn ? 'warn' : ''}`} style={{ ...style, color }}>
          <FontAwesomeIcon icon={faCheck} style={{ color }} />
          {/* {!warn && <FontAwesomeIcon icon={faCheck} style={{ color }} />} */}
          {count ? <FlexCenterWrapper>{count}</FlexCenterWrapper> : null}
        </Wrapper>
      </GeneralTooltip >
    );
  } else {
    return (
      <Wrapper className={`specialist-flag ${warn ? 'warn' : ''}`} style={{ ...style, color }}>
        <FontAwesomeIcon icon={faCheck} style={{ color }} />
        {/* {!warn && <FontAwesomeIcon icon={faCheck} style={{ color }} />} */}
        {count ? <FlexCenterWrapper>{count}</FlexCenterWrapper> : null}
      </Wrapper>
    );
  }

};

Completed.propTypes = {
  count: PropTypes.number,
  style: PropTypes.object,
  color: PropTypes.string
};

export default Completed;
