import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

// Own
import ReportVisibilityTab from './Components/ReportVisibilityTab/ReportVisibilityTab';
import { mainClientVisibilitySettingsObjectsSelector } from 'components/ContractInFocus/Selectors/visibility.selectors';
import { useGetContractReportingMenu, useGetPortfolioReportingMenu } from 'components/Layout/Contract/Containers/ContractSidebar/contractSidebarReportingMenu';
import { VisibilityControls } from 'components/Common/Components/RightVisibilityMenu/Components/VisibilityControl/VisibilityControl';
import { setClientStaticOpsDataVisibilitySettings } from 'components/ContractInFocus/Actions/contractVisibilitySettings.actions';
import { portfolioInFocusPortfolio } from "components/PortfolioInFocus/Selectors/portfolioInFocus.selectors";
import { portfolioHydratedFactory } from "components/Portfolios/Selectors/Portfolio.selectors";
import * as selectors from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import { reportVisibilityModalOpenSelector } from "store/selectors/settings.selector";
import { changeSetting } from "store/actions/actions";

// Styles
import styles from "styles/app/common/variables.scss";
import './contractReportVisibility.scss';

interface ReportVisibilityProps {
    onModalClose?: () => void;
}

export const ReportVisibility: React.FunctionComponent<ReportVisibilityProps> = ({
    onModalClose,
}) => {

    const dispatch = useDispatch();

    const contractReportDialogTitle = 'Visibility Controls';
    const primaryActiveTab = 'Planned';
    const baseClientVisibilitySettings = useSelector(mainClientVisibilitySettingsObjectsSelector);
    const canWrite: boolean = !!baseClientVisibilitySettings.permissions?.PUT;

    const open = !!useSelector(reportVisibilityModalOpenSelector);

    const contract = useSelector(selectors.contractInFocusContractDataSelector);
    const portfolioInFocusState = useSelector(portfolioInFocusPortfolio);
    const portfolio = useSelector(portfolioHydratedFactory(portfolioInFocusState.id))?.data;

    const [activeTabName, setActiveTabName] = useState(primaryActiveTab);
    const [visibilityMenu, setVisibilityMenu] = useState<any>();
    const selectFrozenFor = useSelector(selectors.contractInFocusFrozenForSelector);

    const contractMenu = useGetContractReportingMenu(contract).filter(menuItem => menuItem.menu !== 'Report');
    const portfolioMenu = useGetPortfolioReportingMenu(portfolio).filter(menuItem => menuItem.menu !== 'Report');
    const menu = portfolio ? portfolioMenu : contractMenu;

    const handleClose = () => {
        dispatch(changeSetting("reportVisibilityModalOpen", false));
    }

    const defaultToggleVisibilityControls = (updateKey: string, appliedTo: any): void => {
        //default because if a custom toggle function is provided in the visibility settings, it will run that instead (VisibilityControl takes care of that)
        //HT HOW: this will become the default that may be overridden in visibility controls
        dispatch(setClientStaticOpsDataVisibilitySettings(updateKey, appliedTo));
    }

    const handleTabClick = (tabMenu: string) => {
        setActiveTabName(tabMenu);
        const selectedMenu = menu.find(item => item.menu === tabMenu);
        selectedMenu && setVisibilityMenu(() => selectedMenu.reportVisibilityFilter);
    }

    return (
        <>

            {
                (portfolio && contract) ? <></> :

                    <Dialog
                        id="contractReportVisibility"
                        open={open}
                        onClose={handleClose}
                        onExited={onModalClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="contractReportVisibilityTitle">{contractReportDialogTitle}</DialogTitle>
                        <DialogContent>
                            <div className="visibility-dialog__content">
                                <ReportVisibilityTab
                                    onTabClick={handleTabClick}
                                    activeTabName={activeTabName}
                                    tabs={menu}
                                />
                                <div style={{ maxWidth: '400px', paddingTop: '.5rem' }}>
                                    {baseClientVisibilitySettings.data && visibilityMenu &&
                                        <VisibilityControls
                                            appliedTo={portfolio || contract}
                                            className="visibility-dialog__controls"
                                            canWrite={canWrite}
                                            panelLayout={true}
                                            //HT HOW - this allows us to specify how to update - not just the current state - here we need to grab other functions for more dynamic cases
                                            visibilitySettings={visibilityMenu({
                                                visibilitySettings: baseClientVisibilitySettings.data,
                                                contract,
                                                portfolio,
                                                inReportContext: true,
                                                frozenFor: selectFrozenFor
                                            }
                                            )}
                                            defaultOnClick={defaultToggleVisibilityControls}
                                            color={styles.blueDark}
                                        />
                                    }
                                </div>

                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
            }
        </>
    );
}