import { Person } from "../Interfaces/People.interface";
import { getLookup } from "./People.reducer.helper";
import { retainFocusedRecord, getLookupArray, fetchReducer } from "components/AdminPanel/Helpers/Entity.helper";
import { arrayToDict } from "services/API/API.helper";
import { AdminState } from "../../Interfaces/Admin.state";
import { Dictionary } from "components/Common/Interfaces/Entity.interface";
import { isStateEqual } from "store/reducers/reducer.helper";
import {
  removePsuedoRecordFromState,
  createPsuedoRecord,
  mergeChildObjectsByKey,
  prepareMainListViewsAfterCreate
} from "components/AdminPanel/Reducers/adminReducer.helper";

import * as actions from "../Actions/People.actions";
import isEqual from 'lodash/isEqual';


const initialState = {
  data: {},
  lookup: {},
  recordmeta: {},
  jobTitles: [],
  meta: {
    PUT: undefined,
    POST: undefined
  },
  options: []
}

interface PersonState extends AdminState {
  data: Dictionary<Person>;
}

const peopleReducer = (state: PersonState = initialState, action: any) => {
  switch (action.type) {
    case actions.FETCH_PEOPLE.success: {
      return fetchReducer(state, action, 'people', getLookup);
    }

    case actions.SET_GROUP_PERSON.reducer: {

      return isStateEqual(state.data, action.personGroup.data) && state.recordmeta &&
        isStateEqual(state.recordmeta, action.personGroup.meta)
        ? state
        : {
          ...state,
          data: mergeChildObjectsByKey(state.data, action.personGroup.data),
          recordmeta: {
            ...state.recordmeta,
            ...action.personGroup.meta,
          }
        }
    }

    case actions.REFRESH_PERSON_META.success: {
      return {
        ...state,
        meta: {
          ...state.meta,
          POST: action.meta
        }
      }
    }

    case actions.CREATE_PSUEDO_PERSON.reducer: {
      return createPsuedoRecord(state);
    }

    case actions.CLEAR_PSUEDO_PERSON.reducer: {
      return removePsuedoRecordFromState(state);
    }


    case actions.ADD_TO_PEOPLE.reducer: {

      const lookup = getLookupArray(action.people.data, getLookup);
      const dictionary = arrayToDict(action.people.data);

      return {
        ...state,
        data: retainFocusedRecord(state.data, dictionary, true),
        meta: state.meta,
        lookup
      };
    }

    case actions.SET_PERSON.success: {
      const { preparedObj, preparedData } = prepareMainListViewsAfterCreate(state.data, action.person, action);
      return {
        ...state,
        data: {
          [action.person.id]: preparedObj,
          ...preparedData,
        },
        lookup: {
          ...state.lookup,
          [action.person.id]: getLookup(action.person)
        },
        meta: {
          ...state.meta,
          PUT: action.meta || state.meta.PUT
        },
        recordmeta: {
          ...state.recordmeta,
          [action.person.id]: { ...action.meta },
        },
        options: action.options,
        permissions: {
          ...state.permissions,
          [action.person.id]: action.permissions
        },
      };
    }

    case actions.SET_PERSON.reducer: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.person.id]: {
            ...action.person,
          },
        },
        lookup: {
          ...state.lookup,
          [action.person.id]: getLookup(action.person)
        }
      };
    }

    case actions.DELETE_PERSON.success: {

      delete state.data[action.personId];

      return {
        ...state,
        data: {
          ...state.data,
        }
      };
    }

    case actions.SET_PERSON_JOBTITLE.reducer: {
      return {
        ...state,
        jobTitles: action.jobTitles
      };
    }


    case actions.DELETE_PERSON.reducer: {

      delete state.data[action.personId];
      delete state.lookup[action.personId];

      if (state.recordmeta) {
        delete state.recordmeta[action.personId];
      }

      return {
        ...state,
        data: {
          ...state.data,
        }
      };
    }

    default:
      return state;
  }
};

export default peopleReducer;
