import React, { memo } from 'react'

import { SubHeading, SubSmall } from 'components/Layout/Contract/Components/ContractSubSite/ContractSubHeadingSiteStyles.js';
import { PortfolioPeriod } from 'components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface';
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

interface PortfolioSubHeadingTitleProps {
    selectedPortfolio: HydratedPortfolio;
    portfolioPeriods: PortfolioPeriod[];
}

const PortfolioSubHeadingTitle: React.FC<PortfolioSubHeadingTitleProps> = ({ selectedPortfolio, portfolioPeriods }) => {
    return (
        <div>
            <SubHeading>
                {selectedPortfolio.name}
            </SubHeading>
            <SubSmall>
                {selectedPortfolio.contracts.length} contracts &amp; {portfolioPeriods.length} periods
            </SubSmall>
        </div>
    )
}

export default memo(PortfolioSubHeadingTitle);