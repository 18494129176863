import * as actions from "components/AdminPanel/ContractPeriods/Actions/ContractPeriods.actions";

import { AdminNormState } from "../../Interfaces/Admin.state";
import { PortfolioPeriod } from "components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface";

const initialState = {
    data: {},
    meta: {},
    permissions: {
        PUT: false,
        POST: false,
        DELETE: false
    } // not record meta and only gets set once
}

export type PortfolioPeriodState = AdminNormState<PortfolioPeriod>;

const portfolioPeriodsReducer = (state: PortfolioPeriodState = initialState, action: any) => {
    switch (action.type) {
        case actions.FETCH_PORTFOLIO_PERIOD.reducer: {
            const newMeta = action.meta || state.meta;
            const newPermissions = action.permissions || state.permissions;
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.portfolioPeriods
                },
                meta: {
                    ...newMeta
                },
                permissions: {
                    ...newPermissions
                }
            };
        }

        case actions.DELETE_PORTFOLIO_PERIOD.reducer: {
            delete state.data[action.id];
            return state;
        }

        case actions.CREATE_PORTFOLIO_PERIOD.reducer:
        case actions.UPDATE_PORTFOLIO_PERIOD.reducer: {
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.portfolioPeriod.id]: action.portfolioPeriod
                }
            };
        }

        default:
            return state;
    }
};

export default portfolioPeriodsReducer;
