import React, { memo, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import { Button } from 'reactstrap';

import {
  StyledAvatar,
  StyledWrapper,
  StyledImageWrapper,
  StyledPasswordButtonWrapper,
  StyledAvatarActionLabel,
  StyledAvatarActionContainer,
  useProfileAvatarStyles
} from './ProfileAvatarStyles';

import FileUploader from '../../../Common/Components/Input/FileUploader';
import { updateAvatarApi, clearAvatarApi } from '../../Services/ProfileAvatarService';
import { Profile } from '../../Interfaces/Profile.interface';
import { Avatar } from '@material-ui/core';

interface ProfileAvatarProps {
  data: Profile;
  showPassword: boolean;
  onAvatarUpdate: { (avatar: any): void };
  onShowPassword: { (): void };
}

const ProfileAvatar = ({
  onAvatarUpdate,
  onShowPassword,
  showPassword,
  data
}: ProfileAvatarProps) => {

  const classes = useProfileAvatarStyles();

  const [showAvatarUpload, setShowAvatarUpload] = useState(false);

  const onUpload = (file: any, progress: any) => {
    updateAvatarApi(file, progress).subscribe((profile: Profile) => {
      onAvatarUpdate(profile.avatar);
      setShowAvatarUpload(false);
    });
  }

  const onClear = () => {
    clearAvatarApi().subscribe((profile: Profile) => {
      onAvatarUpdate(profile.avatar);
    });
  }

  const renderLabel = (): string =>
    `<div class="filepond-label">Drag and drop your avatar here or click</div> 
     <div class="filepond--label-action"> upload</div>`;

  const handleChangeAvatarClick = (): void => {
    setShowAvatarUpload(true);
  }

  const renderAvatar = (avatar: string) => (
    <StyledAvatar>
      {avatar
        ? <img alt="Profile Avatar" src={`${avatar}`} />
        : <Avatar alt={`${data.first_name} ${data.last_name}`} className={classes.avatar} />
      }
      <StyledAvatarActionContainer>
        <StyledAvatarActionLabel onClick={handleChangeAvatarClick}>
          <EditIcon />
          {avatar ? 'Change' : 'Add Avatar'}
        </StyledAvatarActionLabel>
        {avatar
          ? <StyledAvatarActionLabel onClick={() => onClear()}>
            <DeleteIcon />
            Remove
          </StyledAvatarActionLabel>
          : null
        }
      </StyledAvatarActionContainer>
    </StyledAvatar>
  )

  const noAvatar = (profile: Profile): boolean => !profile.avatar || !profile.avatar.thumbnail;

  const renderFileUploader = () => (
    <StyledAvatar>
      <FileUploader
        labelIdle={renderLabel()}
        onUpload={onUpload}
        ready={true}
      />
      {!noAvatar(data)
        ? (
          <StyledAvatarActionLabel
            marginTop="-.5rem"
            onClick={() => setShowAvatarUpload(false)}
          >
            <BlockIcon /> Cancel
          </StyledAvatarActionLabel>
        )
        : null
      }
    </StyledAvatar>
  )

  return (
    <>
      <StyledWrapper id="profileAvatar">
        <StyledImageWrapper>
          {!data.avatar || showAvatarUpload
            ? renderFileUploader()
            : renderAvatar(data.avatar.thumbnail)
          }
        </StyledImageWrapper>
        <StyledPasswordButtonWrapper>
          <Button onClick={onShowPassword} color="secondary">
            {showPassword ? "Hide Password" : "Update Password"}
          </Button>
        </StyledPasswordButtonWrapper>
      </StyledWrapper>
    </>
  );
}

export default memo(ProfileAvatar);