import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { withResizeDetector } from "react-resize-detector";
import {
  SiteIndexWrapper,
  StyledSiteSub,
  StyledSiteTitle,
  StyledImageWrapper,
  StyledTextWrapper
} from "./SiteFrontCardStyles";
import { SiteIndex } from "../../Interfaces/Sites.interface";
import IconButton from "../../../Common/Components/IconButton/IconButton";
import { useWindowSize } from "../../../Common/Hooks/useWindowSize";
import { selectHeader } from '../../../Layout/selectors/Layout.selectors';
import { useSelector } from 'react-redux';
import { HighlightText } from '../../../Common/Components/HighlightText/HighlightText';

import styles from '../../../../styles/app/common/variables.scss';
import "../../../../styles/app.scss";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    minHeight: "200px",
    maxWidth: "540px",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden"
  }
}));

interface SiteCardProps {
  site: SiteIndex;
  width: number;
  height?: number;
  onFavourite: { (): void };
}

const SiteFrontCard = ({
  site,
  width,
  onFavourite
}: SiteCardProps) => {
  const classes = useStyles();
  const selectedHeader = useSelector(selectHeader);
  const [windowWidth] = useWindowSize();

  const handleFavourite = (event: MouseEvent) => {
    event.stopPropagation();
    onFavourite();
  };

  return (
    <SiteIndexWrapper
      className="fadeIn"
      favourite={site.favourite}
    >
      <Paper elevation={3} className={classes.paper}>
        <IconButton
          onClick={(id, button, event) => handleFavourite(event)}
          icon="fas fa-star"
          margin="none"
          active={true}
          style={{ zIndex: 3 }}
        />
        <StyledImageWrapper width={windowWidth}>
          <img alt={`Building ${site.name}`} src={site.site_image?.thumbnail} />
        </StyledImageWrapper>
        <StyledTextWrapper>
          <StyledSiteTitle width={width}>
            <HighlightText
              color={styles.brandGoldDarker}
              text={site.name}
              matchText={selectedHeader.search}
            />
          </StyledSiteTitle>
          <StyledSiteSub>
            {" "}
            {site.site_ref} - {site.site_contracts?.length} Contract(s){" "}
          </StyledSiteSub>
        </StyledTextWrapper>
      </Paper>
    </SiteIndexWrapper>
  );
};

export default withResizeDetector(memo(SiteFrontCard));
