/* 
  This is a custom devextreme datagrid with a high level of complexity.
  Each row is identified by issue_category
  Each row has 2 virtual calculated cells
  Adding a new row is not data aware but virtual (on refresh it will vanish)
  Options are limited by unselected list through calculation made at the beginning and when you click the add button

  Review mock data to get an idea of the structure
  /components/ContractInFocus/Maintenance/Mocks/reactiveByCategory.mocks.ts
*/

import React, { useEffect, useState, memo, useRef, useCallback } from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { ArchivedIcon, UnArchivedIcon } from 'components/Common/Components/FileTypeIcon/FileTypeIcon';
import InfoIcon from "@material-ui/icons/Info";

import { useSelector } from "react-redux";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, { KeyboardNavigation, Column, Editing, Grouping, Paging, Lookup, Summary, TotalItem, DataGridHeaderFilter } from "devextreme-react/data-grid";
import { withResizeDetector } from "react-resize-detector";
import { isNullOrUndefined } from "util";
import { IconButton, Tooltip } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import AddIcon from '@material-ui/icons/Add';
import { of } from "rxjs";
// Own
import { panableColumnView, showColumns } from "components/ContractInFocus/Maintenance/Helper/View.helpers";
import { ContractPeriod } from "components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface";
import { createReactiveByCategoryRow, reactiveCategoryPrimaryColumn, getTotalColumnHeaderCaption } from "../../Helper/ReactiveByCategory.helpers";
import { FieldMetaGroup, PreFlightListInfo, Dictionary } from 'components/Common/Interfaces/Entity.interface';
import { APIPrivileges } from 'services/Interface/Interface';
import { ColumnProps } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';
import { ChoiceReactiveIssueCategoryOptions, ReactiveIssueCategoryOptions, NewReactiveCategory, UpdateReactiveCategory } from "components/ContractInFocus/Maintenance/Interfaces/reactiveByCategory.interfaces";
import { getMonthAndYearFromColumn } from "components/ContractInFocus/Maintenance/Helper/MaintenanceTable.helper";
import { useGetPanners, setInitialViewFrom } from "components/ContractInFocus/Hooks/UsePannable/usePannable";
import * as inFocusSelector from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import { InteractiveTableChart } from "../../Interfaces/interactiveTable.interfaces";
import { ContractPeriodDates } from "components/ContractInFocus/interfaces/contractInFocusActions.interfaces";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { TreeList, Column as TreeListColumn } from 'devextreme-react/tree-list';
import { AddReactiveByCategoryRow } from "components/ContractInFocus/Maintenance/Components/ReactiveByCategoryTable/ReactiveByCategoryGeneratorModal";
import { addNotification } from 'components/Notification/Actions/Notification.actions';
import { NOTIFICATION_ERROR } from 'components/Notification/Constants/constants';
import { LocalReactiveByCategoryProps } from "components/ContractInFocus/Maintenance/Services/ReactiveByCategory.services";


// Styles
import './ReactiveByCategoryTable.scss';

function showArchiveButton(e: any) {
  return !!e.row.data.header_pointer;
  //console.log(e.row.data);
}

interface GetPortfolioContractFromDataProps {
  portfolio?: HydratedPortfolio,
  data: any
}

const getPortfolioContractFromData = ({
  portfolio,
  data }: GetPortfolioContractFromDataProps
) => {
  let portfolioContract;
  if (portfolio) {
    portfolioContract = portfolio.contracts.find(x => x.id === data.associated_with);
    if (!portfolioContract) {
      addNotification({ message: `contract ${data.associated_with} not found on portfolio ${portfolio.name}`, type: NOTIFICATION_ERROR })
    }
    return portfolioContract
  }
}

type PPMReactiveTableProps = {
  testId: string;
  data: any[];
  contract?: SiteContract;
  portfolio?: HydratedPortfolio;
  matrix: any[];
  columnMeta: ColumnProps[];
  meta: FieldMetaGroup;
  privileges: APIPrivileges;
  width: number;
  reactiveCategoryOptions: ChoiceReactiveIssueCategoryOptions;
  reactiveTotals?: InteractiveTableChart;
  nonMatchingMonths?: any;
  newRecord: (payload: any) => any;
  updateRecord: (payload: any) => any;
  deleteRecord: (payload: any) => any;
  toggleCategoryArchive: ({ contract, portfolio, reactive_category_option, id }: LocalReactiveByCategoryProps) => void;
  selectedPeriod?: ContractPeriod | ContractPeriodDates;
  preFlightGenerateReactiveCategoryInfo?: PreFlightListInfo;
  tableSelector: string;
};

const ReactiveByCategoryTable = ({
  testId,
  data,
  portfolio,
  contract,
  matrix,
  privileges,
  columnMeta,
  meta,
  width,
  updateRecord,
  newRecord,
  deleteRecord,
  toggleCategoryArchive,
  reactiveCategoryOptions,
  preFlightGenerateReactiveCategoryInfo,
  reactiveTotals,
  nonMatchingMonths,
  selectedPeriod,
  tableSelector
}: PPMReactiveTableProps) => {
  const [dataSource, setDataSource] = useState<DataSource>();
  const [gridData, setGridData] = useState<any[]>([]);
  const [showArchived, setShowArchived] = useState(false);
  const [viewFrom, setViewFrom] = useState(0);
  const getSetViewFrom = [viewFrom, setViewFrom];
  const [viewLength, setViewLength] = useState(0);
  const [calculatedColumn, setCalculatedColumn] = useState(columnMeta);
  const [selectableReactiveCategoryOptions, setSelectableReactiveCategoryOptions] = useState<ReactiveIssueCategoryOptions[]>([]);
  const reactiveByCategoryColumnIdx = 1;
  const dataGridRef = useRef<any>(null);
  const [panned, setPanned] = useState(false);
  const [displayAddCategoryForm, setDisplayAddCategoryForm] = useState(false);
  const addCategoryFormValues = useRef<any>({});
  const selectedFocusedSnapshot = useSelector(inFocusSelector.contractInFocusSnapshotFocusSelector);
  const offsetTwelthes = selectedFocusedSnapshot ? 12 : 8;
  const cannotPanLeft = useRef(false);
  const cannotPanRight = useRef(false);
  const { onPanLeft, onPanRight } = useGetPanners({
    dataLength: columnMeta.length - 2,
    getSetViewFrom,
    setPanned,
    viewLength,
    lowerLimitReachedRef: cannotPanLeft,
    upperLimitReachedRef: cannotPanRight
  });
  const groupContractByRelegated = true;

  const handleAddCategory = (): void => {
    const formValuesContract = addCategoryFormValues.current?.contract;
    if (portfolio && !Number.isNaN(formValuesContract)) {
      const contract = portfolio.contracts.find(x => x.id === formValuesContract)
      if (contract) {
        addCategoryFormValues.current.contract = contract;
      }
    }
    newRecord(addCategoryFormValues.current);
  };

  const [colsNotMatching, setColsNotMatching] = useState<string[]>([]);
  useEffect(() => {
    let addableOps: ReactiveIssueCategoryOptions[] = [];
    if (portfolio) {
      const addable = calculateAddableCategoriesForPortfolio(reactiveCategoryOptions.list, portfolio, data);
      Object.keys(addable).map(x => {
        addableOps = [...addableOps, ...addable[x]]
      })
    } else if (contract) {
      addableOps = calculateAddableCategoriesForContract(reactiveCategoryOptions.list, data); //data not gridData, because gridData may not be showing archived.
    }
    setSelectableReactiveCategoryOptions(addableOps);
  }, [reactiveCategoryOptions, data, setSelectableReactiveCategoryOptions, portfolio, contract]);

  useEffect(() => {
    const viewLength = showColumns(!width ? 1000 : width, selectedFocusedSnapshot);
    setViewLength(viewLength);
    const panFilteredColumns = panableColumnView({ columnMeta, viewFrom, viewLength, upperLimitReachedRef: cannotPanRight, lowerLimitReachedRef: cannotPanLeft });
    setCalculatedColumn(panFilteredColumns);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [data, width, viewFrom, setViewLength]);

  useEffect(() => {
    let newData = data;
    if (!showArchived) {
      newData = data.filter(x => !x.hidden);
    }
    setGridData(newData);
  }, [data, showArchived]);

  useEffect(() => {
    const theseColsNotMatching = nonMatchingMonths ? Object.keys(nonMatchingMonths).filter(month => nonMatchingMonths[month]) : [];
    setColsNotMatching(theseColsNotMatching);
    //console.log('colsNotMatching: ', colsNotMatching);
  }, [nonMatchingMonths])

  // useEffect(() => {
  //   console.log('here be dataSource: ', data);
  //   console.log('here be matrix: ', matrix);
  // }, [data, matrix]);

  useEffect(() => {
    if (data) {
      const custom = new CustomStore({
        key: portfolio ? "header_ref" : "issue_category",
        load: () => gridData,
        update: (key: any, values: any) => {
          const hasIssueCategoryBeenSetForRow = "" !== key;
          return hasIssueCategoryBeenSetForRow
            ? onCreateUpdateOrDeleteReactiveData(key, values)
            : onSetIssueCategoryForVirtualRow(key, values)
        }
      });

      setDataSource(new DataSource({ store: custom }));
    }
    /* eslint-disable react-hooks/exhaustive-deps*/
  }, [gridData, portfolio]);

  useEffect(() => {
    setInitialViewFrom(
      {
        dataGrid: dataGridRef.current?.instance,
        panned,
        dataArray: columnMeta.slice(1, -1),
        offsetTwelthes,
        setViewFrom,
        selectedFocusedSnapshot,
        viewLength,
        findColBy: 'caption',
      }
    )
    // dependencies here are important - at least without the viewLength one the calcs are inaccurate so it must change between renders!
  }, [panned, dataGridRef.current, columnMeta, offsetTwelthes, setViewFrom, selectedFocusedSnapshot, viewLength]);

  const onSetIssueCategoryForVirtualRow = (key: any, values: any) => {
    const focusedVirtualRow = gridData.find(row => row.issue_category === "");
    //console.log('key: ', key, 'values: ', values);
    const reactiveCategoryId = values[Object.keys(values)[0]]
    //console.log('reactiveCategoryOptions: ', reactiveCategoryOptions);
    const reactiveCategoryOption = reactiveCategoryOptions.dictionary[reactiveCategoryId];
    //console.log('reactiveCategoryOption: ', reactiveCategoryOption);
    const rowsWithoutFocusedVirtualRow = gridData.filter(row => row.issue_category !== "");


    if (focusedVirtualRow) {
      const updatedDataGrid = [
        ...rowsWithoutFocusedVirtualRow,
        {
          ...focusedVirtualRow,
          issue_category: reactiveCategoryId,
          //name: reactiveCategoryOption.value,
          name: reactiveCategoryOption.display_name,
          [reactiveCategoryPrimaryColumn.name]: {
            first: true,
            readonly: true,
            value: reactiveCategoryOption.display_name
            //value: reactiveCategoryOption.value
          }
        }
      ];

      setGridData(updatedDataGrid);
    }
    return new Promise(resolve => resolve())
  }

  const onCreateUpdateOrDeleteReactiveData = (key: any, values: any) => {
    const columnKey = Object.keys(values)[0];
    console.log('columnKey: ', columnKey);
    let dataRowForUpdate;
    let matrixRowForUpdate;
    let associatedWith: any;
    let portfolioContract;
    // console.log('key: ', key);
    if (portfolio) {
      dataRowForUpdate = gridData.find(item => item.header_ref === key);
      matrixRowForUpdate = matrix.find(item => item.header_ref === key);
      associatedWith = dataRowForUpdate.associated_with;
      portfolioContract = getPortfolioContractFromData({ portfolio, data: dataRowForUpdate });
    } else {
      dataRowForUpdate = gridData.find(item => item.issue_category === key);
      matrixRowForUpdate = matrix.find(item => item.issue_category === key);
    }
    const insertRecord = (!dataRowForUpdate[columnKey] && dataRowForUpdate[columnKey] !== 0) || !!dataRowForUpdate[columnKey]?.insert;
    const cellId = matrixRowForUpdate ? matrixRowForUpdate[columnKey]?.id : undefined;
    dataRowForUpdate[columnKey] = values[columnKey]; // stops the value from flickering in the datagrid
    const deleteRecord = values[columnKey] === null;

    if (insertRecord) {
      return callBackCreate(dataRowForUpdate['issue_category'], columnKey, values[columnKey], portfolioContract)
    } else
      if (deleteRecord) {
        return callBackDelete(cellId);
      } else {
        return callBackUpdate(cellId, values[columnKey], associatedWith);
      }
  }

  const callBackCreate = (issue_category: string, columnKey: string, value: any, portfolioContract?: SiteContract) => {
    const payload: Partial<NewReactiveCategory> = {
      issue_category,
      hidden: false,
      ...getMonthAndYearFromColumn(columnKey),
      value
    };
    if (portfolioContract) {
      payload.contract = portfolioContract;
    }
    return of(newRecord(payload)).toPromise();
  }

  const callBackDelete = (id: string) => {
    const payload: Partial<UpdateReactiveCategory> = { id };
    return of(deleteRecord(payload)).toPromise();
  }

  const callBackUpdate = (id: string, value: any, associatedWith?: string | number) => {
    let contract;
    if (associatedWith) {
      contract = portfolio?.contracts.find(item => item.id == associatedWith);
    }
    const dataGridPayload: Partial<UpdateReactiveCategory> = { id, value, contract };
    return of(updateRecord(dataGridPayload)).toPromise();
  }

  const getValue = (props: any) => {
    if (props.value) {
      const value = [
        props.value.calcValue,
        props.value.value,
        reactiveCategoryOptions.dictionary[props.value]?.display_name,
        props.value.displayValue,
      ].reduce(
        (acc, item) => (isNullOrUndefined(item) ? acc : item),
        undefined
      );

      return <span> {value} </span>;
    } else {
      return null;
    }
  };

  const handlePanLeft = () => {
    onPanLeft();
  }

  const handlePanRight = () => {
    onPanRight();
  }

  const onNewReactiveCategory = () => {
    // this if for associating a new category with the category (and if in the portfolio context, the modal will also get you to create one value)
    if (portfolio) {
      setDisplayAddCategoryForm(true);
    } else {
      setGridData([...gridData, createReactiveByCategoryRow(columnMeta)]);
    }
  }

  const calculateAddableCategoriesForContract = (reactiveOptionsList: ReactiveIssueCategoryOptions[], thisData: any) => {
    return reactiveOptionsList.filter(option => !thisData.find((row: any) => row.issue_category === option.id));
  }

  const calculateAddableCategoriesForPortfolio = (reactiveOptionsList: ReactiveIssueCategoryOptions[], portfolio: HydratedPortfolio, data: any) => {
    const lookup: Dictionary<ReactiveIssueCategoryOptions[]> = {};
    portfolio.contracts.map(contract => {
      const thisData = data.filter((x: any) => x.contract_ref === contract?.contract_ref);
      //const thisList = list.filter(option => !thisData.find((row: any) => row.issue_category === option.id));
      const thisList = calculateAddableCategoriesForContract(reactiveOptionsList, thisData);
      if (thisList.length) {
        lookup[contract?.id] = thisList;
      }
    })
    return lookup;
  }


  const getGroupCaption = (e: any) => e.value ? 'Hidden' : 'Visible';

  const toggleArchiveCategory = (data: any): void => {
    // NB this works by the presence or absence of relegated_id.  If present the service to deletion the 'relegation' record is called,
    // otherwise the service to create the said relegation record is called.
    console.log('data: ', data);
    const portfolioContract = getPortfolioContractFromData({ portfolio, data });
    toggleCategoryArchive({
      reactive_category_option: data.issue_category,
      portfolio,
      contract: portfolioContract || contract,
      id: data.relegated_id
    });
  }

  // <i className="fas fa-redo" onClick={() => handleRestoreCategory(data)} />


  const ButtonCellTemplate = (props: any) => {
    const { row: { data } } = props;

    return (
      <div className="toggleArchiveCategory">
        {data.header_pointer ? <div>
          {data.hidden ? (
            <Tooltip title={`Restore category for contract ${data.contract_ref}`}>
              <IconButton onClick={() => toggleArchiveCategory(data)}>
                <ArchivedIcon width="20px" />
              </IconButton>
            </Tooltip>
            // <i className="fas fa-fa-unarchive" style={{ cursor: 'pointer' }} onClick={() => handleRestoreCategory(data)} />
          ) : (
            <Tooltip title={`Archive category for contract ${data.contract_ref}`}>
              <IconButton onClick={() => toggleArchiveCategory(data)}>
                <UnArchivedIcon width="20px" />
              </IconButton>
            </Tooltip>
            // <i className="fas fa-archive" style={{ cursor: 'pointer' }} onClick={() => toggleArchiveCategory(data)} />
          )}
        </div> : <Tooltip title={`Expand this row to control archiving of contract issue categories`}>
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>}
      </div>
    );
  }

  const handleCellPrepared = (e: any) => {
    const { rowIndex, columnIndex, value, data } = e;
    if (colsNotMatching.includes(e.column.dataField)) {
      if (portfolio) {
        if (e.rowType === "header") {
          e.cellElement.addClass('integrityError');
          e.cellElement.prop('title', "The sum of the cells in this column for at least one contract do not balance with the corresponding contract data in the 'Total Reactive' table. \
          Expand the rows below to see which contract figures do not balance");
        } else {
          if (nonMatchingMonths[e.column.dataField]?.includes(e.data.associated_with)) {
            e.cellElement.addClass('integrityError');
            e.cellElement.prop('title', 'The sum of the cells for this contract do not balance with the corresponding data in the "Total Reactive" table');
          }
        }
      } else {
        e.cellElement.addClass('integrityError');
        e.cellElement.prop('title', 'The sum of the cells in this column do not balance with the corresponding data in the "Total Reactive" table');
      }
    }
    //console.log('rowIndex: ', rowIndex, ' columnIndex: ', columnIndex, ' value: ', value, ' data: ', data);
    if (columnIndex === reactiveByCategoryColumnIdx && data?.issue_category === value && value !== "") {
      //console.log('e.data', e.data);
      const selectBox = document.querySelector(`#${tableSelector}.reactiveByCategoryGridContainer [aria-rowindex="${rowIndex + 1}"] .dx-selectbox`);

      //console.log('selectBox :', selectBox);
      if (selectBox) {
        // @ts-ignore
        selectBox.innerHTML = e.data.name;
      }
      ////HT had to add cell as selectBox isn't there all the time
      const cell = document.querySelector(`#${tableSelector}.reactiveByCategoryGridContainer [aria-rowindex="${rowIndex + 1}"] .my-first-cell`);
      if (cell) {
        // @ts-ignore
        cell.innerHTML = e.data.name;
      }
    }
  }

  const handleRowPrepared = (rowInfo: any) => {
    if (rowInfo.rowType === 'data') {
      if (rowInfo.data.relegated_id) {
        rowInfo.rowElement.addClass('archived');
      }
    }
  }

  return (
    <>

      <div className="buttons no-print">
        <FormControlLabel
          label="Archiving Mode"
          labelPlacement="start"
          className="show-archived-switch no-print"
          control={
            <Switch
              size="small"
              onChange={() => {
                setShowArchived(showArchived === false ? true : false);
              }}
              defaultChecked={false}
            />
          }
        />
        {privileges.POST ?
          <IconButton
            disabled={!selectableReactiveCategoryOptions.length}
            onClick={onNewReactiveCategory}>
            <AddIcon />
          </IconButton>
          : null}
        <IconButton onClick={handlePanLeft}>
          <NavigateBeforeIcon />
        </IconButton>
        <IconButton onClick={handlePanRight}>
          <NavigateNextIcon />
        </IconButton>
      </div>
      {dataSource ?
        <>
          <div className="no-print">
            {portfolio ?
              <TreeList
                ref={dataGridRef}
                className="reactiveByCategoryGridContainer"
                id={tableSelector}
                data-testid={`${testId}-table`}
                dataSource={dataSource}
                showBorders={true}
                onCellPrepared={handleCellPrepared}
                keyExpr="header_ref"
                parentIdExpr="header_pointer"
                rootValue={null}
                onRowPrepared={handleRowPrepared}
                onEditingStart={(e: any) => {
                  // console.log('e: ...', e);
                  if (!e.data?.associated_with) {
                    e.cancel = true;
                  }
                }}
              >
                <KeyboardNavigation
                  editOnKeyPress={true}
                  enterKeyAction="moveFocus"
                  enterKeyDirection="column"
                />
                <Grouping
                  autoExpandAll={true}
                />
                <Paging enabled={false} />
                <Editing
                  mode="cell"
                  allowUpdating={privileges.PUT}
                />
                <TreeListColumn
                  dataField="portfolio_label"
                  width={250}
                />
                {
                  !selectedFocusedSnapshot && showArchived &&
                  <Column type="buttons" width={45}
                    buttons={[{
                      visible: showArchiveButton,
                      onClick: (component: any) => toggleArchiveCategory(component.row.data),
                    }]}
                    cellRender={ButtonCellTemplate}
                  />
                }
                {calculatedColumn && calculatedColumn.map((column, index) => {
                  return index !== 0 ? (
                    <TreeListColumn
                      cssClass={column.className}
                      key={`${column.dataField}-${index}`}
                      {...column}
                      dataType="number"
                    />
                  ) : null
                }
                )}

              </TreeList>

              : <DataGrid
                ref={dataGridRef}
                className="reactiveByCategoryGridContainer"
                id={tableSelector}
                data-testid={`${testId}-table`}
                dataSource={dataSource}
                showBorders={true}
                onCellPrepared={handleCellPrepared}
                onRowPrepared={handleRowPrepared}
              >
                <KeyboardNavigation
                  editOnKeyPress={true}
                  enterKeyAction="moveFocus"
                  enterKeyDirection="column"
                />
                {groupContractByRelegated && showArchived && <Grouping
                  autoExpandAll={true}
                />}
                <Paging enabled={false} />
                <Editing
                  mode="cell"
                  allowUpdating={privileges.PUT}
                />
                {groupContractByRelegated && showArchived && <Column
                  dataField="hidden"
                  groupIndex={0}
                  groupCellRender={getGroupCaption}
                />}
                <Column
                  cssClass={columnMeta[0].className}
                  {...columnMeta[0]}
                  cellRender={getValue}
                >
                  <Lookup
                    dataSource={selectableReactiveCategoryOptions}
                    displayExpr="display_name"
                    valueExpr="id"
                  />
                </Column>
                {
                  !selectedFocusedSnapshot && showArchived &&
                  <Column type="buttons" width={45}
                    buttons={[{
                      visible: showArchiveButton,
                      onClick: (component: any) => toggleArchiveCategory(component.row.data),
                    }]}
                    cellRender={ButtonCellTemplate}
                  />
                }

                {calculatedColumn && calculatedColumn.map((column, index) => {
                  return (index !== 0) ? (
                    <Column
                      cssClass={column.className}
                      key={`${column.dataField}-${index}`}
                      {...column}
                      dataType="number"
                    />
                  ) : null
                }
                )}
                {/* {calculatedColumn && calculatedColumn.map((column, index) => {
                return index !== 0 ? (
                  <Summary>
                    <TotalItem
                      column={column.dataField}
                      summaryType="sum" />
                  </Summary>
                ) : null
              }
              )} */}
              </DataGrid>

            }
            {privileges.POST && displayAddCategoryForm && portfolio && preFlightGenerateReactiveCategoryInfo &&
              <AddReactiveByCategoryRow
                preFlightGenerateReactiveCategoryInfo={preFlightGenerateReactiveCategoryInfo}
                addCategoryFormValues={addCategoryFormValues}
                handleAddCategory={handleAddCategory}
                portfolio={portfolio}
                contract={contract}
                onModalClose={() => {
                  setDisplayAddCategoryForm(false)
                }}
                calculateAddableCategoriesForContract={calculateAddableCategoriesForContract}
                calculateAddableCategoriesForPortfolio={calculateAddableCategoriesForPortfolio}
                data={data}
                reactiveCategoryOptionsList={reactiveCategoryOptions.list}
              />
            }
          </div>
          <PrintableReactiveByCategoryTable data={matrix} portfolio={portfolio} selectedPeriod={selectedPeriod} viewFrom={viewFrom} viewLength={viewLength}></PrintableReactiveByCategoryTable>
        </>
        : null}
    </>
  );
};

interface PrintableReactiveByCategoryTableProps {
  data: any,
  selectedPeriod?: ContractPeriod | ContractPeriodDates,
  viewLength: number,
  viewFrom: number,
  debug?: boolean
  portfolio?: HydratedPortfolio
}

export const PrintableReactiveByCategoryTable: React.FC<PrintableReactiveByCategoryTableProps> = ({
  data,
  selectedPeriod,
  viewLength,
  viewFrom,
  debug = false,
  portfolio
}) => {
  const visibleData = portfolio ? data.filter((d: any) => !d.hidden && !d.header_pointer) : data;
  const months = selectedPeriod?.months.slice(viewFrom, viewFrom + viewLength) || [];
  const totalColumnHeaderCaption = selectedPeriod ? getTotalColumnHeaderCaption(selectedPeriod) : "total";
  return <table id="PrintDataGrid" style={{ width: "100%", borderCollapse: "collapse" }} className={`reactive-by-category-printable ${debug ? '' : 'no-screen'}`}>
    <thead>
      <tr>
        <td style={{ textAlign: "left", width: "250px" }}>Category</td>
        {months.map((month: string, index: number) => {
          return <td
            style={{ textAlign: "center" }}
            key={`${index}`}>
            {month}
          </td>
        })}
        <td>
          {totalColumnHeaderCaption}
        </td>
      </tr>
    </thead>
    <tbody>
      {visibleData.map((categoryData: any, index: number) => {
        return <tr
          key={`${categoryData.name}-${index}`}
        >
          <td>{categoryData.name}</td>
          {months.map((month: string, index: number) => {
            const thisValue = categoryData[month] ? categoryData[month].value.toFixed(2) : ''
            return <td
              style={{ textAlign: "center" }}
              key={`${index}`}>
              {thisValue}
            </td>
          })}
          <td>
            {categoryData[totalColumnHeaderCaption].value.toFixed(2)}
          </td>
        </tr>

      })}
    </tbody>
  </table>
}

export default withResizeDetector(memo(ReactiveByCategoryTable));
