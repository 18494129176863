import React, { useState, useEffect, memo, ReactElement, useReducer, useCallback } from 'react';
import { isEqual } from "lodash";

// Own
import { GeneralDocsGridWrapper } from 'components/SiteDocuments/Components/ContractDocumentsViewGrid/ContractDocumentGridStyles';
import { ContractDocumentFilesWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { FieldMetaGroup, PreFlightListInfo } from 'components/Common/Interfaces/Entity.interface';

import DocumentsUploader from "components/Common/Components/DocumentsUploader/DocumentUploader";
import DocumentsGrid from "components/Common/Components/DocumentsGrid/DocumentsGrid";
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { ColumnsConfig } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';
import { simpleFetchSnapshotDocumentPreFlightInfo } from "components/ContractInFocus/Services/snapShot.service";
import { FormValues } from "components/Common/Components/DocumentsUploader/DocumentUploader";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { defaultSnapshotInitialUploadDocState } from "constants/snapshots.constants";

interface PortfolioDocumentGridProps {
    portfolio: HydratedPortfolio;
    setModalTitle: React.Dispatch<React.SetStateAction<string>>;
    modalTitle: string;
    initialIndex?: number;
    setDocumentTabTitles: React.Dispatch<React.SetStateAction<string[] | undefined>>;
}

type activeTabsType = "snapshot-documents" | "nested-contract-snapshot-documents"

const documentTabLookups: { [key in activeTabsType]: string } = {
    "snapshot-documents": "Portfolio Documents",
    "nested-contract-snapshot-documents": "Contract Documents",
};

const documentTabTitles = Object.values(documentTabLookups);
const documentTabValues = Object.keys(documentTabLookups);

const portfolioDocumentsColumnsConfig: ColumnsConfig = {
    'snapshot_date': {
        'caption': 'Snapshot',
        'dataType': 'date',
        'format': 'd MMM yyyy'
    },
    'display_type': {
        'caption': "Document Category",
    },
    'description': {
        'caption': 'Description'
    },
    'updated_at': {
        'caption': 'Last Updated At',
        'dataType': 'date',
        'format': 'd MMM yyyy HH:mm:ss'
    }
}

const portfolioContractDocumentsColumnsConfig: ColumnsConfig = {
    'contract_ref': {
        'caption': 'Contract'
    },
    ...portfolioDocumentsColumnsConfig,
}

const fieldsConfig: FieldsFormConfig = {
    'snapshot': {
        label: "Select Snapshot",
        selectFirstAsDefault: false,
        selectLastAsDefault: false,
    },
    'snapshot_document_type': {
        excludeValues: ['monthly_maintenance_report'],
        sideEffect: (values, fieldConfigs, onChangeFormValues) => {
            let changed = false;
            if (values['snapshot_document_type'] == "monthly_maintenance_report_appendix" && values["internal_access_only"] == true) {
                values['internal_access_only'] = false;
                changed = true;
            }
            if (values['snapshot_document_type'] !== "monthly_maintenance_report_appendix" && fieldConfigs['internal_access_only'].disabled == true) {
                fieldConfigs['internal_access_only'].disabled = false;
                changed = true;
            } else if (values['snapshot_document_type'] == "monthly_maintenance_report_appendix" && fieldConfigs['internal_access_only'].disabled !== true) {
                fieldConfigs['internal_access_only'].disabled = true;
                changed = true;
            }
            if (changed) {
                onChangeFormValues(values);
            }
            return changed;
        }
    },
    'internal_access_only': {},
    'description': {
        //     style: {
        //         fontWeight: 400
        //     },
        //     forceRequired: true,
    },
}

const PortfolioDocumentsGrid = ({
    portfolio,
    modalTitle,
    setModalTitle,
    initialIndex,
    setDocumentTabTitles,
}: PortfolioDocumentGridProps) => {

    const modalTitlesLookup: { [key in activeTabsType]: string } = React.useMemo(() => ({
        "nested-contract-snapshot-documents": `Snapshot related documents for portfolio: '${portfolio?.name}'`,
        "snapshot-documents": `Snapshot related documents for portfolio: '${portfolio?.name}'`
    }), [portfolio]);

    useEffect(() => {
        setDocumentTabTitles && setDocumentTabTitles(documentTabTitles);
    }, [setDocumentTabTitles]);

    const [activeTab, setActiveTab] = useState<activeTabsType>();

    useEffect(() => {
        //@ts-ignore typescript is too dumb to see that that on the next line activeTab cannot be undefined where used as an index
        const newModalTitle = modalTitlesLookup[activeTab];
        if (newModalTitle && modalTitle !== newModalTitle) {
            setModalTitle(newModalTitle)
        }
    }, [activeTab, setModalTitle, modalTitlesLookup, modalTitle]);

    const [snapshotDocumentPostMeta, setSnapshotDocumentPostMeta] = useState<FieldMetaGroup>();
    const [preFlightInfo, setPreFlightInfo] = useState<PreFlightListInfo>();
    //const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 0);
    const [mustRefresh, forceUpdate] = useState(0);
    const [fetchFilters, setFetchFilters] = useState(undefined);
    const index = initialIndex || 0;
    const [listBasePath, setListBasePath] = useState<string>();

    const refresh = useCallback((reset?: boolean) => forceUpdate(reset ? 0 : mustRefresh + 1), [forceUpdate, mustRefresh]);
    //const refresh = useCallback(forceUpdate, [forceUpdate])

    useEffect(() => {
        const newActiveTab = documentTabValues[index] as activeTabsType;
        console.log('newActiveTab: ', newActiveTab);
        if (newActiveTab && newActiveTab !== activeTab) {
            setActiveTab(newActiveTab);
            setListBasePath(`portfolios/${portfolio.id}/${newActiveTab}/`)
            refresh(true);
        }
    }, [index, portfolio.id]);

    const acceptedFileTypesModifier = useCallback((formValues: FormValues, currentlyAcceptedTypes?: string[]) => {
        const v = formValues['snapshot_document_type']
        if (v == 'monthly_maintenance_report_appendix') {
            return ['application/pdf']
        }
        return currentlyAcceptedTypes;
    }, [])



    useEffect(() => {
        activeTab && simpleFetchSnapshotDocumentPreFlightInfo({ portfolioId: portfolio.id, portfolioEndpoint: activeTab }).then((newPreFlightInfo) => {
            console.log('activeTab: ', activeTab);
            if (!isEqual(preFlightInfo, newPreFlightInfo)) {
                setPreFlightInfo(newPreFlightInfo);
            }
            if (!isEqual(preFlightInfo?.meta, newPreFlightInfo.meta)) {
                setSnapshotDocumentPostMeta(newPreFlightInfo?.meta);
            }
        });
    }, [portfolio.id, preFlightInfo, activeTab]);

    //.dx-texteditor.dx-editor-underlined:after
    if (preFlightInfo) {
        return (
            <>
                <GeneralDocsGridWrapper>
                    <ContractDocumentFilesWrapper height={!preFlightInfo.canCreate ? 75 : 65}>
                        {listBasePath && <DocumentsGrid
                            listBasePath={listBasePath}
                            columnsConfig={activeTab === "snapshot-documents" ? portfolioDocumentsColumnsConfig : portfolioContractDocumentsColumnsConfig}
                            fetchFilters={fetchFilters}
                            refreshSignal={mustRefresh}
                            hideSource
                        />}
                    </ContractDocumentFilesWrapper>

                    {preFlightInfo.canCreate && listBasePath && <DocumentsUploader
                        acceptedFileTypesModifier={acceptedFileTypesModifier}
                        urlContext={listBasePath}
                        fieldConfigs={fieldsConfig}
                        preFlightInfo={preFlightInfo}
                        gridId="snapshotDocumentsView"
                        reset={refresh}
                        initialState={defaultSnapshotInitialUploadDocState}
                    />}
                </GeneralDocsGridWrapper>
            </>
        )
    } else {
        return <></>
    }
}

export default memo(PortfolioDocumentsGrid);