import API, { APIN } from "services/API/API";
import { FieldMetaGroup, PreFlightListInfo, PreFlightDetailInfo } from 'components/Common/Interfaces/Entity.interface';
import { removeReadOnlyFields, getListData, getListMeta, getPrivileges, getPreFlightListMeta, getPreFlightDetailMeta, preFlightCanCreateCheck, preFlightCanUpdateCheck, getDataAndMeta, getDataAndMetaAndPUTMeta, parseMultiCreateResponse, getCount } from "services/API/API.helper";
import { Visit, CreateVisit } from "components/Schedulers/Interfaces/Schedule.interfaces";
import { onResponseError } from "services/API/API.interceptor.js"
import { ContractIdOrPortfolioId } from "services/API/common/contractAPIs";
import { CONTRACT_ROUTE } from "services/API/common/globalAPIs";


const BASE_CONTRACT_ROUTE = CONTRACT_ROUTE;
const BASE_PORTFOLIO_ROUTE = 'portfolios/';
const SUPPLIER_VISITS_FRAGMENT = 'supplier-visits/';
const BASE_BANK_HOLIDAYS_ROUTE = 'get_bank_holidays/'
const BASE_VISIT_MULTI_CREATE_ROUTE = 'supplier-visits-multi-create/';
const BASE_SUPPLIER_SERVICE_VISITS_ROUTE = 'supplier-service-visits-for-schedule/';
const BASE_SUPPLIER_CONTRACT_ROUTE = 'supplier-contracts/';
const BASE_SUPPLIER_SERVICE_ROUTE = 'supplier-services/';

//use this for retreiving supplier contracts with visits grouped by month and nested (for specialist table)
const getSupplierServiceVisitsRoute = (contract_ref?: string | number, portfolioId?: string | number) => {
    if (contract_ref) {
        return `${BASE_CONTRACT_ROUTE}${contract_ref}/${BASE_SUPPLIER_SERVICE_VISITS_ROUTE}`
    }
    if (portfolioId) {
        return `${BASE_PORTFOLIO_ROUTE}${portfolioId}/${BASE_SUPPLIER_SERVICE_VISITS_ROUTE}`
    }
    return `${BASE_SUPPLIER_SERVICE_VISITS_ROUTE}`
}

export const getSupplierServicesRoute = (contract_ref: string | number, id?: string) => {
    return `${BASE_CONTRACT_ROUTE}${contract_ref}/${BASE_SUPPLIER_SERVICE_ROUTE}${id ? id + '/' : ''}`
}
// use this for retrieving and listing visits - because agnostic to whether nested under a given supplier contract
const getSupplierVisitsRoute = (contract_ref?: string, portfolioId?: string | number) => {
    let route = "/get-supplier-visits/never/";
    if (contract_ref) {
        route = `${BASE_CONTRACT_ROUTE}${contract_ref}/${SUPPLIER_VISITS_FRAGMENT}`;
    }
    if (portfolioId) {
        route = `${BASE_PORTFOLIO_ROUTE}${portfolioId}/${SUPPLIER_VISITS_FRAGMENT}`
    }
    return route;
}
const getSupplierServiceContractsRoute = (contract_ref?: string) => {
    //return `${BASE_SUPPLIER_SERVICE_ROUTE}${supplierServiceId}/${BASE_SUPPLIER_CONTRACT_ROUTE}`;
    return `${BASE_CONTRACT_ROUTE}${contract_ref}/supplier-contracts/`
}
const getBankHolidaysRoute = (contract_ref: string) => `${BASE_CONTRACT_ROUTE}${contract_ref}/${SUPPLIER_VISITS_FRAGMENT}${BASE_BANK_HOLIDAYS_ROUTE}`
const getSupplierVisitsMultiCreateRoute = (contract_ref: string) => `${BASE_CONTRACT_ROUTE}${contract_ref}/${BASE_VISIT_MULTI_CREATE_ROUTE}`

// use this for CRUD on supplier contracts
const getSupplierContractRoute = (contract_ref: string) => `${BASE_CONTRACT_ROUTE}${contract_ref}/${BASE_SUPPLIER_CONTRACT_ROUTE}`


export const simpleFetchVisitListPreFlightInfo = (contract_ref?: string, portfolioId?: number): Promise<PreFlightListInfo> => {
    let route;
    if (contract_ref) {
        //route = getSupplierVisitsRoute(contract_ref);
        route = getSupplierServiceVisitsRoute(contract_ref)
    }
    if (portfolioId) {
        //route = getSupplierVisitsRoute(undefined, portfolioId);
        route = getSupplierServiceVisitsRoute(undefined, portfolioId);
    }
    if (route) {
        return APIN.options(route).then((response) => {
            const meta = getPreFlightListMeta(response); //preflight uses the 'options' verb
            const canCreate = preFlightCanCreateCheck(response);
            return { meta, canCreate, canRead: true }
        }).catch(error => {
            if (error.response?.status == 403) {
                return { meta: {}, canCreate: false, canRead: false }
            } else {
                return onResponseError(error);
            }
        })
    } else {
        return Promise.resolve({ meta: {}, canCreate: false, canRead: false });
    }

}

export interface VisitQueryParams {
    flat?: boolean;
    month?: string;
    year?: string | number;
    start_date?: string;
    end_date?: string;
    contractor?: string | number;
    equipment_name?: string;
    exceptions_only?: number;
    exclude_too_few_visits?: boolean;
    supplier_service?: string;
    supplier_contract?: string;
}

interface SimpleUpdateVisitProps extends ContractIdOrPortfolioId {
    id: string;
    payload: Visit;
}
export const simpleUpdateVisit = (props: SimpleUpdateVisitProps) => {
    let baseRoute = getSupplierVisitsRoute(props.contractRef);
    const route = `${baseRoute}${props.id}/`
    // NB need to verify that this contract belongs to the specified portfolio on the back end...
    // ps portfolio is not an attribute of a visit - this is to allow the look up of relevant portfolio permissions on the backend.
    const newPayload = { ...props.payload, surrogate_portfolio: props.portfolioId }
    return API.patch(route, newPayload).then((response) => {
        return getDataAndMeta(response);
    });
}

export const getBankHolidays = (contract_ref: string) => {
    const bankHolidaysRoute = getBankHolidaysRoute(contract_ref);
    const route = `${bankHolidaysRoute}`
    return API.get(route).then((response) => {
        return response.data;
    });
}

interface DeleteSupplierVisitProps extends ContractIdOrPortfolioId {
    id: string;
}
export const deleteSupplierVisit = (props: DeleteSupplierVisitProps) => {
    let baseRoute = getSupplierVisitsRoute(props.contractRef);
    let params = {};
    // visit has no portfolio, but the portfolio is used to check permissions against portfolio object, if we're in the portfolio context
    if (props.portfolioId) {
        params = {
            surrogate_portfolio: props.portfolioId
        }
    }
    let route = `${baseRoute}${props.id}/`
    return API.delete(route, { params: params }).then((response) => {
        return getDataAndMeta(response);
    });
}

interface CreateSupplierVisitProps extends ContractIdOrPortfolioId {
    payload: Visit;
}
export const simpleCreateSupplierVisit = (props: CreateSupplierVisitProps) => {
    // visit has no portfolio, but the portfolio is used to check permissions against portfolio object, if we're in the portfolio context
    let route = getSupplierVisitsRoute(props.contractRef);
    let params = {};
    if (props.portfolioId) {
        params = {
            surrogate_portfolio: props.portfolioId
        }
    }
    const payload = { ...props.payload, ...params }
    return API.post(route as string, payload).then((response) => {
        return getDataAndMeta(response);
    });
}

interface CreateMultipleSupplierVisitProps extends ContractIdOrPortfolioId {
    payload: CreateVisit[];
}
interface VisitWithPortfolioContext extends CreateVisit {
    surrogate_portfolio?: number | string;
}

export const multiCreateSupplierVisits = (props: CreateMultipleSupplierVisitProps) => {
    const contract_ref = props.contractRef as string;
    let route = getSupplierVisitsMultiCreateRoute(contract_ref);
    let newPayload: VisitWithPortfolioContext[] = [...props.payload];
    if (props.portfolioId) {
        newPayload = props.payload.map(x => ({ ...x, surrogate_portfolio: props.portfolioId }))
    }
    return API.post(route, newPayload).then((response) => {
        return parseMultiCreateResponse(response);
    });
}

export const fetchSupplierContractCreatePreFlightInfo = (props: ContractIdOrPortfolioId): Promise<PreFlightListInfo> => {
    const contract_ref = props.contractRef as string;
    let params = {};
    if (props.portfolioId) {
        params = {
            surrogate_portfolio: props.portfolioId
        }
    }
    let route = getSupplierContractRoute(contract_ref);
    return APIN.options(route, { params: params }).then((response) => {
        const meta = getPreFlightListMeta(response); //preflight uses the 'options' verb
        const canCreate = preFlightCanCreateCheck(response);
        return { meta, canCreate, canRead: true }
    }).catch(error => {
        if (error.response?.status == 403) {
            return { meta: {}, canCreate: false, canRead: false }
        } else {
            return onResponseError(error);
        }
    })
}

interface FetchSupplierServicePreFlightInfoProps extends ContractIdOrPortfolioId {
    supplierServiceId: string;
}

interface FetchSupplierContractPreFlightInfoProps extends ContractIdOrPortfolioId {
    supplierContractId: string;
}
export const fetchSupplierContractUpdatePreFlightInfo = (props: FetchSupplierContractPreFlightInfoProps): Promise<PreFlightDetailInfo> => {
    const contract_ref = props.contractRef as string;
    let params = {};
    if (props.portfolioId) {
        params = {
            surrogate_portfolio: props.portfolioId
        }
    }
    let baseRoute = getSupplierContractRoute(contract_ref);
    let route = `${baseRoute}${props.supplierContractId}/`;
    return APIN.options(route, { params }).then((response) => {
        const meta = getPreFlightDetailMeta(response); //preflight uses the 'options' verb
        const canUpdate = preFlightCanUpdateCheck(response);
        return { meta, canUpdate, canRead: true }
    }).catch(error => {
        if (error.response?.status == 403) {
            return { meta: {}, canUpdate: false, canRead: false }
        } else {
            return onResponseError(error);
        }
    })
}

export const getSupplierServiceVisitReportsRoute = (props: FetchSupplierServicePreFlightInfoProps): string => {
    let route = `${BASE_SUPPLIER_SERVICE_ROUTE}${props.supplierServiceId}/visit-reports/`; // if this is for a contract, the contract is inferred from the supplier contract
    if (props.portfolioId) {
        route = `portfolios/${props.portfolioId}/${route}`
    }
    return route;
}

export const fetchSupplierVisitReportsPreFlightInfo = (props: FetchSupplierServicePreFlightInfoProps): Promise<PreFlightListInfo> => {
    let route = getSupplierServiceVisitReportsRoute(props);
    return APIN.options(route).then((response) => {
        const meta = getPreFlightListMeta(response); //preflight uses the 'options' verb
        const canCreate = preFlightCanCreateCheck(response);
        return { meta, canCreate, canRead: true }
    }).catch(error => {
        if (error.response?.status == 403) {
            return { meta: {}, canCreate: false, canRead: false }
        } else {
            return onResponseError(error)
        }
    });
}

export const getVisitVisitReportsRoute = (visitId: string): string => {
    let route = `visits/${visitId}/reports/`;
    return route;
}

export const getPermitDocumentsRoute = (permitId: string): string => {
    let route = `ptws/${permitId}/documents/`;
    return route;
}

interface SupplierServiceVisitProps {
    contract_ref?: string;
    portfolioId?: number;
    query_params: VisitQueryParams
}

export const fetchSupplierServicesWithMonthVisits = (props: SupplierServiceVisitProps) => {
    // here we actually switch routes in portfolio context, as we want to get back supplier contracts for every contract in the portfolio
    let baseRoute;
    if (props.portfolioId) {
        baseRoute = getSupplierServiceVisitsRoute(undefined, props.portfolioId);
    } else {
        baseRoute = getSupplierServiceVisitsRoute(props.contract_ref);
    }
    let route = `${baseRoute}`;
    return API.get(route, { params: props.query_params }).then((response) => {
        const visitList = {
            data: getListData(response),
            metadata: getListMeta(response),
            permissions: getPrivileges(response),
            totalCount: getCount(response)
        }
        return visitList;
    });
}


export const fetchSupplierServicesWithMonthVisitsPreFlightInfo = (props: SupplierServiceVisitProps): Promise<PreFlightListInfo> => {
    let route;
    if (props.portfolioId) {
        route = getSupplierServiceVisitsRoute(undefined, props.portfolioId);
    } else {
        route = getSupplierServiceVisitsRoute(props.contract_ref);
    }
    route = route || "fetch-supplier-services-with-month-visits/never";
    return APIN.options(route).then((response) => {
        const meta = getPreFlightListMeta(response); //preflight uses the 'options' verb
        const canCreate = preFlightCanCreateCheck(response);
        return { meta, canCreate, canRead: true }
    }).catch(error => {
        if (error.response?.status == 403) {
            return { meta: {}, canCreate: false, canRead: false }
        } else {
            return onResponseError(error)
        }
    })
}

interface SimpleFetchSupplierVisitProps extends ContractIdOrPortfolioId {
    contractRef: string;
    query_params: VisitQueryParams;
}

interface VisitQueryParamsWithPortfolioContext extends VisitQueryParams {
    surrogate_portfolio?: number | string;
}

export const fetchSupplierVisits = (props: SimpleFetchSupplierVisitProps) => {
    let baseRoute = getSupplierVisitsRoute(props.contractRef);
    let params: VisitQueryParamsWithPortfolioContext = props.query_params;
    // visit has no portfolio, but the portfolio is used to check permissions against portfolio object, if we're in the portfolio context
    if (props.portfolioId) {
        params = {
            ...props.query_params,
            surrogate_portfolio: props.portfolioId
        }
    }
    let route = `${baseRoute}`;
    return API.get(route, { params: params }).then((response) => {
        return getDataAndMetaAndPUTMeta(response);
    });
}

interface FetchSupplierServiceContractsProps extends ContractIdOrPortfolioId {
    supplier_service: string;
    start_date?: string;
    end_date?: string;
    surrogate_portfolio?: string | number;
}

export const fetchSupplierServiceContracts = (props: FetchSupplierServiceContractsProps) => {
    let baseRoute = getSupplierServiceContractsRoute(props.contractRef);
    // visit has no portfolio, but the portfolio is used to check permissions against portfolio object, if we're in the portfolio context
    let params = {
        ...props,
    }
    if (props.portfolioId) {
        params = {
            ...params,
            surrogate_portfolio: props.portfolioId
        }
    }
    let route = `${baseRoute}`;
    return API.get(route, { params }).then((response) => {
        return getDataAndMetaAndPUTMeta(response);
    });
}

interface FetchSingleSupplierServiceContractProps extends ContractIdOrPortfolioId {
    id: string;
    supplier_service: string;
    surrogate_portfolio?: string | number;
}

export const fetchSingleSupplierServiceContract = (props: FetchSingleSupplierServiceContractProps) => {
    let baseRoute = getSupplierServiceContractsRoute(props.contractRef);
    // visit has no portfolio, but the portfolio is used to check permissions against portfolio object, if we're in the portfolio context
    let params = {
        supplier_service: props.supplier_service,
        surrogate_portfolio: props.surrogate_portfolio
    }
    if (props.portfolioId) {
        params = {
            ...params,
            surrogate_portfolio: props.portfolioId
        }
    }
    let route = `${baseRoute}${props.id}`;
    return API.get(route, { params }).then((response) => {
        return getDataAndMeta(response);
    });
}

interface SimpleFetchVisitProps extends ContractIdOrPortfolioId {
    visitId: string;
}
export const simpleFetchVisit = (props: SimpleFetchVisitProps) => {
    let baseRoute = getSupplierVisitsRoute(props.contractRef);
    let route = `${baseRoute}${props.visitId}/`

    let params = {};
    // visit has no portfolio, but the portfolio is used to check permissions against portfolio object, if we're in the portfolio context
    if (props.portfolioId) {
        params = {
            surrogate_portfolio: props.portfolioId
        }
    }
    return API.get(route, { params: params }).then((response) => {
        return getDataAndMeta(response);
    });
}



