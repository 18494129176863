import styled from "styled-components";
import styles from "../../../../styles/app/common/variables.scss";

export const GridHeading = styled.div`
  margin-bottom: ${({ ExtraButtons }) => {
      return ExtraButtons
        ? `
      margin-bottom: 0;
    `
        : `margin-bottom: 1rem;`;
    }}
    ${({ externalCardHeaders }) => {
      return externalCardHeaders
        ? `
      margin-top: .5rem;
      margin-bottom: 1.5rem;
    `
        : ``;
    }};
`;

export const GridTitle = styled.div`
  font-size: 1.5rem;
  color: ${styles.blueDark};

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1rem;
  }
`;

export const GridSubTitle = styled.div`
  font-size: 1rem;
  color: ${styles.grayMedDarker};
`;
