import React, { useReducer, useCallback } from 'react';
import { useSelector } from 'react-redux';

// Own
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { PreFlightListInfo } from "components/Common/Interfaces/Entity.interface";
import DocumentsUploader from "components/Common/Components/DocumentsUploader/DocumentUploader";
import { GeneralDocsGridWrapper } from 'components/SiteDocuments/Components/ContractDocumentsViewGrid/ContractDocumentGridStyles';
import { profileOrganisationSelector } from 'components/Profile/Selectors/Profile.selector';
import { BasePermitToWork } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";

interface ExternalPermitUploaderProps {
    ptw: BasePermitToWork;
    setData: (data: any) => void;
    preFlightInfo: PreFlightListInfo | undefined;
}

export const getPermitDocBaseRoute = (ptw: BasePermitToWork) => {
    return `ptws/${ptw.id}/agreement/`;
}

const ExternalPermitUploader = ({ ptw, setData, preFlightInfo }: ExternalPermitUploaderProps) => {
    const agreementBasePath = getPermitDocBaseRoute(ptw);
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 0);
    const selectProfileOrganisationFromState = useSelector(profileOrganisationSelector);
    const refresh = useCallback(forceUpdate, [forceUpdate]);

    const getFieldFormConfig = useCallback((): FieldsFormConfig => {
        return {
            // 'notes': {
            //     forceRequired: true,
            //     label: 'Description'
            // },
            'permit_to_work': {
                hidden: true,
                defaultValue: ptw.id
            },
            'author_organisation': {
                hidden: true,
                defaultValue: selectProfileOrganisationFromState
            },
            'external_generation': {
                hidden: true,
                defaultValue: true
            },
        }
    }, [ptw, selectProfileOrganisationFromState]);

    const fieldsConfig = getFieldFormConfig();

    return <GeneralDocsGridWrapper>

        {!ptw.agreement && preFlightInfo && agreementBasePath &&
            <DocumentsUploader
                urlContext={agreementBasePath}
                fieldConfigs={fieldsConfig}
                preFlightInfo={preFlightInfo}
                gridId="DetailDocumentsUploader"
                reset={refresh}
                zIndex={30000}
                setData={setData}
                showIfNotEditable
            />
        }
    </GeneralDocsGridWrapper>
}

export default ExternalPermitUploader;