import React, { useState, useReducer, useEffect } from "react";
//import { useSelector } from "react-redux";
import moment from "moment";

// Own
import FinancialGrid from "./Containers/FinancialGrid/FinancialGrid";
import { financialsRightVisibilityMenu } from "./Models/financialsRightVisibilityMenu";
import withPageContext, { WithPageContextProps } from "../HoC/WithPageContext/WithPageContext";
import { RefreshControlComponent } from "components/Common/Components/RightVisibilityMenu/Components/RightContextButton/refreshButton";
import { financialsColumns } from './Models/financialsColumn';
import { IFinancials } from "components/ContractInFocus/Financials/interfaces/financials.interface";
import PaperQuoteView from "./Containers/PaperQuoteView";
import CommonAPIService from "components/ContractInFocus/Services/commonAPI.services";
import { reportSubtitles } from "components/ContractInFocus/Models/contractIndex.model";
import AWDetailModal from "components/ContractInFocus/Financials/Containers/FinancialGrid/AWDetailModal";
// import { contractInFocusFocusedContractPeriodSelector } from "components/ContractInFocus/Selectors/contractInFocus.selectors";
import "./Styles/AdditionalWorks.scss";
import financialServices from "components/ContractInFocus/Financials/Services/financialServices";
import withPortfolioPageContext from "components/PortfolioInFocus/HOC/WithPortfolioPageContext/WithPortfolioPageContext";

interface FinancialsProps extends WithPageContextProps { }

const Financials: React.FC<FinancialsProps> = ({ visibilitySettings, frozenFor, sectionNumberMap, contract, contractReportContext, portfolio }) => {
  const [paperQuoteViewData, setPaperQuoteViewData] = useState<IFinancials | undefined>();
  const [refreshFinancialsAt, setRefreshFinancialsAt] = useState<number>(moment.now());
  const [openDetailModal, setOpenDetailModal] = useState(false);

  // const selectedContractPeriod = useSelector(
  //   contractInFocusFocusedContractPeriodSelector
  // );

  const refreshFinancials = (event: any) => {
    if (portfolio) {
      return CommonAPIService.triggerPortfolioServerSideFinancialsDataUpdate(portfolio.id)
    }
    if (contract) {
      return CommonAPIService.triggerServerSideFinancialsDataUpdate(contract.contract_ref)
    }
  }

  const afterRefresh = () => {
    setRefreshFinancialsAt(moment.now());
  }

  const hashFragment = window.location.hash;

  const [detailData, setDetailData] = useState<IFinancials>();

  const [mustReload, forceReload] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    if (hashFragment) {
      const awId = hashFragment.replace('#', '');
      financialServices.getAWRecord({ contractRef: contract?.contract_ref, portfolioId: portfolio?.id, awId }).then((data) => {
        if (data.id) {
          setDetailData(data);
          setOpenDetailModal(true);
        }
      })
    }
  }, [hashFragment, contract, portfolio])

  return (
    <>
      {(!!contract || !!portfolio) && <RefreshControlComponent onclick={refreshFinancials} after={afterRefresh} />}
      <div id="FinancialGridsContainer" style={{ display: paperQuoteViewData ? "none" : "block" }}>
        {visibilitySettings.show_new_works_financials_table ?
          <FinancialGrid
            contract={contract}
            portfolio={portfolio}
            refreshFinancialsAt={refreshFinancialsAt}
            financialCategory="new_works"
            //title="New Additional Works"
            dataTestId="financialsNewWorks"
            frozenFor={frozenFor}
            printColumns={financialsColumns}
            sectionNumberMap={sectionNumberMap}
            setPaperQuoteViewData={setPaperQuoteViewData}
            title={reportSubtitles.AWNew}
            forceReload={forceReload}
            mustReload={mustReload}
            paging
            remoteOperations
            contractReportContext={contractReportContext}
          />
          : null}
        {visibilitySettings.show_reactive_works_financials_table ?
          <FinancialGrid
            contract={contract}
            portfolio={portfolio}
            refreshFinancialsAt={refreshFinancialsAt}
            financialCategory="reactive_works"
            //title="Reactive Additional Works"
            dataTestId="financialsReactiveWorks"
            frozenFor={frozenFor}
            printColumns={financialsColumns}
            sectionNumberMap={sectionNumberMap}
            setPaperQuoteViewData={setPaperQuoteViewData}
            title={reportSubtitles.AWReactive}
            forceReload={forceReload}
            mustReload={mustReload}
            paging
            remoteOperations
            contractReportContext={contractReportContext}
          />
          : null
        }
        {visibilitySettings.show_part_comp_repair_financials_table ?
          <FinancialGrid
            contract={contract}
            portfolio={portfolio}
            refreshFinancialsAt={refreshFinancialsAt}
            financialCategory="part_comp"
            //title="Part Comp Repair Additional Works"
            dataTestId="financialsPartComp"
            frozenFor={frozenFor}
            printColumns={financialsColumns}
            sectionNumberMap={sectionNumberMap}
            setPaperQuoteViewData={setPaperQuoteViewData}
            title={reportSubtitles.AWPartComp}
            forceReload={forceReload}
            mustReload={mustReload}
            paging
            remoteOperations
            contractReportContext={contractReportContext}
          />
          : null}
        {
          (visibilitySettings.show_unfiltered_financials_table && !frozenFor) //NB: IF YOU UPDATE THIS DISPLAY LOGIC, REVISIT CONSTRUCTION OF VISIBILITY MENU IN financialsRightVisibilityMenu.ts to ensure it is appropriately adjusted!!
            ?
            <div
            //ref={unfilteredTableRef}
            >
              <FinancialGrid
                contract={contract}
                portfolio={portfolio}
                refreshFinancialsAt={refreshFinancialsAt}
                gridId="unfilteredAdditionalWorksGrid"
                printPrevent={true}
                noFilter={true}
                paging
                remoteOperations
                //title="Unfiltered Financials"
                dataTestId="allAdditionalWorks"
                frozenFor={frozenFor}
                printColumns={financialsColumns}
                sectionNumberMap={sectionNumberMap}
                setPaperQuoteViewData={setPaperQuoteViewData}
                title={reportSubtitles.AWUnfiltered}
                forceReload={forceReload}
                mustReload={mustReload}
                contractReportContext={contractReportContext}
              />
            </div>
            : null
        }
      </div>
      {paperQuoteViewData && <PaperQuoteView data={paperQuoteViewData} setPaperQuoteViewData={setPaperQuoteViewData} portfolio={portfolio}></PaperQuoteView>}
      {openDetailModal && detailData && <AWDetailModal
        contract={contract}
        portfolio={portfolio}
        data={detailData}
        setOpen={setOpenDetailModal}
        open={openDetailModal}
        forceReload={forceReload}
      //hideClientPermissions={hideClientPermissions}
      />}
    </>
  );
};

export default withPageContext(Financials, {
  visibilityMenu: financialsRightVisibilityMenu
});

export const PortfolioFinancials = withPortfolioPageContext(Financials, {
  visibilityMenu: financialsRightVisibilityMenu
});