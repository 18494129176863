import API, { APIR } from "services/API/API"
import { CONTRACT_PERIOD_ROUTE, PORTFOLIO_PERIOD_ROUTE } from "services/API/common/globalAPIs";
import { unWrapDataAndMeta, getDataAndMeta, getListData, getListMeta, arrayToDict, getListPrivileges, getPrivileges } from "services/API/API.helper";
import { store } from "store/store";
import { ContractPeriod, PortfolioPeriod } from "components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface";
import * as actions from "components/AdminPanel/ContractPeriods/Actions/ContractPeriods.actions";
import { contractPeriodsEndpoint, portfolioPeriodsEndpoint } from "services/API/common/contractAPIs";
import { fetchContractPeriodsSuccess } from "components/ContractInFocus/Actions/contractInFocus.actions";
import { groupFetchSite } from "components/AdminPanel/Sites/Services/sitesServices";
import { fetchContractPeriodReducer } from "components/AdminPanel/ContractPeriods/Actions/ContractPeriods.actions";
import { fetchPortfolioPeriodReducer } from "components/AdminPanel/ContractPeriods/Actions/ContractPeriods.actions";
import { fetchPortfolioPeriodsSuccess } from "components/PortfolioInFocus/Actions/portfolioInFocus.actions";
import { simpleFetchContract } from "components/AdminPanel/Contracts/Services/contractService";


export const service = null;

const createContractPeriod = (data: Partial<ContractPeriod>, contractId: any): Promise<any> => {
  const { id, ...payload } = data;
  const contract = store.getState().contracts.data[contractId];
  const site = contract.site;
  return APIR.post(CONTRACT_PERIOD_ROUTE, { ...payload, contract: contractId }).pipe(
    unWrapDataAndMeta()
  ).toPromise().then((response) => {
    store.dispatch(actions.updateContractPeriodReducer(response.data));
    simpleFetchContract(contract.contract_ref);
    groupFetchSite([site]);//group fetch also updates relevant parts that update the index...
    return response;
  });
}

const updateContractPeriod = (periodId: string, period: Partial<ContractPeriod>) => {
  const { id, contract, ...contractPeriod } = period;
  const thisContract = contract ? store.getState().contracts.data[contract] : null;
  const site = thisContract?.site;
  return API.patch(`${CONTRACT_PERIOD_ROUTE}${periodId}/?format=json`, contractPeriod).then((rawResponse) => {
    const response = getDataAndMeta(rawResponse);
    store.dispatch(actions.updateContractPeriodReducer(response.data));
    site && groupFetchSite([site]);
    thisContract && simpleFetchContract(thisContract.contract_ref);
  });
}

const deleteContractPeriod = (id: any) => {
  const contractId = store.getState().contractPeriods.data[id].contract;
  const contract = store.getState().contracts.data[contractId];
  const site = contract?.site;
  return API.delete(`${CONTRACT_PERIOD_ROUTE}${id}/`).then((response) => {
    store.dispatch(actions.deleteContractPeriodReducer(id))
    site && groupFetchSite([site]);
    contract && simpleFetchContract(contract.contract_ref);
  });
}

export const simpleFetchContractPeriods = (contract_ref: string) => {
  return API.get(contractPeriodsEndpoint(contract_ref)).then((response) => {
    const contractPeriods = {
      data: getListData(response),
      meta: getListMeta(response),
      permissions: getListPrivileges(response),
    }
    store.dispatch(fetchContractPeriodsSuccess(contractPeriods))
    store.dispatch(fetchContractPeriodReducer(arrayToDict(contractPeriods.data), contractPeriods.meta, contractPeriods.permissions))
    return contractPeriods
  });
}

// portfolio

const createPortfolioPeriod = (data: Partial<PortfolioPeriod>, portfolioId: any): Promise<any> => {
  const { id, ...payload } = data;
  //const site = store.getState().portfolios.data[portfolioId].site;
  return APIR.post(PORTFOLIO_PERIOD_ROUTE, { ...payload, portfolio: portfolioId }).pipe(
    unWrapDataAndMeta()
  ).toPromise().then((response) => {
    store.dispatch(actions.updatePortfolioPeriodReducer(response.data));
    return response;
  });
}

const updatePortfolioPeriod = (periodId: string, period: Partial<PortfolioPeriod>) => {
  const { id, portfolio, ...portfolioPeriod } = period;
  const site = portfolio ? store.getState().portfolios.data[portfolio].site : null;
  return API.patch(`${PORTFOLIO_PERIOD_ROUTE}${periodId}/?format=json`, portfolioPeriod).then((rawResponse) => {
    const response = getDataAndMeta(rawResponse);
    store.dispatch(actions.updatePortfolioPeriodReducer(response.data));
  });
}

const deletePortfolioPeriod = (id: any) => {
  const portfolioId = store.getState().portfolioPeriods.data[id].portfolio;
  const site = store.getState().portfolios.data[portfolioId].site;
  return API.delete(`${PORTFOLIO_PERIOD_ROUTE}${id}/`).then((response) => {
    store.dispatch(actions.deletePortfolioPeriodReducer(id))
    // groupFetchSite([site]);
  });
}

export const simpleFetchPortfolioPeriods = (portfolioId: number) => {
  return API.get(portfolioPeriodsEndpoint(portfolioId)).then((response) => {
    const portfolioPeriods = {
      data: getListData(response),
      meta: getListMeta(response),
      permissions: getPrivileges(response)
    }
    store.dispatch(fetchPortfolioPeriodsSuccess(portfolioPeriods))
    store.dispatch(fetchPortfolioPeriodReducer(arrayToDict(portfolioPeriods.data), portfolioPeriods.meta, portfolioPeriods.permissions))
    return portfolioPeriods
  });
}



export default {
  createPortfolioPeriod,
  updatePortfolioPeriod,
  deletePortfolioPeriod,
  createContractPeriod,
  updateContractPeriod,
  deleteContractPeriod,
}