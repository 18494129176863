/* eslint-disable */
import React, { memo, useState } from 'react'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { MenuItem } from '@material-ui/core';
import { useSubHeadingNavStyles } from '../../Containers/ContractSubHeading/ContractSubHeadingStyles';
import { ContractInterface } from '../../../../AdminPanel/Contracts/Interfaces/Contract.interface';
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { Wrapper } from './ContractSubHeadingContractSelectorStyles';
//import { transformContracts } from './ContractSubHeadingContractSelector.helpers';

interface ContractSubHeadingContractSelectorProps {
  //contracts: ContractInterface[];
  contracts: SiteContract[];
  selectedContract: any;
  onSelectContract: (contract: ContractInterface) => void
}

const ContractSubHeadingContractSelector = ({
  contracts,
  selectedContract,
  onSelectContract
}: ContractSubHeadingContractSelectorProps) => {
  const classes = useSubHeadingNavStyles();

  //const transformedContracts = transformContracts(contracts)

  const getSelectContract = (id: number): ContractInterface =>
    contracts.find(contract => contract.id === id) || selectedContract;

  const handleSelectContract = (element: any) => {
    const contract = getSelectContract(element.target.value);
    onSelectContract(contract);
  }

  return (
    <Wrapper>
      <FormControl className={classes.formControl}>
        <InputLabel shrink htmlFor="mainContractSelector-native-label">
          Contract:
        </InputLabel>
        <Select
          value={selectedContract.id}
          onChange={(value: any) => handleSelectContract(value)}
          id="mainContractSelector"
        >
          {contracts.map(contract =>
            <MenuItem key={contract.id} value={contract.id} className={classes.selectItem}>
              {contract.display_name}
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </Wrapper>
  )
}

export default ContractSubHeadingContractSelector;