import React, { useRef, useState, useEffect, useCallback } from "react";
import moment from 'moment';
import { useSelector } from "react-redux";
import DataGrid, {
  Grouping,
  Column,
  Editing,
  Paging,
  RequiredRule,
  MasterDetail,
  RemoteOperations
} from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import { Paper } from "@material-ui/core";
//import PageViewIcon from '@material-ui/icons/Pageview';
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomStore from "devextreme/data/custom_store";

// Own
import { dateUDF } from 'components/Common/Utils/Dates';
import financialsService from "../../Services/financialServices";
import CommonAPIService from "components/ContractInFocus/Services/commonAPI.services";
import QuoteDetail from "components/ContractInFocus/Financials/Containers/QuoteDetailView";
import Header from "../../../../Common/Components/GridHeader/GridHeader";
import PrintDataGrid from "../../../../Common/Components/PrintDataGrid/PrintDataGrid";
import { IFinancials } from "../../interfaces/financials.interface";
import { AWPendingText, AWDeclinedText } from 'components/ContractInFocus/Financials/Models/AWDocumentsColumns';
import { InlineWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { toTitleCase } from "helpers/String/String.helper";
import {
  ColumnProps,
  DataGridMeta,
} from "components/ContractInFocus/Interfaces/DataGridColumn.interface";
import {
  getColumnProps,
  editableTextAreaOnPreparing,
  getGridProps,
} from "../../../../../helpers/DataGrid/DataGridColumn.helper";

import { columnPropsPlaceHolder } from "components/ContractInFocus/Models/ColumnProps";
import { gridMetaInitialState } from "components/ContractInFocus/Models/Grid";
import { PrintDataGridVisibleColumns } from "../../../../Common/Components/PrintDataGrid/Interfaces/printDataGrid.interfaces";
import { getRemoteOpParamsAndContractScopeParams, getLastXMonthsParams } from 'helpers/Pipelines/contractScopeOperator';
import { reportSectionStructure } from "components/ContractInFocus/Models/contractIndex.model";
import { PrintTitle, getSubTitle } from "../../../Components/ContractPrintTitle/ContractPrintTitle";
import { financialsColumns } from "../../Models/financialsColumn";
import { APIPrivileges } from "services/Interface/Interface";
import { Dictionary } from "components/Common/Interfaces/Entity.interface";
import * as selectors from "components/ContractInFocus/Selectors/contractInFocus.selectors";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { downStreamOfAcceptedStatus } from "components/ContractInFocus/Financials/financials.constants.js";
import { getSubTitleNumber } from "components/ContractInFocus/ContractReport/Helpers/contractReportToc.helper";
import { tocsSelector } from "components/ContractInFocus/Selectors/visibility.selectors";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

import "./financial-grid-styles.scss";

type GenericFinancialsProps = {
  //contract: ContractInterface;
  contract: SiteContract;
  portfolio?: HydratedPortfolio
  ref?: any;
  paging?: boolean;
  remoteOperations?: boolean;
  gridId?: string;
  printPrevent?: boolean;
  refreshFinancialsAt: number;
  noFilter?: boolean;
  gridHeight?: string;
  financialCategory?: string;
  title: string;
  dataTestId: string;
  printColumns: PrintDataGridVisibleColumns[];
  frozenFor?: string;
  sectionNumberMap: Dictionary<number>;
  setPaperQuoteViewData: Function;
  forceReload: () => void;
  mustReload: number;
  contractReportContext?: boolean;
};

const FinancialGrid: React.FC<GenericFinancialsProps> = ({
  contract,
  portfolio,
  gridId,
  printPrevent,
  paging,
  remoteOperations,
  refreshFinancialsAt,
  noFilter,
  gridHeight,
  financialCategory,
  title,
  dataTestId,
  printColumns,
  frozenFor,
  sectionNumberMap,
  setPaperQuoteViewData,
  forceReload,
  mustReload,
  contractReportContext
}) => {
  const selectedSnapshot = useSelector(selectors.contractOrPortfolioSnapshotSelector({ portfolioId: portfolio?.id, contractId: contract?.id }));
  const selectedPeriod = useSelector(selectors.contractOrPortfolioPeriodSelector({ portfolioId: portfolio?.id, contractId: contract?.id }));

  const isMountedRef = useRef(true);
  const [dataSource, setDataSource] = useState<DataSource>();
  const [hideClientPermissions, sethideClientPermissions] = useState<APIPrivileges>();
  const [metadata, setMetadata] = useState<DataGridMeta>(gridMetaInitialState);
  const [dataGridContentReady, setContentReady] = useState(false);
  const rowStates = useRef<any>({});
  const [subTitleIndex, setSubTitleIndex] = useState<string>('');
  //const [paperQuoteViewData, setPaperQuoteViewDataData] = useState<IFinancials | undefined>();
  const reportSection = reportSectionStructure.Financial;
  const tocs = useSelector(tocsSelector);
  const [printData, setPrintData] = useState<any[]>();

  const gridRef = useRef<DataGrid>(null);

  const getEndpoint = useCallback(
    () => {
      let ep;
      if (portfolio) {
        ep = `portfolios/${portfolio?.id}/financials/?format=json${financialCategory ? `&financial-category=${financialCategory}` : ''}`
      } else {
        ep = `contracts/${contract?.contract_ref}/financials/?format=json${financialCategory ? `&financial-category=${financialCategory}` : ''}`
      }
      return ep;
    },
    [contract, financialCategory, portfolio]
  );

  const processValues = useCallback((item: IFinancials) => {
    // glossary:
    // item.client_po = PO reference registered in FP
    // item.awquoteclientresponse response submitted via Apprise
    // item.status status comes from FP field, but here it is overridden for display in certain circumstances (i.e. where not in a definite 'downstream state' in FP)
    if (item.awquoteclientresponse?.reference?.length && !item.client_po?.length) {
      item.client_po = AWPendingText // if a PO reference has been submitted via apprise but none appears in FP
    } else if (!item.client_po?.length && item.awquoteclientresponse?.quote_declined) {
      item.client_po = AWDeclinedText //this will now be used as the value to check if the quote has been declined...
    }
    if (!downStreamOfAcceptedStatus.includes(item.status)) {
      if (item.client_po == AWDeclinedText) {
        item.status = "client rejected"
      } else if (item.awquoteclientresponse?.reference?.length || item.client_po?.length) {
        item.status = "client accepted"
      } else if (item.status === "quote" && item.quote_expired) {
        item.status = "quote expired"
      }
    }

    if (item.status) {
      item.status = toTitleCase(item.status)
    }
    return item
  }, []);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    }

  }, []);

  useEffect(() => {
    const thisSubTitleIndex = getSubTitleNumber(
      title,
      tocs.flatTocsLookup,
    )
    thisSubTitleIndex && setSubTitleIndex(thisSubTitleIndex);
  }, [tocs, title])

  useEffect(() => {
    if (mustReload && mustReload > 0) {
      dataSource?.reload();
    }
  }, [mustReload, dataSource])

  useEffect(() => {
    if ((contract || portfolio) && selectedPeriod) {
      const contract_ref = contract?.contract_ref;

      if (portfolio) {
        sethideClientPermissions({ POST: false, PUT: false, DELETE: false });
      } else {
        contract_ref && financialsService.permissionsHideClientFinancial(contract_ref, sethideClientPermissions);
      }

      const custom = new CustomStore({
        key: "id",
        load: (loadOptions: any) => {
          let startDate;
          let endDate;
          let adaptedLoadOptions = { ...loadOptions };
          const sortOptions = (loadOptions.sort === null || !loadOptions.sort) ? [] : loadOptions.sort;

          const selectedSortOptions = sortOptions.filter((x: any) => x.selector !== "relegation_status");

          if (!selectedSortOptions || !selectedSortOptions.length) {
            adaptedLoadOptions = {
              ...adaptedLoadOptions,
              sort: [
                ...sortOptions,
                { selector: 'relegation_status', desc: false, isExpanded: true },
                { selector: 'date_requested', desc: true }]
            }
          }

          if (!selectedSnapshot && financialCategory) { // if 'unfiltered' financialCategory should be undefined and we'll get the entire CP data too
            const now = moment();
            const endOfCP = moment(selectedPeriod?.end_date);
            const endDateToUse = now > endOfCP ? endOfCP : now;
            const boundaries = getLastXMonthsParams(1, undefined, endDateToUse.format(dateUDF), selectedSnapshot);
            startDate = boundaries['start-date'];
            endDate = boundaries['end-date'];
          }
          const params = getRemoteOpParamsAndContractScopeParams({
            loadOptions: adaptedLoadOptions,
            selectedPeriod: financialCategory ? selectedPeriod : undefined,
            selectedSnapshot,
            contractReportContext,
            startDate,
            endDate
          });
          return (CommonAPIService.getAll<IFinancials>(
            getEndpoint,
            setMetadata,
            portfolio?.id || contract_ref,
            params
          ).then((response: any) => {
            let processedData;
            if (response.grouped) {
              // response.grouped is just a flag we create on the BE as there is nothing 100% reliable for detecting if the data is intended to be 
              // processsed as a group otherwise... e.g. items (specified by DX when returning groups) could just be an attribute of a normal object.
              processedData = response.data.map((group: any) => {
                return {
                  ...group,
                  items: group.items ? group.items.map((item: IFinancials) => {
                    return {
                      ...processValues(item),
                    }
                  }) : null
                }
              })
            } else {
              processedData = response.data.map((item: IFinancials) => {
                return ({
                  ...processValues(item),
                })
              })
            }

            response.data = processedData;

            setPrintData(processedData);

            const forGrid = {
              ...response.metadata,
              data: response.data,
              totalCount: response.totalCount
            }
            return forGrid;
          }
          ))
        },

        // insert: (values) =>
        //   CommonAPIService.create<IFinancials>(
        //     getEndpoint,
        //     contract,
        //     values
        //   ),
        // remove: (key) => handleDelete(key),
        // update: (id, values) =>
        //   CommonAPIService.update<IFinancials>(
        //     getEndpoint,
        //     contract_ref,
        //     id,
        //     values
        //   ),
      });

      const dataStore = new DataSource({
        store: custom
      })

      isMountedRef.current && setDataSource(dataStore);

      return () => {
        dataStore && dataStore.dispose();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract, selectedPeriod, financialCategory, refreshFinancialsAt, portfolio, getEndpoint]);

  // const handleDelete = (financialId: any) => {
  //   return CommonAPIService.del(
  //     getEndpoint,
  //     contract.contract_ref,
  //     financialId
  //   );
  // };

  const getColumnPropsExt = (field: string): ColumnProps => {
    if (isMountedRef.current && metadata.loaded) {
      return getColumnProps(field, metadata.activeMeta, false)
    }
    return columnPropsPlaceHolder;
  };

  const handleEditingStart = () => {
    if (metadata) {
      isMountedRef.current && setMetadata({ ...metadata, activeMeta: metadata.PUTMeta });
    }
  };

  const handleRowUpdated = () => {
    if (metadata) {
      isMountedRef.current && setMetadata({ ...metadata, activeMeta: metadata.POSTMeta });
    }
  };

  const handleRowExpanded = (e: any) => {
    //TODO: passing client response ref through to awdetail view to help deal with devextreme grid detail view caching -  
    //Currently this means detail view will get data from previous expansion - using setState and reloading leads to ‘flicker’.  
    // Will return to improve this. 
    if (e.key && ![false, true].includes(e.key[0])) { //this handles the case where we're expanding a group - e.key = [false] or [null]
      rowStates.current[e.key] = "expanding";
      financialsService.getClientResponseForAW(e.key, portfolio?.id).then(
        (clientResponse: any) => {
          let r;
          if (clientResponse.data.length) {
            r = clientResponse.data[0];
          } else {
            r = null;
          }
        }
      );
    }
  }

  const handleRowCollapsed = (e: any) => {
    rowStates.current[e.key] = "collapsing"
  }

  const getGroupCaption = (e: any) => {
    return e.value ? "Excluded from Report" : "Shown In Report"
  };

  const handleOpenQuotePageView = (data: IFinancials) => {
    setPaperQuoteViewData(data);
  }

  // const handleRowPrepared = (rowInfo: any) => {
  //   if (rowInfo.rowType === 'data') {
  //     if (rowInfo.data.status === "Quote Expired") {
  //       rowInfo.rowElement.addClass('expired');
  //     }
  //   }
  // }

  const handleOnCellPrepared = (e: any) => {
    if (e.row?.data?.status === "Quote Expired" && e.value === "Quote Expired") {
      e.cellElement.addClass('expired');
    }
  }

  const PrintHeading = () => <PrintTitle sectionNumberMap={sectionNumberMap} reportSection={reportSection} />;

  return (

    <InlineWrapper data-test-id={dataTestId}>
      <Paper elevation={3}>
        {!printPrevent && <PrintHeading />}
        <Header
          title={title}
          subTitle={getSubTitle(metadata)}
          className="no-print"
        //subTitle={`Your ${title.toLocaleLowerCase()} for this contract ${frozenFor}`}
        />
        {!printPrevent && <Header
          title={`${subTitleIndex} ${title}`}
          subTitle={getSubTitle(metadata)}
          className="no-screen"
        />}
        {dataSource && (
          <DataGrid
            id={gridId ? gridId : undefined}
            ref={gridRef}
            repaintChangesOnly
            className="no-print financialsGrid"
            dataSource={dataSource}
            remoteOperations={remoteOperations && !frozenFor}
            {...getGridProps(metadata.activeMeta)}
            onEditorPreparing={editableTextAreaOnPreparing(metadata.activeMeta)}
            onEditingStart={handleEditingStart}
            onRowUpdated={handleRowUpdated}
            onRowExpanded={handleRowExpanded}
            onRowCollapsed={handleRowCollapsed}
            onCellPrepared={handleOnCellPrepared}
            onContentReady={() => isMountedRef.current && setContentReady(true)}
            dateSerializationFormat="yyyy-MM-dd"
            height={gridHeight ? gridHeight : undefined}
            filterRow={{
              visible: true,
              applyFilter: 'auto',
              //showOperationChooser: false,
            }}
          >
            <Grouping autoExpandAll={true} />
            {/* Note that we should not page in the context of snapshots - as otherwise the report may miss data. */}
            {paging && !frozenFor ? <Paging defaultPageSize={20} /> : <Paging enabled={false} />
            }

            {metadata.activeMeta.relegation_status && <Column
              visible={false}
              dataField="relegation_status"
              groupCellRender={getGroupCaption}
              groupIndex={0}
            />}

            {financialsColumns.map((item, idx) => (
              <Column key={idx} {...getColumnPropsExt(item.name)} {...item}>
                {item.required && <RequiredRule />}
              </Column>
            ))}

            {!selectedSnapshot && (
              <Column
                width={60}
                allowEditing={false}
                cellRender={(e) => (
                  <FontAwesomeIcon
                    icon={faFile}
                    cursor="pointer"
                    onClick={() => handleOpenQuotePageView(e.data)}
                  />
                )}
              />
            )}

            <MasterDetail
              enabled={!selectedSnapshot ? true : false}
              component={
                (e) => {
                  return (<QuoteDetail
                    data={e.data.data}
                    contract={contract}
                    portfolio={portfolio}
                    rowReference={rowStates}
                    dataSource={dataSource}
                    forceReload={forceReload}
                    hideClientPermissions={hideClientPermissions}
                    processValues={processValues}
                  />)
                }
              }
            />
          </DataGrid>
        )}
        {!printPrevent &&
          <PrintDataGrid
            meta={metadata.activeMeta}
            visibleColumns={printColumns}
            //records={dataSource?.items().find((item) => !item.key)?.items}
            records={printData}
          />
        }
      </Paper>
    </InlineWrapper>
  );
};

//FinancialGrid.whyDidYouRender = true;

export default FinancialGrid;
