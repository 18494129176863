import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  margin-left: -100%;
  display: flex;
  justify-content: flex-end;
  padding: 4px;
`;
