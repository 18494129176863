import React from "react";
import DataGrid, { Column, Editing, Paging, Lookup, RequiredRule } from "devextreme-react/data-grid";

import { getGridProps } from '../../../../helpers/DataGrid/DataGridColumn.helper';
import { getEventEditorPreparing } from "components/ContractInFocus/Logs/Helpers/LogsGrid.helper";
import { NewLogsProps } from "../Logs";
import LogsWrapper from "components/ContractInFocus/Logs/LogsWrapper";
import { personnelMatterLogsColumns, personnelMatterLogsColumnWidths, portfolioPersonnelMatterLogsColumnWidths } from "../Models/logsColumns.model";
import { Dictionary } from "components/Common/Interfaces/Entity.interface";
import { reportSubtitles } from "components/ContractInFocus/Models/contractIndex.model";
import { removeDisabledFieldsBasedOnRecordMeta } from "helpers/Forms/form.helper";

const getContractEndpoint = (ref?: string | number) => `contracts/${ref}/personnel-matter-logs/?format=json`;
const getPortfolioEndpoint = (id?: string | number) => `portfolios/${id}/personnel-matter-logs/?format=json`;

const PersonnelMatterLogs: React.FC<NewLogsProps> = (
    {
        dataSource,
        metadata,
        setContentReady,
        handleEditingStart,
        handleRowUpdated,
        handleOnRowPrepared,
        getColumnPropsExt,
        portfolio,
        contract,
        styles
    }) => {
    const columnWidths = portfolio ? portfolioPersonnelMatterLogsColumnWidths : personnelMatterLogsColumnWidths;

    const handleOnEditorPreparing = (e: any) => {
        removeDisabledFieldsBasedOnRecordMeta(e, metadata.POSTMeta);
        const func1 = getEventEditorPreparing(metadata);
        func1(e);
    }
    return (

        <div>
            {dataSource && (
                <DataGrid
                    className="no-print"
                    dataSource={dataSource}
                    {...getGridProps(metadata.activeMeta)}
                    //onEditorPreparing={getEventEditorPreparing(metadata)} //{editableTextAreaOnPreparing(metadata.activeMeta)}
                    onEditorPreparing={handleOnEditorPreparing}
                    onEditingStart={handleEditingStart}
                    onRowUpdated={handleRowUpdated}
                    onRowPrepared={handleOnRowPrepared}
                    onContentReady={() => setContentReady(true)}
                    dateSerializationFormat="yyyy-MM-dd"
                    style={styles}
                >
                    <Editing
                        mode="cell"
                        allowUpdating={metadata.privileges.PUT}
                        allowDeleting={metadata.privileges.DELETE}
                        allowAdding={metadata.privileges.POST}
                    />

                    <Paging enabled={false} />
                    {portfolio && <Column
                        {...getColumnPropsExt('contract')}
                        //@ts-ignore
                        width={columnWidths['contract']}
                        caption="Contract"
                    ></Column>
                    }
                    <Column
                        {...getColumnPropsExt('date')}
                        width={columnWidths['date']}
                    >
                        <RequiredRule />
                    </Column>
                    <Column
                        {...getColumnPropsExt('details')}
                        width={columnWidths['details']}

                    >
                        <RequiredRule />
                    </Column>
                    <Column
                        {...getColumnPropsExt('category')}
                        width={columnWidths['category']}

                    >
                        <RequiredRule />
                        <Lookup
                            dataSource={metadata.loaded ? metadata.POSTMeta?.category.choices : []}
                            valueExpr="value"
                            displayExpr="display_name"
                        />
                    </Column>
                    <Column
                        {...getColumnPropsExt('name')}
                        width={columnWidths['name']}

                    >
                        <RequiredRule />
                    </Column>
                    <Column {...getColumnPropsExt('resolved_on')}
                        width={columnWidths['resolved_on']}
                    />
                    <Column {...getColumnPropsExt('resolution')}
                        width={columnWidths['resolution']}
                    />

                </DataGrid>
            )}
        </div>

    );
};

const getWrappedPersonnelMatterLogs = (contract: any, portfolio: any, sectionNumberMap: Dictionary<number>) => {
    return () => {
        let getEndpoint: any;
        if (contract) {
            getEndpoint = getContractEndpoint;
        } else if (portfolio) {
            getEndpoint = getPortfolioEndpoint;
        }
        return <LogsWrapper
            WrappedLogsGrid={PersonnelMatterLogs}
            getEndpoint={getEndpoint}
            contract={contract}
            portfolio={portfolio}
            logColumnsDef={personnelMatterLogsColumns}
            logsHandle='PersonnelMatters'
            logsTitle={reportSubtitles.PersonnelMatters}
            sectionNumberMap={sectionNumberMap}
        />
    }
}

export default getWrappedPersonnelMatterLogs;
