import React from "react";
import { useSelector } from "react-redux";

// Own
import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";
import {
  DataGridUpdateEvent,
  DataGridDropEvent,
} from "interfaces/datagrid.interfaces";
import { AdminDataGrid } from "components/AdminPanel/Containers/AdminDataGrid";
import { adminPanelType } from "components/AdminPanel/Models/AdminPanel.model";
import { adminListContractsSelector } from "components/AdminPanel/Contracts/Selectors/Contracts.selectors";
import { unfilteredContractDatagridWithSiteColumns, unfilteredContractDatagridColumns, getContractGridActions } from "components/AdminPanel/Contracts/Models/ContractDetail.model";
import { contractWithLookupSelector } from "components/AdminPanel/Organisation/Selectors/Organisations.selectors";
import { peopleCalcLookupSelector } from "components/AdminPanel/People/Selectors/People.selector";
import { sitesLookupSelector } from "components/AdminPanel/Sites/Selectors/Sites.selectors";
import { transformContractMetaForGrid } from "components/AdminPanel/Contracts/Helper/contractHelper";
import { apiSuccess, openPanelInSelectionMode } from 'components/AdminPanel/Helpers/AdminPanel.helper';
import { ContractInterface } from "components/AdminPanel/Contracts/Interfaces/Contract.interface";

import contractServices from "components/AdminPanel/Contracts/Services/contractService";
import { EntityKeys } from "constants/entity.constants";

interface ContractSublistViewProps {
  panelId: string;
  masterRecordData?: object;
  id: any;
  contractsState: any;
  dataTestId: string;
  handleDropCB?: any;
  withSite: boolean;
  masterRecordType?: string;
  selectActionIdentifier?: string;
  onAddLinks?: Function;
  onUnlink?: (item: any) => void;
  linkPanelTitle?: string;
}

const ContractSublistView: React.FunctionComponent<ContractSublistViewProps> = ({
  panelId,
  masterRecordData,
  id,
  contractsState,
  dataTestId,
  handleDropCB,
  withSite = false,
  masterRecordType,
  selectActionIdentifier,
  onAddLinks,
  onUnlink,
  linkPanelTitle
}) => {
  const selectContractState = useSelector(adminListContractsSelector);
  const selectOrganisationLookupState = useSelector(contractWithLookupSelector);
  const selectPeopleLookupState = useSelector(peopleCalcLookupSelector);
  const selectSiteLookupState = useSelector(sitesLookupSelector);

  const cloneMembersFromState = (): ContractInterface[] => {
    return (contractsState || []).slice();
  };

  const members = cloneMembersFromState();
  const currentMemberIds = members?.map(p => p.id) || [];

  const handleUpdate = (e: DataGridUpdateEvent) => {
    // @ts-ignore
    e.cancel = contractServices.updateContract(e.oldData.contract_ref, e.newData, selectContractState.recordmeta[e.key]).then(apiSuccess);
  };

  const handleContractLink = (): void => {
    //openPanelWithDragMessage(adminPanelType.people);
    masterRecordType && selectActionIdentifier && linkPanelTitle && openPanelInSelectionMode(
      {
        selectActionIdentifier,
        masterRecordType,
        masterRecordData,
        linkedRecordType: adminPanelType.contracts,
        onAddLinks,
        currentMemberIds,
        linkPanelTitle,
      }
    )
  };

  const linkContract = selectActionIdentifier ? handleContractLink : undefined;

  const getContractMeta = (): FieldMetaGroup =>
    transformContractMetaForGrid(selectContractState.meta, selectOrganisationLookupState, selectPeopleLookupState, selectSiteLookupState);

  const handleFetchContractGroup = () => { };

  const handleDrop = (e: DataGridDropEvent) => {
    handleDropCB && handleDropCB(e.itemData, id);
  };

  return (
    <AdminDataGrid
      data={contractsState}
      meta={getContractMeta()}
      permissions={selectContractState.permissions}
      recordmeta={selectContractState.recordmeta}
      dataTestId={dataTestId}
      getDatagridColumns={withSite ? unfilteredContractDatagridWithSiteColumns : unfilteredContractDatagridColumns}
      masterView={false}
      entityKey={EntityKeys.Contracts}
      columnActions={getContractGridActions(onUnlink)}
      panelId={panelId}
      dragAndDrop={false}
      fetchGroup={handleFetchContractGroup}
      onUpdate={handleUpdate}
      onDrop={handleDrop}
      onLink={linkContract}
    />
  );
};

//ContractSublistView.whyDidYouRender = true;

export default ContractSublistView;
