import { APIR } from "../../../services/API/API";
import { getData } from "../../../services/API/API.helper";
import { catchError, map } from "rxjs/operators";
import { of } from "rxjs";

export const updateAvatarApi = (file: any, progress: any) => {
  const formData = new FormData();
  formData.append('avatar', file, file.name);

  return APIR.patch(`/people/me/`, formData, {
    onUploadProgress: progressEvent =>
      progress && progress(false, progressEvent.loaded, progressEvent.total)
  }).pipe(
    map((response) => getData(response)),
    catchError((error) => of(error))
  );
};

export const clearAvatarApi = () => {
  return APIR.patch(`/people/me/`, { avatar: null }).pipe(
    map((response) => getData(response)),
    catchError((error) => of(error))
  );
};
