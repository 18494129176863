import { createRequestActionTypes } from "../../components/Common/Utils/ReduxUtil";

export const ADD_DATA_SUBITEM = createRequestActionTypes('ROOT_ADD_DATA_SUBITEM');
export const addDataSubItem = (branch: string, sublistKey: string, recordId: number, newSubEntity: any) =>
  ({ type: ADD_DATA_SUBITEM.start, branch, sublistKey, recordId, newSubEntity });

export const ADD_SUBITEM = createRequestActionTypes('ROOT_ADD_SUBITEM');
export const addSubItem = (branch: string, leaf: string, recordId: number, entityPayload: any) =>
  ({ type: ADD_SUBITEM.start, branch, leaf, recordId, entityPayload });

export const REMOVE_DATA_SUBITEM = createRequestActionTypes('ROOT_REMOVE_DATA_SUBITEM');
export const removeDataSubItem = (branch: string, sublistKey: string, recordId: number, subitemId: any) =>
  ({ type: REMOVE_DATA_SUBITEM.start, branch, sublistKey, recordId, subitemId });

export const REMOVE_SUBITEM = createRequestActionTypes('ROOT_REMOVE_SUBITEM');
export const removeSubItem = (branch: string, leaf: string, recordId: number, entityId: any) =>
  ({ type: REMOVE_SUBITEM.start, branch, leaf, recordId, entityId });

export const CANCEL_DATA_SUBITEM = createRequestActionTypes('ROOT_CANCEL_DATA_SUBITEM');
export const cancelDataSubItem = (branch: string, sublistKey: string, recordId: number, newSubEntity: any) =>
  ({ type: CANCEL_DATA_SUBITEM.start, branch, sublistKey, recordId, newSubEntity });

export const SET_SUBITEM_MODE = createRequestActionTypes('ROOT_SET_SUBITEM_MODE');
export const setSubItemMode = (branch: string, sublistKey: string, recordId: number, subItemId: number, mode: string) =>
  ({ type: SET_SUBITEM_MODE.start, branch, sublistKey, recordId, subItemId, mode });

export const SET_SUBITEM_DATA_MODE = createRequestActionTypes('ROOT_SET_SUBITEM_DATA_MODE');
export const setSubItemDataAndMode = (branch: string, sublistKey: string, recordId: number, subItemId: number, data: any, mode: string) =>
  ({ type: SET_SUBITEM_DATA_MODE.start, branch, sublistKey, recordId, subItemId, data, mode });

export const SET_SUBITEM_DATA = createRequestActionTypes('ROOT_SET_SUBITEM_DATA');
export const setSubItemData = (branch: string, sublistKey: string, recordId: number, data: any) =>
  ({ type: SET_SUBITEM_DATA.start, branch, sublistKey, recordId, data });

export const CANCEL_NEW_SUBITEM = createRequestActionTypes('ROOT_CANCEL_NEW_SUBITEM');
export const cancelNewSubItem = (branch: string, sublistKey: string, recordId: number) =>
  ({ type: CANCEL_NEW_SUBITEM.start, branch, sublistKey, recordId });

export const NEW_SUBITEM = createRequestActionTypes('ROOT_NEW_SUBITEM');
export const newSubItem = (branch: string, sublistKey: string, recordId: number, newItem: any) =>
  ({ type: NEW_SUBITEM.start, branch, sublistKey, recordId, newItem });

export const SAVE_NEW_SUBITEM = createRequestActionTypes('ROOT_SAVE_NEW_SUBITEM');
export const saveNewSubItem = (branch: string, sublistKey: string, recordId: number, newItem: any) =>
  ({ type: SAVE_NEW_SUBITEM.start, branch, sublistKey, recordId, newItem });


