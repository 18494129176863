import React, { useState } from "react";
import MuiDrawer from "@material-ui/core/Drawer";
import clsx from 'clsx';
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';

interface drawerStyles {
    drawerWidth: number,
    drawerWidthClosed?: number,
    theme: Theme,
    downShift?: number,
    zIndex?: number,
    collapsible?: boolean,
    collapseButtonDownShift: number,
}

const getDrawerStyles = (
    drawerWidth: number,
    theme: Theme,
    drawerWidthClosed?: number,
    downShift?: number,
    zIndex?: number,
) => {

    const createStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            zIndex: zIndex,
            top: downShift || 0,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        paper: {
            top: downShift || 0,
        },
        drawerExpanded: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerCollapsed: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            //overflowX: 'hidden',
            width: drawerWidthClosed || theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: drawerWidthClosed || theme.spacing(9) + 1,
            },
        },
        drawerClosed: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: 0,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
    }));
    return createStyles();
}

interface InDrawerConfig {
    drawerWidth: number;
    downShift: number;
    zIndex: number;
    drawerWidthClosed?: number;
    variant?: "permanent" | "persistent" | "temporary" | undefined;
    anchor?: "bottom" | "left" | "right" | "top" | undefined;
}

interface InDrawerProps { }

export const UseDrawer = (Menu: React.FunctionComponent<any>, configurations: InDrawerConfig) => {
    const InDrawer: React.FunctionComponent<InDrawerProps> = ({
        ...props
    }) => {
        const theme = useTheme();
        const { drawerWidth, downShift, zIndex, drawerWidthClosed } = configurations;
        const classes = getDrawerStyles(drawerWidth, theme, drawerWidthClosed, downShift, zIndex);
        //@ts-ignore
        const { collapsed, toggleCollapseSidebar, closed, toggleHideSideBar } = props;

        return <MuiDrawer
            className={clsx(classes.drawer, {
                [classes.drawerExpanded]: !closed && !collapsed,
                [classes.drawerCollapsed]: !closed && collapsed,
                [classes.drawerClosed]: closed,
                "no-print": true,
            })}  // aside-container may be seen as defaults to adjust styles it makes more sense to wrap the component produced by this HOC and add appropriate specific css
            classes={{
                paper: clsx(classes.paper, {
                    [classes.drawerExpanded]: !closed && !collapsed,
                    [classes.drawerCollapsed]: !closed && collapsed,
                    [classes.drawerClosed]: closed,
                }),
            }}
            PaperProps={{ elevation: 2 }}
            variant={configurations.variant || "permanent"} // undefined will result in permanent
            //closed={closed}
            anchor={configurations.anchor || "left"}
            elevation={2}
        >

            <Menu {...{ ...props, toggleCollapseSidebar, collapsed, toggleHideSideBar, closed }}></Menu>
        </MuiDrawer>
    }
    InDrawer.displayName = `WithPageContext(HoC)(${getDisplayName(Menu)})`;
    return InDrawer;
}

const getDisplayName = (WrappedComponent: any) => {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default UseDrawer;