import React, { useState, useEffect, useCallback, useRef, useReducer } from 'react';
import { Paper } from "@material-ui/core";
import { useSelector } from 'react-redux';

import { internalMemberSelector } from 'components/Profile/Selectors/Profile.selector';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import "./basicImagesGridStyles.scss";

// Own
import { Dictionary, FieldMetaGroup, TransformObjectResponse } from "components/Common/Interfaces/Entity.interface";
import { getDocumentsApi, deleteDocumentApi } from "store/Common/Helpers/commonHelpers";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import MatConfirmationDialog from 'components/Common/Components/Material/MatConfirmationDialog/MatConfirmationDialog';
import GeneralEntityForm, { generateStandardGeneralActionButtonDefinitions } from "components/Common/Components/GeneralEntityForm/GeneralEntityForm";
import { FormErrorsType } from "store/Common/Interfaces/Common.interface";
import { store } from "store/store";
import { addNotification } from 'components/Notification/Actions/Notification.actions';
import { NOTIFICATION_SUCCESS } from 'components/Notification/Constants/constants';


export interface formValues {
    [field: string]: string | number | boolean | null | undefined; //represents field: actual value
}

const descriptionFormFieldsConfig = {
    description: {
        multiline: true,
        rows: 3
    },
};

export interface BasicImage {
    full_size: string;
    thumbnail: string;
}

interface deletionConfirmationState {
    open: boolean;
    agreeCallback: () => void;
    message: string;
    img?: any;
}


export interface GeneralImagesGridProps {
    listBasePath: string;
    fetchFilters?: {
        [name: string]: string;
    };
    contract?: SiteContract;
    portfolio?: HydratedPortfolio;
    surrogate_portfolio?: HydratedPortfolio;
    refreshSignal: any;
    deleteImageOverride?: (baseUrl: string, id: string | number, params: {
        [name: string]: string;
    }) => Promise<any>;
    allowDelete?: boolean;
    allowEdit?: boolean;
    dispatchRefreshContext: React.DispatchWithoutAction;
    showDescription?: boolean;
    handleUpdateDescription?: UpdateImageDescriptionFunc;
}

export type UpdateImageDescriptionFunc = ({ id, payload }: { payload: any, id: string }) => Promise<TransformObjectResponse<Dictionary<any>>>;
interface ImageDescriptionFormProps {
    handleUpdateDescription?: UpdateImageDescriptionFunc
    allowEdit?: boolean;
    image: any;
    meta: FieldMetaGroup;
}

const ImageDescriptionForm = ({ image, handleUpdateDescription, allowEdit, meta }: ImageDescriptionFormProps) => {

    const formValuesRef = useRef<Dictionary<string>>({});
    const [formErrors, setFormErrors] = useState<FormErrorsType>({});
    const [thisImage, setImage] = useState(image);
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 1);
    const formLevelSharedSpace = useRef({});

    const handleSave = useCallback((recordId?: string, callback?: any) => {
        if (meta) {
            const payload = { 'description': formValuesRef.current['description'] };
            recordId
                && handleUpdateDescription && handleUpdateDescription({
                    id: recordId,
                    payload: payload,

                }).then((response) => {
                    store.dispatch(addNotification({ message: "Saved", type: NOTIFICATION_SUCCESS }))
                    console.log(response.data);
                    setImage(response.data.data);
                    callback && callback();
                }).catch((e) => {
                    console.log('issue: ', e);
                });
        } else {
            console.log('no put meta!')
        }
    }, [meta, formValuesRef, handleUpdateDescription]);

    const getButtons = useCallback(() => {
        const theseButtons = allowEdit ? generateStandardGeneralActionButtonDefinitions({
            handleSave: handleSave,
            formErrors: formErrors,
            showDelete: false,
        }) : []
        return theseButtons;
    }, [allowEdit, handleSave, formErrors]);

    return <div className='descriptionEditField'>
        {allowEdit &&
            <GeneralEntityForm
                buttons={getButtons()}
                formValuesRef={formValuesRef}
                useDefaultRevertChanges
                refreshSignal={mustRefresh}
                formLevelSharedSpace={formLevelSharedSpace.current}
                initialData={thisImage}
                canWrite={!!allowEdit}
                formFieldsConfig={descriptionFormFieldsConfig}
                meta={meta}
                dispatchRefreshContext={forceUpdate}
                gridClass="imageEditDescriptionForm"
                formErrors={formErrors}
                setFormErrors={setFormErrors}
            />
        }
    </div>

}

const ImagesGrid = (
    {
        listBasePath,
        fetchFilters,
        surrogate_portfolio,
        refreshSignal,
        deleteImageOverride,
        allowDelete,
        allowEdit,
        dispatchRefreshContext,
        showDescription,
        handleUpdateDescription
    }: GeneralImagesGridProps) => {

    const basePath = listBasePath;
    const [response, setResponse] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [images, setImages] = useState<any>();
    const [metaInfo, setMetaInfo] = useState<any>();

    useEffect(() => {
        let fetchParams: any = fetchFilters || {};
        let surrogatePortfolioParams = {}
        if (surrogate_portfolio?.id) {
            surrogatePortfolioParams = {
                surrogate_portfolio: surrogate_portfolio?.id
            }
            fetchParams = { ...fetchFilters, ...surrogatePortfolioParams }
        }
        getDocumentsApi(basePath, setMetaInfo, fetchParams, undefined, undefined).then((data) => {
            setImages(data);
            setTimeout(() => {
                setLoading(false);
            }, 250)
        });
    }, [basePath, surrogate_portfolio, fetchFilters, refreshSignal]);

    const internalMember = useSelector(internalMemberSelector);

    let shouldShowInternalAccess = false;

    let checkMeta = metaInfo?.putMeta || metaInfo?.meta;

    // undefined counts as false, internal member overrides read only because in some contexts internal access only might not be editable
    // but should be visible for internal staff.
    shouldShowInternalAccess = !!(checkMeta?.internal_access_only && (internalMember || !checkMeta?.internal_access_only?.read_only));

    // const handleDownload = useCallback((component: any) => {
    //     window.open(component.row.data.download_link);
    // }, [])

    // const handleOpen = useCallback((component: any) => {
    //     window.open(component.row.data.file, '_blank');
    // }, []);

    // const hasMeta = useCallback(() => metaInfo?.meta && !!Object.keys(metaInfo?.meta).length, [metaInfo?.meta]);
    // const docsPresent = useCallback(() => metaInfo && metaInfo.misc.numDocs && metaInfo.misc.numDocs > 0, [metaInfo]);

    const confirmationInitialState: deletionConfirmationState = { open: false, agreeCallback: () => { }, message: '' };
    const [confirmationDialog, setConfirmationDialog] = useState(confirmationInitialState);

    const handleDelete = (image: any): void => {
        setConfirmationDialog({
            open: true,
            agreeCallback: () => {
                const thisDelete = deleteImageOverride || deleteDocumentApi;
                thisDelete(basePath, image.id, {}).then(
                    () => {
                        console.log(`document ${image.id} removed`);
                        dispatchRefreshContext && dispatchRefreshContext();
                    }
                );
                setConfirmationDialog(confirmationInitialState);
            },
            message: `Are you sure you want to delete the following image: '${image.description}'?`,
            img: image
        });
    }



    return <div className={`${loading ? 'loading' : ''} imagesGrid`}>
        <MatConfirmationDialog
            onAgree={confirmationDialog.agreeCallback}
            onDisagree={() => setConfirmationDialog(confirmationInitialState)}
            open={confirmationDialog.open}
            actions={{ agree: 'Yes', disagree: 'No' }}
        >
            {confirmationDialog.message}
            {confirmationDialog.img?.incident_image?.thumbnail && <div className='smallImageConfirmation'><img src={confirmationDialog.img?.incident_image?.thumbnail} style={{ width: "120px", height: "auto" }} /></div>}
        </MatConfirmationDialog>
        {images && images.map((image: any, i: number) => {
            return <Paper className='imagesGridImageWrapper' key={i} elevation={3}>
                <div className='imgBlockOuter'>
                    <div className='imgBlockInner'>
                        <img src={image.incident_image?.thumbnail} />
                        <div className='imageActions'>
                            <div className='visibilityIcon actionItem'>
                                <IconButton
                                    href={image.incident_image?.full_size} target="_blank"
                                >
                                    <VisibilityIcon

                                    />
                                </IconButton>
                            </div>

                            <div className='downloadIcon actionItem'>
                                <IconButton
                                    href={image.incident_image?.download_link} target="_blank"
                                >
                                    <em className="fa fa-download"></em>
                                </IconButton>
                            </div>
                            {allowDelete && <div className='deleteIcon actionItem'>
                                <IconButton
                                    onClick={() => {
                                        handleDelete(image);
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </div>}
                        </div>
                    </div>
                </div>

                {showDescription &&
                    <ImageDescriptionForm
                        image={image}
                        handleUpdateDescription={handleUpdateDescription}
                        allowEdit={allowEdit}
                        meta={metaInfo.putMeta}
                    />
                }
            </Paper>
        }
        )
        }
    </div>
}

export default ImagesGrid;