import React from "react";
import { Link } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useHistory } from "react-router-dom";

// Styles
import "./SidebarMenu.scss";

const SidebarItemContent = ({ collapsed, item }) => {
  const Icon = item.iconType ? item.iconType : undefined;
  return (
    <div className="main-left-sidebar--label-container">
      <div className="main-left-sidebar--icon sidebar-icon">
        {Icon ? (
          <Icon className={item.className} />
        ) : (
          <em className={item.className || item.icon}></em>
        )}
      </div>
      {!collapsed && (
        <div className="main-left-sidebar--icon-label fadeIn">
          {collapsed ? item.label.value.substr(0, 1) : item.label.value}
        </div>
      )}
    </div>
  );
};

const transformPath = item => ({
  ...item,
  path: item.path + window.location.search
});

/** Normal items for the sidebar */
const SidebarItem = ({ collapsed, item, isActive, handler }) => (
  <li className={isActive ? "active" : ""}>
    <Link to={item.path} title={item.name}>
      <SidebarItemContent collapsed={collapsed} item={item} />
    </Link>
  </li>
);

const SidebarMenu = ({
  collapsed,
  toggleCollapseSidebar,
  toggleHideSideBar,
  closed,
  upperImage,
  refreshSideBar,
  useMenuItems
}) => {
  const history = useHistory();
  const routeActive = paths => {
    const pathLastPageSegment = paths.split("/").filter(x => x !== "");
    const locationLastPageSegment = history.location.pathname
      .split("/")
      .filter(x => x !== "");
    return (
      pathLastPageSegment[pathLastPageSegment.length - 1] ===
      locationLastPageSegment[locationLastPageSegment.length - 1]
    );
  };

  return (
    <div
      className={
        closed
          ? "main-left-sidebar closed"
          : collapsed
          ? "main-left-sidebar collapsed"
          : "main-left-sidebar expanded"
      }
    >
      <div className="left-sidebar-upper-image">
        {upperImage ? (
          <>
            <img
              alt={`${upperImage.name} upperImage thumbnail`}
              src={upperImage ? upperImage.thumbnail : undefined}
            />
          </>
        ) : null}
      </div>
      <div
        className={
          closed
            ? "sideBarToggleWrapper closed"
            : collapsed
            ? "sideBarToggleWrapper collapsed"
            : "sideBarToggleWrapper expanded"
        }
      >
        <>
          {(!collapsed || (collapsed && toggleHideSideBar)) && (
            <IconButton
              className={
                collapsed ? "sideBarToggle collapsed" : "sideBarToggle expanded"
              }
              onClick={() => {
                collapsed ? toggleHideSideBar() : toggleCollapseSidebar();
              }}
            >
              <NavigateBeforeIcon />
            </IconButton>
          )}
        </>
      </div>

      <div
        className={
          collapsed
            ? "sideBarToggleWrapper collapsed opener"
            : "sideBarToggleWrapper expanded opener"
        }
      >
        <IconButton
          className="sideBarToggle collapsed"
          onClick={() => {
            toggleCollapseSidebar();
          }}
        >
          <NavigateNextIcon />
        </IconButton>
      </div>

      <nav data-sidebar-anyclick-close="true" className="sidebar">
        {/* START sidebar nav */}
        <ul className={collapsed ? "sidebar-nav collapsed" : "sidebar-nav"}>
          {/* Iterates over all sidebar items */}
          {useMenuItems.map((item, i) => {
            return (
              <SidebarItem
                collapsed={collapsed} //{settings.isCollapsed}
                isActive={routeActive(item.path)}
                item={transformPath(item)}
                key={i}
              />
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default SidebarMenu;
