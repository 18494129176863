import { createRequestActionTypes } from "../../Common/Utils/ReduxUtil";
import { Profile } from "../Interfaces/Profile.interface";
import { ProfileState } from "../Interfaces/ProfileState.interface";

export const GET_PROFILE = createRequestActionTypes('PROFILE_GET_PROFILE');
export const getProfile = () => ({ type: GET_PROFILE.start });
export const getProfileSuccess = (profile: ProfileState, showProfile = true) => ({ type: GET_PROFILE.success, profile, showProfile });
export const getProfileError = (error: any) => ({ type: GET_PROFILE.error, error });

export const SET_PROFILE_ENTITY = createRequestActionTypes('PROFILE_SET_PROFILE_ENTITY');
export const setProfileEntity = (profile: Profile) => ({ type: SET_PROFILE_ENTITY.start, profile });

export const CANCEL_PROFILE = createRequestActionTypes('PROFILE_CANCEL_PROFILE');
export const cancelProfile = () => ({ type: CANCEL_PROFILE.start });

export const UPDATE_PROFILE = createRequestActionTypes('PROFILE_UPDATE_PROFILE');
export const updateProfile = (profile: Partial<Profile>, ignoreFailure?: boolean) => ({ type: UPDATE_PROFILE.start, profile, ignoreFailure });
export const updateProfileSuccess = (profile: any) => ({ type: UPDATE_PROFILE.success, profile });
export const updateProfileReducer = (profile: any) => ({ type: UPDATE_PROFILE.reducer, profile });
export const updateProfileError = (error: any) => ({ type: UPDATE_PROFILE.error, error });

export const TOGGLE_PERSONAL_PROFILE_SETTINGS = createRequestActionTypes('TOGGLE_PERSONAL_PROFILE_SETTINGS');

export const togglePersonalProfileSettings = (updateKey: string) => {
    return { type: TOGGLE_PERSONAL_PROFILE_SETTINGS.start, payload: { updateKey } }
};


export default UPDATE_PROFILE;


