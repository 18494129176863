import axios from "axios";
import Axios from "axios-observable";
import apiConfig from "./API.model";
import {
  setupInterceptors,
  setupInterceptorsWithoutAutoMessageDispatch
} from "./API.interceptor";

const bindInterceptor = setupInterceptors();

const bindInterceptorWithoutDispatch = setupInterceptorsWithoutAutoMessageDispatch();

const multipartConfig = { headers: { "Content-Type": "multipart/form-data" } };

export const getMultipartConfig = () => ({ ...multipartConfig });

// API
const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  ...apiConfig
});

// API without auto dispatch

export const APIN = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  ...apiConfig
});

export const APINR = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  ...apiConfig
});

API.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
//APIN.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";

bindInterceptor(API);

export default API;

bindInterceptorWithoutDispatch(APIN);
bindInterceptorWithoutDispatch(APINR);

// API OBSERVABLE
export const APIR = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  ...apiConfig
});

APIR.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";

bindInterceptor(APIR);
