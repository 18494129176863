import React, { useState, useEffect, useRef } from "react";

export const getComponent = (props, key) =>
  props.children.filter(comp => {
    return comp.key === key;
  });

export function ConvertClientAddressForDisplay({ origAddressString }) {
  if (!origAddressString || !origAddressString.length) {
    return "";
  }
  const splitAddress = origAddressString.split(/(?:\r\n|\r|\n)/g);
  return splitAddress.map(function(item, idx) {
    return (
      <span key={idx}>
        {item}
        <br />
      </span>
    );
  });
}
