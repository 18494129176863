import { APIPrivileges } from "services/Interface/Interface";
import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";
import { IMeterReadingsStream, ISubmitMeterReadingsStream } from 'components/ContractInFocus/MeterReadings/Interfaces/MeterReadings.interface';
import { createRequestActionTypes } from 'components/Common/Utils/ReduxUtil';

interface fetchContractMeterStreamsResponseI {
    data: IMeterReadingsStream[],
    meta: FieldMetaGroup,
    putMeta?: FieldMetaGroup,
    permissions?: APIPrivileges
}

interface updateMeterStreamsResponseI {
    data: IMeterReadingsStream[]
}

export const FETCH_METER_STREAMS_FOR_CONTRACT = createRequestActionTypes("FETCH_METER_STREAMS_FOR_CONTRACT");
export const fetchMeterStreamsForContract = (contractId: number, payload: fetchContractMeterStreamsResponseI) =>
    ({ type: FETCH_METER_STREAMS_FOR_CONTRACT.reducer, contractId: contractId, payload: payload });

export const UPDATE_METER_STREAMS_FOR_CONTRACT = createRequestActionTypes("UPDATE_METER_STREAMS_FOR_CONTRACT");
export const updateMeterStreamsForContract = (contractId: number, payload: updateMeterStreamsResponseI) =>
    ({ type: UPDATE_METER_STREAMS_FOR_CONTRACT.reducer, contractId: contractId, payload: payload });