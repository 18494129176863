/* eslint-disable */
import { Dictionary } from "../../../Common/Interfaces/Entity.interface";
import { TasksCountType } from "../Models/PPMReactive.model";
import { PortfolioInteractiveTableChart } from '../Interfaces/interactiveTable.interfaces';
import { isNullOrUndefined } from "util";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

// export const roundNumber = (value: number, decimalPlace: number): number =>
//   parseInt(`${value * (Math.pow(10, decimalPlace))}`) / (Math.pow(10, decimalPlace)) || 0;

export function roundNumber(value: number, decimalPlaces: number) {
  const multiplier = Math.pow(10, decimalPlaces);
  let thisNum = Math.round(value * multiplier) / multiplier;
  return thisNum;
}

const getValue = (container: any) => (container || {}).displayValue || (container || {}).value || 0;

// calcIterationDateBeforeStopCalcDate not currently used, but might be reintroduced...
// const calcIterationDateBeforeStopCalcDate = (stopCalculationAfter: Date, iterableDateColumnMonthYear: string) => {
//   const momentBlockDate = moment(stopCalculationAfter);
//   const momentIterationDate = moment(iterableDateColumnMonthYear, 'MMM YYYY', true);
//   return (momentBlockDate.isValid() && momentIterationDate.isValid() && momentBlockDate.isAfter(momentIterationDate))
// }

const getTasksCarriedForward = (
  calculatedRow: Dictionary<any>,
  prevKey: string | null,
  key: string,
  tableDataAsDictionary: Dictionary<any>,
  tasksCountMeta: TasksCountType
) => {
  if (typeof (tasksCountMeta.tasks_carried_forward_manual?.key) == "undefined") {
    return 0;
  }
  const tasksCarriedForwardCell = tableDataAsDictionary[tasksCountMeta.tasks_carried_forward_manual.key][key];
  const tasksCarriedForward = tasksCarriedForwardCell.displayValue || tasksCarriedForwardCell.value;
  const previousValue = prevKey ? getValue(calculatedRow[prevKey]) : null;
  if (isNullOrUndefined(tasksCarriedForward)) {
    return previousValue
  }
  return tasksCarriedForward
}

export const calculatePercentCompleted = (
  calculatedRow: Dictionary<any>,
  prevKey: string | null,
  key: string,
  tableDataAsDictionary: Dictionary<any>,
  tasksCountMeta: TasksCountType
) => {
  const tasksCarriedForward = getTasksCarriedForward(calculatedRow, prevKey, key, tableDataAsDictionary, tasksCountMeta);
  const tasksIssued = tableDataAsDictionary[tasksCountMeta.tasks_issued.key][key].value;
  const tasksCompleted = tableDataAsDictionary[tasksCountMeta.tasks_completed.key][key].value;
  return roundNumber((tasksCompleted / ((tasksCarriedForward + tasksIssued))) * 100, 2);
}

export const calculateNotCompleted = (
  calculatedRow: Dictionary<any>,
  prevKey: string | null,
  dataKey: string,
  tableDataAsDictionary: Dictionary<any>,
  tasksCountMeta: TasksCountType,
) => {
  let returnValue = null;
  const tasksCarriedForward = getTasksCarriedForward(calculatedRow, prevKey, dataKey, tableDataAsDictionary, tasksCountMeta);
  const tasksIssuedElements = Object.keys(tasksCountMeta).filter(x => tasksCountMeta[x].role == "issued");
  const tasksCompletedElements = Object.keys(tasksCountMeta).filter(x => tasksCountMeta[x].role == "completed");

  // we do not want to return 0 from these loops if all the values from elements with a given 'role' are null or undefined.  This is because
  // 0 may well be treated differently from null or undefined in deciding how to display or generally treat values.
  // So we should ensure that: 
  // 1) if the current value is NOT null or undefined, then it is added to the accumulator (or 0 if the accumulator itself is still undefined).
  // 2) If the current value is null or undefined, then it is effectively discarded.

  const logLookforDate = "Jan 2021";
  const logLookforType = "Time spent on PPM(hours)";

  // console.log('tasksCountMeta: ', tasksCountMeta);

  // if (dataKey == logLookforDate && tasksCountMeta.time_spent.display == logLookforType) {
  //   console.log('tasks issued elements: ', tasksIssuedElements);
  //   console.log('tasksCompletedElement: ', tasksCompletedElements);
  // }

  const reducer = (accumulator: number | undefined, currentDataPoint: string) => {
    const currValue = tableDataAsDictionary[tasksCountMeta[currentDataPoint].key][dataKey].value;
    return (currValue == null || currValue == undefined) ? accumulator : (accumulator || 0) + currValue;
  }

  const tasksIssued = tasksIssuedElements.reduce(reducer, undefined);

  // if (dataKey == logLookforDate && tasksCountMeta.time_spent.display == logLookforType) {
  //   console.log('tasksIssued: ', tasksIssued);
  // }

  const tasksCompleted = tasksCompletedElements.reduce(reducer, undefined);

  // if (dataKey == logLookforDate && tasksCountMeta.time_spent.display == logLookforType) {
  //   console.log('tasksCompleted: ', tasksCompleted);
  // }

  if (tasksCompleted || tasksCompleted === 0) {
    returnValue = (tasksCarriedForward || 0) + tasksIssued - tasksCompleted;

    // if (dataKey == logLookforDate && tasksCountMeta.time_spent.display == logLookforType) {
    //   console.log('tasksCarriedForward: ', tasksCarriedForward);
    // }

  }

  // if (dataKey == logLookforDate && tasksCountMeta.time_spent.display == logLookforType) {
  //   console.log('returnValue: ', returnValue);
  // }

  return returnValue
};

interface GetContractCarriedForwardRowForMonthProps {
  tableDataAsDictionary: Dictionary<any>,
  tasksCountMeta: TasksCountType,
  months: string[]
}

const getContractCarriedForwardRowForMonth = ({
  tableDataAsDictionary,
  tasksCountMeta,
  months
}: GetContractCarriedForwardRowForMonthProps) => {
  const tasksCarriedForwardRow = tableDataAsDictionary[tasksCountMeta.tasks_carried_forward_manual.key];
  const notCompletedInAMonthRow = tableDataAsDictionary[tasksCountMeta.not_completed_in_a_month.key];
  const completedInAMonthRow = tableDataAsDictionary[tasksCountMeta.tasks_completed.key];
  const newTasksCarriedForwardRow = { ...tasksCarriedForwardRow };
  months.map((month, index) => {
    const savedValue = tasksCarriedForwardRow[month].value;
    let calcValue;
    let previousMonth;
    let previousCompletedTasks = null;
    let previousUnCompletedTasks = null;
    if (index > 0) {
      previousMonth = months[index - 1];
      previousCompletedTasks = completedInAMonthRow[previousMonth].value;
      previousUnCompletedTasks = notCompletedInAMonthRow[previousMonth].value;
    }
    if (!savedValue && savedValue !== 0) {
      calcValue = previousCompletedTasks === null ? 0 : previousUnCompletedTasks;
      newTasksCarriedForwardRow[month] = { ...newTasksCarriedForwardRow[month], calcValue, displayValue: calcValue }
    } else {
      newTasksCarriedForwardRow[month] = { ...newTasksCarriedForwardRow[month], calcValue, displayValue: savedValue }
    }
  })
  return newTasksCarriedForwardRow;
}

interface GetTotalPorttfolioCarriedForwardRowForMonthProps extends GetContractCarriedForwardRowForMonthProps {
  result?: PortfolioInteractiveTableChart
}

const getTotalPorttfolioCarriedForwardRowForMonth = ({
  tableDataAsDictionary,
  tasksCountMeta,
  months,
  result
}: GetTotalPorttfolioCarriedForwardRowForMonthProps) => {
  const totalsTasksCarriedForwardRow = tableDataAsDictionary[tasksCountMeta.tasks_carried_forward_manual.key];
  const newTasksCarriedForwardRow = { ...totalsTasksCarriedForwardRow };
  const manualCarriedForwardRows = result?.table.filter(x => x.associated_with !== "total" && x.key === tasksCountMeta.tasks_carried_forward_manual.key);
  // console.log('manualCarriedForwardRows: ', manualCarriedForwardRows);
  manualCarriedForwardRows && manualCarriedForwardRows.map((row: any) => {
    months.map((month: string) => {
      const totalsRowMonth = newTasksCarriedForwardRow[month];
      if (!totalsRowMonth?.displayValue) {
        totalsRowMonth.displayValue = 0;
      }
      const thisMonth = row[month];
      const calcValue = thisMonth.calcValue || 0;
      const value = thisMonth.value || 0;
      // we use display rather than calcValue or value because we want something we can simply
      // sum across all contracts in the portfolio which will always hold the most relevant value
      // whereas 'value' really respresents the savedValue (i.e. manual) and calcValue a calculation value
      if (!isNaN(parseInt(calcValue))) {
        newTasksCarriedForwardRow[month].displayValue += value || calcValue;
      } else {
        console.log('NaN value for month ', month, 'name: ', row.name, ' calcValue: ', calcValue);
      }
    })
  })
  return newTasksCarriedForwardRow;
}

export const calculateTasksCarriedForwardNullValues = (
  tableDataAsDictionary: Dictionary<any>,
  primaryField: string,
  tasksCountMeta: TasksCountType,
  months: string[],
  portfolio?: HydratedPortfolio,
  result?: PortfolioInteractiveTableChart
) => {
  let newTasksCarriedForwardRow;
  if (portfolio && tableDataAsDictionary[tasksCountMeta.tasks_issued.key]?.associated_with == "total") {
    const totalsNotCompletedInAMonthRow = tableDataAsDictionary[tasksCountMeta.not_completed_in_a_month.key];
    const totalsTasksIssuedRow = tableDataAsDictionary[tasksCountMeta.tasks_issued.key];
    const totalsTasksCompletedRow = tableDataAsDictionary[tasksCountMeta.tasks_completed.key];
    const totalsPercentCompletedRow = tableDataAsDictionary[tasksCountMeta.percent_completed.key];
    const newTotalsTasksCarriedForwardRow = getTotalPorttfolioCarriedForwardRowForMonth({ tableDataAsDictionary, tasksCountMeta, months, result });
    // This is a bit hackish, but as the not completed and % completed have already been calculated (in getCalculatedRows) based on the values served from the backend (i.e. for the totals row
    // the sums calculated from the stored values of other rows, not including any auto calculated carried forward figures), we'll just use the figures we've just derived 
    // above across the contract rows to figure out the not completed and % completed here:
    months.map((month: string) => {
      totalsNotCompletedInAMonthRow[month].value = newTotalsTasksCarriedForwardRow[month].displayValue + totalsTasksIssuedRow[month].value - totalsTasksCompletedRow[month].value;
      totalsPercentCompletedRow[month].value = roundNumber((totalsTasksCompletedRow[month].value / ((newTotalsTasksCarriedForwardRow[month].displayValue + + totalsTasksIssuedRow[month].value))) * 100, 2);
    })
    return newTotalsTasksCarriedForwardRow;
  } else {
    newTasksCarriedForwardRow = getContractCarriedForwardRowForMonth({ tableDataAsDictionary, tasksCountMeta, months });
  }
  return newTasksCarriedForwardRow;
}