export const auditColumns = [
  { name: "asset_description", width: 170 },
  { name: "report_inspection_date", width: 185 },
  { name: "examiner_comment_summary", width: 200 },
  { name: "jbs_comments" },
  //{ name: "action_required_by", width: 125 },
  { name: "responsibility", width: 125 },
  { name: "date_completed", width: 125 },
  { name: "next_inspection_date", width: 155 },
]

export const portfolioAuditColumns = [{ name: "contract_ref", width: 100, caption: 'Contract' }, ...auditColumns]
