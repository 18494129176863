import {
  ADD_NOTIFICATION,
  NOTIFICATION_VIEWED,
  CLEAR_NOTIFICATION
} from "../Actions/Notification.actions";
import { GlobalNotification } from "../Interfaces/GlobalNotification";
import { IDHelper } from "../../Common/Utils/Key";
import { Dictionary } from "../../Common/Interfaces/Entity.interface";

const initialState = {
  messages: {},
};

export interface NotificationState {
  messages: Dictionary<GlobalNotification>;
}

const notificationReducer = (state: NotificationState = initialState, action: any) => {
  switch (action.type) {
    case ADD_NOTIFICATION.reducer : {
      const { notification } = action;

      const newId = IDHelper();

      const newState = {
        ...state,
        messages: {
          ...state.messages,
          [newId]: { 
            id: newId,
            message: notification.message,
            type: notification.type,
            viewed: false
          }
        }
      }

      return newState;
    }
    
    case NOTIFICATION_VIEWED.reducer : {
      const newState = {...state};
      const keys = Object.keys(newState.messages).filter(key => !state.messages[key].viewed);
      keys.forEach( key => delete newState.messages[key]);
      return newState;
    }

    case CLEAR_NOTIFICATION.reducer: {
      return {
        ...state,
        messages: {}
      }
    }

    default:
      return state;
  }
};

export default notificationReducer;
