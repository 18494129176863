export const checkSpecificSupportingDocumentColumns = {
    'h_a_s_supporting_document_type': {
        caption: 'Supporting Document Type',
        width: '200px',
    },
    'notes': {
        caption: 'Description',
        align: "left",
    },
    'internal_access_only': {
        width: '90px',
        caption: 'Internal'
    },
    // 'mime_type': {
    //   dataField: 'mime_type',
    //   width: 150,
    //   caption: 'Type'
    // }
};

export const generalSupportingDocumentsListColumns = {
    'check_scheduled_for': {
        caption: 'Check Scheduled For',
        readonly: true,
        width: '200px'
    },
    ...checkSpecificSupportingDocumentColumns,

}