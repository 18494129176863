import React, { useState, useEffect, useMemo, useCallback, useRef, useReducer } from 'react';
import moment from 'moment';

// Own
import { Visit, SupplierService } from 'components/Schedulers/Interfaces/Schedule.interfaces';
import VisitDetailDocuments from "components/Schedulers/VisitModal/Components/VisitDetail/VisitDetailDocuments";
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import MatConfirmationDialog from 'components/Common/Components/Material/MatConfirmationDialog/MatConfirmationDialog';
import { simpleFetchVisit } from "components/Schedulers/Services/visitService";
import visitFormInitialState from 'components/Schedulers/VisitModal/Models/VisitForm';
import { dateUDF } from "components/Common/Utils/Dates.js";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { getDimTitle } from "helpers/Forms/modal.helper";

import "components/Common/Components/GeneralActionForm/GeneralActionFormStyles.scss"

// Styles
import {
    VisitDetailWrapper,
    VisitNoReportWrapper,
    VisitReportsWrapper
} from './VisitDetailStyles';

interface VisitDocumentsContainerProps {
    initialVisit?: Visit;
    portfolio?: HydratedPortfolio;
    defaultNewItemDate?: Date;
    supplierService: SupplierService;
    setModalTitle: React.Dispatch<React.SetStateAction<(() => JSX.Element) | undefined>>;
}

const VisitDocumentsContainer = ({
    portfolio,
    initialVisit,
    defaultNewItemDate,
    supplierService,
    setModalTitle,
}: VisitDocumentsContainerProps) => {

    const [visit, setVisit] = useState<Visit | undefined>(initialVisit);
    const visitFormValues = useRef<any>({});
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 1);

    const visitInitialState = useMemo(() => {
        // console.log('visit: ', visit);
        let thisState;
        if (!visit) {
            thisState = { ...visitFormInitialState, scheduled_for: (moment(defaultNewItemDate).format(dateUDF) || moment().format(dateUDF)), supplier_service: supplierService.id };
        } else {
            thisState = { ...visit, scheduled_for: moment(visit.scheduled_for).format(dateUDF), supplier_service: supplierService.id };
        }
        // console.log('thisState: ', thisState);
        return thisState;
    }, [visit, defaultNewItemDate, supplierService.id])

    const getModalTitle = useCallback(() => {
        return () => <>
            <div className="detail-modal--title">
                Visit
                {getDimTitle('by')}
                {supplierService.contractor.name}
                {getDimTitle('to')}
                {supplierService.equipment_name}
                {getDimTitle('on')}
                {visit?.scheduled_for}
            </div>
        </>
    }, [supplierService, visit])

    useEffect(() => {
        setModalTitle(getModalTitle);
    }, [supplierService, visit, getModalTitle, setModalTitle]);

    useEffect(() => {
        visitFormValues.current = visitInitialState;
        forceUpdate();
    }, [visitInitialState])


    const noReportsComments = `You can't add reports until created`;
    const closeDetailViewMessage = 'Are you sure you would like to close';
    const confirmationInitialState = { open: false, agreeCallback: () => { }, };
    const [confirmationDialog, setConfirmationDialog] = useState(confirmationInitialState);

    const refreshVisit = () => {
        if (visit) {
            visit.id && simpleFetchVisit({
                contractRef: supplierService.contract__contract_ref,
                portfolioId: portfolio?.id,
                visitId: visit.id
            }).then((response) => {
                setVisit(response.data);
            })
        } else {
            console.log('no visit id!')
        }
    }

    const closeConfirmationDialog = (): void => {
        setConfirmationDialog({ ...confirmationDialog, open: false });
    }


    return <>
        {mustRefresh && <>
            <MatConfirmationDialog
                onAgree={confirmationDialog.agreeCallback}
                onDisagree={closeConfirmationDialog}
                open={confirmationDialog.open}
                actions={{ agree: 'Yes', disagree: 'No' }}
            >
                {closeDetailViewMessage}
            </MatConfirmationDialog>
            <VisitDetailWrapper>
                <VisitReportsWrapper>
                    {visit
                        ? <VisitDetailDocuments
                            visit={visit}
                            refreshVisit={refreshVisit}
                            supplierService={supplierService}
                            portfolio={portfolio}
                        />
                        : <VisitNoReportWrapper>
                            <FolderOpenIcon />
                            {noReportsComments}
                        </VisitNoReportWrapper>}
                </VisitReportsWrapper>

            </VisitDetailWrapper>
            {/* <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions> */}
        </>}
    </>
}

export default VisitDocumentsContainer;