import { MeterReading, MeterReadingChartDataMeta } from "../Interfaces/MeterReadings.interface";
import { sentenceCase } from "../../../../helpers/String/String.helper";

const isMonth = (month: string) =>
  ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].indexOf(month) !== -1;

export const transformMeterReadingForChart = (meterReadings: MeterReading[]): MeterReadingChartDataMeta => {
  const transformed = meterReadings.reduce((acc: any, reading) => {
    const year = reading.year;

    const getValues = (meterReading: MeterReading) => {
      return Object.keys(reading).reduce((keysAcc, key: any) => {

        const newKey = sentenceCase(key);

        return {
          ...keysAcc, ...(isMonth(newKey)
            ? {
              [newKey]: {
                ...acc.data[newKey],
                [`year${year}`]: meterReading[key]
              }
            }
            : undefined)
        }
      }, {})
    }

    return { data: getValues(reading), years: [...acc.years, year] };

  }, { data: {}, years: [] });

  return {
    ...transformed, data: Object.keys(transformed.data).map(key => {
      return { ...transformed.data[key], month: key }
    })
  }
}
