import { ContractInterface } from "../Interfaces/Contract.interface";
import {
  FETCH_CONTRACTS,
  SET_CONTRACT,
  DELETE_CONTRACT,
  FETCH_CONTRACT_SUBLIST_META,
  SET_GROUP_CONTRACTS,
} from "../Actions/Contracts.actions";
import { isStateEqual } from "store/reducers/reducer.helper";

import { AdminState } from "../../Interfaces/Admin.state";
import { getLookup } from "./Contracts.reducer.helper";
import { fetchReducer } from "../../Helpers/Entity.helper";
import { FieldMetaGroup, Dictionary } from "components/Common/Interfaces/Entity.interface";
import {
  mergeChildObjectsByKey,
  prepareMainListViewsAfterCreate
} from "components/AdminPanel/Reducers/adminReducer.helper";

const initialState = {
  data: {},
  lookup: {},
  recordmeta: {},
  options: [],
  meta: {
    PUT: undefined,
    POST: undefined
  }
};

export interface ContractsState extends AdminState {
  data: Dictionary<ContractInterface>;
  recordmeta: Dictionary<FieldMetaGroup>;
  [idx: string]: any;
}

const contractsReducer = (state: ContractsState = initialState, action: any) => {
  switch (action.type) {
    case FETCH_CONTRACTS.success: {
      //return fetchReducer(state, action, 'contracts', getLookup);
      return fetchReducer(state, action, 'contracts'); //getLookup doesn't seem needed here - check if it can be dropped generally (including on individual contract get)
    }

    case SET_CONTRACT.success: {
      const { contractPeriods, ...contract } = action.contract;
      const { preparedObj, preparedData } = prepareMainListViewsAfterCreate(state.data, action.contract, action);

      return {
        ...state,
        data: {
          [action.contract.id]: preparedObj,
          ...preparedData
        },
        lookup: {
          ...state.lookup,
          [contract.id]: getLookup(action.contract)
        },
        meta: {
          ...state.meta,
          PUT: action.meta || state.meta.PUT
        },
        options: action.options

      };
    }

    case FETCH_CONTRACT_SUBLIST_META.success:
      return {
        ...state,
        meta: {
          ...state.meta,
          POST: {
            ...state.meta.POST,
            ...action.payload.meta
          },
          PUT: {
            ...state.meta.PUT,
            ...action.payload.meta
          }
        }
      };

    case DELETE_CONTRACT.reducer: {
      delete state.data[action.contractId];
      delete state.lookup[action.contractId];

      if (state.recordmeta) {
        delete state.recordmeta[action.contractId];
      }

      return {
        ...state,
        data: {
          ...state.data,
        }
      };
    }

    case SET_CONTRACT.reducer: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.contract.id]: {
            ...action.contract,
          }
        },
        lookup: { // ! Candidate for remove - check that lookup is calculated in selector
          ...state.lookup,
          [action.contract.id]: getLookup(action.contract)
        },
        meta: {
          ...state.meta,
          PUT: action.meta || state.meta.PUT
        },
        options: action.options
      };
    }

    case SET_GROUP_CONTRACTS.reducer: {
      const newState = {
        ...state,
        data: mergeChildObjectsByKey(state.data, action.contractGroup.data),
        // recordmeta: {
        //   ...state.recordmeta,
        //   //...action.contractGroup.meta,
        // }
      }
      const equal = isStateEqual(state, newState);
      if (!equal) {
        return newState;
      }
      return state;
    }

    case DELETE_CONTRACT.success: {
      delete state.data[action.contractId];

      return {
        ...state,
        data: {
          ...state.data,
        }
      };
    }

    default:
      return state;
  }
};

export default contractsReducer;
