import { setProjectConfig } from "store/Common/Actions/common.actions.js";
import { store } from "store/store";
import API from "services/API/API";

const getSortCallback = getPropertyCallback => {
  return (element1, element2) => {
    const value1 = getPropertyCallback(element1);
    const value2 = getPropertyCallback(element2);
    if (value1 > value2) {
      return 1;
    } else if (value1 < value2) {
      return -1;
    }
    return 0;
  };
};

const debounce = (fn, ms) => {
  let timer;
  return () => {
    // eslint-disable-next-line no-undef
    clearTimeout(timer);
    // eslint-disable-next-line no-undef
    timer = setTimeout(() => {
      timer = null;
      // eslint-disable-next-line no-undef
      fn.apply(this, arguments);
    }, ms);
  };
};

export { getSortCallback, debounce };
