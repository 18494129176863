import { ofType, StateObservable } from 'redux-observable';
import { switchMap, map, catchError } from 'rxjs/operators';
import { toTitleCase } from "helpers/String/String.helper";
import { addNotification } from "components/Notification/Actions/Notification.actions";
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from "components/Notification/Constants/constants";
import { of } from 'rxjs';

import { APIR, APINR } from '../../../services/API/API';
import { State } from "store/interfaces/State";
import { unWrapDataAndMeta, unWrapData } from '../../../services/API/API.helper';

import * as fromProfileActions from '../Actions/Profile.actions';
import { Profile } from 'components/Profile/Interfaces/Profile.interface';

const ROUTE = '/people/me/';

export const fetchProfileEpic = (action$: any) => action$.pipe(
  ofType(fromProfileActions.GET_PROFILE.start),
  switchMap(() =>
    APIR.get(`${ROUTE}?format=json`).pipe(
      unWrapDataAndMeta(),
      map(response => fromProfileActions.getProfileSuccess({ ...response })),
      catchError((error) => of(fromProfileActions.getProfileError(error))),
    )
  )
);

export const updateProfileEpic = (action$: any) => action$.pipe(
  ofType(fromProfileActions.UPDATE_PROFILE.start),
  switchMap(({ profile, ignoreFailure }) => {
    let updateMethod = ignoreFailure ? APINR : APIR;
    return updateMethod.patch(`${ROUTE}?format=json`, profile).pipe(
      unWrapData(),
      //map((profile) => fromProfileActions.updateProfileSuccess((profile))),
      switchMap(profile => [
        fromProfileActions.updateProfileSuccess((profile)),
        addNotification({ message: 'Saved', type: NOTIFICATION_SUCCESS })
      ]),
      catchError((error) => {
        if (ignoreFailure) {
          return of(fromProfileActions.updateProfileSuccess({ data: profile }))
        } else {
          let errorString = '';
          try {
            let err = error.response?.data?.message;
            if (err) {
              Object.keys(err).map((k) => {
                const value = err[k];
                const key = toTitleCase(`${k}`);
                errorString += `${key}: ${value}`;
              })
            }
          } catch (e) {
            console.log('e: ', e);
          }
          return of(addNotification({ message: errorString.length ? errorString : error, type: NOTIFICATION_ERROR }))
          //return of(addNotification({ message: error, type: NOTIFICATION_ERROR }))
        }
      }),
    )
  }
  )
);

const API_FAILURE_IGNORED_FOR = ['collapse_contract_sidebar', 'hide_contract_sidebar', 'collapse_index_sidebar', 'hide_index_sidebar']

export const togglePersonalProfileSettingsEpic = (action$: any, store$: StateObservable<State>) => action$.pipe(
  ofType(fromProfileActions.TOGGLE_PERSONAL_PROFILE_SETTINGS.start),
  switchMap(({ payload }) => {
    const profileUpdateData = {
      [payload.updateKey as keyof Profile]: !store$.value.profile.data[payload.updateKey as keyof Profile]
    }
    return of(fromProfileActions.updateProfile(profileUpdateData, API_FAILURE_IGNORED_FOR.includes(payload.updateKey)))
  }
  )
);

export default [
  fetchProfileEpic,
  updateProfileEpic,
  togglePersonalProfileSettingsEpic
]