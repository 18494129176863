export const meterReadingColumns = [
  { name: "year", width: 150 },
  { name: "january" },
  { name: "february" },
  { name: "march" },
  { name: "april" },
  { name: "may" },
  { name: "june" },
  { name: "july" },
  { name: "august" },
  { name: "september" },
  { name: "october" },
  { name: "november" },
  { name: "december" }
]
