import { ofType, StateObservable } from 'redux-observable';
import { map, switchMap, catchError, mapTo } from 'rxjs/operators';
import { from } from 'rxjs';
import moment from "moment";
import Cookies from "universal-cookie";

// Own
import { longPeriodDate, dateUDF, wordDateFormat, getInWordDateFormat, DISPLACE_ZERO_STARTING_MONTH } from 'components/Common/Utils/Dates';
import { DownloadRequestRemote, getDownloadHeaderConfig, DownloadData, DOWNLOAD_REQUEST_REMOTE } from '../Types/Download.types';
import { downloadRequestRemoteSuccess, downloadStandby } from '../Actions/Download.actions';
import { APIR } from 'services/API/API';
import { addNotification } from 'components/Notification/Actions/Notification.actions';
import { NOTIFICATION_ERROR } from 'components/Notification/Constants/constants';
import { State } from 'store/interfaces/State';
import { getReportTocs } from 'components/ContractInFocus/ContractReport/Helpers/contractReportToc.helper';
import { getFullReportSectionStructure, reportSectionStructure } from 'components/ContractInFocus/Models/contractIndex.model'
import { Dictionary } from "components/Common/Interfaces/Entity.interface";
import { toPythonVarName } from "helpers/String/String.helper";

const REMOTEGRABURL = `${process.env.REACT_APP_PDF_URL}/api/remoteGrab`;

const cookies = new Cookies();

const remoteDownloadRequestEpic = (action$: any, state$: StateObservable<State>) => action$.pipe(
  ofType(DOWNLOAD_REQUEST_REMOTE.start),
  buildCoreReportPayload(state$, "contract"),
  getElementForPrinting,
  //ofType(DOWNLOAD_STANDBY.reducer)
  submitPayloadForRemotePrinting
);

// const remoteDownloadPortfolioRequestEpic = (action$: any, state$: StateObservable<State>) => action$.pipe(
//   ofType(DOWNLOAD_REQUEST_REMOTE_PORTFOLIO.start),
//   buildCoreReportPayload(state$, "portfolio"),
//   getElementForPrinting,
//   submitPayloadForRemotePrinting
// );

type ForType = "portfolio" | "contract";

const buildCoreReportPayload = (state$: StateObservable<State>, forType: ForType) => map(({ payload }: { payload: DownloadRequestRemote }) => {
  // ? Extract elements from the global / redux state which are needed for the report payload
  const portfolio = payload.portfolio; //|| getPortfolioFromState(state$);
  const contract = payload.contract; // || getContractFromState(state$);
  const { sites, profile } = state$.value;
  const momentDate = moment(`${payload.year}-${payload.month + DISPLACE_ZERO_STARTING_MONTH}-01`, dateUDF);
  let portfolioContractKeys: Dictionary<string> | undefined;
  if (portfolio?.contracts) {
    portfolioContractKeys = {};
    portfolio?.contracts.sort((a, b) => a.contract_ref > b.contract_ref ? 1 : -1).map(x => {
      const value = `${x.contract_category} contract at ${x.site_name}, with ${x.contract_with.name}${x.alias ? ', known as ' + '"' + x.alias + '"' : ''}`;
      //@ts-ignore ts cannot figure out that on the next line portfolioContractKeys cannot be undefined
      portfolioContractKeys[x.contract_ref] = value;
    });
  }
  const [focusedPeriodStartDate, focusedPeriodEndDate] = [getInWordDateFormat(payload.focusedPeriod.start_date), getInWordDateFormat(payload.focusedPeriod.end_date)];
  const portfolioCoverImage = portfolio?.portfolio_monthly_maintenance_report_cover_image || portfolio?.portfolio_image;
  const contractCoverImage = contract?.monthly_maintenance_report_cover_image
  const fallBackCoverImage = payload.maintenance_report_cover_fallback
  const coverImage = portfolioCoverImage || contractCoverImage || fallBackCoverImage;
  const reportMeta = {
    site: contract ? sites.data[contract.site_id]?.name : '',
    date: momentDate.format("MMMM YYYY"),
    issuedOn: moment().format(wordDateFormat),
    reportCaption: `Month Reported: ${longPeriodDate(momentDate.toDate())}`, // get contract period in
    reportContractPeriod: `${focusedPeriodStartDate} - ${focusedPeriodEndDate}`,
    client: contract?.contract_with.name || "",
    alias: contract?.alias,
    reportTitle: portfolio ? "Portfolio Monthly Review" : "Contract Monthly Review",
    reportCoverImage: coverImage?.full_size,
    author: `${profile.data.first_name} ${profile.data.last_name}`,
    small_jaguar_doc_logo_with_text: payload.jaguar_doc_logo_with_text?.small,
    appendices: payload.appendices,
    contractCategory: contract?.contract_category,
    distribution: payload.distribution,
    contractId: contract?.id,
    portfolioId: portfolio?.id,
    portfolioName: portfolio?.name,
    account_manager: contract?.account_manager,
    site_manager: contract?.site_manager,
    contractPeriodCaption: `${focusedPeriodStartDate} - ${focusedPeriodEndDate}`,
    monthReported: longPeriodDate(momentDate.toDate()),
    portfolioContractKeys: portfolioContractKeys
  };

  // console.log('process env: ', process.env);
  if (portfolio) {
    payload.fullVisibilityData = {
      ...payload.fullVisibilityData,
      show_meter_reading_events_table: false
    }
  }

  // This should really be put into a selector and that selector passed into this function
  if (payload.appendices?.length) {
    payload.appendices.map((appendix: any) => {
      //the title will have been injected as the visibility value in the report section structure...
      payload.fullVisibilityData[toPythonVarName(appendix.title)] = true; //if the appendix is included at all, currently it follows that it is visible
    })
  }

  const tocsStructures = getReportTocs(getFullReportSectionStructure(payload.appendices), payload.fullVisibilityData);

  const downloadPayload: any = {
    token: cookies.get('token'),
    remoteTargetUrl: payload.remoteTargetUrl,//reportRoute,
    elementToBePrinted: `#${payload.elementToBePrinted}`,
    reportMeta,
    page: document.querySelector("html"),
    tocs: tocsStructures,
    snapshot: payload.snapshot,
  }
  return downloadPayload;
});

const getElementForPrinting = map((payload: any, ...state: any) => {
  return {
    ...payload,
    targetElementToBePrinted: payload.elementToBePrinted,
    elementToBePrinted: payload.elementToBePrinted.toLowerCase() !== "html"
      ? payload.page.querySelector(payload.elementToBePrinted)
      : payload.page
  }
});

const submitPayloadForRemotePrinting = switchMap((payload: DownloadData) => {
  const submissionPayload = {
    token: payload.token,
    localstorage: payload.localstorage,
    calculatedStyles: payload.calculatedStyles,
    elementToBePrinted: payload.targetElementToBePrinted,
    reportMeta: payload.reportMeta,
    remoteTargetUrl: payload.remoteTargetUrl,
    tocs: payload.tocs,
    snapshot: payload.snapshot
  };
  return APIR.post(
    REMOTEGRABURL,
    submissionPayload,
    getDownloadHeaderConfig()
  ).pipe(
    map((response) => downloadRequestRemoteSuccess({ htmlSource: payload.constructedHtmlSource, base64: response.data, snapshot: payload.snapshot })),
    catchError((error) => {
      let msg = `${error}`;
      if (error.response?.data) {
        msg = msg + ' Detail: ' + JSON.stringify(error.response.data);
      }
      return from([
        addNotification({ message: msg, type: NOTIFICATION_ERROR }),
        downloadStandby()
      ])
    }
    )
  )
})

export default [
  remoteDownloadRequestEpic,
  //remoteDownloadPortfolioRequestEpic,
];