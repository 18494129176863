import { filter } from 'rxjs/operators';
import API, { APIR, getMultipartConfig } from "../../../../services/API/API";
import { logError } from "../../../../services/Log/Log.service";
import { unWrapDataAndMeta } from "../../../../services/API/API.helper";
import { transformTaskCountData, transformForChartType, transformTaskCountDataForPortfolioOrContract } from "../Helper/TablePolyfill.helpers";
import { BasicTaskCountTableMeta, SLATaskCountTableMeta } from "components/ContractInFocus/Maintenance/Models/PPMReactive.model";
import { Dictionary, FieldMetaGroup } from "../../../Common/Interfaces/Entity.interface";
import { map, catchError } from "rxjs/operators";
import { of, Observable } from "rxjs";
import { ReportConfig } from "../Interfaces/interactiveTable.interfaces";
import { InteractiveTransformedResponse } from '../Interfaces/interactiveService.interfaces';
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { Snapshot } from 'components/ContractInFocus/Interfaces/ContractInFocus.interfaces';
import { addErrorNotice } from "components/Notification/helper/notification.helper";
import { includeCSRFHeader } from "components/ContractInFocus/Interfaces/ContractInFocus.interfaces";
import { downloadFromMemory } from "store/Common/Helpers/commonHelper.js";
import queryString from "query-string";

import Cookies from "universal-cookie";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { ContractOrPortfolio } from "services/API/common/contractAPIs";
const cookies = new Cookies();


//const getContractUrl = (endpoint: string, contract: ContractInterface) =>
const getContractUrl = (endpoint: string, contract: SiteContract) =>
  `contracts/${contract.contract_ref}/${endpoint}/`;

const getPortfolioUrl = (endpoint: string, portfolio: HydratedPortfolio) =>
  `portfolios/${portfolio.id}/${endpoint}/`;


interface getTaskMaintenanceDataProps {
  category: ReportConfig,
  contract?: SiteContract,
  portfolio?: HydratedPortfolio,
  months: string[],
  transformForChart: transformForChartType,
  focusSnapshot?: Snapshot,
  params?: Object
}


const getBasicMaintenanceData = ({
  category,
  contract,
  portfolio,
  months,
  transformForChart,
  focusSnapshot,
  params,
}: getTaskMaintenanceDataProps
): Observable<InteractiveTransformedResponse> => {
  let url;
  if (contract) {
    url = `${getContractUrl(category.endpoint, contract)}?format=json`;
  } else {
    url = `${getPortfolioUrl(category.endpoint, portfolio as HydratedPortfolio)}?format=json`;
  }
  const primaryField = category.dataKey === "RM" ? "Total RMs" : "Total PPMs";
  return APIR.get(url, { params }).pipe(
    unWrapDataAndMeta(),
    filter(({ data }) => {
      return !!data
    }),
    map(({ data, meta, permissions }) => {
      // NB: right now the rows to sum and calculate % for are the same for all maintenance tables, so declaring here.  If they vary, declare 
      // seperately for each table (e.g. total, statutory, fabric, below)
      let calculateSumForRowsConfig = category.calculateSumForRowsConfig;
      if (!calculateSumForRowsConfig) {
        calculateSumForRowsConfig = ["tasks_issued", "tasks_completed", "time_spent"];
      }
      let rowSumPercentagesConfig = category.rowSumPercentagesConfig;
      if (!rowSumPercentagesConfig) {
        rowSumPercentagesConfig = {
          "percent_completed": {
            "nominator": "tasks_completed",
            "denominator": "tasks_issued"
          }
        }
      }

      return {
        data: {
          total: !data.total ? null : transformTaskCountDataForPortfolioOrContract(
            {
              data: data.total,
              months: months,
              primaryField: primaryField,
              transformForChart: transformForChart,
              calculateSumForRowsConfig: calculateSumForRowsConfig,
              percentagesConfig: rowSumPercentagesConfig,
              tasksCountMeta: BasicTaskCountTableMeta,
              portfolio,
            }
          ),
          statutory: !data.statutory ? null : transformTaskCountDataForPortfolioOrContract(
            {
              data: data.statutory,
              months: months,
              primaryField: "Total Statutory",
              transformForChart: transformForChart,
              focusSnapshot: focusSnapshot,
              calculateSumForRowsConfig,
              percentagesConfig: rowSumPercentagesConfig,
              tasksCountMeta: BasicTaskCountTableMeta,
              portfolio,
            }

          ),
          fabric: data.fabric ? transformTaskCountDataForPortfolioOrContract(
            {
              data: data.fabric,
              months: months,
              primaryField: "Fabric PPMs",
              transformForChart: transformForChart,
              calculateSumForRowsConfig,
              percentagesConfig: rowSumPercentagesConfig,
              tasksCountMeta: BasicTaskCountTableMeta,
              portfolio,
            }
          ) : null,
        },
        metadata: meta,
        privileges: permissions
      }
    }),
    catchError((error) => {
      console.log('error: ', error);
      console.log(error.stack);
      addErrorNotice(error);
      return of(error);
    })
  )
};

const getSLAMaintenanceData = (
  {
    category,
    contract,
    portfolio,
    months,
    transformForChart,
    focusSnapshot,
    params,
  }: getTaskMaintenanceDataProps): Observable<InteractiveTransformedResponse> => {
  let url;
  if (contract) {
    url = `${getContractUrl(category.endpoint, contract)}?format=json`;
  } else {
    url = `${getPortfolioUrl(category.endpoint, portfolio as HydratedPortfolio)}?format=json`;
  }
  const primaryField = category.dataKey === "RM" ? "Total SLA RMs" : "Total SLA PPMs";
  return APIR.get(url, { params }).pipe(
    unWrapDataAndMeta(),
    filter(({ data }) => !!data),
    map(({ data, meta, permissions }) => {
      let calculateSumForRowsConfig = category.calculateSumForRowsConfig;
      let rowSumPercentagesConfig = category.rowSumPercentagesConfig;
      const transformedData = transformTaskCountDataForPortfolioOrContract(
        {
          data: data.total,
          months: months,
          primaryField: primaryField,
          transformForChart: transformForChart,
          calculateSumForRowsConfig: calculateSumForRowsConfig,
          percentagesConfig: rowSumPercentagesConfig,
          tasksCountMeta: SLATaskCountTableMeta,
          carryForward: false,
          portfolio
        }
      )
      return {
        data: {
          total: transformedData,
        },
        metadata: meta,
        privileges: permissions
      }
    }),
    catchError((error) => {
      console.log('error: ', error);
      addErrorNotice(error);
      return of(error);
    })
  )
};

interface UpdateRecordProps extends ContractOrPortfolio {
  category: ReportConfig;
  id: number;
  payload: any;
}

const updateRecord = (
  props: UpdateRecordProps
) => {
  const csrfToken = cookies.get('csrftoken');
  const headers = includeCSRFHeader(csrfToken);
  props.payload['csrfmiddlewaretoken'] = csrfToken;
  let url = 'update-maintenance-record/never/';
  if (props.contract) {
    url = `${getContractUrl(props.category.endpoint, props.contract)}${props.id}/`;
  }
  if (props.portfolio) {
    url = `${getPortfolioUrl(props.category.endpoint, props.portfolio)}${props.id}/`;
  }
  return APIR.patch(url, props.payload, headers).pipe(
    catchError((err) => logError(`GET_${url}_error:${JSON.stringify(err)}`))
  );
};

interface NewRecordProps extends ContractOrPortfolio {
  category: ReportConfig;
  column: string,
  subdivision: string,
  payload: Dictionary<number>
}

const newRecord = (
  props: NewRecordProps
) => {
  const date = props.column.split(" ");
  const newRecord = {
    year: +date[1],
    month: date[0],
    tasks_carried_forward_manual: null,
    tasks_completed: null,
    tasks_issued: 0,
    time_spent: 0,
    contract: props.contract?.id,
    contract_id: props.payload.contract_id,
    portfolio: props.portfolio?.id,
    category: props.category.dataKey,
    subdivision: props.subdivision,
    ...props.payload
  };
  let url = 'update-maintenance-record/never/';
  if (props.contract) {
    url = `${getContractUrl(props.category.endpoint, props.contract)}`;
  }
  if (props.portfolio) {
    url = `${getPortfolioUrl(props.category.endpoint, props.portfolio)}`;
  }
  return APIR.post(url, newRecord).pipe(
    catchError((err) => logError(`GET_${url}_error:${JSON.stringify(err)}`))
  );
};

const get_export_data_querystring = () => queryString.stringify({ "file_format": 0 });

interface exportTaskProps extends ContractOrPortfolio {
  headers: any;
}

export const exportOpenPPMs = (props: exportTaskProps) => {
  let route = `admin/core/openppmtask/export/`;
  let titleString = '';
  if (props.portfolio) {
    route = `${route}?portfolio=${props.portfolio.id}`;
    titleString = `Open_PPMs_for_portfolio_${props.portfolio.name}.csv`;
  } else if (props.contract) {
    route = `${route}?contract_ref=${props.contract.contract_ref}`;
    titleString = `Open_PPMs_for_contractRef_${props.contract.contract_ref}.csv`
  }
  return API.post(route, get_export_data_querystring(), props.headers).then((response: any) => {
    downloadFromMemory(response.data, 'text/csv', titleString);
  });
}

export const exportOpenReactives = (props: exportTaskProps) => {
  let route = `admin/core/openreactivetask/export/`;
  let titleString = '';
  if (props.portfolio) {
    route = `${route}?portfolio=${props.portfolio.id}`;
    titleString = `Open_Reactives_for_portfolio_${props.portfolio.name}.csv`;
  } else if (props.contract) {
    route = `${route}?contract_ref=${props.contract.contract_ref}`;
    titleString = `Open_Reactives_for_contractRef_${props.contract.contract_ref}.csv`
  }
  return API.post(route, get_export_data_querystring(), props.headers).then((response: any) => {
    downloadFromMemory(response.data, 'text/csv', titleString);
  });
}

export const exportInsuranceAudits = (props: exportTaskProps) => {
  let route = `admin/core/insuranceaudit/export/`;
  let titleString = '';
  if (props.portfolio) {
    route = `${route}?portfolio=${props.portfolio.id}`;
    titleString = `Insurance_Audits_for_portfolio_${props.portfolio.name}.csv`;
  } else if (props.contract) {
    route = `${route}?contract_ref=${props.contract.contract_ref}`;
    titleString = `Insurance_Audits_for_contractRef_${props.contract.contract_ref}.csv`
  }
  return API.post(route, get_export_data_querystring(), props.headers).then((response: any) => {
    downloadFromMemory(response.data, 'text/csv', titleString);
  });
}

export default {
  getBasicMaintenanceData,
  getSLAMaintenanceData,
  updateRecord,
  newRecord,
  exportOpenPPMs,
  exportInsuranceAudits
};
