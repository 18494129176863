import { createRequestActionTypes } from "../../Common/Utils/ReduxUtil";
import { GlobalNotification } from "../Interfaces/GlobalNotification";

export const ADD_NOTIFICATION = createRequestActionTypes('NOTIFICATIONS_API_ADD_NOTIFICATION');
export const addNotification = (notification: GlobalNotification) => 
({ type: ADD_NOTIFICATION.reducer, notification });

export const NOTIFICATION_VIEWED = createRequestActionTypes('NOTIFICATION_VIEWED');
export const notificationViewed = (notificationType: string) => 
({ type: NOTIFICATION_VIEWED.reducer, notificationType });

export const CLEAR_NOTIFICATION = createRequestActionTypes('NOTIFICATIONS_API_CLEAR_NOTIFICATION');
export const clearNotification = () => 
({ type: CLEAR_NOTIFICATION.reducer });