import React, { useState, useRef, useEffect } from 'react';

import ProfileForm from '../Components/ProfileForm/ProfileForm';
import ProfileAvatar from '../Components/ProfileAvatar/ProfileAvatar';

import { StyledProfileWrapper } from './ProfileStyles';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { profileSelector } from 'components/Profile/Selectors/Profile.selector';
import { cancelProfile, updateProfile } from '../Actions/Profile.actions';
import { ProfileState } from '../Interfaces/ProfileState.interface';
import { FieldChangeType } from '../../AdminPanel/Interfaces/Detail';
// import { setUserAvatar } from '../../../store/Common/Actions/common.actions';
import { Profile as IProfile } from '../Interfaces/Profile.interface';
import { removeReadOnlyFields } from "services/API/API.helper";

import * as fromProfileActions from "../Actions/Profile.actions";

import './Profile.scss';
import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";

const getPassword = (password: string | boolean | undefined, showPassword: boolean): Object => {
  return (showPassword && typeof password === 'string' && password !== '')
    ? { password }
    : {};
}

export const handleUpdateProfile = (profile: IProfile, dispatch: any, meta: FieldMetaGroup, showPassword: boolean): void => {
  //const { id, email, password, new_password, general_admin_level, h_a_s_admin_level, ptw_admin_level, global_contract_level, job_title, organisation, organisation_name, avatar, created_at, ...updateableProfileFields } = { ...profile };
  const new_password = getPassword(profile.new_password, showPassword);
  const fields = removeReadOnlyFields(profile, meta);
  const { password, avatar, created_at, ...updateableProfileFields } = fields;
  //const { avatar, created_at, ...updateableProfileFields } = fields;
  const newProfile = { ...updateableProfileFields, ...new_password }
  dispatch(updateProfile(newProfile));
}

export const Profile = () => {

  const profileState: ProfileState = useSelector(profileSelector);
  const [showPassword, setShowPassword] = useState(false);
  const [valid, setValid] = useState(true);
  const newPassword = useRef<string>();
  const confirmPassword = useRef<string>();

  const dispatch = useDispatch();

  const modalClassName = 'profile-modal';
  const avatarFieldname = 'avatar';

  const handleCancel = (): void => {
    dispatch(cancelProfile());
  }

  function isNumeric(string_num: string) {
    let value1 = string_num.toString();
    let value2 = parseFloat(string_num).toString();
    return (value1 === value2);
  }

  const validateForm = (profile: IProfile): void => {
    setValid(true);
    const newPasswordLength = (newPassword.current || '').length;
    if (newPassword.current && newPasswordLength > 0) {
      newPassword.current = newPassword.current.trim()
      newPassword.current !== confirmPassword.current && setValid(false);
      newPasswordLength < 8 && setValid(false);
      isNumeric(newPassword.current) && setValid(false);
    }
  }

  const handleChange = ({ fieldName, value }: FieldChangeType): void => {
    const data = { ...profileState.data, [fieldName]: value };
    dispatch(fromProfileActions.setProfileEntity(data));
    validateForm(data);
  };

  const onPasswordEdit = ({ fieldName, value }: FieldChangeType): void => {
    newPassword.current = (value);
    const data = { ...profileState.data, [fieldName]: value };
    validateForm(data);
  };

  const onConfirmPasswordEdit = ({ fieldName, value }: FieldChangeType): void => {
    confirmPassword.current = value;
    const data = { ...profileState.data, [fieldName]: value };
    validateForm(data);
  };

  const setPasswordBlank = (): void => {
    handleChange({ fieldName: 'password', value: '' });
  }

  const handleShowPassword = (showPassword: boolean): void => {
    setShowPassword(!showPassword);
    if (showPassword) {
      setPasswordBlank();
      setValid(true);
    }
  }

  return !profileState.data
    ? null
    : (
      <Modal
        zIndex={5000}
        isOpen={profileState.showProfile}
        className={modalClassName}
        size="xl"
        centered={true}
      >
        <ModalBody>
          <StyledProfileWrapper>
            <ProfileAvatar
              onAvatarUpdate={(newAvatar) => handleChange({ fieldName: avatarFieldname, value: newAvatar })}
              data={profileState.data}
              showPassword={showPassword}
              onShowPassword={() => handleShowPassword(showPassword)}
            />
            <ProfileForm
              showPassword={showPassword}
              data={profileState.data}
              meta={profileState.meta}
              onChange={handleChange}
              onPasswordChange={onPasswordEdit}
              onConfirmPasswordChange={onConfirmPasswordEdit}
              passwordMatch={() => newPassword.current === confirmPassword.current}
            />
          </StyledProfileWrapper>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              const dataToSubmit = newPassword.current && newPassword.current.length ? { ...profileState.data, new_password: newPassword.current } : profileState.data;
              return handleUpdateProfile(dataToSubmit, dispatch, profileState.meta, showPassword)
            }
            }
            disabled={!valid}
          >
            Save
          </Button>{' '}
          <Button color="info" onClick={handleCancel}>Cancel</Button>
        </ModalFooter>
      </Modal>
    );
}