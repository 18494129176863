import React, { MouseEvent } from 'react';
import { CircularProgress } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { useFabStyles } from './MatFabButtonStyles';

interface MatFabButtonProps {
  disabled?: boolean;
  loading?: boolean;
  visible?: boolean;
  onClick?: (e: MouseEvent) => void;
  children: any;
  className?: string;
  styles?: any;
}

export const MatFabButton = ({
  disabled,
  visible,
  onClick,
  children,
  styles,
  className,
  loading
}: MatFabButtonProps) => {
  const classes = useFabStyles();

  const handleOnClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onClick && onClick(e);
  }

  return (
    <div className="no-print fab-button-wrapper">
      {visible ?
        <div data-testid="material-fab-button" className="no-print">

          <Fab
            style={styles}
            disabled={disabled || loading}
            className={`${classes.reportButton} ${className ?? ''}`}
            color="primary"
            onClick={handleOnClick}
            type="button"
          >
            {loading
              ? <CircularProgress size={22} className="fadeIn" />
              : <>{children}</>
            }
          </Fab>
        </div>
        : null}
    </div>
  )
}