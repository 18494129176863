import React, { useState, useEffect, useReducer, useCallback } from 'react';

// Own
import { generalSupportingDocumentsListColumns } from 'components/Schedulers/HASDetailModal/Models/HASCheckSupportingDocumentColumns';
import DocumentsGrid from "components/Common/Components/DocumentsGrid/DocumentsGrid";
import { PreFlightListInfo } from "components/Common/Interfaces/Entity.interface";
import { getHASContractTaskSupportingDocumentsRoute } from "components/AdminPanel/HAS/HASTaskServices";
import { ContractDocumentFilesWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { GeneralDocsGridWrapper } from 'components/SiteDocuments/Components/ContractDocumentsViewGrid/ContractDocumentGridStyles';
import { getDataAndMetaAndPUTMeta } from "services/API/API.helper";
import { HASTask, HASTaskCheck } from "components/AdminPanel/HAS/Interfaces/HASTaskInterfaces";
import { getDimTitle } from "helpers/Forms/modal.helper";
import { Period } from "components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface";


interface DocumentProps {
    contractTaskId: string;
    portfolioId?: string | number;
    setModalTitle: React.Dispatch<React.SetStateAction<(() => JSX.Element) | undefined>>;
    thisHASTask: HASTask | undefined;
    taskIsGlobal: boolean;
    dispatchRefreshContext?: React.DispatchWithoutAction | undefined;
    period?: Period;
}

const Documents = ({ contractTaskId, portfolioId, setModalTitle, thisHASTask, taskIsGlobal, dispatchRefreshContext, period }: DocumentProps) => {

    const basePath = getHASContractTaskSupportingDocumentsRoute({
        portfolioId: portfolioId,
        contractTaskId: contractTaskId
    })
    const [fetchFilters, setFetchFilters] = useState<any>({})

    useEffect(() => {
        if (period) {
            if (fetchFilters.start_date !== period.start_date || period.end_date !== fetchFilters.end_date) {
                let theseFetchFilters = fetchFilters || {}
                theseFetchFilters = { ...theseFetchFilters, start_date: period.start_date, end_date: period.end_date };
                setFetchFilters(theseFetchFilters);
            }
        }
    }, [period, fetchFilters])

    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 0);
    const [preFlightInfo, setPreFlightInfo] = useState<PreFlightListInfo>();
    const [documentsResponse, setDocumentsResponse] = useState<any>();

    const getModalTitle = useCallback(() => {
        return () => <>
            {thisHASTask ? <div className="detail-modal--title">
                Documents
                {getDimTitle('for Task: ')}
                {`${thisHASTask.name}${taskIsGlobal ? " (Global Task)" : ""}`}
            </div> :
                <div className="detail-modal--title">
                    New Health and Safety Task
                </div>
            }
        </>
    }, [taskIsGlobal, thisHASTask])

    useEffect(() => {
        setModalTitle(getModalTitle)
    }, [thisHASTask, getModalTitle, setModalTitle])

    useEffect(() => {
        if (documentsResponse) {
            // here we'll re-use the response loaded by the documents grid.  This reduces the requests executed but also means
            // we get the documents meta for the current check - currently this will affect if a statutory document
            // can be uploaded or not.
            const info = getDataAndMetaAndPUTMeta(documentsResponse);
            setPreFlightInfo({ meta: info.meta, canCreate: !!info.permissions?.POST, canRead: true })
        }

    }, [documentsResponse]);

    return <GeneralDocsGridWrapper>
        {generalSupportingDocumentsListColumns && <ContractDocumentFilesWrapper height={!preFlightInfo?.canCreate ? 70 : 60}>
            <DocumentsGrid
                listBasePath={basePath}
                hideSource
                columnsConfig={generalSupportingDocumentsListColumns}
                fetchFilters={fetchFilters}
                refreshSignal={mustRefresh}
                actionColumnsWidth={100}
                setResponse={setDocumentsResponse}
                surrogate_portfolio_id={portfolioId}
            />
        </ContractDocumentFilesWrapper>}
    </GeneralDocsGridWrapper>
}

export default Documents;