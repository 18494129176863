import React, { useMemo, useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Own
import { Organisation } from "components/AdminPanel/Organisation/Interfaces/Organisation";
import {
  organisationByIdSelector,
  membersByOrganisationIdSelector,
} from "components/AdminPanel/Organisation/Selectors/Organisations.selectors";
import { Person } from "components/AdminPanel/People/Interfaces/People.interface";
import organisationServices from "components/AdminPanel/Organisation/Services/organisationServices";
import peopleServices from "components/AdminPanel/People/Services/peopleServices";
import { AdminImageForm } from "components/AdminPanel/Containers/AdminImageForm";
import { getPanelSelectorFactory } from "components/AdminPanel/Selectors/Selectors";
import { setDetailPage } from "components/AdminPanel/Actions/adminPanel.actions";
import { clearPsuedoOrganisation } from "components/AdminPanel/Organisation/Actions/Organisation.actions";
import { ImageConfig } from "components/AdminPanel/Containers/AdminImageForm/AdminImageForm";
import {
  organisationImage,
  getOrganisationFormItems,
} from "../../Models/OrganisationDetail.model";
import { TabbedPanel } from "components/Common/Components/TabPanel";
import { PeopleSublistView } from "components/AdminPanel/People/Containers/PeopleSublistView";
import { peopleDatagridColumns } from "components/AdminPanel/People/Models/PeopleDetail.model";
import { apiSuccess } from "components/AdminPanel/Helpers/AdminPanel.helper";
import { FORM_ROW_ID } from "components/AdminPanel/Models/AdminPanel.model";

//TabbedPanel.whyDidYouRender = true;

interface OrganisationDetailViewProps {
  panelId: string;
  data: { data: Partial<Organisation>; component: any };
}

const OrganisationDetailView: React.FC<OrganisationDetailViewProps> = ({
  panelId,
  data: { data },
}) => {
  const membersDataTestId = "organisation-details-view";
  const organisationTabs = ["Detail", "Members"];
  const organisationId = data.id;

  const createLinkFields = useMemo(() => ({ organisation: organisationId }), [
    organisationId,
  ]);

  const selectAdminPanelState = useSelector(getPanelSelectorFactory(panelId));
  const selectOrganisationState = useSelector(
    organisationByIdSelector(data.id)
  );
  const selectMembers = useSelector(membersByOrganisationIdSelector(data.id));

  const dispatch = useDispatch();

  const [imagesConfig, setImagesConfig] = useState<ImageConfig[]>([]);

  useEffect(() => {
    const onLogoUpload = (file: any, progress: any) => {
      organisationServices
        .updateOrganisationLogo(organisationId, file)
        .subscribe(() => { });
    };

    const onLogoClear = () => {
      organisationServices
        .clearOrganisationLogo(organisationId)
        .subscribe(() => { });
    };

    setImagesConfig(
      [
        {
          imageCaption: organisationImage.caption,
          imageKey: organisationImage.key,
          onUpload: onLogoUpload,
          onClear: onLogoClear
        },
      ]
    )
  }, [organisationId])

  const handleCancel = (organisation: Organisation): void => {
    const IS_GRID_FORM = organisation.id === FORM_ROW_ID;
    IS_GRID_FORM && dispatch(clearPsuedoOrganisation());
  }

  const handleUpdate = (organisation: Organisation, formData: Organisation): void => {
    const IS_GRID_FORM = organisation.id === FORM_ROW_ID;

    IS_GRID_FORM
      ? organisationServices.createOrganisation({ ...formData, logo: undefined }, panelId)
      : organisationServices.updateOrganisation(
        organisationId,
        formData,
        selectOrganisationState.meta
      );
  };

  const getDetailTab = () => {
    return (
      <AdminImageForm
        imagesConfig={imagesConfig}
        data={selectOrganisationState.data}
        meta={selectOrganisationState.meta}
        formMeta={getOrganisationFormItems(
          selectOrganisationState.meta,
          selectOrganisationState.data
        )}
        onUpdate={handleUpdate}
        onCancel={handleCancel}
      />
    );
  };

  const handleCreateMember = (data: any): void => {
    //HT Note - to discuss reason for ts-ignore
    //@ts-ignore
    return peopleServices
      .createPerson({
        ...data,
        ...createLinkFields,
      })
      .then(apiSuccess); // HT Note: what is the purpose of this returning false?
  };

  const handleMemberDrop = useCallback(
    (person: Person) => {
      peopleServices
        .linkPersonToOrganisation(person, organisationId)
        .subscribe();
    },
    [organisationId]
  );

  const handleChangeTab = (index: number) => {
    dispatch(setDetailPage(panelId, data.id, index));
  };

  return (
    <>
      {selectOrganisationState && selectAdminPanelState && (
        <TabbedPanel
          showTabs={data.id !== FORM_ROW_ID}
          pageIndex={selectAdminPanelState[data.id]?.detailIndex || 0}
          onChangeTab={handleChangeTab}
          tabs={organisationTabs}
        >
          <div>{selectOrganisationState.meta && getDetailTab()}</div>
          <div>
            <PeopleSublistView
              dataTestId={membersDataTestId}
              panelId={panelId}
              masterRecordData={data}
              peopleState={selectMembers}
              getDatagridColumns={peopleDatagridColumns}
              createPerson={handleCreateMember}
              onDrop={handleMemberDrop}
            />
          </div>
        </TabbedPanel>
      )}
    </>
  );
};

export default OrganisationDetailView;
