import { Dictionary, EntityState } from "../../Common/Interfaces/Entity.interface";
import { APIPrivileges } from "services/Interface/Interface";

export const includeCSRFHeader = (token: string, headers?: any) => ({
  withCredentials: true,
  headers: {
    ...headers,
    'X-CSRFToken': token,
  }
});


export class NotificationContractSettingsC {
  notification_email_on_aw_po_document_add_or_remove = false; //tested
  notification_email_on_other_document_add_or_remove = false;//tested
  notification_email_on_aw_quote_accept_or_reject = false; //tested
  notification_email_on_aw_quote_expired = false;
  notification_email_on_aw_comment = false; //tested
  notification_email_on_aw_fp_quote_status = false; //tested
  notification_email_on_aw_fp_cancelled_status = false; //tested
  notification_email_on_aw_fp_accounts_status = false; //tested
  notification_email_on_aw_fp_invoiced_status = false; //tested
  notification_email_on_aw_fp_complete_status = false; //tested
  notification_email_on_snapshot_publication = false;
  constructor(
    readonly id?: string,
  ) { }
}

export interface NotificationContractSettings extends NotificationContractSettingsC { }
export interface NotificationPortfolioSettings extends NotificationContractSettingsC { }

type NotificationContractSettingPropsArrayT = Array<keyof NotificationContractSettings>;

type NotificationPortfolioSettingPropsArrayT = Array<keyof NotificationPortfolioSettings>;

export const NotificationContractSettingPropsArray: NotificationContractSettingPropsArrayT =
  Object.keys(new NotificationContractSettingsC()) as NotificationContractSettingPropsArrayT;

export const NotificationPortfolioSettingPropsArray: NotificationPortfolioSettingPropsArrayT =
  Object.keys(new NotificationContractSettingsC()) as NotificationPortfolioSettingPropsArrayT;

export class MainInFocusVisibilitySettingsC {
  show_audit_table = true;
  show_ppm_total_table = true;
  show_ppm_total_graph = true;
  show_ppm_sla_table = true;
  show_ppm_sla_graph = true;
  show_rm_sla_table = true;
  show_rm_sla_graph = true;
  show_ppm_statutory_table = true;
  show_ppm_statutory_graph = true;
  show_ppm_fabric_table = true;
  show_ppm_fabric_graph = true;
  show_ppm_open_table = true;
  show_rm_total_table = true;
  show_rm_total_graph = true;
  show_rm_total_issues_table = true;
  show_rm_total_issue_hours_table = true;
  show_rm_total_issues_graph = true;
  show_rm_total_issue_hours_graph = true;
  show_rm_open_table = true;
  show_matters_for_discussion_table = true;
  show_technical_event_table = true;
  show_accident_event_log_table = true;
  show_near_miss_event_log_table = true;
  show_subcontractor_event_table = true;
  show_plant_condition_table = true;
  show_personnel_matter_table = true;
  show_leave_table = true;
  show_meter_reading_events_table = true;
  show_innovation_table = true;
  show_new_works_financials_table = true;
  show_part_comp_repair_financials_table = true;
  show_specialist_table = true;
  show_reactive_works_financials_table = true;
  show_unfiltered_financials_table = true;
  show_comprehensive_po_actual_spend_table = true
  show_consumable_po_actual_spend_table = true;
  show_consumable_monthly_combined_aggregate_table = true;
  show_consumable_monthly_actual_aggregate_table = true;
  show_consumable_po_committed_spend_table = true;
  show_consumable_individual_spend_table = true;
  show_managed_po_actual_spend_table = true;
  show_managed_monthly_combined_aggregate_table = true;
  show_managed_monthly_actual_aggregate_table = true;
  show_managed_po_committed_spend_table = true;
  show_managed_individual_spend_table = true;
  show_contract_spend_settings = true;
  show_comprehensive_monthly_actual_aggregate_table = true;
  show_comprehensive_monthly_combined_aggregate_table = true;
  show_comprehensive_po_committed_spend_table = true;
  show_comprehensive_individual_spend_table = true;
  show_spend_markup = true;
  specialist_planner_mode = false;
  specialist_report_by_exception = false;
  show_h_a_s_scheduler = true;
  show_h_a_s_events_table = true;
  show_h_a_s_accidents_table = true;
  show_h_a_s_near_misses_table = true;
  show_work_permits_table = true;
  constructor(
    readonly id?: string | number,
    readonly contract?: number,
    readonly portfolio?: number,
  ) { }
}

// export interface VisibilityContractSettings extends VisibilityContractSettingsC {
//   [idx: string]: number | boolean | undefined | string;
// }

export interface MainInFocusVisibilitySettings extends MainInFocusVisibilitySettingsC { }

type VisibilityContractSettingPropsArrayT = Array<keyof MainInFocusVisibilitySettings>;

export const MainInFocusVisibilitySettingPropsArray: VisibilityContractSettingPropsArrayT =
  Object.keys(new MainInFocusVisibilitySettingsC()) as VisibilityContractSettingPropsArrayT;

// export interface VisibilityContractSettings {
//   id?: any;
//   show_ppm_total_table: boolean;
//   show_ppm_total_graph: boolean;
//   show_ppm_sla_table: boolean;
//   show_ppm_sla_graph: boolean;
//   show_ppm_statutory_table: boolean;
//   show_ppm_statutory_graph: boolean;
//   show_ppm_fabric_table: boolean;
//   show_ppm_fabric_graph: boolean;
//   show_ppm_open_table: boolean;
//   show_rm_total_table: boolean;
//   show_rm_total_graph: boolean;
//   show_rm_total_issues_table: boolean;
//   show_rm_total_issue_hours_table: boolean;
//   show_rm_total_issues_graph: boolean;
//   show_rm_total_issue_hours_graph: boolean;
//   show_rm_open_table: boolean;
//   show_matters_for_discussion_table: boolean;
//   show_technical_event_table: boolean;
//   show_accident_event_log_table = true;
//   show_near_miss_event_log_table = true;
//   show_subcontractor_event_table: boolean;
//   show_plant_condition_table: boolean;
//   show_personnel_matter_table: boolean;
//   show_meter_reading_events_table: boolean;
//   show_innovation_table: true,
//   show_audit_table: boolean;
//   show_new_works_financials_table: boolean;
//   show_part_comp_repair_financials_table: boolean;
//   show_specialist_table: boolean;
//   show_reactive_works_financials_table: boolean;
//   show_unfiltered_financials_table: boolean;
//   show_comprehensive_po_actual_spend_table: boolean;
//   show_spend_markup: boolean;
//   show_consumable_po_actual_spend_table: boolean;
//   show_managed_po_actual_spend_table: boolean;
//   show_contract_spend_settings: boolean;
//   show_comprehensive_monthly_actual_aggregate_table: boolean;
//   show_consumable_monthly_actual_aggregate_table: boolean;
//   show_managed_monthly_actual_aggregate_table: boolean;
//   show_meter_streams?: boolean;
//   [idx: string]: number | boolean | undefined;
// }

export type VisibilityControlsTransformed = Dictionary<Dictionary<boolean>>;

export type ReportSection = {
  name: string;
  heading?: string;
  visible: string[];
  subTitles: ReportSectionSubTitles[];
}

export type ReportSectionSubTitles = {
  name: string;
  visible: string[];
}

export type Snapshot = {
  id: any;
  frozen_for: string;
  report_last_produced_at: string | null;
  last_frozen_at: string | null;
  contract: any;
  contract_period: any;
  publications: SnapshotPublication[];
}

export type SnapshotPublication = {
  id: string,
  url?: string,
  published_by_string: string,
  created_at?: string,
  updated_at?: string,
  publication_number: number,
  publication_notes: string | null,
  snapshot: string,
  published_by: number
}

export interface FrozenHistoryBranch extends EntityState<Dictionary<Snapshot>> {
  focus?: any;
  permissions: APIPrivileges;
}

export interface PersonalHiddenObjectI {
  id: string;
  url?: string;
  content_type_name: string;
  object_id: string;
  hidden_for: number;
  content_type: number;
}

export interface SubmitPersonalHiddenObjectI {
  // no need for hidden_for just now.. it is always taken as the logged in user
  // content_type_name or content_type should be submitted
  content_type_name?: string;
  object_id: string;
  content_type?: number;
  category: string;
}
