import { FrozenHistoryBranch } from "../interfaces/contractInFocus.interfaces";
import { IDState, IDsState } from "../../Common/Interfaces/Entity.interface";

import { initialEntityState, initialIDsState, initialIDState, initialEntityListState } from "../../Common/Models/Entity.models";
import { CONTRACT_GET_MONTHLY_MAINTENANCE_REPORTS } from "components/ContractInFocus/Actions/contractDocuments.actions";
import { apiPrivilegesInitalState } from "services/models/apiprivileges";
import { MonthlyMaintenanceReports } from "interfaces/contract.interfaces";
import { ReactiveCategoryOptionsState } from 'components/ContractInFocus/Interfaces/ContractInFocusState.interface';
import * as contractActions from "components/ContractInFocus/Actions/contractInFocus.actions";

export type ContractInFocusState = {
  site: IDState;
  focusedContractPeriod: { id: any } | undefined;
  contract: IDState;
  //clientAccess: IDsState;
  distribution: IDsState;
  frozenHistory: FrozenHistoryBranch;
  monthlyMaintenanceReports: MonthlyMaintenanceReports;
  reactiveCategoryOptions: ReactiveCategoryOptionsState;
  // contractDocumentsViewerOpen: boolean;
};

const initialState = {
  site: initialIDState,
  focusedContractPeriod: undefined,
  reactiveCategoryOptions: initialEntityState,
  contract: initialEntityState,
  //clientAccess: initialIDsState,
  distribution: initialIDsState,
  monthlyMaintenanceReports: initialEntityListState,
  frozenHistory: {
    ...initialEntityState,
    data: {},
    focus: undefined,
    permissions: apiPrivilegesInitalState
  },
  // contractDocumentsViewerOpen: false
};

const contractInFocusReducer = (state: ContractInFocusState = initialState, { type, payload }: { type: string, payload: any }) => {
  switch (type) {

    // case contractActions.SET_SELECTED_CONTRACT.success: {
    //   console.log('contract in focus success payload: ', payload);
    //   const getFocusedSnapshot = () => {
    //     // This doesn't exists
    //     // console.log('payload snapshot: ', payload.snapshot);
    //     // if (payload.snapshot) {
    //     //   return payload.snapshot.id;
    //     // }

    //     if (payload.contract.id === state.contract.id) {
    //       return state.frozenHistory.focus;
    //     }

    //     return undefined;
    //   }
    //   return {
    //     ...state,
    //     ...payload,
    //     //focusedContractPeriod: payload.focusedContractPeriod,
    //     frozenHistory: {
    //       ...payload.frozenHistory,
    //       focus: getFocusedSnapshot()
    //     },
    //   };
    // }

    case contractActions.FETCH_CONTRACT_SNAPSHOTS.success: {
      return {
        ...state,
        frozenHistory: {
          ...payload.snapshotHistory,
          focus: payload.focusedSnapshot ?? state.frozenHistory.focus
        },
      };
    }

    case contractActions.INSERT_CONTRACT_SNAPSHOT.reducer: {
      return {
        ...state,
        frozenHistory: {
          ...state.frozenHistory,
          data: {
            ...state.frozenHistory.data,
            [payload.snapshot.id]: payload.snapshot
          }
        },
      };
    }

    case contractActions.CLEAR_CONTRACT_IN_FOCUS.reducer: {
      // return {
      //   ...state,
      //   frozenHistory: {
      //     ...state.frozenHistory,
      //     focus: undefined
      //   },
      // }
      // HT Note: once you've normalized the contract info and made only parts of the screen reload as state changes, 
      // try clearing the contract in Focus properly as follows and do it when leaving a contract, not entering one (see Routes.js)
      return initialState
    }

    case contractActions.SWITCH_CONTRACT_IN_FOCUS.reducer: {
      return {
        ...initialState,
        contract: {
          id: payload.contractId
        },
      }
      //return initialState
    }

    case contractActions.CONTRACT_IN_FOCUS_SET_SELECTED_SNAPSHOT.reducer: {
      return {
        ...state,
        frozenHistory: {
          ...state.frozenHistory,
          focus: payload.selectedSnapshot?.id
        },
      };
    }

    case contractActions.CONTRACT_IN_FOCUS_FETCH_CONTRACT_PERIODS.success: {
      return {
        ...state,
        contractPeriods: payload.contractPeriod,
      };
    }

    case contractActions.RETRIEVE_CONTRACT_IN_FOCUS_FROM_CACHE.reducer: {
      return {
        ...initialState,
        ...payload.contractInFocus
      }
    }


    case contractActions.SET_SELECTED_CONTRACT_PERIOD.start:
      return {
        ...state,
        focusedContractPeriod: payload
      };

    case CONTRACT_GET_MONTHLY_MAINTENANCE_REPORTS.success:
      return {
        ...state,
        monthlyMaintenanceReports: { ...payload.monthlyMaintenanceReports }
      }

    default:
      return state;
  }
};

export default contractInFocusReducer;
