import { ofType, StateObservable } from 'redux-observable';
import { switchMap, map, catchError } from 'rxjs/operators';
import { toTitleCase } from "helpers/String/String.helper";
import { addNotification } from "components/Notification/Actions/Notification.actions";
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from "components/Notification/Constants/constants";
import { of } from 'rxjs';

import { APIR, APINR } from '../../../services/API/API';
import { State } from "store/interfaces/State";
import { unWrapDataAndMeta, unWrapData } from '../../../services/API/API.helper';

import * as fromPersonalSettingsActions from '../Actions/PersonalSettings.actions';
import { PersonalSettings } from 'components/Profile/Interfaces/Profile.interface';

const ROUTE = '/my/personal-settings/';

export const fetchPersonalSettingsEpic = (action$: any) => action$.pipe(
    ofType(fromPersonalSettingsActions.GET_PERSONAL_SETTINGS.start),
    switchMap(() => {
        return APIR.get(`${ROUTE}?format=json`).pipe(
            unWrapDataAndMeta(),
            map(response => fromPersonalSettingsActions.getPersonalSettingsSuccess({ ...response })),
            catchError((error) => of(fromPersonalSettingsActions.getPersonalSettingsError(error))),
        )
    }
    )
);

export const updatePersonalSettingsEpic = (action$: any) => action$.pipe(
    ofType(fromPersonalSettingsActions.UPDATE_PERSONAL_SETTINGS.start),
    switchMap(({ personalSettings, ignoreFailure }) => {
        let updateMethod = ignoreFailure ? APINR : APIR;
        return updateMethod.patch(`${ROUTE}?format=json`, personalSettings).pipe(
            unWrapData(),
            //map((personalSettings) => fromPersonalSettingsActions.updatePersonalSettingsSuccess((personalSettings))),
            switchMap(personalSettings => [
                fromPersonalSettingsActions.updatePersonalSettingsSuccess((personalSettings)),
            ]),
            catchError((error) => {
                if (ignoreFailure) {
                    return of(fromPersonalSettingsActions.updatePersonalSettingsSuccess({ data: personalSettings }))
                } else {
                    let errorString = '';
                    try {
                        let err = error.response?.data?.message;
                        if (err) {
                            Object.keys(err).map((k) => {
                                const value = err[k];
                                const key = toTitleCase(`${k}`);
                                errorString += `${key}: ${value}`;
                            })
                        }
                    } catch (e) {
                        console.log('e: ', e);
                    }
                    return of(addNotification({ message: errorString.length ? errorString : error, type: NOTIFICATION_ERROR }))
                }
            }),
        )
    }
    )
);

const API_FAILURE_IGNORED_FOR = ['collapse_contract_sidebar', 'hide_contract_sidebar', 'collapse_index_sidebar', 'hide_index_sidebar']

export const togglePersonalSettingsEpic = (action$: any, store$: StateObservable<State>) => action$.pipe(
    ofType(fromPersonalSettingsActions.TOGGLE_PERSONAL_SETTINGS.start),
    switchMap(({ payload }) => {
        const personalSettingsUpdateData = {
            [payload.updateKey as keyof PersonalSettings]: !store$.value.personalSettings.data[payload.updateKey as keyof PersonalSettings]
        }
        return of(fromPersonalSettingsActions.updatePersonalSettings(personalSettingsUpdateData, API_FAILURE_IGNORED_FOR.includes(payload.updateKey)))
    }
    )
);

export default [
    fetchPersonalSettingsEpic,
    updatePersonalSettingsEpic,
    togglePersonalSettingsEpic
]