import React, { useRef, useEffect, useState } from "react";
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { APIPrivileges } from "services/Interface/Interface";
import { isEqual } from "lodash";

// Own
import { AdminDataGrid } from "components/AdminPanel/Containers/AdminDataGrid";
import { adminPanelType } from "components/AdminPanel/Models/AdminPanel.model";
import { generalPortfolioSublistColumns, linkPortfolioGridActions } from "components/AdminPanel/Portfolios/Models/Portfolios.model";
import { apiSuccess, openPanelInSelectionMode } from 'components/AdminPanel/Helpers/AdminPanel.helper';
import { AccessiblePortfoliosStateForSpecificPerson, AccessiblePortfolioI } from "components/Portfolios/Interfaces/Portfolios.interface";
import { Person } from "components/AdminPanel/People/Interfaces/People.interface";
import portfolioAdminServices from "components/AdminPanel/Portfolios/Services/portfolioAdminServices";
import { removeDisabledFieldsBasedOnRecordMeta } from "helpers/Forms/form.helper";

import { EntityKeys } from "constants/entity.constants";
import peopleServices from "components/AdminPanel/People/Services/peopleServices";
import { FieldMetaGroup, PreFlightListInfo } from "components/Common/Interfaces/Entity.interface";

interface AccessiblePortfoliosViewProps {
    panelId: string;
    masterRecordData: Person;
    id: any;
    accessiblePortfoliosState: AccessiblePortfoliosStateForSpecificPerson;
    dataTestId: string;
    handleDropCB?: any;
    masterRecordType?: string;
    selectActionIdentifier?: string;
    onAddLinks?: Function;
    onUnlink?: (item: any) => void;
    linkPanelTitle?: string;
}

const AccessiblePortfoliosView: React.FunctionComponent<AccessiblePortfoliosViewProps> = ({
    panelId,
    masterRecordData,
    id,
    accessiblePortfoliosState,
    dataTestId,
    handleDropCB,
    masterRecordType,
    selectActionIdentifier,
    onAddLinks,
    onUnlink,
    linkPanelTitle
}) => {

    // const cloneMembersFromState = (): AccessiblePortfolioI[] => {
    //     return (accessiblePortfoliosState.data || []).slice();
    // };

    const accessList = accessiblePortfoliosState.data;
    const peopleAccessOptionsRef = useRef<any>([]);
    const currentMemberIds = accessList.map(m => m.portfolio)
    const [privileges, setPrivileges] = useState<APIPrivileges>()
    const [preFlightInfo, setPreFlightInfo] = useState<PreFlightListInfo>();
    const [meta, setMetaData] = useState<FieldMetaGroup>()
    const [datasource, setDatasource] = useState<any>();

    useEffect(() => {
        portfolioAdminServices.portfolioAccessLinksPreFlightInfo(masterRecordData.id).then((newPreFlightInfo) => {
            if (!isEqual(preFlightInfo, newPreFlightInfo)) {
                setPreFlightInfo(newPreFlightInfo);
            }
            if (!isEqual(preFlightInfo?.meta, newPreFlightInfo.meta)) {
                setMetaData(newPreFlightInfo?.meta);
            }
        });
        peopleServices.personSimpleFetchPortfolioAccess(masterRecordData.id).then( //this could be used to retrieve data but here is just used to get privileges as data comes from redux state
            (response) => {
                setPrivileges(response.permissions);
                //setMetaData(response.putMeta);
                return response.data;
            })
    }, []);

    useEffect(() => {
        const custom = new CustomStore({
            key: "id",
            // load: loadOptions => peopleServices.personSimpleFetchPortfolioAccess(masterRecordData.id).then(
            //     (response) => {
            //         setPrivileges(response.permissions);
            //         //setMetaData(response.putMeta);
            //         return response.data;
            //     }
            // ), // Consider if there's any point using this and getting rid of redux state for other people's portfolio access - if so bear in mind
            // you need to refresh this component after unlinking a portfolio and after closing modal that links portfolios
            load: () => accessList,
            update: (id, values) => peopleServices.updateAccessiblePortfolios(masterRecordData, id, values, meta)
        });

        setDatasource(
            // @ts-ignore
            new DataSource({
                store: custom
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessList]);

    const handlePortfolioLink = (): void => {
        //openPanelWithDragMessage(adminPanelType.people);
        masterRecordType && selectActionIdentifier && linkPanelTitle && openPanelInSelectionMode(
            {
                selectActionIdentifier,
                masterRecordType,
                masterRecordData,
                linkedRecordType: adminPanelType.portfolios,
                onAddLinks,
                currentMemberIds,
                linkPanelTitle,
            }
        )
    };

    const linkPortfolio = selectActionIdentifier ? handlePortfolioLink : undefined;

    return (
        <>
            {meta && <AdminDataGrid
                data={datasource}
                editMode="cell"
                meta={meta}
                permissions={privileges}
                dataTestId={dataTestId}
                getDatagridColumns={generalPortfolioSublistColumns}
                passedEditorPreparing={(e: any) => {
                    removeDisabledFieldsBasedOnRecordMeta(e);
                }}
                masterView={false}
                entityKey={EntityKeys.Portfolios}
                columnActions={linkPortfolioGridActions(onUnlink)}
                panelId={panelId}
                dragAndDrop={false}
                //onUpdate={handleAccessUpdate}
                onLink={linkPortfolio}
            />}
        </>
    );
};

export default AccessiblePortfoliosView;
