import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
//https://github.com/pqina/react-filepond
//https://itnext.io/uploading-files-with-react-and-filepond-f8a798308557
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateType
);

function getProcessUpload(onUpload) {
  return function processUpload(
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort,
    transfer,
    options
  ) {
    try {
      onUpload(file, progress);
      load(file);
    } catch (err) {
      console.log("ERR", err);
      error();
    }

    return {
      abort: () => {
        // This function is entered if the user has tapped the cancel button
        //request.abort();
        // Let FilePond know the request has been cancelled
        abort();
      }
    };
  };
}

const FileUploader = ({
  onUpload,
  labelIdle,
  allowMultiple,
  ready,
  acceptedFileTypes = undefined,
  refreshSignal = false
}) => {
  const server = {
    fetch: null,
    revert: null,
    process: getProcessUpload(onUpload)
  };

  useEffect(() => {
    setTimeout(() => {
      setFiles([]);
    }, 2000);
  }, [refreshSignal]);

  const [files, setFiles] = useState([]);

  return (
    <FilePond
      files={files}
      maxFileSize="30MB"
      allowRevert={false}
      disabled={ready == false}
      onupdatefiles={setFiles}
      // onComplete={() => {
      //   if (filePond.getFiles().length != 0) {
      //     for (var i = 0; i <= filePond.getFiles().length - 1; i++) {
      //       filePond.removeFile(filePond.getFiles()[0].id)
      //     }
      //   }
      // }}
      acceptedFileTypes={acceptedFileTypes}
      server={server}
      allowMultiple={allowMultiple}
      labelIdle={`${labelIdle ||
        'Drag & Drop your files or <span class="filepond--label-action">Browse</span>'} (Max size 30MB)`}
    />
  );
};

FileUploader.propTypes = {
  onUpload: PropTypes.func.isRequired,
  labelIdle: PropTypes.string,
  allowMultiple: PropTypes.bool
};

export const CoverImageUploader = ({ onUpload, labelIdle }) => {
  const acceptedFileTypes = [
    "image/jpeg",
    "image/gif",
    "image/png",
    "image/svg+xml"
  ];
  const server = {
    fetch: null,
    revert: null,
    process: getProcessUpload(onUpload)
  };

  const maxFileSize = "10MB";
  const imageWidth = 1920;
  const imageHeight = 880; //1080;

  const getCoverImageUploaderText = `<div class="filepond-label">Drag and drop your image here or click</div> 
   <div class="filepond--label-action"> upload</div>
   <div class="filepond-label">Max File Size: ${maxFileSize} </div>
   <div class="filepond-label">Image resolution: ${imageWidth} x ${imageHeight} </div>`;

  return (
    <FilePond
      //maxFileSize="10MB"
      allowRevert={false}
      acceptedFileTypes={acceptedFileTypes}
      imageValidateSizeMinWidth={imageWidth}
      imageValidateSizeMaxWidth={imageWidth}
      imageValidateSizeMinHeight={imageHeight}
      imageValidateSizeMaxHeight={imageHeight}
      server={server}
      allowMultiple={false}
      labelIdle={getCoverImageUploaderText}
    />
  );
};

export const SingleImageUploader = ({ onUpload, labelIdle }) => {
  const acceptedFileTypes = [
    "image/jpeg",
    "image/gif",
    "image/png",
    "image/svg+xml"
  ];
  const server = {
    fetch: null,
    revert: null,
    process: getProcessUpload(onUpload)
  };

  const maxFileSize = "10MB";

  const getSingleImageUploaderText = `<div class="filepond-label">Drag and drop your image here or click</div> 
   <div class="filepond--label-action"> upload</div>
   <div class="filepond-label">(Max File Size ${maxFileSize}) </div>`;

  return (
    <FilePond
      maxFileSize={maxFileSize}
      allowRevert={false}
      acceptedFileTypes={acceptedFileTypes}
      server={server}
      allowMultiple={false}
      labelIdle={getSingleImageUploaderText}
    />
  );
};

export default FileUploader;

export const getSingleImageUploader = (onUpload, labelIdle) => (
  <SingleImageUploader
    onUpload={onUpload}
    labelIdle={labelIdle}
  ></SingleImageUploader>
);
export const getCoverImageUploader = (onUpload, labelIdle) => (
  <CoverImageUploader
    onUpload={onUpload}
    labelIdle={labelIdle}
  ></CoverImageUploader>
);
