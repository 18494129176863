import adminPanelDataReducer from "./adminPanelData.reducer";
import adminPanelOpenReducer from "./adminPanelOpen.reducer";
import adminPanelOpsReducer from "./adminPanelOps.reducer";
import { reduceReducers } from "../../Common/Utils/ReduxUtil";

export default reduceReducers(
  adminPanelDataReducer,
  adminPanelOpenReducer,
  adminPanelOpsReducer
);
