import React, { memo, useCallback, useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Own
import CommonSideBarMenu from "components/Layout/Common/SideBar/CommonSideBar.js";
import { UseDrawer } from "components/Layout/Contract/Containers/ContractSidebar/DrawerHOC";
import useIndexMenuItems from "components/IndexPage/IndexMenu";
import { healthAndSafetyAdminSelector, healthAndSafetyAdminAccessSelector } from 'components/Profile/Selectors/Profile.selector';
import {
  hasNoSitesSelector
} from "components/Sites/Selectors/Sites.selectors";
import {
  hasNoPortfoliosSelector
} from "components/Portfolios/Selectors/Portfolio.selectors";


import {
  indexSideBarCollapsedSelector,
  indexSideBarHiddenSelector
} from "components/Profile/Selectors/Profile.selector";

import { updatePersonalSettingsReducer } from "components/Profile/Actions/PersonalSettings.actions";
import { updatePersonalSettingsService } from "components/Profile/Services/profile.services";

//ContractSidebar.whyDidYouRender = true;

const WrappedIndexSideBar = UseDrawer(CommonSideBarMenu, {
  drawerWidth: 220,
  drawerWidthClosed: 36,
  zIndex: 116,
  downShift: 64,
  variant: "permanent"
});

const WrappedSideBar = memo(withRouter(WrappedIndexSideBar)) as any;

const toggleHideIndexSideBar = (dispatch: React.Dispatch<any>, sideBarHidden: boolean) => {
  const payload = {
    hide_index_sidebar: !sideBarHidden
  };
  // here we want to update the local profile state immediately whether the BE succeeds or not
  dispatch(updatePersonalSettingsReducer(payload));
  updatePersonalSettingsService(payload);
  // setTimeout(() => {
  //   dispatch(changeSetting("layoutUpdateBroadcast", Date.now()));
  // }, 250);
};

const toggleCollapseIndexSidebar = (dispatch: React.Dispatch<any>, sideBarCollapsed: boolean) => {
  console.log('hey, here is current value: ', sideBarCollapsed);
  const payload = {
    collapse_index_sidebar: !sideBarCollapsed
  };
  // here we want to update the local profile state immediately whether the BE succeeds or not
  dispatch(updatePersonalSettingsReducer(payload));
  updatePersonalSettingsService(payload);
  // setTimeout(() => { // this just causes a flicker now...
  //   dispatch(changeSetting("layoutUpdateBroadcast", Date.now()));
  // }, 250);
};

const IndexSideBarContainer = () => {
  const dispatch = useDispatch();
  const HASAdminMemberLevel = useSelector(healthAndSafetyAdminSelector);
  const HASAdminAccess = useSelector(healthAndSafetyAdminAccessSelector);
  const sideBarCollapsed = useSelector(indexSideBarCollapsedSelector);
  const sideBarHidden = useSelector(indexSideBarHiddenSelector);
  const IndexMenuItems = useRef(useIndexMenuItems(!!HASAdminAccess));
  const [theseMenuItems, setTheseMenuItems] = useState(IndexMenuItems.current);
  const hasNoSites = useSelector(hasNoSitesSelector);
  const hasNoPortfolios = useSelector(hasNoPortfoliosSelector);
  useEffect(() => {
    if (hasNoSites) {
      setTheseMenuItems(IndexMenuItems.current.filter(x => x.path !== "/sites"))
    } else if (hasNoPortfolios) {
      setTheseMenuItems(IndexMenuItems.current.filter(x => x.path !== "/portfolios"))
    }
  }, [hasNoSites, hasNoPortfolios])
  const updateHideSideBar = useCallback(
    () => toggleHideIndexSideBar(dispatch, sideBarHidden),
    [dispatch, sideBarHidden]
  );
  const updateCollapseSideBar = useCallback(
    () => toggleCollapseIndexSidebar(dispatch, sideBarCollapsed),
    [dispatch, sideBarCollapsed]
  );
  return <>
    {theseMenuItems.length > 1 && <WrappedSideBar
      collapsed={sideBarCollapsed}
      closed={sideBarHidden}
      toggleCollapseSidebar={updateCollapseSideBar}
      //toggleHideSideBar={updateHideSideBar} // for now we don't pass this as we haven't yet decided where to stick hamburger
      useMenuItems={theseMenuItems}
    />}
  </>
};

export default memo(IndexSideBarContainer);



