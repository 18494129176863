import { MainInFocusVisibilitySettings } from 'components/ContractInFocus/interfaces/contractInFocus.interfaces';

export const initialStateVisibilityContractSettings: MainInFocusVisibilitySettings = {
  show_ppm_statutory_graph: true,
  show_ppm_statutory_table: true,
  show_ppm_total_graph: true,
  show_ppm_total_table: true,
  show_ppm_sla_graph: true,
  show_ppm_sla_table: true,
  show_rm_sla_graph: true,
  show_rm_sla_table: true,
  show_ppm_fabric_graph: true,
  show_ppm_fabric_table: true,
  show_ppm_open_table: true,
  show_rm_total_graph: true,
  show_rm_total_issues_graph: true,
  show_rm_total_issues_table: true,
  show_rm_total_issue_hours_graph: true,
  show_rm_total_issue_hours_table: true,
  show_rm_total_table: true,
  show_rm_open_table: true,
  show_matters_for_discussion_table: true,
  show_technical_event_table: true,
  show_accident_event_log_table: true,
  show_near_miss_event_log_table: true,
  show_subcontractor_event_table: true,
  show_plant_condition_table: true,
  show_personnel_matter_table: true,
  show_leave_table: true,
  show_meter_reading_events_table: true,
  show_innovation_table: true,
  show_audit_table: true,
  show_new_works_financials_table: true,
  show_part_comp_repair_financials_table: true,
  show_reactive_works_financials_table: true,
  show_unfiltered_financials_table: true,
  show_specialist_table: true,
  show_comprehensive_monthly_actual_aggregate_table: true,
  show_comprehensive_monthly_combined_aggregate_table: true,
  show_consumable_monthly_combined_aggregate_table: true,
  show_consumable_monthly_actual_aggregate_table: true,
  show_consumable_po_actual_spend_table: true,
  show_consumable_po_committed_spend_table: true,
  show_consumable_individual_spend_table: true,
  show_managed_monthly_combined_aggregate_table: true,
  show_managed_monthly_actual_aggregate_table: true,
  show_managed_po_actual_spend_table: true,
  show_managed_po_committed_spend_table: true,
  show_managed_individual_spend_table: true,
  show_comprehensive_po_actual_spend_table: true,
  show_contract_spend_settings: true,
  show_comprehensive_po_committed_spend_table: true,
  show_comprehensive_individual_spend_table: true,
  specialist_report_by_exception: false,
  show_spend_markup: true,
  specialist_planner_mode: false,
  show_h_a_s_scheduler: true,
  show_h_a_s_events_table: true,
  show_h_a_s_accidents_table: true,
  show_h_a_s_near_misses_table: true,
  show_work_permits_table: true
}