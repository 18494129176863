export default {
  options: {
    screenSmallWidth: 500,
    cellWidth: 120,
    otherElementsWidth: 150
  },
  columns: {
    contractor: {
      label: "Contractor",
      key: "contractor",
      isText: true
    }
  },
  status: {
    completed: "completed",
    scheduled: "scheduled",
    rescheduled: "rescheduled",
    cancelled: "cancelled"
  },
  mainCategory: {
    plannedPreventedMaintainance: "PPM",
    reactiveMaintainance: "RM"
  }
};
