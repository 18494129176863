
export const tempReclickCell = (cell: any, domElementSelector: string, editorElementSelector: string, cellClickedRef: any) => {
  try {
    const { columnIndex, rowIndex } = cell;

    if (cellClickedRef.current.columnIndex !== columnIndex || cellClickedRef.current.rowIndex !== rowIndex) {
      const domElement: HTMLElement = document.querySelector(domElementSelector) as HTMLElement;
      if (domElement) {
        setTimeout(() => {
          domElement.click();
          const editorElement: HTMLElement = document.querySelector(editorElementSelector) as HTMLElement;
          setTimeout(() => {
            editorElement && editorElement.focus();
          }, 100);
        });
      }
    }
    cellClickedRef.current = { columnIndex, rowIndex };
  } catch (e) {
    console.log(`unable to force select ${e}`);
  }
}

export const getMonthAndYearFromColumn = (columnName: string) => {
  const [month, year] = columnName.split(" ");
  return { month, year: +year };
};