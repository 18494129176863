import { switchMap, map, catchError, filter, tap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';

import * as fromPortfoliosActions from 'components/AdminPanel/Portfolios/Actions/AdminPanelPortfolios.actions';
import { APIR } from "../../../services/API/API";
import { unWrapListDataAndMeta } from "../../../services/API/API.helper";

const BASE_ROUTE = '/portfolios/';

export const fetchPortfoliosEpic = (action$: any) => action$.pipe(
    ofType(fromPortfoliosActions.FETCH_PORTFOLIOS.start),
    switchMap(({ payload }) =>
        APIR.get(BASE_ROUTE).pipe(
            unWrapListDataAndMeta(),
            map(portfolios => fromPortfoliosActions.fetchPortfoliosSuccess(portfolios)),
            catchError(error => {
                return of(fromPortfoliosActions.fetchPortfoliosError(error));
            })
        )
    )
);

export default [
    fetchPortfoliosEpic
];
