/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";

// Own
import IncidentDetailModal from "components/ContractInFocus/HAS/IncidentDetailModal";
import HASDetailModal from "components/Schedulers/HASDetailModal/HASDetailModal";
import { fetchHASContractTask } from "components/AdminPanel/HAS/HASTaskServices";
import { HASContractTask } from "components/AdminPanel/HAS/Interfaces/HASTaskInterfaces";
import InViewWrapper from 'components/Common/Components/InViewWrapper/InViewWrapper';
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";

interface InitialHASDetailArgs {
    contractTaskId: string;
    documentId: string;
    checkId: string;
    initialContextObj?: HASContractTask;
}

interface HASLinkModalProps {
    contract?: SiteContract;
    portfolio?: HydratedPortfolio;
}

const HASLinkModal = ({ contract, portfolio }: HASLinkModalProps) => {
    let csvFileNameBase = 'Health And Safety Logs ';
    if (portfolio) {
        csvFileNameBase = `${csvFileNameBase} for portfolio ${portfolio.name}`
    } else if (contract) {
        csvFileNameBase = `${csvFileNameBase} for contract ${contract.contract_ref}`
    }
    const hashFragment = window.location.hash;
    const [openIncidentDetailModal, setOpenIncidentDetailModal] = useState(false);
    const [openHASDetailModal, setOpenHASDetailModal] = useState(false);

    let hasDetailArgs = useRef<InitialHASDetailArgs>();
    let initiallySelectedCheckId = useRef<string>();

    useEffect(() => {
        if (hashFragment) {
            const thisHash = hashFragment.replace('#', '');
            const theseArgs = thisHash.split('___');
            const [recordType, _] = theseArgs;
            if (recordType === "accident" || recordType === "near_miss") {
                setOpenIncidentDetailModal(true);
                setOpenHASDetailModal(false);
            } else if (recordType === "supporting_check_doc") {
                hasDetailArgs.current = {
                    "contractTaskId": theseArgs[1],
                    "checkId": theseArgs[2],
                    "documentId": theseArgs[3],
                }
                initiallySelectedCheckId.current = theseArgs[2];
                fetchHASContractTask({
                    portfolioId: portfolio?.id,
                    contract_ref: contract?.contract_ref,
                    contractTaskId: hasDetailArgs.current.contractTaskId,
                    query_params: {}
                }).then((response) => {
                    if (hasDetailArgs.current) {
                        hasDetailArgs.current.initialContextObj = response.data;
                    }
                    setOpenHASDetailModal(true)
                    setOpenIncidentDetailModal(false);
                })
            } else {
                setOpenIncidentDetailModal(false);
                setOpenHASDetailModal(false);
            }
        }
    }, [hashFragment, contract, portfolio])

    const inViewOptions = useRef({
        /* Optional options */
        threshold: 0,
        rootMargin: '0px 0px 200px',
    });

    return <>

        {openIncidentDetailModal && <InViewWrapper
            i={4}
            inViewOptions={inViewOptions.current}
            placeHolderMinHeight="100vh"
            WrappedComponent={() => <IncidentDetailModal
                // this doesn't need to be passed the record id as it does it's own parsing of the hash portion of the url
                contractRef={contract?.contract_ref}
                portfolioId={portfolio?.id}
                setOpen={setOpenIncidentDetailModal}
                open={openIncidentDetailModal}
            />} />}
        {openHASDetailModal && hasDetailArgs?.current?.initialContextObj && <InViewWrapper
            i={5}
            inViewOptions={inViewOptions.current}
            placeHolderMinHeight="100vh"
            WrappedComponent={() => <HASDetailModal
                contract={contract}
                portfolio={portfolio}
                setOpen={setOpenHASDetailModal}
                open={openHASDetailModal}
                initialIndex={1}
                initialContextObject={hasDetailArgs?.current?.initialContextObj}
                initiallySelectedCheckId={initiallySelectedCheckId}
            />} />}
    </>
}

export default HASLinkModal
