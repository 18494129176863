import { tap } from 'rxjs/operators';

// Own
import API, { APIR, APIN, getMultipartConfig } from "services/API/API";
import { ParseAPIResponse } from "services/Interface/Interface";
import { onResponseError } from "services/API/API.interceptor.js";
import { PORTFOLIO_ROUTE } from "services/API/common/globalAPIs";
import { HydratedPortfolio, Portfolio, PortfolioContractLink, PortfolioAccessLink } from 'components/Portfolios/Interfaces/Portfolios.interface';
import { adminPanelType } from "components/AdminPanel/Models/AdminPanel.model";
import { getDataAndMeta, unWrapDataAndMeta, removeReadOnlyFields, getData, getPreFlightListMeta, preFlightCanCreateCheck, getPrivileges } from "services/API/API.helper";
import { store } from "store/store";
import * as actions from "components/AdminPanel/Portfolios/Actions/AdminPanelPortfolios.actions";
import * as fromRootActions from "store/actions/root.actions";
import { FieldMetaGroup, EntityState, PreFlightListInfo } from 'components/Common/Interfaces/Entity.interface';
import * as portfolioActions from "components/AdminPanel/Portfolios/Actions/AdminPanelPortfolios.actions";
import { PersonWithAccessI } from "components/AdminPanel/Contracts/Interfaces/Contract.interface";

export const updatePortfolioImage = (id: number, image: any, attribute: string) => {
    const getFileObject = () => {
        const fd = new FormData();
        fd.append(attribute, image);
        return fd;
    }

    return APIR.patch<ParseAPIResponse<Portfolio>>(`${PORTFOLIO_ROUTE}${id}/`, image ? getFileObject() : { [attribute]: null }, image ? getMultipartConfig() : {}).pipe(
        unWrapDataAndMeta(),
        tap(({ data }: { data: any }) =>
            store.dispatch(fromRootActions.setBranchField(adminPanelType.portfolios, id, attribute, data[attribute]))
        )
    )
}

function getPortfolioContentsBaseRoute(portfolioId: string | number) { return `${PORTFOLIO_ROUTE}${portfolioId}/portfolio-contents/` }

const fetchPortfolioContractLinks = (portfolioId: string | number) => {
    // we fetch this separately to the nested contracts under the Portfolio state branch for the view where we're managing the portfolio contents
    // because portfolios have other things watching them (including the parent portfolios list) which we don't want to refresh while editing.
    // keeps things focused too.
    return API.get(getPortfolioContentsBaseRoute(portfolioId)).then(
        (response: any) => {
            const data = getData(response);
            const privileges = getPrivileges(response);
            return { data, privileges };
        }
    )
};

const fetchPortfolioAccessLinks = (portfolioId: string | number) => {
    return API.get(getPortfolioAccessBaseRoute(portfolioId)).then(
        (response: any) => {
            const data = getData(response);
            const privileges = getPrivileges(response);
            return { data, privileges };
        }
    )
};

export const portfolioAccessLinksPreFlightInfo = (portfolioId: number): Promise<PreFlightListInfo> => {
    let route = getPortfolioAccessBaseRoute(portfolioId);
    return APIN.options(route).then((response) => {
        const meta = getPreFlightListMeta(response); //preflight uses the 'options' verb
        const canCreate = preFlightCanCreateCheck(response);
        return { meta, canCreate, canRead: true }
    }).catch(error => {
        if (error.response?.status == 403) {
            return { meta: {}, canCreate: false, canRead: false }
        } else {
            return onResponseError(error)
        }
    })
}

const updatePortfolioAccess = (portfolio: HydratedPortfolio, accessId: string, data: Partial<PersonWithAccessI>, meta?: FieldMetaGroup) => {
    const payload = meta ? removeReadOnlyFields(data, meta) : data;
    console.log('meta: ', meta);
    const baseRoute = getPortfolioAccessBaseRoute(portfolio.id);//use the nested route to help ensure its the right one
    const route = `${baseRoute}${accessId}/`
    return API.patch(route, payload)
    // .then((response) => {

    // });
}

export const linkContractsToPortfolio = (portfolio: HydratedPortfolio, accessObjs: PortfolioContractLink[]) => {
    return API.post(getPortfolioContentsBaseRoute(portfolio.id), accessObjs);
}

function getPortfolioAccessBaseRoute(portfolioId: string | number) { return `${PORTFOLIO_ROUTE}${portfolioId}/access/` }

export const grantAccessToPortfolio = (portfolio: HydratedPortfolio, accessObjs: PortfolioAccessLink[]) => {
    return API.post(getPortfolioAccessBaseRoute(portfolio.id), accessObjs);
}

export const removeAccessToPortfolio = (portfolio: HydratedPortfolio, personId: number | string) => {
    const baseRoute = getPortfolioAccessBaseRoute(portfolio.id);
    return API.delete(`${baseRoute}${personId}`);
}

const removeContractFromPortfolio = (portfolio: HydratedPortfolio, contractId: number | string) => {
    const baseRoute = getPortfolioContentsBaseRoute(portfolio.id);
    return API.delete(`${baseRoute}${contractId}`);
}

export const clearPortfolioImage = (id: any, attribute: string) => updatePortfolioImage(id, null, attribute);

export const createPortfolio = (data: Partial<Portfolio>, panelId?: string): Promise<EntityState<Portfolio>> | any => {
    const { id, ...payload } = data
    return API.post(PORTFOLIO_ROUTE, payload)
        .then((response) => {
            const dataAndMeta = getDataAndMeta(response);
            // store.dispatch(
            //     portfolioActions.setPortfolioSuccess(
            //         { ...dataAndMeta.data }, dataAndMeta.meta, dataAndMeta.options, dataAndMeta.permissions, true, panelId, dataAndMeta.data.id
            //     )
            // );
            // TODO investigate why we are passing through all the data above in the case of sites...
            store.dispatch(
                actions.setPortfolioReducer(dataAndMeta.data)
            )
            return () => false;
        })
}

const updatePortfolio = (id: number, data: Partial<Portfolio>, meta: FieldMetaGroup) => {
    const { portfolio_image, portfolio_monthly_maintenance_report_cover_image, ...payload } = removeReadOnlyFields(data, meta);
    return API.patch(`${PORTFOLIO_ROUTE}${id}/`, payload).then((response) => {
        store.dispatch(portfolioActions.setPortfolioReducer({ ...response.data.data }))
        store.dispatch(portfolioActions.fetchPortfolios()) // portfolios for index...
    });
}

// export const fetchPortfolio = (id: string) => {
//     return APIR.get(`${PORTFOLIO_ROUTE}${id}/`).pipe(
//         unWrapDataAndMeta<Portfolio>()
//     ).toPromise().then((response) => {
//         store.dispatch(portfolioActions.setPortfolioSuccess(response.data, response.meta, response.options, response.permissions))
//         store.dispatch(portfolioActions.setPortfolioContractsReducer(response.data.portfolio_contracts.map(contract => contract.id), response.data.id));
//         return response;
//     });
// }

const deletePortfolio = (id: number) => {
    return API.delete(`${PORTFOLIO_ROUTE}${id}/`).then((response) => {
        store.dispatch(portfolioActions.deletePortfolioReducer(id))
    });
}

export default {
    updatePortfolioImage,
    clearPortfolioImage,
    createPortfolio,
    updatePortfolioAccess,
    updatePortfolio,
    deletePortfolio,
    grantAccessToPortfolio,
    removeAccessToPortfolio,
    linkContractsToPortfolio,
    portfolioAccessLinksPreFlightInfo,
    removeContractFromPortfolio,
    fetchPortfolioContractLinks,
    fetchPortfolioAccessLinks
}