import React from 'react';
import { IHASAccident } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";
import { clearInjuryDiagram, updateInjuryDiagram } from "components/AdminPanel/HAS/HASIncidentServices";
import { getFullInvestigationFieldsConfig, getSignOffFieldsConfig, useGetSignOffFormLayout, useGetFullAccidentFormLayout } from "components/ContractInFocus/HAS/HASAccidentDetailModels";
import IncidentFormManagementWrapper, { PreManagementWrapperIncidentFormProps, PrepareBasicHASIncidentFormConfigProps, GetIncidentFormLayoutProps } from "components/ContractInFocus/HAS/IncidentFormManagementWrapper";
import HASIncidentActions from "components/ContractInFocus/HAS/HASIncidentActions";
import AccidentWitnessStatements from "components/ContractInFocus/HAS/HASWitnessStatements";
import { simpleFetchAccidentFieldInfoList } from "components/AdminPanel/HAS/HASIncidentServices";
import { getAccidentRoute } from 'components/AdminPanel/HAS/HASIncidentServices';

const prepareBasicFieldConfigsForInvestigationOrSignOff = ({
    thisRecord,
    setThisRecord,
    dispatchRefreshContext: forceUpdate,
    formValuesRef,
    HASAdminAccess
}
    : PrepareBasicHASIncidentFormConfigProps) => {
    const record = thisRecord as IHASAccident;
    const onInjuryDiagramUpload = (file: any, progress: any) => {
        updateInjuryDiagram(record.id, file, "injury_diagram").then((response) => {
            const injDi = response.data['injury_diagram'];
            formValuesRef.current['injury_diagram'] = injDi;
            setThisRecord({ ...record, 'injury_diagram': injDi })
            forceUpdate();
        })
    };

    // PREP FOR GETTING BASIC FIELD CONFIGS
    const onInjuryDiagramClear = () => {
        clearInjuryDiagram(record.id, "injury_diagram").then((response) => {
            const injDi = response.data['injury_diagram'];
            formValuesRef.current['injury_diagram'] = injDi;
            setThisRecord({ ...record, 'injury_diagram': injDi })
            forceUpdate();
        })
    };

    let baseFieldsConfig = (record.full_investigation_mode ?
        getFullInvestigationFieldsConfig({ HASAdminAccess: HASAdminAccess }) :
        getSignOffFieldsConfig({ HASAdminAccess: HASAdminAccess }));

    if (record.full_investigation_mode) {
        baseFieldsConfig['injury_diagram'] = {
            fileInputControlConfig: {
                onUpload: onInjuryDiagramUpload,
                onClear: onInjuryDiagramClear,
            }
        }
    }
    return baseFieldsConfig;
}

const UseGetFormLayoutForInvestigationOrSignOff = ({
    thisRecord,
    HASAdminAccess,
    simpleLayout,
    printable
}
    : GetIncidentFormLayoutProps) => {
    const record = thisRecord as IHASAccident;
    const RenderAccidentActions = () => {
        return <HASIncidentActions
            incident={record}
            incidentType="accident"
            title="Actions"
            printable={printable}
        />
    };

    const RenderWitnessStatementsForm = () => {
        // console.log('rendering witness statements');
        return <AccidentWitnessStatements
            data={record}
        />
    };

    const fullInvestigationLayout = useGetFullAccidentFormLayout({
        HASAdminAccess: HASAdminAccess,
        RenderAccidentActions: RenderAccidentActions,
        RenderWitnessStatementsForm: RenderWitnessStatementsForm
    });

    const defaultLayout = useGetSignOffFormLayout({
        HASAdminAccess: HASAdminAccess,
        RenderAccidentActions: RenderAccidentActions,
    });

    if (record.full_investigation_mode) {
        if (simpleLayout) { return undefined } else {
            const layout = fullInvestigationLayout
            return layout;

        }
    } else {
        if (simpleLayout) { return undefined } else {
            const layout = defaultLayout
            return layout;
        }
    }
}

function HASInvestigationOrSignOffView({
    ...props
}: PreManagementWrapperIncidentFormProps) {
    const thisData = props.data as IHASAccident;
    const gridClass = `${thisData.full_investigation_mode ? 'fullInvestigation' : 'signOff'} HASIncidentDetailForm fieldGroupsForm darker-disabled`;
    return <IncidentFormManagementWrapper
        {...props}
        prepareBasicFieldConfigs={prepareBasicFieldConfigsForInvestigationOrSignOff}
        getFormLayout={UseGetFormLayoutForInvestigationOrSignOff}
        gridClass={gridClass}
        simpleFetchFieldInfoList={simpleFetchAccidentFieldInfoList}
        recordType="accident"
        getIncidentRoute={getAccidentRoute}
    />
}

export default React.memo(HASInvestigationOrSignOffView);