import React, { useState, useRef, useReducer, useCallback, useEffect } from 'react';

interface FadeInWrapperProps {
    timeToTrigger: number;
    transitionTime: number;

    minHeight: string;
    children: any;
}

const FadeInWrapper = ({ children, timeToTrigger, minHeight, transitionTime }: FadeInWrapperProps) => {
    const [status, setStatus] = useState<string>('out');

    useEffect(() => {
        setTimeout(() => setStatus('in'), timeToTrigger)
    })

    return <div className={`fadeInWrapper`} style={{
        minHeight: minHeight,
        opacity: status === 'out' ? 0 : 100,
        transition: `opacity ${transitionTime}`
    }}>
        {children}
    </div>
}

export default FadeInWrapper;