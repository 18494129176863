import React, { useEffect, useState, useReducer, useCallback, useRef } from "react";
import { useSelector } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

// Own
// import * as organisationSelectors from "../AdminPanel/Organisation/Selectors/Organisations.selectors";
import { useQuery } from "store/Common/Helpers/commonHelper.js";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import HASDetailModal from "components/Schedulers/HASDetailModal/HASDetailModal";
import { ArchiveControls, GlobalIndicator, GetHASSchedulerButtonCellTemplateProps, HeaderHASNotificationControlProps, HASNotificationControls, HeaderHASNotificationControls } from "components/Schedulers/ScheduleDataGrid/Components/Buttons/SchedulerActionButtons";
import Scheduler, { FilterControlProperties } from "components/Schedulers/Scheduler";
import {
    archiveContractFromHASTask,
    unArchiveContractFromHASTask,
    fetchHASContractTasksWithMonthChecks,
    fetchHASContractTasksWithMonthChecksPreFlightInfo,
    simpleCreateHASContractTaskPersonalSettings,
    simpleUpdateHASContractTaskPersonalSettings,
    updateMultipleContractTasksNotificationSettings
} from "components/AdminPanel/HAS/HASTaskServices";
import * as contractInFocusSelectors from "components/ContractInFocus/Selectors/contractInFocus.selectors";
import { generateColumnHeaders } from "components/Schedulers/Helpers/HASTasksColumns.helper";
import { HASTasksLegend } from "components/Schedulers/Legend";
import { HASCheckDataCellRender } from "components/Schedulers/ScheduleDataGrid/Components/Cell/CellDataGrid.js";
import { Snapshot } from "components/ContractInFocus/Interfaces/ContractInFocus.interfaces";
import { profileSelector } from 'components/Profile/Selectors/Profile.selector';


import "./ScheduleHeaderStyles.scss";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { HASScheduleProps } from "components/ContractInFocus/HoC/WithPageContext/WithPageContext";

export interface ArchiveArgs {
    data: any,
    portfolio?: HydratedPortfolio,
    contract?: SiteContract
}

interface ScheduleControlProps {
    showPlannerView?: boolean,
    showArchived: boolean,
    setShowArchived: React.Dispatch<React.SetStateAction<boolean>>,
    selectedFocusedSnapshot?: Snapshot,
    showNotificationSettings: boolean,
    setShowNotificationSettings: React.Dispatch<React.SetStateAction<boolean>>,
    setFilterQueryParams?: React.Dispatch<React.SetStateAction<FilterControlProperties>>,
    filterQueryParams?: FilterControlProperties
}

const getControlSwitches = ({
    showArchived,
    setShowArchived,
    showNotificationSettings,
    setShowNotificationSettings,
    selectedFocusedSnapshot,
    setFilterQueryParams,
    filterQueryParams
}: ScheduleControlProps
) => {
    return () => <>
        {!selectedFocusedSnapshot && <FormControlLabel
            label="Notifications Mode"
            labelPlacement="start"
            className="show-archived-switch no-print"
            control={
                <Switch
                    size="small"
                    onChange={() => {
                        setShowNotificationSettings(!showNotificationSettings);
                    }}
                    defaultChecked={showNotificationSettings}
                />
            }
        />
        }
        {
            !selectedFocusedSnapshot && <FormControlLabel
                label="Archiving Mode"
                labelPlacement="start"
                className="show-archived-switch no-print"
                control={
                    <Switch
                        size="small"
                        onChange={() => {
                            setShowArchived(!showArchived);
                        }}
                        defaultChecked={showArchived}
                    />
                }
            />
        }
        {
            <FormControlLabel
                label="Core Tasks Only"
                labelPlacement="start"
                className="show-archived-switch no-print"
                control={
                    <Switch
                        size="small"
                        onChange={() => {
                            setFilterQueryParams && setFilterQueryParams({
                                ...filterQueryParams,
                                global_only: !filterQueryParams?.global_only ? 1 : 0
                            });
                        }}
                        defaultChecked={!!filterQueryParams?.global_only}
                    />
                }
            />
        }
        {
            <FormControlLabel
                label="Exceptions Only"
                labelPlacement="start"
                className="show-archived-switch no-print"
                control={
                    <Switch
                        size="small"
                        onChange={() => {
                            setFilterQueryParams && setFilterQueryParams({
                                ...filterQueryParams,
                                exceptions_only: !filterQueryParams?.exceptions_only ? 1 : 0
                            });
                        }}
                        defaultChecked={!!filterQueryParams?.exceptions_only}
                    />
                }
            />
        }
        {
            <FormControlLabel
                label="With Checks Only"
                labelPlacement="start"
                className="show-archived-switch no-print"
                control={
                    <Switch
                        size="small"
                        onChange={() => {
                            setFilterQueryParams && setFilterQueryParams({
                                ...filterQueryParams,
                                with_checks_only: !filterQueryParams?.with_checks_only ? 1 : 0
                            });
                        }}
                        defaultChecked={!!filterQueryParams?.with_checks_only}
                    />
                }
            />
        }
    </>
}

const getHASButtonCellTemplate = ({ showArchived, showNotificationSettings, handleArchive, handleSubmitNotificationSetting, handleRestore, data }: GetHASSchedulerButtonCellTemplateProps) => {
    // const missingVisits = data.number_of_visits - data.actual_visits_count;
    // const multipleVisits = data.number_of_visits > 1;
    // const multipleActual = data.actual_visits_count > 1;
    // const noActualVisits = data.actual_visits_count === 0;
    return (
        <>
            {/* {
                missingVisits > 0 && !showArchived &&
                <Tooltip title={`${data.number_of_visits} visit${multipleVisits ? 's have' : ' has'} been contracted and ${noActualVisits ? 'none have' : multipleActual ? 'only ' + data.actual_visits_count + ' have' : 'only ' + data.actual_visits_count + ' has'} been scheduled or completed within the duration of the current supplier contract`}>
                    <div className="visitsCountVarianceWrapper no-print">
                        <div className="visitsCountVariance">
                            <div className="inner">
                                {missingVisits}
                            </div>
                        </div>
                    </div>
                </Tooltip>
            } */}
            {showArchived && <ArchiveControls
                data={data}
                showArchived={showArchived}
                handleArchive={handleArchive}
                handleRestore={handleRestore}
            />}
            {showNotificationSettings && <HASNotificationControls
                showNotificationSettings={showNotificationSettings}
                handleSubmitNotificationSetting={handleSubmitNotificationSetting}
                data={data}
            />}
            {!showArchived && !showNotificationSettings &&
                <GlobalIndicator data={data} />
            }
        </>
    );
};

const getHASHeaderCellTemplate = ({ showNotificationSettings, handleSubmitNotificationSetting }: HeaderHASNotificationControlProps) => {
    return (
        <>

            {showNotificationSettings && <HeaderHASNotificationControls
                showNotificationSettings={showNotificationSettings}
                handleSubmitNotificationSetting={handleSubmitNotificationSetting}
            />}
        </>
    );
};

const HASSchedule = (props: HASScheduleProps) => {

    let query = useQuery();
    const isPDFRequest = !!query.get('pdfView');
    const visibleFixedColumns = ["archived", "contract__contract_ref", "task__name", "task__interval_unit", "task__interval"];
    const [clickableCaptionColumns, setClickableCaptionColumns] = useState(['task__name']);
    const [showArchived, setShowArchived] = useState(false);
    const [showNotificationSettings, setShowNotificationSettings] = useState(false);
    const [reload, forceReload] = useReducer((x) => x + 1, 0);
    const profileState = useSelector(profileSelector);
    const myId = profileState?.data?.id;
    const standardButtonCellWidth = 45;
    const [buttonCellWidth, setButtonCellWidth] = useState(standardButtonCellWidth);
    const [filterQueryParams, setFilterQueryParams] = useState<FilterControlProperties>({
        'global_only': 0,
        'exceptions_only': props.defaultExceptionsOnly ? 1 : 0,
        'with_checks_only': props.defaultExceptionsOnly ? 1 : 0
    })

    const selectedFocusedSnapshot = useSelector(
        contractInFocusSelectors.contractOrPortfolioSnapshotSelector({ contractId: props.contract?.id, portfolioId: props.portfolio?.id })
    );

    useEffect(() => {
        if (showNotificationSettings) {
            setShowArchived(false)
        }
        if (showArchived) {
            setShowNotificationSettings(false);
        }
    }, [showNotificationSettings, setShowNotificationSettings, showArchived, setShowArchived])

    useEffect(() => {
        if (showNotificationSettings) {
            setButtonCellWidth(250);
        } else {
            setButtonCellWidth(standardButtonCellWidth);
        }
    }, [showNotificationSettings])

    useEffect(() => {
        props.refreshAt && forceReload();
    }, [props.refreshAt])

    const fetchInfo = useCallback(({ args, query_params }: { args?: any, query_params?: any }) => {
        let config = {
            contract_ref: props.contract?.contract_ref,
            portfolioId: props.portfolio?.id,
            query_params: {}
        }
        if (args) {
            config = {
                ...args,
                ...config
            }
        }
        if (query_params) {
            config.query_params = { ...config.query_params, ...query_params }
        }
        return fetchHASContractTasksWithMonthChecks(config);
    }, [props.contract?.contract_ref, props.portfolio?.id])

    const fetchPreflightInfo = useCallback(({ args, query_params }: { args?: any, query_params?: any }) => {
        let config = {
            contract_ref: props.contract?.contract_ref,
            portfolioId: props.portfolio?.id,
            query_params: {}
        }
        if (args) {
            config = {
                ...args,
                ...config
            }
        }
        if (query_params) {
            config.query_params = { ...config.query_params, ...query_params }
        }
        return fetchHASContractTasksWithMonthChecksPreFlightInfo(config);
    }, [props.contract?.contract_ref, props.portfolio?.id]
    )

    const getButtonCellTemplate = useCallback((e) => {
        const data = e.data;
        const onArchiving = () => archiveContractFromHASTask(data.task, data.contract).then((response) => {
            forceReload();
        });

        const onRestoring = () => unArchiveContractFromHASTask(data.task, data.contract).then((response) => {
            forceReload();
        });

        const handleSubmitNotificationSetting = (notificationSettings: any, id?: string) => {
            if (id) {
                simpleUpdateHASContractTaskPersonalSettings({
                    id: id,
                    payload: {
                        contract_task: data.id,
                        person: myId,
                        ...notificationSettings
                    },
                    HASContractTaskId: data.id
                }).then((response) => {
                    forceReload();
                })

            } else {
                simpleCreateHASContractTaskPersonalSettings({
                    payload: {
                        contract_task: data.id,
                        person: myId,
                        ...notificationSettings
                    },
                    HASContractTaskId: data.id
                }).then((response) => {
                    forceReload();
                })
            }

        }

        return getHASButtonCellTemplate({
            showArchived,
            handleArchive: onArchiving,
            handleRestore: onRestoring,
            handleSubmitNotificationSetting,
            showNotificationSettings,
            data: e.row.data,
        })
    }, [showArchived, myId, showNotificationSettings]);

    const getHeaderButtonCellTemplate = useCallback((e: any, contractRef?: string, portfolioId?: number, query_params?: any) => {
        const handleSubmitNotificationSetting = (notificationSettings: any) => {
            const thisData = { ...notificationSettings, person: myId }
            updateMultipleContractTasksNotificationSettings({
                contract_ref: contractRef,
                portfolioId: portfolioId,
                query_params: query_params,
                data: thisData
            }).then((response) => {
                forceReload();
            })
        }
        return getHASHeaderCellTemplate({
            handleSubmitNotificationSetting,
            showNotificationSettings,
        })
    }, [myId, showNotificationSettings]);

    const getHASControlSwitches = useCallback(() => getControlSwitches({
        showArchived,
        setShowArchived,
        showNotificationSettings,
        setShowNotificationSettings,
        filterQueryParams,
        setFilterQueryParams,
        selectedFocusedSnapshot
    }), [showArchived, setShowArchived, showNotificationSettings, setShowNotificationSettings, filterQueryParams, setFilterQueryParams, selectedFocusedSnapshot])

    const handleOnRowPrepared = function (e: any) {
        if (e.rowType === 'data' && e.data?.task__for_contract) {
            e.rowElement.addClass('forContract');
        }
    }

    return <Scheduler
        title="H&S Checks"
        contextIdString={"HASChecks"}
        DetailModal={HASDetailModal}
        showPlannerView={false}
        canShowPlannerView={false}
        isPDFRequest={isPDFRequest}
        show // in the HAS context the visibility settings are controlled in either the HASCentre and in the contract/portfolio context
        // there currently aren't any as each section has it's own menu item -  so this can be left as true
        clickableCaptionColumns={clickableCaptionColumns}
        getButtonCellTemplate={getButtonCellTemplate}
        getHeaderButtonCellTemplate={getHeaderButtonCellTemplate}
        buttonCellWidth={buttonCellWidth}
        visibleFixedColumns={visibleFixedColumns}
        fetchInfo={fetchInfo}
        generateColumnHeaders={generateColumnHeaders}
        Legend={HASTasksLegend}
        initialEditModalIndex={1}
        CellRender={HASCheckDataCellRender}
        showArchived={showArchived}
        setShowArchived={setShowArchived}
        ControlSwitches={getHASControlSwitches()}
        remoteOperations
        contractRefDataField="contract__contract_ref"
        reload={reload}
        fetchPreflightInfo={fetchPreflightInfo}
        pageSize={15}
        passedInPeriod={props.passedInPeriod}
        extraFilterQueryParams={filterQueryParams}
        handleOnRowPrepared={handleOnRowPrepared}
        HASEditableContext
        {...props}
    ></Scheduler>
}

export default HASSchedule


