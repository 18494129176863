import React, { useEffect, useState, useRef } from "react";
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';


// Own
import { store } from "store/store";
import { AdminDataGrid } from "components/AdminPanel/Containers/AdminDataGrid";
import { adminPanelType } from "components/AdminPanel/Models/AdminPanel.model";
import { generalContractSublistColumns, linkContractGridActions } from "components/AdminPanel/Contracts/Models/ContractDetail.model";
import { openPanelInSelectionMode } from 'components/AdminPanel/Helpers/AdminPanel.helper';
import { HydratedPortfolio, HydratedPortfolioContractLink, PortfolioContractLink } from "components/Portfolios/Interfaces/Portfolios.interface";
import { portfolioContractLinkHydratedFactory } from "components/Portfolios/Selectors/Portfolio.selectors";
import * as portfolioActions from "components/AdminPanel/Portfolios/Actions/AdminPanelPortfolios.actions";

import { EntityKeys } from "constants/entity.constants";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";
import portfolioAdminServices from "components/AdminPanel/Portfolios/Services/portfolioAdminServices";
interface PortfolioContractViewProps {
    panelId: string;
    masterRecordData: HydratedPortfolio;
    id: any;
    //contractsState: SiteContract[];
    dataTestId: string;
    masterRecordType?: string;
    selectActionIdentifier?: string;
    linkPanelTitle?: string;
    meta: FieldMetaGroup
}

const PortfolioContractView: React.FunctionComponent<PortfolioContractViewProps> = ({
    panelId,
    masterRecordData,
    id,
    //contractsState,
    dataTestId,
    masterRecordType,
    selectActionIdentifier,
    linkPanelTitle,
    meta
}) => {

    const currentMemberIds = useRef<(string | number)[]>();
    const [datasource, setDatasource] = useState<any>();

    useEffect(() => {
        const custom = new CustomStore({
            key: "id",
            load: loadOptions => portfolioAdminServices.fetchPortfolioContractLinks(masterRecordData.id).then(
                (response) => {
                    currentMemberIds.current = response.data.map((x: PortfolioContractLink) => x.contract);
                    const getHydratedLinks = portfolioContractLinkHydratedFactory(response.data);
                    const data = getHydratedLinks(store.getState()).map((x: HydratedPortfolioContractLink) => x.contract);
                    return data;
                }
            )
        });

        setDatasource(
            // @ts-ignore
            new DataSource({
                store: custom
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function handleAddContractsToPortfolio(contractIds: number[]) {
        const accessObjs = contractIds.map((contractId) => (
            {
                "contract": contractId,
                "portfolio": masterRecordData.id
            }
        ))
        portfolioAdminServices
            .linkContractsToPortfolio(masterRecordData, accessObjs)
            .then(() => {
                console.log('reloading');
                // at the moment the admin panel is refreshed when closing the other admin picker panel, but we want to stop that so a reload will
                // be required
                store.dispatch(portfolioActions.fetchPortfolios());
                datasource.reload();
            })
    }

    const handleRemoveContractFromPortfolio = (contract: SiteContract) => {
        portfolioAdminServices.removeContractFromPortfolio(
            masterRecordData,
            contract.id
        )
            .then(() => {
                store.dispatch(portfolioActions.fetchPortfolios());
                datasource.reload();
            })
    };

    const handleContractLink = (): void => {
        masterRecordType && selectActionIdentifier && linkPanelTitle && openPanelInSelectionMode(
            {
                selectActionIdentifier,
                masterRecordType,
                masterRecordData,
                linkedRecordType: adminPanelType.contracts,
                onAddLinks: handleAddContractsToPortfolio,
                currentMemberIds: currentMemberIds.current || [],
                linkPanelTitle,
            }
        )
    };

    const linkContract = selectActionIdentifier ? handleContractLink : undefined;

    return (
        <AdminDataGrid
            repaintChangesOnly
            data={datasource}
            editMode="cell"
            meta={meta}
            dataTestId={dataTestId}
            getDatagridColumns={generalContractSublistColumns}
            masterView={false}
            entityKey={EntityKeys.Contracts}
            columnActions={linkContractGridActions(handleRemoveContractFromPortfolio)}
            panelId={panelId}
            onUpdate={() => { }} // records are currently updated in this context - just linked or removed
            onLink={linkContract}
        />
    );
};

export default PortfolioContractView;
