import styled from "styled-components";
import { fontFamily } from "../../../../styles/app/common/variables";
import styles from "./../../../../styles/app/common/variables.scss";
import { StyledSiteTitleProps } from "store/Common/Interfaces/Common.interface";
import { hideOverflowText } from "../../../../styles/app/common/text";

const isFullWidth = (width: number) =>
  width < 600 || (width > 802 && width < 1280) || width > 1605;

export const StyledImageWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  flex-grow: 1;

  img {
    width: auto;
    height: 100%;

    ${({ width }: { width: number }) => {
    return isFullWidth(width)
      ? ` 
        width: 100%;
        height: auto;
      `
      : null;
  }}
  }
`;

export const StyledTextWrapper = styled.div`
  height: 80px;
  display: flex;
  flex-direction: column;
  padding: 0.51rem 0;
  justify-content: space-around;
`;

export const StyledSiteTitle = styled.div`
  color: ${styles.blueDark};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 450;
  font-size: ${(props: StyledSiteTitleProps) =>
    props.width > 210 ? "1.25rem" : "1rem"};
`;

export const StyledSiteSub = styled.div`
  font-size: 0.85rem;
  ${hideOverflowText}
`;

export const SiteIndexWrapper = styled.div`
  ${({ favourite }: { favourite: boolean }) => {
    return `
  
    font-family: ${fontFamily};
    cursor: pointer;    

    .MuiPaper-rounded {
      position: relative;
      border-bottom: 0.25rem solid ${favourite ? styles.brandGold : styles.white
      };
      height: 294px;
    }
  
    .fa-star {
      position: absolute;
      right: .5rem;
      top: .5rem;
      font-size: 1.5rem;
      color: ${favourite ? styles.brandGold : styles.white};
    }`;
  }}
`;
