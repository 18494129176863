import { createSelector } from "reselect"
import { State } from "../../../store/interfaces/State";
import { PersonalSettingsState, ProfileState } from "../Interfaces/ProfileState.interface";
import { createDeepEqualSelector, createShallowEqualSelector } from "helpers/SelectorUtilities/selectorUtilities";
import { settingsSelector } from 'store/selectors/settings.selector';


export const profileSelector = (state: State): ProfileState => state.profile;

export const personalSettingsSelector = (state: State): PersonalSettingsState => state.personalSettings;


export const profileEntitySelector = createSelector(
  profileSelector, (profileState: ProfileState) => profileState.data
);

export const personalSettingsDataSelector = createSelector(
  personalSettingsSelector, (personalSettingsState: PersonalSettingsState) => personalSettingsState.data
);

export const adminAccessSelector = createSelector(
  profileSelector,
  profile => profile?.data?.admin_access || false
);

export const amSuperUserSelector = createSelector(
  profileSelector,
  profile => profile?.data?.superuser || false
);

export const userIdSelector = createSelector(
  profileSelector,
  profile => profile?.data?.id || undefined
);

export const internalMemberSelector = createSelector(
  profileSelector,
  profile => profile?.data?.internal_member || undefined
);

export const healthAndSafetyAdminSelector = createSelector(
  profileSelector,
  profile => profile?.data?.h_a_s_admin_level || undefined
);

export const generalPermitTrainingExpires = createSelector(
  profileSelector,
  profile => profile?.data?.permit_training_valid_to || undefined
);

export const confinedSpacesTrainingExpires = createSelector(
  profileSelector,
  profile => profile?.data?.confined_spaces_training_valid_to || undefined
);

export const ptwAdminSelector = createSelector(
  profileSelector,
  profile => profile?.data?.ptw_admin_level || undefined
);

export const healthAndSafetyAdminAccessSelector = createSelector(
  profileSelector,
  profile => profile?.data?.h_a_s_admin_member || false
);

export const profileOrganisationSelector = createSelector(
  profileSelector,
  profile => profile?.data?.organisation
);

export const contractSideBarCollapsedSelector = createSelector(
  personalSettingsSelector,
  personalSettings => personalSettings?.data?.collapse_contract_sidebar
);

export const showHASCentreSchedulerSelector = createSelector(
  personalSettingsSelector,
  personalSettings => personalSettings?.data?.show_h_a_s_centre_scheduler
);

export const showHASCentreEventsTableSelector = createSelector(
  personalSettingsSelector,
  personalSettings => personalSettings?.data?.show_h_a_s_centre_events_table
);

export const showHASCentreWorkPermitsTableSelector = createSelector(
  personalSettingsSelector,
  personalSettings => personalSettings?.data?.show_h_a_s_centre_work_permits_table
);

export const showHASCentreSpecialistTableSelector = createSelector(
  personalSettingsSelector,
  personalSettings => personalSettings?.data?.show_h_a_s_centre_specialist_table
);

export const showHASCentreAccidentsTableSelector = createSelector(
  personalSettingsSelector,
  personalSettings => personalSettings?.data?.show_h_a_s_centre_accidents_table
);

export const showHASCentreNearMissesTableSelector = createSelector(
  personalSettingsSelector,
  personalSettings => personalSettings?.data?.show_h_a_s_centre_near_misses_table
);

export const contractSideBarHiddenSelector = createSelector(
  personalSettingsSelector,
  personalSettings => personalSettings?.data?.hide_contract_sidebar
);

export const indexSideBarCollapsedSelector = createSelector(
  personalSettingsSelector,
  personalSettings => personalSettings?.data?.collapse_index_sidebar
);

export const indexSideBarHiddenSelector = createSelector(
  personalSettingsSelector,
  personalSettings => personalSettings?.data?.hide_index_sidebar
);

export const broadcastLayoutUpdateSelector = createSelector(
  settingsSelector,
  settings => settings?.layoutUpdateBroadcast
);

export const avatarDetailsSelector = createDeepEqualSelector(
  profileSelector,
  profile => ({
    first_name: profile?.data?.first_name,
    last_name: profile?.data?.last_name,
    avatar: profile?.data?.avatar
  })
);

export const sitesIndexVisibilitySelector = createDeepEqualSelector(
  personalSettingsDataSelector,
  (personalSettings) => {
    return {
      "Non Favourite Sites": {
        default: {
          updateKey: "show_non_favourited_sites",
          value: personalSettings?.show_non_favourited_sites || false
        }
      },
      "Deactivated Contracts": {
        default: {
          updateKey: "show_deactivated_contracts",
          value: personalSettings?.show_deactivated_contracts || false
        }
      }
    }
  }
);

export const HASCentreVisibilitySelector = createDeepEqualSelector(
  personalSettingsDataSelector,
  (personalSettings) => {
    return {
      "Show H&S Checks": {
        default: {
          updateKey: "show_h_a_s_centre_scheduler",
          value: personalSettings?.show_h_a_s_centre_scheduler || false
        }
      },
      "Show Events": {
        default: {
          updateKey: "show_h_a_s_centre_events_table",
          value: personalSettings?.show_h_a_s_centre_events_table || false
        }
      },
      "Show Accidents": {
        default: {
          updateKey: "show_h_a_s_centre_accidents_table",
          value: personalSettings?.show_h_a_s_centre_accidents_table || false
        }
      },
      "Show Near Misses": {
        default: {
          updateKey: "show_h_a_s_centre_near_misses_table",
          value: personalSettings?.show_h_a_s_centre_near_misses_table || false
        }
      },
      "Show Work Permits": {
        default: {
          updateKey: "show_h_a_s_centre_work_permits_table",
          value: personalSettings?.show_h_a_s_centre_work_permits_table || false
        }
      },
      "Show Specialist Visits": {
        default: {
          updateKey: "show_h_a_s_centre_specialist_table",
          value: personalSettings?.show_h_a_s_centre_specialist_table || false
        }
      },
    }
  }
);

export const selectShowNonFavouriteSitesSetting = createDeepEqualSelector(
  sitesIndexVisibilitySelector,
  sitesVisibilityProfile => sitesVisibilityProfile["Non Favourite Sites"].default.value
);

export const portfoliosIndexVisibilitySelector = createDeepEqualSelector(
  personalSettingsDataSelector,
  (personalSettings) => {
    return {
      "Non Favourite Portfolios": {
        default: {
          updateKey: "show_non_favourited_portfolios",
          value: personalSettings?.show_non_favourited_portfolios || false
        }
      }
    }
  }
);

export const selectShowNonFavouritePortfoliosSetting = createDeepEqualSelector(
  portfoliosIndexVisibilitySelector,
  portfoliosVisibilityProfile => portfoliosVisibilityProfile["Non Favourite Portfolios"].default.value
);

