/* eslint-disable */
import React, { useState, useRef, useReducer, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";

// Own
import { store } from "store/store";
import { getPanelSelectorFactory } from "components/AdminPanel/Selectors/Selectors";
import HASStatutoryTemplateDoc from "components/AdminPanel/HAS/Containers/HASTaskAdminDetailView/HASStatutoryTemplateDocument";
import { FieldMetaGroup, PreFlightListInfo } from 'components/Common/Interfaces/Entity.interface';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { simpleUpdateHASTask, fetchHASTaskContractLinksPreFlightInfo } from "components/AdminPanel/HAS/HASTaskServices";
import { addNotification } from "components/Notification/Actions/Notification.actions";
import { NOTIFICATION_SUCCESS } from "components/Notification/Constants/constants";
import { newGetPayloadFromRef } from 'services/API/API.helper';
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { APIPrivileges } from "services/Interface/Interface";
import HASTaskContractsView from "components/AdminPanel/HAS/Containers/HASTaskAdminDetailView/HASTaskContractsView";
import { TabbedPanel } from 'components/Common/Components/TabPanel/TabPanel';
import { setDetailPage } from "components/AdminPanel/Actions/adminPanel.actions";
import GeneralEntityForm, { generateStandardGeneralActionButtonDefinitions } from "components/Common/Components/GeneralEntityForm/GeneralEntityForm";
import { FormErrorsType } from "store/Common/Interfaces/Common.interface";

import "components/Common/Components/GeneralActionForm/GeneralActionFormStyles.scss"

import { FORM_ROW_ID } from "components/AdminPanel/Models/AdminPanel.model";

const getFormFieldsConfig = (canWrite: boolean): FieldsFormConfig => ({
    //statutory_doc_ref: {},
    guidance: {
        multiline: true,
        rows: 15
    },
})

// Styles
import {
    DetailWrapper,
    NoReportWrapper,
    ReportsWrapper
} from 'components/Common/Components/GeneralEntityForm/GeneralDetailStyles';
import { isEqual } from 'lodash';

interface HASTaskDetailProps {
    panelId: string;
    data: { data: any };
    rowKey: string | number;
    meta?: FieldMetaGroup;
    putMeta?: FieldMetaGroup;
    permissions?: APIPrivileges;
    masterViewSharedSpace?: React.MutableRefObject<any>;
    onClose: () => void;
    detailShowing?: boolean;
}

function HASTaskAdminDetailView({
    panelId,
    data: { data },
    meta,
    putMeta,
    permissions,
    onClose,
    masterViewSharedSpace,
    rowKey,
}: HASTaskDetailProps) {

    if (!masterViewSharedSpace?.current[rowKey] && masterViewSharedSpace) {
        masterViewSharedSpace.current[rowKey] = {}; //important to only set this to an empty object when not yet defined
    }

    useEffect(() => {
        return () => {
            if (masterViewSharedSpace?.current[rowKey]) {
                masterViewSharedSpace.current[rowKey] = undefined; // IMPORTANT! release any memory when this component disposes if not caching...
                // NB if not caching, probably makes sense to show this component conditionally to with 'detailShowing' too
                // though you could potentially save the component memory but cache the contents
            }
        }
    }, [])

    const rowLevelSharedSpace = masterViewSharedSpace?.current[rowKey];

    const contractSublistTestId = "hastask-contract-detail-view";
    const [thisRecord, setThisRecord] = useState<any>();
    const formValuesRef = useRef<any>({});
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 1);
    const canWrite = !!permissions?.PUT;
    const [formErrors, setFormErrors] = useState<FormErrorsType>({});

    const HASTaskTabs = ["Detail", "Contracts"];
    const [HASContractTaskLinksInfo, setHASContractTasksLinksInfo] = useState<PreFlightListInfo>();

    useEffect(() => {
        if (!thisRecord || !isEqual(data, thisRecord)) {
            setThisRecord(data);
        }
    }, [data]);


    useEffect(() => {
        !HASContractTaskLinksInfo && thisRecord && fetchHASTaskContractLinksPreFlightInfo(thisRecord.id).then(response => {
            setHASContractTasksLinksInfo(response)
        });
    }, [thisRecord, HASContractTaskLinksInfo])

    const dispatch = useDispatch();

    const handleChangeTab = (index: number) => {
        thisRecord.id && dispatch(setDetailPage(panelId, thisRecord.id, index));
    };

    const noReportsComments = `You can't add statutory until created`;

    const selectAdminPanelState = useSelector(getPanelSelectorFactory(panelId));

    const handleSave = useCallback((recordId?: string, callback?: any) => {
        if (putMeta) {
            recordId && simpleUpdateHASTask({
                id: recordId, payload: newGetPayloadFromRef({ ref: formValuesRef, metaData: putMeta }),
                meta: putMeta
            }).then((response) => {
                store.dispatch(addNotification({ message: "Saved", type: NOTIFICATION_SUCCESS }))
                // rowLevelSharedSpace.data = formValuesRef.current = response.data; // we don't need to save results, either in 
                // masterView structure (as that is caching which we don't currently need)
                // no need to update grid datasource (e.g. with commonUpdateGridRow) because this form currently only shows values
                // not shown in the grid
                setThisRecord(response.data);
                callback && callback();
            }).catch((e) => {
                console.log('issue: ', e);
            });
        } else {
            console.log('no put meta!')
        }
    }, [putMeta, formValuesRef]);
    if (rowLevelSharedSpace) {
        rowLevelSharedSpace.handleSave = handleSave;
    }

    const getButtons = useCallback(() => generateStandardGeneralActionButtonDefinitions({
        handleSave,
        formErrors
    }), [handleSave]);

    return (<>
        {thisRecord &&
            <TabbedPanel
                pageIndex={selectAdminPanelState[thisRecord.id]?.detailIndex || 0}
                showTabs={thisRecord.id.toString() !== FORM_ROW_ID}
                onChangeTab={handleChangeTab}
                tabs={HASTaskTabs}
            >
                <div>
                    {meta && mustRefresh && <>
                        <DetailWrapper>
                            <GeneralEntityForm
                                buttons={getButtons()}
                                useDefaultRevertChanges
                                formValuesRef={formValuesRef}
                                refreshSignal={mustRefresh}
                                formLevelSharedSpace={rowLevelSharedSpace} // rowLevelSharedSpace typically used as formLevelSharedSpace where 
                                // we want to cached data outside this form and there is just one form managed by this component. Local space can be used
                                // otherwise, which will not survive this component
                                rowLevelSharedSpace={rowLevelSharedSpace}
                                initialData={thisRecord}
                                canWrite={!!permissions?.PUT}
                                formFieldsConfig={getFormFieldsConfig(!!permissions?.PUT)}
                                meta={meta}
                                dispatchRefreshContext={forceUpdate}
                                gridClass="HASTaskAdminEditForm"
                                formErrors={formErrors}
                                setFormErrors={setFormErrors}
                            />
                            <ReportsWrapper>
                                {thisRecord
                                    ? <HASStatutoryTemplateDoc
                                        canWrite={canWrite}
                                        data={thisRecord}
                                    />
                                    : <NoReportWrapper>
                                        <FolderOpenIcon />
                                        {noReportsComments}
                                    </NoReportWrapper>}
                            </ReportsWrapper>
                        </DetailWrapper>
                    </>}
                </div>
                <div>
                    {HASContractTaskLinksInfo && <HASTaskContractsView
                        panelId={panelId}
                        dataTestId={contractSublistTestId}
                        id={thisRecord.id}
                        masterRecordData={thisRecord}
                        masterRecordType="hastask"
                        selectActionIdentifier="addContracts"
                        linkPanelTitle={"ADD CONTRACTS TO HEALTH AND SAFETY TASK: " + thisRecord.name.toUpperCase()}
                        meta={HASContractTaskLinksInfo.meta}
                        permissions={HASContractTaskLinksInfo.privileges}
                        canCreate={HASContractTaskLinksInfo.canCreate}
                    />}
                </div>

            </TabbedPanel>}

    </>)
}

const ConditionalHASTaskAdminDetailView: React.FC<HASTaskDetailProps> = ({
    detailShowing,
    ...props
}) => {
    return <>
        {detailShowing && <HASTaskAdminDetailView
            {...props} />}
    </>
}

//HASTaskAdminDetailView.whyDidYouRender = true;

export default ConditionalHASTaskAdminDetailView;