import { createSelector } from "reselect";
import { State } from "store/interfaces/State";
import { contractInFocusSelector } from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import { portfolioInFocusSelector } from "components/PortfolioInFocus/Selectors/portfolioInFocus.selectors";

// NOTE currently ACLs are all about knowing on the FE which particular contract/portfolio models/tables are 'accessible' to the current user

export const contractACLs = (state: State) => state.contractACLs;
export const contractInFocusACLSelector = createSelector([contractACLs, contractInFocusSelector], (ACLS, CIF) => {
    return ACLS ? ACLS[CIF?.contract.id] : undefined;
})

export const needsPersonalContractSettingsModalSelector = createSelector([contractACLs, contractInFocusSelector], (ACLS, CIF) => {
    // Note this is just to determine if the icon needs to be shown
    // personalContractSettings may still need to be retrieved from the BE even
    // if the user isn't tweaking them manually via the settings icon
    if (ACLS) {
        const theseACLS = ACLS[CIF.contract.id];
        return theseACLS ? theseACLS.financials.canRead || theseACLS.report?.canRead : false;
    }
    return false;
})

export const portfolioACLs = (state: State) => state.portfolioACLs;
export const portfolioInFocusACLSelector = createSelector([portfolioACLs, portfolioInFocusSelector], (ACLS, PIF) => {
    return ACLS ? ACLS[PIF?.portfolio.id] : undefined;
})

export const needsPersonalPortfolioSettingsModalSelector = createSelector([portfolioACLs, portfolioInFocusSelector], (ACLS, PIF) => {
    // Note this is just to determine if the icon needs to be shown
    // personalPortfolioSettings may still need to be retrieved from the BE even
    // if the user isn't tweaking them manually via the settings icon
    if (ACLS) {
        const theseACLS = ACLS[PIF.portfolio.id];
        return theseACLS ? theseACLS.financials.canRead || theseACLS.report?.canRead : false;
    }
    return false;
})

// NB if we want to display items based on combinations of permissions (e.g. for various routes) the place to 
// abstract that would be here in the selectors.
export const ppmCIFAccessSelector = createSelector([contractInFocusACLSelector], (contractInFocusACLSelector) => {
    return !!contractInFocusACLSelector?.planned.canRead;
})

export const ppmPIFAccessSelector = createSelector([portfolioInFocusACLSelector], (portfolioInFocusACLSelector) => {
    return !!portfolioInFocusACLSelector?.planned.canRead;
})

export const reactiveCIFAccessSelector = createSelector([contractInFocusACLSelector], (contractInFocusACLSelector) => {
    return !!contractInFocusACLSelector?.reactive.canRead;
})

export const reactivePIFAccessSelector = createSelector([portfolioInFocusACLSelector], (portfolioInFocusACLSelector) => {
    return !!portfolioInFocusACLSelector?.reactive.canRead;
})

export const eventsCIFAccessSelector = createSelector([contractInFocusACLSelector], (contractInFocusACLSelector) => {
    return !!contractInFocusACLSelector?.events.canRead;
})

export const eventsPIFAccessSelector = createSelector([portfolioInFocusACLSelector], (portfolioInFocusACLSelector) => {
    return !!portfolioInFocusACLSelector?.events.canRead;
})

export const financialsCIFAccessSelector = createSelector([contractInFocusACLSelector], (contractInFocusACLSelector) => {
    return !!contractInFocusACLSelector?.financials.canRead;
})

export const financialsPIFAccessSelector = createSelector([portfolioInFocusACLSelector], (portfolioInFocusACLSelector) => {
    return !!portfolioInFocusACLSelector?.financials.canRead;
})

export const auditCIFAccessSelector = createSelector([contractInFocusACLSelector], (contractInFocusACLSelector) => {
    return !!contractInFocusACLSelector?.audit.canRead;
})

export const auditPIFAccessSelector = createSelector([portfolioInFocusACLSelector], (portfolioInFocusACLSelector) => {
    return !!portfolioInFocusACLSelector?.audit.canRead;
})

export const spendCIFAccessSelector = createSelector([contractInFocusACLSelector], (contractInFocusACLSelector) => {
    return !!contractInFocusACLSelector?.spend.canRead;
})

export const spendPIFAccessSelector = createSelector([portfolioInFocusACLSelector], (portfolioInFocusACLSelector) => {
    return !!portfolioInFocusACLSelector?.spend.canRead;
})

export const meterReadingCIFAccessSelector = createSelector([contractInFocusACLSelector], (contractInFocusACLSelector) => {
    return !!contractInFocusACLSelector?.meter?.canRead;
})

// export const meterReadingPIFAccessSelector = createSelector([portfolioInFocusACLSelector], (portfolioInFocusACLSelector) => {
//     return !!portfolioInFocusACLSelector?.meter_reading?.canRead;
// })

export const reportCIFAccessSelector = createSelector([contractInFocusACLSelector], (contractInFocusACLSelector) => {
    return !!contractInFocusACLSelector?.report?.canRead;
})

export const reportPIFAccessSelector = createSelector([portfolioInFocusACLSelector], (portfolioInFocusACLSelector) => {
    return !!portfolioInFocusACLSelector?.report?.canRead;
})

export const HASCIFAccessSelector = createSelector([contractInFocusACLSelector], (contractInFocusACLSelector) => {
    return !!contractInFocusACLSelector?.health_and_safety?.canRead;
})