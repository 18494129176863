import styled from "styled-components";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  styledShadow,
  contractSubHeaderSpacer,
  hasSubHeaderSpacer
} from "../../../../../styles/app/mixins/mixins";

export const MainSubheadingWrapper = styled.div`
  ${contractSubHeaderSpacer}
  justify-content: space-between;
  z-index: 1;
  position: fixed;
  transition: opacity 0.1s;
  //transition-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1);
  ${styledShadow}
`;

export const HASSubheadingWrapper = styled.div`
  ${hasSubHeaderSpacer}
  justify-content: space-between;
  z-index: 1;
  position: fixed;
  transition: opacity 0.1s;
  //transition-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1);
  ${styledShadow}
`;

export const MainSubheadingSpacer = styled.div`
  ${contractSubHeaderSpacer}
`;

export const HASSubheadingSpacer = styled.div`
  ${hasSubHeaderSpacer}
`;

export const ControlsWrapper = styled.div`
  margin-right: 1rem;
  margin-top: 1rem;
  display: flex;
`;

export const useSubHeadingNavStyles = makeStyles(theme =>
  createStyles({
    selectItem: {
      fontSize: 14
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      marginTop: "-0.8rem",
      paddingRight: "1.5rem"
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  })
);
