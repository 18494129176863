import { selectionConfigState } from "components/AdminPanel/Interfaces/Panel"

export const ADD_SELECTED_ENTITIES_TO_PANEL = "ADD_SELECTED_ENTITIES_TO_PANEL";
export const addSelectedEntitiesToPanel = (
    panelId: string, selectActionIdentifier: string, selectedIds: (string | number)[]
) => ({ type: ADD_SELECTED_ENTITIES_TO_PANEL, panelId, selectActionIdentifier, selectedIds });

export const ADD_SELECTION_CONFIGURATION = "ADD_SELECTION_CONFIGURATION";
export const addSelectionConfiguration = (
    panelId: string, selectionConfig?: selectionConfigState
) => ({ type: ADD_SELECTION_CONFIGURATION, panelId, selectionConfig });