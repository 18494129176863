import {
  FieldMetaGroup,
} from "components/Common/Interfaces/Entity.interface";
import { ColumnPropGroup } from "components/ContractInFocus/Interfaces/DataGridColumn.interface";
import { adminPanelType } from "../../Models/AdminPanel.model";
import { FormItem, FormItemKey, FormItemKeyGroup, GroupedFormItems } from "interfaces/form.interface";
import { dictToArray } from "services/API/API.helper";
import { store } from "store/store";
import { extractFormProps } from "helpers/Forms/form.helper";
import { AdminAction } from "interfaces/form.interface";
import { openPanel } from "components/AdminPanel/Helpers/AdminPanel.helper";
import { filterColumnProps } from "components/ContractInFocus/Interfaces/DataGridColumn.interface";


export const maintenanceReportCoverImage = {
  key: 'monthly_maintenance_report_cover_image',
  label: 'Report Cover'
}

export const linkContractGridActions = (
  unlinkCB?: (item: any) => void, skipContractViewIcon?: boolean, skipContractLinkIcon?: boolean
): AdminAction[] => {
  const openAction = {
    key: "open-contract",
    icon: "fas fa-toolbox",
    dataField: "id",
    onClick: (data: any) => {
      return openPanel({
        panelType: adminPanelType.contracts,
        expandRecordId: data['contract']
      })
    }
  };

  const theseActions: AdminAction[] = [];
  if (!skipContractViewIcon) {
    theseActions.push(openAction)
  }
  if (unlinkCB && !skipContractLinkIcon) {
    theseActions.push({
      key: "unlink-contract",
      icon: "fas fa-unlink",
      dataField: "id",
      onClick: unlinkCB,
    })
  }

  return theseActions;
};

export const getContractGridActions = (
  unlinkCB?: (item: any) => void
): AdminAction[] => {
  const openAction = {
    key: "open-contract",
    icon: "fas fa-toolbox",
    dataField: "id",
    onClick: (data: any) => openPanel({
      panelType: adminPanelType.contracts,
      expandRecordId: data['id']
    }),
  };

  return unlinkCB
    ? [
      openAction,
      {
        key: "unlink-contract",
        icon: "fas fa-unlink",
        dataField: "id",
        onClick: unlinkCB,
      },
    ]
    : [openAction];
};

export const contractDatagridColumns = (
  //meta: FieldMetaGroup
  includeInactive?: boolean
): ColumnPropGroup => ({
  contract_with_id: {
  },
  contract_number: {
    width: 0,
    gridModeInsert: true,
    gridModeRead: false,
    gridModeEdit: false,
  },
  contract_ref: {
    width: "7%",
    gridModeInsert: false,
    gridModeRead: true,
    gridModeEdit: true,
  },
  account_manager_id: {
    width: "14%",
  },
  business_unit_head_id: {
    width: "14%",
  },
  site_manager_id: {
    width: "14%",
  },
  contract_category: {
    width: 0,
    gridModeInsert: true,
    gridModeRead: false,
    gridModeEdit: false,
  },
  expose_aw_focal_point_documents: {
    width: 0,
    gridModeInsert: true,
    gridModeRead: false,
    gridModeEdit: true,
  },
  client_contact_id: {
    width: "14%",
    gridModeInsert: true,
    gridModeRead: true,
    gridModeEdit: true,
  },
  active: {
    gridModeInsert: !!includeInactive,
    gridModeRead: !!includeInactive,
    gridModeEdit: !!includeInactive,
  }

});

export const contractDatagridWithSiteColumns = (
  includeInactive?: boolean
  //meta: FieldMetaGroup
): ColumnPropGroup => {
  //meta.client_contact.required = true;
  return {
    site_id: {
      width: "16%",
      dataField: "site",
    },
    //...contractDatagridColumns(meta),
    ...contractDatagridColumns(includeInactive),

  }
};

export function getContractsFilterExpForGrid(meta: any, headerFilterPage: string) {
  const thisHeaderFilterPage = headerFilterPage === "default" ? "all" : headerFilterPage;
  //const relationRecordId = getRelationChoiceId(meta.contract_category.choices, thisHeaderFilterPage);
  const filterExp = (function getFilters() {
    switch (thisHeaderFilterPage) {
      case "all":
        return null
      default:
        //@ts-ignore
        return ["contract_category", "=", thisHeaderFilterPage]
    }
  })();
  return filterExp
}

export const unfilteredContractDatagridWithSiteColumns = (
  meta: FieldMetaGroup
): ColumnPropGroup => {
  //meta.client_contact.required = true;
  return {
    site_id: {
      width: "16%",
      dataField: "site",
    },
    //...contractDatagridColumns(meta),
    ...contractDatagridColumns(true),
  }
};

export const unfilteredContractDatagridColumns = (
  meta: FieldMetaGroup
): ColumnPropGroup => {
  return contractDatagridColumns(true);
};


export const ContractHASTaskSublistColumns = (
  meta: FieldMetaGroup
): ColumnPropGroup => ({
  task__name: {
    width: "40%",
    caption: "Task"
  },
  task_frequency: {
    width: "20%",
    caption: "Task Interval",
  },
  task__applicable_from: {
    width: "20%",
  },
  first_check_date_specified_as: {
    width: "20%",
  }
});

export const HASTaskContractSublistColumns = (
  meta: FieldMetaGroup
): ColumnPropGroup => ({
  site_name: {
    width: "30%",
  },
  contract__contract_ref: {
    width: "10%",
    caption: "Contract ref"
  },
  contract_with__name: {
    width: "30%",
    caption: "Contract with",
  },
  first_check_date_specified_as: {
    width: "30%",
  }
});

export const generalContractSublistColumns = (
  meta: FieldMetaGroup
): ColumnPropGroup => ({
  site_name: {
    width: "25%",
  },
  contract_ref: {
    width: "10%",
  },
  contract_with_name: {
    width: "25%",
  },
  // category: {
  //   width: "16%",
  //   gridModeInsert: true,
  //   gridModeRead: true,
  //   gridModeEdit: true,
  // },
  ops_data_access: {
    width: "20%",
    gridModeInsert: true,
    gridModeRead: true,
    gridModeEdit: true,
  },
  h_a_s_permit_access: {
    width: "20%",
    gridModeInsert: true,
    gridModeRead: true,
    gridModeEdit: true,
  },
});

export const newContract = {
  contract_number: null,
  contract_category: null,
  site: {
    site_image: {},
  },
  contract_with: null,
  account_manager: null,
  business_unit_head: null,
  site_manager: null,
  client_contact: null,
};

// Creates a JSON structure and returns by key based on the link
export const createLinkedContract = (link: string, linkId: number) => {
  switch (link) {
    case adminPanelType.sites:
      return { ...newContract, site: { id: linkId } };
    default:
      return { ...newContract };
  }
};

export const contractFormItems: (FormItemKey | FormItemKeyGroup)[] = [
  { key: "contract_category" },
  {
    key: "site_id",
    label: "Site",
    choices: dictToArray(store.getState().sites.lookup),
  },
  {
    key: "alias",
    label: "Contract Alias",
  },
  {
    key: "account_manager_id",
    label: "Account manager",
    //choices: dictToArray(store.getState().organisations.lookup),
  },
  {
    key: "business_unit_head_id",
    label: "Business unit head",
    //choices: dictToArray(store.getState().organisations.lookup),
  },
  {
    key: "site_manager_id",
    label: "Site manager",
    //choices: dictToArray(store.getState().organisations.lookup),
  },
  {
    key: "contract_with_id",
    label: "Contract with",
    choices: dictToArray(store.getState().organisations.lookup),
  },
  {
    key: "client_contact_id",
    label: "Client contact",
    //choices: dictToArray(store.getState().organisations.lookup),
  },
  {
    key: "contract_number",
    label: "Contract Number"
  },
  {
    key: "active",
    label: "Active",
  },
  // {
  //   key: "VisibilityOptions",
  //   caption: "Visibility Options",
  //   items: [
  //     {
  //       key: "expose_aw_focal_point_documents",
  //       label: "Expose Additional Works Focal Point Documents To Non Jaguar Staff"
  //     },
  //     {
  //       key: "expose_supplier_quotation_documents_by_default",
  //       label: "Expose Supplier Quotes To Non Jaguar Staff By Default"
  //     },
  //     {
  //       key: "include_h_a_s",
  //       label: "Include Health and Safety managment on this contract"
  //     },
  //     {
  //       key: "expose_h_a_s",
  //       label: "Expose Health and Safety managment to Non Jaguar Staff"
  //     }
  //   ]
  // },

];

export const getContractFormItems = (meta: FieldMetaGroup, insert: boolean, formItems: (FormItemKey | FormItemKeyGroup)[]): Array<FormItem | GroupedFormItems> => {
  const theseFormItems = !insert ? formItems.filter(
    item => (item as FormItemKey).key ? (item as FormItemKey).key !== 'contract_number' : true
  ) : formItems;

  // const flattenedList = theseFormItems.reduce((acc, currentValue) => {
  //   const fG = currentValue as FormItemKeyGroup;
  //   if (fG.items) {
  //     const newAcc = acc.concat(fG.items);
  //     return newAcc;
  //   }
  //   const iK = currentValue as FormItemKey;
  //   if (iK.key) {
  //     acc.push(iK)
  //     return acc;
  //   }
  //   return acc;
  // }, [] as Array<FormItemKey | FormItemKeyGroup>)

  const extractedContractFormItems = [...theseFormItems.map(
    item => extractFormProps(item as FormItemKey, meta)
  )
  ]
  //console.log('extractedContractFormItems: ', extractedContractFormItems);
  return extractedContractFormItems
};

export const contractFilteredDatagridColumns = (
  props: filterColumnProps, includeInactive?: boolean
) => {
  const allColumns = contractDatagridWithSiteColumns(includeInactive);
  return {
    tenancy: {
      caption: "Tenant Contracts",
      onClick: () => props.setHeaderFilterPage("tenancy"),
      columns: (meta: FieldMetaGroup): any => ({
        ...allColumns
      }),
    },
    base: {
      caption: "Base Contracts",
      onClick: () => props.setHeaderFilterPage("base"),
      columns: (meta: FieldMetaGroup): any => ({
        ...allColumns
      }),
    },
    default: {
      caption: "All",
      onClick: () => props.setHeaderFilterPage("default"),
      columns: (meta: FieldMetaGroup): any => ({
        ...allColumns
      }),
    },

  };
};

export const getContractFilteredDatagridColumns = (includeInactive?: boolean) => (props: filterColumnProps) => {
  return contractFilteredDatagridColumns(props, includeInactive);
}

const tset = getContractFilteredDatagridColumns(true);

