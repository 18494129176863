export const openReactiveColumns = [
  { name: "task_ref", width: "8%" },
  { name: "job_description", width: "28%" },
  { name: "location", width: "15%" },
  { name: "discipline", width: "15%" },
  { name: "due_by", width: "8%" },
  { name: "reason", width: "26%" }
]

export const openPortfolioReactiveColumns = [
  { name: "contract_ref", width: "6%", caption: 'Contract' },
  { name: "task_ref", width: "8%" },
  { name: "job_description", width: "26%" },
  { name: "location", width: "15%" },
  { name: "discipline", width: "13%" },
  { name: "due_by", width: "8%" },
  { name: "reason", width: "24%" }
]