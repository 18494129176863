import React from 'react';
import Button from '@material-ui/core/Button';
import { ContractMenu } from 'components/Layout/Contract/Containers/ContractSidebar/contractMenu.interfaces';

// Styles
import './reportVisibilityTab.scss';

type ReportVisibilityTabProps = {
  activeTabName: string;
  tabs: ContractMenu[];
  onTabClick: (tabName: string) => void;
}

export default ({
  activeTabName,
  tabs,
  onTabClick
}: ReportVisibilityTabProps) => {

  return (
    <div id="visibilityControlsTab" data-testid="visibility-controls-tab">
      {tabs.map(tabItem =>
        <TabItem
          key={tabItem.menu}
          name={tabItem.label.value}
          iconClassName={tabItem.icon}
          active={activeTabName === tabItem.menu}
          onClick={() => onTabClick(tabItem.menu)}
        />
      )}
    </div>
  );
}

// Tab Item
type TabItemProps = {
  active?: boolean;
  iconClassName?: string;
  name: string;
  onClick: () => void;
}

const TabItem = ({
  name,
  iconClassName = '',
  active = false,
  onClick
}: TabItemProps) => {

  const getActiveClass = (active: boolean): 'outlined' | 'text' => {
    return active ? 'outlined' : 'text';
  }

  return (
    <Button
      onClick={onClick}
      className="visibility-control__button"
      variant={getActiveClass(active)}
      color="primary"
    >
      <div>
        <em className={`${iconClassName} visibility-control__icon`} />
      </div>
      <div className="main-left-sidebar--icon-label fadeIn">
        {name}
      </div>
    </Button>
  );
}