import { Dictionary } from "../../Common/Interfaces/Entity.interface";
import { ReportSection, ReportSectionSubTitles } from "../interfaces/contractInFocus.interfaces";
import { store } from "store/store";
import { toPythonVarName } from "helpers/String/String.helper";

export type ReportSectionStructure = Dictionary<ReportSection>;

export const reportSubtitles = {
  "AllPPM": 'All PPM Tasks',
  "SLA": 'PPM Tasks Within SLA',
  "StatutoryPPM": 'Statutory PPM Tasks',
  "FabricPPM": 'Fabric PPM Tasks',
  "OpenPPM": 'Open PPMs',
  "AllAudits": "All Insurance Audit Inspections",
  "AllSpecialistVisits": "All Specialist PPM Tasks",
  "AllReactive": "All Reactive Tasks",
  "ReactiveSLA": 'Reactive Tasks Within SLA',
  "ReactiveByCategory": "Reactive Tasks by Category",
  "ReactiveByCategoryHours": "Hours Spent on Reactive Tasks by Category",
  "OpenReactive": 'Open Reactive',
  "GeneralMattersForDiscussionSubtitle": "Matters for Discussion",
  "TechnicalEvents": "Technical Events",
  "NearMissEvents": "Near Miss Events",
  "AccidentEvents": "Accident Events",
  "SubcontractorEvents": "Subcontractor Events",
  "PlantCondition": "Plant Condition",
  "PersonnelMatters": "Personnel Matters for Discussion",
  "Leave": "Leave",
  "Innovations": "Innovations",
  "AWNew": "New Works AW Quotations/Jobs",
  "AWReactive": "Reactive Works AW Quotations/Jobs",
  "AWPartComp": "Semi Comprehensive AW Quotations/Jobs",
  "AWUnfiltered": "Unfiltered AW Quotations/Jobs",
  "SpendContractSettings": "Contract Particulars",
  "SpendComprehensiveTotals": "Comprehensive - Totals",
  "SpendComprehensivePOs": "Comprehensive – Purchase Orders",
  "SpendComprehensivePaidTotals": "Comprehensive - Paid - Totals",
  "SpendComprehensivePaidPOs": "Comprehensive - Paid - Purchase Orders",
  "SpendComprehensiveNonPOTransactions": "Comprehensive – Non Purchase Order Transactions",
  "SpendConsumableTotals": "Consumable - Totals",
  "SpendConsumablePOs": "Consumable – Purchase Orders",
  "SpendConsumablePaidTotals": "Consumable - Paid - Totals",
  "SpendConsumablePaidPOs": "Consumable - Paid - Purchase Orders",
  "SpendConsumableNonPOTransactions": "Consumable – Non Purchase Order Transactions",
  "SpendManagedTotals": "Managed - Totals",
  "SpendManagedPOs": "Managed – Purchase Orders",
  "SpendManagedPaidTotals": "Managed - Paid - Totals",
  "SpendManagedPaidPOs": "Managed - Paid - Purchase Orders",
  "SpendManagedNonPOTransactions": "Managed – Non Purchase Order Transactions",
  "MeterReadingEvents": "Meter Reading Events",
  "ContractsGlossary": "Contracts Glossary",
  "ReportDetails": "Report Details"
}


const buildFullReportSectionStructure = (reportSectionStructure: ReportSectionStructure, appendices?: any[]): ReportSectionStructure => {
  // console.log('reportSectionStructure: ', reportSectionStructure);
  const state = store.getState()
  const focusedPortfolio = state.portfolioInFocus?.portfolio?.id;
  let changed = false;
  let combined: any = { ...reportSectionStructure };
  const meterStreamSubtitles: ReportSectionSubTitles[] = []
  if (state) {
    const focusedContractMeterStreams = state.meterStreams[state.contractInFocus.contract.id]
    // here we're going to lower case and add underscores to title
    focusedContractMeterStreams && !focusedPortfolio && focusedContractMeterStreams.data.map((stream: any) => {
      const metering = combined['Metering']
      const found = metering.subTitles.find((x: ReportSection) => x.name == stream.title)
      if (!found) {
        meterStreamSubtitles.push(
          {
            name: stream.title,
            visible: ['show_metering__' + toPythonVarName(stream.title) + '_graph', 'show_metering__' + toPythonVarName(stream.title) + '_table']
          })
        changed = true;
      }
    })
    if (changed && !focusedPortfolio) {
      const subtitles = [...meterStreamSubtitles, ...reportSectionStructure['Metering'].subTitles]
      combined = {
        ...reportSectionStructure,
        'Metering': {
          ...reportSectionStructure.Metering,
          subTitles: subtitles
        }
      }
    }

    if (appendices) {
      const appendixSubtitles: ReportSectionSubTitles[] = []
      appendices.map((appendix) => { appendixSubtitles.push({ name: appendix.title, visible: [toPythonVarName(appendix.title)] }) })
      combined = {
        ...combined,
        'Appendices': {
          name: 'Appendices',
          visible: [],
          subTitles: appendixSubtitles
        }
      }
    }

    if (focusedPortfolio) {
      combined = {
        ...combined,
        'ContractsGlossary': {
          name: 'Contracts Glossary',
          visible: [toPythonVarName('Contracts Glossary')],
          subTitles: []
        }
      }
    }

    combined = {
      ...combined,
      'ReportDetails': {
        name: 'Report Details',
        visible: [toPythonVarName('Report Details')],
        subTitles: []
        //subTitles: [{ name: 'Report Details', visible: [toPythonVarName('Report Details')] }]
      }
    }
    //console.log('combined Structure: ', combined);
  }
  // console.log('combined Structure: ', combined);
  if (focusedPortfolio) {
    const { Metering, ...newCombined } = combined; // we have to explicitly remove this section as it should never show at the moment in the portfolio context
    return newCombined;
  }
  return combined;
  //return reportSectionStructure;
}

export const reportSectionStructure: ReportSectionStructure = {
  PPM: {
    name: 'PPM Statistics',
    heading: 'Planned Task Statistics',
    visible: [],
    subTitles: [
      { name: reportSubtitles.AllPPM, visible: ['show_ppm_total_graph', 'show_ppm_total_table'] },
      { name: reportSubtitles.SLA, visible: ['show_ppm_sla_graph', 'show_ppm_sla_table'] },
      { name: reportSubtitles.StatutoryPPM, visible: ['show_ppm_statutory_graph', 'show_ppm_statutory_table'] },
      { name: reportSubtitles.FabricPPM, visible: ['show_ppm_fabric_graph', 'show_ppm_fabric_table'] },
      { name: reportSubtitles.OpenPPM, visible: ['show_ppm_open_table'] }
    ]
  },
  Reactive: {
    name: 'Reactive Statistics',
    heading: 'Reactive',
    visible: [],
    subTitles: [
      { name: reportSubtitles.AllReactive, visible: ['show_rm_total_graph', 'show_rm_total_table'] },
      { name: reportSubtitles.ReactiveSLA, visible: ['show_rm_sla_graph', 'show_rm_sla_table'] },
      { name: reportSubtitles.ReactiveByCategory, visible: ['show_rm_total_issues_graph', 'show_rm_total_issues_table'] },
      { name: reportSubtitles.ReactiveByCategoryHours, visible: ['show_rm_total_issue_hours_graph', 'show_rm_total_issue_hours_table'] },
      { name: reportSubtitles.OpenReactive, visible: ['show_rm_open_table'] }
    ]
  },
  Specialist: {
    name: 'Specialist Visit Statistics',
    heading: 'Specialist Visits',
    visible: [],
    subTitles: [
      { name: reportSubtitles.AllSpecialistVisits, visible: ['show_specialist_table'] },
    ]
  },
  Events: {
    name: 'Journal of Events or Incidents and Matters for Discussion',
    heading: 'Event Journal',
    visible: [],
    subTitles: [
      { name: reportSubtitles.GeneralMattersForDiscussionSubtitle, visible: ['show_matters_for_discussion_table'] },
      { name: reportSubtitles.TechnicalEvents, visible: ['show_technical_event_table'] },
      { name: reportSubtitles.NearMissEvents, visible: ['show_near_miss_event_log_table'] },
      { name: reportSubtitles.AccidentEvents, visible: ['show_accident_event_log_table'] },
      { name: reportSubtitles.SubcontractorEvents, visible: ['show_subcontractor_event_table'] },
      { name: reportSubtitles.PlantCondition, visible: ['show_plant_condition_table'] },
      { name: reportSubtitles.PersonnelMatters, visible: ['show_personnel_matter_table'] },
      { name: reportSubtitles.Leave, visible: ['show_leave_table'] },
      { name: reportSubtitles.Innovations, visible: ['show_innovation_table'] },
    ]
  },
  Financial: {
    name: 'Financial Reports',
    heading: 'Financial',
    visible: [],
    subTitles: [
      {
        name: reportSubtitles.AWNew, visible: [
          'show_new_works_financials_table'
        ]
      },
      {
        name: reportSubtitles.AWReactive, visible: [
          'show_reactive_works_financials_table'
        ]
      },
      {
        name: reportSubtitles.AWPartComp, visible: [
          'show_part_comp_repair_financials_table'
        ]
      },
      {
        name: reportSubtitles.AWUnfiltered, visible: [
          //'show_unfiltered_financials_table' // NB this isn't printed
        ]
      }
    ]
  },
  Audit: {
    name: 'Insurance & Audit Inspections',
    heading: 'Insurance Audit Inspections',
    visible: [],//['show_audit_table'],
    subTitles: [
      { name: reportSubtitles.AllAudits, visible: ['show_audit_table'] },
    ]
  },
  SpendComprehensive: {
    name: 'Comprehensive Spend',
    heading: 'Comprehensive Spend',
    visible: [],
    subTitles: [
      { name: reportSubtitles.SpendComprehensiveTotals, visible: ['show_comprehensive_monthly_combined_aggregate_table'] },
      { name: reportSubtitles.SpendComprehensivePOs, visible: ['show_comprehensive_po_committed_spend_table'] },
      { name: reportSubtitles.SpendComprehensivePaidTotals, visible: ['show_comprehensive_monthly_actual_aggregate_table'] },
      { name: reportSubtitles.SpendComprehensivePaidPOs, visible: ['show_comprehensive_po_actual_spend_table'] },
      { name: reportSubtitles.SpendComprehensiveNonPOTransactions, visible: ['show_comprehensive_individual_spend_table'] },
    ]
  },
  SpendConsumable: {
    name: 'Consumable Spend',
    heading: 'Consumable Spend',
    visible: [],
    subTitles: [
      { name: reportSubtitles.SpendContractSettings, visible: ['show_contract_spend_settings'] },
      { name: reportSubtitles.SpendConsumableTotals, visible: ['show_consumable_monthly_combined_aggregate_table'] },
      { name: reportSubtitles.SpendConsumablePOs, visible: ['show_consumable_po_committed_spend_table'] },
      { name: reportSubtitles.SpendConsumablePaidTotals, visible: ['show_consumable_monthly_actual_aggregate_table'] },
      { name: reportSubtitles.SpendConsumablePaidPOs, visible: ['show_consumable_po_actual_spend_table'] },
      { name: reportSubtitles.SpendConsumableNonPOTransactions, visible: ['show_consumable_individual_spend_table'] },
    ]
  },
  SpendManaged: {
    name: 'Managed Spend',
    heading: 'Managed Spend',
    visible: [],
    subTitles: [
      { name: reportSubtitles.SpendManagedTotals, visible: ['show_managed_monthly_combined_aggregate_table'] },
      { name: reportSubtitles.SpendManagedPOs, visible: ['show_managed_po_committed_spend_table'] },
      { name: reportSubtitles.SpendManagedPaidTotals, visible: ['show_managed_monthly_actual_aggregate_table'] },
      { name: reportSubtitles.SpendManagedPaidPOs, visible: ['show_managed_po_actual_spend_table'] },
      { name: reportSubtitles.SpendManagedNonPOTransactions, visible: ['show_managed_individual_spend_table'] },
    ]
  },
  Metering: {
    name: 'Metering',
    heading: 'Metering',
    visible: [],
    subTitles: [{ name: reportSubtitles.MeterReadingEvents, visible: ['show_meter_reading_events_table'] }]
  },
  // MeterReadingEvents: {
  //   name: 'Meter Reading Events',
  //   heading: 'Meter Reading Events',
  //   visible: ['show_meter_reading_events_table'],
  //   subTitles: []
  // }
};

export const getFullReportSectionStructure = (appendices?: any[]) => {
  const fullStructure = buildFullReportSectionStructure(reportSectionStructure, appendices);
  return fullStructure
}

export const reportSectionsList = Object.keys(reportSectionStructure).map((key) => reportSectionStructure[key].name);
//  ['PPM', 'Reactive', 'Specialist', 'Events', 'Financial', 'Audit', 'Spend', 'Electric', 'Gas', 'Water'];