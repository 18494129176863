import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataGrid, { Column, Paging, Editing, Form, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid';
import MatConfirmationDialog from 'components/Common/Components/Material/MatConfirmationDialog/MatConfirmationDialog';
import {
    DataGridEditEvent,
} from "interfaces/datagrid.interfaces";
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { internalMemberSelector } from 'components/Profile/Selectors/Profile.selector';

// Own
import { getDocumentsApi, deleteDocumentApi, updateDocumentApi } from "store/Common/Helpers/commonHelpers";
import { DocumentsMetaInfo } from "components/Common/Interfaces/Entity.interface";
import { ColumnsConfig } from "components/ContractInFocus/Interfaces/DataGridColumn.interface";

// Styles
import { getColumnPropsIfFoundInMeta } from 'helpers/DataGrid/DataGridColumn.helper';
import { FileTypeIcon, FileSourceIcon } from 'components/Common/Components/FileTypeIcon/FileTypeIcon';

export const getGeneralDocsColumnConfig = () => {
    return {
        'tgd_datetime': {
            caption: 'Created On',
            dataType: 'date',
            format: 'd MMM yyyy'
        },
        'fp_orig_file_title': {
            caption: 'Title',
            filter: false,
        },

        'internal_access_only': {
            caption: 'Internal',
            allowEditing: true,
        },
        'display_type': {
            caption: 'Type'
        },
        // 'description': {
        //     caption: 'Description'
        // }
    }
}

interface overRideGetDocumentProps {
    setMetaInfo: React.Dispatch<DocumentsMetaInfo>;
    setResponse?: React.Dispatch<any>;
    recentAction?: string;
    defaultLoad: () => Promise<any>
}


export interface GeneralDocsGridProps {
    listBasePath: string;
    columnsConfig: ColumnsConfig;
    hideSearch?: boolean;
    hideFilters?: boolean;
    docTypeOverride?: string;
    orderable?: boolean;
    handleOnReorder?: (e: any, callback: () => void) => void;
    refreshSignal?: any;
    hideSource?: boolean;
    hideOnEmpty?: boolean;
    surrogate_portfolio_id?: string | number; // this is used in situations where we only want the documents for this contract, 
    // but we want the permissions evaluated against the portfolio
    fetchFilters?: {
        [name: string]: string;
    };
    actionColumnsWidth?: string | number;
    sourceColumnsWidth?: string | number;
    editMode?: 'batch' | 'cell' | 'row' | 'form' | 'popup';
    updateRecordMetaBeforeEdit?: boolean;
    setResponse?: React.Dispatch<any>;
    overRideGetDocuments?: ({ setMetaInfo, setResponse, recentAction, defaultLoad }: overRideGetDocumentProps) => Promise<any> | any[];
    overRideUpdateDocuments?: (id: string, values: any) => Promise<any>;
    overRideDeleteDocument?: ((key: any, surrogatePortfolioParams?: any) => Promise<void>) | undefined;
    dispatchRefreshContext?: React.DispatchWithoutAction | undefined;
    checkBeforeEditingMsg?: string;
    checkBeforeDeletingMsg?: string;
}

const DocumentsGrid = (
    {
        listBasePath,
        columnsConfig,
        docTypeOverride,
        hideSource,
        orderable,
        handleOnReorder,
        hideOnEmpty,
        hideSearch,
        hideFilters,
        surrogate_portfolio_id,
        fetchFilters,
        refreshSignal,
        actionColumnsWidth,
        sourceColumnsWidth,
        editMode,
        updateRecordMetaBeforeEdit,
        setResponse,
        overRideGetDocuments,
        overRideUpdateDocuments,
        overRideDeleteDocument,
        dispatchRefreshContext,
        checkBeforeEditingMsg,
        checkBeforeDeletingMsg
    }: GeneralDocsGridProps) => {

    const basePath = listBasePath;
    const [metaInfo, setMetaInfo] = useState<DocumentsMetaInfo>();
    const [datasource, setDatasource] = useState();
    const recentActionsRef = useRef(''); // this is used to pass which action has just taken place to the overRideGetDocuments, so it can 
    // make decisions based on that context, e.g. not getting the documents list from some existing lookup
    // but refreshing from the backend or perhaps delegating to the defaultLoad method
    const gridRef = useRef<any>(null);
    const internalMember = useSelector(internalMemberSelector);

    let shouldShowInternalAccess = false;

    let checkMeta = metaInfo?.putMeta || metaInfo?.meta;

    // undefined counts as false, internal member overrides read only because in some contexts internal access only might not be editable
    // but should be visible for internal staff.
    shouldShowInternalAccess = !!(checkMeta?.internal_access_only && (internalMember || !checkMeta?.internal_access_only?.read_only));

    const handleOnCellPrepared = useCallback((e: any) => {
        if (e.rowType === 'data' && e.column.command === 'edit')
            if (e.row.data.fp_file_id != null) {
                e.cellElement.find('a.dx-link-delete').remove();
            }
    }, []);

    const updateMeta = useCallback((e: DataGridEditEvent) => {
        if (updateRecordMetaBeforeEdit && metaInfo?.misc?.forRecord !== e.data.id) {
            getDocumentsApi(`${basePath}${e.data.id}/`, setMetaInfo, undefined, e.data.id);
        }
    }, [basePath, metaInfo?.misc?.forRecord, updateRecordMetaBeforeEdit]);

    const removeRecord = useCallback((recordId: string, surrogatePortfolioParams: any) => {
        if (overRideDeleteDocument) {
            return overRideDeleteDocument(recordId, surrogatePortfolioParams).then(
                () => gridRef.current?.instance && gridRef.current.instance.refresh()
            )
        } else {
            const deleteResponse = deleteDocumentApi(basePath, recordId, surrogatePortfolioParams).then(
                () => {
                    if (recentActionsRef) {
                        recentActionsRef.current = "recordDeleted";
                        dispatchRefreshContext && dispatchRefreshContext();
                    }
                    gridRef.current?.instance && gridRef.current.instance.refresh()
                }
            );
            return deleteResponse;
        }
    }, [basePath, overRideDeleteDocument, dispatchRefreshContext, gridRef]);

    const setUpDeleteConfirmation = useCallback((recordId: string, surrogatePortfolioParams: any): void => {
        checkBeforeDeletingMsg && setConfirmationDialog({
            open: true,
            agreeCallback: () => {
                setAgreeToCheckBeforeChange(true);
                setConfirmationDialog(confirmationInitialState.current);
                removeRecord(recordId, surrogatePortfolioParams);
            },
            message: checkBeforeDeletingMsg,
        });
    }, [checkBeforeDeletingMsg, removeRecord]);

    useEffect(() => {
        let fetchParams: any = fetchFilters || {};
        let surrogatePortfolioParams = {}
        if (surrogate_portfolio_id) {
            surrogatePortfolioParams = {
                surrogate_portfolio: surrogate_portfolio_id
            }
            fetchParams = { ...fetchFilters, ...surrogatePortfolioParams }
        }

        const custom = new CustomStore({
            key: "id",
            load: loadOptions => {
                let docs
                const defaultLoad = () => getDocumentsApi(basePath, setMetaInfo, fetchParams, undefined, setResponse);
                if (overRideGetDocuments) {
                    let r = overRideGetDocuments({ setMetaInfo, setResponse, recentAction: recentActionsRef.current, defaultLoad });
                    //defaultLoad is useful in case the overRideGetDocuments wants to merely delegate to the defaultLoad method in
                    // particular contexts
                    recentActionsRef.current = "";
                    return r;
                } else {
                    recentActionsRef.current = "";
                    return defaultLoad();
                }
            },
            // @ts-ignore
            // remove: id => {
            //     if (overRideDeleteDocument) {
            //         return overRideDeleteDocument(id, surrogatePortfolioParams)
            //     } else {
            //         const deleteResponse = deleteDocumentApi(basePath, id, surrogatePortfolioParams).then(
            //             () => {
            //                 if (recentActionsRef) {
            //                     recentActionsRef.current = "recordDeleted";
            //                     dispatchRefreshContext && dispatchRefreshContext();
            //                 }
            //             }
            //         );
            //         return deleteResponse;
            //     }
            // },
            remove: id => {
                if (!checkBeforeDeletingMsg || agreeToCheckBeforeChange) {
                    removeRecord(id, surrogatePortfolioParams);
                } else {
                    setUpDeleteConfirmation(id, surrogatePortfolioParams);
                }
            },
            update: (id, values) => {
                const r = overRideUpdateDocuments ? overRideUpdateDocuments(id, { ...values, ...surrogatePortfolioParams }) : updateDocumentApi(basePath, id, { ...values, ...surrogatePortfolioParams })
                recentActionsRef.current = "recordUpdated";
                dispatchRefreshContext && dispatchRefreshContext();
                return r;
            }
        });

        setDatasource(
            // @ts-ignore
            new DataSource({
                store: custom
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [basePath, surrogate_portfolio_id, fetchFilters]);

    useEffect(() => {
        if (refreshSignal > 0) {
            setTimeout(() => {
                // NB this timeout is just for aesthetic reasons (to allow the upload animation to complete first) 
                // - the new document will be there even if the datasource were to be loaded outside of this timeout
                //@ts-ignore
                datasource && datasource.reload();
                // NB never call dispatchRefreshContext in here as it will lead to a loop.
            }, 2500)
        }
    }, [refreshSignal, datasource]);



    const cellRenderFileType = useCallback((row: any) => <FileTypeIcon fileType={row.data.mime_type} />, []);

    const cellRenderFileSource = useCallback((row: any) => <FileSourceIcon fileSource={row.data.source} />, []);

    const handleDownload = useCallback((component: any) => {
        window.open(component.row.data.download_link);
    }, [])

    const localHandleOnReorder = useCallback((e: any) => {
        handleOnReorder && handleOnReorder(e, () => {
            //@ts-ignore
            datasource && datasource.reload();
        });

    }, [datasource, handleOnReorder]);

    const handleOpen = useCallback((component: any) => {
        window.open(component.row.data.file, '_blank');
    }, []);

    const hasMeta = useCallback(() => metaInfo?.meta && !!Object.keys(metaInfo?.meta).length, [metaInfo?.meta]);

    const docsPresent = useCallback(() => metaInfo && metaInfo.misc?.numDocs && metaInfo.misc.numDocs > 0, [metaInfo]);

    const confirmationInitialState = useRef({ open: false, agreeCallback: () => { }, message: '' });

    const [confirmationDialog, setConfirmationDialog] = useState(confirmationInitialState.current);

    const [agreeToCheckBeforeChange, setAgreeToCheckBeforeChange] = useState(false);

    const setUpEditConfirmation = useCallback((recordId?: string): void => {
        checkBeforeEditingMsg && setConfirmationDialog({
            open: true,
            agreeCallback: () => {
                setAgreeToCheckBeforeChange(true);
                setConfirmationDialog(confirmationInitialState.current);
            },
            message: checkBeforeEditingMsg,
        });
    }, [checkBeforeEditingMsg]);

    const editAndDeleteActions = [];//canWrite ? ['edit', 'delete'] : [];
    const canPut = metaInfo?.permissions?.PUT;
    const canPost = metaInfo?.permissions?.POST;
    const canDelete = metaInfo?.permissions?.DELETE;
    canPut && editAndDeleteActions.push('edit');
    canDelete && editAndDeleteActions.push('delete');
    canPost && editAndDeleteActions.push('create');

    const thisEditMode = editMode || "cell"

    const handleEditingStart = useCallback((e: any) => {
        if (!agreeToCheckBeforeChange && checkBeforeEditingMsg) {
            setUpEditConfirmation();
        } else {
            updateMeta(e);
            if (thisEditMode === "cell") {
                const field = e.column.dataField as string;
                e.cancel = columnsConfig[field]?.allowEditing !== undefined ? !columnsConfig[field].allowEditing : metaInfo?.meta[field]?.read_only;
            }
        }

    }, [columnsConfig, metaInfo?.meta, thisEditMode, updateMeta, agreeToCheckBeforeChange, setUpEditConfirmation, checkBeforeEditingMsg]);
    //return 

    return <div>
        <MatConfirmationDialog
            onAgree={confirmationDialog.agreeCallback}
            onDisagree={() => {
                setConfirmationDialog(confirmationInitialState.current);
                //@ts-ignore
                gridRef.current.instance.cancelEditData();
            }}
            open={confirmationDialog.open}
            actions={{ agree: 'Yes', disagree: 'No' }}
        >
            {confirmationDialog.message}
        </MatConfirmationDialog>
        <DataGrid className={docsPresent() || !hideOnEmpty ? "generalDocsContainer" : "generalDocsContainer hide"} //dx-datagrid-jt"
            ref={gridRef}
            dataSource={datasource}
            columnAutoWidth={true}
            showBorders={true}
            columnHidingEnabled={true}
            height="100%"//{canWrite ? 320 : '100%'}
            rowAlternationEnabled={true}
            onEditingStart={handleEditingStart}
            onCellPrepared={handleOnCellPrepared}
            rowDragging={
                {
                    allowReordering: orderable,
                    onReorder: localHandleOnReorder
                }
            }
        >
            <Paging enabled={false} />
            <SearchPanel
                visible={!hideSearch}
                placeholder="Search..."
                searchVisibleColumnsOnly={true}
            />
            <HeaderFilter visible={!hideFilters} />
            <Editing
                mode={thisEditMode}
                allowUpdating={canPut}
                allowDeleting={canDelete}
            />
            {hasMeta()
                ?
                Object.keys(columnsConfig).map((dataField) => {
                    const columnConfig = columnsConfig[dataField];
                    // note columnConfig overrides Meta here...
                    const columnProps = metaInfo ? { ...getColumnPropsIfFoundInMeta(dataField, metaInfo?.putMeta || metaInfo?.meta), ...columnConfig } : {};
                    return (columnProps && <Column
                        key={dataField}
                        {...columnProps}
                        visible={columnConfig.visible === false ? false : dataField !== 'internal_access_only' || shouldShowInternalAccess}
                        width={columnConfig.width}
                        caption={columnConfig.caption ? columnConfig.caption : columnProps.caption}
                    />)
                }
                )
                : null}

            <Column
                visible={hasMeta()}
                caption="Type"
                dataField="mime_type"
                cellRender={cellRenderFileType}
                alignment="center"
                width={'60px'}
            />
            {!hideSource && <Column
                visible={hasMeta()}
                caption="Source"
                dataField="source"
                cellRender={cellRenderFileSource}
                alignment="center"
                width={sourceColumnsWidth || 80}
            />}
            <Column
                alignment="left"
                type="buttons"
                width={actionColumnsWidth || 140}
                visible={hasMeta()}
                buttons={[{
                    hint: 'Download',
                    icon: 'download',
                    onClick: (component: any) => handleDownload(component)
                }, {
                    hint: 'Open',
                    icon: 'fa fa-eye',
                    onClick: (component: any) => handleOpen(component)
                }, ...editAndDeleteActions]} />
        </DataGrid>
    </div>
}
//DocumentsGrid.whyDidYouRender = true;

export default DocumentsGrid;