import React, { useState, useEffect } from 'react';

// Own
import { quoteItemColumns } from 'components/ContractInFocus/Financials/Models/financialsColumn';
import { UsefulDXListWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { sumObProps, toPounds } from "store/Common/Helpers/commonHelper.js";
import financialsService from "components/ContractInFocus/Financials/Services/financialServices";

interface QuoteItemProps {
    aw_id: string;
    portfolio_id?: string | number;
}

const QuoteItemList = ({ aw_id, portfolio_id }: QuoteItemProps) => {

    const [quoteItems, setQuoteItems] = useState<any>();

    const totalCharge = quoteItems ? sumObProps(quoteItems?.data, 'total') : 0;
    const totalChargeCurr = toPounds(totalCharge);
    const hasDataAndMeta = (): boolean => quoteItems?.meta && !!Object.keys(quoteItems.meta).length;

    useEffect(() => {
        if (!quoteItems) {
            financialsService.getAWQuoteItemsForAW(aw_id, portfolio_id).then(
                (quoteItems) => {
                    setQuoteItems(quoteItems);
                }
            );
        }
    }, [quoteItems, aw_id, portfolio_id]);//clientReponseValueRef.current, clientResponseValue


    return (
        <UsefulDXListWrapper>
            {quoteItems && <table>
                <thead>
                    <tr>{hasDataAndMeta() && quoteItemColumns.map((column) =>
                        <th key={quoteItems.meta[column.dataField].label}
                            //@ts-ignore
                            className={column.className ? column.className : undefined}
                            //@ts-ignore
                            style={column.headerStyle}
                        >{column.header ? column.header : quoteItems.meta[column.dataField].label}</th>)}</tr>
                </thead>
                <tbody>
                    {quoteItems.data.map(
                        (row: any) => {
                            return <tr key={row["id"]}>
                                {
                                    quoteItemColumns.map(
                                        (column) =>
                                            <td key={column.dataField}
                                                className={column.currency ? "currency" : undefined}
                                                //@ts-ignore
                                                style={column.cellStyle ? column.cellStyle : undefined}>
                                                {
                                                    ["PUAW", "SCAW"].includes(row["cost_centre"]) && (column.header == "Hourly Rate" || column.header == "Hours") ? undefined :
                                                        !["PUAW", "SCAW"].includes(row["cost_centre"]) && column.header == "Supplier Cost" ? undefined :
                                                            column.currency ? (toPounds(row[column.dataField])) : (row[column.dataField])

                                                }
                                            </td>
                                    )
                                }
                            </tr>
                        }
                    )}
                    <tr><td colSpan={5} className="awDetailLabel total">Quote Total</td><td className="currency total">{totalChargeCurr}</td></tr>
                </tbody>
            </table>}
        </UsefulDXListWrapper>
    )
}

export default QuoteItemList //withResizeDetector(QuoteItemList);
