export const visitReportListColumns = [
  {
    dataField: 'visit_document_category',
    caption: 'Visit Document Type',
  },
  {
    dataField: 'notes',
    caption: 'Notes',
    align: "left",
    width: "50%"
  },
  {
    dataField: 'internal_access_only',
    width: 90,
    caption: 'Internal'
  },
  // {
  //   dataField: 'mime_type',
  //   width: 150,
  //   caption: 'Type'
  // }
];

export const visitReportColumns = {
  'visit_document_category': {
    caption: 'Visit Document Type',
    width: '200px',
  },
  'notes': {
    caption: 'Description',
    align: "left",
  },
  'permit': {
    align: "left",
    caption: "Permit",
    width: '200px',
  },
  'document_ref': {
    align: "left",
    width: "200px"
  },
  'internal_access_only': {
    width: '90px',
    caption: 'Internal'
  },
  // 'mime_type': {
  //   dataField: 'mime_type',
  //   width: 150,
  //   caption: 'Type'
  // }
};
