import styled from "styled-components";

export const HeaderLogoWrapper = styled.div`
  position: relative;
  cursor: pointer;
  img {
    position: absolute;
    left: -10px;
    height: 40px;
    width: auto;
  }
  .img-placeholder {
    width: 30px;
    padding-right: 3.2rem;
    height: 40px;
  }
`;