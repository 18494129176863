import { Organisation } from "../Interfaces/Organisation";
import { Dictionary } from "components/Common/Interfaces/Entity.interface";

export const getOrganisationLookup = (organisation: Organisation) => ({
  value: organisation.id,
  display_name: organisation.name
});

export const splitSingleOrganisationAndMembers = (organisation: Organisation) => {
  const { organisation_people, ...strippedOrganisation } = organisation;
  return {
    data: strippedOrganisation,
    members: organisation_people?.map(person => person.id)
  }
}

export const splitOrganisationsAndMembers = (organisations: Dictionary<Organisation>) =>
  Object.keys(organisations).reduce((acc, key) => {
    const { data, members } = splitSingleOrganisationAndMembers(organisations[key]);
    return {
      data: { ...acc.data, [key]: data },
      members: { ...acc.members, [key]: members }
    }
  }, {
    data: {},
    members: {}
  })

// export const splitOrganisationsAndMembers = (organisations: Dictionary<Organisation>) =>
//   Object.keys(organisations).reduce((acc, key) => {
//     const { organisation_people, ...organisation } = organisations[key];
//     return {
//       data: { ...acc.data, [key]: organisation },
//       members: { ...acc.members, [key]: organisation_people?.map(person => person.id) }
//     }
//   }, {
//     data: {},
//     members: {}
//   })