import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { isEqual } from "lodash";
import { useSelector } from 'react-redux';

// Own
import DocumentsGrid from "components/Common/Components/DocumentsGrid/DocumentsGrid";
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { PreFlightListInfo } from "components/Common/Interfaces/Entity.interface";
import DocumentsUploader from "components/Common/Components/DocumentsUploader/DocumentUploader";
import { getMattersForDiscussionDocumentsRoute, fetchMattersForDiscussionPreFlightInfo } from "components/ContractInFocus/HAS/MattersForDiscussionServices";
import { ContractDocumentFilesWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { GeneralDocsGridWrapper } from 'components/SiteDocuments/Components/ContractDocumentsViewGrid/ContractDocumentGridStyles';
import { profileOrganisationSelector } from 'components/Profile/Selectors/Profile.selector';
import { ColumnPropGroup } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';

export const eventDocumentColumns: ColumnPropGroup = {
    'notes': {
        caption: 'Description',
        alignment: 'start',
        width: '50%'
    },
    "matter_for_discussion_document_type": {}
};

interface HASEventDocsProps {
    data: any;
}

const HASEventDocs = ({ data }: HASEventDocsProps) => {

    const id: string = data.id;

    const basePath = getMattersForDiscussionDocumentsRoute(id);
    const [fetchFilters, setFetchFilters] = useState();
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 0);
    const [preFlightInfo, setPreFlightInfo] = useState<PreFlightListInfo>();
    const refresh = useCallback(forceUpdate, [forceUpdate])
    const selectProfileOrganisationFromState = useSelector(profileOrganisationSelector);


    const getFieldFormConfig = useCallback((): FieldsFormConfig => {
        return {
            'notes': {
                label: 'Description',
                forceRequired: true,
            },
            'matter_for_discussion_document_type': {},
            'event': {
                hidden: true,
                defaultValue: id
            },
            'author_organisation': {
                hidden: true,
                defaultValue: selectProfileOrganisationFromState
            }
        }
    }, [id, selectProfileOrganisationFromState]);

    const fieldsConfig = getFieldFormConfig();

    useEffect(() => {
        fetchMattersForDiscussionPreFlightInfo({ eventId: id }).then((newPreFlightInfo) => {
            if (!isEqual(preFlightInfo, newPreFlightInfo)) {
                setPreFlightInfo(newPreFlightInfo);
            }
        });
    }, [preFlightInfo, id]);

    return <GeneralDocsGridWrapper>
        {eventDocumentColumns && <ContractDocumentFilesWrapper height={!preFlightInfo?.canCreate ? 30 : 20}>
            <DocumentsGrid
                listBasePath={basePath}
                hideSearch
                hideFilters
                hideSource
                columnsConfig={eventDocumentColumns}
                fetchFilters={fetchFilters}
                editMode="row"
                refreshSignal={mustRefresh}
                actionColumnsWidth={100}
                updateRecordMetaBeforeEdit
            />
        </ContractDocumentFilesWrapper>}

        {preFlightInfo?.canCreate && basePath &&
            <DocumentsUploader
                urlContext={basePath}
                fieldConfigs={fieldsConfig}
                preFlightInfo={preFlightInfo}
                gridId="HASEventsDocumentUploader"
                reset={forceUpdate}
                zIndex={1300}
                inAppViewingLocationOverride="admin"
            />
        }
    </GeneralDocsGridWrapper>
}

export default HASEventDocs;