import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Paper } from "@material-ui/core";

// Own
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { LookupEntity, PreFlightListInfo, Dictionary } from 'components/Common/Interfaces/Entity.interface';
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import ActionForm from "components/Common/Components/GeneralActionForm/GeneralActionForm";
import * as inFocusSelector from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import { ContractPeriod, PortfolioPeriod } from "components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface";
import { ReactiveIssueCategoryOptions } from "components/ContractInFocus/Maintenance/Interfaces/reactiveByCategory.interfaces";

const getRowInitialisationFieldsConfig = (period: ContractPeriod | undefined): FieldsFormConfig => {
    return {
        'year': {
            metaOverride: {
                type: "choice",
                choices: period?.year_lookup
            },
            sideEffect: (values, fieldConfigs, onChangeFormValues, previousFormValues) => {
                let changed = false;
                let year = values['year']?.toString() as string | number;
                let oldYearValue;
                if (previousFormValues) {
                    oldYearValue = previousFormValues['year'];
                }
                if (!year) {
                    if (!fieldConfigs['month'].disabled) {
                        console.log('going to disable month field');
                        fieldConfigs['month'].disabled = true;
                        changed = true;
                    }
                } else {
                    if (fieldConfigs['month'].disabled) {
                        fieldConfigs['month'].disabled = false;
                        changed = true;
                    }
                }
                if (year && year !== oldYearValue) {
                    const monthChoices = period?.year_month_lookup[year]
                    fieldConfigs['month'].metaOverride = {
                        choices: monthChoices
                    }
                    changed = true;
                }
                return changed;
            }
        },
        'month': {
        },
        'value': {
            label: 'Value'
        },
    }
}

type AddReactiveByCategoryRowProps = {
    data: any;
    contract?: SiteContract;
    portfolio: HydratedPortfolio;
    preFlightGenerateReactiveCategoryInfo: PreFlightListInfo;
    handleAddCategory: () => void;
    addCategoryFormValues: React.MutableRefObject<{}>;
    onModalClose?: () => void;
    calculateAddableCategoriesForContract: (reactiveOptionsList: ReactiveIssueCategoryOptions[], thisData: any) => ReactiveIssueCategoryOptions[];
    calculateAddableCategoriesForPortfolio: (reactiveOptionsList: ReactiveIssueCategoryOptions[], portfolio: HydratedPortfolio, thisData: any) => Dictionary<ReactiveIssueCategoryOptions[]>;
    reactiveCategoryOptionsList: ReactiveIssueCategoryOptions[];
}

export const AddReactiveByCategoryRow: React.FunctionComponent<AddReactiveByCategoryRowProps> = ({
    data,
    contract,
    portfolio,
    preFlightGenerateReactiveCategoryInfo,
    handleAddCategory,
    addCategoryFormValues,
    onModalClose,
    calculateAddableCategoriesForContract,
    calculateAddableCategoriesForPortfolio,
    reactiveCategoryOptionsList
}) => {
    const dialogueTitle = `Add New Reactive By Category Row`;
    const [open, setOpen] = React.useState(true);
    const issueCategoryChoices: Dictionary<ReactiveIssueCategoryOptions[]> = calculateAddableCategoriesForPortfolio(reactiveCategoryOptionsList, portfolio, data);
    const action = useCallback(() => {
        setOpen(false);
        handleAddCategory();
        setTimeout(() => {
            //putting in short timeout just in case somehow this starts before handleAddCategory has sent payload
            addCategoryFormValues.current = {};
        }, 250)
    }, [handleAddCategory, addCategoryFormValues])
    let contractChoices: LookupEntity[] = [];
    let forMonthChoices: LookupEntity[] = [];
    const selectedFocusedPeriod = useSelector(inFocusSelector.contractOrPortfolioPeriodSelector({ portfolioId: portfolio?.id, contractId: contract?.id }));
    if (portfolio) {
        console.log('selectedFocusedPeriod: ', selectedFocusedPeriod);
        contractChoices = portfolio.contracts.map((contract: SiteContract) => ({ value: contract.id, display_name: contract.contract_ref }))
        forMonthChoices = selectedFocusedPeriod?.months.map((month: string) => ({ value: month, display_name: month })) || [];
    }

    const [rowInitialisationFieldsConfig, setRowInitialisationFieldsConfig] = useState({});
    useEffect(() => {
        setRowInitialisationFieldsConfig(getRowInitialisationFieldsConfig(selectedFocusedPeriod));
    }, [selectedFocusedPeriod])

    const [formChanged, setFormChanged] = useState(false);

    const addReactiveCategoryFieldsConfig: FieldsFormConfig = {
        'contract': {
            metaOverride: {
                type: "choice",
                choices: contractChoices.filter(x => Object.keys(issueCategoryChoices).find(cId => cId == x.value))
            },
            sideEffect: (values, fieldConfigs, onChangeFormValues, previousFormValues) => {
                let changed = false;
                let contractId = values['contract'] as string | number;
                let oldContractIdValue;
                if (previousFormValues) {
                    oldContractIdValue = previousFormValues['contract'];
                }
                if (!contractId) {
                    if (!fieldConfigs['issue_category'].disabled) {
                        fieldConfigs['issue_category'].disabled = true;
                        changed = true;
                    }
                } else {
                    if (fieldConfigs['issue_category'].disabled) {
                        fieldConfigs['issue_category'].disabled = false;
                        changed = true;
                    }
                }

                if (contractId !== oldContractIdValue) {
                    const contractObj = portfolio?.contracts.find(x => x.id === contractId);
                    let thisData = data;
                    if (contractObj) {
                        thisData = data.filter((x: any) => x.contract_ref === contractObj?.contract_ref)
                        console.log('thisData: ', thisData);
                        //@ts-ignore
                        let filteredChoices = issueCategoryChoices[contractId] || [];
                        filteredChoices = filteredChoices.map(x => ({ ...x, value: x.id }));
                        fieldConfigs['issue_category'].metaOverride = {
                            //@ts-ignore
                            choices: filteredChoices
                        }
                        changed = true;
                    }
                }
                return changed;
            }
        },
        'issue_category': {},
        'subdivision': {
            hidden: true,
            autoCalculator: () => 'total'
        }
    }

    const handleClose = () => {
        addCategoryFormValues.current = {};
        setOpen(false);
    }

    const generalLimit = preFlightGenerateReactiveCategoryInfo.itemsLimit?.all;
    const generalLimitExceeded = generalLimit ? generalLimit?.currentCount <= generalLimit.max : false;

    return (
        <Dialog
            id="reportDistributionListManager"
            open={open}
            onClose={handleClose}
            onExited={onModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
            fullWidth={true}
            disableEnforceFocus
            transitionDuration={{ appear: 0, enter: 1000, exit: 0 }}
        >
            <DialogTitle id="distributionListTitle">{dialogueTitle}</DialogTitle>
            <DialogContent
            //style={{ backgroundColor: styles.redLight }}
            >
                <Paper elevation={3}>
                    <ActionForm
                        formValues={addCategoryFormValues}
                        fieldConfigs={addReactiveCategoryFieldsConfig}
                        //setSaveReady={setReadyToCreate}
                        setGAFormChanged={setFormChanged}
                        caption="Choose the row identifiers..."
                        gridClass='reactiveCategoryRowIdentifierForm'
                        metaForForm={preFlightGenerateReactiveCategoryInfo.meta}
                    />
                    <ActionForm
                        formValues={addCategoryFormValues}
                        fieldConfigs={rowInitialisationFieldsConfig}
                        //setSaveReady={setReadyToCreate}
                        setGAFormChanged={setFormChanged}
                        caption="...and add one value to initialise the row."
                        gridClass='reactiveCategoryInitialValueForm'
                        metaForForm={preFlightGenerateReactiveCategoryInfo.meta}
                    />
                    <Button onClick={action}
                        color="secondary"
                        type="submit"
                        variant="contained"
                        disabled={!formChanged || !preFlightGenerateReactiveCategoryInfo.canCreate || generalLimitExceeded}
                        style={
                            {
                                float: "right",
                                marginTop: "1rem"
                            }
                        }>
                        Create
                    </Button>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>

    );
}