import { createSelector } from 'reselect';
import { NotificationState } from '../Reducers/Notification.reducer';
import { State } from '../../../store/interfaces/State';
import { dictToArray } from '../../../services/API/API.helper';

export const notificationsSelector = (state: State): NotificationState => state.notifications;

export const newNotificationsSelector = createSelector(
  notificationsSelector, (notifications: NotificationState) => 
  dictToArray(notifications.messages).filter(notification => notification.viewed === false)
);
