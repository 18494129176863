import React, { memo } from 'react'

import { SpecialistsLegend } from "components/Schedulers/Legend";
import { ColumnProps } from 'components/ContractInFocus/Interfaces/dataGridColumn.interface';

import './PlannerHead.scss';
import styles from '../../../../../styles/app/common/variables.scss';


interface PlannerHeadProps {
    columnMeta: ColumnProps[];
    contractReportContext?: boolean;
}
//backgroundColor: `${styles.brandGold}`
const PlannerHead: React.FunctionComponent<PlannerHeadProps> = ({ columnMeta, contractReportContext }) => {

    const columnWidthDistribution = 100 / (columnMeta.length - 2)

    return (
        <thead className={`specialist-planner__head ${contractReportContext ? 'reportContext' : ''}`}>
            <tr>
                <td className={`specialist-planner__legend ${contractReportContext ? 'reportContext' : ''}`} colSpan={columnMeta.length}>
                    <SpecialistsLegend />
                </td>
            </tr>
            <tr className="specialist-planner__columns">
                {columnMeta.map((field) => (
                    <td
                        style={{ minWidth: field.width, width: field.width || `${columnWidthDistribution}%`, fontSize: "0.75rem", textAlign: (field?.alignment || "left") }}
                        key={`head${field.caption}`}
                    >{field.caption}

                    </td>
                ))}
            </tr>
        </thead >
    )
}

export default memo(PlannerHead);