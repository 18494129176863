/*eslint-disable */
import { appReducer } from "./reducers";
import {
  CANCEL_DATA_SUBITEM,
  SET_SUBITEM_MODE,
  ADD_DATA_SUBITEM,
  ADD_SUBITEM,
  REMOVE_SUBITEM,
  REMOVE_DATA_SUBITEM,
  SET_SUBITEM_DATA_MODE,
  SET_SUBITEM_DATA,
  CANCEL_NEW_SUBITEM,
  SAVE_NEW_SUBITEM,
  NEW_SUBITEM
} from "../actions/branchSublist.actions";
import { mode } from "../../components/Common/Consts/DataMode";
import { getSublist, branchSubItemHelper } from "./reducer.helper";
import { isArray } from "jquery";

export const branchSublistReducer = (state: any, action: any) => {
  switch (action.type) {
    case ADD_DATA_SUBITEM.start: {
      const subList = getSublist(state, action.branch, action.recordId, action.sublistKey);

      const found = subList.find((item: any) => item.id === action.newSubEntity.id);

      if (found) {
        return appReducer(state, action)
      }

      const newSubItemList = [
        ...subList,
        action.newSubEntity
      ];

      return branchSubItemHelper(state, action, newSubItemList);
    }

    case ADD_SUBITEM.start: {
      // determin whether entityPayload is an array or an item
      const payloadArray = Array.isArray(action.entityPayload);
      const entityId = (payloadArray ? action.entityPayload[0].id : action.entityPayload.id);
      const subList = state[action.branch][action.leaf][action.recordId];
      const found = subList.find((item: any) => item.id === entityId );

      if (found) {
        return appReducer(state, action)
      }

      return {
        ...state,
        [action.branch]: {
          ...state[action.branch],
          [action.leaf]: {
            ...state[action.branch][action.leaf],
            [action.recordId]:
              // if the payload that you want to add is an array, then treat the state node as an array
              // otherwise set the leaf node to the payload
              payloadArray
                ? [...(state[action.branch][action.leaf][action.recordId] || []), ...action.entityPayload]
                : action.entityPayload
          }
        }
      }
    }

    case REMOVE_SUBITEM.start: {
      return {
        ...state,
        [action.branch]: {
          ...state[action.branch],
          [action.leaf]: {
            ...state[action.branch][action.leaf],
            [action.recordId]:
              // if this leaf node is an array, then filter the record otherwise wipe out the node
              Array.isArray(state[action.branch][action.leaf][action.recordId])
                ? state[action.branch][action.leaf][action.recordId].filter((item: any) => item.id !== action.entityId)
                : undefined
          }
        }
      }
    }

    case REMOVE_DATA_SUBITEM.start: {
      const newList = state[action.branch].data[action.recordId][action.sublistKey]
        .filter((record: any) => record.id !== action.subitemId);

      return branchSubItemHelper(state, action, newList);
    }

    case SET_SUBITEM_MODE.start: {
      const subList = getSublist(state, action.branch, action.recordId, action.sublistKey);

      const index = subList.findIndex(subItem => subItem.id === action.subItemId);

      const subItem = {
        ...subList[index],
        mode: action.mode,
        initialState: action.mode === mode.edit ? subList[index] : undefined
      };

      subList.splice(index, 1, subItem);

      return branchSubItemHelper(state, action, subList);
    }

    case SET_SUBITEM_DATA_MODE.start: {
      const subList = getSublist(state, action.branch, action.recordId, action.sublistKey);

      const index = subList.findIndex(subItem => subItem.id === action.subItemId);

      const subItem = {
        ...action.data,
        mode: action.mode
      };

      subList.splice(index, 1, subItem);

      return branchSubItemHelper(state, action, subList);
    }

    case SET_SUBITEM_DATA.start: {
      const subList = getSublist(state, action.branch, action.recordId, action.sublistKey);

      const recordIndex = subList.findIndex(
        record => record.id === action.data.id
      );

      subList.splice(recordIndex, 1, action.data);

      return branchSubItemHelper(state, action, subList);
    }

    case CANCEL_NEW_SUBITEM.start: {
      const subList = getSublist(state, action.branch, action.recordId, action.sublistKey);
      const index = subList.findIndex(subItem => subItem.mode === mode.new);
      subList.splice(index, 1); //impure
      return branchSubItemHelper(state, action, subList);
    }

    case NEW_SUBITEM.start: {
      const subList = getSublist(state, action.branch, action.recordId, action.sublistKey);
      const foundNewItem = subList.find((item: any) => item.mode === mode.new);
      if (foundNewItem) {
        return appReducer(state, action);
      }
      const newSublist = [...subList, { ...action.newItem, mode: mode.new, id: 'new' }];
      return branchSubItemHelper(state, action, newSublist);
    }

    case SAVE_NEW_SUBITEM.start: {
      const subList = getSublist(state, action.branch, action.recordId, action.sublistKey);
      const recordIndex = subList.findIndex(record => record.mode === mode.new);
      subList.splice(recordIndex, 1, action.newItem);
      return branchSubItemHelper(state, action, subList);
    }

    case CANCEL_DATA_SUBITEM.start: {
      const subItemList = [...state[action.branch].data[action.recordId][action.sublistKey]];
      const recordIndex = subItemList.findIndex(record => record.mode === "new");
      subItemList.splice(recordIndex, 1);

      return branchSubItemHelper(state, action, subItemList);
    }

    default:
      return appReducer(state, action);
  }
}
