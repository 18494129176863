import { pick, isEqual } from "lodash";

import {
    SET_PERSONAL_IN_FOCUS_SETTINGS
} from 'components/ContractInFocus/Actions/contractVisibilitySettings.actions';
import { NotificationPortfolioSettings, NotificationPortfolioSettingPropsArray } from "components/ContractInFocus/Interfaces/ContractInFocus.interfaces";

export type PortfolioNotificationSettingsState = {
    [idx: number]: NotificationPortfolioSettings;
};

const initialState = {
};

const personalPortfolioNotificationSettingsReducer = (state: PortfolioNotificationSettingsState = initialState, { type, payload }: { type: string, payload: any }) => {
    // we're using redux to split personal settings into relevant branches rather than merely grabbing the notification settings where we need them 
    // mainly because we're already requesting personal settings elsewhere and long term with more settings added it will reduce the number of requests
    switch (type) {

        case SET_PERSONAL_IN_FOCUS_SETTINGS.success: {
            const settings = payload.personalVisibilitySettings;
            const portfolio = payload.portfolio; // then it's relevant to this reducer
            if (portfolio) {
                const vizProps = pick(Array.isArray(settings) ? settings[0] : settings, NotificationPortfolioSettingPropsArray);
                // simple object, would be worth an isEqual comparison, but it's not invoked unless the contract changes and when it is
                // it currently always replaces the existing 'current' root object - it's not indexed by contract.  If we shift to indexing by contract, might 
                // be worth comparing...
                // simple object, worth the comparison as we may be triggering personal settings updates from other quarters.
                if (!isEqual(state[payload.id], vizProps)) {
                    return {
                        ...state,
                        [payload.id]: vizProps
                    };
                }
            }
            return state;
        }
        default:
            return state;
    }
};

export default personalPortfolioNotificationSettingsReducer;
