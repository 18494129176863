import React, { useState, useEffect, useCallback, useReducer } from "react";
import { useSelector } from "react-redux";
import { GeneralTooltip } from "components/Common/Components/InfoHint/InfoHint";
import moment from "moment";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

// Own
import API from "services/API/API";
import { getSupplierServicesRoute } from 'components/Schedulers/Services/visitService';
import withPageContext, { WithPageContextProps } from "../ContractInFocus/HoC/WithPageContext/WithPageContext";
import { reportSectionStructure, reportSubtitles } from "components/ContractInFocus/Models/contractIndex.model";
import { specialistRightVisibilityMenu } from "./Models/specialistRightVisibilityMenu";
// import * as organisationSelectors from "../AdminPanel/Organisation/Selectors/Organisations.selectors";
import { getSubTitleNumber, adjustSubTitles } from "components/ContractInFocus/ContractReport/Helpers/contractReportToc.helper";
import { tocsSelector } from "components/ContractInFocus/Selectors/visibility.selectors";
import { useQuery } from "store/Common/Helpers/commonHelper.js";
import { generateSpecialistPlannerPDF } from "components/Schedulers/Services/specialistPlanner.service";
import * as visibilitySelectors from 'components/ContractInFocus/Selectors/visibility.selectors';
import withPortfolioPageContext from "components/PortfolioInFocus/HOC/WithPortfolioPageContext/WithPortfolioPageContext";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import VisitModal from "components/Schedulers/VisitModal/VisitModal";
import { ArchiveControls, GetBaseSchedulerButtonCellTemplateProps } from "components/Schedulers/ScheduleDataGrid/Components/Buttons/SchedulerActionButtons";
import { internalMemberSelector } from 'components/Profile/Selectors/Profile.selector';
import Scheduler, { FilterControlProperties } from "components/Schedulers/Scheduler";
import { fetchSupplierServicesWithMonthVisits, fetchSupplierServicesWithMonthVisitsPreFlightInfo } from "components/Schedulers/Services/visitService";
import { generateColumnHeaders, generateColumnHeadersForPlannerView } from "components/Schedulers/Helpers/specialistMaintenanceColumns.helper";
import { SpecialistsLegend } from "components/Schedulers/Legend";
import { SpecialistVisitDataCellRender } from "components/Schedulers/ScheduleDataGrid/Components/Cell/CellDataGrid.js";
import * as contractInFocusSelectors from "components/ContractInFocus/Selectors/contractInFocus.selectors";
import { Snapshot } from "components/ContractInFocus/Interfaces/ContractInFocus.interfaces";

import "./ScheduleHeaderStyles.scss";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

export interface ArchiveArgs {
  data: any,
  portfolio?: HydratedPortfolio,
  contract?: SiteContract
}

const getSpecialistButtonCellTemplate = ({ showArchived, handleArchive, handleRestore, data }: GetBaseSchedulerButtonCellTemplateProps) => {
  const missingVisits = data.total_visits_missing;
  const excessVisits = data.total_excess_visits;
  // const multipleVisits = data.number_of_visits > 1;
  // const multipleActual = data.actual_visits_count > 1;
  // const noActualVisits = data.actual_visits_count === 0;
  //const missingVisitsMessage = `${data.number_of_visits} visit${multipleVisits ? 's have' : ' has'} been contracted and ${noActualVisits ? 'none have' : multipleActual ? 'only ' + data.actual_visits_count + ' have' : 'only ' + data.actual_visits_count + ' has'} been scheduled or completed within the duration of the current supplier contract`;
  return (
    <>
      {
        missingVisits > 0 && !showArchived &&
        <GeneralTooltip title={data.missing_visits_message}>
          <div className="visitsCountVarianceWrapper no-print">
            <div className="visitsCountVariance">
              <div className="inner">
                {missingVisits}
              </div>
            </div>
          </div>
        </GeneralTooltip>
      }
      {
        excessVisits > 0 && !showArchived &&
        <GeneralTooltip title={data.excess_visits_message}>
          <div className="visitsCountVarianceWrapper excess no-print">
            <div className="visitsCountVariance">
              <div className="inner">
                {excessVisits}
              </div>
            </div>
          </div>
        </GeneralTooltip>
      }
      <ArchiveControls
        data={data}
        showArchived={showArchived}
        handleArchive={handleArchive}
        handleRestore={handleRestore}
      />
    </>
  );
};

interface ScheduleControlProps {
  contractReportContext?: boolean;
  showPlannerView?: boolean,
  showArchived: boolean,
  setShowArchived: React.Dispatch<React.SetStateAction<boolean>>,
  selectedFocusedSnapshot?: Snapshot
  setFilterQueryParams?: React.Dispatch<React.SetStateAction<FilterControlProperties>>,
  filterQueryParams?: FilterControlProperties
}

const getControlSwitches = ({
  contractReportContext,
  showPlannerView,
  showArchived,
  setShowArchived,
  selectedFocusedSnapshot,
  filterQueryParams,
  setFilterQueryParams
}: ScheduleControlProps
) => {
  return () => <>
    {
      !selectedFocusedSnapshot && !showPlannerView && <FormControlLabel
        label="Archiving Mode"
        labelPlacement="start"
        className="show-archived-switch no-print"
        control={
          <Switch
            size="small"
            onChange={() => {
              setShowArchived(showArchived === false ? true : false);
            }}
            defaultChecked={showArchived}
          />
        }
      />}
    {
      !contractReportContext && <FormControlLabel
        label="Exceptions Only"
        labelPlacement="start"
        className="show-archived-switch no-print"
        control={
          <Switch
            size="small"
            onChange={() => {
              setFilterQueryParams && setFilterQueryParams({
                ...filterQueryParams,
                exceptions_only: !filterQueryParams?.exceptions_only ? 1 : 0
              });
            }}
            defaultChecked={!!filterQueryParams?.exceptions_only}
          />
        }
      />
    }
  </>
}

const today = new Date();

interface SpecialistMaintenanceProps extends Omit<WithPageContextProps, 'contract'> {
  contract?: SiteContract;
}

const SpecialistMaintenance = (props: SpecialistMaintenanceProps) => {

  let query = useQuery();
  const isPDFRequest = !!query.get('pdfView');
  const selectedPersonalContractSettings = useSelector(visibilitySelectors.personalInFocusVisibilitySettingsSelector);

  const [showPlannerView, setShowPlannerView] = useState(isPDFRequest);
  const [canShowPlannerView, setCanShowPlannerView] = useState(isPDFRequest);
  const plannerModeSetting = 'specialist_planner_mode'
  const [showArchived, setShowArchived] = useState(false);

  const exceptionsOnly = props.contractReportContext ? props.visibilitySettings.specialist_report_by_exception : props.defaultExceptionsOnly || false;

  const [filterQueryParams, setFilterQueryParams] = useState<FilterControlProperties>({
    'exceptions_only': exceptionsOnly ? 1 : 0
  })

  const [reload, forceReload] = useReducer((x) => x + 1, 0);

  const allSpecialistsTitle = reportSubtitles.AllSpecialistVisits;
  const thisSpecialistsTitle = adjustSubTitles(allSpecialistsTitle, props.visibilitySettings);
  const tocs = useSelector(tocsSelector);
  const [subTitleIndex, setSubTitleIndex] = useState<string>('');
  const visibleFixedColumns = ["contractor__name", "archived", "equipment_name"]; //switched 'contractor' for contractor__name for filter abilities

  const internalMember = useSelector(internalMemberSelector);

  const selectedFocusedSnapshot = useSelector(
    contractInFocusSelectors.contractOrPortfolioSnapshotSelector({ contractId: props.contract?.id, portfolioId: props.portfolio?.id })
  );

  // NOTE!! if the fetchInfo is not wrapped in useCallback it causes a rerender of the component before baseQuerysetParams is set in the 
  // scheduler, and this causes a request to be sent for the records with exceptions_only false when changed to true...
  // and because that query may return after the subsequent request for the records with exceptions_only set to true, 
  // the result set can be overridden.  We need a more robust way of changing the basequeryvalues, but not using state can result in 
  // looping.
  const fetchInfo = useCallback(({ args, query_params }: { args?: any, query_params?: any }) => {
    let config = {
      contract_ref: props.contract?.contract_ref,
      portfolioId: props.portfolio?.id,
      query_params: { exclude_too_few_visits: props.contractReportContext }
    }
    if (args) {
      config = {
        ...config,
        ...args
      }
    }
    if (query_params) {
      config.query_params = { ...config.query_params, ...query_params }
    }
    return fetchSupplierServicesWithMonthVisits(config);
  }, [props.contract, props.portfolio?.id, props.contractReportContext])

  // NOTE!! if the fetchPreflightInfo is not wrapped in useCallback it causes a rerender of the component before baseQuerysetParams is set in the 
  // scheduler, and this causes a request to be sent for the records with exceptions_only false when changed to true...
  // and because that query may return after the subsequent request for the records with exceptions_only set to true, 
  // the result set can be overridden.  We need a more robust way of changing the basequeryvalues, but not using state can result in 
  // looping.
  const fetchPreflightInfo = useCallback(({ args, query_params }: { args?: any, query_params?: any }) => {
    let config = {
      contract_ref: props.contract?.contract_ref,
      portfolioId: props.portfolio?.id,
      query_params: {}
    }
    if (args) {
      config = {
        ...args,
        ...config
      }
    }
    if (query_params) {
      config.query_params = { ...config.query_params, ...query_params }
    }
    return fetchSupplierServicesWithMonthVisitsPreFlightInfo(config);
  }, [props?.contract?.contract_ref, props.portfolio?.id])

  const addToCellClassName = (cellData: any) => {
    let contractEndDate;
    let className = '';
    if (cellData.column.caption?.toLowerCase().trim() === "contractor") {
      const lastContractEndDate = cellData?.data?.latest_contract_end_date;
      contractEndDate = moment(lastContractEndDate).toDate();
      if (contractEndDate && contractEndDate < today && internalMember) {
        className = `${className} OutOfContract`;
      }
    }
    return className;
  }

  const [clickableCaptionColumns, setClickableCaptionColumns] = useState(['contractor__name']);

  useEffect(() => {
    const thisSubTitleIndex = getSubTitleNumber(
      allSpecialistsTitle,
      tocs.flatTocsLookup
    )
    thisSubTitleIndex && setSubTitleIndex(thisSubTitleIndex);
  }, [tocs, allSpecialistsTitle])

  const reportSubTitle = `${subTitleIndex} ${thisSpecialistsTitle}`

  useEffect(() => {
    setShowPlannerView(!props.contractReportContext && (isPDFRequest || !!selectedPersonalContractSettings[plannerModeSetting]));
    setCanShowPlannerView(!props.contractReportContext)
  }, [selectedPersonalContractSettings, props.contractReportContext, isPDFRequest])

  const getRemoteTargetUrl = (viewFrom: number) => {
    const url = new URL(window.location.href);
    url.searchParams.append('pdfView', '1');
    url.searchParams.append('viewFrom', viewFrom.toString()); // this isn't designed for UI but to be picked up by pdfgenerator
    return url
  }

  const contextIdString = "MaintenanceVisits"

  const getHandleGeneratePDF = useCallback((config: any) => {
    return () => {
      generateSpecialistPlannerPDF(
        {
          payload: {
            token: config.cookies.get('token'),
            elementToBePrinted: `#schedulerView${contextIdString}`,
            remoteTargetUrl: getRemoteTargetUrl(config.viewFrom).href,
            contractRef: config.contract?.contract_ref,
            portfolioId: config.portfolio?.id,
            portfolioName: config.portfolio?.name,
            title: 'Jaguar Building Services Ltd Specialist Subcontractor Administration System',
            site: config.selectedSite?.data?.name || ''
          },
          setGeneratingPDF: config.setGeneratingPDF,
          setPDFBase64: config.setPDFBase64
        }
      );
    }
  }, [])

  const getSpecialistControlSwitches = useCallback(() => getControlSwitches({
    contractReportContext: props.contractReportContext,
    showPlannerView,
    showArchived,
    setShowArchived,
    selectedFocusedSnapshot,
    filterQueryParams,
    setFilterQueryParams
  }), [showPlannerView, showArchived, setShowArchived, selectedFocusedSnapshot, filterQueryParams, setFilterQueryParams, props.contractReportContext])

  const getButtonCellTemplate = useCallback((e) => {

    const onArchiving = () => {
      const baseRoute = getSupplierServicesRoute(props.contract?.contract_ref || e.data.contract__contract_ref);
      const route = `${baseRoute}${e.data.id}/`
      let payload: any = {
        archived: true
      }
      if (props.portfolio?.id) {
        payload = { ...payload, surrogate_portfolio: props.portfolio.id }
      }
      API.patch(route, payload).then((response: any) => {
        forceReload();
      })
    }

    const onRestoring = () => {
      const baseRoute = getSupplierServicesRoute(props.contract?.contract_ref || e.data.contract__contract_ref);
      const route = `${baseRoute}${e.data.id}/`
      let payload: any = {
        archived: false
      }
      if (props.portfolio?.id) {
        payload = { ...payload, surrogate_portfolio: props.portfolio.id }
      }
      API.patch(route, payload).then((response: any) => {
        forceReload();
      })
    }

    return getSpecialistButtonCellTemplate({
      showArchived,
      handleArchive: onArchiving,
      handleRestore: onRestoring,
      data: e.row.data
    })
  }, [showArchived, props.contract, props.portfolio])

  return <Scheduler
    contextIdString={contextIdString}
    title="Specialist Visits"
    DetailModal={VisitModal}
    showPlannerView={showPlannerView}
    canShowPlannerView={canShowPlannerView}
    isPDFRequest={isPDFRequest}
    getHandleGeneratePDF={getHandleGeneratePDF}
    plannerModeSetting={plannerModeSetting}
    reportSubTitle={reportSubTitle}
    show={props.visibilitySettings.show_specialist_table}
    reportSection={reportSectionStructure.Specialist}
    clickableCaptionColumns={clickableCaptionColumns}
    getButtonCellTemplate={getButtonCellTemplate}
    ControlSwitches={getSpecialistControlSwitches()}
    visibleFixedColumns={visibleFixedColumns}
    addToCellClassName={addToCellClassName}
    fetchInfo={fetchInfo}
    fetchPreflightInfo={fetchPreflightInfo}
    generateColumnHeaders={generateColumnHeaders}
    generateColumnHeadersForPlannerView={generateColumnHeadersForPlannerView}
    Legend={SpecialistsLegend}
    CellRender={SpecialistVisitDataCellRender}
    showArchived={showArchived}
    setShowArchived={setShowArchived}
    reload={reload}
    buttonCellWidth={45}
    contractRefDataField="contract__contract_ref"
    extraFilterQueryParams={filterQueryParams}
    pageSize={props.pageSize || 10}
    {...props}
  ></Scheduler>
}
export const HASCentreSpecialistMaintenance = SpecialistMaintenance;

export default withPageContext(SpecialistMaintenance, {
  visibilityMenu: specialistRightVisibilityMenu
});

export const PortfolioSpecialistMaintenance = withPortfolioPageContext(SpecialistMaintenance, {
  visibilityMenu: specialistRightVisibilityMenu
});
