import { FieldMetaGroup } from 'components/Common/Interfaces/Entity.interface';
import { ColumnPropGroup } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';

export const contractPeriodDatagridColumns = (meta: FieldMetaGroup): ColumnPropGroup => ({
  start_date: {
    dataField: "start_date",
  },
  end_date: {
    dataField: "end_date",
  }  
});
