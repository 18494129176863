import React, { memo, useRef, useCallback, useEffect, useState } from 'react';
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import getSubContractorEventLogs from "components/ContractInFocus/Logs/Components/SubcontractorEventLogs";
import getTechnicalEventLogs from "components/ContractInFocus/Logs/Components/TechnicalEventLogs";

import { PureAudit } from "components/ContractInFocus/Audit/Audit";
import { IconButton } from '@material-ui/core';
import { GeneralTooltip } from 'components/Common/Components/InfoHint/InfoHint';
import FullscreenExit from '@material-ui/icons/FullscreenExit';

import "./TaskDash.scss";

interface TaskDashProps {
    contract?: SiteContract;
    portfolio?: HydratedPortfolio;
}

export default function TaskDashView({
    contract, portfolio
}: TaskDashProps) {

    const ThisAudit = useCallback(() => <PureAudit contract={contract} skipImports />, [contract]);

    const SubContractorEventLogs = memo(getSubContractorEventLogs(contract, portfolio));
    const TechnicalEventLogs = memo(getTechnicalEventLogs(contract, portfolio));

    const [mouseMoveTimer, setMouseMoveTimer] = useState<number>();

    const handleMouseMoveOverQuadrant = (quadrantRef: React.MutableRefObject<any>) => {
        clearTimeout(mouseMoveTimer);
        setMouseMoveTimer(
            setTimeout(() => {
                handleQuadrantExpand(quadrantRef);
            }, 50)
        )
    }

    const firstQuadrantRef = useRef<any>(null);
    const secondQuadrantRef = useRef<any>(null);
    const thirdQuadrantRef = useRef<any>(null);
    const fourthQuadrantRef = useRef<any>(null);

    const firstQuadrantExpandFixed = useRef<boolean>(false);
    const secondQuadrantExpandFixed = useRef<boolean>(false);
    const thirdQuadrantExpandFixed = useRef<boolean>(false);
    const fourthQuadrantExpandFixed = useRef<boolean>(false);

    const unExpandedHeight = "42vh";
    const unExpandedWidth = "43vw";
    const expandedHeight = "90vh";
    const expandedWidth = "90vw";



    useEffect(() => {

        firstQuadrantRef.current.style.width = unExpandedWidth;
        firstQuadrantRef.current.style.height = unExpandedHeight;

        secondQuadrantRef.current.style.width = unExpandedWidth;
        secondQuadrantRef.current.style.height = unExpandedHeight;

        thirdQuadrantRef.current.style.width = unExpandedWidth;
        thirdQuadrantRef.current.style.height = unExpandedHeight;

        fourthQuadrantRef.current.style.width = unExpandedWidth;
        fourthQuadrantRef.current.style.height = unExpandedHeight;

    }, []);

    const handleQuadrantExpand = useCallback((quadrantRef: React.MutableRefObject<any>) => {
        if (!quadrantRef.current.hasLeft) {
            if (quadrantRef !== firstQuadrantRef) { firstQuadrantRef.current.style.zIndex = 0; }
            if (quadrantRef !== secondQuadrantRef) { secondQuadrantRef.current.style.zIndex = 0; }
            if (quadrantRef !== thirdQuadrantRef) { thirdQuadrantRef.current.style.zIndex = 0; }
            if (quadrantRef !== fourthQuadrantRef) { fourthQuadrantRef.current.style.zIndex = 0; }
            quadrantRef.current.style.zIndex = 1;
            quadrantRef.current.style.width = expandedWidth;
            quadrantRef.current.style.height = expandedHeight;
            quadrantRef.current.classList.remove("fade-right");


        }
    }, []);

    const QuadrantCollapse = useCallback((quadrantRef: React.MutableRefObject<any>) => {
        quadrantRef.current.style.width = unExpandedWidth;
        quadrantRef.current.style.height = unExpandedHeight;
        quadrantRef.current.classList.add("fade-right");
    }, []);

    const handleQuadrantMouseLeave = useCallback((quadrantRef: React.MutableRefObject<any>, quadrantRefFixed: React.MutableRefObject<boolean>) => {
        clearTimeout(mouseMoveTimer);
        if (!quadrantRefFixed.current) {
            QuadrantCollapse(quadrantRef);
        }
    }, []);

    const handleQuadrantClick = useCallback((quadrantRef: React.MutableRefObject<any>, quadrantRefFixed: React.MutableRefObject<boolean>) => {
        quadrantRefFixed.current = true;
        //handleQuadrantExpand(quadrantRef);
        quadrantRef.current.classList.add("expanded");
    }, []);

    const handleCollapseClick = useCallback((quadrantRef: React.MutableRefObject<any>, quadrantRefFixed: React.MutableRefObject<boolean>) => {
        quadrantRefFixed.current = false;
        QuadrantCollapse(quadrantRef);
        quadrantRef.current.classList.remove("expanded");

    }, []);

    const quadrantArray = useRef([
        [firstQuadrantRef, firstQuadrantExpandFixed, SubContractorEventLogs, "firstQuadrant"],
        [secondQuadrantRef, secondQuadrantExpandFixed, TechnicalEventLogs, "secondQuadrant"],
        [thirdQuadrantRef, thirdQuadrantExpandFixed, ThisAudit, "thirdQuadrant"],
        [fourthQuadrantRef, fourthQuadrantExpandFixed, ThisAudit, "fourthQuadrant"]]);

    return <div className='taskDash'>
        <div className='watermark' id="watermark">PRIORITY LIST</div>

        <div id='urgentListsContainer'>

            {quadrantArray.current.map((q, i) => {
                const thisRef = q[0] as React.MutableRefObject<any>;
                const thisExpandFixedRef = q[1] as React.MutableRefObject<any>;
                const QuadComp = q[2] as React.FC;
                const thisId = q[3] as string;

                return (
                    <div key={thisId}>

                        <div ref={thisRef} className="quadrant fade-right" id={thisId}
                            onMouseMove={() => {
                                thisRef.current.hasLeft = false;
                                handleMouseMoveOverQuadrant(thisRef);
                            }}
                            onMouseLeave={() => {
                                thisRef.current.hasLeft = true;
                                handleQuadrantMouseLeave(thisRef, thisExpandFixedRef);
                            }}
                            onClick={() => {
                                handleQuadrantClick(thisRef, thisExpandFixedRef);
                            }}
                        >
                            <GeneralTooltip className="collapser" title="Collapse">
                                <div className='expansionControl fullScreenForm Collapser'>
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleCollapseClick(thisRef, thisExpandFixedRef);
                                        }}
                                    >
                                        <FullscreenExit />
                                    </IconButton>
                                </div>
                            </GeneralTooltip>
                            <div className='quadrant-wrapper'>
                                <QuadComp />
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    </div>

}