import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';

// Own
import API from "services/API/API";
import { DocumentViewerModalProps } from 'components/Common/Components/DocumentViewer/DocumentViewer';
import { BasePermitToWork } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";
import { getExtensionFromURLString } from "helpers/String/String.helper";
import { WorkPermitPDFViewer } from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/WorkPermitPDFViewer";
import MatConfirmationDialog from 'components/Common/Components/Material/MatConfirmationDialog/MatConfirmationDialog';
import { PreFlightListInfo } from "components/Common/Interfaces/Entity.interface";

interface deletionConfirmationState {
    open: boolean;
    agreeCallback: () => void;
    message: string;
    img?: any;
}

interface WorkPermitDocViewerProps extends DocumentViewerModalProps {
    data: BasePermitToWork;
    docBaseRoute: string;
    setData: (value: React.SetStateAction<BasePermitToWork>) => void;
    preFlightDocInfo: PreFlightListInfo | undefined;
    showDeleteButton?: boolean;
    showDownloadButton?: boolean;
    signalRefresh?: number;
    supportingDocs?: any[];
}

export const WorkPermitDocViewer = ({
    data, docBaseRoute, setData, preFlightDocInfo, showDeleteButton, showDownloadButton, signalRefresh, supportingDocs, ...props
}: WorkPermitDocViewerProps) => {
    const deleteDoc = useCallback(() => API.delete(`${docBaseRoute}${data.agreement?.id}`).then((response: any) => {
        setData({ ...data, agreement: undefined })
    }), [data, docBaseRoute, setData]);
    const deleteConfirmationInitialState: deletionConfirmationState = { open: false, agreeCallback: () => { }, message: '' };
    const [confirmationDialog, setConfirmationDialog] = useState(deleteConfirmationInitialState);
    if (data.agreement && signalRefresh) {
        const RenderDeleteConfirmationDialogue = () => <MatConfirmationDialog
            onAgree={confirmationDialog.agreeCallback}
            onDisagree={() => setConfirmationDialog(deleteConfirmationInitialState)}
            open={confirmationDialog.open}
            actions={{ agree: 'Yes', disagree: 'No' }}
        >
            {confirmationDialog.message}
            {confirmationDialog.img?.incident_image?.thumbnail && <div className='smallImageConfirmation'><img src={confirmationDialog.img?.incident_image?.thumbnail} style={{ width: "120px", height: "auto" }} /></div>}
        </MatConfirmationDialog>
        const handleDeleteUploaded = (): void => {
            setConfirmationDialog({
                open: true,
                agreeCallback: () => {
                    deleteDoc();
                    setConfirmationDialog(deleteConfirmationInitialState);
                },
                message: `Are you sure you want to remove the currently uploaded permit document?`,
            });
        }

        const DeleteButton = () => (
            <Button
                disabled={!data.agreement?.id || !preFlightDocInfo?.privileges?.POST} onClick={() => {
                    // note that as we're getting the privileges from the 'list' route, DELETE would not be true, 
                    // but for all practical purposes we can assume for the forseeable that create permissions come 
                    // with delete permissions
                    handleDeleteUploaded();
                }}
            >
                Remove Uploaded Permit
            </Button>
        )

        const DownloadButton = () => (
            <a className='downLoad' href={data.agreement?.download_link}>
                Download Permit
            </a>

        )

        const NextSignatureLink = () => <>
            {data.next_signature_url && data.ptw_status !== "cancelled" && <span className='next-signature-link'>Next signature required: <a className='next-signature-link' href={data.next_signature_url} rel="noopener noreferrer" target='_blank'>{data.next_signature_label}</a></span>}
        </>

        const UploadPTWActionButtons = () => {
            return <div className='ptw-actions-row'>
                <NextSignatureLink />
                <div className='ptwUploaderActionButtons'>
                    {showDownloadButton && <DownloadButton />}
                    {showDeleteButton && <DeleteButton />}
                    {supportingDocs && supportingDocs?.map((doc: any) => {
                        return <a href={doc.file} target='_blank' rel="noopener noreferrer">{`View ${doc.visit_document_category_label} (${doc.document_ref})`}</a>
                    })}
                </div>
            </div>
        }
        const thisExt = getExtensionFromURLString(data.agreement?.file);
        if (thisExt === 'pdf') {
            return <>
                <RenderDeleteConfirmationDialogue />
                <WorkPermitPDFViewer
                    inModal={false}
                    isOpen={!!data.agreement}
                    url={data.agreement?.file}
                    pdfWrapperClass='portrait'
                    height={1250}
                />
                <UploadPTWActionButtons />
            </>
        } else if (thisExt && ['jpg', 'png', 'svg', 'jpeg'].includes(thisExt)) {
            return <>
                <RenderDeleteConfirmationDialogue />
                <img src={data.agreement?.file} width="100%" style={{ padding: "1rem" }} />
                <UploadPTWActionButtons />
            </>
        } else {
            return <>
                <>
                    The stored permit document is of an unsupported type
                </>
                <RenderDeleteConfirmationDialogue />
            </>
        }

    } else {
        return <></>
    }
}