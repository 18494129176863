import { VisitDataRow } from 'components/Schedulers/Interfaces/Schedule.interfaces';

export const sortPrintGridRecords = (records?: VisitDataRow[]) => {
  if (records) {
    records.sort((recordA, recordB) => {
      if (recordA.archived && !recordB.archived) {
        return 1
      }

      if (!recordA.archived && recordB.archived) {
        return -1
      }

      return 0
    })
  } else {
    return undefined
  }
}
