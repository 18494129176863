import React, { useState, useEffect, useRef, useCallback, useReducer } from 'react';

// Own
import ConfirmationModal from 'components/Common/Components/Modal/Modal';
import { ModalProps } from 'components/Common/Components/Modal/interfaces/modal.interfaces';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import { MenuItem } from '@material-ui/core';
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { LookupEntity } from "components/Common/Interfaces/Entity.interface";
import { toTitleCase } from "helpers/String/String.helper";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { Dictionary } from "components/Common/Interfaces/Entity.interface";
import { simpleFetchList } from "components/ContractInFocus/Services/commonAPI.services";


interface ModalContextContractSelectionProps extends ModalProps {
    portfolio: HydratedPortfolio;
    setContextContract: React.Dispatch<React.SetStateAction<SiteContract | undefined>>
    title?: string;
    dataField: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    defaultValue?: string | number;
    zIndex?: number;
    otherOptionMapping?: Dictionary<string>;
    setOtherOption?: React.Dispatch<React.SetStateAction<any>>;
    HASEditableContext?: boolean;
}

const setContractFromList = (setContract: (value: React.SetStateAction<SiteContract | undefined>) => void, match: string, contracts: SiteContract[]) => {
    const contract = contracts.find(x => x.id.toString() === match)
    if (contract) {
        setContract(contract);
    }
}

export const ModalContextContractSelector = ({
    isOpen = true,
    portfolio,
    onCancel,
    setContextContract,
    title,
    dataField,
    disabled,
    style,
    defaultValue,
    zIndex,
    HASEditableContext,
}: ModalContextContractSelectionProps) => {

    const thisModalTitle = title || 'Please select a contract';
    const finalStyles = {
        width: '100%',
        zIndex: zIndex || 1200,
        ...style
    }
    const valueRef = useRef<string | number>();
    const [selectedContract, setSelectedContract] = useState<SiteContract>();
    const [theseContracts, setTheseContracts] = useState<SiteContract[]>();
    useEffect(() => {
        if (!portfolio) {
            let route = '/writable-contracts/'
            if (HASEditableContext) {
                route = '/h-a-s-writable-contracts/'
            }
            simpleFetchList({
                route: route,
            }).then(response => {
                setTheseContracts(response.data);
            })
        } else {
            setTheseContracts(portfolio.contracts)
        }
    }, [portfolio, HASEditableContext]);
    const onChangePortfolioContractSelected = (e: any) => {
        console.log('e: ', e);
        const contract_id = e.target.value as number | string;
        valueRef.current = contract_id;
        // this next line will set the portfolio contract, which may complete the conditions for opening the visit modal 
        // and will ensure a condition to close the portfolio contract selector modal
        contract_id && theseContracts && setContractFromList(setSelectedContract, contract_id.toString(), theseContracts);
    }
    const getChoices = useCallback(() => {
        const choices: LookupEntity[] = [];
        theseContracts && theseContracts.map((x: SiteContract) => choices.push({ value: x.id, display_name: `${x.site_name} ${x.contract_ref}` }))
        return choices;
    }, [theseContracts]);
    const choices = getChoices();
    const inputRef = useRef();
    const [buttonActive, activateButton] = useState(false);
    useEffect(() => {
        activateButton(!!selectedContract);
    }, [selectedContract])
    const onChangeSelect = (event: React.ChangeEvent<
        {
            name?: string | undefined;
            value: unknown
        }
    >) => {
        onChangePortfolioContractSelected(event);
    }
    return (
        <>
            <ConfirmationModal
                id="contractFreeze"
                isOpen={isOpen}
                title={thisModalTitle}
                onCancel={onCancel}
                onConfirmation={() => { setContextContract(selectedContract) }}
                size="md"
                okCaption="Select"
                disabled={!buttonActive}
            >
                <FormControl
                    className="portfolio-contract-selector"
                    style={{
                        // put any defaults in here
                        ...finalStyles
                    }}
                >
                    <Select
                        id={`contextContractSelector`}
                        autoFocus={true}
                        disabled={disabled}
                        style={{
                            // put any defaults in here
                            ...finalStyles
                        }}
                        inputRef={inputRef}
                        defaultValue={valueRef.current ? valueRef.current : defaultValue}
                        MenuProps={{
                            style: {
                                zIndex: finalStyles.zIndex
                            }
                        }}
                        onChange={(e) => onChangeSelect(e)}>
                        {(choices || []).map((field) => {
                            return <MenuItem
                                key={field.value}
                                value={field.value}
                                className={dataField}
                            >
                                {toTitleCase(field.display_name)}
                            </MenuItem>
                        })
                        }
                    </Select>
                </FormControl>
            </ConfirmationModal>
        </>
    );
}

interface PortfolioContractSelectorProps {
    portfolio: HydratedPortfolio;
    thisLabel?: string;
    currentFocus: React.MutableRefObject<string | undefined>;
    dataField: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    zIndex?: number;
    onChange: (e: any) => void;
    onBlur?: (value: string | number | undefined, onChange: (e: any) => void) => void;
    valueRef: React.MutableRefObject<any>;
    defaultValue?: string | number;
}

export const PortfolioContractSelector: React.FC<PortfolioContractSelectorProps> = ({
    portfolio,
    thisLabel,
    currentFocus,
    dataField,
    disabled,
    style,
    zIndex,
    onChange,
    onBlur,
    valueRef,
    defaultValue,
}) => {
    const finalStyles = {
        width: '100%',
        zIndex: zIndex || 1200,
        ...style
    }
    const getChoices = useCallback(() => {
        const choices: LookupEntity[] = [];
        portfolio && portfolio.contracts.map((x: SiteContract) => choices.push({ value: x.id, display_name: x.contract_ref }))
        return choices;
    }, [portfolio]);
    const choices = getChoices();
    const inputRef = useRef();
    const currentValue = valueRef.current;
    const defaultOnBlur = useCallback((e: any) => {
        onBlur && onBlur(valueRef.current, onChange);
    }, [onBlur, valueRef, onChange]);
    const onChangeSelect = (event: React.ChangeEvent<
        {
            name?: string | undefined;
            value: unknown
        }
    >) => {
        onChange(event);
    }
    return (
        <FormControl
            className="portfolio-contract-selector"
            style={{
                // put any defaults in here
                ...finalStyles
            }}
        >
            {
                !!thisLabel &&
                <InputLabel shrink>
                    {thisLabel}
                </InputLabel>
            }
            <Select
                id={`portfolioContractSelector`}
                autoFocus={currentFocus.current == dataField}
                onFocus={() => { currentFocus.current = dataField }}
                disabled={disabled}
                style={{
                    // put any defaults in here
                    ...finalStyles
                }}
                inputRef={inputRef}
                onBlur={defaultOnBlur}
                //value={inputValue}
                defaultValue={currentValue ? currentValue : defaultValue}
                MenuProps={{
                    style: {
                        zIndex: finalStyles.zIndex
                    }
                }}
                onChange={(e) => onChangeSelect(e)}>
                {(choices || []).map((field) => {
                    return <MenuItem
                        key={field.value}
                        value={field.value}
                        className={dataField}
                    >
                        {toTitleCase(field.display_name)}
                    </MenuItem>
                })
                }
            </Select>
        </FormControl>
    )
}



