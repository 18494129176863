import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { store } from "store/store";
import { broadcastLayoutUpdateSelector } from "components/Profile/Selectors/Profile.selector";

export const useGetLeftMargin = (sideBarHidden: boolean, sideBarCollapsed: boolean) => {
    const layoutUpdateBroadcast = useSelector(broadcastLayoutUpdateSelector);
    const getLeftMargin = useCallback((): string => {
        // const personalSettings = store.getState().personalSettings?.data
        // let sideBarHidden = false;
        // let sideBarCollapsed = false;
        // if (personalSettings) {
        //     sideBarHidden = personalSettings[sideBarHiddenAttribute];
        //     sideBarCollapsed = personalSettings[sideBarCollapsedAttribute];
        // }
        // console.log('personalSettings: ', personalSettings);
        if (sideBarHidden) {
            return "0";
        }
        if (sideBarCollapsed) {
            return "2rem";
        }
        return "13.35rem";
    }, [sideBarHidden, sideBarCollapsed]);

    const leftMarginRef = useRef(getLeftMargin());
    const [leftMargin, setLeftMargin] = useState<string>(leftMarginRef.current);

    useLayoutEffect(() => {
        const newLeftMargin = getLeftMargin();
        if (leftMargin !== newLeftMargin) {
            setLeftMargin(getLeftMargin());
        }
    }, [layoutUpdateBroadcast, sideBarHidden, sideBarCollapsed])

    return leftMargin;
}
