import React, { useEffect, useState, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";

// Own
import { TabbedPanel } from "components/Common/Components/TabPanel";
import { Portfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import portfolioAdminService from "components/AdminPanel/Portfolios/Services/portfolioAdminServices";
import PortfolioContractsView from "components/AdminPanel/Contracts/Containers/ContractSublistView/PortfolioContractsView";
import { AdminImageForm } from "components/AdminPanel/Containers/AdminImageForm";
import { getCoverImageUploader } from "components/Common/Components/Input/FileUploader.js"
import PortfolioAccessView from "components/AdminPanel/Contracts/Containers/ContractSublistView/PortfolioAccessView";
import PortfolioPeriodSublistView from "components/AdminPanel/ContractPeriods/Containers/ContractPeriodsSublistView/PortfolioPeriodSublistView";
import {
    getPortfolioFormItems,
} from "components/AdminPanel/Portfolios/Models/Portfolios.model";
import { setDetailPage } from "components/AdminPanel/Actions/adminPanel.actions";
import { getPanelSelectorFactory } from "components/AdminPanel/Selectors/Selectors";
import * as selectors from "components/Portfolios/Selectors/Portfolio.selectors";
import contractServices from "components/AdminPanel/Contracts/Services/contractService";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { ImageConfig } from "components/AdminPanel/Containers/AdminImageForm/AdminImageForm";

import { FORM_ROW_ID } from "components/AdminPanel/Models/AdminPanel.model";

export function portfolioHandleDistributionListSubmit(portfolio: HydratedPortfolio, datasource?: any) {
    return (peopleIds: number[]) => {
        const accessObjs = peopleIds.map((personId) => (
            {
                "person": personId,
                "portfolio": portfolio.id
            }
        ))
        contractServices
            .addToMaintenanceReportDistribution({ portfolioId: portfolio.id, accessObjs })
            .then(() => {
                datasource && datasource.reload()
            });
    }
}

interface PortfoliosDetailViewProps {
    panelId: string;
    data: { data: Portfolio };
}

const PortfoliosDetailView: React.FC<PortfoliosDetailViewProps> = ({
    panelId,
    data: { data },
}) => {
    const portfolioId = data.id;
    const portfolioTabs = ["Detail", "Contracts", "People", "Portfolio Periods"];
    // const portfolioTabs = ["Detail", "Contracts", "People"];

    const contractSublistTestId = "portfolios-contract-detail-view";
    const explicitDataTestId = "explicit-portfolio-grid";


    const selectAdminPanelState = useSelector(getPanelSelectorFactory(panelId));

    const selectPortfolio = useSelector(selectors.portfolioHydratedFactory(portfolioId));

    const dispatch = useDispatch();

    const handleChangeTab = (index: number) => {
        dispatch(setDetailPage(panelId, data.id, index));
    };

    const [imagesConfig, setImagesConfig] = useState<ImageConfig[]>([]);

    useEffect(() => {
        const onPortfolioImageUpload = (file: any, progress: any) => {
            portfolioAdminService.updatePortfolioImage(data.id, file, "portfolio_image").subscribe();
        };
        const onPortfolioCoverImageUpload = (file: any, progress: any) => {
            portfolioAdminService.updatePortfolioImage(data.id, file, "portfolio_monthly_maintenance_report_cover_image").subscribe();
        };

        const onPortfolioImageClear = () => {
            portfolioAdminService.clearPortfolioImage(data.id, "portfolio_image").subscribe();
        };

        const onPortfolioCoverImageClear = () => {
            portfolioAdminService.clearPortfolioImage(data.id, "portfolio_monthly_maintenance_report_cover_image").subscribe();
        };
        setImagesConfig(
            [
                {
                    imageCaption: 'Portfolio Image',
                    imageKey: 'portfolio_image',
                    onUpload: onPortfolioImageUpload,
                    onClear: onPortfolioImageClear
                },
                {
                    imageCaption: 'Portfolio Report Cover Image',
                    imageKey: 'portfolio_monthly_maintenance_report_cover_image',
                    onUpload: onPortfolioCoverImageUpload,
                    onClear: onPortfolioCoverImageClear,
                    uploader: getCoverImageUploader
                },
            ]
        )
    }, [data])

    const handleCancel = (portfolio: Portfolio): void => {
        const IS_GRID_FORM = portfolio.id.toString() === FORM_ROW_ID;
        // THIS SHOULD ENSURE THE STATE IS AS WAS BEFORE EDITING THE FORM, i.e. just repopulate the form from the stored object
        //IS_GRID_FORM && dispatch(actions.clearPsuedoPortfolio());
    };

    const handleUpdate = (oldPortfolio: Portfolio, portfolio: Portfolio): void => {
        const IS_GRID_FORM = portfolio.id.toString() === FORM_ROW_ID;

        IS_GRID_FORM
            ? portfolioAdminService.createPortfolio({ ...portfolio, portfolio_image: undefined, portfolio_monthly_maintenance_report_cover_image: undefined }, panelId)
            : portfolioAdminService.updatePortfolio(portfolio.id, portfolio, selectPortfolio.meta.POST);
    };

    return (
        <>
            {selectPortfolio.data && (
                <TabbedPanel
                    pageIndex={selectAdminPanelState[data.id]?.detailIndex || 0}
                    showTabs={data.id.toString() !== FORM_ROW_ID}
                    onChangeTab={handleChangeTab}
                    tabs={portfolioTabs}
                >
                    <div>{selectPortfolio.meta &&
                        <AdminImageForm
                            data={selectPortfolio.data}
                            meta={selectPortfolio.meta.POST}
                            formMeta={getPortfolioFormItems(selectPortfolio.meta.POST)}
                            imagesConfig={imagesConfig}
                            onUpdate={handleUpdate}
                            onCancel={handleCancel}
                        />
                    }</div>
                    <div>
                        <PortfolioContractsView
                            panelId={panelId}
                            dataTestId={contractSublistTestId}
                            id={portfolioId}
                            masterRecordData={selectPortfolio.data}
                            masterRecordType="portfolio"
                            selectActionIdentifier="addContracts"
                            linkPanelTitle={"ADD CONTRACTS TO PORTFOLIO: " + selectPortfolio.data.name.toUpperCase()}
                            meta={selectPortfolio.meta.POST}
                        />
                    </div>
                    <div>
                        <PortfolioAccessView
                            dataTestId={explicitDataTestId}
                            panelId={panelId}
                            masterRecordData={selectPortfolio.data}
                            masterRecordType="portfolio"
                            selectActionIdentifier="portfolioExplicitAccess"
                            linkPanelTitle={"GRANT EXPLICIT ACCESS TO PORTFOLIO: " + selectPortfolio.data.name.toUpperCase()}
                        />
                    </div>
                    <div>
                        <PortfolioPeriodSublistView
                            id={portfolioId}
                            dataTestId={"portfolioPeriodSublist"}
                            panelId={panelId}
                        />
                    </div>
                </TabbedPanel>
            )}
        </>
    );
};

export default PortfoliosDetailView;
