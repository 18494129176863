import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from '@material-ui/core/Button';
import { APIPrivileges } from "services/Interface/Interface";
import { getDataAndMeta } from "services/API/API.helper";
import API from 'services/API/API';
import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";
import { faExclamationTriangle, faStrikethrough } from "@fortawesome/free-solid-svg-icons";
import { MentionsInput, Mention } from "react-mentions";
import MentionDefaultInputStyles from "components/Common/Components/MentionsInput/defaultStyles.js";
import DefaultMentionsStyle from "components/Common/Components/MentionsInput/defaultMentionsStyle";
import { recordDateTime } from '../../../Common/Utils/Dates';
import { onResponseError } from "services/API/API.interceptor.js"
import { ParseAPIResponse } from 'services/Interface/Interface';

import { useInView } from 'react-intersection-observer';

// Styling
import "components/ContractInFocus/Financials/Styles/QuoteDetail.scss";

// const users = [
//     {
//         id: 'saul',
//         display: 'Saul Goodman',
//     },
//     {
//         id: 'hank',
//         display: 'Hank Schrader',
//     },
//     {
//         id: 'skyler',
//         display: 'Skyler White',
//     },
// ]

export const disableDxKeyProcessor = (e: any) => {
    if ([9, 13, 38, 40].includes(e.event.keyCode)) {
        //if (e.event?.keyCode === 9 || e.event?.keyCode === 40) {
        e.handled = true;
    }
}

export interface CreateFieldInfo {
    id?: string; // this will only exist if the fieldInfo object already exists, otherwise it will be undefined
    issue_field: string; // this should always exist needless to say - it will be used to create a new fieldInfo object if required
    field_redacted?: boolean;
    requires_resolution?: boolean;
    parentId: string;
    canCreateFieldInfo: boolean;
    fieldLabel: string;
}

export interface FieldInfoLinkProps extends CreateFieldInfo {
    setFieldInfo: React.Dispatch<any>;
    meta?: FieldMetaGroup;
    openingPrep?: () => void;
    className?: string;
    initiallySelectedDataField?: React.MutableRefObject<string | undefined>;
}

export interface FieldInfo extends CreateFieldInfo {
    id: string;
}

export const FieldInfoButton = ({
    setFieldInfo,
    id,
    issue_field,
    canCreateFieldInfo,
    parentId,
    fieldLabel,
    meta,
    field_redacted,
    requires_resolution,
    openingPrep,
    className,
    initiallySelectedDataField
}: FieldInfoLinkProps) => {

    const infoProps = {
        id,
        issue_field,
        parentId,
        canCreateFieldInfo,
        fieldLabel,
        field_redacted,
        requires_resolution,
        meta,
    }
    const openFieldInfo = useMemo(() => () => {
        setFieldInfo(infoProps)
    }, [setFieldInfo, infoProps]);
    // console.log('initiallySelectedDataField.current: ', initiallySelectedDataField?.current);
    // console.log('issue_field: ', issue_field);
    if (initiallySelectedDataField && initiallySelectedDataField.current === issue_field) {
        initiallySelectedDataField.current = undefined;
        openFieldInfo();
    }
    const noFlags = !field_redacted && !requires_resolution;
    return <div className='formFieldFlagsWrapper' onClick={() => {
        openingPrep && openingPrep();
        openFieldInfo();
    }}>
        {noFlags && <FontAwesomeIcon
            icon={faInfoCircle}
            cursor="pointer"
            className={`${requires_resolution ? "flagged" : "normal"} ${className}`}
        />}
        {requires_resolution && <FontAwesomeIcon
            icon={faExclamationTriangle}
            cursor="pointer"
            className={`flagged ${className}`}
        />}
        {field_redacted && <FontAwesomeIcon
            icon={faStrikethrough}
            cursor="pointer"
            className={`flagged ${className}`}
        />}
    </div>
}

export const useCloseFieldInfo = ({
    setFieldInfo
}: FieldInfoLinkProps) => {
    const closeFieldInfo = useCallback(() => { setFieldInfo(undefined) }, [setFieldInfo]);
    return <Button onClick={closeFieldInfo}> Close </Button>
}

export type SubmitFieldCommentType = (text: string) => Promise<ParseAPIResponse<any>>;

interface GenerateSubmitFieldCommentProps {
    url: string;
    field_info_id: string;
    portfolioId?: string | number;
    containing_tab?: string;
}

export const generateSubmitFieldComment = ({ url, field_info_id, portfolioId, containing_tab }: GenerateSubmitFieldCommentProps) => (text: string) => {
    const payload = {
        text: text,
        about: field_info_id,
        portfolio: portfolioId,
        containing_tab: containing_tab
    }
    return API.post(url, payload).then((response) => {
        return getDataAndMeta(response);
    });
}

export type GetFieldCommentsType = () => Promise<ParseAPIResponse<any>>;

export const generateGetFieldComments = (url: string) => () => {
    return API.get(url).then((response) => getDataAndMeta(response));
};


export interface MentionablePerson {
    id: string;
    display: string;
}



interface Comment {
    id: string;
    about: string;
    text: string;
    submitted_by: string;
    created_at: string;
    current_user_is_owner: boolean;
    submitted_by_first_name?: string;
    submitted_by_last_name?: string;
    log_type?: string;
}

interface CommentBlockViewProps {
    data: FieldInfo;
    getComments: React.MutableRefObject<GetFieldCommentsType | undefined>; // this should be a 'closure' so it doesn't need any arguments
    createComment: React.MutableRefObject<SubmitFieldCommentType | undefined> // this should be a 'closure' that only requires the text of the comment to submit
    disableDxNavKeys?: React.MutableRefObject<boolean>;
    incidentMentionablePersons?: React.MutableRefObject<MentionablePerson[] | undefined>;
    initiallySelectedComment?: React.MutableRefObject<string | undefined>;
}

const CommentBlock: React.FC<CommentBlockViewProps> = ({
    data,
    getComments,
    createComment,
    disableDxNavKeys,
    incidentMentionablePersons,
    initiallySelectedComment
}) => {
    const [commentHasText, setCommentHasText] = useState<boolean>(false);
    const [commentPermissions, setCommentPermissions] = useState<APIPrivileges>();
    const [history, setHistory] = useState<Comment[]>();
    let lastHistoryItemId = useRef<string>();
    let commentText = "";
    const commentFieldRef = useRef<any>({ value: '' });
    const [commentFieldContents, setCommentFieldContents] = useState('');
    const fetchHistoryTimer = useRef<any>();

    const [visRef, inView, entry] = useInView({
        /* Optional options */
        threshold: 0,
        initialInView: true,
        trackVisibility: true,
        delay: 500,
    });

    const scrollHistoryToEnd = useCallback(() => {
        const historyWrapperid = `History-${data.id}`
        const scroll = document.getElementById(historyWrapperid);
        if (scroll) {
            scroll.scrollTop = scroll.scrollHeight;
            scroll.animate({ scrollTop: scroll.scrollHeight });
        }
    }, [data.id]);


    const updateHistory = useCallback(() => {
        getComments.current && getComments.current().then(
            (response) => {
                if (response !== null) {
                    setCommentPermissions(response.permissions)
                    const thisData = response.data;
                    // if it is null something has errored and it will be in console log of getAWHistory
                    const newLastHistoryItemId = thisData[thisData.length - 1]?.id.toString();
                    setHistory(thisData);
                    setTimeout(() => {
                        if (!initiallySelectedComment?.current && newLastHistoryItemId && (newLastHistoryItemId !== lastHistoryItemId.current || !lastHistoryItemId.current)) {
                            scrollHistoryToEnd()
                            lastHistoryItemId.current = newLastHistoryItemId;
                        }
                    }, 200);
                }
            }
        );
    }, [scrollHistoryToEnd, getComments, initiallySelectedComment]);

    const handleSubmitComment = useCallback(() => {
        const thisCommentText = `${commentFieldContents}`; // interpolation used just in case in some browser implementations changing thisCommentText changes commentFieldContents
        createComment.current && createComment.current(commentFieldContents).then(
            (response) => {
                updateHistory();
            }
        ).catch(
            error => {
                setCommentFieldContents(thisCommentText); // put comment back again
                return onResponseError(error)
            }
        )
        setCommentFieldContents(''); // this will run before comment is saved and returned, which will look more performant, if the BE/email takes some time
    }, [commentFieldContents, createComment, updateHistory])

    const handleDiscardCommentChanges = () => {
        setCommentFieldContents('');
        if (commentFieldRef.current) {
            commentFieldRef.current.value = '';
        }
    }

    useEffect(() => {
        setCommentHasText(!!(commentFieldContents && commentFieldContents.trim().length));
    }, [commentFieldContents])



    useEffect(() => {
        // we clear up the fetchHistory in another useEffect below, when the row status is no longer 'expanding'. 
        // this is necessary because devextreme datagrid caches this component once the row has been expanded, so 
        // it isn't really unmounted when the row is collapsed.
        // however we also return a function to clear it from this useEffect, just as another failsafe, so if the component is
        // 'unmounted' it will be cleared.
        const timer = fetchHistoryTimer.current;

        return () => {
            //console.log('clearing fetchHistoryTimer if exists on component destruction');
            if (timer) {
                window.clearInterval(timer);
            }
        };
    }, [getComments])

    useEffect(() => {
        // using intersection observer to manage polling state - still going to keep the 'unmount' clear interval above as a 'backup' to ensure that
        // the polling stops if the element is destroyed.
        if (inView && !fetchHistoryTimer.current) {
            // console.log('going to restart history polling...')
            fetchHistoryTimer.current = setInterval(() => {
                updateHistory();
            }, 7500);
        } else if (!inView && fetchHistoryTimer.current) {
            // console.log('going to stop history polling...')
            window.clearInterval(fetchHistoryTimer.current);
            fetchHistoryTimer.current = null;
        }
        return (() => {
            // console.log('cleaning up timer...');
            fetchHistoryTimer.current && clearInterval(fetchHistoryTimer.current)
        });
    }, [inView, updateHistory]);

    useEffect(() => {
        updateHistory();
    }, [updateHistory, getComments]);

    const onMentionsInputChange = useCallback((event, newValue, newPlainTextValue, mentions) => {
        if (disableDxNavKeys) disableDxNavKeys.current = true;
        setCommentFieldContents(newValue);

    }, [setCommentFieldContents, disableDxNavKeys])

    const onMentionsInputBlur = useCallback((event, clickedSuggestion) => {
        if (disableDxNavKeys) disableDxNavKeys.current = false;

    }, [disableDxNavKeys]);
    const onAdd = useCallback((...args) => console.log(...args), [])

    const scrollToRef: any = useRef();

    useEffect(() => {
        setTimeout(() => {
            initiallySelectedComment && scrollToRef.current && scrollToRef.current.scrollIntoView && scrollToRef.current.scrollIntoView()
        }, 1000)

    }, [scrollToRef, initiallySelectedComment])

    return (

        <div className="commentsBlock" ref={visRef}>

            {history && !!history.length &&
                <div className='response-history-container'>
                    <div id={`History-${data.id}`} className='response-history-wrapper'>
                        {history && history.map((item) => {
                            return (
                                <div key={item.id} ref={item.id === initiallySelectedComment?.current ? scrollToRef : undefined}>
                                    <div id={item.id} className={`commentWrapper ${item.current_user_is_owner ? 'owner' : ''} `} >
                                        {item.log_type == "action" ?
                                            <>
                                                <div className="response-history-item">
                                                    <span className="actionLabel">ACTION:</span>
                                                    <span className="actionText">{item.text}</span>
                                                </div>
                                                <div className="actionLabel"> BY:&nbsp;{item.submitted_by_first_name}&nbsp;{item.submitted_by_last_name}</div>
                                                <span className="actionLabel">EMAIL:&nbsp;</span><span>{item.submitted_by}</span><br />
                                                <span className="actionLabel">AT:&nbsp;</span><span>{item.created_at}</span>
                                            </>
                                            :
                                            <>
                                                <div className={`response-history-item commentBubble ${item.current_user_is_owner ? 'owner' : ''} ${item.id === initiallySelectedComment?.current ? 'initiallySelected' : ''}`}>
                                                    {!item.current_user_is_owner &&
                                                        <div className="submittedByNames">{item.submitted_by_first_name}&nbsp;{item.submitted_by_last_name}</div>
                                                    }
                                                    <div className="submittedBy">{item.current_user_is_owner ? "you" : item.submitted_by}</div>
                                                    <div className="actionText">{item.text}</div>
                                                    <div className="commentAt">{recordDateTime(item.created_at)}</div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
            {commentPermissions?.POST &&
                <>
                    <div className="CommentsInputWrapper">
                        {/* <TextField inputRef={commentFieldRef} id="CommentInput" variant="outlined" placeholder="Add Comment" multiline onChange={event => {
                            const { value } = event.target;
                            setCommentText(value);
                        }}>
                        </TextField> */}
                        <MentionsInput
                            value={commentFieldContents}
                            onChange={onMentionsInputChange}
                            onBlur={onMentionsInputBlur}
                            style={MentionDefaultInputStyles}
                            placeholder={"Mention people using '@'"}
                            a11ySuggestionsListLabel={"Suggested mentions"}
                            className="commentMentionAbleInput"
                            allowSpaceInQuery
                        >
                            <Mention
                                //markup="@[__display__](user:__id__)"
                                markup="@[::display::__display__|::email::__id__::]"
                                trigger="@"
                                data={incidentMentionablePersons?.current || []}
                                renderSuggestion={(suggestion, search, highlightedDisplay) => (
                                    <div className="user">{highlightedDisplay}</div>
                                )}
                                onAdd={onAdd}
                                style={DefaultMentionsStyle}
                                appendSpaceOnAdd
                            />
                        </MentionsInput>
                    </div>

                    <Button
                        disabled={commentHasText ? false : true} color="primary" type="submit" onClick={handleSubmitComment} >Submit</Button>
                    <Button
                        disabled={commentHasText ? false : true} color="primary" type="submit" onClick={handleDiscardCommentChanges} >Discard</Button>
                </>
            }
        </div>
    );
}

export default CommentBlock;


