import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';

// Own
import API from "services/API/API";
import { DocumentViewerModalProps } from 'components/Common/Components/DocumentViewer/DocumentViewer';
import { getExtensionFromURLString } from "helpers/String/String.helper";
import { WorkPermitPDFViewer } from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/WorkPermitPDFViewer";
import MatConfirmationDialog from 'components/Common/Components/Material/MatConfirmationDialog/MatConfirmationDialog';
import { PreFlightListInfo } from "components/Common/Interfaces/Entity.interface";
import { IHASRiddor } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";

interface deletionConfirmationState {
    open: boolean;
    agreeCallback: () => void;
    message: string;
    img?: any;
}

interface RiddorSubmittedReportViewerProps extends DocumentViewerModalProps {
    data: IHASRiddor;
    docBaseRoute: string;
    setData: (value: React.SetStateAction<IHASRiddor>) => void;
    preFlightDocInfo: PreFlightListInfo | undefined;
    showDeleteButton?: boolean;
    showDownloadButton?: boolean;
    signalRefresh?: number;
}

const RiddorSubmittedReportViewer = ({
    data, docBaseRoute, setData, preFlightDocInfo, showDeleteButton, showDownloadButton, signalRefresh, ...props
}: RiddorSubmittedReportViewerProps) => {
    const deleteDoc = useCallback(() => API.delete(`${docBaseRoute}${data.submitted_report?.id}`).then((response: any) => {
        setData({ ...data, submitted_report: undefined })
    }), [data, docBaseRoute, setData]);
    const deleteConfirmationInitialState: deletionConfirmationState = { open: false, agreeCallback: () => { }, message: '' };
    const [confirmationDialog, setConfirmationDialog] = useState(deleteConfirmationInitialState);
    if (data.submitted_report && signalRefresh) {
        const RenderDeleteConfirmationDialogue = () => <MatConfirmationDialog
            onAgree={confirmationDialog.agreeCallback}
            onDisagree={() => setConfirmationDialog(deleteConfirmationInitialState)}
            open={confirmationDialog.open}
            actions={{ agree: 'Yes', disagree: 'No' }}
        >
            {confirmationDialog.message}
            {confirmationDialog.img?.incident_image?.thumbnail && <div className='smallImageConfirmation'><img src={confirmationDialog.img?.incident_image?.thumbnail} style={{ width: "120px", height: "auto" }} /></div>}
        </MatConfirmationDialog>
        const handleDeleteUploaded = (): void => {
            setConfirmationDialog({
                open: true,
                agreeCallback: () => {
                    deleteDoc();
                    setConfirmationDialog(deleteConfirmationInitialState);
                },
                message: `Are you sure you want to remove the currently uploaded riddor report?`,
            });
        }

        const DeleteButton = () => (
            <Button
                disabled={!data.submitted_report?.id || !preFlightDocInfo?.privileges?.POST} onClick={() => {
                    // note that as we're getting the privileges from the 'list' route, DELETE would not be true, 
                    // but for all practical purposes we can assume for the forseeable that create permissions come 
                    // with delete permissions
                    handleDeleteUploaded();
                }}
            >
                Remove Uploaded Report
            </Button>
        )

        const DownloadButton = () => (
            <a className='downLoad' href={data.submitted_report?.download_link}>
                <Button
                    disabled={!data.submitted_report?.download_link} type="button"
                >
                    Download
                </Button>
            </a>

        )

        const UploadActionButtons = () => {
            return <div className='riddor-report-actions-row'>
                <div className='uploaderActionButtons'>
                    {showDownloadButton && <DownloadButton />}
                    {showDeleteButton && <DeleteButton />}
                </div>
            </div>
        }
        const thisExt = getExtensionFromURLString(data.submitted_report?.file);
        console.log('thisExt: ', thisExt);
        if (thisExt === 'pdf') {
            return <>
                <RenderDeleteConfirmationDialogue />
                <WorkPermitPDFViewer
                    inModal={false}
                    isOpen={!!data.submitted_report}
                    url={data.submitted_report?.file}
                    pdfWrapperClass='portrait'
                    height={1250}
                />
                <UploadActionButtons />
            </>
        } else if (thisExt && ['jpg', 'png', 'svg', 'jpeg'].includes(thisExt)) {
            return <>
                <RenderDeleteConfirmationDialogue />
                <img src={data.submitted_report?.file} width="100%" style={{ padding: "1rem" }} />
                <UploadActionButtons />
            </>
        } else {
            return <>
                <>
                    The stored report is of an unsupported file type
                </>
                <RenderDeleteConfirmationDialogue />
            </>
        }

    } else {
        return <></>
    }
}

export default RiddorSubmittedReportViewer;