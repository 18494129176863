import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { withResizeDetector } from "react-resize-detector";

import { Portfolio } from 'components/Portfolios/Interfaces/Portfolios.interface';
import {
  SiteIndexWrapper,
  StyledSiteSub,
  StyledSiteTitle,
  StyledImageWrapper,
  StyledTextWrapper
} from "./PortfolioCardFrontStyles";
import { ContractCard } from 'components/IndexPage/Interfaces/CardList.interface';
import IconButton from "../../../Common/Components/IconButton/IconButton";
import { useWindowSize } from "../../../Common/Hooks/useWindowSize";
import { selectHeader } from '../../../Layout/selectors/Layout.selectors';
import { useSelector } from 'react-redux';
import { HighlightText } from '../../../Common/Components/HighlightText/HighlightText';

import styles from '../../../../styles/app/common/variables.scss';
import "../../../../styles/app.scss";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    minHeight: "200px",
    maxWidth: "540px",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden"
  }
}));

// interface CardProps {
//   site: ContractCard;
//   width: number;
//   height?: number;
//   onFavourite: { (): void };
// }

interface PortfolioCardFrontProps {
  data: Portfolio;
  width: number;
  height?: number;
  onFavourite: () => void;
}

const PortfolioCardFront = ({
  data,
  width,
  onFavourite
}: PortfolioCardFrontProps) => {
  const classes = useStyles();
  const selectedHeader = useSelector(selectHeader);
  const [windowWidth] = useWindowSize();

  const handleFavourite = (event: MouseEvent) => {
    event.stopPropagation();
    onFavourite();
  };

  return (
    <SiteIndexWrapper
      className="fadeIn"
      favourite={data.favourite}
    >
      <Paper elevation={3} className={classes.paper}>
        <IconButton
          onClick={(id, button, event) => handleFavourite(event)}
          icon="fas fa-star"
          margin="none"
          active={true}
          style={{ zIndex: 3 }}
        />
        <StyledImageWrapper width={windowWidth}>
          <img alt={`Building ${data.name}`} src={data.portfolio_image.thumbnail} />
        </StyledImageWrapper>
        <StyledTextWrapper>
          <StyledSiteTitle width={width}>
            <HighlightText
              color={styles.brandGoldDarker}
              text={data.name}
              matchText={selectedHeader.search}
            />
          </StyledSiteTitle>
          <StyledSiteSub>
            {" "}
            {data.contracts?.length} Contract(s){" "}
          </StyledSiteSub>
        </StyledTextWrapper>
      </Paper>
    </SiteIndexWrapper>
  );
};

export default withResizeDetector(memo(PortfolioCardFront));
