import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
//import { useSelector } from 'react-redux';

// Own
//import { broadcastLayoutUpdateSelector } from "components/Profile/Selectors/Profile.selector";
import { periodInitialConfig } from "components/Common/Components/PeriodChooser/PeriodChooser";
import PeriodChooser from "components/Common/Components/PeriodChooser/PeriodChooser";

// Styles
import { HASSubheadingWrapper, HASSubheadingSpacer } from 'components/Layout/Contract/Containers/ContractSubHeading/ContractSubHeadingStyles.js';
import 'components/Layout/Contract/Containers/ContractSubHeading/ContractSubHeading.scss';
import { useScrollDirection } from 'components/Layout/CustomHooks/LayoutHooks';
import { SubHeading } from "components/Layout/Contract/Components/ContractSubSite/ContractSubHeadingSiteStyles.js";

const HASCentreSubHeader = ({ initialStartDate, initialEndDate, period, setPeriod, periodChooserClass }: periodInitialConfig) => {
    const scrollDirection = useScrollDirection();
    const headerSpacerInViewOptions = useRef({
        threshold: 0,
    });
    const { ref: subHeaderRef, inView: subHeadingSpacerInView, entry: subHeaderEntry } = useInView({ ...headerSpacerInViewOptions.current });
    //const layoutUpdateBroadcast = useSelector(broadcastLayoutUpdateSelector);
    //const [showHamburgerMenu, setShowHamburgerMenu] = useState<boolean>();
    const [showSubHeading, setShowSubheading] = useState<boolean>(false);

    const getLeftMargin = useCallback((): string => {
        // //const sideBarHidden = isPDFRequest || store.getState().profile?.data?.hide_contract_sidebar;
        // //const sideBarCollapsed = store.getState().profile?.data?.collapse_contract_sidebar;
        // const sideBarHidden = isPDFRequest || store.getState().personalSettings?.data?.hide_contract_sidebar;
        // const sideBarCollapsed = store.getState().personalSettings?.data?.collapse_contract_sidebar;
        // if (sideBarHidden) {
        //     !showHamburgerMenu && setShowHamburgerMenu(true);
        //     return "4rem";
        // } else {
        //     showHamburgerMenu && setShowHamburgerMenu(false);
        // }
        // if (sideBarCollapsed) {
        //     return "4rem";
        // }
        return "4rem";
    }, []);

    const leftMarginRef = useRef(getLeftMargin());
    const [leftMargin, setLeftMargin] = useState<string>(leftMarginRef.current);

    useEffect(() => {
        setLeftMargin(getLeftMargin());
    }, [])

    useEffect(() => {
        if (scrollDirection.isScrollingUp) {
            setShowSubheading(true);
        } else if (scrollDirection.isScrollingDown) {
            setShowSubheading(false);
        }
    }, [scrollDirection])

    return (
        <>
            <HASSubheadingSpacer ref={subHeaderRef} />
            <HASSubheadingWrapper id="HASCentreSubHeading" style={{
                paddingLeft: leftMargin,
                display: (subHeadingSpacerInView || showSubHeading) ? 'flex' : 'None',
            }}>
                <SubHeading>
                    Health and Safety Centre
                </SubHeading>

                <PeriodChooser
                    period={period}
                    setPeriod={setPeriod}
                    initialStartDate={initialStartDate}
                    initialEndDate={initialEndDate}
                />

            </HASSubheadingWrapper>
        </>
    );
}

export default HASCentreSubHeader;