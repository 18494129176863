import { Organisation } from "../Interfaces/Organisation";
import { splitOrganisationsAndMembers, splitSingleOrganisationAndMembers, getOrganisationLookup as getLookup } from "./Organisation.reducer.helper";
import { AdminState } from "../../Interfaces/Admin.state";
import { fetchReducer } from "components/AdminPanel/Helpers/Entity.helper";
import { isStateEqual } from "store/reducers/reducer.helper";
import { Dictionary } from "components/Common/Interfaces/Entity.interface";
import * as actions from "../Actions/Organisation.actions";
import {
  removePsuedoRecordFromState,
  createPsuedoRecord,
  mergeChildObjectsByKey,
  prepareMainListViewsAfterCreate
} from "components/AdminPanel/Reducers/adminReducer.helper";

const initialState = {
  data: {},
  members: {},
  lookup: {},
  recordmeta: {},
  meta: {
    PUT: undefined,
    POST: undefined
  },
  options: []
};

interface OrganisationState extends AdminState {
  data: Dictionary<Organisation>;
  members: Dictionary<any[]>;
}

const organisationReducer = (state: OrganisationState = initialState, action: any) => {
  switch (action.type) {
    case actions.FETCH_ORGANISATIONS.success: {
      return fetchReducer(state, action, 'organisations', getLookup);
    }

    case actions.SET_GROUP_ORGANISATION.reducer: {
      const { data, members } = splitOrganisationsAndMembers(action.organisationGroup.data);
      const newState = {
        ...state,
        data: mergeChildObjectsByKey(state.data, data),
        members: {
          ...state.members,
          ...members
        },
        recordmeta: {
          ...state.recordmeta,
          ...action.organisationGroup.meta,
        }
      }
      const equal = isStateEqual(state, newState);
      //console.log('equal: ', equal);
      if (!equal) {
        return newState;
      }
      return state;
    }

    case actions.SET_PSUEDO_ORGANISATION.reducer: {
      // HT Note: should this be abstracted to SET_PSUEDO_ADMIN_RECORD?
      return createPsuedoRecord(state);
    }

    case actions.CLEAR_PSUEDO_ORGANISATION.reducer: {
      return removePsuedoRecordFromState(state);
    }

    case actions.SET_ORGANISATION.success: {
      // HT TODO: check how this works differently to SET_GROUP_ORGANISATION above, and whether we should be maintaining both or whether 
      // group can be reused
      const { preparedObj, preparedData } = prepareMainListViewsAfterCreate(state.data, action.organisation, action);
      const { data, members } = splitSingleOrganisationAndMembers(preparedObj);
      //const dataDiff = logObjDifferences(state.data[action.organisation.id], preparedObj);
      const newState = {
        ...state,
        data: {
          ...preparedData,
          [action.organisation.id]: data,
        },
        members: {
          ...state.members,
          [action.organisation.id]: members,
        },
        // THIS SHOULD BE DERIVED - REMOVE - 
        // HT TODO: check where it would be derived and if not obvious consult with Dan
        lookup: {
          ...state.lookup,
          [action.organisation.id]: getLookup(action.organisation)
        },
        meta: {
          ...state.meta,
        },
        recordmeta: {
          ...state.recordmeta,
          [action.organisation.id]: { ...action.meta },
        },
        options: action.options,
        permissions: {
          ...state.permissions,
          [action.organisation.id]: action.permissions
        },
      };
      const equal = isStateEqual(state, newState);
      if (!equal) {
        return newState;
      }
      return state;
    }

    case actions.SET_ORGANISATION.reducer: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.organisation.id]: {
            ...action.organisation,
          }
        },
        // THIS SHOULD BE DERIVED - REMOVE
        lookup: {
          ...state.lookup,
          [action.organisation.id]: getLookup(action.organisation)
        }
      };
    }

    case actions.SET_ORGANISATION_INDUSTRIES.reducer: {

      return {
        ...state,
        industries: action.industries,
      };
    }

    case actions.DELETE_ORGANISATION.success: {

      delete state.data[action.organisationId];

      return {
        ...state,
        data: {
          ...state.data,
        }
      };
    }

    case actions.DELETE_ORGANISATION.reducer: {

      delete state.data[action.organisationId];
      delete state.lookup[action.organisationId];

      if (state.recordmeta) {
        delete state.recordmeta[action.organisationId];
      }

      return {
        ...state,
        data: {
          ...state.data,
        }
      };
    }

    case actions.ADD_ORGANISATION_MEMBER.reducer: {
      return {
        ...state,
        members: {
          ...state.members,
          [action.organisationId]: [...(state.members[action.organisationId] || []), action.personId]
        }
      };
    }

    case actions.REMOVE_ORGANISATION_MEMBER.reducer: {
      return {
        ...state,
        members: {
          ...state.members,
          [action.organisationId]: (state.members[action.organisationId] || []).filter(id => id !== action.personId)
        }
      };
    }

    default:
      return state;
  }
};

export default organisationReducer;
