import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from "lodash";

// Own
import { visitReportColumns } from 'components/Schedulers/VisitModal/Models/VisitReportsColumns';
import { Visit, SupplierService } from 'components/Schedulers/Interfaces/Schedule.interfaces';
import DocumentsGrid from "components/Common/Components/DocumentsGrid/DocumentsGrid";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { PreFlightListInfo } from "components/Common/Interfaces/Entity.interface";
import { getPreFlightInfo } from 'components/ContractInFocus/Services/commonAPI.services';
import DocumentsUploader from "components/Common/Components/DocumentsUploader/DocumentUploader";
import { getVisitVisitReportsRoute } from "components/Schedulers/Services/visitService";
import { ContractDocumentFilesWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { GeneralDocsGridWrapper } from 'components/SiteDocuments/Components/ContractDocumentsViewGrid/ContractDocumentGridStyles';
import { profileOrganisationSelector } from 'components/Profile/Selectors/Profile.selector';


interface VisitReportsProps {
    visit: Visit;
    refreshVisit: () => void;
    supplierService: SupplierService;
    portfolio?: HydratedPortfolio;
}

const VisitReports = ({ visit, refreshVisit, supplierService, portfolio }: VisitReportsProps) => {

    // const visitReportBasePath = getSupplierServiceVisitReportsRoute({
    //     portfolioId: portfolio?.id,
    //     contractId: supplierService?.contract,
    //     supplierServiceId: supplierService?.id
    // })
    const visitReportBasePath = getVisitVisitReportsRoute(visit.id);
    const [fetchFilters, setFetchFilters] = useState({ visit: visit.id })
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 0);
    const [preFlightInfo, setPreFlightInfo] = useState<PreFlightListInfo>();
    const selectProfileOrganisationFromState = useSelector(profileOrganisationSelector);
    const refresh = useCallback(() => {
        refreshVisit()
        forceUpdate()
    }, [forceUpdate, refreshVisit])

    const getFieldFormConfig = useCallback((): FieldsFormConfig => {
        return {
            'visit_document_category': {
                forceRequired: true
            },
            "document_ref": {
                forceRequired: true,
            },
            'internal_access_only': {
                sideEffect: (values, fieldConfigs, onChangeFormValues, previousValues) => {
                    let changed = false;
                    const ssow_categories = ["RAMS", "method_statement", "risk_assessment", "emergency_rescue_plan"];
                    const category = values?.visit_document_category as string;
                    const is_ssow_doc = category && ssow_categories.includes(category);
                    const config = fieldConfigs['internal_access_only'];
                    if (is_ssow_doc && values.internal_access_only) {
                        values.internal_access_only = false;
                        changed = true;
                        fieldConfigs['internal_access_only'].disabled = true;
                    }
                    if (is_ssow_doc && !config.disabled) {
                        fieldConfigs['internal_access_only'].disabled = true;
                        changed = true;
                    } else if (!is_ssow_doc && config.disabled) {
                        fieldConfigs['internal_access_only'].disabled = false;
                        changed = true;
                    }
                    return changed;
                },
            },
            'notes': {
                forceRequired: false,
                label: 'Description'
            },
            'visit': {
                hidden: true,
                defaultValue: visit.id
            },
            'permit': {},
            'author_organisation': {
                hidden: true,
                defaultValue: selectProfileOrganisationFromState
            }
        }
    }, [visit, selectProfileOrganisationFromState]);

    const fieldsConfig = getFieldFormConfig();

    // useEffect(() => {
    //     fetchSupplierVisitReportsPreFlightInfo({ portfolioId: portfolio?.id, contractId: supplierService?.contract, supplierServiceId: supplierService.id }).then((newPreFlightInfo) => {
    //         if (!isEqual(preFlightInfo, newPreFlightInfo)) {
    //             setPreFlightInfo(newPreFlightInfo);
    //         }
    //     });
    // }, [portfolio?.id, preFlightInfo, supplierService?.id, supplierService?.contract]);

    useEffect(() => {
        getPreFlightInfo(visitReportBasePath).then((newPreFlightInfo) => {
            if (!isEqual(preFlightInfo, newPreFlightInfo)) {
                setPreFlightInfo(newPreFlightInfo);
            }
        });
    }, [visit, visitReportBasePath, preFlightInfo]);

    return <GeneralDocsGridWrapper>
        {visitReportColumns && <ContractDocumentFilesWrapper height={!preFlightInfo?.canCreate ? 70 : 60}>
            <DocumentsGrid
                listBasePath={visitReportBasePath}
                hideSource
                columnsConfig={visitReportColumns}
                fetchFilters={fetchFilters}
                refreshSignal={mustRefresh}
                actionColumnsWidth={100}
            />
        </ContractDocumentFilesWrapper>}

        {preFlightInfo?.canCreate && visitReportBasePath &&
            <DocumentsUploader
                urlContext={visitReportBasePath}
                fieldConfigs={fieldsConfig}
                preFlightInfo={preFlightInfo}
                gridId="VisitDetailDocumentsUploader" //DetailDocumentsUploader
                reset={refresh}
                zIndex={1300}
            />
        }
    </GeneralDocsGridWrapper>
}

export default VisitReports;