import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { useSelector } from 'react-redux';

// Own
import { checkSpecificSupportingDocumentColumns } from 'components/Schedulers/HASDetailModal/Models/HASCheckSupportingDocumentColumns';
import DocumentsGrid from "components/Common/Components/DocumentsGrid/DocumentsGrid";
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { PreFlightListInfo } from "components/Common/Interfaces/Entity.interface";
import DocumentsUploader from "components/Common/Components/DocumentsUploader/DocumentUploader";
import { getHASContractTaskSupportingDocumentsRoute } from "components/AdminPanel/HAS/HASTaskServices";
import { ContractDocumentFilesWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { GeneralDocsGridWrapper } from 'components/SiteDocuments/Components/ContractDocumentsViewGrid/ContractDocumentGridStyles';
import { profileOrganisationSelector } from 'components/Profile/Selectors/Profile.selector';
import { HASTaskCheck } from "components/AdminPanel/HAS/Interfaces/HASTaskInterfaces";
import { getDataAndMetaAndPUTMeta } from "services/API/API.helper";

import {
    VisitDetailWrapper,
    VisitReportsWrapper
} from 'components/Schedulers/VisitModal/Components/VisitDetail/VisitDetailStyles';

interface DocumentProps {
    hasCheck: HASTaskCheck;
    contractTaskId: string;
    portfolioId?: string | number;
    dispatchRefreshContext?: React.DispatchWithoutAction | undefined;

}

const Documents = ({ hasCheck, contractTaskId, portfolioId, dispatchRefreshContext }: DocumentProps) => {

    const basePath = getHASContractTaskSupportingDocumentsRoute({
        portfolioId: portfolioId,
        contractTaskId: contractTaskId // if this is for a contract, contract inferred from this id
    })
    const [fetchFilters, setFetchFilters] = useState({ h_a_s_check: hasCheck.id })
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 0);
    const [preFlightInfo, setPreFlightInfo] = useState<PreFlightListInfo>();
    const selectProfileOrganisationFromState = useSelector(profileOrganisationSelector);
    const refresh = useCallback(() => {
        forceUpdate()
        dispatchRefreshContext && dispatchRefreshContext()
    }, [forceUpdate, dispatchRefreshContext])
    const [documentsResponse, setDocumentsResponse] = useState<any>();

    const getFieldFormConfig = useCallback((): FieldsFormConfig => {
        return {
            'h_a_s_supporting_document_type': {
                forceRequired: true
            },
            'internal_access_only': {},
            'notes': {
                forceRequired: true,
                label: 'Description'
            },
            'h_a_s_check': {
                hidden: true,
                defaultValue: hasCheck ? hasCheck.id : undefined
            },
            'author_organisation': {
                hidden: true,
                defaultValue: selectProfileOrganisationFromState
            }
        }
    }, [hasCheck, selectProfileOrganisationFromState]);

    const fieldsConfig = getFieldFormConfig();

    useEffect(() => {
        if (documentsResponse) {
            // here we'll re-use the response loaded by the documents grid.  This reduces the requests executed but also means
            // we get the documents meta for the current check - currently this will affect if a statutory document
            // can be uploaded or not.
            const info = getDataAndMetaAndPUTMeta(documentsResponse);
            setPreFlightInfo({ meta: info.meta, canCreate: !!info.permissions?.POST, canRead: true })
        }

    }, [documentsResponse]);

    return <VisitDetailWrapper>
        <VisitReportsWrapper>
            <GeneralDocsGridWrapper>
                {checkSpecificSupportingDocumentColumns && <ContractDocumentFilesWrapper height={!preFlightInfo?.canCreate ? 70 : 60}>
                    <DocumentsGrid
                        listBasePath={basePath}
                        hideSource
                        columnsConfig={checkSpecificSupportingDocumentColumns}
                        fetchFilters={fetchFilters}
                        refreshSignal={mustRefresh}
                        actionColumnsWidth={100}
                        setResponse={setDocumentsResponse}
                        surrogate_portfolio_id={portfolioId}
                        dispatchRefreshContext={dispatchRefreshContext}
                    />
                </ContractDocumentFilesWrapper>}

                {preFlightInfo?.canCreate && basePath && hasCheck && documentsResponse &&
                    <>
                        <DocumentsUploader
                            urlContext={basePath}
                            fieldConfigs={fieldsConfig}
                            preFlightInfo={preFlightInfo}
                            gridId="DetailDocumentsUploader"
                            reset={refresh}
                            zIndex={1300}
                            surrogatePortfolioId={portfolioId}
                        />
                        <div className="statutory-template-document-links">
                            <span>{
                                hasCheck.latest_statutory_document_template_view_link ?
                                    <a className='statutory-document-link view'
                                        href={hasCheck.latest_statutory_document_template_view_link
                                        }
                                    >View the latest statutory template document</a> : <></>
                            }</span>
                            {
                                hasCheck.latest_statutory_document_template_download_link ?
                                    <span><a
                                        className='statutory-document-link view'
                                        href={hasCheck.latest_statutory_document_template_download_link}
                                    >Download the latest statutory template document
                                    </a></span> : <></>
                            }
                        </div>

                    </>

                }
            </GeneralDocsGridWrapper>
        </VisitReportsWrapper>
    </VisitDetailWrapper>
}

export default Documents;