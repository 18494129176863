import React from 'react';
import {
  Chart,
  Series,
  CommonSeriesSettings,
  Legend,
  ValueAxis,
  ArgumentAxis,
  Label,
  Font,
  Tooltip
} from 'devextreme-react/chart';

// Own
import { chartsFontProps, colourSequence } from 'constants/chart.constants';

// Styles
// import styles from '../../../../../styles/app/common/variables.scss';

type PPMReactiveChartProps = {
  data: any[];
};

const ReactiveByCategoryChart = ({ data }: PPMReactiveChartProps) => {
  const customizeTooltip = (arg: any) => ({
    text: `${arg.seriesName} jobs: ${arg.valueText}`
  });

  const calcBarPadding = data.length < 4 ? 0.5 : 0; // 1 reduce : 0 fill

  return (
    <div id='data-chart'>
      <Chart 
        id='chart' 
        rotated={true} 
        dataSource={data}
        animation={false}
      >
        <CommonSeriesSettings 
          argumentField='name' 
          type='bar'
          barPadding={calcBarPadding}
          
        />
        <Series 
          color={colourSequence[1]} 
          valueField='count' 
          name='Total' 
        />
        <ValueAxis position="left">
          <Label> <Font {...chartsFontProps} /> </Label>
        </ValueAxis>        
        <ArgumentAxis>
          <Label> <Font {...chartsFontProps} /> </Label>
        </ArgumentAxis>        
        <Legend
          visible={false}
          verticalAlignment='bottom'
          horizontalAlignment='center'
          itemTextPosition='top'
        />
        <Tooltip
          enabled={true}
          location='edge'
          customizeTooltip={customizeTooltip}
        />
      </Chart>
      <div style={{ pageBreakBefore: 'always' }}></div>
    </div>
  );
};

export default ReactiveByCategoryChart;
