import React, { PureComponent } from "react";
import { ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import { adminPanelType } from "components/AdminPanel/Models/AdminPanel.model.js";
import IconButton from "../../../Common/Components/IconButton/IconButton";
import { showMainTargetListOnlyInSelectionMode } from "store/SelectedEntities/selectEntitySettings";

import "./AdminPanelHeader.scss";

export class AdminPanelHeader extends PureComponent {
  // if H&S admin only then
  // 1) Only show H&S Tab
  // 2) Only show contract link actions if H&S Admin writer
  // 3) Allow contract read if H&S writer so contracts can be listed.
  onClick = buttonId => this.props.onChangeTab(buttonId);

  onClickClose = () => this.props.onClose();

  handlePanelClone = panelId => this.props.onClone(panelId);

  isActive = buttonId => this.props.activeTabId === buttonId;

  // if(!this.props.selectionConfig.showTargetListOnly==undefined){

  // }

  showMainTargetListOnly =
    this.props.selectionConfig?.showTargetListOnly ||
    showMainTargetListOnlyInSelectionMode;

  onlyShowTab =
    this.showMainTargetListOnly && this.props.selectionConfig
      ? this.props.selectionConfig.linkedRecordType
      : null;

  onlyShowTabs = this.props.onlyShowTabs;

  static propTypes = {
    panelId: PropTypes.string,
    activeTabId: PropTypes.string,
    onClose: PropTypes.func,
    onChangeTab: PropTypes.func,
    onClone: PropTypes.func,
    selectionConfig: PropTypes.object,
    onlyShowTabs: PropTypes.array
  };

  render = () => {
    let showOrganisations = true;
    let showPeople = true;
    let showSites = true;
    let showContracts = true;
    let showPortolios = true;
    let showHAS = true;
    if (this.onlyShowTab) {
      showOrganisations = this.onlyShowTab === adminPanelType.organisations;
      showPeople = this.onlyShowTab === adminPanelType.people;
      showSites = this.onlyShowTab === adminPanelType.sites;
      showContracts = this.onlyShowTab === adminPanelType.contracts;
      showPortolios = this.onlyShowTab === adminPanelType.portfolios;
      showHAS = this.onlyShowTab === adminPanelType.h_a_s;
    } else if (this.onlyShowTabs) {
      showOrganisations = this.onlyShowTabs.includes(
        adminPanelType.organisations
      );
      showPeople = this.onlyShowTabs.includes(adminPanelType.people);
      showSites = this.onlyShowTabs.includes(adminPanelType.sites);
      showContracts = this.onlyShowTabs.includes(adminPanelType.contracts);
      showPortolios = this.onlyShowTabs.includes(adminPanelType.portfolios);
      showHAS = this.onlyShowTabs.includes(adminPanelType.h_a_s);
    }
    return (
      <ModalHeader
        data-test-id="adminPanelHeader"
        id="adminPanelHeader"
        className={this.props.selectionConfig ? "task-mode" : ""}
        toggle={this.toggleModal}
      >
        <div className="admin-panel-tabs">
          {showOrganisations && (
            <IconButton
              icon={"fas fa-users"}
              clickable={true}
              id={adminPanelType.organisations}
              active={this.isActive(adminPanelType.organisations)}
              onClick={this.onClick.bind(this)}
              whiteIcon={this.props.selectionConfig ? true : null}
            />
          )}
          {showPeople && (
            <IconButton
              icon={"fas fa-user"}
              clickable={true}
              id={adminPanelType.people}
              active={this.isActive(adminPanelType.people)}
              onClick={this.onClick.bind(this)}
              whiteIcon={this.props.selectionConfig ? true : null}
            />
          )}
          {showSites && (
            <IconButton
              icon={"fas fa-building"}
              clickable={true}
              id={adminPanelType.sites}
              active={this.isActive(adminPanelType.sites)}
              onClick={this.onClick.bind(this)}
              whiteIcon={this.props.selectionConfig ? true : null}
            />
          )}
          {showContracts && (
            <IconButton
              icon={"fas fa-briefcase"}
              clickable={true}
              id={adminPanelType.contracts}
              active={this.isActive(adminPanelType.contracts)}
              onClick={this.onClick.bind(this)}
              whiteIcon={this.props.selectionConfig ? true : null}
            />
          )}
          {showPortolios && (
            <IconButton
              icon={"fas fa-city"}
              clickable={true}
              id={adminPanelType.portfolios}
              active={this.isActive(adminPanelType.portfolios)}
              onClick={this.onClick.bind(this)}
              whiteIcon={this.props.selectionConfig ? true : null}
            />
          )}
          {showHAS && (
            <IconButton
              icon={"fas fa-medkit"}
              clickable={true}
              id={adminPanelType.h_a_s}
              active={this.isActive(adminPanelType.h_a_s)}
              onClick={this.onClick.bind(this)}
              whiteIcon={this.props.selectionConfig ? true : null}
            />
          )}
        </div>

        <div className="admin-panel-title">
          {this.props.selectionConfig && (
            <h2> {this.props.selectionConfig.linkPanelTitle} </h2>
          )}
        </div>
        <div className="admin-panel-controls">
          <IconButton
            icon="far fa-clone"
            id="clone"
            onClick={() => this.handlePanelClone(this.props.panelId)}
            whiteIcon={this.props.selectionConfig ? true : null}
          />

          <IconButton
            margin="none"
            icon="fa fa-times"
            id="close"
            onClick={this.onClickClose.bind(this)}
            whiteIcon={this.props.selectionConfig ? true : null}
          />
        </div>
      </ModalHeader>
    );
  };
}

export default AdminPanelHeader;
