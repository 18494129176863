/* eslint-disable */
Function.prototype.bind =
  Function.prototype.bind ||
  function(thisp) {
    console.log("polyfilled");
    var fn = this;
    return function() {
      return fn.apply(thisp, arguments);
    };
  };
