import React, { useState, useEffect, useCallback } from "react";
import DataGrid, { Column, Editing, RequiredRule } from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";

// Own
import CommonAPIService from "components/ContractInFocus/Services/commonAPI.services";
import { InlineWrapper } from 'components/ContractInFocus/Styles/CommonStyles';
import GridHeader from "components/Common/Components/GridHeader/GridHeader.js";
import { getSubTitle } from "components/ContractInFocus/Components/ContractPrintTitle/ContractPrintTitle";
import { ColumnProps, DataGridMeta } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';
import { getColumnProps, editableTextAreaOnPreparing, getGridProps } from 'helpers/DataGrid/DataGridColumn.helper';
import { columnPropsPlaceHolder } from 'components/ContractInFocus/Models/ColumnProps';
import { gridMetaInitialState } from 'components/ContractInFocus/Models/Grid';
import { PrintChartAndTableLabels } from "components/Common/constants.js";
import { HotWorksPTW, HotWorkIsolationSystem } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";

interface HotWorkIsolationProps {
    hot_works_permit: HotWorksPTW;
    title: string;
}

const HotWorkIsolations: React.FC<HotWorkIsolationProps> = ({
    hot_works_permit,
    title
}) => {
    const getBaseEndpoint = useCallback(() => `hot-work-ptws/${hot_works_permit.id}/isolation-systems/?format=json`, [hot_works_permit]);
    const getEndpoint = getBaseEndpoint;
    const [dataSource, setDataSource] = useState<DataSource>();
    const [metadata, setMetadata] = useState<DataGridMeta>(gridMetaInitialState);
    const [contentReady, setContentReady] = useState(false);
    const thisKey = hot_works_permit.id;

    useEffect(() => {
        const custom = new CustomStore({
            key: "id",
            load: loadOptions => {
                let params: any;
                const thisData = CommonAPIService.getAll<HotWorkIsolationSystem>(
                    getEndpoint,
                    setMetadata,
                    thisKey,
                    params
                )
                return thisData;
            },
            insert: values => {
                const theseValues: HotWorkIsolationSystem = { hot_works_ptw: thisKey, ...values }
                return CommonAPIService.create<any>({ getEndpoint, values: theseValues })
            },
            // @ts-ignore
            remove: key => {
                return CommonAPIService.del<any>(getEndpoint, thisKey, key)
            },
            update: (id, values) => {
                return CommonAPIService.update<any>(getEndpoint, thisKey, id, values)
            }
        });

        setDataSource(
            new DataSource({
                store: custom
            })
        );
    }, [getEndpoint, thisKey]);

    // const handleDateColumns = (data: any): void => {
    //     ["target_completion_date", "completion_date"].map(
    //         (x) => {
    //             if (data[x]) {
    //                 data[x] = saveDateFormat(data[x])
    //             }
    //         }
    //     )
    // };

    // const handleRowInserting = (values: any): void => {
    //     handleDateColumns(values.data);
    // };

    // const handleRowUpdating = (values: any): void => {
    //     handleDateColumns(values.newData);
    // }

    const getColumnPropsExt = useCallback((field: string): ColumnProps | undefined => {
        return metadata.loaded ? getColumnProps(field, metadata.activeMeta) : columnPropsPlaceHolder;
    }, [metadata])

    const handleRowUpdated = () => {
        if (metadata) {
            setMetadata({ ...metadata, activeMeta: metadata.POSTMeta });
        }
    }

    const renderColIfMetaDataDefined = useCallback((fieldName: string, requiredOveride: boolean, colPropsOverride = {}) => {
        const metaColProps = getColumnPropsExt(fieldName);
        if (metaColProps) {
            const colProps = { ...metaColProps, ...colPropsOverride }
            return (<Column {...colProps}>
                {requiredOveride && <RequiredRule />}
            </Column>
            )
        }
    }, [getColumnPropsExt])

    return (
        <InlineWrapper>
            <GridHeader
                title={title}
                className={`${PrintChartAndTableLabels ? '' : 'no-print'}`}
                subTitle={getSubTitle(metadata)}
            />
            {dataSource && (
                <DataGrid
                    className='no-print'
                    dataSource={dataSource}
                    //onRowInserting={handleRowInserting}
                    //onRowUpdating={handleRowUpdating}
                    onRowUpdated={handleRowUpdated}
                    {...getGridProps(metadata.activeMeta)}
                    onEditorPreparing={editableTextAreaOnPreparing(metadata.activeMeta)}
                    onContentReady={() => setContentReady(true)}
                    showColumnHeaders
                >
                    <Editing
                        mode="cell"
                        allowUpdating={metadata.privileges.PUT}
                        allowDeleting={metadata.privileges.DELETE}
                        allowAdding={metadata.privileges.POST}
                    />
                    {renderColIfMetaDataDefined('system', true)}
                    {renderColIfMetaDataDefined('panel', true)}
                    {renderColIfMetaDataDefined('device_number', false)}
                </DataGrid>
            )}
        </InlineWrapper>
    );
};

export default HotWorkIsolations;