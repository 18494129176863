import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import DataGrid, { Scrolling, Column, Editing, Paging, } from 'devextreme-react/data-grid';
import { Paper } from '@material-ui/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

// Own
import CommonAPIService from "components/ContractInFocus/Services/commonAPI.services";
import Header from 'components/Common/Components/GridHeader/GridHeader';
import PrintDataGrid from "components/Common/Components/PrintDataGrid/PrintDataGrid";
import { getSubTitle } from "components/ContractInFocus/Components/ContractPrintTitle/ContractPrintTitle";
import { ContractSpendSettingsI } from '../../Interfaces/spend.interfaces';
import { InlineWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { getColumnProps } from 'helpers/DataGrid/DataGridColumn.helper';
import { ColumnProps, DataGridMeta } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';
import { columnPropsPlaceHolder } from 'components/ContractInFocus/Models/ColumnProps';
import { gridMetaInitialState } from "components/ContractInFocus/Models/Grid";
import { contractSpendSettingsColumns } from '../../Models/spendColumns';
import { getContractScopeParams } from 'helpers/Pipelines/contractScopeOperator';
import * as selectors from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import { currencyColumnFormat } from 'constants/grid.constants';
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { PrintChartAndTableLabels } from "components/Common/constants.js";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";


type ContractSpendSettingsProps = {
    id?: string;
    includeMarkup: boolean;
    //contract: ContractInterface;
    contract?: SiteContract;
    portfolio?: HydratedPortfolio;
    refreshSpendAt?: number;
    title: string;
    dataTestId: string;
};

const ContractSpendSettings: React.FC<ContractSpendSettingsProps> = ({
    id = 'ContractSpendSettings',
    includeMarkup,
    contract,
    portfolio,
    title,
    dataTestId,
    refreshSpendAt
}) => {
    //const selectedSnapshot = useSelector(selectors.contractInFocusSnapshotFocusSelector);
    const selectedSnapshot = useSelector(selectors.contractOrPortfolioSnapshotSelector({ portfolioId: portfolio?.id, contractId: contract?.id }));
    //const selectFocusedContractPeriod = useSelector(selectors.contractInFocusFocusedContractPeriodSelector);
    const selectFocusedPeriod = useSelector(selectors.contractOrPortfolioPeriodSelector({ portfolioId: portfolio?.id, contractId: contract?.id }));

    const [dataSource, setDataSource] = useState<DataSource>();
    const [spendSettings, setSpendSettings] = useState<ContractSpendSettingsI[]>([]);
    const [metadata, setMetadata] = useState<DataGridMeta>(gridMetaInitialState);
    const [, setContentReady] = useState(false);

    useEffect(() => {
        if ((contract || portfolio) && selectFocusedPeriod) {
            let getEndpoint = (id: any) => 'get-contract-spend-settings/never/';
            if (portfolio?.id) {
                getEndpoint = (portfolioId?: number) => `portfolios/${portfolioId}/contract-spend-settings/?format=json${includeMarkup ? '&markup=1' : ''}`;
            } else {
                getEndpoint = (contractRef?: string | number) => `contracts/${contractRef}/contract-spend-settings/?format=json${includeMarkup ? '&markup=1' : ''}`;
            }

            const custom = new CustomStore({
                key: 'id',
                load: loadOptions => {
                    return CommonAPIService.getAll<ContractSpendSettingsI>(
                        getEndpoint,
                        setMetadata,
                        portfolio?.id || contract?.contract_ref,
                        getContractScopeParams(selectFocusedPeriod, selectedSnapshot)
                    ).then(({ data }) => {
                        const finalData = data?.length ? data : []
                        setSpendSettings(finalData);
                        return { data: finalData };
                    })
                },
            });

            setDataSource(
                new DataSource({
                    store: custom
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contract, refreshSpendAt, includeMarkup]);

    const getColumnPropsExt = (field: string): ColumnProps => {
        return metadata.loaded ? getColumnProps(field, metadata.GETMeta) : columnPropsPlaceHolder;
    }

    return (
        <InlineWrapper
            id={id}
            pageBreakAfter={true}
            data-testid={dataTestId}
        >
            <Paper elevation={3}>
                <>
                    <Header
                        title={`${title} Table`}
                        subTitle={getSubTitle(metadata)}
                        className={`${PrintChartAndTableLabels ? null : 'no-print'}`}
                    />
                    {/* <PrintDataGrid
                        meta={metadata.GETMeta}
                        visibleColumns={contractSpendSettingsColumns}
                        records={spendSettings}
                    /> */}
                </>

                <DataGrid
                    style={{ maxHeight: '800px' }}
                    className='no-print'
                    dataSource={dataSource}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    onContentReady={() => setContentReady(true)}
                    showColumnHeaders={true}
                >
                    <Paging enabled={false} />
                    <Column {...getColumnPropsExt('id')} visible={false} />
                    {portfolio && <Column {...getColumnPropsExt('contract_ref')} width={125} caption="Contract Ref" />}
                    <Column {...getColumnPropsExt('contract_margin')} width={125} caption="Margin (%)" />
                    <Column {...getColumnPropsExt('consumable_budget')} format={currencyColumnFormat} caption="Consumable Or Managed Budget" />
                    <Column {...getColumnPropsExt('comprehensive_budget')} format={currencyColumnFormat} />
                    <Column {...getColumnPropsExt('comprehensive_limit')} format={currencyColumnFormat} />
                </DataGrid>
            </Paper>
        </InlineWrapper>
    );
};

export default ContractSpendSettings;
