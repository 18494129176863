import React, { useEffect, useState, useRef, useMemo, useReducer, useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import UndoIcon from '@material-ui/icons/Undo';

import { store } from "store/store";
import { AutoInput } from 'components/Common/Components/AutoInput/AutoInput';
import { FieldMetaGroup, FormValues } from "components/Common/Interfaces/Entity.interface";
import { FieldsFormConfig } from "components/Common/Components/DocumentsGrid/DocumentsGrid.interface";
import { ScheduleResponse } from 'components/Schedulers/Interfaces/Schedule.interfaces';
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { onResponseError } from "services/API/API.interceptor.js";
import { addNotification } from 'components/Notification/Actions/Notification.actions';
import { NOTIFICATION_SUCCESS, NOTIFICATION_WARNING, NOTIFICATION_ERROR } from 'components/Notification/Constants/constants';
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { ParseAPIResponse } from "services/Interface/Interface";

import "components/Schedulers/Components/NotesDetail/NoteFormStyles.scss";
import { removeReadOnlyFields } from 'services/API/API.helper';

interface NotesDetailProps {
    meta: FieldMetaGroup;
    row: any;
    contractRef: string;
    portfolioId?: string | number;
    previewRow: boolean;
    notesStore: FormValues;
    dispatchRefreshContext: React.DispatchWithoutAction;
    updateNotes: (props: any) => Promise<ParseAPIResponse<any>>
}

const DetailNotesView = ({
    meta,
    row,
    previewRow,
    notesStore,
    contractRef,
    portfolioId,
    dispatchRefreshContext,
    updateNotes
}: NotesDetailProps) => {

    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 1);
    const currentFocus = useRef<string>('notes');

    const thisRowData = useRef(row.data.data);

    useEffect(() => {
        if (notesStore[thisRowData.current.id] === undefined) {
            notesStore[thisRowData.current.id] = thisRowData.current['notes'];
        }
    }, [notesStore]);

    const formValuesRef = useRef<FormValues>({ 'notes': notesStore[thisRowData.current.id] });
    const checkNotesChanged = useCallback(() => notesStore[thisRowData.current.id] !== thisRowData.current.notes, [notesStore])

    const notesFormConfig: FieldsFormConfig = {
        'notes': {
            multiline: true,
            rows: 3,
            controlClassName: 'darker-disabled'
        }
    };

    const [notesChanged, setNotesChanged] = useState(checkNotesChanged());
    const updateNotesChanged = useCallback(() => {
        setNotesChanged(checkNotesChanged());
    }, [setNotesChanged, checkNotesChanged]);

    useEffect(() => {
        formValuesRef.current['notes'] = notesStore[thisRowData.current.id];
        updateNotesChanged() // this makes sure it is re-evaluated after save (so styles of save/revert button are updated)
    }, [notesStore, formValuesRef, updateNotesChanged, thisRowData.current.id])

    const save = (e: any) => {
        if (!previewRow) {
            let combinedData = { ...thisRowData.current, notes: notesStore[thisRowData.current.id] as string }
            combinedData = removeReadOnlyFields(combinedData, meta);
            meta && updateNotes({
                contractRef: contractRef,
                portfolioId: portfolioId,
                id: thisRowData.current.id,
                payload: combinedData,
                meta: meta
            }).then((response) => {
                store.dispatch(addNotification({ message: "Saved", type: NOTIFICATION_SUCCESS }))
                console.log('response.data?.notes: ', response.data?.notes);
                notesStore[thisRowData.current.id] = thisRowData.current.notes = response.data?.notes;
                updateNotesChanged();
                dispatchRefreshContext();
            }).catch(error => {
                console.log('e: ', e);
                e?.component?.cancelEditData();
                onResponseError(error)
            });
        }
    }

    return <div className="notes-wrapper">
        {notesChanged && !previewRow && <>
            <IconButton className="undo" onClick={() => {
                formValuesRef.current['notes'] = thisRowData.current.notes;
                notesStore[thisRowData.current.id] = thisRowData.current.notes;
                updateNotesChanged();
                forceUpdate();
            }}>
                <UndoIcon />
            </IconButton>
            <IconButton className="save" onClick={save}>
                <SaveIcon />
            </IconButton>
        </>}
        {mustRefresh && <AutoInput
            zIndex={1200}
            key="notes"
            dataField="notes"
            //@ts-ignore
            fieldConfig={notesFormConfig['notes']}
            fieldMeta={meta["notes"]}
            formValuesRef={formValuesRef}
            refreshSignal={mustRefresh}
            onChangeFormValues={(newValues) => {
                notesStore[thisRowData.current.id] = newValues['notes'];
                formValuesRef.current['notes'] = newValues['notes'];
                updateNotesChanged();
            }}
            currentFocus={currentFocus}
            dispatchRefreshContext={forceUpdate}
            showReadOnly
        />}
    </div>
}

export default DetailNotesView;