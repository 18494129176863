
// export const getObjectDiff = (obj1, obj2) => {
//   const diff = Object.keys(obj1).reduce((result, key) => {
//     if (!obj2.hasOwnProperty(key)) {
//       result.push(key);
//     } else if (obj1[key] === obj2[key]) {
//       const resultKeyIndex = result.indexOf(key);
//       result.splice(resultKeyIndex, 1);
//     }
//     return result;
//   }, Object.keys(obj2));

//   return diff;
// };

export const getDiffBetweenArrayAndDictionary = (source: any[], key: string, dictionary: any) => source.filter(item => !dictionary[item[key]]);

export const getIdDiffBetweenArrayAndDictionary = (source: any[], dictionary: any) => getDiffBetweenArrayAndDictionary(source, 'id', dictionary);

export const getDiffBetweenArrays = (source: any[], target: any[], key: string) => source.filter(item => !target.find(t => t[key] === item[key]));