import { HAS_ACCIDENTS_ROUTE, HAS_NEAR_MISSES_ROUTE, HAS_RIDDOR_ROUTE } from "services/API/common/globalAPIs";
import { PreFlightListInfo } from 'components/Common/Interfaces/Entity.interface';
import API, { APIN, getMultipartConfig } from "services/API/API";

import { getPreFlightListMeta, preFlightCanCreateCheck } from "services/API/API.helper";
import { getDataAndMetaAndPUTMeta, getDataAndMeta } from "services/API/API.helper";
import { onResponseError } from "services/API/API.interceptor.js";
import { FieldInfo } from "components/Common/Components/FormFieldComments/FormFieldComments";
import { addNotification } from 'components/Notification/Actions/Notification.actions';
import { NOTIFICATION_SUCCESS } from 'components/Notification/Constants/constants';
import { store } from "store/store";
import { getContractOrPortfolioOrBaseRoute, GetContractOrPortfolioChildProps, ContractIdOrPortfolioId } from "services/API/common/contractAPIs";
import { IHASEventLog, IncidentFieldType } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";

export function getAccidentRoute(id?: string | number) {
    let route = HAS_ACCIDENTS_ROUTE;
    if (id) {
        route = `${route}${id}/`;
    }
    return route;
}

export function getRiddorRoute(id?: string | number) {
    let route = HAS_RIDDOR_ROUTE;
    if (id) {
        route = `${route}${id}/`;
    }
    return route;
}

export function getAccidentRiddorRoute(accidentId?: string | number) {
    let route = getAccidentRoute(accidentId);
    if (accidentId) {
        route = `${route}riddor/`;
    }
    return route;
}

export function getNearMissesRoute(id?: string | number) {
    let route = HAS_NEAR_MISSES_ROUTE;
    if (id) {
        route = `${route}${id}/`;
    }
    return route;
}

interface GetContractOrPortfolioIncidentRouteProps extends ContractIdOrPortfolioId {
    incidentType: IncidentFieldType
    id: string | number;
}

export function getContractOrPortfolioIncidentRoute({ contractRef, portfolioId, id, incidentType }: GetContractOrPortfolioIncidentRouteProps) {
    let route = incidentType === "accident" ? HAS_ACCIDENTS_ROUTE : HAS_NEAR_MISSES_ROUTE;
    if (id) {
        route = `${route}${id}`;
    }
    route = getContractOrPortfolioOrBaseRoute({ contractId: contractRef, portfolioId: portfolioId, endpoint: route })
    return route;
}

export function getAccidentWitnessStatementsRoute(accidentId: string) {
    return `${getAccidentRoute(accidentId)}witness-statements/`;
}

export function getRiddorQualificationsListRoute(id?: string | number) {
    return `${getRiddorRoute(id)}ap-qualifications/`;
}

export function getRiddorTrainingCourseListRoute(id?: string | number) {
    return `${getRiddorRoute(id)}ap-training-courses/`;
}

export function getAccidentRelevantUsers(accidentId: string) {
    const route = `${getAccidentRoute(accidentId)}relevant-users/`;
    return API.get(route).then((response: any) => response);
}

export function getAccidentCheckListRoute(id?: string | number) {
    return `${getAccidentRoute(id)}check-list/`;
}

export function getAccidentCheckList(accidentId: string) {
    const route = getAccidentCheckListRoute(accidentId);
    return API.get(route).then((response: any) => response);
}

export function getNearMissRelevantUsers(nearMissId: string) {
    const route = `${getNearMissesRoute(nearMissId)}relevant-users/`;
    return API.get(route).then((response: any) => response);
}

export const simpleFetchIncidentInFocus = ({ contractRef, portfolioId, id, incidentType }: GetContractOrPortfolioIncidentRouteProps) => {
    const route = getContractOrPortfolioIncidentRoute({ contractRef: contractRef, portfolioId, id, incidentType });
    // a contract user should be verified against the contract
    return API.get(route).then((response: any) => {
        return getDataAndMetaAndPUTMeta(response);
    });
}

export function getAccidentImagesRoute(accidentId: string) {
    return `${getAccidentRoute(accidentId)}images/`;
}

export function getNearMissImagesRoute(nearMissId: string) {
    return `${getNearMissesRoute(nearMissId)}images/`;
}

export function getAccidentDocumentsRoute(accidentId: string) {
    return `${getAccidentRoute(accidentId)}documents/`;
}

export function getEventLogNestedAccidentDocumentsRoute(accidentId: string) {
    return `${getAccidentRoute(accidentId)}event-log-documents/`;
}

export function getNearMissDocumentsRoute(nearMissId: string) {
    return `${getNearMissesRoute(nearMissId)}documents/`;
}

export function getEventLogNestedNearMissDocumentsRoute(nearMissId: string) {
    return `${getNearMissesRoute(nearMissId)}event-log-documents/`;
}

interface IncidentChildPreFlightProps {
    parentId: string;
}

export const fetchAccidentWitnessStatementsPreFlightInfo = (props: IncidentChildPreFlightProps): Promise<PreFlightListInfo> => {
    let route = getAccidentWitnessStatementsRoute(props.parentId);
    return APIN.options(route).then((response) => {
        const meta = getPreFlightListMeta(response); //preflight uses the 'options' verb
        const canCreate = preFlightCanCreateCheck(response);
        return { meta, canCreate, canRead: true }
    }).catch(error => {
        if (error.response?.status == 403) {
            return { meta: {}, canCreate: false, canRead: false }
        } else {
            return onResponseError(error)
        }
    });
}

export const fetchRiddorQualificationsPreFlightInfo = (props: IncidentChildPreFlightProps): Promise<PreFlightListInfo> => {
    let route = getRiddorQualificationsListRoute(props.parentId);
    return APIN.options(route).then((response) => {
        const meta = getPreFlightListMeta(response); //preflight uses the 'options' verb
        const canCreate = preFlightCanCreateCheck(response);
        return { meta, canCreate, canRead: true }
    }).catch(error => {
        if (error.response?.status == 403) {
            return { meta: {}, canCreate: false, canRead: false }
        } else {
            return onResponseError(error)
        }
    });
}

export const fetchRiddorTrainingCoursesPreFlightInfo = (props: IncidentChildPreFlightProps): Promise<PreFlightListInfo> => {
    let route = getRiddorTrainingCourseListRoute(props.parentId);
    return APIN.options(route).then((response) => {
        const meta = getPreFlightListMeta(response); //preflight uses the 'options' verb
        const canCreate = preFlightCanCreateCheck(response);
        return { meta, canCreate, canRead: true }
    }).catch(error => {
        if (error.response?.status == 403) {
            return { meta: {}, canCreate: false, canRead: false }
        } else {
            return onResponseError(error)
        }
    });
}

export const fetchAccidentImagesPreFlightInfo = (props: IncidentChildPreFlightProps): Promise<PreFlightListInfo> => {
    let route = getAccidentImagesRoute(props.parentId);
    return APIN.options(route).then((response) => {
        const meta = getPreFlightListMeta(response); //preflight uses the 'options' verb
        const canCreate = preFlightCanCreateCheck(response);
        return { meta, canCreate, canRead: true }
    }).catch(error => {
        if (error.response?.status == 403) {
            return { meta: {}, canCreate: false, canRead: false }
        } else {
            return onResponseError(error)
        }
    });
}

export const fetchNearMissImagesPreFlightInfo = (props: IncidentChildPreFlightProps): Promise<PreFlightListInfo> => {
    let route = getNearMissImagesRoute(props.parentId);
    return APIN.options(route).then((response) => {
        const meta = getPreFlightListMeta(response); //preflight uses the 'options' verb
        const canCreate = preFlightCanCreateCheck(response);
        return { meta, canCreate, canRead: true }
    }).catch(error => {
        if (error.response?.status == 403) {
            return { meta: {}, canCreate: false, canRead: false }
        } else {
            return onResponseError(error)
        }
    });
}

export const fetchAccidentDocumentsPreFlightInfo = (props: IncidentChildPreFlightProps): Promise<PreFlightListInfo> => {
    let route = getAccidentDocumentsRoute(props.parentId);
    return APIN.options(route).then((response) => {
        const meta = getPreFlightListMeta(response); //preflight uses the 'options' verb
        const canCreate = preFlightCanCreateCheck(response);
        return { meta, canCreate, canRead: true }
    }).catch(error => {
        if (error.response?.status == 403) {
            return { meta: {}, canCreate: false, canRead: false }
        } else {
            return onResponseError(error)
        }
    });
}

export const fetchNearMissDocumentsPreFlightInfo = (props: IncidentChildPreFlightProps): Promise<PreFlightListInfo> => {
    let route = getNearMissDocumentsRoute(props.parentId);
    return APIN.options(route).then((response) => {
        const meta = getPreFlightListMeta(response); //preflight uses the 'options' verb
        const canCreate = preFlightCanCreateCheck(response);
        return { meta, canCreate, canRead: true }
    }).catch(error => {
        if (error.response?.status == 403) {
            return { meta: {}, canCreate: false, canRead: false }
        } else {
            return onResponseError(error)
        }
    });
}

export const getAccidentFieldInfoRoute = (parentId: string) => getAccidentRoute(parentId) + 'field-info/';
export const simpleFetchAccidentFieldInfoList = (parentId: string) => {
    const route = getAccidentFieldInfoRoute(parentId);
    // a contract user should be verified against the contract
    return API.get(route).then((response: any) => {
        return getDataAndMetaAndPUTMeta(response);
    });
}

export const getRiddorFieldInfoRoute = (parentId: string) => getRiddorRoute(parentId) + 'field-info/';
export const simpleFetchRiddorFieldInfoList = (parentId: string) => {
    const route = getRiddorFieldInfoRoute(parentId);
    // a contract user should be verified against the contract
    return API.get(route).then((response: any) => {
        return getDataAndMetaAndPUTMeta(response);
    });
}

export const getNearMissFieldInfoRoute = (parentId: string) => getNearMissesRoute(parentId) + 'field-info/';
export const simpleFetchNearMissFieldInfoList = (parentId: string) => {
    const route = getNearMissFieldInfoRoute(parentId);
    // a contract user should be verified against the contract
    return API.get(route).then((response: any) => {
        return getDataAndMetaAndPUTMeta(response);
    });
}

export const simpleUpdateFieldInfo = ({ parentId, fieldInfoId, payload, fieldType }: { parentId: string, fieldInfoId: string, payload: Partial<FieldInfo>, fieldType: string }) => {
    console.log('fieldType: ', fieldType);
    let baseRoute = "never-update-field-info";
    switch (fieldType) {
        case "accident": {
            baseRoute = getAccidentFieldInfoRoute(parentId);
        }
            break;
        case "near_miss": {
            baseRoute = getNearMissFieldInfoRoute(parentId);
        }
            break;
        case "riddor": {
            baseRoute = getRiddorFieldInfoRoute(parentId);
        }
            break;
        default: {
            baseRoute = "never-update-field-info";
        }
    }
    //const baseRoute = fieldType == "accident" ? getAccidentFieldInfoRoute(parentId) : getNearMissFieldInfoRoute(parentId);
    const route = baseRoute + `${fieldInfoId}/`;
    return API.patch(route, payload).then((response) => {
        store.dispatch(addNotification({ message: "Saved", type: NOTIFICATION_SUCCESS }))
        return getDataAndMetaAndPUTMeta(response);
    });
}

export const simpleGetAccidentFieldInfo = ({ parentId, fieldInfoId }: { parentId: string, fieldInfoId: string }) => {
    const route = getAccidentFieldInfoRoute(parentId) + `${fieldInfoId}/`;
    return API.get(route).then((response) => {
        return getDataAndMeta(response);
    });
}

export const simpleGetRiddor = ({ accidentId }: { accidentId: string }) => {
    const route = getAccidentRiddorRoute(accidentId);
    return API.get(route).then((response) => {
        //return getDataAndMeta(response);
        return getDataAndMetaAndPUTMeta(response);
    });
}

export const simpleGetAccidentEventLog = ({ accidentId }: { accidentId: string }) => {
    let route = `${getAccidentRoute(accidentId)}event-logs/`;
    return API.get(route).then((response) => {
        return getDataAndMetaAndPUTMeta(response);
    });
}

export const simpleUpdateAccidentEventLog = ({ accidentId, id, payload }: { accidentId: string, id: string, payload: Partial<IHASEventLog> }) => {
    let route = `${getAccidentRoute(accidentId)}event-logs/${id}/`;
    return API.patch(route, payload).then((response) => {
        store.dispatch(addNotification({ message: "Saved", type: NOTIFICATION_SUCCESS }))
        return getDataAndMetaAndPUTMeta(response);
    });
}

export const simpleGetNearMissFieldInfo = ({ parentId, fieldInfoId }: { parentId: string, fieldInfoId: string }) => {
    const route = getNearMissFieldInfoRoute(parentId) + `${fieldInfoId}/`;
    return API.get(route).then((response) => {
        return getDataAndMeta(response);
    });
}

export const simpleGetNearMissEventLog = ({ nearMissId }: { nearMissId: string }) => {
    let route = `${getNearMissesRoute(nearMissId)}event-logs/`;
    return API.get(route).then((response) => {
        return getDataAndMetaAndPUTMeta(response);
    });
}

export const simpleUpdateNearMissEventLog = ({ nearMissId, id, payload }: { nearMissId: string, id: string, payload: Partial<IHASEventLog> }) => {
    let route = `${getNearMissesRoute(nearMissId)}event-logs/${id}/`;
    return API.patch(route, payload).then((response) => {
        store.dispatch(addNotification({ message: "Saved", type: NOTIFICATION_SUCCESS }))
        return getDataAndMetaAndPUTMeta(response);
    });
}

export const simpleGetRiddorFieldInfo = ({ parentId, fieldInfoId }: { parentId: string, fieldInfoId: string }) => {
    const route = getRiddorFieldInfoRoute(parentId) + `${fieldInfoId}/`;
    return API.get(route).then((response) => {
        return getDataAndMeta(response);
    });
}


export const updateInjuryDiagram = (id: string, image: any, attribute: string) => {
    const getFileObject = () => {
        const fd = new FormData();
        fd.append(attribute, image);
        return fd;
    }
    return API.patch(`${HAS_ACCIDENTS_ROUTE}${id}/`, image ? getFileObject() : { [attribute]: null }, image ? getMultipartConfig() : {}).then(
        (response) => {
            return getDataAndMeta(response);
        }
    )
}

export const clearInjuryDiagram = (id: any, attribute: string) => updateInjuryDiagram(id, null, attribute);