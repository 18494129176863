import { contractsEndpoint, portfoliosEndpoint } from "services/API/common/contractAPIs";
import { forkJoin } from "rxjs";
import { APIR, APIN } from "services/API/API";
import { unWrapListDataAndMeta, arrayToDict } from "services/API/API.helper";
import { ContractPeriod } from 'components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface';
import { map, catchError } from "rxjs/operators";
import { DeleteReactiveCategory, UpdateReactiveCategory, NewReactiveCategory } from "../Interfaces/reactiveByCategory.interfaces";
import { ReportConfig } from "../Interfaces/interactiveTable.interfaces";
import { transformForReactiveCategoryGridAndChart, generateColumnHeaders, reactiveCategoryPrimaryColumn } from "../Helper/ReactiveByCategory.helpers";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { addErrorNotice } from "components/Notification/helper/notification.helper";
import { reactiveCategoryOptionsAPI } from "services/API/common/optionAPIs";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { ContractIdOrPortfolioId, ContractOrPortfolio } from "services/API/common/contractAPIs";
import { PreFlightListInfo } from 'components/Common/Interfaces/Entity.interface';
import { getPreFlightListMeta, preFlightCanCreateCheck } from "services/API/API.helper";
import { onResponseError } from "services/API/API.interceptor.js"

interface getReactiveByCategoryInterface {
  reportConfig: ReportConfig,
  contract?: SiteContract,
  portfolio?: HydratedPortfolio,
  contractPeriod: ContractPeriod,
  subdivision: string,
  params?: any
}

interface baseGetReactiveByCategoryInterface extends getReactiveByCategoryInterface {
  url: string,
}

// Makes a double request, one for the reactive by category and another for the options
function baseGetReactiveByCategory(
  {
    reportConfig,
    contract,
    portfolio,
    contractPeriod,
    subdivision,
    url,
    params
  }: baseGetReactiveByCategoryInterface
) {
  const reactiveRequests = forkJoin({
    reactiveByCategory: APIR.get(url, { params }).pipe(unWrapListDataAndMeta()),
    reactiveCategoryOptions: reactiveCategoryOptionsAPI()
  });

  return reactiveRequests.pipe(
    // TODO: get rid of this double request and just use the choices in the meta
    // TODO: sort this typing out
    map(({ reactiveByCategory, reactiveCategoryOptions }: { reactiveByCategory: any, reactiveCategoryOptions: any }) => {
      const { data, meta, permissions } = reactiveByCategory;
      const noReactiveData = data.length === 0 || !data[0] || data[0] && !data[0][subdivision];
      const dataPayload = noReactiveData ? [] : data[0][subdivision];
      const emptyDataPayload = { chart: {}, table: [], matrix: [] };
      return {
        data: dataPayload.length ? transformForReactiveCategoryGridAndChart(dataPayload, contractPeriod, reactiveCategoryPrimaryColumn.name, portfolio) : emptyDataPayload,
        meta,
        columnMeta: generateColumnHeaders(contractPeriod),
        permissions,
        reactiveCategoryOptions: {
          dictionary: arrayToDict(reactiveCategoryOptions.data),
          list: reactiveCategoryOptions.data
        }
      }
    }),
    catchError(error => addErrorNotice(`getReactiveByCategory: ${error}`))
  );

}

interface GetReactiveByCategoryEndpointProps extends ContractOrPortfolio {
  baseEndpoint: string;
}

const getReactiveByCategoryEndpoint = (props: GetReactiveByCategoryEndpointProps) => {
  let route = '';
  if (props.portfolio) {
    return `${portfoliosEndpoint(props.baseEndpoint, props.portfolio?.id)}?format=json`;
  }
  else {
    return `${contractsEndpoint(props.baseEndpoint, props.contract?.contract_ref)}?format=json`;
  }
}

export const getReactiveByCategory = (
  {
    reportConfig,
    contract,
    portfolio,
    contractPeriod,
    subdivision,
    params,
  }: getReactiveByCategoryInterface

) => {
  const url = portfolio ? `${portfoliosEndpoint(reportConfig.endpoint, portfolio.id)}?format=json` : `${contractsEndpoint(reportConfig.endpoint, contract?.contract_ref)}?format=json`;
  return baseGetReactiveByCategory({ url, reportConfig, contract, contractPeriod, subdivision, params, portfolio });
};

export const reactiveByCategoryUpdate = ({ config, contract, portfolio, id, value }: UpdateReactiveCategory
) => {
  let url;
  if (portfolio) {
    url = `${portfoliosEndpoint(config.endpoint, portfolio.id)}${id}/`;
  } else {
    url = `${contractsEndpoint(config.endpoint, contract.contract_ref)}${id}/`;
  }
  return APIR.patch(url, { value }).pipe(
    catchError(error => addErrorNotice(`reactiveByCategoryUpdate: ${error}`))
  );
};

export const reactiveByCategoryDelete = ({ config, contract, portfolio, id }: DeleteReactiveCategory
) => {
  let url;
  if (portfolio) {
    url = `${portfoliosEndpoint(config.endpoint, portfolio.id)}${id}/`;
  } else {
    url = `${contractsEndpoint(config.endpoint, contract?.contract_ref)}${id}/`;
  }
  return APIR.delete(url).pipe(
    catchError(error => addErrorNotice(`reactiveByCategoryDelete: ${error}`))
  );
};

export const reactiveByCategoryNew = (newEntity: NewReactiveCategory, portfolio?: HydratedPortfolio) => {
  const newRecord = {
    ...newEntity,
    contract: newEntity.contract.id,
  };
  let url;
  if (portfolio) {
    url = `${portfoliosEndpoint(newEntity.config.endpoint, portfolio.id)}`;
  } else {
    url = `${contractsEndpoint(newEntity.config.endpoint, newEntity.contract.contract_ref)}`;
  }
  return APIR.post(url, newRecord).pipe(
    catchError(error => addErrorNotice(`reactiveByCategoryNew: ${error}`))
  );
};

interface getReactiveByCategoryPreFlightInterface {
  reportConfig: ReportConfig,
  contract?: SiteContract,
  portfolio?: HydratedPortfolio,
}
export const simpleFetchReactiveTaskByCategoryPreFlightInfo = ({ reportConfig, portfolio, contract }: getReactiveByCategoryPreFlightInterface): Promise<PreFlightListInfo> => {
  let route = getReactiveByCategoryEndpoint({
    baseEndpoint: reportConfig.endpoint,
    portfolio,
    contract
  });
  return APIN.options(route).then((response) => {
    //console.log('response to options: ', response);
    const meta = getPreFlightListMeta(response); //preflight uses the 'options' verb
    const canCreate = preFlightCanCreateCheck(response);
    return { meta, canCreate, canRead: true }
  }).catch(error => {
    if (error.response?.status == 403) {
      return { meta: {}, canCreate: false, canRead: false }
    } else {
      return onResponseError(error)
    }
  });
}

export const relegateReactiveContractCategory = ({ endpoint, contract, portfolio, reactive_category_option }: ToggleRelegateReactiveByCategoryProps) => {
  let url = `never-relegate-${endpoint}`;
  if (portfolio) { // contract will be passed even if portfolio is passed, so this option MUST come first
    url = `${portfoliosEndpoint(endpoint, portfolio.id)}`;
  } else if (contract) { // contract will be passed even if portfolio is passed, so this option MUST be an 'else'
    url = `${contractsEndpoint(endpoint, contract.contract_ref)}`;
  }
  const payload = {
    contract: contract?.id,
    reactive_issue_category_option: reactive_category_option
  }
  return APIR.post(url, payload).pipe(
    catchError(error => addErrorNotice(`reactiveByCategoryNew: ${error}`))
  );
}

export const restoreReactiveContractCategory = ({ endpoint, contract, portfolio, id }: ToggleRelegateReactiveByCategoryProps) => {
  let url = `never-restore-${endpoint}`;;
  if (portfolio) { // contract will be passed even if portfolio is passed, so this option MUST come first
    url = `${portfoliosEndpoint(endpoint, portfolio.id)}${id}`;
  } else if (contract) { // contract will be passed even if portfolio is passed, so this option MUST be an 'else'
    url = `${contractsEndpoint(endpoint, contract.contract_ref)}${id}`;
  }
  return APIR.delete(url).pipe(
    catchError(error => addErrorNotice(`reactiveByCategoryNew: ${error}`))
  );
}

// export const toggleRelegateReactiveByCategory = ({ pointer, contract, portfolio, id, hidden }: ToggleRelegateReactiveByCategoryProps) => {
//   let url = 'never-toggle-relegate-reactive-category';
//   if (portfolio) { // contract will be passed even if portfolio is passed, so this option MUST come first
//     url = `${portfoliosEndpoint(pointer, portfolio.id)}`;
//   } else if (contract) { // contract will be passed even if portfolio is passed, so this option MUST be an 'else'
//     url = `${contractsEndpoint(pointer, contract.contract_ref)}`;
//   }
//   const payload = hidden ? {
//     add_to_relegated_options_list: [id],
//     contract: contract?.id,
//     reactive_issue_category_option: id
//   } : { remove_from_relegated_options_list: [id], contract: contract?.id, reactive_issue_category_option: id }
//   return APIR.post(url, payload).pipe(
//     catchError(error => addErrorNotice(`reactiveByCategoryNew: ${error}`))
//   );
// }



export interface LocalReactiveByCategoryProps extends ContractOrPortfolio {
  id?: string,
  reactive_category_option?: string,
}

export interface ToggleRelegateReactiveByCategoryProps extends LocalReactiveByCategoryProps {
  endpoint: string,
}

export const toggleRelegateReactiveByCategory = ({ endpoint, contract, portfolio, reactive_category_option, id }: ToggleRelegateReactiveByCategoryProps) => {
  if (id) {
    return restoreReactiveContractCategory({ endpoint, contract, portfolio, id });
  } else {
    return relegateReactiveContractCategory({ endpoint, contract, portfolio, reactive_category_option });
  }
}