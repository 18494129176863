import React, { useState, useEffect, useReducer, useCallback, useRef } from 'react';
import { isEqual } from "lodash";
import { useSelector } from 'react-redux';

// Own
import DocumentsGrid from "components/Common/Components/DocumentsGrid/DocumentsGrid";
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { PreFlightListInfo } from "components/Common/Interfaces/Entity.interface";
import DocumentsUploader from "components/Common/Components/DocumentsUploader/DocumentUploader";
import { getAccidentWitnessStatementsRoute, fetchAccidentWitnessStatementsPreFlightInfo } from "components/AdminPanel/HAS/HASIncidentServices"
import { ContractDocumentFilesWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { GeneralDocsGridWrapper } from 'components/SiteDocuments/Components/ContractDocumentsViewGrid/ContractDocumentGridStyles';
import { profileOrganisationSelector } from 'components/Profile/Selectors/Profile.selector';
import { IHASAccident } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";
import { healthAndSafetyAdminAccessSelector, internalMemberSelector } from 'components/Profile/Selectors/Profile.selector';

interface AccidentWitnessStatementProps {
    data: IHASAccident;
}

const AccidentWitnessStatement = ({ data }: AccidentWitnessStatementProps) => {
    const id: string = data.id;

    const basePath = getAccidentWitnessStatementsRoute(id);
    const [fetchFilters, setFetchFilters] = useState();
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 0);
    const [preFlightInfo, setPreFlightInfo] = useState<PreFlightListInfo>();
    const refresh = useCallback(forceUpdate, [forceUpdate])
    const selectProfileOrganisationFromState = useSelector(profileOrganisationSelector);
    const [fieldsConfig, setFieldsConfig] = useState<FieldsFormConfig>();
    const HASAdminAccess = useSelector(healthAndSafetyAdminAccessSelector);
    const internalMember = useSelector(internalMemberSelector);
    const modelColumns = useRef({
        'name': { width: '20%' },
        'address': { width: '20%' },
        'telephone': { width: '15%' },
        'internal_access_only': {
            visible: internalMember,
            width: '125px'
        },
        'h_a_s_department_only': {
            visible: HASAdminAccess,
            caption: "H&S staff only",
            width: '125px'
        }
    });


    useEffect(() => {
        const config = {
            // 'notes': {
            //     label: 'Description',
            //     forceRequired: true
            // },
            'accident': {
                hidden: true,
                defaultValue: id
            },
            'author_organisation': {
                hidden: true,
                defaultValue: selectProfileOrganisationFromState
            },
            'internal_access_only': {},
            'h_a_s_department_only': { hidden: !HASAdminAccess },
            'name': {},
            'address': {},
            'telephone': {}
        }
        setFieldsConfig(config);
    }, [id, selectProfileOrganisationFromState, HASAdminAccess]);

    useEffect(() => {
        if (!preFlightInfo) {
            fetchAccidentWitnessStatementsPreFlightInfo({ parentId: id }).then((newPreFlightInfo) => {
                if (!isEqual(preFlightInfo, newPreFlightInfo)) {
                    setPreFlightInfo(newPreFlightInfo);
                }
            });
        }
    }, [preFlightInfo, id]);

    return <GeneralDocsGridWrapper className='witnessStatementsWrapper field-group-wrapper'>
        <h3 className='field-group-title'>Witness Statements</h3>
        <div className='witnessDocumentsBody'>
            {modelColumns.current && <ContractDocumentFilesWrapper height={!preFlightInfo?.canCreate ? 30 : 20}>
                <DocumentsGrid
                    listBasePath={basePath}
                    hideSearch
                    hideFilters
                    hideSource
                    columnsConfig={modelColumns.current}
                    fetchFilters={fetchFilters}
                    editMode="row"
                    refreshSignal={mustRefresh}
                    //actionColumnsWidth={100}
                    updateRecordMetaBeforeEdit
                />
            </ContractDocumentFilesWrapper>
            }

            {preFlightInfo?.canCreate && basePath && fieldsConfig &&
                <DocumentsUploader
                    urlContext={basePath}
                    fieldConfigs={fieldsConfig}
                    preFlightInfo={preFlightInfo}
                    gridId="AccidentWitnessStatementUploader"
                    reset={forceUpdate}
                    zIndex={1300}
                //inAppViewingLocationOverride="admin"
                />
            }
        </div>

    </GeneralDocsGridWrapper>
}

export default React.memo(AccidentWitnessStatement);