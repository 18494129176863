import { map, tap } from 'rxjs/operators';

// Own
import API, { APIR, APIN } from 'services/API/API'
import { APIResult } from 'components/Common/Interfaces/Entity.interface';
import { Snapshot } from 'components/ContractInFocus/interfaces/contractInFocus.interfaces';
import { onResponseError } from "services/API/API.interceptor.js"
import { CONTRACT_ROUTE, PORTFOLIO_ROUTE } from "services/API/common/globalAPIs";
import { getPreFlightListMeta, preFlightCanCreateCheck, getListData, getListMeta, getListPrivileges } from "services/API/API.helper";
import { PreFlightListInfo } from 'components/Common/Interfaces/Entity.interface';
import { getSnapshotPublicationsRoute, APISnapshotProps } from "services/API/common/contractAPIs";
import { ContractIdOrPortfolioId, ContractOrPortfolio } from "services/API/common/contractAPIs";
import { getData } from "services/API/API.helper";
interface SnapshotProps extends ContractIdOrPortfolioId {
  payload: any;
}

export const createSnapshot = (props: SnapshotProps) => {
  console.log('createSnapshot called, props: ', props);
  let route = 'create-snapshot/never/';
  if (props.portfolioId) {
    route = `/portfolios/${props.portfolioId}/frozen-for-list/`
  }
  if (props.contractId) {
    route = `/contracts/${props.contractId}/frozen-for-list/`
  }
  return API.post<APIResult<Snapshot>>(route, props.payload).then(
    (response) => {
      console.log('response in tap: ', response);
      return response.data
    })
}


interface PublishSnapshotPostProps extends APISnapshotProps {
  payload: any;
}

export const publishSnapshot = (props: PublishSnapshotPostProps) => {
  const route = getSnapshotPublicationsRoute(props);
  return API.post<APIResult<Snapshot>>(route, props.payload).then((response) => {
    return getData(response)
  }
  )
}

interface SnapshotDocumentPreFlightProps extends ContractIdOrPortfolioId {
  portfolioEndpoint?: string;
}

export const simpleFetchSnapshotDocumentPreFlightInfo = (props: SnapshotDocumentPreFlightProps): Promise<PreFlightListInfo> => {
  let route = 'fetch-snapshots-preflight/never/';
  if (props.contractId) {
    route = `${CONTRACT_ROUTE}${props.contractId}/snapshot-documents/`;
  }
  if (props.portfolioId) {
    route = `${PORTFOLIO_ROUTE}${props.portfolioId}/${props.portfolioEndpoint}/`;
  }
  return APIN.options(route).then((response) => {
    const meta = getPreFlightListMeta(response); //preflight uses the 'options' verb
    const canCreate = preFlightCanCreateCheck(response);
    return { meta, canCreate, canRead: true }
  }).catch(error => {
    if (error.response?.status == 403) {
      return { meta: {}, canCreate: false, canRead: false }
    } else {
      return onResponseError(error)
    }
  });
}

export const simpleFetchPublishSnapshotPreFlightInfo = (props: APISnapshotProps): Promise<PreFlightListInfo> => {
  let route = getSnapshotPublicationsRoute(props);
  return APIN.options(route).then((response) => {
    //console.log('response to options: ', response);
    const meta = getPreFlightListMeta(response); //preflight uses the 'options' verb
    const canCreate = preFlightCanCreateCheck(response);
    return { meta, canCreate, canRead: true }
  }).catch(error => {
    if (error.response?.status == 403) {
      return { meta: {}, canCreate: false, canRead: false }
    } else {
      return onResponseError(error)
    }
  });
}

export const simpleFetchSnapshotPublications = (props: APISnapshotProps) => {
  let route = getSnapshotPublicationsRoute(props);
  return API.get(route).then((response) => {
    const snapshotPublications = {
      data: getListData(response),
      meta: getListMeta(response),
      privileges: getListPrivileges(response),
    }
    //console.log('snapshotPublications: ', snapshotPublications);
    return snapshotPublications
  });
}

export default {
  createSnapshot,
  simpleFetchSnapshotPublications,
  simpleFetchSnapshotDocumentPreFlightInfo,
  simpleFetchPublishSnapshotPreFlightInfo
}