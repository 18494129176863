import { createRequestActionTypes } from "components/Common/Utils/ReduxUtil";
import { PortfolioInFocusState } from "components/PortfolioInFocus/Reducers/portfolioInFocus.reducer";
import { MonthlyMaintenanceReports } from "interfaces/contract.interfaces";
import { PeriodOption } from "components/ContractInFocus/Interfaces/contractInFocusActions.interfaces";
import { EntityState } from "components/Common/Interfaces/Entity.interface";
import { PortfolioPeriod } from "components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface";
import { FetchPortfolioSnapshotAction, FetchPortfolioSnapshotSuccessAction } from "components/PortfolioInFocus/Interfaces/portfolioInFocusActions.interfaces";
import { PortfolioACLI } from "components/ContractInFocus/Reducers/portfolioACL.reducer";

import {
    // FetchSnapshotSuccessAction,
    SetSelectedPortfolioSuccessAction,
    SetSelectedPortfolioActions,
    SwitchSelectedPortfolioInFocusI,
    //   FetchSnapshotAction,
    //   ClearSelectedSnapshotAction,
    SetSelectedPortfolioSnapshotActions
} from "components/PortfolioInFocus/Interfaces/portfolioInFocusActions.interfaces";

// retreivePortfolioInFocusFromCache, setSelectedPortfolioPeriod, setSelectedPortfolioSnapshot

export const PORTFOLIO_IN_FOCUS_FETCH_PORTFOLIO_PERIODS = createRequestActionTypes('PORTFOLIO_IN_FOCUS_FETCH_PORTFOLIO_PERIODS');

export const fetchPortfolioPeriods = (portfolioId: number) =>
    ({ type: PORTFOLIO_IN_FOCUS_FETCH_PORTFOLIO_PERIODS.start, payload: { portfolioId } });
export const fetchPortfolioPeriodsSuccess = (portfolioPeriod: EntityState<PortfolioPeriod>) =>
    ({ type: PORTFOLIO_IN_FOCUS_FETCH_PORTFOLIO_PERIODS.success, payload: { portfolioPeriod } });
export const fetchPortfolioPeriodsErrors = (error: string) => ({ type: PORTFOLIO_IN_FOCUS_FETCH_PORTFOLIO_PERIODS.error, error });

export const PORTFOLIO_IN_FOCUS_SET_SELECTED_SNAPSHOT = createRequestActionTypes('PORTFOLIO_IN_FOCUS_SET_SELECTED_SNAPSHOT');

export const setSelectedPortfolioSnapshot = ({ selectedSnapshot }: SetSelectedPortfolioSnapshotActions) =>
    ({ type: PORTFOLIO_IN_FOCUS_SET_SELECTED_SNAPSHOT.reducer, payload: { selectedSnapshot } });

export const SWITCH_SELECTED_PORTFOLIO_IN_FOCUS = createRequestActionTypes('PORTFOLIO_IN_FOCUS_SWITCH_SELECTED_PORTFOLIO');
export const switchSelectedPortfolio = ({ portfolioId }: SwitchSelectedPortfolioInFocusI) =>
    ({ type: SWITCH_SELECTED_PORTFOLIO_IN_FOCUS.reducer, payload: { portfolioId } });

export const SET_SELECTED_PORTFOLIO = createRequestActionTypes('PORTFOLIO_IN_FOCUS_SET_SELECTED_PORTFOLIO');

export const setSelectedPortfolio = ({ portfolioId, snapshotDateSelector }: SetSelectedPortfolioActions) =>
    ({ type: SET_SELECTED_PORTFOLIO.start, payload: { portfolioId, snapshotDateSelector } });
export const setSelectedPortfolioSuccess = ({ portfolio, frozenHistory, monthlyMaintenanceReports }: SetSelectedPortfolioSuccessAction) =>
    ({ type: SET_SELECTED_PORTFOLIO.success, payload: { portfolio, frozenHistory, monthlyMaintenanceReports } });

export const setSelectedPortfolioError = (err: any) => ({ type: SET_SELECTED_PORTFOLIO.error, payload: { err } });
export const setSelectedPortfolioReduced = () => ({ type: SET_SELECTED_PORTFOLIO.reducer });

export const CLEAR_PORTFOLIO_IN_FOCUS = createRequestActionTypes('PORTFOLIO_IN_FOCUS_CLEAR_PORTFOLIO_IN_FOCUS');
export const clearPortfolioInFocus = () => ({ type: CLEAR_PORTFOLIO_IN_FOCUS.reducer });

export const SWITCH_PORTFOLIO_IN_FOCUS = createRequestActionTypes('PORTFOLIO_IN_FOCUS_SWITCH_PORTFOLIO_IN_FOCUS');
export const switchPortfolioInFocus = (portfolioId: number) => ({ type: SWITCH_PORTFOLIO_IN_FOCUS.reducer, payload: { portfolioId } });

export const RETRIEVE_PORTFOLIO_IN_FOCUS_FROM_CACHE = createRequestActionTypes('RETRIEVE_PORTFOLIO_IN_FOCUS_FROM_CACHE');
export const retreivePortfolioInFocusFromCache = (portfolioInFocus: PortfolioInFocusState) => ({ type: RETRIEVE_PORTFOLIO_IN_FOCUS_FROM_CACHE.reducer, payload: { portfolioInFocus } });

export const ADD_PORTFOLIO_IN_FOCUS_TO_CACHE = createRequestActionTypes('ADD_PORTFOLIO_IN_FOCUS_TO_CACHE');
export const addPortfolioInFocusToCache = (portfolioInFocus: PortfolioInFocusState) => ({ type: ADD_PORTFOLIO_IN_FOCUS_TO_CACHE.reducer, payload: { portfolioInFocus } });

export const REMOVE_PORTFOLIO_IN_FOCUS_FROM_CACHE = createRequestActionTypes('REMOVE_PORTFOLIO_IN_FOCUS_FROM_CACHE');
export const removePortfolioInFocusFromCache = (portfolioId: number) => ({ type: REMOVE_PORTFOLIO_IN_FOCUS_FROM_CACHE.reducer, payload: { portfolioId } });

export const CLEAR_PORTFOLIO_IN_FOCUS_CACHE = createRequestActionTypes('CLEAR_PORTFOLIO_IN_FOCUS_CACHE');
export const clearPortfolioInFocusCache = () => ({ type: CLEAR_PORTFOLIO_IN_FOCUS_CACHE.reducer, payload: null });

export const ADD_PORTFOLIO_MENU_ACCESS = createRequestActionTypes('ADD_PORTFOLIO_MENU_ACCESS');
export const addPortfolioACL = (portfolioId: string | number, menuAccess: PortfolioACLI) => ({ type: ADD_PORTFOLIO_MENU_ACCESS.reducer, payload: { menuAccess, portfolioId } });

export const REMOVE_PORTFOLIO_MENU_ACCESS = createRequestActionTypes('REMOVE_PORTFOLIO_MENU_ACCESS');
export const removePortfolioMenuAccess = (portfolioId: number) => ({ type: REMOVE_PORTFOLIO_MENU_ACCESS.reducer, payload: { portfolioId } });

export const CLEAR_ALL_PORTFOLIOS_MENU_ACCESS = createRequestActionTypes('CLEAR_ALL_PORTFOLIOS_MENU_ACCESS');
export const clearAllPortfoliosMenuAccess = () => ({ type: CLEAR_ALL_PORTFOLIOS_MENU_ACCESS.reducer, payload: null });

export const INSERT_PORTFOLIO_SNAPSHOT = createRequestActionTypes('PORTFOLIO_IN_FOCUS_INSERT_SNAPSHOT');
export const insertPortfolioSnapshotReducer = ({ snapshot }: { snapshot: any }) => ({ type: INSERT_PORTFOLIO_SNAPSHOT.reducer, payload: { snapshot } });

export const PORTFOLIO_GET_MONTHLY_MAINTENANCE_REPORTS = createRequestActionTypes('PORTFOLIO_GET_MONTHLY_MAINTENANCE_REPORTS');

export const getPortfolioMonthlyMaintenanceReports = (portfolioId: string | number) =>
    ({ type: PORTFOLIO_GET_MONTHLY_MAINTENANCE_REPORTS.start, payload: { portfolioId } });
export const getPortfolioMonthlyMaintenanceReportsSuccess = (monthlyMaintenanceReports: MonthlyMaintenanceReports) =>
    ({ type: PORTFOLIO_GET_MONTHLY_MAINTENANCE_REPORTS.success, payload: { monthlyMaintenanceReports } });
export const getPortfolioMonthlyMaintenanceReportsErrors = (error: string) => ({ type: PORTFOLIO_GET_MONTHLY_MAINTENANCE_REPORTS.error, error });

export const SET_SELECTED_PORTFOLIO_PERIOD = createRequestActionTypes('SET_SELECTED_PORTFOLIO_PERIOD');
export const setSelectedPortfolioPeriod = (selectedPeriod: PeriodOption) => ({ type: SET_SELECTED_PORTFOLIO_PERIOD.reducer, payload: selectedPeriod });

export const FETCH_PORTFOLIO_SNAPSHOTS = createRequestActionTypes('PORTFOLIO_IN_FOCUS_FETCH_SNAPSHOT');

export const fetchPortfolioSnapshots = ({ portfolioPeriod, focusedSnapshot, fetchSnapshotCallback }: FetchPortfolioSnapshotAction) => ({ type: FETCH_PORTFOLIO_SNAPSHOTS.start, payload: { portfolioPeriod, focusedSnapshot, fetchSnapshotCallback } });
export const fetchPortfolioSnapshotSuccess = ({ snapshotHistory, focusedSnapshot, fetchSnapshotCallback }: FetchPortfolioSnapshotSuccessAction) => ({ type: FETCH_PORTFOLIO_SNAPSHOTS.success, payload: { snapshotHistory, focusedSnapshot, fetchSnapshotCallback } });
export const fetchPortfolioSnapshotError = (err: any) => ({ type: FETCH_PORTFOLIO_SNAPSHOTS.error, payload: { err } });

/* Set Dialog Open */
export const SET_PORTFOLIO_DOCS_DIALOGUE_OPEN = createRequestActionTypes("SET_PORTFOLIO_DOCS_DIALOGUE_OPEN");
export const setPortfolioDocsDialogueOpen = (portfolioId: number) => ({ type: SET_PORTFOLIO_DOCS_DIALOGUE_OPEN.reducer, payload: { portfolioId } });

/* Set Dialog Open */
export const SET_PORTFOLIO_DOCS_DIALOGUE_CLOSE = createRequestActionTypes("SET_PORTFOLIO_DOCS_DIALOGUE_CLOSE");
export const setPortfolioDocsDialogueClose = (portfolioId: number) => ({ type: SET_PORTFOLIO_DOCS_DIALOGUE_CLOSE.reducer, payload: { portfolioId } });

/* Toggle Dialog */
export const TOGGLE_PORTFOLIO_DOCS_DIALOGUE_OPEN = createRequestActionTypes("TOGGLE_PORTFOLIO_DOCS_DIALOGUE_OPEN");
export const togglePortfolioDocsDialogueOpen = (portfolioId: number) => ({ type: TOGGLE_PORTFOLIO_DOCS_DIALOGUE_OPEN.reducer, payload: { portfolioId } });