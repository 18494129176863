import { MainInFocusVisibilitySettings } from "../../interfaces/contractInFocus.interfaces";
import { RightVisibilityMenuT } from "../../interfaces/personalContractSettings.interfaces";
import { GetRightVisibilityMenu } from "components/Common/Components/RightVisibilityMenu/Interfaces/RightContext.interfaces";


export const meterReadingsRightVisibilityMenu = ({ visibilitySettings }: GetRightVisibilityMenu): RightVisibilityMenuT => ({
  "Meter Reading Events": {
    table: {
      value: visibilitySettings.show_meter_reading_events_table,
      updateKey: 'show_meter_reading_events_table'
    }
  },
});