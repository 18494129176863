/* eslint-disable */
import React, { useState, memo, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Own
import { store } from "store/store";
import ListGrid from "components/IndexPage/IndexListGrid";
import { NoItems } from "components/Common/Components/NoItems";
import { Header } from "components/Layout/interfaces/LayoutState.interface";
import Card from "components/IndexPage/IndexCard";
import { ListCard } from "components/IndexPage/Interfaces/CardList.interface";
import PortfolioCardBack from 'components/Portfolios/Components/PortfolioCardBack/PortfolioCardBack';
import PortfolioCardFront from "components/Portfolios/Components/PortfolioCardFront/PortfolioCardFront";
import { addPortfolioToMyFavourites, removePortfolioFromMyFavourites } from "components/Portfolios/Services/portfolioServices";
import { contractSelector } from "components/AdminPanel/Contracts/Selectors/Contracts.selectors";
import {
    selectPortfoliosDataArray,
    selectFavouritePortfoliosDataArray,
    hasNoPortfoliosSelector
} from "components/Portfolios/Selectors/Portfolio.selectors";
import { selectHeader } from "../Layout/selectors/Layout.selectors";
import RightVisibilityMenu from "components/Common/Components/RightVisibilityMenu/RightVisibilityMenu";
import { portfoliosIndexVisibilitySelector, selectShowNonFavouritePortfoliosSetting } from "components/Profile/Selectors/Profile.selector";
import { defaultTogglePersonalSettings } from "components/Profile/Helpers/profileHelpers";
import * as fromContractActions from "components/AdminPanel/Contracts/Actions/Contracts.actions";
import * as portfolioActions from "components/AdminPanel/Portfolios/Actions/AdminPanelPortfolios.actions";


// styles
import { SiteWrapper } from "./SitesStyles";
import { Portfolio } from 'components/Portfolios/Interfaces/Portfolios.interface';
import { isEqual } from "lodash";


const Portfolios = () => {
    const selectedPortfolios = useSelector(selectPortfoliosDataArray); // This is from the Redux State
    const selectedFavouritePortfolios = useSelector(selectFavouritePortfoliosDataArray);
    const contracts = useSelector(contractSelector);
    const selectedHeader = useSelector(selectHeader); // This is from the Redux State
    const portfoliosIndexVisibilityProfile = useSelector(portfoliosIndexVisibilitySelector);
    const showNonFavouritePortfoliosSetting = useSelector(selectShowNonFavouritePortfoliosSetting);

    const [portfoliosList, setPortfoliosList] = useState<Portfolio[]>([]);

    useEffect(() => {
        store.dispatch(fromContractActions.fetchContracts());
        store.dispatch(portfolioActions.fetchPortfolios());
    }, [])

    useEffect(() => {
        if (showNonFavouritePortfoliosSetting && selectedPortfolios || !showNonFavouritePortfoliosSetting && selectedFavouritePortfolios) {
            if (contracts.data) {
                setPortfoliosList(showNonFavouritePortfoliosSetting ? selectedPortfolios : selectedFavouritePortfolios);
            }
            setPortfoliosState(showNonFavouritePortfoliosSetting ? selectedPortfolios : selectedFavouritePortfolios);
        }
    }, [contracts, showNonFavouritePortfoliosSetting, selectedPortfolios]);

    const [portfoliosState, setPortfoliosState] = useState<Portfolio[]>(portfoliosList); // This is from the Local Component Store

    const matches = (value: string, portfolio: Portfolio) =>
        portfolio.name.toLowerCase().includes(value.toLowerCase())

    const filterPortfoliosBySearch = (selectedHeader: Header, portfoliosList: Portfolio[]) => {
        const filteredPortfolios = portfoliosList.filter((p: any) => {
            return matches(selectedHeader.search.toLowerCase(), p)
        });
        setPortfoliosState(filteredPortfolios);
    };

    const toggleFavourite = (portfolio: ListCard): void => {
        const newFavState = portfolio.favourite ? 1 : 0;
        if (!newFavState) {
            addPortfolioToMyFavourites(portfolio.id);
        } else {
            removePortfolioFromMyFavourites(portfolio.id);
        }
    };

    useEffect(() => {

        if (selectedHeader.search && selectedHeader.search.length > 0) {
            filterPortfoliosBySearch(selectedHeader, portfoliosList);
        } else {
            if (!isEqual(portfoliosState, portfoliosList)) {
                // It seems that what mainly changes here are the image urls - changed on each invocation.  But we'll 
                // be adding portfolios to BE caching.
                setPortfoliosState(portfoliosList);
            }
        }
    }, [portfoliosList, selectedHeader.search]); // when redux changes let's update the local component
    const hasNoPortfolios = useSelector(hasNoPortfoliosSelector);
    return (
        <SiteWrapper>
            <RightVisibilityMenu
                //uses configuration fed into withPageContext - used for individual interface pages
                visibilitySettings={portfoliosIndexVisibilityProfile}
                defaultOnClick={defaultTogglePersonalSettings}
            />
            {hasNoPortfolios ? <NoItems
                caption="You haven't been assigned any portofolios yet! If you think you should have been, please contact a system administrator."
            /> : <ListGrid objectsForCards={portfoliosState} CardComponent={Card} CardBack={PortfolioCardBack} CardFront={PortfolioCardFront} toggleFavourite={toggleFavourite} ></ListGrid>
            }
        </SiteWrapper>
    );
};

//Portfolios.whyDidYouRender = true;

export default memo(Portfolios);
