/* eslint-disable */
import React, { useCallback, useState, useEffect, useReducer } from 'react';
import { IHASRiddor, IHASAccident, RiddorSubmittedReport } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";
import { useGetRiddorFormLayout, getRiddorFormConfig } from "components/ContractInFocus/HAS/HASAccidentDetailModels";
import IncidentFormManagementWrapper, { PreManagementWrapperIncidentFormProps, PrepareBasicHASIncidentFormConfigProps, GetIncidentFormLayoutProps } from "components/ContractInFocus/HAS/IncidentFormManagementWrapper";
import { simpleFetchRiddorFieldInfoList } from "components/AdminPanel/HAS/HASIncidentServices";
import { getRiddorRoute } from 'components/AdminPanel/HAS/HASIncidentServices';
import { RiddorSubList, AccidentCheckList } from "components/ContractInFocus/HAS/RiddorSubForms";
import RiddorReportUploader, { getRiddorReportBaseRoute } from "components/ContractInFocus/HAS/RiddorReportUploader"
import RiddorReportViewer from "components/ContractInFocus/HAS/RiddorDocViewer";
import commonAPIServices from "components/ContractInFocus/Services/commonAPI.services";
import { PreFlightListInfo } from 'components/Common/Interfaces/Entity.interface';
import {
    FormWrapper,
} from 'components/Common/Components/GeneralEntityForm/GeneralDetailStyles';

interface RiddorProps extends PreManagementWrapperIncidentFormProps {
    accident: IHASAccident;
}

const prepareBasicFieldConfigsForRiddor = ({
    thisRecord,
    HASAdminAccess
}
    : PrepareBasicHASIncidentFormConfigProps) => {
    let fieldsConfig = (getRiddorFormConfig({ HASAdminAccess: HASAdminAccess }))
    return fieldsConfig
}

function HASRiddorView({
    ...props
}: RiddorProps) {
    const gridClass = `riddor HASIncidentDetailForm fieldGroupsForm darker-disabled`;
    const [data, setData] = useState(props.data as IHASRiddor);
    const docBaseRoute = getRiddorReportBaseRoute(data);
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 1); // should start as 1 to ensure file is displayed

    const showNewDoc = (submitted_report: RiddorSubmittedReport) => (
        setData({ ...data, submitted_report: submitted_report })
    )
    const [showNotes, setShowNotes] = useState(false);

    const WrappedGetFormLayoutForRiddor = useCallback((accident: IHASAccident) => {
        return ({
            thisRecord,
            HASAdminAccess,
            simpleLayout
        }: GetIncidentFormLayoutProps
        ) => {
            const record = thisRecord as IHASRiddor;

            // const RenderAPQualificationsList = () => {
            //     return <RiddorSubList
            //         riddor={record}
            //         sublistType='qualification'
            //         title="Qualifications"
            //     />
            // };

            // const RenderAPTrainingCoursesList = () => {
            //     return <RiddorSubList
            //         riddor={record}
            //         sublistType='course'
            //         title="Training Courses Attended"
            //     />
            // };

            // const RenderCheckList = () => {
            //     return <AccidentCheckList
            //         accident={accident}
            //         title="Check List"
            //     />
            // };
            if (simpleLayout) { return undefined } else {
                const layout = useGetRiddorFormLayout({
                    HASAdminAccess: HASAdminAccess,
                    // RenderAPQualificationsList: RenderAPQualificationsList,
                    // RenderAPTrainingCoursesList: RenderAPTrainingCoursesList,
                    // RenderCheckList: RenderCheckList,
                })
                return layout;
            };
        }


    }, []);

    const RenderAPQualificationsList = useCallback(() => {
        return <RiddorSubList
            riddor={data}
            sublistType='qualification'
            title="Qualifications"
        />
    }, [data]);;

    const RenderAPTrainingCoursesList = useCallback(() => {
        return <RiddorSubList
            riddor={data}
            sublistType='course'
            title="Training Courses Attended"
        />
    }, [data]);

    const RenderCheckList = useCallback(() => {
        return <AccidentCheckList
            accident={props.accident}
            title="Check List"
        />
    }, [props.accident]);

    const [preFlightDocInfo, setPreFlightDocInfo] = useState<PreFlightListInfo>();

    useEffect(() => {
        commonAPIServices.getPreFlightInfo(docBaseRoute).then(response => {
            setPreFlightDocInfo(response);
        }).catch(
            (error) => { console.log(error) }
        )
    }, [docBaseRoute]);

    useEffect(() => {
        // this should only trigger if the file has changed...
        if (data?.submitted_report?.file) {
            forceUpdate();
        }
    }, [data?.submitted_report?.file])

    //{data.submitted_report?.file ? <>
    const UseGetFormLayoutForRiddor = WrappedGetFormLayoutForRiddor(props.accident);
    return <>
        {data.submitted_report?.file && <>
            <RiddorReportViewer
                data={data as IHASRiddor}
                setData={setData}
                docBaseRoute={docBaseRoute}
                preFlightDocInfo={preFlightDocInfo}
                isOpen={!!data.submitted_report}
                showDeleteButton
                showDownloadButton
                signalRefresh={mustRefresh}
            />
            <div className='showRiddorNotesLinkWrapper'><a className='showRiddorNotesLink' onClick={() => setShowNotes(!showNotes)}>{`${showNotes ? 'Hide Notes' : 'Show Notes'}`}</a></div>
        </>}
        {(!data.submitted_report?.file || showNotes) && <>
            <h3 className='riddor-upload-title'>Notes for RIDDOR submission</h3>
            <IncidentFormManagementWrapper
                {...props}
                prepareBasicFieldConfigs={prepareBasicFieldConfigsForRiddor}
                getFormLayout={UseGetFormLayoutForRiddor}
                gridClass={gridClass}
                simpleFetchFieldInfoList={simpleFetchRiddorFieldInfoList}
                recordType="riddor"
                getIncidentRoute={getRiddorRoute}
                showReadOnly
            />
            <FormWrapper>
                <div className='riddorInfoListsWrapper'>
                    <div className='riddorInfoListItem'>
                        <RenderAPQualificationsList />
                    </div>
                    <div className='riddorInfoListItem'>
                        <RenderAPTrainingCoursesList />
                    </div>
                    <div className='riddorInfoListItem'>
                        <RenderCheckList />
                    </div>
                </div>
            </FormWrapper>

        </>}
        {preFlightDocInfo && !data.submitted_report?.file && <RiddorReportUploader
            riddor={data}
            setData={showNewDoc}
            preFlightInfo={preFlightDocInfo}
        />}

    </>
}

export default React.memo(HASRiddorView);