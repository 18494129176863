import React, { useRef, useCallback, useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

// Own
import { AdminFilteredDataGrid } from "components/AdminPanel/Containers/AdminFilteredDataGrid";
import { allPeopleSelector, jobTitlesState } from "components/AdminPanel/People/Selectors/People.selector";
import { peopleFilteredDatagridColumns, getPeopleFilterExpForGrid, getPersonFormItems } from "components/AdminPanel/People/Models/PeopleDetail.model";
import { PeopleDetailView } from "components/AdminPanel/People/Containers/PeopleDetailView";
import { columnVisibility } from "helpers/DataGrid/DataGridColumn.helper";
import peopleServices from "components/AdminPanel/People/Services/peopleServices";
import { Person } from "components/AdminPanel/People/Interfaces/People.interface";
import { allOrganisationsSelector } from "components/AdminPanel/Organisation/Selectors/Organisations.selectors";
import {
  DataGridEvent,
  DataGridUpdateEvent,
} from "interfaces/datagrid.interfaces";
import { apiSuccess } from 'components/AdminPanel/Helpers/AdminPanel.helper';
import { organisationLookupSelector } from "components/AdminPanel/Organisation/Selectors/Organisations.selectors";
import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";
import { EntityKeys } from "constants/entity.constants";
import { createPsuedoPerson } from "components/AdminPanel/People/Actions/People.actions";
import { useSortedJobTitles } from "components/AdminPanel/People/Helper/People.customHooks";
import { FORM_ROW_ID } from "components/AdminPanel/Models/AdminPanel.model";
import { getPeopleFormFieldsAdjuster } from "components/AdminPanel/People/Helper/People.form.helper.js";
import { filterColumnProps } from "components/ContractInFocus/Interfaces/DataGridColumn.interface";


interface PeopleMasterViewProps {
  panelId: string;
}

const getPeopleListDataColumnsCallBack = (includeInactive: boolean) => {
  const callBack = (props: filterColumnProps) => {
    let columns = peopleFilteredDatagridColumns({ ...props, includeIsActive: includeInactive });
    return columns;
  };
  return callBack;
}

const PeopleMasterView: React.FC<PeopleMasterViewProps> = ({ panelId }) => {

  const dataTestId = 'people-master-view';

  const peopleState = useSelector(allPeopleSelector);
  const organisationLookupState = useSelector(organisationLookupSelector);
  const jobTitleChoices = useSortedJobTitles();
  const organisationsData = useSelector(allOrganisationsSelector);
  const currentJobTitleOptions = useRef(null);
  const currentAdminLevelOptions = useRef(null);
  const currentGlobalContractLevelOptions = useRef(null);

  const [peopleList, setPeopleList] = useState(peopleState.data.filter((x: any) => x.is_active == true));

  const [includeInactive, setIncludeInactive] = useState(false);

  const getPeopleListDataColumns = getPeopleListDataColumnsCallBack(includeInactive);

  const peopleDataGridActions = {
    'toggle_inactive_people': {
      caption: includeInactive ? 'Hide Deactivated People' : 'Display Deactivated People',
      location: "after",
      onClick: (e: any) => {
        setIncludeInactive(!includeInactive);
      }
    }
  }

  useEffect(() => {
    if (includeInactive) {
      setPeopleList(peopleState.data);
    } else {
      setPeopleList(peopleState.data.filter((x: any) => x.is_active == true));
    }
  }, [includeInactive]);

  const getPeopleMeta = (): FieldMetaGroup => {
    return {
      ...peopleState.meta,
      organisation: {
        ...peopleState.meta?.organisation,
        type: 'choice',
        choices: organisationLookupState
      }
    }
  }

  const peopleFormFieldAdjuster = useCallback(getPeopleFormFieldsAdjuster(organisationsData, currentJobTitleOptions, currentAdminLevelOptions, currentGlobalContractLevelOptions, "dataRow"), [organisationsData, currentJobTitleOptions, currentAdminLevelOptions, currentGlobalContractLevelOptions]);

  const peopleMeta = getPeopleMeta();

  const handleUpdate = (e: DataGridUpdateEvent) => {
    // @ts-ignore
    e.cancel = peopleServices.updatePerson(e.key, e.oldData, e.newData, peopleState.recordmeta[e.key]).then(apiSuccess);
  };

  const handleDelete = (e: DataGridEvent): void => {
    // @ts-ignore
    e.cancel = peopleServices.deletePerson(e.key).then(apiSuccess);
  };

  const handleCreate = (e: DataGridEvent): void => {
    // @ts-ignore
    peopleServices.createPerson(e.data, panelId);
  };

  const handleRowExpanding = (e: DataGridEvent) => {
    e.cancel = false;
    peopleServices.personFetchContractAccess(e.key)
    peopleServices.personFetchPortfolioAccess(e.key);
  }

  const handleFetchPeople = (members: Person[]) => {
    peopleServices.groupFetchPeople(members);
  };

  //console.log('people master view meta: ', getPeopleMeta());
  // HT NOTE: how is masterView below defined here?
  return (
    <AdminFilteredDataGrid
      data={peopleList}
      meta={peopleMeta}
      permissions={peopleState.permissions}
      recordmeta={peopleState.recordmeta}
      panelId={panelId}
      masterView
      dataTestId={dataTestId}
      detailView={PeopleDetailView}
      createFormMeta={getPersonFormItems(peopleMeta, false, jobTitleChoices)}
      getDatagridColumns={getPeopleListDataColumns}
      getPreFilterExp={getPeopleFilterExpForGrid}
      onDelete={handleDelete}
      onUpdate={handleUpdate}
      onCreate={handleCreate}
      //onCreatePseudo={handleCreatePseudo}
      datagridActions={peopleDataGridActions}
      fetchGroup={handleFetchPeople}
      dragAndDrop={false}//was true
      entityKey={EntityKeys.People}
      onRowExpanding={handleRowExpanding}
      editMode="form"
      passedEditorPreparing={peopleFormFieldAdjuster}
    />
  );
};
// PeopleMasterView.whyDidYouRender = true;
export default React.memo(PeopleMasterView);
