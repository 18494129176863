import { tap, switchMap, map, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";

// Own
import { mode } from "../../../Common/Consts/DataMode";
import { APIR } from "services/API/API";
import { getData, getMeta, removeReadOnlyFields } from "../../../../services/API/API.helper";
import { adminPanelType } from "../../Models/AdminPanel.model";
import { ROUTE, transformContract } from "./ContractsHelpers.epic";
import { ContractInterface } from "../Interfaces/Contract.interface";
import { addDataSubItem } from '../../../../store/actions/branchSublist.actions';
import { unWrapDataAndMetaAndPUTMeta } from "services/API/API.helper";
import { FieldMetaGroup } from "../../../Common/Interfaces/Entity.interface";
import { transformContractDataForGrid } from "components/AdminPanel/Contracts/Helper/contractHelper";
import * as fromAdminPanelActions from "../../Actions/adminPanel.actions";
import * as fromRootActions from "../../../../store/actions/root.actions";
import * as fromContractActions from "../Actions/Contracts.actions";

export const fetchContractsEpic = (action$: any) => action$.pipe(
  ofType(fromContractActions.FETCH_CONTRACTS.start),
  switchMap(() =>
    APIR.get(`${ROUTE}?format=json`).pipe(
      unWrapDataAndMetaAndPUTMeta(),
      map(response => {
        const newResponse = {
          ...response,
          data: response.data.map((contract: ContractInterface) => ({
            ...transformContractDataForGrid(contract)
          }),
          )
        }
        // console.log('newResponse', newResponse);
        return newResponse;
      }),
      map(contracts => fromContractActions.fetchContractsSuccess(contracts)),
    )
  )
);

export const updateContractEpic = (action$: any) => action$.pipe(
  ofType(fromContractActions.UPDATE_CONTRACT.start),
  map(({ panelId, contract, meta }: { panelId: string, contract: ContractInterface, meta: FieldMetaGroup }) => ({
    panelId,
    ...contract,
    contract_with: contract.contract_with.id,
    site: contract.site.id,
    meta
  })),
  switchMap(({ panelId, contract_ref, meta, ...contract }: any) => {
    const payload = removeReadOnlyFields(contract, meta);
    return APIR.patch(`${ROUTE}${contract_ref}/`, payload).pipe(
      map(organisationResponse => ({
        data: transformContract(getData(organisationResponse)),
        meta: getMeta(organisationResponse)
      })),
      map((response) => fromContractActions.updateContractSuccess({ ...response.data })),
      catchError((error) => of(fromAdminPanelActions.setApiErrors(panelId, error))),
    )
  })
);

export const updateContractSuccessEpic = (action$: any) => action$.pipe(
  ofType(fromContractActions.UPDATE_CONTRACT.success),
  map(() => fromContractActions.fetchContracts()),
);

export const createContractEpic = (action$: any) => action$.pipe(
  ofType(fromContractActions.CREATE_CONTRACT.start),
  map((action: any) => {
    const { id, ...contract } = action.contract;
    const newContract = {
      ...contract,
      contract_with: (contract.contract_with || {}).id,
      site: (contract.site || {}).id
    }
    return { panelId: action.panelId, recordId: id, contract: newContract };
  }),
  switchMap(({ recordId, panelId, contract }) => {
    return APIR.post(`${ROUTE}`, { ...contract }).pipe(
      map(response => ({
        data: { ...transformContract(getData(response)), focused: true },
        meta: getMeta(response)
      })),
      switchMap(({ data, meta }) => [
        fromRootActions.replaceEntityById(adminPanelType.contracts, recordId, panelId, data, meta),
        fromAdminPanelActions.setMode(panelId, mode.view),
        fromContractActions.createContractSuccess(),
        fromContractActions.fetchContractSublistMeta(data.contract_ref)
      ]),
    )
  }
  )
);

export const linkContractToSite = (action$: any) => action$.pipe(
  ofType(fromContractActions.LINK_CONTRACT_TO_SITE.start),
  switchMap(({ site, contract }) =>
    APIR.patch(`${ROUTE}${contract.contract_ref}/`, { site: site.id }).pipe(
      switchMap(() => [
        addDataSubItem(adminPanelType.sites, 'site_contracts', site.id, contract),
        fromContractActions.linkContractToSiteSuccess(),
      ]),
    )
  ),
  catchError((error: any) => {
    console.log(error);
    return of(fromContractActions.linkContractToSiteError(error));
  }),
);

// HT Note: unused!!?  Seems that currently only DELETE_CONTRACT.reducer is called when a contract is deleted
export const deleteContractEpic = (action$: any) => action$.pipe(
  ofType(fromContractActions.DELETE_CONTRACT.start),
  switchMap(({ contract, panelId }) =>
    APIR.delete(`${ROUTE}${contract.contract_ref}/`).pipe(
      switchMap(() => [
        fromContractActions.deleteContractSuccess(contract.id, panelId),
        fromAdminPanelActions.setMode(panelId, mode.list)
      ]),
      catchError((error) => of(fromAdminPanelActions.setApiErrors(panelId, error))),
    ),
  ),
);

export default [
  fetchContractsEpic,
  updateContractEpic,
  updateContractSuccessEpic,
  createContractEpic,
  linkContractToSite,
  deleteContractEpic
];