import React, { useState, useRef, useEffect } from "react";
import moment from "moment";

// Own
import { spendRightVisibilityMenu } from './Models/spendRightVisibilityMenu';
import { RefreshControlComponent } from "components/Common/Components/RightVisibilityMenu/Components/RightContextButton/refreshButton";
import { SpendAggregate } from "./Containers/SpendAggregate";
import { SpendSimple } from "./Containers/SpendSimple";
import { SpendSimplePOs } from "./Containers/SpendSimple";
import ContractSpendSettings from "components/ContractInFocus/Spend/Containers/SpendSettings/ContractSpendSettings";
import withPageContext, { WithPageContextProps } from "../HoC/WithPageContext/WithPageContext";
import NewPageWrapper from "components/Common/Components/PrintStyles/Print";
import { reportSectionStructure, reportSubtitles } from "components/ContractInFocus/Models/contractIndex.model";
import { PrintTitle } from "../Components/ContractPrintTitle/ContractPrintTitle";
import CommonAPIService from "components/ContractInFocus/Services/commonAPI.services";
import { Dictionary } from "components/Common/Interfaces/Entity.interface";
import { getSubTitleNumber } from "components/ContractInFocus/ContractReport/Helpers/contractReportToc.helper";
import Header from "components/Common/Components/GridHeader/GridHeader.js";
import { tocsSelector } from "components/ContractInFocus/Selectors/visibility.selectors";
import withPortfolioPageContext from "components/PortfolioInFocus/HOC/WithPortfolioPageContext/WithPortfolioPageContext";
import { useSelector } from "react-redux";

interface SpendProps extends WithPageContextProps { };

const Spend: React.FC<SpendProps> = ({
  animate,
  contract,
  portfolio,
  visibilitySettings,
  sectionNumberMap
}) => {

  const [refreshSpendAt, setRefreshSpendAt] = useState<number>(moment.now());
  const [subTitleIndexes, setSubTitleIndexes] = useState<Dictionary<string | undefined>>({});
  const [includeMarkup, setIncludeMarkup] = useState(visibilitySettings.show_spend_markup);
  const [markupTitleStatus, setMarkupTitleStatus] = useState('');

  const comprehensiveTotalsTitle = reportSubtitles.SpendComprehensiveTotals;
  const comprehensivePurchaseOrdersTitle = reportSubtitles.SpendComprehensivePOs;
  const comprehensivePaidTotalsTitle = reportSubtitles.SpendComprehensivePaidTotals;
  const comprehensivePaidPurchaseOrdersTitle = reportSubtitles.SpendComprehensivePaidPOs;
  const comprehensiveNonPOTransactionsTitle = reportSubtitles.SpendComprehensiveNonPOTransactions;

  const consumableTotalsTitle = reportSubtitles.SpendConsumableTotals;
  const consumablePurchaseOrdersTitle = reportSubtitles.SpendConsumablePOs;
  const consumablePaidTotalsTitle = reportSubtitles.SpendConsumablePaidTotals;
  const consumablePaidPurchaseOrdersTitle = reportSubtitles.SpendConsumablePaidPOs;
  const consumableNonPOTransactionsTitle = reportSubtitles.SpendConsumableNonPOTransactions;

  const managedTotalsTitle = reportSubtitles.SpendManagedTotals;
  const managedPurchaseOrdersTitle = reportSubtitles.SpendManagedPOs;
  const managedPaidTotalsTitle = reportSubtitles.SpendManagedPaidTotals;
  const managedPaidPurchaseOrdersTitle = reportSubtitles.SpendManagedPaidPOs;
  const managedNonPOTransactionsTitle = reportSubtitles.SpendManagedNonPOTransactions;

  const tocs = useSelector(tocsSelector);

  const refreshSpend = (event: any) => {
    if (portfolio) {
      return CommonAPIService.triggerPortfolioServerSideSpendDataUpdate(portfolio.id);
    }
    if (contract) {
      return CommonAPIService.triggerServerSideSpendDataUpdate(contract.contract_ref)
    }
  }

  const afterRefresh = () => {
    setRefreshSpendAt(moment.now());
  }

  useEffect(() => {
    setIncludeMarkup(visibilitySettings.show_spend_markup)
    if (!visibilitySettings.show_spend_markup) {
      setMarkupTitleStatus(' (Exc Markup)')
    } else {
      setMarkupTitleStatus('')
    }
  }, [visibilitySettings.show_spend_markup])

  const showComprehensive = (): boolean => (
    visibilitySettings.show_comprehensive_monthly_actual_aggregate_table
    || visibilitySettings.show_comprehensive_po_actual_spend_table
    || visibilitySettings.show_comprehensive_monthly_combined_aggregate_table
    || visibilitySettings.show_comprehensive_po_committed_spend_table
    || visibilitySettings.show_comprehensive_individual_spend_table
  )
  const showConsumable = (): boolean => (
    visibilitySettings.show_consumable_monthly_actual_aggregate_table
    || visibilitySettings.show_consumable_po_actual_spend_table
    || visibilitySettings.show_consumable_monthly_combined_aggregate_table
    || visibilitySettings.show_consumable_po_committed_spend_table
    || visibilitySettings.show_consumable_individual_spend_table
  )

  const showManaged = (): boolean => (
    visibilitySettings.show_managed_monthly_actual_aggregate_table
    || visibilitySettings.show_managed_po_actual_spend_table
    || visibilitySettings.show_managed_monthly_combined_aggregate_table
    || visibilitySettings.show_managed_po_committed_spend_table
    || visibilitySettings.show_managed_individual_spend_table
  )

  useEffect(() => {
    const newSubTitleIndexes: Dictionary<string | undefined> = {};

    const comprehensiveIndexLookups = [comprehensiveTotalsTitle, comprehensivePurchaseOrdersTitle, comprehensivePaidTotalsTitle, comprehensivePaidPurchaseOrdersTitle, comprehensiveNonPOTransactionsTitle];
    comprehensiveIndexLookups.map((l: string) => {
      newSubTitleIndexes[l] = getSubTitleNumber(l, tocs.flatTocsLookup);
    })

    const consumableIndexLookups = [consumableTotalsTitle, consumablePurchaseOrdersTitle, consumablePaidTotalsTitle, consumablePaidPurchaseOrdersTitle, consumableNonPOTransactionsTitle];
    consumableIndexLookups.map((l: string) => {
      newSubTitleIndexes[l] = getSubTitleNumber(l, tocs.flatTocsLookup);
    })

    const managedIndexLookups = [managedTotalsTitle, managedPurchaseOrdersTitle, managedPaidTotalsTitle, managedPaidPurchaseOrdersTitle, managedNonPOTransactionsTitle];
    managedIndexLookups.map((l: string) => {
      newSubTitleIndexes[l] = getSubTitleNumber(l, tocs.flatTocsLookup);
    })

    setSubTitleIndexes(newSubTitleIndexes);

  }, [
    tocs,
    comprehensiveTotalsTitle,
    comprehensivePurchaseOrdersTitle,
    comprehensivePaidTotalsTitle,
    comprehensivePaidPurchaseOrdersTitle,
    comprehensiveNonPOTransactionsTitle,
    consumableTotalsTitle,
    consumablePurchaseOrdersTitle,
    consumablePaidTotalsTitle,
    consumablePaidPurchaseOrdersTitle,
    consumableNonPOTransactionsTitle,
    managedTotalsTitle,
    managedPurchaseOrdersTitle,
    managedPaidTotalsTitle,
    managedPaidPurchaseOrdersTitle,
    managedNonPOTransactionsTitle
  ])
  const PrintComprehensiveHeading = () => <PrintTitle sectionNumberMap={sectionNumberMap} reportSection={reportSectionStructure.SpendComprehensive} />;
  const PrintConsumableHeading = () => <PrintTitle sectionNumberMap={sectionNumberMap} reportSection={reportSectionStructure.SpendConsumable} />;
  const PrintManagedHeading = () => <PrintTitle sectionNumberMap={sectionNumberMap} reportSection={reportSectionStructure.SpendManaged} />;
  return (
    <>
      <RefreshControlComponent onclick={refreshSpend} after={afterRefresh} />
      {
        visibilitySettings.show_contract_spend_settings &&
        <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
          <PrintComprehensiveHeading />
          <ContractSpendSettings
            includeMarkup={includeMarkup}
            contract={contract}
            portfolio={portfolio}
            refreshSpendAt={refreshSpendAt}
            title="Contract Particulars"
            dataTestId="contract-spend-settings"
          />
        </div>
      }
      {showComprehensive() && <PrintComprehensiveHeading />}
      {
        visibilitySettings.show_comprehensive_monthly_combined_aggregate_table &&
        <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
          <Header
            title={`${subTitleIndexes[comprehensiveTotalsTitle]} ${comprehensiveTotalsTitle}${markupTitleStatus}`}
            className="no-screen"
          />
          <SpendAggregate
            includeMarkup={includeMarkup}
            contract={contract}
            portfolio={portfolio}
            refreshSpendAt={refreshSpendAt}
            aggregateType="combined-comprehensive"
            title={`${comprehensiveTotalsTitle}${markupTitleStatus}`}
            dataTestId="combined-comprehensive-spend-aggregate"
          />
        </div>

      }
      {
        visibilitySettings.show_comprehensive_po_committed_spend_table &&
        <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
          <PrintComprehensiveHeading />
          <Header
            title={`${subTitleIndexes[comprehensivePurchaseOrdersTitle]} ${comprehensivePurchaseOrdersTitle}${markupTitleStatus}`}
            className="no-screen"
          />
          <SpendSimplePOs
            includeMarkup={includeMarkup}
            contract={contract}
            portfolio={portfolio}
            refreshSpendAt={refreshSpendAt}
            spendType="committed-comprehensive"
            title={`${comprehensivePurchaseOrdersTitle}${markupTitleStatus}`}//'Comprehensive – Purchase Orders'
            dataTestId="committed-comprehensive-spend-pos"
            paging
            remoteOperations
          />
        </div>
      }
      {
        visibilitySettings.show_comprehensive_monthly_actual_aggregate_table &&
        <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
          <PrintComprehensiveHeading />
          <Header
            title={`${subTitleIndexes[comprehensivePaidTotalsTitle]} ${comprehensivePaidTotalsTitle}${markupTitleStatus}`}
            className="no-screen"
          />
          <SpendAggregate
            includeMarkup={includeMarkup}
            contract={contract}
            portfolio={portfolio}
            refreshSpendAt={refreshSpendAt}
            aggregateType="comprehensive"
            title={`${comprehensivePaidTotalsTitle}${markupTitleStatus}`}//'Comprehensive – Paid – Totals'
            dataTestId="comprehensive-spend-aggregate"
          />
        </div>

      }
      {
        visibilitySettings.show_comprehensive_po_actual_spend_table &&
        <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
          <PrintComprehensiveHeading />
          <Header
            title={`${subTitleIndexes[comprehensivePaidPurchaseOrdersTitle]} ${comprehensivePaidPurchaseOrdersTitle}${markupTitleStatus}`}
            className="no-screen"
          />
          <SpendSimplePOs
            includeMarkup={includeMarkup}
            contract={contract}
            portfolio={portfolio}
            refreshSpendAt={refreshSpendAt}
            spendType="comprehensive"
            title={`${comprehensivePaidPurchaseOrdersTitle}${markupTitleStatus}`}//'Comprehensive – Paid – Purchase Orders'
            dataTestId="comprehensive-spend-pos"
            paging
            remoteOperations
          />
        </div>

      }
      {
        visibilitySettings.show_comprehensive_individual_spend_table &&
        <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
          <PrintComprehensiveHeading />
          <Header
            title={`${subTitleIndexes[comprehensiveNonPOTransactionsTitle]} ${comprehensiveNonPOTransactionsTitle}${markupTitleStatus}`}
            className="no-screen"
          />
          <SpendSimple
            includeMarkup={includeMarkup}
            contract={contract}
            portfolio={portfolio}
            refreshSpendAt={refreshSpendAt}
            spendType="comprehensive"
            title={`${comprehensiveNonPOTransactionsTitle}${markupTitleStatus}`}//'Comprehensive – Non Purchase Order Transactions'
            dataTestId="comprehensive-spend"
            paging
            remoteOperations
          />
        </div>

      }

      {showComprehensive() && <NewPageWrapper breakAfter />}

      {showConsumable() && <PrintTitle sectionNumberMap={sectionNumberMap} reportSection={reportSectionStructure.SpendConsumable} />}
      {
        visibilitySettings.show_consumable_monthly_combined_aggregate_table &&
        <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
          <PrintConsumableHeading />
          <Header
            title={`${subTitleIndexes[consumableTotalsTitle]} ${consumableTotalsTitle}${markupTitleStatus}`}
            className="no-screen"
          />
          <SpendAggregate
            includeMarkup={includeMarkup}
            contract={contract}
            portfolio={portfolio}
            refreshSpendAt={refreshSpendAt}
            aggregateType="combined-consumable"
            title={`${consumableTotalsTitle}${markupTitleStatus}`}//'Consumables – Totals'
            dataTestId="combined-consumables-spend-aggregate"
          />
        </div>

      }
      {
        visibilitySettings.show_consumable_po_committed_spend_table &&
        <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
          <PrintConsumableHeading />
          <Header
            title={`${subTitleIndexes[consumablePurchaseOrdersTitle]} ${consumablePurchaseOrdersTitle}${markupTitleStatus}`}
            className="no-screen"
          />
          <SpendSimplePOs
            includeMarkup={includeMarkup}
            contract={contract}
            portfolio={portfolio}
            refreshSpendAt={refreshSpendAt}
            spendType="committed-consumable"
            title={`${consumablePurchaseOrdersTitle}${markupTitleStatus}`}//"Consumables – Purchase Orders"
            dataTestId="committed-consumables-spend-pos"
            paging
            remoteOperations
          />
        </div>
      }
      {
        visibilitySettings.show_consumable_monthly_actual_aggregate_table &&
        <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
          <PrintConsumableHeading />
          <Header
            title={`${subTitleIndexes[consumablePaidTotalsTitle]} ${consumablePaidTotalsTitle}${markupTitleStatus}`}
            className="no-screen"
          />
          <SpendAggregate
            includeMarkup={includeMarkup}
            contract={contract}
            portfolio={portfolio}
            refreshSpendAt={refreshSpendAt}
            aggregateType="consumable"
            title={`${consumablePaidTotalsTitle}${markupTitleStatus}`}//'Consumables – Paid – Totals'
            dataTestId="consumables-spend-aggregate"
          />
        </div>
      }

      {
        visibilitySettings.show_consumable_po_actual_spend_table &&
        <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
          <PrintConsumableHeading />
          <Header
            title={`${subTitleIndexes[consumablePaidPurchaseOrdersTitle]} ${consumablePaidPurchaseOrdersTitle}${markupTitleStatus}`}
            className="no-screen"
          />
          <SpendSimplePOs
            includeMarkup={includeMarkup}
            contract={contract}
            portfolio={portfolio}
            refreshSpendAt={refreshSpendAt}
            spendType="consumable"
            title={`${consumablePaidPurchaseOrdersTitle}${markupTitleStatus}`}//"Consumables – Paid – Purchase Orders"
            dataTestId="consumables-spend-pos"
            paging
            remoteOperations
          />
        </div>
      }
      {
        visibilitySettings.show_consumable_individual_spend_table &&
        <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
          <PrintConsumableHeading />
          <Header
            title={`${subTitleIndexes[consumableNonPOTransactionsTitle]} ${consumableNonPOTransactionsTitle}${markupTitleStatus}`}
            className="no-screen"
          />
          <SpendSimple
            includeMarkup={includeMarkup}
            contract={contract}
            portfolio={portfolio}
            refreshSpendAt={refreshSpendAt}
            spendType="consumable"
            title={`${consumableNonPOTransactionsTitle}${markupTitleStatus}`}//"Consumables – Non Purchase Order Transactions"
            dataTestId="consumables-spend"
            paging
            remoteOperations
          />
        </div>
      }
      {
        showConsumable() &&
        <NewPageWrapper breakAfter />
      }

      {showManaged() && <PrintTitle sectionNumberMap={sectionNumberMap} reportSection={reportSectionStructure.SpendManaged} />}
      {
        visibilitySettings.show_managed_monthly_combined_aggregate_table &&
        <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
          <PrintManagedHeading />
          <Header
            title={`${subTitleIndexes[managedTotalsTitle]} ${managedTotalsTitle}${markupTitleStatus}`}
            className="no-screen"
          />
          <SpendAggregate
            includeMarkup={includeMarkup}
            contract={contract}
            portfolio={portfolio}
            refreshSpendAt={refreshSpendAt}
            aggregateType="combined-managed"
            title={`${managedTotalsTitle}${markupTitleStatus}`}//'Managed – Totals'
            dataTestId="combined-managed-spend-aggregate"
          />
        </div>

      }
      {
        visibilitySettings.show_managed_po_committed_spend_table &&
        <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
          <PrintManagedHeading />
          <Header
            title={`${subTitleIndexes[managedPurchaseOrdersTitle]} ${managedPurchaseOrdersTitle}${markupTitleStatus}`}
            className="no-screen"
          />
          <SpendSimplePOs
            includeMarkup={includeMarkup}
            contract={contract}
            portfolio={portfolio}
            refreshSpendAt={refreshSpendAt}
            spendType="committed-managed"
            title={`${managedPurchaseOrdersTitle}${markupTitleStatus}`}//"Managed – Purchase Orders"
            dataTestId="committed-managed-spend-pos"
            paging
            remoteOperations
          />
        </div>
      }
      {
        visibilitySettings.show_managed_monthly_actual_aggregate_table &&
        <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
          <PrintManagedHeading />
          <Header
            title={`${subTitleIndexes[managedPaidTotalsTitle]} ${managedPaidTotalsTitle}${markupTitleStatus}`}
            className="no-screen"
          />
          <SpendAggregate
            includeMarkup={includeMarkup}
            contract={contract}
            portfolio={portfolio}
            refreshSpendAt={refreshSpendAt}
            aggregateType="managed"
            title={`${managedPaidTotalsTitle}${markupTitleStatus}`}//'Managed – Paid – Totals'
            dataTestId="managed-spend-aggregate"
          />
        </div>
      }

      {
        visibilitySettings.show_managed_po_actual_spend_table &&
        <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
          <PrintManagedHeading />
          <Header
            title={`${subTitleIndexes[managedPaidPurchaseOrdersTitle]} ${managedPaidPurchaseOrdersTitle}${markupTitleStatus}`}
            className="no-screen"
          />
          <SpendSimplePOs
            includeMarkup={includeMarkup}
            contract={contract}
            portfolio={portfolio}
            refreshSpendAt={refreshSpendAt}
            spendType="managed"
            title={`${managedPaidPurchaseOrdersTitle}${markupTitleStatus}`}//"Managed – Paid – Purchase Orders"
            dataTestId="managed-spend-pos"
            paging
            remoteOperations
          />
        </div>
      }
      {
        visibilitySettings.show_managed_individual_spend_table &&
        <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
          <PrintManagedHeading />
          <Header
            title={`${subTitleIndexes[managedNonPOTransactionsTitle]} ${managedNonPOTransactionsTitle}${markupTitleStatus}`}
            className="no-screen"
          />
          <SpendSimple
            includeMarkup={includeMarkup}
            contract={contract}
            portfolio={portfolio}
            refreshSpendAt={refreshSpendAt}
            spendType="managed"
            title={`${managedNonPOTransactionsTitle}${markupTitleStatus}`}//"Managed – Non Purchase Order Transactions"
            dataTestId="managed-spend"
            paging
            remoteOperations
          />
        </div>
      }
      {
        showManaged() &&
        <NewPageWrapper breakAfter />
      }




    </>
  );
};

export default withPageContext(Spend, {
  visibilityMenu: spendRightVisibilityMenu
})

export const PortfolioSpend = withPortfolioPageContext(Spend, {
  visibilityMenu: spendRightVisibilityMenu
})