import React, { memo, useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

import SiteCard from '../../Components/SiteCard/SiteCard';
import { SiteIndex } from "../../Interfaces/Sites.interface";
import { SiteCardModeFront, SiteCardModeBack } from "../../Models/SitesEnum";
import { Dictionary } from "../../../Common/Interfaces/Entity.interface";
import { favouriteSite } from "components/AdminPanel/Sites/Actions/AdminPanelSites.actions";
import { dictionaryIdPrefix } from "../../Helpers/SiteDictionary.helper";
import { sitesIndexVisibilitySelector, selectShowNonFavouriteSitesSetting } from 'components/Profile/Selectors/Profile.selector';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: "1rem"
  }
}));

interface SiteGridProps {
  sites: Dictionary<SiteIndex>;
}

const SiteGrid = ({ sites }: SiteGridProps) => {
  const classes = useStyles();
  //const sortBySiteRef = (a: any, b: any) => (a.site_ref > b.site_ref) ? 1 : -1
  const sortBySiteRef = (a: any, b: any) => {
    let aSite = sites[a];
    let bSite = sites[b];
    return aSite.site_ref > bSite.site_ref ? 1 : -1
  }

  const dispatch = useDispatch();
  const sitesIndexVisibilitySettings = useSelector(sitesIndexVisibilitySelector);
  const siteFavouriteKeys = Object.keys(sites).filter((key) => sites[key].favourite).sort(sortBySiteRef);
  const siteStandardKeys = Object.keys(sites).filter((key) => !sites[key].favourite).sort(sortBySiteRef);
  const showNonFavourites = useSelector(selectShowNonFavouriteSitesSetting);
  const showDeactivatedContracts = sitesIndexVisibilitySettings["Deactivated Contracts"].default.value;
  let siteKeys = showNonFavourites ? [...siteFavouriteKeys, ...siteStandardKeys] : siteFavouriteKeys;
  if (!showDeactivatedContracts) {
    siteKeys = siteKeys.filter((key) => sites[key].active)
  }
  const [siteState, setSiteState] = useState(sites);

  useEffect(() => {
    setSiteState(sites);
  }, [sites, showNonFavourites, showDeactivatedContracts]); //resetting site state on changing showNonFavourites settings helps when the index page has been reloaded since last changing
  // the setting. It means the siteState gets the new site list in time for the card rendering to have all the keys, when re-displaying non favourited sites.

  const changeSite = (
    sites: Dictionary<SiteIndex>,
    replacement: SiteIndex
  ): Dictionary<SiteIndex> => {

    // iterate the passed in sites, if the newly selected site is displaying the rear face, flip all cards to the front
    // (the replacement card will override it's replacement afterwards, so will show up on the rear face)
    // otherwise leave them as they are .. it's ok to do this because if the replacement card is being 
    // moved onto it's front face, the card being replaced should be the only card showing the rear face owing to this same
    // function having being run on each previous selection.
    const newSites = replacement.mode === SiteCardModeBack
      ? siteKeys.reduce((acc, key: string) => {
        const newSite = { ...sites[key], mode: SiteCardModeFront }
        return { ...acc, [key]: newSite };
      }, {})
      : sites;

    return {
      ...newSites,
      [dictionaryIdPrefix(replacement.id)]: replacement
    }
  };

  const handleSelectSite = (site: SiteIndex): void => {
    setSiteState(changeSite(siteState, site));
  };

  const handleFavourite = (site: SiteIndex): void => {
    const newFavState = site.favourite ? false : true;
    dispatch(favouriteSite(site.id, newFavState));
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {siteKeys.map((key: any) => {
          //console.log('key: ', key);
          if (siteState[key]) {
            //console.log('siteState[key]: ', siteState[key]);
            return (
              siteState[key] && <Grid key={siteState[key].id} item xs={12} sm={6} lg={3}>
                <SiteCard
                  onFavourite={handleFavourite}
                  onSelectSite={handleSelectSite}
                  onUnselectSite={handleSelectSite}
                  site={siteState[key]}
                  documentDialogOpen={false}
                />
              </Grid>
            )
          }
        }
        )}
      </Grid>
    </div>
  );
};

export default memo(SiteGrid);
