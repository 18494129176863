import React, { useEffect, useState, useCallback, useRef, memo } from "react";
import { Paper, Divider } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Observable } from "rxjs";

// Own
import MaintenanceTable from "../../Components/MaintenanceTable/MaintenanceTable";
import { MaintenanceChartProps } from "components/ContractInFocus/Maintenance/Components/MaintenanceChart/MaintenanceChart";
import { Snapshot } from "components/ContractInFocus/Interfaces/ContractInFocus.interfaces";
import { transformForChartType } from "components/ContractInFocus/Maintenance/Helper/TablePolyfill.helpers";
import { InteractiveTransformedResponse } from "components/ContractInFocus/Maintenance/Interfaces/interactiveService.interfaces";
import { getSubTitle } from "components/ContractInFocus/Components/ContractPrintTitle/ContractPrintTitle";
import {
  TableChartWrapper,
  DividerWrapper
} from "./MaintenanceTableChartStyles";
import MaintenanceService from "components/ContractInFocus/Maintenance/Services/MaintenanceService";
import * as contractSelectors from "components/ContractInFocus/Selectors/contractInFocus.selectors";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import GridHeader from "components/Common/Components/GridHeader/GridHeader";
import {
  NewGridPayload,
  UpdateGridPayload,
  ReportConfig,
  ReportSubdivisionConfig,
  InteractiveTableChart,
  DataRow
} from "../../Interfaces/interactiveTable.interfaces";
import { FieldMetaGroup, Dictionary } from '../../../../Common/Interfaces/Entity.interface';

import NewPageWrapper from "components/Common/Components/PrintStyles/Print";
import { APIPrivileges } from 'services/Interface/Interface';
import { apiPrivilegesInitalState } from 'services/models/apiprivileges';
import { getContractScopeParams } from 'helpers/Pipelines/contractScopeOperator';
import { ContractInterface } from "components/AdminPanel/Contracts/Interfaces/Contract.interface";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { PrintChartAndTableLabels } from "components/Common/constants.js";
import { ContractPeriod } from "components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface";
import * as portfolioInFocusSelectors from 'components/PortfolioInFocus/Selectors/portfolioInFocus.selectors';
import { getMonthsBetween } from "components/Common/Utils/Dates.js";

import { showColumns } from "components/ContractInFocus/Maintenance/Helper/View.helpers";
import { panableView, extractDataArraysFromBookendedTableRow } from "components/ContractInFocus/Maintenance/Helper/View.helpers";
import { useGetPanners, setInitialViewFrom } from "components/ContractInFocus/Hooks/UsePannable/usePannable";

// Styles
import { InlineWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { isEqual } from "lodash";

export interface getMaintenanceDataProps {
  category: ReportConfig,
  contract?: SiteContract,
  portfolio?: HydratedPortfolio,
  months: string[],
  transformForChart: transformForChartType,
  focusSnapshot: Snapshot,
  params: Dictionary<string>
}

interface MaintenanceTableChartProps {
  contract?: SiteContract;
  portfolio?: HydratedPortfolio;
  //selectedPeriod: ContractPeriod;
  //focusedSnapshot?: Snapshot;
  className?: string;
  title?: string;
  subdivision: ReportSubdivisionConfig;
  category: ReportConfig;
  Chart: React.FC<MaintenanceChartProps>;
  getData: ({ category, contract, portfolio, months, transformForChart, focusSnapshot, params, }: getMaintenanceDataProps) => Observable<InteractiveTransformedResponse>;
  transformForChart: (ppmRecords: DataRow[], months: string[]) => any;
  setLoading?: (loading: boolean) => void;
  animate?: boolean;
  showTable?: boolean;
  showChart?: boolean;
  exposeDataSet?: Function;
}

const MaintenanceTableChart = ({
  contract,
  portfolio,
  className,
  //selectedPeriod,
  //focusedSnapshot,
  title,
  category,
  Chart,
  getData,
  transformForChart,
  subdivision,
  setLoading,
  animate = true,
  showTable = true,
  showChart = true,
  exposeDataSet
}: MaintenanceTableChartProps) => {
  const contractContractPeriod = useSelector(contractSelectors.contractInFocusFocusedContractPeriodSelector);
  const portfolioPeriodDates = useSelector(portfolioInFocusSelectors.portfolioInFocusPeriod);
  const portfolioPeriodDateMonths = (portfolioPeriodDates?.start_date && portfolioPeriodDates?.end_date) ? getMonthsBetween(portfolioPeriodDates?.start_date, portfolioPeriodDates?.end_date) : [];
  // typescript appears unable to correctly infer the ContractPeriod | ContractPeriodDates | undefined type from the ternary expression below, hence ts-ignore below
  const selectedPeriod = contract ? contractContractPeriod : portfolioPeriodDates;
  const portfolioSelectedSnapShot = useSelector(portfolioInFocusSelectors.portfolioInFocusSnapshotFocusSelector);
  const contractSelectedSnapshot = useSelector(contractSelectors.contractInFocusSnapshotFocusSelector);
  const selectedFocusedSnapshot = contract ? contractSelectedSnapshot : portfolioSelectedSnapShot;
  const [dataset, setDataset] = useState<InteractiveTableChart>();
  const [gridWidth, setGridWidth] = useState<number>();
  const [dataGridRef, setDataGridRef] = useState<React.MutableRefObject<any>>();
  const [metadata, setMetadata] = useState<FieldMetaGroup>();
  const [privileges, setPrivileges] = useState<APIPrivileges>(apiPrivilegesInitalState);

  const [viewLength, setViewLength] = useState(0);
  const [primaryRow, setPrimaryRow] = useState<any>();
  const [primaryRowAsArray, setPrimaryRowAsArray] = useState<any[]>();
  const [dataAsArray, setDataAsArray] = useState<any[]>();
  const [viewFrom, setViewFrom] = useState(0);
  const getSetViewFrom = [viewFrom, setViewFrom];
  const [panned, setPanned] = useState(false);
  const cannotPanLeft = useRef(false);
  const cannotPanRight = useRef(false);
  const offsetTwelthes = selectedFocusedSnapshot ? 12 : 8  //for snapshots we want the snapshot month to be on the 'hard right' where there are enough columns
  const { onPanLeft, onPanRight } = useGetPanners({
    dataLength: dataAsArray?.length || 0,
    getSetViewFrom,
    setPanned,
    viewLength,
    lowerLimitReachedRef: cannotPanLeft,
    upperLimitReachedRef: cannotPanRight
  });

  // This is used by MaintenanceTable to set pannable information for the MaintenanceChart 
  const [pannableChartPosition, setPannableChartPosition] = useState<any>({ viewFrom: 0, viewLength: 12 });

  useEffect(() => {
    const newPrimaryRow = dataset?.table[0];
    if (newPrimaryRow) {
      if (!primaryRow || !isEqual(primaryRow, newPrimaryRow)) {
        setPrimaryRow(newPrimaryRow)
        const { primaryRowAsArray, dataAsArray } = extractDataArraysFromBookendedTableRow(newPrimaryRow);
        setPrimaryRowAsArray(primaryRowAsArray);
        setDataAsArray(dataAsArray);
      }
    }
  }, [dataset])

  useEffect(() => {
    if (primaryRowAsArray && dataAsArray) {
      const viewLength = showColumns(!gridWidth ? 1000 : gridWidth, selectedFocusedSnapshot);
      setViewLength(viewLength);
      const panFilteredRow = panableView({ primaryRowAsArray, dataAsArray, viewFrom, viewLength });
      setPrimaryRow(panFilteredRow);
      setPannableChartPosition && setPannableChartPosition({ viewFrom, viewLength })
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [gridWidth, viewFrom, setViewLength, primaryRowAsArray, dataAsArray]);

  useEffect(() => {
    dataAsArray && setInitialViewFrom(
      {
        dataGrid: dataGridRef?.current?.instance,
        panned,
        dataArray: dataAsArray,
        offsetTwelthes,
        setViewFrom,
        selectedFocusedSnapshot,
        viewLength
      }
    )
    // dependencies here are important - at least without the viewLength one the calcs are inaccurate so it must change between renders!
  }, [panned, dataGridRef?.current, dataAsArray, offsetTwelthes, setViewFrom, selectedFocusedSnapshot, viewLength]);

  const reloadData = useCallback(() => {
    setLoading && setLoading(true);
    if ((contract || portfolio) && selectedPeriod) {
      const params = getContractScopeParams(selectedPeriod);
      getData({
        category,
        contract,
        portfolio,
        //@ts-ignore
        months: selectedPeriod?.months || portfolioPeriodDateMonths,
        transformForChart: transformForChart,
        focusSnapshot: selectedFocusedSnapshot,
        params
      }).subscribe(response => {
        !!response.data && setDataset(response.data[subdivision.dataKey])
        !!response.metadata && setMetadata(response.metadata);
        !!response.privileges && setPrivileges(response.privileges);
        setLoading && setLoading(false);
      });
    }
  }, [category, contract, portfolio, selectedPeriod, setLoading, subdivision.dataKey]);

  useEffect(() => {
    reloadData();
  }, [reloadData]);

  exposeDataSet && exposeDataSet(dataset);

  const handleNewRecord = (
    payload: NewGridPayload,
    subdivision: string
  ) => {
    if ((contract || portfolio) && selectedPeriod) {
      MaintenanceService.newRecord(
        {
          category,
          column: payload.columnKey,
          contract,
          portfolio,
          subdivision,
          payload: payload.dataGridPayload
        }
      ).subscribe(() => {
        markColumnForUpdate(payload.columnKey);
        reloadData();
      });
    }
  };

  const markColumnForUpdate = (column: string): void => {
    dataset && dataset.table.forEach(row => (row[column].insert = false));
  };

  const getHandleNewRecord = useCallback(payload =>
    handleNewRecord(
      payload,
      subdivision.dataKey
    ), []);

  const handleUpdateRecord = useCallback((payload: UpdateGridPayload) => {
    if ((contract || portfolio) && selectedPeriod) {
      MaintenanceService.updateRecord(
        {
          category,
          contract,
          portfolio,
          id: payload.id,
          payload: payload.dataGridPayload
        }
      ).subscribe(() => reloadData());
    }
  }, []);

  return (
    (showChart || showTable)
      ? <TableChartWrapper id="tableChart">
        <NewPageWrapper breakAfter>
          <InlineWrapper>
            <Paper elevation={3}>
              {dataset && metadata ? (
                <>
                  {showChart
                    ? <>
                      <GridHeader
                        level={1}
                        title={`${subdivision.caption} ${category.caption} Chart`}
                        subTitle={getSubTitle(metadata)}
                        className={PrintChartAndTableLabels ? '' : 'no-print'}
                      />
                      {/* <MaintenanceChart
                        className={className ? `${className}-chart` : ''}
                        animate={animate}
                        data={dataset.chart}
                        {...pannableChartPosition}
                      /> */}
                      {dataset.chart && <Chart
                        className={className ? `${className}-chart` : ''}
                        animate={animate}
                        data={dataset.chart}
                        {...pannableChartPosition}
                      />}
                      <DividerWrapper className='maintenance-divider'>
                        <Divider />
                      </DividerWrapper>
                    </>
                    : null}
                  {showTable
                    ? <>
                      <GridHeader
                        level={1}
                        title={`${subdivision.caption} ${category.caption} Table`}
                        subTitle={getSubTitle(metadata)}
                        className={PrintChartAndTableLabels ? '' : 'no-print'}
                      />
                      <MaintenanceTable
                        className={className ? `${className}-table` : ''}
                        forPortfolio={!!portfolio}
                        updateRecord={handleUpdateRecord}
                        newRecord={getHandleNewRecord}
                        data={dataset.table}
                        meta={metadata}
                        privileges={privileges}
                        setGridWidth={setGridWidth}
                        setDataGridRef={setDataGridRef}
                        primaryRow={primaryRow}
                        viewFrom={viewFrom}
                        onPanLeft={onPanLeft}
                        onPanRight={onPanRight}
                        cannotPanRight={cannotPanRight.current}
                        cannotPanLeft={cannotPanLeft.current}
                      />
                    </>
                    : null}
                </>
              ) : null}
            </Paper>
          </InlineWrapper>
        </NewPageWrapper>
      </TableChartWrapper>
      : null
  );
};

//MaintenanceTableChart.whyDidYouRender = true;

export default memo(MaintenanceTableChart);
