import { createSelector } from 'reselect';
import { dictToArray } from '../../../../services/API/API.helper';
import { State } from '../../../../store/interfaces/State';
import { EntityBranchState } from '../../../Common/Interfaces/Entity.interface';
import { OrganisationListState, Members, OrganisationBranchState, Organisation, OrganisationState } from '../Interfaces/Organisation';
import { getOrganisationLookup } from '../Reducer/Organisation.reducer.helper';
import { peopleSelector } from '../../People/Selectors/People.selector';
import { getRelationChoiceId } from "helpers/DataGrid/DataGridColumn.helper";


export const organisationSelector: any = (state: State): OrganisationState => state.organisations;

export const organisationMetaSelector = createSelector(
  organisationSelector, (organistion: OrganisationBranchState) => organistion.meta
);

// export const organisationRecordMetaSelector = createSelector(
//   organisationSelector, (organistion: OrganisationState) => {
//     return organistion.recordmeta
//   }
// );

export const organisationByIdSelector = (id: any) => createSelector(
  organisationSelector, (organisations: OrganisationState) => ({ data: organisations.data[id], meta: organisations.meta.PUT })
);

//export const organisationByIdSelector = (id: any) => (state: State): Organisation => {data: state.organisations.data[id], meta: state.organisations.meta.PUT};

export const organisationMembersSelector: any = (state: State): Members => state.organisations.members;

export const membersByOrganisationIdSelector = (id: any) => createSelector(
  organisationMembersSelector, peopleSelector, (members: Members, people) => {
    return (members[id] || []).map(personId => people.data[personId])
  }
);

export const adminListOrganisationSelector = createSelector(
  organisationSelector, (organistions: any) => {
    return {
      data: dictToArray(organistions.data),
      dict: organistions.data,
      meta: organistions.meta.POST,
      recordmeta: organistions.recordmeta,
      permissions: organistions.permissions
    }
  });

export const allOrganisationsSelector = createSelector<any, OrganisationState, OrganisationListState>(
  organisationSelector, (organistion: any) => ({
    data: dictToArray(organistion.data),
    dict: organistion.data,
    meta: organistion.meta.POST,
    recordmeta: organistion.recordmeta,
    permissions: organistion.permissions
  })
);

export const organisationLookupSelector = createSelector(
  organisationSelector, (organistion: EntityBranchState) =>
  Object.keys(organistion.data).map(key => getOrganisationLookup(organistion.data[key]))
);

export const getOrganisationLookupSelectorByRelation = (relationChoice: string) => {
  return createSelector(
    organisationSelector, allOrganisationsSelector, (organisation: EntityBranchState, allOrganisationsSelector: OrganisationListState) => {
      const relationChoiceId = getRelationChoiceId(allOrganisationsSelector.meta?.relation?.choices || [], relationChoice);
      //@ts-ignore
      const filteredOrganisations = Object.fromEntries(Object.entries(organisation.data).filter(([k, v]) => v.relation === relationChoiceId))
      //@ts-ignore // it seems from Entries is deemed by typescript to return unknown objects...
      return filteredOrganisations ? Object.keys(filteredOrganisations).map(key => getOrganisationLookup(filteredOrganisations[key])) : [];
    }
  );
}

export const contractWithLookupSelector = createSelector(
  allOrganisationsSelector,
  (organisations) => organisations.data
    .filter((organisation: Organisation) => organisation.contract_with_eligible)
    .map(getOrganisationLookup)
);


export const organisationIndustriesSelector = createSelector(
  organisationSelector, (organistion: OrganisationBranchState) => (organistion.industries)
);
