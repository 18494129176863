import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStop } from "@fortawesome/free-solid-svg-icons";

// Own
import Completed from "./ScheduleDataGrid/Components/Flags/Completed";
import Cancelled from "./ScheduleDataGrid/Components/Flags/Cancelled";
import Scheduled from "./ScheduleDataGrid/Components/Flags/Scheduled";
import Rescheduled from "./ScheduleDataGrid/Components/Flags/Rescheduled";
import styles from "../../styles/app/common/variables.scss";
import "components/Schedulers/ScheduleLegendStyles.scss";

export interface ScheduleLegendProps {
  className?: string;
}

export const SpecialistsLegend: React.FunctionComponent<ScheduleLegendProps> = ({
  className = ''
}) => {
  const Block = ({ color }: any) => <FontAwesomeIcon icon={faStop} style={{ color }} />;

  return (
    <div
      id='specialistLegend'
      className={`scheduleLegend ${className}`}
    >
      <div>
        <Completed color={styles.grayMedDark} /> <span>Completed</span>
      </div>
      <div>
        <Cancelled color={styles.grayMedDark} /> <span>Cancelled</span>
      </div>
      <div>
        <Scheduled color={styles.grayMedDark} /> <span>Scheduled</span>
      </div>
      <div>
        <Rescheduled color={styles.grayMedDark} /> <span>Rescheduled</span>
      </div>
      <div style={{ fontWeight: 500, color: styles.grayMedDark }}>
        <Block color={styles.grayMedDark} /> <span>Scheduled PPM</span>
      </div>
      <div style={{ fontWeight: 500, color: styles.scheduledColor }}>
        <Block color={styles.scheduledColor} /> <span>Booked PPM</span>
      </div>
      <div style={{ fontWeight: 500, color: styles.reactiveColor }}>
        <Block color={styles.reactiveColor} /> <span>Reactive</span>
      </div>

    </div>
  );
};

export const HASTasksLegend: React.FunctionComponent<ScheduleLegendProps> = ({
  className = ''
}) => {
  const Block = ({ color }: any) => <FontAwesomeIcon icon={faStop} style={{ color }} />;

  return (
    <div
      id='HASTaskLegend'
      className={`scheduleLegend ${className}`}
    >
      <div>
        <Scheduled color={styles.grayMedDark} /> <span>Scheduled</span>
      </div>
      <div>
        <Completed color={styles.grayMedDark} /> <span>Completed</span>
      </div>
      <div style={{ fontWeight: 500, color: styles.scheduledColor }}>
        <Block color={styles.scheduledColor} /> <span>Routine</span>
      </div>
      <div style={{ fontWeight: 500, color: styles.reactiveColor }}>
        <Block color={styles.reactiveColor} /> <span>Ad Hoc</span>
      </div>
    </div>
  );
};
