export const openPmsColumns = [
  { name: "task_ref", width: "8%" },
  { name: "asset_description", width: "25%" },
  { name: "location", width: "11%" },
  { name: "discipline", width: "11%" },
  { name: "due_by", width: "8%" },
  { name: "asset_code", width: "10%" },
  { name: "frequency", width: "10%" },
  { name: "reason", width: "17%" }
]

export const portfolioOpenPPMColumns = [
  { name: "contract_ref", width: "6%", caption: 'Contract' },
  { name: "task_ref", width: "8%" },
  { name: "asset_description", width: "23%" },
  { name: "location", width: "11%" },
  { name: "discipline", width: "9%" },
  { name: "due_by", width: "8%" },
  { name: "asset_code", width: "10%" },
  { name: "frequency", width: "8%" },
  { name: "reason", width: "17%" }
]