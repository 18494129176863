import React from "react";
import moment from "moment";
import { PrintHeader } from "../../../Common/Components/GridHeader/GridHeader";
import { ReportSection } from "../../interfaces/contractInFocus.interfaces";
import { Dictionary } from "components/Common/Interfaces/Entity.interface";
import styles from "../../../../styles/app/common/variables.scss";
import { LogoWithText } from "components/Common/Components/Logo/Logo";


type PrintTitleProps = {
  reportSection: ReportSection;
  sectionNumberMap: Dictionary<number>;
};

type PlannerTitleProps = {
  contract_ref: string;
  portfolio_name?: string;
  site_name?: string;
};

export const PrintTitle: React.FC<PrintTitleProps> = ({
  sectionNumberMap,
  reportSection,
}) => (
  <PrintHeader title={`Section ${sectionNumberMap ? sectionNumberMap[reportSection.name] : null} - ${reportSection.heading}`} />
);

//no-screen

export const PrintPlannerTitle: React.FC<PlannerTitleProps> = ({ contract_ref, site_name, portfolio_name }) => (
  <div className="plannerTitleWrapper no-screen" style={{ marginBottom: "2rem" }}>
    <div className="plannerMainTitleWrapper" style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
      <h3 style={{ paddingTop: "20px" }}>Jaguar Building Services Ltd Specialist Subcontractor Administration System </h3>
      <LogoWithText height="60px" />
    </div>
    <hr style={{ width: "100%", height: "0px", borderBottomColor: styles.darkBlue }}></hr>
    <div className="planner SubTitle" style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
      <div className="leftGroup" style={{ display: "flex", width: "50%", justifyContent: "space-between", fontWeight: 600 }}>
        <span>Subcontractor Details</span>
        {portfolio_name && <span>Portfolio:&nbsp;{portfolio_name}</span>}
        {!portfolio_name && <span>Contract No:&nbsp;{contract_ref}</span>}
        {!portfolio_name && <span >Site Name:&nbsp;{site_name}</span>}
      </div>
      <div className="rightGroup" style={{ fontWeight: 600 }}>
        {moment().format("DD MMMM YYYY")}
      </div>
    </div>

  </div>
);

export const getSubTitle = (metadata: any) => {
  return metadata.description ? `${metadata.description}` : undefined
}
