import { FieldFormConfig } from "components/Common/Components/DocumentsGrid/DocumentsGrid.interface";

export const getInputWrapperClassName = (dataField: string, fieldConfig?: FieldFormConfig) => {
    return `input-wrapper--${dataField} ${fieldConfig?.controlClassName ? fieldConfig.controlClassName : ''}`
}

export const getAutoFieldInputWrapperClassName = (fieldConfig: FieldFormConfig, dataField: string) => {
    const cn = getInputWrapperClassName(dataField, fieldConfig);
    return `autoField-input-wrapper ${cn} ${fieldConfig.printable ? 'printable' : ''} ${fieldConfig.hidden ? 'hidden' : ''}`
}

export const getInViewReffableClassName = (dataField: string, fieldConfig?: FieldFormConfig) => {
    const cn = getInputWrapperClassName(dataField, fieldConfig);
    return `inViewReffableContainer ${cn}`
}
