import { FieldMetaGroup, EntityState } from "components/Common/Interfaces/Entity.interface";
import { APIPrivileges } from "services/Interface/Interface";
import { IMeterReadingsStream } from 'components/ContractInFocus/MeterReadings/Interfaces/MeterReadings.interface';
import * as meterStreamActions from "../Actions/MeterStreams.actions";


export type MeterStreamListState = EntityState<IMeterReadingsStream[]>;
export interface MeterStreamState {
    [contractId: number]: MeterStreamListState;
}
interface meterStreamAction {
    contractId: number;
    payload: any;
    type: string;
}
const initialState = {
}

const meterStreamsReducer = (state: MeterStreamState = initialState, { contractId, type, payload }: meterStreamAction) => {
    switch (type) {
        case meterStreamActions.FETCH_METER_STREAMS_FOR_CONTRACT.reducer: {
            return {
                ...state,
                [contractId]: {
                    data: payload.data,
                    meta: { "POST": payload.meta, "PUT": payload.putMeta },
                    permissions: payload.permissions
                },
            }
        }
        case meterStreamActions.UPDATE_METER_STREAMS_FOR_CONTRACT.reducer: {
            const newDataSet = state[contractId].data.map((record) => {
                if (record.id == payload.data.id) {
                    return payload.data
                } else {
                    return record
                }
            })
            return {
                ...state,
                [contractId]: {
                    ...state[contractId],
                    data: newDataSet,
                },
            }
        }
        default:
            return state;
    }
};

export default meterStreamsReducer;