export const sentenceCase = (str: string): string =>
  str.substr(0, 1).toUpperCase() + str.substr(1, str.length);

export const capitalize = (s: string | undefined | null) => s && s[0].toUpperCase() + s.slice(1);

interface ElipseProps {
  s: string | undefined | null,
  mode: "start" | "end" | "middle",
  threshold: number,
  blockSize: number,
}
export const elipse = ({ s, mode, threshold, blockSize }: ElipseProps) => {
  let thisString = s || '';
  let newString: string | undefined = undefined;
  let thisThreshold = threshold || 250;
  if (s?.length && s?.length > thisThreshold) {
    if (mode === "middle") {
      newString = thisString.slice(0, blockSize) + '...' + (thisString.slice(thisString.length - blockSize, thisString.length));
    } else if (mode === "start") {
      newString = thisString.slice(0, blockSize) + '...';
    } else if (mode === "end") {
      newString = '...' + (thisString.slice(thisString.length - blockSize, thisString.length));
    }
  }
  return newString || thisString;
}

export function toTitleCase(str: string): string {
  if (str) {
    const words = str.split(" ");
    const newStr = words.map((word) => {
      if (word[0]) { // if there are several spaces together, word could be an empty string and word[0] undefined
        return word[0].toUpperCase() + word.substring(1);
      } else {
        return word //will preserve empty spaces
      }
    }).join(" ");
    return newStr;
  } else {
    return '';
  }
}

export function wordPerLine(str: string): string {
  if (str) {
    const words = str.split(" ");
    const newStr = words.map((word) => {
      if (word[0]) { // if there are several spaces together, word could be an empty string and word[0] undefined
        return word[0].toUpperCase() + word.substring(1);
      } else {
        return '' //will remove empty spaces
      }
    }).join("\r\n");
    return newStr;
  } else {
    return '';
  }
}

export function toPythonVarName(str: string): string {
  let lower = str.toLowerCase();
  return lower.split(" ").filter(x => x !== "").join("_").replace(/\W/g, "");
}

export function getExtensionFromURLString(filename: string) {
  //@ts-ignore ts for some reason thinks that after .split('.') could produce undefined, which it could not
  return filename?.split(/[#?]/)[0].split('.').pop().trim();

}

