import React, { useState, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

// Own
import { updateOrderApi } from "components/ContractInFocus/Services/commonAPI.services";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import DocumentsGrid from "components/Common/Components/DocumentsGrid/DocumentsGrid";
import { GeneralDocsGridWrapper } from 'components/SiteDocuments/Components/ContractDocumentsViewGrid/ContractDocumentGridStyles';
import { AppendixDocumentFilesWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { ColumnsConfig } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';
import { PreFlightListInfo } from 'components/Common/Interfaces/Entity.interface';
import DocumentsUploader from "components/Common/Components/DocumentsUploader/DocumentUploader";
import { addNotification } from 'components/Notification/Actions/Notification.actions';
import * as inFocusSelectors from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import { NOTIFICATION_ERROR } from 'components/Notification/Constants/constants';
import { defaultSnapshotInitialUploadDocState } from "constants/snapshots.constants";

// Styles
import './contractReportVisibility.scss';
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

type ContractReportAppendicesProps = {
    contract?: SiteContract;
    portfolio?: HydratedPortfolio;
    preFlightInfo: PreFlightListInfo;
    onModalClose?: () => void;
}

export const ContractReportAppendices: React.FunctionComponent<ContractReportAppendicesProps> = ({
    contract,
    portfolio,
    preFlightInfo,
    onModalClose,
}) => {

    const dialogueTitle = 'Report Appendix Controls';
    const [open, setOpen] = React.useState(false);
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 0);
    const [fieldsConfig, setFieldsConfig] = useState<FieldsFormConfig>({});
    const selectedSnapShot = useSelector(inFocusSelectors.contractOrPortfolioSnapshotSelector({ portfolioId: portfolio?.id, contractId: contract?.id }));
    //const selectedSnapShot = useSelector(inFocusSelectors.contractInFocusSnapshotHistorySelector);
    const listBasePath = `${portfolio ? 'portfolio-' : ''}snapshots/${selectedSnapShot.id}/mmr-appendices/`;
    const orderBasePath = `${portfolio ? 'portfolio-' : ''}snapshots/${selectedSnapShot.id}/mmr-appendix-order/`;
    const [fetchFilters, setFetchFilters] = useState(
        {
            "snapshot-document-types": "monthly_maintenance_report_appendix",
            "snapshot": selectedSnapShot.id
        }
    )

    const refresh = () => {
        //console.log('about to refresh...');
        forceUpdate()
    }

    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
    }
    const columnsConfig: ColumnsConfig = {
        'description': {
            'caption': 'Description',
        },
    }

    const handleOnReorder = (e: any, callback: () => void) => {
        updateOrderApi(orderBasePath, e.itemData.order_id, { order: e.toIndex }).then(() => {
            callback()
        });
    }

    useEffect(() => {
        setFieldsConfig(
            {
                'snapshot': {
                    label: "Select Snapshot",
                    hidden: true,
                    autoCalculator: (values) => selectedSnapShot?.id,
                    selectFirstAsDefault: false,
                    selectLastAsDefault: false,
                },
                'snapshot_document_type': {
                    hidden: true,
                    autoCalculator: (values) => {
                        let id = undefined;
                        const choices = preFlightInfo?.meta?.snapshot_document_type.choices
                        if (choices) {
                            const appendixChoice = choices.filter(x => x.display_name.toLowerCase() === "monthly maintenance report appendix")
                            id = appendixChoice[0] ? appendixChoice[0].value : undefined;
                            if (!id) {
                                setTimeout(() => {
                                    dispatch(addNotification({ message: 'Monthly Maintenance Appendix Type id could not be found!', type: NOTIFICATION_ERROR }));
                                }, 1000);
                                setTimeout(() => {
                                    setOpen(false);
                                }, 2000);
                            }
                        }
                        return id;
                    }
                },
                'description': {},
            }
        )
    }, [preFlightInfo, dispatch, selectedSnapShot])



    useEffect(() => {
        setOpen(true);
    }, [contract?.contract_ref, portfolio?.id]);

    return (
        <Dialog
            id="contractReportAppendices"
            open={open}
            onClose={handleClose}
            onExited={onModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth={true}
            transitionDuration={{ appear: 0, enter: 1000, exit: 0 }}
        >
            <DialogTitle id="ReportAppendicesTitle">{dialogueTitle}</DialogTitle>
            <DialogContent>
                <div className="reportAppendices-dialog__content">
                    {preFlightInfo!! && <GeneralDocsGridWrapper>
                        <AppendixDocumentFilesWrapper>
                            <DocumentsGrid
                                listBasePath={listBasePath}
                                columnsConfig={columnsConfig}
                                fetchFilters={fetchFilters}
                                refreshSignal={mustRefresh}
                                orderable={true}
                                handleOnReorder={handleOnReorder}
                                hideSource
                            />
                        </AppendixDocumentFilesWrapper>
                        {preFlightInfo.canCreate && <DocumentsUploader
                            urlContext={listBasePath}
                            fieldConfigs={fieldsConfig}
                            acceptedFileTypes={["application/pdf"]}
                            //@ts-ignore not sure why typescript is being thick here...
                            preFlightInfo={preFlightInfo}
                            gridId="snapshotAppendicesView"
                            reset={refresh}
                            initialState={defaultSnapshotInitialUploadDocState}
                        />}
                    </GeneralDocsGridWrapper>
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>

    );
}