import React, { useState, useEffect, useRef } from 'react';
import CachedIcon from '@material-ui/icons/Cached';
import { MatIconButton } from 'components/Common/Components/Material/MatIconButton/MatIconButton';

import { RightContextRefreshButtonWrapper, refreshControlsStyles, getRefreshControlsStyles } from "components/Common/Components/RightVisibilityMenu/Components/RightContextButton/rightContextButtonStyles";


type RefreshingProps = {
    onclick: any;
    after: any;
    disabled?: boolean;
};

type RefreshingButtonProps = {
    onclick: any;
    refreshing: boolean;
    disabled?: boolean;
};

export const RefreshButton: React.FC<RefreshingButtonProps> = ({
    onclick,
    refreshing,
    disabled
}) => {
    return <RightContextRefreshButtonWrapper>
        <MatIconButton onClick={onclick} disabled={disabled}>
            <CachedIcon style={getRefreshControlsStyles(refreshing)} />
        </MatIconButton>
    </RightContextRefreshButtonWrapper>
}

export const RefreshControlComponent: React.FC<RefreshingProps> = ({
    onclick,
    after,
    disabled
}) => {

    //const refreshing = useRef(false);
    const [refreshingStatus, setRefreshingStatus] = useState(false);

    const refresh = (event: any) => {
        event.stopPropagation();
        if (!refreshingStatus) {
            setRefreshingStatus(true);
            onclick().then(
                () => {
                    after();
                    setRefreshingStatus(false);
                }
            ).catch(() => {
                setRefreshingStatus(false);
            });
        }
    }
    return <RefreshButton onclick={refresh} refreshing={refreshingStatus} disabled={disabled}></RefreshButton>
}