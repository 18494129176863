import React from 'react'
import IconButton from '@material-ui/core/IconButton';
import FolderSharedIcon from '@material-ui/icons/FolderShared';

// Styles 
import { useHeaderAdminStyles } from './HeaderAdminButtonStyles';

interface HeaderAdminButtonProps {
  visible: boolean;
  isDark: boolean;
  onOpenAdminPanel: () => void;
}

const HeaderAdminButton: React.SFC<HeaderAdminButtonProps> = ({
  visible,
  isDark,
  onOpenAdminPanel
}) => {

  const classes = useHeaderAdminStyles();

  const getIconClass = (isDark: boolean): string =>
  isDark ? classes.darkMenuIcon : classes.lightMenuIcon;

  return (
    <>
      {visible &&
        <IconButton
          edge='start'
          color='inherit'
          aria-label='Admin Panel'
          onClick={onOpenAdminPanel}
        >
          <FolderSharedIcon className={getIconClass(isDark)} />
        </IconButton>
      }
    </>
  )
}

export default HeaderAdminButton;