import { switchMap, map, catchError } from "rxjs/operators";
import { ofType, Epic } from "redux-observable";
import moment from "moment";

import { of, Observable } from "rxjs";
import { mode } from "../../../Common/Consts/DataMode";
import { APIR } from "../../../../services/API/API";
import { getData, getMeta } from "../../../../services/API/API.helper";
import { ROUTE } from "./ContractsHelpers.epic";
import { contractPeriodKey } from "../../ContractPeriods/Interfaces/ContractPeriod.interface";
import { transformContractPeriod } from "./ContractsHelpers.epic";
import {
  saveNewSubItem,
  setSubItemMode,
  removeDataSubItem
} from '../../../../store/actions/branchSublist.actions';
import { adminPanelType } from "../../Models/AdminPanel.model";
import { fetchContractPeriods } from "components/ContractInFocus/Actions/contractInFocus.actions";
import * as fromContractPeriodActions from "../../ContractPeriods/Actions/ContractPeriods.actions";
import * as fromAdminPanelActions from "../../Actions/adminPanel.actions";


const CONTRACT_PERIOD_ROUTE = '/contract-periods/';

const updateContractPeriodEpic: Epic = (action$: any) => action$.pipe(
  ofType(fromContractPeriodActions.UPDATE_CONTRACT_PERIOD.start),
  map((action: any) => {
    const { id, contract, mode, ...contractPeriod } = action.contractPeriod;
    return { panelId: action.panelId, contract, id, contractPeriod }
  }),
  switchMap(({ panelId, contract, id, contractPeriod }) =>
    APIR.patch(`${ROUTE}${contract.contract_ref}${CONTRACT_PERIOD_ROUTE}${id}/`, contractPeriod).pipe(
      map(response => ({
        data: transformContractPeriod(getData(response)),
        meta: getMeta(response)
      })),
      switchMap(() => [
        setSubItemMode(adminPanelType.contracts, contractPeriodKey, contract.id, id, mode.view),
        fetchContractPeriods(contract.contract_ref)
      ]),
      catchError((error) => of(fromAdminPanelActions.setApiErrors(panelId, error))),
    )
  )
);

export const createContractPeriodEpic = (action$: any) => action$.pipe(
  ofType(fromContractPeriodActions.CREATE_CONTRACT_PERIOD.start),
  map((action: any) => {
    const { contract, start_date, end_date } = action.contractPeriod;
    return {
      panelId: action.panelId,
      contract,
      contractPeriod: {
        start_date: moment(start_date).format("YYYY-MM-DD"),
        end_date: moment(end_date).format("YYYY-MM-DD"),
        contract: contract.id
      },
    };
  }),
  switchMap(({ panelId, contract, contractPeriod }) =>
    APIR.post(`${ROUTE}${contract.contract_ref}${CONTRACT_PERIOD_ROUTE}?format=json`, contractPeriod).pipe(
      map(response => ({
        data: transformContractPeriod(getData(response)),
        meta: getMeta(response)
      })),
      switchMap(contractPeriod => [
        saveNewSubItem(adminPanelType.contracts, contractPeriodKey, contract.id, contractPeriod.data),
        fetchContractPeriods(contract.contract_ref)
      ]),
      catchError((error) => of(fromAdminPanelActions.setApiErrors(panelId, error))),
    )
  )
);

export const deleteContractPeriodEpic = (action$: Observable<any>) => action$.pipe(
  ofType(fromContractPeriodActions.DELETE_CONTRACT_PERIOD.start),
  switchMap(({ panelId, contract, contractPeriodId }) =>
    APIR.delete(`${ROUTE}${contract.contract_ref}${CONTRACT_PERIOD_ROUTE}${contractPeriodId}`).pipe(
      switchMap(() => [
        removeDataSubItem(adminPanelType.contracts, contractPeriodKey, contract.id, contractPeriodId),
        fetchContractPeriods(contract.contract_ref)
      ]),
      catchError((error) => of(fromAdminPanelActions.setApiErrors(panelId, error))),
    )
  )
);

export default [
  createContractPeriodEpic,
  updateContractPeriodEpic,
  deleteContractPeriodEpic
];