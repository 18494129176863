import { store } from "store/store";

import meterStreamServices from "components/ContractInFocus/MeterReadings/Services/MeterStreamServices";
import { MainInFocusVisibilitySettings } from "../../interfaces/contractInFocus.interfaces";
import { IMeterReadingsStream, ISubmitMeterReadingsStream } from 'components/ContractInFocus/MeterReadings/Interfaces/MeterReadings.interface';
import personalHideServices from "components/ContractInFocus/Services/personalHideObject.services";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { APIPrivileges } from "services/Interface/Interface";
import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";
import { RightVisibilityMenuT } from "../../interfaces/personalContractSettings.interfaces";
import { GetRightVisibilityMenu } from "components/Common/Components/RightVisibilityMenu/Interfaces/RightContext.interfaces";


//function getToggleContractReportMeterStreamVisibility(param: string, record: any, contract: ContractInterface) {
function getToggleContractReportMeterStreamVisibility(param: string, record: any, contract: SiteContract) {
    // abstracting this is likely to end up with code smell - still, phrasing input args generically to make it faster to copy and implement
    // in other places
    return () => {
        // we're going to use the selector to grab the record again at 'runtime', because if we pass the record in the closure may make the 
        // data (including the value we want to toggle) stale - unfortunately passing in a selector (e.g. contractMeterStreams) 
        // seems to translate it to a set of records at the time of passing it in - so the only way seems to be interrogating the store directly
        const state = store.getState()
        const contractMeterStreams = state.meterStreams[contract.id]?.data
        const filteredStateRecords = contractMeterStreams ? contractMeterStreams.filter((r: any) => r.id.toString() === record.id.toString()) : []
        const stateRecord = filteredStateRecords.length == 1 ? filteredStateRecords[0] : undefined;
        const toggledValue = !stateRecord[param]
        stateRecord && meterStreamServices.updateMeterStream(contract, record.id,
            { [param]: toggledValue }
        ).catch(
            (reason) => {
                console.log('toggle failed because: ', reason);
                record[param] = !toggledValue;
            }
        )
        //NB it is necessary to manually toggle the value of the 'record' in the 'visibility settings' here... because
        // unlike with the 'default' toggle mechanism, this is a dictionary generated by a closure, not something that is connected to a 'live' selector... only the closure
        // has a reference here..
        record[param] = toggledValue;
    }
}

function togglePermitted(permissions: APIPrivileges | undefined, putMeta: FieldMetaGroup, param: string) {
    return permissions?.PUT && putMeta[param]?.read_only === false; //must check it is actually false, to disambiguate in case it's undefined
}

//HT HOW - here we need functions for meter stream to dynamically update itself and update the relevant values...
// the menu itself will have to be generated rather than declared as it is for static. Its worth using redux
// so the values can be used in various places with control over when to fetch....
//export const getContractReportMeterStreamsRightVisibilityMenu = (contractMeterStreams: any, contract: ContractInterface): any => {
export const getContractReportMeterStreamsRightVisibilityMenu = (contractMeterStreams: any, contract: SiteContract): any => {

    //const selectedMeterStreams = useSelector(contractInFocusMeterStreamsSelector);
    const permissions = contractMeterStreams.permissions
    const putMeta = contractMeterStreams.meta?.PUT
    return ({ visibilitySettings }: GetRightVisibilityMenu) => { // note that this function takes the standard contract viz settings so it can also do something with those..
        const visibilityMap = <any>{};
        contractMeterStreams.data && contractMeterStreams.data.map && contractMeterStreams.data.map((meterStream: IMeterReadingsStream) => {
            visibilityMap[meterStream.title] = {
                graph: {
                    value: meterStream.show_graph_in_monthly_maintenance_report, //just gives the starting value, the toggle function then updates the value on the BE
                    // and also toggles the 'visual' state of the icon button separately (they should stay in synch but are not 'intrinsically' linked)
                    togglePermitted: togglePermitted(permissions, putMeta, 'show_graph_in_monthly_maintenance_report'),
                    toggleFunction: getToggleContractReportMeterStreamVisibility('show_graph_in_monthly_maintenance_report', meterStream, contract)
                },
                table: {
                    value: meterStream.show_table_in_monthly_maintenance_report,
                    togglePermitted: togglePermitted(permissions, putMeta, 'show_table_in_monthly_maintenance_report'),
                    toggleFunction: getToggleContractReportMeterStreamVisibility('show_table_in_monthly_maintenance_report', meterStream, contract)
                }
            }
        })
        visibilityMap["Meter Reading Events"] = {
            // because this doesn't provide a toggleFunction property, it will be treated (by the visibilityControl component) 
            // as a 'static' setting on the relevant settings model, just as it should be...
            table: {
                value: visibilitySettings.show_meter_reading_events_table,
                updateKey: 'show_meter_reading_events_table'
            }
        }
        //console.log('visibility Map: ', visibilityMap);
        return visibilityMap;
    }

};

//function getToggleMeterStreamPersonalVisibility(category: string, record: any, contract: ContractInterface) {
function getToggleMeterStreamPersonalVisibility(category: string, record: any, contract: SiteContract) {

    return () => {
        const state = store.getState();
        const hiddenObjs = state.personalHiddenObjects['meterstream'] || [];
        //note we're iterating meterstreams here, which are represented by object_id on a hidden object so comparison is r.object_id to record.id
        const filteredStateRecords = hiddenObjs ? hiddenObjs.filter((r: any) => r.object_id.toString() === record.id.toString() && r.category === category) : [];
        const stateRecord = filteredStateRecords.length == 1 ? filteredStateRecords[0] : undefined;
        if (!stateRecord) {
            // it musn't be hidden yet...
            personalHideServices.addPersonalHiddenObject('meterstream', {
                category: category,
                object_id: record.id,
                content_type_name: 'meterstream'
            })
        } else {
            personalHideServices.removePersonalHiddenObject('meterstream', stateRecord.id)
        }
    }
}

//export const getMeterStreamsPersonalRightVisibilityMenu = (visibilitySettings: VisibilityContractSettings, contractMeterStreams: any, contract: SiteContract, hiddenGraphObjs: any[], hiddenTableObjs: any[]): RightVisibilityMenuT => {
export const getMeterStreamsPersonalRightVisibilityMenu = (visibilitySettings: MainInFocusVisibilitySettings, contractMeterStreams: any, contract: SiteContract, hiddenGraphObjs: any[], hiddenTableObjs: any[]): RightVisibilityMenuT => {
    //just grab the settings here... no need for put meta and permissions as these are personal settings
    const visibilityMap = <any>{};
    contractMeterStreams.data && contractMeterStreams.data.map && contractMeterStreams.data.map((meterStream: IMeterReadingsStream) => {
        // hidden_for_me is a property added to the relevant model - not a field.  It's more convenient and by and large probably more performant 
        // than querying the BE in this function and interrogating the 'Hide' objects directly
        visibilityMap[meterStream.title] = {
            graph: {
                value: !hiddenGraphObjs.map(x => x.object_id).includes(meterStream.id), //just gives the starting value, the toggle function then updates the value on the BE
                // and also toggles the 'visual' state of the icon button separately (they should stay in synch but are not 'intrinsically' linked).
                togglePermitted: true,
                toggleFunction: getToggleMeterStreamPersonalVisibility('graph', meterStream, contract)
            },
            table: {
                value: !hiddenTableObjs.map(x => x.object_id).includes(meterStream.id),
                togglePermitted: true,
                toggleFunction: getToggleMeterStreamPersonalVisibility('table', meterStream, contract)
            }
        }
    })
    visibilityMap["Meter Reading Events"] = {
        // because this doesn't provide a toggleFunction property, it will be treated (by the visibilityControl component) 
        // as a 'static' setting on the relevant settings model, just as it should be...
        table: {
            value: visibilitySettings.show_meter_reading_events_table,
            updateKey: 'show_meter_reading_events_table'
        }
    }
    //console.log('visibility Map: ', visibilityMap);
    return visibilityMap;
};