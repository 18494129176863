import React from 'react';

import DescriptionIcon from '@material-ui/icons/Description';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import ImageIcon from '@material-ui/icons/Image';

import { mimeTypes } from './fileTypes';
import styles from '../../../../styles/app/common/variables.scss';

interface FileTypeIconProps {
  fileType: string;
}

interface FileSourceIconProps {
  fileSource: string;
}

export const FileTypeIcon = ({
  fileType = ''
}: FileTypeIconProps) => {

  const getPDF = (fileType: string) =>
    mimeTypes.mimePdf.indexOf(fileType) !== -1
      ? <img alt="PDF file type" className="pdf-icon" src="./img/pdf-icon-small2.png" />
      : null;

  const getTable = (fileType: string) =>
    mimeTypes.mimeGrid.indexOf(fileType) !== -1
      ? <TableChartOutlinedIcon style={{ color: styles.success }} />
      : null;

  const getWord = (fileType: string) =>
    mimeTypes.mimeDoc.indexOf(fileType) !== -1
      ? <DescriptionIcon style={{ color: styles.dynamicBlue }} />
      : null;

  const getImage = (fileType: string) =>
    mimeTypes.mimeImage.indexOf(fileType) !== -1
      ? <ImageIcon style={{ color: styles.purple }} />
      : null;

  return (
    <>
      {getPDF(fileType)}
      {getImage(fileType)}
      {getWord(fileType)}
      {getTable(fileType)}
    </>
  )
}

export const RescheduleIcon = (props: any) => (
  <svg
    className='svg-inline--fa'
    viewBox="0 0 866 496"
    fill={props.fill ? props.fill : "black"}
    color={props.fill ? props.fill : "black"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M248 0C111 0 0 111 0 248s111 248 248 248 248-111 248-248S385 0 248 0Zm92.49 313-20 25a16.004 16.004 0 0 1-16.948 5.374A16 16 0 0 1 298 340.5l-67-49.72a40.01 40.01 0 0 1-15-31.23V104a16.002 16.002 0 0 1 16-16h32a16.002 16.002 0 0 1 16 16v144l58 42.5a15.992 15.992 0 0 1 5.908 10.734A16.012 16.012 0 0 1 340.49 313Z"
      fill={props.fill ? props.fill : "black"}
    />
    <path
      d="m607.5 42.9 22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L858 215c9.4 9.4 9.4 24.6 0 33.9L663.6 443.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L728.4 272H441c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L607.9 77.2c-9.8-9.3-10-24.8-.4-34.3Z"
      fill={props.fill ? props.fill : "black"}
    />
  </svg>
)

export const SpendIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 449"
    className='svg-inline--fa'
    fill={props.fill ? props.fill : "currentColor"}
    color={props.fill ? props.fill : "currentColor"}
    {...props}
  >
    <g id="Layer_1" data-name="Layer 1"><path d="M256.24,37.81c-141.38,0-256,93.12-256,208,0,49.56,21.41,95,57,130.74C44.7,426.86,2.94,471.78,2.44,472.31a8,8,0,0,0,5.8,13.5c66.26,0,116-31.75,140.6-51.38a304.66,304.66,0,0,0,107.4,19.38c141.38,0,256-93.12,256-208S397.62,37.81,256.24,37.81Z" fill={props.fill ? props.fill : "currentColor"} transform="translate(0.26 -37.31)" /></g>
    <g id="Layer_2" data-name="Layer 2"><path d="M333.34,359.6V312.28H229.26v-.83q14.34-13.6,19.65-29.72A92.51,92.51,0,0,0,252.53,252h49.4V212.71h-57a127.22,127.22,0,0,1-4-30c-.16-7.41,3.95-21.21,7.92-26.54Q257.09,145,278,144.51q22,.33,33.87,7.31l9.53-45.25q-6.66-3.66-18.52-6.45a130.5,130.5,0,0,0-28.09-2.92q-40.65.43-64.61,22.49t-24.53,60.14a174.54,174.54,0,0,0,3.14,32.88H158.15V252h39a114.18,114.18,0,0,1,.81,16.73q-.81,21.66-14.19,37.49t-30,22.44v30.9Z" fill="white" transform="translate(0.26 -37.31)" /></g>
  </svg>
)

export const AWSIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 640 448"
    className='svg-inline--fa'
    fill={props.fill ? props.fill : "currentColor"}
    color={props.fill ? props.fill : "currentColor"}
    {...props}
  >
    <g id="Layer_1" data-name="Layer 1">
      <path d="M608,32H32A32,32,0,0,0,0,64V448a32,32,0,0,0,32,32H608a32,32,0,0,0,32-32V64A32,32,0,0,0,608,32ZM416,312a8,8,0,0,1-8,8H296a8,8,0,0,1-8-8V296a8,8,0,0,1,8-8H408a8,8,0,0,1,8,8Zm160,0a8,8,0,0,1-8,8H488a8,8,0,0,1-8-8V296a8,8,0,0,1,8-8h80a8,8,0,0,1,8,8Zm0-96a8,8,0,0,1-8,8H296a8,8,0,0,1-8-8V200a8,8,0,0,1,8-8H568a8,8,0,0,1,8,8Z" fill={props.fill ? props.fill : "currentColor"} transform="translate(0 -32)" /></g>
    <g id="Layer_2" data-name="Layer 2"><path d="M215.27,353.3V314.43H129.75v-.69c7.86-7.45,13.22-15.57,16.15-24.42a76.13,76.13,0,0,0,3-24.39h40.59V232.61H142.62a104.27,104.27,0,0,1-3.28-24.69c-.17-8.44,2-15.73,6.52-21.8s12.49-9.26,23.93-9.55q18.09.27,27.83,6l7.83-37.18a61.51,61.51,0,0,0-15.22-5.3,107.19,107.19,0,0,0-23.08-2.4q-33.39.36-53.08,18.48T93.91,205.59a143.88,143.88,0,0,0,2.58,27H71.32v32.32H103.4a93.47,93.47,0,0,1,.66,13.75,49.16,49.16,0,0,1-11.66,30.8q-11,13-24.67,18.43V353.3Z" fill="white" transform="translate(0 -32)" /></g>
  </svg>
)

export const FileSourceIcon = ({
  fileSource = ''
}: FileSourceIconProps) => {

  const getFP = (fileSource: string) =>
    fileSource === "FP"
      ? <img alt="PDF file type" className="pdf-icon" src="./img/focalpoint-favicon.ico" />
      : null;

  const getApprise = (fileSource: string) =>
    fileSource === "AP"
      ? <img alt="PDF file type" className="pdf-icon" src="./img/alphabet-a.svg" />
      : null;

  return (
    <>
      {getFP(fileSource)}
      {getApprise(fileSource)}
    </>
  )
}

export const ArchivedIcon = ({ width, height }: { width?: string, height?: string }) => {
  return <img alt="Archived Icon" className="archived-icon" src="./img/archived.png" width={width} height={height} />
}

export const UnArchivedIcon = ({ width, height }: { width?: string, height?: string }) => {
  return <img alt="UnArchived Icon" className="unarchived-icon" src="./img/unarchived.png" width={width} height={height} />
}

export const NotifyInAdvanceIcon = ({ disabled, width, height }: { disabled: boolean, width?: string, height?: string }) => {
  return (
    disabled ?
      <img alt="Notify In Advance Icon" className='notify-in-advance-icon schedule-control-icon disabled' src="./img/clock_icons/Before.svg" width={width} height={height} /> :
      <img alt="Notify In Advance Icon Disabled" className="archived-icon" src="./img/clock_icons/Before.svg" width={width} height={height} />)
}

export const NotifyDueIcon = ({ disabled, width, height }: { disabled: boolean, width?: string, height?: string }) => {
  return (disabled ? <img alt="Notify When Due Icon" className='notify-due-icon schedule-control-icon disabled' src="./img/clock_icons/Due.svg" width={width} height={height} /> :
    <img alt="Notify When Due Icon Disabled" className='notify-due-icon schedule-control-icon' src="./img/clock_icons/Due.svg" width={width} height={height} />)
}

export const NotifyOverDueIcon = ({ disabled, width, height }: { disabled: boolean, width?: string, height?: string }) => {
  return (disabled ? <img alt="Notify When Overdue Icon" className='notify-overdue-icon schedule-control-icon disabled' src="./img/clock_icons/After.svg" width={width} height={height} /> :
    <img alt="Notify When Overdue Icon Disabled" className='notify-overdue-icon schedule-control-icon' src="./img/clock_icons/After.svg" width={width} height={height} />)
}

export const NotifyNewDocument = ({ disabled, width, height }: { disabled: boolean, width?: string, height?: string }) => {
  return (disabled ? <em className="fas fa-file-import disabled" ></em> :
    <em className="fas fa-file-import" ></em>)
}

