import { ColumnPropGroup } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';


export const statutoryTemplateDocumentColumns: ColumnPropGroup = {
    'issue_number': {
        caption: 'Issue',
        width: '78px',
        alignment: 'start',
    },
    'notes': {
        caption: 'Issue Notes',
        alignment: 'start',
        width: '60%'
    },
    'notify_account_managers': {
        hidingPriority: 0
    },
    'notify_site_managers': {
        hidingPriority: 1
    },
    'notify_business_unit_heads': {
        hidingPriority: 2
    },
    'notify_all_key_roles': {
        hidingPriority: 3
    }

};