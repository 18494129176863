import React, { useState, useEffect, memo, ReactElement, useReducer, useCallback } from 'react';
import { isEqual } from "lodash";

// Own
import { GeneralDocsGridWrapper } from 'components/SiteDocuments/Components/ContractDocumentsViewGrid/ContractDocumentGridStyles';
import { ContractDocumentFilesWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { FieldMetaGroup, PreFlightListInfo } from 'components/Common/Interfaces/Entity.interface';

import DocumentsUploader from "components/Common/Components/DocumentsUploader/DocumentUploader";
import DocumentsGrid from "components/Common/Components/DocumentsGrid/DocumentsGrid";
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { ColumnsConfig } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';
import { simpleFetchSnapshotDocumentPreFlightInfo } from "components/ContractInFocus/Services/snapShot.service";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { FormValues } from "components/Common/Components/DocumentsUploader/DocumentUploader";
import { defaultSnapshotInitialUploadDocState } from "constants/snapshots.constants";

interface ContractDocumentGridProps {
    contract: SiteContract;
    setModalTitle: React.Dispatch<React.SetStateAction<string>>;
    setDocumentTabTitles: React.Dispatch<React.SetStateAction<string[] | undefined>>;
}

const columnsConfig: ColumnsConfig = {
    // 'snapshot_date_formatted': {
    //     'caption': 'Snapshot'
    // },
    'snapshot_date': {
        'caption': 'Snapshot',
        'dataType': 'date',
        'format': 'd MMM yyyy'
    },
    'display_type': {
        'caption': "Document Category",
    },
    'description': {
        'caption': 'Description'
    },
    'updated_at': {
        'caption': 'Last Updated At',
        'dataType': 'date',
        'format': 'd MMM yyyy HH:mm:ss'
    }
}

const fieldsConfig: FieldsFormConfig = {
    'snapshot': {
        label: "Select Snapshot",
        selectFirstAsDefault: false,
        selectLastAsDefault: false,
    },
    'snapshot_document_type': {
        excludeValues: ['monthly_maintenance_report'],
        sideEffect: (values, fieldConfigs, onChangeFormValues) => {
            let changed = false;
            if (values['snapshot_document_type'] == "monthly_maintenance_report_appendix" && values["internal_access_only"] == true) {
                values['internal_access_only'] = false;
                changed = true;
            }
            if (values['snapshot_document_type'] !== "monthly_maintenance_report_appendix" && fieldConfigs['internal_access_only'].disabled == true) {
                fieldConfigs['internal_access_only'].disabled = false;
                changed = true;
            } else if (values['snapshot_document_type'] == "monthly_maintenance_report_appendix" && fieldConfigs['internal_access_only'].disabled !== true) {
                fieldConfigs['internal_access_only'].disabled = true;
                changed = true;
            }
            if (changed) {
                onChangeFormValues(values);
            }
            return changed;
        }
    },
    'internal_access_only': {},
    'description': {
        //     style: {
        //         fontWeight: 400
        //     },
        //     forceRequired: true,
    },
}

const documentTabTitles: string[] = [];

const ContractDocumentGrid = ({
    contract,
    setModalTitle,
    setDocumentTabTitles
}: ContractDocumentGridProps) => {
    setDocumentTabTitles(documentTabTitles);
    const [snapshotDocumentPostMeta, setSnapshotDocumentPostMeta] = useState<FieldMetaGroup>();
    const [preFlightInfo, setPreFlightInfo] = useState<PreFlightListInfo>();
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 0);
    const [fetchFilters, setFetchFilters] = useState(undefined)
    const [listBasePath, setListBasePath] = useState(`contracts/${contract.contract_ref}/snapshot-documents/`)
    const [urlContext, setUrlContext] = useState(`contracts/${contract.contract_ref}/snapshot-documents/`)

    const acceptedFileTypesModifier = useCallback((formValues: FormValues, currentlyAcceptedTypes?: string[]) => {
        const v = formValues['snapshot_document_type']
        if (v == 'monthly_maintenance_report_appendix') {
            return ['application/pdf']
        }
        return currentlyAcceptedTypes;
    }, [])

    useEffect(() => {
        setModalTitle(`Snapshot related documents for contract: ${contract.contract_ref}`)
    }, [contract, setModalTitle]);

    const refresh = useCallback(forceUpdate, [forceUpdate])

    useEffect(() => {
        simpleFetchSnapshotDocumentPreFlightInfo({ contractId: contract.contract_ref }).then((newPreFlightInfo) => {
            if (!isEqual(preFlightInfo, newPreFlightInfo)) {
                setPreFlightInfo(newPreFlightInfo);
            }
            if (!isEqual(preFlightInfo?.meta, newPreFlightInfo.meta)) {
                setSnapshotDocumentPostMeta(newPreFlightInfo?.meta);
            }
        });
    }, [contract.contract_ref, preFlightInfo]);

    //.dx-texteditor.dx-editor-underlined:after
    if (preFlightInfo) {
        return (
            <GeneralDocsGridWrapper>
                <ContractDocumentFilesWrapper>
                    <DocumentsGrid
                        listBasePath={listBasePath}
                        columnsConfig={columnsConfig}
                        fetchFilters={fetchFilters}
                        refreshSignal={mustRefresh}
                        hideSource
                    />
                </ContractDocumentFilesWrapper>

                <DocumentsUploader
                    acceptedFileTypesModifier={acceptedFileTypesModifier}
                    urlContext={urlContext}
                    fieldConfigs={fieldsConfig}
                    preFlightInfo={preFlightInfo}
                    gridId="snapshotDocumentsView"
                    reset={refresh}
                    initialState={defaultSnapshotInitialUploadDocState}
                />
            </GeneralDocsGridWrapper>
        )
    } else {
        return <></>
    }
}

export default memo(ContractDocumentGrid);