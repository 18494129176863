import { State } from "store/interfaces/State";
import { createSelector } from "reselect";
import { createDeepEqualSelector, createShallowEqualSelector } from "helpers/SelectorUtilities/selectorUtilities";

const personalHiddenObjectsState = (state: State) => state.personalHiddenObjects;
export const personalHiddenMeterStreamsState = (state: State) => state.personalHiddenObjects['meterstream'];
export const personalHiddenMeterTables = createDeepEqualSelector(personalHiddenMeterStreamsState, (personalHiddenMeterStreams: any[]) => {
    const a = personalHiddenMeterStreams || [];
    return a.filter((x: any) => x.category == "table")
})
export const personalHiddenMeterGraphs = createDeepEqualSelector(personalHiddenMeterStreamsState, (personalHiddenMeterStreams: any[]) => {
    const a = personalHiddenMeterStreams || [];
    return a.filter((x: any) => x.category == "graph")
})
