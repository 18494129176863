import { createSelector } from 'reselect';
import { dictToArray, existingRecordOnly } from '../../../../services/API/API.helper';
import { State } from 'store/interfaces/State';
import { EntityBranchState } from 'components/Common/Interfaces/Entity.interface';
import { contractSelector } from 'components/AdminPanel/Contracts/Selectors/Contracts.selectors';
import { createDeepEqualSelector, createLogDeepEqualSelector } from "helpers/SelectorUtilities/selectorUtilities";

export const sitesSlice = (state: State) => state.sites;

export const sitesSelector = createSelector(
  sitesSlice, (sites) => ({ data: sites.data, meta: sites.meta.POST })
);

export const allSitesSelector = createSelector(
  sitesSlice, (sites) => ({ data: existingRecordOnly(dictToArray(sites.data)), meta: sites.meta.POST })
);

export const siteByIdSelector = (id: any) => createSelector(
  sitesSlice, (sites) => ({
    data: sites.data[id],
    meta: sites.recordmeta[id]
  })
);

export const siteContractsSelector = (state: State) => state.sites.contracts; // This just lists the contracts that belong to the site ... not necessary perhaps now that we have nested site contracts.

export const contractsBySiteIdSelector = (id: any) => createDeepEqualSelector(
  siteContractsSelector, contractSelector, (sitesContracts, contracts) => (sitesContracts[id] || []).map((contractId: any) => contracts.data[contractId])
);

// export const contractsBySiteIdSelector = (id: any) => createSelector(
//   siteContractsSelector, contractSelector, (sitesContracts, contracts) => (sitesContracts[id] || []).map((contractId: any) => contracts.data[contractId])
// );

export const sitesLookupSelector = createSelector(
  sitesSlice, (sites: EntityBranchState) =>
  ([...dictToArray(sites.lookup)])
);

export const adminListSitesSelector = createSelector(
  sitesSlice, (sites: any) => {
    return {
      data: dictToArray(sites.data),
      dict: sites.data,
      meta: sites.meta.POST,
      recordmeta: sites.recordmeta,
      permissions: sites.permissions
    }
  });
