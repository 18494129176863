import { FieldMetaGroup } from 'components/Common/Interfaces/Entity.interface';
import { ColumnPropGroup } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';
import { FormItem } from 'interfaces/form.interface';

import { adminPanelType } from "../../Models/AdminPanel.model";
import { AdminAction } from "interfaces/form.interface";
import { openPanel } from "components/AdminPanel/Helpers/AdminPanel.helper";

export const portfolioImage = {
    key: 'portfolio_image',
    label: 'Portfolio Image'
}

export const portfolioReportCoverImage = {
    key: 'portfolio_monthly_maintenance_report_cover_image',
    label: 'Report Cover'
}


export const portfolioDatagridColumns = (meta: FieldMetaGroup): ColumnPropGroup => ({
    name: {
        alignment: "left"
    },
    active: { width: 150 }
});


export const getPortfolioFormItems = (meta: FieldMetaGroup): FormItem[] => [
    {
        dataField: 'id',
        visible: false,
    },
    {
        dataField: meta.name.field_name,
        editorOptions: {
            readOnly: meta.name.read_only
        }
    },
    {
        dataField: meta.active.field_name,
        editorOptions: {
            readOnly: meta.active.read_only
        }
    },

];


export const newPortfolio = {
    url: "",
    portfolio_image: {
        thumbnail: null
    },
    name: "Brand New Portfolio",
    contracts: []
};

export const linkPortfolioGridActions = (
    unlinkCB?: (item: any) => void
): AdminAction[] => {
    const openAction = {
        key: "open-portfolio",
        icon: "fas fa-toolbox",
        dataField: "id",
        onClick: (data: any) => openPanel({
            panelType: adminPanelType.portfolios,
            expandRecordId: data['portfolio']
        }),
    };
    return unlinkCB
        ? [
            openAction,
            {
                key: "unlink-portfolio",
                icon: "fas fa-unlink",
                dataField: "id",
                onClick: unlinkCB,
            },
        ]
        : [openAction];
};

export const generalPortfolioSublistColumns = (
    meta: FieldMetaGroup
): ColumnPropGroup => {
    return {
        portfolio_name: {
            width: "60%",
        },
        // category: {
        //     width: "25%",
        //     gridModeInsert: true,
        //     gridModeRead: true,
        //     gridModeEdit: true,
        // }
        ops_data_access: {
            width: "20%",
            gridModeInsert: !meta.ops_data_access.read_only,
            gridModeRead: true,
            gridModeEdit: !meta.ops_data_access.read_only,
        },
        h_a_s_permit_access: {
            width: "20%",
            gridModeInsert: !meta.h_a_s_permit_access.read_only,
            gridModeRead: true,
            gridModeEdit: !meta.h_a_s_permit_access.read_only,
        },
    }
};
