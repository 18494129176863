/* eslint-disable */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from "react-redux";

import { Button } from '@material-ui/core';
import { isEqual } from 'lodash';

import TextField from '@material-ui/core/TextField';
import QuoteItemList from "components/ContractInFocus/Financials/Components/QuoteItemsList";
import { AWDocumentListColumns, AWPendingText } from 'components/ContractInFocus/Financials/Models/AWDocumentsColumns';
import { ConvertClientAddressForDisplay } from "components/Common/Utils/Component.js";
import financialsService from "components/ContractInFocus/Financials/Services/financialServices";
import { commonUpdateGridRow } from "components/ContractInFocus/Services/commonAPI.services";
import { IFinancials, clientAWQuoteResponseI } from "components/ContractInFocus/Financials/interfaces/financials.interface";
import AWDocuments from "components/ContractInFocus/Financials/Containers/FinancialGrid/AWDocuments/Containers/AWDocuments";
import { APIPrivileges } from "services/Interface/Interface";
import * as inFocusSelector from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import HistoryBlock from "components/ContractInFocus/Financials/Components/HistoryBlock";
import { downStreamOfAcceptedStatus } from "components/ContractInFocus/Financials/financials.constants.js";
import { standardDateFormat } from "components/Common/Utils/Dates";
// Styling
import "components/ContractInFocus/Financials/Styles/QuoteDetail.scss";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

interface QuoteItemDetailViewProps {
    contract: SiteContract;
    portfolio?: HydratedPortfolio;
    data: IFinancials;
    rowReference: any;
    hideClientPermissions?: APIPrivileges;
    dataSource: any;
    forceReload: () => void;
    processValues?: (item: IFinancials) => IFinancials;
}

const QuoteDetail: React.FC<QuoteItemDetailViewProps> = ({
    contract,
    portfolio,
    data,
    rowReference,
    hideClientPermissions,
    dataSource,
    forceReload,
    processValues
}) => {
    const [clientResponseValue, setClientResponseValue] = useState<clientAWQuoteResponseI | null>();
    const [changeClientResponsePermissions, setChangeClientResponsePermissions] = useState<APIPrivileges>();
    const [createClientResponsePermissions, setCreateClientResponsePermissions] = useState<APIPrivileges>();
    const [poText, setPOText] = useState<string>("");
    const clientReponseValueRef = useRef<any>();
    const [poUploaded, setPOUploaded] = useState<any>();
    const selectedFrozenFor = useSelector(inFocusSelector.contractInFocusFrozenForSelector);
    const [awData, setAWData] = useState<IFinancials>(data);

    useEffect(() => {
        // THIS IS TRIGGERED WHEN A REF PASSED INTO THIS COMPONENT FROM THE PARENT GRID, CHANGES.
        // IT IS CHANGED ON GRID EVENTS EXPANDING AND COLLAPSING.  THIS IS DONE BECAUSE THE GRID
        // CACHES ROWS, SO THE COMPONENT ISN'T RE-RENDERED ON EXPANDING.
        const curRowState = rowReference.current[awData.id];
        if (curRowState == "expanding" && awData.id) {
            financialsService.getClientResponseForAW(awData.id, portfolio?.id).then(
                (clientResponse: any) => {
                    let r;
                    if (clientResponse.data) {
                        if (clientResponse.data.length) {
                            r = clientResponse.data[0];
                        } else {
                            r = null;
                        }
                        if (!isEqual(r, clientReponseValueRef.current)) {
                            setClientResponseValue(r);
                            clientReponseValueRef.current = r;
                        }
                    } else {
                        console.log('clientResponses does not seem to contain data: ', clientResponse)
                    }

                }
            );
        }

    }, [rowReference.current[awData.id]]);

    const updateParentRow = useCallback(() => {
        if (processValues) {
            financialsService.getAWRecord({ contractRef: contract?.contract_ref, portfolioId: portfolio?.id, awId: awData.id }).then((response_data) => {
                if (response_data.id) {
                    const theseValues = processValues(response_data);
                    commonUpdateGridRow(
                        {
                            dataSource,
                            key: response_data.id,
                            changes: theseValues
                        });
                    setAWData(theseValues);
                }
            })
        }
    }, []);

    useEffect(() => {
        financialsService.permissionsCreateClientResponse(awData.id, setCreateClientResponsePermissions, portfolio?.id);
        awData && clientResponseValue && financialsService.permissionsChangeClientResponse(awData.id, clientResponseValue?.id, setChangeClientResponsePermissions, portfolio?.id);
    }, [awData, clientResponseValue]);

    const handleSubmitResponseToQuote = () => {
        const payload = {
            "quote_declined": 0,
            "reference": poText,
            "aw": awData.id,
        }
        financialsService.submitQuoteResponse(awData.id, payload, portfolio?.id).then(
            (response) => {
                setClientResponseValue(response.data);
                setPOText('');
                updateParentRow();
            }
        );
    }

    const handleClickRelegate = () => {
        awData.relegation_status
            ? financialsService.restoreClientFinancialRecord(
                contract.contract_ref,
                awData.relegation_record_id,
                dataSource,
                forceReload,
            )
            : financialsService.relegateClientFinancialRecord(
                contract.contract_ref,
                awData,
                contract.id,
                dataSource,
                forceReload
            );
    };

    const handleDecline = () => {
        const payload = {
            "quote_declined": true,
            "aw": awData.id,
        }
        financialsService.submitQuoteResponse(awData.id, payload, portfolio?.id).then(
            (response) => {
                setClientResponseValue(response.data);
                setPOText('');
                updateParentRow();
            }
        );
    }

    const clientPOTextIsPending = awData.client_po == AWPendingText;

    const handleDeleteResponseToQuote = () => {
        changeClientResponsePermissions?.DELETE && clientResponseValue?.id.length && financialsService.deleteQuoteResponse(awData.id, clientResponseValue.id, portfolio?.id).then(
            (response) => {
                setClientResponseValue(null);
                setPOText('');
                updateParentRow();
            }
        );
    }

    return (
        <div className="AWDetailsWrapper" id={awData.id}>
            <div className="AWDetails">

                <div className="awBlockContainer">
                    {awData.description && awData.description.length &&
                        <div className="aw-description-block awDetailBlock"><h4 className="title">Full Description:&nbsp;</h4>{awData.description}</div>
                    }
                    <HistoryBlock data={awData} portfolio={portfolio} />
                    <div className="awDetailBlock aw-cost-breakdown-block">
                        <h4 className="title">Cost Break Down:</h4>
                        <QuoteItemList
                            aw_id={awData.id}
                            portfolio_id={portfolio?.id}
                        />
                    </div>
                    {downStreamOfAcceptedStatus.includes(awData.status?.toLowerCase()) ?
                        <>
                            <div className="quoteFixedStatus awDetailBlock">
                                <div className="quoteFixedStatusInnerWrapper">
                                    <span className='quoteFixedStatusText'>Status: {awData.status}</span><br />
                                    {awData.invoice_number && <div className="detail invoice-number"><br /><span>Invoice Number:&nbsp;</span>{awData.invoice_number}</div>}<br />
                                    {awData.invoice_date && <div className="detail invoice-date"><br /><span>Invoice Date:&nbsp;</span>{standardDateFormat(awData.invoice_date)}</div>}
                                </div>
                            </div>

                        </> :
                        clientResponseValue?.quote_declined ?
                            <div className="quoteFixedStatus awDetailBlock">
                                <div className="quoteFixedStatusInnerWrapper">
                                    <span className='quoteFixedStatusText'>Status: Quote Declined</span><br />
                                    {changeClientResponsePermissions?.DELETE && <Button color="primary" type="submit" onClick={handleDeleteResponseToQuote} >Undo client decline</Button>}
                                </div>
                            </div>
                            :
                            <>
                                <div className="awDetailBlock aw-purchase-order-block">
                                    <h4 className="title">{
                                        awData.client_po && !clientPOTextIsPending ? "Purchase Order:" :
                                            (clientResponseValue?.reference || poUploaded == true) ? `Purchase Order '${clientResponseValue?.reference}' Pending Registration...` :
                                                "Submit Purchase Order..."
                                    }
                                    </h4>
                                    <div className="undoPOPendingWrapper">
                                        {(!awData.client_po || clientPOTextIsPending) && clientResponseValue?.reference && changeClientResponsePermissions?.DELETE &&
                                            <Button color="primary" type="submit" onClick={handleDeleteResponseToQuote} >Undo pending Registration</Button>}
                                    </div>
                                    {awData.client_po && awData.client_po.length && !clientPOTextIsPending && < div > <span className="awDetailLabel">Client PO:</span>{awData.client_po}</div>}
                                    {awData.client_po && awData.client_po.length && !clientPOTextIsPending && <div><span className="awDetailLabel">PO Received:</span>{awData.po_date}</div>}
                                    {!awData.client_po && !clientResponseValue &&
                                        <>
                                            <div className="POInputWrapper">
                                                <TextField id="POInput" label="PO Number/Reference" onChange={event => {
                                                    const { value } = event.target;
                                                    setPOText(value);
                                                }}>
                                                </TextField>
                                            </div>
                                            <div className="POActionsWrapper">
                                                {createClientResponsePermissions?.POST && !!poText.length && <Button color="primary" type="submit" onClick={handleSubmitResponseToQuote} >Submit PO</Button>}
                                                {createClientResponsePermissions?.POST && !poText.length && <Button className='declineQuoteButton' type="submit" onClick={handleDecline}>...Or Decline AW</Button>}
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="awDetailBlock aw-invoice-block">
                                    <h4 className="title">JBS Invoice:</h4>
                                    <div><span>Invoice Number:</span>{awData.invoice_number}</div>
                                    <div><span className="awDetailLabel">Invoice Date:</span>{awData.invoice_date}</div>
                                </div>
                            </>

                    }
                    <div className="awDetailBlock aw-client-block">
                        <h4 className="title">Client Details:</h4>
                        <div><span className="awDetailLabel">Name:&nbsp;</span>{awData.client_name}</div>
                        <br />
                        <div><span className="awDetailLabel">Billing Address:</span></div>
                        <ConvertClientAddressForDisplay {
                            ...{
                                origAddressString: data?.quote_default_address
                            }
                        }>
                        </ConvertClientAddressForDisplay>
                        {hideClientPermissions?.POST &&
                            <div className="relegateAdditionalWorksButtonWrapper">
                                <Button className="relegateAdditionalWorksButton" color="primary" onClick={handleClickRelegate}>{awData.relegation_status ? 'restore client aws to report' : 'remove client aws from report'}</Button>
                            </div>
                        }
                    </div>

                    {!selectedFrozenFor && <div className="awDetailBlock aw-documents-block">
                        <h4 className="title">Upload Documents...</h4>
                        <div className="awDocumentsBlock">
                            <AWDocuments columnsList={AWDocumentListColumns} aw={awData} portfolio={portfolio} ></AWDocuments>
                        </div>
                    </div>
                    }

                </div>
            </div>
        </div >
    )
}
export default QuoteDetail;