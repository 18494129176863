import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DataGrid, { Column, Editing, Paging, RequiredRule } from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { Paper } from "@material-ui/core";
import { isEqual } from "lodash";

// Own
import CommonAPIService from "components/ContractInFocus/Services/commonAPI.services";
import { IMeterReadingEvent } from "components/ContractInFocus/MeterReadings/Interfaces/MeterReadings.interface";
import Header from '../../Common/Components/GridHeader/GridHeader';
import { InlineWrapper } from 'components/ContractInFocus/Styles/CommonStyles';
import GridHeader from "../../Common/Components/GridHeader/GridHeader";
import { ColumnProps, DataGridMeta } from '../interfaces/dataGridColumn.interface';
import { getColumnProps, editableTextAreaOnPreparing, getGridProps } from '../../../helpers/DataGrid/DataGridColumn.helper';
import { columnPropsPlaceHolder } from 'components/ContractInFocus/Models/ColumnProps';
import { gridMetaInitialState } from 'components/ContractInFocus/Models/Grid';
import PrintDataGrid from "components/Common/Components/PrintDataGrid/PrintDataGrid";
import { getSubTitle } from "components/ContractInFocus/Components/ContractPrintTitle/ContractPrintTitle";
import { meterReadingEventColumns } from "components/ContractInFocus/MeterReadings/Models/meterReadingEventColumns";
import { saveDateFormat } from "components/Common/Utils/Dates";
import * as selectors from "components/ContractInFocus/Selectors/contractInFocus.selectors";
import { getSubTitleNumber } from "components/ContractInFocus/ContractReport/Helpers/contractReportToc.helper";
import { tocsSelector } from "components/ContractInFocus/Selectors/visibility.selectors";
import { reportSectionStructure, reportSubtitles } from "components/ContractInFocus/Models/contractIndex.model";
import NewPageWrapper from "components/Common/Components/PrintStyles/Print";

const getEndpoint = (contract_ref?: string | number) => `contracts/${contract_ref}/meter-reading-events/?format=json`;

const MeterReadingEvent = () => {
    // this view uses snapshots but it does not filter to contract-period or month - because the meter readings in the same section don't
    const contract = useSelector(selectors.contractInFocusContractDataSelector);
    const selectFrozenFor = useSelector(selectors.contractInFocusFrozenForSelector);
    const [dataSource, setDataSource] = useState<DataSource>();
    const [metadata, setMetadata] = useState<DataGridMeta>(gridMetaInitialState);
    const [, setContentReady] = useState(false);
    const [subTitleIndex, setSubTitleIndex] = useState<string>('');
    const tocs = useSelector(tocsSelector);


    const meterReadingEventsTitle = reportSubtitles.MeterReadingEvents;


    useEffect(() => {
        const thisSubTitleIndex = getSubTitleNumber(
            meterReadingEventsTitle,
            tocs.flatTocsLookup,
        )
        thisSubTitleIndex && setSubTitleIndex(thisSubTitleIndex);
    }, [tocs, meterReadingEventsTitle])

    useEffect(() => {
        if (contract) {
            const custom = new CustomStore({
                key: "id",
                load: () => CommonAPIService.getAll<IMeterReadingEvent>(
                    getEndpoint,
                    setMetadata,
                    contract.contract_ref
                ),
                insert: values => CommonAPIService.create<IMeterReadingEvent>(
                    {
                        getEndpoint,
                        contract,
                        values
                    }
                ),
                // @ts-ignore
                remove: key => CommonAPIService.del<IMeterReadingEvent>(getEndpoint, contract.contract_ref, key),
                update: (id, values) => CommonAPIService.update<IMeterReadingEvent>(getEndpoint, contract.contract_ref, id, values)
            });

            setDataSource(
                new DataSource({
                    store: custom
                })
            );
        }
    }, [contract]);

    const handleRowInserting = (values: any): void => {
        values.data.start_date = saveDateFormat(values.data.start_date);
        values.data.end_date = saveDateFormat(values.data.end_date);
    };

    const getColumnPropsExt = (field: string): ColumnProps => {
        return metadata.loaded ? getColumnProps(field, metadata.activeMeta) : columnPropsPlaceHolder;
    }

    const handleEditingStart = () => {
        const newMetaData = { ...metadata, activeMeta: metadata.PUTMeta }
        if (!isEqual(metadata, newMetaData)) {
            setMetadata(newMetaData);
        }
    }

    const handleRowUpdated = () => {
        if (metadata) {
            setMetadata({ ...metadata, activeMeta: metadata.POSTMeta });
        }
    }

    return (
        <InlineWrapper pageBreakAfter={true}>
            <Header
                title={`${subTitleIndex} ${meterReadingEventsTitle}`}
                className="no-screen"
            />
            {/* <NewPageWrapper breakAfter /> */}
            <Paper elevation={3}>
                <GridHeader
                    title={`Meter Reading Events`}
                    subTitle={getSubTitle(metadata)}
                    className="no-print"
                //subTitle={`Your Meter Reading Events for this contract ${selectFrozenFor}`}
                />
                {dataSource && (
                    <DataGrid
                        className='no-print'
                        dataSource={dataSource}
                        onRowInserting={handleRowInserting}
                        onEditingStart={handleEditingStart}
                        onRowUpdated={handleRowUpdated}
                        {...getGridProps(metadata.activeMeta)}
                        onEditorPreparing={editableTextAreaOnPreparing(metadata.activeMeta)}
                        onContentReady={() => setContentReady(true)}
                    >
                        <Paging enabled={false} />
                        <Editing
                            mode="cell"
                            allowUpdating={metadata.privileges.PUT}
                            allowDeleting={metadata.privileges.DELETE}
                            allowAdding={metadata.privileges.POST}
                        />
                        <Column caption="System">
                            <Column {...getColumnPropsExt('water')} width={80}>
                            </Column>
                            <Column {...getColumnPropsExt('gas')} width={80}>
                            </Column>
                            <Column {...getColumnPropsExt('electric')} width={80}>
                            </Column>
                        </Column>
                        <Column {...getColumnPropsExt('start_date')} width={115}>
                            <RequiredRule />
                        </Column>
                        <Column {...getColumnPropsExt('end_date')} width={115}>
                            <RequiredRule />
                        </Column>
                        <Column {...getColumnPropsExt('description')}>
                            <RequiredRule />
                        </Column>
                    </DataGrid>
                )}
                <PrintDataGrid
                    meta={metadata.activeMeta}
                    visibleColumns={meterReadingEventColumns}
                    records={dataSource?.items()}
                />
            </Paper>
        </InlineWrapper>
    )
}

export default MeterReadingEvent;
