export const styledShadow = `
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
`;

export const contractSubHeaderSpacer = `
height: 5rem;
padding-top: 1rem;
width:100vw;
margin-top: 3.5rem;
@media print {
  display: none;
}
`;

export const hasSubHeaderSpacer = `
padding-top: 1rem;
width:100vw;
margin-top: 3.5rem;
margin-bottom: 2rem;
@media print {
  display: none;
}
`;
