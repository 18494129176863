import * as contractActions from "components/ContractInFocus/Actions/contractInFocus.actions";

interface viewerStateI {
    open: boolean;
}

export type ContractDocsViewerState = {
    [id: string]: viewerStateI;
};

const initialState = {};

// This could have been managed as a single boolean right now, rather than by contract, as right now it's a global modal - but might be nice at some point
// to keep track of document viewer state per contract.

const contractDocsViewerReducer = (state: ContractDocsViewerState = initialState, { type, payload }: { type: string, payload: any }) => {
    switch (type) {

        case contractActions.SET_CONTRACT_DOCS_DIALOGUE_OPEN.reducer: {
            return {
                ...state,
                [payload.contractId]: {
                    open: true
                }
            }
        }
        case contractActions.SET_CONTRACT_DOCS_DIALOGUE_CLOSE.reducer: {
            return {
                ...state,
                [payload.contractId]: {
                    open: false
                }
            }
        }
        case contractActions.TOGGLE_CONTRACT_DOCS_DIALOGUE_OPEN.reducer: {
            return {
                ...state,
                [payload.contractId]: {
                    open: !state[payload.contractId].open
                }
            }
        }

        default:
            return state;
    }
};

export default contractDocsViewerReducer;
