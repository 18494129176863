import React from "react";
import PropTypes from "prop-types";

// Own
import visitConstants from "components/Schedulers/Models/VisitsConstants.js";
import { store } from "store/store";
import { NotesPreviewer } from "components/Schedulers/Scheduler";
import { CellWrapper, FlagsWrapper } from "./CellStyles";
import Completed from "../Flags/Completed";
import Cancelled from "../Flags/Cancelled";
import Scheduled from "../Flags/Scheduled";
import Rescheduled from "../Flags/Rescheduled";
import {
  reactiveColor,
  scheduledColor
} from "../../../../../styles/app/common/variables.scss";
import { grey } from "@material-ui/core/colors";
import { capitalize, elipse } from "helpers/String/String.helper";
const {
  plannedPreventedMaintainance,
  reactiveMaintainance
} = visitConstants.mainCategory;

const warnIfItemsWithout = (visits, attributes, msg) => {
  let count = 0;
  attributes.map(attrName => {
    const filtered = visits.filter(x => !x[attrName]).length; //remember 0 is falsey, so if x[attrName] === 0 then !x[attrName] will be true, but if you're relying on this it must be a integer, not a string
    if (filtered) {
      count = count + 1;
    }
  });
  if (count === attributes.length) {
    // i.e. this only returns true or the supplied msg if all the attributes result in some visits left in the filtered array
    return msg || true;
  }
  //return count === attributes.length;
};

const getCheckNotes = items => {
  if (!items) {
    return "";
  }
  let theseStrings = [];
  items.map(x => {
    if (x.notes?.length) {
      theseStrings.push(
        `${capitalize(x.check_type)} check, ${x.status}: ${elipse({
          s: x.notes,
          mode: "middle",
          threshold: 500,
          blockSize: 200
        })}`
      );
    }
  });
  return theseStrings.join("\r\n");
};

const getVisitNotes = items => {
  if (!items) {
    return "";
  }
  let theseStrings = [];
  items.map(x => {
    if (x.notes?.length) {
      theseStrings.push(
        `${capitalize(x.service_type)} visit, ${x.status}: ${elipse({
          s: x.notes,
          mode: "middle",
          threshold: 500,
          blockSize: 200
        })}`
      );
    }
  });
  return theseStrings.join("\r\n");
};

// const warnIfVisitsScheduledInThePast = (visits, now) => {
//   const filtered = visits.filter(v => moment(v.scheduled_for) < now);
//   return !!filtered.length;
// };

const warnIfPastDue = objs => {
  const filtered = objs.filter(o => o.past_due === true);
  if (filtered.length) {
    return "One or more scheduled visits, now in the past, has not been marked as completed";
  }
};

const warnIfX = (objs, x, msg) => {
  const filtered = objs.filter(o => o[x] === true);
  if (filtered.length) {
    return msg || true;
  }
};

const warnIfVisitsMissingPTW = (objs, today, addPTWWarningsFrom) => {
  const missingPTWMsg = "A visit does not have an issued permit to work";
  let filtered = objs.filter(o => {
    const sF = new Date(o.scheduled_for);
    return sF <= today && sF >= addPTWWarningsFrom;
  });
  const missing = warnIfX(filtered, "missing_ptw", missingPTWMsg);
  return missing;
};

const warnIfUnfinishedPTW = (objs, today) => {
  const unfinishedPTWMsg =
    "A permit to work associated with a visit has expired, but the permit has not been completed";
  return warnIfX(objs, "unfinished_ptw", unfinishedPTWMsg);
};

const warnIfActivePTW = (objs, today) => {
  const msg = "A permit to work associated with a visit is currently active";
  return warnIfX(objs, "active_ptw", msg);
};

export const SpecialistVisitDataCellRender = cellData => {
  // cannot useSelector here as CellRender is called by dxDataGrid and it causes invalid hook errors
  const thisAddPTWWarningsFrom =
    store.getState().common?.projectConfig?.add_ptw_warnings_from ||
    "2024-04-01";
  const overRideAddPTWWarningsFrom =
    cellData.data?.contract__override_add_ptw_warnings_from;
  const addPTWWarningsFrom = overRideAddPTWWarningsFrom
    ? new Date(overRideAddPTWWarningsFrom)
    : new Date(thisAddPTWWarningsFrom);
  const today = new Date();
  const cellValue = cellData.cellData
    ? cellData.cellData.value
    : cellData.value;

  if (!cellValue) {
    return <></>;
  }

  const nonClickable = cellData.cellData?.nonClickable;
  const thisNotesString = getVisitNotes(cellValue.visits);

  const getVisitsStats = ({
    visitStatus,
    serviceType,
    skipVisitStatuses,
    booked,
    warnFunction
  }) => {
    let warn = undefined;
    if (!cellValue || !cellValue.visits) {
      return null;
    }
    let visits = cellValue.visits;
    if (visitStatus) {
      visits = visits.filter(visit => visit && visit.status === visitStatus);
    }
    if (serviceType) {
      visits = visits.filter(
        visit => visit && visit.service_type === serviceType
      );
    }
    if (skipVisitStatuses) {
      visits = visits.filter(
        visit => visit && !skipVisitStatuses.includes(visit.status)
      );
    }
    if (booked === true || booked === false) {
      // e.g. don't filter if booked is undefined
      visits = visits.filter(visit => visit.booked === booked);
    }
    // if (visits.length == 1) {
    //   return null;
    // }
    if (warnFunction) {
      warn = warnFunction(visits);
    }
    return {
      count: visits.length,
      warn: warn
    };
  };

  const getCountForStatus = visitStatus => {
    if (!cellValue || !cellValue.visits) {
      return 0;
    }
    const visits = cellValue.visits.filter(
      visit => visit && visit.status === visitStatus
    );
    return visits.length;
  };

  // This code groups specialist visit by visit type
  // const infoScheduled = getCountForStatus(scheduled);
  // const infoRescheduled = getCountForStatus(rescheduled);
  // const infoCancelled = getCountForStatus(cancelled);

  // PPM

  const infoScheduledPPM = getVisitsStats({
    visitStatus: "scheduled",
    serviceType: plannedPreventedMaintainance,
    warnFunction: visits => {
      const warnings = [
        warnIfPastDue(visits),
        warnIfVisitsMissingPTW(visits, today, addPTWWarningsFrom),
        warnIfUnfinishedPTW(visits, today),
        warnIfActivePTW(visits, today)
      ].filter(x => x);
      if (warnings.length) {
        return warnings.join("\r\n");
      }
    }
  });

  const infoRescheduledPPM = getVisitsStats({
    visitStatus: "rescheduled",
    serviceType: plannedPreventedMaintainance
  });
  const infoCancelledPPM = getVisitsStats({
    visitStatus: "cancelled",
    serviceType: plannedPreventedMaintainance
  });

  const infoBookedScheduledPPM = getVisitsStats({
    visitStatus: "scheduled",
    serviceType: plannedPreventedMaintainance,
    booked: true
  });

  // REACTIVE
  const infoScheduledReactive = getVisitsStats({
    visitStatus: "scheduled",
    serviceType: reactiveMaintainance
  });
  const infoRescheduledReactive = getVisitsStats({
    visitStatus: "rescheduled",
    serviceType: reactiveMaintainance
    // warnFunction: visits => {
    //   const now = moment();
    //   return warnIfVisitsScheduledInThePast(visits, now);
    // }
  });

  const infoCancelledReactive = getVisitsStats({
    visitStatus: "cancelled",
    serviceType: reactiveMaintainance
  });

  const completedWarnFunction = visits => {
    const reportWarningMsg =
      "Neither a service report nor a certificate has been made available";
    const reportWarnings = warnIfItemsWithout(
      visits,
      ["service_reports_count", "certificates_count"],
      reportWarningMsg
    );
    const warnings = [
      reportWarnings,
      warnIfVisitsMissingPTW(visits, today, addPTWWarningsFrom),
      warnIfUnfinishedPTW(visits, today),
      warnIfActivePTW(visits, today)
    ].filter(x => x);
    if (warnings.length) {
      return warnings.join("\r\n");
    }
  };

  const infoPPMCompleted = getVisitsStats({
    visitStatus: "completed",
    serviceType: plannedPreventedMaintainance,
    warnFunction: completedWarnFunction
  });

  // const completedWithoutServiceReport = getCountForServiceReport(
  //   completed,
  // );

  const infoReactiveMCompleted = getVisitsStats({
    visitStatus: "completed",
    serviceType: reactiveMaintainance,
    warnFunction: completedWarnFunction
  });

  // ? necessary to allow for temporarily undefined while changing whether in contract/out of contract shown
  const countPPMCompleted = infoPPMCompleted?.count;
  const warnPPMCompleted = infoPPMCompleted?.warn;
  const warnPPMScheduled = infoScheduledPPM?.warn;
  const warnReactiveScheduled = infoScheduledReactive?.warn;
  const countReactiveMCompleted = infoReactiveMCompleted?.count;
  const warnReactiveMCompleted = infoReactiveMCompleted?.warn;
  const countCancelledReactive = infoCancelledReactive?.count;
  const countScheduledReactive = infoScheduledReactive?.count;
  const countRescheduledReactive = infoRescheduledReactive?.count;
  const countCancelledPPM = infoCancelledPPM?.count;
  const countBookedScheduledPPM = infoBookedScheduledPPM?.count;
  const countScheduledPPM = infoScheduledPPM?.count;
  const countRescheduledPPM = infoRescheduledPPM?.count;

  const displayCount = count => (count > 1 ? count : null);

  const isCellPopulatedWithFlag = () =>
    countPPMCompleted ||
    countReactiveMCompleted ||
    countCancelledReactive ||
    countScheduledReactive ||
    countRescheduledReactive ||
    countCancelledPPM ||
    countBookedScheduledPPM ||
    countScheduledPPM ||
    countRescheduledPPM ||
    warnPPMScheduled ||
    warnReactiveScheduled;

  return (
    <CellWrapper>
      {isCellPopulatedWithFlag() ? (
        <FlagsWrapper
          clickable={!nonClickable}
          className="flags-wrapper"
          style={{ cursor: nonClickable ? "arrow" : "pointer" }}
        >
          {countPPMCompleted ? (
            <Completed
              count={displayCount(countPPMCompleted)}
              color={scheduledColor}
              warn={warnPPMCompleted}
            />
          ) : null}
          {countReactiveMCompleted ? (
            <Completed
              count={displayCount(countReactiveMCompleted)}
              color={reactiveColor}
              warn={warnReactiveMCompleted}
            />
          ) : null}

          {/* Flags based on Type */}
          {/* {countCancelled ? <Cancelled count={countCancelled} /> : null}
          {countScheduled ? <Scheduled count={countScheduled} /> : null}
          {countRescheduled ? <Rescheduled count={countRescheduled} /> : null} */}

          {countCancelledPPM ? (
            <Cancelled
              count={displayCount(countCancelledPPM)}
              color={scheduledColor}
            />
          ) : null}
          {countBookedScheduledPPM ? (
            <Scheduled
              count={displayCount(countBookedScheduledPPM)}
              color={scheduledColor}
              warn={warnPPMScheduled}
            />
          ) : null}
          {countScheduledPPM > countBookedScheduledPPM ? (
            <Scheduled
              count={displayCount(countScheduledPPM - countBookedScheduledPPM)}
              color={grey["400"]}
              warn={warnPPMScheduled}
            />
          ) : null}
          {countRescheduledPPM ? (
            <Rescheduled
              count={displayCount(countRescheduledPPM)}
              color={scheduledColor}
            />
          ) : null}

          {countCancelledReactive ? (
            <Cancelled
              count={displayCount(countCancelledReactive)}
              color={reactiveColor}
            />
          ) : null}
          {countScheduledReactive ? (
            <Scheduled
              count={displayCount(countScheduledReactive)}
              color={reactiveColor}
              warn={warnReactiveScheduled}
            />
          ) : null}
          {countRescheduledReactive ? (
            <Rescheduled
              count={displayCount(countRescheduledReactive)}
              color={reactiveColor}
            />
          ) : null}
        </FlagsWrapper>
      ) : (
        <FlagsWrapper
          clickable={!nonClickable}
          className="flags-wrapper"
          style={{ cursor: nonClickable ? "arrow" : "pointer" }}
        />
      )}
      {!!thisNotesString?.length && (
        <NotesPreviewer notes={thisNotesString} onclick={undefined} />
      )}
    </CellWrapper>
  );
};

SpecialistVisitDataCellRender.propTypes = {
  cellData: PropTypes.object.isRequired
};

//////////////////////////////////////////////////////

export const HASCheckDataCellRender = cellData => {
  const cellValue = cellData.cellData
    ? cellData.cellData.value
    : cellData.value;

  if (!cellValue) {
    return <></>;
  }

  const nonClickable = cellData.cellData?.nonClickable;
  const cellItems = cellValue?.checks;

  const getHASCheckStatus = ({ objStatus, objType, warnFunction }) => {
    let warn = false;

    if (!cellValue || !cellItems) {
      return null;
    }
    let objs = cellItems.filter(
      obj => obj && obj.status === objStatus && obj.check_type === objType
    );
    if (warnFunction) {
      warn = warnFunction(objs);
    }
    return {
      count: objs.length,
      warn: warn
    };
  };

  const getCountForStatus = objStatus => {
    if (!cellValue || !cellItems) {
      return 0;
    }
    const objs = cellItems.filter(obj => obj && obj.status === objStatus);
    return objs.length;
  };

  // This code groups specialist visit by visit type
  // const infoScheduled = getCountForStatus(scheduled);
  // const infoRescheduled = getCountForStatus(rescheduled);
  // const infoCancelled = getCountForStatus(cancelled);

  const routineExceptionChecks = objs => {
    const msgs = [];
    const past_due = warnIfX(objs, "past_due");
    const completed_early = warnIfX(objs, "completed_early");
    const completed_late = warnIfX(objs, "completed_late");
    const lacking_required_docs = warnIfX(objs, "lacking_required_docs");
    const missing_ptw = warnIfX(objs, "missing_ptw");
    const has_unfinished_ptw = warnIfX(objs, "unfinished_ptw");
    if (past_due) {
      msgs.push("One or more scheduled checks is past due");
    }
    if (completed_early) {
      msgs.push("One or more checks was completed too early.");
    }
    if (completed_late) {
      msgs.push("One or more scheduled checks was completed late");
    }
    if (lacking_required_docs) {
      msgs.push("One or more completed checks is lacking required documents");
    }
    if (missing_ptw) {
      msgs.push(
        "This visit does not yet have an issued permit to work associated with it"
      );
    }
    if (has_unfinished_ptw) {
      msgs.push(
        "A permit to work associated with this visit has expired, but the permit has not been cancelled"
      );
    }
    if (msgs.length) {
      return msgs.join("\r\n");
    }
  };

  const adhocExceptionChecks = objs => {
    const msgs = [];
    const lacking_required_docs = warnIfX(objs, "lacking_required_docs");
    const missing_ptw = warnIfX(objs, "missing_ptw");
    const has_unfinished_ptw = warnIfX(objs, "unfinished_ptw");
    if (lacking_required_docs) {
      msgs.push("One or more completed checks is lacking required documents");
    }
    if (missing_ptw) {
      msgs.push(
        "This visit does not yet have an issued permit to work associated with it"
      );
    }
    if (has_unfinished_ptw) {
      msgs.push(
        "A permit to work associated with this visit has expired, but the permit has not been closed or cancelled"
      );
    }
    if (msgs.length) {
      return msgs.join("\r\n");
    }
  };

  // ROUTINE
  const routineCheckScheduled = getHASCheckStatus({
    objStatus: "scheduled",
    objType: "routine",
    warnFunction: routineExceptionChecks
  });

  // ADHOC
  const infoAdhocScheduled = getHASCheckStatus({
    objStatus: "scheduled",
    objType: "adhoc"
  });

  const routineCheckCompleted = getHASCheckStatus({
    objStatus: "completed",
    objType: "routine",
    warnFunction: routineExceptionChecks
  });

  const infoAdhocCompleted = getHASCheckStatus({
    objStatus: "completed",
    objType: "adhoc",
    warnFunction: adhocExceptionChecks
  });

  // ? necessary to allow for temporarily undefined while changing whether in contract/out of contract shown
  const countRoutineCompleted = routineCheckCompleted?.count;
  const warnRoutineCompleted = routineCheckCompleted?.warn;
  const countRoutineScheduled = routineCheckScheduled?.count;
  const warnRoutineScheduled = routineCheckScheduled?.warn;
  const warnAdhocScheduled = infoAdhocScheduled?.warn;
  const countAdhocCompleted = infoAdhocCompleted?.count;
  const warnAdhocCompleted = infoAdhocCompleted?.warn;
  const countAdhocScheduled = infoAdhocScheduled?.count;

  const displayCount = count => (count > 1 ? count : null);
  const thisNotesString = getCheckNotes(cellItems);

  const isCellPopulatedWithFlag = () =>
    countRoutineCompleted ||
    countAdhocCompleted ||
    countAdhocScheduled ||
    countRoutineScheduled ||
    warnRoutineScheduled ||
    warnAdhocScheduled;

  return (
    <CellWrapper>
      {isCellPopulatedWithFlag() ? (
        <FlagsWrapper
          clickable={!nonClickable}
          className="flags-wrapper"
          style={{ cursor: nonClickable ? "arrow" : "pointer" }}
        >
          {countRoutineCompleted ? (
            <Completed
              count={displayCount(countRoutineCompleted)}
              color={scheduledColor}
              warn={warnRoutineCompleted}
            />
          ) : null}
          {countAdhocCompleted ? (
            <Completed
              count={displayCount(countAdhocCompleted)}
              color={reactiveColor}
              warn={warnAdhocCompleted}
            />
          ) : null}

          {/* Flags based on Type */}
          {countRoutineScheduled ? (
            <Scheduled
              count={displayCount(countRoutineScheduled)}
              color={scheduledColor}
              warn={warnRoutineScheduled}
            />
          ) : null}
          {countAdhocScheduled ? (
            <Scheduled
              count={displayCount(countAdhocScheduled)}
              color={reactiveColor}
              warn={warnAdhocScheduled}
            />
          ) : null}
        </FlagsWrapper>
      ) : (
        <FlagsWrapper
          clickable={!nonClickable}
          className="flags-wrapper"
          style={{ cursor: nonClickable ? "arrow" : "pointer" }}
        />
      )}
      {thisNotesString?.length && (
        <NotesPreviewer notes={thisNotesString} onclick={undefined} />
      )}
    </CellWrapper>
  );
};

HASCheckDataCellRender.propTypes = {
  cellData: PropTypes.object.isRequired
};
