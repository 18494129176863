import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
export const Wrapper = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;

  @media print {
    .MuiPaper-elevation3 {
      box-shadow: none !important;
    }
  }
`;
// height: 100%;

export const ChildrenWrapper = styled.div`
  background: ${props =>
    `linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url("${props.backgroundImageUrl}");`}
  /* Create the parallax scrolling effect */
  background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
  background-attachment: fixed;
`;

export const useNotificationBarStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  }
}));
