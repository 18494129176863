import React from "react";

import { Organisation } from "components/AdminPanel/Organisation/Interfaces/Organisation";
import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";
import { filterColumnProps } from "components/ContractInFocus/Interfaces/DataGridColumn.interface";
import { FormItem } from "interfaces/form.interface";
import { columnVisibility, getRelationChoiceId, createdAtColumn } from "helpers/DataGrid/DataGridColumn.helper";
import moment from "moment";

const nameColumn = (width?: number | string, visible = true) => ({
  name: {
    width,
    alignment: "left",
    ...columnVisibility(visible),
  },
});

const onStopColumn = (width?: number | string, visible = true) => ({
  on_stop: {
    width,
    alignment: "left",
    ...columnVisibility(visible),
  },
});

const relationColumn = (width?: number | string, visible = true) => ({
  relation: {
    width,
    alignment: "left",
    ...columnVisibility(visible),
  },
});

const supplierRefColumn = (width?: number | string, visible = true) => ({
  supplier_ref: {
    width,
    alignment: "left",
    ...columnVisibility(visible),
  },
});

export function getOrganisationFilterExpForGrid(meta: any, headerFilterPage: string) {
  const thisHeaderFilterPage = headerFilterPage === "default" ? "subcontractor" : headerFilterPage;
  const relationRecordId = getRelationChoiceId(meta.relation.choices, thisHeaderFilterPage);
  const filterExp = (function getFilters() {
    switch (thisHeaderFilterPage) {
      case "all":
        return null
      case "recent":
        //@ts-ignore
        return ["created_at", ">", moment().add(-5, 'day')]
      default:
        //@ts-ignore
        return ["relation", "=", relationRecordId]
    }
  })();
  return filterExp
}

export const organisationFilteredDatagridColumns = (
  props: filterColumnProps
) => {
  return {
    recent: {
      caption: "Recent",
      onClick: () => props.setHeaderFilterPage("recent"),
      columns: (meta: FieldMetaGroup): any => ({
        ...nameColumn("75%"),
        ...supplierRefColumn(undefined, false),
        ...relationColumn("12%"),
        ...onStopColumn(undefined, false),
        //...sectorColumn("25%"),
        //...servicesColumn(),
        ...createdAtColumn()
      }),
    },
    client: {
      caption: "Clients",
      onClick: () => props.setHeaderFilterPage("client"),
      columns: (meta: FieldMetaGroup): any => ({
        ...nameColumn("80%"),
        ...supplierRefColumn(undefined, false),
        ...relationColumn(undefined, false),
        ...onStopColumn(undefined, false),
        //...sectorColumn(),
        //...servicesColumn(undefined, false),
        ...createdAtColumn("20%")
      }),
    },
    default: {
      caption: "Sub-Contractors",
      onClick: () => props.setHeaderFilterPage("default"),
      columns: (meta: FieldMetaGroup): any => ({
        ...nameColumn("60%"),
        ...supplierRefColumn("15%"),
        ...onStopColumn("5%"),
        ...relationColumn(undefined, false),
        //...sectorColumn(undefined, false),
        //...servicesColumn(),
        ...createdAtColumn("20%")
      }),
    },
    // all: {
    //   caption: "All",
    //   onClick: () => props.setHeaderFilterPage("all"),
    //   columns: (meta: FieldMetaGroup): any => ({
    //     ...nameColumn(300),
    //     ...relationColumn(200),
    //     ...sectorColumn(350),
    //     ...servicesColumn(),
    //     ...createdAtColumn()
    //   }),
    // },
  };
};

export const organisationImage = {
  key: "logo",
  caption: "Logo",
};

export const getOrganisationFormItems = (
  meta: FieldMetaGroup,
  organisation?: Organisation
): FormItem[] => {
  let fields = [];

  if (organisation?.relation_choice_value === "subcontractor") {
    fields = [
      {
        dataField: "id",
        visible: false,
      },
      {
        dataField: "supplier_ref",
        editorOptions: {
          readOnly: meta?.supplier_ref.read_only,
        },
      },
      {
        dataField: "name",
        editorOptions: {
          readOnly: meta?.name.read_only,
        },
      },
      {
        dataField: "relation",
        editorType: "dxSelectBox",
        editorOptions: {
          items: meta?.relation?.choices || [],
          displayExpr: "display_name",
          valueExpr: "value",
          readOnly: meta.relation.read_only,
        },
      },
      {
        dataField: "address",
        editorOptions: {
          readOnly: meta?.address.read_only,
          height: 150,
        },
        editorType: "dxTextArea",
      },
      {
        dataField: "postcode",
        editorOptions: {
          readOnly: meta?.postcode.read_only,
        },
      },
      {
        dataField: "telephone",
        editorOptions: {
          readOnly: meta?.telephone.read_only,
        },
      },
      {
        dataField: "on_stop",
        editorOptions: {
          readOnly: meta?.on_stop.read_only,
        },
      },
    ]
  } else if (organisation?.relation_choice_value === "client") {
    fields = [
      {
        dataField: "id",
        visible: false,
      },
      {
        dataField: "name",
        editorOptions: {
          readOnly: meta?.name.read_only,
        },
      },
    ];
  } else {
    fields = [
      {
        dataField: "id",
        visible: false,
      },
      {
        dataField: "name",
        editorOptions: {
          readOnly: meta?.name.read_only,
        },
      },
      {
        dataField: "relation",
        editorType: "dxSelectBox",
        editorOptions: {
          items: meta?.relation?.choices || [],
          displayExpr: "display_name",
          valueExpr: "value",
          readOnly: meta?.relation?.read_only,
        },
      },
    ];

  }

  return fields
};

export const newOrganisation = {
  name: "",
  relation: "",
  url: "",
  logo: {
    thumbnail: null,
  },
  industry: null,
};
