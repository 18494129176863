import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import FastForwardIcon from '@material-ui/icons/FastForward';
import FastRewindIcon from '@material-ui/icons/FastRewind';

import { IconButton } from '@material-ui/core';

// Own
import ConfirmationModal from 'components/Common/Components/Modal/Modal';
import { defaultDocViewerTitle } from './DocumentViewer.model';

// Styles
import { DocumentWrapperStyle } from './DocumentWrapperStyles';
import './Document.scss';

export interface DocumentViewerModalProps {
  id?: string;
  isOpen: boolean;
  title?: string;
  body?: string;
  okCaption?: string;
  color?: string;
  cancelCaption?: string;
  error?: string;
  fileBase64?: string;
  url?: string;
  children?: any;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClosed?: () => void;
  pdfWrapperClass?: string;
}

export interface DocumentViewerProps {
  fileBase64?: string;
  children?: any;
  pdfWrapperClass?: string;
  url?: string;
  height?: number;
  retryInterval?: number; //retry interval in seconds - if undefined it will not retry
}

export const DocumentViewer = (
  {
    children,
    fileBase64,
    pdfWrapperClass,
    url,
    height,
    retryInterval
  }: DocumentViewerProps) => {

  const [documentPages, setDocumentPages] = useState({ numPages: -1, pageNumber: 1 });

  const [docKey, setDocKey] = useState(0);

  const [retry, setRetry] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setDocumentPages({ pageNumber: 1, numPages });
  }

  useEffect(() => {
    if (retryInterval && retry) {
      const timer = setTimeout(() => {
        setDocKey(docKey + 1);
      }, (retryInterval * 1000))
      return () => clearTimeout(timer);
    }
  }, [retry, retryInterval, docKey]);

  const onDocumentLoadError = () => {
    setRetry(true);
  }

  const nextPage = (): void => {
    setDocumentPages({ ...documentPages, pageNumber: documentPages.pageNumber + 1 });
  }

  const firstPage = (): void => {
    setDocumentPages({ ...documentPages, pageNumber: 1 });
  }

  const lastPage = (): void => {
    setDocumentPages({ ...documentPages, pageNumber: documentPages.numPages });
  }

  const prevPage = (): void => {
    setDocumentPages({ ...documentPages, pageNumber: documentPages.pageNumber - 1 });
  }

  return <DocumentWrapperStyle data-testid="document-viewer">
    {children}
    {!fileBase64 && !url ? null :
      <Document
        file={url || `data:application/pdf;base64,${fileBase64}`}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentLoadError}
        key={docKey}
      >
        <Page pageNumber={documentPages.pageNumber} className={`ja-document-viewer-page ${pdfWrapperClass ? pdfWrapperClass : ''}`} height={height || 595} />
      </Document>
    }

    <div>
      <IconButton disabled={documentPages.pageNumber === 1} onClick={firstPage}>
        <FastRewindIcon />
      </IconButton>
      <IconButton disabled={documentPages.pageNumber === 1} onClick={prevPage}>
        <NavigateBeforeIcon />
      </IconButton>
      Page {documentPages.pageNumber} of {documentPages.numPages}
      <IconButton
        data-testid="document-viewer__next-page"
        disabled={documentPages.pageNumber === documentPages.numPages}
        onClick={nextPage}
      >
        <NavigateNextIcon />
      </IconButton>
      <IconButton disabled={documentPages.pageNumber === documentPages.numPages} onClick={lastPage}>
        <FastForwardIcon />
      </IconButton>
    </div>
  </DocumentWrapperStyle>
}



export const DocumentViewerModal = ({
  id = 'documentViewModal',
  children,
  isOpen,
  title = defaultDocViewerTitle,
  body,
  color,
  error,
  cancelCaption,
  okCaption,
  fileBase64,
  onConfirm,
  onCancel,
  onClosed,
  pdfWrapperClass
}: DocumentViewerModalProps) => {

  return (
    <ConfirmationModal
      id={id}
      isOpen={isOpen}
      color={color}
      title={title}
      body={body}
      error={error}
      size="xl"
      okCaption={okCaption || 'Save'}
      cancelCaption={cancelCaption}
      onConfirmation={onConfirm}
      onCancel={onCancel}
      onClosed={onClosed}
    >
      <DocumentViewer
        fileBase64={fileBase64}
        pdfWrapperClass={pdfWrapperClass}
      >
        {children}
      </DocumentViewer>
    </ConfirmationModal>
  );
};