import React, { Suspense, memo, useEffect, useCallback, useState } from "react";
import { Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from 'moment';
import { useParams } from "react-router-dom";

// Own
import PageLoader from "../../Common/PageLoader";
import SpecialistMaintenance from "../../Schedulers/SpecialistMaintenance";
import Audit from "../../ContractInFocus/Audit/Audit";
import ContractReport from "../../ContractInFocus/ContractReport/ContractReport";
import MeterStreams from "../../ContractInFocus/MeterReadings/MeterStreams";
import Logs from "../../ContractInFocus/Logs/Logs";
import Financials from "../../ContractInFocus/Financials/Financials";
//import HASManagement from "components/ContractInFocus/HAS/HASManagement";
import PPMsReport from "../../ContractInFocus/Maintenance/PPMsReport";
import ReactiveReport from "../../ContractInFocus/Maintenance/ReactiveReport";
import ContractSubHeader from "./Containers/ContractSubHeading/ContractSubHeading";
import InFocusSidebar from "./Containers/ContractSidebar/InFocusSidebar";
import ContractNavigation from "./ContractNavigation";
import { PrivateRoute } from "../../../services/Auth/PrivateRoute";
import { Spend } from "components/ContractInFocus/Spend";
import { isGlobalStoreSetsReadySelector } from "store/selectors/store.selectors";
import { adminAccessSelector } from "components/Profile/Selectors/Profile.selector";
import { ContractPeriod } from 'components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface';
import { useGetGroupedContractMenuItems, getFirstPathFromGroupedMenuItems } from "components/Layout/Contract/Containers/ContractSidebar/inFocusSideBarMenuItems";
import { useGetCIFSideBarMenuVisibility, useCheckHASCompatibleEnv, ViewMenuValues } from "components/Profile/Helpers/profileHelpers";

import * as contractPeriodSelectors from "components/AdminPanel/ContractPeriods/Selectors/ContractPeriods.selectors";
import * as inFocusSelectors from "components/ContractInFocus/Selectors/contractInFocus.selectors";
import { useShouldViewContractReportVisibility } from 'components/Profile/Helpers/profileHelpers';
import * as visibilitySelector from 'components/ContractInFocus/Selectors/visibility.selectors';
import { contractActiveSelector } from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import { useGetLeftMargin } from "components/Layout/Common/SideBar/SidebarHelpers";
import { contractSideBarCollapsedSelector, contractSideBarHiddenSelector } from "components/Profile/Selectors/Profile.selector";
import HASSchedule from "components/Schedulers/HASSchedule";
import HASAccidents from "components/ContractInFocus/HAS/HASAccidents";
import HASNearMisses from "components/ContractInFocus/HAS/HASNearMisses";
import { PaperWrappedPTWsList } from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/WorkPermits";

// Styles
import {
  ContractLayoutContainer,
  ContractContentWrapper,
} from "./ContractLayoutStyles";
import TaskDashView from "components/Common/Components/TaskDash/TaskDash";

const ContractLayout = () => {
  const baseRoute = "/contract/:contract_ref/";
  const active = useSelector(contractActiveSelector);
  const isGlobalStoreReady = useSelector(isGlobalStoreSetsReadySelector);
  const shouldViewContractReportVisibility = useShouldViewContractReportVisibility();
  // TODO: The settings state causes refresh, when the browser size is adjusted, the state is updated
  // which triggers component and sub components to re-render.  This is used to adjust the content indentation
  // against a changing side menu.  Check relative / absolute positioning of the layout
  const selectedContractId = useSelector(
    inFocusSelectors.contractInFocusContractId
  );
  const selectedContract = useSelector(
    inFocusSelectors.contractInFocusContractSelector
  );

  const { contract_ref } = useParams();
  const ready = contract_ref === selectedContract?.data.contract_ref;

  const contract = selectedContract?.data;
  const HASCompatibleEnv = useCheckHASCompatibleEnv();

  const selectedSite = useSelector(
    inFocusSelectors.contractInFocusSiteSelector
  );
  const site = selectedSite?.data;
  const selectedContractPeriods = useSelector(
    contractPeriodSelectors.contractContractPeriodsSelector(selectedContractId)
  ).data;

  const selectedSnapShot = useSelector(
    inFocusSelectors.contractInFocusSnapshotHistorySelector
  );

  const ThesePTWs = useCallback(() => {
    return <PaperWrappedPTWsList
      contract={selectedContract?.data}
      portfolio={undefined}
      contractField="contract"
      remoteOperations
      title="Work Permits"
      pageSize={15}
    />
  }, [selectedContract])

  useEffect(() => {
    // this useEffect can be used to change the key to ensure that any page within the switch statement will rerender
    // currently we use it for changing the snapshot, because otherwise the only change is an API injector
    // which works fine when any page renders but after changing the snapshot, we'll want to force rerender the current page
    // we could consider doing the same for contract period if we wanted to automatically add start and end date query params to every request
    if (selectedSnapShot?.focus?.id) {
      setThisKey(selectedSnapShot?.focus.id)
    } else {
      setThisKey('live')
    }
  }, [selectedSnapShot?.focus])

  const visibilitySettings = useSelector(visibilitySelector.mainClientVisibilitySettingsObjectsSelector).data;

  // const mostRecentPublication = useSelector(inFocusSelectors.contractInFocusMostRecentPublicationsSelector);

  // Derive isMostRecentContractPeriod;
  const selectContractPeriod = useSelector(
    inFocusSelectors.contractInFocusFocusedContractPeriodSelector
  )
  const sortedContractPeriods: ContractPeriod[] = [];
  Object.keys(selectedContractPeriods).forEach(
    (key: any) => {
      sortedContractPeriods.push(selectedContractPeriods[key])
    });
  sortedContractPeriods.sort((a, b) => a.start_date > b.start_date ? 1 : -1);
  const currentMoment = moment();
  const start = moment(selectContractPeriod?.start_date);
  const end = moment(selectContractPeriod?.end_date);
  //const indexOfSelectContractPeriod = sortedContractPeriods.indexOf(selectContractPeriod);
  //const isMostRecentContractPeriod = indexOfSelectContractPeriod == sortedContractPeriods.length - 1 || indexOfSelectContractPeriod == -1;
  const isCurrentPeriod = (currentMoment <= end && currentMoment >= start);
  const selectedAdminAccess = useSelector(adminAccessSelector);

  const sideBarHidden = useSelector(contractSideBarHiddenSelector);
  const sideBarCollapsed = useSelector(contractSideBarCollapsedSelector);

  const leftMargin = useGetLeftMargin(sideBarHidden, sideBarCollapsed);

  const isViewSnapshot = (): boolean => selectedSnapShot.focus !== undefined;
  const isSubHeaderDataReady = (): boolean => !!selectedContractPeriods && !!selectedContract?.data;

  const menuVisibility: ViewMenuValues = useGetCIFSideBarMenuVisibility({
    visibilitySettings,
    frozenFor: selectedSnapShot?.focus?.frozen_for,
    contract: contract,
  });

  const [thisKey, setThisKey] = useState<string>()

  const contractMenuItems = useGetGroupedContractMenuItems(contract, menuVisibility);
  const firstPath = getFirstPathFromGroupedMenuItems(contractMenuItems);
  const menuImage = React.useMemo(() => contract?.monthly_maintenance_report_cover_image || site?.site_image, [contract, site])

  //const ThisContractNavigation = useMemo(() => ContractNavigation, [contract])
  return (
    <>
      <PrivateRoute
        path="/contract/:contract_ref/"
        component={ContractNavigation}
      />
      {(isGlobalStoreReady || !selectedAdminAccess) && contract && ready && firstPath && selectContractPeriod && menuVisibility ? (
        <>
          {(isSubHeaderDataReady() && selectedContract) ? <ContractSubHeader contract={selectedContract.data} isCurrentPeriod={isCurrentPeriod} /> : null}
          {(isSubHeaderDataReady() && selectedContract?.data) ? (
            <>
              {<InFocusSidebar
                menuVisibility={menuVisibility}
                menuItems={contractMenuItems}
                menuImage={menuImage}
              />}
              <ContractLayoutContainer snapshotMode={isViewSnapshot()} isCurrentPeriod={isCurrentPeriod} active={active} >
                <ContractContentWrapper
                  className="contract-content-wrapper"
                  leftMenuIndent={leftMargin}
                >
                  <Suspense fallback={<PageLoader />}>
                    {thisKey && <Switch key={thisKey}>
                      <PrivateRoute
                        contract={selectedContract.data}
                        path={`${baseRoute}dash`}
                        component={TaskDashView}
                      />
                      <PrivateRoute
                        contract={selectedContract.data}
                        path={`${baseRoute}ppm`}
                        component={PPMsReport}
                      />
                      <PrivateRoute
                        contract={selectedContract.data}
                        path={`${baseRoute}rm`}
                        component={ReactiveReport}
                      />
                      <PrivateRoute
                        contract={selectedContract.data}
                        path={`${baseRoute}specialist-maintenance`}
                        component={SpecialistMaintenance}
                      />
                      <PrivateRoute
                        contract={selectedContract.data}
                        path={`${baseRoute}audit`}
                        component={Audit}
                      />
                      <PrivateRoute
                        contract={selectedContract.data}
                        path={`${baseRoute}meter-streams`}
                        component={MeterStreams}
                      />
                      <PrivateRoute
                        contract={selectedContract.data}
                        path={`${baseRoute}events`}
                        component={Logs}
                      />
                      <PrivateRoute
                        contract={selectedContract.data}
                        path={`${baseRoute}spend`}
                        component={Spend}
                      />
                      <PrivateRoute
                        contract={selectedContract.data}
                        path={`${baseRoute}financials`}
                        component={Financials}
                      />
                      { //this is separate to the actual display of the menu item, it means the route won't return anything either...
                        shouldViewContractReportVisibility &&
                        <PrivateRoute
                          contract={selectedContract.data}
                          path={`${baseRoute}report`}
                          component={ContractReport}
                        />
                      }
                      {/* {HASCompatibleEnv && <PrivateRoute
                        // this HASCompatibleEnv check is separate to the actual display of the menu item, it means the route won't return anything either...
                        // obvs the backend should also ensure no significant data would be returned... this just handles not loading
                        // the component
                        contract={selectedContract.data}
                        path={`${baseRoute}h-a-s`}
                        component={HASManagement}
                      />} */}
                      {HASCompatibleEnv && <PrivateRoute
                        // this HASCompatibleEnv check is separate to the actual display of the menu item, it means the route won't return anything either...
                        // obvs the backend should also ensure no significant data would be returned... this just handles not loading
                        // the component
                        contract={selectedContract.data}
                        path={`${baseRoute}h-a-s-checks-schedule`}
                        component={HASSchedule}
                      />}
                      {HASCompatibleEnv && <PrivateRoute
                        // this HASCompatibleEnv check is separate to the actual display of the menu item, it means the route won't return anything either...
                        // obvs the backend should also ensure no significant data would be returned... this just handles not loading
                        // the component
                        contract={selectedContract.data}
                        path={`${baseRoute}h-a-s-work-permits`}
                        component={ThesePTWs}
                      />}
                      {HASCompatibleEnv && <PrivateRoute
                        // this HASCompatibleEnv check is separate to the actual display of the menu item, it means the route won't return anything either...
                        // obvs the backend should also ensure no significant data would be returned... this just handles not loading
                        // the component
                        contract={selectedContract.data}
                        path={`${baseRoute}h-a-s-accidents`}
                        component={HASAccidents}
                      />}
                      {HASCompatibleEnv && <PrivateRoute
                        // this HASCompatibleEnv check is separate to the actual display of the menu item, it means the route won't return anything either...
                        // obvs the backend should also ensure no significant data would be returned... this just handles not loading
                        // the component
                        contract={selectedContract.data}
                        path={`${baseRoute}h-a-s-near-misses`}
                        component={HASNearMisses}
                      />}
                      {firstPath && ready && <PrivateRoute
                        // THIS MUST BE PUT LAST SO WE ONLY REDIRECT TO THE FIRST MENU ITEM IF NO OTHER ROUTE IS MATCHED
                        contract={selectedContract.data}
                        path={`${baseRoute}`}
                        redirectTo={`${firstPath}`}
                        component={null}
                      />}
                    </Switch>}
                  </Suspense>
                </ContractContentWrapper>
              </ContractLayoutContainer>
            </>
          ) : null}

        </>
      ) : null}
    </>
  );
};

//ContractLayout.whyDidYouRender = true;

export default memo(ContractLayout);
