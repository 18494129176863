import styled from 'styled-components';
// import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const SupplierServiceDetailsFormWrapper = styled.div`
    display:grid;
    margin-top: 1rem;
    padding: 1rem;
    width: ${props => props.theme.width};
    grid-template-columns: repeat(12, minmax(0, 1fr));
`;

export const SupplierContractDetailsPOSuggestionsWrapper = styled.div`
    margin-top: 1rem;
    padding: 1rem;
    width: ${props => props.theme.width};
`;

SupplierServiceDetailsFormWrapper.defaultProps = {
    theme: {
        width: "100%",
    }
}

SupplierContractDetailsPOSuggestionsWrapper.defaultProps = {
    theme: {
        width: "50%",
    }
}

