import {
  DOWNLOAD_CLEAR,
  DownloadState,
  PDFGeneration,
  DOWNLOAD_STANDBY,
  DownloadActionTypes,
  DOWNLOAD_REQUEST_REMOTE
} from "../Types/Download.types";

const initialState: DownloadState = {
  htmlSource: '',
  meta: null,
  pdfBase64: '',
  status: PDFGeneration.Standby,
  snapshot: {
    id: '',
    contract: null,
    frozen_for: '',
    report_last_produced_at: '',
    last_frozen_at: '',
    contract_period: null,
    publications: [],
  }
};

// TODO: get typescript properly configured for redux this is the hero
const downloadReducer = (state = initialState, action: DownloadActionTypes): DownloadState => {

  switch (action.type) {

    case DOWNLOAD_REQUEST_REMOTE.start:
      return {
        ...state,
        status: PDFGeneration.Requested,
        pdfBase64: ''
      };

    case DOWNLOAD_REQUEST_REMOTE.success:
      return {
        ...state,
        status: PDFGeneration.Ready,
        // @ts-ignore // typescript or eslint need upgrading probably
        pdfBase64: action.payload.base64,
        // @ts-ignore
        snapshot: action.payload.snapshot,
        // @ts-ignore
        htmlSource: action.payload.htmlSource,
      };

    case DOWNLOAD_STANDBY.reducer:
      return {
        ...state,
        status: PDFGeneration.Standby,
      };

    case DOWNLOAD_CLEAR.reducer:
      return {
        ...state,
        htmlSource: '',
        pdfBase64: ''
      };

    default:
      return state;
  }
};

export default downloadReducer;
