import moment, { Moment } from 'moment';
import { DateSelectorMap } from '../interfaces/monthYearSelector.interfaces';
import { fromSaveDateFormat, DISPLACE_ZERO_STARTING_MONTH } from 'components/Common/Utils/Dates';

const setCalculatedDate = (dateSelectorMap: DateSelectorMap, momentDate: Moment) => {
  dateSelectorMap[+momentDate.format('YYYY')]
    ? dateSelectorMap[+momentDate.format('YYYY')][+momentDate.format('M')] = momentDate.format('MMMM')
    : dateSelectorMap[+momentDate.format('YYYY')] = { [+momentDate.format('M')]: momentDate.format('MMMM') };
  return dateSelectorMap;
}

export const calculateMonths = (startDate: Date, endDate: Date) => {
  let dateSelectorMap: DateSelectorMap = {};
  const currentMoment = moment(startDate);
  const endMoment = moment(endDate);

  dateSelectorMap = setCalculatedDate(dateSelectorMap, currentMoment);

  while (currentMoment.isBefore(endMoment, 'month')) {
    currentMoment.add(1, 'months');
    dateSelectorMap = setCalculatedDate(dateSelectorMap, currentMoment);
  }

  return dateSelectorMap;
}

export const transformStringDatesToDateSelectMap = (dateList: string[]): DateSelectorMap => {
  const initialState: DateSelectorMap = {};
  const dates = dateList.reduce((acc, date) => {
    const dateObject = fromSaveDateFormat(date);
    return {
      ...acc,
      [+dateObject.getFullYear()]: {
        ...acc[dateObject.getFullYear()],
        [+dateObject.getMonth() + DISPLACE_ZERO_STARTING_MONTH]: `${dateObject.getMonth() + DISPLACE_ZERO_STARTING_MONTH}`
      }
    }
  }, initialState);

  return dates;
}