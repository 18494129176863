import React from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

// Own
import {
  StyledSiteRow,
  StyledSiteCell,
} from "./SiteContractRowStyles";
import { SiteContract, SiteIndex } from '../../Interfaces/Sites.interface';
import { withResizeDetector } from 'react-resize-detector';
import CommonAPIService from "components/ContractInFocus/Services/commonAPI.services";

interface SiteContractProps {
  site: SiteIndex;
  contract: SiteContract;
  width: number;
  onDocClicked: (contractId: number) => void;
  showExpiry: boolean;
}

const SiteContractRow = ({ contract, site, width, onDocClicked, showExpiry }: SiteContractProps) => {

  const history = useHistory();
  const dispatch = useDispatch();

  const handleClicked = (event: any): void => {
    CommonAPIService.triggerServerSideContractDataUpdate(contract.contract_ref);
    event.stopPropagation();
    history.push(`/contract/${contract.contract_ref}/`);
  }

  const handleDocClicked = (event: any, contractId: number): void => {
    event.stopPropagation();
    onDocClicked(contractId);
  }

  return (
    <StyledSiteRow onClick={handleClicked} width={width} className={contract.active ? "active" : "inactive"}>
      <StyledSiteCell title={contract.contract_ref} className={contract.in_contract ? "in-contract contract-no" : "not-in-contract contract-no"}>
        <span>{contract.contract_ref}</span>
      </StyledSiteCell>
      <StyledSiteCell title={contract.alias || contract.contract_with.name} className={contract.in_contract ? "in-contract contract-with" : "not-in-contract contract-with"} style={{ flexGrow: 1 }}>
        <em className="fas fa-briefcase"></em>
        <span>{contract.alias || contract.contract_with.name}</span>
      </StyledSiteCell>
      {showExpiry && <StyledSiteCell title={contract.expiry} className={contract.in_contract ? "in-contract contract-with" : "not-in-contract contract-with"} style={{ flexGrow: 1 }}>
        <em className="fas fa-clock"></em>
        <span>{contract.expiry}</span>
      </StyledSiteCell>}
      {/* <StyledSiteCell
        title={contract?.new_documents_count?.toString()}
        className="doc-count"
        onClick={(event) => handleDocClicked(event, contract.id)}
      >
        <em className="fas fa-file-alt"></em>
        <span>{contract.new_documents_count}</span>
      </StyledSiteCell> */}
    </StyledSiteRow>
  );
}

export default withResizeDetector(
  SiteContractRow
);