import React, { memo, useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ListCard, EntityForCard, CardProps } from "components/IndexPage/Interfaces/CardList.interface";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: "1rem"
    }
}));

interface CardListGridProps {
    objectsForCards: EntityForCard[];
    CardComponent: React.FunctionComponent<CardProps>;
    CardFront: any;
    CardBack: any;
    toggleFavourite: (arg: ListCard) => void;
}

const CardListGrid = ({ objectsForCards, CardComponent, CardFront, CardBack, toggleFavourite }: CardListGridProps) => {

    const classes = useStyles();
    const [cardState, setCardState] = useState<ListCard[]>([]);

    useEffect(() => {
        const newCardsState = objectsForCards.map(o => ({ ...o, mode: "front" }));
        setCardState(newCardsState);
    }, [objectsForCards]);

    const turnCard = (
        cards: ListCard[],
        replacement: ListCard
    ): ListCard[] => {
        // iterate the passed in cards, if the newly selected card is displaying the rear face, flip all cards to the front
        // (the replacement card will override it's replacement afterwards, so will show up on the rear face)
        // otherwise leave them as they are .. it's ok to do this because if the replacement card is being 
        // moved onto it's front face, the card being replaced should be the only card showing the rear face owing to this same
        // function having being run on each previous selection.
        const newCards = cards.map(c => (c.id === replacement.id ? replacement : { ...c, mode: "front" }));
        return newCards
    };

    const handleSelectCard = (card: ListCard): void => {
        setCardState(turnCard(cardState, card));
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                {cardState.map((c) => {
                    return (
                        <Grid key={c.id} item xs={12} sm={6} lg={3}>
                            <CardComponent
                                onFavourite={toggleFavourite}
                                onSelect={handleSelectCard}
                                onUnselect={handleSelectCard}
                                data={c}
                                CardBack={CardBack}
                                CardFront={CardFront}
                            />
                        </Grid>
                    )
                }
                )}
            </Grid>
        </div>
    );
};

//CardListGrid.whyDidYouRender = true;

export default memo(CardListGrid);
