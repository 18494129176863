import React, { useState, useEffect, useRef, useCallback, useReducer, memo, useMemo } from 'react';
import { IntersectionOptions } from 'react-intersection-observer';
import { Paper } from "@material-ui/core";

// Own
import { AutoInput } from 'components/Common/Components/AutoInput/AutoInput';
import { FormErrorsType } from "store/Common/Interfaces/Common.interface";
import { FieldsFormConfig } from "components/Common/Components/DocumentsGrid/DocumentsGrid.interface";
import { FieldMetaGroup, Dictionary, Primitive, FieldGroup } from "components/Common/Interfaces/Entity.interface";
import InViewWrapper from 'components/Common/Components/InViewWrapper/InViewWrapper';
import { formValueConsideredMissing, formValueWrongBoolean } from "store/Common/Helpers/commonHelpers";
import { InViewProgressTracker } from "components/Common/Components/InViewWrapper/InViewWrapper";
import { logObjDifferences } from "store/reducers/reducer.helper";

// Styles
import "components/Common/Components/GeneralActionForm/GeneralActionFormStyles.scss";
import { isEqual } from 'lodash';

export interface FormValues {
    [field: string]: Primitive; //represents field: actual value
}

interface PredictCommonErrorsUsingMetaProps {
    field: string,
    formValuesRef: React.MutableRefObject<FormValues>,
    formErrors: FormErrorsType,
    fieldConfigs: FieldsFormConfig;
    metaForForm: FieldMetaGroup;
}

const predictCommonErrorsUsingMeta = ({ field, metaForForm, formErrors, fieldConfigs, formValuesRef }: PredictCommonErrorsUsingMetaProps) => {
    const meta = metaForForm[field];
    const displayName = meta?.label || field;
    const theseFormErrors = formErrors[field];
    const config = fieldConfigs[field];
    const value = formValuesRef.current[field];
    const submitValueMissing = formValueConsideredMissing({ formValue: value, config, meta, includeRequiredForSubmitOnly: true });
    const submitValueIllegalNegative = formValueWrongBoolean({ value_required: true, formValue: value, config, meta, includeRequiredForSubmitOnly: true })
    const submitValueIllegalPositive = formValueWrongBoolean({ value_required: false, formValue: value, config, meta, includeRequiredForSubmitOnly: true })
    const saveValueMissing = formValueConsideredMissing({ formValue: value, config, meta });
    const saveValueIllegalNegative = formValueWrongBoolean({ value_required: true, formValue: value, config, meta })
    const saveValueIllegalPositive = formValueWrongBoolean({ value_required: false, formValue: value, config, meta })
    const fieldValidationMessages: any = {};
    if (submitValueMissing || saveValueMissing) {
        const missingMessage = `'${displayName}' needs a value.`;
        if (submitValueMissing) {
            fieldValidationMessages['submit'] = missingMessage;
        }
        if (saveValueMissing) {
            fieldValidationMessages['save'] = missingMessage;
        }
    }
    if (submitValueIllegalNegative || saveValueIllegalNegative) {
        const missingMessage = `'${displayName}' must be positive before proceeding.`;
        fieldValidationMessages['submit'] = missingMessage;
        if (saveValueIllegalNegative) {
            fieldValidationMessages['save'] = missingMessage;
        }
    }
    if (submitValueIllegalPositive || saveValueIllegalPositive) {
        const missingMessage = `'${displayName}' must be negative before proceeding.`;
        if (submitValueIllegalPositive) {
            fieldValidationMessages['submit'] = missingMessage;
        }
        if (saveValueIllegalPositive) {
            fieldValidationMessages['save'] = missingMessage;
        }
    }
    return fieldValidationMessages;
}

interface ValidatorProps {
    formValuesRef: React.MutableRefObject<FormValues>;
    previousValuesRef: React.MutableRefObject<FormValues | undefined>;
    allowErrorReEvaluation?: React.MutableRefObject<boolean>;
    formErrors?: FormErrorsType;
    setFormErrors?: React.Dispatch<Dictionary<Dictionary<string | undefined>>>;
    fieldConfigs: FieldsFormConfig;
    metaForForm: FieldMetaGroup;
}

export type GeneralFormValidator = (props: ValidatorProps) => FormErrorsType;
interface RunAllValidatorProps extends ValidatorProps {
    generalValidator?: GeneralFormValidator;
}

const runAllValidators = (
    {
        formValuesRef,
        previousValuesRef,
        allowErrorReEvaluation,
        formErrors,
        setFormErrors,
        fieldConfigs,
        metaForForm,
        generalValidator
    }: RunAllValidatorProps) => {
    // NB when this function is used it should be the ONLY function to alter formErrors (i.e. to call setFormErrors)
    // because otherwise an infinite regression is possible when it looks to compare the existing errors with 
    // the ones it would set now (i.e. if they're changed afterwards by some other function, every time it runs).  
    // However, as an extra guard rail, and also so that formErrors from the B/E that might be set 
    // will persist until the field values are changed, this function will only run when at least 
    // one value in the form has changed since it last ran (we don't check for the exact same field value before running a particular validation
    // as it is conceivable that this function would need to run on a different field to the one where the value has changed)
    // allowErrorReEvaluation is an override for cases where something has changed that might be thrown up by generalValidator
    const valuesChanged = !isEqual(previousValuesRef.current, formValuesRef.current);
    if (setFormErrors && formErrors && (valuesChanged || allowErrorReEvaluation?.current)) {
        if (allowErrorReEvaluation?.current) {
            // immediately set it to false again so it doesn't permanently disable the check above
            allowErrorReEvaluation.current = false;
        }
        let newFieldValidationErrors: Dictionary<Dictionary<string | undefined>> = {};
        Object.keys(fieldConfigs).map(
            (k) => {
                // NOTE this function must do EVERYTHING it is going to do in terms of validation adjustments, and THEN compare the results to the 
                // existing - that way it will be possible to check if anything material has changed and prevent an infinite loop.
                // NB there will nearly always be a concept of when an object can be saved and frequently when some further action can be taken - and this is related to
                // B/E restrictions, communicated via the meta.  Therefore calculating when an object can be saved or subject to a further action ('submitted')
                // are common 'special' cases - and we handle them here for convenience. 
                // First predict errors based on meta info
                const predictatbleFieldValidationMessages = predictCommonErrorsUsingMeta({ formErrors, formValuesRef, field: k, fieldConfigs, metaForForm });
                // Second, run specific calcs based on more particular logic stored in fieldValidators in the formConfig
                const validator = fieldConfigs[k].fieldValidator;
                const validation = validator ? validator({ formValuesRef, value: formValuesRef.current[k], meta: metaForForm[k] }) : false;
                // Combine the two with the 'specific' calcs in the formValidator overriding (as they have access to the meta too and are form specific)
                const newFieldValidation = { ...predictatbleFieldValidationMessages, ...validation, };
                newFieldValidationErrors[k] = newFieldValidation;
            }
        );
        let generalFormValidation = {};
        if (generalValidator) {
            generalFormValidation = generalValidator({
                formValuesRef,
                previousValuesRef,
                formErrors,
                setFormErrors,
                fieldConfigs,
                metaForForm
            });
        }
        const newValidationErrors = { ...newFieldValidationErrors, ...generalFormValidation }
        if (!isEqual(formErrors, newValidationErrors)) {
            setFormErrors(newValidationErrors);
            //const dataDiff = logObjDifferences(formErrors, newValidationErrors);
        }
    }
};

interface RenderComponentInterface {
    Component: React.FC<any>;
    index: number;
    inViewOptions?: React.MutableRefObject<IntersectionOptions>;
    inViewProgressTracker?: React.MutableRefObject<InViewProgressTracker>;
    groupKey: string;
    skipInViewWrapper?: Boolean;
    className?: string;
}

const RenderComponentInForm = React.memo(({ Component, index, groupKey, skipInViewWrapper, className, inViewOptions, inViewProgressTracker }: RenderComponentInterface) => {
    if (inViewOptions && !skipInViewWrapper) {
        return <InViewWrapper
            key={groupKey}
            WrappedComponent={() => <div className={className || ''}><Component /></div>} // using "() => x" rather than defining the element in the theseReportSections array as () => <SomeSectionComponent .../> means that commonProps isn't called every time there's a rerender cycle
            inViewOptions={inViewOptions.current}
            inViewProgressTracker={inViewProgressTracker}
            i={index}
            override={undefined} // if false we want to pass this as undefined
            placeHolderMinHeight="25vh"
        />
    }
    return <div className={className}>
        <
            Component
            key={groupKey}
        />
    </div>
});

const getGroupClassName = (group: FieldGroup) => `${group.className}${group.children?.length ? ' parent' : ''} ${group.component ? '' : 'field-group-wrapper'}`;

interface ActionFormProps {
    formValues: React.MutableRefObject<FormValues>;
    fieldConfigs: FieldsFormConfig;
    formLayout?: FieldGroup[];
    generalFieldZindex?: number;
    wipe?: boolean;
    callWithOnChange?: (newFormValues: FormValues) => void;
    caption?: string;
    gridClass?: string;
    metaForForm: FieldMetaGroup;
    refreshSignal?: any;
    showReadOnly?: boolean;
    paperElevation?: number;
    inViewOptions?: React.MutableRefObject<IntersectionOptions>;
    inViewProgressTracker?: React.MutableRefObject<InViewProgressTracker>;
    initiallySelectedDataField?: React.MutableRefObject<string | undefined>;
    formErrors?: FormErrorsType;
    setFormErrors?: React.Dispatch<FormErrorsType>;
    setGAFormChanged?: React.Dispatch<boolean>; //NOTE that GEF has it's own 'formChanged' type function, 
    // setGAFormChange is here for other components using this component directly - TODO - analyse what's special about the GEF formChanged and if sensible, move 
    // to this GAF component to unify
    addColonToLabel?: boolean;
    generalValidator?: GeneralFormValidator;
    allowErrorReEvaluation?: React.MutableRefObject<boolean>;
}

const ActionForm = (
    {
        formValues,
        fieldConfigs,
        generalFieldZindex,
        wipe,
        callWithOnChange,
        caption,
        gridClass,
        metaForForm,
        refreshSignal,
        showReadOnly,
        formLayout,
        paperElevation,
        inViewOptions,
        inViewProgressTracker,
        initiallySelectedDataField,
        formErrors,
        setFormErrors,
        setGAFormChanged,
        addColonToLabel,
        generalValidator,
        allowErrorReEvaluation
    }: ActionFormProps) => {

    const previousFormValues = useRef<FormValues>();
    const initialFormValues = useRef<FormValues>({ ...formValues.current });
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 1);
    const currentFocus = useRef<string>();
    const [postComponentSelected, setPostComponentSelected] = useState<string | undefined>(initiallySelectedDataField?.current);

    useEffect(() => {
        forceUpdate();
    }, [refreshSignal])

    const scrollToRef: any = useRef();

    const runSideEffects = useCallback((newFormValues: FormValues, fieldConfigs: FieldsFormConfig, onChangeFormValues: (newValues: FormValues) => void) => {
        let sideEffects = [];
        let sideEffectsToRun = Object.keys(fieldConfigs).filter(x => fieldConfigs[x].sideEffect !== undefined);
        for (let index in sideEffectsToRun) {
            const field = sideEffectsToRun[index];
            //@ts-ignore
            const sideEffectChangedSomething = fieldConfigs[field].sideEffect(newFormValues, fieldConfigs, onChangeFormValues, previousFormValues.current);
            if (sideEffectChangedSomething) {
                sideEffects.push(field)
            }
        }
        runAllValidators({
            // is a side effect, so it runs here (before previousFormValues are updated but after other sideEffects, as those may affect validation, including missing values)
            formValuesRef: formValues,
            formErrors,
            setFormErrors,
            fieldConfigs,
            metaForForm,
            previousValuesRef: previousFormValues,
            generalValidator,
            allowErrorReEvaluation
        });
        previousFormValues.current = newFormValues;
        if (sideEffects.length > 0) {
            forceUpdate();
        }
    }, [metaForForm, formValues, formErrors, setFormErrors, generalValidator, allowErrorReEvaluation]);

    useEffect(() => {
        if (wipe) {
            formValues.current = {};
            forceUpdate();
        }
    }, [wipe, formValues])

    useEffect(() => {
        initiallySelectedDataField && scrollToRef.current && scrollToRef.current.scrollIntoView && scrollToRef.current.scrollIntoView()
    }, [scrollToRef, initiallySelectedDataField])

    const onChangeFormValues = useCallback((newValues: FormValues) => {
        const updatedFormValues = { ...formValues.current, ...newValues }
        formValues.current = updatedFormValues;
        runSideEffects(updatedFormValues, fieldConfigs, onChangeFormValues);
        if (setGAFormChanged) {
            setGAFormChanged(!isEqual(initialFormValues.current, formValues.current));
        }
        if (callWithOnChange) {
            callWithOnChange(updatedFormValues);
        }
    }, [
        fieldConfigs,
        formValues,
        setGAFormChanged,
        runSideEffects,
        callWithOnChange,
    ]
    )

    const hasMeta = useCallback(() => metaForForm && !!Object.keys(metaForForm).length, [metaForForm]);
    const RenderField = useCallback(({ dataField, fieldConfigs, formErrors }: { dataField: string | React.FC<any>, fieldConfigs: FieldsFormConfig, formErrors: FormErrorsType | undefined }) => {
        if (typeof dataField === "string") {
            const fieldConfig = fieldConfigs[dataField]
            return (fieldConfig ? <AutoInput
                wrapperRef={initiallySelectedDataField?.current === dataField ? scrollToRef : undefined}
                zIndex={generalFieldZindex || 3001}
                key={dataField}
                dataField={dataField}
                fieldConfig={fieldConfig}
                fieldMeta={metaForForm[dataField]}
                formValuesRef={formValues}
                onChangeFormValues={onChangeFormValues}
                currentFocus={currentFocus}
                refreshSignal={mustRefresh}
                dispatchRefreshContext={forceUpdate}
                showReadOnly={showReadOnly}
                setPostComponentSelected={setPostComponentSelected}
                extraClassNames={postComponentSelected === dataField ? 'postComponentSelected' : 'postComponentNotSelected'}
                formErrors={formErrors} // nothing has incorporated this yet, but this is a 'stub' to be used to integrate B/E form Error feedback
                setFormErrors={setFormErrors} // nothing has incorporated this yet, but this is a 'stub' to be used to integrate B/E form Error feedback
                addColonToLabel={addColonToLabel}
            /> : <></>)
        } else {
            const DataField = dataField
            return <DataField />
        }

    }, [
        generalFieldZindex,
        metaForForm,
        formValues,
        setFormErrors,
        onChangeFormValues,
        currentFocus,
        mustRefresh,
        showReadOnly,
        postComponentSelected,
        initiallySelectedDataField,
        addColonToLabel
    ]);

    const RenderComponentOrFieldGroup = useCallback(({ group, i }: { group: FieldGroup, i: number }) => {
        if (group.component) {
            // NB DO NOT PASS IN COMPONENTS WHICH WILL SEND REQUESTS TO THE BACKEND ON EACH RENDER, OR ONES THAT ARE COMPLEX
            // AS THEY WILL BE REEVALUATED WHENEVER THE FORM IS EVALUATED - WHICH WILL BE ON MOST VALUE CHANGES.
            return <RenderComponentInForm
                Component={group.component}
                skipInViewWrapper={group.skipInViewWrapper}
                className={getGroupClassName(group)}
                index={i}
                groupKey={group.group_id || group.group_title}
                inViewOptions={inViewOptions}
                inViewProgressTracker={inViewProgressTracker}
            />
        }
        return <RenderFieldGroup
            group={group}
            key={group.group_id || group.group_title}
            fieldConfigs={fieldConfigs}
            formErrors={formErrors}
        />
    }, [inViewOptions, inViewProgressTracker, fieldConfigs, formErrors]);

    const RenderFormLayout = ({ formLayout, paperElevation }: { formLayout: FieldGroup[], paperElevation?: number }) => {
        return <>
            {paperElevation ?
                <>{
                    formLayout.map((group, i) => {
                        return <Paper elevation={paperElevation} key={group.group_id || group.group_title}>
                            <RenderComponentOrFieldGroup group={group} i={i} />
                        </Paper>
                    })
                } </>
                :
                <>{
                    formLayout.map((group, i) => {
                        return <RenderComponentOrFieldGroup group={group} i={i} key={group.group_id || group.group_title} />
                    })
                }</>
            }
        </>
    }

    const RenderFieldGroup = ({ group, fieldConfigs, formErrors }: { group: FieldGroup, fieldConfigs: FieldsFormConfig, formErrors: FormErrorsType | undefined }) => {
        return <div className={getGroupClassName(group)}>
            <h3 className='field-group-title'>{group.group_title}</h3>
            {group.group_subtitle && <h4 className='field-group-subtitle'>{group.group_subtitle}</h4>}
            {
                group.fields.map((dataField, i) => {
                    if (inViewOptions?.current && !initiallySelectedDataField && !group.skipInViewWrapper) {
                        return <InViewWrapper
                            key={i}
                            WrappedComponent={() => <RenderField
                                dataField={dataField}
                                fieldConfigs={fieldConfigs}
                                formErrors={formErrors}
                            />} // using "() => x" rather than defining the element in the theseReportSections array as () => <SomeSectionComponent .../> means that commonProps isn't called every time there's a rerender cycle
                            inViewOptions={inViewOptions.current}
                            inViewProgressTracker={inViewProgressTracker}
                            i={i}
                            override={undefined} // if false we want to pass this as undefined
                            placeHolderMinHeight="25vh"
                            dataField={dataField}
                            fieldConfigs={fieldConfigs}
                        />
                    }
                    return <RenderField
                        key={i}
                        dataField={dataField}
                        fieldConfigs={fieldConfigs}
                        formErrors={formErrors}
                    />
                })
            }

            {
                group.children && <RenderFormLayout
                    formLayout={group.children}
                />

            }

        </div>
    }

    const RenderSimpleForm = ({ paperElevation }: { paperElevation?: number | undefined }) => {
        return (paperElevation ? <Paper elevation={paperElevation}>
            <>
                {
                    Object.keys(fieldConfigs).map((dataField) => {
                        return <RenderField
                            key={dataField}
                            dataField={dataField}
                            fieldConfigs={fieldConfigs}
                            formErrors={formErrors}
                        />
                    })
                }
            </>
        </Paper> : <>
            {
                Object.keys(fieldConfigs).map((dataField) => {
                    return <RenderField
                        key={dataField}
                        dataField={dataField}
                        fieldConfigs={fieldConfigs}
                        formErrors={formErrors}
                    />
                })
            }
        </>)
    }

    useEffect(() => {
        // NB there are many side effects we might want to run immediately - so it's important we run onChangeFormValues once on load
        onChangeFormValues(formValues.current);
    }, [formValues, onChangeFormValues])

    return <div className="generalActionFormWrapper">
        {caption && <span className="actionFormCaption">{caption}</span>}
        {
            hasMeta() &&
            <div className={`generalActionFormGrid ${gridClass ? gridClass : ''}`}>
                {
                    mustRefresh && <>
                        {formLayout ?
                            <RenderFormLayout
                                formLayout={formLayout}
                                paperElevation={paperElevation}
                            /> : <RenderSimpleForm paperElevation={paperElevation} />
                        }
                    </>
                }
            </div>
        }

    </div>
}

// ActionForm.whyDidYouRender = true;

export default React.memo(ActionForm);