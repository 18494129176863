import React, { useEffect, useState, useRef, memo } from "react";
import { useSelector, useDispatch } from "react-redux";

// Own
import { TabbedPanel } from "components/Common/Components/TabPanel";
//import { AdminSite } from "components/AdminPanel/Sites/Interfaces/Sites.interface";
import { Site } from "components/Sites/Interfaces/Site.inteface";
import siteService from "components/AdminPanel/Sites/Services/sitesServices";
import { AdminImageForm } from "components/AdminPanel/Containers/AdminImageForm";
import {
  siteImage,
  getSiteFormItems,
} from "components/AdminPanel/Sites/Models/Sites.model";
import { setDetailPage } from "components/AdminPanel/Actions/adminPanel.actions";
import { getPanelSelectorFactory } from "components/AdminPanel/Selectors/Selectors";
import { ContractSublistView } from "components/AdminPanel/Contracts/Containers/ContractSublistView";
import * as selectors from "components/AdminPanel/Sites/Selectors/Sites.selectors";
import { FORM_ROW_ID } from "components/AdminPanel/Models/AdminPanel.model";
import { ImageConfig } from "components/AdminPanel/Containers/AdminImageForm/AdminImageForm";

interface SitesDetailViewProps {
  panelId: string;
  data: { data: Site };
}

const SitesDetailView: React.FC<SitesDetailViewProps> = ({
  panelId,
  data: { data },
}) => {
  const siteId = data.id;
  const siteTabs = ["Detail", "Contracts"];
  const contractSublistTestId = "sites-contract-detail-view";
  const thisDate = new Date()

  const selectAdminPanelState = useSelector(getPanelSelectorFactory(panelId));
  const selectSitesContractsState = useSelector(
    selectors.contractsBySiteIdSelector(siteId)
  );

  const selectSite = useSelector(selectors.siteByIdSelector(data.id));

  const dispatch = useDispatch();

  const handleChangeTab = (index: number) => {
    dispatch(setDetailPage(panelId, data.id, index));
  };



  const [imagesConfig, setImagesConfig] = useState<ImageConfig[]>([]);

  useEffect(() => {

    const onSiteImageUpload = (file: any, progress: any) => {
      siteService.updateSiteImage(data.id, data.site_ref, file).subscribe();
    };

    const onSiteImageClear = () => {
      siteService.clearSiteImage(data.id, data.site_ref).subscribe();
    };

    setImagesConfig(
      [
        {
          imageCaption: siteImage.label,
          imageKey: siteImage.key,
          onUpload: onSiteImageUpload,
          onClear: onSiteImageClear
        },
      ]
    )
  }, [data])

  const handleCancel = (site: Site): void => {
    const IS_GRID_FORM = site.id.toString() === FORM_ROW_ID;
    // THIS SHOULD ENSURE THE STATE IS AS WAS BEFORE EDITING THE FORM, i.e. just repopulate the form from the stored object
    //IS_GRID_FORM && dispatch(actions.clearPsuedoSite());
  };

  const handleUpdate = (oldSite: Site, site: Site): void => {
    const IS_GRID_FORM = site.id.toString() === FORM_ROW_ID;

    IS_GRID_FORM
      ? siteService.createSite({ ...site, site_image: undefined }, panelId)
      : siteService.updateSite(site.site_ref, site, selectSite.meta);
  };

  const getDetailTab = () => {
    return (
      <AdminImageForm
        data={selectSite.data}
        meta={selectSite.meta}
        formMeta={getSiteFormItems(selectSite.meta)}
        onUpdate={handleUpdate}
        onCancel={handleCancel}
        imagesConfig={imagesConfig}
      />
    );
  };

  return (
    <>
      {selectSite.data && (
        <TabbedPanel
          pageIndex={selectAdminPanelState[data.id]?.detailIndex || 0}
          showTabs={data.id.toString() !== FORM_ROW_ID}
          onChangeTab={handleChangeTab}
          tabs={siteTabs}
        >
          <div>{selectSite.meta && getDetailTab()}</div>
          <div>
            <ContractSublistView
              panelId={panelId}
              withSite={false}
              contractsState={selectSitesContractsState}
              dataTestId={contractSublistTestId}
              id={siteId}
              handleDropCB={siteService.linkContractToSite}
            />
          </div>
        </TabbedPanel>
      )}
    </>
  );
};

// SitesDetailView.whyDidYouRender = true;

//export default SitesDetailView;

export default memo(SitesDetailView);
