import React, { useState, useRef, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Select from "@material-ui/core/Select";
import { MenuItem } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { createStyles, makeStyles } from "@material-ui/core/styles";


// Own
import ConfirmationModal from 'components/Common/Components/Modal/Modal';
import { ModalProps } from 'components/Common/Components/Modal/interfaces/modal.interfaces';
import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";
import { ContractInterface } from "components/AdminPanel/Contracts/Interfaces/Contract.interface";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { toTitleCase } from "helpers/String/String.helper";
import meterStreamServices from "components/ContractInFocus/MeterReadings/Services/MeterStreamServices";


export const materialModalStyles = makeStyles(theme =>
    createStyles({
        selectItem: {
            fontSize: 14,
            minWidth: 200
        },
        formControl: {
            margin: theme.spacing(1),
            paddingRight: "1.5rem"
        },
        textInputControl: {
            minWidth: 350,
        },
        selectFormControl: {
            transform: "translateY(4px)",
            minWidth: 200,
        },
        selectEmpty: {
            marginTop: theme.spacing(2)
        }
    })
);


interface EditMeterStreamProps extends ModalProps {
    //contract: ContractInterface;
    contract: SiteContract;
    meterStreamId: string;
    initialTitle: string;
    initialUtilityTypeId: string;
    initialMeterStreamUnitValue: string | undefined;
    meta: FieldMetaGroup;
    reloadParent: Function;
    close: Function,
}

export default ({
    isOpen = true,
    contract,
    meterStreamId,
    initialTitle,
    initialUtilityTypeId,
    initialMeterStreamUnitValue,
    meta,
    onCancel,
    close,
    reloadParent,
}: EditMeterStreamProps) => {

    const editMeterStreamTitle = `Edit ${initialTitle} Metering Stream`;
    const [buttonActive, activateButton] = useState(false);
    const [utilityTypeOpen, setUtilityTypeOpen] = useState(false);
    const [utilityType, setUtilityType] = useState(initialUtilityTypeId);
    const meterStreamTitleInput = useRef<any>();
    const meterStreamTitleValue = useRef(initialTitle);
    const meterStreamUnitValue = useRef(initialMeterStreamUnitValue);
    const utilityTypeValue = useRef<string | number>(initialUtilityTypeId);
    const modalClasses = materialModalStyles();

    const toggleUtilityTypeOpen = () => {
        setUtilityTypeOpen(!utilityTypeOpen);
    }

    const handleInfo = (event: React.MouseEvent): void => {
        meterStreamServices.updateMeterStream(contract, meterStreamId, { contract: contract.id, utility_type: utilityType, title: meterStreamTitleValue.current, unit: meterStreamUnitValue.current }).then(
            (response) => {
                reloadParent();
                close();
            }
        )
    };

    const checkFormState = (): void => {
        if (meterStreamTitleInput.current?.value.length > 0 && utilityTypeValue.current !== "") {
            activateButton(true);
        } else {
            activateButton(false);
        }
    }

    const onChangeUtility = (event: any) => {
        utilityTypeValue.current = event.target.value;
        setUtilityType(event.target.value);
        //utilityTypeDisplay.current = toTitleCase(name);
        checkFormState();
    }

    const separatorStyles = { marginBottom: "2rem" };

    return (
        <>
            <ConfirmationModal
                id="editMeterStream"
                isOpen={isOpen}
                title={editMeterStreamTitle}
                onCancel={onCancel}
                onConfirmation={handleInfo}
                size="xl"
                okCaption="Update Metering Stream"
                disabled={!buttonActive}
            >
                {/* <h3>Meter Title</h3>
                
                <div style={separatorStyles}>
                    <LabelInput meta={meta} label="Meter Title" />
                </div>
                <h3>Meter Utility</h3>
                <div style={separatorStyles}>
                    <LabelInput meta={meta} label="Meter Utility" />
                </div> */}
                <FormControl className={`${modalClasses.formControl} ${modalClasses.textInputControl}`}>
                    <TextField inputRef={meterStreamTitleInput} id="titleInput" variant="outlined" multiline={false} onChange={event => {
                        const { value } = event.target;
                        checkFormState();
                        meterStreamTitleValue.current = value;
                    }} defaultValue={meterStreamTitleValue.current}>
                    </TextField>
                </FormControl>
                <FormControl className={`${modalClasses.formControl} ${modalClasses.selectFormControl}`}>
                    <InputLabel shrink>
                        Select Utility Type
                    </InputLabel>
                    <Select value={utilityTypeValue.current} label="Select Utility Type" MenuProps={{
                        style: { zIndex: 35001 }
                    }}
                        onChange={(e: any) => onChangeUtility(e)}>
                        {(meta.utility_type.choices || []).map((field) => {
                            return <MenuItem
                                key={field.value}
                                value={field.value}
                                className={modalClasses.selectItem}
                            >
                                {toTitleCase(field.display_name)}
                            </MenuItem>
                        })
                        }
                    </Select>
                </FormControl>
                <FormControl className={`${modalClasses.formControl} ${modalClasses.textInputControl}`}>
                    <TextField inputRef={meterStreamTitleInput} id="unitInput" variant="outlined" defaultValue={initialMeterStreamUnitValue} placeholder="Add Metering Stream Unit" multiline={false} onChange={event => {
                        const { value } = event.target;
                        checkFormState();
                        meterStreamUnitValue.current = value;
                    }}>
                    </TextField>
                </FormControl>
            </ConfirmationModal>
        </>
    );
}