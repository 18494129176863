import React, { useState, useRef } from "react";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, TooltipProps } from "@material-ui/core";
import {
  withStyles
} from "@material-ui/core/styles";

import "components/Common/Components/InfoHint/infoHint.scss";

const useAppriseToolTipStyles = makeStyles({
  tooltip: {
    fontSize: "1em",
  },
});

export const GeneralWarnAppriseToolTip = (props: TooltipProps) => {
  const classes = useAppriseToolTipStyles();
  return <Tooltip {...props} classes={{ tooltip: classes.tooltip }} />;
};

const InfoToolTip = withStyles({
  tooltip: {
    fontSize: "0.9rem",
    whiteSpace: 'pre-line',
    maxWidth: '50vw'
  }
})(Tooltip);

export const InfoHint = ({ message }: { message: string }) => {
  const hintRef = useRef(null);
  return (
    <>
      {message && message.length && (
        <div
          ref={hintRef}
          className="info-hint no-print"
          style={{ display: "inline-block" }}
        >
          <InfoToolTip title={message}>
            <InfoIcon />
          </InfoToolTip>
        </div>
      )}
    </>
  );
};

export const WarningHint = ({ message }: { message: string }) => {
  const hintRef = useRef(null);
  return (
    <>
      {message && message.length && (
        <div
          ref={hintRef}
          className={"warning-hint no-print"}
          style={{ display: "inline-block", color: "red" }}
        >
          <InfoToolTip title={message}>
            <WarningIcon />
          </InfoToolTip>
        </div>
      )}
    </>
  );
};

export const GeneralTooltip = (props: TooltipProps) => {
  return (
    <>
      <span
        className="general-tooltip"
      >
        <InfoToolTip {...props} />

      </span>
    </>
  );
};



