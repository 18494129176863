import React, { useEffect, Suspense, lazy, useCallback, useRef } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import path from "path";

// Own
import API from "services/API/API";
import PageLoader from "./components/Common/PageLoader";
import BasePage from "./components/Layout/BasePage";
import { PrivateRoute } from "./services/Auth/PrivateRoute";
// import sites from "./components/Sites/Sites";
import IndexPage from "components/IndexPage/IndexPage";
import BaseHorizontal from "./components/Layout/BaseHorizontal";
import { setRoute } from "./store/Common/Actions/common.actions";
import { resetState } from "./store/actions/actions";
import ContractLayout from "./components/Layout/Contract/ContractLayout";
import PortfolioLayout from "./components/Layout/Portfolio/PortfolioLayout";
import HASCentre from "components/HASCentre/HASCentre.tsx";
import { waitFor } from "./router.helper";
import { changeSetting } from "store/actions/actions";
import { defaultIndexPageSelector } from "store/selectors/settings.selector";
import { history } from "helpers/History/History";
import { store } from "store/store";

import {
  hasNoSitesSelector,
  sitesLengthSelector,
  hasOneViableSiteSelector,
  siteSelector,
  singleSiteFirstContractPathSelector
} from "components/Sites/Selectors/Sites.selectors";
import {
  hasNoPortfoliosSelector,
  portfoliosLengthSelector,
  portfoliosSelector,
  singlePortfolioPathSelector
} from "components/Portfolios/Selectors/Portfolio.selectors";

export function saveRouteBeforeLogout(pathname) {
  let currentRoute = pathname
    ? pathname
    : window.location.href.replace(window.location.origin, "");
  if (!nonRedirectRoute.includes(currentRoute)) {
    localStorage.setItem("savedLocation", currentRoute);
  }
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const nonRedirectRoute = ["/login"];

export const getObjLocation = (id, objString) => {
  return API.get(`${objString}-location/${id}/`).then(response => {
    const newLocation = response?.data?.location;
    if (newLocation) {
      return `${path.join("/", newLocation)}`; // ensures the path starts with a slash
    }
  });
};

const AutoLogin = lazy(() => import("./components/Auth/AutoLogin/AutoLogin"));
const Login = lazy(() => import("./components/Auth/Login/Login"));

const listofPages = ["/login", "/auto"];

const externalPagesRedirectMap = {
  //the point of this is to force the app to actually load the url rather than using internal routing - and this is where the webserver should take over.
  "/recover/": "/password_reset/"
};

const locationPathLookups = {
  "/get-aw-location": "aw",
  "/get-accident-field-comment-location": "accident-field-comment",
  "/get-riddor-field-comment-location": "riddor-field-comment",
  "/get-near-miss-field-comment-location": "near-miss-field-comment",
  "/get-accident-app-location": "accident-app",
  "/get-near-miss-app-location": "near-miss-app",
  "/get-matter-for-discussion-log-app-location":
    "matter-for-discussion-log-app",
  "/get-h-a-s-check-supporting-doc-app-location":
    "h-a-s-check-supporting-doc-app"
};

const Routes = ({ location }) => {
  const contractPath = "/contract/";
  const portfolioPath = "/portfolio/";

  // NB We don't use the portfolios or sites selector themselves here as we just
  // need specific info and without making equality checks, just including them
  // would set up a loop.
  const hasNoSites = useSelector(hasNoSitesSelector);
  const singlePortfolioPath = useSelector(singlePortfolioPathSelector);
  const singleSiteFirstContractPath = useSelector(
    singleSiteFirstContractPathSelector
  );
  const hasNoPortfolios = useSelector(hasNoPortfoliosSelector);
  const dispatch = useCallback(useDispatch(), []);
  const portfoliosPath = "/portfolios";
  const sitesPath = "/sites";
  const HASpath = "/h-a-s";
  const hashFragment = window.location.hash;

  const isHASPage =
    location.pathname == HASpath || location.pathname == HASpath + "/";

  const defaultIndexPage = useSelector(defaultIndexPageSelector);

  const HASAdminAccess =
    store.getState().profile?.data?.h_a_s_admin_member || false;

  // NB HASAdminAccess is the same info retreived in healthAndSafetyAdminAccessSelector but
  // if we attempt to get any sort of profile related info with a useSelector in the main body
  // of this component - login is broken because no Auth token is set.  Bizzare - would be good to get to the bottom of
  // that some time

  useEffect(() => {
    const ready =
      hasNoPortfolios !== undefined &&
      hasNoSites !== undefined &&
      HASAdminAccess !== undefined;
    if (ready) {
      // this is important because it seems the dispatches don't always take effect
      // in the order in which they are called, so for example the effect of a clause where
      // hasNoPortfolios is undefined, could take effect after a later dispatch where it is defined.
      // also this lowers the number of times dispatches are called
      if (!HASAdminAccess && singleSiteFirstContractPath && hasNoPortfolios) {
        defaultIndexPage !== singleSiteFirstContractPath &&
          dispatch(
            changeSetting("defaultIndexPage", singleSiteFirstContractPath)
          );
      } else if (!HASAdminAccess && singlePortfolioPath && hasNoSites) {
        defaultIndexPage !== singlePortfolioPath &&
          dispatch(changeSetting("defaultIndexPage", singlePortfolioPath));
      } else if (hasNoPortfolios && hasNoSites && HASAdminAccess) {
        defaultIndexPage !== HASpath &&
          dispatch(changeSetting("defaultIndexPage", HASpath));
      } else if (hasNoSites && !hasNoPortfolios) {
        defaultIndexPage !== portfoliosPath &&
          dispatch(changeSetting("defaultIndexPage", portfoliosPath));
      } else if (hasNoPortfolios && !hasNoSites) {
        defaultIndexPage !== sitesPath &&
          dispatch(changeSetting("defaultIndexPage", sitesPath));
      } else if (location.pathname == "/portfolios") {
        defaultIndexPage !== portfoliosPath &&
          dispatch(changeSetting("defaultIndexPage", portfoliosPath));
      } else if (location.pathname == "/sites") {
        defaultIndexPage !== sitesPath &&
          dispatch(changeSetting("defaultIndexPage", sitesPath));
      } else if (location.pathname == "/") {
        dispatch(changeSetting("defaultIndexPage", sitesPath));
      }
    }
  }, [
    location.pathname,
    hasNoPortfolios,
    hasNoPortfolios,
    hasNoSites,
    singleSiteFirstContractPath,
    singlePortfolioPath,
    hashFragment,
    defaultIndexPage,
    HASAdminAccess
  ]);

  const isContractPage = () => {
    const contractPage = location.pathname.indexOf(contractPath) !== -1;
    return contractPage;
  };

  const isPortfolioPage = () => {
    const portfolioPage = location.pathname.indexOf(portfolioPath) !== -1;
    return portfolioPage;
  };

  // deciding which aw path to route to when receiving an external link
  const locationPathLookupObjString = locationPathLookups[location.pathname];
  if (locationPathLookupObjString && hashFragment) {
    const objId = hashFragment.replace("#", "");
    getObjLocation(objId, locationPathLookupObjString).then(location => {
      if (location) {
        history.push(location);
      }
    });
  }

  // console.log("defaultIndexPage: ", defaultIndexPage);

  useEffect(() => {
    dispatch(setRoute(location));
    // HT clearContractInFocus on leaving contract on once you have 'normalised' contract data
    // (at present the corresponding reducer doesn't do very much - you'll want to clear out the state properly)
    // !isContractPage() && dispatch(clearContractInFocus());
    window.scrollTo(0, 0);
    document.querySelector("#app").scrollTop = 0;
  }, [location, dispatch]);

  if (Object.keys(externalPagesRedirectMap).indexOf(location.pathname) > -1) {
    window.location = externalPagesRedirectMap[location.pathname];
  }

  if (listofPages.indexOf(location.pathname) > -1) {
    dispatch(resetState());
    dispatch(setRoute(location));
    return (
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route path="/login" component={waitFor(Login)} />
            <Route path="/auto" component={waitFor(AutoLogin)} />
          </Switch>
        </Suspense>
      </BasePage>
    );
  }

  return (
    <BaseHorizontal useRef={defaultIndexPage}>
      <>
        <Route
          exact
          path="/"
          render={() =>
            defaultIndexPage ? <Redirect to={defaultIndexPage} /> : <></>
          }
        />
        {isHASPage ? (
          <Route path="/h-a-s" component={waitFor(HASCentre)} />
        ) : isContractPage() ? (
          <Route path="/contract/:contract_ref" component={ContractLayout} />
        ) : isPortfolioPage() ? (
          <Route path="/portfolio/:portfolio_id" component={PortfolioLayout} />
        ) : (
          <PrivateRoute path="/" component={IndexPage} />
        )}
      </>
    </BaseHorizontal>
  );
};

export default withRouter(Routes);
