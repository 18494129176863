import { HeaderTypes } from '../actions/Layout.types'
import { HEADER_SEARCH } from '../actions/Layout.actions'
import { LayoutState } from '../interfaces/LayoutState.interface'

const initialState: LayoutState = {
  header: {
    search: ''
  }
}

const LayoutReducer = (
  state = initialState,
  action: HeaderTypes
): LayoutState => {
  switch (action.type) {
    case HEADER_SEARCH.reducer:
      return {
        header: {
          ...state.header,
          search: action.payload.search
        }
      }
    default:
      return state
  }
}

export default LayoutReducer;