import UPDATE_PERSONAL_SETTINGS, {
    GET_PERSONAL_SETTINGS, CANCEL_PERSONAL_SETTINGS, SET_PERSONAL_SETTINGS_ENTITY
} from "../Actions/PersonalSettings.actions";

const initialState = {
    data: undefined,
    meta: undefined
};

interface State {
    data: any;
    meta: any;
}

const reducer = (state: State = initialState, action: any) => {
    switch (action.type) {
        case GET_PERSONAL_SETTINGS.success:
            return {
                ...state,
                showPersonalSettings: action.showPersonalSettings,
                ...action.personalSettings,
                data: {
                    ...action.personalSettings.data,
                    password: ''
                }
            }

        case CANCEL_PERSONAL_SETTINGS.start:
            return {
                ...state,
                showPersonalSettings: false
            }

        case UPDATE_PERSONAL_SETTINGS.success:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.personalSettings.data,
                },
                showPersonalSettings: false
            }

        case UPDATE_PERSONAL_SETTINGS.reducer:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.personalSettings,
                },
                showPersonalSettings: false
            }

        case SET_PERSONAL_SETTINGS_ENTITY.start:
            return {
                ...state,
                data: { ...action.personalSettings },
            }

        default:
            return state;
    }
}

export default reducer;