export const AWPendingText = "pending..."
export const AWDeclinedText = "declined"
export const AWQuoteStatusText = "quote"
export const AWDocumentListColumns = [
    {
        dataField: 'aw_document_type',
        caption: 'Document Type',
        width: 200
    },
    {
        dataField: 'description',
        caption: 'Description'
    },
    {
        dataField: 'fp_file_id',
        caption: 'Focal Point Id',
        visible: false,

    },
    {
        dataField: 'internal_access_only',
        width: 120,
        caption: 'Internal'
    },
    // {
    //     dataField: 'mime_type',
    //     width: 150,
    //     caption: 'File Type'
    // }
];

export const AWPOListColumns = [
    {
        dataField: 'mime_type',
        width: 150,
        caption: 'File Type'
    }
];