import { ofType, StateObservable } from "redux-observable";
import { from, of } from "rxjs";
import { switchMap, catchError, map, filter, delay, tap } from "rxjs/operators";

// Own
import { CONTRACT_GET_MONTHLY_MAINTENANCE_REPORTS, getMonthlyMaintenanceReportsSuccess, getMonthlyMaintenanceReportsErrors } from "../Actions/contractDocuments.actions";
import { getContractFromState } from './contractInFocusEpic.helper';
import { monthlyMaintenanceReportsApi } from 'services/API/common/contractAPIs';

import { getListData, getListMeta, arrayToDict, unWrapDataAndMeta, unWrapDataAndMetaAndPUTMeta } from 'services/API/API.helper';
import { NOTIFICATION_ERROR } from 'components/Notification/Constants/constants';
import { State } from "store/interfaces/State";

import * as contractsActionsInterface from "../interfaces/contractInFocusActions.interfaces";
import * as fromNotificationActions from 'components/Notification/Actions/Notification.actions';
import * as contractAPIs from "services/API/common/contractAPIs";
import * as contractPeriodAPIs from "services/API/common/contractPeriodAPIs";
import * as fromContractsInFocus from "../Actions/contractInFocus.actions";
import * as fromVisibilityActions from "../Actions/contractVisibilitySettings.actions";

export const fetchMaintenanceReportDocumentsEpic = (action$: any) => action$.pipe(
  ofType(CONTRACT_GET_MONTHLY_MAINTENANCE_REPORTS.start),
  switchMap(({ payload }) => {
    const { contractRef } = payload;
    return monthlyMaintenanceReportsApi({ contractId: contractRef }).pipe(
      //return contractSnapshotDocumentsApi({contractId: contractRef}).pipe(
      map((response: any) => getMonthlyMaintenanceReportsSuccess(response)),
      catchError((error) => of(getMonthlyMaintenanceReportsErrors(error)))
    )
  })
);

const fetchContractPeriodsEpic = (action$: any, state$: StateObservable<State>) => action$.pipe(
  ofType(fromContractsInFocus.CONTRACT_IN_FOCUS_FETCH_CONTRACT_PERIODS.start),
  filter(({ payload }) => payload.contractRef === getContractFromState(state$).contract_ref),
  switchMap(({ payload }) =>
    contractAPIs.contractPeriodsAPI(payload.contractRef).pipe(
      map((response) => ({
        data: getListData(response),
        meta: getListMeta(response),
      })),
      map((contractPeriods) => fromContractsInFocus.fetchContractPeriodsSuccess(contractPeriods)),
    )));

const fetchClientStaticOpsDataVisibilitySettingsEpic = (action$: any, state$: StateObservable<State>) => action$.pipe(
  ofType(fromVisibilityActions.FETCH_FULL_CLIENT_STATIC_OPSDATA_VISIBILITY_SETTINGS_CONFIG.start),
  switchMap(({ payload }: { payload: contractAPIs.ContractOrPortfolio }) => {
    return contractAPIs.maintenanceReportConfigurationAPI(payload).pipe(
      //unWrapDataAndMeta(),
      unWrapDataAndMetaAndPUTMeta(),
      map(({ data, putMeta, permissions }) => fromVisibilityActions.fetchClientStaticOpsDataVisibilitySettingsSuccess({ data: data[0], meta: putMeta, permissions })),
    )
  })
);

const setSelectedContractPeriodEpic = (action$: any) => action$.pipe(
  ofType(fromContractsInFocus.SET_SELECTED_CONTRACT_PERIOD.start),
  map(({ payload }: any) => fromContractsInFocus.fetchContractSnapshots({ contractPeriod: payload })),
  catchError((error) => of(fromNotificationActions.addNotification({ message: error, type: NOTIFICATION_ERROR })))
);

const fetchSnapshotHistoryEpic = (action$: any, state$: StateObservable<State>) => action$.pipe(
  ofType(fromContractsInFocus.FETCH_CONTRACT_SNAPSHOTS.start),
  filter(({ payload }) => !!payload.contractPeriod), //HT NOTE removed && state$.value.profile?.data?.admin_access on filter - this should be accessible to all contract viewers
  switchMap(({ payload }: { payload: contractsActionsInterface.FetchSnapshotAction }) => {
    return contractPeriodAPIs.contractPeriodFrozenForListApi(payload.contractPeriod.id).pipe(
      map((response) => {
        return fromContractsInFocus.fetchContractSnapshotSuccess({
          snapshotHistory: { ...response, data: arrayToDict(response.data) },
          focusedSnapshot: payload.focusedSnapshot?.id,
          fetchSnapshotCallback: payload.fetchSnapshotCallback
        });
      })
    )
  }),
  catchError((error) => of(fromNotificationActions.addNotification({ message: error, type: NOTIFICATION_ERROR })))
);

// Once the fetch snapshot history fetch is complete successfully, call this result
const fetchSnapshotHistorySuccessEpic = (action$: any, state$: StateObservable<State>) => action$.pipe(
  ofType(fromContractsInFocus.FETCH_CONTRACT_SNAPSHOTS.success),
  //tap(({ payload }: { payload: contractsActionsInterface.FetchSnapshotSuccessAction }) => { console.log('here is da payload: ', payload) }),
  // NB currently it seems that generally fetchSnapshotCallback is not defined...
  filter(({ payload }) => !!payload.fetchSnapshotCallback),
  // delay the action so that the reducer can get ahead and update the state
  delay(250),
  tap(({ payload }: { payload: contractsActionsInterface.FetchSnapshotSuccessAction }) => {
    // exectue the callback action.  Exacmple callback will be navigatge the snapshot
    payload.fetchSnapshotCallback && payload.fetchSnapshotCallback();
  })
);

export default [
  fetchMaintenanceReportDocumentsEpic,
  fetchContractPeriodsEpic,
  fetchSnapshotHistoryEpic,
  setSelectedContractPeriodEpic,
  fetchClientStaticOpsDataVisibilitySettingsEpic,
  fetchSnapshotHistorySuccessEpic
];