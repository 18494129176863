import React, { useState, useEffect } from "react";
import DataGrid, { Column, Editing, Paging, Lookup, RequiredRule } from "devextreme-react/data-grid";

import { getGridProps } from '../../../../helpers/DataGrid/DataGridColumn.helper';
import { getEventEditorPreparing } from "components/ContractInFocus/Logs/Helpers/LogsGrid.helper";
import { NewLogsProps } from "../Logs";
import LogsWrapper from "components/ContractInFocus/Logs/LogsWrapper";
import { plantConditionLogsColumns, plantConditionLogsColumnWidths, portfolioPlantConditionLogsColumnWidths } from "../Models/logsColumns.model";
import { Dictionary } from "components/Common/Interfaces/Entity.interface";
import { reportSubtitles } from "components/ContractInFocus/Models/contractIndex.model";

const getContractEndpoint = (ref?: string | number) => `contracts/${ref}/plant-condition-logs/?format=json`;
const getPortfolioEndpoint = (id?: string | number) => `portfolios/${id}/plant-condition-logs/?format=json`;

const PlantConditionLogs: React.FC<NewLogsProps> = (
    {
        dataSource,
        metadata,
        setContentReady,
        handleEditingStart,
        handleRowUpdated,
        handleOnRowPrepared,
        getColumnPropsExt,
        portfolio,
        contract,
        styles
    }) => {
    const columnWidths = portfolio ? portfolioPlantConditionLogsColumnWidths : plantConditionLogsColumnWidths;
    return (

        <div>
            {dataSource && (
                <DataGrid
                    className="no-print"
                    dataSource={dataSource}
                    {...getGridProps(metadata.activeMeta)}
                    onEditorPreparing={getEventEditorPreparing(metadata)} //{editableTextAreaOnPreparing(metadata.activeMeta)}
                    onEditingStart={handleEditingStart}
                    onRowUpdated={handleRowUpdated}
                    onRowPrepared={handleOnRowPrepared}
                    onContentReady={() => setContentReady(true)}
                    dateSerializationFormat="yyyy-MM-dd"
                    style={styles}
                >
                    <Paging enabled={false} />
                    <Editing
                        mode="cell"
                        allowUpdating={metadata.privileges.PUT}
                        allowDeleting={metadata.privileges.DELETE}
                        allowAdding={metadata.privileges.POST}
                    />
                    {portfolio && <Column
                        {...getColumnPropsExt('contract')}
                        //@ts-ignore
                        width={columnWidths['contract']}
                        caption="Contract"
                    ></Column>}
                    <Column
                        {...getColumnPropsExt('date')}
                        width={columnWidths['date']}
                    >
                        <RequiredRule />
                    </Column>
                    <Column
                        {...getColumnPropsExt('details')}
                        width={columnWidths['details']}
                    >
                        <RequiredRule />
                    </Column>
                    <Column
                        {...getColumnPropsExt('risk')}
                        width={columnWidths['risk']}
                    >
                        <RequiredRule />
                        <Lookup
                            dataSource={metadata.loaded ? metadata.POSTMeta?.risk.choices : []}
                            valueExpr="value"
                            displayExpr="display_name"
                        />
                    </Column>
                    <Column
                        {...getColumnPropsExt('plant_name')}
                        width={columnWidths['plant_name']}
                    >
                        <RequiredRule />
                    </Column>
                    <Column {...getColumnPropsExt('resolved_on')} width={columnWidths['resolved_on']} />
                    <Column {...getColumnPropsExt('resolution')} width={columnWidths['resolution']} />

                </DataGrid>
            )}
        </div>

    );
};

const getWrappedPlantConditionLogs = (contract: any, portfolio: any, sectionNumberMap: Dictionary<number>) => {
    return () => {
        let getEndpoint: any;
        if (contract) {
            getEndpoint = getContractEndpoint;
        } else if (portfolio) {
            getEndpoint = getPortfolioEndpoint;
        }
        return <LogsWrapper
            WrappedLogsGrid={PlantConditionLogs}
            getEndpoint={getEndpoint}
            contract={contract}
            portfolio={portfolio}
            logColumnsDef={plantConditionLogsColumns}
            logsHandle='PlantCondition'
            logsTitle={reportSubtitles.PlantCondition}
            sectionNumberMap={sectionNumberMap}
        />
    }
}

export default getWrappedPlantConditionLogs;
