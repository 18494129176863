import API from "../API/API";
import { Log } from "./Log.interface";

export const logError = async (message: string) => {
  return await logMessage({ body: message, log_type: 'error' });
};

export const logMessage = async (log: Log) => {
  try {
    console.error(log);
    let response = await API.post('/clientside-logs/', log);
    return response.data.data;
  } catch (err) {
    console.log(err);
    return null;
  }
};
