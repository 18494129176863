import React, { useState, useEffect } from "react";

// Own
import withPageContext, { WithPageContextProps } from '../HoC/WithPageContext/WithPageContext';
import withPortfolioPageContext from "components/PortfolioInFocus/HOC/WithPortfolioPageContext/WithPortfolioPageContext";
import MaintenanceTableChart from "./Containers/MaintenanceTableChart/MaintenanceTableChart";
import MaintenanceChart, { SLAMaintenanceChart } from "components/ContractInFocus/Maintenance/Components/MaintenanceChart/MaintenanceChart"
import PPMService from "components/ContractInFocus/Maintenance/Services/MaintenanceService";
import OpenPPMDataGrid from "./Containers/OpenPPMs/OpenPPMs";
import NewPageWrapper from "components/Common/Components/PrintStyles/Print";
import Header from "components/Common/Components/GridHeader/GridHeader.js";
import { getSubTitleNumber } from "components/ContractInFocus/ContractReport/Helpers/contractReportToc.helper";
import { transformDataForStandardTasksChart, transformDataForSLATasksChart } from "components/ContractInFocus/Maintenance/Helper/ChartHelper";

import { ReportTableClass, ReportTableType } from "./Models/PPMReactive.model";
import { ppmRightVisibilityMenu } from "./Models/maintenanceRightVisibilityMenu";
import { reportSectionStructure, reportSubtitles } from "components/ContractInFocus/Models/contractIndex.model";
import { PrintTitle } from "../Components/ContractPrintTitle/ContractPrintTitle";
import { Dictionary } from "components/Common/Interfaces/Entity.interface";
import { tocsSelector } from "components/ContractInFocus/Selectors/visibility.selectors";
import { useSelector } from "react-redux";

interface PPMsReportProps extends WithPageContextProps { }

const PPMsReport = ({ visibilitySettings, animate = true, sectionNumberMap, contract, portfolio }: PPMsReportProps) => {
  const hasPPMTotal = () => visibilitySettings.show_ppm_total_graph || visibilitySettings.show_ppm_total_table
  const hasPPMSLA = () => visibilitySettings.show_ppm_sla_graph || visibilitySettings.show_ppm_sla_table
  const hasPPMStatutory = () => visibilitySettings.show_ppm_statutory_table || visibilitySettings.show_ppm_statutory_graph
  const hasPPMFabric = () => visibilitySettings.show_ppm_fabric_table || visibilitySettings.show_ppm_fabric_graph
  const hasPPMOpen = () => visibilitySettings.show_ppm_open_table
  const reportSection = reportSectionStructure.PPM;
  const PrintHeading = () => <PrintTitle sectionNumberMap={sectionNumberMap} reportSection={reportSection} />;
  const tocs = useSelector(tocsSelector);

  const [subTitleIndexes, setSubTitleIndexes] = useState<Dictionary<string | undefined>>({});
  const allPPMsTitle = reportSubtitles.AllPPM;//`${ReportTableType.total.caption} ${ReportTableClass.PPM.caption}`;
  const SLAPPMsTitle = reportSubtitles.SLA;
  const statutoryPPMSTitle = reportSubtitles.StatutoryPPM;//`${ReportTableType.statutory.caption} ${ReportTableClass.PPM.caption}`;
  const fabricPPMTitle = reportSubtitles.FabricPPM;//`${ReportTableType.fabric.caption} ${ReportTableClass.PPM.caption}`;
  const openPPMTitle = reportSubtitles.OpenPPM;
  let getBasicMaintenanceData = PPMService.getBasicMaintenanceData;

  useEffect(() => {
    const indexLookups = [allPPMsTitle, SLAPPMsTitle, statutoryPPMSTitle, fabricPPMTitle, openPPMTitle];
    const newSubTitleIndexes: Dictionary<string | undefined> = {};//
    indexLookups.map((l: string) => {
      newSubTitleIndexes[l] = getSubTitleNumber(l, tocs.flatTocsLookup);
    })
    setSubTitleIndexes(newSubTitleIndexes);

  }, [tocs, allPPMsTitle, SLAPPMsTitle, statutoryPPMSTitle, fabricPPMTitle, openPPMTitle])

  return (
    <div id="report">
      {getBasicMaintenanceData &&
        <>
          {hasPPMTotal() &&
            <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
              <PrintHeading />
              <Header
                title={`${subTitleIndexes[allPPMsTitle]} ${allPPMsTitle}`}
                className="no-screen"
              />
              <MaintenanceTableChart
                contract={contract}
                portfolio={portfolio}
                className="ppm-total"
                category={ReportTableClass.PPM}
                getData={getBasicMaintenanceData}
                Chart={MaintenanceChart}
                transformForChart={transformDataForStandardTasksChart}
                subdivision={ReportTableType.total}
                animate={animate}
                showChart={visibilitySettings.show_ppm_total_graph}
                showTable={visibilitySettings.show_ppm_total_table}
              />
            </div>
          }
          {hasPPMSLA() &&
            <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
              <PrintHeading />
              <Header
                title={`${subTitleIndexes[SLAPPMsTitle]} ${SLAPPMsTitle}`}
                className="no-screen"
              />
              <MaintenanceTableChart
                contract={contract}
                portfolio={portfolio}
                className="ppm-total-slas"
                category={ReportTableClass.PPM_SLA}
                getData={PPMService.getSLAMaintenanceData}
                Chart={SLAMaintenanceChart}
                transformForChart={transformDataForSLATasksChart}
                subdivision={ReportTableType.total}
                animate={animate}
                showChart={visibilitySettings.show_ppm_sla_graph}
                showTable={visibilitySettings.show_ppm_sla_table}
              />
            </div>
          }
          {hasPPMStatutory() &&
            <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
              <PrintHeading />
              <Header
                title={`${subTitleIndexes[statutoryPPMSTitle]} ${statutoryPPMSTitle}`}
                className="no-screen"
              />
              <MaintenanceTableChart
                contract={contract}
                portfolio={portfolio}
                className="ppm-statutory"
                category={ReportTableClass.PPM}
                getData={getBasicMaintenanceData}
                Chart={MaintenanceChart}
                transformForChart={transformDataForStandardTasksChart}
                animate={false}
                subdivision={ReportTableType.statutory}
                showTable={visibilitySettings.show_ppm_statutory_table}
                showChart={visibilitySettings.show_ppm_statutory_graph}
              />
            </div>
          }
          {hasPPMFabric() &&
            <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
              <PrintHeading />
              <Header
                title={`${subTitleIndexes[fabricPPMTitle]} ${fabricPPMTitle}`}
                className="no-screen"
              />
              <MaintenanceTableChart
                contract={contract}
                portfolio={portfolio}
                className="ppm-fabric"
                category={ReportTableClass.PPM}
                getData={getBasicMaintenanceData}
                Chart={MaintenanceChart}
                transformForChart={transformDataForStandardTasksChart}
                animate={false}
                subdivision={ReportTableType.fabric}
                showTable={visibilitySettings.show_ppm_fabric_table}
                showChart={visibilitySettings.show_ppm_fabric_graph}
              />
            </div>
          }
          {hasPPMOpen() &&
            <NewPageWrapper breakAfter={true}>
              <PrintHeading />
              <Header
                title={`${subTitleIndexes[openPPMTitle]} ${openPPMTitle}`}
                className="no-screen"
              />
              <OpenPPMDataGrid
                title={openPPMTitle}
                contract={contract}
                portfolio={portfolio}
              />
              <PrintHeading />
            </NewPageWrapper>
          }
        </>
      }
    </div>
  );
};

// PPMsReport.whyDidYouRender = true;

export default withPageContext(PPMsReport, {
  visibilityMenu: ppmRightVisibilityMenu
});

export const portfolioPPMsReport = withPortfolioPageContext(PPMsReport, {
  visibilityMenu: ppmRightVisibilityMenu
})