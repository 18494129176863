import { createSelector } from "reselect";

import {
    dictToArray,
} from "../../../services/API/API.helper";

import { SelectorOrdering, selectObjectsOrderedByFactory, selectObjectValuesFilterByFactory, hydrationSelectorFactory } from "store/Common/Selectors/Common.selectors";
import {
    contractsDataSelector
} from "components/AdminPanel/Contracts/Selectors/Contracts.selectors";

import { PortfolioState } from "components/Portfolios/Reducers/Portfolio.reducers";
import { State } from "store/interfaces/State";
import { HydratedPortfolio, HydratedPortfolioContractLink, PortfolioContractLink, PortfolioAccessLink } from "components/Portfolios/Interfaces/Portfolios.interface";
import { HASTaskContractLink } from "components/AdminPanel/HAS/Interfaces/HASTaskInterfaces";

export const portfoliosMetaState = (state: State) => state.portfolios.meta;

export const portfoliosSelector = (state: State): PortfolioState => state.portfolios;
export const portfoliosDataSelector = (state: State) => state.portfolios.data;
export const portfoliosLengthSelector = (state: State): number => Object.keys(state.portfolios?.data).length;

export const portfolioByIdSelectorFactory = (id: number) => createSelector(
    portfoliosSelector,
    (portfolios: PortfolioState) => portfolios.data[id]
)

const undefinedPortfolio = {
    data: undefined,
    meta: undefined,

}

export const portfolioHydratedFactory = (id: number) => {
    const thisPortfolioSelector = portfolioByIdSelectorFactory(id);
    const portfolioHydratedFactoryConfig = {
        addToSelectorConfig: {
            selector: thisPortfolioSelector,
            path: 'contracts'
        },
        getFromSelectorConfig: {
            selector: contractsDataSelector
        }
    }
    const contractHydratedPortfolioSelector = hydrationSelectorFactory(portfolioHydratedFactoryConfig);
    return createSelector(
        contractHydratedPortfolioSelector,
        portfoliosSelector,
        (portfolio: HydratedPortfolio, portfolios: PortfolioState) => (
            {
                data: portfolio,
                meta: { ...portfolios.meta, ...portfolios.recordmeta[id] }
            }
        )
    )
}

export const portfolioContractLinkHydratedFactory = (objs: PortfolioContractLink[]) => {
    // the config has to be defined inside this function as it uses objs.  It's a fairly light weight op.
    // However, later perhaps we can memoize to get referential stability on the 'selector' function here if
    // we feel it warrants it (but make sure to appropriately handle cache size and set the resolver key rather than defau)
    const portfolioContractLinkHydratedFactoryConfig = {
        addToSelectorConfig: {
            selector: () => objs,
            path: 'contract'
        },
        getFromSelectorConfig: {
            selector: contractsDataSelector
        }
    }
    return hydrationSelectorFactory(portfolioContractLinkHydratedFactoryConfig);
}

export const portfolioAccessLinkHydratedFactory = portfolioContractLinkHydratedFactory;

export const HASTaskContractLinkHydratedFactory = (objs: HASTaskContractLink[]) => {
    // the config has to be defined inside this function as it uses objs.  It's a fairly light weight op.
    // However, later perhaps we can memoize to get referential stability on the 'selector' function here if
    // we feel it warrants it (but make sure to appropriately handle cache size and set the resolver key rather than defau)
    const HASTaskContractLinkHydratedFactoryConfig = {
        addToSelectorConfig: {
            selector: () => objs,
            path: 'contract'
        },
        getFromSelectorConfig: {
            selector: contractsDataSelector
        }
    }
    return hydrationSelectorFactory(HASTaskContractLinkHydratedFactoryConfig);
}

export const portfoliosDataArraySelector = createSelector(
    portfoliosSelector,
    portfolios => dictToArray(portfolios.data)
);

export const singlePortfolioPathSelector = createSelector(
    portfoliosDataArraySelector,
    (portfolios) => {
        let path = undefined;
        if (portfolios.length === 1) {
            path = `/portfolio/${portfolios[0].id}/`;
        }
        return path;
    }
);

export const adminListPortfoliosSelector = createSelector(
    portfoliosSelector, (portfolios: any) => {
        return {
            data: dictToArray(portfolios.data),
            dict: portfolios.data,
            meta: portfolios.meta.POST,
            recordmeta: portfolios.recordmeta,
            permissions: portfolios.permissions
        }
    });

const portfolioDataWithContractsSelectorFactoryConfig = {
    addToSelectorConfig: {
        selector: portfoliosDataArraySelector,
        path: 'contracts'
    },
    getFromSelectorConfig: {
        selector: contractsDataSelector
    }
}

export const portfolioDataWithContractsSelectorFactory = () => {
    const hydrated = hydrationSelectorFactory(portfolioDataWithContractsSelectorFactoryConfig);
    return hydrated
}

export const portfolioDataWithContractsSelector = portfolioDataWithContractsSelectorFactory();

export const selectFavouritePortfolios = selectObjectValuesFilterByFactory(portfolioDataWithContractsSelector, 'favourite', true);

// Sortings
const portfoliosDefaultSorting: SelectorOrdering = { params: ['favourite', 'name'], direction: ['desc', 'asc'] }
const favouritePortfoliosDefaultSorting: SelectorOrdering = { params: ['favourite'], direction: ['desc', 'asc'] }

export const hasNoPortfoliosSelector = (state: State) => state.portfolios.hasNoPortfolio;
export const selectPortfoliosDataArray = selectObjectsOrderedByFactory({ selector: portfolioDataWithContractsSelector, ...portfoliosDefaultSorting });
export const selectFavouritePortfoliosDataArray = selectObjectsOrderedByFactory({ selector: selectFavouritePortfolios, ...favouritePortfoliosDefaultSorting });
