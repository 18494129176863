import { createSelector } from 'reselect';
import { dictToArray, existingRecordOnly } from '../../../../services/API/API.helper';
import { State } from '../../../../store/interfaces/State';
import { ContractInterface, ContractsForRolesListI, ContractPersonTypes, PersonWithAccessI, ContractPeopleWithAccessState } from '../Interfaces/Contract.interface';
import { ContractsState } from '../Reducers/Contracts.reducer';

export const contractSelector = (state: State): ContractsState => state.contracts;
export const AllPeopleWithAccessSelector = (state: State) => state.contractPeopleWithAccess;
export const maintenanceReportDistributionListSelector = (state: State) => state.monthlyMaintenanceReportDistribution;
export const contractPeopleWithAccessSelectorFactory = (id: any) => createSelector(
  AllPeopleWithAccessSelector, (peopleWithAccess) => ({
    data: peopleWithAccess[id]?.data || [],
    metaWrapper: peopleWithAccess.metaWrapper
  })
);

export const contractsDataSelector = (state: State) => state.contracts.data;

export const contractDistributionListSelectorFactory = (id: any) => createSelector(
  maintenanceReportDistributionListSelector, (maintenanceReportDistributionList) => ({
    data: maintenanceReportDistributionList[id]?.data || [],
    metaWrapper: maintenanceReportDistributionList.metaWrapper
  })
);

const getPersonRoleContracts = (contracts: ContractInterface[], role: string): ContractsForRolesListI[] => {
  return contracts.map((contract: ContractInterface) => ({
    id: contract.id,
    role: role,
    contract_ref: contract.contract_ref,
    contract_number: contract.contract_number,
    contract_with_name: contract.contract_with_name,
    site_name: contract.site_name
  })
  )
};

export const personContractRolesSelectorFactory = (personId: number) => createSelector(
  contractSelector, (contracts: ContractsState) => {
    const data = Object.values(contracts.data)
    const aMC = data.filter((contract: ContractInterface) => contract.account_manager?.id == personId)
    const sMC = data.filter((contract: ContractInterface) => contract.site_manager?.id == personId)
    const bUH = data.filter((contract: ContractInterface) => contract.business_unit_head?.id == personId)
    const cCC = data.filter((contract: ContractInterface) => contract.client_contact?.id == personId)
    const tBUH = getPersonRoleContracts(bUH, "Business Unit Head");
    const tAMC = getPersonRoleContracts(aMC, "Account Manager");
    const tSMC = getPersonRoleContracts(sMC, "Site Manager");
    const tCCC = getPersonRoleContracts(cCC, "Client Contact");
    return [...tAMC, ...tSMC, ...tCCC, ...tBUH]
  }
);

export const allContractsSelector = createSelector(
  contractSelector, (contract: ContractsState) =>
  ({ data: existingRecordOnly(dictToArray(contract.data)), meta: contract.meta.POST })
);

export const adminListContractsSelector = createSelector(
  contractSelector, (contracts: any) => {
    return {
      data: dictToArray(contracts.data),
      dict: contracts.data,
      meta: contracts.meta.POST,
      recordmeta: contracts.recordmeta,
      permissions: contracts.permissions
    }
  });

export const contractLookupSelector = createSelector(
  contractSelector, (contract: ContractsState) =>
  ([...dictToArray(contract.lookup)])
);

export const contractByIdSelector = (id: any) => createSelector(
  //contractSelector, (contracts: ContractsState) => ({ data: contracts.data[id], meta: contracts.recordmeta[id] })
  contractSelector, (contracts: ContractsState) => ({ data: contracts.data[id], meta: contracts.meta.PUT })
);

export const contractSublistItemsSelectorFactory = (sublistKey: string, contractId: number, branch = 'data'): any =>
  createSelector(
    contractSelector,
    (contract: any) => contract[branch][contractId][sublistKey]
  );

export const contractSublistSelectorFactory = (sublistKey: string, contractId: number, recordId: number): any =>
  createSelector(
    contractSelector, (contract: ContractsState) => contract.data[contractId][sublistKey].find((record: any) => record.id === recordId)
  );

export const contractPeopleIds = (peopleType: ContractPersonTypes, id: any) => (state: State) => state.contracts.data[id] ? state.contracts.data[id][peopleType] : [];