import { ofType, StateObservable } from "redux-observable";
import { switchMap, catchError, map } from "rxjs/operators";
import { of } from "rxjs";

// Own
import { getContractFromState } from './contractInFocusEpic.helper';
import { APIR } from "services/API/API";
import { State } from "store/interfaces/State";
import { getData, getMeta } from "services/API/API.helper";
import * as fromVisibilityOpsDataSettings from "components/ContractInFocus/Actions/contractVisibilitySettings.actions";
import { MainInFocusVisibilitySettings, NotificationContractSettings } from "components/ContractInFocus/interfaces/ContractInFocus.interfaces"
import { ContractIdOrPortfolioId } from "services/API/common/contractAPIs";

export const getPersonalContractVisibilitySettingsRoute = (contractRef: string, id?: string | number) => `/contracts/${contractRef}/personal-contract-settings/${id}/`;

export const getPersonalPortfolioVisibilitySettingsRoute = (portfolioId: number, id?: string | number) => `/portfolios/${portfolioId}/personal-portfolio-settings/${id}/`;

interface GetPersonalContractVisibilitySettingsRouteProps extends ContractIdOrPortfolioId {
  id?: string | number;
}

export const getPersonalVisibilitySettingsRoute = (props: GetPersonalContractVisibilitySettingsRouteProps) => {
  let route = 'personal-visibility/never/';
  if (props.portfolioId) {
    route = `/portfolios/${props.portfolioId}/personal-portfolio-settings/${props.id}/`;
  }
  if (props.contractId) {
    route = `/contracts/${props.contractRef}/personal-contract-settings/${props.id}/`;
  }
  return route;
}

export const togglePersonalInFocusSettingsEpic = (action$: any, store$: StateObservable<State>) => action$.pipe(
  ofType(fromVisibilityOpsDataSettings.TOGGLE_PERSONAL_IN_FOCUS_SETTINGS.start),
  switchMap(({ payload }) => {
    // const contract_ref = payload.obj?.contract_ref;
    // const portfolioId = payload.obj?.id;
    const payloadId = payload.contract?.id || payload.portfolio?.id;
    const { id } = store$.value.mainInFocusVisibilityObject.basicPersonalVisibilitySettings;
    let route = getPersonalVisibilitySettingsRoute({
      contractId: payload.obj?.contracts ? undefined : payload.obj?.id,
      contractRef: payload.obj?.contract_ref,
      portfolioId: payload.obj?.contracts ? payload.obj?.id : undefined,
      id: id
    });
    // if (contract_ref) {
    //   route = getPersonalContractVisibilitySettingsRoute(contract_ref, id);
    // } else {
    //   route = getPersonalPortfolioVisibilitySettingsRoute(portfolioId, id);
    // }
    const settings = { [payload.updateKey as keyof MainInFocusVisibilitySettings]: !store$.value.mainInFocusVisibilityObject.basicPersonalVisibilitySettings[payload.updateKey as keyof MainInFocusVisibilitySettings] };
    return APIR.patch(route, settings).pipe(
      map((response) => ({
        data: getData(response),
        meta: getMeta(response)
      })),
      map(({ data, meta }) => {
        return fromVisibilityOpsDataSettings.setPersonalInFocusSettingsSuccess({ id: payloadId, personalVisibilitySettings: data })
      }
      ),
      catchError(error => {
        return of(fromVisibilityOpsDataSettings.setPersonalInFocusSettingsErrors(error));
      })
    )
  })
);

const getContractClientVisibilityRoute = (contractRef: string, id: any) => `/contracts/${contractRef}/monthly-maintenance-report-configuration/${id}/`;
const getPortfolioClientVisibilityRoute = (portfolioId: string, id: any) => `/portfolios/${portfolioId}/monthly-maintenance-report-configuration/${id}/`;

export const setContractSettingsEpic = (action$: any, store$: StateObservable<State>) => action$.pipe(
  ofType(
    fromVisibilityOpsDataSettings.SET_CLIENT_STATIC_OPSDATA_VISIBILITY_SETTINGS.start,
  ),
  switchMap(({ payload }) => {
    let route = 'client-visibility/never/';
    // const contract_ref = payload.contract?.contract_ref;
    // const portfolioId = payload.portfolio?.id;
    // note that for this to work, there always needs to be an obvious distinction between a portfolio and a contract.  At the moment this distinction
    // is that the contract has the contract_ref attribute.
    const contract_ref = payload.obj?.contract_ref;
    const contracts = payload.obj?.contracts; // this should only exist for portfolios
    const portfolioId = payload.obj?.id;
    //const payloadId = payload.contract?.id || payload.portfolio?.id;
    const { id } = store$.value.mainInFocusVisibilityObject.basicClientVisibilitySettings.data;
    const settings = { [payload.updateKey as keyof MainInFocusVisibilitySettings]: !store$.value.mainInFocusVisibilityObject.basicClientVisibilitySettings.data[payload.updateKey as keyof MainInFocusVisibilitySettings] };
    if (contract_ref) {
      route = getContractClientVisibilityRoute(contract_ref, id);
    } else if (contracts && portfolioId) {
      route = getPortfolioClientVisibilityRoute(portfolioId, id);
    }
    return APIR.patch(route, settings).pipe(
      map((response) => ({
        data: getData(response)
      })),
      map(({ data }) => {
        return fromVisibilityOpsDataSettings.setClientStaticOpsDataVisibilitySettingsSuccess(data);
      }),
      catchError(error => of(fromVisibilityOpsDataSettings.setClientStaticOpsDataVisibilitySettingsErrors(error)))
    )
  })
);

export default [
  togglePersonalInFocusSettingsEpic,
  setContractSettingsEpic
];