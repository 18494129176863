import React from "react";
import PropTypes from "prop-types";

// Styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { black } from "../../../../../styles/app/common/variables.scss";
import { Wrapper, FlexCenterWrapper } from "./FlagsStyles";
import { RescheduleIcon } from "components/Common/Components/FileTypeIcon/FileTypeIcon";

const Rescheduled = ({ count, color }) => {
  return (
    <Wrapper className="specialist-flag" style={{ color }}>
      <RescheduleIcon fill={color} />
      {/* <FlexCenterWrapper>
        <FontAwesomeIcon icon={faClock} style={{ color, marginRight: "3px" }} />
        <FontAwesomeIcon icon={faArrowRight} style={{ color }} />
      </FlexCenterWrapper> */}
      {count ? <FlexCenterWrapper>{count}</FlexCenterWrapper> : null}
    </Wrapper>
  );
};

Rescheduled.propTypes = {
  count: PropTypes.number
};

export default Rescheduled;
