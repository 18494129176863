import React, { useRef, useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { IconButton } from '@material-ui/core';
import { useHistory } from "react-router-dom";

// own
import HASAccidentDetail from "components/ContractInFocus/HAS/HASAccidentDetail";

import { IHASAccident, IHASNearMiss } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";
import "components/ContractInFocus/Financials/Styles/AdditionalWorks.scss";
import { IncidentFieldType } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";
import { ParseAPIResponseWithPUTMeta } from "services/Interface/Interface";
import { simpleFetchIncidentInFocus } from "components/AdminPanel/HAS/HASIncidentServices";
import HASNearMissDetail from "components/ContractInFocus/HAS/HASNearMissDetail";
import { onResponseError } from "services/API/API.interceptor.js"
import { useAccidentEndpoint } from "components/ContractInFocus/HAS/HASAccidents";
import { useNearMissEndpoint } from "components/ContractInFocus/HAS/HASNearMisses";

interface IncidentDetailModalProps {
    contractRef?: string;
    portfolioId?: string | number;
    portfolioName?: string;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
}

const IncidentDetailModal = ({ contractRef, portfolioId, open, setOpen, portfolioName }: IncidentDetailModalProps) => {

    const hashFragment = window.location.hash || '';
    const [response, setResponse] = useState<ParseAPIResponseWithPUTMeta<(IHASAccident | IHASNearMiss)>>();
    const [recordType, recordId, issue_field, containing_tab, comment_id] = hashFragment.replace('#', '').split('___');
    const initiallySelectedDataField: React.MutableRefObject<string | undefined> = useRef(issue_field);
    const initiallySelectedTab: React.MutableRefObject<string | undefined> = useRef(containing_tab);
    const initiallySelectedComment: React.MutableRefObject<string | undefined> = useRef(comment_id);
    const history = useHistory();
    const getNearMissEndpoint = useNearMissEndpoint({ contractRef, portfolioId });
    const nearMissEndpoint = getNearMissEndpoint();
    const getAccidentEndpoint = useAccidentEndpoint({ contractRef, portfolioId });
    const accidentEndpoint = getAccidentEndpoint();

    useEffect(() => {
        if (recordType === "accident" || recordType === "near_miss") {
            simpleFetchIncidentInFocus({
                contractRef: contractRef,
                portfolioId: portfolioId,
                incidentType: recordType as IncidentFieldType,
                id: recordId
            }).then((response) => {
                setResponse(response);
            }).catch(error => {
                setOpen(false);
                return onResponseError(error)
            })
        } else {
            setOpen(false);
        }
    }, [recordType, recordId, setOpen, contractRef, portfolioId]);

    const handleClose = (): void => {
        // The config below allows you to remove the hash part of the url without reloading the entire page and also to go back to the has with back button
        history.push(`${window.location.pathname}${window.location.search}${window.location.hash}`);
        window.history.replaceState(null, "Comment", `${window.location.pathname}${window.location.search}`);
        setOpen(false);
    }
    const tableLevelSharedSpace = useRef({});
    const isModalReady = (): boolean => !!response;
    let thisData;
    if (recordType === "accident") {
        thisData = response?.data as IHASAccident;
    } else {
        thisData = response?.data as IHASNearMiss;
    }
    return (
        <Modal
            className="fullWidth"
            id="incidentDetailModal"
            centered={true}
            isOpen={isModalReady() && open}
            size={'xl'}
        >
            {
                response ? <>
                    <ModalHeader>
                        <div className="incidentHeaderDetail"></div>
                        <div className="incidentHeaderDetail title">{
                            //@ts-ignore
                            recordType === "accident" ? `Affected person: ${response.data?.ap_name}, Location: ${response.data?.location}` : `Employee name: ${response.data?.employee_name}, Location: ${response.data?.location}`
                        }</div>
                        <div className="incidentHeaderDetail closeButtonWrapper">
                            <IconButton onClick={handleClose} >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        {response && <div className='fullScreenFormWrapper'>
                            {recordType == "accident" ? <HASAccidentDetail
                                data={response.data as IHASAccident}
                                rowKey={response.data?.id}
                                masterViewSharedSpace={tableLevelSharedSpace}
                                permissions={response.permissions}
                                gridDataSource={undefined}
                                tableLevelSharedSpace={tableLevelSharedSpace}
                                setFullScreenForm={undefined}
                                disableDxNavKeys={undefined}
                                initiallySelectedDataField={initiallySelectedDataField}
                                initiallySelectedTab={initiallySelectedTab}
                                initiallySelectedComment={initiallySelectedComment}
                                baseEndpoint={accidentEndpoint}
                            /> : <HASNearMissDetail
                                data={response.data as IHASNearMiss}
                                rowKey={response.data?.id}
                                masterViewSharedSpace={tableLevelSharedSpace}
                                permissions={response.permissions}
                                gridDataSource={undefined}
                                tableLevelSharedSpace={tableLevelSharedSpace}
                                setFullScreenForm={undefined}
                                disableDxNavKeys={undefined}
                                initiallySelectedDataField={initiallySelectedDataField}
                                initiallySelectedTab={initiallySelectedTab}
                                initiallySelectedComment={initiallySelectedComment}
                                baseEndpoint={nearMissEndpoint}
                            />
                            }
                        </div>
                        }
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </> : <div className="page-loader">
                    <em className="fas fa-circle-notch fa-spin fa-2x text-muted"></em>
                </div>}
        </Modal>
    )
}

export default IncidentDetailModal;