import {
    ADD_RECIPIENT_TO_MAINTENANCE_REPORT_DISTRIBUTION_LIST,
    MERGE_MAINTENANCE_REPORT_DISTRIBUTION_LIST,
    REPLACE_MAINTENANCE_REPORT_DISTRIBUTION_LIST,
    REMOVE_RECIPIENT_FROM_DISTRIBUTION_LIST
} from "components/AdminPanel/Contracts/Actions/Contracts.actions";

import { arrayToDict } from "services/API/API.helper";

import { APIPrivileges } from "services/Interface/Interface";
import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";

import { DistributionRecipientI, ContractMaintenanceReportDistributionState } from "components/AdminPanel/Contracts/Interfaces/Contract.interface";

import { isEqual } from "lodash";

const initialState = {
    metaWrapper: {
        meta: {},
        putMeta: {},
    }
}

interface maintenanceReportDistributionAction {
    contractId: number;
    recipients?: DistributionRecipientI[];
    recipientObjId?: string | number;
    meta?: FieldMetaGroup;
    putMeta?: FieldMetaGroup;
    permissions?: APIPrivileges;
    [otherOptions: string]: unknown;
}

function getMappings(existingList: DistributionRecipientI[] | undefined, newList: DistributionRecipientI[] | undefined) {
    const eL = existingList || [];
    const nL = newList || [];
    const existingData = arrayToDict(eL);
    const newData = arrayToDict(nL);
    return [existingData, newData];
}

function addOrUpdateRecords(existingList: DistributionRecipientI[] | undefined, newList: DistributionRecipientI[] | undefined) {
    const [eD, nD] = getMappings(existingList, newList);
    const compoundList = Object.values({ ...eD, ...nD })
    return compoundList;
}

function fetchRecords(existingList: DistributionRecipientI[] | undefined, newList: DistributionRecipientI[] | undefined) {
    const [eL, nL] = getMappings(existingList, newList);
    return Object.values({ ...nL });
}

export const monthlyMaintenanceReportDistributionReducer = (state: ContractMaintenanceReportDistributionState = initialState, action: maintenanceReportDistributionAction) => {
    switch (action.type) {
        case ADD_RECIPIENT_TO_MAINTENANCE_REPORT_DISTRIBUTION_LIST.reducer: {
            const contractId = action.contractId;
            const newSet = fetchRecords(state[contractId]?.data, action.recipients)
            // Right now the rights to manage contract links do not vary by contract relation to the current user, but only by admin status.  
            // Therefore we will store the meta, putMeta and permissions on the 'root' state of this reduceer slice, rather than within each contract.  
            // However, should we ever want to allow the assignment of permissions 
            // to others based on the logged in user's relationship to a specific contract, then obviously we will have to move the permissions inside the contract lookup
            // structure and have the code that checks the permissions look them up on the relevant contract instead..
            const stateMetaWrapper = state.metaWrapper;
            let metaWrapper = undefined;
            const newMetaWrapper = {
                meta: {
                    ...action.meta,
                },
                putMeta: {
                    ...action.putMeta,
                },
                permissions: {
                    ...action.permissions
                }
            }
            if (!isEqual(stateMetaWrapper, newMetaWrapper)) {
                metaWrapper = newMetaWrapper
            } else {
                metaWrapper = stateMetaWrapper
            }
            return {
                ...state,
                [action.contractId]: {
                    data: newSet,
                },
                metaWrapper: metaWrapper
            };
        }
        case MERGE_MAINTENANCE_REPORT_DISTRIBUTION_LIST.reducer: {
            const contractId = action.contractId;
            const newSet = addOrUpdateRecords(state[contractId]?.data, action.recipients)
            return {
                ...state,
                [action.contractId]: {
                    data: newSet,
                }
            };
        }
        case REPLACE_MAINTENANCE_REPORT_DISTRIBUTION_LIST.reducer: {
            const contractId = action.contractId;
            return {
                ...state,
                [action.contractId]: {
                    data: action.recipients,
                }
            };
        }
        case REMOVE_RECIPIENT_FROM_DISTRIBUTION_LIST.reducer: {
            const recipientList = state[action.contractId]?.data || [];
            const newList = recipientList.filter(recipientObj => recipientObj.id.toString() !== action.recipientObjId?.toString())
            return {
                ...state,
                [action.contractId]: {
                    data: newList,
                }
            };
        }

        default:
            return state;
    }
};

export default monthlyMaintenanceReportDistributionReducer;