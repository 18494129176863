import { MainInFocusVisibilitySettingPropsArray, MainInFocusVisibilitySettings } from '../../interfaces/contractInFocus.interfaces'
import { ReportSectionStructure } from 'components/ContractInFocus/models/contractIndex.model'
import { ReportSectionSubTitles } from 'components/ContractInFocus/interfaces/contractInFocus.interfaces'
import { reportSubtitles } from "components/ContractInFocus/Models/contractIndex.model";
import { ReportSection } from 'components/ContractInFocus/interfaces/contractInFocus.interfaces'
import { Dictionary } from "components/Common/Interfaces/Entity.interface";

export interface TOCs {
  pointer: number;
  minorPointer: number;
  toc: string[][];
}

export interface TOCStructures {
  flatTocsLookup: Dictionary<string>;
  flatTocsArray: string[][];
  tocsNested?: {
    name: string;
    sectionNumber: number;
    subSections: {
      name: string;
      subSectionNumber: string;
    }[];
  }[]
}

const getVisibleSubTitlesForSection = (subTitles: ReportSectionSubTitles[], visibilitySettings: MainInFocusVisibilitySettings): ReportSectionSubTitles[] => {
  // console.log('subTitles: ', subTitles);
  const filtered = subTitles.filter(subTitle => isSubTitleVisible(subTitle, visibilitySettings));
  // console.log('visibleSubtitles: ', filtered);
  return filtered
}

// const hasSubTitlesVisible = (subTitles: ReportSectionSubTitles[], visibilitySettings: MainInFocusVisibilitySettings): boolean =>
//   !!getVisibleSubTitlesForSection(subTitles, visibilitySettings).length;

const isSubTitleVisible = (subTitle: ReportSectionSubTitles, visibilitySettings: MainInFocusVisibilitySettings): boolean => {
  // console.log('subTitle: ', subTitle);
  // console.log('visibilitySettings: ', visibilitySettings);
  // if (subTitle.name === "Report Details") {
  //   console.log('visibilitySettings: ', visibilitySettings);
  //   console.log('subtitle visibility: ', subTitle.visible)
  // }
  const visible = !!subTitle.visible.filter(reportElement => {
    // console.log('reportElement: ', reportElement);
    // console.log('visibilitySettings: ', visibilitySettings);
    // console.log('lookup: ', visibilitySettings[reportElement as keyof MainInFocusVisibilitySettings]);
    return visibilitySettings[reportElement as keyof MainInFocusVisibilitySettings] === true
  }).length;
  return visible;
}


const isTitleVisible = (visibleKeys: string[], visibilitySettings: MainInFocusVisibilitySettings): boolean =>
  !!visibleKeys.filter(reportElement => visibilitySettings[reportElement as keyof MainInFocusVisibilitySettings] === true).length

export const adjustSubTitles = (subTitle: string, visibilitySettings: MainInFocusVisibilitySettings) => {
  if (subTitle == reportSubtitles.AllSpecialistVisits && visibilitySettings.specialist_report_by_exception) {
    return subTitle + ' (Exceptions Only)';
  }
  return subTitle;
}

export const getReportTocs = (reportSectionStructure: ReportSectionStructure, visibilitySettings: MainInFocusVisibilitySettings): TOCStructures => {
  let sectionPointer = 0;
  const flatTocsArray = <string[][]>[];
  const flatTocsLookup = <Dictionary<string>>{};
  const tocsNested = Object.keys(reportSectionStructure).map(
    (tocKey: string) => {
      // if (tocKey === "ReportDetails") {
      //   console.log('tocKey: ', tocKey);
      //   console.log('reportSectionStructure: ', reportSectionStructure);
      //   console.log('subTitles: ', reportSectionStructure[tocKey].subTitles);

      // }

      const visibleSubTitles = getVisibleSubTitlesForSection(reportSectionStructure[tocKey].subTitles, visibilitySettings);
      // console.log('visibltSubTitles: ', visibleSubTitles);
      if (!!visibleSubTitles.length || isTitleVisible(reportSectionStructure[tocKey].visible, visibilitySettings)) {

        sectionPointer += 1;
        const sectionName = reportSectionStructure[tocKey].name;
        flatTocsLookup[sectionName] = `${sectionPointer}`;
        flatTocsArray.push([`${sectionPointer}`, sectionName])

        const structuredSection = {
          name: sectionName,
          sectionNumber: sectionPointer,
          subSections: visibleSubTitles.map((sT, ii) => {
            const subSectionNumber = `${sectionPointer}.${ii + 1}`;
            let subTitle = adjustSubTitles(sT.name, visibilitySettings);
            flatTocsLookup[sT.name] = subSectionNumber;
            flatTocsArray.push([`${subSectionNumber}`, subTitle])
            return {
              name: subTitle,
              subSectionNumber: subSectionNumber
            }
          }
          )
        }

        return structuredSection
      }
    }).filter(x => x !== undefined);//the final filter here is to remove the undefined results in the array (i.e. the one's which were didn't meet the visible check)

  // console.log('flatTocsLookup: ', flatTocsLookup);
  // console.log('flatTocsArray: ', flatTocsArray);
  // console.log('tocsNested: ', tocsNested);

  return {
    flatTocsLookup,
    flatTocsArray,
    //@ts-ignore ts seems unable to figure out that filtering out undefined explicitly will leave no undefined items.  A sad state of affairs..
    tocsNested
  }
}

export function getSubTitleNumber(name: string, tocs?: Dictionary<string>) {
  if (tocs) {
    return tocs[name];
  }
  return undefined;
}
