import { useCallback } from "react";

import { getIndexFromPannableData, calcViewFrom, getInitialViewFrom, getThisMonth } from "./usePannable.helper";
import { showColumns } from "components/ContractInFocus/Maintenance/Helper/View.helpers";

function getUpperLimitReached(viewFrom: number, viewLength: number, dataLength: number) {
    return viewLength > dataLength || viewFrom + viewLength >= dataLength;
}

function getLowerLimitReached(viewFrom: number, viewLength: number, dataLength: number) {
    return viewLength > dataLength || viewFrom <= 0;
}

interface UsePannerProps {
    dataLength: number,
    getSetViewFrom: any,
    setPanned: any,
    viewLength: number,
    lowerLimitReachedRef?: any,
    upperLimitReachedRef?: any
}

export const useGetPanners = ({ dataLength, getSetViewFrom, setPanned, viewLength, lowerLimitReachedRef, upperLimitReachedRef }: UsePannerProps) => {
    // null to periodDate gives current month
    // const viewableColumns = useRef(10);
    const [viewFrom, setViewFrom] = getSetViewFrom;

    const upperLimitReached = getUpperLimitReached(viewFrom, viewLength, dataLength);
    const lowerLimitReached = getLowerLimitReached(viewFrom, viewLength, dataLength);
    if (upperLimitReachedRef) {
        upperLimitReachedRef.current = upperLimitReached;
    }
    if (lowerLimitReachedRef) {
        lowerLimitReachedRef.current = lowerLimitReached;
    }

    const onPanLeft = useCallback(() => {
        setPanned(true);
        const newViewFrom = !viewFrom ? 0 : viewFrom - 1
        //console.log('newViewFrom: ', newViewFrom);
        return setViewFrom(newViewFrom);
    }, [setViewFrom, setPanned, viewFrom])

    const onPanRight = useCallback(() => {
        setPanned(true);
        //console.log('dataLength: ', dataLength);
        const newViewFrom = viewFrom + viewLength >= dataLength ? viewFrom : viewFrom + 1;
        //console.log('newViewFrom: ', newViewFrom);
        return setViewFrom(newViewFrom);
    }, [setViewFrom, setPanned, viewFrom, dataLength, viewLength]);

    return { onPanLeft, onPanRight };
}
interface GetGridIndexInterface {
    dataGrid: any, // this should be the current 'instance' of the relevant dataGrid
    panned: boolean,
    dataArray: any[],
    numberOfDataCols?: number,
    offsetTwelthes: number,
    setViewFrom: Function,
    selectedFocusedSnapshot: any,
    viewLength?: number,
    findColBy?: string,
    overrideViewFrom?: number,
}

export function setInitialViewFrom(args: GetGridIndexInterface) {
    const {
        dataGrid,
        panned,
        dataArray,
        offsetTwelthes,
        setViewFrom,
        selectedFocusedSnapshot,
        viewLength,
        findColBy,
        overrideViewFrom
    } = args;
    //const selectedFocusedSnapshot = useSelector(inFocusSelector.contractInFocusSnapshotFocusSelector);
    const thisMonth = getThisMonth(selectedFocusedSnapshot);
    const gridWidth = dataGrid ? dataGrid._$element[0].clientWidth : 1000;
    const currentViewLength = viewLength || showColumns(gridWidth || 1000, selectedFocusedSnapshot);
    if (!panned) {
        const index = getIndexFromPannableData(dataArray, thisMonth, findColBy);
        const viewFrom = (overrideViewFrom || overrideViewFrom === 0) ? overrideViewFrom : getInitialViewFrom(currentViewLength, index, offsetTwelthes, dataArray.length);
        //const initialViewFrom = calcViewFrom(viewableColumns.current, getIndexFromPannableData(dataAsArray, thisMonth), offsetTwelthes);
        setViewFrom(viewFrom);

    }
}

