import React, { useRef } from "react";

// Own
import {
    DataGridUpdateEvent,
    DataGridDropEvent,
} from "interfaces/datagrid.interfaces";
import { AdminDataGrid } from "components/AdminPanel/Containers/AdminDataGrid";
import { adminPanelType } from "components/AdminPanel/Models/AdminPanel.model";
import { generalContractSublistColumns, linkContractGridActions } from "components/AdminPanel/Contracts/Models/ContractDetail.model";
import { apiSuccess, openPanelInSelectionMode } from 'components/AdminPanel/Helpers/AdminPanel.helper';
import { AccessibleContractsStateForSpecificPerson, AccessibleContractI } from "components/AdminPanel/Contracts/Interfaces/Contract.interface";
import { Person } from "components/AdminPanel/People/Interfaces/People.interface";
import { removeDisabledFieldsBasedOnRecordMeta } from "helpers/Forms/form.helper";
import { EntityKeys } from "constants/entity.constants";
import peopleServices from "components/AdminPanel/People/Services/peopleServices";

interface AccessibleContractsViewProps {
    panelId: string;
    masterRecordData: Person;
    id: any;
    accessibleContractsState: AccessibleContractsStateForSpecificPerson;
    dataTestId: string;
    handleDropCB?: any;
    masterRecordType?: string;
    selectActionIdentifier?: string;
    onAddLinks?: Function;
    onUnlink?: (item: any) => void;
    linkPanelTitle?: string;
}

const AccessibleContractsView: React.FunctionComponent<AccessibleContractsViewProps> = ({
    panelId,
    masterRecordData,
    id,
    accessibleContractsState,
    dataTestId,
    handleDropCB,
    masterRecordType,
    selectActionIdentifier,
    onAddLinks,
    onUnlink,
    linkPanelTitle
}) => {

    const cloneMembersFromState = (): AccessibleContractI[] => {
        return (accessibleContractsState.data || []).slice();
    };

    const accessList = cloneMembersFromState();

    const currentMemberIds = accessList.map(m => m.contract)

    const handleContractLink = (): void => {
        //openPanelWithDragMessage(adminPanelType.people);
        masterRecordType && selectActionIdentifier && linkPanelTitle && openPanelInSelectionMode(
            {
                selectActionIdentifier,
                masterRecordType,
                masterRecordData,
                linkedRecordType: adminPanelType.contracts,
                onAddLinks,
                currentMemberIds,
                linkPanelTitle,
            }
        )
    };

    const linkContract = selectActionIdentifier ? handleContractLink : undefined;

    const handleAccessUpdate = (e: DataGridUpdateEvent): void => {
        // @ts-ignore    
        e.cancel = peopleServices.updateAccessibleContracts(
            masterRecordData,
            e.key,
            e.oldData,
            e.newData,
            accessibleContractsState.metaWrapper.putMeta
            //selectPeopleState.recordmeta[e.key]
        ).then(apiSuccess);
    };

    const handleFetchContractGroup = () => { };

    const handleDrop = (e: DataGridDropEvent) => {
        handleDropCB && handleDropCB(e.itemData, id);
    };

    return (
        <AdminDataGrid
            data={accessList}
            editMode="cell"
            meta={accessibleContractsState.metaWrapper.meta}
            permissions={accessibleContractsState.metaWrapper.permissions}
            dataTestId={dataTestId}
            getDatagridColumns={generalContractSublistColumns}
            passedEditorPreparing={(e: any) => {
                removeDisabledFieldsBasedOnRecordMeta(e);
            }}
            masterView={false}
            entityKey={EntityKeys.Contracts}
            columnActions={linkContractGridActions(onUnlink)}
            panelId={panelId}
            dragAndDrop={false}
            fetchGroup={handleFetchContractGroup}
            onUpdate={handleAccessUpdate}
            onDrop={handleDrop}
            onLink={linkContract}
        />
    );
};

export default AccessibleContractsView;
