import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';

interface GetTheseFieldsConfigProps {
    HASAdminAccess?: boolean;
}

export const getInitialInfoFieldsConfig = ({ HASAdminAccess }: GetTheseFieldsConfigProps): FieldsFormConfig => ({

    employee_name: {},
    employee_job_title: {},
    employee_email: {},
    // Record of incident
    occurred_at: {},
    location: {},
    incident_description: {
        multiline: true,
        rows: 10
    },
    names_of_employees_involved: {},
    // immediate actions taken
    area_closed_off_or_restricted: {},
    work_prohibited: {},
    equipment_out_of_service_and_secured: {},
    interim_safety_procedures_implemented: {},
    // confidentiality requested
    confidential: {},
    h_and_s_department_comments: {},
});

interface InitialFormLayoutProps {
    HASAdminAccess: boolean;
}

export const useGetInitialInfoNearMissFormLayout = ({ HASAdminAccess }: InitialFormLayoutProps) => {
    // General details
    const thisLayout = [
        {
            fields: [
                "employee_name",
                "employee_job_title",
                "employee_email",
            ],
            group_title: "Details of the person reporting or in charge of the work activity",
            group_id: "Details of the person reporting or in charge of the work activity",
            className: "employeeDetails",
        },
        {
            fields: [
                "occurred_at",
                "location",
                "incident_description",
                "names_of_employees_involved"
            ],
            group_title: "Record of incident",
            group_id: "Record of incident",
            className: "recordOfIncident"
        },
        {
            fields: [
                "area_closed_off_or_restricted",
                "work_prohibited",
                "equipment_out_of_service_and_secured",
                "interim_safety_procedures_implemented",
            ],
            group_title: "Immediate Actions Taken",
            group_id: "Immediate Actions Taken",
            className: "immediateActionsTaken"
        },
    ]
    if (HASAdminAccess) {
        thisLayout.push({
            fields: [
                "h_and_s_department_comments",
                "confidential"
            ],
            group_title: "H&S Department Use Only",
            group_id: "H&S Department Use Only",
            className: "HAndSUseOnly"
        })
    }
    return thisLayout;
};

export const getNearMissInvestigationFieldsConfig = ({ HASAdminAccess }: GetTheseFieldsConfigProps): FieldsFormConfig => ({
    near_miss_report_number: {},
    employee_name: {},
    names_of_employees_involved: {},
    employee_job_title: {},
    employee_email: {},
    // Record of incident
    occurred_at: {},
    location: {},
    incident_description: {
        multiline: true,
        rows: 10
    },
    cause: {
        multiline: true,
        rows: 3
    },
    additional_comments: {
        multiline: true,
        rows: 3
    },
    reported_to: {},
    reported_at: {},

    // immediate actions taken
    area_closed_off_or_restricted: {},
    work_prohibited: {},
    equipment_out_of_service_and_secured: {},
    interim_safety_procedures_implemented: {},
    // confidentiality requested
    confidential: {},
    h_and_s_department_comments: {
        multiline: true,
        rows: 3
    },
});

interface NearMissInvestigationFormLayoutProps {
    HASAdminAccess: boolean;
    RenderImmediateNearMissActions: () => JSX.Element;
    RenderFurtherNearMissActions: () => JSX.Element;
}

export const useGetNearMissInvestigationFormLayout = ({ HASAdminAccess, RenderImmediateNearMissActions, RenderFurtherNearMissActions }: NearMissInvestigationFormLayoutProps) => {
    // General details
    const thisLayout = [
        {
            fields: [
                "near_miss_report_number",
                "employee_name",
                "names_of_employees_involved",
                "employee_job_title",
                "employee_email",
            ],
            group_title: "Details of the person reporting or in charge of the work activity",
            group_id: "Details of the person reporting or in charge of the work activity",
            className: "employeeDetails",
        },
        {
            fields: [
                "occurred_at",
                "location",
                "incident_description",
                "cause",
                "additional_comments",
                "reported_to",
                "reported_at",
            ],
            group_title: "Record of incident",
            group_id: "Record of incident",
            className: "recordOfIncident"
        },
        {
            fields: [
                "area_closed_off_or_restricted",
                "work_prohibited",
                "equipment_out_of_service_and_secured",
                "interim_safety_procedures_implemented",
            ],
            group_title: "Immediate Actions Taken",
            group_id: "Immediate Actions Taken",
            className: "immediateActionsTaken"
        },
        {
            fields: [],
            group_title: "",
            group_id: "RenderImmediateNearMissActions",
            className: "",
            component: RenderImmediateNearMissActions
        },
        {
            fields: [],
            group_title: "",
            group_id: "RenderFurtherNearMissActions",
            className: "",
            component: RenderFurtherNearMissActions
        },
    ]
    if (HASAdminAccess) {
        thisLayout.push({
            fields: [
                "h_and_s_department_comments",
                "confidential"
            ],
            group_title: "H&S Department Use Only",
            group_id: "H&S Department Use Only",
            className: "HAndSUseOnly"
        })
    }
    return thisLayout;
};