
import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { downloadRequestRemote } from 'components/ContractInFocus/HoC/WithPageContext/Actions/Download.actions';
import { DownloadRequestRemote } from 'components/ContractInFocus/HoC/WithPageContext/Types/Download.types';
import { projectConfigSelector } from 'components/ContractInFocus/HoC/WithPageContext/Selectors/Download.selectors';
import { simpleFetchContract, simpleFetchMMRAppendices } from "components/AdminPanel/Contracts/Services/contractService";
import { ContractOrPortfolio } from "services/API/common/contractAPIs";
import { simpleFetchDistribution } from "components/AdminPanel/Contracts/Services/contractService";
import { forkJoin } from 'rxjs';

interface UseGenerateMonthlyMaintenanceReportProps extends ContractOrPortfolio { }

const useGenerateMonthlyMaintenanceReport = (props: UseGenerateMonthlyMaintenanceReportProps) => {
    const projectConfig = useSelector(projectConfigSelector);
    const dispatch = useDispatch();

    const onGeneratePDF = useCallback(
        (downloadPayload: DownloadRequestRemote): void => {
            downloadPayload.jaguar_doc_logo_with_text = projectConfig.jaguar_doc_logo_with_text;
            downloadPayload.maintenance_report_cover_fallback = projectConfig.maintenance_report_cover_fallback;
            if (props.portfolio) {
                downloadPayload.portfolio = props.portfolio
                forkJoin({
                    distribution: simpleFetchDistribution({ portfolioId: props.portfolio.id }),
                    appendices: simpleFetchMMRAppendices({ portfolioId: props.portfolio?.id, snapshotId: downloadPayload.snapshot.id })
                }).subscribe(response => {
                    downloadPayload.distribution = response.distribution.data;
                    downloadPayload.appendices = response.appendices.data;
                    dispatch(downloadRequestRemote(downloadPayload));
                })
            }
            if (props.contract) {
                simpleFetchContract(props.contract.contract_ref).then(
                    (gridContract) => {
                        // we fetch the contract first in case any aws links have expired... we could of course check the state of the contract in the state and try to see
                        // if the image urls have expired yet, but we would be comparing it to the local computer time (I think), which will be a source of frustration.
                        downloadPayload.contract = gridContract;
                        forkJoin(
                            {
                                distribution: simpleFetchDistribution({ contractId: props.contract?.id, contractRef: props.contract?.contract_ref }),
                                appendices: simpleFetchMMRAppendices({ contractId: props.contract?.id, contractRef: props.contract?.contract_ref, snapshotId: downloadPayload.snapshot.id })
                            }
                        )
                            .subscribe(response => {
                                downloadPayload.distribution = response.distribution.data;
                                downloadPayload.appendices = response.appendices.data;
                                dispatch(downloadRequestRemote(downloadPayload));
                            });
                    }
                )
            }

        }, [dispatch, projectConfig.jaguar_doc_logo_with_text, projectConfig.maintenance_report_cover_fallback, props.contract, props.portfolio]
    );
    return onGeneratePDF;
}

export default useGenerateMonthlyMaintenanceReport;