import { ADD_SELECTION_CONFIGURATION } from "../Actions/SelectedEntities.actions";
import { REMOVE_ADMIN_PANEL } from "components/AdminPanel/Actions/adminPanel.actions";

const initialConfigurationsState = {};
export const selectEntityConfigurationsReducer = (
  state = initialConfigurationsState,
  action
) => {
  switch (action.type) {
    case ADD_SELECTION_CONFIGURATION: {
      const { panelId, selectionConfig } = action;
      return {
        ...state,
        [panelId]: selectionConfig
      };
    }
    case REMOVE_ADMIN_PANEL: {
      const { [action.panelId]: value, ...withoutPanelId } = state;
      return { ...withoutPanelId };
    }
    default:
      return state;
  }
};

export default selectEntityConfigurationsReducer;
