import { CanWrite, EntityState } from "../Interfaces/Entity.interface";

export const canWrite: CanWrite = {
  POST: true,
  DELETE: true
}

export const initialEntityState: EntityState<any> = {
  data: null,
  meta: {},
  permissions: {
    POST: false,
    PUT: false,
    DELETE: false
  }
};

export const initialIDsState = {
  ids: [],
  meta: {},
};

export const initialIDState = {
  ids: undefined,
  meta: {},
};

export const initialEntityListState = {
  data: [],
  meta: {},
};

export const initialDataOnlyListState = {
  data: [],
};