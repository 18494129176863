import { Spend, SpendRaw, SpendRawList } from '../Interfaces/spend.interfaces';
import { orderedShortMonths } from 'constants/date.constants';

// export const transformSpendResponse = (spendResponse: SpendRaw, firstMonth: number): Spend[] => {
//   const tableRows = orderedShortMonths.map(month => {
//     const partProcessedData = Object.keys(spendResponse).reduce((acc, key) => {
//       return {
//         ...acc,
//         [key]: spendResponse[key][month] || 0
//       }
//     }, {});

//     return {
//       month,
//       ...partProcessedData
//     }
//   });

//   const firstMonthGroup = tableRows.slice(firstMonth)
//   return [...firstMonthGroup, ...tableRows.slice(0, firstMonth)];
// }

export const transformSpendResponse = () => { }

export const minimumSpendColumns = 6;

function sortAggregateColumns(header1: string, header2: string) {
  const a = Number(header1.replace(/\D/g, ''));
  const b = Number(header2.replace(/\D/g, ''));
  return (a - b)
  // if (a < b) {
  //   return -1;
  // }
  // if (a > b) {
  //   return 1;
  // }
  // a must be equal to b
  // return 0;
}

// export const extractSpendColumnsOld = (spendResponse: SpendRaw): string[] => {
//   const rawHeaders = Object.keys(spendResponse).map(key => key);
//   rawHeaders.sort(sortAggregateColumns);
//   console.log('rawHeaders: ', rawHeaders);
//   return rawHeaders.length < minimumSpendColumns ? rawHeaders.concat(new Array(minimumSpendColumns - rawHeaders.length).fill('')) : rawHeaders;
// }

export const extractSpendColumns = (spendResponse: SpendRawList): string[] => {
  let rawHeaders: any = [];
  spendResponse.map((ob) => {
    //@ts-ignore
    rawHeaders.push(...Object.keys(ob))
  })
  //@ts-ignore
  rawHeaders = [...new Set(rawHeaders)];
  rawHeaders.sort(sortAggregateColumns);
  rawHeaders = rawHeaders.length < minimumSpendColumns ? rawHeaders.concat(new Array(minimumSpendColumns - rawHeaders.length).fill('')) : rawHeaders;
  return rawHeaders;
}