import { createSelector } from "reselect";
import { State } from "store/interfaces/State";
import { contractInFocusMeterStreamsSelector } from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import { IMeterReadingsStream } from 'components/ContractInFocus/MeterReadings/Interfaces/MeterReadings.interface';
import { toPythonVarName } from "helpers/String/String.helper";
import { MeterStreamListState } from "components/ContractInFocus/MeterReadings/Reducers/MeterStreams.reducer";
import { createDeepEqualSelector } from "helpers/SelectorUtilities/selectorUtilities";
import { getFullReportSectionStructure } from "components/ContractInFocus/Models/contractIndex.model";
import { getReportTocs } from "components/ContractInFocus/ContractReport/Helpers/contractReportToc.helper";
import { ContractIdOrPortfolioId } from "services/API/common/contractAPIs";

export const mainInFocusVisibilityObjects = (state: State) => state.mainInFocusVisibilityObject;

export const personalInFocusVisibilitySettingsSelector = createSelector(
  mainInFocusVisibilityObjects,
  (mainInFocusVisibilityObjects) => mainInFocusVisibilityObjects.basicPersonalVisibilitySettings
);

export const mainClientVisibilitySettingsObjectsSelector = createDeepEqualSelector(
  mainInFocusVisibilityObjects,
  (mainInFocusVisibilityObjects) => mainInFocusVisibilityObjects.basicClientVisibilitySettings
);

export const getFullVisibilityData = (baseVisibility: any, meterStreamVisibility?: MeterStreamListState, appendices?: any[]) => {
  const combined: any = { ...baseVisibility.data };
  // here we're going to lower case and add underscores to title
  meterStreamVisibility && meterStreamVisibility.data && meterStreamVisibility.data.map((stream: IMeterReadingsStream) => {
    const varString = toPythonVarName(stream.title)
    combined['show_metering__' + varString + '_graph'] = stream.show_graph_in_monthly_maintenance_report
    combined['show_metering__' + varString + '_table'] = stream.show_table_in_monthly_maintenance_report
  })
  combined[toPythonVarName('Contracts Glossary')] = true;
  combined[toPythonVarName('Report Details')] = true;
  if (appendices) {
    appendices.map((appendix) => {
      //the title will have been injected as the visibility value in the report section structure...
      combined[toPythonVarName(appendix.title)] = true; //if the appendix is included at all, currently it follows that it is visible
    })
  }
  //console.log('combined: ', combined);
  return combined;
}

export const fullContractVisibilityDataSelector = createDeepEqualSelector(
  mainInFocusVisibilityObjects,
  contractInFocusMeterStreamsSelector,
  (baseVisibility, meterStreamVisibility) => {
    // if (meterStreamVisibility.data) {
    //   return getFullVisibilityData(baseVisibility.basicClientVisibilitySettings, meterStreamVisibility);
    // }
    // return baseVisibility.basicClientVisibilitySettings.data;
    // NB the next line won't act on meterstreams if not defined anyway, but it does add other bits
    return getFullVisibilityData(baseVisibility.basicClientVisibilitySettings, meterStreamVisibility);
  }
)

export const fullPortfolioVisibilityDataSelector = createDeepEqualSelector(
  mainInFocusVisibilityObjects,
  (baseVisibility) => {
    // currently does nothing really, just a placeholder to keep portfolio/contract selectors available symmetrical.  A place to put more logic once meterstreams implemented
    // for portfolio
    return getFullVisibilityData(baseVisibility.basicClientVisibilitySettings);
  }
)

// export const getFullVisibilityDataFactory = (portfolio?:HydratedPortfolio) => {
//   if(portfolio) {
//     return 
//   }
// }

export const tocsSelector = createDeepEqualSelector(
  fullContractVisibilityDataSelector,
  (visibility) => {
    return getReportTocs(getFullReportSectionStructure(), visibility);
  }
)

export const contractOrPortfolioFullSelectedVisibilitySelector = (props: ContractIdOrPortfolioId) => {
  return createSelector(fullPortfolioVisibilityDataSelector, fullContractVisibilityDataSelector, (portfolioFullSelectedVisibilityData, contractFullSelectedVisibilityData) => {
    if (props.portfolioId) {
      return portfolioFullSelectedVisibilityData
    }
    return contractFullSelectedVisibilityData
  });
}

// make a selector to mix up the basic contract visibility object with dynamic sections, producing something of the form ReportSectionStructure
