import { ColumnProps } from "components/ContractInFocus/interfaces/dataGridColumn.interface";

const primaryColumnAttributes = {
    alignment: 'center',
    allowEditing: true,
    dataType: 'string',
}

export const columnConfig = {
    task: {
        name: 'Task',
        field: 'task__name',
        primary: true,
    },
    contract: {
        name: 'Contract',
        field: 'contract__contract_ref',
        caption: "Contract",
        primary: true
    },
    frequency: {
        name: 'Frequency',
        field: 'task__interval',
        caption: "Frequency",
        primary: true
    },
    frequency_unit: {
        name: 'Frequency Unit',
        field: 'task__interval_unit',
        caption: "Frequency Unit",
        primary: true
    }
}

export const generateColumnHeaders = (months: string[]): ColumnProps[] => {
    const columns: ColumnProps[] = months.map(month => {
        return {
            caption: month,
            alignment: 'center',
            allowEditing: true,
            dataField: month,
            primary: false,
        }
    });

    return [
        {
            ...primaryColumnAttributes,
            alignment: 'left',
            width: 200,
            caption: columnConfig.task.name,
            dataField: columnConfig.task.field,
            primary: true,
        },
        {
            ...primaryColumnAttributes,
            alignment: 'left',
            width: 100,
            caption: columnConfig.frequency.name,
            dataField: columnConfig.frequency.field,
            primary: true,
        },
        {
            ...primaryColumnAttributes,
            alignment: 'left',
            width: 120,
            caption: columnConfig.frequency_unit.name,
            dataField: columnConfig.frequency_unit.field,
            primary: true,
        },
        ...columns,
    ]
}