import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { rootReducer } from "./reducers/root.reducer";
import middlewares from "./middlewares/middlewares";
import { epicMiddleware, rootEpic } from "./middlewares/epic.middleware";
import { updateTheme } from "./middlewares/themes.middleware.js";
import { persistedState, saveState } from "./persisted.store.js";

export const store = createStore(
  rootReducer,
  persistedState, // second argument overrides the initial state
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default function configureStore() {
  // add a listener that will be invoked on any state change
  // store.subscribe(() => {
  //   //console.log('wow, I am called');
  //   saveState(store.getState());
  // });

  // Update the initial theme
  updateTheme(store.getState());
  epicMiddleware.run(rootEpic);

  return store;
}

window.addEventListener("unload", () => {
  // TODO :FIND OUT WHY WE NEED CERTAIN STATE IN ORDER TO RELOAD THE PAGE - AND WHY IT ISN'T IN THE SESSION
  // ALSO MAKE SURE LOGOUT IS AVAILABLE REGARDLESS, AS A FAILSAFE
  saveState(store.getState());
});
