import styled from "styled-components";
import styles from "../../styles/app/common/variables.scss";

export const SiteWrapper = styled.div`
  min-height: 100vh;
  overflow: hidden;
  background-color: ${styles.blueLight};
  min-width: 420px;
  padding-top: 4rem;
`;
