const visitFormInitialState = {
  service_type: '',
  scheduled_for: '',
  booked: false,
  status: '',
  status_effective_from: new Date(),
  context_category: '',
  visit_reports: [],
  notes: '',
  updated_at: new Date(),
  id: "",
}

export default visitFormInitialState;