import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// Own
import { AdminFilteredDataGrid } from "components/AdminPanel/Containers/AdminFilteredDataGrid";
import { getContractFilteredDatagridColumns, getContractFormItems, contractFormItems, getContractsFilterExpForGrid } from "components/AdminPanel/Contracts/Models/ContractDetail.model";
import { ContractDetailView } from "components/AdminPanel/Contracts/Containers/ContractDetailView"
import {
  DataGridEvent,
  DataGridUpdateEvent,
} from "interfaces/datagrid.interfaces";
import { apiSuccess } from 'components/AdminPanel/Helpers/AdminPanel.helper';
import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";
import { adminListContractsSelector, contractSelector } from "components/AdminPanel/Contracts/Selectors/Contracts.selectors";
import { transformContractMetaForGrid } from "components/AdminPanel/Contracts/Helper/contractHelper";
import { contractWithLookupSelector } from "components/AdminPanel/Organisation/Selectors/Organisations.selectors";
import { peopleCalcLookupSelector } from "components/AdminPanel/People/Selectors/People.selector";
import { sitesLookupSelector } from "components/AdminPanel/Sites/Selectors/Sites.selectors";
import contractServices from "components/AdminPanel/Contracts/Services/contractService";

interface ContractMasterViewProps {
  panelId: string;
}

const ContractMasterView: React.FC<ContractMasterViewProps> = ({ panelId }) => {
  const dataTestId = "people-master-view";
  const entityKey = 'contracts';
  const contractsState = useSelector(contractSelector);
  const selectContractListState = useSelector(adminListContractsSelector);
  const selectContractWithLookupState = useSelector(contractWithLookupSelector);
  const selectPeopleLookupState = useSelector(peopleCalcLookupSelector);
  const selectSiteLookupState = useSelector(sitesLookupSelector);

  const [contractsList, setContractsList] = useState(selectContractListState.data.filter((x: any) => x.active == true));

  const [includeInactive, setIncludeInactive] = useState(false);

  const getContractListDataColumns = getContractFilteredDatagridColumns(includeInactive);

  const contractDataGridActions = {
    'toggle_inactive_contracts': {
      caption: includeInactive ? 'Hide Inactive Contracts' : 'Display Inactive Contracts',
      location: "after",
      onClick: (e: any) => {
        setIncludeInactive(!includeInactive);
      }
    }
  }

  useEffect(() => {
    if (includeInactive) {
      setContractsList(selectContractListState.data);
    } else {
      setContractsList(selectContractListState.data.filter((x: any) => x.active == true));
    }
  }, [includeInactive, selectContractListState.data])

  const handleUpdate = (e: DataGridUpdateEvent) => {
    // @ts-ignore
    e.cancel = contractServices.updateContract(e.oldData.contract_ref, e.newData, selectContractListState.recordmeta[e.key]).then(apiSuccess);
  };

  const handleCreate = (e: DataGridEvent): void => {
    // @ts-ignore
    contractServices.createContract(e.data, panelId).then((response) => {
      contractServices.fetchContractForAdmin(response.data.contract_ref, selectContractWithLookupState, selectPeopleLookupState, selectSiteLookupState);
    }
    )
  };

  const handleDelete = (e: DataGridEvent): void => {
    // @ts-ignore
    e.cancel = contractServices.deleteContract(e.data.id, e.data.contract_ref).then(apiSuccess);
  };

  const handleRowExpanding = (e: DataGridEvent) => {
    e.cancel = false;
    const thisContract = contractsState.data[e.key];
    thisContract && contractServices.fetchContractForAdmin(thisContract.contract_ref, selectContractWithLookupState, selectPeopleLookupState, selectSiteLookupState);
  }

  const getContractMeta = (): FieldMetaGroup =>
    transformContractMetaForGrid(selectContractListState.meta, selectContractWithLookupState, selectPeopleLookupState, selectSiteLookupState);

  const generalContractMeta = getContractMeta();

  return (
    <AdminFilteredDataGrid
      data={contractsList}
      meta={generalContractMeta}
      permissions={selectContractListState.permissions}
      recordmeta={selectContractListState.meta.PUT}
      masterView
      dragAndDrop={false}//was true
      panelId={panelId}
      dataTestId={dataTestId}
      detailView={ContractDetailView}
      createFormMeta={getContractFormItems(generalContractMeta, true, contractFormItems)}
      getDatagridColumns={getContractListDataColumns}
      getPreFilterExp={getContractsFilterExpForGrid}
      onDelete={handleDelete}
      onUpdate={handleUpdate}
      onCreate={handleCreate}
      datagridActions={contractDataGridActions}
      //onCreatePseudo={handleCreatePseudo}
      //fetchGroup={handleFetchContract}
      entityKey={entityKey}
      //fetchDetail={fetchDetail}
      editMode="form"
      onRowExpanding={handleRowExpanding}
    />
  );
};

export default ContractMasterView;
// ContractMasterView.whyDidYouRender = true;
