import React, { useState, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import DataGrid, { Column, Editing, Paging, Lookup, RequiredRule } from 'devextreme-react/data-grid';
import { Paper } from '@material-ui/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { Button, DialogActions } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

// Own
import CommonAPIService from "components/ContractInFocus/Services/commonAPI.services";
import Header from '../../Common/Components/GridHeader/GridHeader';
import MeterReadingsChart from './Components/MeterReadingsChart/MeterReadingsChart';
import PrintDataGrid from "components/Common/Components/PrintDataGrid/PrintDataGrid";
import { getSubTitle } from "components/ContractInFocus/Components/ContractPrintTitle/ContractPrintTitle";
import { IMeterReadings, MeterReading } from './Interfaces/MeterReadings.interface';
import { InlineWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { getColumnProps } from '../../../helpers/DataGrid/DataGridColumn.helper';
import { ColumnProps, DataGridMeta } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';
import { columnPropsPlaceHolder } from 'components/ContractInFocus/Models/ColumnProps';
import { meterReadingColumns } from './Models/meterReadingsColumns';
import { gridMetaInitialState } from "components/ContractInFocus/Models/Grid";
import * as selectors from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import meterStreamServices from "components/ContractInFocus/MeterReadings/Services/MeterStreamServices";
import { IMeterReadingsStream } from 'components/ContractInFocus/MeterReadings/Interfaces/MeterReadings.interface';
import EditIcon from '@material-ui/icons/Edit';
import EditMeterStreamModal from "components/ContractInFocus/MeterReadings/Components/EditMeterStreamModal";
import { getSubTitleNumber } from "components/ContractInFocus/ContractReport/Helpers/contractReportToc.helper";
import { tocsSelector } from "components/ContractInFocus/Selectors/visibility.selectors";


import { toTitleCase } from "helpers/String/String.helper";
import { ContractInterface } from "components/AdminPanel/Contracts/Interfaces/Contract.interface";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";

import { APIPrivileges } from "services/Interface/Interface";

export const genericMeterStreamStyles = makeStyles(theme =>
    createStyles({
        removeButtonWrapper: {
            width: "100%",
            textAlign: "center",
        }
    })
);

type GenericMeterReadingsProps = {
    //contract: ContractInterface;
    contract: SiteContract;
    meterStream: IMeterReadingsStream;
    meterStreamPermissions?: APIPrivileges;
    meterStreamPutMeta: any;
    utilityType: string;
    title: string;
    animate?: boolean;
    dataTestId: string;
    showChart: boolean;
    showTable: boolean;
    reloadParent: Function;
};

const getEndpoint = (contract_ref?: string | number) =>
    `contracts/${contract_ref}/meter-readings/?format=json`;

const GenericMeterReadings = ({
    contract,
    meterStream,
    meterStreamPermissions,
    meterStreamPutMeta,
    utilityType,
    title,
    dataTestId,
    animate = true,
    showChart = true,
    showTable = true,
    reloadParent
}: GenericMeterReadingsProps) => {
    const styleClasses = genericMeterStreamStyles();
    const selectFrozenFor = useSelector(selectors.contractInFocusFrozenForSelector);
    const [dataSource, setDataSource] = useState<DataSource>();
    const [metadata, setMetadata] = useState<DataGridMeta>(gridMetaInitialState);
    const [meterReadings, setMeterReadings] = useState<MeterReading[]>([]);
    const [, setContentReady] = useState(false);
    const [meterReadingsLength, setMeterReadingsLength] = useState(-1);
    const [openEditMeterStream, setOpenEditMeterStream] = useState(false);
    const [mustUpdate, forceUpdate] = useReducer((x) => x + 1, 0);
    const tocs = useSelector(tocsSelector);
    const [subTitleIndex, setSubTitleIndex] = useState<string>('');

    const closeEditMeterStream = () => {
        setOpenEditMeterStream(false);
    }

    useEffect(() => {
        meterReadings && setMeterReadingsLength(meterReadings.length);
    }, [meterReadings])

    useEffect(() => {
        const thisSubTitleIndex = getSubTitleNumber(
            title,
            tocs.flatTocsLookup,
        )
        thisSubTitleIndex && setSubTitleIndex(thisSubTitleIndex);
        // console.log('tocs: ', tocs);
        // console.log('title: ', title);
        // console.log('thisSubTitleIndex: ', thisSubTitleIndex);
    }, [tocs, title])

    useEffect(() => {
        if (contract && meterStream.id) {
            const getEndpointWithUtilityType = (contract_ref?: string | number) =>
                `contracts/${contract_ref}/meter-readings/?format=json&meter-stream=${meterStream.id}`;

            const custom = new CustomStore({
                key: 'id',
                load: loadOptions => {
                    return CommonAPIService.getAll<MeterReading>(
                        getEndpointWithUtilityType,
                        setMetadata,
                        contract.contract_ref
                    ).then(({ data }) => {
                        const finalData = data?.length ? data : []
                        setMeterReadings(finalData);
                        return { data: finalData };
                    })
                },

                insert: values =>
                    CommonAPIService.create<IMeterReadings>(
                        {
                            getEndpoint,
                            contract,
                            values
                        }
                    ),
                // @ts-ignore
                remove: key =>
                    CommonAPIService.del<IMeterReadings>(
                        getEndpoint,
                        contract.contract_ref,
                        key
                    ),
                update: (id, values) =>
                    CommonAPIService.update<IMeterReadings>(
                        getEndpoint,
                        contract.contract_ref,
                        id,
                        values
                    )
            });

            setDataSource(
                new DataSource({
                    store: custom
                })
            );
        }
    }, [contract, meterStream.id]);

    const handleRowInserting = (values: any): void => {
        //values.data.utility_type = utilityType;
        values.data.meter_stream = meterStream.id;
    };

    const handleEditingStart = () => {
        if (metadata) {
            setMetadata({ ...metadata, activeMeta: metadata.PUTMeta });
        }
    }

    const handleRowUpdated = () => {
        if (metadata) {
            setMetadata({ ...metadata, activeMeta: metadata.POSTMeta });
        }
    }

    const getColumnPropsExt = (field: string): ColumnProps => {
        return metadata.loaded ? { ...getColumnProps(field, metadata.activeMeta), width: undefined } : columnPropsPlaceHolder;
    }


    return (
        <>
            <InlineWrapper pageBreakAfter={true} data-testid={dataTestId}>
                {(showChart || showTable) ?
                    <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
                        <Header
                            title={`${subTitleIndex} ${toTitleCase(title)}`}
                            className="no-screen"
                        />
                        <Paper elevation={3}>
                            {meterReadingsLength == 0 && meterStreamPermissions?.DELETE && <div className={styleClasses.removeButtonWrapper}>
                                <Button
                                    className="button-remove-meterstream"
                                    color="primary"
                                    onClick={() => {
                                        meterStreamServices.deleteMeterStream(contract.contract_ref, meterStream.id).then(
                                            (response) => {
                                                reloadParent();
                                            }
                                        )
                                    }}
                                >
                                    Delete Meter Stream
                                </Button>
                            </div>}
                            {meterStreamPermissions && meterStreamPermissions.PUT &&
                                <div className="meter-stream-edit-control-wrapper">
                                    <EditIcon className="no-print" onClick={(e) => { setOpenEditMeterStream(true) }} />
                                </div>
                            }


                            {showChart ? (<>
                                <Header
                                    title={`${toTitleCase(title)} Chart`}
                                    subTitle={getSubTitle(metadata)}
                                    className="no-print"
                                //subTitle={`Your ${title.toLocaleLowerCase()} Chart for this contract ${selectFrozenFor}`}
                                />

                                <MeterReadingsChart animate={animate} meterReadings={meterReadings} meterStream={meterStream} />
                            </>)
                                : null}

                            {showTable ? (
                                <>
                                    <Header
                                        title={`${toTitleCase(title)} Table`}
                                        className='no-print'
                                        subTitle={getSubTitle(metadata)}
                                    //subTitle={`Your ${title.toLocaleLowerCase()} Table for this contract ${selectFrozenFor}`}
                                    />
                                    <PrintDataGrid
                                        meta={metadata.activeMeta}
                                        visibleColumns={meterReadingColumns}
                                        records={meterReadings}
                                    />
                                </>
                            )
                                : null}

                            <DataGrid
                                className='no-print'
                                dataSource={dataSource}
                                visible={showTable}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                onRowInserting={handleRowInserting}
                                onEditingStart={handleEditingStart}
                                onRowUpdated={handleRowUpdated}
                                onContentReady={() => setContentReady(true)}
                            >
                                <Paging enabled={false} />
                                <Editing
                                    mode='cell'
                                    allowUpdating={metadata.privileges.PUT}
                                    allowDeleting={metadata.privileges.DELETE}
                                    allowAdding={metadata.privileges.POST}
                                />
                                <Column {...getColumnPropsExt('id')} visible={false} />
                                <Column {...getColumnPropsExt('year')}>
                                    <RequiredRule />
                                    <Lookup
                                        dataSource={metadata.loaded ? metadata.POSTMeta?.year.choices : []}
                                        valueExpr='value'
                                        displayExpr='display_name'
                                    />
                                </Column>
                                <Column {...getColumnPropsExt('january')} />
                                <Column {...getColumnPropsExt('february')} />
                                <Column {...getColumnPropsExt('march')} />
                                <Column {...getColumnPropsExt('april')} />
                                <Column {...getColumnPropsExt('may')} />
                                <Column {...getColumnPropsExt('june')} />
                                <Column {...getColumnPropsExt('july')} />
                                <Column {...getColumnPropsExt('august')} />
                                <Column {...getColumnPropsExt('september')} />
                                <Column {...getColumnPropsExt('october')} />
                                <Column {...getColumnPropsExt('november')} />
                                <Column {...getColumnPropsExt('december')} />
                            </DataGrid>
                        </Paper>
                    </div>
                    : null

                }

                {meterStreamPermissions && meterStreamPermissions.PUT && openEditMeterStream &&
                    <EditMeterStreamModal
                        contract={contract}
                        meterStreamId={meterStream.id}
                        initialTitle={meterStream.title}
                        initialMeterStreamUnitValue={meterStream.unit}
                        initialUtilityTypeId={meterStream.utility_type.id}
                        meta={meterStreamPutMeta}
                        isOpen={openEditMeterStream}
                        onCancel={closeEditMeterStream}
                        close={closeEditMeterStream}
                        reloadParent={forceUpdate}
                    />
                }

            </InlineWrapper>
        </>
    );
};

export default GenericMeterReadings;
