import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import styles from "styles/app/common/variables.scss";

const iconSize = {
  height: "1.75rem",
  width: "1.75rem"
};

export const useHeaderAdminStyles = makeStyles((theme: Theme) =>
createStyles({
  darkMenuIcon: {
    ...iconSize
  },
  lightMenuIcon: {
    ...iconSize,
    color: styles.blueDark
  },
})
);
