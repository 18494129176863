import { createRequestActionTypes } from "components/Common/Utils/ReduxUtil";
import { MonthlyMaintenanceReports } from "interfaces/contract.interfaces";

export const CONTRACT_GET_MONTHLY_MAINTENANCE_REPORTS = createRequestActionTypes('CONTRACT_GET_MONTHLY_MAINTENANCE_REPORTS');

export const getMonthlyMaintenanceReports = (contractRef: string) =>
    ({ type: CONTRACT_GET_MONTHLY_MAINTENANCE_REPORTS.start, payload: { contractRef } });
export const getMonthlyMaintenanceReportsSuccess = (monthlyMaintenanceReports: MonthlyMaintenanceReports) =>
    ({ type: CONTRACT_GET_MONTHLY_MAINTENANCE_REPORTS.success, payload: { monthlyMaintenanceReports } });
export const getMonthlyMaintenanceReportsErrors = (error: string) => ({ type: CONTRACT_GET_MONTHLY_MAINTENANCE_REPORTS.error, error });


