// @ts-nocheck
/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button } from 'reactstrap';
import { useSelector } from "react-redux";
import DataGrid, {
  Column,
  Editing,
  Paging,
  Lookup,
  RequiredRule,
  FilterRow
} from "devextreme-react/data-grid";
import { Paper } from "@material-ui/core";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";

import CommonAPIService from "components/ContractInFocus/Services/commonAPI.services";
import { exportOpenPPMs } from "components/ContractInFocus/Maintenance/Services/MaintenanceService";
import { OpenPPMRow } from "./Interfaces/OpenPPMs.interface";
import { InlineWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { getSubTitle } from "components/ContractInFocus/Components/ContractPrintTitle/ContractPrintTitle";
import Header from "../../../../Common/Components/GridHeader/GridHeader";
import { TableChartWrapper } from "../MaintenanceTableChart/MaintenanceTableChartStyles";
import { filterRowConfig, gridMetaInitialState } from "components/ContractInFocus/Models/Grid";
import { getColumnProps, editableTextAreaOnPreparing, getGridProps } from '../../../../../helpers/DataGrid/DataGridColumn.helper';
import { ColumnProps, DataGridMeta } from '../../../interfaces/dataGridColumn.interface';
import { columnPropsPlaceHolder } from 'components/ContractInFocus/Models/ColumnProps';
import PrintDataGrid from "../../../../Common/Components/PrintDataGrid/PrintDataGrid";
import { openPmsColumns, portfolioOpenPPMColumns } from "./Models/OpenPMs.models";
import { saveDateTimeFormat } from "../../../../Common/Utils/Dates";
import * as selectors from "components/ContractInFocus/Selectors/contractInFocus.selectors";
import { PrintChartAndTableLabels } from "components/Common/constants.js";
import { includeCSRFHeader } from "components/ContractInFocus/Interfaces/ContractInFocus.interfaces";
import { getCSRF, prependToAPI } from "services/API/API.helper";

// const getEndpoint = (contractRef: string | number) => `contracts/${contractRef}/open-ppm-tasks/?format=json`;
const getContractEndpoint = (contractRef: string | number) => `contracts/${contractRef}/open-ppm-tasks/?format=json`;
const getPortfolioEndpoint = (portfolioId: string | number) => `portfolios/${portfolioId}/open-ppm-tasks/?format=json`;

import "./openPPMStyles.scss";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

interface OpenPPMGridProps {
  title: string;
  contract: SiteContract;
  portfolio?: HydratedPortfolio
}

const OpenPPMGrid = ({ title, contract, portfolio }: OpenPPMGridProps) => {
  const isMountedRef = useRef(true);

  let getEndpoint: any;
  if (contract) {
    getEndpoint = getContractEndpoint;
  } else if (portfolio) {
    getEndpoint = getPortfolioEndpoint;
  }

  const selectFrozenFor = useSelector(selectors.contractInFocusFrozenForSelector);
  const csrfToken = getCSRF();

  const [dataSource, setDataSource] = useState<DataSource>();
  const [metadata, setMetadata] = useState<DataGridMeta>(gridMetaInitialState);
  const [, setContentReady] = useState(false);

  useEffect(() => {
    let dataStore: DataSource;
    if (contract) {
      const custom = new CustomStore({
        key: "id",
        load: loadOptions => CommonAPIService.getAll<OpenPPMRow>(getEndpoint, setMetadata, contract.contract_ref),
        insert: values => CommonAPIService.create<OpenPPMRow>({ getEndpoint, contract, values }),
        remove: key => CommonAPIService.del<OpenPPMRow>(getEndpoint, contract.contract_ref, key),
        update: (id, values) => CommonAPIService.update<OpenPPMRow>(getEndpoint, contract.contract_ref, id, values)
      });

      dataStore = new DataSource({
        store: custom
      });

      isMountedRef.current && setDataSource(dataStore);
    }

    return () => {
      //dataStore && dataStore.dispose();
      isMountedRef.current = false;
    }
  }, [contract]);

  useEffect(() => {
    let dataStore: DataSource;
    if (portfolio) {
      const custom = new CustomStore({
        key: "id",
        load: loadOptions => CommonAPIService.getAll<OpenPPMRow>(getEndpoint, setMetadata, portfolio.id),
      });

      dataStore = new DataSource({
        store: custom
      });

      isMountedRef.current && setDataSource(dataStore);
    }

    return () => {
      //dataStore && dataStore.dispose();
      isMountedRef.current = false;
    }
  }, [portfolio]);

  const handleRowInserting = useCallback((values: any): void => {
    values.data.due_by = saveDateTimeFormat(values.data.due_by);
  }, [saveDateTimeFormat]);

  const getColumnPropsExt = (field: string): ColumnProps => {
    return metadata.loaded ? getColumnProps(field, metadata.activeMeta) : columnPropsPlaceHolder;
  }

  const handleGoToOpenPPMImport = () => {
    //window.open("api/admin/core/openppmtask/import/?contract_ref=" + contract.contract_ref, "_self");//, "_blank"
    if (portfolio) {
      window.open(`${prependToAPI}api/admin/core/openppmtask/import/?portfolio=${portfolio.id}`, "_blank");
    } else {
      window.open(`${prependToAPI}api/admin/core/openppmtask/import/?contract_ref=${contract.contract_ref}`, "_blank");
    }

  }

  const handleGoToOpenPPMExport = () => {
    exportOpenPPMs({ contract, portfolio, headers: includeCSRFHeader(csrfToken, { 'content-type': 'application/x-www-form-urlencoded' }) });
  }

  const handleEditingStart = () => {
    isMountedRef.current && !portfolio && setMetadata({ ...metadata, activeMeta: metadata.PUTMeta });
  }

  const handleRowUpdated = () => {
    isMountedRef.current && setMetadata({ ...metadata, activeMeta: metadata.POSTMeta });
  }

  return (
    <TableChartWrapper data-test-id="openPpms">
      <InlineWrapper>
        <Paper elevation={3}>
          <Header
            title={title}
            subTitle={getSubTitle(metadata)}
            className={PrintChartAndTableLabels ? '' : 'no-print'}
          //subTitle={`Your open ppms for this contract ${selectFrozenFor}`}
          />
          <div className="dataActionButtonsWrapper">
            {metadata.privileges.POST && <Button
              onClick={handleGoToOpenPPMImport}
              className="no-print"
              color="primary">Import OpenPPMs</Button>
            }
            {metadata.privileges.POST && <Button
              onClick={handleGoToOpenPPMExport}
              className="no-print"
              color="primary">Export OpenPPMs</Button>
            }
          </div>


          {dataSource && (
            <DataGrid
              className="no-print"
              id="openPPMsGridContainer"
              dataSource={dataSource}
              {...getGridProps(metadata.activeMeta)}
              onEditorPreparing={editableTextAreaOnPreparing(metadata.activeMeta)}
              onRowInserting={handleRowInserting}
              onEditingStart={handleEditingStart}
              onRowUpdated={handleRowUpdated}
              wordWrapEnabled={true}
              onContentReady={() => isMountedRef.current && setContentReady(true)}
            >
              <FilterRow visible={filterRowConfig.visible} applyFilter={filterRowConfig.applyFilter} />
              <Paging enabled={false} />
              <Editing
                mode="cell"
                allowUpdating={metadata.privileges.PUT}
                allowDeleting={metadata.privileges.DELETE}
                allowAdding={metadata.privileges.POST}
              />


              {portfolio && <Column
                {...getColumnPropsExt('contract_ref')}
                width="8%"
                minWidth={undefined}
                caption="Contract"
              />
              }

              <Column
                {...getColumnPropsExt('task_ref')}
                width="8%"
                minWidth={undefined}
              >
                <RequiredRule />
              </Column>
              <Column
                {...getColumnPropsExt('asset_description')}
                width="25%"
              >
                <RequiredRule />
              </Column>
              <Column
                {...getColumnPropsExt('location')}
                width="11%"
              >
                <RequiredRule />
              </Column>
              <Column
                {...getColumnPropsExt('discipline')}
                width="11%"
                minWidth={undefined}
              >

                <RequiredRule />
              </Column>
              <Column
                {...getColumnPropsExt('due_by')}
                width="8%"
              />
              <Column
                {...getColumnPropsExt('asset_code')}
                width="10%"
                minWidth={undefined}
              >
                <RequiredRule />
              </Column>
              <Column
                {...getColumnPropsExt('frequency')}
                width="10%"
                minWidth={undefined}
              >
                <RequiredRule />
              </Column>
              <Column
                {...getColumnPropsExt('reason')}
                width="17%"
              />
            </DataGrid>
          )}
          <PrintDataGrid
            meta={metadata.activeMeta}
            visibleColumns={portfolio ? portfolioOpenPPMColumns : openPmsColumns}
            records={dataSource?.items()}
          />
        </Paper>
      </InlineWrapper>
    </TableChartWrapper>
  );
};

export default OpenPPMGrid;
