import styled from "styled-components";

// Own
import styles from "../../../../styles/app/common/variables.scss";

export const openControlsStyles = {
  color: styles.white,
  transform: "rotate(-90deg)"
};

export const RightVisibilityMenuWrapper = styled.div`
  background-color: ${styles.blueDark};
  top: calc(${styles.navbarHeight} + 8.5rem);
  width: auto;
  max-width: 400px;
  max-height: 75vh;
  border-radius: 0.5rem 0 0 0.5rem;
  overflow: auto;
  position: fixed;
  right: 0rem;
  color: ${styles.white};
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  z-index: 5000;

  div:last-child {
    border-bottom: 0;
  }
`;
