import { StateObservable } from "redux-observable";
import { State } from "store/interfaces/State";
import { contractPeriodFrozenForListApi } from "services/API/common/contractPeriodAPIs";
import { Snapshot, FrozenHistoryBranch } from "components/ContractInFocus/Interfaces/ContractInFocus.interfaces";
import { ContractPeriod } from 'components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface';

export const ROUTE = "/contracts/";

export const getContractFromState = (state$: StateObservable<State>) => {
  return state$.value.contracts.data[state$.value.contractInFocus.contract.id]
}

export const getFocusedContractPeriodFromState = (state$: StateObservable<State>): ContractPeriod | undefined => {
  const contractPeriods = state$.value.contracts.data[state$.value.contractInFocus.contract.id]?.contractPeriods;
  return (contractPeriods || []).find((period: ContractPeriod) => period.id === state$.value.contractInFocus.focusedContractPeriod?.id);
}

export const getSnapshotHistory = (state$: StateObservable<State>): FrozenHistoryBranch => state$.value.contractInFocus.frozenHistory;

export const contractsPeriodsApiRequests = (contractPeriodId: any) => ({
  frozenHistory: contractPeriodFrozenForListApi(contractPeriodId),
});

export const findSelectedSnapshot = (snapshots: Snapshot[], frozenForDate?: string) => {
  const snapshotKey: any = snapshots ? Object.keys(snapshots).find((key: any) => snapshots[key].frozen_for === frozenForDate) : undefined;
  return snapshotKey && snapshots ? snapshots[snapshotKey] : undefined;
}