import React, { memo } from "react";

import getMattersForDiscussionLogs from "./Components/MattersForDiscussionLogs";
import getInnovationLogs from "./Components/InnovationLogs";
import getLeaveLogs from "./Components/LeaveLogs";
import getPersonnelMatterLogs from "./Components/PersonnelMatterLogs";
import getTechicalEventLogs from "./Components/TechnicalEventLogs";
import getAccidentEventLogs from "./Components/AccidentEventLogs";
import getNearMissEventLogs from "./Components/NearMissEventLogs";
import getPlantConditionLogs from "./Components/PlantConditionLogs";
import getSubcontractorEventLogs from "./Components/SubcontractorEventLogs";

import withPageContext, { WithPageContextProps } from "../HoC/WithPageContext/WithPageContext";
import withPortfolioPageContext from "components/PortfolioInFocus/HOC/WithPortfolioPageContext/WithPortfolioPageContext";
import { logsRightVisibilityMenu } from "./Models/logsRightVisibilityMenu";
import { reportSectionStructure } from "components/ContractInFocus/Models/contractIndex.model";
import { PrintTitle } from "../Components/ContractPrintTitle/ContractPrintTitle";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { Dictionary } from "components/Common/Interfaces/Entity.interface";
import { ReportSection } from "components/ContractInFocus/Interfaces/ContractInFocus.interfaces";
import { PrintDataGridVisibleColumns } from "components/Common/Components/PrintDataGrid/Interfaces/printDataGrid.interfaces";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

interface LogsProp extends WithPageContextProps { };
export interface LogsProps {
  //contract: ContractInterface;
  contract: SiteContract;
  portfolio: HydratedPortfolio
  frozenFor?: string;
  sectionNumberMap?: Dictionary<number>;
  reportSection?: ReportSection;
  logsHandle?: string;
  logColumnsDef?: PrintDataGridVisibleColumns[];
};

export interface NewLogsProps {
  contract?: SiteContract;
  portfolio?: HydratedPortfolio
  dataSource: any,
  metadata: any,
  setContentReady: any,
  handleEditingStart: any,
  handleRowUpdated: any,
  handleOnRowPrepared?: any,
  getColumnPropsExt: any,
  columnsOverride?: any,
  remoteOperations?: boolean;
  pageSize?: number;
  filterRow?: boolean;
  csvFileNameBase?: string;
  styles?: any;
}

const Logs: React.FC<LogsProp> = ({
  contract,
  portfolio,
  visibilitySettings,
  frozenFor,
  sectionNumberMap,
  remoteOperations,
  pageSize,
  filterRow,
  csvFileNameBase
}) => {
  const blankComp = () => <></>;
  const PrintHeading = () => <PrintTitle sectionNumberMap={sectionNumberMap} reportSection={reportSectionStructure.Events} />;

  const MattersForDiscussionLogs = visibilitySettings.show_matters_for_discussion_table ? memo(getMattersForDiscussionLogs({ contract, portfolio, sectionNumberMap, remoteOperations, pageSize, filterRow })) : blankComp;
  const InnovationLogs = visibilitySettings.show_innovation_table ? memo(getInnovationLogs(contract, portfolio, sectionNumberMap)) : blankComp;
  const LeaveLogs = visibilitySettings.show_leave_table ? memo(getLeaveLogs(contract, portfolio, sectionNumberMap)) : blankComp;
  const PersonnelMatterLogs = visibilitySettings.show_personnel_matter_table ? memo(getPersonnelMatterLogs(contract, portfolio, sectionNumberMap)) : blankComp;
  const TechnicalEventLogs = visibilitySettings.show_technical_event_table ? memo(getTechicalEventLogs(contract, portfolio, sectionNumberMap)) : blankComp;
  const AccidentEventLogs = visibilitySettings.show_accident_event_log_table ? memo(getAccidentEventLogs(contract, portfolio, sectionNumberMap)) : blankComp;
  const NearMissEventLogs = visibilitySettings.show_near_miss_event_log_table ? memo(getNearMissEventLogs(contract, portfolio, sectionNumberMap)) : blankComp;
  const PlantConditionLogs = visibilitySettings.show_plant_condition_table ? memo(getPlantConditionLogs(contract, portfolio, sectionNumberMap)) : blankComp;
  const SubcontractorEventLogs = visibilitySettings.show_subcontractor_event_table ? memo(getSubcontractorEventLogs(contract, portfolio, sectionNumberMap)) : blankComp;

  return (
    <>
      {
        (
          visibilitySettings.show_matters_for_discussion_table ||
          visibilitySettings.show_technical_event_table ||
          visibilitySettings.show_accident_event_log_table ||
          visibilitySettings.show_near_miss_event_log_table ||
          visibilitySettings.show_subcontractor_event_table ||
          visibilitySettings.show_plant_condition_table ||
          visibilitySettings.show_personnel_matter_table ||
          visibilitySettings.show_leave_table ||
          visibilitySettings.show_innovation_table
        ) &&
        <PrintHeading />
      }
      {visibilitySettings.show_matters_for_discussion_table &&
        <MattersForDiscussionLogs />
      }
      {visibilitySettings.show_accident_event_log_table &&
        <AccidentEventLogs />
      }
      {visibilitySettings.show_near_miss_event_log_table &&
        <NearMissEventLogs />
      }
      {visibilitySettings.show_technical_event_table &&
        <TechnicalEventLogs />
      }
      {visibilitySettings.show_subcontractor_event_table &&
        <SubcontractorEventLogs />
      }
      {visibilitySettings.show_plant_condition_table &&
        <PlantConditionLogs />
      }
      {visibilitySettings.show_personnel_matter_table &&
        <PersonnelMatterLogs />
      }
      {visibilitySettings.show_leave_table &&
        <LeaveLogs />
      }
      {visibilitySettings.show_innovation_table &&
        <InnovationLogs />
      }
    </>
  );
};

//Logs.whyDidYouRender = true;

export default withPageContext(Logs, {
  visibilityMenu: logsRightVisibilityMenu
});

export const PortfolioLogs = withPortfolioPageContext(Logs, {
  visibilityMenu: logsRightVisibilityMenu
});
