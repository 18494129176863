import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import DataGrid, { Scrolling, Column, Editing, Paging, } from 'devextreme-react/data-grid';
import { Paper } from '@material-ui/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

// Own
import CommonAPIService from "components/ContractInFocus/Services/commonAPI.services";
import Header from 'components/Common/Components/GridHeader/GridHeader';
import PrintDataGrid from "components/Common/Components/PrintDataGrid/PrintDataGrid";
import { getSubTitle } from "components/ContractInFocus/Components/ContractPrintTitle/ContractPrintTitle";
import { SpendSimplePOData } from '../../Interfaces/spend.interfaces';
import { InlineWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { getColumnProps } from 'helpers/DataGrid/DataGridColumn.helper';
import { ColumnProps, DataGridMeta } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';
import { columnPropsPlaceHolder } from 'components/ContractInFocus/Models/ColumnProps';
import { gridMetaInitialState } from "components/ContractInFocus/Models/Grid";
import { spendSimplePOColumns } from '../../Models/spendColumns';
import { getContractScopeParams, getRemoteOpParamsAndContractScopeParams } from 'helpers/Pipelines/contractScopeOperator';
import * as selectors from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import { currencyColumnFormat } from 'constants/grid.constants';
import { ContractInterface } from "components/AdminPanel/Contracts/Interfaces/Contract.interface";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { PrintChartAndTableLabels } from "components/Common/constants.js";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { ContractPeriodDates } from "components/ContractInFocus/interfaces/contractInFocusActions.interfaces";
import { ContractPeriod } from 'components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface';
import { portfolioInFocusPeriod } from 'components/PortfolioInFocus/Selectors/portfolioInFocus.selectors';

type SpendSimplePOProps = {
    id?: string;
    includeMarkup: boolean;
    //contract: ContractInterface;
    contract?: SiteContract;
    portfolio?: HydratedPortfolio;
    refreshSpendAt?: number;
    title: string;
    dataTestId: string;
    spendType: 'consumable' | 'comprehensive' | 'managed' | 'committed-consumable' | 'committed-comprehensive' | 'committed-managed';
    paging?: boolean;
    remoteOperations?: boolean;
};

const SpendSimplePOs: React.FunctionComponent<SpendSimplePOProps> = ({
    id = 'spendSimplePOs',
    includeMarkup,
    contract,
    portfolio,
    refreshSpendAt,
    title,
    dataTestId,
    spendType,
    paging,
    remoteOperations
}) => {
    const selectedFrozenFor = useSelector(selectors.contractOrPortfolioFrozenForSelector({ portfolioId: portfolio?.id, contractId: contract?.id }));
    const selectedSnapshot = useSelector(selectors.contractOrPortfolioSnapshotSelector({ portfolioId: portfolio?.id, contractId: contract?.id }));
    const selectedPeriod = useSelector(selectors.contractOrPortfolioPeriodSelector({ portfolioId: portfolio?.id, contractId: contract?.id }));

    const [dataSource, setDataSource] = useState<DataSource>();
    const [spend, setSpend] = useState<SpendSimplePOData[]>([]);
    const [metadata, setMetadata] = useState<DataGridMeta>(gridMetaInitialState);
    const [, setContentReady] = useState(false);

    useEffect(() => {
        if ((contract || portfolio) && selectedPeriod) {
            // const getEndpoint = (contractRef?: string | number) =>
            //     `contracts/${contractRef}/${spendType}-spend-pos/?format=json${includeMarkup ? '&markup=1' : ''}`;
            const getEndpoint = (idOrRef?: string | number) =>
                `${portfolio ? "portfolios" : "contracts"}/${idOrRef}/${spendType}-spend-pos/?format=json${includeMarkup ? '&markup=1' : ''}`;

            const idOrRef = portfolio ? portfolio.id : contract?.contract_ref;

            const custom = new CustomStore({
                key: 'po_number',
                load: (loadOptions: any) => {
                    const params = getRemoteOpParamsAndContractScopeParams({ loadOptions, selectedPeriod, selectedSnapshot });
                    return CommonAPIService.getAll<SpendSimplePOData>(
                        getEndpoint,
                        setMetadata,
                        idOrRef,
                        params
                    ).then((response: any) => {
                        const finalData = response.data?.length ? response.data : []
                        setSpend(finalData);
                        return { data: finalData, totalCount: response.totalCount };
                    })
                },
            });

            setDataSource(
                new DataSource({
                    store: custom
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contract, spendType, refreshSpendAt, includeMarkup]);

    const handleRowInserting = (values: any): void => {
        // values.data.utility_type = utilityType;
    };

    const handleEditingStart = () => {
        if (metadata) {
            setMetadata({ ...metadata, activeMeta: metadata.PUTMeta });
        }
    }

    const handleRowUpdated = () => {
        if (metadata) {
            setMetadata({ ...metadata, activeMeta: metadata.POSTMeta });
        }
    }

    const getColumnPropsExt = (field: string): ColumnProps => {
        return metadata.loaded ? getColumnProps(field, metadata.activeMeta) : columnPropsPlaceHolder;
    }

    return (
        <InlineWrapper
            id={id}
            pageBreakAfter={true}
            data-testid={dataTestId}
        >
            <Paper elevation={3}>
                <>
                    <Header
                        title={`${title} Table`}
                        subTitle={getSubTitle(metadata)}
                        className={`${PrintChartAndTableLabels ? null : 'no-print'}`}
                    //subTitle={`Your ${title.toLocaleLowerCase()} Table for this contract ${selectFrozenFor}`}
                    />
                    <PrintDataGrid
                        meta={metadata.activeMeta}
                        visibleColumns={spendSimplePOColumns}
                        records={spend}
                    />
                </>

                <DataGrid
                    keyExpr='po_number'
                    //style={{ maxHeight: '800px' }}
                    className='no-print'
                    dataSource={dataSource}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    onRowInserting={handleRowInserting}
                    onEditingStart={handleEditingStart}
                    onRowUpdated={handleRowUpdated}
                    onContentReady={() => setContentReady(true)}
                    remoteOperations={remoteOperations && !selectedFrozenFor}
                    filterRow={{
                        visible: true,
                        applyFilter: 'auto',
                        //showOperationChooser: false,
                    }}
                >
                    <Scrolling rowRenderingMode="virtual" />
                    {paging && !selectedFrozenFor ? <Paging defaultPageSize={15} /> : <Paging enabled={false} />}
                    <Editing
                        mode='cell'
                        allowUpdating={metadata.privileges.PUT}
                        allowDeleting={metadata.privileges.DELETE}
                        allowAdding={metadata.privileges.POST}
                    />
                    {/* {portfolio && <Column {...getColumnPropsExt('contract_ref')} width={80} />} */}
                    <Column {...getColumnPropsExt('po_number')} width={125} />
                    <Column {...getColumnPropsExt('po_raised_date')} dataType='date' format={'dd/MM/yyyy'} width={150} />
                    <Column {...getColumnPropsExt('po_description')} />
                    <Column {...getColumnPropsExt('total_cost')} format={currencyColumnFormat} alignment="right" />
                </DataGrid>
            </Paper>
        </InlineWrapper>
    );
};

export default SpendSimplePOs;
