/* eslint-disable */
import Cookies from "universal-cookie";
import { switchMap, tap, map, filter } from "rxjs/operators";
import { ofType } from "redux-observable";
import { FETCH_LISTS } from './App.actions';
import * as adminOrganisationActions from "./components/AdminPanel/Organisation/Actions/Organisation.actions";
import * as adminPeopleActions from "./components/AdminPanel/People/Actions/People.actions";
import * as fromContractActions from "./components/AdminPanel/Contracts/Actions/Contracts.actions";
import * as sitesActions from "components/AdminPanel/Sites/Actions/AdminPanelSites.actions";
import * as portfolioActions from "components/AdminPanel/Portfolios/Actions/AdminPanelPortfolios.actions";
import * as personalSettingsActions from "components/Profile/Actions/PersonalSettings.actions";
import { fetchProjectConfig } from "store/Common/Actions/common.actions.js";
import { saveState } from "store/persisted.store.js";
import { store } from "store/store";

const cookies = new Cookies();

export const fetchListsEpic = (action$, state$) => action$.pipe(
  ofType(FETCH_LISTS.start),
  filter(() => !!cookies.get('token')),
  tap((actions) => {
    setTimeout(() => {
      //console.log('gonna save state...');
      saveState(store.getState());
    }, 500)
    return actions
  }),
  switchMap(() => {
    // PS it goes without saying this is just to avoid making unnecessary calls/calls that will throw errors.  The FE is never relied upon for security
    // the backend ensures that any routes that shouldn't be called by the current user, don't get any info returned that shouldn't be.
    const profileData = state$.value.profile?.data;
    let baseActions = [
      fromContractActions.fetchContracts(),
      sitesActions.fetchSites(),
      portfolioActions.fetchPortfolios(),
      personalSettingsActions.getPersonalSettings(),
      adminOrganisationActions.fetchOrganisations(),
      fetchProjectConfig()
    ]
    if (profileData?.internal_member) {
      baseActions = [...baseActions, adminPeopleActions.fetchPeople(),] // Currently this is needed for the distribution list.  We should only add it here is an admin
      // and in distribution list area get a separate list when it's needed by internal members.
    }
    return baseActions
  })
);

export default [
  fetchListsEpic
];
