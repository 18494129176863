import { FieldMeta } from "../../../Common/Interfaces/Entity.interface";
import { Dictionary, LookupEntity } from "components/Common/Interfaces/Entity.interface";

export const contractPeriodKey = "contractPeriods";

export interface Period {
  start_date: string;
  end_date: string;
  months: string[];
}

export interface ContractPeriod {
  id: any;
  url?: string;
  start_date: string;
  end_date: string;
  start_date_string?: string;
  end_date_string?: string;
  mode?: string;
  months: string[];
  year_month_lookup: Dictionary<LookupEntity[]>;
  year_lookup: LookupEntity[];
  contract: number;
  portfolio?: number; //probably makes more sense to use ContractPeriod for both portfolio and contract
}
export interface ContractOrPortfolioPeriod extends Omit<ContractPeriod, 'contract'> {
  contract?: number;
}

export interface PortfolioPeriod extends Omit<ContractPeriod, 'portfolio'> {
  portfolio: number;
}

interface PeriodMeta {
  id: FieldMeta;
  start_date: FieldMeta;
  end_date: FieldMeta;
}

export interface ContractPeriodMeta extends PeriodMeta {
  contract: FieldMeta;
}

export interface PortfolioPeriodMeta extends PeriodMeta {
  portfolio: FieldMeta;
}