import { ADD_SELECTED_ENTITIES_TO_PANEL } from "../Actions/SelectedEntities.actions";
import { REMOVE_ADMIN_PANEL } from "components/AdminPanel/Actions/adminPanel.actions";

const initialAddEntitiesState = {};
export const selectedEntitiesReducer = (
  state = initialAddEntitiesState,
  action
) => {
  switch (action.type) {
    case ADD_SELECTED_ENTITIES_TO_PANEL: {
      const { panelId, selectedIds } = action;
      return {
        ...state,
        [panelId]: selectedIds
      };
    }
    case REMOVE_ADMIN_PANEL: {
      const { [action.panelId]: value, ...withoutPanelId } = state;
      return { ...withoutPanelId };
    }
    default:
      return state;
  }
};

export default selectedEntitiesReducer;
