import { pick, isEqual } from "lodash";

import {
  FETCH_FULL_CLIENT_STATIC_OPSDATA_VISIBILITY_SETTINGS_CONFIG,
  SET_CLIENT_STATIC_OPSDATA_VISIBILITY_SETTINGS,
  SET_PERSONAL_IN_FOCUS_SETTINGS,
  SET_SINGLE_PERSONAL_IN_FOCUS_SETTING
} from 'components/ContractInFocus/Actions/contractVisibilitySettings.actions';
import { MainInFocusVisibilitySettings, MainInFocusVisibilitySettingPropsArray } from "components/ContractInFocus/Interfaces/ContractInFocus.interfaces";
import { EntityState } from "../../Common/Interfaces/Entity.interface";
import { initialStateVisibilityContractSettings } from "components/ContractInFocus/Models/contractInFocusState.model";

export type MainVisibilityObjectState = {
  basicPersonalVisibilitySettings: MainInFocusVisibilitySettings;
  basicClientVisibilitySettings: EntityState<MainInFocusVisibilitySettings>;
};

const initialState = {
  basicPersonalVisibilitySettings: {
    ...initialStateVisibilityContractSettings
  },
  basicClientVisibilitySettings: {
    data: { ...initialStateVisibilityContractSettings },
    meta: {}
  }
};

const visibilityReducer = (state: MainVisibilityObjectState = initialState, { type, payload }: { type: string, payload: any }) => {
  switch (type) {

    case SET_PERSONAL_IN_FOCUS_SETTINGS.success: {
      const settings = payload.personalVisibilitySettings;
      // personalContractSettings contains some irrelevant properties.  Here we pick 
      // the relevant ones using a props array derived from the same class as the interface
      // we use elsewhere - a class so it can be present both at transpile and run time.
      const vizProps = pick(Array.isArray(settings) ? settings[0] : settings, MainInFocusVisibilitySettingPropsArray);
      // simple object, worth the comparison as we may be triggering personal settings updates from other quarters.
      if (!isEqual(state.basicPersonalVisibilitySettings, vizProps)) {
        return {
          ...state,
          basicPersonalVisibilitySettings: vizProps
        };
      }
      return state;
    }

    case SET_SINGLE_PERSONAL_IN_FOCUS_SETTING.reducer: {
      const { attribute, value } = payload;
      if (state.basicPersonalVisibilitySettings[attribute as keyof MainInFocusVisibilitySettings] !== value) {
        return {
          ...state,
          basicPersonalVisibilitySettings: {
            ...state.basicPersonalVisibilitySettings,
            [attribute]: value
          }
        };
      }
      return state;
    }

    case SET_CLIENT_STATIC_OPSDATA_VISIBILITY_SETTINGS.success: {
      const settings = payload.visibilitySettings;
      return {
        ...state,
        basicClientVisibilitySettings: {
          ...state.basicClientVisibilitySettings,
          data: Array.isArray(settings) ? settings[0] : settings
        }
      };
    }

    case FETCH_FULL_CLIENT_STATIC_OPSDATA_VISIBILITY_SETTINGS_CONFIG.success: {
      return {
        ...state,
        basicClientVisibilitySettings: {
          data: payload.data,
          meta: payload.meta,
          permissions: payload.permissions
        }
      };
    }

    default:
      return state;
  }
};

export default visibilityReducer;
