import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Own
import portfolioAdminServices from "components/AdminPanel/Portfolios/Services/portfolioAdminServices";
import { adminListPortfoliosSelector } from "components/Portfolios/Selectors/Portfolio.selectors";
import { portfolioDatagridColumns, getPortfolioFormItems } from 'components/AdminPanel/Portfolios/Models/Portfolios.model';
import { AdminDataGrid } from "components/AdminPanel/Containers/AdminDataGrid";
import { DataGridEvent, DataGridUpdateEvent } from 'interfaces/datagrid.interfaces';
//import { AdminPortfolio } from "components/AdminPanel/Portfolios/Interfaces/Portfolios.interface";
import { apiSuccess } from 'components/AdminPanel/Helpers/AdminPanel.helper';
import { PortfoliosDetailView } from 'components/AdminPanel/Portfolios/Containers/PortfoliosDetailView';
interface PortfoliosMasterViewProps {
    panelId: string;
}

const PortfoliosMasterView: React.FC<PortfoliosMasterViewProps> = ({ panelId }) => {
    const dataTestId = "portfolio-master-view";

    const portfoliosState = useSelector(adminListPortfoliosSelector);

    const handleUpdate = (e: DataGridUpdateEvent): void => {
        // @ts-ignore
        e.cancel = portfolioAdminServices.updatePortfolio(e.oldData.id, e.newData, portfoliosState.recordmeta[e.key]).then(apiSuccess);
    }

    const handleCreate = (e: DataGridEvent): void => {
        // @ts-ignore
        portfolioAdminServices.createPortfolio(e.data, panelId);
    };

    const handleDelete = (e: DataGridEvent): void => {
        // @ts-ignore
        e.cancel = portfolioAdminServices.deletePortfolio(e.data.id, e.key).then(apiSuccess);
    }

    return (
        <AdminDataGrid
            data={portfoliosState.data}
            meta={portfoliosState.meta}
            permissions={portfoliosState.permissions}
            recordmeta={portfoliosState.recordmeta}
            masterView
            panelId={panelId}
            dataTestId={dataTestId}
            detailView={PortfoliosDetailView}
            createFormMeta={getPortfolioFormItems(portfoliosState.meta)}
            getDatagridColumns={portfolioDatagridColumns}
            onDelete={handleDelete}
            onUpdate={handleUpdate}
            onCreate={handleCreate}
            editMode="form"
        />
    )
}

export default PortfoliosMasterView;