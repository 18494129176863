export const FORM_ROW_ID = "0";
export const RECENT_IN_DAYS = 1;

export const adminPanelType = {
  organisations: "organisations",
  people: "people",
  sites: "sites",
  contracts: "contracts",
  portfolios: "portfolios",
  h_a_s: "health_and_safety"
};

export const adminPanelLabel = {
  singlular: {
    organisation: "Organisation",
    people: "Person",
    sites: "Site",
    contracts: "Contract",
    portfolios: "Portfolio"
  },
  plural: {
    organisation: "Organisations",
    people: "People",
    sites: "Sites",
    contracts: "Contracts",
    portfolios: "Portfolios"
  }
};
