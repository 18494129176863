import { State } from "../interfaces/State";
import { SettingState } from './interfaces/SettingsState.interface';
import { createSelector } from "reselect";

export const settingsSelector = (state: State): SettingState => state.settings;

export const defaultIndexPageSelector = createSelector(
    settingsSelector,
    settings => settings?.defaultIndexPage
);

export const reportVisibilityModalOpenSelector = createSelector(
    settingsSelector,
    settings => settings?.reportVisibilityModalOpen
);