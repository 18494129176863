/* eslint-disable */
import { appReducer } from "./reducers";
import { RootState } from "../interfaces/State";
import { Dictionary, FieldMetaChoices, FieldMeta } from "../../components/Common/Interfaces/Entity.interface";
import { isEmpty, isEqual, xorWith } from 'lodash';

export function logObjDifferences(obj1: any, obj2: any) {
  if (obj1 && obj2) {
    const diff = Object.keys(obj1).reduce((result, key) => {
      if (!obj2.hasOwnProperty(key)) {
        result.push(key);
      } else if (isEqual(obj1[key], obj2[key])) {
        const resultKeyIndex = result.indexOf(key);
        result.splice(resultKeyIndex, 1);
      }
      return result;
    }, Object.keys(obj2));

    return diff;
  }
  return "obj1 or obj2 is falsey"
}

export const isStateEqual = (stateData: Dictionary<any>, newData: Dictionary<any>) => {
  //console.log('is state equal called!');
  const relevantStateData = Object.keys(newData).reduce((acc: any, key: any) => ({ ...acc, [key]: stateData[key] }), {});
  return isEqual(relevantStateData, newData);
};

export const isArrayEqual = (x: any[], y: any[]) => isEmpty(xorWith(x, y, isEqual));

export const branchHelper = (state: any, action: any, updateObject: any) => {
  const newState = {
    ...state,
    [action.branch]: {
      ...state[action.branch],
      ...updateObject
    }
  }

  return appReducer(newState, action);
};

export const branchSubItemHelper = (state: any, action: any, subList: any) => {
  const subItemUpdate = {
    data: {
      ...state[action.branch][action.listKey],
      [action.recordId]: {
        ...state[action.branch].data[action.recordId],
        [action.sublistKey]: [
          ...subList
        ]
      }
    }
  };

  return branchHelper(state, action, subItemUpdate);
};

export const getSublist = (state: any, branch: string, recordId: number, sublistKey: string): any[] => {
  return (state[branch].data[recordId][sublistKey] || []);
}

const setSubmitToFalse = (meta: Dictionary<FieldMeta | FieldMetaChoices>) => {
  return Object.keys(meta).reduce(
    (accMeta: any, key: any) => ({
      ...accMeta,
      [key]: {
        ...meta[key],
        submitted: false
      }
    })
    , {});
}

export const resetMetaSubmit = (state: RootState, branch: string) => {
  return {
    PUT: state[branch].meta.PUT ? setSubmitToFalse(state[branch].meta.PUT) : undefined,
    POST: state[branch].meta.POST ? setSubmitToFalse(state[branch].meta.POST) : undefined
  }
};

export function rangeMaker(size: number, startAt: number = 0): ReadonlyArray<number> {
  return [...Array(size).keys()].map(i => i + startAt);
}