import { createRequestActionTypes } from "../../Common/Utils/ReduxUtil";
import { PersonalSettings } from "../Interfaces/Profile.interface";
import { PersonalSettingsState } from "../Interfaces/ProfileState.interface";

export const GET_PERSONAL_SETTINGS = createRequestActionTypes('GET_PERSONAL_SETTINGS');
export const getPersonalSettings = () => ({ type: GET_PERSONAL_SETTINGS.start });
export const getPersonalSettingsSuccess = (personalSettings: PersonalSettingsState, showPersonalSettings = true) => ({ type: GET_PERSONAL_SETTINGS.success, personalSettings, showPersonalSettings });
export const getPersonalSettingsError = (error: any) => ({ type: GET_PERSONAL_SETTINGS.error, error });

export const SET_PERSONAL_SETTINGS_ENTITY = createRequestActionTypes('PERSONAL_SETTINGS_SET_PERSONAL_SETTINGS_ENTITY');
export const setPersonalSettingsEntity = (personalSettings: PersonalSettings) => ({ type: SET_PERSONAL_SETTINGS_ENTITY.start, personalSettings });

export const CANCEL_PERSONAL_SETTINGS = createRequestActionTypes('PERSONAL_SETTINGS_CANCEL_PERSONAL_SETTINGS');
export const cancelPersonalSettings = () => ({ type: CANCEL_PERSONAL_SETTINGS.start });

export const UPDATE_PERSONAL_SETTINGS = createRequestActionTypes('PERSONAL_SETTINGS_UPDATE_PERSONAL_SETTINGS');
export const updatePersonalSettings = (personalSettings: Partial<PersonalSettings>, ignoreFailure?: boolean) => ({ type: UPDATE_PERSONAL_SETTINGS.start, personalSettings, ignoreFailure });
export const updatePersonalSettingsSuccess = (personalSettings: any) => ({ type: UPDATE_PERSONAL_SETTINGS.success, personalSettings });
export const updatePersonalSettingsReducer = (personalSettings: any) => ({ type: UPDATE_PERSONAL_SETTINGS.reducer, personalSettings });
export const updatePersonalSettingsError = (error: any) => ({ type: UPDATE_PERSONAL_SETTINGS.error, error });

export const TOGGLE_PERSONAL_SETTINGS = createRequestActionTypes('TOGGLE_PERSONAL_SETTINGS');

export const togglePersonalSettings = (updateKey: string) => {
    return { type: TOGGLE_PERSONAL_SETTINGS.start, payload: { updateKey } }
};


export default UPDATE_PERSONAL_SETTINGS;


