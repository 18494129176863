import { ColumnProps } from 'components/ContractInFocus/interfaces/dataGridColumn.interface';
import moment from 'moment';

export const panableView = (
  dataRow: ColumnProps[],
  showFrom: number,
  visibleLength: number,
) => {
  const panableDataAsArray = dataRow.filter(x => !x.primary);

  const calculatedShowFrom =
    visibleLength > panableDataAsArray.length
      ? 0
      : showFrom + visibleLength > panableDataAsArray.length
        ? panableDataAsArray.length - visibleLength
        : showFrom;

  const panableArray = panableDataAsArray.slice(
    calculatedShowFrom < 0 ? 0 : calculatedShowFrom,
    calculatedShowFrom + visibleLength
  );

  const fixedColumns = dataRow.filter(x => x.primary);

  return [
    ...fixedColumns,
    ...panableArray,
  ];
};

export function sortByScheduledFor(items: any[], desc = false) {
  const sortedItems = [...items].sort(function (a, b) {
    var sA = a.scheduled_for; // ignore upper and lowercase
    var sB = b.scheduled_for; // ignore upper and lowercase
    if (sA < sB) {
      return desc ? 1 : -1;
    }
    if (sA > sB) {
      return desc ? -1 : 1;
    }
    return 0;
  });
  return sortedItems;
}

export const sliceSchedulePrintDataGrid = (printColumns: ColumnProps[], firstContractPeriodMonth: string, comparisonDate: Date): ColumnProps[] => {
  //NB: TODO ask JBS if they really want this....(i.e. to only show from the last contract period anniversary) and if so, should we do the same on the 
  // snapshot...
  const fixedColumns = printColumns.slice(0, 2);

  const today = moment(comparisonDate);
  const firstDate = moment(firstContractPeriodMonth, 'MMM YYYY');
  const sameMonthThisYear = moment(`${firstDate.get('month') + 1} ${today.get('year')}`, 'M YYYY');
  const sameMonthLastYear = moment(`${firstDate.get('month') + 1} ${today.get('year') - 1}`, 'M YYYY');

  const sliceIndex = sameMonthThisYear.isAfter(today)
    ? printColumns.findIndex(column => column.caption === sameMonthLastYear.format('MMM YYYY'))
    : printColumns.findIndex(column => column.caption === sameMonthThisYear.format('MMM YYYY'));

  const finalSlicePosition = Math.max(sliceIndex, 2);
  const slicedGrid = [...fixedColumns, ...printColumns.slice(finalSlicePosition, finalSlicePosition + 12)];
  return slicedGrid;
}