import { createSelectorCreator, defaultMemoize } from 'reselect';
import { shallowEqual } from "react-redux";
import { isEqual, reduce, isPlainObject } from "lodash";

const diff = function (obj1: any, obj2: any) {
    return reduce(obj1, function (result, value, key) {
        if (isPlainObject(value)) {
            //@ts-ignore
            result[key] = diff(value, obj2[key]);
        } else if (!isEqual(value, obj2[key])) {
            //@ts-ignore
            result[key] = value;
        }
        return result;
    }, {});
};

export const logIsEqual = (a: any, b: any) => {
    console.log('checking a: ', a, ' b: ', b);
    const check = isEqual(a, b);
    console.log('check: ', check);
    if (!check) {
        console.log('differences are: ');
        console.log(diff(a, b))
    }
    return check;
}

// create a "selector creator" that uses lodash.isequal instead of ===
export const createDeepEqualSelector = createSelectorCreator(
    defaultMemoize,
    isEqual
)

export const createLogDeepEqualSelector = createSelectorCreator(
    defaultMemoize,
    logIsEqual
)



export const createShallowEqualSelector = createSelectorCreator(
    defaultMemoize,
    shallowEqual
)

// use the new "selector creator" to create a selector
// const mySelector = createDeepEqualSelector(
//     state => state.values.filter(val => val < 5),
//     values => values.reduce((acc, val) => acc + val, 0)
// )