import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { adminPanelType } from "../../Models/AdminPanel.model";
import { OrganisationMasterView } from "components/AdminPanel/Organisation/Containers/OrganisationMasterView";
import { PeopleMasterView } from "components/AdminPanel/People/Containers/PeopleMasterView";
import { SitesMasterView } from "components/AdminPanel/Sites/Containers/SitesMasterView";
import { HASTasksMasterView } from "components/AdminPanel/HAS/Containers/HASTasksMasterView";
import { PortfoliosMasterView } from "components/AdminPanel/Portfolios/Containers/PortfoliosMasterView";
import { ContractMasterView } from "components/AdminPanel/Contracts/Containers/ContractsMasterView";

import "./AdminPanelBody.scss";
export class AdminPanelBody extends PureComponent {
  static propTypes = {
    activeTabId: PropTypes.string,
    id: PropTypes.string
  };

  isOrganisationPage = tabIndex => tabIndex === adminPanelType.organisations;

  isPeoplePage = tabIndex => tabIndex === adminPanelType.people;

  isSitesPage = tabIndex => tabIndex === adminPanelType.sites;

  isPortfoliosPage = tabIndex => tabIndex === adminPanelType.portfolios;

  isContractsPage = tabIndex => tabIndex === adminPanelType.contracts;

  isHASPage = tabIndex => tabIndex === adminPanelType.h_a_s;

  render() {
    return (
      <div className="admin-panel-body">
        {this.isOrganisationPage(this.props.activeTabId) && (
          <OrganisationMasterView panelId={this.props.id} />
        )}

        {this.isPeoplePage(this.props.activeTabId) && (
          <PeopleMasterView panelId={this.props.id} />
        )}

        {this.isSitesPage(this.props.activeTabId) && (
          <SitesMasterView panelId={this.props.id} />
        )}

        {this.isContractsPage(this.props.activeTabId) && (
          <ContractMasterView panelId={this.props.id} />
        )}

        {this.isPortfoliosPage(this.props.activeTabId) && (
          <PortfoliosMasterView panelId={this.props.id} />
        )}

        {this.isHASPage(this.props.activeTabId) && (
          <HASTasksMasterView panelId={this.props.id} />
        )}
      </div>
    );
  }
}

export default AdminPanelBody;
