import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { isEqual } from "lodash";
import { useSelector } from 'react-redux';

import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// Own
import ImagesGrid from "components/Common/Components/ImagesDisplayGrid/BasicImagesGrid";
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { PreFlightListInfo } from "components/Common/Interfaces/Entity.interface";
import DocumentsUploader from "components/Common/Components/DocumentsUploader/DocumentUploader";
import { getAccidentImagesRoute, fetchAccidentImagesPreFlightInfo, getNearMissImagesRoute, fetchNearMissImagesPreFlightInfo } from "components/AdminPanel/HAS/HASIncidentServices"
import { GeneralDocsGridWrapper } from 'components/SiteDocuments/Components/ContractDocumentsViewGrid/ContractDocumentGridStyles';
import { profileOrganisationSelector } from 'components/Profile/Selectors/Profile.selector';
import { ColumnPropGroup } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';
import { IHASAccident, IHASNearMiss, IncidentFieldType } from 'components/AdminPanel/HAS/Interfaces/HASReportingInterfaces';

import "components/ContractInFocus/HAS/Styles/incidentImages.scss";
import commonAPIServices from 'components/ContractInFocus/Services/commonAPI.services';

export const accidentImageColumns: ColumnPropGroup = {
    'description': {
        caption: 'Description',
        alignment: 'start',
        width: '60%'
    },
};

interface HASIncidentImagesProps {
    data: IHASAccident | IHASNearMiss;
    imageParentType: IncidentFieldType;
    FormSettingsComponent: () => JSX.Element;
    formSettingsInFocus: boolean;
    handleFormSettingsClose: () => void;
}

const HASIncidentImages = ({ data, imageParentType, FormSettingsComponent, formSettingsInFocus, handleFormSettingsClose }: HASIncidentImagesProps) => {

    const id: string = data.id;

    const basePath = imageParentType == "accident" ? getAccidentImagesRoute(id) : getNearMissImagesRoute(id);
    const [fetchFilters, setFetchFilters] = useState();
    const [mustRefreshImageGrid, forceUpdateImageGrid] = useReducer((x) => x + 1, 1);
    const [preFlightInfo, setPreFlightInfo] = useState<PreFlightListInfo>();
    const refresh = useCallback(forceUpdateImageGrid, [forceUpdateImageGrid])
    const selectProfileOrganisationFromState = useSelector(profileOrganisationSelector);
    const [loading, setLoading] = useState(true);

    const getFieldFormConfig = useCallback((): FieldsFormConfig => {
        return {
            'description': {
                label: 'Description',
                forceRequired: true
            },
            [imageParentType]: {
                hidden: true,
                defaultValue: id
            },
            'author_organisation': {
                hidden: true,
                defaultValue: selectProfileOrganisationFromState
            }
        }
    }, [id, selectProfileOrganisationFromState, imageParentType]);

    const fieldsConfig = getFieldFormConfig();

    useEffect(() => {
        const getPreFlightInfo = imageParentType == "accident" ? fetchAccidentImagesPreFlightInfo : fetchNearMissImagesPreFlightInfo;
        getPreFlightInfo({ parentId: id }).then((newPreFlightInfo) => {
            if (!isEqual(preFlightInfo, newPreFlightInfo)) {
                setPreFlightInfo(newPreFlightInfo);
            }
            setTimeout(() => {
                setLoading(false);
            }, 250)
        });
    }, [preFlightInfo, id, imageParentType]);

    const handleUpdateDescription = useCallback(({ payload, id }: { payload: any, id: string }) => {
        const getPath = () => `${basePath}`;
        return commonAPIServices.update(getPath, undefined, id, payload);
    }, [basePath]);

    return <GeneralDocsGridWrapper>
        <div className={`formWithComments ${formSettingsInFocus ? 'commentsOpen' : 'commentsClosed'}`}>
            <div className='formSection'>
                <div className={`${loading ? 'loading' : ''} loadingControl`}>
                    {accidentImageColumns &&
                        <div className='incidentImagesWrapper'>
                            <ImagesGrid
                                listBasePath={basePath}
                                fetchFilters={fetchFilters}
                                dispatchRefreshContext={forceUpdateImageGrid}
                                //surrogate_portfolio={portfolio}
                                refreshSignal={mustRefreshImageGrid}
                                allowDelete={preFlightInfo?.canCreate}
                                allowEdit={preFlightInfo?.canCreate}
                                handleUpdateDescription={handleUpdateDescription}
                                showDescription
                            />
                        </div>

                    }
                </div>
            </div>

            {formSettingsInFocus && FormSettingsComponent &&
                <div className='commentsSection'>
                    <div className='sideHeader'>
                        <div className="commentsSection-close-button">
                            <IconButton onClick={handleFormSettingsClose} >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                    <FormSettingsComponent />
                </div>
            }

        </div>
        {preFlightInfo?.canCreate && basePath &&
            <DocumentsUploader
                urlContext={basePath}
                fieldConfigs={fieldsConfig}
                preFlightInfo={preFlightInfo}
                gridId="HASIncidentImagesUploader"
                reset={refresh}
                zIndex={1300}
                fileAttribute="incident_image"
            />
        }


    </GeneralDocsGridWrapper>
}

export default HASIncidentImages;