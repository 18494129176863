import { createRequestActionTypes } from "components/Common/Utils/ReduxUtil";
import { EntityState } from "components/Common/Interfaces/Entity.interface";
import { ContractPeriod } from "components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface";
import { ContractInFocusState } from "components/ContractInFocus/Reducers/contractInFocus.reducer";

import {
  FetchSnapshotSuccessAction,
  SetSelectedContractSuccessAction,
  SetSelectedContractActions,
  SwitchSelectedContractInFocusI,
  FetchSnapshotAction,
  ClearSelectedSnapshotAction,
  SetSelectedSnapshotActions
} from "../interfaces/contractInFocusActions.interfaces";

import { ContractACLI } from "components/ContractInFocus/Reducers/contractACL.reducer";

export const CONTRACT_IN_FOCUS_FETCH_CONTRACT_PERIODS = createRequestActionTypes('CONTRACT_IN_FOCUS_FETCH_CONTRACT_PERIODS');

export const fetchContractPeriods = (contractRef: string) =>
  ({ type: CONTRACT_IN_FOCUS_FETCH_CONTRACT_PERIODS.start, payload: { contractRef } });
export const fetchContractPeriodsSuccess = (contractPeriod: EntityState<ContractPeriod>) =>
  ({ type: CONTRACT_IN_FOCUS_FETCH_CONTRACT_PERIODS.success, payload: { contractPeriod } });
export const fetchContractPeriodsErrors = (error: string) => ({ type: CONTRACT_IN_FOCUS_FETCH_CONTRACT_PERIODS.error, error });


// export const CONTRACT_IN_FOCUS_FETCH_DISTRIBUTION = createRequestActionTypes('CONTRACT_IN_FOCUS_FETCH_DISTRIBUTION');

// export const fetchContractPeriodReducer = (contractPeriods: Dictionary<ContractPeriod>, meta?: FieldMetaGroup, permissions?: APIPrivileges) =>
//   ({ type: CONTRACT_IN_FOCUS_FETCH_DISTRIBUTION.reducer, contractPeriods, meta, permissions });

export const CONTRACT_IN_FOCUS_SET_SELECTED_SNAPSHOT = createRequestActionTypes('CONTRACT_IN_FOCUS_SET_SELECTED_SNAPSHOT');

export const setSelectedSnapshot = ({ selectedSnapshot }: SetSelectedSnapshotActions) =>
  ({ type: CONTRACT_IN_FOCUS_SET_SELECTED_SNAPSHOT.reducer, payload: { selectedSnapshot } });

export const CONTRACT_IN_FOCUS_CLEAR_SELECTED_SNAPSHOT = createRequestActionTypes('CONTRACT_IN_FOCUS_CLEAR_SELECTED_SNAPSHOT');
export const clearSelectedSnapshot = (payload: ClearSelectedSnapshotAction) => ({ type: CONTRACT_IN_FOCUS_CLEAR_SELECTED_SNAPSHOT.start, payload });

export const SWITCH_SELECTED_CONTRACT_IN_FOCUS = createRequestActionTypes('CONTRACT_IN_FOCUS_SWITCH_SELECTED_CONTRACT');
export const switchSelectedContract = ({ contractId }: SwitchSelectedContractInFocusI) =>
  ({ type: SWITCH_SELECTED_CONTRACT_IN_FOCUS.reducer, payload: { contractId } });

//export const SET_SELECTED_CONTRACT = createRequestActionTypes('CONTRACT_IN_FOCUS_SET_SELECTED_CONTRACT');

// export const setSelectedContract = ({ contractRef, contractPeriod: contractPeriod, snapshotDateSelector }: SetSelectedContractActions) =>
//   ({ type: SET_SELECTED_CONTRACT.start, payload: { contractRef, contractPeriod, snapshotDateSelector } });
// export const setSelectedContractSuccess = ({ contract, site, focusedContractPeriod, distribution, frozenHistory, monthlyMaintenanceReports, reactiveCategoryOptions }: SetSelectedContractSuccessAction) =>
//   ({ type: SET_SELECTED_CONTRACT.success, payload: { contract, site, focusedContractPeriod, frozenHistory, distribution, monthlyMaintenanceReports, reactiveCategoryOptions } });

// export const setSelectedContractError = (err: any) => ({ type: SET_SELECTED_CONTRACT.error, payload: { err } });
// export const setSelectedContractReduced = () => ({ type: SET_SELECTED_CONTRACT.reducer });

export const FETCH_CONTRACT_SNAPSHOTS = createRequestActionTypes('CONTRACT_IN_FOCUS_FETCH_SNAPSHOT');

export const fetchContractSnapshots = ({ contractPeriod, focusedSnapshot, fetchSnapshotCallback }: FetchSnapshotAction) => ({ type: FETCH_CONTRACT_SNAPSHOTS.start, payload: { contractPeriod, focusedSnapshot, fetchSnapshotCallback } });
export const fetchContractSnapshotSuccess = ({ snapshotHistory, focusedSnapshot, fetchSnapshotCallback }: FetchSnapshotSuccessAction) => ({ type: FETCH_CONTRACT_SNAPSHOTS.success, payload: { snapshotHistory, focusedSnapshot, fetchSnapshotCallback } });
export const fetchContractSnapshotError = (err: any) => ({ type: FETCH_CONTRACT_SNAPSHOTS.error, payload: { err } });

export const SET_SELECTED_CONTRACT_PERIOD = createRequestActionTypes('CONTRACT_IN_FOCUS_SET_SELECTED_CONTRACT_PERIOD');
export const setSelectedContractPeriod = (contractPeriod: ContractPeriod) => ({ type: SET_SELECTED_CONTRACT_PERIOD.start, payload: contractPeriod });

export const CLEAR_CONTRACT_IN_FOCUS = createRequestActionTypes('CONTRACT_IN_FOCUS_CLEAR_CONTRACT_IN_FOCUS');
export const clearContractInFocus = () => ({ type: CLEAR_CONTRACT_IN_FOCUS.reducer });

export const SWITCH_CONTRACT_IN_FOCUS = createRequestActionTypes('CONTRACT_IN_FOCUS_SWITCH_CONTRACT_IN_FOCUS');
export const switchContractInFocus = (contractId: number) => ({ type: SWITCH_CONTRACT_IN_FOCUS.reducer, payload: { contractId } });

export const RETRIEVE_CONTRACT_IN_FOCUS_FROM_CACHE = createRequestActionTypes('RETRIEVE_CONTRACT_IN_FOCUS_FROM_CACHE');
export const retreiveContractInFocusFromCache = (contractInFocus: ContractInFocusState) => ({ type: RETRIEVE_CONTRACT_IN_FOCUS_FROM_CACHE.reducer, payload: { contractInFocus } });

export const ADD_CONTRACT_IN_FOCUS_TO_CACHE = createRequestActionTypes('ADD_CONTRACT_IN_FOCUS_TO_CACHE');
export const addContractInFocusToCache = (contractInFocus: ContractInFocusState) => ({ type: ADD_CONTRACT_IN_FOCUS_TO_CACHE.reducer, payload: { contractInFocus } });

export const REMOVE_CONTRACT_IN_FOCUS_FROM_CACHE = createRequestActionTypes('REMOVE_CONTRACT_IN_FOCUS_FROM_CACHE');
export const removeContractInFocusFromCache = (contractId: number) => ({ type: REMOVE_CONTRACT_IN_FOCUS_FROM_CACHE.reducer, payload: { contractId } });

export const CLEAR_CONTRACT_IN_FOCUS_CACHE = createRequestActionTypes('CLEAR_CONTRACT_IN_FOCUS_CACHE');
export const clearContractInFocusCache = () => ({ type: CLEAR_CONTRACT_IN_FOCUS_CACHE.reducer, payload: null });

export const ADD_CONTRACT_MENU_ACCESS = createRequestActionTypes('ADD_CONTRACT_MENU_ACCESS');
export const addContractACL = (contractId: string | number, menuAccess: ContractACLI) => ({ type: ADD_CONTRACT_MENU_ACCESS.reducer, payload: { menuAccess, contractId } });

export const REMOVE_CONTRACT_MENU_ACCESS = createRequestActionTypes('REMOVE_CONTRACT_MENU_ACCESS');
export const removeContractMenuAccess = (contractId: number) => ({ type: REMOVE_CONTRACT_MENU_ACCESS.reducer, payload: { contractId } });

export const CLEAR_ALL_CONTRACTS_MENU_ACCESS = createRequestActionTypes('CLEAR_ALL_CONTRACTS_MENU_ACCESS');
export const clearAllContractsMenuAccess = () => ({ type: CLEAR_ALL_CONTRACTS_MENU_ACCESS.reducer, payload: null });

export const INSERT_CONTRACT_SNAPSHOT = createRequestActionTypes('CONTRACT_IN_FOCUS_INSERT_CONTRACT_SNAPSHOT');
export const insertSnapshotReducer = ({ snapshot }: { snapshot: any }) => ({ type: INSERT_CONTRACT_SNAPSHOT.reducer, payload: { snapshot } });

/* Set Dialog Open */
export const SET_CONTRACT_DOCS_DIALOGUE_OPEN = createRequestActionTypes("SET_CONTRACT_DOCS_DIALOGUE_OPEN");
export const setContractDocsDialogueOpen = (contractId: number) => ({ type: SET_CONTRACT_DOCS_DIALOGUE_OPEN.reducer, payload: { contractId } });

/* Set Dialog Open */
export const SET_CONTRACT_DOCS_DIALOGUE_CLOSE = createRequestActionTypes("SET_CONTRACT_DOCS_DIALOGUE_CLOSE");
export const setContractDocsDialogueClose = (contractId: number) => ({ type: SET_CONTRACT_DOCS_DIALOGUE_CLOSE.reducer, payload: { contractId } });

/* Toggle Dialog */
export const TOGGLE_CONTRACT_DOCS_DIALOGUE_OPEN = createRequestActionTypes("TOGGLE_CONTRACT_DOCS_DIALOGUE_OPEN");
export const toggleContractDocsDialogueOpen = (contractId: number) => ({ type: TOGGLE_CONTRACT_DOCS_DIALOGUE_OPEN.reducer, payload: { contractId } });