import React, { MouseEvent } from 'react';
import { IconButton, CircularProgress } from '@material-ui/core';

interface MatIconButtonProps {
  disabled?: boolean;
  loading?: boolean;
  onClick?: (e: MouseEvent) => void;
  children: any;
  styles?: any;
  hint?: string;
}

export const MatIconButton = ({
  hint,
  disabled,
  onClick,
  children,
  styles,
  loading
}: MatIconButtonProps) => {

  const handleOnClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onClick && onClick(e);
  }

  return (
    <div style={styles}>
      <IconButton
        aria-label={hint?.toLocaleLowerCase()}
        disabled={disabled || loading}
        className="no-print"
        onClick={handleOnClick}
        type="button"
        title={hint}
      >
        {loading
          ? <CircularProgress size={22} className="fadeIn" />
          : <>{children}</>
        }
      </IconButton>
    </div>
  )
}