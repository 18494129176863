import styled from 'styled-components';

export const TableChartWrapper = styled.div`
  @media print {
    margin: 0;
    page-break-after: always;
  }

  .MuiPaper-rounded {
    display: flex;
    flex-direction: column;
    min-height: 539px;

    @media print {
      min-height: 300px;
      display: block;
    }
  }
 
`;

export const DividerWrapper = styled.div`
  margin: 1.5rem 0;
`;
