import {
    FETCH_ACCESSIBLE_PORTFOLIOS_FOR_PERSON,
    UPDATE_ACCESSIBLE_PORTFOLIOS,
    REMOVE_ACCESSIBLE_PORTFOLIOS
} from "components/AdminPanel/People/Actions/People.actions";

import { APIPrivileges } from "services/Interface/Interface";
import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";
import { arrayToDict } from "services/API/API.helper";

import { AccessiblePortfolioI, PersonAccessiblePortfoliosState } from "components/Portfolios/Interfaces/Portfolios.interface";

import { isEqual } from "lodash";

const initialState = {
    metaWrapper: {
        meta: {},
        putMeta: {},
    }
}

interface accessibleContractAction {
    personId: number;
    accessiblePortfolios?: AccessiblePortfolioI[];
    accessObjId?: string | number;
    meta?: FieldMetaGroup;
    putMeta?: FieldMetaGroup;
    permissions?: APIPrivileges;
    [otherOptions: string]: unknown;
}

function getMappings(existingList: AccessiblePortfolioI[] | undefined, newList: AccessiblePortfolioI[] | undefined) {
    const eL = existingList || [];
    const nL = newList || [];
    const existingData = arrayToDict(eL);
    const newData = arrayToDict(nL);
    return [existingData, newData];
}

function addOrUpdateRecords(existingList: AccessiblePortfolioI[] | undefined, newList: AccessiblePortfolioI[] | undefined) {
    const [eL, nL] = getMappings(existingList, newList);
    return Object.values({ ...eL, ...nL });
}

function fetchRecords(existingList: AccessiblePortfolioI[] | undefined, newList: AccessiblePortfolioI[] | undefined) {
    const [eL, nL] = getMappings(existingList, newList);
    return Object.values({ ...nL });
}

export const accessiblePortfoliosReducer = (state: PersonAccessiblePortfoliosState = initialState, action: accessibleContractAction) => {
    switch (action.type) {
        case FETCH_ACCESSIBLE_PORTFOLIOS_FOR_PERSON.reducer: {
            const personId = action.personId;
            const newSet = fetchRecords(state[personId]?.data, action.accessiblePortfolios)
            // Right now the rights to manage contract links do not vary by contract relation to the current user, but only by admin status.  
            // Therefore we will store the meta, putMeta and permissions on the 'root' state of this reduceer slice, rather than within each contract.  
            // However, should we ever want to allow the assignment of permissions 
            // to others based on the logged in user's relationship to a specific contract, then obviously we will have to move the permissions inside the contract lookup
            // structure and have the code that checks the permissions look them up on the relevant contract instead..
            const stateMetaWrapper = state.metaWrapper;
            let metaWrapper = undefined;
            const newMetaWrapper = {
                meta: {
                    ...action.meta,
                },
                putMeta: {
                    ...action.putMeta,
                },
                permissions: {
                    ...action.permissions
                }
            }
            if (!isEqual(stateMetaWrapper, newMetaWrapper)) {
                metaWrapper = newMetaWrapper
            } else {
                metaWrapper = stateMetaWrapper
            }
            return {
                ...state,
                [action.personId]: {
                    data: newSet,
                },
                metaWrapper: metaWrapper
            };
        }
        case UPDATE_ACCESSIBLE_PORTFOLIOS.reducer: {
            const personId = action.personId;
            const newSet = addOrUpdateRecords(state[personId]?.data, action.accessiblePortfolios)
            return {
                ...state,
                [action.personId]: {
                    data: newSet,
                }
            };
        }
        case REMOVE_ACCESSIBLE_PORTFOLIOS.reducer: {
            const accessList = state[action.personId]?.data || [];
            const newList = accessList.filter(accessObj => accessObj.id.toString() !== action.accessObjId?.toString())
            return {
                ...state,
                [action.personId]: {
                    data: newList,
                }
            };
        }

        default:
            return state;
    }
};

export default accessiblePortfoliosReducer;