import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

// Own
import ConfirmationModal from 'components/Common/Components/Modal/Modal';
import { ModalProps } from 'components/Common/Components/Modal/interfaces/modal.interfaces';
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { contractInFocusPersonalNotificationSettings } from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import { portfolioInFocusPersonalNotificationSettings } from "components/PortfolioInFocus/Selectors/portfolioInFocus.selectors";
import { updatePersonalContractInFocusSettings, updatePersonalNotificationSettings } from "components/ContractInFocus/Services/personalInFocusSettings.services";
import { MainInFocusVisibilitySettings, NotificationContractSettings, NotificationPortfolioSettings } from "components/ContractInFocus/interfaces/ContractInFocus.interfaces"
import styles from "styles/app/common/variables.scss";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

export const materialModalStyles = makeStyles(theme =>
    createStyles({
        selectItem: {
            fontSize: 14,
            minWidth: 200
        },
        formControl: {
            margin: theme.spacing(1),
            paddingRight: "1.5rem"
        },
        textInputControl: {
            minWidth: 350,
        },
        selectFormControl: {
            transform: "translateY(4px)",
            minWidth: 200,
        },
        selectEmpty: {
            marginTop: theme.spacing(2)
        }
    })
);
interface GeneralPersonalContractSettingsProps extends ModalProps {
    contract?: SiteContract;
    portfolio?: HydratedPortfolio;
    manageOpen: Function;
}

export default ({
    isOpen = true,
    contract,
    portfolio,
    onCancel,
    manageOpen,
}: GeneralPersonalContractSettingsProps) => {

    const personalContractNotificationSettings = useSelector(contractInFocusPersonalNotificationSettings);
    const personalPortfolioNotificationSettings = useSelector(portfolioInFocusPersonalNotificationSettings);
    let personalNotificationSettings: NotificationContractSettings | NotificationPortfolioSettings | undefined = undefined;
    let updatePersonalNotificationSettingsTitle = '';

    if (portfolio) {
        personalNotificationSettings = personalPortfolioNotificationSettings;
        updatePersonalNotificationSettingsTitle = `Your Personal Portfolio Settings for Portfolio: '${portfolio?.name}'`;
    } else {
        updatePersonalNotificationSettingsTitle = `Your Personal Contract Settings for Contract ${contract?.contract_ref}`;
        personalNotificationSettings = personalContractNotificationSettings;
    }

    const [buttonActive, activateButton] = useState(false);

    let pNVs = { ...personalNotificationSettings };

    const localCancel = (e: any) => {
        setPersonalNotificationValues(pNVs);
        onCancel && onCancel(e);
    }

    const [personalNotificationValues, setPersonalNotificationValues] = useState(personalNotificationSettings);

    const modalClasses = materialModalStyles();
    const handleCloseDialog = () => {
        manageOpen(false);
    }

    const handleUpdate = (event: React.MouseEvent): void => {
        // console.log('personalNotificationSettings: ', personalNotificationSettings);
        personalNotificationSettings?.id && updatePersonalNotificationSettings(
            {
                contract: contract,
                portfolio: portfolio,
                personalSettingsId: personalNotificationSettings.id,
                payload: personalNotificationValues,
                callback: handleCloseDialog
            })
    };

    function updateBooleanNotificationSettingFactory(setting: string) {
        return (event: any) => {
            activateButton(true);
            setPersonalNotificationValues({ ...personalNotificationValues, [setting]: event.target.checked })
        }
    }

    return (
        <>
            {personalNotificationSettings && (contract || portfolio) &&
                <ConfirmationModal
                    id="updatePersonalContractInFocusSettings"
                    isOpen={isOpen}
                    title={updatePersonalNotificationSettingsTitle}
                    onCancel={localCancel}
                    onConfirmation={handleUpdate}
                    size="xl"
                    okCaption="Update"
                    disabled={!buttonActive}
                    bodyStyles={{ backgroundColor: styles.blueLight }}
                >
                    <Paper elevation={3}>
                        <h5 style={{ paddingTop: "1rem" }}>Send me an email about <strong>Additional Work Items</strong> when:</h5>
                        <FormControl className={`${modalClasses.formControl}`}>
                            <FormLabel className="generalPersonalContractSettingsFormGuideText" style={{ fontStyle: "italic", fontSize: "0.9rem" }} component="legend">The following kind of documents are uploaded or removed for an additional works quote:</FormLabel>
                            <FormGroup title="Additional Works Document Notification Settings">
                                {/* Looping through the personalNotificationValues keys would be very much a false economy of effort for maintenance here.  Better to explicitly order and group them visually */}
                                <FormControlLabel control={
                                    <Checkbox name="notification_email_on_aw_po_document_add_or_remove"
                                        checked={personalNotificationValues.notification_email_on_aw_po_document_add_or_remove}
                                        onChange={updateBooleanNotificationSettingFactory("notification_email_on_aw_po_document_add_or_remove")}
                                    />
                                }
                                    label="A purchase order"
                                />
                                <FormControlLabel control={
                                    <Checkbox name="notification_email_on_other_document_add_or_remove"
                                        checked={personalNotificationValues.notification_email_on_other_document_add_or_remove}
                                        onChange={updateBooleanNotificationSettingFactory("notification_email_on_other_document_add_or_remove")}
                                    />
                                }
                                    label="Any other kind of document"
                                />
                            </FormGroup>
                            <FormGroup title="Additional Works Status Notification Settings">
                                <FormLabel className="generalPersonalContractSettingsFormGuideText" style={{ fontStyle: "italic", fontSize: "0.9rem" }} component="legend">An additional works quote is assigned the following status:</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox name="notification_email_on_aw_fp_quote_status"
                                        checked={personalNotificationValues.notification_email_on_aw_fp_quote_status}
                                        onChange={updateBooleanNotificationSettingFactory("notification_email_on_aw_fp_quote_status")}
                                    />
                                }
                                    label="New (aka 'quote')"
                                />
                                <FormControlLabel control={
                                    <Checkbox name="notification_email_on_aw_quote_expired"
                                        checked={personalNotificationValues.notification_email_on_aw_quote_expired}
                                        onChange={updateBooleanNotificationSettingFactory("notification_email_on_aw_quote_expired")}
                                    />
                                }
                                    label="Quote Expired"
                                />
                                <FormControlLabel control={
                                    <Checkbox name="notification_email_on_aw_quote_accept_or_reject"
                                        checked={personalNotificationValues.notification_email_on_aw_quote_accept_or_reject}
                                        onChange={updateBooleanNotificationSettingFactory("notification_email_on_aw_quote_accept_or_reject")}
                                    />
                                }
                                    label="Accepted or Rejected"
                                />
                                <FormControlLabel control={
                                    <Checkbox name="notification_email_on_aw_fp_complete_status"
                                        checked={personalNotificationValues.notification_email_on_aw_fp_complete_status}
                                        onChange={updateBooleanNotificationSettingFactory("notification_email_on_aw_fp_complete_status")}
                                    />
                                }
                                    label="Complete"
                                />
                                <FormControlLabel control={
                                    <Checkbox name="notification_email_on_aw_fp_accounts_status"
                                        checked={personalNotificationValues.notification_email_on_aw_fp_accounts_status}
                                        onChange={updateBooleanNotificationSettingFactory("notification_email_on_aw_fp_accounts_status")}
                                    />
                                }
                                    label="Accounts"
                                />
                                <FormControlLabel control={
                                    <Checkbox name="notification_email_on_aw_fp_invoiced_status"
                                        checked={personalNotificationValues.notification_email_on_aw_fp_invoiced_status}
                                        onChange={updateBooleanNotificationSettingFactory("notification_email_on_aw_fp_invoiced_status")}
                                    />
                                }
                                    label="Invoiced"
                                />
                                <FormControlLabel control={
                                    <Checkbox name="notification_email_on_aw_fp_cancelled_status"
                                        checked={personalNotificationValues.notification_email_on_aw_fp_cancelled_status}
                                        onChange={updateBooleanNotificationSettingFactory("notification_email_on_aw_fp_cancelled_status")}
                                    />
                                }
                                    label="Cancelled"
                                />
                            </FormGroup>
                            <FormGroup title="Additional Works Miscellaneous Notification Settings">
                                <FormLabel className="generalPersonalContractSettingsFormGuideText" style={{ fontStyle: "italic", fontSize: "0.9rem" }} component="legend">Miscellaneous:</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox name="notification_email_on_aw_comment"
                                        checked={personalNotificationValues.notification_email_on_aw_comment}
                                        onChange={updateBooleanNotificationSettingFactory("notification_email_on_aw_comment")}
                                    />
                                }
                                    label="When comments are made on an additional works quote"
                                />
                            </FormGroup>
                        </FormControl>
                    </Paper>
                    <br />
                    <Paper elevation={3}>
                        <h5 style={{ paddingTop: "1rem" }}>Send me an email about <strong>Snapshots</strong> when:</h5>
                        <FormControl className={`${modalClasses.formControl}`}>
                            {/* <FormLabel className="generalPersonalContractSettingsFormGuideText" style={{ fontStyle: "italic", fontSize: "0.9rem" }} component="legend">A new snapshot (or snapshot revision) is published:</FormLabel> */}
                            <FormGroup title="Additional Works Document Notification Settings">
                                {/* Looping through the personalNotificationValues keys would be very much a false economy of effort for maintenance here.  Better to explicitly order and group them visually */}
                                <FormControlLabel control={
                                    <Checkbox name="notification_email_on_snapshot_publication"
                                        checked={personalNotificationValues.notification_email_on_snapshot_publication}
                                        onChange={updateBooleanNotificationSettingFactory("notification_email_on_snapshot_publication")}
                                    />
                                }
                                    label="A new snapshot (or snapshot revision) is published" // tweak here for portfolios when we know what we're doing there
                                />
                            </FormGroup>
                        </FormControl>
                    </Paper>
                </ConfirmationModal>
            }
        </>
    );
}