// NB we don't have an 'add/remove contract(s)' reducer because when a contract is added or removed, other autocalculated values assigned to the portfolio
// are likely to change - as such when we add or remove a contract, we fetch and update the entire portfolio object.  On adding or removing a contract we'd expect
// that most if not all components plugged into the portfolio will need to re-render anyway. A similar consideration applies to deleting a contract entirely 
// (albeit that probably won't be allowed on the BE until the relationship to the portfolio is removed)

import {
    FETCH_PORTFOLIOS,
    SET_PORTFOLIO,
    DELETE_PORTFOLIO,
    UPDATE_PORTFOLIO_IMAGE,
    FAVOURITE_PORTFOLIO,
    SET_GROUP_PORTFOLIO,
} from "components/AdminPanel/Portfolios/Actions/AdminPanelPortfolios.actions";

import { Portfolio } from "../Interfaces/Portfolios.interface";
import {
    Meta,
    Dictionary,
    FieldMetaGroup
} from "../../Common/Interfaces/Entity.interface";
import { fetchReducer } from "components/AdminPanel/Helpers/Entity.helper";
import {
    mergeChildObjectsByKey,
} from "components/AdminPanel/Reducers/adminReducer.helper";

const initialState = {
    selectedPortfolio: null,
    hasNoPortfolio: undefined,
    data: {},
    recordmeta: {},
    meta: {
        PUT: undefined,
        POST: undefined
    }
};

//export interface PortfolioState extends AdminState {
export interface PortfolioState {
    selectedPortfolio: null,
    hasNoPortfolio?: boolean; // makes more sense to define this as hasNoPortfolio rather than hasPortfolio, so it only becomes truthy once the call has returned.
    data: Dictionary<Portfolio>;
    meta: Meta;
    recordmeta: Dictionary<FieldMetaGroup>;
}

const portfoliosReducer = (state: PortfolioState = initialState, action: any) => {
    switch (action.type) {
        case FETCH_PORTFOLIOS.success: {
            const newState = fetchReducer(state, action, 'portfolios');
            newState['hasNoPortfolio'] = !Object.keys(newState.data).length;
            return newState;
        }

        case SET_PORTFOLIO.reducer: {
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.portfolio.id]: {
                        ...action.portfolio,
                    },
                }
            };
        }

        case DELETE_PORTFOLIO.reducer: {
            delete state.data[action.portfolioId];
            if (state.recordmeta) {
                delete state.recordmeta[action.portfolioId];
            }
            return {
                ...state,
                data: {
                    ...state.data,
                }
            };
        }

        case UPDATE_PORTFOLIO_IMAGE.success: {
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.portfolio.id]: action.portfolio
                }
            };
        }

        case DELETE_PORTFOLIO.success: {
            const data: any = { ...state.data };
            delete data[action.portfolioId];

            return {
                ...state,
                data
            };
        }

        case FAVOURITE_PORTFOLIO.success: {
            const data: Dictionary<Portfolio> = state.data;

            const newData: any = {
                ...state.data,
                [action.portfolioId]: {
                    ...data[action.portfolioId],
                    favourite: action.favourited
                }
            };

            return {
                ...state,
                data: newData
            };
        }

        default:
            return state;
    }
};

export default portfoliosReducer;