export const grayDark = '#3a3f51';
export const grayMedDark = '#b1b1b1';
export const fontFamily = 'Jost';//, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial';

export const gridBreakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
};